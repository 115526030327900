import { BigNumber } from 'bignumber.js';

import { DAYS_OF_YEAR, SECONDS_OF_YEAR } from '../consts/fixtures';

export interface Price {
  value: { price: number | string; decimal: number | string };
}

/**
 * https://parallelfi.atlassian.net/browse/OM-1310?focusedCommentId=18443
 * @param apy
 */
export const calculateDailyInterest = (apy: BigNumber) =>
  BigNumber(apy.plus(1).toNumber() ** (1 / SECONDS_OF_YEAR))
    .minus(1)
    .times(SECONDS_OF_YEAR)
    .times(1 / DAYS_OF_YEAR);

export const shiftedLeftBy = (amount: string | number | BigNumber, decimals: number): BigNumber => {
  return BigNumber(amount).shiftedBy(-Math.abs(decimals));
};

export const shiftedRightBy = (amount: BigNumber, decimals: number): BigNumber => {
  return BigNumber(amount.shiftedBy(decimals).toFixed(0, BigNumber.ROUND_DOWN));
};
