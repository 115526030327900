import { mapValues, omit } from 'lodash';
import { Environment } from 'parax-sdk';
import { ChainId } from '@parallel-utils/contracts-registry';

import { ContractName } from '../types';

import { getContractsConfig } from './contractsConfig';

import { ERC721Symbol, SupportedNetworks } from '@/apps/paraspace/typings';
import { convertToChecksumAddress } from '@/apps/paraspace/utils/convertToChecksumAddress';

export type ERC721Config = Record<
  ERC721Symbol,
  {
    symbol: ERC721Symbol;
    contractName: ContractName;
    collectionName: string;
    wrappedContractName: ContractName;
    nftEnumerableType: number;
    address: string;
    xTokenAddress: string;
    selfIncrementTokenId: boolean;
    unlisted?: boolean;
  }
>;

export const getErc721Config = (chainId: SupportedNetworks, env: Environment) => {
  const contractsConfig = getContractsConfig(env);
  const collections = [ChainId.Ethereum, ChainId.Sepolia].includes(chainId as number)
    ? omit(contractsConfig.nft[chainId]?.collection, ERC721Symbol.MBEAN)
    : contractsConfig.nft[chainId]?.collection;
  return mapValues(
    collections,
    (
      { contractName, nTokenContractName, collectionName, nftEnumerableType, selfIncrementTokenId },
      symbol: string
    ) => ({
      symbol: symbol as ERC721Symbol,
      contractName,
      collectionName,
      nftEnumerableType,
      wrappedContractName: contractName,
      address: convertToChecksumAddress(contractsConfig.contracts[chainId][contractName]),
      xTokenAddress: convertToChecksumAddress(
        contractsConfig.contracts[chainId][nTokenContractName]
      ),
      selfIncrementTokenId
    })
  ) as ERC721Config;
};
