import { Button, Input, Stack, StackProps } from '@parallel-mono/components';
import { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import { utils } from 'ethers';

type DelegateNftFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  totalTokens: number;
  onSubmit: (delegate: string) => void;
};

export const DelegateNftForm = memo(
  ({ totalTokens, onSubmit, ...others }: DelegateNftFormProps) => {
    const [address, setAddress] = useState('');

    const handleDelegateButtonClicked = useCallback(() => {
      onSubmit(address);
    }, [onSubmit, address]);

    const handleAddressChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setAddress(e.target.value);
      },
      [setAddress]
    );

    const error = useMemo(() => {
      if (utils.isAddress(address)) {
        return null;
      }

      return 'Please enter a valid address';
    }, [address]);

    return (
      <Stack gap="1rem" {...others}>
        <Input placeholder="0x" value={address} onChange={handleAddressChange} label="Address" />
        <Button size="large" block disabled={!!error} onClick={handleDelegateButtonClicked}>
          {error ?? `Delegate ${totalTokens} NFTs`}
        </Button>
      </Stack>
    );
  }
);
