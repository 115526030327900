import { memo, FC, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';

import { useBuyCartProvider } from '../../../context';

import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { InProgress, ApproveWallet } from '@/apps/paraspace/components';
import { useParallelToast, useWeb3Context } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { ShopListingWithProtocolQuery } from '@/apps/paraspace/generated/graphql';
import { SELLER_HEALTH_FACTOR_IS_TOO_LOW } from '@/apps/paraspace/pages/Shop/hooks';
import { Platform } from '@/apps/paraspace/typings';

type Props = {
  formData: {
    buyNowAmount: string;
    creditAmount: string;
    protocolData: ShopListingWithProtocolQuery;
  };
  onSuccess: () => void;
  onFailed: (e: Error) => void;
};

enum Phase {
  Approving,
  Submitting
}

export const BatchBuyOpenseaSubmitter: FC<Props> = memo(({ formData, onFailed, onSuccess }) => {
  const [phase, setPhase] = useState<Phase>(Phase.Approving);

  const toast = useParallelToast();
  const { batchBuyWithCredit } = usePool();
  const { submitTransactions } = useWeb3Context();
  const { buyCartList } = useBuyCartProvider();
  const { buyNowAmount, creditAmount, protocolData } = formData;

  useEffect(() => {
    let creditLeft = BigNumber(creditAmount);
    const payLaterAmounts = buyCartList.map(item => {
      const usedCredit = creditLeft.isGreaterThan(item.price) ? item.price : creditLeft;
      if (usedCredit.isGreaterThan(0)) {
        creditLeft = creditLeft.minus(usedCredit);
      }
      return usedCredit.toString();
    });
    const platforms = buyCartList.map(item => item.platform.toUpperCase()) as Platform[];
    const protocolVersions = protocolData.shopListingWithProtocol.map(
      item => item!.protocolVersion!
    );
    const protocolContracts = protocolData.shopListingWithProtocol.map(
      item => item!.protocolContract!
    );
    const marketProtocolData = protocolData.shopListingWithProtocol.map(item => item!.protocolData);

    toast.promise(
      batchBuyWithCredit({
        buyNowAmount: buyNowAmount.toString(),
        payLaterAmounts,
        platforms,
        marketProtocolData,
        protocolVersions,
        protocolContracts
      })
        .then(txs => submitTransactions(txs))
        .then(async tx => {
          setPhase(Phase.Submitting);
          await tx?.wait();
          onSuccess();
        })
        .catch(async (error: Error) => {
          onFailed(error);
          throw getUserFriendlyError(error, SELLER_HEALTH_FACTOR_IS_TOO_LOW);
        })
    );
  }, [
    batchBuyWithCredit,
    submitTransactions,
    buyCartList,
    buyNowAmount,
    creditAmount,
    protocolData,
    onFailed,
    onSuccess,
    toast
  ]);

  if (phase === Phase.Approving) {
    return <ApproveWallet />;
  }

  return <InProgress tip="" />;
});
