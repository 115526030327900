import { useState, useCallback } from 'react';
import ReactGA from 'react-ga4';

import { UnmatchModalProps } from '../UnmatchModal';
import { ApeCoinListing, ApeListing, BakcListing } from '../../../types';
import { initialApeCoinListing, initialApeListing } from '../../../consts';

import { useParallelToast } from '@/apps/paraspace/contexts';
import { Maybe, useUnmatchP2PApeStakingListingMutation } from '@/apps/paraspace/generated/graphql';
import useP2PPairStaking from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

const defaultUnmatchListingModalProps: UnmatchModalProps = {
  apeListing: initialApeListing,
  apeCoinListing: initialApeCoinListing,
  bakcListing: null,
  isOpen: false,
  disabled: false
};

export const useUnmatchListing = (): [
  UnmatchModalProps,
  (params: {
    blockHeight: string;
    matchedHash: string;
    apeListing: ApeListing;
    apeCoinListing: ApeCoinListing;
    bakcListing: Maybe<BakcListing>;
  }) => Promise<void>
] => {
  const [unmatchListingModalProps, setUnmatchModalProps] = useState<UnmatchModalProps>(
    defaultUnmatchListingModalProps
  );

  const parallelToast = useParallelToast();

  const { unmatchListing: unmatchListingFromContract } = useP2PPairStaking();
  const [unmatchP2PApeStakingListingFromService] = useUnmatchP2PApeStakingListingMutation();

  const unMatchListingImpl = useCallback(
    (matchedHash: string, blockHeight: string) => {
      setUnmatchModalProps(curr => ({ ...curr, disabled: true }));
      return unmatchListingFromContract(matchedHash)
        .then(async result => {
          setUnmatchModalProps(curr => ({
            ...curr,
            isOpen: false
          }));
          await result?.wait();
          await unmatchP2PApeStakingListingFromService({
            variables: {
              matchedHash,
              blockHeight
            }
          });
        })
        .finally(() => {
          setUnmatchModalProps(curr => ({ ...curr, disabled: false }));
        });
    },
    [unmatchListingFromContract, unmatchP2PApeStakingListingFromService]
  );

  const unmatchListing = useCallback(
    ({
      blockHeight,
      matchedHash,
      apeListing,
      apeCoinListing,
      bakcListing
    }: {
      blockHeight: string;
      matchedHash: string;
      apeListing: ApeListing;
      apeCoinListing: ApeCoinListing;
      bakcListing: Maybe<BakcListing>;
    }) => {
      return new Promise<void>((resolve, reject) => {
        setUnmatchModalProps(curr => ({
          ...curr,
          isOpen: true,
          onClose: () => {
            setUnmatchModalProps(prev => ({
              ...prev,
              isOpen: false
            }));
            reject();
          },
          onConfirm: () => {
            parallelToast
              .promise(
                unMatchListingImpl(matchedHash, blockHeight).catch(error => {
                  throw getUserFriendlyError(error);
                })
              )
              .then(() => {
                ReactGA.event({ action: 'apestaking', category: 'p2pstaking', label: 'unmatch' });
                resolve();
              })
              .catch(reject);
          },
          apeListing,
          apeCoinListing,
          bakcListing
        }));
      });
    },
    [parallelToast, unMatchListingImpl]
  );

  return [unmatchListingModalProps, unmatchListing];
};
