import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ReservoirBid } from '@/apps/paraspace/generated/graphql';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { BlurBid } from '@/apps/paraspace/pages/hooks/useAcceptBlurBid';
import { useFormatEntity } from '@/apps/paraspace/pages/Shop/hooks';

export const useFormatBlurBid = (order: ReservoirBid): BlurBid => {
  const { account } = useWeb3Context();
  const { erc20InfoMap } = useMMProvider();
  const { offerValue, contractAddress, identifierOrCriteria, hash } = order;
  const { formatOrder } = useFormatEntity();
  const { platformFee } = formatOrder(order);

  return {
    initiator: account,
    paymentToken: erc20InfoMap.WETH.address,
    bidingPrice: offerValue!,
    collection: contractAddress,
    tokenId: Number(identifierOrCriteria),
    bidOrderHash: hash,
    marketPlaceFee: platformFee.toString()
  };
};
