import { noop } from 'lodash';
import { useCallback, useState } from 'react';

import { SupplyNftModalProps } from '../modals';

import { ERC721Symbol, WalletType } from '@/apps/paraspace/typings';

const defaultModalProps: SupplyNftModalProps = {
  isOpen: false,
  symbol: ERC721Symbol.MAYC,
  collectionName: '',
  walletType: 'AA',
  onClose: noop
};

export const useSupplyNft = (): [
  SupplyNftModalProps,
  (symbol: ERC721Symbol, collectionName: string, walletType: WalletType) => Promise<void>
] => {
  const [modalProps, setModalProps] = useState<SupplyNftModalProps>(defaultModalProps);

  const supplyNft = useCallback(
    (symbol: ERC721Symbol, collectionName: string, walletType: WalletType) => {
      if (modalProps.isOpen) {
        throw new Error('There is a supplying in progress');
      }
      return new Promise<void>((resolve, reject) => {
        setModalProps(curr => ({
          ...curr,
          isOpen: true,
          symbol,
          walletType,
          collectionName,
          onError: reject,
          onFinish: resolve,
          onClose: () => {
            setModalProps(prev => ({ ...prev, isOpen: false }));
            resolve();
          }
        }));
      });
    },
    [modalProps.isOpen]
  );

  return [modalProps, supplyNft];
};
