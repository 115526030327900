import { useCallback, useMemo } from 'react';
import { DeploylessViewerClient } from 'deployless-view';
import { zipWith } from 'lodash';
import { BigNumber } from 'ethers';
import { CrytoPunkService } from 'paraspace-utilities-contract-helpers';

import useERC721 from './useERC721';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

/**
 * @deprecated use useCryptoPunks hook instead
 * @returns
 */
const useCryptPunksMarket = () => {
  const { provider, eoaAccount: account, submitEOATransactions } = useWeb3Context();

  const contracts = useContractsMap();
  const { ownerOf } = useERC721(contracts.nWPUNKS);

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new CrytoPunkService(provider, contracts.PUNKS);
  }, [contracts.PUNKS, provider]);

  const viewer = useMemo(() => {
    if (!provider) {
      return null;
    }

    return new DeploylessViewerClient(provider);
  }, [provider]);

  const getPunksApprovedInfo = useCallback(
    async (ids: number[]) => {
      if (!service || !viewer) {
        return [];
      }
      const punkContract = service.getContractInstance(contracts.PUNKS);

      const raw = await viewer?.multicall(
        ids.map(id => {
          return {
            target: contracts.PUNKS,
            callData: punkContract.interface.encodeFunctionData('punksOfferedForSale', [id])
          };
        })
      );

      const punksInfos = await Promise.all(
        raw.resultsArray.map(async (each: any) => {
          const result = punkContract.interface.decodeFunctionResult('punksOfferedForSale', each);
          const owner = await ownerOf(result.punkIndex.toNumber());
          // supplied
          if (owner === account) {
            return true;
          }
          // approved but not supplied
          if (
            result.isForSale === true &&
            result.onlySellTo === contracts.WPunkGatewayProxy &&
            result.minValue.lte(BigNumber.from(0))
          ) {
            return true;
          }
          return false;
        })
      );

      const res = zipWith(ids, punksInfos, (left: number, right: boolean) => {
        return {
          id: left,
          approved: right
        };
      });

      return res as { id: number; approved: boolean }[];
    },

    [account, contracts.PUNKS, contracts.WPunkGatewayProxy, ownerOf, service, viewer]
  );

  const offerPunkForSaleToAddress = useCallback(
    async (punkIndex: number) => {
      if (!provider || !service) {
        return null;
      }

      const tx = await service.offerPunkForSaleToAddress({
        user: account,
        punkIndex,
        minSalePriceInWei: '0',
        targetAddress: contracts.WPunkGatewayProxy
      });
      const txRes = submitEOATransactions(tx);
      return txRes;
    },
    [provider, service, account, contracts.WPunkGatewayProxy, submitEOATransactions]
  );

  return { getPunksApprovedInfo, offerPunkForSaleToAddress };
};

export default useCryptPunksMarket;
