import { Inline, Stack, Button, Modal, Text } from '@parallel-mono/components';
import { memo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import NestingInProgress from './NestingInProgress';
import NestingOnHold from './NestingOnHold';
import { CalculatedNestingData } from './useMoonBirdsData';

import { NFTThumbnail } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';

interface NestingProps {
  data: CalculatedNestingData;
  handleStatus: () => void;
  inToggleNesting: boolean;
}

const NestingBase = ({ data, handleStatus, inToggleNesting }: NestingProps) => {
  const { tokenId } = useParams();

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);
  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleUnNestClick = () => {
    handleStatus();
    setModalOpen(false);
  };

  return (
    <>
      {data.nesting ? (
        <NestingInProgress data={data} showUnNest handleUnNestClick={handleModalOpen} />
      ) : (
        <NestingOnHold
          level={data.level}
          totalTime={data.totalTime}
          handleNestClick={handleStatus}
          inToggleNesting={inToggleNesting}
        />
      )}
      <Modal
        title="Un-nest"
        isOpen={modalOpen}
        onClose={handleModalClose}
        size="medium"
        closeOnBackdropClick={false}
      >
        <Stack gap="3rem">
          <Stack gap="1.5rem">
            <Text skin="secondary">Are you sure you want to un-nest.</Text>
            <NFTThumbnail
              symbol={ERC721Symbol.MOONBIRD}
              tokenId={Number(tokenId)}
              size="medium"
              showDescription
            />
          </Stack>
          <NestingInProgress data={data} />
          <Inline>
            <Button skin="secondary" size="large" block onClick={handleModalClose}>
              Nevermind
            </Button>
            <Button size="large" block onClick={handleUnNestClick} disabled={inToggleNesting}>
              Un-Nest
            </Button>
          </Inline>
        </Stack>
      </Modal>
    </>
  );
};

export const Nesting = memo(NestingBase);
