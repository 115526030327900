import { memo, useCallback } from 'react';
import { TransactionReceipt } from '@ethersproject/providers';
import ReactGA from 'react-ga4';

import { FormSubmitter } from '../FormSubmitter';

import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { TimelockStepIntro } from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useTimelock } from '@/apps/paraspace/pages/hooks/Timelock';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type WithdrawERC20SubmitterProps = {
  formData: {
    amount: number | string;
    symbol: ERC20Symbol;
  };
  onFinish: (agreementId: Maybe<string>, releaseTime: Maybe<Date>) => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const BorrowERC20Submitter = memo(
  ({ formData, onFinish, onError }: WithdrawERC20SubmitterProps) => {
    const { borrowERC20 } = usePool();
    const { erc20InfoMap } = useMMProvider();
    const { submitTransactions } = useWeb3Context();
    const { symbol, amount } = formData;
    const { address } = erc20InfoMap[symbol];
    const { extractEventData } = useTimelock();

    const handleFinish = useCallback(
      ([result]: Maybe<TransactionReceipt>[]) => {
        const { logs = [] } = result ?? {};
        const [{ releaseTime = null, agreementId = null } = {}] = extractEventData<{
          releaseTime: number;
          agreementId: string;
        }>('AgreementCreated', logs);
        ReactGA.event({
          action: 'borrow',
          category: 'erc20',
          label: symbol,
          value: Number(amount) ?? undefined
        });
        onFinish?.(agreementId, releaseTime === null ? null : new Date(releaseTime * 1000));
      },
      [extractEventData, symbol, amount, onFinish]
    );

    const submit = useCallback(async () => {
      const tx = await borrowERC20(address, `${amount}`);
      return submitTransactions([tx!]);
    }, [address, amount, borrowERC20, submitTransactions]);

    return (
      <FormSubmitter
        inProgressMessage="Securely Borrowing"
        onError={onError}
        onFinish={handleFinish}
        submit={submit}
      >
        <TimelockStepIntro />
      </FormSubmitter>
    );
  }
);
