import { useCallback, useState } from 'react';

import { CheckoutModalProps } from './modals';
import { BuyCartAsset } from './types';

const defaultCheckoutModalProps: CheckoutModalProps = {
  isOpen: false,
  assets: []
};

export const useCheckout = <A extends BuyCartAsset>(
  assets: A[]
): [CheckoutModalProps, () => Promise<A[]>] => {
  const [checkoutModalProps, setCheckoutModalProps] = useState(defaultCheckoutModalProps);

  const checkout = useCallback(() => {
    return new Promise<A[]>((resolve, reject) => {
      setCheckoutModalProps(curr => ({
        ...curr,
        assets,
        isOpen: true,
        onError: reject,
        onFinish: () => resolve(assets),
        onClose: () => {
          setCheckoutModalProps(prev => ({ ...prev, isOpen: false }));
        }
      }));
    });
  }, [assets]);

  return [checkoutModalProps, checkout];
};
