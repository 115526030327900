import { memo, useMemo, MouseEvent, useCallback, ReactNode } from 'react';
import { differenceBy } from 'lodash';
import styled from 'styled-components';
import {
  Button,
  H5,
  Inline,
  InlineProps,
  Pagination,
  SmallText,
  Stack,
  StackProps,
  Text,
  useDualModeState
} from '@parallel-mono/components';

import { TokenDelegationInfo } from '../types';

import {
  Collapse,
  NFTThumbnailCheck,
  AccountPill,
  Tooltip,
  NFTCollectionThumbnail,
  CollapseProps
} from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { usePagination } from '@/apps/paraspace/hooks/usePagination';
import { truncateTextMid } from '@/apps/paraspace/utils/format';
import { useScrollContainer } from '@/apps/paraspace/contexts';

type CollapsableTokensSelectorProps = Omit<CollapseProps, 'children' | 'header'> & {
  symbol: ERC721Symbol;
  headerHint: ReactNode;
  collectionName: string;
  tokens: TokenDelegationInfo[];
  selectedTokens: TokenDelegationInfo[];
  onSelectionChange: (selection: TokenDelegationInfo[]) => void;
};

const StyledCollapse = styled(Collapse)`
  border: 1px solid ${({ theme }) => theme.skin.grey[200]};
  width: 100%;
`;

const TokensContainer = styled.div`
  padding-top: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
`;

type ItemTipProps = Omit<StackProps, 'children'> & { delegate: string };

const ItemTipContainer = styled(Stack)`
  word-break: break-all;
  text-align: left;
`;

const ItemTip = memo(({ delegate, ...others }: ItemTipProps) => (
  <ItemTipContainer gap="0.25rem" {...others}>
    <H5>Delegated to:</H5>
    <Text>{delegate}</Text>
  </ItemTipContainer>
));

const stopPropagation = (e: MouseEvent) => e.stopPropagation();

type HeaderProps = Omit<InlineProps, 'children'> & {
  symbol: ERC721Symbol;
  collectionName: string;
  headerHint: ReactNode;
  buttonText: string;
  onButtonClicked: (e: MouseEvent) => void;
};

const Header = memo(
  ({ symbol, collectionName, headerHint, buttonText, onButtonClicked, ...others }: HeaderProps) => {
    const handleButtonClicked = useCallback(
      (e: MouseEvent) => {
        stopPropagation(e);
        onButtonClicked?.(e);
      },
      [onButtonClicked]
    );
    return (
      <Inline width="100%" justifyContent="space-between" {...others}>
        <Inline onClick={stopPropagation} gap="0.5rem">
          <NFTCollectionThumbnail symbol={symbol} size="small" />
          <Stack gap="0">
            <Text>{collectionName}</Text>
            <SmallText>{headerHint}</SmallText>
          </Stack>
        </Inline>
        <Button onClick={handleButtonClicked} skin="secondary">
          {buttonText}
        </Button>
      </Inline>
    );
  }
);

const PAGE_SIZE = 10;
export const CollapsableTokensSelector = memo(
  ({
    symbol,
    headerHint,
    tokens,
    collectionName,
    selectedTokens,
    onSelectionChange,
    defaultOpen,
    onToggle,
    open,
    ...others
  }: CollapsableTokensSelectorProps) => {
    const [finalOpen, setInternalOpen] = useDualModeState(defaultOpen, open);
    const handleToggleOpen = useCallback(() => {
      setInternalOpen(!finalOpen);
      onToggle?.(!finalOpen);
    }, [onToggle, finalOpen, setInternalOpen]);

    const selectedTokenIds = useMemo(() => selectedTokens.map(it => it.tokenId), [selectedTokens]);
    const handleSelectAll = useCallback(() => {
      if (!finalOpen) {
        handleToggleOpen();
      }
      onSelectionChange(tokens);
    }, [onSelectionChange, tokens, finalOpen, handleToggleOpen]);

    const handleUnselectAll = useCallback(() => {
      onSelectionChange([]);
    }, [onSelectionChange]);

    const allSelected = useMemo(
      () => differenceBy(tokens, selectedTokens, it => it.tokenId).length === 0,
      [selectedTokens, tokens]
    );

    const header = useMemo(
      () => (
        <Header
          symbol={symbol}
          headerHint={headerHint}
          collectionName={collectionName}
          buttonText={allSelected ? 'Unselect All' : 'Select All'}
          onButtonClicked={allSelected ? handleUnselectAll : handleSelectAll}
        />
      ),
      [symbol, handleSelectAll, handleUnselectAll, headerHint, collectionName, allSelected]
    );

    const handleToggleItem = useCallback(
      (delegation: TokenDelegationInfo) => {
        onSelectionChange(
          selectedTokenIds.includes(delegation.tokenId)
            ? selectedTokens.filter(it => it.tokenId !== delegation.tokenId)
            : selectedTokens.concat(delegation)
        );
      },
      [onSelectionChange, selectedTokens, selectedTokenIds]
    );

    const { currentPage, setCurrentPage, pageData, totalPage } = usePagination(tokens, PAGE_SIZE);

    const scrollContainer = useScrollContainer();
    return (
      <StyledCollapse {...others} open={finalOpen} onToggle={handleToggleOpen} header={header}>
        <TokensContainer>
          {pageData.map(delegation => {
            const { tokenId, delegate } = delegation;
            return (
              <NFTThumbnailCheck
                key={tokenId}
                symbol={symbol}
                tokenId={tokenId}
                checked={selectedTokenIds.includes(tokenId)}
                onChange={() => {
                  handleToggleItem(delegation);
                }}
              >
                <Stack alignItems="center" gap="0.5rem">
                  <Text>#{tokenId}</Text>
                  {delegate !== null && (
                    <Tooltip
                      getPopupContainer={() => scrollContainer!}
                      placement="top-start"
                      content={<ItemTip delegate={delegate} />}
                      key={String(tokenId)}
                    >
                      <AccountPill address={delegate} addressFormatter={truncateTextMid} />
                    </Tooltip>
                  )}
                </Stack>
              </NFTThumbnailCheck>
            );
          })}
        </TokensContainer>
        <Inline justifyContent="center" inset="1rem">
          {totalPage > 1 && (
            <Pagination
              total={totalPage}
              page={currentPage}
              onChange={setCurrentPage}
              siblingCount={0}
              startBoundaryCount={3}
            />
          )}
        </Inline>
      </StyledCollapse>
    );
  }
);
