import { Card, CardProps, H5, Icon, Stack, Text } from '@parallel-mono/components';
import { ReactNode, memo } from 'react';
import styled from 'styled-components';

type EmptyStateProps = Omit<CardProps, 'children'> & {
  title: ReactNode;
  description: ReactNode;
  children?: ReactNode;
};

const Container = styled(Card)`
  width: 100%;
`;

export const EmptyState = memo(({ title, description, children, ...others }: EmptyStateProps) => {
  return (
    <Container border {...others}>
      <Stack alignItems="center" width="100%">
        <Icon name="boxOpen" size="4rem" />
        <Stack alignItems="center" gap="0.25rem">
          <H5 fontWeight="bold">{title}</H5>
          <Text skin="secondary">{description}</Text>
        </Stack>
        {children}
      </Stack>
    </Container>
  );
});
