import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  H3,
  H5,
  Image,
  Scroller,
  Stack,
  SmallText,
  Text,
  Alert
} from '@parallel-mono/components';
import styled from 'styled-components';
import { InfoPanel } from '@parallel-mono/business-components';

import { SignProxy } from './SignProxy';

import { ApeListItem } from '@/apps/paraspace/pages/ApePairing/contexts';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useERC721Image } from '@/apps/paraspace/hooks';

type Props = {
  availableBaycAndMaycList: ApeListItem[];
  availableBakcList: ApeListItem[];
  isProxyCreated: boolean;
  contractEnabling: boolean;
  upgradeUserProxy: () => void;
  handleMint: (ape: ApeListItem, bakc?: ApeListItem) => void;
  minting: boolean;
};

const ImageContainer = styled(Stack).attrs({ gap: '0.25rem' })<{ active: boolean }>`
  border-radius: 0.5rem;
  padding: 2px;
  box-sizing: content-box;
  cursor: pointer;
  border: 2px solid
    ${({
      theme: {
        skin: { primary, secondary }
      },
      active
    }) => (active ? primary.main : secondary.main)};
  transition: border 0.2s;
`;

const Wrapper = styled(Stack)`
  max-width: 30rem;
  width: 100%;
`;

const TokenImage = ({
  token,
  active,
  onClick
}: {
  token: ApeListItem;
  active: boolean;
  onClick: () => void;
}) => (
  <ImageContainer active={active} onClick={onClick}>
    <Image src={useERC721Image(token.symbol, token.tokenId, 'large')} width="5.75rem" />
    <SmallText>#{token.tokenId}</SmallText>
  </ImageContainer>
);

const NoBalanceCard = styled(Card)`
  background: ${({ theme }) => theme.skin.grey[100]};
  padding: 2.5rem 1.5rem;
  text-align: center;
  box-shadow: none;
`;

export const MintSewerPass: FC<Props> = memo(
  ({
    availableBaycAndMaycList,
    availableBakcList,
    isProxyCreated,
    contractEnabling,
    upgradeUserProxy,
    handleMint,
    minting
  }) => {
    const [selectedApe, setSelectedApe] = useState<ApeListItem | undefined>();

    const [selectedBakc, setSelectedBakc] = useState<ApeListItem | undefined>();

    const tier = useMemo(() => {
      if (selectedApe?.symbol === ERC721Symbol.BAYC) {
        if (selectedBakc) {
          return '4';
        }
        return '3';
      }
      if (selectedApe?.symbol === ERC721Symbol.MAYC) {
        if (selectedBakc) {
          return '2';
        }
        return '1';
      }
      return '-';
    }, [selectedApe, selectedBakc]);

    const handleSelectBakc = useCallback(
      (bakc: ApeListItem) => {
        if (bakc.tokenId === selectedBakc?.tokenId && bakc.symbol === selectedBakc?.symbol) {
          setSelectedBakc(undefined);
        } else {
          setSelectedBakc(bakc);
        }
      },
      [selectedBakc]
    );

    useEffect(() => {
      setSelectedApe(undefined);
      setSelectedBakc(undefined);
    }, [availableBaycAndMaycList.length, availableBakcList.length]);

    return (
      <Wrapper>
        {!isProxyCreated && (
          <SignProxy upgradeUserProxy={upgradeUserProxy} contractEnabling={contractEnabling} />
        )}
        <Card border>
          <Stack>
            <H3>Mint Sewer Pass</H3>
            <Stack alignItems="center" gap="1rem">
              <H5>Select a BAYC/MAYC</H5>
              {availableBaycAndMaycList.length > 0 ? (
                <Scroller>
                  {availableBaycAndMaycList.map(ape => (
                    <TokenImage
                      key={ape.tokenId}
                      token={ape}
                      active={
                        selectedApe?.tokenId === ape.tokenId && selectedApe?.symbol === ape.symbol
                      }
                      onClick={() => setSelectedApe(ape)}
                    />
                  ))}
                </Scroller>
              ) : (
                <NoBalanceCard>
                  <Text skin="secondary">
                    No BAYC/MAYC on ParaSpace. Visit official website to mint with tokens in your
                    wallet.
                  </Text>
                </NoBalanceCard>
              )}
            </Stack>
            <Stack alignItems="center" gap="1rem">
              <H5>Select a BAKC</H5>
              {availableBakcList.length > 0 ? (
                <Scroller>
                  {availableBakcList.map(bakc => (
                    <TokenImage
                      key={bakc.tokenId}
                      token={bakc}
                      active={
                        selectedBakc?.tokenId === bakc.tokenId &&
                        selectedBakc?.symbol === bakc.symbol
                      }
                      onClick={() => handleSelectBakc(bakc)}
                    />
                  ))}
                </Scroller>
              ) : (
                <NoBalanceCard>
                  <Text skin="secondary">
                    No BAKC found in your wallet, but it is optional for mint.
                  </Text>
                </NoBalanceCard>
              )}
            </Stack>
            {selectedBakc && (
              <Alert type="warning">
                Auto-compound staking of BAKC is coming soon but currently not supported on
                ParaSpace. If you wish to stake more or claim rewards, please withdraw BAKC after
                minting from the platform.
              </Alert>
            )}
            <InfoPanel
              skin="primary"
              infos={[
                { title: 'Collection Name', value: 'Dookey Dash Sewer Pass' },
                { title: 'Your Tier', value: tier }
              ]}
            />
            <Button
              disabled={!selectedApe || !isProxyCreated || minting}
              loading={minting}
              block
              size="large"
              onClick={() => selectedApe && handleMint(selectedApe, selectedBakc)}
            >
              Mint
            </Button>
          </Stack>
        </Card>
      </Wrapper>
    );
  }
);
