import { HostedImage } from '@parallel-mono/business-components';
import {
  Button,
  H5,
  H6,
  Icon,
  Inline,
  SmallText,
  Stack,
  StackProps
} from '@parallel-mono/components';
import { floor, isEmpty } from 'lodash';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import BigNumber from 'bignumber.js';
import { useTheme } from 'styled-components';
import {
  TokenOption,
  Value
} from '@parallel-mono/business-components/components/SelectableTokenInput/types';
import { ChainId } from '@parallel-utils/contracts-registry';

import { RESERVED_GAS_FEE } from '../../configs';
import { ImageWrapper, StyledCard, StyledSelectableTokenInput } from '../StyledComponents';
import { NetworkPopup } from '../NetworkPopup';
import { useERC20Balances, useNetworkFee, useSupportedERC20s } from '../../hooks';
import { BridgeNetworks, parallelChain, supportedBridgeChains } from '../../configs/networks';
import { InfosPanel } from '../InfosPanel';
import { NetworkConfig } from '../../types';
import { useNetworkProvider } from '../../hooks/useNetworkProvider';
import { useBridgeTokenImpl } from '../../contexts';
import { getDisplayBalance } from '../../helper';

import { useValidation } from './hooks';
import { useNetworkHandler } from './hooks/useNetworkHandler';

import { useEOAProvider } from '@/apps/parax/contexts';
import { Maybe } from '@/apps/parax/typings/basic';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/parax/utils';
import { zero } from '@/apps/parax/consts';
import { useToggle } from '@/apps/parax/contexts/TogglesContext';

export type BalanceUpdaterImperative = {
  refetchBalance: () => void;
};

export const Bridge = memo(
  forwardRef<
    BalanceUpdaterImperative,
    Omit<StackProps, 'children'> & {
      // eslint-disable-next-line react/no-unused-prop-types
      onBridgeSuccess: (isNative: boolean) => void;
    }
  >(({ onBridgeSuccess }, ref: any) => {
    const [inputtedAddress, setInputtedAddress] = useState<string>();
    const [amount, setAmount] = useState<Maybe<number>>(null);
    const [selectedNetwork, setSelectedNetwork] = useNetworkHandler();
    const { account, chainId: connectedChainId, switchNetwork } = useEOAProvider();
    const erc20Enabled = useToggle('BRIDGE_ERC20_DEPOSIT');

    const selectedNetworkOption = useMemo(
      () => supportedBridgeChains.find(v => v.chainId === selectedNetwork),
      [selectedNetwork]
    );
    const supportedBridgeChainsOptions = useMemo(
      () =>
        supportedBridgeChains.filter(v =>
          erc20Enabled ? true : [ChainId.Ethereum, ChainId.Sepolia].includes(v.chainId)
        ),
      [erc20Enabled]
    );
    const supportedERC20s = useSupportedERC20s(selectedNetworkOption?.chainId ?? null);
    const [selectedTokenSymbol, setSelectedTokenSymbol] = useState<Maybe<string>>(null);

    const provider = useNetworkProvider(selectedNetworkOption?.chainId);
    const {
      balanceMap: tokenBalanceMap,
      fetchBalance: refetchBalances,
      fetchingStatus
    } = useERC20Balances(supportedERC20s, provider, selectedNetworkOption?.chainId);

    const refetchBalanceHandle = useMemo(
      () => ({
        refetchBalance: refetchBalances
      }),
      [refetchBalances]
    );

    useImperativeHandle(ref, () => refetchBalanceHandle);

    const nativeTokenInfo = useMemo(
      () =>
        BridgeNetworks[selectedNetwork]?.nativeCurrency as
          | NetworkConfig['nativeCurrency']
          | undefined,
      [selectedNetwork]
    );

    const supportedTokens = useMemo(() => {
      if (selectedNetworkOption && nativeTokenInfo) {
        // selectedNetworkOption.nativeTokenBridge
        const tokens = (erc20Enabled ? supportedERC20s : [])
          .map(v => v.symbol)
          .map(symbol => {
            const balance = tokenBalanceMap?.[symbol]?.balance ?? zero;
            const priceInUsd = tokenBalanceMap?.[symbol]?.priceInUsd ?? zero;
            return {
              name: symbol,
              symbol,
              balance: balance.toNumber(),
              displayBalance: getDisplayBalance(fetchingStatus, balance ?? zero),
              priceInUSD: priceInUsd.toNumber()
            } as TokenOption;
          });
        if (!selectedNetworkOption.nativeTokenBridge) {
          return tokens;
        }
        return [
          {
            name: selectedNetworkOption.nativeCurrency.name,
            symbol: selectedNetworkOption.nativeCurrency.symbol,
            balance:
              tokenBalanceMap?.[selectedNetworkOption.nativeCurrency.symbol]?.balance?.toNumber() ??
              0,
            displayBalance: getDisplayBalance(
              fetchingStatus,
              tokenBalanceMap?.[selectedNetworkOption.nativeCurrency.symbol]?.balance ?? zero
            ),
            priceInUSD:
              tokenBalanceMap?.[
                selectedNetworkOption.nativeCurrency.symbol
              ]?.priceInUsd?.toNumber() ?? 0
          } as TokenOption
        ].concat(tokens);
      }
      return [];
    }, [
      selectedNetworkOption,
      nativeTokenInfo,
      erc20Enabled,
      supportedERC20s,
      tokenBalanceMap,
      fetchingStatus
    ]);

    useEffect(() => {
      setSelectedTokenSymbol(curr => {
        if (curr === null || !supportedTokens.some(it => it.symbol === curr)) {
          return supportedTokens[0]?.symbol ?? null;
        }
        return curr;
      });
    }, [supportedTokens]);

    const selectedToken = useMemo(
      () => supportedTokens.find(v => v.symbol === selectedTokenSymbol) ?? null,
      [selectedTokenSymbol, supportedTokens]
    );

    const errorMessage = useValidation({ amount, token: selectedToken ?? null });
    const toAddress = useMemo(() => inputtedAddress ?? account, [account, inputtedAddress]);
    const { networkFee } = useNetworkFee(
      {
        symbol: selectedTokenSymbol ?? '',
        amount,
        eid: parallelChain.eId!,
        to: toAddress
      },
      provider,
      selectedNetworkOption?.chainId ?? null
    );

    const handleSelectTokenChange = useCallback((option: Value<TokenOption>) => {
      setAmount(option.amount);
      setSelectedTokenSymbol(option.token?.symbol ?? null);
    }, []);

    const handleMaxClick = useCallback(() => {
      if (!isEmpty(tokenBalanceMap)) {
        const selectedTokenBalance = selectedToken?.balance ?? 0;
        const maxBalance =
          selectedToken?.symbol === nativeTokenInfo?.symbol &&
          BigNumber(selectedTokenBalance).gt(zero)
            ? BigNumber(selectedTokenBalance).minus(RESERVED_GAS_FEE).toNumber()
            : selectedTokenBalance;
        setAmount(floor(maxBalance ?? 0, MAXIMUM_BALANCE_DECIMALS));
      }
    }, [nativeTokenInfo, selectedToken?.balance, selectedToken?.symbol, tokenBalanceMap]);

    const theme = useTheme();

    const { bridgeERC20Token, bridgeNativeToken } = useBridgeTokenImpl();

    const bridgeERC20TokenRef = useRef(bridgeERC20Token);
    bridgeERC20TokenRef.current = bridgeERC20Token;
    const bridgeNativeTokenRef = useRef(bridgeNativeToken);
    bridgeNativeTokenRef.current = bridgeNativeToken;

    const l1Provider = useNetworkProvider(selectedNetworkOption?.chainId);
    const l2Provider = useNetworkProvider(parallelChain.chainId);

    const [bridging, setBridging] = useState(false);
    const handleBridge = useCallback(async () => {
      if (connectedChainId !== (selectedNetworkOption!.chainId as number)) {
        await switchNetwork(selectedNetworkOption!.chainId as number);
      }
      setBridging(true);
      try {
        if (selectedNetworkOption?.nativeCurrency.symbol === selectedToken?.symbol) {
          const l1GasFeeInWei = new BigNumber((await l1Provider!.getGasPrice()).toString());
          const l2GasFeeInWei = new BigNumber((await l2Provider!.getGasPrice()).toString());
          await bridgeNativeTokenRef.current({
            amount: new BigNumber(amount!),
            receiver: toAddress,
            l1GasFeeInWei,
            l2GasFeeInWei,
            toChainEid: parallelChain.eId!
          });
          onBridgeSuccess(true);
        } else {
          await bridgeERC20TokenRef.current({
            amount: new BigNumber(amount!),
            receiver: toAddress,
            symbol: selectedToken?.symbol!,
            toChainEid: parallelChain.eId!
          });
          onBridgeSuccess(false);
        }
      } finally {
        setAmount(null);
        refetchBalances();
        setBridging(false);
      }
    }, [
      amount,
      refetchBalances,
      toAddress,
      selectedToken?.symbol,
      connectedChainId,
      selectedNetworkOption,
      switchNetwork,
      onBridgeSuccess,
      l1Provider,
      l2Provider
    ]);
    return (
      <Stack>
        <StyledCard shadow="none">
          <Inline gap="0" alignItems="center" justifyContent="space-between">
            <NetworkPopup
              title="From"
              activeNetwork={selectedNetwork}
              activeNetworkName={selectedNetworkOption?.name}
              activeNetworkSymbol={selectedNetworkOption?.symbol}
              onNetworkChange={option => setSelectedNetwork(option.chainId)}
              networkOptions={supportedBridgeChainsOptions}
            />
            <Icon name="arrowRight" color={theme.skin.text.sub1} strokeWidth="2" />
            <Inline width="145px" justifyContent="end" gap="0.75rem" alignItems="center">
              <Stack gap="0">
                <SmallText style={{ textAlign: 'right' }} skin="secondary" fontWeight="bold">
                  To
                </SmallText>
                <H6>Parallel</H6>
              </Stack>
              <ImageWrapper alignItems="center" justifyContent="space-between" inset="0.25rem">
                <HostedImage
                  height="1.5rem"
                  width="1.5rem"
                  name="design/PDS_V3/logo/parallel-v2-logo"
                />
              </ImageWrapper>
            </Inline>
          </Inline>
        </StyledCard>
        <Stack gap="0.5rem">
          <H5>Asset</H5>
          <StyledSelectableTokenInput
            value={{ token: selectedToken ?? null, amount }}
            defaultValue={{ token: supportedTokens[0], amount: null }}
            tokens={supportedTokens}
            decimals={MAXIMUM_BALANCE_DECIMALS}
            onChange={handleSelectTokenChange}
            onActionButtonClicked={handleMaxClick}
            hasError={Boolean(errorMessage)}
            actionButtonText="Max"
          />
        </Stack>
        <InfosPanel
          network={selectedNetwork}
          networkFee={networkFee}
          selectedToken={selectedToken}
          address={toAddress}
          amount={amount ?? 0}
          onAddressChange={value => setInputtedAddress(value)}
        />
        <Button
          size="large"
          block
          disabled={Boolean(errorMessage) || bridging}
          loading={bridging}
          onClick={handleBridge}
        >
          {errorMessage ?? 'Bridge'}
        </Button>
      </Stack>
    );
  })
);
