import { BigNumber as EthersBigNumber } from 'ethers';
import { Token } from '@uniswap/sdk-core';
import BigNumberJs from 'bignumber.js';
import { TickMath, tickToPrice } from '@uniswap/v3-sdk';
import { omitBy, zipObject } from 'lodash';
import { ReservesDataHumanized, UiPoolDataProvider } from 'paraspace-utilities-contract-helpers';

import {
  BasicNFTSpecificInfo,
  ERC20Symbol,
  ERC721Symbol,
  TokenInfo,
  UniswapSpecificInfo
} from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { shiftedLeftBy } from '@/apps/paraspace/utils/calculations';

const MAX_PRICE_LENGTH = 10;
// https://github.com/Uniswap/v3-core/blob/main/contracts/UniswapV3Factory.sol#L26
const FIXED_DIVISOR = 50;

const getUniSwapTokenInfo = ({
  info,
  chainId,
  tokensBasicInfo,
  getSymbolByContractAddress,
  reserveData
}: {
  info: {
    token0: string;
    token1: string;
    feeRate: number;
    positionTickLower: number;
    positionTickUpper: number;
    currentTick: number;
    liquidity: EthersBigNumber;
    liquidityToken0Amount: EthersBigNumber;
    liquidityToken1Amount: EthersBigNumber;
    lpFeeToken0Amount: EthersBigNumber;
    lpFeeToken1Amount: EthersBigNumber;
    tokenPrice: EthersBigNumber;
    baseLTVasCollateral: EthersBigNumber;
    reserveLiquidationThreshold: EthersBigNumber;
  };
  chainId: number;
  tokensBasicInfo: Record<string, TokenInfo>;
  getSymbolByContractAddress: (addr: string) => Maybe<ERC721Symbol | ERC20Symbol>;
  reserveData: ReservesDataHumanized;
}): Maybe<BasicNFTSpecificInfo & UniswapSpecificInfo> => {
  const {
    token0,
    token1,
    liquidity,
    liquidityToken0Amount,
    liquidityToken1Amount,
    lpFeeToken1Amount,
    lpFeeToken0Amount,
    currentTick,
    positionTickUpper,
    positionTickLower,
    feeRate
  } = info;
  const token0Symbol = getSymbolByContractAddress(token0) as ERC20Symbol;
  const token1Symbol = getSymbolByContractAddress(token1) as ERC20Symbol;
  if (
    !token0Symbol ||
    !token1Symbol ||
    !tokensBasicInfo[token0Symbol] ||
    !tokensBasicInfo[token1Symbol]
  ) {
    return null;
  }
  const token0Decimals = tokensBasicInfo[token0Symbol].decimals;
  const token1Decimals = tokensBasicInfo[token1Symbol].decimals;
  const transformToken0 = new Token(chainId, token0, token0Decimals);
  const transformToken1 = new Token(chainId, token1, token1Decimals);
  const token0ConvertToken1MinPrice = new BigNumberJs(
    tickToPrice(transformToken0, transformToken1, positionTickLower).toSignificant(MAX_PRICE_LENGTH)
  );
  const token0ConvertToken1MaxPrice = new BigNumberJs(
    tickToPrice(transformToken0, transformToken1, positionTickUpper).toSignificant(MAX_PRICE_LENGTH)
  );
  const token0ConvertToken1CurrentPrice = new BigNumberJs(
    tickToPrice(transformToken0, transformToken1, currentTick).toSignificant(MAX_PRICE_LENGTH)
  );

  const tickSpacing = feeRate / FIXED_DIVISOR;
  const isMinTick = positionTickLower === Math.ceil(TickMath.MIN_TICK / tickSpacing) * tickSpacing;
  const isMaxTick = positionTickUpper === Math.floor(TickMath.MAX_TICK / tickSpacing) * tickSpacing;

  const priceInETH = shiftedLeftBy(
    new BigNumberJs(info.tokenPrice.toString()),
    reserveData.baseCurrencyData.marketReferenceCurrencyDecimals
  );

  const priceInUsd = priceInETH
    .times(reserveData.baseCurrencyData.marketReferenceCurrencyPriceInUsd)
    .shiftedBy(-8);

  return {
    priceInETH,
    priceInUsd,
    baseLTVasCollateral: new BigNumberJs(info.baseLTVasCollateral.toString()).div(10000),
    reserveLiquidationThreshold: new BigNumberJs(info.reserveLiquidationThreshold.toString()).div(
      10000
    ),
    liquidity: new BigNumberJs(liquidity.toString()),
    liquidityToken0Amount: shiftedLeftBy(liquidityToken0Amount.toString(), token0Decimals),
    liquidityToken1Amount: shiftedLeftBy(liquidityToken1Amount.toString(), token1Decimals),
    lpFeeToken0Amount: shiftedLeftBy(lpFeeToken0Amount.toString(), token0Decimals),
    lpFeeToken1Amount: shiftedLeftBy(lpFeeToken1Amount.toString(), token1Decimals),

    token0ConvertToken1MinPrice: isMinTick ? new BigNumberJs(0) : token0ConvertToken1MinPrice,
    token0ConvertToken1MaxPrice: isMaxTick
      ? new BigNumberJs(Infinity)
      : token0ConvertToken1MaxPrice,
    token0ConvertToken1CurrentPrice,

    token1ConvertToken0MinPrice: isMinTick
      ? new BigNumberJs(0)
      : new BigNumberJs(
          tickToPrice(transformToken1, transformToken0, positionTickUpper).toSignificant(
            MAX_PRICE_LENGTH
          )
        ),
    token1ConvertToken0MaxPrice: isMaxTick
      ? new BigNumberJs(Infinity)
      : new BigNumberJs(
          tickToPrice(transformToken1, transformToken0, positionTickLower).toSignificant(
            MAX_PRICE_LENGTH
          )
        ),
    token1ConvertToken0CurrentPrice: new BigNumberJs(
      tickToPrice(transformToken1, transformToken0, currentTick).toSignificant(MAX_PRICE_LENGTH)
    ),

    token0,
    token1,
    token0Symbol,
    token1Symbol,

    feeRate: shiftedLeftBy(feeRate.toString(), 6),
    isInRange:
      token0ConvertToken1MinPrice.comparedTo(token0ConvertToken1CurrentPrice) < 0 &&
      token0ConvertToken1MaxPrice.comparedTo(token0ConvertToken1CurrentPrice) > 0,
    isClosed:
      new BigNumberJs(liquidityToken0Amount.toString()).isZero() &&
      new BigNumberJs(liquidityToken1Amount.toString()).isZero()
  };
};

export const getUniswapTokenInfoMap = async ({
  provider,
  tokenIds,
  chainId,
  poolAddressProvider,
  uniswapAddress,
  paraSpaceOracle,
  tokensBasicInfo,
  getSymbolByContractAddress,
  reserveData
}: {
  provider: UiPoolDataProvider;
  tokenIds: number[];
  poolAddressProvider: string;
  uniswapAddress: string;
  paraSpaceOracle: string;
  chainId: number;
  tokensBasicInfo: Maybe<Record<string, TokenInfo>>;
  getSymbolByContractAddress: (addr: string) => Maybe<ERC20Symbol>;
  reserveData: ReservesDataHumanized;
}) => {
  if (!tokensBasicInfo || tokenIds.length < 1) {
    return {};
  }
  const infos = await provider.batchGetUniswapV3LpTokenData({
    oracleAddress: paraSpaceOracle,
    poolAddressProvider,
    tokenIds,
    tokenAddress: uniswapAddress
  });

  const infoMap = zipObject(
    tokenIds,
    infos.map(info =>
      getUniSwapTokenInfo({
        info,
        chainId,
        tokensBasicInfo,
        getSymbolByContractAddress,
        reserveData
      })
    )
  );

  return omitBy(infoMap, v => v === null) as Record<
    string,
    BasicNFTSpecificInfo & UniswapSpecificInfo
  >;
};
