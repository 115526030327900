import { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { Spinner, Stack, StackProps } from '@parallel-mono/components';
import { BigNumber } from 'bignumber.js';

import { BorrowNativeTokenDerivativesFormData } from './types';

import {
  BorrowNativeTokenDerivativeSubmitter,
  FormSubmitter,
  Stepper
} from '@/apps/paraspace/components';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import useDebtTokenBase from '@/apps/paraspace/pages/hooks/useDebtTokenBase';
import { zero } from '@/apps/paraspace/consts/values';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

type BorrowSteppersProps = Omit<StackProps, 'children' | 'onError'> & {
  formData: BorrowNativeTokenDerivativesFormData;
  onFinish: (val: boolean) => void;
  onError: () => void;
};

export const BorrowNativeTokenDerivativesSteppers = memo(
  ({ formData, onFinish, onError, ...others }: BorrowSteppersProps) => {
    const { approveDelegation, getDelegatedAllowance } = useDebtTokenBase(
      formData.variableDebtTokenAddress
    );
    const [loading, setLoading] = useState(true);

    const [allowance, setAllowance] = useState(zero);

    const { erc20InfoMap } = useMMProvider();

    useEffect(() => {
      getDelegatedAllowance().then(value => {
        setAllowance(new BigNumber(value));
        setLoading(false);
      });
    }, [getDelegatedAllowance]);

    const [step, setStep] = useState(0);
    const handleNext = useCallback(() => {
      setStep(curr => curr + 1);
    }, []);

    const handleBorrowed = useCallback(async () => {
      onFinish(false);
    }, [onFinish]);

    const steps = useMemo(() => {
      return [
        {
          description: `Approve ${formData.derivative}`,
          content: (
            <FormSubmitter submit={approveDelegation} onError={onError} onFinish={handleNext} />
          ),
          skip: !(formData.derivative === ERC20Symbol.ETH && allowance.lt(formData.amount))
        },
        {
          description: `Borrow ${
            erc20InfoMap[formData.derivative].displayName
          } and add to Timelock Queue`,
          content: (
            <BorrowNativeTokenDerivativeSubmitter
              formData={formData}
              onFinish={handleBorrowed}
              onError={onError}
            />
          )
        }
      ];
    }, [formData, approveDelegation, onError, handleNext, allowance, erc20InfoMap, handleBorrowed]);

    return (
      <Stack width="100%" alignItems="center" {...others}>
        {loading ? <Spinner /> : <Stepper step={step} steps={steps} width="100%" />}
      </Stack>
    );
  }
);
