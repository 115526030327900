import {
  H2,
  Inline,
  Text,
  InlineProps,
  Responsive,
  Icon,
  useBreakpoints
} from '@parallel-mono/components';
import { ReactElement, ReactNode, memo, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { useScrollContainer } from '../../contexts';

import {
  ValueItem,
  BannerCard,
  BannerTitle,
  ExternalLink,
  GradientIconWrapper,
  ValueListWrapper,
  BannerContainer,
  Wrapper,
  BANNER_SCROLLING_THRESHOLD
} from './styledComponent';

type Props = Omit<InlineProps, 'children'> & {
  title: string;
  description: ReactNode;
  icon: ReactElement;
  valueList: {
    label: string;
    value: ReactNode;
    externalLink?: string;
  }[];
};

export const SummaryInfoBanner = memo(
  ({ title: pageTable, description, icon, valueList, ...props }: Props) => {
    const [bannerShrinking, setBannerShrinking] = useState(false);

    const scrollContainer = useScrollContainer();
    const { desktop } = useBreakpoints();

    useEffect(() => {
      const handlePageScroll = (e: Event) => {
        const scrolledHeight = (e!.target as HTMLDivElement).scrollTop;
        if (scrolledHeight >= BANNER_SCROLLING_THRESHOLD) {
          setBannerShrinking(true);
        } else {
          setBannerShrinking(false);
        }
      };

      if (!scrollContainer || !desktop) {
        return undefined;
      }
      scrollContainer.addEventListener('scroll', handlePageScroll);
      return () => scrollContainer.removeEventListener('scroll', handlePageScroll);
    }, [scrollContainer, desktop]);

    const animationClass = useMemo(() => (bannerShrinking ? 'shrinking' : ''), [bannerShrinking]);

    const theme = useTheme();

    return (
      <Wrapper shrinking={bannerShrinking} {...props}>
        <BannerContainer width="100%">
          <BannerCard className={animationClass} border>
            <Responsive
              justifyContent="space-between"
              alignItems={bannerShrinking && !desktop ? 'flex-start' : 'center'}
            >
              <Inline alignItems="center" gap={bannerShrinking ? '1.25rem' : '1.5rem'}>
                <GradientIconWrapper className={animationClass}>{icon}</GradientIconWrapper>
                <BannerTitle className={animationClass} gap="0.25rem">
                  <H2>{pageTable}</H2>
                  {!bannerShrinking && description}
                </BannerTitle>
              </Inline>
              <ValueListWrapper gap="2rem" className={animationClass}>
                {valueList.map(({ label, value, externalLink }, index) => {
                  return (
                    <ValueItem
                      className={animationClass}
                      key={`${label}-${index}`}
                      gap="0"
                      alignItems="center"
                    >
                      <Inline gap="0.5rem" alignItems="center">
                        <H2>{value}</H2>
                        {externalLink && !bannerShrinking && (
                          <ExternalLink href={externalLink} target="_blank">
                            <Icon
                              name="externalLink"
                              strokeWidth={2}
                              color={theme.skin.grey[500]}
                            />
                          </ExternalLink>
                        )}
                      </Inline>
                      <Text skin="secondary">{label}</Text>
                      {externalLink && bannerShrinking && (
                        <ExternalLink href={externalLink} target="_blank">
                          <Icon
                            name="externalLink"
                            strokeWidth={2}
                            size="1rem"
                            color={theme.skin.grey[500]}
                          />
                        </ExternalLink>
                      )}
                    </ValueItem>
                  );
                })}
              </ValueListWrapper>
            </Responsive>
          </BannerCard>
        </BannerContainer>
      </Wrapper>
    );
  }
);
