import {
  Card,
  Icon,
  Image,
  Inline,
  Popover,
  PopoverProps,
  Skeleton,
  SmallText,
  Stack,
  Text
} from '@parallel-mono/components';
import { FC, ReactElement, memo } from 'react';
import { HostedImage } from '@parallel-mono/business-components';
import styled from 'styled-components';

import { useScrollContainer, useWeb3Context } from '@/apps/paraspace/contexts';

type Props = {
  EOABalance: {
    loading: boolean;
    value: ReactElement;
  };
  paraXBalance: {
    loading: boolean;
    value: ReactElement;
  };
};

const StyledCard = styled(Card)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    min-width: 14rem;
  `}
`;

export const WalletsBalanceDropdown: FC<Props & Omit<PopoverProps, 'popup' | 'children'>> = memo(
  ({ EOABalance, paraXBalance, ...others }) => {
    const scrollContainer = useScrollContainer();
    const {
      authentication: {
        meta: { walletType, walletIcon }
      }
    } = useWeb3Context();
    return (
      <Popover
        zIndex={9999}
        gap="0.5rem"
        placement="bottom"
        {...others}
        popup={
          <StyledCard inset="1rem 1.5rem" shadow="secondary">
            <Stack>
              <Inline justifyContent="space-between" alignItems="center">
                <Inline gap="1rem">
                  <HostedImage
                    name="design/PDS_V3/logo/parallel-v2-logo"
                    height="1.25rem"
                    width="1.25rem"
                  />
                  <Text>AA Wallet</Text>
                </Inline>
                {paraXBalance.loading ? <Skeleton.Title width="2.5rem" /> : paraXBalance.value}
              </Inline>
              <Inline alignItems="center" justifyContent="space-between">
                <Inline gap="1rem">
                  <Image src={walletIcon!} width="1.25rem" height="1.25rem" />
                  <Text>{walletType}</Text>
                </Inline>
                {EOABalance.loading ? <Skeleton.Title width="2.5rem" /> : EOABalance.value}
              </Inline>
            </Stack>
          </StyledCard>
        }
        getPopupContainer={() => scrollContainer!}
      >
        <Inline gap="0.5rem" alignItems="center">
          <SmallText skin="secondary">2 Wallets</SmallText>
          <Icon size="small" name="chevronDown" />
        </Inline>
      </Popover>
    );
  }
);
