import styled from 'styled-components';
import { Stack, H6, Inline, SmallText, Icon } from '@parallel-mono/components';

import { useBuyCartProvider } from '../../../context';
import { BuyCartAsset } from '../../../context/types';

import { PriceContainer } from '@/apps/paraspace/pages/Shop/components';
import { NFTThumbnail } from '@/apps/paraspace/components';

const ListLine = styled.div`
  height: 0;
  border-bottom: 0.125rem solid ${({ theme }) => theme.skin.grey[200]};
`;

const AssetIconContainer = styled(Inline)`
  position: relative;
  width: 2rem;
  height: 2rem;
`;

const CloseIconContainer = styled(Inline)`
  position: absolute;
  right: 0;
  top: 0;
  width: 0.875rem;
  height: 0.875rem;
  background: ${({ theme }) => theme.skin.background.sub1};
  color: ${({ theme }) => theme.skin.text.sub1};
  border: 1px solid ${({ theme }) => theme.skin.text.sub1};
  box-shadow: 0 0 1rem rgba(58, 78, 88, 0.05);
  border-radius: 50%;
  cursor: pointer;
`;

const NftItemLabel = styled(H6)`
  white-space: nowrap;
`;

const NftListContainer = styled(Stack)`
  max-height: 13rem;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const NftItem = (buyCartAsset: BuyCartAsset) => {
  const { tokenId, price, symbol } = buyCartAsset;
  const { removeBuyCartList } = useBuyCartProvider();

  return (
    <Inline justifyContent="space-between" alignItems="center">
      <Inline gap="0.75rem" alignItems="center">
        <AssetIconContainer gap="0" justifyContent="center" alignItems="center">
          <NFTThumbnail size="small" tokenId={tokenId} symbol={symbol} />
          <CloseIconContainer
            justifyContent="center"
            alignItems="center"
            onClick={() => removeBuyCartList([buyCartAsset])}
          >
            <Icon strokeWidth={4} name="close" />
          </CloseIconContainer>
        </AssetIconContainer>
        <NftItemLabel>
          {symbol} #{tokenId}
        </NftItemLabel>
      </Inline>
      <PriceContainer price={price} currency="ETH" />
    </Inline>
  );
};

export const BuyCartNftList = () => {
  const { buyCartList, totalPrice } = useBuyCartProvider();

  return (
    <Stack gap="1rem">
      <NftListContainer gap="1rem">
        {buyCartList.map(token => (
          <NftItem key={token.contractAddress + token.tokenId} {...token} />
        ))}
      </NftListContainer>
      <ListLine />
      <Inline justifyContent="space-between" alignItems="center">
        <SmallText skin="secondary">Total</SmallText>
        <PriceContainer price={totalPrice} currency="ETH" />
      </Inline>
    </Stack>
  );
};
