import { memo, useCallback } from 'react';
import BigNumber from 'bignumber.js';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useERC721, useTransfer } from '@/apps/paraspace/hooks';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { withoutGasEstimation } from '@/apps/paraspace/contexts/Web3Context/utils';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { FormSubmitter } from '@/apps/paraspace/components';

export const SupplyMoonbirdsSubmitter = memo(
  ({
    formData: { tokenIds, symbol, address },
    isDeposited = false,
    onError,
    onFinish
  }: {
    formData: { tokenIds: number[]; symbol: ERC721Symbol; address: string };
    isDeposited?: boolean;
    onError: () => void;
    onFinish: () => void;
  }) => {
    const { provider, eoaAccount, account } = useWeb3Context();

    const { genApprovalForAll, genSupplyERC721Txs } = useERC721(symbol);

    const { genTxDataTransferERC721 } = useTransfer();

    const submit = useCallback(async () => {
      const depositTxs = isDeposited
        ? []
        : tokenIds.map(tokenId => {
            return genTxDataTransferERC721(eoaAccount, account, address, tokenId);
          });
      const approvalTxs = (await genApprovalForAll({ ids: tokenIds })) ?? [];
      const supplyTxs = (await genSupplyERC721Txs({ tokenIds })) ?? [];

      const extendedTxDataArr = await withoutGasEstimation(async () =>
        Promise.all(
          approvalTxs
            ?.concat(supplyTxs)
            .filter(notEmpty)
            .map(tx => tx.tx())
        )
      );
      const approveAndSupplyTxsParams = extendedTxDataArr.map(extendedTxData => {
        const { to, value, data } = extendedTxData;
        return { to: to!, value: new BigNumber(value ?? 0).toString(), data: data! };
      });

      return provider.submitTransactionsWithParaAccount([
        ...depositTxs,
        ...approveAndSupplyTxsParams
      ]);
    }, [
      isDeposited,
      tokenIds,
      genApprovalForAll,
      genSupplyERC721Txs,
      provider,
      genTxDataTransferERC721,
      eoaAccount,
      account,
      address
    ]);

    return (
      <FormSubmitter
        submit={submit}
        onError={onError}
        onFinish={onFinish}
        inProgressMessage="Deposit ERC721"
      />
    );
  }
);
