import { memo, useCallback, useMemo, useState } from 'react';

import ApproveWithdraw, { ApproveWithdrawProps } from './ApproveWithdraw';

import { useContractsMap } from '@/apps/paraspace/hooks';
import { ApproveERC721Submitter, StepperProps, Stepper } from '@/apps/paraspace/components';

type BakcStakeFormSubmitterProps = Omit<StepperProps, 'step' | 'steps' | 'onError'> & {
  formData: Omit<ApproveWithdrawProps, 'onFinish' | 'onError'>;
  onError: () => void;
  onFinish: (amount: number) => void;
};

const BakcStakeFormSubmitter = memo(
  ({ formData, onError, onFinish, ...others }: BakcStakeFormSubmitterProps) => {
    const [step, setStep] = useState<number>(0);

    const handleNextStep = useCallback(() => {
      setStep(curr => curr + 1);
    }, [setStep]);

    const contracts = useContractsMap();

    const steps = useMemo(
      () => [
        {
          description: `Approve ${formData.symbol}`,
          content: (
            <ApproveERC721Submitter
              formData={{
                name: formData.symbol,
                assetAddress: contracts[formData.symbol]
              }}
              onFinish={handleNextStep}
              onError={onError}
            />
          )
        },
        {
          description: '',
          content: <ApproveWithdraw {...formData!} onFinish={onFinish} onError={onError} />
        }
      ],
      [onFinish, onError, handleNextStep, formData, contracts]
    );

    return <Stepper step={step} steps={steps} {...others} />;
  }
);

export default BakcStakeFormSubmitter;
