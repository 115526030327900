import {
  CardProps,
  Inline,
  Stack,
  Card,
  H6,
  H5,
  Button,
  Text,
  H3,
  Alert,
  useBreakpoints,
  Responsive
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { FC, memo, useCallback, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import BigNumber from 'bignumber.js';
import { formatNumber, sumBy } from '@parallel-mono/utils';
import { isEmpty, isNil } from 'lodash';

import { Listing, ListingType } from '../../../types';

import { SelectedListingPill } from './ListingPill';
import { JoinP2PListingDropdownMenu } from './JoinP2PListingDropdownMenu';

import {
  ApeStakingTokenSymbol,
  ERC20Symbol,
  ERC721Symbol,
  WalletType
} from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { one, zero } from '@/apps/paraspace/consts/values';
import { ValuePill } from '@/apps/paraspace/components';

const LISTING_TYPE_TO_SYMBOL: Record<
  ListingType,
  { symbol: ERC20Symbol.APE | ApeStakingTokenSymbol; label?: string }
> = {
  [ListingType.ApeCoinListing]: { symbol: ERC20Symbol.APE, label: 'ApeCoin' },
  [ListingType.ApeListing]: { symbol: ERC721Symbol.BAYC, label: 'BAYC/MAYC' },
  [ListingType.BakcListing]: { symbol: ERC721Symbol.BAKC }
};

type ListingSelectProps = {
  compoundApy: BigNumber;
  onJoinListing: (listing: Listing[], walletType: WalletType) => Promise<void>;
  selectedListings: Listing[];
} & Omit<CardProps, 'children'>;

const StyledCard = styled(Card)`
  padding: 0.75rem 1rem;
`;
const StyledListingField = styled(Stack)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    > * + * {
      margin-top: 0rem;
    }
  `};
`;

const EmptySelectPillText = styled(Text)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    font-size: 0.75rem;
  `};
`;

export const BakcListingSelector: FC<ListingSelectProps> = memo(
  ({ selectedListings, onJoinListing, compoundApy, ...props }) => {
    const { account } = useWeb3Context();
    const { mobile } = useBreakpoints();
    const isListingSelected = useMemo(() => selectedListings.length > 0, [selectedListings.length]);

    const handleJoinStaking = useCallback(
      (source: WalletType) => {
        onJoinListing(selectedListings, source);
      },
      [onJoinListing, selectedListings]
    );

    const yourShare = useMemo(() => {
      const joinListingsShare =
        sumBy(
          selectedListings.filter(listing => listing.offerer !== account),
          listing => listing.share
        ) ?? zero;
      return isListingSelected ? one.minus(joinListingsShare) : null;
    }, [account, isListingSelected, selectedListings]);

    const yourApy = useMemo(
      () => yourShare && compoundApy.times(yourShare),
      [compoundApy, yourShare]
    );

    const provideListingSymbols = useMemo(() => {
      if (isListingSelected) {
        return [ListingType.ApeCoinListing, ListingType.ApeListing, ListingType.BakcListing]
          .filter(listingType => !selectedListings.some(v => v.listingType === listingType))
          .map(listingType => LISTING_TYPE_TO_SYMBOL[listingType]);
      }
      return null;
    }, [selectedListings, isListingSelected]);

    const shareLimitExceeded = useMemo(
      () => sumBy(selectedListings, listing => listing.share).gte(one),
      [selectedListings]
    );

    const disableJoinButton = useMemo(
      () => selectedListings.length < 1 || yourShare?.lte(0) || shareLimitExceeded,
      [selectedListings.length, shareLimitExceeded, yourShare]
    );

    const showErrorTips = useMemo(
      () => (!isNil(yourShare) && yourShare.lte(zero)) || shareLimitExceeded,
      [shareLimitExceeded, yourShare]
    );

    const theme = useTheme();

    return (
      <Stack gap="1.5rem">
        <StyledCard border {...props}>
          <Responsive justifyContent="space-between">
            <StyledListingField gap="0.5rem">
              <H6 fontWeight="medium" skin="secondary">
                Join
              </H6>
              <Inline gap=".5rem">
                {isEmpty(selectedListings) ? (
                  <ValuePill>
                    <EmptySelectPillText skin="secondary">Select an offer</EmptySelectPillText>
                  </ValuePill>
                ) : (
                  <ValuePill borderColor={theme.skin.text.main}>
                    <SelectedListingPill
                      tokenId={
                        selectedListings[0].listingType !== ListingType.ApeCoinListing
                          ? selectedListings[0].tokenId
                          : null
                      }
                      symbol={selectedListings[0].symbol}
                    />
                  </ValuePill>
                )}
                {selectedListings.length > 1 && (
                  <ValuePill borderColor={theme.skin.text.main}>
                    <SelectedListingPill
                      tokenId={
                        selectedListings[1].listingType !== ListingType.ApeCoinListing
                          ? selectedListings[1].tokenId
                          : null
                      }
                      symbol={selectedListings[1].symbol}
                    />
                  </ValuePill>
                )}
              </Inline>
            </StyledListingField>
            <StyledListingField gap={provideListingSymbols ? '0.5rem' : '1rem'}>
              <H6 fontWeight="medium" skin="secondary">
                You Provide
              </H6>
              {provideListingSymbols ? (
                <Inline gap=".5rem">
                  {provideListingSymbols.map(({ symbol, label }) => (
                    <ValuePill key={symbol}>
                      <CryptoIcon symbol={symbol} size="1.5rem" />
                      <H5 fontWeight="medium">{label ?? symbol}</H5>
                    </ValuePill>
                  ))}
                </Inline>
              ) : (
                <H3>-</H3>
              )}
            </StyledListingField>
            <StyledListingField gap="1rem">
              <H6 fontWeight="medium" skin="secondary">
                Your % Share
              </H6>
              <H3>
                {yourShare && yourShare.gt(0)
                  ? formatNumber(yourShare, { output: 'percent' })
                  : '-%'}
              </H3>
            </StyledListingField>
            <StyledListingField gap="1rem">
              <H6 fontWeight="medium" skin="secondary">
                Your APY
              </H6>
              <H3>
                {yourApy && yourApy.gt(0)
                  ? formatNumber(yourApy, {
                      output: 'percent'
                    })
                  : '-%'}
              </H3>
            </StyledListingField>
            <StyledListingField justifyContent="center">
              <JoinP2PListingDropdownMenu
                menuTrigger={
                  <Button block={mobile} disabled={disableJoinButton}>
                    Next
                  </Button>
                }
                clickHandler={source => {
                  handleJoinStaking(source);
                }}
              />
            </StyledListingField>
          </Responsive>
        </StyledCard>
        {showErrorTips && (
          <Alert type="error">The offers you selected add up to more than 100% share of APY.</Alert>
        )}
      </Stack>
    );
  }
);
