import { useMemo } from 'react';
import BigNumberJs from 'bignumber.js';
import { mapValues } from 'lodash';

import { useEOABalances } from '../../../..';
import { useTokensInfo } from '../../../../TokensInfoProvider';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  ERC721Symbol,
  NFTSpecificInfo,
  StakeFishState,
  WalletType
} from '@/apps/paraspace/typings';
import { emptyArray, zero } from '@/apps/paraspace/consts/values';

export type StakeFishNFT = {
  tokenId: number;
  priceInUsd: BigNumberJs;
  ltv: BigNumberJs;
  reserveLiquidationThreshold: BigNumberJs;
  unclaimedRewards: BigNumberJs;
};

export const useStakeFishNfts = (walletType: WalletType) => {
  const { nftInfoMap } = useMMProvider();
  const { erc721BalanceMap: EOABalances } = useEOABalances();
  const { tokensInfo } = useTokensInfo();
  const {
    priceInUsd = zero,
    baseLTVasCollateral = zero,
    reserveLiquidationThreshold = zero
  } = tokensInfo?.[ERC721Symbol.SF_VLDR] ?? {};

  const balance =
    (walletType === 'AA'
      ? nftInfoMap[ERC721Symbol.SF_VLDR].balance
      : EOABalances?.[ERC721Symbol.SF_VLDR].balance) ?? emptyArray;

  const tokenSpecificInfos = useMemo(() => {
    if (walletType === 'AA') {
      return nftInfoMap[ERC721Symbol.SF_VLDR].tokenSpecificInfos;
    }
    const stakeFishInfo = tokensInfo?.[ERC721Symbol.SF_VLDR];
    return mapValues(EOABalances?.[ERC721Symbol.SF_VLDR]?.stakeFishInfoMap, v => ({
      ...v,
      priceInUsd: stakeFishInfo?.priceInUsd,
      priceInETH: stakeFishInfo?.priceInETH,
      baseLTVasCollateral: stakeFishInfo?.baseLTVasCollateral,
      reserveLiquidationThreshold: stakeFishInfo?.reserveLiquidationThreshold
    })) as Record<string, NFTSpecificInfo>;
  }, [tokensInfo, EOABalances, walletType, nftInfoMap]);

  const enrichStakeFishNfts = useMemo(
    () =>
      balance
        .filter(tokenId =>
          [StakeFishState.PostDeposit, StakeFishState.Active].includes(
            tokenSpecificInfos?.[tokenId]?.state
          )
        )
        .map(tokenId => ({
          tokenId,
          priceInUsd,
          ltv: baseLTVasCollateral,
          reserveLiquidationThreshold,
          unclaimedRewards: tokenSpecificInfos[tokenId]?.unclaimedPendingReward || zero
        })),
    [balance, baseLTVasCollateral, priceInUsd, reserveLiquidationThreshold, tokenSpecificInfos]
  );

  return {
    stakeFishNfts: enrichStakeFishNfts
  };
};
