import { H6, Icon, Inline, Button } from '@parallel-mono/components';
import { memo, useMemo, MouseEvent } from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { isEmpty } from 'lodash';
import { WalletTypeEnum } from 'parax-sdk';

import { ValuePill } from '../ValuePill';
import { ColorModeSwitch } from '../ColorModeSwitch';
import { WalletIcon, useWalletModalContext } from '../Wallet';
import { LinkButton } from '../LinkButton';

import { AppSelector, CreateParaAccount } from './components';
import { DesktopAccountSelector } from './components/AccoountSelector';

import config, { Network } from '@/apps/parax/config';
import { useEOAProvider, useAAProvider, useV1UserProvider } from '@/apps/parax/contexts';
import { truncateTextMid } from '@/apps/parax/utils';

const ClickablePill = styled(ValuePill)<{ disabled?: boolean }>`
  padding: 0;
  background: ${({ theme }) => tinycolor(theme.skin.background.main).setAlpha(0.5).toString()};
  ${({ disabled, theme }) =>
    disabled
      ? 'cursor: default;'
      : `
    &:hover {
      background: ${tinycolor(theme.skin.background.main).setAlpha(0.75).toString()};
    }`}
`;
const BorderedPill = styled(ClickablePill)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const NoBorderedPill = styled(ClickablePill)`
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const CursorInline = styled(Inline)`
  cursor: pointer;
`;

const stopPropagation = (e: MouseEvent) => e.stopPropagation();

export const RightHeader = memo(() => {
  const { isUsingUserWallet, account: eoaAccount, walletType, chainId } = useEOAProvider();
  const { account: paraAccount, accounts: paraAccounts } = useAAProvider();
  const { isWalletModalOpen, setWalletModalOpen } = useWalletModalContext();
  const { isV1User } = useV1UserProvider();

  const showMigrateButton = useMemo(() => {
    return false;
    // Hide the migration button Temporarily
    // return isV1User || hasV1TimeLock;
  }, []);

  const handleWalletConnectClick = () => {
    setWalletModalOpen(!isWalletModalOpen);
    ReactGA.event({
      category: 'Paraspace',
      action: 'Connect Wallet'
    });
  };

  const migrationLink = useMemo(() => {
    if ([Network.SEPOLIA, Network.MAINNET].includes(chainId)) {
      return `${config.siteUrlV1}/migration`;
    }
    return config.siteUrlV1;
  }, [chainId]);

  return (
    <Inline gap="1rem" alignItems="center">
      {isUsingUserWallet ? (
        <>
          {showMigrateButton && (
            <LinkButton
              as="a"
              to=""
              target="_blank"
              href={isV1User ? migrationLink : config.siteUrlV1}
              size="medium"
            >
              <H6>{isV1User ? 'Migrate from V1' : 'Claim V1 Timelock'}</H6>
            </LinkButton>
          )}
          <ColorModeSwitch />
          <AppSelector />
          <CursorInline gap="0">
            <BorderedPill gap="0.25rem">
              {isEmpty(paraAccounts) && eoaAccount && (
                <CreateParaAccount onMouseDown={stopPropagation} />
              )}
              {!isEmpty(paraAccounts) && (
                <DesktopAccountSelector
                  gap="0.5rem"
                  placement="bottom"
                  zIndex={10000}
                  trigger="click"
                >
                  <Inline alignItems="center" gap="0.25rem">
                    <Icon name="purse" strokeWidth={2} size="1.25rem" />
                    <H6>{truncateTextMid(paraAccount ?? '')}</H6>
                    <Icon name="chevronDown" strokeWidth="2" />
                  </Inline>
                </DesktopAccountSelector>
              )}
            </BorderedPill>
            <NoBorderedPill
              disabled={walletType === WalletTypeEnum.GNOSIS_SAFE}
              onClick={
                walletType === WalletTypeEnum.GNOSIS_SAFE
                  ? undefined
                  : () => setWalletModalOpen(!isWalletModalOpen)
              }
            >
              <Inline gap="0.25rem" inset="6px 1rem">
                <WalletIcon wallet={walletType} width="1.25rem" height="1.25rem" />
                <H6>{truncateTextMid(eoaAccount)}</H6>
              </Inline>
            </NoBorderedPill>
          </CursorInline>
        </>
      ) : (
        <Inline gap="1rem" alignItems="center">
          <ColorModeSwitch />
          <Button skin="primary" onClick={handleWalletConnectClick}>
            Connect Wallet
          </Button>
        </Inline>
      )}
    </Inline>
  );
});
