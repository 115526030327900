import { BigNumber } from 'bignumber.js';
import { PlacementType } from '@parallel-mono/components';

import { formatToPercentage } from '@/apps/paraspace/utils/format';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { Tooltip } from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { zero } from '@/apps/paraspace/consts/values';

const shouldShowToolTip = (
  symbol: ERC20Symbol,
  apy: Maybe<number>,
  isNativeTokenOrDerivatives: boolean
) => {
  const isCAPE = symbol === ERC20Symbol.CAPE;
  return isCAPE || (isNativeTokenOrDerivatives && apy);
};

export const getEffectiveSupplyApyTip = ({
  symbol,
  apy,
  baseApy,
  tooltipPlacement,
  isNativeTokenOrDerivatives
}: {
  symbol: ERC20Symbol;
  apy: Maybe<number>;
  baseApy: Maybe<BigNumber>;
  tooltipPlacement?: PlacementType;
  isNativeTokenOrDerivatives: boolean;
}) => {
  return shouldShowToolTip(symbol, apy, isNativeTokenOrDerivatives) ? (
    <Tooltip
      placement={tooltipPlacement}
      content={`This is the effective APY including compound interests and staking interests. The base APY is ${formatToPercentage(
        baseApy ?? zero
      )}.`}
    />
  ) : null;
};
export const getEffectiveBorrowApyTip = ({
  symbol,
  apy,
  baseApy,
  tooltipPlacement,
  isNativeTokenOrDerivatives
}: {
  symbol: ERC20Symbol;
  apy: Maybe<number>;
  baseApy: Maybe<BigNumber>;
  tooltipPlacement?: PlacementType;
  isNativeTokenOrDerivatives: boolean;
}) => {
  return shouldShowToolTip(symbol, apy, isNativeTokenOrDerivatives) ? (
    <Tooltip
      placement={tooltipPlacement}
      content={`This is the effective APY including compound interests and staking interests. The base APY is ${formatToPercentage(
        baseApy ?? zero
      )}. You can reduce your borrow APY by repaying regularly to prevent interests compounding.`}
    />
  ) : null;
};
