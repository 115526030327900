import { DecoratedInput, DecoratedInputProps, Icon } from '@parallel-mono/components';
import { memo } from 'react';
import styled from 'styled-components';

export type SearchInputProps = Omit<DecoratedInputProps<'input'>, 'startAdornment' | 'Component'>;

const startAdornment = <Icon name="search" size="1.25rem" strokeWidth={2} />;

const StyledDecoratedInput = styled(DecoratedInput)`
  width: auto;
  border-radius: 10rem;
  font-size: 1rem;
`;

export const SearchInput = memo((props: SearchInputProps) => {
  return <StyledDecoratedInput Component="input" startAdornment={startAdornment} {...props} />;
});
