import { useEffect, useRef, useState } from 'react';
import { AppPackage } from 'parax-sdk';
import { uniqueId } from 'lodash';

import { FetchingStatus } from '../../typings';
import { Maybe } from '../../typings/basic';

import { InternalAppConfig } from '@/apps/consts';

export const useLazyApp = (
  appConfig: Maybe<InternalAppConfig>
): [Maybe<{ appPackage: AppPackage; config: InternalAppConfig }>, FetchingStatus] => {
  const [appFetchingStatus, setAppFetchingStatus] = useState<FetchingStatus>(FetchingStatus.INIT);
  const [app, setApp] =
    useState<Maybe<{ appPackage: AppPackage; config: InternalAppConfig }>>(null);

  const taskIdRef = useRef(uniqueId());
  useEffect(() => {
    const taskId = uniqueId();
    taskIdRef.current = taskId;
    if (appConfig === null) {
      return;
    }
    setAppFetchingStatus(FetchingStatus.FETCHING);
    setApp(null);
    appConfig
      .getAppPackage()
      .then(result => {
        if (taskId === taskIdRef.current) {
          setApp({ appPackage: result, config: appConfig });
          setAppFetchingStatus(FetchingStatus.SUCCESS);
        }
      })
      .catch(() => {
        if (taskId === taskIdRef.current) {
          setAppFetchingStatus(FetchingStatus.FAIL);
        }
      });
  }, [appConfig]);

  return [app, appFetchingStatus];
};
