import { get, isEqual, keyBy, keys, map, xor } from 'lodash';
import { useEffect, useState } from 'react';

import { combineValidatorTokenInfo } from '../utils';
import { StakefishAssetValidatorInfo } from '../types';

import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { useNtokenData } from '@/apps/paraspace/pages/hooks/useNtokenData';
import { useContractsMap, useStakeFishData } from '@/apps/paraspace/hooks';
import { StakeFishNTokenData } from '@/apps/paraspace/typings';

export type StakefishAssetValidatorInfoMap = Record<string, StakefishAssetValidatorInfo>;

export const useAssetsStakefishInfo = (assetIds: number[]) => {
  const [cachedAssetIds, setCachedAssetIds] = useState(assetIds);
  const [stakefishInfoMap, setStakeFishInfoMap] = useState<StakefishAssetValidatorInfoMap>(
    {} as StakefishAssetValidatorInfoMap
  );

  const contracts = useContractsMap();

  useEffect(() => {
    setCachedAssetIds(prev => (isEqual(prev, assetIds) ? prev : assetIds));
  }, [assetIds]);

  const { getNTokens } = useNtokenData<{ stakefishNTokenData: StakeFishNTokenData }>(
    contracts.nSFVLDR
  );

  const { fetchValidatorList } = useStakeFishData();

  useAsyncEffect(async () => {
    const exitedTokenIds = keys(stakefishInfoMap).map(v => Number(v));
    const tokenIds = xor(exitedTokenIds, cachedAssetIds);
    const nTokens = await getNTokens(tokenIds);
    const validatorsIndex = nTokens
      .map(detail => get(detail, 'stakefishNTokenData.validatorIndex')?.toNumber())
      .filter(v => v);

    const validatorList = await fetchValidatorList(validatorsIndex);

    const validatorAssets = map(nTokens, token => {
      const targetValidator = validatorList.find(
        validator =>
          validator.validatorIndex === token.stakefishNTokenData.validatorIndex.toNumber()
      );
      return combineValidatorTokenInfo(token, targetValidator);
    });
    setStakeFishInfoMap(
      prev => ({ ...prev, ...keyBy(validatorAssets, 'tokenId') } as StakefishAssetValidatorInfoMap)
    );
  }, [cachedAssetIds]);

  return { stakefishInfoMap };
};
