import { useMemo } from 'react';
import { sumBy } from '@parallel-mono/utils';

import { UniswapItem } from '../types';

import { useBorrowLimitPrediction } from './useBorrowLimitPrediction';

import { NumberRange } from '@/apps/paraspace/components';
import { formatToCurrency } from '@/apps/paraspace/utils/format';

export const useInfos = (tokensToSupply: UniswapItem[]) => {
  const { currentBorrowLimit, predictedBorrowLimit } = useBorrowLimitPrediction(tokensToSupply);

  const totalLiquidity = useMemo(() => sumBy(tokensToSupply, 'priceInUsd'), [tokensToSupply]);

  const totalUnclaimedFee = useMemo(() => sumBy(tokensToSupply, 'unclaimedFee'), [tokensToSupply]);

  return useMemo(
    () => [
      {
        title: 'Liquidity',
        value: formatToCurrency(totalLiquidity),
        tip: 'Total value represented by the LPs'
      },
      {
        title: 'Unclaimed Fee',
        value: formatToCurrency(totalUnclaimedFee),
        tip: 'Trading fees generated by the LPs'
      },
      {
        title: 'New Borrow Limit',
        value: (
          <NumberRange
            start={currentBorrowLimit.toNumber()}
            end={predictedBorrowLimit.toNumber()}
            formatter={num => formatToCurrency(num)}
          />
        )
      }
    ],
    [totalLiquidity, totalUnclaimedFee, currentBorrowLimit, predictedBorrowLimit]
  );
};
