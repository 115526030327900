import { memo, useCallback, useMemo } from 'react';
import {
  Button,
  DataGrid,
  DataGridColumn,
  H5,
  Inline,
  Stack,
  Toggle,
  Tag
} from '@parallel-mono/components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { UniSwapV3Item } from '../types';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import useDevice from '@/apps/paraspace/hooks/useDevice';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { emptyArray } from '@/apps/paraspace/consts/values';
import { useUniSwapV3Manager } from '@/apps/paraspace/pages/contexts/UniSwapV3ManagerProvider/useUniSwapV3Manager';
import { NFTThumbnail } from '@/apps/paraspace/components';
import { useContractAddressBySymbol } from '@/apps/paraspace/hooks';

const InlineWithCursor = styled(Inline)`
  cursor: pointer;
`;

const H5WithWidth = styled(H5)`
  max-width: 20rem;
`;

type UniSwapV3ItemsGridProps = {
  symbol: ERC721Symbol;
  onToggleItemCollateral?: (id: number) => void;
  inLiquidation: boolean;
};

export const UniSwapV3ItemsGrid = memo((props: UniSwapV3ItemsGridProps) => {
  const { symbol, onToggleItemCollateral, inLiquidation } = props;
  const { isMobile } = useDevice();

  const { nftInfoMap, load } = useMMProvider();
  const {
    nftSuppliedList = emptyArray,
    nftCollateralList = emptyArray,
    tokenSpecificInfos = {}
  } = nftInfoMap[symbol];

  const { imageMap, addLiquidity, removeLiquidity } = useUniSwapV3Manager();

  const items: UniSwapV3Item[] = useMemo(() => {
    return nftSuppliedList.map(id => ({
      id,
      symbol,
      asCollateral: nftCollateralList.includes(id),
      name: imageMap?.[id]?.name ?? '',
      isInRange: tokenSpecificInfos[id].isInRange,
      isClosed: tokenSpecificInfos[id].isClosed
    }));
  }, [nftSuppliedList, symbol, nftCollateralList, imageMap, tokenSpecificInfos]);

  const handleRemoveLiquidity = useCallback(
    async (collectionSymbol: string, id: number) => {
      await removeLiquidity(collectionSymbol, id);
      load();
    },
    [removeLiquidity, load]
  );

  const navigate = useNavigate();
  const uniswapContractAddress = useContractAddressBySymbol(ERC721Symbol.UNISWAP_LP);
  const handleDetailClick = useCallback(
    id => {
      navigate(`/details/${uniswapContractAddress}/${id}`);
    },
    [navigate, uniswapContractAddress]
  );

  const columns: DataGridColumn<UniSwapV3Item>[] = useMemo(
    () => [
      {
        title: '',
        name: 'imageThumbnail',
        render: ({ data: { name, id, isInRange, isClosed } }) => (
          <Stack gap="0.5rem" alignItems="baseline">
            <InlineWithCursor
              gap=".75rem"
              alignItems="center"
              onClick={() => {
                handleDetailClick(id);
              }}
            >
              <NFTThumbnail symbol={ERC721Symbol.UNISWAP_LP} tokenId={id} size="small" />
              <H5WithWidth fontWeight="bold">{name.replace(/^Uniswap.*?-\s?/, '')}</H5WithWidth>
            </InlineWithCursor>
            <Inline gap="0.5rem">
              {!isInRange && !isClosed && (
                <Tag skin="warning" size="small">
                  Out of Range
                </Tag>
              )}
              {isClosed && (
                <Tag skin="primary" size="small">
                  Closed
                </Tag>
              )}
            </Inline>
          </Stack>
        )
      },
      {
        title: '',
        name: '',
        render: ({ data: { asCollateral, id } }) => (
          <Toggle
            disabled={asCollateral && inLiquidation}
            checked={asCollateral}
            onChange={() => onToggleItemCollateral && onToggleItemCollateral(id)}
          />
        ),
        width: '7rem',
        hidden: isMobile
      },
      {
        title: '',
        name: '',
        width: '26rem',
        render: ({ data: { id, symbol: collectionSymbol, isClosed } }) => (
          <Inline justifyContent="flex-end" gap="1rem" width="100%">
            <Button skin="secondary" onClick={() => addLiquidity(collectionSymbol, id)}>
              Add Liquidity
            </Button>
            <Button
              skin="secondary"
              disabled={isClosed || inLiquidation}
              onClick={() => handleRemoveLiquidity(collectionSymbol, id)}
            >
              Remove Liquidity
            </Button>
          </Inline>
        )
      }
    ],
    [
      isMobile,
      onToggleItemCollateral,
      handleRemoveLiquidity,
      addLiquidity,
      handleDetailClick,
      inLiquidation
    ]
  );

  return <DataGrid columns={columns} data={items} hideColumnsTitle />;
});
