import { JsonRpcProvider } from '@ethersproject/providers';
import { Contract, BigNumber as EthersBigNumber } from 'ethers';
import { useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import {
  parallelTestnetFuncContractAddresses,
  parallelFuncContractAddresses
} from '@parallel-utils/contracts-registry';
import { Environment } from 'parax-sdk';

import { Maybe } from '../../typings/basic';

import abi from './priceOracleABI.json';

import { env } from '@/env';

export const priceOracleContract = {
  [Environment.DEVELOPMENT]: parallelTestnetFuncContractAddresses.Oracle,
  [Environment.STAGING]: parallelTestnetFuncContractAddresses.Oracle,
  [Environment.PRODUCTION]: parallelFuncContractAddresses.Oracle
}[env];

export const useOracleClient = (provider: Maybe<JsonRpcProvider>) => {
  const oracleClient = useMemo(
    () => (provider ? new Contract(priceOracleContract, abi, provider) : null),
    [provider]
  );
  const getTokenPrices = useCallback(
    (addresses: string[]): Promise<BigNumber[]> => {
      if (oracleClient === null) {
        throw new Error('oracle not ready');
      }
      return oracleClient
        .unsafeGetAssetsPrices(addresses)
        .then((res: EthersBigNumber[]) =>
          res.map(price => new BigNumber(price.toString()).shiftedBy(-8))
        );
    },
    [oracleClient]
  );

  return { getTokenPrices };
};
