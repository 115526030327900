import { Stack, StackProps, Spinner, Card, Image, H5, Responsive } from '@parallel-mono/components';
import { FC, memo, useCallback, useState } from 'react';
import styled from 'styled-components';

import { ApeListItem } from '../../contexts';

import { MintSewerPass } from './components/MintSewerPass';
import tierRules from './images/tierRules.svg';
import { useMintSewerPass } from './hooks/useMintSewerPass';
import { MintStepper, MintStepperProps } from './components/MintStepper';

type Props = Omit<StackProps, 'children'>;

const Wrapper = styled(Responsive)`
  position: relative;
`;

const MintWrapper = styled(Stack)`
  order: 0;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    order: 1;
  `};
`;

const TierRulesWrapper = styled(Stack)`
  order: 1;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    order: 0;
    align-self: center;
    margin-bottom: 1rem;
  `};
`;

const HiddenColumn = styled.div`
  width: 20%;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    width: 0;
  `};
`;

export const Mint: FC<Props> = memo(() => {
  const [mintStepperProps, setMintStepperProps] = useState({ isOpen: false } as MintStepperProps);

  const {
    loaded,
    isProxyCreated,
    contractEnabling,
    upgradeUserProxy,
    availableApes,
    availableBakcs,
    mint,
    minting
  } = useMintSewerPass();

  const handleMint = useCallback(
    (ape: ApeListItem, bakc?: ApeListItem) => {
      if (!bakc || bakc.supplied) {
        mint(
          { id: ape.tokenId, symbol: ape.symbol },
          bakc ? { id: bakc.tokenId, symbol: bakc.symbol } : undefined
        );
        return;
      }
      setMintStepperProps({
        isOpen: true,
        onMint: mint,
        mintTokens: bakc ? [ape, bakc] : [ape],
        onClose: () => {
          setMintStepperProps(curr => ({
            ...curr,
            isOpen: false
          }));
        }
      });
    },
    [mint]
  );

  return (
    <Wrapper width="100%" alignItems="flex-start" justifyContent="space-between" gap="0">
      <MintStepper {...mintStepperProps} />
      <HiddenColumn />
      <MintWrapper width="100%" alignItems="center">
        {loaded ? (
          <MintSewerPass
            availableBaycAndMaycList={availableApes ?? []}
            availableBakcList={availableBakcs ?? []}
            isProxyCreated={isProxyCreated}
            contractEnabling={contractEnabling}
            upgradeUserProxy={upgradeUserProxy}
            handleMint={handleMint}
            minting={minting}
          />
        ) : (
          <Spinner size="large" />
        )}
      </MintWrapper>
      <TierRulesWrapper>
        <Card border inset="1.5rem">
          <Stack gap="1rem">
            <H5>Tier Rules</H5>
            <Image src={tierRules} />
          </Stack>
        </Card>
      </TierRulesWrapper>
    </Wrapper>
  );
});
