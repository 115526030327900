import { useCallback } from 'react';
import { ethers } from 'ethers';

import { useAppConfig } from '../../hooks';

export const useParaAccountTransfer = () => {
  const { erc721Config } = useAppConfig();

  const transferERC20 = useCallback(
    async (toAddress: string, contractAddress: string, amount: string) => {
      const contract = new ethers.Contract(contractAddress, [
        'function transfer(address to, uint256 value) returns (bool)'
      ]);

      const transaction = {
        to: contractAddress,
        data: contract.interface.encodeFunctionData('transfer', [toAddress, amount]),
        value: '0'
      };

      return transaction;
    },
    []
  );
  const transferERC721 = useCallback(
    async (fromAddress: string, toAddress: string, contractAddress: string, tokenId: number) => {
      const contract = new ethers.Contract(contractAddress, [
        'function safeTransferFrom(address from, address to, uint256 tokenId) external',
        'function transferPunk(address to, uint256 punkIndex)'
      ]);
      const transaction = {
        to: contractAddress,
        data:
          contractAddress === erc721Config.PUNK?.address
            ? contract.interface.encodeFunctionData('transferPunk', [toAddress, tokenId])
            : contract.interface.encodeFunctionData('safeTransferFrom', [
                fromAddress,
                toAddress,
                tokenId
              ]),
        value: '0'
      };

      return transaction;
    },
    [erc721Config]
  );

  return { transferERC20, transferERC721 };
};
