import { ReactNode, memo } from 'react';
import styled from 'styled-components';

import { ERC721TokenSelector, ERC721TokenSelectorProps } from '@/apps/paraspace/components';

const EmptyState = styled.div`
  background: ${({ theme }) => theme.skin.secondary.main};
  border-radius: 0.5rem;
  padding: 2.5rem 0.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const TokenSelector = memo(
  ({
    tokenList,
    loading,
    noDataMessage,
    ...others
  }: ERC721TokenSelectorProps & {
    noDataMessage: ReactNode;
  }) => {
    if (!loading && tokenList.length === 0) {
      return <EmptyState>{noDataMessage}</EmptyState>;
    }

    return <ERC721TokenSelector tokenList={tokenList} loading={loading} {...others} />;
  }
);
