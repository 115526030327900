import { Network } from 'paraspace-configs-v2';

import { ERC20Symbol } from '../typings';

import { erc20Decimals } from './erc20DecimalsConfig';

export type ERC20Config = Record<
  ERC20Symbol,
  {
    contractName: string;
    isNativeToken?: boolean;
    isWrappedNativeToken?: boolean;
    nativeTokenSymbol?: string;
    displayName?: string;
    decimals: number;
  }
>;

const ERC20_CONFIG_FOR_SEPOLIA = {
  [ERC20Symbol.APE]: {
    decimals: erc20Decimals[ERC20Symbol.APE],
    contractName: 'APE'
  },
  [ERC20Symbol.CAPE]: {
    decimals: erc20Decimals[ERC20Symbol.CAPE],
    contractName: 'cAPE'
  },
  [ERC20Symbol.SAPE]: {
    decimals: erc20Decimals[ERC20Symbol.SAPE],
    displayName: 'Staked Ape',
    contractName: 'sAPE'
  },
  [ERC20Symbol.ETH]: {
    decimals: erc20Decimals[ERC20Symbol.ETH],
    isNativeToken: true,
    contractName: ''
  },
  [ERC20Symbol.WETH]: {
    decimals: erc20Decimals[ERC20Symbol.WETH],
    nativeTokenSymbol: 'ETH',
    contractName: 'WETH',
    isWrappedNativeToken: true
  },
  [ERC20Symbol.STETH]: {
    decimals: erc20Decimals[ERC20Symbol.STETH],
    contractName: 'stETH'
  },
  [ERC20Symbol.WSTETH]: {
    decimals: erc20Decimals[ERC20Symbol.WSTETH],
    contractName: 'wstETH'
  },
  // [ERC20Symbol.CBETH]: {
  //   decimals: erc20Decimals[ERC20Symbol.CBETH],
  //   contractName: 'cbETH'
  // },
  // [ERC20Symbol.RETH]: {
  //   decimals: erc20Decimals[ERC20Symbol.RETH],
  //   contractName: 'rETH'
  // },
  // [ERC20Symbol.AWETH]: {
  //   decimals: erc20Decimals[ERC20Symbol.AWETH],
  //   contractName: 'aWETH'
  // },
  [ERC20Symbol.USDC]: {
    decimals: erc20Decimals[ERC20Symbol.USDC],
    contractName: 'USDC'
  },
  [ERC20Symbol.USDT]: {
    decimals: erc20Decimals[ERC20Symbol.USDT],
    contractName: 'USDT'
  },
  [ERC20Symbol.DAI]: {
    decimals: erc20Decimals[ERC20Symbol.DAI],
    contractName: 'DAI'
  },
  [ERC20Symbol.WBTC]: {
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC'
  },
  [ERC20Symbol.BLUR]: {
    decimals: erc20Decimals[ERC20Symbol.BLUR],
    contractName: 'BLUR'
  },
  [ERC20Symbol.FRAX]: {
    decimals: erc20Decimals[ERC20Symbol.FRAX],
    contractName: 'FRAX'
  }
  // [ERC20Symbol.UBAYC]: {
  //   decimals: erc20Decimals[ERC20Symbol.UBAYC],
  //   contractName: 'µBAYC'
  // },
  // [ERC20Symbol.UPPG]: {
  //   decimals: erc20Decimals[ERC20Symbol.UPPG],
  //   contractName: 'µPPG'
  // }
} as ERC20Config;

const ERC20_CONFIG_FOR_MAINNET = {
  [ERC20Symbol.APE]: {
    decimals: erc20Decimals[ERC20Symbol.APE],
    contractName: 'APE'
  },
  [ERC20Symbol.CAPE]: {
    decimals: erc20Decimals[ERC20Symbol.CAPE],
    contractName: 'cAPE'
  },
  [ERC20Symbol.SAPE]: {
    decimals: erc20Decimals[ERC20Symbol.SAPE],
    displayName: 'Staked Ape',
    contractName: 'sAPE'
  },
  [ERC20Symbol.ETH]: {
    decimals: erc20Decimals[ERC20Symbol.ETH],
    isNativeToken: true,
    contractName: ''
  },
  [ERC20Symbol.WETH]: {
    decimals: erc20Decimals[ERC20Symbol.WETH],
    nativeTokenSymbol: 'ETH',
    contractName: 'WETH',
    isWrappedNativeToken: true
  },
  [ERC20Symbol.STETH]: {
    decimals: erc20Decimals[ERC20Symbol.STETH],
    contractName: 'stETH'
  },
  [ERC20Symbol.WSTETH]: {
    decimals: erc20Decimals[ERC20Symbol.WSTETH],
    contractName: 'wstETH'
  },
  [ERC20Symbol.CBETH]: {
    decimals: erc20Decimals[ERC20Symbol.CBETH],
    contractName: 'cbETH'
  },
  [ERC20Symbol.RETH]: {
    decimals: erc20Decimals[ERC20Symbol.RETH],
    contractName: 'rETH'
  },
  [ERC20Symbol.AWETH]: {
    decimals: erc20Decimals[ERC20Symbol.AWETH],
    contractName: 'aWETH'
  },
  [ERC20Symbol.USDC]: {
    decimals: erc20Decimals[ERC20Symbol.USDC],
    contractName: 'USDC'
  },
  [ERC20Symbol.USDT]: {
    decimals: erc20Decimals[ERC20Symbol.USDT],
    contractName: 'USDT'
  },
  [ERC20Symbol.DAI]: {
    decimals: erc20Decimals[ERC20Symbol.DAI],
    contractName: 'DAI'
  },
  [ERC20Symbol.WBTC]: {
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC'
  },
  [ERC20Symbol.BLUR]: {
    decimals: erc20Decimals[ERC20Symbol.BLUR],
    contractName: 'BLUR'
  },
  [ERC20Symbol.FRAX]: {
    decimals: erc20Decimals[ERC20Symbol.FRAX],
    contractName: 'FRAX'
  },
  [ERC20Symbol.UBAYC]: {
    decimals: erc20Decimals[ERC20Symbol.UBAYC],
    contractName: 'µBAYC'
  },
  [ERC20Symbol.UPPG]: {
    decimals: erc20Decimals[ERC20Symbol.UPPG],
    contractName: 'µPPG'
  }
} as ERC20Config;

const ERC20_CONFIG_FOR_ARBITRUM = {
  [ERC20Symbol.ETH]: {
    decimals: erc20Decimals[ERC20Symbol.ETH],
    isNativeToken: true,
    contractName: ''
  },
  [ERC20Symbol.WETH]: {
    decimals: erc20Decimals[ERC20Symbol.WETH],
    nativeTokenSymbol: 'ETH',
    contractName: 'WETH',
    isWrappedNativeToken: true
  },
  [ERC20Symbol.WSTETH]: {
    decimals: erc20Decimals[ERC20Symbol.WSTETH],
    contractName: 'wstETH'
  },
  [ERC20Symbol.USDC]: {
    decimals: erc20Decimals[ERC20Symbol.USDC],
    contractName: 'USDC',
    displayName: 'USDC.e'
  },
  [ERC20Symbol.USDT]: {
    decimals: erc20Decimals[ERC20Symbol.USDT],
    contractName: 'USDT'
  },
  [ERC20Symbol.DAI]: {
    decimals: erc20Decimals[ERC20Symbol.DAI],
    contractName: 'DAI'
  },
  [ERC20Symbol.WBTC]: {
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC'
  },
  [ERC20Symbol.ARB]: {
    decimals: erc20Decimals[ERC20Symbol.ARB],
    contractName: 'ARB'
  },
  [ERC20Symbol.GMX]: {
    decimals: erc20Decimals[ERC20Symbol.GMX],
    contractName: 'GMX'
  },
  [ERC20Symbol.LINK]: {
    decimals: erc20Decimals[ERC20Symbol.LINK],
    contractName: 'LINK'
  },
  [ERC20Symbol.UNI]: {
    decimals: erc20Decimals[ERC20Symbol.UNI],
    contractName: 'UNI'
  },
  [ERC20Symbol.AAVE]: {
    decimals: erc20Decimals[ERC20Symbol.AAVE],
    contractName: 'AAVE'
  },
  [ERC20Symbol.RDNT]: {
    decimals: erc20Decimals[ERC20Symbol.RDNT],
    contractName: 'RDNT'
  }
} as ERC20Config;

const ERC20_CONFIG_FOR_POLYGON = {
  [ERC20Symbol.MATIC]: {
    decimals: erc20Decimals[ERC20Symbol.MATIC],
    isNativeToken: true,
    contractName: ''
  },
  [ERC20Symbol.WMATIC]: {
    decimals: erc20Decimals[ERC20Symbol.WMATIC],
    contractName: 'WMATIC',
    isWrappedNativeToken: true
  },
  [ERC20Symbol.STMATIC]: {
    decimals: erc20Decimals[ERC20Symbol.STMATIC],
    contractName: 'stMATIC'
  },
  [ERC20Symbol.WETH]: {
    decimals: erc20Decimals[ERC20Symbol.WETH],
    contractName: 'WETH'
  },
  [ERC20Symbol.WBTC]: {
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC'
  },
  [ERC20Symbol.USDC]: {
    decimals: erc20Decimals[ERC20Symbol.USDC],
    contractName: 'USDC'
  },
  [ERC20Symbol.USDT]: {
    decimals: erc20Decimals[ERC20Symbol.USDT],
    contractName: 'USDT'
  },
  [ERC20Symbol.DAI]: {
    decimals: erc20Decimals[ERC20Symbol.DAI],
    contractName: 'DAI'
  },
  [ERC20Symbol.AAVE]: {
    decimals: erc20Decimals[ERC20Symbol.AAVE],
    contractName: 'AAVE'
  },
  [ERC20Symbol.LINK]: {
    decimals: erc20Decimals[ERC20Symbol.LINK],
    contractName: 'LINK'
  },
  [ERC20Symbol.UNI]: {
    decimals: erc20Decimals[ERC20Symbol.UNI],
    contractName: 'UNI'
  },
  [ERC20Symbol.CRV]: {
    decimals: erc20Decimals[ERC20Symbol.CRV],
    contractName: 'CRV'
  },
  [ERC20Symbol.BAL]: {
    decimals: erc20Decimals[ERC20Symbol.BAL],
    contractName: 'BAL'
  }
} as ERC20Config;

const ERC20_CONFIG_FOR_MOONBEAM = {
  [ERC20Symbol.GLMR]: {
    decimals: erc20Decimals[ERC20Symbol.GLMR],
    isNativeToken: true,
    contractName: 'GLMR'
  },
  [ERC20Symbol.WGLMR]: {
    decimals: erc20Decimals[ERC20Symbol.WGLMR],
    isWrappedNativeToken: true,
    contractName: 'WGLMR'
  },
  [ERC20Symbol.WETH]: {
    decimals: erc20Decimals[ERC20Symbol.WETH],
    contractName: 'WETH',
    displayName: 'WETH.wh'
  },
  [ERC20Symbol.USDC]: {
    decimals: erc20Decimals[ERC20Symbol.USDC],
    contractName: 'USDC',
    displayName: 'USDC.wh'
  },
  [ERC20Symbol.WBTC]: {
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC',
    displayName: 'WBTC.wh'
  },
  [ERC20Symbol.XCDOT]: {
    decimals: erc20Decimals[ERC20Symbol.XCDOT],
    contractName: 'xcDOT',
    displayName: 'xcDOT'
  },
  [ERC20Symbol.STDOT]: {
    decimals: erc20Decimals[ERC20Symbol.STDOT],
    contractName: 'stDOT'
  },
  [ERC20Symbol.XCUSDT]: {
    decimals: erc20Decimals[ERC20Symbol.XCUSDT],
    contractName: 'xcUSDT',
    displayName: 'xcUSDT'
  }
} as ERC20Config;

const ERC20_CONFIG_FOR_ZKSYNC = {
  [ERC20Symbol.ETH]: {
    decimals: erc20Decimals[ERC20Symbol.ETH],
    isNativeToken: true,
    contractName: ''
  },
  [ERC20Symbol.WETH]: {
    decimals: erc20Decimals[ERC20Symbol.WETH],
    contractName: 'WETH',
    isWrappedNativeToken: true
  },
  [ERC20Symbol['USDC.E']]: {
    decimals: erc20Decimals[ERC20Symbol.USDC],
    contractName: 'USDC',
    displayName: 'USDC.e'
  },
  [ERC20Symbol.WBTC]: {
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC'
  }
} as ERC20Config;

const ERC20_CONFIG_FOR_PARALLEL_POLKADOT = {
  [ERC20Symbol.PARA]: {
    decimals: erc20Decimals[ERC20Symbol.PARA],
    isNativeToken: true,
    contractName: 'PARA'
  },
  [ERC20Symbol.USDC]: {
    decimals: erc20Decimals[ERC20Symbol.USDC],
    contractName: 'USDC'
  },
  [ERC20Symbol.USDT]: {
    decimals: erc20Decimals[ERC20Symbol.USDT],
    contractName: 'USDT'
  },
  [ERC20Symbol.DAI]: {
    decimals: erc20Decimals[ERC20Symbol.DAI],
    contractName: 'DAI'
  },
  [ERC20Symbol.WBTC]: {
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC'
  },
  [ERC20Symbol.APE]: {
    decimals: erc20Decimals[ERC20Symbol.APE],
    contractName: 'APE'
  }
} as ERC20Config;

const ERC20_CONFIG_FOR_PARALLEL_TESTNET = {
  [ERC20Symbol.ETH]: {
    decimals: erc20Decimals[ERC20Symbol.ETH],
    isNativeToken: true,
    contractName: ''
  }
} as ERC20Config;

const ERC20_CONFIG_FOR_PARALLEL = {
  [ERC20Symbol.ETH]: {
    decimals: erc20Decimals[ERC20Symbol.ETH],
    isNativeToken: true,
    contractName: ''
  }
} as ERC20Config;

export const ERC20_CONFIG_FOR_NETWORK_MAP: Partial<Record<Network, ERC20Config>> = {
  [Network.ARBITRUM_GOERLI]: ERC20_CONFIG_FOR_ARBITRUM,
  [Network.ARBITRUM_ONE]: ERC20_CONFIG_FOR_ARBITRUM,
  [Network.POLYGON_MUMBAI]: ERC20_CONFIG_FOR_POLYGON,
  [Network.POLYGON_MAINNET]: ERC20_CONFIG_FOR_POLYGON,
  [Network.SEPOLIA]: ERC20_CONFIG_FOR_SEPOLIA,
  [Network.MAINNET]: ERC20_CONFIG_FOR_MAINNET,
  [Network.FORK]: ERC20_CONFIG_FOR_MAINNET,
  [Network.MOONBASE_ALPHA]: ERC20_CONFIG_FOR_MOONBEAM,
  [Network.MOONBEAM]: ERC20_CONFIG_FOR_MOONBEAM,
  [Network.ZKSYNC_ERA]: ERC20_CONFIG_FOR_ZKSYNC,
  [Network.ZKSYNC_GOERLI]: ERC20_CONFIG_FOR_ZKSYNC,
  [Network.PARALLEL_TESTNET]: ERC20_CONFIG_FOR_PARALLEL_POLKADOT,
  [Network.PARALLEL_L3_TESTNET]: ERC20_CONFIG_FOR_PARALLEL_TESTNET,
  [Network.PARALLEL]: ERC20_CONFIG_FOR_PARALLEL
};
