import { memo, useCallback, useMemo, useState, useEffect } from 'react';
import {
  Button,
  H5,
  Inline,
  InputField,
  Skeleton,
  SmallText,
  Stack,
  StackProps
} from '@parallel-mono/components';
import { InfoPanel, SelectableTokenInputProps } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';
import { isNil } from 'lodash';

import { useDebouncedBorrowApy } from '../hooks/useDebouncedBorrowApy';
import { useExpandableTokens } from '../../../hooks';
import { NoCollateralMessage } from '../../../components';
import {
  DISABLE_BORROW_UTILIZATION_THRESHOLD,
  ESCAPE_UTILIZATION_THRESHOLD_USERS
} from '../../../configs';

import { BorrowNativeTokenDerivativesFormData } from './types';
import { BorrowedContainer } from './styledComponents';
import { validate } from './validate';
import { SelectableNativeTokenDerivativeInput } from './SelectableNativeTokenDerivativeInput';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  MAXIMUM_BALANCE_DECIMALS,
  MINIMUM_ACCOUNTABLE_NUM,
  MAX_BORROW_PERCENTAGE
} from '@/apps/paraspace/pages/config';
import { one } from '@/apps/paraspace/consts/values';
import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { TimelockTerms, useTimelockTermsCheck, BorrowLimitBar } from '@/apps/paraspace/components';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type BorrowNativeTokenDerivativesFormProps = Omit<
  StackProps,
  'children' | 'onSubmit' | 'onChange'
> & {
  onSubmit: (data: BorrowNativeTokenDerivativesFormData) => void;
  onCancel: () => void;
  onFormDataChange: (data: BorrowNativeTokenDerivativesFormData) => void;
  defaultDerivative: ERC20Symbol;
};

export const BorrowNativeTokenDerivativesForm = memo(
  ({
    onSubmit,
    onCancel,
    onFormDataChange,
    defaultDerivative,
    ...others
  }: BorrowNativeTokenDerivativesFormProps) => {
    const {
      erc20InfoMap,
      overviewUserInfo: {
        liquidationThresholdInUsd,
        totalCollateralPositionInUsd,
        borrowLimitInUsd,
        totalBorrowedPositionInUsd
      }
    } = useMMProvider();
    const { account } = useWeb3Context();

    const expandableTokenSymbols = useExpandableTokens(defaultDerivative);

    const tokens = useMemo(() => {
      return expandableTokenSymbols.map(symbol => ({
        name: erc20InfoMap[symbol]?.displayName ?? symbol,
        symbol,
        balance: erc20InfoMap[symbol]?.balance?.toNumber() ?? null,
        priceInUSD: erc20InfoMap[symbol]?.priceInUsd?.toNumber() ?? null
      }));
    }, [erc20InfoMap, expandableTokenSymbols]);
    const [value, setValue] = useState<
      SelectableTokenInputProps<{
        name: string;
        symbol: ERC20Symbol;
        balance: number | null;
        priceInUSD: number | null;
      }>['value']
    >({
      token: tokens.find(token => token.symbol === defaultDerivative) ?? tokens[0],
      amount: null
    });

    const { amount } = value!;
    const { symbol, priceInUSD } = value!.token!;

    const borrowLimitValue = useMemo(() => {
      const result = borrowLimitInUsd.minus(totalBorrowedPositionInUsd).div(priceInUSD ?? one);
      return BigNumber.max(0, result);
    }, [borrowLimitInUsd, totalBorrowedPositionInUsd, priceInUSD]);

    const userAvailableToBorrow = useMemo(() => {
      return borrowLimitValue.times(MAX_BORROW_PERCENTAGE);
    }, [borrowLimitValue]);

    const {
      baseLTVasCollateral,
      reserveLiquidationThreshold,
      borrowApyRate,
      totalDebt,
      availableLiquidity,
      variableDebtTokenAddress,
      address,
      displayName
    } = erc20InfoMap[symbol];

    const { claimableTime, timelockTermsChecked, handleTimelockTermsCheck } = useTimelockTermsCheck(
      amount ?? 0,
      symbol
    );

    const handleActionButtonClicked = useCallback(() => {
      setValue(v => ({
        ...v!,
        amount: userAvailableToBorrow?.times(0.5).decimalPlaces(4).toNumber() ?? 0
      }));
    }, [userAvailableToBorrow]);

    const borrowApy = useDebouncedBorrowApy({
      amount,
      symbol,
      defaultValue: borrowApyRate
    });

    const { totalBorrowedPositionInUsd: newBorrowed } = useLendingSimulation([
      {
        type: ActionTypeEnum.BORROW,
        targetAsset: {
          id: symbol,
          value: new BigNumber(priceInUSD ?? 0).times(amount || 0),
          LTV: baseLTVasCollateral,
          reserveLiquidationThreshold
        }
      }
    ]);

    const utilization = useMemo(
      () => totalDebt.div(totalDebt.plus(availableLiquidity)),
      [totalDebt, availableLiquidity]
    );

    const errorMessage = useMemo(
      () =>
        validate({
          amount: value?.amount!,
          userAvailableToBorrow,
          availableLiquidity,
          utilization,
          account
        }),
      [userAvailableToBorrow, value?.amount, availableLiquidity, utilization, account]
    );

    const disableBorrow =
      amount === null ||
      !!errorMessage ||
      !timelockTermsChecked ||
      (utilization.gte(DISABLE_BORROW_UTILIZATION_THRESHOLD) &&
        !ESCAPE_UTILIZATION_THRESHOLD_USERS.includes(account));

    useEffect(() => {
      onFormDataChange({
        amount: new BigNumber(amount!),
        derivative: symbol,
        variableDebtTokenAddress,
        address
      });
    }, [address, amount, onFormDataChange, symbol, variableDebtTokenAddress]);

    const handleSubmitButtonClicked = useCallback(() => {
      onSubmit({
        amount: new BigNumber(amount!),
        derivative: symbol,
        variableDebtTokenAddress,
        address
      });
    }, [onSubmit, amount, symbol, variableDebtTokenAddress, address]);

    const userHasCollateral = totalCollateralPositionInUsd.gt(0);
    if (!userHasCollateral) {
      return <NoCollateralMessage onSupplyNFT={onCancel} onSupplyERC20={onCancel} />;
    }

    return (
      <Stack width="100%" {...others}>
        <InputField
          Component={SelectableNativeTokenDerivativeInput}
          error={errorMessage}
          inputProps={{
            value,
            tokens,
            actionButtonText: `50% of borrow limit (${formatBalance(userAvailableToBorrow)})`,
            decimals: MAXIMUM_BALANCE_DECIMALS,
            onActionButtonClicked: handleActionButtonClicked,
            onChange: setValue,
            skin: 'secondary'
          }}
        />
        <BorrowedContainer inset="1rem">
          <Inline alignItems="center">
            <SmallText skin="secondary">Borrowing:</SmallText>
            <H5>${formatNumber(newBorrowed)}</H5>
          </Inline>
          <BorrowLimitBar
            borrowedValue={newBorrowed}
            borrowLimitValue={borrowLimitInUsd}
            liquidationPointValue={liquidationThresholdInUsd}
            totalSuppliedValue={totalCollateralPositionInUsd}
          />
        </BorrowedContainer>
        <InfoPanel
          skin="primary"
          infos={[
            {
              title: 'Borrow APY',
              value: isNil(borrowApy) ? (
                <Skeleton.Button height="1.5rem" variant="round" />
              ) : (
                formatNumber(borrowApy, { output: 'percent' })
              )
            }
          ]}
        />
        <TimelockTerms
          checked={timelockTermsChecked}
          onTermsCheck={handleTimelockTermsCheck}
          claimableTime={claimableTime}
        />
        <Button
          skin="primary"
          size="large"
          block
          disabled={disableBorrow}
          onClick={handleSubmitButtonClicked}
        >
          {(() => {
            if (errorMessage) {
              return 'Borrows';
            }
            return `Borrows ${formatNumber(value!.amount ?? 0, {
              decimal: MAXIMUM_BALANCE_DECIMALS,
              threshold: {
                min: MINIMUM_ACCOUNTABLE_NUM
              }
            })} ${displayName}`;
          })()}
        </Button>
      </Stack>
    );
  }
);
