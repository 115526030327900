import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  InputOrderFilter,
  OrderSortBy,
  ShopBidByPlatform,
  Marketplace,
  useGetOrdersLazyQuery
} from '@/apps/paraspace/generated/graphql';
import { useFormatEntity, useOffersMakerInfos } from '@/apps/paraspace/pages/Shop/hooks';
import { useToggle } from '@/apps/paraspace/contexts';
import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';

const PAGE_SIZE = 5;

export const useGetOffers = (variables: {
  filter: InputOrderFilter;
  sort?: OrderSortBy;
  pageLimit?: number;
}) => {
  const { formatOrder } = useFormatEntity();
  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = useMemo(() => variables.pageLimit ?? PAGE_SIZE, [variables.pageLimit]);

  const [fetchOrders, { data, refetch, loading }] = useGetOrdersLazyQuery({
    fetchPolicy: 'network-only'
  });

  const stabilizedVariables = useStabilizedSnapshot(variables);
  const blurEnabled = useToggle('BLUR_INTEGRATION');

  useEffect(() => {
    fetchOrders({
      variables: {
        filter: stabilizedVariables.filter,
        pageInfo: {
          offset: (currentPage - 1) * pageLimit,
          limit: pageLimit
        },
        sortBy: stabilizedVariables.sort,
        platforms: blurEnabled ? [Marketplace.Blur] : []
      }
    });
  }, [blurEnabled, currentPage, fetchOrders, pageLimit, stabilizedVariables]);

  const ordersData = useMemo(
    () => data?.orders?.nodes?.map(order => formatOrder(order as ShopBidByPlatform)) || [],
    [data?.orders?.nodes, formatOrder]
  );
  const stabilizedOrders = useStabilizedSnapshot(ordersData);
  const { isLoading: isLoadingMakerInfo, offers } = useOffersMakerInfos(stabilizedOrders);

  const isLoading = useMemo(
    () => (isEmpty(data) && loading) || isLoadingMakerInfo,
    [data, isLoadingMakerInfo, loading]
  );

  const totalPage = useMemo(
    () => Math.ceil((data?.orders?.totalCount ?? 0) / pageLimit),
    [data?.orders?.totalCount, pageLimit]
  );

  const totalCount = useMemo(() => data?.orders?.totalCount ?? 0, [data?.orders?.totalCount]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return {
    refetch,
    loading: isLoading,
    offers,
    totalPage,
    currentPage,
    totalCount,
    handlePageChange
  };
};
