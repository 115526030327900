import { useMemo } from 'react';
import { filter } from 'lodash';

import { EthValidatorRow } from './types';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol, FetchingStatus, StakeFishState } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';

export const useEthValidatorTableData = () => {
  const { nftInfoMap, userInfoLoaded } = useMMProvider();
  const { erc721BalanceMap: eoaErc721BalanceMap, erc721BalanceMapPollingStatus } = useEOABalances();
  const isERC721BalanceLoading =
    eoaErc721BalanceMap === null && erc721BalanceMapPollingStatus === FetchingStatus.FETCHING;
  const loading = useMemo(() => !nftInfoMap || !nftInfoMap?.[ERC721Symbol.SF_VLDR], [nftInfoMap]);

  const data: Maybe<EthValidatorRow[]> = useMemo(() => {
    if (!nftInfoMap || !nftInfoMap?.[ERC721Symbol.SF_VLDR]) return [];

    const {
      collectionName,
      balance,
      priceInETH,
      baseLTVasCollateral,
      availableLiquidity,
      priceInUsd,
      tokenSpecificInfos
    } = nftInfoMap[ERC721Symbol.SF_VLDR];

    const paraXBalances = filter(balance, tokenId =>
      [StakeFishState.PostDeposit, StakeFishState.Active].includes(
        tokenSpecificInfos?.[tokenId]?.state
      )
    ).map(tokenId => ({ symbol: ERC721Symbol.SF_VLDR, tokenId }));

    const EOABalance = filter(eoaErc721BalanceMap?.[ERC721Symbol.SF_VLDR]?.balance, tokenId =>
      [StakeFishState.PostDeposit, StakeFishState.Active].includes(
        eoaErc721BalanceMap?.[ERC721Symbol.SF_VLDR]?.stakeFishInfoMap?.[tokenId]
          ?.state as StakeFishState
      )
    ).map(tokenId => ({ symbol: ERC721Symbol.SF_VLDR, tokenId }));

    const balanceMap = {
      EOA: {
        balances: EOABalance,
        loading: isERC721BalanceLoading
      },
      AA: { balances: paraXBalances, loading: !userInfoLoaded }
    };

    const isBalanceLoading = !userInfoLoaded && isERC721BalanceLoading;

    return [
      {
        symbol: ERC721Symbol.SF_VLDR,
        collectionName,
        balanceMap,
        floorPrice: priceInETH,
        ltv: baseLTVasCollateral,
        totalSupplied: availableLiquidity,
        totalUsd: availableLiquidity.times(priceInUsd ?? 0),
        balanceLoadingStatus: isBalanceLoading ? FetchingStatus.FETCHING : FetchingStatus.SUCCESS
      }
    ];
  }, [eoaErc721BalanceMap, isERC721BalanceLoading, nftInfoMap, userInfoLoaded]);

  return { data, loading };
};
