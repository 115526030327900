import { Pool } from 'paraspace-utilities-contract-helpers';
import { useCallback, useMemo } from 'react';

import { ApeStakingMainTokenSymbol } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

export type StakingApeProps = {
  type: ApeStakingMainTokenSymbol;
  tokenId: number;
  borrowAmount: number;
  cashAmount: number;
  bakcTokenId?: number;
  bakcBorrowAmount?: number;
  bakcCashAmount?: number;
};

export const usePoolApeStaking = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new Pool(provider, {
      POOL: contracts.PoolProxy,
      WETH_GATEWAY: contracts.WETHGatewayProxy
    });
  }, [contracts.PoolProxy, contracts.WETHGatewayProxy, provider]);

  const genStakingApe = useCallback(
    async ({
      type,
      tokenId,
      borrowAmount,
      cashAmount,
      bakcTokenId,
      bakcBorrowAmount,
      bakcCashAmount
    }: StakingApeProps) => {
      if (!service || !provider) return null;

      const tx = service.depositApeCoin({
        address: contracts[type],
        apeCoinAddress: contracts.cAPE,
        tokenId,
        borrowAmount,
        cashAmount,
        bakcTokenId,
        bakcBorrowAmount,
        bakcCashAmount,
        user: account
      });
      return tx;
    },
    [service, provider, contracts, account]
  );

  const genClaimApeTx = useCallback(
    (type: ApeStakingMainTokenSymbol, tokenIds: number[]) => {
      if (!service) return null;
      return service.claimApecoin(contracts[type], tokenIds, account);
    },
    [contracts, service, account]
  );

  const genBatchClaimBAKCTx = useCallback(
    (
      type: ApeStakingMainTokenSymbol,
      // all those pairs must share the same main token type
      pairs: { mainTokenId: number; bakcTokenId: number }[]
    ) => {
      if (!service) return null;
      return service.claimBAKC(contracts[type], account, pairs);
    },
    [contracts, service, account]
  );

  return {
    genClaimApeTx,
    genBatchClaimBAKCTx,
    genStakingApe
  };
};
