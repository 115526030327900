import { useCallback, useEffect, useRef, useState } from 'react';
import { uniqueId } from 'lodash';
import { Contract, providers } from 'ethers';
import { decode } from 'js-base64';

import { useGetContractAddressBySymbol } from './useGetContractAddressBySymbol';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ERC721Symbol } from '@/apps/paraspace/typings';

const MINIMUM_ABI = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'tokenURI',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'positions',
    outputs: [
      {
        internalType: 'uint96',
        name: 'nonce',
        type: 'uint96'
      },
      {
        internalType: 'address',
        name: 'operator',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'token0',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'token1',
        type: 'address'
      },
      {
        internalType: 'uint24',
        name: 'fee',
        type: 'uint24'
      },
      {
        internalType: 'int24',
        name: 'tickLower',
        type: 'int24'
      },
      {
        internalType: 'int24',
        name: 'tickUpper',
        type: 'int24'
      },
      {
        internalType: 'uint128',
        name: 'liquidity',
        type: 'uint128'
      },
      {
        internalType: 'uint256',
        name: 'feeGrowthInside0LastX128',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'feeGrowthInside1LastX128',
        type: 'uint256'
      },
      {
        internalType: 'uint128',
        name: 'tokensOwed0',
        type: 'uint128'
      },
      {
        internalType: 'uint128',
        name: 'tokensOwed1',
        type: 'uint128'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  }
];

const getERC721ImageThroughContract = async ({
  provider,
  tokenIds,
  contractAddress
}: {
  provider: providers.JsonRpcProvider;
  tokenIds: number[];
  contractAddress: string;
}) => {
  try {
    const contract = new Contract(contractAddress, MINIMUM_ABI, provider);

    // fetching images data in parallel
    const tokenData = await Promise.all(
      tokenIds.map(tokenId => {
        return contract.tokenURI(tokenId).then((rawUri: string) => {
          const data: {
            name: string;
            description: string;
            image: string;
          } = JSON.parse(decode(rawUri.split(',')[1]));
          return data;
        });
      })
    );

    return tokenData.map(({ image, name, description }) => ({
      image,
      name,
      description,
      imageThumbnail: image
    }));
  } catch (e: unknown) {
    return [];
  }
};

export const useGetERC721ImagesThroughContract = () => {
  const { provider } = useWeb3Context();
  const getContractAddressBySymbol = useGetContractAddressBySymbol();

  return useCallback(
    ({ tokenIds, symbol }: { tokenIds: number[]; symbol: ERC721Symbol }) =>
      getERC721ImageThroughContract({
        contractAddress: getContractAddressBySymbol(symbol),
        tokenIds,
        provider
      }),
    [getContractAddressBySymbol, provider]
  );
};

export const useERC721ImagesThroughContract = ({
  tokenIds,
  symbol
}: {
  tokenIds: number[];
  symbol: ERC721Symbol;
}) => {
  const getERC721ImagesThroughContract = useGetERC721ImagesThroughContract();

  const [imageInfos, setImageInfos] = useState<
    { image: string; name: string; description: string; imageThumbnail: string }[] | undefined
  >(undefined);

  const reqIdRef = useRef<string>();
  useEffect(() => {
    const requestId = uniqueId();
    reqIdRef.current = requestId;
    getERC721ImagesThroughContract({ symbol, tokenIds }).then(imgInfos => {
      if (requestId === reqIdRef.current) {
        setImageInfos(imgInfos);
      }
    });
  }, [symbol, tokenIds, getERC721ImagesThroughContract]);

  return imageInfos;
};
