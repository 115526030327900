import styled from 'styled-components';
import { Stack, Card, SmallText, TypographyVariant } from '@parallel-mono/components';
import { Link as RouterLink } from 'react-router-dom';

import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { Link } from '@/apps/paraspace/components';
import { DEVELOPER_DOCS_LINK } from '@/apps/paraspace/consts/externalLinks';

const StackWrapper = styled(Stack).attrs({ as: Card, border: true, inset: '.75rem', gap: '.5rem' })`
  text-align: center;
`;

export const RewardExplanation = () => {
  return (
    <StackWrapper>
      <SmallText skin="secondary">
        All pending rewards are periodically harvested and reinvested into{' '}
        <Link
          as={RouterLink}
          to={`${absoluteRouteNames.APE_STAKING.COIN_POOL.index}/withdraw`}
          variant={TypographyVariant.bodySmall}
        >
          Auto-Compounding cAPE pool
        </Link>
        , which can then be claimed here.{' '}
        <Link
          href={`${DEVELOPER_DOCS_LINK}/product-guide-and-walkthrough/ape-staking-guide#where-can-i-see-all-the-rewards-from-share-pool`}
          variant={TypographyVariant.bodySmall}
        >
          Read More
        </Link>
      </SmallText>
    </StackWrapper>
  );
};
