import { forwardRef, memo, useImperativeHandle, useMemo, useRef } from 'react';
import { Stack } from '@parallel-mono/components';

import ERC20Tokens from './ERC20Table';
import MySupplyPositions from './MySupplyPositions';
import MyBorrowedPositions from './MyBorrowedPositions';
import TimelockQueue from './TimelockQueue';
import { NftTable } from './NftTable';

import { useAppConfig } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';

export interface MarketHandle {
  locateSupplyNft: () => void;
  locateBorrowTable: () => void;
}

export const Market = memo(
  forwardRef<MarketHandle, {}>((props, ref) => {
    const supplyNFTsRef = useRef<HTMLDivElement>(null);
    const erc20TokensRef = useRef<HTMLDivElement>(null);

    const { features } = useAppConfig();

    useImperativeHandle(ref, () => ({
      locateSupplyNft: () => {
        supplyNFTsRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      },
      locateBorrowTable: () => {
        erc20TokensRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }));

    const showNftTable = useMemo(
      () =>
        features.find(featureKey =>
          [
            Feature.GetCreditFromUniswapToken,
            Feature.GetCreditFromBlueChips,
            Feature.GetCreditFromEthValidator
          ].includes(featureKey)
        ),
      [features]
    );

    return (
      <Stack gap="1.5rem">
        {/* TODO remove this when paraspace v1 is closed */}

        <TimelockQueue />
        <MySupplyPositions />
        <MyBorrowedPositions />
        {showNftTable && <NftTable ref={supplyNFTsRef} />}
        <ERC20Tokens ref={erc20TokensRef} />
      </Stack>
    );
  })
);
