import { Stack, Image } from '@parallel-mono/components';

import Loading from './assets/loading.gif';

export const PageConnectLoading = () => {
  return (
    <Stack
      inset="2rem"
      width="100%"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
    >
      <Image src={Loading} width="150px" />
    </Stack>
  );
};
