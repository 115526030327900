import { Checkbox, CheckboxProps, Typography, TypographyVariant } from '@parallel-mono/components';
import { FC } from 'react';
import styled from 'styled-components';

export interface WarningForBuyingApeStakingProps extends Omit<CheckboxProps, 'children'> {
  variant?: TypographyVariant;
}

// TODO: temporary to fix this checkbox style
const StyledCheckbox = styled(Checkbox)`
  align-items: start;

  > label {
    margin-top: 3px;
  }

  > span {
    padding-left: 0.75rem;
  }
`;

export const WarningForBuyingApeStaking: FC<WarningForBuyingApeStakingProps> = ({
  variant = TypographyVariant.body,
  ...others
}) => {
  return (
    <StyledCheckbox size="large" {...others}>
      <Typography variant={variant}>
        Purchase of NFT will not transfer any staking positions associated with it.
      </Typography>
    </StyledCheckbox>
  );
};
