import { useMemo } from 'react';

import { StakeFishNFT } from './useStakeFishNfts';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { zero } from '@/apps/paraspace/consts/values';

export const useBorrowPositionChange = ({ selectedTokens }: { selectedTokens: StakeFishNFT[] }) => {
  const { overviewUserInfo: { borrowLimitInUsd = zero } = {} } = useMMProvider();

  const { borrowLimitInUsd: newBorrowLimitInUsd } = useLendingSimulation(
    selectedTokens.map(({ tokenId, ltv, priceInUsd, reserveLiquidationThreshold }) => {
      return {
        type: ActionTypeEnum.SUPPLY,
        targetAsset: {
          id: `${tokenId}`,
          value: priceInUsd,
          LTV: ltv,
          reserveLiquidationThreshold
        }
      };
    })
  );

  const newCollateralValueInUsd = useMemo(
    () => newBorrowLimitInUsd.minus(borrowLimitInUsd),
    [borrowLimitInUsd, newBorrowLimitInUsd]
  );

  return {
    newCollateralValueInUsd,
    newBorrowLimitInUsd,
    originalBorrowLimitInUsd: borrowLimitInUsd
  };
};
