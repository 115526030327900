import { memo, useMemo } from 'react';
import { CryptoIcon } from '@parallel-mono/business-components';
import { Inline, Stack, SmallText, H3, useBreakpoints, H5 } from '@parallel-mono/components';
import styled from 'styled-components';
import { map, omit } from 'lodash';
import { formatNumber } from '@parallel-mono/utils';

import { Tooltip } from '@/apps/paraspace/components';
import {
  HIDDEN_DECIMAL_PERCENT,
  MIN_PERCENT_DECIMAL,
  DEFAULT_PERCENT_DECIMAL,
  MAX_PERCENT
} from '@/apps/paraspace/pages/config';
import { ApeStakingPoolSummary } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';

const PoolItemWrapper = styled(Inline)`
  .first-column {
    flex-basis: 10%;
    text-align: center;
  }

  .second-column {
    flex-basis: 45%;
    text-align: center;
  }

  .third-column {
    flex-basis: 45%;
    text-align: center;
  }
`;

export const NftStakingPoolContent = memo(
  ({ apeStakingPoolSummary }: { apeStakingPoolSummary: ApeStakingPoolSummary | null }) => {
    const { mobile } = useBreakpoints();
    const summary = useMemo(
      () => omit(apeStakingPoolSummary, [ERC20Symbol.APE]),
      [apeStakingPoolSummary]
    );

    return (
      <Stack gap={mobile ? '1.5rem' : '0.5rem'}>
        {!mobile && (
          <PoolItemWrapper gap="0">
            <SmallText skin="secondary" className="first-column">
              Pool
            </SmallText>
            <Inline gap="0.25rem" className="second-column" justifyContent="center">
              <SmallText skin="secondary">Compound APY</SmallText>
              <Tooltip content="Your reward yield with rewards automatically claimed and compounded at regular intervals." />
            </Inline>
            <SmallText skin="secondary" className="third-column">
              Staked On ParaSpace
            </SmallText>
          </PoolItemWrapper>
        )}

        {map(summary, ({ compoundApy, stakedApeAmount }, key) => {
          const formattedCompoundApy = formatNumber(compoundApy ?? zero, {
            output: 'percent',
            decimal: compoundApy.gte(HIDDEN_DECIMAL_PERCENT)
              ? MIN_PERCENT_DECIMAL
              : DEFAULT_PERCENT_DECIMAL,
            threshold: {
              max: MAX_PERCENT
            }
          });

          return mobile ? (
            <Stack gap="0.5rem" key={key}>
              <Inline alignItems="center" gap="0.5rem">
                <CryptoIcon symbol={key} size="large" />
                <H5>{key}</H5>
              </Inline>
              <Inline justifyContent="space-between">
                <Stack gap="0.25rem" alignItems="center">
                  <SmallText skin="secondary">Compound APY</SmallText>
                  <H3 skin="success">{formattedCompoundApy}</H3>
                </Stack>
                <Stack gap="0.25rem" alignItems="center">
                  <SmallText skin="secondary">Staked On ParaSpace</SmallText>
                  <Inline gap="0.25rem">
                    <H3>
                      {formatNumber(stakedApeAmount ?? zero, {
                        decimal: 1
                      })}
                    </H3>
                    <H3 skin="secondary">APE</H3>
                  </Inline>
                </Stack>
              </Inline>
            </Stack>
          ) : (
            <PoolItemWrapper gap="0" key={key}>
              <div className="first-column">
                <CryptoIcon symbol={key} size="2rem" />
              </div>
              <H3 skin="success" className="second-column">
                {formattedCompoundApy}
              </H3>
              <Inline gap="0.25rem" className="third-column" justifyContent="center">
                <H3>
                  {formatNumber(stakedApeAmount ?? zero, {
                    decimal: 1
                  })}
                </H3>
                <H3 skin="secondary">APE</H3>
              </Inline>
            </PoolItemWrapper>
          );
        })}
      </Stack>
    );
  }
);
