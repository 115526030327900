import { AutoCompoundApe } from 'paraspace-utilities-contract-helpers';
import { useCallback, useMemo } from 'react';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

export const useAutoCompoundApe = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();

  const service = useMemo(() => {
    const apeStakingService = new AutoCompoundApe(provider, contracts.cAPE);
    return apeStakingService;
  }, [contracts.cAPE, provider]);

  const genStakingAutoCompoundApeTx = useCallback(
    (amount: string) => {
      if (!service) return null;

      return service.deposit({ user: account, amount });
    },
    [service, account]
  );

  const genWithdrawAutoCompoundApeTx = useCallback(
    async (amount: string) => {
      if (!service) return null;

      return service.withdraw({ user: account, amount });
    },
    [account, service]
  );
  return {
    genStakingAutoCompoundApeTx,
    genWithdrawAutoCompoundApeTx
  };
};
