import { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Text } from '@parallel-mono/components';

import { useGetUserApesState } from '../hooks';
import { useListedOnShop } from '../../ListedOnShopProvider';

import {
  ERC721TokenSelector,
  ERC721TokenSelectorProps,
  NftToken
} from '@/apps/paraspace/components';
import { ApeStakingTokenSymbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { useCheckV1TimelockStatus } from '@/apps/paraspace/pages/Credit';

type ApeStakingTokenSelectorProps = Omit<ERC721TokenSelectorProps, 'tokenList'> & {
  symbols: ApeStakingTokenSymbol[];
  stakingPool: ApeStakingTokenSymbol;
  walletType: WalletType;
  loading?: boolean;
};

const EmptyState = styled.div`
  background: ${({ theme }) => theme.skin.secondary.main};
  border-radius: 0.5rem;
  padding: 2.5rem 0.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ApeStakingTokenSelector = memo(
  ({
    symbols,
    stakingPool,
    walletType,
    loading = false,
    ...others
  }: ApeStakingTokenSelectorProps) => {
    const { erc721Config } = useAppConfig();

    const { apeAndBakcTokenSet, loading: apeStatusLoading } = useGetUserApesState(stakingPool);
    const { getListedShopStatusByToken, loading: isLoadingShopListed } = useListedOnShop();

    const contractAddresses = useMemo(
      () => symbols.map(symbol => erc721Config[symbol].address),
      [erc721Config, symbols]
    );
    const tokenIds = useMemo(
      () =>
        symbols.map(symbol =>
          apeAndBakcTokenSet[symbol]
            .filter(
              ({ source }) => source === walletType || (walletType === 'AA' && source === null)
            )
            .map(v => v.tokenId)
        ),
      [apeAndBakcTokenSet, symbols, walletType]
    );

    const { isLoading: isToken0V1TimelockLoading, tokensStatus: token0InTimelockStatus = [] } =
      useCheckV1TimelockStatus(contractAddresses[0], tokenIds[0]);
    const { isLoading: isToken1V1TimelockLoading, tokensStatus: token1InTimelockStatus = [] } =
      useCheckV1TimelockStatus(contractAddresses[1], tokenIds[1]);

    const v1TimelockTokens = useMemo(
      () => [...token0InTimelockStatus, ...token1InTimelockStatus],
      [token0InTimelockStatus, token1InTimelockStatus]
    );

    const isLoading =
      loading ||
      apeStatusLoading ||
      isLoadingShopListed ||
      isToken0V1TimelockLoading ||
      isToken1V1TimelockLoading;

    const getDisabledTip = useCallback(
      ({
        isStaked,
        isInP2P,
        isListedOnShop,
        isInV1Timelock,
        v1TimelockStatus
      }: {
        isStaked: boolean;
        isInP2P: boolean;
        isListedOnShop: boolean;
        isInV1Timelock: boolean;
        v1TimelockStatus?: {
          isClaimed: boolean;
          expectedRelease: string;
          isReleaseTimeReached: boolean;
        };
      }) => {
        if (isStaked && !isInP2P) {
          return 'NFT staked in NFT Staking Pool cannot be used in Share Pool at the same time. Please unstake from NFT Staking Pool before proceeding.';
        }
        if (isListedOnShop) {
          return 'Cannot list this NFT for shared pool because it’s listed on NFT Shop.';
        }
        if (isInV1Timelock && v1TimelockStatus) {
          const { expectedRelease, isReleaseTimeReached } = v1TimelockStatus;
          return `This NFT cannot be used to create listing temporarily, it is still in the timelock of ParaSpace V1, please ${
            !isReleaseTimeReached ? `retry after ${expectedRelease!}.` : 'wait patiently.'
          } `;
        }
        return '';
      },
      []
    );

    const tokenList: NftToken[] = useMemo(
      () =>
        symbols.flatMap(symbol => {
          const tokenSet = apeAndBakcTokenSet[symbol].filter(
            ({ source }) => source === walletType || source === null
          );
          return (
            tokenSet
              ?.filter(({ isInP2P, isStaked, isPairToBAKC }) => {
                if (stakingPool === ERC721Symbol.BAKC && symbol !== ERC721Symbol.BAKC) {
                  return !isPairToBAKC;
                }
                return !(isInP2P && isStaked);
              })
              .sort((a, b) => Number(a.isStaked) - Number(b.isStaked))
              .filter(item => {
                if (walletType === 'AA') {
                  return !item.source || (item.source && item.source === 'AA');
                }
                return item.source && item.source === 'EOA';
              })
              .map(({ tokenId, isStaked, isInP2P, symbol: tokenSymbol }) => {
                const isListedOnShop = getListedShopStatusByToken(tokenSymbol, tokenId);
                const v1TimelockStatus = v1TimelockTokens.find(
                  v => v.tokenId === tokenId && v.symbol === tokenSymbol
                );
                const isInV1Timelock = Boolean(v1TimelockStatus) && !v1TimelockStatus?.isClaimed;
                return {
                  tokenId,
                  symbol,
                  disabled: (isStaked && !isInP2P) || isListedOnShop || isInV1Timelock,
                  disabledTip: getDisabledTip({
                    isStaked,
                    isInP2P,
                    isListedOnShop,
                    isInV1Timelock,
                    v1TimelockStatus
                  })
                };
              }) ?? []
          );
        }),
      [
        symbols,
        apeAndBakcTokenSet,
        stakingPool,
        walletType,
        getListedShopStatusByToken,
        v1TimelockTokens,
        getDisabledTip
      ]
    );

    const symbolsName = symbols.join('/');

    if (!isLoading && tokenList.length === 0) {
      return (
        <EmptyState className={others.className} style={others.style}>
          <Text fontWeight="bold">Sad...</Text>
          <Text skin="secondary">no {symbolsName} found in your wallet</Text>
        </EmptyState>
      );
    }

    return <ERC721TokenSelector tokenList={tokenList} loading={isLoading} {...others} />;
  }
);
