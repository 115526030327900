import { useCallback } from 'react';
import { isNil } from 'lodash';

import { ListingFromParaSpace, ListingFromReservoir, Offer } from '../types';
import { useCollections } from '../contexts';

import { shiftedLeftBy } from '@/apps/paraspace/utils/calculations';
import {
  Marketplace,
  Order,
  ShopBidByPlatform,
  ShopListingByPlatform,
  ReservoirBid,
  CreditData,
  OrderTarget
} from '@/apps/paraspace/generated/graphql';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useAppConfig, useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { zero } from '@/apps/paraspace/consts/values';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { CollectionFees } from '@/apps/paraspace/utils/calculateFees';
import { FEE_POINT_DECIMAL } from '@/apps/paraspace/utils/format';

export const useFormatEntity = () => {
  const { erc20Config, erc721Config } = useAppConfig();
  const { erc20InfoMap, nftInfoMap } = useMMProvider();
  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  const { collections } = useCollections();

  const formatListing = useCallback(
    (listing: ShopListingByPlatform) => {
      const price = shiftedLeftBy(listing.listingPrice, erc20Config.WETH.decimals);
      const { priceInUsd: wethPriceInUsd } = erc20InfoMap[ERC20Symbol.WETH] || {};
      const priceInUsd = wethPriceInUsd && price ? wethPriceInUsd.times(price) : zero;

      // eslint-disable-next-line no-underscore-dangle
      if (listing.__typename === 'Listing') {
        return {
          orderHash: listing.orderHash,
          currency: listing.currency,
          listingTime: listing.listingTime,
          expirationTime: listing.expirationTime,
          price,
          priceInUsd,
          platform: Marketplace.ParaSpace,
          protocolVersion: listing.protocolVersion!,
          protocolContract: listing.protocolContract!,
          protocolData: listing.protocolData!
        } as ListingFromParaSpace;
      }

      return {
        orderHash: listing.orderHash,
        currency: listing.currency,
        listingTime: listing.listingTime,
        expirationTime: listing.expirationTime,
        platform: listing.platform,
        price,
        priceInUsd
      } as ListingFromReservoir;
    },
    [erc20Config.WETH.decimals, erc20InfoMap]
  );

  const enrichCreditData = useCallback(
    (creditData?: Maybe<CreditData>) => {
      if (creditData) {
        const symbol = getSymbolByContractAddress(creditData.token) as ERC20Symbol;
        const amount = shiftedLeftBy(creditData.amount, erc20Config[symbol].decimals);
        const { priceInUsd: priceInUsdForOne } = erc20InfoMap[symbol] || {};
        return {
          symbol,
          amount,
          priceInUsd: priceInUsdForOne?.times(amount) ?? zero,
          contractAddress: creditData.token
        };
      }
      return null;
    },
    [erc20Config, erc20InfoMap, getSymbolByContractAddress]
  );

  const formatOrder = useCallback(
    (order: ShopBidByPlatform): Offer => {
      // eslint-disable-next-line no-underscore-dangle
      if (order.__typename === 'Order') {
        const {
          considerationItems,
          offerItems,
          expirationTime,
          maker,
          platform,
          creditData,
          to,
          target
        } = order;
        const offerSymbol = getSymbolByContractAddress(offerItems[0].token!) as ERC20Symbol;
        const offerAmount = shiftedLeftBy(
          offerItems[0].startAmount ?? '0',
          erc20Config[offerSymbol].decimals
        );
        const { priceInUsd: offerSymbolPriceInUsd } = erc20InfoMap[offerSymbol] || {};
        const offerPriceInUsd = offerSymbolPriceInUsd?.times(offerAmount) ?? zero;

        const nftSymbol = getSymbolByContractAddress(considerationItems[0].token!) as ERC721Symbol;
        const nftTokenId =
          target === OrderTarget.Token ? Number(considerationItems[0].identifierOrCriteria) : null;
        const { collectionName } = erc721Config[nftSymbol] || {};
        const nftName = isNil(nftTokenId) ? collectionName : `${collectionName}#${nftTokenId}`;

        const collection = collections.find(
          item => item.contractAddress === considerationItems[0].token
        );
        const { paraSpaceFeePoint, creatorFeePoint } = collection?.fees || ({} as CollectionFees);

        return {
          order: order as Order,
          considerationItem: {
            symbol: nftSymbol,
            contractAddress: considerationItems[0].token!,
            tokenId: nftTokenId,
            name: nftName,
            floorPrice: nftInfoMap[nftSymbol]?.priceInETH ?? zero,
            floorPriceInUsd: nftInfoMap[nftSymbol]?.priceInUsd ?? zero
          },
          orderItem: {
            priceInUsd: offerPriceInUsd,
            amount: offerAmount,
            symbol: offerSymbol,
            contractAddress: offerItems[0].token!
          },
          creditData: enrichCreditData(creditData),
          expirationTime,
          platform,
          maker,
          to,
          creatorFeePoint: shiftedLeftBy(creatorFeePoint, FEE_POINT_DECIMAL),
          platformFeePoint: shiftedLeftBy(paraSpaceFeePoint, FEE_POINT_DECIMAL),
          platformFee: shiftedLeftBy(
            offerAmount.multipliedBy(paraSpaceFeePoint || 0),
            FEE_POINT_DECIMAL
          )
        };
      }
      const {
        identifierOrCriteria,
        contractAddress,
        currency,
        offerValue,
        expirationTime,
        maker,
        platform,
        feeBps
      } = order as ReservoirBid;

      const nftSymbol = getSymbolByContractAddress(contractAddress!) as ERC721Symbol;
      const { collectionName } = erc721Config[nftSymbol] || {};
      const { priceInUsd, address, decimals } = erc20InfoMap[currency] || {};
      const amount = shiftedLeftBy(offerValue!, decimals);
      const offerPriceInUsd = priceInUsd?.times(amount!) ?? zero;
      const nftTokenId = identifierOrCriteria ? Number(identifierOrCriteria) : null;
      const nftName = nftTokenId ? collectionName : `${collectionName}#${nftTokenId}`;
      const collection = collections.find(item => item.contractAddress === contractAddress);
      const { creatorFeePoint } = collection?.fees || ({} as CollectionFees);
      return {
        order: order as ReservoirBid,
        considerationItem: {
          symbol: nftSymbol,
          contractAddress,
          tokenId: nftTokenId,
          name: nftName,
          floorPrice: nftInfoMap[nftSymbol]?.priceInETH ?? zero,
          floorPriceInUsd: nftInfoMap[nftSymbol]?.priceInUsd ?? zero
        },
        orderItem: {
          priceInUsd: offerPriceInUsd,
          amount,
          symbol: currency as ERC20Symbol,
          contractAddress: address
        },
        creditData: null,
        expirationTime,
        platform,
        maker,
        creatorFeePoint: shiftedLeftBy(creatorFeePoint, FEE_POINT_DECIMAL),
        platformFeePoint: shiftedLeftBy(feeBps || 0, FEE_POINT_DECIMAL),
        platformFee: shiftedLeftBy(amount.multipliedBy(feeBps || 0), FEE_POINT_DECIMAL)
      };
    },
    [
      collections,
      enrichCreditData,
      erc20Config,
      erc20InfoMap,
      erc721Config,
      getSymbolByContractAddress,
      nftInfoMap
    ]
  );

  return {
    formatListing,
    formatOrder
  };
};
