import { FC, HTMLAttributes, memo, useCallback } from 'react';
import styled from 'styled-components';

import { ShopItemOnListingPage } from '../types';

import { AssetCard } from './AssetCard';

const gridGap = '0.5rem';

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(14rem, calc(25% - ${gridGap} * 0.75)), 1fr));
  grid-gap: ${gridGap};
  ${({ theme }) => theme.breakpoints.only('mobile')`
    grid-template-columns: repeat(2, 1fr);
  `}

  & > * {
    min-width: 0;
  }
`;

type Props = {
  assets: ShopItemOnListingPage[];
  onEnterDetail: () => void;
  onSelectAssets: (assets: ShopItemOnListingPage[]) => void;
};
export const GridItemsExplore: FC<Props & Omit<HTMLAttributes<HTMLDivElement>, 'children'>> = memo(
  ({ assets, onEnterDetail, onSelectAssets: onSelectAsset, ...others }) => {
    const handleSelectAsset = useCallback(
      (asset: ShopItemOnListingPage) => {
        onSelectAsset([asset]);
      },
      [onSelectAsset]
    );
    return (
      <Grid {...others}>
        {assets.map(asset => (
          <AssetCard
            key={asset.contractAddress! + asset.tokenId}
            asset={asset}
            onEnterDetail={onEnterDetail}
            onSelectAsset={handleSelectAsset}
          />
        ))}
      </Grid>
    );
  }
);
