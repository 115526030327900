import { BigNumber } from 'bignumber.js';
import { isEmpty } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { ClaimTimelockModal } from '../Modal/ClaimTimelockModal';

import TimelockQueueTable from './TimelockQueueTable';
import { useTimelockData, TimelockQueueRow } from './hooks';
import { TimelockQueueState } from './util';

import { useWeb3Context } from '@/apps/paraspace/contexts';

const TimelockQueue = () => {
  const { account } = useWeb3Context();
  const { timelockData, refetch } = useTimelockData(account);

  const locked = useMemo(
    () =>
      new BigNumber(
        timelockData?.filter(item => item.state !== TimelockQueueState.RELEASED).length ?? 0
      ),
    [timelockData]
  );
  const released = useMemo(
    () =>
      new BigNumber(
        timelockData?.filter(item => item.state === TimelockQueueState.RELEASED).length ?? 0
      ),
    [timelockData]
  );

  const [open, setOpen] = useState(false);
  const [timelockItem, setTimelockItem] = useState<TimelockQueueRow | null>(null);

  const [claimingAgreements, setClaimingAgreements] = useState<number[]>([]);

  const timelockDataWithClaimingStatus = useMemo(
    () =>
      timelockData?.map(it => {
        if (claimingAgreements.includes(it.agreementId)) {
          return {
            ...it,
            claiming: true
          };
        }
        return it;
      }),
    [timelockData, claimingAgreements]
  );

  const closeModal = useCallback(() => setOpen(false), []);
  const claimTimelock = useCallback((data: TimelockQueueRow) => {
    setClaimingAgreements(curr => curr.concat(data.agreementId));
    setTimelockItem(data);
    setOpen(true);
  }, []);

  const handleClaimingSuccess = useCallback(
    (data: TimelockQueueRow) => {
      setClaimingAgreements(curr => curr.filter(it => it !== data.agreementId));
      refetch();
    },
    [refetch]
  );

  const handleClaimingFailed = useCallback((data: TimelockQueueRow) => {
    setClaimingAgreements(curr => curr.filter(it => it !== data.agreementId));
  }, []);

  const showTimelockTable = useMemo(
    () => !isEmpty(timelockDataWithClaimingStatus) && account,
    [account, timelockDataWithClaimingStatus]
  );

  return (
    <>
      {showTimelockTable && (
        <TimelockQueueTable
          claimHandle={claimTimelock}
          queue={timelockDataWithClaimingStatus ?? []}
          locked={locked}
          released={released}
        />
      )}
      <ClaimTimelockModal
        key={timelockItem?.agreementId}
        timelockItem={timelockItem!}
        isOpen={open}
        onError={handleClaimingFailed}
        onSuccess={handleClaimingSuccess}
        onClose={closeModal}
      />
    </>
  );
};

export default TimelockQueue;
