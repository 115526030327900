import { useMemo } from 'react';
import { useMount } from 'react-use';

import { BendDaoNft } from '../types';
import { LoanState, useBendDaoPosition } from '../../../BendDaoPositionProvider';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { emptyArray, zero } from '@/apps/paraspace/consts/values';
import useSuppliedAssets from '@/apps/paraspace/pages/hooks/useLendingSimulation/useSuppliedAssets';
import {
  ActionTypeEnum,
  calculateLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

export const useBendDaoNfts = (symbol: ERC721Symbol) => {
  const { nftInfoMap, basicInfoLoaded, erc20InfoMap, overviewUserInfo, userInfoLoaded } =
    useMMProvider();
  const assets = useSuppliedAssets();

  const { infoMap, loaded, refresh } = useBendDaoPosition();
  const bendDaoNfts = infoMap[symbol] ?? emptyArray;

  useMount(() => {
    refresh();
  });

  const {
    priceInUsd = zero,
    priceInETH = zero,
    baseLTVasCollateral = zero,
    reserveLiquidationThreshold
  } = nftInfoMap[symbol] ?? {};

  const enrichedBendDaoNfts = useMemo(() => {
    if (!basicInfoLoaded || !userInfoLoaded) {
      return [];
    }

    return bendDaoNfts
      .filter(it => it.state === LoanState.Active)
      .map(it => {
        const { decimals = 0 } = erc20InfoMap[it.debtSymbol] ?? {};
        const debt = it.debt.shiftedBy(-decimals);
        const { borrowLimitInUsd, totalBorrowedPositionInUsd } = calculateLendingSimulation(
          [
            {
              type: ActionTypeEnum.SUPPLY,
              targetAsset: {
                id: String(it.tokenId),
                value: priceInUsd.times(it.multiplier),
                LTV: baseLTVasCollateral,
                reserveLiquidationThreshold
              }
            },
            {
              type: ActionTypeEnum.BORROW,
              targetAsset: {
                id: String(it.tokenId),
                value: debt.times(erc20InfoMap[it.debtSymbol]?.priceInUsd ?? 0),
                LTV: erc20InfoMap[it.debtSymbol]?.baseLTVasCollateral ?? 0,
                reserveLiquidationThreshold:
                  erc20InfoMap[it.debtSymbol]?.reserveLiquidationThreshold ?? 0
              }
            }
          ],
          assets,
          overviewUserInfo,
          userInfoLoaded
        );
        const debtInUsd = debt.times(erc20InfoMap[it.debtSymbol]?.priceInUsd ?? 0);
        const estimatedCreditInUsd = priceInUsd.times(baseLTVasCollateral).times(it.multiplier);
        return {
          ...it,
          debt,
          debtInUsd,
          priceInUsd,
          priceInETH,
          ltv: baseLTVasCollateral,
          reserveLiquidationThreshold,
          estimatedCreditInUsd,
          newBorrowedInUsd: totalBorrowedPositionInUsd,
          newCreditInUsd: borrowLimitInUsd,
          disabled: borrowLimitInUsd?.lt(totalBorrowedPositionInUsd)
        } as BendDaoNft;
      });
  }, [
    basicInfoLoaded,
    userInfoLoaded,
    bendDaoNfts,
    assets,
    overviewUserInfo,
    erc20InfoMap,
    priceInUsd,
    priceInETH,
    baseLTVasCollateral,
    reserveLiquidationThreshold
  ]);

  return {
    loading: !loaded || !basicInfoLoaded,
    refresh,
    bendDaoNfts: enrichedBendDaoNfts
  };
};
