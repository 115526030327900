import { InfoPanel, TokenInput } from '@parallel-mono/business-components';
import { Button, Inline, Modal, Stack, StackProps, Text } from '@parallel-mono/components';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { floor, isNil } from 'lodash';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';

import { WithdrawModalContent } from './WithdrawModalContent';

import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import {
  HEALTHY_FACTOR_WITH_CUSHION_FOR_WITHDRAW,
  MAXIMUM_BALANCE_DECIMALS
} from '@/apps/paraspace/pages/config';
import {
  Tooltip,
  NumberRange,
  TimelockTerms,
  useTimelockTermsCheck
} from '@/apps/paraspace/components';
import { formatBalance, MIN_BALANCE_THRESHOLD } from '@/apps/paraspace/utils/format';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { zero } from '@/apps/paraspace/consts/values';
import { useClaimToEOAToggle } from '@/apps/paraspace/pages/hooks';

export const Withdraw: FC<Omit<StackProps, 'children'>> = memo(({ ...others }) => {
  const [fromBalanceAmount, setFromBalanceAmount] = useState<number | null>(null);
  const [fromLendingAmount, setFromLendingAmount] = useState<number | null>(null);
  const {
    erc20InfoMap,
    load: refreshLendingInfo,
    overviewUserInfo: { totalBorrowedPositionInUsd, liquidationThresholdInUsd }
  } = useMMProvider();
  const { refresh: refreshCApeInfo } = useAutoCompoundApeInfo();
  const { shouldClaimToEOA, toggleComponent } = useClaimToEOAToggle();

  const {
    suppliedAmount: cApeSuppliedAmount = new BigNumber(0),
    usageAsCollateralEnabledOnUser,
    priceInUsd,
    reserveLiquidationThreshold,
    availableLiquidity,
    balance
  } = erc20InfoMap[ERC20Symbol.CAPE] || {};

  const {
    shouldAddToTimelockQueue,
    claimableTime,
    handleTimelockTermsCheck,
    timelockTermsChecked
  } = useTimelockTermsCheck(fromLendingAmount || 0, ERC20Symbol.CAPE);

  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

  const cApeBalance = useMemo(() => balance || new BigNumber(0), [balance]);

  const availableToWithdraw = useMemo(() => {
    // liquidiationPoint - x * threshold * priceInUsd >= borrowedUsd * 1.01
    return BigNumber.maximum(
      0,
      BigNumber.minimum(
        cApeSuppliedAmount,
        usageAsCollateralEnabledOnUser && totalBorrowedPositionInUsd.gt(0)
          ? liquidationThresholdInUsd
              .minus(totalBorrowedPositionInUsd.times(HEALTHY_FACTOR_WITH_CUSHION_FOR_WITHDRAW))
              .div(priceInUsd)
              .div(reserveLiquidationThreshold)
          : cApeSuppliedAmount,
        availableLiquidity
      )
    );
  }, [
    availableLiquidity,
    cApeSuppliedAmount,
    liquidationThresholdInUsd,
    priceInUsd,
    reserveLiquidationThreshold,
    totalBorrowedPositionInUsd,
    usageAsCollateralEnabledOnUser
  ]);

  const balanceInputErrorMessage = useMemo(() => {
    if (fromBalanceAmount && fromBalanceAmount > (cApeBalance?.toNumber() || 0)) {
      return 'Insufficient balance.';
    }
    return null;
  }, [cApeBalance, fromBalanceAmount]);

  const lendingInputErrorMessage = useMemo(() => {
    if (fromLendingAmount && fromLendingAmount > (cApeSuppliedAmount?.toNumber() || 0)) {
      return 'Insufficient balance.';
    }
    if (fromLendingAmount && availableToWithdraw.lt(fromLendingAmount)) {
      return 'Exceed eligible withdraw amount, please repay some debt.';
    }

    if (fromLendingAmount && availableLiquidity.lt(fromLendingAmount)) {
      return `Withdraw amount exceeds market liquidity, please enter an amount below ${formatBalance(
        availableLiquidity
      )}.`;
    }
    return null;
  }, [availableLiquidity, availableToWithdraw, cApeSuppliedAmount, fromLendingAmount]);

  const handleBalanceInputChange = (value: number | null) => {
    setFromBalanceAmount(value);
  };
  const handleBalanceInputMaxClick = () => {
    setFromBalanceAmount(cApeBalance?.decimalPlaces(4, BigNumber.ROUND_DOWN).toNumber() || null);
  };

  const handleLendingInputChange = (value: number | null) => {
    setFromLendingAmount(value);
  };
  const handleLendingInputMaxClick = () => {
    setFromLendingAmount(
      cApeSuppliedAmount?.decimalPlaces(4, BigNumber.ROUND_DOWN).toNumber() || null
    );
  };

  const receivingAmount = useMemo(
    () => (fromBalanceAmount || 0) + (fromLendingAmount || 0),
    [fromBalanceAmount, fromLendingAmount]
  );

  const confirmButtonDisabled = useMemo(
    () =>
      (!fromBalanceAmount && !fromLendingAmount) ||
      Boolean(balanceInputErrorMessage) ||
      Boolean(lendingInputErrorMessage) ||
      (shouldAddToTimelockQueue && !timelockTermsChecked),
    [
      balanceInputErrorMessage,
      fromBalanceAmount,
      fromLendingAmount,
      lendingInputErrorMessage,
      shouldAddToTimelockQueue,
      timelockTermsChecked
    ]
  );

  const panelInfos = [
    {
      title: (
        <Inline gap="0.25rem">
          <Text skin="secondary">You Will Receive</Text>
          <Tooltip content="This is the amount of $APE you will get for your $cAPE." />
        </Inline>
      ),
      value: `${formatNumber(receivingAmount)} APE`
    },
    {
      title: 'Total Staked',
      value: (
        <NumberRange
          start={cApeBalance.plus(cApeSuppliedAmount)?.toNumber() ?? 0}
          end={cApeBalance.plus(cApeSuppliedAmount)?.minus(receivingAmount).toNumber() ?? 0}
          formatter={formatNumber}
          symbol="APE"
        />
      )
    }
  ];

  const resetInput = useCallback(() => {
    setFromBalanceAmount(null);
    setFromLendingAmount(null);
  }, []);

  const refresh = useCallback(async () => {
    try {
      await Promise.all([refreshCApeInfo(), refreshLendingInfo()]);
    } catch (e) {
      console.warn('Reload data failed: ', e);
    }
  }, [refreshCApeInfo, refreshLendingInfo]);

  return (
    <Stack {...others}>
      <TokenInput
        inputProps={{
          inputProps: {
            autoFocus: true
          }
        }}
        value={fromBalanceAmount && floor(fromBalanceAmount, 4)}
        actionButtonText="Max"
        hint={
          <Text skin="secondary">
            Max:{' '}
            {formatNumber(cApeBalance ?? zero, {
              decimal: MAXIMUM_BALANCE_DECIMALS,
              threshold: { min: MIN_BALANCE_THRESHOLD }
            })}{' '}
            cAPE
          </Text>
        }
        label={<Text fontWeight="bold">From Wallet</Text>}
        token="APE"
        decimals={MAXIMUM_BALANCE_DECIMALS}
        onChange={handleBalanceInputChange}
        onAction={handleBalanceInputMaxClick}
        error={balanceInputErrorMessage}
      />
      <TokenInput
        inputProps={{
          inputProps: {
            autoFocus: true
          }
        }}
        value={fromLendingAmount && floor(fromLendingAmount, 4)}
        actionButtonText="Max"
        hint={
          <Text skin="secondary">
            Max:{' '}
            {formatNumber(cApeSuppliedAmount ?? zero, {
              decimal: MAXIMUM_BALANCE_DECIMALS,
              threshold: { min: MIN_BALANCE_THRESHOLD }
            })}{' '}
            cAPE
          </Text>
        }
        label={<Text fontWeight="bold">From Lending</Text>}
        token="APE"
        decimals={MAXIMUM_BALANCE_DECIMALS}
        onChange={handleLendingInputChange}
        onAction={handleLendingInputMaxClick}
        error={lendingInputErrorMessage}
      />
      <InfoPanel skin="primary" infos={panelInfos} />
      {shouldAddToTimelockQueue && !isNil(fromLendingAmount) && (
        <TimelockTerms
          claimableTime={claimableTime}
          onTermsCheck={handleTimelockTermsCheck}
          checked={timelockTermsChecked}
        />
      )}
      {!shouldAddToTimelockQueue && (
        <Inline justifyContent="space-between">{toggleComponent}</Inline>
      )}
      <Button
        size="large"
        onClick={() => setIsWithdrawModalOpen(true)}
        block
        disabled={confirmButtonDisabled}
      >
        Confirm
      </Button>

      <Modal
        isOpen={isWithdrawModalOpen}
        {...others}
        closeOnBackdropClick={false}
        onClose={() => setIsWithdrawModalOpen(false)}
        title="Withdraw"
      >
        <WithdrawModalContent
          data={{
            lendingAmount: fromLendingAmount ?? 0,
            balanceAmount: fromBalanceAmount ?? 0,
            shouldClaimToEOA
          }}
          onClose={() => setIsWithdrawModalOpen(false)}
          refresh={refresh}
          reset={resetInput}
        />
      </Modal>
    </Stack>
  );
});
