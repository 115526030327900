import {
  Modal,
  ModalProps,
  Text,
  Stack,
  Card,
  CardProps,
  Inline,
  H5,
  Tag,
  Responsive,
  useBreakpoints,
  Pagination
} from '@parallel-mono/components';
import styled from 'styled-components';
import { map } from 'lodash';

import { NFT_TRAIT_BOOST_FOR_NETWORK } from './nftTraitBoostMap';

import { NFTCollectionThumbnail } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { usePagination } from '@/apps/paraspace/hooks/usePagination';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type TraitBoostViewModalProps = {
  onClose: () => void;
} & Omit<ModalProps, 'children'>;

type ItemBoostCardProps = {
  symbol: ERC721Symbol;
  list: { label: string; value: number }[];
} & Omit<CardProps, 'children'>;

const Container = styled.div`
  column-count: 2;
  column-gap: 0.75rem;
  ${({ theme }) => theme.breakpoints.down('tablet')`
    column-count: 1;
  `};
`;
const StyledCard = styled(Card)`
  break-inside: avoid;
  margin-bottom: 0.75rem;
`;

const StyledTag = styled(Tag)`
  white-space: nowrap;
`;

const ItemBoostCard = ({ symbol, list, ...other }: ItemBoostCardProps) => {
  const { mobile } = useBreakpoints();
  const { pageData, totalPage, currentPage, setCurrentPage } = usePagination(list, 5);

  const { erc721Config } = useAppConfig();
  const collectionName = erc721Config[symbol]?.collectionName ?? '';

  return (
    <StyledCard border {...other}>
      <Stack gap="1rem">
        <Inline gap="0.5rem" alignItems="center">
          <NFTCollectionThumbnail symbol={symbol} size="small" round />
          <H5>{collectionName}</H5>
        </Inline>
        <Stack gap="1rem">
          {pageData.map(({ label, value }) => (
            <Responsive
              key={label}
              justifyContent={mobile ? 'flex-start' : 'space-between'}
              alignItems="flex-start"
              breakPoint="tablet"
            >
              <Text skin="secondary">{label}</Text>
              <StyledTag skin="success" size="small">
                {value}x Boost
              </StyledTag>
            </Responsive>
          ))}
        </Stack>
        {totalPage > 1 && (
          <Pagination
            total={totalPage}
            page={currentPage}
            onChange={setCurrentPage}
            siblingCount={0}
            startBoundaryCount={3}
          />
        )}
      </Stack>
    </StyledCard>
  );
};

export const TraitBoostViewModal = ({ onClose, ...other }: TraitBoostViewModalProps) => {
  const { chainId } = useWeb3Context();
  const nftTraitBoost = NFT_TRAIT_BOOST_FOR_NETWORK[chainId];
  return (
    <Modal size="45rem" title="Rarity Boost" onClose={onClose} {...other}>
      <Stack>
        <Stack>
          <Text skin="secondary">
            NFTs with rare traits can get higher collateral value than the floor.
          </Text>
          <Text skin="secondary">
            If an NFT has more than 1 rarity traits, only the one with higher boost value is
            counted.
          </Text>
        </Stack>
        <Container>
          {map(nftTraitBoost || {}, (value, symbol) => (
            <ItemBoostCard key={symbol} symbol={symbol as ERC721Symbol} list={value} />
          ))}
        </Container>
      </Stack>
    </Modal>
  );
};
