import { ERC721Symbol, SupportedNetworks } from '@/apps/paraspace/typings';
import { Network } from '@/apps/paraspace/config';

const ethereumCollections = [
  ERC721Symbol.BAYC,
  ERC721Symbol.CRYPTOPUNKS,
  ERC721Symbol.MAYC,
  ERC721Symbol.BAKC,
  ERC721Symbol.AZUKI,
  ERC721Symbol.DOODLE,
  ERC721Symbol.MOONBIRD,
  ERC721Symbol.MEEBITS,
  ERC721Symbol.OTHERDEED,
  ERC721Symbol.CLONEX,
  ERC721Symbol.UNISWAP_LP,
  ERC721Symbol.PPG,
  ERC721Symbol.SEWER,
  ERC721Symbol.SF_VLDR,
  ERC721Symbol.BEANZ,
  ERC721Symbol.VSL,
  ERC721Symbol.EXP,
  ERC721Symbol.KODA,
  ERC721Symbol.BLOCKS,
  ERC721Symbol.HV_MTL,
  ERC721Symbol.ELEM
];

export const LIQUIDATION_SUPPORTED_COLLECTIONS = {
  [Network.SEPOLIA]: ethereumCollections,
  [Network.MAINNET]: ethereumCollections
} as Record<SupportedNetworks, [ERC721Symbol]>;
