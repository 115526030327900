import { useMemo, useCallback } from 'react';
import { UiPoolDataProvider } from 'paraspace-utilities-contract-helpers';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

export const useNftDelegationQuery = () => {
  const { provider, chainId } = useWeb3Context();

  const contracts = useContractsMap();

  const uiPoolData = useMemo(
    () =>
      new UiPoolDataProvider({
        uiPoolDataProviderAddress: contracts.UiPoolDataProvider,
        provider,
        chainId
      }),
    [provider, chainId, contracts.UiPoolDataProvider]
  );

  const getDategatesForTokens = useCallback(
    (
      params: {
        nTokenAddr: string;
        tokenIds: number[];
      }[]
    ) => {
      return uiPoolData.getDategatesForTokensInBatch(
        params.map(it => ({
          nTokenAddr: it.nTokenAddr,
          tokenIds: it.tokenIds.map(id => String(id))
        }))
      );
    },
    [uiPoolData]
  );

  return { getDategatesForTokens };
};
