import { Responsive, Stack, StackProps } from '@parallel-mono/components';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CreateApeListing from './component/CreateApeListing';
import { ListingSelector, SelectedValue } from './component';
import CreateApeCoinListing from './component/CreateApeCoinListing';

import { GoBack } from '@/apps/paraspace/components';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';

type Props = Omit<StackProps, 'children'>;

const SharePoolCreateContainer = styled(Responsive)`
  position: relative;
`;

const SharePoolCreateMain = styled(Stack)`
  width: 30rem;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
  `};
`;

type State = Omit<SelectedValue, 'poolAPY'>;

export const SharePoolCreate: FC<Props> = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  // TODO: add default value
  const { state } = location;
  const { account } = useWeb3Context();

  const [selectorValue, setSelectorValue] = useState<SelectedValue>(
    (state as SelectedValue) ?? {
      walletType: 'AA',
      p2pPool: ERC721Symbol.BAYC,
      p2pToken: ERC721Symbol.BAYC,
      poolAPY: zero
    }
  );

  // without location state, let's back to listing page
  useEffect(() => {
    if (!(state as State)?.p2pToken) {
      navigate(absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS.index, { replace: true });
    }
  }, [navigate, state]);

  const handleChange = useCallback(res => {
    setSelectorValue(res);
  }, []);

  return (
    <SharePoolCreateContainer width="100%" alignItems="center" justifyContent="center">
      <GoBack />
      <SharePoolCreateMain key={account}>
        <ListingSelector defaultValue={selectorValue} onHandleChange={handleChange} />
        {selectorValue.p2pToken === ERC20Symbol.APE && (
          <CreateApeCoinListing selectorValue={selectorValue} />
        )}
        {selectorValue.p2pToken !== ERC20Symbol.APE && (
          <CreateApeListing selectorValue={selectorValue} />
        )}
      </SharePoolCreateMain>
    </SharePoolCreateContainer>
  );
});
