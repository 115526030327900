import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';

import { useValidApps } from '../hooks';

export const NavigateToDefaultApp = memo(() => {
  const navigate = useNavigate();
  const { internalApps } = useValidApps();
  useMount(() => {
    const defaultApp = internalApps.find(it => it.default) ?? internalApps[0];
    if (defaultApp) {
      navigate(`./${defaultApp.route}`, { replace: true });
    }
  });

  return null;
});
