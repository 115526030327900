import { H6, Inline, Stack, Text, H5 } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import { FC, memo } from 'react';
import styled from 'styled-components';

import { BreakDownData } from '.';

import { Tooltip } from '@/apps/paraspace/components';

const StyledBreakDownItem = styled(Tooltip)<{
  color: string;
  shadowColor: string;
  percent: number;
}>`
  background-color: ${props => props.color};
  height: 0.5rem;
  flex: ${props => (props.percent < 0.01 ? '0 0 1%' : props.percent)};
  cursor: pointer;
  &:hover {
    z-index: 9999;
    box-shadow: 0 0 0 0.25rem ${props => props.shadowColor};
  }
`;

export const BreakDownItem: FC<BreakDownData & { percent: number }> = memo(
  ({ percent, toolTipName, fieldName, color, shadowColor, staked }) => {
    return (
      <StyledBreakDownItem
        placement="top"
        arrow
        percent={percent}
        color={color}
        content={
          <Stack width="12rem" gap="1rem" alignItems="flex-start">
            <H6>ParaSpace {toolTipName}</H6>
            <Inline width="100%" gap="1rem" justifyContent="space-between">
              <Text skin="secondary">{fieldName}</Text>
              <Stack gap="0.25rem" alignItems="flex-end">
                <Inline gap="0.25rem">
                  <H5>{formatNumber(staked.toNumber(), { decimal: 0 })}</H5>
                  <H5 skin="secondary">APE</H5>
                </Inline>
                <Text skin="secondary">
                  {formatNumber(percent, { output: 'percent', decimal: 1 })}
                </Text>
              </Stack>
            </Inline>
          </Stack>
        }
        shadowColor={shadowColor}
      >
        <div />
      </StyledBreakDownItem>
    );
  }
);
