import { HostedImage } from '@parallel-mono/business-components';
import {
  Button,
  H5,
  H6,
  Icon,
  Inline,
  SmallText,
  Stack,
  StackProps
} from '@parallel-mono/components';
import { floor, isEmpty, isNil } from 'lodash';
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled, { useTheme } from 'styled-components';
import { TokenOption } from '@parallel-mono/business-components/components/SelectableTokenInput/types';
import { ChainId } from '@parallel-utils/contracts-registry';

import { ImageWrapper, StyledCard, StyledSelectableTokenInput } from '../StyledComponents';
import { parallelChain, supportedBridgeChains } from '../../configs/networks';
import { InfosPanel } from '../InfosPanel';
import { NetworkPopup } from '../NetworkPopup';
import { useNetworkFee } from '../../hooks';
import { useNetworkProvider } from '../../hooks/useNetworkProvider';
import { useBridgeTokenImpl } from '../../contexts';
import { getDisplayBalance } from '../../helper';
import { DEFAULT_WITHDRAWAL_RESERVED_GAS } from '../../configs';

import { useNetworkHandler, useValidation } from './hooks';

import { useEOAProvider, useParaXToast } from '@/apps/parax/contexts';
import { Maybe } from '@/apps/parax/typings/basic';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/parax/utils';
import { zero } from '@/apps/parax/consts';

const MaxText = styled(SmallText)<{ disabled: boolean }>`
  opacity: ${({ theme, disabled }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
`;

/* eslint max-lines-per-function: ["error", { "max": 500 }] */
export const Withdraw: FC<Omit<StackProps, 'children'>> = memo(() => {
  const { account, chainId, switchNetwork } = useEOAProvider();
  const [inputtedAddress, setInputtedAddress] = useState<string>();

  const [amount, setAmount] = useState<Maybe<number>>(null);
  const [withdrawing, setWithdrawing] = useState(false);

  const [selectedTokenSymbol, setSelectedTokenSymbol] = useState<Maybe<string>>(null);

  const {
    nativeTokenBalance,
    isLoadingLiquidity,
    selectedNetwork,
    setSelectedNetwork,
    networksLiquidity,
    supportedNetworks,
    tokens: { supportedTokens, refetchSupportedTokens }
  } = useNetworkHandler(selectedTokenSymbol);

  const supportedTokenOptions = useMemo(() => {
    return supportedTokens
      .map(
        v =>
          ({
            name: v.symbol as string,
            symbol: v.symbol as string,
            balance: v.balance?.toNumber(),
            priceInUSD: v.priceInUsd,
            displayBalance: getDisplayBalance(v.balanceStatus, v.balance ?? zero)
          } as TokenOption)
      )
      .filter(v => v.balance);
  }, [supportedTokens]);

  const selectedToken = useMemo(
    () => supportedTokenOptions.find(v => v.symbol === selectedTokenSymbol) ?? null,
    [selectedTokenSymbol, supportedTokenOptions]
  );

  const handleSelectTokenChange = useCallback(option => {
    setAmount(option.amount);
    if (option.token) {
      setSelectedTokenSymbol(option.token.symbol);
    }
  }, []);

  const handleMaxClick = useCallback(() => {
    if (isNil(selectedNetwork) || !selectedToken?.balance) {
      setAmount(0);
      return;
    }
    if (selectedToken.symbol === 'ETH') {
      setAmount(
        Number(
          BigNumber(selectedToken?.balance ?? 0)
            .minus(DEFAULT_WITHDRAWAL_RESERVED_GAS)
            .toFixed(MAXIMUM_BALANCE_DECIMALS, BigNumber.ROUND_FLOOR)
        )
      );
      return;
    }
    const maxAmount = BigNumber.min(
      selectedToken?.balance ?? zero,
      networksLiquidity?.[selectedNetwork] ?? zero
    ).toNumber();
    setAmount(floor(maxAmount, MAXIMUM_BALANCE_DECIMALS));
  }, [networksLiquidity, selectedNetwork, selectedToken]);

  const selectedNetworkOption = useMemo(
    () => supportedBridgeChains.find(v => v.chainId === selectedNetwork),
    [selectedNetwork]
  );

  useEffect(() => {
    setAmount(prev => {
      if (selectedNetwork && BigNumber(prev ?? 0).gt(networksLiquidity?.[selectedNetwork] ?? 0)) {
        return null;
      }
      return prev;
    });
  }, [networksLiquidity, selectedNetwork]);

  const toAddress = useMemo(() => inputtedAddress ?? account, [account, inputtedAddress]);
  const provider = useNetworkProvider(parallelChain.chainId);
  const { networkFee, isLoading: isNetworkFeeLoading } = useNetworkFee(
    {
      symbol: selectedTokenSymbol ?? '',
      amount,
      eid: selectedNetworkOption?.eId!,
      to: toAddress
    },
    provider,
    parallelChain.chainId
  );

  const { bridgeERC20Token, withdrawNativeToken } = useBridgeTokenImpl();
  const toast = useParaXToast();

  const bridgeERC20TokenRef = useRef(bridgeERC20Token);
  const withdrawNativeTokenRef = useRef(withdrawNativeToken);
  withdrawNativeTokenRef.current = withdrawNativeToken;
  bridgeERC20TokenRef.current = bridgeERC20Token;

  const handleWithdraw = useCallback(async () => {
    const connectionId = chainId as number;
    if (connectionId !== parallelChain.chainId) {
      await switchNetwork(parallelChain.chainId as number);
    }
    try {
      setWithdrawing(true);
      if (selectedToken?.symbol === 'ETH') {
        await toast.promise(withdrawNativeTokenRef.current(BigNumber(amount!), toAddress));
      } else {
        await bridgeERC20TokenRef.current({
          amount: new BigNumber(amount!),
          receiver: toAddress,
          symbol: selectedToken?.symbol!,
          toChainEid: selectedNetworkOption?.eId!
        });
      }
    } finally {
      setAmount(null);
      setWithdrawing(false);
      refetchSupportedTokens();
    }
  }, [
    amount,
    chainId,
    refetchSupportedTokens,
    selectedNetworkOption?.eId,
    selectedToken?.symbol,
    switchNetwork,
    toAddress,
    toast
  ]);

  const theme = useTheme();

  const isInsufficientLiquidity = useMemo(() => {
    if (selectedTokenSymbol === 'ETH') {
      return false;
    }
    return Boolean(
      (selectedNetwork && networksLiquidity?.[selectedNetwork]?.lt(BigNumber(amount ?? 0))) ||
        (selectedTokenSymbol && isNil(networksLiquidity))
    );
  }, [amount, networksLiquidity, selectedNetwork, selectedTokenSymbol]);

  const isInsufficientNativeToken = useMemo(() => {
    if (selectedToken?.symbol === 'ETH') {
      return BigNumber(nativeTokenBalance ?? 0)
        .minus(amount ?? 0)
        .lt(networkFee ?? DEFAULT_WITHDRAWAL_RESERVED_GAS);
    }
    return nativeTokenBalance.lt(networkFee ?? zero);
  }, [amount, nativeTokenBalance, networkFee, selectedToken]);

  const errorMessage = useValidation({ amount, token: selectedToken ?? null });

  const buttonText = useMemo(() => {
    if (isEmpty(supportedTokenOptions)) {
      return 'No withdrawable token';
    }
    if (isInsufficientLiquidity) {
      return `Insufficient ${selectedToken?.symbol} liquidity`;
    }
    if (errorMessage) {
      return errorMessage;
    }
    if ((chainId as number) !== parallelChain.chainId) {
      return `Connect to ${parallelChain.symbol} to Withdraw`;
    }
    if (isInsufficientNativeToken) {
      return `Insufficient ${parallelChain.nativeCurrency.symbol} balance to pay network fee`;
    }

    return 'Withdraw';
  }, [
    chainId,
    errorMessage,
    isInsufficientLiquidity,
    isInsufficientNativeToken,
    selectedToken?.symbol,
    supportedTokenOptions
  ]);

  return (
    <Stack>
      <Stack gap="0.5rem">
        <H5>Select Asset</H5>
        <StyledSelectableTokenInput
          value={{ token: selectedToken, amount }}
          defaultValue={{ token: supportedTokenOptions[0], amount: null }}
          tokens={supportedTokenOptions}
          decimals={MAXIMUM_BALANCE_DECIMALS}
          onChange={handleSelectTokenChange}
          onActionButtonClicked={handleMaxClick}
          hasError={Boolean(errorMessage)}
          actionButtonText={
            selectedToken ? <MaxText disabled={isLoadingLiquidity}>Max</MaxText> : ''
          }
        />
      </Stack>
      <StyledCard inset="0" border shadow="none">
        <Inline gap="0" alignItems="center" justifyContent="space-between">
          <Inline width="160px" justifyContent="start" gap="0.75rem" alignItems="center">
            <ImageWrapper alignItems="center" justifyContent="space-between" inset="0.25rem">
              <HostedImage
                height="1.5rem"
                width="1.5rem"
                name="design/PDS_V3/logo/parallel-v2-logo"
              />
            </ImageWrapper>
            <Stack gap="0">
              <SmallText style={{ textAlign: 'left' }} skin="secondary" fontWeight="bold">
                From
              </SmallText>
              <H6>Parallel</H6>
            </Stack>
          </Inline>
          <Icon name="arrowRight" color={theme.skin.text.sub1} strokeWidth="2" />
          <Inline width="160px" justifyContent="flex-end" gap="0.75rem" alignItems="center">
            {isNil(selectedNetwork) || isNil(selectedToken) ? (
              <SmallText style={{ textAlign: 'center' }} skin="secondary">
                Please select a withdrawable token first
              </SmallText>
            ) : (
              <NetworkPopup
                title="To"
                activeNetwork={selectedNetwork}
                activeNetworkName={selectedNetworkOption?.name}
                activeNetworkSymbol={selectedNetworkOption?.symbol}
                onNetworkChange={option => setSelectedNetwork(option.chainId)}
                networkOptions={supportedNetworks}
              />
            )}
          </Inline>
        </Inline>
      </StyledCard>
      <InfosPanel
        network={ChainId.Parallel}
        networkFee={networkFee}
        selectedToken={selectedToken}
        address={inputtedAddress ?? account}
        amount={amount ?? 0}
        onAddressChange={value => setInputtedAddress(value)}
      />
      <Button
        loading={withdrawing || isNetworkFeeLoading}
        size="large"
        disabled={
          isInsufficientLiquidity ||
          isInsufficientNativeToken ||
          Boolean(errorMessage) ||
          withdrawing ||
          isNetworkFeeLoading
        }
        block
        onClick={handleWithdraw}
      >
        {buttonText}
      </Button>
    </Stack>
  );
});
