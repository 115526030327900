import BigNumber from 'bignumber.js';
import { floor } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { Duration, durationList } from '@/apps/paraspace/consts/duration';

export const useMakeOfferInput = ({
  walletBalance,
  futureAvailableCredit
}: {
  walletBalance: BigNumber;
  futureAvailableCredit: BigNumber;
  tokenId?: number;
}) => {
  const [borrowAmount, setBorrowAmount] = useState<number | null>(null);
  const [walletAmount, setWalletAmount] = useState<number | null>(null);
  const [selectedBidDuration, setSelectedBidDuration] = useState<Duration>(durationList[0]);

  const offerAmount = useMemo(
    () => new BigNumber(walletAmount ?? 0).plus(borrowAmount ?? 0),
    [borrowAmount, walletAmount]
  );

  const handleMaxBorrowAmount = useCallback(() => {
    setBorrowAmount(floor(futureAvailableCredit.toNumber(), MAXIMUM_BALANCE_DECIMALS));
  }, [futureAvailableCredit]);

  const handleBorrowAmountChange = useCallback(
    (value: number | null) => {
      if (value && futureAvailableCredit.lte(value)) {
        handleMaxBorrowAmount();
        return;
      }
      setBorrowAmount(value);
    },
    [futureAvailableCredit, handleMaxBorrowAmount]
  );

  const handleMaxWalletAmount = useCallback(() => {
    setWalletAmount(floor(walletBalance.toNumber(), MAXIMUM_BALANCE_DECIMALS));
  }, [walletBalance]);

  const handleWalletAmountChange = useCallback(
    (value: number | null) => {
      if (value && walletBalance.lte(value)) {
        handleMaxWalletAmount();
        return;
      }
      setWalletAmount(value);
    },
    [handleMaxWalletAmount, walletBalance]
  );

  const handleBidDurationChange = (option: Duration | null) =>
    option && setSelectedBidDuration(option);

  return {
    borrowAmount,
    walletAmount,
    offerAmount,
    handleBorrowAmountChange,
    handleMaxBorrowAmount,
    handleWalletAmountChange,
    handleMaxWalletAmount,
    selectedBidDuration,
    handleBidDurationChange
  };
};
