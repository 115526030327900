import { Button, Card, CardProps, H3, H5, Stack, Text, Toggle } from '@parallel-mono/components';
import { FC, useCallback } from 'react';
import styled from 'styled-components';

import { Notification } from '../contexts';

import { Maybe } from '@/apps/paraspace/typings/basic';

const NotificationsWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 4rem 1rem;
  grid-template-columns: repeat(2, 1fr);
`;
const StyledToggle = styled(Toggle)`
  align-items: flex-start;
  label {
    flex: 0 0 2.5rem;
  }
`;

type Props = {
  notifications: Maybe<Notification[]>;
  onNotificationsChange: (values: Notification[]) => void;
  onSave: () => Promise<void>;
  disabledSaveButton: boolean;
};

export const Notifications: FC<Props & Omit<CardProps, 'children'>> = ({
  notifications,
  onNotificationsChange,
  onSave,
  disabledSaveButton,
  ...others
}) => {
  const handleSubscriptionToggleChange = useCallback(
    id => (value: boolean) => {
      const newNotifications = notifications!.map(notification =>
        notification.id === id ? { ...notification, value } : notification
      );
      onNotificationsChange(newNotifications);
    },
    [notifications, onNotificationsChange]
  );

  return (
    <Card border {...others}>
      <Stack gap="2.5rem">
        <Stack gap="0.25rem">
          <H3>Notifications</H3>
          <Text skin="secondary">
            Notifications could be slightly delayed in event of rapid market movements.
          </Text>
        </Stack>

        <NotificationsWrapper>
          {notifications?.map(({ name, id, description, value }) => {
            return (
              <StyledToggle
                key={`${name}-${id}`}
                checked={value}
                onChange={handleSubscriptionToggleChange(id)}
                label={
                  <Stack inset="0 0 0 1rem" gap="0">
                    <H5>{name}</H5>
                    <Text skin="secondary">{description}</Text>
                  </Stack>
                }
              />
            );
          })}
        </NotificationsWrapper>
        <Button disabled={disabledSaveButton} onClick={onSave}>
          Save
        </Button>
      </Stack>
    </Card>
  );
};
