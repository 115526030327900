import { findKey, isNil } from 'lodash';
import { useCallback } from 'react';

import { useContractsMap } from './useContractsMap';
import { useAppConfig } from './useAppConfig';

import { convertToChecksumAddress } from '@/apps/paraspace/utils/convertToChecksumAddress';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { ContractName } from '@/apps/paraspace/config';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const useGetSymbolByContractAddress = () => {
  const contracts = useContractsMap();
  const { erc20Config, erc721Config } = useAppConfig();

  return useCallback(
    <T extends ERC721Symbol | ERC20Symbol>(contractAddress: string): Maybe<T> => {
      const contractName = findKey(
        contracts,
        addr => convertToChecksumAddress(addr) === convertToChecksumAddress(contractAddress)
      ) as ContractName | undefined;

      if (isNil(contractName)) return null;

      const symbol: Maybe<ERC721Symbol | ERC20Symbol> =
        (findKey(erc20Config, { contractName }) as ERC20Symbol) ??
        (findKey(erc721Config, { contractName }) as ERC721Symbol) ??
        null;

      return symbol as T;
    },
    [contracts, erc20Config, erc721Config]
  );
};
