import { H6, Inline, SmallText, Stack, TypographyVariant, Image } from '@parallel-mono/components';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@/apps/paraspace/components';
import heartHalfContained from '@/apps/paraspace/assets/heart-half-contained.svg';

export const RecommendSupply = () => {
  return (
    <Stack gap="0.5rem">
      <Inline gap="0.75rem">
        <Image width="1.5rem" height="1.5rem" src={heartHalfContained} />
        <Stack gap="0.25rem">
          <H6>Need more liquidity? Supply collaterals to increase credit!</H6>
          <SmallText skin="secondary">
            Borrow from money market and pay a portion of the NFT over a period of time. Increase
            your credit by supplying.{' '}
            <Link variant={TypographyVariant.body} fontWeight="medium" as={RouterLink} to="/">
              Supply
            </Link>
          </SmallText>
        </Stack>
      </Inline>
    </Stack>
  );
};
