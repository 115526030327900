import { memo, useCallback, useMemo } from 'react';

import { BuyCartAsset } from '../../types';

import {
  SuccessState as RawSuccessState,
  SuccessStateProps as RawSuccessStateProps,
  StackedIcons
} from '@/apps/paraspace/components';

type SuccessStateProps = Omit<RawSuccessStateProps, 'desc' | 'tip' | 'icon' | 'onAction'> & {
  assets: BuyCartAsset[];
  onClose?: () => void;
};

export const SuccessState = memo(({ assets, onClose, ...others }: SuccessStateProps) => {
  const tokensIcon = useMemo(
    () => (
      <StackedIcons
        assets={assets.map(asset => ({
          tokenId: asset.tokenId,
          symbol: asset.symbol
        }))}
      />
    ),
    [assets]
  );

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <RawSuccessState
      desc={`Successfully Purchased ${assets.length} NFT${assets.length > 1 ? 's' : ''}`}
      tip="Because you have used credit, you have created a borrow position. You can keep track of it."
      icon={tokensIcon}
      onAction={handleClose}
      actionButtonText="Done"
      {...others}
    />
  );
});
