import { FC, memo, useCallback, useMemo, useState } from 'react';

import { AcceptBlurBidSubmitter, CheckAcceptBlurBidSubmitter } from '../submitters';

import { useFormatBlurBid } from './useFormatBlurBid';

import { Stepper } from '@/apps/paraspace/components';
import { ReservoirBid } from '@/apps/paraspace/generated/graphql';

type AcceptBlurOfferProps = {
  formData: ReservoirBid;
  onError: () => void;
  onSuccess: () => void;
};

export const AcceptBlurOfferSteppers: FC<AcceptBlurOfferProps> = memo(
  ({ onError, onSuccess, formData }) => {
    const bid = useFormatBlurBid(formData);

    const [step, setStep] = useState(0);
    const handleNextStep = useCallback(() => {
      setStep(prev => prev + 1);
    }, []);

    const steps = useMemo(
      () => [
        {
          description: `Step 1 to Accept`,
          content: (
            <AcceptBlurBidSubmitter
              formData={{
                bid
              }}
              onFinish={handleNextStep}
              onError={onError}
            />
          )
        },
        {
          description: `Step 2 to Check`,
          content: (
            <CheckAcceptBlurBidSubmitter
              formData={{
                bid
              }}
              onFinish={onSuccess}
              onError={onError}
            />
          )
        }
      ],
      [bid, handleNextStep, onError, onSuccess]
    );

    return <Stepper step={step} steps={steps} />;
  }
);
