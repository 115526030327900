import { memo, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  H5,
  Inline,
  Skeleton,
  SmallText,
  Stack
} from '@parallel-mono/components';
import { InfoPanel, TokenInput } from '@parallel-mono/business-components';
import { isNil } from 'lodash';

import { BuyShopItemPayload } from '..';

import { useDebounceInterestRate, useFormHandler, useValidation } from './hooks';
import { RecommendSupply } from './RecommendSupply';
import { FormHeader } from './FormHeader';

import { BuyShopItemFormData } from '.';

import useCreditBalance from '@/apps/paraspace/pages/hooks/useCreditBalance';
import {
  Tooltip,
  useShowApeStakingWarning,
  WarningForBuyingApeStaking,
  ConnectWallet,
  BorrowPositionCard
} from '@/apps/paraspace/components';
import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { Marketplace } from '@/apps/paraspace/generated/graphql';
import { zero } from '@/apps/paraspace/consts/values';

type BuyShopItemFormProps = {
  payload: BuyShopItemPayload;
  onSubmit: (formData: BuyShopItemFormData) => void;
  onUnwrapWETHClick: () => void;
};

export const BuyShopItemForm = memo((props: BuyShopItemFormProps) => {
  const { payload, onSubmit, onUnwrapWETHClick } = props;
  const {
    collection: { symbol },
    lowestPriceListing,
    multiplier,
    tokenId
  } = payload;

  const { isUsingUserWallet } = useWeb3Context();
  const { erc20InfoMap } = useMMProvider();

  const [tickApeStakingWarning, setTickApeStakingWarning] = useState(false);

  const showApeStakingWarning = useShowApeStakingWarning([{ symbol, tokenId }]);
  const shouldButtonLoading = useMemo(
    () => lowestPriceListing.platform === Marketplace.ParaSpace && !lowestPriceListing.protocolData,
    [lowestPriceListing]
  );

  const {
    priceInUsd: ethPriceInUsd,
    balance: ethBalance,
    baseLTVasCollateral,
    availableLiquidity,
    reserveLiquidationThreshold,
    borrowApyRate
  } = erc20InfoMap[ERC20Symbol.ETH] || {};

  const {
    futureAvailableCredit,
    futureBorrowLimitInUsd,
    futureLiquidationThreshold,
    futureTotalCollateralPositionInUsd
  } = useCreditBalance({
    symbol,
    creditBasedCurrencyValueInUsd: ethPriceInUsd,
    multiplier
  });

  const {
    payAmount,
    borrowAmount,
    handlePayAmountChange,
    handleBorrowAmountChange,
    handleSetMaxBorrowAmount,
    handleSetMaxPayAmount,
    usingBalance,
    handleUsingBalance
  } = useFormHandler({
    listingPrice: lowestPriceListing.price,
    walletBalance: ethBalance ?? zero,
    futureAvailableCredit
  });

  const errorMessage = useValidation({
    payAmount,
    borrowAmount,
    walletBalance: ethBalance,
    futureAvailableCredit,
    availableLiquidity
  });

  const { isInterestRateLoading, interestRate } = useDebounceInterestRate({
    borrowAmount,
    borrowApyRate,
    disabled: !!errorMessage
  });

  const { totalBorrowedPositionInUsd: newBorrowed } = useLendingSimulation([
    {
      type: ActionTypeEnum.BORROW,
      targetAsset: {
        id: ERC20Symbol.WETH,
        value: ethPriceInUsd.times(borrowAmount ?? 0),
        LTV: baseLTVasCollateral,
        reserveLiquidationThreshold
      }
    }
  ]);

  const shouldButtonDisable = useMemo(
    () =>
      !!errorMessage ||
      !(payAmount || borrowAmount) ||
      shouldButtonLoading ||
      (showApeStakingWarning && !tickApeStakingWarning),
    [
      borrowAmount,
      errorMessage,
      payAmount,
      shouldButtonLoading,
      showApeStakingWarning,
      tickApeStakingWarning
    ]
  );

  const handleTickApeStakingWarning = useCallback(({ checked }: { checked: boolean }) => {
    setTickApeStakingWarning(checked);
  }, []);

  const summaries = useMemo(
    () => [
      {
        title: 'Interest Rate',
        value: isInterestRateLoading ? (
          <Skeleton.Button height="1.5rem" variant="round" />
        ) : (
          formatToPercentage(interestRate)
        )
      }
    ],
    [interestRate, isInterestRateLoading]
  );

  const handleBuyWithCredit = useCallback(() => {
    onSubmit({
      symbol,
      tokenId,
      payAmount,
      borrowAmount
    });
  }, [onSubmit, symbol, tokenId, payAmount, borrowAmount]);

  const buttonText = useMemo(() => {
    if (errorMessage) {
      return errorMessage;
    }
    if (shouldButtonLoading) {
      return 'Loading';
    }
    return 'Confirm';
  }, [errorMessage, shouldButtonLoading]);

  const isShowRecommendSupply = useMemo(
    () =>
      !!borrowAmount &&
      (futureAvailableCredit.lte(borrowAmount) || availableLiquidity.lte(borrowAmount)),
    [availableLiquidity, borrowAmount, futureAvailableCredit]
  );

  if (!isUsingUserWallet) {
    return (
      <>
        <FormHeader payload={payload} />
        <ConnectWallet btnTxt="Connect Wallet to Buy" />
      </>
    );
  }

  return (
    <Stack>
      <FormHeader payload={payload} />
      <TokenInput
        label={<H5> Buy With Credit </H5>}
        hint={
          <SmallText skin="secondary">
            {`Credit: ${formatBalance(futureAvailableCredit)} ETH `}
            <Tooltip
              placement="bottom"
              content="This is the amount of credit you have access to, including the available borrow amount from your existing loan positions as well as the amount you can borrow by collateralizing the NFT you are purchasing."
            />
          </SmallText>
        }
        token="ETH"
        value={borrowAmount}
        onChange={handleBorrowAmountChange}
        placeholder="0"
        decimals={MAXIMUM_BALANCE_DECIMALS}
        actionButtonText="MAX"
        onAction={handleSetMaxBorrowAmount}
        inputProps={{
          inputProps: {
            autoFocus: true
          }
        }}
      />
      <Checkbox checked={usingBalance} onChange={handleUsingBalance}>
        <H5 skin="secondary">Add from Wallet Balance</H5>
      </Checkbox>
      {usingBalance && (
        <TokenInput
          label={<H5>Wallet</H5>}
          hint={
            <SmallText skin="secondary">Balance: {formatBalance(ethBalance ?? zero)} ETH</SmallText>
          }
          token="ETH"
          value={payAmount}
          onChange={handlePayAmountChange}
          placeholder="0"
          decimals={MAXIMUM_BALANCE_DECIMALS}
          actionButtonText="MAX"
          onAction={handleSetMaxPayAmount}
        />
      )}
      {!isNil(borrowAmount) && (
        <Stack>
          <BorrowPositionCard
            borrowedValueInUsd={newBorrowed}
            borrowLimitValueInUsd={futureBorrowLimitInUsd}
            liquidationPointValueInUsd={futureLiquidationThreshold}
            totalSuppliedValueInUsd={futureTotalCollateralPositionInUsd}
          />
          <InfoPanel skin="primary" infos={summaries} />
        </Stack>
      )}
      {showApeStakingWarning && (
        <WarningForBuyingApeStaking
          checked={tickApeStakingWarning}
          onChange={handleTickApeStakingWarning}
        />
      )}
      <Inline gap="0.25rem">
        <Button
          block
          size="large"
          skin="primary"
          onClick={handleBuyWithCredit}
          disabled={shouldButtonDisable}
          loading={shouldButtonLoading}
        >
          {buttonText}
        </Button>
        <Button size="large" block onClick={onUnwrapWETHClick}>
          Unwrap wETH
        </Button>
      </Inline>
      {isShowRecommendSupply && <RecommendSupply />}
    </Stack>
  );
});
