import { useCallback, useState } from 'react';

import {
  InitiateNftDelegationModalFormTokensMap,
  InitiateNftDelegationModalProps
} from '../DelegateNftModal';

import { ERC721Symbol } from '@/apps/paraspace/typings';

const defaultModalProps: InitiateNftDelegationModalProps = {
  tokensMap: {},
  isOpen: false
};

export const useInitiateNftDelegation = (): [
  InitiateNftDelegationModalProps,
  (tokens: InitiateNftDelegationModalFormTokensMap) => Promise<void>
] => {
  const [modalProps, setModalProps] = useState<InitiateNftDelegationModalProps>(defaultModalProps);

  const closeModal = useCallback(() => {
    setModalProps(curr => ({
      ...curr,
      isOpen: false
    }));
  }, [setModalProps]);

  const initiateNftDelegation = useCallback(
    (
      tokensMap: Partial<{ [key in ERC721Symbol]: { tokenIds: number[]; collectionName: string } }>
    ) => {
      return new Promise<void>((resolve, reject) => {
        setModalProps(curr => ({
          ...curr,
          isOpen: true,
          tokensMap,
          onClose: closeModal,
          onFinish: resolve,
          onError: reject
        }));
      });
    },
    [closeModal, setModalProps]
  );

  return [modalProps, initiateNftDelegation];
};
