import { H6, Inline, Button } from '@parallel-mono/components';
import { memo } from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { WalletTypeEnum } from 'parax-sdk';

import { useEOAProvider } from '@/apps/parax/contexts';
import { truncateTextMid } from '@/apps/parax/utils';
import {
  AppSelector,
  ColorModeSwitch,
  ValuePill,
  WalletIcon,
  useWalletModalContext
} from '@/apps/parax/components';

const ClickablePill = styled(ValuePill)<{ disabled?: boolean }>`
  padding: 0;
  background: ${({ theme }) => tinycolor(theme.skin.background.main).setAlpha(0.5).toString()};
  ${({ disabled, theme }) =>
    disabled
      ? 'cursor: default;'
      : `
    &:hover {
      background: ${tinycolor(theme.skin.background.main).setAlpha(0.75).toString()};
    }`}
`;

const CursorInline = styled(Inline)`
  cursor: pointer;
`;

export const RightHeader = memo(() => {
  const { isUsingUserWallet, account: eoaAccount, walletType } = useEOAProvider();
  const { isWalletModalOpen, setWalletModalOpen } = useWalletModalContext();

  const handleWalletConnectClick = () => {
    setWalletModalOpen(!isWalletModalOpen);
    ReactGA.event({
      category: 'Paraspace',
      action: 'Connect Wallet'
    });
  };

  return (
    <Inline gap="1rem" alignItems="center">
      {isUsingUserWallet ? (
        <>
          <ColorModeSwitch />
          <AppSelector />
          <CursorInline gap="0">
            <ClickablePill
              disabled={walletType === WalletTypeEnum.GNOSIS_SAFE}
              onClick={
                walletType === WalletTypeEnum.GNOSIS_SAFE
                  ? undefined
                  : () => setWalletModalOpen(!isWalletModalOpen)
              }
            >
              <Inline gap="0.25rem" inset="6px 1rem">
                <WalletIcon wallet={walletType} width="1.25rem" height="1.25rem" />
                <H6>{truncateTextMid(eoaAccount)}</H6>
              </Inline>
            </ClickablePill>
          </CursorInline>
        </>
      ) : (
        <Inline gap="1rem" alignItems="center">
          <ColorModeSwitch />
          <Button skin="primary" onClick={handleWalletConnectClick}>
            Connect Wallet
          </Button>
        </Inline>
      )}
    </Inline>
  );
});
