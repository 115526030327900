import { formatNumber } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';

import {
  DISABLE_BORROW_UTILIZATION_THRESHOLD,
  ESCAPE_UTILIZATION_THRESHOLD_USERS
} from '../../../configs';

export const validate = ({
  amount,
  userAvailableToBorrow,
  availableLiquidity,
  utilization,
  account
}: {
  amount: number;
  userAvailableToBorrow: BigNumber;
  availableLiquidity: BigNumber;
  utilization: BigNumber;
  account: string;
}) => {
  if (amount === null) {
    return null;
  }

  if (!Number.isFinite(amount) || (amount ?? 0) <= 0) {
    return 'Invalid input.';
  }

  if (userAvailableToBorrow.lt(amount)) {
    return 'Exceed borrow limit.';
  }

  if (availableLiquidity.lt(amount)) {
    return `Borrow amount exceeds market liquidity${
      availableLiquidity.toNumber() > 0
        ? `, please enter an amount below ${formatNumber(availableLiquidity)}.`
        : '.'
    }`;
  }

  if (
    utilization.gte(DISABLE_BORROW_UTILIZATION_THRESHOLD) &&
    !ESCAPE_UTILIZATION_THRESHOLD_USERS.includes(account)
  ) {
    return 'The current utilization rate is too high.';
  }

  return null;
};
