import { chain, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useBreakpoints, Image } from '@parallel-mono/components';

import { getExpandableTokensInfo } from '../utils';

import { SupplyPositions } from './SupplyPositions';
import { CollectionPosition, TokenPosition } from './types';
import { SupplyPositionsMobile } from './Mobile';
import { mergeTokenDerivatives } from './utils';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { TokenCategory } from '@/apps/paraspace/config';

const MySupplyPositions = () => {
  const { nftInfoMap, erc20InfoMap } = useMMProvider();
  const { erc721Config, erc20Config } = useAppConfig();

  const nftPositions = useMemo(() => {
    const nestedList = Object.keys(erc721Config).map(key => {
      const token = nftInfoMap[key];
      if (token && token.nftSuppliedList) {
        const items = token.nftSuppliedList.map(id => {
          return {
            ...token,
            tokenId: id
          };
        });
        return {
          ...token,
          items
        };
      }
      return null;
    });
    return nestedList.filter(it => it !== null && it.items.length > 0) as CollectionPosition[];
  }, [nftInfoMap, erc721Config]);

  const tokenPositions = useMemo(() => {
    const normalizedTokens = chain(erc20Config)
      .mapValues((_erc20, symbol) => erc20InfoMap[symbol])
      .values()
      .filter(notEmpty)
      .filter(v => v.suppliedAmount?.gt(0) ?? false)
      .filter(v => !v.isWrappedNativeToken) // For the supply positions table, should display the native token rather than the wNative token
      .value();

    const expandableTokens = chain(normalizedTokens)
      .groupBy(erc20 => {
        // group the expandable tokens
        if (erc20.tokenCategory) {
          return erc20.tokenCategory;
        }
        // other single token
        return 'normalToken';
      })
      .mapValues((value, key) => {
        if (key === 'normalToken') {
          return value;
        }
        const { displayName, icon } = getExpandableTokensInfo(key as TokenCategory);
        return mergeTokenDerivatives({
          tokenDerivatives: value,
          erc20Config,
          displayName,
          displayIcon: <Image width="2.5rem" height="2.5rem" src={icon} />
        });
      })
      .values()
      .value();

    const flattenNormalTokens = chain(expandableTokens)
      .flatten()
      .orderBy((token: TokenPosition) => isEmpty(token.subRows))
      .value();

    return flattenNormalTokens;
  }, [erc20Config, erc20InfoMap]);

  const { mobile } = useBreakpoints();

  if (isEmpty(nftPositions) && isEmpty(tokenPositions)) {
    return null;
  }

  if (mobile) {
    return <SupplyPositionsMobile nftPositions={nftPositions} tokenPositions={tokenPositions} />;
  }
  return <SupplyPositions nftPositions={nftPositions} tokenPositions={tokenPositions} />;
};

export default MySupplyPositions;
