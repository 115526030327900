import { Card, Icon, Stack, H5, Text, Button } from '@parallel-mono/components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';

const Wrapper = styled(Stack)`
  width: 100%;
`;

export const EmptyMatchPairCard = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Card border>
        <Stack inset="2.5rem" alignItems="center" gap="1rem">
          <Icon name="boxOpen" size="4rem" />
          <Stack gap="0.25rem" alignItems="center">
            <H5>Currently there's no active matches.</H5>
            <Text skin="secondary">You can view listings and join a staking.</Text>
          </Stack>
          <Button
            skin="secondary"
            onClick={() => navigate(absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS.index)}
          >
            View Listings
          </Button>
        </Stack>
      </Card>
    </Wrapper>
  );
};
