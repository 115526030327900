import { Fees } from '@/apps/paraspace/generated/graphql';

export type CollectionFees = {
  paraSpaceFeeAddress: string;
  paraSpaceFeePoint: number;
  creatorFeeAddress: string;
  creatorFeePoint: number;
};

export const calculateFees = (fees: Fees | null | undefined) => {
  const paraSpaceFeeAddress = fees?.paraspace_fees ? Object.keys(fees?.paraspace_fees)[0] : '';
  const paraSpaceFeePoint = fees?.paraspace_fees[paraSpaceFeeAddress] ?? 0;
  const creatorFeeAddress = fees?.seller_fees ? Object.keys(fees?.seller_fees)[0] : '';
  const creatorFeePoint = fees?.seller_fees[creatorFeeAddress] ?? 0;
  return {
    paraSpaceFeeAddress,
    paraSpaceFeePoint,
    creatorFeeAddress,
    creatorFeePoint
  } as CollectionFees;
};
