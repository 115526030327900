import {
  Button,
  H5,
  H6,
  Inline,
  SmallText,
  Stack,
  StackProps,
  Text,
  Icon,
  Skeleton,
  Image
} from '@parallel-mono/components';
import { FC, useCallback, useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';
import { isEmpty } from 'lodash';
import { HostedImage } from '@parallel-mono/business-components';

import { CollectionRow } from '..';
import { ExplorerLink } from '../components';
import { WalletsBalanceDropdown } from '../../../../components';

import {
  DropdownMenu,
  NFTCollectionThumbnail,
  ChainlinkLogo,
  StackedIcons
} from '@/apps/paraspace/components';
import { useBendDaoBalanceMap, useWalletBalanceMap } from '@/apps/paraspace/pages/Credit/hooks';
import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';
import { FetchingStatus, WalletType } from '@/apps/paraspace/typings';
import { useNativeTokenSymbol } from '@/apps/paraspace/pages/hooks/useNativeTokenSymbol';
import { Feature } from '@/apps/paraspace/config';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { useWeb3Context } from '@/apps/paraspace/contexts';

export type NftCardProps = Omit<StackProps, 'children'> & {
  data: CollectionRow;
  onSupply: (nft: { collectionName: string; symbol: string }, walletType: WalletType) => void;
  onSupplyFromOtherProtocols: (nft: { collectionName: string; symbol: string }) => void;
};

const NftCard: FC<NftCardProps> = ({ data, onSupply, onSupplyFromOtherProtocols, ...others }) => {
  const {
    symbol,
    collectionName,
    isComingSoon,
    balanceMap,
    floorPrice,
    ltv,
    totalSupplied,
    balanceLoadingStatus,
    floorPriceInMarketCurrency
  } = data;
  const { AA: paraXBalance, EOA: EOABalance } = useWalletBalanceMap();
  const bendDaoBalanceMap = useBendDaoBalanceMap();
  const nativeSymbol = useNativeTokenSymbol();
  const {
    marketCurrencyTokenInfo: { name: marketCurrencyTokenName, isNativeTokenForNetWork },
    features
  } = useAppConfig();
  const showBendaoMover = useMemo(() => features.includes(Feature.BendaoMover), [features]);

  const {
    authentication: {
      meta: { walletIcon, walletType }
    }
  } = useWeb3Context();

  const renderBalanceInfo = useCallback(() => {
    if (balanceLoadingStatus === FetchingStatus.FETCHING) {
      return <Skeleton.Title />;
    }
    if (isComingSoon || (isEmpty(balanceMap.AA) && isEmpty(balanceMap.EOA))) {
      return <Text>-</Text>;
    }
    return (
      <Stack gap="0.5rem" alignItems="flex-end">
        <StackedIcons
          assets={balanceMap.AA.balances.concat(balanceMap.EOA.balances)}
          variant="list"
          size="small"
        />
        <Inline>
          <WalletsBalanceDropdown
            placement="bottom-end"
            EOABalance={{
              loading: balanceMap.EOA.loading,
              value: <H6>{balanceMap.EOA.balances.length}</H6>
            }}
            paraXBalance={{
              loading: balanceMap.AA.loading,
              value: <H6>{balanceMap.AA.balances.length}</H6>
            }}
          />
        </Inline>
      </Stack>
    );
  }, [balanceLoadingStatus, balanceMap.AA, balanceMap.EOA, isComingSoon]);

  return (
    <Stack gap="1rem" {...others}>
      <Inline gap="0.5rem" alignItems="center">
        <NFTCollectionThumbnail symbol={symbol} size="small" round />
        <H5>{collectionName}</H5>
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Your Wallet
        </H6>
        {renderBalanceInfo()}
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Oracle Est. Floor
        </H6>
        {!floorPrice || isComingSoon ? (
          <Text>-</Text>
        ) : (
          <Stack gap="0" alignItems="flex-end">
            {isNativeTokenForNetWork ? (
              <>
                <Text>
                  {formatBalance(floorPrice)} {nativeSymbol}
                </Text>
                <ChainlinkLogo symbol={symbol} />
              </>
            ) : (
              <>
                <Text>
                  {formatBalance(floorPriceInMarketCurrency)} {marketCurrencyTokenName}
                </Text>
                <SmallText skin="secondary">
                  {formatBalance(floorPrice)} {nativeSymbol}
                </SmallText>
              </>
            )}
          </Stack>
        )}
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Loan To Value
        </H6>
        {!floorPrice || !ltv || isComingSoon ? (
          <Text>-</Text>
        ) : (
          <Stack gap="0" alignItems="flex-end">
            <Text>{formatToPercentage(ltv)}</Text>
            <SmallText skin="secondary">
              {formatBalance(floorPriceInMarketCurrency.times(ltv))} {marketCurrencyTokenName}/NFT
            </SmallText>
          </Stack>
        )}
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Total Supplied
        </H6>
        {!totalSupplied || isComingSoon ? (
          <Text>-</Text>
        ) : (
          <Stack gap="0" alignItems="flex-end">
            <Text>{formatNumber(totalSupplied)}</Text>
            <ExplorerLink symbol={symbol} />
          </Stack>
        )}
      </Inline>
      {isComingSoon ? (
        <Button skin="secondary" disabled block>
          Coming Soon
        </Button>
      ) : (
        <DropdownMenu
          placement="bottom-end"
          title="Supply From"
          trigger="click"
          options={[
            {
              icon: (
                <HostedImage
                  name="design/PDS_V3/logo/parallel-v2-logo"
                  height="1.25rem"
                  width="1.25rem"
                />
              ),
              label: <Text>Parallel Balance</Text>,
              onClick: () => onSupply({ collectionName, symbol }, 'AA'),
              value: formatNumber(paraXBalance[symbol]?.length ?? 0)
            },
            {
              icon: <Image src={walletIcon!} width="1.25rem" height="1.25rem" />,
              label: <Text>{walletType} Balance</Text>,
              onClick: () => onSupply({ collectionName, symbol }, 'EOA'),
              value: formatNumber(EOABalance[symbol]?.length ?? 0)
            },
            showBendaoMover
              ? {
                  icon: <Icon name="globe" size="1.5rem" strokeWidth={2} />,
                  label: <Text>Other Protocols</Text>,
                  onClick: () => onSupplyFromOtherProtocols({ collectionName, symbol }),
                  value: formatNumber(bendDaoBalanceMap[symbol] ?? 0)
                }
              : null
          ].filter(notEmpty)}
          menuTrigger={
            <Button skin="secondary" block>
              Supply
            </Button>
          }
        />
      )}
    </Stack>
  );
};

export default NftCard;
