import { Stack } from '@parallel-mono/components';
import { FC, memo } from 'react';

import { ApeCoinListing, ApeListing, BakcListing, Listing } from '../../../types';
import { Position } from '../types';

import { PositionCard } from './PositionCard';

import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { zero } from '@/apps/paraspace/consts/values';
import { Maybe } from '@/apps/paraspace/typings/basic';

type Props = {
  positions: Position[];
  account: string;
  onCancelListing: (listing: Listing) => Promise<void>;
  onUnmatchListing: (params: {
    blockHeight: string;
    matchedHash: string;
    apeListing: ApeListing;
    apeCoinListing: ApeCoinListing;
    bakcListing: Maybe<BakcListing>;
  }) => void;
};

export const PositionList: FC<Props> = memo(
  ({ positions, account, onCancelListing, onUnmatchListing }) => {
    const { nftPoolsCompoundApy } = useAutoCompoundApeInfo();
    return (
      <Stack width="100%">
        {positions.map((position, index) => {
          const { apeListing, apeCoinListing, bakcListing } = position;
          const stakingPool =
            apeListing?.stakingPool ?? apeCoinListing?.stakingPool ?? bakcListing?.stakingPool;
          return (
            <PositionCard
              key={index}
              account={account}
              poolApy={nftPoolsCompoundApy?.[stakingPool!] ?? zero}
              position={position}
              onCancelListing={onCancelListing}
              onUnmatchListing={onUnmatchListing}
            />
          );
        })}
      </Stack>
    );
  }
);
