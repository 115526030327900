import { useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';
import { CryptoIcon } from '@parallel-mono/business-components';
import { Stack, H3, Button } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import ReactGA from 'react-ga4';

import { SuccessState } from '../components/SuccessState';

import ClaimReward from './ClaimReward';

import { ErrorState } from '@/apps/paraspace/components';

enum FormState {
  MAIN_TOKEN_FORM = 1,
  STEPPER = 2,
  ERROR = 3,
  SUCCESS = 4
}

export type ClaimAllFormProps = {
  claimValue: BigNumber;
  onClose: () => void;
  onSuccess: () => void;
};

export const ClaimAllForm = ({ claimValue, onClose, onSuccess }: ClaimAllFormProps) => {
  const [formState, setFormState] = useState<FormState>(FormState.MAIN_TOKEN_FORM);

  const handleError = useCallback(() => {
    setFormState(FormState.ERROR);
  }, []);

  const handleSuccess = useCallback(() => {
    setFormState(FormState.SUCCESS);
    ReactGA.event({
      action: 'apestaking',
      category: 'p2pstaking',
      label: 'withdraw',
      value: claimValue.toNumber()
    });
  }, [claimValue]);

  if (formState === FormState.MAIN_TOKEN_FORM) {
    return (
      <Stack alignItems="center">
        <CryptoIcon symbol="CAPE" size="5rem" />
        <H3>{formatNumber(claimValue)} CAPE</H3>
        <Button block size="large" onClick={() => setFormState(FormState.STEPPER)}>
          Confirm
        </Button>
      </Stack>
    );
  }

  if (formState === FormState.STEPPER) {
    return <ClaimReward tip="Claim all rewards" onError={handleError} onFinish={handleSuccess} />;
  }

  if (formState === FormState.ERROR) {
    return <ErrorState closeModal={onClose} />;
  }

  if (formState === FormState.SUCCESS) {
    return (
      <SuccessState
        tip="Successfully Claimed All Rewards"
        describe="You can check your wallet for balance."
        onSuccess={() => {
          onSuccess();
          onClose();
        }}
      />
    );
  }

  return null;
};
