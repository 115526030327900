import { createContext, useContext } from 'react';

const Context = createContext<{
  scrollToNFTSupplyTable: () => void;
  scrollToERC20Table: () => void;
}>({
  scrollToNFTSupplyTable: () => false,
  scrollToERC20Table: () => false
});

export const useNFTPageUIProvider = () => useContext(Context);

export const NFTPageUIProvider = Context.Provider;
