import { Inline, Responsive, Stack, StackProps } from '@parallel-mono/components';
import { isNil } from 'lodash';
import { FC, ReactElement, useMemo } from 'react';
import styled from 'styled-components';

const SideMenuWrapper = styled(Stack)<{ hasBanner: boolean }>`
  position: sticky;
  top: calc(var(--header-height-pc) + 1rem);
  height: fit-content;
  ${({ theme }) => theme.breakpoints.not('mobile')`
    max-width: 13rem;
    min-width: 12rem;
  `};
  ${({ theme }) => theme.breakpoints.down('tablet')`
    width: 100%;
    position: relative;
    top: 0;
  `};
`;
const ContentWrapper = styled(Inline)`
  flex: 1 1 100%;
`;
const PageWrapper = styled(Responsive)`
  ${({ theme }) => theme.breakpoints.down('tablet')`
    padding-top: 1.5rem;
  `};
`;

type Props = {
  banner?: ReactElement;
  showBanner?: boolean;
  mainContent: ReactElement;
  sideMenu?: ReactElement;
  showSideMenu?: boolean;
};

export const PageLayout: FC<Props & Omit<StackProps, 'children'>> = ({
  banner,
  showBanner = true,
  mainContent: content,
  sideMenu,
  showSideMenu = true,
  ...others
}) => {
  const hasBanner = useMemo(() => showBanner && Boolean(banner), [banner, showBanner]);
  return (
    <Stack width="100%" gap="0" {...others}>
      {showBanner && banner}
      <PageWrapper inset="2rem 0 0 0" gap="1.5rem" breakPoint="tablet" width="100%">
        {showSideMenu && !isNil(sideMenu) && (
          <SideMenuWrapper hasBanner={hasBanner}>{sideMenu}</SideMenuWrapper>
        )}
        <ContentWrapper width="100%">{content}</ContentWrapper>
      </PageWrapper>
    </Stack>
  );
};
