import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Container, Stack } from '@parallel-mono/components';

const ContentWrapper = styled(Container)`
  background: transparent;
  overflow-x: clip;
  border-radius: 0;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    min-height: calc(100vh - var(--header-height-mobile));
    padding: 0 1rem;
  `};
`;

const Content = styled(Stack)`
  position: relative;
  max-width: var(--max-app-width-pc);
  margin: 0 auto;
  padding-bottom: 4rem;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    padding: 0 0 3rem;
  `};
`;

export const MainWrapper: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => (
  <ContentWrapper {...rest}>
    <Content gap="2.5rem">{children}</Content>
  </ContentWrapper>
);
