import { useCallback, useMemo } from 'react';
import { WETHGatewayService } from 'paraspace-utilities-contract-helpers';

import submitTransaction from '../utils/submitTransaction';
import { MIN_BALANCE_THRESHOLD } from '../utils/format';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

export const useNativeTokenGateway = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();
  const service = useMemo(() => {
    if (!provider) {
      return null;
    }

    return new WETHGatewayService(provider, contracts.WETHGatewayProxy);
  }, [contracts.WETHGatewayProxy, provider]);

  // These functions operate internally on the weth token.
  const supplyNativeToken = useCallback(
    async ({
      amount,
      from,
      onBehalfOf
    }: {
      amount: string;
      from?: string;
      onBehalfOf?: string;
    }) => {
      if (!provider || !service) {
        return null;
      }

      const tx = (
        await service.depositETH({
          user: from ?? account,
          amount,
          onBehalfOf: onBehalfOf ?? account
        })
      )[0];
      return tx;
    },
    [account, provider, service]
  );

  const repayNativeToken = useCallback(
    async (params: { amount: string; max: boolean; from?: string }) => {
      const { amount, max = false, from = account } = params;
      if (!provider || !service) {
        return null;
      }

      const value = max ? `${parseFloat(amount) + MIN_BALANCE_THRESHOLD}` : amount;
      const tx = (await service.repayETH({ user: from, amount: value, onBehalfOf: account }))[0];

      return tx;
    },
    [account, provider, service]
  );

  const borrowNativeToken = useCallback(
    async (debtTokenAddress: string, amount: string) => {
      if (!provider || !service) {
        return null;
      }

      const txs = await service.borrowETH({
        amount,
        user: account,
        debtTokenAddress
      });

      if (txs.length !== 1) {
        throw new Error('You need to approve before borrowing !');
      }

      const txRes = submitTransaction({ provider, tx: txs[0] });
      return txRes;
    },
    [account, provider, service]
  );

  const withdrawNativeToken = useCallback(
    async (aTokenAddress: string, amount: string, max: boolean = false) => {
      if (!provider || !service) {
        return null;
      }

      const txs = await service.withdrawETH({
        user: account,
        amount: max ? '-1' : amount,
        aTokenAddress
      });

      if (txs.length !== 1) {
        throw new Error('You need to approve before withdrawing !');
      }
      const txRes = submitTransaction({ provider, tx: txs[0] });
      return txRes;
    },
    [account, provider, service]
  );

  return { supplyNativeToken, borrowNativeToken, withdrawNativeToken, repayNativeToken };
};
