import { ReactNode, memo, useCallback, useMemo, useState } from 'react';
import { Stack, StackProps } from '@parallel-mono/components';

import {
  ApproveERC721FromEOASubmitter,
  ApproveERC721Submitter,
  Stepper,
  SupplyERC721FromEOASubmitter,
  SupplyLegacyERC721Submitter
} from '@/apps/paraspace/components';
import { useContractsMap } from '@/apps/paraspace/hooks';
import { ERC721Symbol, WalletType } from '@/apps/paraspace/typings';

type UniSwapV3SupplyStepperProps = Omit<StackProps, 'children' | 'onError'> & {
  formData: { tokenIds: number[]; walletType: WalletType };
  onFinish: () => void;
  onError: () => void;
};

export const UniSwapV3SupplyStepper = memo(
  ({
    onFinish,
    onError,
    formData: { tokenIds, walletType },
    ...others
  }: UniSwapV3SupplyStepperProps) => {
    const contracts = useContractsMap();

    const [step, setStep] = useState(0);

    const handleNext = useCallback(() => {
      setStep(curr => curr + 1);
    }, []);

    const steps: {
      description: ReactNode;
      content: ReactNode;
    }[] = useMemo(
      () =>
        ({
          AA: [
            {
              description: 'Approve Uniswaps',
              content: (
                <ApproveERC721Submitter
                  formData={{
                    name: 'Uniswap',
                    assetAddress: contracts[ERC721Symbol.UNISWAP_LP]
                  }}
                  onError={onError}
                  onFinish={handleNext}
                />
              )
            },
            {
              description: 'Supply Uniswaps',
              content: (
                <SupplyLegacyERC721Submitter
                  formData={{
                    symbol: ERC721Symbol.UNISWAP_LP,
                    tokenIds
                  }}
                  onError={onError}
                  onFinish={onFinish}
                />
              )
            }
          ],
          EOA: [
            {
              description: 'Approve Uniswaps',
              content: (
                <ApproveERC721FromEOASubmitter
                  formData={{
                    name: 'Uniswap',
                    assetAddress: contracts[ERC721Symbol.UNISWAP_LP]
                  }}
                  onError={onError}
                  onFinish={handleNext}
                />
              )
            },
            {
              description: 'Supply Uniswaps',
              content: (
                <SupplyERC721FromEOASubmitter
                  formData={{
                    symbol: ERC721Symbol.UNISWAP_LP,
                    tokenIds
                  }}
                  onError={onError}
                  onFinish={onFinish}
                />
              )
            }
          ]
        }[walletType]),
      [contracts, onError, handleNext, tokenIds, onFinish, walletType]
    );

    return (
      <Stack {...others}>
        <Stepper steps={steps} step={step} />
      </Stack>
    );
  }
);
