import { ReactNode, createContext, memo, useCallback, useContext, useMemo } from 'react';
import { UiPoolDataProvider } from 'paraspace-utilities-contract-helpers';
import { Network } from 'paraspace-configs-v2';
import { JsonRpcProvider } from '@ethersproject/providers';

import { useAutoPolledState } from '../hooks';
import { useTokensInfo } from '../TokensInfoProvider';
import { useEOABalances, useUserBalances } from '../UserBalanceProvider';
import { useUserPosition } from '../UserPositionProvider';
import { useReservesAndIncentives } from '../../hooks/useReservesAndIncentives';

import { getUniswapTokenInfoMap } from './utils';

import { useAppConfig, useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';
import {
  BasicNFTSpecificInfo,
  ERC721Symbol,
  FetchingStatus,
  UniswapSpecificInfo
} from '@/apps/paraspace/typings';

type UniswapInfoContextValue = {
  infoMap: Record<string, BasicNFTSpecificInfo & UniswapSpecificInfo>;
  pollingStatus: FetchingStatus;
  refresh: () => Promise<void>;
};

const UniswapInfoContext = createContext<UniswapInfoContextValue>({
  infoMap: {},
  pollingStatus: FetchingStatus.INIT,
  refresh: () => {
    throw new Error('Not implemented yet');
  }
});

type UniswapInfoProviderProps = {
  children: ReactNode;
  pollingInterval: number;
  provider: JsonRpcProvider;
  chainId: Network;
};

const initialState = {};

export const UniswapInfoProvider = memo(
  ({ children, pollingInterval, chainId, provider }: UniswapInfoProviderProps) => {
    const { erc721BalanceMap: eoaErc721BalanceMap } = useEOABalances();
    const { erc721BalanceMap } = useUserBalances();
    const { getReserveData } = useReservesAndIncentives();

    const inAABalanceTokenIds = useMemo(
      () => erc721BalanceMap?.[ERC721Symbol.UNISWAP_LP]?.balance ?? null,
      [erc721BalanceMap]
    );
    const inEoaBalanceTokenIds = useMemo(
      () => eoaErc721BalanceMap?.[ERC721Symbol.UNISWAP_LP]?.balance ?? null,
      [eoaErc721BalanceMap]
    );

    const inBalanceTokenIds = useMemo(
      () => (inAABalanceTokenIds ?? []).concat(inEoaBalanceTokenIds ?? []),
      [inAABalanceTokenIds, inEoaBalanceTokenIds]
    );

    const { erc721PositionMap } = useUserPosition();
    const suppliedTokenIds = useMemo(
      () => erc721PositionMap?.[ERC721Symbol.UNISWAP_LP]?.nftSuppliedList ?? null,
      [erc721PositionMap]
    );

    const getSymbolByContractAddress = useGetSymbolByContractAddress();
    const { contracts } = useAppConfig();

    const uiPoolDataProvider = useMemo(
      () =>
        new UiPoolDataProvider({
          uiPoolDataProviderAddress: contracts.UiPoolDataProvider,
          provider,
          chainId
        }),
      [provider, chainId, contracts.UiPoolDataProvider]
    );

    const { tokensInfo } = useTokensInfo();

    const tokenIds = useMemo(() => {
      return (suppliedTokenIds ?? []).concat(inBalanceTokenIds ?? []);
    }, [suppliedTokenIds, inBalanceTokenIds]);

    const stabilizedTokenIds = useStabilizedSnapshot(tokenIds);

    const pollUniswapInfos = useCallback(async () => {
      const reserveData = await getReserveData();
      if (!reserveData) return {};
      return getUniswapTokenInfoMap({
        tokenIds: stabilizedTokenIds,
        provider: uiPoolDataProvider,
        chainId,
        getSymbolByContractAddress,
        tokensBasicInfo: tokensInfo,
        poolAddressProvider: contracts.PoolAddressesProvider,
        uniswapAddress: contracts['UNI-V3-POS'],
        paraSpaceOracle: contracts.ParaSpaceOracle,
        reserveData
      });
    }, [
      getReserveData,
      stabilizedTokenIds,
      uiPoolDataProvider,
      chainId,
      getSymbolByContractAddress,
      tokensInfo,
      contracts
    ]);

    const {
      pollingStatus,
      state: uniswapInfoMap,
      refresh
    } = useAutoPolledState({
      pollingInterval,
      initialState,
      pollFn: pollUniswapInfos,
      keepStateOnSwitchContext: true
    });

    const contextValue = useMemo(
      () => ({
        infoMap: uniswapInfoMap,
        pollingStatus,
        refresh
      }),
      [uniswapInfoMap, pollingStatus, refresh]
    );

    return (
      <UniswapInfoContext.Provider value={contextValue}>{children}</UniswapInfoContext.Provider>
    );
  }
);

UniswapInfoProvider.displayName = 'UniswapInfoProvider';

export const useUniswapInfos = () => useContext(UniswapInfoContext);
