import { FC, useState } from 'react';
import BigNumberJs from 'bignumber.js';

import { OfferFormValue } from '..';

import { ApproveWallet, Loading, Payload, TransmitData } from '.';

import { OrderSide } from '@/apps/paraspace/consts/order';
import {
  InputCreateOrder,
  OrderTarget,
  useCreateOrderMutation
} from '@/apps/paraspace/generated/graphql';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import useSeaport from '@/apps/paraspace/pages/hooks/useSeaport';
import { convertListOrderTo14Compatible } from '@/apps/paraspace/utils/listOrderFormater';
import { useContractsMap } from '@/apps/paraspace/hooks';
import { shiftedRightBy } from '@/apps/paraspace/utils/calculations';

interface BorrowingFromPoolProps {
  onFinish?: () => void;
  onError?: (e: any) => void;
  offerFormValue: OfferFormValue;
  payload: Payload;
  transmitData: TransmitData;
}

const BorrowingFromPool: FC<BorrowingFromPoolProps> = ({
  offerFormValue,
  payload,
  transmitData,
  onError,
  onFinish
}) => {
  const { mutationOptions = {}, tokenId } = payload;
  const { token } = offerFormValue;
  const { order } = transmitData;

  const [createOrderMutation] = useCreateOrderMutation({
    ...mutationOptions
  });
  const { account, provider } = useWeb3Context();
  const contracts = useContractsMap();

  const { signPayLaterPayload } = useSeaport();
  const [waitingApprove, setWaitingApprove] = useState<Boolean>(true);

  const creditAmount = shiftedRightBy(BigNumberJs(token.borrowAmount), token.decimal).toString();

  useAsyncEffect(async () => {
    try {
      const inputOrder = {
        target: tokenId ? OrderTarget.Token : OrderTarget.Collection,
        side: OrderSide.Offer,
        protocolData: {
          ...convertListOrderTo14Compatible(order!)
        },
        // TODO: update once paraspace seaport has migrated to v1.4
        protocolVersion: '1.1',
        protocolContract: contracts.Seaport
      } as InputCreateOrder;
      // use wallet only
      const payLaterParameters = {
        token: token.address,
        amount: creditAmount,
        orderSignature: order!.signature,
        address: account
      };
      setWaitingApprove(true);
      const EOAAccount = await provider.getSigner().getAddress();
      const creditData = await signPayLaterPayload(payLaterParameters, EOAAccount);
      inputOrder.creditData = creditData;
      setWaitingApprove(false);

      await createOrderMutation({
        variables: { inputOrder }
      });
      onFinish?.();
    } catch (e: any) {
      onError?.(e);
    }
  }, []);

  return waitingApprove ? <ApproveWallet /> : <Loading tip="Borrowing Securely" />;
};

export default BorrowingFromPool;
