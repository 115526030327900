import { formatNumber } from '@parallel-mono/utils';
import BigNumberJs from 'bignumber.js';

import { Maybe } from '@/apps/paraspace/typings/basic';

export const validateWalletInput = ({
  walletBalance,
  amount
}: {
  walletBalance: BigNumberJs;
  amount: Maybe<number>;
}) => {
  if (amount) {
    if (walletBalance.lt(amount)) {
      return 'Insufficient balance.';
    }
  }
  return null;
};

export const validateCreditInput = ({
  amount,
  availableLiquidity,
  borrowLimit,
  restrictToBorrowApe
}: {
  borrowLimit: BigNumberJs;
  availableLiquidity: BigNumberJs;
  restrictToBorrowApe: boolean;
  amount: Maybe<number>;
}) => {
  if (amount) {
    if (availableLiquidity.lt(amount)) {
      return `Borrow amount exceeds market liquidity, please enter an amount below ${formatNumber(
        availableLiquidity
      )} cAPE.`;
    }
    if (borrowLimit.lt(amount)) {
      return 'Exceed borrow limit.';
    }
    if (restrictToBorrowApe) {
      return 'Borrow is disabled due to extreme high utilization.';
    }
  }
  return null;
};

export const validateForm = ({
  walletAmount,
  borrowAmount,
  stakeLimit,
  stakedAmount
}: {
  walletAmount: Maybe<number>;
  borrowAmount: Maybe<number>;
  stakedAmount: BigNumberJs;
  stakeLimit: BigNumberJs;
}) => {
  if (walletAmount || borrowAmount) {
    if ((walletAmount ?? 0) + (borrowAmount ?? 0) < 1) {
      return `You need to stake at least 1 APE`;
    }

    if (stakeLimit.minus(stakedAmount).lt((walletAmount ?? 0) + (borrowAmount ?? 0))) {
      return `Total staking on each NFT cannot exceed ${formatNumber(
        stakeLimit.minus(stakedAmount)
      )} APE.`;
    }
  }

  return null;
};
