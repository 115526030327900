import { useCallback } from 'react';

import CollateralModal from '../../Modal/CollateralModal';
import { SupplyType } from '../../../contexts/modals';

import { useModal } from '@/apps/paraspace/hooks';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { useMoneyMarketActions } from '@/apps/paraspace/pages/Credit/contexts';

export const useSupplyPositionModals = () => {
  const { nftInfoMap, erc20InfoMap } = useMMProvider();
  const { supplyNft, supplyERC20, withdrawERC20 } = useMoneyMarketActions();

  const { openModal: collateralOpenModal, holder: collateralHolder } = useModal(CollateralModal, {
    size: '500px',
    titleContent: modalData => {
      const isCollateral =
        modalData &&
        (modalData.isNft
          ? (nftInfoMap[modalData.symbol].nftCollateralList || []).includes(modalData.id)
          : erc20InfoMap[modalData.symbol].usageAsCollateralEnabledOnUser);
      return isCollateral ? 'Remove from Collateral' : 'Use as Collateral';
    }
  });

  const openNFTSupplyModal = useCallback(
    (nft: { collectionName: string; symbol: string }, wallet: WalletType) =>
      supplyNft(nft.symbol as ERC721Symbol, nft.collectionName, wallet),
    [supplyNft]
  );

  const openERC20SupplyModal = useCallback(
    (symbol: ERC20Symbol, type: SupplyType) => {
      supplyERC20(symbol, type);
    },
    [supplyERC20]
  );

  return {
    openERC20SupplyModal,
    withdrawERC20,
    collateralOpenModal,
    collateralHolder,
    openNFTSupplyModal
  };
};
