import { TokenInput } from '@parallel-mono/business-components';
import { Button, H5, Inline, Modal, ModalProps, Stack, Text } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import { memo, useCallback, useState } from 'react';
import { floor } from 'lodash';

import { formatBalance } from '@/apps/paraspace/utils/format';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { Tooltip } from '@/apps/paraspace/components';

type PayLaterEditModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  payload: {
    credit: BigNumber;
    totalPrice: BigNumber;
    availableLiquidity: BigNumber;
    defaultValue: number;
  };
  onClose: () => void;
  onFinish: (value: number) => void;
};

export const PayLaterEditModal = memo(
  ({ payload, onFinish, onClose, ...others }: PayLaterEditModalProps) => {
    const { credit, totalPrice, availableLiquidity, defaultValue } = payload;
    const [value, setValue] = useState<number | null>(
      floor(defaultValue, MAXIMUM_BALANCE_DECIMALS)
    );
    const [error, setError] = useState('');

    const validate = useCallback(
      (inputValue: number) => {
        if (inputValue > credit.toNumber()) {
          setError('Exceed borrowable amount.');
        } else if (inputValue > totalPrice.toNumber()) {
          setError('Exceed total price.');
        } else if (availableLiquidity.lt(inputValue)) {
          setError(
            `Borrow amount exceeds market liquidity${
              availableLiquidity.toNumber() > 0
                ? `, please enter an amount below ${formatBalance(availableLiquidity, 2)} ETH.`
                : '.'
            }`
          );
        } else {
          setError('');
        }
      },
      [credit, totalPrice, availableLiquidity]
    );

    const handleInputChange = useCallback(
      (inputValue: number | null) => {
        setValue(inputValue);
        validate(inputValue!);
      },
      [validate]
    );

    const handleClickMax = useCallback(() => {
      const inputValue = BigNumber.min(credit, totalPrice).toNumber();
      setValue(floor(inputValue, MAXIMUM_BALANCE_DECIMALS));
      validate(inputValue);
    }, [credit, totalPrice, validate]);

    const handleConfirmClick = () => {
      if (value !== null) {
        onFinish(value);
        onClose();
      }
    };

    return (
      <Modal onClose={onClose} {...others}>
        <Stack>
          <TokenInput
            label={<H5>Amount</H5>}
            hint={
              <Inline gap=".25rem">
                <Text skin="secondary">Credit: {formatBalance(credit)} ETH</Text>
                <Tooltip
                  placement="bottom"
                  content="This is the amount of credit you have access to, including the available borrow amount from your existing loan positions as well as the amount you can borrow by collateralizing the NFT you are purchasing."
                />
              </Inline>
            }
            token="ETH"
            value={value}
            onChange={handleInputChange}
            error={error}
            placeholder="0"
            decimals={MAXIMUM_BALANCE_DECIMALS}
            actionButtonText="MAX"
            onAction={handleClickMax}
            inputProps={{
              inputProps: {
                autoFocus: true
              }
            }}
          />
          <Button
            block
            size="large"
            onClick={handleConfirmClick}
            disabled={value === null || !!error}
          >
            Confirm Edit
          </Button>
        </Stack>
      </Modal>
    );
  }
);
