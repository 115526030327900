import { FC, memo, useEffect, useMemo, useState } from 'react';
import { groupBy, toArray } from 'lodash';
import BigNumber from 'bignumber.js';
import {
  Inline,
  Responsive,
  SmallText,
  Stack,
  StackProps,
  useBreakpoints
} from '@parallel-mono/components';
import styled from 'styled-components';
import { sumBy } from '@parallel-mono/utils';

import { useApeListStatesAndActions, ApeListItem } from '../../../../contexts';

import { BreakDownItem } from './BreakDownItem';

import { ApeStakingTokenSymbol, ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { zero } from '@/apps/paraspace/consts/values';

const Dot = styled.div<{ color: string }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${props => props.color};
`;

const BreakDownLine = styled(Inline)`
  width: 100%;
  & :first-child {
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
  }
  & :last-child {
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
  }
`;

export type BreakDownData = {
  poolName: string;
  toolTipName: string;
  fieldName: string;
  color: string;
  shadowColor: 'rgba(12, 62, 227, 0.4)';
  staked: BigNumber;
};

const calculateTotalStaked = (apeList: ApeListItem[]) =>
  sumBy(apeList, item => item.stakedAmount ?? zero);

type PoolOverviewData = Record<
  ERC20Symbol.APE | ERC20Symbol.CAPE | ApeStakingTokenSymbol,
  BreakDownData
>;

const defaultBreakDownData = {
  [ERC20Symbol.APE]: {
    poolName: 'ApeCoin',
    toolTipName: 'Ape Coin',
    fieldName: 'Supplied',
    color: '#0C3EE3',
    shadowColor: 'rgba(12, 62, 227, 0.4)'
  },
  [ERC721Symbol.BAKC]: {
    poolName: 'BAKC',
    toolTipName: 'BAKC Pool',
    fieldName: 'Staked',
    color: '#7AD5B9',
    shadowColor: 'rgba(122, 213, 185, 0.4)'
  },
  [ERC721Symbol.MAYC]: {
    poolName: 'MAYC',
    toolTipName: 'MAYC Pool',
    fieldName: 'Staked',
    color: '#F4A446',
    shadowColor: 'rgba(244, 164, 70, 0.4)'
  },
  [ERC721Symbol.BAYC]: {
    poolName: 'BAYC',
    toolTipName: 'BAYC Pool',
    fieldName: 'Staked',
    color: '#B4E647',
    shadowColor: 'rgba(180, 230, 71, 0.4)'
  },
  [ERC20Symbol.CAPE]: {
    poolName: 'cAPE',
    toolTipName: 'cAPE Pool',
    fieldName: 'Supplied',
    color: '#9A6AFF',
    shadowColor: 'rgba(127, 17, 224, 0.4)'
  }
};
export const StakingBreakDown: FC<Omit<StackProps, 'children'>> = memo(({ ...other }) => {
  const [stakingBreakDown, setStakingBreakDown] = useState(
    defaultBreakDownData as PoolOverviewData
  );
  const { erc20InfoMap } = useMMProvider();
  const { mobile } = useBreakpoints();
  const { balance: capeBalance, suppliedAmount: capeSuppliedAmount } = erc20InfoMap.CAPE ?? {};
  const { suppliedAmount: apeSuppliedAmount } = erc20InfoMap.APE;

  const capeAmount = useMemo(
    () => (capeBalance && capeSuppliedAmount ? capeBalance.plus(capeSuppliedAmount) : zero),
    [capeBalance, capeSuppliedAmount]
  );

  const { apesInSuppliedExcludingInP2P } = useApeListStatesAndActions();

  const groupedApesByPool = useMemo(
    () =>
      groupBy(
        apesInSuppliedExcludingInP2P.filter(ape => ape.stakedAmount?.gt(0)),
        'symbol'
      ),
    [apesInSuppliedExcludingInP2P]
  );

  useEffect(() => {
    setStakingBreakDown(prev => ({
      [ERC20Symbol.APE]: { ...prev.APE, staked: apeSuppliedAmount ?? zero },
      [ERC20Symbol.CAPE]: { ...prev.CAPE, staked: capeAmount },
      [ERC721Symbol.BAKC]: {
        ...prev.BAKC,
        staked: calculateTotalStaked(groupedApesByPool.BAKC ?? [])
      },
      [ERC721Symbol.MAYC]: {
        ...prev.MAYC,
        staked: calculateTotalStaked(groupedApesByPool.MAYC ?? [])
      },
      [ERC721Symbol.BAYC]: {
        ...prev.BAYC,
        staked: calculateTotalStaked(groupedApesByPool.BAYC ?? [])
      }
    }));
  }, [apeSuppliedAmount, capeAmount, groupedApesByPool]);

  const stakingBreakDownArray = useMemo(() => toArray(stakingBreakDown), [stakingBreakDown]);
  const totalStakedAmount = useMemo(
    () => stakingBreakDownArray.reduce((total, item) => total.plus(item.staked), BigNumber(0)),
    [stakingBreakDownArray]
  );
  return (
    <Stack gap="0.75rem" {...other}>
      <Responsive breakPoint="tablet" gap="0.75rem" justifyContent="space-between">
        <SmallText skin="secondary">Asset Breakdown</SmallText>
        <Responsive breakPoint="tablet" justifyContent={mobile ? 'flex-start' : 'flex-end'}>
          {toArray(stakingBreakDown).map(item => (
            <Inline key={item.poolName} alignItems="center" gap="0.5rem">
              <Dot color={item.color} />
              <SmallText skin="secondary">{item.poolName}</SmallText>
            </Inline>
          ))}
        </Responsive>
      </Responsive>
      <BreakDownLine gap="0">
        {totalStakedAmount.gt(0) &&
          stakingBreakDownArray.map(item => (
            <BreakDownItem
              key={item.poolName}
              percent={item.staked.div(totalStakedAmount).toNumber()}
              {...item}
            />
          ))}
      </BreakDownLine>
    </Stack>
  );
});
