import { Inline, InlineProps, Tag, useBreakpoints } from '@parallel-mono/components';
import { NavLink } from 'react-router-dom';
import { HostedImage } from '@parallel-mono/business-components';
import styled, { CSSProperties } from 'styled-components';
import { Environment } from 'parax-sdk';

import { useWeb3Context } from '../../contexts';

HostedImage.displayName = 'HostedImage';
(window as any).HostedImage = HostedImage;

export const LogoIcon = styled(HostedImage).attrs({
  name: `design/PDS_V3/logo/parallel-v2-logo-with-name`
})`
  display: block;
  height: ${({ height }) => height ?? '2rem'};
  ${({ theme, height }) => theme.breakpoints.down('desktop')`
    height: ${height ?? '1.5rem'};
  `};
`;

const IconContainer = styled(Inline)`
  ${({ theme }) => theme.breakpoints.down('desktop')`
    flex-wrap: wrap;
  `};
`;

const LogoLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

type LogoProps = Omit<InlineProps, 'children'> & {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
};

export const Logo = ({ width, height, ...others }: LogoProps) => {
  const { desktop: isDesktop } = useBreakpoints();
  const { env } = useWeb3Context();

  return (
    <IconContainer gap={isDesktop ? '1rem' : '.5rem'} alignItems="center" {...others}>
      <LogoLink to="/">
        <LogoIcon width={width} height={height} />
      </LogoLink>
      {env !== Environment.PRODUCTION && (
        <Tag skin="warning" size={isDesktop ? 'large' : 'small'}>
          Testnet
        </Tag>
      )}
    </IconContainer>
  );
};
