import { H6, Inline, Spinner } from '@parallel-mono/components';
import { memo } from 'react';
import styled from 'styled-components';

import { ConnectWallet, WalletIcon, useWalletModalContext } from '../../Wallet';
import { MenuPill } from '../../MenuPill';
import { ColorModeSwitch } from '../../ColorModeSwitch';
import { AppSelector } from '../components';
import { Logo } from '../../Logo';
import { MobileAccountSelector } from '../components/AccoountSelector';

import { useAppsContext, useEOAProvider } from '@/apps/parax/contexts';
import { FetchingStatus } from '@/apps/parax/typings';

const StyledMenuPill = styled(MenuPill)`
  height: fit-content;
  width: fit-content;
  min-height: 2.25rem;
  min-width: 2.25rem;
  padding: 0;
  background-color: ${({ theme }) => theme.skin.grey['100']};
`;

export const MobileHeader = memo(
  ({ hideAAAccountSelector = false }: { hideAAAccountSelector?: boolean }) => {
    const { setWalletModalOpen } = useWalletModalContext();

    const { currentApp, fetchingStatus } = useAppsContext();

    const { account: eoaAccount, walletType, isUsingUserWallet } = useEOAProvider();

    return (
      <Inline width="100%" justifyContent="space-between" gap="0">
        <Inline alignItems="center" gap="0.25rem">
          <Logo />
        </Inline>
        <Inline gap="0.5rem" alignItems="center">
          <ColorModeSwitch />
          <StyledMenuPill>
            {(!currentApp || fetchingStatus === FetchingStatus.SUCCESS) && <AppSelector />}
            {currentApp && fetchingStatus === FetchingStatus.FETCHING && <Spinner />}
          </StyledMenuPill>
          {eoaAccount && !hideAAAccountSelector && (
            <StyledMenuPill>
              <MobileAccountSelector />
            </StyledMenuPill>
          )}

          {isUsingUserWallet ? (
            <StyledMenuPill
              onClick={() => {
                setWalletModalOpen(true);
              }}
              style={{ padding: '0.5rem 0.75rem' }}
            >
              <WalletIcon wallet={walletType} width="1.25rem" height="1.25rem" />
              <H6>{eoaAccount.slice(0, 6)}</H6>
            </StyledMenuPill>
          ) : (
            <ConnectWallet btnSize="small" skin="primary" btnTxt="Connect" />
          )}
        </Inline>
      </Inline>
    );
  }
);
