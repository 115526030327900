import BigNumber from 'bignumber.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useMemo } from 'react';
import { isNil } from 'lodash';

import { DEFAULT_WITHDRAWAL_RESERVED_GAS } from '../../../configs';

export const useValidation = ({
  amount,
  token
}: {
  amount: Maybe<number>;
  token: Maybe<{
    symbol: string;
    balance: number | null;
  }>;
}) => {
  const errorMessage = useMemo(() => {
    if (isNil(amount) || amount === 0) {
      return 'Invalid amount';
    }
    if (
      token?.symbol === 'ETH' &&
      BigNumber(amount)
        .plus(DEFAULT_WITHDRAWAL_RESERVED_GAS)
        .gt(token?.balance ?? 0)
    ) {
      return `Insufficient ${token?.symbol} for gas`;
    }
    if (BigNumber(amount).gt(token?.balance ?? 0)) {
      return `Insufficient ${token?.symbol} balance.`;
    }
    return null;
  }, [token, amount]);

  return errorMessage;
};
