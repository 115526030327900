import BigNumberJs from 'bignumber.js';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { DEFAULT_MULTIPLIER, MAX_BORROW_PERCENTAGE } from '@/apps/paraspace/pages/config';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';

const useBatchNftCredit = ({
  tokenList,
  creditBasedCurrencyValueInUsd
}: {
  tokenList: { symbol: ERC721Symbol; multiplier: BigNumberJs }[];
  creditBasedCurrencyValueInUsd: BigNumberJs;
}) => {
  const stabilizedTokenList = useStabilizedSnapshot(tokenList);
  const stabilizedCreditBasedCurrencyValueInUsd = useStabilizedSnapshot(
    creditBasedCurrencyValueInUsd
  );

  const {
    overviewUserInfo: { totalBorrowedPositionInUsd },
    nftInfoMap
  } = useMMProvider();

  const supplyTokenList = useMemo(() => {
    if (isEmpty(nftInfoMap)) {
      return [];
    }

    return stabilizedTokenList.map(token => {
      const { baseLTVasCollateral, reserveLiquidationThreshold, priceInUsd } =
        nftInfoMap[token.symbol];
      return {
        type: ActionTypeEnum.SUPPLY,
        targetAsset: {
          id: `${token.symbol}`,
          value: priceInUsd?.times(token?.multiplier ?? DEFAULT_MULTIPLIER),
          LTV: baseLTVasCollateral,
          reserveLiquidationThreshold
        }
      };
    });
  }, [nftInfoMap, stabilizedTokenList]);

  const { borrowLimitInUsd: newBorrowLimitInUsd } = useLendingSimulation(supplyTokenList);

  const futureNftCredit = useMemo(
    () =>
      BigNumberJs.max(
        newBorrowLimitInUsd
          .minus(totalBorrowedPositionInUsd)
          .div(stabilizedCreditBasedCurrencyValueInUsd),
        new BigNumberJs(0)
      ),
    [stabilizedCreditBasedCurrencyValueInUsd, newBorrowLimitInUsd, totalBorrowedPositionInUsd]
  );

  const futureNftAvailableCredit = useMemo(
    () =>
      futureNftCredit.isNaN()
        ? new BigNumberJs(0)
        : futureNftCredit.times(MAX_BORROW_PERCENTAGE).decimalPlaces(18, BigNumberJs.ROUND_FLOOR),
    [futureNftCredit]
  );

  return futureNftAvailableCredit;
};

export default useBatchNftCredit;
