import { Component, ReactElement, PropsWithChildren } from 'react';

import UnknownError from '../UnknownError';

import { CustomError } from './useErrorHandler';

type CustomErrorRender = (props: { error: Error | null }) => ReactElement;

type Props = PropsWithChildren<{ customErrorRender?: CustomErrorRender }>;

export class ErrorBoundary extends Component<Props, { error: CustomError | Error | null }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null
    };
  }

  static getDerivedStateFromError(error: CustomError | Error) {
    return { error };
  }

  componentDidCatch() {
    // we can add the error tracker here or print the error
  }

  resetError = () => {
    this.setState({ error: null });
  };

  // eslint-disable-next-line class-methods-use-this
  defaultRender = ({ error }: { error: CustomError | Error }) => {
    return <UnknownError error={error} resetError={this.resetError} />;
  };

  render() {
    const { error } = this.state;
    const { children, customErrorRender } = this.props;
    const errorRender = customErrorRender || this.defaultRender;
    return error ? errorRender({ error }) : children;
  }
}
