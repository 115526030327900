import { Button } from '@parallel-mono/components';
import { ComponentProps, FC, memo } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

export interface LinkButtonProps extends LinkProps, ComponentProps<typeof Button> {}

const StyledButton = styled(Button)`
  text-decoration: none;
`;

export const LinkButton: FC<LinkButtonProps> = memo(({ children, as = RouterLink, ...props }) => {
  return (
    <StyledButton forwardedAs={as} {...props}>
      {children}
    </StyledButton>
  );
});
