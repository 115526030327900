import { FC, useMemo } from 'react';
import {
  Button,
  CardProps,
  DataGridColumn,
  H5,
  Inline,
  Skeleton,
  SmallText,
  Stack,
  Text
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { flatMap } from 'lodash';

import { RepayDropdownMenu } from '../../components';
import { getEffectiveApy } from '../utils';
import {
  DISABLE_BORROW_UTILIZATION_THRESHOLD,
  ESCAPE_UTILIZATION_THRESHOLD_USERS
} from '../../configs';

import BorrowedPositionsHeader from './BorrowedPositionsHeader';

import { BorrowPositionRow, Handlers } from '.';

import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';
import { Collapse, DataGrid, Tooltip } from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { getEffectiveBorrowApyTip } from '@/apps/paraspace/pages/Credit/MoneyMarket/utils/getEffectiveApyTip';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { zero } from '@/apps/paraspace/consts/values';

export type PositionsTableProps = Omit<CardProps, 'children'> & {
  positions: BorrowPositionRow[];
  handlers: Handlers;
};

const MyBorrowedPositionsTable: FC<PositionsTableProps> = ({ positions, handlers, ...others }) => {
  const {
    overviewUserInfo: {
      healthFactor,
      nftHealthFactor,
      totalCollateralPositionInUsd,
      liquidationThresholdInUsd,
      borrowLimitInUsd,
      totalBorrowedPositionInUsd
    },
    nftInfoMap
  } = useMMProvider();
  const { account } = useWeb3Context();

  const tokensInAuction = useMemo(
    () => flatMap(nftInfoMap, it => it.auctionedTokens).length,
    [nftInfoMap]
  );
  const nftsInCollateral = useMemo(
    () => flatMap(nftInfoMap, it => it.nftCollateralList).length,
    [nftInfoMap]
  );

  const { effectiveCapeBorrowApy } = useAutoCompoundApeInfo();

  const { erc20Config, nativeTokenAndDerivatives } = useAppConfig();

  const columns: DataGridColumn<BorrowPositionRow>[] = useMemo(
    () => [
      {
        name: 'assets',
        title: 'Assets',
        width: '3fr',
        render: ({ data: { displayName, symbol } }) => (
          <Inline gap="1rem" alignItems="center">
            <CryptoIcon symbol={symbol} />
            <H5 fontWeight="bold">{displayName}</H5>
          </Inline>
        )
      },
      {
        name: 'borrowed',
        title: 'Borrowed Amount',
        width: '2fr',
        render: ({ data: { borrowedAmount, borrowedAmountInUsd } }) => (
          <Stack gap="0">
            <Text>{formatBalance(borrowedAmount)}</Text>
            {borrowedAmountInUsd.gt(zero) ? (
              <SmallText skin="secondary">
                {formatToCurrency(borrowedAmountInUsd.toNumber())}
              </SmallText>
            ) : (
              <Skeleton.Button height="1rem" width="2rem" />
            )}
          </Stack>
        )
      },
      {
        name: 'apy',
        title: 'APY',
        width: '2fr',
        render: ({ data: { borrowApyRate, symbol } }) => {
          const isNativeTokenOrDerivatives = nativeTokenAndDerivatives.includes(symbol);
          const effectiveBorrowApy = getEffectiveApy({
            baseApy: borrowApyRate,
            effectiveCapeApy: effectiveCapeBorrowApy,
            symbol,
            apy: erc20Config[symbol]?.apy,
            isNativeTokenOrDerivatives
          });
          const effectiveBorrowApyTooltip = getEffectiveBorrowApyTip({
            symbol,
            baseApy: borrowApyRate,
            apy: erc20Config[symbol]?.apy ?? null,
            isNativeTokenOrDerivatives
          });
          return (
            <Stack gap="0">
              <Inline alignItems="center" gap=".25rem">
                <Text>{effectiveBorrowApy}</Text>
                {effectiveBorrowApyTooltip}
              </Inline>
            </Stack>
          );
        }
      },
      {
        name: 'actions',
        title: '',
        width: '3fr',
        justifyContent: 'flex-end',
        render: ({ data }) => {
          return (
            <Inline gap="1rem" alignItems="center">
              <Tooltip
                disabled={
                  data.utilization.lt(DISABLE_BORROW_UTILIZATION_THRESHOLD) ||
                  ESCAPE_UTILIZATION_THRESHOLD_USERS.includes(account)
                }
                content="The current utilization rate is too high. Please try again later."
              >
                <Button
                  skin="secondary"
                  onClick={() => handlers.handleBorrow(data)}
                  disabled={
                    data.utilization.gte(DISABLE_BORROW_UTILIZATION_THRESHOLD) &&
                    !ESCAPE_UTILIZATION_THRESHOLD_USERS.includes(account)
                  }
                >
                  Borrow more
                </Button>
              </Tooltip>
              <RepayDropdownMenu data={data}>
                <Button skin="secondary">Repay</Button>
              </RepayDropdownMenu>
            </Inline>
          );
        }
      }
    ],
    [effectiveCapeBorrowApy, handlers, erc20Config, nativeTokenAndDerivatives, account]
  );

  return (
    <Collapse
      header={
        <BorrowedPositionsHeader
          borrowedValue={totalBorrowedPositionInUsd}
          borrowLimitValue={borrowLimitInUsd}
          liquidationPointValue={liquidationThresholdInUsd}
          totalSuppliedValue={totalCollateralPositionInUsd}
          healthFactor={healthFactor}
          nftHealthFactor={nftHealthFactor}
          tokensInAuction={tokensInAuction}
          nftsInCollateral={nftsInCollateral}
        />
      }
      defaultOpen
      border
      {...others}
    >
      <DataGrid<BorrowPositionRow> columns={columns} data={positions} />
    </Collapse>
  );
};

export default MyBorrowedPositionsTable;
