import { useCallback, useMemo } from 'react';
import { ApeStakingService } from 'paraspace-utilities-contract-helpers';
import BigNumberJs from 'bignumber.js';
import { keyBy, mapValues } from 'lodash';
import { ApeCoinStaking } from 'paraspace-utilities-contract-helpers/dist/esm/ape-staking-contract/typechain/ApeCoinStaking';

import { StakeInfoListItem } from '../types';
import { OFFICIAL_STAKE_CONFIG } from '../config';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import {
  ApeStakingTokenSymbol,
  ERC721Symbol,
  ApeStakingMainTokenSymbol,
  WalletType
} from '@/apps/paraspace/typings';
import submitTransaction from '@/apps/paraspace/utils/submitTransaction';

export type StakingInfoMap = Record<string, { stakedAmount: BigNumberJs }>;

export type StakingApeProps = {
  type: ApeStakingTokenSymbol;
  tokenId: number;
  cashAmount: number;
  bakcCashAmount?: number;
  apeSource: WalletType;
  apeCoinSource: WalletType;
};

export type PairInfoListProps = {
  mainTokenId: number;
  bakcTokenId: number;
  amount: number;
};

/**
 * @deprecated use useOfficialApeStaking hook instead
 */
export const useLegacyOfficialApeStaking = () => {
  const { provider, account, env } = useWeb3Context();

  const service = useMemo(() => {
    const apeStakingService = new ApeStakingService(
      provider,
      OFFICIAL_STAKE_CONFIG[env].APE_STAKING_ADDR
    );
    return apeStakingService;
  }, [provider, env]);

  // currently we only need user's staking amount for single collection
  // hence we return an id-info map here
  const getStakingInfoByUserAddress = useCallback(async () => {
    const stakeInfoMap = await service.getUserApeStakingInfo(account);

    return mapValues(stakeInfoMap, stakeInfo => keyBy(stakeInfo, 'tokenId'));
  }, [account, service]);

  const getStakingInfoByTokenInfo = useCallback(
    async (symbol: ApeStakingTokenSymbol, tokenId: string, ownerAddr: string) => {
      const result = await service.getTokensApeStakingInfo([
        { type: symbol, id: tokenId, userAddr: ownerAddr }
      ]);
      return result[0];
    },
    [service]
  );

  const getStakingInfosByTokenInfos = useCallback(
    (
      tokens: {
        type: ApeStakingTokenSymbol;
        id: string | number;
        userAddr: string;
      }[]
    ) => {
      return service.getTokensApeStakingInfo(tokens.map(it => ({ ...it, id: String(it.id) })));
    },
    [service]
  );

  const depositBayc = useCallback(
    async (
      tokens: {
        tokenId: number;
        amount: string;
      }[]
    ) => {
      const tx = await service.depositBayc(tokens, account);
      return submitTransaction({ provider, tx });
    },
    [account, provider, service]
  );

  const stakingBakc = useCallback(
    async (
      pairInfoList: {
        tokenId: number;
        symbol: ApeStakingTokenSymbol;
        bakcTokenId: number;
        amountFromBalance: number;
      }[]
    ) => {
      const baycInfoList: PairInfoListProps[] = [];
      const maycInfoList: PairInfoListProps[] = [];
      pairInfoList.forEach(info => {
        if (info.symbol === ERC721Symbol.MAYC) {
          maycInfoList.push({
            mainTokenId: info.tokenId,
            bakcTokenId: info.bakcTokenId,
            amount: info.amountFromBalance || 0
          });
        }
        if (info.symbol === ERC721Symbol.BAYC) {
          baycInfoList.push({
            mainTokenId: info.tokenId,
            bakcTokenId: info.bakcTokenId,
            amount: info.amountFromBalance || 0
          });
        }
      });
      const tx = await service.depositBAKC(baycInfoList, maycInfoList, account);
      return submitTransaction({ provider, tx });
    },
    [account, provider, service]
  );

  const stakingApe = useCallback(
    async (
      symbol: ApeStakingTokenSymbol,
      tokens: {
        tokenId: number;
        amount: string | number;
        mainTokenId?: number;
        mainTokenSymbol?: ApeStakingTokenSymbol;
      }[]
    ) => {
      if (symbol === ERC721Symbol.MAYC) {
        const tx = await service.depositMayc(tokens, account);
        return submitTransaction({ provider, tx });
      }

      if (symbol === ERC721Symbol.BAKC) {
        const pairInfoList = tokens.map(({ tokenId, amount, mainTokenId, mainTokenSymbol }) => ({
          tokenId: mainTokenId!,
          symbol: mainTokenSymbol!,
          bakcTokenId: tokenId,
          amountFromBalance: amount as number
        }));
        return stakingBakc(pairInfoList);
      }

      const tx = await service.depositBayc(tokens, account);
      return submitTransaction({ provider, tx });
    },
    [account, provider, service, stakingBakc]
  );

  const claimRewards = useCallback(
    async (token: StakeInfoListItem) => {
      const { symbol, tokenId, mainTokenSymbol } = token;
      if (symbol === ERC721Symbol.BAKC && mainTokenSymbol) {
        const { mainTokenId } = token;

        const claimArgs = {
          // If the mainTokenSymbol is MAYC, pass [] as the first params(baycPairs)
          [ERC721Symbol.MAYC]: [[], [{ mainTokenId, bakcTokenId: tokenId }]],
          // If the mainTokenSymbol is BAYC, pass [] as the second params(maycPairs)
          [ERC721Symbol.BAYC]: [[{ mainTokenId, bakcTokenId: tokenId }], []]
        }[mainTokenSymbol] as ApeCoinStaking.PairNftStruct[][];

        const tx = await service.claimBakc(claimArgs[0], claimArgs[1], account);
        return submitTransaction({ provider, tx });
      }
      if ([ERC721Symbol.MAYC, ERC721Symbol.BAYC].includes(symbol)) {
        const tx = await service.claimBaycOrMayc(symbol, [tokenId], account);
        return submitTransaction({ provider, tx });
      }
      throw new Error(`Unsupported tokens ${symbol}`);
    },
    [account, provider, service]
  );

  const claimBakc = useCallback(
    async (
      baycPairs: { mainTokenId: number; bakcTokenId: number }[],
      maycPairs: { mainTokenId: number; bakcTokenId: number }[]
    ) => {
      const tx = await service.claimBakc(baycPairs, maycPairs, account);
      return submitTransaction({ provider, tx });
    },
    [account, provider, service]
  );

  const claimBaycOrMayc = useCallback(
    async (symbol: ApeStakingMainTokenSymbol, tokenIds: number[]) => {
      const tx = await service.claimBaycOrMayc(symbol, tokenIds, account);
      return submitTransaction({ provider, tx });
    },
    [account, provider, service]
  );

  const withdraw = useCallback(
    async (token: StakeInfoListItem, amount: string) => {
      const { symbol, tokenId, mainTokenSymbol } = token;
      if (symbol === ERC721Symbol.BAKC && mainTokenSymbol) {
        const { mainTokenId, stakedAmount } = token;
        const isUncommit = !!stakedAmount?.eq(amount);

        const withdrawArgs = {
          // If the mainTokenSymbol is MAYC, pass [] as the first params(baycPairs)
          [ERC721Symbol.MAYC]: [[], [{ mainTokenId, bakcTokenId: tokenId, amount, isUncommit }]],
          // If the mainTokenSymbol is BAYC, pass [] as the second params(maycPairs)
          [ERC721Symbol.BAYC]: [[{ mainTokenId, bakcTokenId: tokenId, amount, isUncommit }], []]
        }[mainTokenSymbol] as ApeCoinStaking.PairNftWithdrawWithAmountStruct[][];

        const tx = await service.withdrawBakc(withdrawArgs[0], withdrawArgs[1], account);
        return submitTransaction({ provider, tx });
      }
      if ([ERC721Symbol.MAYC, ERC721Symbol.BAYC].includes(symbol)) {
        const tx = await service.withdrawBaycOrMayc(symbol, [{ tokenId, amount }], account);
        return submitTransaction({ provider, tx });
      }
      throw new Error(`Unsupported tokens ${symbol}`);
    },
    [account, provider, service]
  );

  return {
    getStakingInfoByUserAddress,
    getStakingInfoByTokenInfo,
    getStakingInfosByTokenInfos,
    depositBayc,
    stakingApe,
    claimRewards,
    withdraw,
    stakingBakc,
    claimBakc,
    claimBaycOrMayc
  };
};
