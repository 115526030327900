import { memo, useMemo } from 'react';
import { Stack, Image, ModalProps, Modal } from '@parallel-mono/components';
import { InfoPanel } from '@parallel-mono/business-components';

import { ETHValidatorSupplyForm } from './ETHValidatorSupplyForm';
import SupplyStakeFishStepper from './SupplyStakeFishStepper';
import { SupplyETHValidatorFormData } from './types';

import {
  ErrorState,
  SuccessState,
  ConnectWallet,
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import noNFT from '@/apps/paraspace/assets/no-nft.png';
import { WalletType } from '@/apps/paraspace/typings';

export type SupplyETHValidatorModalProps = {
  formData: { walletType: WalletType };
  onFinish: () => void;
  onError: () => void;
  onClose: () => void;
} & Omit<ModalProps, 'children' | 'onClose'>;

export const SupplyETHValidatorModal = memo(
  ({
    onClose,
    isOpen,
    formData: { walletType },
    onError,
    onFinish,
    ...others
  }: SupplyETHValidatorModalProps) => {
    const { phase, submittedFormData, handleFormSubmit, handleSubmitFailed, handleSubmitSuccess } =
      useCollectAndSubmitProcedure<SupplyETHValidatorFormData>({
        running: isOpen,
        onError,
        onFinish
      });

    const { isUsingUserWallet } = useWeb3Context();

    const successPanelInfos = useMemo(
      () => [
        {
          title: 'Borrow Limit Added',
          value: formatToCurrency(submittedFormData?.newCollateralValue ?? 0)
        }
      ],
      [submittedFormData]
    );

    if (!isUsingUserWallet) {
      return (
        <Modal onClose={onClose} isOpen={isOpen} size="500px" title="Supply NFT">
          <Stack alignItems="center" width="27.25rem">
            <Image src={noNFT} width="10rem" height="11.25rem" />
            <ConnectWallet skin="primary" btnSize="large" btnTxt="Connect Wallet" />
          </Stack>
        </Modal>
      );
    }

    return (
      <Modal
        title="Supply Stake.fish NFTs"
        closeOnBackdropClick={false}
        size={phase === CollectAndSubmitProcedurePhase.Collecting ? '45rem' : '30rem'}
        onClose={onClose}
        isOpen={isOpen}
        {...others}
      >
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <ETHValidatorSupplyForm walletType={walletType} onSubmit={handleFormSubmit} />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <SupplyStakeFishStepper
            onError={handleSubmitFailed}
            onSuccess={handleSubmitSuccess}
            formData={{ ...submittedFormData!, walletType }}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            onAction={onClose}
            actionButtonText="Go to Borrow"
            desc={`Successfully Supplied ${submittedFormData?.tokenIds.length} NFTs`}
            tip="You can now use them as collateral and borrow ERC-20 assets on ParaSpace!"
          >
            <InfoPanel width="100%" skin="primary" infos={successPanelInfos} />
          </SuccessState>
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
      </Modal>
    );
  }
);
