import { FC, memo, useCallback, useEffect } from 'react';
import { ERC721Symbol } from 'paraspace-configs-v2';
import { H4, Spinner, Stack, Text } from '@parallel-mono/components';

import useAcceptBlurBid, { BlurBid } from '@/apps/paraspace/pages/hooks/useAcceptBlurBid';
import useLegacyERC721 from '@/apps/paraspace/pages/hooks/useLegacyERC721';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';

type Props = {
  onFinish: () => void;
  onError: () => void;
  formData: {
    bid: BlurBid;
  };
};

enum TransactionStatus {
  COMPLETE_TRANSACTION,
  IN_TRANSACTION
}

enum ResultStatus {
  loading,
  success,
  failed
}

export const CheckAcceptBlurBidSubmitter: FC<Props> = memo(
  ({ onFinish, onError, formData: { bid } }) => {
    const { nftInfoMap } = useMMProvider();
    const { getAcceptBlurBidsRequestStatus } = useAcceptBlurBid();
    const getSymbolByContractAddress = useGetSymbolByContractAddress();

    const symbol = getSymbolByContractAddress(bid.collection as string);
    const { address, xTokenAddress } = nftInfoMap[symbol as ERC721Symbol];
    const { ownerOf } = useLegacyERC721(address);

    const check = useCallback(async () => {
      const res = await getAcceptBlurBidsRequestStatus(bid);

      if (res === TransactionStatus.IN_TRANSACTION) {
        return ResultStatus.loading;
      }

      const owner = await ownerOf(Number(bid.tokenId));
      if (res === TransactionStatus.COMPLETE_TRANSACTION && owner !== xTokenAddress) {
        return ResultStatus.success;
      }
      if (res === TransactionStatus.COMPLETE_TRANSACTION && owner === xTokenAddress) {
        return ResultStatus.failed;
      }

      return ResultStatus.loading;
    }, [bid, getAcceptBlurBidsRequestStatus, ownerOf, xTokenAddress]);

    useEffect(() => {
      const intervalId = setInterval(async () => {
        try {
          const result = await check();
          if (result !== ResultStatus.loading) clearInterval(intervalId);

          if (result === ResultStatus.success) onFinish();
          if (result === ResultStatus.failed) onError();
        } catch (error) {
          onError();
        }
      }, 2000);
      return () => {
        clearInterval(intervalId);
      };
    }, [check, onError, onFinish]);

    return (
      <Stack alignItems="center">
        <Spinner />
        <Stack alignItems="center" gap="0.25rem">
          <H4>Completing transaction</H4>
          <Text skin="secondary">
            Sending your NFT to the bidder and receiving payments from them.
          </Text>
        </Stack>
      </Stack>
    );
  }
);
