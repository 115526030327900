import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, noop } from 'lodash';
import { H2, H5, Stack } from '@parallel-mono/components';
import { TransactionReceipt } from '@ethersproject/providers';
import BigNumber from 'bignumber.js';

import { FormSubmitter, FormSubmitterProps } from '../FormSubmitter';

import { useTimelock } from '@/apps/paraspace/pages/hooks/Timelock';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useIsNativeTokenCheck } from '@/apps/paraspace/pages/hooks/useIsNativeTokenCheck';
import { useParaAccountTransfer } from '@/apps/paraspace/pages/hooks';
import { useAppConfig, useCAPE, useContractsMap } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import useMoonBirds from '@/apps/paraspace/pages/hooks/useMoonBirds';

type ClaimTimelockSubmitterProps = Omit<FormSubmitterProps, 'submit' | 'inProgressMessage'> & {
  formData: {
    symbol: ERC721Symbol | ERC20Symbol;
    agreementIds: string[];
    shouldUnwrapToCryptoPunks?: boolean;
    tokenIds?: Maybe<number[]>;
    amount?: Maybe<BigNumber>;
    shouldClaimToEOA?: boolean;
    shouldConvertToAPE?: boolean;
  };
  shouldWaitingPrepare?: boolean;
};

const WAITING_TIME = 15;

export const ClaimTimelockSubmitter = ({
  formData: {
    symbol,
    agreementIds,
    tokenIds,
    amount,
    shouldUnwrapToCryptoPunks = false,
    shouldClaimToEOA,
    shouldConvertToAPE = false
  },
  shouldWaitingPrepare = true,
  onFinish,
  ...others
}: ClaimTimelockSubmitterProps) => {
  const { claim, claimMoonbirds, claimNativeToken, claimPunks } = useTimelock();
  const deadlinePromiseResolverRef = useRef(noop);
  const { checkIsNativeTokenSymbol, nativeToken } = useIsNativeTokenCheck();
  const { transferERC20, transferERC721 } = useParaAccountTransfer();
  const { erc20Config, erc721Config } = useAppConfig();
  const {
    authentication: {
      meta: { account: EOAAccount }
    },
    account,
    submitStrippedTransactions
  } = useWeb3Context();
  const contracts = useContractsMap();

  const { safeTransferWhileNesting } = useMoonBirds();

  const { withdrawCAPE } = useCAPE();

  const initialDeadlineValue = useMemo(
    () => ({
      value: performance.now() + WAITING_TIME * 1000,
      promise: new Promise<void>(resolve => {
        deadlinePromiseResolverRef.current = resolve;
      })
    }),
    []
  );
  const handleFinish = useCallback(
    (results: Maybe<TransactionReceipt>[]) => {
      onFinish(results);
    },
    [onFinish]
  );

  const deadlineRef = useRef<{
    value: number;
    promise: Promise<void>;
  }>(initialDeadlineValue);

  const prepare = useCallback(() => deadlineRef.current.promise, []);

  const getSecondsLeft = useCallback(
    () => Math.ceil((deadlineRef.current.value - performance.now()) / 1000),
    []
  );
  const [secondsLeft, setSecondsLeft] = useState<number>(getSecondsLeft());

  const generateCAPEClaimAndWithdrawTxs = useCallback(() => {
    if (amount && amount?.gt(0)) {
      // Due to contract accuracy issues, withdraw 10 pcAPE will receive (10 - 1e-18) APE,
      // So should only withdraw (10 - 1e-18) cAPE to APE
      const actualAmount = amount.minus(1e-18);
      const withdrawTx = withdrawCAPE(actualAmount.toString(10));
      if (shouldClaimToEOA) {
        const { decimals, contractName } = erc20Config[ERC20Symbol.APE];
        const contractAddress = contracts[contractName];
        const transferAmount = BigNumber(actualAmount ?? 0)
          .shiftedBy(decimals)
          .toString(10);
        const transferTx = transferERC20(EOAAccount, contractAddress, transferAmount);
        return [withdrawTx, transferTx];
      }

      return [withdrawTx];
    }
    return [];
  }, [EOAAccount, amount, contracts, erc20Config, shouldClaimToEOA, transferERC20, withdrawCAPE]);

  const generateTransferTxs = useCallback(() => {
    if (checkIsNativeTokenSymbol(symbol as ERC20Symbol) && nativeToken && amount?.gt(0)) {
      const transferETHTx = {
        to: EOAAccount,
        value: amount.shiftedBy(nativeToken?.decimals).toString(10),
        data: '0x'
      };
      return Promise.resolve(transferETHTx);
    }
    if (symbol in erc20Config && amount?.gt(0)) {
      const { decimals, contractName } = erc20Config[symbol as ERC20Symbol];
      const contractAddress = contracts[contractName];
      const transferAmount = BigNumber(amount).shiftedBy(decimals).toString(10);
      return transferERC20(EOAAccount, contractAddress, transferAmount);
    }
    if (symbol === ERC721Symbol.MOONBIRD && !isEmpty(tokenIds)) {
      return tokenIds?.map(tokenId => safeTransferWhileNesting(account, EOAAccount, tokenId!));
    }
    if (symbol in erc721Config && !isEmpty(tokenIds)) {
      const contractAddress =
        symbol === ERC721Symbol.WPUNKS && shouldUnwrapToCryptoPunks
          ? erc721Config.PUNK.address
          : erc721Config[symbol as ERC721Symbol].address;
      return tokenIds!.map(tokenId =>
        transferERC721(account, EOAAccount, contractAddress, tokenId)
      );
    }
    return null;
  }, [
    EOAAccount,
    account,
    amount,
    checkIsNativeTokenSymbol,
    contracts,
    erc20Config,
    erc721Config,
    nativeToken,
    safeTransferWhileNesting,
    shouldUnwrapToCryptoPunks,
    symbol,
    tokenIds,
    transferERC20,
    transferERC721
  ]);

  const generateClaimTxs = useCallback(() => {
    if (checkIsNativeTokenSymbol(symbol as ERC20Symbol)) {
      return claimNativeToken(agreementIds);
    }
    // If the user wants CryptoPunks but not WPUNKS, we should convert the WPUNKS to CryptoPunks.
    if (symbol === ERC721Symbol.WPUNKS && shouldUnwrapToCryptoPunks) {
      return claimPunks(agreementIds);
    }
    if (symbol === ERC721Symbol.MOONBIRD) {
      return claimMoonbirds(agreementIds);
    }
    return claim(agreementIds);
  }, [
    agreementIds,
    checkIsNativeTokenSymbol,
    claim,
    claimMoonbirds,
    claimNativeToken,
    claimPunks,
    shouldUnwrapToCryptoPunks,
    symbol
  ]);

  const submit = useCallback(async () => {
    const claimTxs = generateClaimTxs().then(res => res?.tx());
    let claimAndTransferTxs = [claimTxs];
    if (shouldConvertToAPE) {
      const transferTxs = await generateCAPEClaimAndWithdrawTxs();
      claimAndTransferTxs = claimAndTransferTxs.concat(transferTxs as any);
    } else if (shouldClaimToEOA) {
      const transferTxs = generateTransferTxs();
      claimAndTransferTxs = claimAndTransferTxs.concat(transferTxs as any).filter(notEmpty);
    }

    return Promise.all(claimAndTransferTxs).then(txArray => {
      const txs = txArray.map(it => ({
        to: it?.to ?? '',
        value: it?.value?.toString() ?? '0',
        data: it?.data ?? ''
      }));

      return submitStrippedTransactions(txs);
    });
  }, [
    generateClaimTxs,
    shouldConvertToAPE,
    shouldClaimToEOA,
    generateCAPEClaimAndWithdrawTxs,
    generateTransferTxs,
    submitStrippedTransactions
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const latestSecondsLeft = getSecondsLeft();
      setSecondsLeft(latestSecondsLeft);
      if (latestSecondsLeft <= 0) {
        deadlinePromiseResolverRef.current();
        clearInterval(intervalId);
      }
    }, 1000);
  }, [getSecondsLeft]);

  const inPreparationMessage = useMemo(() => {
    if (secondsLeft > 0) {
      return (
        <Stack alignItems="center">
          <H2>{secondsLeft}</H2>
          <H5>Waiting for timelock contract to confirm the release of tokens</H5>
        </Stack>
      );
    }
    return null;
  }, [secondsLeft]);

  return (
    <FormSubmitter
      prepare={shouldWaitingPrepare ? prepare : undefined}
      inPreparationMessage={inPreparationMessage}
      submit={submit}
      inProgressMessage="Claiming your asset"
      onFinish={handleFinish}
      {...others}
    />
  );
};
