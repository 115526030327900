import { useCallback } from 'react';
import BigNumber from 'bignumber.js';

import useV1ERC20 from './useV1ERC20';
import { useV1Approval } from './useV1Approval';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useAppConfig, useSymbolByContractAddress } from '@/apps/paraspace/hooks';

export const useV1cAPEApproval = ({
  token,
  spender,
  amount
}: {
  token: string;
  spender?: string;
  amount: number | BigNumber | null;
}) => {
  const { approve: approveToken, getAllowance } = useV1ERC20(token);
  const symbol = useSymbolByContractAddress(token);

  const checkAllowance = useCallback(
    (allowance: Maybe<BigNumber>) => allowance?.gte(amount ?? 0) ?? false,
    [amount]
  );

  const { erc20Config } = useAppConfig();

  const createApproveTransaction = useCallback(
    async (allowance: Maybe<BigNumber>) => {
      if (allowance?.gt(0) && erc20Config[symbol as ERC20Symbol]?.needResetAllowanceBeforeApprove) {
        const tx = await approveToken(spender, '0');
        await tx?.wait();
      }
      return approveToken(spender);
    },
    [erc20Config, symbol, approveToken, spender]
  );

  const getAllowanceAsBigNumber = useCallback(
    () => getAllowance(spender).then(str => new BigNumber(str)),
    [spender, getAllowance]
  );

  return useV1Approval<BigNumber>({
    createApproveTransaction,
    checkAllowance,
    getAllowance: getAllowanceAsBigNumber
  });
};
