import { Button, Inline, Stack, H2, Text, useBreakpoints, Image } from '@parallel-mono/components';
import { FC } from 'react';

import bannerImg from '../images/compound-banner.png';

import { AutoCompoundWorkInfo } from './AutoCompoundWorkInfo';

import { useModal } from '@/apps/paraspace/hooks';
import { Link } from '@/apps/paraspace/components';
import { DEVELOPER_DOCS_LINK } from '@/apps/paraspace/consts/externalLinks';

export const Banner: FC<{}> = () => {
  const { openModal: openWorkInfoModal, holder: workInfoHolder } = useModal(AutoCompoundWorkInfo, {
    size: '500px',
    titleContent: 'Auto-Compounding on ParaSpace'
  });
  const { mobile } = useBreakpoints();

  return (
    <>
      <Inline gap="2.5rem">
        <Stack gap="1.5rem">
          <Stack gap="0.25rem">
            <H2>Auto Compound Pools</H2>
            <Text>
              Automatically reinvest your rewards to maximize your returns and increase your profits
              over time.{' '}
              <Link
                target="_blank"
                href={`${DEVELOPER_DOCS_LINK}/product-guide-and-walkthrough/ape-staking-guide#how-does-nft-staking-pool-works`}
              >
                Read more
              </Link>
            </Text>
          </Stack>
          <Button skin="secondary" onClick={openWorkInfoModal}>
            Read Compound Rule
          </Button>
        </Stack>
        {!mobile ? <Image src={bannerImg} height="163px" /> : null}
      </Inline>
      {workInfoHolder}
    </>
  );
};
