import { FC } from 'react';

import { AcceptCommonOfferSteppers } from './AcceptCommonOfferSteppers';
import { AcceptBlurOfferSteppers } from './AcceptBlurOfferSteppers';

import { ReservoirBid, ShopBidByPlatform } from '@/apps/paraspace/generated/graphql';

export type FormData = {
  order: ShopBidByPlatform;
  contractAddress: string;
  tokenId: number;
};

export type Props = {
  formData: FormData;
  onSuccess: () => void;
  onError: () => void;
};

export const AcceptOfferSteppers: FC<Props> = ({
  formData: { order, contractAddress, tokenId },
  ...others
}) => {
  // eslint-disable-next-line no-underscore-dangle
  return order.__typename === 'Order' ? (
    <AcceptCommonOfferSteppers formData={{ order, contractAddress, tokenId }} {...others} />
  ) : (
    <AcceptBlurOfferSteppers formData={order as ReservoirBid} {...others} />
  );
};
