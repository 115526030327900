import { FC, memo } from 'react';
import styled from 'styled-components';
import { Inline, InlineProps, useBreakpoints } from '@parallel-mono/components';

import { FilterPill } from '../FilterPill';

const FilterToggle = styled(Inline)`
  height: 2.5rem;
`;

type Props = {
  opened: boolean;
  onToggle: (opened: boolean) => void;
};

export const FilterPanelToggle: FC<Props & Omit<InlineProps, 'children'>> = memo(
  ({ opened, onToggle, ...others }) => {
    const { mobile } = useBreakpoints();
    return (
      <FilterToggle {...others}>
        <FilterPill showArrow={!mobile} active={opened} onToggle={onToggle} />
      </FilterToggle>
    );
  }
);
