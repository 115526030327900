import BigNumberJS, { BigNumber } from 'bignumber.js';
import { isNil } from 'lodash';

import { zero } from '@/apps/paraspace/consts/values';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { calculateEffectiveApy } from '@/apps/paraspace/utils/calculateApy';
import { formatToPercentage } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const getEffectiveApy = ({
  baseApy,
  effectiveCapeApy,
  symbol,
  apy = zero,
  isNativeTokenOrDerivatives
}: {
  baseApy: Maybe<BigNumberJS>;
  effectiveCapeApy: Maybe<BigNumberJS>;
  symbol: ERC20Symbol;
  apy?: BigNumber | number;
  isNativeTokenOrDerivatives: boolean;
}) => {
  const isCAPE = symbol === ERC20Symbol.CAPE;
  if (isNil(baseApy)) {
    return '-';
  }
  if (isCAPE) {
    return isNil(effectiveCapeApy) ? '-' : formatToPercentage(effectiveCapeApy);
  }

  if (isNativeTokenOrDerivatives) {
    return formatToPercentage(calculateEffectiveApy(apy, baseApy));
  }
  return formatToPercentage(baseApy);
};
