import { useMemo } from 'react';
import { flatMap } from 'lodash';

import { useMMProvider } from '../contexts/MMProvider';
import { IN_LIQUIDATION_HF_THRESHOLD, RECOVERY_NFT_HF_THRESHOLD } from '../config';

import { useWeb3Context } from '@/apps/paraspace/contexts';

export const useAccountLiquidationStatus = () => {
  const { account } = useWeb3Context();
  const {
    overviewUserInfo: { nftHealthFactor, healthFactor },
    nftInfoMap
  } = useMMProvider();

  const auctionedAssetsCount = useMemo(
    () => flatMap(nftInfoMap, it => it.auctionedTokens).length,
    [nftInfoMap]
  );

  const inLiquidation = useMemo(() => {
    return (
      (healthFactor?.isLessThan(IN_LIQUIDATION_HF_THRESHOLD) ||
        (auctionedAssetsCount > 0 && nftHealthFactor?.isLessThan(RECOVERY_NFT_HF_THRESHOLD))) ??
      false
    );
  }, [healthFactor, auctionedAssetsCount, nftHealthFactor]);

  return {
    inLiquidation,
    account,
    healthFactor,
    nftHealthFactor
  };
};
