import { createContext, memo, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';
import BigNumber from 'bignumber.js';

import { V1cAPEConvertModal, V1cAPEConvertModalProps } from './V1cAPEConvertModal';
import { cAPEBalanceInfo, useV1cAPEBalanceInfo } from './hooks';

import { Maybe } from '@/apps/paraspace/typings/basic';

type V1cAPEConvertProviderProps = {
  children: ReactNode;
};

type V1cAPEConvertContextValue = {
  convertV1cAPE: (amount: BigNumber, from: string, to: string) => Promise<void>;
  balanceInfo: Maybe<cAPEBalanceInfo>;
};

const V1cAPEConvertContext = createContext<V1cAPEConvertContextValue>({
  convertV1cAPE: (_amount, _from: string, _to) => {
    throw new Error('not implemented yet');
  },
  balanceInfo: null
});

const defaultModalProps = {
  isOpen: false,
  onClose: noop
} as V1cAPEConvertModalProps;

export const V1cAPEConvertProvider = memo(({ children }: V1cAPEConvertProviderProps) => {
  const [modalProps, setModalProps] = useState<V1cAPEConvertModalProps>(defaultModalProps);
  const { refreshUserV1cAPEBalanceInfos, balanceInfo } = useV1cAPEBalanceInfo();

  const convertV1cAPE = useCallback(
    (amount: BigNumber, from: string, to: string) => {
      return new Promise<void>((resolve, reject) => {
        setModalProps({
          isOpen: true,
          formData: {
            amount,
            from,
            to
          },
          onFinish: () => {
            refreshUserV1cAPEBalanceInfos();
            resolve();
          },
          onError: () => {
            refreshUserV1cAPEBalanceInfos();
            reject();
          },
          onClose: () => {
            setModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
          }
        });
      });
    },
    [refreshUserV1cAPEBalanceInfos]
  );

  const contextValue = useMemo(
    () => ({ convertV1cAPE, balanceInfo }),
    [balanceInfo, convertV1cAPE]
  );

  return (
    <V1cAPEConvertContext.Provider value={contextValue}>
      <V1cAPEConvertModal {...modalProps} />
      {children}
    </V1cAPEConvertContext.Provider>
  );
});

export const useV1cAPEConvertProvider = () => useContext(V1cAPEConvertContext);
