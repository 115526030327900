import { useCallback, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';

import { StakeBakcFormData } from '../StakeModal';

import { validate } from './validate';

import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { useApeTokenStakingInfo } from '@/apps/paraspace/pages/OfficialPairing/hook/useApeTokenStakingInfo';
import { ERC20Symbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useStakeInfoList } from '@/apps/paraspace/pages/OfficialPairing/StakeInfoListProvider/StakeInfoListProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useEOABalances, useUserBalances } from '@/apps/paraspace/pages/contexts';
import { zero } from '@/apps/paraspace/consts/values';

export const useStatesAndHandlers = ({
  bakcTokenId,
  bakcSource,
  apeCoinSource,
  onSubmit
}: {
  bakcTokenId: number;
  bakcSource: WalletType;
  apeCoinSource: WalletType;
  onSubmit: (formData: StakeBakcFormData) => void;
}) => {
  const { stakingInfoList } = useStakeInfoList();
  const selectorList = useMemo(() => stakingInfoList.filter(i => !i.pairedBakc), [stakingInfoList]);
  const defaultSelector = useMemo(() => selectorList[0], [selectorList]);

  const [selectedApe, setSelectedApe] = useState(defaultSelector);
  const [amountFromBalance, setAmountFromBalance] = useState<Maybe<number>>(null);

  const handleSubmit = useCallback(() => {
    onSubmit({
      amountFromBalance: amountFromBalance!,
      tokenId: selectedApe.tokenId!,
      symbol: selectedApe.symbol!,
      bakcTokenId,
      bakcSource,
      apeCoinSource,
      apeSource: selectedApe.source!
    });
  }, [amountFromBalance, bakcTokenId, onSubmit, selectedApe, bakcSource, apeCoinSource]);

  const handleBalanceAmountChange = useCallback(amount => {
    setAmountFromBalance(amount);
  }, []);

  const handleBakcPairChange = useCallback(token => {
    setSelectedApe(token);
  }, []);

  const { erc20BalanceMap: eoaErc20BalanceMap } = useEOABalances();
  const { erc20BalanceMap: aaErc20BalanceMap } = useUserBalances();
  const eoaBalance = eoaErc20BalanceMap?.[ERC20Symbol.APE] ?? zero;
  const aaBalance = aaErc20BalanceMap?.[ERC20Symbol.APE] ?? zero;
  const balance = apeCoinSource === 'AA' ? aaBalance : eoaBalance;

  const { account } = useWeb3Context();
  const [stakingInfo] = useApeTokenStakingInfo({
    symbol: ERC721Symbol.BAKC,
    tokenId: bakcTokenId ? String(bakcTokenId) : null,
    owner: account
  });

  const { stakedAmount = null, stakeLimit = null, apy = null } = stakingInfo || {};

  const remainingLimit = stakeLimit?.minus(stakedAmount ?? 0) ?? null;
  const maxValue = balance?.gt(remainingLimit ?? 0) ? remainingLimit : balance;

  const handleMaximizeAmountFromBalance = useCallback(() => {
    setAmountFromBalance(
      maxValue?.decimalPlaces(MAXIMUM_BALANCE_DECIMALS, BigNumber.ROUND_FLOOR)?.toNumber() ?? 0
    );
  }, [maxValue]);

  const errors = useMemo(
    () =>
      validate(
        {
          tokenId: selectedApe?.tokenId,
          amountFromBalance
        } ?? {},
        { stakeLimit: stakeLimit?.toNumber() ?? 0 }
      ),
    [amountFromBalance, selectedApe?.tokenId, stakeLimit]
  );

  return {
    errors,
    selectorList,
    defaultSelector,
    balance,
    remainingLimit,
    apy,
    maxValue,
    amountFromBalance,
    handleSubmit,
    handleBalanceAmountChange,
    handleBakcPairChange,
    handleMaximizeAmountFromBalance
  };
};
