import { Image } from '@parallel-mono/components';

import usApeLogoSvg from '../assets/us-ape-logo-home.svg';

import { HeaderWrapper } from '@/apps/paraspace/components';

export const USApeHeader = () => {
  return (
    <HeaderWrapper top="0">
      <Image src={usApeLogoSvg} height="40px" />
    </HeaderWrapper>
  );
};
