import { useCallback, useState } from 'react';
import { noop } from 'lodash';

import { NftInfo, Offer } from '../../../types';
import { ViewAllOffersModalProps } from '../modals';

const defaultViewAllOffersModalProps: ViewAllOffersModalProps = {
  onClose: noop,
  isOpen: false,
  inLiquidation: false
};

export const useViewAllOffers = (): [
  ViewAllOffersModalProps,
  (offer: Offer, nftInfo: NftInfo, inLiquidation: boolean) => Promise<void>
] => {
  const [viewAllOffersModalProps, setViewAllOffersModalProps] = useState<ViewAllOffersModalProps>(
    defaultViewAllOffersModalProps
  );

  const viewAllOffers = useCallback(
    (topOffer: Offer, nftInfo: NftInfo, inLiquidation: boolean) => {
      return new Promise<void>(resolve => {
        setViewAllOffersModalProps(prev => ({
          ...prev,
          isOpen: true,
          topOffer,
          nftInfo,
          inLiquidation,
          onClose: () => {
            setViewAllOffersModalProps(prevState => ({
              ...prevState,
              isOpen: false
            }));
          },
          onFinish: resolve
        }));
      });
    },
    [setViewAllOffersModalProps]
  );
  return [viewAllOffersModalProps, viewAllOffers];
};
