import { Stack, StackProps, Stepper } from '@parallel-mono/components';
import { ReactNode, memo, useCallback, useMemo, useState } from 'react';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { MovePositionFromBendDAOSubmitter } from '@/apps/paraspace/pages/Credit/MoneyMarket/formSubmitters';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ApproveERC721FromEOASubmitter } from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

type SupplyNftFromBendDAOFormSubmitterProps = Omit<StackProps, 'children' | 'onError'> & {
  formData: {
    symbol: ERC721Symbol;
    tokens: {
      tokenId: number;
      loanId: Maybe<string>;
    }[];
  };
  onFinish: () => void;
  onError: () => void;
};

export const SupplyNftFromBendDAOFormSubmitter = memo(
  ({
    onFinish,
    onError,
    formData: { symbol, tokens },
    ...others
  }: SupplyNftFromBendDAOFormSubmitterProps) => {
    const { nftInfoMap } = useMMProvider();
    const { collectionName = '', address = '' } = nftInfoMap[symbol] ?? {};
    const loanIds = useMemo(
      () => tokens.map(it => it.loanId).filter(it => it !== null) as string[],
      [tokens]
    );
    const [step, setStep] = useState(0);

    const handleNext = useCallback(() => {
      setStep(curr => curr + 1);
    }, []);

    const tokenIds = useMemo(() => tokens.map(it => it.tokenId), [tokens]);
    const steps: {
      title: ReactNode;
      description: ReactNode;
      content: ReactNode;
    }[] = useMemo(
      () => [
        {
          title: 'Step 1',
          description: 'Approve ERC721',
          content: (
            <ApproveERC721FromEOASubmitter
              formData={{
                name: collectionName,
                assetAddress: address,
                ids: tokenIds
              }}
              onError={onError}
              onFinish={handleNext}
            />
          )
        },
        {
          title: 'Step 2',
          description: 'Repay BendDAO debt',
          content: (
            <MovePositionFromBendDAOSubmitter
              formData={loanIds}
              onError={onError}
              onFinish={onFinish}
            />
          )
        }
      ],
      [loanIds, onError, onFinish, handleNext, collectionName, address, tokenIds]
    );

    return (
      <Stack {...others}>
        <Stepper steps={steps} step={step} />
        {steps[step].content}
      </Stack>
    );
  }
);
