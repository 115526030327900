import { forwardRef, useCallback, useMemo } from 'react';
import { Card, useBreakpoints, Image } from '@parallel-mono/components';
import { chain, isEmpty } from 'lodash';

import { useMoneyMarketActions } from '../../contexts';
import { getExpandableTokensInfo } from '../utils';

import TokensTable from './TokensTable';
import CardForMobile from './Mobile';
import { mergeTokenDerivatives } from './utils';

import { useAppConfig } from '@/apps/paraspace/hooks';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Info, ERC20Symbol } from '@/apps/paraspace/typings';
import { ERC20TableRow } from '@/apps/paraspace/pages/Credit/MoneyMarket/ERC20Table/types';
import { TokenCategory } from '@/apps/paraspace/config';

export type Handlers = {
  handleBorrow: (data: ERC20Info) => void;
  handleBorrowNativeTokenDerivatives: (derivative: ERC20Symbol) => Promise<void>;
  handleSupplyERC20FromAA: (symbol: ERC20Symbol) => void;
  handleSupplyERC20FromEOA: (symbol: ERC20Symbol) => void;
};

const ERC20Table = forwardRef<HTMLDivElement, {}>((_, ref) => {
  const { mobile } = useBreakpoints();
  const { erc20InfoMap } = useMMProvider();
  const { borrowNativeTokenDerivatives, supplyERC20, borrowERC20 } = useMoneyMarketActions();

  const { erc20Config } = useAppConfig();
  const tokens: ERC20TableRow[] = useMemo(() => {
    const erc20Tokens = chain(erc20Config)
      .mapValues((_erc20, symbol) => erc20InfoMap[symbol])
      .values()
      .filter(each => each && !erc20Config[each.symbol]!.isFakeToken)
      .value();

    const expandableTokens = chain(erc20Tokens)
      .groupBy(erc20 => {
        // group the expandable tokens
        if (erc20.tokenCategory) {
          return erc20.tokenCategory as TokenCategory;
        }
        // other single token
        return 'normalToken';
      })
      .mapValues((value, key) => {
        if (key === 'normalToken') {
          return value;
        }
        const { icon, displayName } = getExpandableTokensInfo(key as any);
        return mergeTokenDerivatives({
          tokenDerivatives: value,
          erc20Config,
          displayName,
          displayIcon: <Image width="2.5rem" height="2.5rem" src={icon} />
        }) as ERC20TableRow;
      })
      .values()
      .value();

    const flattenNormalTokens = chain(expandableTokens)
      .flatten()
      .orderBy((token: ERC20TableRow) => isEmpty(token.subRows))
      .value();

    return flattenNormalTokens;
  }, [erc20Config, erc20InfoMap]);

  const handleSupplyERC20FromAA = useCallback(
    (symbol: ERC20Symbol) => supplyERC20(symbol, 'AA'),
    [supplyERC20]
  );

  const handleSupplyERC20FromEOA = useCallback(
    (symbol: ERC20Symbol) => supplyERC20(symbol, 'EOA'),
    [supplyERC20]
  );

  const handleBorrow = useCallback(
    (data: ERC20Info) => {
      borrowERC20(data.symbol);
    },
    [borrowERC20]
  );

  const handleBorrowNativeTokenDerivatives = useCallback(
    (derivative: ERC20Symbol) => borrowNativeTokenDerivatives(derivative),
    [borrowNativeTokenDerivatives]
  );

  const handlers = useMemo(
    () => ({
      handleSupplyERC20FromAA,
      handleSupplyERC20FromEOA,
      handleBorrow,
      handleBorrowNativeTokenDerivatives
    }),
    [
      handleBorrow,
      handleSupplyERC20FromAA,
      handleSupplyERC20FromEOA,
      handleBorrowNativeTokenDerivatives
    ]
  );

  return (
    <Card border ref={ref}>
      {mobile ? (
        <CardForMobile tokens={tokens} handlers={handlers} />
      ) : (
        <TokensTable tokens={tokens} handlers={handlers} />
      )}
    </Card>
  );
});

export default ERC20Table;
