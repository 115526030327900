import { range } from 'lodash';
import { CanvasHTMLAttributes, memo, useCallback, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import tinycolor from 'tinycolor2';

export type PriceDecayGraphProps = {
  expDecayStart: number;
  expDecayEnd: number;
  linearDecayEnd: number;
  currentPrice: number | null;
  stepLinear: number;
  stepExp: number;
} & CanvasHTMLAttributes<HTMLCanvasElement>;

const createDecayFunc = ({
  expDecayStart,
  expDecayEnd,
  linearDecayEnd,
  stepExp,
  stepLinear
}: {
  expDecayStart: number;
  expDecayEnd: number;
  linearDecayEnd: number;
  stepExp: number;
  stepLinear: number;
}) => {
  const linearDecayStart = expDecayEnd;

  const expDecayFunc = (x: number) => expDecayStart * Math.E ** (0 - stepExp * x);
  const inversedExpDecayFunc = (y: number) => Math.log(y / expDecayStart) / -stepExp;

  const linearDecayFunc = (x: number) => linearDecayStart - stepLinear * x;
  const inversedLinearDecayFunc = (y: number) => (linearDecayStart - y) / stepLinear;

  const expDecayEndingX = inversedExpDecayFunc(expDecayEnd);
  return {
    decayFunc: (x: number) => {
      if (x < expDecayEndingX) {
        return expDecayFunc(x);
      }
      return linearDecayFunc(x - expDecayEndingX);
    },
    inversedDecayFunc: (y: number) => {
      if (y > expDecayEnd) {
        return inversedExpDecayFunc(y);
      }
      return expDecayEndingX + inversedLinearDecayFunc(y);
    },
    xRange: [0, expDecayEndingX + inversedLinearDecayFunc(linearDecayEnd)],
    yRange: [linearDecayEnd, expDecayStart]
  };
};

const draw = ({
  ctx,
  expDecayStart,
  expDecayEnd,
  linearDecayEnd,
  width: originalWidth,
  height: originalHeight,
  stepLinear,
  stepExp,
  currentPrice,
  color
}: {
  ctx: CanvasRenderingContext2D;
  expDecayStart: number;
  expDecayEnd: number;
  linearDecayEnd: number;
  width: number;
  height: number;
  stepLinear: number;
  stepExp: number;
  currentPrice: number | null;
  color: string;
}) => {
  const {
    decayFunc,
    inversedDecayFunc,
    xRange: [startX, endX],
    yRange: [startY, endY]
  } = createDecayFunc({
    expDecayStart,
    expDecayEnd,
    linearDecayEnd,
    stepLinear,
    stepExp
  });

  ctx.clearRect(0, 0, originalWidth, originalHeight);

  const currentX = currentPrice ? inversedDecayFunc(currentPrice) : null;
  const currentY = currentPrice;

  const padding = 8;
  const width = originalWidth - padding * 2;
  const height = originalHeight - padding * 2;

  const coordinatesTransform = new DOMMatrix()
    .translate(0, originalHeight)
    .scale(1, -1)
    .translate(padding, padding)
    .scale(width / (endX - startX), height / (endY - startY))
    .translate(-startX, -startY);

  const totalInterpolationCount = 50;

  const drawDecayChartInRange = (x0: number, x1: number) => {
    ctx.save();
    ctx.setTransform(coordinatesTransform);
    ctx.beginPath();
    ctx.moveTo(x0, decayFunc(x0));
    const interpolationCount = Math.floor((totalInterpolationCount * (x1 - x0)) / (endX - startX));
    range(1, interpolationCount + 1).forEach(index => {
      const x = x0 + ((x1 - x0) * index) / interpolationCount;
      const y = decayFunc(x);
      ctx.lineTo(x, y);
    });
    ctx.moveTo(x0, decayFunc(x0));
    ctx.closePath();
    ctx.restore();
  };

  const drawCircle = (x: number, y: number, radius: number) => {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
    ctx.closePath();
  };

  drawDecayChartInRange(startX, currentX ?? startX);
  ctx.setLineDash([]);
  ctx.lineWidth = 2;
  ctx.strokeStyle = color;
  ctx.lineJoin = 'round';
  ctx.lineCap = 'round';
  ctx.stroke();

  drawDecayChartInRange(currentX ?? startX, endX);
  ctx.setLineDash([0, 8, 8, 0]);
  ctx.stroke();

  if (currentX === null || currentY === null) {
    return;
  }

  const { x: currentXAfterTransform, y: currentYAfterTransform } =
    coordinatesTransform.transformPoint({ x: currentX, y: currentY });

  drawCircle(currentXAfterTransform, currentYAfterTransform, 8);
  ctx.fillStyle = tinycolor(color).setAlpha(0.2).toString();
  ctx.fill();

  drawCircle(currentXAfterTransform, currentYAfterTransform, 4);
  ctx.fillStyle = color;
  ctx.fill();
};

export const PriceDecayGraph = memo(
  ({
    expDecayStart,
    expDecayEnd,
    linearDecayEnd,
    currentPrice,
    stepExp,
    stepLinear,
    ...others
  }: PriceDecayGraphProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const {
      skin: {
        primary: { main: color }
      }
    } = useTheme();

    const drawOnCanvas = useCallback(() => {
      if (!canvasRef.current) return;

      const ctx = canvasRef.current.getContext('2d');

      if (ctx === null) return;

      draw({
        ctx,
        expDecayStart,
        expDecayEnd,
        linearDecayEnd,
        currentPrice,
        stepExp,
        stepLinear,
        width: canvasRef.current.width,
        height: canvasRef.current.height,
        color
      });
    }, [expDecayStart, expDecayEnd, linearDecayEnd, currentPrice, stepExp, stepLinear, color]);

    useEffect(() => {
      drawOnCanvas();
    }, [drawOnCanvas]);

    useEffect(() => {
      if (!canvasRef.current) {
        return undefined;
      }
      const resizeObserver = new ResizeObserver(() => {
        drawOnCanvas();
      });
      resizeObserver.observe(canvasRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }, [drawOnCanvas]);

    return <canvas ref={canvasRef} {...others} />;
  }
);
