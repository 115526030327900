import React, { ReactNode, memo, useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { mapKeys } from 'lodash';
import { ApolloQueryResult } from '@apollo/client';

import { useTokensInfoWithForgedPunk } from './useTokensInfoWithForgedPunk';
import { getTokensInfoWithForgedNativeToken } from './getTokensInfoWithForgedNativeToken';

import { ERC20Symbol, ERC721Symbol, TokenInfo } from '@/apps/paraspace/typings';
import {
  GetTokensBasicInfoQuery,
  useGetTokensBasicInfoQuery
} from '@/apps/paraspace/generated/graphql';
import { useAppConfig } from '@/apps/paraspace/hooks';

type TokensInfoContextValue = {
  tokensInfo: Record<string, TokenInfo> | null;
  refetch: () => Promise<ApolloQueryResult<GetTokensBasicInfoQuery>>;
  loading: boolean;
};

const TokensInfoContext = React.createContext<TokensInfoContextValue>({
  tokensInfo: null,
  refetch: async () => {
    throw new Error('Not implemented yet');
  },
  loading: false
});

type TokensInfoProviderProps = {
  children: ReactNode;
  pollingInterval: number;
};

export const TokensInfoProvider = memo(({ children, pollingInterval }: TokensInfoProviderProps) => {
  const { data, refetch, loading } = useGetTokensBasicInfoQuery({
    pollInterval: pollingInterval
  });

  const { erc721Config, erc20Config } = useAppConfig();
  const getTokensInfoWithForgedPunk = useTokensInfoWithForgedPunk();

  const tokensInfo = useMemo(() => {
    const infos: TokenInfo[] =
      data?.tokensBasicInfo.map(it => ({
        displayName:
          erc721Config[it.symbol as ERC721Symbol]?.collectionName ??
          erc20Config[it.symbol as ERC20Symbol]?.displayName ?? // display priority is higher than contractName
          erc20Config[it.symbol as ERC20Symbol]?.contractName ??
          it.symbol,
        collectionName: erc721Config[it.symbol as ERC721Symbol]?.collectionName ?? '',
        symbol: it.symbol,
        address: it.address,
        variableDebtTokenAddress: it.variableDebtTokenAddress,
        xTokenAddress: it.xTokenAddress,
        priceInUsd: new BigNumber(it.priceInUsd),
        priceInETH: new BigNumber(it.priceInETH),
        supplyApyRate: new BigNumber(it.supplyApyRate),
        supplyRewardRate: new BigNumber(it.supplyRewardRate),
        borrowApyRate: new BigNumber(it.borrowApyRate),
        borrowRewardRate: new BigNumber(it.borrowRewardRate),
        availableLiquidity: new BigNumber(it.availableLiquidity),
        variableBorrowIndex: new BigNumber(it.variableBorrowIndex),
        liquidityIndex: new BigNumber(it.liquidityIndex),
        borrowingEnabled: it.borrowingEnabled,
        borrowUp: new BigNumber(it.borrowUp),
        baseLTVasCollateral: new BigNumber(it.baseLTVasCollateral),
        reserveLiquidationThreshold: new BigNumber(it.reserveLiquidationThreshold),
        decimals: it.decimals,
        totalLiquidityUSD: new BigNumber(it.totalLiquidityUSD),
        totalDebt: new BigNumber(it.totalDebt),
        totalDebtUSD: new BigNumber(it.totalDebt),
        hasTokenSpecificInfos: false,
        timeLockStrategyData: {
          lastResetTimestamp: it.timeLockStrategyData.lastResetTimestamp,
          maxWaitTime: it.timeLockStrategyData.maxWaitTime,
          midThreshold: new BigNumber(it.timeLockStrategyData.midThreshold),
          midWaitTime: it.timeLockStrategyData.midWaitTime,
          minThreshold: new BigNumber(it.timeLockStrategyData.minThreshold),
          minWaitTime: it.timeLockStrategyData.minWaitTime,
          period: it.timeLockStrategyData.period,
          poolPeriodLimit: new BigNumber(it.timeLockStrategyData.poolPeriodLimit),
          poolPeriodWaitTime: it.timeLockStrategyData.poolPeriodWaitTime,
          totalAmountInCurrentPeriod: new BigNumber(
            it.timeLockStrategyData.totalAmountInCurrentPeriod
          )
        },
        liquidityRate: new BigNumber(it.liquidityRate),
        lastUpdateTimestamp: it.lastUpdateTimestamp,
        tokenSpecificInfos: {}
      })) ?? ([] as TokenInfo[]);

    if (infos.length < 1) return null;
    return mapKeys(infos, it => it.symbol) as Record<string, TokenInfo>;
  }, [data?.tokensBasicInfo, erc20Config, erc721Config]);

  const tokensInfoWithForgedErc20Tokens = getTokensInfoWithForgedNativeToken(
    tokensInfo,
    erc20Config
  );

  const tokensInfoWithForgedPunk = erc721Config[ERC721Symbol.PUNK]
    ? getTokensInfoWithForgedPunk(tokensInfoWithForgedErc20Tokens)
    : tokensInfoWithForgedErc20Tokens;

  const value = useMemo(
    () => ({
      tokensInfo: tokensInfoWithForgedPunk,
      loading,
      refetch
    }),
    [refetch, loading, tokensInfoWithForgedPunk]
  );

  return <TokensInfoContext.Provider value={value}>{children}</TokensInfoContext.Provider>;
});

TokensInfoProvider.displayName = 'TokensInfoProvider';
export const useTokensInfo = () => useContext(TokensInfoContext);
