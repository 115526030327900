import { EthereumTransactionTypeExtended } from 'paraspace-utilities-contract-helpers';
import BigNumber from 'bignumber.js';
import { ParaXProvider } from 'parax-sdk';
import { providers, BigNumber as EthersBigNumber } from 'ethers';

const submitTransaction = async ({
  provider,
  tx
}: {
  provider: ParaXProvider;
  tx: EthereumTransactionTypeExtended;
}) => {
  const extendedTxData = await tx.tx();
  const { to, value, data } = extendedTxData;
  return provider.submitTransactionsWithParaAccount([
    { to: to!, value: new BigNumber(value ?? 0).toString(10), data: data! }
  ]);
};

export default submitTransaction;

export const submitEOATransaction = async ({
  provider,
  tx
}: {
  provider: providers.JsonRpcProvider;
  tx: EthereumTransactionTypeExtended;
}) => {
  const extendedTxData = await tx.tx();
  const { from, ...txData } = extendedTxData;
  const signer = provider.getSigner(from);
  const txResponse = await signer.sendTransaction({
    ...txData,
    value: txData.value ? EthersBigNumber.from(txData.value) : undefined
  });

  return txResponse;
};
