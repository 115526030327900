import { useCallback, useMemo } from 'react';
import { ethers } from 'ethers';
import { ERC20Service } from 'paraspace-utilities-contract-helpers';
import BigNumberJs from 'bignumber.js';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

const MAX_ALLOWANCE = ethers.constants.MaxUint256.toString();

/**
 * @deprecated use useERC20 hook instead
 */
const useLegacyERC20 = (assetAddr: string, from?: string) => {
  const { provider, account, submitEOATransactions, submitTransactions } = useWeb3Context();
  const contracts = useContractsMap();
  const service = useMemo(() => {
    if (!provider || !assetAddr) {
      return null;
    }

    return new ERC20Service(provider);
  }, [assetAddr, provider]);

  const approve = useCallback(
    async (spender: string = contracts.PoolProxy, amount: string = MAX_ALLOWANCE) => {
      if (!provider || !service) {
        return null;
      }

      const normalizedAmount = new BigNumberJs(amount).gt(MAX_ALLOWANCE) ? MAX_ALLOWANCE : amount;
      const tx = await service.approve({
        user: from ?? account,
        spender,
        token: assetAddr,
        amount: normalizedAmount
      });
      const txRes = from ? submitEOATransactions(tx) : submitTransactions([tx]);
      return txRes;
    },
    [
      contracts.PoolProxy,
      provider,
      service,
      from,
      account,
      assetAddr,
      submitEOATransactions,
      submitTransactions
    ]
  );

  const getAllowance = useCallback(
    async (spender: string = contracts.PoolProxy, user = account) => {
      if (!service) {
        return '0';
      }
      return service.getAllowance({
        user,
        token: assetAddr,
        spender
      });
    },
    [assetAddr, contracts.PoolProxy, account, service]
  );

  const getBalance = useCallback(
    async (user: string = account, token: string = assetAddr) => {
      if (!service) {
        return new BigNumberJs(0);
      }
      return new BigNumberJs((await service.balanceOf({ user, token })).toString());
    },
    [assetAddr, account, service]
  );

  return { approve, getAllowance, getBalance };
};

export default useLegacyERC20;
