import { OrderComponents } from 'paraspace-seaport-js/lib/types';
import { memo, useCallback } from 'react';

import { FormSubmitter } from '@/apps/paraspace/components';
import { Order, useCancelOrderMutation } from '@/apps/paraspace/generated/graphql';
import { Offer } from '@/apps/paraspace/pages/Shop/types';
import useSeaport from '@/apps/paraspace/pages/hooks/useSeaport';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig, getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

type CancelOfferSubmitterProps = {
  offer: Offer;
  onFinish: () => void;
  onError: (err: Maybe<ErrorConfig> | Error) => void;
};

export const CancelOfferSubmitter = memo(
  ({ offer, onFinish, onError }: CancelOfferSubmitterProps) => {
    const { order } = offer;
    const { protocolData, hash } = order as Order;

    const { seaportSDK, cancelOrders } = useSeaport();
    const [cancelOrderMutation] = useCancelOrderMutation({
      refetchQueries: ['getOrders']
    });

    const handleFinish = useCallback(async () => {
      const statusRet = await seaportSDK?.getOrderStatus(hash);
      if (!statusRet?.isCancelled) {
        onError(getUserFriendlyError(new Error('The order cancel action failed')));
        return;
      }
      await cancelOrderMutation({
        variables: { hash }
      });
      onFinish();
    }, [onFinish, cancelOrderMutation, hash, onError, seaportSDK]);

    const cancelOrder = useCallback(async () => {
      if (!protocolData || !protocolData.parameters) {
        throw new Error('The order parameters is not valid');
      }

      const originalStatus = await seaportSDK?.getOrderStatus(hash);

      if (
        originalStatus?.isCancelled ||
        originalStatus?.totalFilled === originalStatus?.totalSize
      ) {
        return null;
      }

      return cancelOrders([protocolData?.parameters] as unknown as OrderComponents[]);
    }, [seaportSDK, protocolData, hash, cancelOrders]);

    return (
      <FormSubmitter
        submit={cancelOrder}
        inProgressMessage="Processing"
        onError={onError}
        onFinish={handleFinish}
      />
    );
  }
);
