import { useMemo } from 'react';
import { findKey } from 'lodash';

import { useAppConfig } from '@/apps/paraspace/hooks';
import { ERC20Symbol } from '@/apps/paraspace/typings';

export const useNativeTokenSymbol = () => {
  const { erc20Config } = useAppConfig();

  const nativeTokenSymbol = useMemo(
    () => findKey(erc20Config, token => token.isNativeToken) as ERC20Symbol,
    [erc20Config]
  );

  return nativeTokenSymbol;
};
