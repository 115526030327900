import { useMemo, useEffect, useCallback, useState } from 'react';
import { keyBy, map, mapValues, zipWith } from 'lodash';
import { ethers } from 'ethers';

import { DelegationMap } from '../../types';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useNftDelegationQuery } from '@/apps/paraspace/pages/hooks/nftDelegation';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const useAccountDelegationInfo = (): {
  delegationMap: Maybe<DelegationMap>;
  loading: boolean;
  refreshDelegationMap: () => Promise<void>;
} => {
  const [state, setState] = useState<{
    delegationMap: Maybe<DelegationMap>;
    loading: boolean;
  }>({
    delegationMap: null,
    loading: true
  });

  const { getDategatesForTokens } = useNftDelegationQuery();
  const { nftInfoMap, userInfoLoaded } = useMMProvider();

  const tokenAbstracts = useMemo(() => {
    return map(nftInfoMap, (it, symbol) => ({
      symbol: symbol as ERC721Symbol,
      nTokenAddr: it.xTokenAddress,
      tokenIds: it.nftSuppliedList ?? []
    }));
  }, [nftInfoMap]);

  const stabilizedTokenAbstracts = useStabilizedSnapshot(tokenAbstracts)
    .filter(i => i.tokenIds.length !== 0)
    .filter(i =>
      [
        ERC721Symbol.BAYC,
        ERC721Symbol.MAYC,
        ERC721Symbol.BAKC,
        ERC721Symbol.OTHERDEED,
        ERC721Symbol.EXP,
        ERC721Symbol.KODA
      ].includes(i.symbol)
    );

  const refreshDelegationMap = useCallback(async () => {
    if (!userInfoLoaded) {
      return;
    }
    const delegations = await getDategatesForTokens(stabilizedTokenAbstracts);

    const delegationInfos = zipWith(
      stabilizedTokenAbstracts,
      delegations,
      (abstract, delegation) => ({
        symbol: abstract.symbol,
        delegations: abstract.tokenIds.map((tokenId, index) => {
          const delegate =
            delegation[0][index].to === ethers.constants.AddressZero
              ? null
              : delegation[0][index].to;
          return {
            tokenId,
            delegate
          };
        })
      })
    );
    const calculatedDelegationMap = mapValues(keyBy(delegationInfos, 'symbol'), 'delegations');
    setState({
      delegationMap: calculatedDelegationMap as DelegationMap,
      loading: false
    });
  }, [stabilizedTokenAbstracts, getDategatesForTokens, userInfoLoaded]);

  useEffect(() => {
    refreshDelegationMap();
  }, [refreshDelegationMap]);

  return {
    delegationMap: state.delegationMap,
    loading: state.loading,
    refreshDelegationMap
  };
};
