import { ListingToken } from '../../types';

import { ERC721Symbol, NFTInfoMap } from '@/apps/paraspace/typings';

export const calculateFinalFormDataWithNftInfoMap = (
  listingTokens: ListingToken[],
  nftInfoMap: NFTInfoMap
) => {
  return listingTokens.map(item => ({
    ...item,
    isSupplied: nftInfoMap[item.symbol]?.nftSuppliedList?.includes(item.tokenId),
    tokenAddress: nftInfoMap[item.symbol]?.address,
    nTokenAddress: nftInfoMap[item.symbol]?.xTokenAddress
  }));
};

/* For the the CRYPTOPUNKS listing, we should treat it as WPUNKS when creating listing order,
   because the CRYPTOPUNKS has been supplied before the listing stepper
*/
export const formatOrderCreatingTokenParams = (tokens: ListingToken[]): ListingToken[] => {
  return tokens.map(token => ({
    ...token,
    symbol: token.symbol === ERC721Symbol.PUNK ? ERC721Symbol.WPUNKS : token.symbol
  }));
};
