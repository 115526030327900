import { CryptoIcon } from '@parallel-mono/business-components';
import { Inline, Stack, H5, SmallText } from '@parallel-mono/components';
import { useMemo } from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';
import isNil from 'lodash/isNil';

import { ApeListing, BakcListing, ListingType, ListingTypeToListingMap } from '../types';

import { PendingTag } from './ContractBanner';

import ApeCoinPlaceholderSvg from '@/apps/paraspace/pages/ApePairing/Images/ape-coin-placeholder.svg';
import { ImageThumbnail, NFTThumbnail, AccountPill } from '@/apps/paraspace/components';
import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC20Symbol,
  ERC721Symbol,
  genericMemo
} from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/ApePairing/consts';

const ApeCoinPlaceholder = styled(ImageThumbnail)<{ size: 'small' | 'medium' }>`
  width: ${({ size }) => (size === 'small' ? '3rem' : '5rem')};
  height: ${({ size }) => (size === 'small' ? '3rem' : '5rem')};
  border-radius: ${({ size }) => (size === 'small' ? '0.25rem' : '0.5rem')};
`;

const ShareInfo = styled(Inline)`
  white-space: nowrap;
`;

type ListingInfoProps<T extends ListingType> = {
  stakingPool: ApeStakingTokenSymbol;
  listingType: T;
  listing: Maybe<ListingTypeToListingMap<T>>;
  account: string;
  poolApy: BigNumber;
  predictedShare?: Maybe<BigNumber>;
};

export const ListingInfo = genericMemo(
  <T extends ListingType>({
    stakingPool,
    listing,
    listingType,
    account,
    poolApy,
    predictedShare
  }: ListingInfoProps<T>) => {
    const iconSize = stakingPool === ERC721Symbol.BAKC ? 'small' : 'medium';
    const listingBanner = useMemo(() => {
      if (!listing && listingType === ListingType.ApeCoinListing) {
        return <ApeCoinPlaceholder size={iconSize} src={ApeCoinPlaceholderSvg} />;
      }
      if (listingType === ListingType.ApeCoinListing) {
        return <CryptoIcon symbol="APE" size={iconSize === 'small' ? '3rem' : '5rem'} />;
      }
      if (listingType === ListingType.ApeListing) {
        return (
          <NFTThumbnail
            size={iconSize}
            symbol={(listing?.symbol || ERC721Symbol.BAYC) as ApeStakingMainTokenSymbol}
            tokenId={(listing as ApeListing)?.tokenId}
          />
        );
      }

      return (
        <NFTThumbnail
          size={iconSize}
          symbol={ERC721Symbol.BAKC}
          tokenId={(listing as BakcListing)?.tokenId}
        />
      );
    }, [listing, listingType, iconSize]);

    const stakeLimit = useMemo(() => new BigNumber(STAKE_LIMIT[stakingPool!] ?? 0), [stakingPool]);

    const listingSymbolName = useMemo(() => {
      if (listingType === ListingType.ApeCoinListing) {
        return ERC20Symbol.APE;
      }
      if (stakingPool === ERC721Symbol.BAKC) {
        if (listingType === ListingType.ApeListing) {
          return 'BAYC/MAYC';
        }
        return ERC721Symbol.BAKC;
      }
      return stakingPool;
    }, [stakingPool, listingType]);

    const share = listing?.share ?? predictedShare;
    return (
      <Inline width="100%" alignItems="flex-start">
        {listingBanner}
        <Stack gap="0.5rem">
          <SmallText>
            {listingType === ListingType.ApeCoinListing && `${formatNumber(stakeLimit ?? 0)}`}
            {listingType !== ListingType.ApeCoinListing && listing
              ? `#${(listing as ApeListing).tokenId}`
              : ` ${listingSymbolName}`}
          </SmallText>
          {listing?.offerer ? (
            <AccountPill address={listing.offerer} isOwner={account === listing.offerer} />
          ) : (
            <PendingTag skin="warning" size="small">
              <SmallText fontWeight="bold">Pending</SmallText>
            </PendingTag>
          )}

          <Stack gap="0rem">
            <SmallText skin="secondary">Comp. APY</SmallText>
            {!isNil(share) && (
              <ShareInfo gap="0.25rem" alignItems="baseline">
                <H5>{formatNumber(share.times(poolApy), { output: 'percent' })}</H5>
                <SmallText skin="secondary">
                  {`· ${formatNumber(share, {
                    output: 'percent'
                  })} share`}
                </SmallText>
              </ShareInfo>
            )}
            {isNil(share) && <H5>-</H5>}
          </Stack>
        </Stack>
      </Inline>
    );
  }
);
