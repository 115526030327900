import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { noop, uniq, uniqueId } from 'lodash';
import { ParaXProvider } from 'parax-sdk';

import { Maybe } from '../../typings/basic';
import { useCreateParaAccountMutation, useParaAccountsLazyQuery } from '../../generated/graphql';
import { useEOAProvider } from '../EOAProvider';

import { useCachedAAAccounts } from './hooks';

const PARAX_ACCOUNT_LOCAL_STORAGE_KEY = 'selected_account';

type DefaultAAContextValue = {
  accounts: string[];
  account: Maybe<string>;
  switchAccount: (account: string) => void;
  mockAccount: (params: {
    EOAAccount: string;
    paraAccount: string;
    createdBlockHeight: number;
    createdHash: string;
    salt: string;
  }) => void;
  refreshAccounts: () => Promise<void>;
  loading: boolean;
  provider: Maybe<ParaXProvider>;
};

type AAContextValue = Omit<DefaultAAContextValue, 'provider'> & {
  provider: ParaXProvider;
};

const AAContext = createContext<DefaultAAContextValue>({
  accounts: [],
  account: null,
  switchAccount: noop,
  mockAccount: noop,
  refreshAccounts: () => Promise.resolve(),
  loading: true,
  provider: null
});

export const AAProvider = memo(({ children }: { children: ReactNode }) => {
  const { chainId, account: EOAAccount, provider: EOAProvider } = useEOAProvider();

  const [cachedAccounts, updateCachedAccounts] = useCachedAAAccounts(chainId, EOAAccount);

  const [{ accounts: remoteAccounts, account: activeAccount, loading }, setState] = useState<
    Pick<AAContextValue, 'accounts' | 'account' | 'loading'>
  >({
    accounts: [],
    account: null,
    loading: true
  });

  const accounts = useMemo(
    () => uniq(remoteAccounts.concat(cachedAccounts)),
    [remoteAccounts, cachedAccounts]
  );

  const [queryParaAccounts] = useParaAccountsLazyQuery({});
  const [createParaAccountMutation] = useCreateParaAccountMutation();

  const getParaAccounts = useCallback(
    async (account: string) => {
      if (account) {
        const result = await queryParaAccounts({
          variables: {
            filter: {
              EOAAccount: account
            }
          },
          fetchPolicy: 'no-cache'
        });
        return result.data?.paraAccounts.map(it => it.paraAccount);
      }
      return [];
    },
    [queryParaAccounts]
  );

  const latestCallIdRef = useRef<string>();
  const refreshAccounts = useCallback(async () => {
    const currentCallId = uniqueId();
    latestCallIdRef.current = currentCallId;
    try {
      const latestAccounts = await getParaAccounts(EOAAccount);
      const loadingParaAccount = !latestAccounts;

      if (latestCallIdRef.current === currentCallId) {
        setState(s => ({ ...s, accounts: latestAccounts ?? [], loading: loadingParaAccount }));
      }
    } catch {
      if (latestCallIdRef.current === currentCallId) {
        setState(s => ({ ...s, loading: true }));
      }
    }
  }, [EOAAccount, getParaAccounts]);

  useEffect(() => {
    refreshAccounts();
  }, [refreshAccounts, chainId]);

  const provider = useMemo(
    () => new ParaXProvider(EOAProvider, EOAAccount, activeAccount),
    [EOAProvider, EOAAccount, activeAccount]
  );

  const switchAccount = useCallback(
    (accountToBe: string) => {
      setState(s => {
        localStorage.setItem(`${EOAAccount}:${PARAX_ACCOUNT_LOCAL_STORAGE_KEY}`, accountToBe);
        return { ...s, account: accountToBe };
      });
    },
    [EOAAccount]
  );

  const mockAccount = useCallback(
    ({
      EOAAccount: paraAccountOwner,
      paraAccount,
      createdBlockHeight,
      createdHash,
      salt
    }: {
      EOAAccount: string;
      paraAccount: string;
      createdBlockHeight: number;
      createdHash: string;
      salt: string;
    }) => {
      updateCachedAccounts(cachedAccounts.concat([paraAccount]));
      createParaAccountMutation({
        variables: {
          inputParaAccount: {
            EOAAccount: paraAccountOwner,
            createdBlockHeight: createdBlockHeight.toString(),
            createdHash,
            paraAccount,
            salt
          }
        }
      });
    },
    [updateCachedAccounts, cachedAccounts, createParaAccountMutation]
  );

  useEffect(() => {
    const storageAccount = localStorage.getItem(`${EOAAccount}:${PARAX_ACCOUNT_LOCAL_STORAGE_KEY}`);
    if (!activeAccount || !accounts.includes(activeAccount)) {
      const selectedAccount = accounts.includes(storageAccount ?? '')
        ? storageAccount
        : accounts[0];
      setState(s => ({ ...s, account: selectedAccount ?? null }));
    }
  }, [EOAAccount, accounts, activeAccount]);

  const contextValue = useMemo(
    () => ({
      accounts,
      account: activeAccount,
      switchAccount,
      refreshAccounts,
      mockAccount,
      loading,
      provider
    }),
    [accounts, activeAccount, switchAccount, loading, provider, refreshAccounts, mockAccount]
  );

  return <AAContext.Provider value={contextValue}>{children}</AAContext.Provider>;
});

export const useAAProvider = () => useContext(AAContext) as AAContextValue;
