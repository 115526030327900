import { Modal, ModalProps } from '@parallel-mono/components';
import { memo, useMemo } from 'react';

import { ConfirmCancelForm } from './ConfirmCancelForm';
import { CancelOfferSubmitter } from './CancelOfferSubmitter';
import { Thumbnail } from './Thumbnail';

import {
  ErrorState,
  SuccessState,
  useCollectAndSubmitProcedure,
  CollectAndSubmitProcedurePhase
} from '@/apps/paraspace/components';
import { Offer } from '@/apps/paraspace/pages/Shop/types';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type CancelOfferModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  offer?: Offer;
  onFinish: () => void;
  onError: () => void;
  onClose: () => void;
};

export const CancelOfferModal = memo(
  ({ onClose, isOpen, onFinish, onError, offer, ...others }: CancelOfferModalProps) => {
    const { considerationItem } = offer ?? {};

    const { phase, handleFormSubmit, handleSubmitFailed, handleSubmitSuccess, submittingError } =
      useCollectAndSubmitProcedure<void, void, Maybe<ErrorConfig> | Error>({
        running: isOpen,
        onFinish,
        onError
      });

    const errorMsg = useMemo(() => {
      if (!submittingError) return '';
      if (submittingError instanceof Error) return submittingError.message;
      return submittingError.userFriendlyErrorMsg;
    }, [submittingError]);

    return (
      <Modal title="Offer Cancel" size="32.5rem" isOpen={isOpen} {...others}>
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <ConfirmCancelForm offer={offer!} onSubmit={handleFormSubmit} onCancel={onClose} />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <CancelOfferSubmitter
            offer={offer!}
            onFinish={handleSubmitSuccess}
            onError={handleSubmitFailed}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            icon={<Thumbnail considerationItem={considerationItem!} />}
            desc="Offer Canceled!"
            actionButtonText="Done"
            onAction={onClose}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && (
          <ErrorState errorMsg={errorMsg} closeModal={onClose} />
        )}
      </Modal>
    );
  }
);
