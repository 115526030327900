import { InfoPanel } from '@parallel-mono/business-components';
import { Button, H4, H5, Stack, Text } from '@parallel-mono/components';
import { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { SupplyNftFormData } from './SupplyNftForm';

import { StackedIcons } from '@/apps/paraspace/components';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { SCROLL_TO_NAME } from '@/apps/paraspace/pages/Credit/pages';

const StyledText = styled(Text)`
  text-align: center;
`;

const SuccessState = ({
  onFinish,
  formData
}: {
  onFinish?: () => void;
  formData: SupplyNftFormData;
}) => {
  const navigate = useNavigate();

  const { symbol, supplyTokenIds, originalBorrowLimitInUsd, newBorrowLimitInUsd } = formData;
  const infos = useMemo(
    () => [
      {
        title: 'Borrow Limit Added',
        value: <H5>{formatToCurrency(newBorrowLimitInUsd.minus(originalBorrowLimitInUsd))}</H5>
      }
    ],
    [newBorrowLimitInUsd, originalBorrowLimitInUsd]
  );

  const assets = useMemo(
    () =>
      supplyTokenIds.map(tokenId => ({
        symbol,
        tokenId
      })),
    [supplyTokenIds, symbol]
  );

  const handleClick = useCallback(() => {
    onFinish?.();
    navigate('/', { state: { scrollTo: SCROLL_TO_NAME.ERC20_TABLE } });
  }, [navigate, onFinish]);

  return (
    <Stack gap="2rem">
      <Stack gap="0.25rem" alignItems="center" inset="0 2rem">
        <StackedIcons assets={assets} maxShow={4} />
        <H4>Successfully Supplied {supplyTokenIds.length} NFTs</H4>
        <StyledText skin="secondary">
          You can now use them as collateral and borrow ERC-20 assets on ParaSpace!
        </StyledText>
      </Stack>
      <InfoPanel skin="primary" infos={infos} />
      <Button size="large" block skin="primary" onClick={handleClick}>
        Go to Borrow
      </Button>
    </Stack>
  );
};

export default memo(SuccessState);
