import { useCallback, useState } from 'react';

import { WithdrawERC721ModalProps } from '../modals';

import { ERC721Symbol } from '@/apps/paraspace/typings';

export const useWithdrawERC721 = (): [
  WithdrawERC721ModalProps,
  (symbol: ERC721Symbol, name: string) => Promise<void>
] => {
  const [modalProps, setModalProps] = useState<WithdrawERC721ModalProps>({
    isOpen: false,
    symbol: ERC721Symbol.CRYPTOPUNKS,
    name: ''
  });

  const withdrawERC721 = useCallback((symbol: ERC721Symbol, name: string) => {
    return new Promise<void>((resolve, reject) => {
      setModalProps(curr => ({
        ...curr,
        isOpen: true,
        symbol,
        name,
        onError: reject,
        onFinish: resolve,
        onClose: () => {
          resolve();
          setModalProps(prev => ({ ...prev, isOpen: false }));
        }
      }));
    });
  }, []);

  return [modalProps, withdrawERC721];
};
