import { H5 } from '@parallel-mono/components';
import { FC, memo } from 'react';
import styled from 'styled-components';
import { CryptoIcon } from '@parallel-mono/business-components';

import { ApeStakingMainAssetSymbol, ERC20Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { NFTThumbnail } from '@/apps/paraspace/components';

const StyledH5 = styled(H5)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    font-size: 0.75rem;
  `};
`;

type SelectedListingPillProps = {
  symbol: ApeStakingMainAssetSymbol;
  tokenId?: Maybe<number>;
};

export const SelectedListingPill: FC<SelectedListingPillProps> = memo(({ symbol, tokenId }) => {
  return (
    <>
      {symbol === ERC20Symbol.APE ? (
        <CryptoIcon symbol={symbol} size="1.5rem" />
      ) : (
        <NFTThumbnail symbol={symbol} tokenId={tokenId!} round size="xSmall" />
      )}
      <StyledH5 fontWeight="medium">
        {symbol}
        {tokenId && `#${tokenId}`}
      </StyledH5>
    </>
  );
});
