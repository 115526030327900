import { H5, Inline, InlineProps, stackTwoColors } from '@parallel-mono/components';
import styled from 'styled-components';

export const MenuItem = styled(Inline).attrs<InlineProps>(props => ({
  inset: '0 1rem',
  alignItems: 'center',
  gap: '0.5rem',
  width: props.width ?? '100%'
}))<{ selected?: boolean; disabled?: boolean }>`
  height: 2.5rem;
  border-radius: 2rem;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, selected }) =>
      stackTwoColors(
        selected ? theme.skin.grey['200'] : theme.skin.background.main,
        theme.skin.action.hoverMask
      )};
  }
  background-color: ${props => (props.selected ? props.theme.skin.grey['200'] : 'transparent')};
  opacity: ${({ disabled, theme }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
`;

export const MenuName = styled(H5)`
  white-space: nowrap;
`;
