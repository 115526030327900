import { useCallback, useMemo } from 'react';
import { HelperContractService } from 'paraspace-utilities-contract-helpers';
import BigNumber from 'bignumber.js';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useAppConfig, useContractsMap } from '@/apps/paraspace/hooks';

export const useHelperContract = () => {
  const { provider } = useWeb3Context();
  const contracts = useContractsMap();
  const { erc20Config } = useAppConfig();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new HelperContractService(provider, contracts.HelperContract);
  }, [contracts.HelperContract, provider]);

  const genConvertApeCoinToPCApeTx = useCallback(
    (amount: string, receiver: string) => {
      if (!service) {
        return null;
      }

      return service.convertApeCoinToPCApe(
        BigNumber(amount).shiftedBy(erc20Config.APE.decimals).toString(10),
        receiver
      );
    },
    [service, erc20Config.APE.decimals]
  );

  const genConvertPCApeToApeCoinTx = useCallback(
    (amount: string, receiver: string) => {
      if (!service) {
        return null;
      }
      return service.convertPCApeToApeCoin(
        BigNumber(amount).shiftedBy(erc20Config.APE.decimals).toString(10),
        receiver
      );
    },
    [service, erc20Config.APE.decimals]
  );

  return { genConvertApeCoinToPCApeTx, genConvertPCApeToApeCoinTx };
};
