import { HostedImage } from '@parallel-mono/business-components';
import { H2, Spinner, Stack, StackProps, Image } from '@parallel-mono/components';
import { memo } from 'react';

type AppLoadingPlaceholderProps = Omit<StackProps, 'children'> & {
  appName: string;
  appIcon: string;
};

export const AppLoadingPlaceholder = memo(
  ({ appName, appIcon, ...others }: AppLoadingPlaceholderProps) => {
    return (
      <Stack alignItems="center" justifyContent="center" width="100%" {...others}>
        <Stack gap="0.5rem" alignItems="center">
          <HostedImage
            height="6rem"
            width="6rem"
            name={appIcon}
            fallback={<Image src={appIcon} height="6rem" width="6rem" />}
          />
          <H2>{appName}</H2>
        </Stack>
        <Spinner size="small" />
      </Stack>
    );
  }
);
