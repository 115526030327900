import { memo, useMemo } from 'react';
import { floor, isNil, values } from 'lodash';
import { InfoPanel, TokenInput } from '@parallel-mono/business-components';
import { Button, Stack, StackProps, Inline, Text, Alert } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import { StakeBakcFormData } from '../StakeModal';

import { BakcSelector } from './BakcSelector';
import { useStatesAndHandlers } from './useStatesAndHandlers';

import { NumberRange, Tooltip } from '@/apps/paraspace/components';
import { MIN_BALANCE_THRESHOLD } from '@/apps/paraspace/utils/format';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { zero } from '@/apps/paraspace/consts/values';
import { WalletType } from '@/apps/paraspace/typings';

type BakcStakeFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  bakcTokenId: number;
  bakcSource: WalletType;
  apeCoinSource: WalletType;
  onSubmit: (formData: StakeBakcFormData) => void;
};

export const PairApeForm = memo(
  ({ onSubmit, bakcTokenId, bakcSource, apeCoinSource, ...others }: BakcStakeFormProps) => {
    const {
      errors,
      balance,
      apy,
      amountFromBalance,
      maxValue,
      handleSubmit,
      handleBalanceAmountChange,
      handleBakcPairChange,
      handleMaximizeAmountFromBalance,
      defaultSelector,
      selectorList
    } = useStatesAndHandlers({
      bakcTokenId,
      onSubmit,
      bakcSource,
      apeCoinSource
    });

    const formInfos = useMemo(
      () => [
        {
          title: 'Total Staked',
          value: (
            <NumberRange
              start={0}
              end={amountFromBalance ?? 0}
              formatter={value =>
                formatNumber(value, {
                  decimal: MAXIMUM_BALANCE_DECIMALS,
                  threshold: { min: MIN_BALANCE_THRESHOLD }
                })
              }
              symbol="APE"
            />
          )
        },
        {
          title: (
            <Inline gap=".25rem">
              <Text skin="secondary">APY</Text>
              <Tooltip
                placement="bottom"
                content="The staking APY provided by the official apecoin staking contract."
              />
            </Inline>
          ),
          value: formatNumber(apy ?? zero, { output: 'percent' })
        }
      ],
      [amountFromBalance, apy]
    );

    const hasError = useMemo(() => values(errors).some(error => !isNil(error)), [errors]);
    const supportedSelectorList = useMemo(
      () => selectorList.filter(selector => selector.source !== null),
      [selectorList]
    );

    return (
      <Stack {...others}>
        <BakcSelector
          onChange={handleBakcPairChange}
          tokenList={supportedSelectorList}
          defaultSelectedToken={defaultSelector}
        />
        <TokenInput
          value={amountFromBalance && floor(amountFromBalance, MAXIMUM_BALANCE_DECIMALS)}
          actionButtonText="Max"
          hint={
            <Text skin="secondary">
              Max:{' '}
              {formatNumber(maxValue ?? 0, {
                decimal: MAXIMUM_BALANCE_DECIMALS,
                threshold: { min: MIN_BALANCE_THRESHOLD }
              })}{' '}
              APE
            </Text>
          }
          label={<Text fontWeight="bold">Amount</Text>}
          token="APE"
          decimals={MAXIMUM_BALANCE_DECIMALS}
          onChange={handleBalanceAmountChange}
          max={balance?.toNumber()}
          onAction={handleMaximizeAmountFromBalance}
          error={errors.amountFromBalance}
        />
        <InfoPanel skin="primary" infos={formInfos} />
        <Alert type="info">
          Selling either of the NFTs in the pair prior to unstaking will result in the following:
          BAYC/MAYC owners are entitled the staked amount, with BAKC owners entitled to unclaimed
          rewards. Only users who own both NFTs have rights to the entire staking position.
        </Alert>
        <Button disabled={hasError} block size="large" onClick={handleSubmit}>
          {errors.tokenId ? errors.tokenId : 'Stake'}
        </Button>
      </Stack>
    );
  }
);
