import React from 'react';
import { H2, Text, Inline, Stack, useBreakpoints } from '@parallel-mono/components';

import { CollectionInfo } from '../../../contexts/CollectionProvider';

import { Link, NFTCollectionThumbnail } from '@/apps/paraspace/components';
import { truncateTextMid } from '@/apps/paraspace/utils/format';
import { useNetworkConfig } from '@/apps/paraspace/hooks';

type CollectionOverviewProps = {
  collection?: CollectionInfo;
};

export const CollectionOverview: React.FC<CollectionOverviewProps> = ({ collection }) => {
  const {
    explorerLink: [explorerLinkBaseUrl]
  } = useNetworkConfig();

  const { mobile } = useBreakpoints();

  return (
    <Inline alignItems="center" inset={mobile ? '0' : '1.5rem 2rem'}>
      <NFTCollectionThumbnail symbol={collection?.symbol!} size="medium" round />
      <Stack gap="0.5rem">
        <H2>{collection?.collectionName}</H2>
        <Text>
          Contract{' '}
          {collection?.contractAddress && (
            <Link
              target="_blank"
              href={`${explorerLinkBaseUrl}/address/${collection?.contractAddress}`}
            >
              {truncateTextMid(collection?.contractAddress)}
            </Link>
          )}
        </Text>
      </Stack>
    </Inline>
  );
};
