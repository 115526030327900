import { memo, MouseEvent, useCallback, useState, useMemo, FC } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardProps,
  Collapse,
  DataGridColumn,
  H5,
  H6,
  Icon,
  Inline,
  Stack,
  Button,
  useBreakpoints,
  Tag,
  TypographyVariant,
  StackProps
} from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router-dom';
import { formatNumber } from '@parallel-mono/utils';
import { orderBy } from 'lodash';

import { useNativeTokenSymbol, useWNativeTokenSymbol } from '../hooks';

import { StyledDataGrid } from './StyledDataGrid';
import { InLiquidationAsset, AuctionStatus } from './types';
import { PriceDecayGraph } from './PriceDecayGraph';
import { AccountLiquidationGrid } from './AccountLiquidationGrid';
import { NoShrinkingNFTThumbnail } from './NoShrinkingNFTThumbnail';

import { Link, Tooltip, ChainlinkLogo, RoundProfilePicture } from '@/apps/paraspace/components';
import {
  truncateTextMid,
  formatBalance,
  formatToPercentage,
  formatToCurrency
} from '@/apps/paraspace/utils/format';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { DEFAULT_MULTIPLIER, RECOVERY_NFT_HF_THRESHOLD } from '@/apps/paraspace/pages/config';
import { useGetSymbolByContractAddress, useNetworkConfig } from '@/apps/paraspace/hooks';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type LiquidationGridProps = {
  profile: {
    walletAddress: string;
  };
  assets: InLiquidationAsset[];
  collateral: string;
  debt: string;
  isPaused: boolean;
  healthFactor: number;
  nftHealthFactor: number;
  inLiquidation: boolean;
  onCloseLiquidation: (walletAddress: string) => Maybe<Promise<void>>;
  onTriggerAuction: (data: { user: string; asset: InLiquidationAsset }) => Maybe<Promise<void>>;
  onBuyAsset: (data: { user: string; asset: InLiquidationAsset }) => void;
  buyingAssets: string[];
} & Omit<CardProps, 'children' | 'border'>;

const GridCard = styled(Card).attrs({
  border: true
})`
  padding: 0;
`;

const GridHeader = styled(Inline).attrs({
  justifyContent: 'space-between',
  alignItems: 'center'
})`
  padding: 1.5rem 2rem;
`;

const StyledTag = styled(Tag)`
  width: fit-content;
`;

const StyledStack = styled(Stack)`
  ${({ theme }) => theme.breakpoints.not('desktop')`
    white-space: nowrap;
  `}
`;

const PausedLiquidationPlaceholder: FC<Omit<StackProps, 'children'>> = memo(({ ...props }) => (
  <Stack width="100%" {...props}>
    <H5>-</H5>
  </Stack>
));

const createColumns = ({
  onTriggerAuction,
  onBuyAsset,
  nftHealthFactor,
  inLiquidation,
  isMobile,
  isTablet,
  isPaused,
  getSymbolByContractAddress,
  nativeToken,
  wNativeToken,
  triggeringAsset,
  buyingAssets
}: {
  onTriggerAuction: (asset: InLiquidationAsset) => void;
  onBuyAsset: (asset: InLiquidationAsset) => void;
  nftHealthFactor: number;
  inLiquidation: boolean;
  balance?: BigNumber;
  isMobile: boolean;
  isTablet: boolean;
  isPaused: boolean;
  nativeToken: ERC20Symbol;
  wNativeToken: ERC20Symbol;
  triggeringAsset: string[];
  buyingAssets: string[];
  getSymbolByContractAddress: (addr: string) => Maybe<ERC721Symbol | ERC20Symbol>;
}): DataGridColumn<InLiquidationAsset>[] => [
  {
    title: isMobile ? null : 'Assets',
    name: '',
    className: isMobile ? 'hiddenTitleLine' : '',
    render: ({
      data: { collectionName, identifierOrCriteria, contractAddress, traitMultiplier }
    }) => {
      const symbol = getSymbolByContractAddress(contractAddress || '') as ERC721Symbol;
      return (
        <Inline alignItems="center" gap="0.75rem">
          <NoShrinkingNFTThumbnail symbol={symbol} tokenId={identifierOrCriteria} size="small" />
          <StyledStack gap="0.25rem">
            <Link
              as={RouterLink}
              variant={TypographyVariant.header5}
              to={`/details/${contractAddress}/${identifierOrCriteria}`}
            >
              {collectionName} #{identifierOrCriteria}
            </Link>
            {traitMultiplier.gt(DEFAULT_MULTIPLIER) && (
              <StyledTag skin="success" size="small">
                {formatNumber(traitMultiplier)}x Boost
              </StyledTag>
            )}
          </StyledStack>
        </Inline>
      );
    }
  },
  {
    title: (
      <Inline gap="0.1rem">
        <H5 fontWeight="regular">TWAP Floor Price</H5>
        <Tooltip
          placement="bottom"
          content="Time-Weighted Average Price of the token over the past 6 hours to ensure accurate data. "
        />
      </Inline>
    ),
    name: '',
    render: ({ data: { symbol, floorPrice, floorPriceInUsd } }) => (
      <Stack gap="0.125rem" alignItems={isMobile ? 'flex-end' : 'flex-start'}>
        <Inline gap="0.25rem" alignItems="center">
          <H5>
            {formatBalance(floorPrice)} {nativeToken}
          </H5>
          <ChainlinkLogo symbol={symbol} showName={false} />
        </Inline>
        <H6 skin="secondary">{formatToCurrency(floorPriceInUsd)}</H6>
      </Stack>
    )
  },
  {
    title: 'Buy Now Price',
    name: '',
    render: ({ data: { buyNowPrice, buyNowPriceInUsd, status, floorPriceInUsd } }) => {
      if (isPaused) {
        return <PausedLiquidationPlaceholder />;
      }
      return (
        <Stack gap="0.125rem" alignItems={isMobile ? 'flex-end' : 'flex-start'}>
          <H5>
            {status === AuctionStatus.StartedAndCanBeLiquidated
              ? `${formatBalance(buyNowPrice)} ${wNativeToken}`
              : '-'}
          </H5>
          {status === AuctionStatus.NotStarted && nftHealthFactor < 1 && (
            <H6 skin="secondary">To be triggered</H6>
          )}
          {status === AuctionStatus.StartedAndCanBeLiquidated &&
            floorPriceInUsd.gt(buyNowPriceInUsd) && (
              <H6 skin="secondary">
                {`${formatToPercentage(
                  floorPriceInUsd.minus(buyNowPriceInUsd).dividedBy(floorPriceInUsd),
                  1
                )} below floor price`}
              </H6>
            )}
          {status === AuctionStatus.StartedAndCanBeLiquidated &&
            buyNowPriceInUsd.gte(floorPriceInUsd) && (
              <H6 skin="secondary">{formatToCurrency(buyNowPriceInUsd)}</H6>
            )}
          {status === AuctionStatus.StartedButCannotBeLiquidated && (
            <H6 skin="secondary">Auction to be closed</H6>
          )}
        </Stack>
      );
    }
  },
  {
    title: 'Time in Auction',
    hidden: isMobile || isTablet,
    name: '',
    render: ({ data: { startTime } }) => {
      if (isPaused) {
        return <PausedLiquidationPlaceholder />;
      }
      return <H5>{startTime > 0 ? dayjs(startTime).fromNow(true) : '-'}</H5>;
    }
  },
  {
    title: 'Trend',
    hidden: isMobile || isTablet,
    name: '',
    width: '10rem',
    render: ({
      data: {
        currentPriceMultiplier,
        minExpPriceMultiplier,
        minPriceMultiplier,
        maxPriceMultiplier,
        stepExp,
        stepLinear,
        status
      }
    }) => {
      if (isPaused) {
        return <PausedLiquidationPlaceholder />;
      }
      return (
        <PriceDecayGraph
          width={140}
          height={60}
          stepExp={stepExp}
          stepLinear={stepLinear}
          expDecayStart={maxPriceMultiplier}
          expDecayEnd={minExpPriceMultiplier}
          linearDecayEnd={minPriceMultiplier}
          currentPrice={
            [
              AuctionStatus.StartedAndCanBeLiquidated,
              AuctionStatus.StartedButCannotBeLiquidated
            ].includes(status)
              ? currentPriceMultiplier
              : null
          }
        />
      );
    }
  },
  {
    title: null,
    name: '',
    justifyContent: 'flex-end',
    className: isMobile ? 'hiddenTitleLine' : '',
    render: ({
      data: { status, symbol, buyNowPrice, identifierOrCriteria, contractAddress },
      data
    }) => {
      const noLiquidityUniswap = symbol === ERC721Symbol.UNISWAP_LP && buyNowPrice.isZero();
      if (isPaused) {
        return null;
      }
      const isTriggering = triggeringAsset.includes(`${symbol}-${identifierOrCriteria}`);
      const isBuying = buyingAssets.includes(`${contractAddress}-${identifierOrCriteria}`);
      if (status === AuctionStatus.NotStarted && nftHealthFactor < 1 && !noLiquidityUniswap) {
        return (
          <Button
            loading={isTriggering}
            disabled={isPaused || isTriggering}
            block={isMobile}
            onClick={() => {
              onTriggerAuction(data);
            }}
          >
            Trigger
          </Button>
        );
      }
      if (status === AuctionStatus.StartedAndCanBeLiquidated && !noLiquidityUniswap) {
        return (
          <Button
            loading={isBuying}
            block={isMobile}
            disabled={inLiquidation || isPaused || isBuying}
            skin="secondary"
            onClick={() => {
              onBuyAsset(data);
            }}
          >
            Buy
          </Button>
        );
      }
      return null;
    }
  }
];

export const InLiquidationGrid = memo((props: LiquidationGridProps) => {
  const {
    buyingAssets,
    profile,
    assets,
    healthFactor,
    nftHealthFactor,
    collateral,
    debt,
    isPaused,
    onCloseLiquidation,
    onTriggerAuction,
    onBuyAsset,
    inLiquidation,
    ...others
  } = props;

  const [isClosingLiquidation, setClosingLiquidation] = useState(false);
  const [triggeringAsset, setTriggeringAsset] = useState<string[]>([]);

  const [open, setOpen] = useState(false);
  const handleToggleGrid = useCallback(() => {
    setOpen(v => !v);
  }, [setOpen]);

  const assetsByOrder = useMemo(
    () =>
      orderBy(
        assets,
        asset => {
          const noLiquidityUniswap =
            asset.symbol === ERC721Symbol.UNISWAP_LP && asset.buyNowPrice.isZero();
          return asset.status === AuctionStatus.StartedAndCanBeLiquidated && !noLiquidityUniswap;
        },
        ['desc']
      ),
    [assets]
  );

  const icons = useMemo(
    () =>
      assetsByOrder.map(({ identifierOrCriteria, contractAddress, symbol }) => ({
        symbol,
        identifierOrCriteria,
        contractAddress
      })),
    [assetsByOrder]
  );

  const handleHeaderClicked = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if ((e.target as HTMLElement).getAttribute('data-expander') === 'true') {
        setOpen(v => !v);
      }
    },
    [setOpen]
  );

  const handleCloseLiquidation = useCallback(() => {
    setClosingLiquidation(true);
    onCloseLiquidation(profile.walletAddress)?.finally(() => {
      setClosingLiquidation(false);
    });
  }, [profile.walletAddress, onCloseLiquidation]);

  const handleTriggerAuction = useCallback(
    (asset: InLiquidationAsset) => {
      const assetUniqId = `${asset.symbol}-${asset.identifierOrCriteria}`;
      setTriggeringAsset(prev => prev?.concat(assetUniqId));
      onTriggerAuction({
        user: profile.walletAddress,
        asset
      })?.finally(() => {
        setTriggeringAsset(prev => prev?.filter(v => v !== assetUniqId));
      });
    },
    [onTriggerAuction, profile.walletAddress]
  );

  const handleBuyAsset = useCallback(
    (asset: InLiquidationAsset) => {
      onBuyAsset({
        user: profile.walletAddress,
        asset
      });
    },
    [profile.walletAddress, onBuyAsset]
  );

  const { desktop, mobile, tablet } = useBreakpoints();
  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  const nativeToken = useNativeTokenSymbol();
  const wNativeToken = useWNativeTokenSymbol();
  const columns = useMemo(
    () =>
      createColumns({
        onTriggerAuction: handleTriggerAuction,
        onBuyAsset: handleBuyAsset,
        nftHealthFactor,
        inLiquidation,
        isMobile: mobile,
        isTablet: tablet,
        isPaused,
        getSymbolByContractAddress,
        nativeToken,
        wNativeToken,
        triggeringAsset,
        buyingAssets
      }),
    [
      handleTriggerAuction,
      handleBuyAsset,
      nftHealthFactor,
      inLiquidation,
      mobile,
      tablet,
      isPaused,
      getSymbolByContractAddress,
      nativeToken,
      wNativeToken,
      triggeringAsset,
      buyingAssets
    ]
  );

  const {
    explorerLink: [explorerLinkBaseUrl]
  } = useNetworkConfig();

  const hasAuction = assetsByOrder.some(asset => asset.status !== AuctionStatus.NotStarted);

  return (
    <GridCard {...others}>
      <GridHeader data-expander="true" onClick={handleHeaderClicked}>
        <Stack width="100%">
          <Inline width="100%" justifyContent="space-between" gap="1rem">
            <Inline data-expander="true" alignItems="center" gap="0.75rem">
              <RoundProfilePicture width="2.5rem" height="2.5rem" />
              <Link
                variant={TypographyVariant.header5}
                target="_blank"
                href={`${explorerLinkBaseUrl}/address/${profile.walletAddress}`}
              >
                {truncateTextMid(profile.walletAddress, 4, 4)}
              </Link>
            </Inline>
            <Inline justifyContent="space-between" alignItems="center" gap="1rem">
              {desktop && (
                <AccountLiquidationGrid
                  isClosingLiquidation={isClosingLiquidation}
                  open={open}
                  assets={icons}
                  isPaused={isPaused}
                  healthFactor={healthFactor}
                  collateral={collateral}
                  debt={debt}
                  handleCloseLiquidation={
                    nftHealthFactor >= RECOVERY_NFT_HF_THRESHOLD && hasAuction
                      ? handleCloseLiquidation
                      : null
                  }
                />
              )}
              <Icon name={open ? 'minusCircle' : 'downContained'} onClick={handleToggleGrid} />
            </Inline>
          </Inline>
          {!desktop && (
            <AccountLiquidationGrid
              isClosingLiquidation={isClosingLiquidation}
              open={open}
              assets={icons}
              isPaused={isPaused}
              healthFactor={healthFactor}
              collateral={collateral}
              debt={debt}
              handleCloseLiquidation={
                nftHealthFactor >= RECOVERY_NFT_HF_THRESHOLD && hasAuction
                  ? handleCloseLiquidation
                  : null
              }
            />
          )}
        </Stack>
      </GridHeader>
      <Collapse open={open}>
        <StyledDataGrid columns={columns} data={assetsByOrder} pageSize={5} />
      </Collapse>
    </GridCard>
  );
});
