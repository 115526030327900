import { Card, H3, Icon, Inline, Popover, SmallText, Stack } from '@parallel-mono/components';
import React, { FC, MouseEventHandler, memo, useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { ClickableIconWrapper } from '../StyledComponents';
import { NetworkConfig } from '../../types';
import { ChainIcon } from '../ChainIcon';
import { SupportedChainId } from '../../configs';

const StyledIcon = styled(Icon)<{ opened: boolean }>`
  transform: rotate(${({ opened }) => (opened ? '-180' : '0')}deg);
  transition: transform 0.3s;
`;

const StyledCard = styled(Card)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: calc(100vw - 100px);
  `}
`;

const ChainRow = styled(Inline)<{ active?: boolean }>`
  border-radius: 5px;
  padding: 1rem 0.75rem;
  cursor: pointer;
  background-color: ${({ active, theme }) =>
    active ? theme.skin.background.sub1 : theme.skin.background.main};
  &:hover {
    background-color: ${({ theme }) => theme.skin.background.sub1};
  }
`;

type Props = {
  activeNetwork: SupportedChainId;
  networkOptions: NetworkConfig[];
  onNetworkChange: (option: NetworkConfig) => void;
};

const NetworkPanel: FC<
  {
    onClose: MouseEventHandler<HTMLDivElement>;
  } & Props
> = memo(({ onClose, networkOptions, activeNetwork, onNetworkChange }) => {
  const handleNetworkClick = useCallback(
    option => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onNetworkChange(option);
      onClose(e);
    },
    [onClose, onNetworkChange]
  );
  return (
    <StyledCard shadow="primary" width="380px" inset="2rem 1rem">
      <Stack gap="1.25rem">
        <Inline alignItems="center" justifyContent="space-between">
          <H3>Select network</H3>
          <ClickableIconWrapper onClick={onClose}>
            <Icon name="close" />
          </ClickableIconWrapper>
        </Inline>
        <Stack gap="0.25rem">
          {networkOptions.map(v => {
            return (
              <ChainRow
                active={activeNetwork === v.chainId}
                onClick={handleNetworkClick(v)}
                key={v.chainId}
                alignItems="center"
                gap="0.5rem"
              >
                <ChainIcon name={v.symbol} width="2rem" height="2rem" />
                <SmallText fontWeight="bold">{v.name}</SmallText>
              </ChainRow>
            );
          })}
        </Stack>
      </Stack>
    </StyledCard>
  );
});

const Display = styled(Inline)`
  cursor: pointer;
`;
export const NetworkPopup: FC<
  Props & {
    activeNetworkName?: string;
    activeNetworkSymbol?: string;
    title: string;
  }
> = memo(
  ({
    onNetworkChange,
    activeNetwork,
    activeNetworkName,
    activeNetworkSymbol,
    networkOptions,
    title
  }) => {
    const [visible, setVisible] = useState(false);
    const theme = useTheme();

    const handleClose: MouseEventHandler<HTMLDivElement> = useCallback(e => {
      e.stopPropagation();
      document.body.click();
    }, []);

    return (
      <Popover
        onVisibleChange={value => setVisible(value)}
        popup={
          <NetworkPanel
            activeNetwork={activeNetwork}
            networkOptions={networkOptions}
            onNetworkChange={onNetworkChange}
            onClose={handleClose}
          />
        }
        trigger="click"
        placement="bottom-start"
      >
        <Display gap="0.75rem" alignItems="center">
          {activeNetworkSymbol && (
            <ChainIcon width="2rem" height="2rem" name={activeNetworkSymbol} />
          )}
          <Stack gap="0">
            <SmallText skin="secondary" fontWeight="bold">
              {title}
            </SmallText>
            {activeNetwork ? (
              <SmallText fontWeight="bold">{activeNetworkName}</SmallText>
            ) : (
              <SmallText fontWeight="bold">Select network</SmallText>
            )}
          </Stack>
          <ClickableIconWrapper inset="0.5rem">
            <StyledIcon
              size="24px"
              opened={visible}
              color={theme.skin.text.sub1}
              strokeWidth="2"
              name="chevronDown"
            />
          </ClickableIconWrapper>
        </Display>
      </Popover>
    );
  }
);
