import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { chain, differenceBy, flatMapDepth, isNil, keyBy, values } from 'lodash';

import { useStakingInfos } from '../StakingInfosProvider';
import { useEOABalances } from '../../../contexts';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  ApeStakingMainTokenSymbol,
  ERC721Symbol,
  ApeStakingTokenSymbol,
  WalletType
} from '@/apps/paraspace/typings';
import { useMainToBakcMap } from '@/apps/paraspace/pages/hooks/ApeStaking/useMainToBakcMap';
import { emptyArray } from '@/apps/paraspace/consts/values';
import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';
import { ApeTokenStakingInfo } from '@/apps/paraspace/pages/hooks/ApeStaking/types';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type ApeListItem = Partial<ApeTokenStakingInfo> & {
  symbol: ApeStakingTokenSymbol;
  tokenId: number;
  loadingStakingInfo?: boolean;
  pairedBakc?: ApeListItem | null;
  supplied: boolean;
  source: Maybe<WalletType>;
};

type ContextValue = {
  apesInBalanceAndInSuppliedExcludingInP2P: ApeListItem[];
  apesInBalanceAndInSuppliedAndInP2P: ApeListItem[];
  apesInSuppliedExcludingInP2P: ApeListItem[];
  apeInfoListLoaded: boolean;
  refresh: () => void;
};
export const ApeListContext = createContext({} as ContextValue);

export const ApeListProvider: FC = ({ children }) => {
  const { Provider } = ApeListContext;
  const { nftInfoMap, basicInfoLoaded, userInfoLoaded, load } = useMMProvider();
  const { erc721BalanceMap } = useEOABalances();

  const {
    [ERC721Symbol.BAYC]: {
      balance: baycAABalance = emptyArray,
      nftSuppliedList: baycSupplment = emptyArray
    } = {},
    [ERC721Symbol.MAYC]: {
      balance: maycAABalance = emptyArray,
      nftSuppliedList: maycSupplment = emptyArray
    } = {},
    [ERC721Symbol.BAKC]: {
      balance: bakcAABalance = emptyArray,
      nftSuppliedList: bakcSupplment = emptyArray
    } = {}
  } = nftInfoMap;

  const {
    [ERC721Symbol.BAYC]: { balance: baycEOABalance = emptyArray } = {},
    [ERC721Symbol.MAYC]: { balance: maycEOABalance = emptyArray } = {},
    [ERC721Symbol.BAKC]: { balance: bakcEOABalance = emptyArray } = {}
  } = erc721BalanceMap ?? {};

  const {
    balanceStakingInfo,
    refreshBalanceStakingInfo,
    suppliedStakingInfo,
    refreshSuppliedStakingInfo
  } = useStakingInfos();

  const tokenStakingInfoMap = useMemo(
    () =>
      chain(balanceStakingInfo?.concat(suppliedStakingInfo ?? []))
        .groupBy('symbol')
        .mapValues(infos => keyBy(infos, 'tokenId'))
        .value(),
    [balanceStakingInfo, suppliedStakingInfo]
  );

  const baycApes = useMemo(
    () =>
      baycSupplment
        .map(
          tokenId =>
            ({
              tokenId,
              supplied: true,
              symbol: ERC721Symbol.BAYC as ApeListItem['symbol'],
              source: null
            } as ApeListItem)
        )
        .concat(
          baycAABalance.map(tokenId => ({
            tokenId,
            supplied: false,
            symbol: ERC721Symbol.BAYC,
            source: 'AA'
          }))
        )
        .concat(
          baycEOABalance.map(tokenId => ({
            tokenId,
            supplied: false,
            symbol: ERC721Symbol.BAYC,
            source: 'EOA'
          }))
        ),
    [baycAABalance, baycSupplment, baycEOABalance]
  );

  const maycApes = useMemo(
    () =>
      maycSupplment
        .map(
          tokenId =>
            ({
              tokenId,
              supplied: true,
              symbol: ERC721Symbol.MAYC as ApeListItem['symbol'],
              source: null
            } as ApeListItem)
        )
        .concat(
          maycAABalance.map(tokenId => ({
            tokenId,
            supplied: false,
            symbol: ERC721Symbol.MAYC,
            source: 'AA'
          }))
        )
        .concat(
          maycEOABalance.map(tokenId => ({
            tokenId,
            supplied: false,
            symbol: ERC721Symbol.MAYC,
            source: 'EOA'
          }))
        ),
    [maycAABalance, maycSupplment, maycEOABalance]
  );

  const bakcApes = useMemo(
    () =>
      bakcSupplment
        .map(
          tokenId =>
            ({
              tokenId,
              supplied: true,
              symbol: ERC721Symbol.BAKC as ApeListItem['symbol'],
              source: null
            } as ApeListItem)
        )
        .concat(
          bakcAABalance.map(tokenId => ({
            tokenId,
            supplied: false,
            symbol: ERC721Symbol.BAKC,
            source: 'AA'
          }))
        )
        .concat(
          bakcEOABalance.map(tokenId => ({
            tokenId,
            supplied: false,
            symbol: ERC721Symbol.BAKC,
            source: 'EOA'
          }))
        ),
    [bakcAABalance, bakcSupplment, bakcEOABalance]
  );

  const mainApes = useMemo(
    () => [...baycApes, ...maycApes, ...bakcApes],
    [bakcApes, baycApes, maycApes]
  );

  const mainApeAbstracts = useStabilizedSnapshot(
    mainApes.map(it => ({
      symbol: it.symbol as ApeStakingMainTokenSymbol,
      tokenId: it.tokenId,
      supplied: it.supplied
    }))
  );

  const [mainToBakcMap, refreshMainToBakcMap] = useMainToBakcMap(mainApeAbstracts);

  // Transferred bakc
  const pairedBAKC = useMemo(
    () => flatMapDepth(mainToBakcMap, item => values(item), 2),
    [mainToBakcMap]
  );
  const notOwnedBAKC = useMemo(
    () =>
      differenceBy(pairedBAKC, bakcApes, n => {
        return `${n.tokenId}${n.symbol}`;
      }).map(item => ({ ...item, supplied: false })),
    [bakcApes, pairedBAKC]
  );

  const { checkIfTokenInPairing } = useP2PInfo();
  const {
    apesInBalanceAndInSuppliedAndInP2P,
    apesInBalanceAndInSuppliedExcludingInP2P,
    apesInSuppliedExcludingInP2P
  } = useMemo(() => {
    const apesAllInOne = mainApes.concat(notOwnedBAKC).map(item => ({
      ...item,
      loadingStakingInfo: tokenStakingInfoMap === null,
      ...tokenStakingInfoMap?.[item.symbol]?.[item.tokenId],
      pairedBakc: mainToBakcMap?.[item.symbol]?.[item.tokenId] ?? null
    })) as ApeListItem[];

    const apesAllInOneExcludingInP2P = apesAllInOne.filter(
      each => !checkIfTokenInPairing(each.symbol, each.tokenId)
    );

    const apesAllInOneExcludingInP2PAndInBalance = apesAllInOneExcludingInP2P.filter(ape => {
      const { symbol, mainTokenId, supplied } = ape;
      if (symbol === ERC721Symbol.BAKC && mainTokenId) {
        const mainToken = apesAllInOneExcludingInP2P.find(item => item.tokenId === mainTokenId);
        return mainToken?.supplied ?? false;
      }
      return supplied;
    });

    return {
      apesInBalanceAndInSuppliedAndInP2P: apesAllInOne || [],
      apesInBalanceAndInSuppliedExcludingInP2P: apesAllInOneExcludingInP2P || [],
      apesInSuppliedExcludingInP2P: apesAllInOneExcludingInP2PAndInBalance || []
    };
  }, [mainApes, notOwnedBAKC, tokenStakingInfoMap, mainToBakcMap, checkIfTokenInPairing]);

  const apeInfoListLoaded = useMemo(
    () =>
      !isNil(balanceStakingInfo) &&
      !isNil(suppliedStakingInfo) &&
      basicInfoLoaded &&
      userInfoLoaded,
    [balanceStakingInfo, basicInfoLoaded, suppliedStakingInfo, userInfoLoaded]
  );

  const refresh = useCallback(() => {
    return Promise.all([
      load(),
      refreshBalanceStakingInfo(),
      refreshSuppliedStakingInfo(),
      refreshMainToBakcMap()
    ]);
  }, [load, refreshBalanceStakingInfo, refreshSuppliedStakingInfo, refreshMainToBakcMap]);

  const value = useMemo(
    () => ({
      apesInBalanceAndInSuppliedExcludingInP2P,
      apesInBalanceAndInSuppliedAndInP2P,
      apesInSuppliedExcludingInP2P,
      apeInfoListLoaded,
      refresh
    }),
    [
      apesInBalanceAndInSuppliedExcludingInP2P,
      apesInBalanceAndInSuppliedAndInP2P,
      apesInSuppliedExcludingInP2P,
      apeInfoListLoaded,
      refresh
    ]
  );

  return <Provider value={value}>{children}</Provider>;
};

export const useApeListStatesAndActions = () => {
  return useContext(ApeListContext);
};
