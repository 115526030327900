import { useCallback, useMemo, useState } from 'react';
import { ethers } from 'ethers';
import { random } from 'lodash';

import { usePolling } from '@/apps/paraspace/pages/contexts/hooks/usePolling';
import { useAppConfig, useNetworkConfig } from '@/apps/paraspace/hooks';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { Feature } from '@/apps/paraspace/config';

type BlockDetail = {
  number: number;
  timestamp: number;
};

export const useGetNetworkBatchBlockInfo = () => {
  const networkConfigs = useNetworkConfig();
  const { features } = useAppConfig();
  const [networkBatchBlockDetail, setNetworkBatchBlockDetail] = useState<Maybe<BlockDetail>>();

  const [rpcUrl, setRpcUrl] = useState(networkConfigs.publicJsonRPCUrl[0]);

  const shouldTimelockCompareWithBatchBlock = useMemo(
    () => !features.includes(Feature.TimelockAutoClaim),
    [features]
  );

  const provider = useMemo(() => new ethers.providers.JsonRpcProvider(rpcUrl), [rpcUrl]);

  const getBatchDetail = useCallback(async () => {
    if (provider && shouldTimelockCompareWithBatchBlock) {
      try {
        const blockNumber = await provider.send('zks_L1BatchNumber', []);
        const batchDetail = await provider.send('zks_getL1BatchDetails', [Number(blockNumber)]);
        setNetworkBatchBlockDetail(batchDetail);
      } catch {
        const urls = networkConfigs.publicJsonRPCUrl;
        setRpcUrl(urls[random(urls.length - 1)]);
        setNetworkBatchBlockDetail(null);
      }
    }
  }, [networkConfigs.publicJsonRPCUrl, provider, shouldTimelockCompareWithBatchBlock]);

  usePolling({ pollingInterval: 10000, pollFn: getBatchDetail });

  return {
    shouldTimelockCompareWithBatchBlock,
    batchBlockDetail: networkBatchBlockDetail
  };
};
