import { Modal, ModalProps } from '@parallel-mono/components';
import { memo } from 'react';
import BigNumber from 'bignumber.js';

import RepayForm from './RepayForm';
import RepayStepper from './RepayStepper';

import {
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure,
  ErrorState,
  SuccessState
} from '@/apps/paraspace/components';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

export enum Repayment {
  AA,
  EOA,
  Supplied
}

export type RepayModalData = {
  repayment: Repayment;
  symbol: ERC20Symbol;
};

export type RepayFormData = {
  repayment: Repayment;
  amount: BigNumber;
  symbol: ERC20Symbol;
  isRepayAll: boolean;
};

export type RepayModalProps = {
  data: RepayModalData;
  isOpen: boolean;
  onClose: () => void;
} & Omit<ModalProps, 'children' | 'onClose' | 'title'>;

const RepayModal = memo((props: RepayModalProps) => {
  const { data, isOpen, onClose } = props;
  const { phase, submittedFormData, handleFormSubmit, handleSubmitFailed, handleSubmitSuccess } =
    useCollectAndSubmitProcedure<RepayFormData>({
      running: isOpen
    });
  const { erc20InfoMap } = useMMProvider();

  return (
    <Modal
      size="32.5rem"
      title={`Repay ${erc20InfoMap[data.symbol].displayName}`}
      onClose={onClose}
      isOpen={isOpen}
    >
      {phase === CollectAndSubmitProcedurePhase.Collecting && (
        <RepayForm data={data} onSubmit={handleFormSubmit} />
      )}
      {phase === CollectAndSubmitProcedurePhase.Submitting && (
        <RepayStepper
          onFinish={handleSubmitSuccess}
          onError={handleSubmitFailed}
          formData={submittedFormData!}
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
      {phase === CollectAndSubmitProcedurePhase.Success && (
        <SuccessState desc="Successful repayment" />
      )}
    </Modal>
  );
});

export default RepayModal;
