import { useCallback, useState } from 'react';
import { noop } from 'lodash';

import { BorrowERC20ModalProps } from '../modals';

import { ERC20Symbol } from '@/apps/paraspace/typings';

const defaultModalProps: BorrowERC20ModalProps = {
  isOpen: false,
  symbol: ERC20Symbol.AAVE,
  onClose: noop,
  onFinish: noop,
  onError: noop
};

export const useBorrowERC20 = (): [
  BorrowERC20ModalProps,
  (symbol: ERC20Symbol) => Promise<void>
] => {
  const [modalProps, setModalProps] = useState<BorrowERC20ModalProps>(defaultModalProps);

  const borrowERC20 = useCallback(
    (symbol: ERC20Symbol) => {
      if (modalProps.isOpen) {
        throw new Error('There is a borrowing in progress');
      }
      return new Promise<void>((resolve, reject) => {
        setModalProps(curr => ({
          ...curr,
          isOpen: true,
          symbol,
          onError: reject,
          onFinish: resolve,
          onClose: () => {
            setModalProps(prev => ({ ...prev, isOpen: false }));
            resolve();
          }
        }));
      });
    },
    [modalProps.isOpen]
  );

  return [modalProps, borrowERC20];
};
