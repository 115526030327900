import { useCallback, useMemo } from 'react';
import { NTokenMoonbirds } from 'paraspace-utilities-contract-helpers';
import { Contract } from 'ethers';

import { useMMProvider } from '../contexts/MMProvider';

import submitTransaction from '@/apps/paraspace/utils/submitTransaction';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

const abi = [
  {
    inputs: [],
    name: 'nestingOpen',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  }
];

const useMoonBirds = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();
  const { nftInfoMap } = useMMProvider();
  const { xTokenAddress } = nftInfoMap[ERC721Symbol.MOONBIRD] ?? {};

  const service = useMemo(() => {
    if (!provider || !xTokenAddress) {
      return null;
    }
    return new NTokenMoonbirds(provider, xTokenAddress);
  }, [provider, xTokenAddress]);

  const getNestingPeriod = useCallback(
    async (tokenId: string) => {
      if (!provider || !service || !tokenId) {
        return null;
      }
      return service.getNestingPeriod(tokenId);
    },
    [provider, service]
  );

  const toggleNesting = useCallback(
    async (tokenId: string, nested: boolean) => {
      if (!provider || !service || !tokenId) {
        return null;
      }

      const moonBirds = new Contract(contracts.MOONBIRD, abi, provider);
      // If false then nesting is blocked, but unnesting is always allowed.
      const isNestingOpen = await moonBirds.nestingOpen();
      if (!isNestingOpen && !nested) {
        return false;
      }
      const tx = await service.toggleNesting([tokenId], account);
      return submitTransaction({ provider, tx });
    },
    [provider, service, account, contracts]
  );

  return { getNestingPeriod, toggleNesting };
};

export default useMoonBirds;
