import { useMemo } from 'react';
import { StakingType } from 'paraspace-utilities-contract-helpers';
import BigNumber from 'bignumber.js';

import { zero } from '@/apps/paraspace/consts/values';
import {
  ApeCoinListing,
  ApeListing,
  BakcListing
} from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/types';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ApeStakingMainTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';

export const usePredictedListings = ({
  apeListing,
  apeCoinListing,
  bakcListing,
  shareOfApe,
  shareOfApeCoin,
  shareOfBakc,
  apeListingTokenInput,
  bakcListingTokenInput
}: {
  apeListing: Maybe<ApeListing>;
  apeCoinListing: Maybe<ApeCoinListing>;
  bakcListing: Maybe<BakcListing>;
  shareOfApe: Maybe<BigNumber>;
  shareOfApeCoin: Maybe<BigNumber>;
  shareOfBakc: Maybe<BigNumber>;
  apeListingTokenInput: Maybe<{
    symbol: ApeStakingMainTokenSymbol;
    tokenId: number;
    supplied: boolean;
  }>;
  bakcListingTokenInput: Maybe<{
    symbol: ERC721Symbol.BAKC;
    tokenId: number;
    supplied: boolean;
  }>;
}) => {
  const { account } = useWeb3Context();

  const predictedApeListing = useMemo(
    () =>
      apeListing ?? {
        stakingType: StakingType.BAKCPairStaking,
        offerer: account,
        share: shareOfApe ?? zero,
        tokenId: apeListingTokenInput?.tokenId,
        symbol: apeListingTokenInput?.symbol
      },
    [apeListing, account, shareOfApe, apeListingTokenInput?.tokenId, apeListingTokenInput?.symbol]
  );

  const predictedApeCoinListing = useMemo(
    () =>
      apeCoinListing ?? {
        stakingType: StakingType.BAKCPairStaking,
        offerer: account,
        share: shareOfApeCoin ?? zero
      },
    [apeCoinListing, account, shareOfApeCoin]
  );

  const predictedBakcListing = useMemo(
    () =>
      bakcListing ?? {
        stakingType: StakingType.BAKCPairStaking,
        offerer: account,
        share: shareOfBakc ?? zero,
        tokenId: bakcListingTokenInput?.tokenId,
        symbol: bakcListingTokenInput?.symbol
      },
    [
      bakcListing,
      account,
      shareOfBakc,
      bakcListingTokenInput?.tokenId,
      bakcListingTokenInput?.symbol
    ]
  );

  return {
    predictedApeListing,
    predictedApeCoinListing,
    predictedBakcListing
  };
};
