import BigNumber from 'bignumber.js';

import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';

export enum AuctionStatus {
  StartedAndCanBeLiquidated = 1,
  StartedButCannotBeLiquidated = 2,
  NotStarted = 3
}

export type TokenAuctionStateMap = {
  [contractAddress: string]: {
    [tokenId: string]: {
      price: BigNumber | null;
      priceInUSD: BigNumber | null;
      auctionStatus: AuctionStatus | null;
    };
  };
};

export type InLiquidationAsset = {
  startTime: number;
  collectionName: string;
  symbol: ERC721Symbol;
  contractAddress: string;
  nTokenAddress: string;
  identifierOrCriteria: number;
  floorPrice: BigNumber;
  buyNowPrice: BigNumber;
  floorPriceInUsd: BigNumber;
  buyNowPriceInUsd: BigNumber;
  status: AuctionStatus;
  stepLinear: number;
  stepExp: number;
  currentPriceMultiplier: number;
  maxPriceMultiplier: number;
  minPriceMultiplier: number;
  minExpPriceMultiplier: number;
  traitMultiplier: BigNumber;
};

export type InLiquidationRow = {
  address: string;
  collateral: string;
  debt: string;
  healthFactor: number;
  nftHealthFactor: number;
  isPaused: boolean;
  assets: InLiquidationAsset[];
};

export enum NearLiquidationAssetType {
  ERC20 = 'ERC20',
  ERC721 = 'ERC721'
}

export type LiquidationAsset = {
  symbol: ERC20Symbol | ERC721Symbol;
  contractAddress?: string;
  identifierOrCriteria?: number;
};

export type NearLiquidationERC20Asset = {
  type: NearLiquidationAssetType.ERC20;
  amount: number;
  symbol: ERC20Symbol;
  value: number;
};

export type NearLiquidationERC721Asset = {
  type: NearLiquidationAssetType.ERC721;
  symbol: ERC721Symbol;
  amount: number;
  collectionName: string;
  contractAddress: string;
  identifierOrCriteria: number;
  floorPrice: BigNumber;
  floorPriceInUsd: BigNumber;
  traitMultiplier: BigNumber;
};

export type NearLiquidationAsset = NearLiquidationERC20Asset | NearLiquidationERC721Asset;

export type NearLiquidationRow = {
  accountAddress: string;
  collateral: string;
  debt: string;
  healthFactor: number;
  nftHealthFactor: number;
  assets: NearLiquidationAsset[];
};
