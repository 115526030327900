import { Responsive } from '@parallel-mono/components';
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';

import { ApeCoinListing, ApeListing, Listing, ListingType } from '../../../types';
import { useP2PStaking } from '../../../contexts';
import { formateSelectedListings } from '../../../utils';

import { ApeListingSelector } from './ApeListingSelector';
import { ListingTable, UpdateListingHandle } from './ListingTable';

import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { ApeStakingMainTokenSymbol, ERC20Symbol, WalletType } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';

type Props = {
  targetPool: ApeStakingMainTokenSymbol;
  defaultSelectedListing: Maybe<ApeCoinListing | ApeListing>;
  onSelectListingChange: (listing: Maybe<ApeCoinListing | ApeListing>) => void;
};
export const ApeListings: FC<Props> = memo(
  ({ targetPool, onSelectListingChange, defaultSelectedListing }) => {
    const navigate = useNavigate();

    const { nftPoolsCompoundApy } = useAutoCompoundApeInfo();
    const [selectedListing, setSelectedListing] = useState(defaultSelectedListing);
    const { joinListingWithApe, joinListingWithApeCoin } = useP2PStaking();

    const compoundApy = useMemo(
      () => nftPoolsCompoundApy?.[targetPool] ?? BigNumber(0),
      [nftPoolsCompoundApy, targetPool]
    );

    const nftPoolRef = useRef<UpdateListingHandle>(null);
    const apeCoinPoolRef = useRef<UpdateListingHandle>(null);

    useEffect(() => {
      setSelectedListing(defaultSelectedListing);
    }, [defaultSelectedListing]);

    const handleSelectListing = useCallback(
      (listing: Listing) => {
        setSelectedListing(prevState => {
          const newListing = (
            prevState?.listingHash === listing?.listingHash ? null : listing
          ) as Maybe<ApeListing | ApeCoinListing>;
          onSelectListingChange(newListing);
          return newListing;
        });
      },
      [onSelectListingChange]
    );

    const handleJoinListing = useCallback(
      async (listing: ApeCoinListing | ApeListing, walletType: WalletType) => {
        const [listingBase] = formateSelectedListings([listing]);

        const joiningPromise =
          listing.listingType === ListingType.ApeCoinListing
            ? joinListingWithApe(
                {
                  ...listingBase,
                  amount: listing.amount
                } as ApeCoinListing,
                walletType
              )
            : joinListingWithApeCoin(
                {
                  ...listingBase,
                  tokenId: listing.tokenId
                } as ApeListing,
                walletType
              );
        joiningPromise
          .then(() => {
            setSelectedListing(null);
            nftPoolRef.current?.updateListing?.(listing.listingHash);
            apeCoinPoolRef.current?.updateListing?.(listing.listingHash);
          })
          .catch(null);
      },
      [joinListingWithApeCoin, joinListingWithApe]
    );

    return (
      <>
        <ApeListingSelector
          compoundApy={compoundApy}
          selectedListing={selectedListing}
          onJoinListing={handleJoinListing}
        />
        <Responsive gap="1rem" justifyContent="space-between">
          <ListingTable
            key={`${targetPool}-${ListingType.ApeListing}`}
            ref={nftPoolRef}
            label={targetPool}
            stakingPool={targetPool}
            listingType={ListingType.ApeListing}
            tokens={[targetPool]}
            onHandleCreateListing={(source: WalletType) => {
              navigate(absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS_CREATE, {
                state: {
                  p2pToken: targetPool,
                  p2pPool: targetPool,
                  walletType: source
                }
              });
            }}
            onSelectedListingChange={handleSelectListing}
            selectedJoinListing={selectedListing}
          />
          <ListingTable
            key={`${targetPool}-ApeCoin-${ListingType.ApeCoinListing}`}
            ref={apeCoinPoolRef}
            label="ApeCoin"
            stakingPool={targetPool}
            listingType={ListingType.ApeCoinListing}
            tokens={[ERC20Symbol.APE]}
            onHandleCreateListing={(source: WalletType) => {
              navigate(absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS_CREATE, {
                state: {
                  p2pToken: ERC20Symbol.APE,
                  p2pPool: targetPool,
                  walletType: source
                }
              });
            }}
            onSelectedListingChange={handleSelectListing}
            selectedJoinListing={selectedListing}
          />
        </Responsive>
      </>
    );
  }
);
