import { useCallback, useMemo } from 'react';
import { NonfungiblePositionManager } from 'paraspace-utilities-contract-helpers';
import BigNumber from 'bignumber.js';
import { findKey } from 'lodash';

import { useMMProvider } from '../contexts/MMProvider';

import submitTransaction from '@/apps/paraspace/utils/submitTransaction';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useAppConfig, useContractsMap } from '@/apps/paraspace/hooks';

const useNtokenUniswap = () => {
  const { provider, account } = useWeb3Context();
  const { nftInfoMap, erc20InfoMap } = useMMProvider();
  const { erc20Config } = useAppConfig();
  const contracts = useContractsMap();

  const { tokenSpecificInfos } = nftInfoMap[ERC721Symbol.UNISWAP_LP] ?? {};

  const positionMgrService = useMemo(() => {
    if (!provider) {
      return null;
    }

    return new NonfungiblePositionManager(provider, contracts['UNI-V3-POS']);
  }, [contracts, provider]);

  const addLiquidity = useCallback(
    (tokenId: string, amountAdd0: BigNumber, amountAdd1: BigNumber) => {
      if (!provider || !positionMgrService) {
        return null;
      }
      const wrappedNativeTokenSymbol = findKey(erc20Config, value => value.isWrappedNativeToken);

      const { token0Symbol, token1Symbol } = tokenSpecificInfos[tokenId];
      const token0Decimal = erc20InfoMap[token0Symbol].decimals;
      const token1Decimal = erc20InfoMap[token1Symbol].decimals;

      const tx = positionMgrService.increaseLiquidity({
        tokenId,
        amount0Desired: amountAdd0.times(10 ** token0Decimal).toFixed(),
        amount1Desired: amountAdd1.times(10 ** token1Decimal).toFixed(),
        // 5% tolerance in default
        amount0Min: amountAdd0
          .times(0.9)
          .times(10 ** token0Decimal)
          .toFixed(0),
        amount1Min: amountAdd1
          .times(0.9)
          .times(10 ** token1Decimal)
          .toFixed(0),
        // 10mins
        deadline: Math.ceil(Date.now() / 1000 + 600),
        // The flowing two parameters is not correct, should be `token0PayByNativeToken` and `token1PayByNativeToken`
        token0PayByETH: token0Symbol === wrappedNativeTokenSymbol,
        token1PayByETH: token1Symbol === wrappedNativeTokenSymbol,
        onBehalfOf: account
      });
      return submitTransaction({ provider, tx });
    },
    [account, erc20Config, erc20InfoMap, positionMgrService, provider, tokenSpecificInfos]
  );

  return { addLiquidity };
};

export default useNtokenUniswap;
