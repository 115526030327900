import { useCallback, useMemo } from 'react';
import { TimeLockService } from 'paraspace-utilities-contract-helpers';
import { Log } from '@ethersproject/providers';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

export const useTimelock = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();
  const service = useMemo(() => {
    if (!provider) {
      return null;
    }

    return new TimeLockService(provider, contracts.TimeLock);
  }, [contracts, provider]);

  const claim = useCallback(
    async (agreementIds: string[]) => {
      if (!service) {
        return null;
      }

      return service.claim(agreementIds, account);
    },
    [account, service]
  );

  const claimMoonbirds = useCallback(
    async (agreementIds: string[]) => {
      if (!service) {
        return null;
      }

      return service.claimMoonbirds(agreementIds, account);
    },
    [account, service]
  );

  const claimNativeToken = useCallback(
    async (agreementIds: string[]) => {
      if (!service) {
        return null;
      }

      return service.claimETH(agreementIds, account);
    },
    [account, service]
  );

  const claimPunks = useCallback(
    async (agreementIds: string[]) => {
      if (!service) {
        return null;
      }

      return service.claimPunks(agreementIds, account);
    },
    [account, service]
  );

  const extractEventData = useCallback(
    <R = {}>(topic: string, logs: Log[]): R[] => {
      if (service === null) {
        throw new Error('service not ready yet');
      }
      const inf = service.instance.interface;
      const eventSig = inf.getEventTopic(topic);
      const logsUnderTopic = logs.filter(it => it.topics[0] === eventSig);
      return logsUnderTopic.map(({ data }) => inf.decodeEventLog(topic, data) as unknown as R);
    },
    [service]
  );

  const getContractFrozenStatus = useCallback(async () => {
    if (!provider || !service) {
      return null;
    }

    const contractFrozenStatus = await service.getContractFrozenStatus();
    return contractFrozenStatus;
  }, [provider, service]);

  const isTimeLockWhiteListed = useCallback(
    async users => {
      if (!provider || !service) {
        return null;
      }

      const isInTimeLockWhiteList = await service.isTimeLockWhiteListed(users);
      return isInTimeLockWhiteList;
    },
    [provider, service]
  );

  return {
    claim,
    claimMoonbirds,
    claimNativeToken,
    claimPunks,
    extractEventData,
    getContractFrozenStatus,
    isTimeLockWhiteListed
  };
};
