import { Icon, DataGrid, DataGridClassNames } from '@parallel-mono/components';
import styled from 'styled-components';

import { SupplyPositionsRow } from '@/apps/paraspace/pages/Credit/MoneyMarket/MySupplyPositions/types';
import { ERC20TableRow } from '@/apps/paraspace/pages/Credit/MoneyMarket/ERC20Table/types';

export const dataGridClassNames: DataGridClassNames = {
  headerCell: 'spt-header-cell',
  cell: 'spt-cell',
  expandToggleHeaderCell: 'spt-expander-header-cell',
  expandableRow: 'spt-expandable-row',
  expandToggleCell: 'spt-expander-cell',
  expandableContainer: 'spt-expand-container',
  expandedRow: 'spt-expanded-row'
};

const getStyledExpandableDataGrid = <T extends {}>({
  isSubRowAlignWithParentRow
}: {
  isSubRowAlignWithParentRow: boolean;
}) => {
  return styled(DataGrid<T>).attrs({
    classNames: dataGridClassNames
  })`
    .${dataGridClassNames.cell} {
      border-style: solid;
      border-color: ${({ theme }) => theme.skin.grey[200]};
      border-width: 1px 0;
      margin-bottom: 0.75rem;

      &:first-child {
        border-left-width: 1px;
        border-radius: 0.75rem 0 0 0.75rem;
      }

      &:last-child {
        border-right-width: 1px;
        border-radius: 0 0.75rem 0.75rem 0;
      }
    }

    .${dataGridClassNames.expandableRow} {
      .${dataGridClassNames.cell} {
        &:nth-last-child(2) {
          border-right-width: 1px;
          border-radius: 0 0.75rem 0.75rem 0;
        }
      }
    }

    .${dataGridClassNames.headerCell} {
      font-size: 1rem;
      font-weight: normal;
      border: none;
    }

    .${dataGridClassNames.expandToggleHeaderCell} {
      width: auto;
    }

    .${dataGridClassNames.expandToggleCell} {
      & svg {
        box-sizing: content-box;
      }
    }

    .${dataGridClassNames.expandableContainer} {
      padding-left: ${isSubRowAlignWithParentRow ? '3rem' : '4.5rem'};
      background: ${({ theme }) => theme.skin.background.main};
      margin-top: calc(-0.75rem - 1px);
      margin-bottom: 0.75rem;
      border: 1px solid ${({ theme }) => theme.skin.grey[200]};
      border-top: none;
      border-radius: 0 0 0.75rem 0.75rem;
      opacity: 0;
      transition: all 0.2s;
    }

    .${dataGridClassNames.expandedRow} {
      .${dataGridClassNames.cell} {
        &:first-child {
          border-radius: 0.75rem 0 0 0;
        }

        &:nth-last-child(2) {
          border-right-width: 1px;
          border-radius: 0 0.75rem 0 0;
        }
      }
      .${dataGridClassNames.expandableContainer} {
        opacity: 1;
      }
    }
  `;
};

export const StyledSupplyPositionDataGrid = getStyledExpandableDataGrid<SupplyPositionsRow>({
  isSubRowAlignWithParentRow: false
});
export const StyledERC20TableDataGrid = getStyledExpandableDataGrid<ERC20TableRow>({
  isSubRowAlignWithParentRow: false
});

export const ExpandToggle = styled(Icon).attrs({ name: 'chevronRight' })<{ open: boolean }>`
  transform: rotate(${({ open }) => (open ? '-90deg' : '90deg')});
  transition: transform 0.2s;

  &:hover {
    cursor: pointer;
  }
`;
