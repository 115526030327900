import { H2, Inline, SmallText, Stack } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import { useNavigate } from 'react-router-dom';
import { FC, memo, useMemo } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

import { STAKE_LIMIT } from '../../consts';
import apeBg from '../../Images/ape-bg.svg';
import baycBg from '../../Images/bayc-bg.svg';

import shareBg from './images/share-pool-bg.png';
import apePoolBg from './images/ape-pool-bg.png';
import nftStakingPoolBg from './images/nft-staking-poll-bg.png';
import { AutoCompoundHistoryTable } from './components/AutoCompoundHistoryTable';
import {
  NftStakingPoolContent,
  PoolInfoCard,
  Banner,
  RotateIcon,
  CrossedIcon,
  StakingPoolDetails
} from './components';

import { useGetP2PApeStakingMatchesSummaryQuery } from '@/apps/paraspace/generated/graphql';
import { Tooltip, Link } from '@/apps/paraspace/components';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import {
  MIN_PERCENT_DECIMAL,
  MAX_PERCENT,
  DEFAULT_PERCENT_DECIMAL,
  HIDDEN_DECIMAL_PERCENT
} from '@/apps/paraspace/pages/config';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { zero } from '@/apps/paraspace/consts/values';

const AutoCompoundContainer = styled(Stack)`
  max-width: 854px;
  margin: 0 auto;
`;

const PoolInfoWrapper = styled(Inline).attrs({
  gap: '2rem',
  width: '100%',
  justifyContent: 'center'
})`
  & > * {
    flex-basis: 50%;
  }
  ${({ theme }) => theme.breakpoints.only('mobile')`
    flex-direction: column;
    align-items: center;

    > * + * {
      margin-left: 0;
      margin-top: 2rem;
    }
  `};
`;
const PoolContentWrapper = styled(Inline).attrs({
  justifyContent: 'space-between',
  alignItems: 'center'
})`
  flex: 1;
`;

const CenterCardContainer = styled.div`
  width: calc(50% - 1rem);
  margin: 1.5rem auto 0;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%
  `};
`;

export const PoolInfos: FC = memo(() => {
  const { data } = useGetP2PApeStakingMatchesSummaryQuery();
  const { effectiveCapeSupplyApy, apeStakingPoolSummary } = useAutoCompoundApeInfo();

  const sharePoolTotalStakedApe = useMemo(() => {
    const {
      totalBAKCStaked = 0,
      totalBAYCStaked = 0,
      totalMAYCStaked = 0
    } = data?.P2PApeStakingMatchesSummary ?? {};
    return (
      STAKE_LIMIT.BAKC * totalBAKCStaked +
      STAKE_LIMIT.BAYC * totalBAYCStaked +
      STAKE_LIMIT.MAYC * totalMAYCStaked
    );
  }, [data?.P2PApeStakingMatchesSummary]);

  const navigate = useNavigate();

  return (
    <AutoCompoundContainer>
      <Banner />
      <PoolInfoWrapper>
        <PoolInfoCard
          bg={apePoolBg}
          icon={<RotateIcon iconName="APE" bg={apeBg} />}
          title="ParaSpace ApeCoin Pool"
          description="For ApeCoin owners to simply stake and earn."
          button={{
            text: 'Go to Pool',
            action: () => {
              navigate(absoluteRouteNames.APE_STAKING.COIN_POOL.index);
            }
          }}
        >
          <PoolContentWrapper gap="0">
            <Stack gap="0" alignItems="center">
              <Inline gap="0.25rem">
                <SmallText skin="secondary">Compound APY</SmallText>
                <Tooltip content="Your maximum yield with ParaSpace automatic daily compounding and lending interests" />
              </Inline>
              <H2 skin="success">
                {formatNumber(effectiveCapeSupplyApy ?? zero, {
                  output: 'percent',
                  decimal: (effectiveCapeSupplyApy ?? zero).gte(HIDDEN_DECIMAL_PERCENT)
                    ? MIN_PERCENT_DECIMAL
                    : DEFAULT_PERCENT_DECIMAL,
                  threshold: {
                    max: MAX_PERCENT
                  }
                })}
              </H2>
            </Stack>
            <Stack gap="0" alignItems="center">
              <SmallText skin="secondary">Staked On ParaSpace</SmallText>
              <Inline gap="0.25rem">
                <H2>
                  {formatNumber(apeStakingPoolSummary?.APE?.amount ?? 0, {
                    decimal: 1
                  })}
                </H2>
                <H2 skin="secondary">APE</H2>
              </Inline>
            </Stack>
          </PoolContentWrapper>
        </PoolInfoCard>
        <PoolInfoCard
          bg={nftStakingPoolBg}
          icon={<RotateIcon iconName="BAYC" bg={baycBg} />}
          title="NFT Staking Pools"
          description={
            <Stack>
              For NFT owners with or without ApeCoins.
              <Link to="/ape-staking#nft-pool-details" as={HashLink}>
                View Yield Details
              </Link>{' '}
            </Stack>
          }
          button={{
            text: 'Go to Pools',
            action: () => {
              navigate(absoluteRouteNames.APE_STAKING.NFT_POOLS);
            }
          }}
        >
          <NftStakingPoolContent apeStakingPoolSummary={apeStakingPoolSummary} />
        </PoolInfoCard>
      </PoolInfoWrapper>
      <CenterCardContainer>
        <PoolInfoCard
          bg={shareBg}
          icon={<CrossedIcon />}
          title="Share Staking Pool"
          description="Find other users to match with your assets for stakings and share the APY earnings."
          button={{
            text: 'Go to Pools',
            action: () => {
              navigate(absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS.index);
            }
          }}
        >
          <PoolContentWrapper gap="0">
            <Stack gap="0" alignItems="center">
              <Inline gap="0.25rem">
                <SmallText skin="secondary">Active Matches</SmallText>
              </Inline>
              <H2 skin="success">{data?.P2PApeStakingMatchesSummary.totalActiveMatches ?? 0}</H2>
            </Stack>
            <Stack gap="0" alignItems="center">
              <SmallText skin="secondary">Staked On ParaSpace</SmallText>
              <Inline gap="0.25rem">
                <H2>
                  {formatNumber(sharePoolTotalStakedApe, {
                    decimal: 1
                  })}
                </H2>
                <H2 skin="secondary">APE</H2>
              </Inline>
            </Stack>
          </PoolContentWrapper>
        </PoolInfoCard>
      </CenterCardContainer>
      <StakingPoolDetails />
      <AutoCompoundHistoryTable />
    </AutoCompoundContainer>
  );
});
