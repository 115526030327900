import { useCallback } from 'react';

import { UnwrapWETHFormData } from '..';

import { FormSubmitter } from '@/apps/paraspace/components';
import useWNativeToken from '@/apps/paraspace/pages/hooks/useWNativeToken';
import { useContractsMap } from '@/apps/paraspace/hooks';

export const UnwrapWETHSubmitter = ({
  formData,
  onFinish,
  onError
}: {
  formData: UnwrapWETHFormData;
  onFinish: () => void;
  onError: () => void;
}) => {
  const contracts = useContractsMap();
  const { withdrawWNativeToken } = useWNativeToken(contracts.WETH);
  const { amount } = formData;
  const submit = useCallback(() => withdrawWNativeToken(amount), [amount, withdrawWNativeToken]);

  return <FormSubmitter onError={onError} onFinish={onFinish} submit={submit} />;
};
