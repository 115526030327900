import { memo, useCallback, useState } from 'react';
import { useMount } from 'react-use';
import {
  CreateOrderAction,
  CreateOrderInput,
  OrderWithCounter
} from 'paraspace-seaport-js/lib/types';

import { ApproveWallet, InProgress } from '@/apps/paraspace/components';
import { ErrorConfig, getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { Maybe } from '@/apps/paraspace/typings/basic';
import useSeaport from '@/apps/paraspace/pages/hooks/useSeaport';

type Props = {
  formData: {
    signer: string;
    createdAcceptOrderInput: CreateOrderInput;
  };
  onFinish: (orderParams: OrderWithCounter) => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const SignSeaportOfferSubmitter = memo(
  ({ formData: { signer, createdAcceptOrderInput }, onFinish, onError }: Props) => {
    const [signing, setSigning] = useState(false);
    const { seaportSDK } = useSeaport();
    const createSeaportOrder = useCallback(async () => {
      if (!seaportSDK) return null;
      const { actions } = await seaportSDK.createOrder(createdAcceptOrderInput, signer);
      return actions.length > 0
        ? (actions[actions.length - 1] as CreateOrderAction).createOrder()
        : null;
    }, [createdAcceptOrderInput, seaportSDK, signer]);

    useMount(() => {
      setSigning(true);
      createSeaportOrder()
        .then(res => {
          onFinish(res!);
        })
        .catch(err => {
          const errConfig = getUserFriendlyError<ErrorConfig>(err, undefined, 'errObj');
          onError(errConfig ?? null);
          throw getUserFriendlyError(err);
        });
    });

    return signing ? <InProgress tip="Signing Order Securely" /> : <ApproveWallet />;
  }
);
