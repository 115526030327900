import { memo, useCallback, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  H4,
  Inline,
  SmallText,
  Spinner,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import { sumBy } from '@parallel-mono/utils';
import { CryptoIcon } from '@parallel-mono/business-components';
import { mapKeys, mapValues } from 'lodash';
import styled from 'styled-components';

import { ApeListItem, useApeListStatesAndActions } from '../../../ApeListProvider';
import { useV1TimelockApeInfo } from '../../../V1TimelockApeInfoProvider';
import { STAKE_LIMIT } from '../../../../consts';
import { assignCapeToApes } from '../utils';
import { ApeStakeInfo } from '../type';
import { MIN_STAKE_CAPE } from '../consts';

import { TokenSelector } from './TokenSelector';

import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { NftToken } from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { zero } from '@/apps/paraspace/consts/values';
import { formatBalance } from '@/apps/paraspace/utils/format';

type StakeApesFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  symbol: ERC721Symbol.MAYC | ERC721Symbol.BAYC;
  onSubmit: (selectedTokens: ApeStakeInfo[]) => void;
};

const MiniChip = styled(Inline)`
  padding: 0;
  background: ${({ theme }) => theme.skin.background.slot};
  border-radius: 10000px;
  padding-right: 0.5rem;
`;
export const StakeApesForm = memo(({ symbol, onSubmit, ...others }: StakeApesFormProps) => {
  const { apesInSuppliedExcludingInP2P } = useApeListStatesAndActions();
  const { timelockInfos, isLoading } = useV1TimelockApeInfo();
  const { erc20InfoMap } = useMMProvider();
  const cAPESuppliedAmount = erc20InfoMap[ERC20Symbol.CAPE].suppliedAmount ?? zero;
  const canStakeApes = useMemo(
    () =>
      apesInSuppliedExcludingInP2P
        .filter(it => it.symbol === symbol)
        .map(it => ({
          ...it,
          stakeLimit: new BigNumber(STAKE_LIMIT[it.symbol])
        }))
        .filter(it => {
          return !timelockInfos.some(
            timelockInfo => timelockInfo.symbol === it.symbol && timelockInfo.tokenId === it.tokenId
          );
        })
        .filter(it => it.stakeLimit.gt(it.stakedAmount ?? 0)),
    [apesInSuppliedExcludingInP2P, timelockInfos, symbol]
  );

  const [selectedTokens, setSelectedTokens] = useState<ApeListItem[]>([]);
  const handleSelectedApesChange = useCallback(
    (selectedApeTokens: Maybe<NftToken[] | NftToken>) => {
      setSelectedTokens((selectedApeTokens ?? []) as ApeListItem[]);
    },
    []
  );

  const handleStakeAll = useCallback(() => {
    const assignedApes = assignCapeToApes(canStakeApes, cAPESuppliedAmount);
    setSelectedTokens(
      assignedApes.map(it => canStakeApes.find(opt => opt.tokenId === it.tokenId)!)
    );
  }, [canStakeApes, cAPESuppliedAmount]);

  const handleUnstakeAll = useCallback(() => {
    setSelectedTokens([]);
  }, []);

  const assignedAPEs = useMemo(
    () => assignCapeToApes(selectedTokens, cAPESuppliedAmount),
    [selectedTokens, cAPESuppliedAmount]
  );

  const stakeAmountMap = useMemo(() => {
    return mapValues(
      mapKeys(assignedAPEs, it => it.tokenId),
      it => it.stakeAmount
    );
  }, [assignedAPEs]);

  const handleSubmit = useCallback(() => {
    onSubmit(assignedAPEs);
  }, [assignedAPEs, onSubmit]);

  const usedCape = sumBy(assignedAPEs, it => it.stakeAmount);

  const enrichedTokenList = useMemo(() => {
    return canStakeApes.map(it => ({
      ...it,
      desc: (
        <MiniChip gap="0.25rem">
          <CryptoIcon symbol="CAPE" size="1.2rem" />
          <SmallText>{formatBalance(stakeAmountMap[it.tokenId])}</SmallText>
        </MiniChip>
      )
    }));
  }, [canStakeApes, stakeAmountMap]);

  const remainingCApe = useMemo(
    () => (usedCape.gt(cAPESuppliedAmount) ? zero : cAPESuppliedAmount.minus(usedCape)),
    [cAPESuppliedAmount, usedCape]
  );

  const allAssigned =
    remainingCApe.lt(MIN_STAKE_CAPE) || selectedTokens.length === canStakeApes.length;

  const errors = useMemo(() => {
    if (selectedTokens.length === 0) {
      return null;
    }

    if (assignedAPEs.length !== selectedTokens.length) {
      return `Not enough cAPE to assign to all selected ${symbol}`;
    }

    return null;
  }, [symbol, assignedAPEs, selectedTokens]);

  const disabled = !!errors || selectedTokens.length === 0;

  return (
    <Stack {...others}>
      {!isLoading && (
        <TokenSelector
          multiselect
          tokenList={enrichedTokenList}
          selectedToken={selectedTokens}
          onChange={handleSelectedApesChange}
          noDataMessage={
            <>
              <Text fontWeight="bold">Sad...</Text>
              <Text skin="secondary">no available {symbol} found</Text>
            </>
          }
        />
      )}
      {isLoading && (
        <Inline justifyContent="center">
          <Spinner size="large" />
        </Inline>
      )}
      <H4>Ava cAPE position: {formatBalance(remainingCApe)}</H4>
      {errors && <Alert type="error">{errors}</Alert>}
      <Inline>
        <Button
          skin="secondary"
          loading={isLoading}
          disabled={isLoading || canStakeApes.length === 0}
          onClick={allAssigned ? handleUnstakeAll : handleStakeAll}
        >
          {allAssigned ? 'Unstake All' : 'Stake All'}
        </Button>
        <Button loading={isLoading} disabled={isLoading || disabled} block onClick={handleSubmit}>
          Re-stake ({selectedTokens.length})
        </Button>
      </Inline>
    </Stack>
  );
});
