import { useCallback, useState } from 'react';
import BigNumberJS from 'bignumber.js';

import {
  ApeCompoundSellToken,
  useLegacyPoolApeStaking
} from '@/apps/paraspace/pages/hooks/usePoolApeStaking';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { getEnumKeyByValue } from '@/apps/paraspace/utils/getEnumKeyByValue';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { FLOAT_SCALING_FACTOR } from '@/apps/paraspace/utils/format';

export type SellToken = ERC20Symbol.ETH | ERC20Symbol.USDC;

export const useAutoSellStrategy = () => {
  const { account } = useWeb3Context();
  const { getUserApeCompoundStrategy } = useLegacyPoolApeStaking();

  const [sellPercentage, setSellPercentage] = useState<Maybe<BigNumberJS>>(null);
  const [sellToken, setSellToken] = useState<Maybe<SellToken>>(null);

  const fetchAutoSellStrategy = useCallback(async () => {
    try {
      const res = await getUserApeCompoundStrategy();
      if (res) {
        setSellPercentage(BigNumberJS(res.swapPercent.toString()).div(FLOAT_SCALING_FACTOR));
        setSellToken(getEnumKeyByValue(ApeCompoundSellToken, res.swapTokenOut) as SellToken);
      }
    } catch (e) {
      console.error('fetch auto sell strategy', e);
      throw e;
    }
  }, [getUserApeCompoundStrategy]);

  useAsyncEffect(async () => {
    await fetchAutoSellStrategy();
  }, [account]);

  return {
    sellPercentage,
    sellToken,
    fetchAutoSellStrategy
  };
};
