import { Spinner, Stack, Text } from '@parallel-mono/components';
import { FC, useCallback, useState } from 'react';
import { useMount } from 'react-use';
import BigNumber from 'bignumber.js';

import { useApeListStatesAndActions } from '../../ApeListProvider';

import { ApeStakeInfo, BakcStakeInfo, ReStakeFormData } from './type';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { useParallelToast, useWeb3Context } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

export const ReStakeSubmitter: FC<{
  formData: ReStakeFormData;
  onFinish: () => void;
  onError: () => void;
}> = props => {
  const { formData, onFinish, onError } = props;
  const { nftInfoMap, erc20InfoMap, loadUserAccountData } = useMMProvider();
  const { refresh: refreshApeList } = useApeListStatesAndActions();
  const { submitTransactions } = useWeb3Context();
  const { reStake } = usePool();

  const [processing, setProcessing] = useState(false);

  const cApeAddress = erc20InfoMap[ERC20Symbol.CAPE].address;
  const pcApeAddress = erc20InfoMap[ERC20Symbol.CAPE].xTokenAddress;

  const parallelToast = useParallelToast();
  const stakeAPEs = useCallback(
    (apes: ApeStakeInfo[]) => {
      const apesToStake = apes.map(ape => ({
        tokenId: ape.tokenId,
        amount: new BigNumber(ape.stakeAmount).toString(10)
      }));
      const txs = reStake({
        nftAsset: nftInfoMap[apes[0].symbol].address,
        pcApeAddress,
        cApeAddress,
        nfts: apesToStake,
        pairs: []
      });
      setProcessing(true);
      return parallelToast
        .promise(
          submitTransactions(txs)
            .then(tx => tx.wait(2))
            .catch(error => {
              throw getUserFriendlyError(error);
            })
        )
        .then(() => {
          onFinish();
        })
        .then(() => {
          return Promise.all([loadUserAccountData(), refreshApeList()]);
        })
        .catch(() => {
          onError();
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [
      onFinish,
      onError,
      parallelToast,
      reStake,
      submitTransactions,
      nftInfoMap,
      cApeAddress,
      pcApeAddress,
      loadUserAccountData,
      refreshApeList
    ]
  );

  const stakeBakcs = useCallback(
    (bakcs: BakcStakeInfo[]) => {
      const maycPairedBakcs = bakcs
        .filter(it => it.apeSymbol === ERC721Symbol.MAYC)
        .map(it => ({
          bakcTokenId: it.bakcTokenId,
          mainTokenId: it.apeTokenId,
          amount: it.stakeAmount.toString(10)
        }));

      const baycPairedBakcs = bakcs
        .filter(it => it.apeSymbol === ERC721Symbol.BAYC)
        .map(it => ({
          bakcTokenId: it.bakcTokenId,
          mainTokenId: it.apeTokenId,
          amount: it.stakeAmount.toString(10)
        }));

      const maycPairedBakcStakeTxs = reStake({
        nftAsset: nftInfoMap[ERC721Symbol.MAYC].address,
        pcApeAddress,
        cApeAddress,
        nfts: [],
        pairs: maycPairedBakcs
      });
      const baycPairedBakcStakeTxs = reStake({
        nftAsset: nftInfoMap[ERC721Symbol.BAYC].address,
        pcApeAddress,
        cApeAddress,
        nfts: [],
        pairs: baycPairedBakcs
      });

      setProcessing(true);
      return parallelToast
        .promise(
          submitTransactions(maycPairedBakcStakeTxs.concat(baycPairedBakcStakeTxs))
            .then(tx => tx.wait(2))
            .catch(error => {
              throw getUserFriendlyError(error);
            })
        )
        .then(() => {
          onFinish();
        })
        .then(() => {
          return Promise.all([loadUserAccountData(), refreshApeList()]);
        })
        .catch(() => {
          onError();
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [
      onFinish,
      onError,
      parallelToast,
      submitTransactions,
      reStake,
      nftInfoMap,
      cApeAddress,
      pcApeAddress,
      loadUserAccountData,
      refreshApeList
    ]
  );

  useMount(() => {
    const { apes = [], bakcs = [] } = formData;
    if (apes.length > 0) {
      stakeAPEs(apes);
    }

    if (bakcs.length > 0) {
      stakeBakcs(bakcs);
    }
  });

  return (
    <Stack alignItems="center">
      {processing && <Spinner size="large" />}
      {processing && <Text>Processing nft staking with cAPE</Text>}
    </Stack>
  );
};
