import { useCallback, useMemo } from 'react';
import { CrytoPunkService, ERC721Service } from 'paraspace-utilities-contract-helpers';
import { DeploylessViewerClient } from 'deployless-view';
import { isEmpty, zipWith } from 'lodash';
import { BigNumber } from 'ethers';
import { ERC721Symbol } from 'paraspace-configs-v2';

import { useMMProvider } from '../pages/contexts/MMProvider';
import usePool from '../pages/hooks/usePool';
import useWPunk from '../pages/hooks/useWPunk';
import useMoonBirds from '../pages/hooks/useMoonBirds';
import { convertToChecksumAddress } from '../utils/convertToChecksumAddress';

import { useContractsMap, useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';

const useCryptoPunks = () => {
  const { provider, account } = useWeb3Context();

  const contracts = useContractsMap();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new CrytoPunkService(provider, contracts.PUNKS);
  }, [contracts.PUNKS, provider]);

  const erc721Service = useMemo(() => {
    return new ERC721Service(provider);
  }, [provider]);

  const viewer = useMemo(() => {
    if (!provider) {
      return null;
    }

    return new DeploylessViewerClient(provider);
  }, [provider]);

  const getPunksApprovedInfo = useCallback(
    async (params: { ids: number[]; user?: string }) => {
      const { ids, user = account } = params;
      if (!service || !viewer) {
        return [];
      }
      const punkContract = service.getContractInstance(contracts.PUNKS);

      const raw = await viewer?.multicall(
        ids.map(id => {
          return {
            target: contracts.PUNKS,
            callData: punkContract.interface.encodeFunctionData('punksOfferedForSale', [id])
          };
        })
      );

      const punksInfos = await Promise.all(
        raw.resultsArray.map(async (each: any) => {
          const result = punkContract.interface.decodeFunctionResult('punksOfferedForSale', each);
          const owner = await erc721Service
            .getContractInstance(contracts.nWPUNKS)
            .ownerOf(result.punkIndex.toNumber());
          // supplied
          if (owner === user) {
            return true;
          }
          // approved but not supplied
          if (
            result.isForSale === true &&
            result.onlySellTo === contracts.WPunkGatewayProxy &&
            result.minValue.lte(BigNumber.from(0))
          ) {
            return true;
          }
          return false;
        })
      );

      const res = zipWith(ids, punksInfos, (left: number, right: boolean) => {
        return {
          id: left,
          approved: right
        };
      });

      return res as { id: number; approved: boolean }[];
    },

    [
      account,
      contracts.PUNKS,
      contracts.WPunkGatewayProxy,
      contracts.nWPUNKS,
      erc721Service,
      service,
      viewer
    ]
  );

  const offerPunkForSaleToAddress = useCallback(
    async (params: { punkIndex: number; user?: string }) => {
      const { punkIndex, user = account } = params;
      if (!provider || !service) {
        return null;
      }

      const tx = await service.offerPunkForSaleToAddress({
        user,
        punkIndex,
        minSalePriceInWei: '0',
        targetAddress: contracts.WPunkGatewayProxy
      });

      return tx;
    },
    [contracts.WPunkGatewayProxy, account, provider, service]
  );

  return { getPunksApprovedInfo, offerPunkForSaleToAddress };
};

/* eslint max-lines-per-function: ["error", { "max": 260 }] */
export const useERC721 = (symbol?: ERC721Symbol) => {
  const { supplyERC721 } = usePool();
  const { supplyPunks } = useWPunk();
  const { supplyMoonBirds } = useMoonBirds();
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();
  const { nftInfoMap } = useMMProvider();
  const assetAddr = symbol ? nftInfoMap[symbol].address : '';
  const { getPunksApprovedInfo, offerPunkForSaleToAddress } = useCryptoPunks();
  const getSymbolByContractAddress = useGetSymbolByContractAddress();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new ERC721Service(provider);
  }, [provider]);

  const approvalForAll = useCallback(
    async ({
      user = account,
      spender = contracts.PoolProxy,
      tokenAddress
    }: {
      user?: string;
      spender?: string;
      tokenAddress?: string;
    }) => {
      if (!provider || !service) {
        return null;
      }

      const tx = await service.setApprovalForAll({
        spender,
        user,
        token: tokenAddress ?? assetAddr
      });
      return tx;
    },
    [assetAddr, contracts.PoolProxy, account, provider, service]
  );

  const isApproved = useCallback(
    async ({
      user = account,
      spender = contracts.PoolProxy,
      tokenId,
      tokenAddress
    }: {
      user?: string;
      spender?: string;
      tokenId: string;
      tokenAddress?: string;
    }) => {
      if (!provider || !service) {
        return null;
      }

      const hasApproved = await service.isApproved({
        spender,
        user,
        token: tokenAddress ?? assetAddr,
        token_id: tokenId
      });
      return hasApproved;
    },
    [assetAddr, provider, service, contracts.PoolProxy, account]
  );

  const balanceOf = useCallback(
    async (param: string | { assetAddress: string; owner: string }[]) => {
      if (!service || !provider) {
        return null;
      }

      // get the sum of multiple nfts's balance
      if (Array.isArray(param)) {
        const result = await Promise.all(
          param.map(async ({ assetAddress, owner }) => {
            return service.balanceOf(assetAddress, owner);
          })
        );
        return result.reduce((prev, curr) => prev + curr.toNumber(), 0);
      }
      const result = await service.balanceOf(assetAddr, param);
      return result.toNumber();
    },
    [assetAddr, provider, service]
  );

  const ownerOf = useCallback(
    async (params: { tokenId: number; address?: string }) => {
      const { tokenId, address = assetAddr } = params;
      if (!service || !provider) {
        return '';
      }

      const contractAddress = address ?? assetAddr;
      return service.getContractInstance(contractAddress).ownerOf(tokenId);
    },
    [assetAddr, provider, service]
  );

  // check approvalForAll for ERC721 except cryptoPunks
  const checkApprovalForAll = useCallback(
    async ({
      user = account,
      spender = contracts.PoolProxy,
      tokenAddress
    }: {
      user?: string;
      spender?: string;
      tokenAddress?: string;
    }) => {
      if (!service) {
        return false;
      }

      const result = await service.isApprovedForAll({
        user,
        spender,
        token: tokenAddress ?? assetAddr,
        token_ids: []
      });
      return result;
    },
    [contracts.PoolProxy, service, account, assetAddr]
  );

  const getAllowanceForAll = useCallback(
    async (params: { ids: number[]; spender: string; user?: string; tokenAddress?: string }) => {
      const { ids, spender, user = account, tokenAddress = assetAddr } = params;
      if (tokenAddress === nftInfoMap[ERC721Symbol.PUNK]?.address) {
        const punkInfo = await getPunksApprovedInfo({ ids, user });
        return punkInfo.every(item => item.approved);
      }
      return checkApprovalForAll({ spender, tokenAddress });
    },
    [account, assetAddr, checkApprovalForAll, getPunksApprovedInfo, nftInfoMap]
  );

  const genApprovalForAll = useCallback(
    async (params?: { ids?: number[]; spender?: string; user?: string; address?: string }) => {
      const {
        ids = [],
        spender = contracts.PoolProxy,
        user = account,
        address = assetAddr
      } = params ?? {};

      const allowance = await getAllowanceForAll({ ids, spender, user, tokenAddress: address });
      if (allowance) return null;
      if (convertToChecksumAddress(address) === nftInfoMap[ERC721Symbol.PUNK]?.address) {
        const punkInfos = (await getPunksApprovedInfo({ ids, user })).filter(
          item => !item.approved
        );
        return Promise.all(
          punkInfos.map(({ id }) => offerPunkForSaleToAddress({ punkIndex: id, user }))
        );
      }
      return [await approvalForAll({ user, spender, tokenAddress: address })];
    },
    [
      contracts.PoolProxy,
      account,
      assetAddr,
      getAllowanceForAll,
      nftInfoMap,
      approvalForAll,
      getPunksApprovedInfo,
      offerPunkForSaleToAddress
    ]
  );

  const genSupplyERC721Txs = useCallback(
    async (params: { tokenIds: number[]; user?: string; tokenAddress?: string }) => {
      const { tokenIds, user = account, tokenAddress = assetAddr } = params;

      const targetSymbol = getSymbolByContractAddress(tokenAddress) as ERC721Symbol;

      const tokensSuppliedStatus = await Promise.all(
        tokenIds.map(async tokenId => {
          const owner = await ownerOf({
            tokenId,
            address: nftInfoMap[targetSymbol].xTokenAddress
          });

          return owner === user;
        })
      );

      const notSuppliedTokenIds = tokenIds.filter((_, index) => !tokensSuppliedStatus[index]);
      if (isEmpty(notSuppliedTokenIds)) {
        return null;
      }

      if (tokenAddress === nftInfoMap[ERC721Symbol.PUNK]?.address) {
        const tx = await supplyPunks(notSuppliedTokenIds);
        return [tx!];
      }
      if (tokenAddress === nftInfoMap[ERC721Symbol.MOONBIRD]?.address) {
        const txs = await supplyMoonBirds(notSuppliedTokenIds);
        return txs!;
      }
      const txs = await supplyERC721(
        tokenAddress,
        notSuppliedTokenIds.map(each => String(each))
      );
      return [txs!];
    },
    [
      account,
      assetAddr,
      getSymbolByContractAddress,
      nftInfoMap,
      supplyERC721,
      ownerOf,
      supplyPunks,
      supplyMoonBirds
    ]
  );

  return {
    approvalForAll,
    checkApprovalForAll,
    isApproved,
    getAllowanceForAll,
    genApprovalForAll,
    genSupplyERC721Txs,
    balanceOf,
    ownerOf
  };
};
