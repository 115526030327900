import { memo } from 'react';
import styled from 'styled-components';
import { Button, H3, Inline, Stack, StackProps, Text } from '@parallel-mono/components';

import { ContractBanner } from '../../../components/ContractBanner/ContractBanner';
import { Listing, ListingType } from '../../../types';

type CancelListingFormProps = Omit<StackProps, 'children'> & {
  account: string;
  onSubmit?: () => void;
  listing: Listing;
  disabled?: boolean;
};

const CenteredText = styled(Text)`
  text-align: center;
`;

export const CancelListingForm = memo(
  ({ listing, account, onSubmit, disabled, ...others }: CancelListingFormProps) => {
    const apeListing = listing.listingType === ListingType.ApeListing ? listing : null;
    const apeCoinListing = listing.listingType === ListingType.ApeCoinListing ? listing : null;
    const bakcListing = listing.listingType === ListingType.BakcListing ? listing : null;
    return (
      <Stack {...others}>
        <ContractBanner
          account={account}
          apeListing={apeListing}
          apeCoinListing={apeCoinListing}
          bakcListing={bakcListing}
        />
        <Stack gap="0.25rem">
          <Inline justifyContent="center">
            <H3>Closing It?</H3>
          </Inline>
          <CenteredText skin="secondary">
            Once closed, you will no longer be available to match with co-stakers.
          </CenteredText>
        </Stack>
        <Button disabled={disabled} block size="large" skin="primary" onClick={onSubmit}>
          Confirm Closing
        </Button>
      </Stack>
    );
  }
);
