import { CryptoIcon } from '@parallel-mono/business-components';
import {
  Button,
  Card,
  H5,
  Inline,
  Responsive,
  SmallText,
  Stack,
  useBreakpoints
} from '@parallel-mono/components';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import { formatNumber, sumBy } from '@parallel-mono/utils';

import { ApeCoinListing, ApeListing, BakcListing, Listing, ListingType } from '../../../types';
import { Position } from '../types';
import { ListingInfo } from '../../../components';

import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { useApeStaking } from '@/apps/paraspace/pages/hooks/ApeStaking/useApeStaking';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { one } from '@/apps/paraspace/consts/values';

const PendingRewards = styled(Inline)`
  border: 2px solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 2rem;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    height: 2.5rem;
    margin-top: 1.5rem;
    justify-content: space-between;
  `};
`;

type PositionCardProps = {
  position: Position;
  account: string;
  poolApy: BigNumber;
  onCancelListing: (listing: Listing) => void;
  onUnmatchListing: (params: {
    blockHeight: string;
    matchedHash: string;
    apeListing: ApeListing;
    apeCoinListing: ApeCoinListing;
    bakcListing: Maybe<BakcListing>;
  }) => void;
};

const calculateListingShare = (mainListing: Maybe<Listing>, pairingListings: Maybe<Listing>[]) => {
  if (mainListing) return mainListing.share;
  if (pairingListings.some(lis => lis === null)) {
    return null;
  }
  return one.minus(sumBy(pairingListings, 'share'));
};

export const PositionCard: FC<PositionCardProps> = memo(
  ({
    position: { apeListing, apeCoinListing, bakcListing, blockHeight, matchedHash, matched },
    account,
    poolApy,
    onCancelListing,
    onUnmatchListing
  }) => {
    const [pendingRewards, setPendingRewards] = useState<BigNumber>();

    const { getStakingInfoByTokenInfo } = useApeStaking();
    const { nftInfoMap } = useMMProvider();
    const { mobile } = useBreakpoints();

    const handleCancelListing = useCallback(() => {
      onCancelListing?.((apeCoinListing ?? apeListing ?? bakcListing)!);
    }, [apeCoinListing, apeListing, bakcListing, onCancelListing]);

    const handleUnmatchListing = useCallback(() => {
      onUnmatchListing?.({
        blockHeight,
        matchedHash,
        apeListing: apeListing!,
        apeCoinListing: apeCoinListing!,
        bakcListing
      });
    }, [apeCoinListing, apeListing, bakcListing, blockHeight, matchedHash, onUnmatchListing]);

    const stakingPool =
      apeListing?.stakingPool ?? apeCoinListing?.stakingPool ?? bakcListing?.stakingPool!;

    const p2pPairingAddress = useMemo(() => nftInfoMap?.P2PPairStaking?.address, [nftInfoMap]);

    useAsyncEffect(async () => {
      if (!matched) return;
      const mainListing = stakingPool === ERC721Symbol.BAKC ? bakcListing : apeListing;
      const stakingInfo = await getStakingInfoByTokenInfo(
        stakingPool,
        mainListing!.tokenId.toString(),
        p2pPairingAddress
      );
      setPendingRewards(stakingInfo?.pendingRewards);
    }, [p2pPairingAddress, apeListing, getStakingInfoByTokenInfo, matched, stakingPool]);

    return (
      <Card border>
        <Stack gap="2rem">
          <Inline justifyContent="space-between" alignItems="flex-end">
            <Inline alignItems="center" gap="0.5rem">
              <CryptoIcon symbol={stakingPool!} size="2rem" />
              <H5>{stakingPool} Pool</H5>
            </Inline>
            <Inline gap="1rem">
              {matched && !mobile && (
                <PendingRewards inset="0 1rem" gap="0.5rem" alignItems="center">
                  <SmallText skin="secondary">Pending Rewards</SmallText>
                  <H5>{formatNumber(pendingRewards ?? 0)} APE</H5>
                </PendingRewards>
              )}

              {matched ? (
                <Button
                  skin="secondary"
                  size={mobile ? 'small' : 'medium'}
                  onClick={handleUnmatchListing}
                >
                  Unmatch
                </Button>
              ) : (
                <Button
                  skin="secondary"
                  size={mobile ? 'small' : 'medium'}
                  onClick={handleCancelListing}
                >
                  Cancel Listing
                </Button>
              )}
            </Inline>
          </Inline>
          {matched && mobile && (
            <PendingRewards inset="0 1rem" gap="0.5rem" alignItems="center">
              <SmallText skin="secondary">Pending Rewards</SmallText>
              <H5>{formatNumber(pendingRewards ?? 0)} APE</H5>
            </PendingRewards>
          )}
          <Responsive width="100%" gap="1.5rem" breakPoint="tablet">
            {stakingPool === ERC721Symbol.BAKC && (
              <ListingInfo
                stakingPool={stakingPool}
                listingType={ListingType.BakcListing}
                listing={bakcListing}
                poolApy={poolApy}
                account={account}
                predictedShare={calculateListingShare(bakcListing, [apeCoinListing, apeListing])}
              />
            )}
            <ListingInfo
              stakingPool={stakingPool}
              listingType={ListingType.ApeListing}
              listing={apeListing}
              poolApy={poolApy}
              account={account}
              predictedShare={calculateListingShare(
                apeListing,
                stakingPool === ERC721Symbol.BAKC ? [apeCoinListing, bakcListing] : [apeCoinListing]
              )}
            />
            <ListingInfo
              stakingPool={stakingPool}
              listingType={ListingType.ApeCoinListing}
              listing={apeCoinListing}
              poolApy={poolApy}
              account={account}
              predictedShare={calculateListingShare(
                apeCoinListing,
                stakingPool === ERC721Symbol.BAKC ? [apeListing, bakcListing] : [apeListing]
              )}
            />
          </Responsive>
        </Stack>
      </Card>
    );
  }
);
