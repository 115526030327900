import { sumBy, weightedAverageBy } from '@parallel-mono/utils';
import { ReactElement } from 'react';

import { calculateEffectiveApy } from '@/apps/paraspace/utils/calculateApy';
import { zero } from '@/apps/paraspace/consts/values';
import { ERC20Config } from '@/apps/paraspace/config';
import { ERC20Info } from '@/apps/paraspace/typings';

export const mergeTokenDerivatives = ({
  tokenDerivatives,
  erc20Config,
  displayIcon,
  displayName
}: {
  displayIcon: ReactElement;
  tokenDerivatives: ERC20Info[];
  erc20Config: ERC20Config;
  displayName: string;
}) => {
  return {
    ...tokenDerivatives[0],
    supplyApyRate: weightedAverageBy(
      tokenDerivatives,
      each => {
        const { apy = zero } = erc20Config[each.symbol] ?? {};
        return calculateEffectiveApy(apy, each.supplyApyRate);
      },
      each => each.suppliedAmount || zero
    ),
    suppliedAmount: sumBy(tokenDerivatives, each => each.suppliedAmount || zero),
    suppliedAmountInUsd: sumBy(tokenDerivatives, each => each.suppliedAmountInUsd || zero),
    displayName,
    displayIcon,
    subRows: tokenDerivatives
  };
};
