import BigNumber from 'bignumber.js';
import { useCallback } from 'react';
import { TransactionResponse } from '@ethersproject/providers';

import { useBridge, useNativeCurrencyBridge } from '../../../hooks';
import { SupportedChainId } from '../../../configs/networks';

import { useEOAProvider } from '@/apps/parax/contexts';
import { Maybe } from '@/apps/parax/typings/basic';

export const useBridgeImplementation = (symbol: Maybe<string>, eId: number) => {
  const { provider, chainId } = useEOAProvider();
  const bridge = useBridge(
    symbol,
    eId!,
    provider.getSigner(),
    chainId as number as SupportedChainId
  );
  const nativeCurrencyBridge = useNativeCurrencyBridge();

  const genBridgeTokenTx = useCallback(
    async (amount: BigNumber, toAddress: string): Promise<TransactionResponse> => {
      if (!bridge) {
        throw new Error('bridge not ready');
      }
      return bridge.getSendTx({ to: toAddress, amount });
    },
    [bridge]
  );

  const genBridgeNativeTokenTx = useCallback(
    async (
      amount: BigNumber,
      toAddress: string,
      l1GasFeeInWei: BigNumber,
      l2GasFeeInWei: BigNumber
    ): Promise<TransactionResponse> => {
      if (!nativeCurrencyBridge) {
        throw new Error('bridge not ready');
      }
      return nativeCurrencyBridge.getSendTx({
        to: toAddress,
        amount,
        l1GasFeeInWei,
        l2GasFeeInWei
      });
    },
    [nativeCurrencyBridge]
  );

  return {
    genBridgeTokenTx,
    genBridgeNativeTokenTx
  };
};
