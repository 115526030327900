import { Stack } from '@parallel-mono/components';
import { useCallback } from 'react';

import { MyListingItem } from '../../useMyListingData';

import { FormSubmitter } from '@/apps/paraspace/components';
import { useCancelOrders } from '@/apps/paraspace/pages/Shop/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useGetShopListingsLazyQuery } from '@/apps/paraspace/generated/graphql';

type CancelListingStepperProps = {
  listing: MyListingItem;
  onFinish: () => void;
  onError: () => void;
};

export const CancelListingStepper = ({ listing, onFinish, onError }: CancelListingStepperProps) => {
  const { account } = useWeb3Context();
  const { contractAddress, tokenId } = listing;
  const [getShopListings] = useGetShopListingsLazyQuery();

  const { cancelOrders } = useCancelOrders(['getShopListings']);

  const submitCancel = useCallback(async () => {
    const { data } = await getShopListings({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          makers: [account],
          assets: [
            {
              contractAddress,
              identifierOrCriteria: `${tokenId}`
            }
          ]
        }
      }
    });

    const { shopListings } = data || {};

    const listingsToBeCancelled =
      shopListings?.map(shopListing => ({
        orderHash: shopListing.orderHash,
        protocolData: shopListing.protocolData!
      })) ?? [];

    await cancelOrders(listingsToBeCancelled);
  }, [account, cancelOrders, contractAddress, getShopListings, tokenId]);

  return (
    <Stack>
      <FormSubmitter onError={onError} onFinish={onFinish} submit={submitCancel} />
    </Stack>
  );
};
