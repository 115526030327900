import { memo, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Responsive, Stack, Tag, Text, useBreakpoints } from '@parallel-mono/components';
import BigNumberJs from 'bignumber.js';
import { map } from 'lodash';

import { useFutureBorrowPosition, useNFTs, useApeStakingPosition } from './hooks';
import { SpecificTip, NftItemDetail, ApeCoinPill } from './components';

import { NFTThumbnailCheck, NoNFTs } from '@/apps/paraspace/components';
import { useUnMount } from '@/apps/paraspace/hooks/useUnmount';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { ERC721Symbol, NFTSpecificInfo, WalletType } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type NFT = {
  tokenId: number;
  symbol: ERC721Symbol;
  priceInUsd: BigNumberJs;
  priceInETH: BigNumberJs;
  ltv: BigNumberJs;
  reserveLiquidationThreshold: BigNumberJs;
  multiplier: BigNumberJs;
  estimatedCreditInUsd: BigNumberJs;
  specificInfo: Maybe<NFTSpecificInfo>;
};

export type CollectionInfo = {
  symbol: ERC721Symbol;
  address: string;
  collectionName: string;
  priceInETH: BigNumberJs;
  hasTokenSpecificInfos: boolean;
  supplyRewardRate: BigNumberJs;
};

export type SupplyNftFormData = {
  symbol: ERC721Symbol;
  address: string;
  walletType: WalletType;
  collectionName: string;
  supplyTokenIds: number[];
  originalBorrowLimitInUsd: BigNumberJs;
  newBorrowLimitInUsd: BigNumberJs;
};

export type SupplyNftFormProps = {
  symbol: ERC721Symbol;
  collectionName: string;
  walletType: WalletType;
  onSubmit?: (data: SupplyNftFormData) => void;
};

const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  max-height: 18.75rem;
  overflow: auto;
  ${({ theme }) => theme.breakpoints.down('md')`
    grid-template-columns: repeat(2, 1fr);
  `};
`;

export const SupplyNftForm = memo(
  ({ symbol, collectionName, walletType, onSubmit }: SupplyNftFormProps) => {
    const { nfts, collectionInfo } = useNFTs({ symbol, wallet: walletType });

    const unmountedRef = useRef(false);
    const { mobile } = useBreakpoints();

    useUnMount(() => {
      unmountedRef.current = true;
    });

    const [selectedItems, setSelectedItems] = useState<NFT[]>([]);

    const showApeStakingInfo = isNFTinvolvedWithApeStaking(symbol);

    const { stakingInfoMap } = useApeStakingPosition({ showApeStakingInfo, symbol });
    const { newBorrowLimitInUsd, originalBorrowLimitInUsd } = useFutureBorrowPosition({
      stakingInfoMap,
      showApeStakingInfo,
      symbol,
      selectedItems
    });

    const handleItemChange = useCallback(
      (item: NFT) => (checked: boolean) => {
        setSelectedItems(prev => {
          return checked ? [...prev, item] : prev.filter(it => it.tokenId !== item.tokenId);
        });
      },
      []
    );

    const handleSelectAllClick = useCallback(() => {
      setSelectedItems(items => (items.length === nfts.length ? [] : nfts));
    }, [nfts]);

    const handleSupplyClick = useCallback(() => {
      onSubmit?.({
        symbol,
        collectionName,
        walletType,
        address: collectionInfo.address,
        supplyTokenIds: map(selectedItems, 'tokenId'),
        originalBorrowLimitInUsd,
        newBorrowLimitInUsd
      });
    }, [
      collectionInfo.address,
      collectionName,
      newBorrowLimitInUsd,
      onSubmit,
      originalBorrowLimitInUsd,
      selectedItems,
      symbol,
      walletType
    ]);

    return nfts.length ? (
      <Stack gap="1.5rem">
        {symbol === ERC721Symbol.MOONBIRD && (
          <SpecificTip message="Supplying Moonbirds NFTs will not disrupt your nesting!" />
        )}
        {showApeStakingInfo && (
          <SpecificTip
            message="Supplying BAYC NFTs will not disrupt your Ape Staking! You can also manage your
          staking after supplying."
          />
        )}
        <ItemsContainer>
          {nfts.map(item => (
            <NFTThumbnailCheck
              tokenId={item.tokenId}
              symbol={symbol}
              key={symbol + item.tokenId}
              checked={selectedItems.some(it => it.tokenId === item.tokenId)}
              onChange={handleItemChange(item)}
              floatingTag={
                item.multiplier.gt(DEFAULT_MULTIPLIER) && (
                  <Tag skin="success" size="small">
                    {item.multiplier.toString()}x Boost
                  </Tag>
                )
              }
            >
              <Stack gap="0.5rem" alignItems="center" justifyContent="center">
                <Text>#{item.tokenId}</Text>
                {showApeStakingInfo && (
                  <ApeCoinPill value={stakingInfoMap[item.tokenId]?.stakedAmount} />
                )}
              </Stack>
            </NFTThumbnailCheck>
          ))}
        </ItemsContainer>
        <NftItemDetail
          collectionInfo={collectionInfo}
          selectedItems={selectedItems}
          currentBorrowPosition={originalBorrowLimitInUsd}
          futureBorrowPosition={newBorrowLimitInUsd}
        />
        <Responsive breakPoint="tablet">
          <Button block={mobile} skin="secondary" size="large" onClick={handleSelectAllClick}>
            {selectedItems.length === nfts.length ? 'Unselect All' : 'Select All'}
          </Button>
          <Button
            skin="primary"
            size="large"
            block
            disabled={selectedItems.length <= 0}
            onClick={handleSupplyClick}
          >
            {`Supply ${selectedItems.length || ''} ${selectedItems.length > 1 ? 'NFTs' : 'NFT'}`}
          </Button>
        </Responsive>
      </Stack>
    ) : (
      <Stack alignItems="center">
        <NoNFTs description={`There’s no ${collectionInfo.collectionName} NFT in this wallet`} />
      </Stack>
    );
  }
);
