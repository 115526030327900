import {
  Icon,
  Inline,
  Stack,
  Card,
  SmallText,
  H2,
  useBreakpoints,
  IconName
} from '@parallel-mono/components';
import { FC, memo } from 'react';
import styled, { useTheme } from 'styled-components';

import { formatBalance } from '@/apps/paraspace/utils/format';

const SummaryItemWrapper = styled(Inline).attrs({
  gap: '0.5rem'
})`
  ${({ theme }) => theme.breakpoints.down('desktop')`
      flex-direction: column;
      > * + * {
          margin-left: 0rem;
      }
  `};
`;

const InfoValue = styled(H2)`
  ${({ theme }) => theme.breakpoints.down('desktop')`
      font-size: 1rem;
  `};
`;

const SummaryCard = styled(Inline).attrs({
  as: Card
})`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.skin.grey[200]};
`;

type Props = {
  stakedValue: number;
  unClaimedRewards: number;
};

const SummaryItem: FC<{ title: string; value: string; icon: IconName }> = ({
  title,
  value,
  icon
}) => {
  const { desktop } = useBreakpoints();
  const theme = useTheme();
  return (
    <SummaryItemWrapper>
      <Icon color={theme.skin.grey[500]} name={icon} strokeWidth={2} />
      <Stack gap={desktop ? '0.5rem' : '0'}>
        <SmallText skin="secondary">{title}</SmallText>
        <Inline gap="0.25rem">
          <InfoValue>{value}</InfoValue>
          <InfoValue skin="secondary">APE</InfoValue>
        </Inline>
      </Stack>
    </SummaryItemWrapper>
  );
};

const StakingSummaryInfos: FC<Props> = ({ stakedValue, unClaimedRewards }: Props) => {
  const { tablet } = useBreakpoints();

  return (
    <Inline>
      <SummaryCard>
        <Inline justifyContent={tablet ? 'space-around' : 'space-between'} width="100%">
          <SummaryItem icon="trendingUp" value={formatBalance(stakedValue)} title="My Stakings" />
          <SummaryItem
            icon="medal"
            value={formatBalance(unClaimedRewards)}
            title="Unclaimed Rewards"
          />
        </Inline>
      </SummaryCard>
    </Inline>
  );
};

export default memo(StakingSummaryInfos);
