import { createContext, memo, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { AddLiquidityModal, AddLiquidityModalProps } from './AddLiquidityModal';

type AddLiquidityContextValue = {
  addLiquidity: (symbol: string, id: number | string) => Promise<void>;
};

const AddLiquidityContext = createContext<AddLiquidityContextValue>({
  addLiquidity: async () => {
    throw new Error('AddLiquidityProvider not found');
  }
});

type AddLiquidityProviderProps = {
  children: ReactNode;
};

const defaultModalProps: AddLiquidityModalProps = {
  isOpen: false,
  symbol: null,
  tokenId: null
};

export const AddLiquidityProvider = memo(({ children }: AddLiquidityProviderProps) => {
  const [modalProps, setModalProps] = useState<AddLiquidityModalProps>(defaultModalProps);

  const addLiquidity = useCallback(
    (symbol: string, tokenId: number | string) => {
      if (modalProps.isOpen) {
        throw new Error('there is a Liquidity Removal in progress');
      }
      return new Promise<void>((resolve, reject) => {
        setModalProps({
          isOpen: true,
          symbol,
          tokenId,
          onFinish: resolve,
          onClose: () => {
            reject();
            setModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
          },
          onTransactionCreated: () => {
            setModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
          }
        });
      });
    },
    [modalProps]
  );

  const contextValue: AddLiquidityContextValue = useMemo(
    () => ({
      addLiquidity
    }),
    [addLiquidity]
  );

  return (
    <AddLiquidityContext.Provider value={contextValue}>
      <AddLiquidityModal {...modalProps} />
      {children}
    </AddLiquidityContext.Provider>
  );
});

export const useAddLiquidity = () => useContext(AddLiquidityContext).addLiquidity;
