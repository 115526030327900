import { range } from 'lodash';

import { ChartData } from './types';

import { Maybe } from '@/apps/paraspace/typings/basic';

export const createData = ({
  paraSpaceEstimatedProfitsByDay,
  horizenLabsEstimatedProfitsByDay,
  anyAPREstimatedProfitsByDay
}: {
  paraSpaceEstimatedProfitsByDay: Maybe<(day: number) => number>;
  horizenLabsEstimatedProfitsByDay: Maybe<(day: number) => number>;
  anyAPREstimatedProfitsByDay: Maybe<(day: number) => number>;
}): ChartData[] => {
  return range(0, 53, 2).map(week => ({
    paraSpace: paraSpaceEstimatedProfitsByDay?.(week * 7) ?? null,
    anyAPR: anyAPREstimatedProfitsByDay?.(week * 7) ?? null,
    horizenLabs: horizenLabsEstimatedProfitsByDay?.(week * 7) ?? null,
    week
  }));
};

export type LegendName = 'paraSpace' | 'anyAPR' | 'horizenLabs';

const legendNameMapper: Record<LegendName, string> = {
  paraSpace: 'ParaSpace',
  anyAPR: 'Any APR',
  horizenLabs: 'Horizen Labs'
};

export const legendFormatter = (key: LegendName) => legendNameMapper[key];
