import { useMemo } from 'react';
import BigNumberJs from 'bignumber.js';
import { mapValues } from 'lodash';

import { emptyArray, zero } from '@/apps/paraspace/consts/values';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol, NFTSpecificInfo, WalletType } from '@/apps/paraspace/typings';
import { useEOABalances, useUniswapInfos } from '@/apps/paraspace/pages/contexts';
import { useTokensInfo } from '@/apps/paraspace/pages/contexts/TokensInfoProvider';

export const useNFTs = ({ symbol, wallet }: { symbol: ERC721Symbol; wallet: WalletType }) => {
  const { nftInfoMap, basicInfoLoaded } = useMMProvider();
  const { erc721BalanceMap: EOAERC721Balance } = useEOABalances();
  const { infoMap: uniswapInfoMap } = useUniswapInfos();
  const { tokensInfo } = useTokensInfo();
  const {
    address = '',
    priceInUsd = zero,
    priceInETH = zero,
    collectionName = '',
    supplyRewardRate = zero,
    baseLTVasCollateral = zero,
    reserveLiquidationThreshold = zero
  } = tokensInfo?.[symbol] ?? {};

  const balance =
    (wallet === 'AA' ? nftInfoMap[symbol].balance : EOAERC721Balance?.[symbol].balance) ??
    emptyArray;

  const tokenTraitMultipliers =
    wallet === 'AA'
      ? nftInfoMap[symbol].tokenTraitMultipliers
      : EOAERC721Balance?.[symbol].tokenTraitMultipliers;

  const tokenSpecificInfos = useMemo(() => {
    if (wallet === 'AA') {
      return nftInfoMap[symbol].tokenSpecificInfos;
    }
    if (symbol === ERC721Symbol.UNISWAP_LP) {
      return uniswapInfoMap;
    }
    if (symbol === ERC721Symbol.SF_VLDR) {
      const stakeFishInfo = tokensInfo?.[symbol];
      return mapValues(EOAERC721Balance?.[symbol]?.stakeFishInfoMap, v => ({
        ...v,
        priceInUsd: stakeFishInfo?.priceInUsd,
        priceInETH: stakeFishInfo?.priceInETH,
        baseLTVasCollateral: stakeFishInfo?.baseLTVasCollateral,
        reserveLiquidationThreshold: stakeFishInfo?.reserveLiquidationThreshold
      })) as Record<string, NFTSpecificInfo>;
    }
    return null;
  }, [wallet, symbol, tokensInfo, EOAERC721Balance, uniswapInfoMap, nftInfoMap]);

  const enrichedNfts = useMemo(() => {
    if (!basicInfoLoaded) {
      return [];
    }
    return balance.map(item => {
      const multiplier = tokenTraitMultipliers?.[item] ?? BigNumberJs(DEFAULT_MULTIPLIER);
      return {
        tokenId: item,
        symbol,
        priceInUsd,
        priceInETH,
        ltv: baseLTVasCollateral,
        reserveLiquidationThreshold,
        multiplier,
        estimatedCreditInUsd: priceInUsd.times(baseLTVasCollateral).times(multiplier),
        specificInfo: (tokenSpecificInfos?.[item] as NFTSpecificInfo) ?? null
      };
    });
  }, [
    balance,
    baseLTVasCollateral,
    basicInfoLoaded,
    priceInETH,
    priceInUsd,
    reserveLiquidationThreshold,
    symbol,
    tokenTraitMultipliers,
    tokenSpecificInfos
  ]);

  const collectionInfo = useMemo(
    () => ({
      symbol,
      address,
      hasTokenSpecificInfos: false,
      collectionName,
      supplyRewardRate,
      priceInETH,
      priceInUsd
    }),
    [address, collectionName, priceInETH, priceInUsd, supplyRewardRate, symbol]
  );

  return {
    nfts: enrichedNfts,
    collectionInfo
  };
};
