import { RowClickEventContext } from '@parallel-mono/components';
import { MouseEvent } from 'react';

import { dataGridClassNames } from '../StyledComponents';

export const handleCollapseRow = (
  { toggleExpandable, expandable, expanded }: RowClickEventContext,
  e: MouseEvent
) => {
  const target = e.target as HTMLElement;
  if (target.tagName === 'BUTTON') {
    return;
  }
  if (target.parentElement?.classList.contains(dataGridClassNames.expandToggleCell!)) {
    return;
  }
  if (!expandable) {
    return;
  }
  toggleExpandable?.(!expanded);
};
