import { DataGrid, DataGridClassNames } from '@parallel-mono/components';
import styled from 'styled-components';

const classNames: DataGridClassNames = {
  cell: 'round-grid-cell',
  headerCell: 'round-grid-headerCell'
};

export const StyledDataGrid = styled(DataGrid).attrs({
  classNames
})`
  padding: 0 2rem 1rem;

  .${classNames.cell} {
    border-style: solid;
    border-color: ${({ theme }) => theme.skin.grey[200]};
    border-width: 1px 0;
    margin-bottom: 0.75rem;

    &:first-child {
      border-left-width: 1px;
      border-radius: 0.75rem 0 0 0.75rem;
    }

    &:last-child {
      border-right-width: 1px;
      border-radius: 0 0.75rem 0.75rem 0;
    }
  }

  .${classNames.headerCell} {
    font-size: 1rem;
    font-weight: normal;
    padding-top: 0;
    border: none;
    margin-bottom: 0;
  }

  .hiddenTitleLine:not(:empty) {
    width: 100%;
    margin-left: 0;
  }
` as typeof DataGrid;
