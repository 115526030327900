import { Network } from 'paraspace-configs-v2';

export const networkIconMap = {
  [Network.MAINNET]:
    'https://biz-assets.parallel.fi/default/icons/chain/ethereum.cb464715f336f81b7f80b760ace1f6ab9073b82f36be2c842b80116e5690b351.svg',
  [Network.SEPOLIA]:
    'https://biz-assets.parallel.fi/default/icons/chain/ethereum.cb464715f336f81b7f80b760ace1f6ab9073b82f36be2c842b80116e5690b351.svg',
  [Network.ARBITRUM_GOERLI]:
    'https://biz-assets.parallel.fi/default/icons/chain/arbitrum.08a1abf787ae7d75c09dbde0d20a070be21f59f505e62be182525621dbbe7d01.svg',
  [Network.ARBITRUM_ONE]:
    'https://biz-assets.parallel.fi/default/icons/chain/arbitrum.08a1abf787ae7d75c09dbde0d20a070be21f59f505e62be182525621dbbe7d01.svg',
  [Network.ZKSYNC_GOERLI]:
    'https://biz-assets.parallel.fi/default/icons/chain/zksync.9239f2181cbdc342cd811317021abd7267b00e00f1aeff45a511541e75e867a2.svg',
  [Network.ZKSYNC_ERA]:
    'https://biz-assets.parallel.fi/default/icons/chain/zksync.9239f2181cbdc342cd811317021abd7267b00e00f1aeff45a511541e75e867a2.svg',
  [Network.PARALLEL_L3_TESTNET]:
    'https://biz-assets.parallel.fi/default/design/PDS_V3/logo/parallel-v2-logo.0250411e9cf623f37ba2092ee8958e3a016423d844860814d7734335435491f3.svg',
  [Network.PARALLEL]:
    'https://biz-assets.parallel.fi/default/design/PDS_V3/logo/parallel-v2-logo.0250411e9cf623f37ba2092ee8958e3a016423d844860814d7734335435491f3.svg',
  168587773:
    'https://biz-assets.parallel.fi/default/icons/chain/blast.2a59b03b80a9d41b305e062adfdc19dad6d25898ced176ba99220f7be4cbc763.svg',
  81457:
    'https://biz-assets.parallel.fi/default/icons/chain/blast.2a59b03b80a9d41b305e062adfdc19dad6d25898ced176ba99220f7be4cbc763.svg'
} as Record<number, string>;
