import { FC, memo } from 'react';
import {
  ChainIconProps,
  HostedImage,
  ChainIcon as RawChainIcon
} from '@parallel-mono/business-components';
import styled from 'styled-components';

const StyledChainIcon = styled(RawChainIcon)<ChainIconProps>`
  ${({ name, theme }) =>
    name === 'blast'
      ? `
    background: ${theme.skin.primary.main};
    border-radius: 50%;
  `
      : ''}
`;

export const ChainIcon: FC<ChainIconProps> = memo(({ height, width, name }) => {
  return name === 'parallel' ? (
    <HostedImage name="design/PDS_V3/logo/parallel-v2-logo" height={height} width={width} />
  ) : (
    <StyledChainIcon name={name} height={height} width={width} />
  );
});
