import { FC, useCallback, useMemo, useState } from 'react';
import { Stack, Text } from '@parallel-mono/components';

import { useCreateOrders } from '../../../hooks';
import { ListingToken } from '../../../types';

import { FormSubmitter } from '@/apps/paraspace/components';

type Props = {
  formData: {
    listingTokens: ListingToken[];
  };
  onError: () => void;
  onFinish: (results: any[]) => void;
};

export const CreateOrdersSubmitter: FC<Props> = ({
  formData: { listingTokens },
  onError,
  onFinish
}) => {
  const createOrderList = useCreateOrders();
  const [submitterStep, setSubmitterStep] = useState(0);
  const handleNextStep = useCallback(() => {
    setSubmitterStep(prev => prev + 1);
  }, []);

  const submitters = useMemo(
    () =>
      listingTokens.map((listingToken, index) => {
        const submit = async (): Promise<any> => {
          const results = await createOrderList([listingToken]);
          return results;
        };

        return (
          <FormSubmitter
            key={`${listingToken.symbol}-${listingToken.tokenId}`}
            submit={submit}
            onError={onError}
            onFinish={index === listingTokens.length - 1 ? onFinish : handleNextStep}
          >
            <Stack inset="0.5rem 0 0 0">
              <Text skin="secondary">
                Creating the {listingToken.symbol}#{listingToken.tokenId} listing.
              </Text>
            </Stack>
          </FormSubmitter>
        );
      }),
    [createOrderList, handleNextStep, listingTokens, onError, onFinish]
  );

  return submitters[submitterStep];
};
