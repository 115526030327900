import { useMemo } from 'react';
import { chain, isEmpty, isNil } from 'lodash';

import { ListableCollection } from '../types';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useCollections } from '@/apps/paraspace/pages/Shop/contexts';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import { ApeStakingTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useAccountLiquidationStatus } from '@/apps/paraspace/pages/hooks/useAccountLiquidationStatus';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { useGetShopListingsQuery } from '@/apps/paraspace/generated/graphql';
import { COLLECTION_EXPECTED_ORDER } from '@/apps/paraspace/consts/collectionSort';

export const useListableCollections = () => {
  const { nftInfoMap } = useMMProvider();
  const { erc721Config } = useAppConfig();

  const { inLiquidation } = useAccountLiquidationStatus();
  const { collections, loading } = useCollections();
  const { checkIfTokenInPairing } = useP2PInfo();

  const collectionsLoading = useMemo(() => isEmpty(collections) && loading, [collections, loading]);

  // For the collection endpoint, there is no CRYPTOPUNKS collection item
  // But we need show the CRYPTOPUNKS balance so that the user can list it
  // So add the it to the listable collections separately
  const collectionsWithCryptopunks = useMemo(() => {
    const wpunksCollectionInfo = collections.find(
      v => v.contractAddress === erc721Config[ERC721Symbol.WPUNKS].address
    );

    if (wpunksCollectionInfo) {
      return chain([
        ...collections,
        {
          ...wpunksCollectionInfo,
          symbol: ERC721Symbol.PUNK,
          address: erc721Config[ERC721Symbol.PUNK].address,
          collectionName: erc721Config[ERC721Symbol.PUNK].collectionName,
          contractName: erc721Config[ERC721Symbol.PUNK].contractName,
          contractAddress: erc721Config[ERC721Symbol.PUNK].address,
          name: erc721Config[ERC721Symbol.PUNK].collectionName
        }
      ])
        .orderBy(({ symbol }) => COLLECTION_EXPECTED_ORDER.indexOf(symbol))
        .value();
    }
    return collections;
  }, [collections, erc721Config]);

  const collectionInfoList: ListableCollection[] = useMemo(
    () =>
      collectionsWithCryptopunks
        .map(collection => {
          const { collectionName, symbol } = collection;
          const {
            balance = [],
            priceInETH,
            nftSuppliedList = [],
            nftCollateralList = []
          } = nftInfoMap?.[symbol] ?? {};

          return {
            symbol,
            collectionName,
            tokens: chain([...nftSuppliedList, ...balance])
              .map(tokenId => {
                const isSupplied = nftSuppliedList?.includes(tokenId);
                const isInP2PPairing = checkIfTokenInPairing(
                  symbol as ApeStakingTokenSymbol,
                  tokenId
                );
                const asCollateral = nftCollateralList.includes(tokenId);
                const disabled = (inLiquidation && asCollateral) || isInP2PPairing;
                const disabledTip = isInP2PPairing
                  ? "Cannot list this NFT because it's staked on Shared Pool. Please unstake to list."
                  : '';

                return {
                  symbol,
                  tokenId,
                  isSupplied,
                  isInP2PPairing,
                  asCollateral,
                  disabled,
                  disabledTip
                };
              })
              .orderBy(['disabled', 'desc'])
              .value(),
            floorPrice: priceInETH
          };
        })
        .filter(collection => !isEmpty(collection.tokens)),
    [checkIfTokenInPairing, collectionsWithCryptopunks, inLiquidation, nftInfoMap]
  );

  const assets = useMemo(() => {
    return chain(collectionInfoList)
      .map(({ symbol, tokens }) => {
        const contractAddress = nftInfoMap[symbol].address;
        return tokens.map(({ tokenId }) => ({
          identifierOrCriteria: String(tokenId),
          contractAddress
        }));
      })
      .filter(tokens => !isEmpty(tokens))
      .flatten()
      .value();
  }, [collectionInfoList, nftInfoMap]);

  const { data: shopListingData, error } = useGetShopListingsQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: { assets, lowestListing: true }
    }
  });

  const isLoading = useMemo(
    () => isEmpty(nftInfoMap) || (isNil(shopListingData) && isNil(error)) || collectionsLoading,
    [collectionsLoading, error, nftInfoMap, shopListingData]
  );

  const listableCollections = useMemo(() => {
    return collectionInfoList
      .map(collection => ({
        ...collection,
        tokens: collection.tokens.filter(({ tokenId, symbol }) => {
          const listing = shopListingData?.shopListings?.find(
            ({ identifierOrCriteria, contractAddress }) =>
              identifierOrCriteria === String(tokenId) &&
              erc721Config[symbol].address === contractAddress
          );

          // filter the tokens that were listed
          return !listing;
        })
      }))
      .filter(({ tokens }) => !isEmpty(tokens));
  }, [collectionInfoList, erc721Config, shopListingData?.shopListings]);

  return {
    listableCollections,
    loading: isLoading
  };
};
