import { CryptoIcon } from '@parallel-mono/business-components';
import { Button, H4, Icon, Inline, Spinner, Stack, Text } from '@parallel-mono/components';
import styled from 'styled-components';
import { useCallback, useMemo, useState } from 'react';
import {
  TokenOption,
  Value
} from '@parallel-mono/business-components/components/SelectableTokenInput/types';
import { floor } from 'lodash';

import { ERC20Symbol } from '../../typings';

import { useParallelToast } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { AddAssetsButton, SelectableTokenInput } from '@/apps/paraspace/components';
import useWNativeToken from '@/apps/paraspace/pages/hooks/useWNativeToken';
import { useContractsMap } from '@/apps/paraspace/hooks';
import { useNativeTokenSymbol, useWNativeTokenSymbol } from '@/apps/paraspace/pages/hooks';

const StyledIcon = styled(Icon)`
  align-self: center;
`;

const AddWNativeTokenForm = ({ hideAddWTokenForm }: { hideAddWTokenForm: () => void }) => {
  const [nativeTokenInputAmount, setNativeTokenInputAmount] = useState<number | null>(null);
  const [state, setState] = useState<'default' | 'pending' | 'completed'>('default');

  const toast = useParallelToast();
  const contracts = useContractsMap();
  const nativeToken = useNativeTokenSymbol();
  const wNativeToken = useWNativeTokenSymbol();
  const { depositWNativeToken } = useWNativeToken(contracts[wNativeToken]);
  const { erc20InfoMap } = useMMProvider();

  const handleDeposit = useCallback(() => {
    if (!nativeTokenInputAmount) return;
    setState('pending');
    toast.promise(
      depositWNativeToken(nativeTokenInputAmount)
        .then(async tx => {
          await tx?.wait();
          setState('completed');
        })
        .catch(e => {
          setState('default');
          throw getUserFriendlyError(e);
        })
    );
  }, [toast, depositWNativeToken, nativeTokenInputAmount]);

  const { priceInUsd, balance } = erc20InfoMap[nativeToken] || {};
  const nativeTokenPriceInUSD = priceInUsd?.toNumber();
  const nativeTokenBalance = useMemo(
    () => floor(balance?.toNumber() || 0, MAXIMUM_BALANCE_DECIMALS),
    [balance]
  );

  const handleNativeTokenMaxClick = () => {
    setNativeTokenInputAmount(nativeTokenBalance);
  };

  const handleNativeTokenChange = ({ amount }: Value<TokenOption>) => {
    setNativeTokenInputAmount(amount || 0);
  };

  const balanceShortage = useMemo(
    () => (nativeTokenInputAmount ?? 0) - nativeTokenBalance,
    [nativeTokenBalance, nativeTokenInputAmount]
  );

  if (state === 'default') {
    return (
      <Stack>
        <SelectableTokenInput
          tokens={[
            {
              name: nativeToken,
              symbol: nativeToken,
              balance: nativeTokenBalance,
              priceInUSD: nativeTokenPriceInUSD
            }
          ]}
          value={{
            token: {
              name: nativeToken,
              symbol: nativeToken,
              balance: nativeTokenBalance,
              priceInUSD: nativeTokenPriceInUSD,
              displayBalance: `${nativeTokenBalance} ${nativeToken}`
            },
            amount: nativeTokenInputAmount
          }}
          actionButtonText="Max"
          decimals={MAXIMUM_BALANCE_DECIMALS}
          onActionButtonClicked={handleNativeTokenMaxClick}
          onChange={handleNativeTokenChange}
        />
        <StyledIcon name="chevronDown" />
        <SelectableTokenInput
          tokens={[
            {
              name: wNativeToken,
              symbol: wNativeToken,
              balance: nativeTokenBalance,
              priceInUSD: nativeTokenPriceInUSD
            }
          ]}
          value={{
            token: {
              name: wNativeToken,
              symbol: wNativeToken,
              balance: nativeTokenBalance,
              priceInUSD: nativeTokenPriceInUSD
            },
            amount: nativeTokenInputAmount
          }}
          onChange={handleNativeTokenChange}
        />
        <Button
          size="large"
          block
          onClick={handleDeposit}
          disabled={!nativeTokenInputAmount || nativeTokenInputAmount > nativeTokenBalance}
        >
          Wrap
        </Button>
        {(balanceShortage > 0 || nativeTokenBalance === 0) && (
          <AddAssetsButton
            context={{
              erc20Assets: [
                {
                  symbol: ERC20Symbol.ETH,
                  amount: balanceShortage
                }
              ],
              erc721Assets: []
            }}
          />
        )}
      </Stack>
    );
  }
  if (state === 'pending') {
    return (
      <Stack alignItems="center">
        <Spinner size="large" />
        <Stack gap=".25rem" alignItems="center">
          <H4>
            Wrapping {nativeToken} to {wNativeToken}
          </H4>
          <Text skin="secondary">This may take up to 30 seconds...</Text>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack alignItems="center">
      <Inline gap="1rem" alignItems="center">
        <CryptoIcon symbol={nativeToken} size="large" />
        <Icon name="arrowRight" />
        <CryptoIcon symbol={wNativeToken} size="large" />
      </Inline>
      <Stack gap=".25rem" alignItems="center">
        <H4>Success!</H4>
        <Text skin="secondary">You can now use your {wNativeToken}.</Text>
      </Stack>
      <Button size="large" block skin="secondary" onClick={hideAddWTokenForm}>
        Continue to Buy Now
      </Button>
    </Stack>
  );
};

export default AddWNativeTokenForm;
