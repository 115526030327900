import { Modal, ModalProps } from '@parallel-mono/components';
import { memo, useCallback } from 'react';

import { RemoveLiquidityForm } from './RemoveLiquidityForm';
import { FormData } from './RemoveLiquidityProvider';

export type RemoveLiquidityModalProps = {
  onRemoveLiquidity?: (formData: FormData) => Promise<void>;
  symbol: string | null;
  id: number | null;
} & Omit<ModalProps, 'children' | 'title' | 'id'>;

export const RemoveLiquidityModal = memo((props: RemoveLiquidityModalProps) => {
  const { onRemoveLiquidity, symbol, id, ...others } = props;
  const handleSubmit = useCallback(
    async (formData: FormData) => onRemoveLiquidity?.(formData),
    [onRemoveLiquidity]
  );

  return (
    <Modal size="32.5rem" title="Remove Liquidity" closeOnBackdropClick={false} {...others}>
      {symbol !== null && id !== null && (
        <RemoveLiquidityForm symbol={symbol} id={id} onSubmit={handleSubmit} />
      )}
    </Modal>
  );
});
