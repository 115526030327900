import { memo, useCallback } from 'react';
import { Button, H4, Stack, StackProps, Text, Image, Responsive } from '@parallel-mono/components';
import styled from 'styled-components';

import { useNFTPageUIProvider } from '../../contexts';

import noCollateral from './no-collateral.svg';

import { useAppConfig } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';

type NoCollateralMessageProps = Omit<StackProps, 'children'> & {
  onSupplyNFT: () => void;
  onSupplyERC20: () => void;
};

const NoCollateralLabel = styled(H4)`
  margin-top: 0.5rem;
`;

const NoCollateralText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.skin.grey[700]};
`;

export const NoCollateralMessage = memo(
  ({ onSupplyNFT, onSupplyERC20, ...others }: NoCollateralMessageProps) => {
    const { scrollToERC20Table, scrollToNFTSupplyTable } = useNFTPageUIProvider();
    const { features } = useAppConfig();

    const handleSupplyNFT = useCallback(() => {
      scrollToNFTSupplyTable();
      onSupplyNFT();
    }, [scrollToNFTSupplyTable, onSupplyNFT]);

    const handleSupplyERC20 = useCallback(() => {
      scrollToERC20Table();
      onSupplyERC20();
    }, [scrollToERC20Table, onSupplyERC20]);

    const enableSupplyNFT = features.some(i =>
      [
        Feature.GetCreditFromBlueChips,
        Feature.GetCreditFromUniswapToken,
        Feature.GetCreditFromEthValidator
      ].includes(i)
    );

    return (
      <Stack alignItems="center" {...others}>
        <Image src={noCollateral} />
        <NoCollateralLabel>No Collateral</NoCollateralLabel>
        <NoCollateralText>
          <Text>
            <>
              To borrow, please first supply {enableSupplyNFT ? 'NFTs or ERC 20' : 'ERC 20'} tokens
              and use them as collateral.
            </>
          </Text>
        </NoCollateralText>
        <Responsive>
          {enableSupplyNFT && (
            <Button onClick={handleSupplyNFT} size="large" skin="secondary" block>
              Supply NFT
            </Button>
          )}
          <Button onClick={handleSupplyERC20} size="large" skin="secondary" block>
            Supply ERC-20
          </Button>
        </Responsive>
      </Stack>
    );
  }
);
