import { FC, memo, useCallback, useMemo, useState } from 'react';
import { chunk } from 'lodash';

import { ReStakeFormData } from './type';
import { APE_STAKING_CHUNK_SIZE, BAKC_STAKING_CHUNK_SIZE } from './consts';
import { ReStakeSubmitter } from './ReStakeSubmmiter';

import { Stepper } from '@/apps/paraspace/components';

type ReStakeSteppersProps = {
  formData: ReStakeFormData;
  onError: () => void;
  onFinish: () => void;
};

export const ReStakeSteppers: FC<ReStakeSteppersProps> = memo(({ onError, onFinish, formData }) => {
  const [step, setStep] = useState(0);
  const handleNextStep = useCallback(() => {
    setStep(prev => prev + 1);
  }, []);

  const steps = useMemo(() => {
    const { apes, bakcs } = formData;
    const apesChunks = chunk(apes, APE_STAKING_CHUNK_SIZE);
    const bakcChunks = chunk(bakcs, BAKC_STAKING_CHUNK_SIZE);

    const apesSteps = apesChunks.map((apesChunk, index) => ({
      description: `re-staking chunks ${index + 1}`,
      content: (
        <ReStakeSubmitter
          key={index}
          formData={{
            apes: apesChunk
          }}
          onFinish={index === apesChunks.length - 1 ? onFinish : handleNextStep}
          onError={onError}
        />
      )
    }));

    const bakcsSteps = bakcChunks.map((bakcsChunk, index) => ({
      description: `re-staking chunks ${index + 1}`,
      content: (
        <ReStakeSubmitter
          key={index}
          formData={{
            bakcs: bakcsChunk
          }}
          onFinish={index === bakcChunks.length - 1 ? onFinish : handleNextStep}
          onError={onError}
        />
      )
    }));

    return apesSteps.concat(bakcsSteps);
  }, [formData, handleNextStep, onError, onFinish]);

  return <Stepper step={step} steps={steps} />;
});
