import { sumBy, weightedAverageBy } from '@parallel-mono/utils';
import { ReactElement } from 'react';
import BigNumberJs from 'bignumber.js';

import { calculateEffectiveApy } from '@/apps/paraspace/utils/calculateApy';
import { zero } from '@/apps/paraspace/consts/values';
import { ERC20Config } from '@/apps/paraspace/config';
import { ERC20Info } from '@/apps/paraspace/typings';

export const mergeTokenDerivatives = ({
  tokenDerivatives,
  erc20Config,
  displayIcon,
  displayName
}: {
  displayIcon: ReactElement;
  tokenDerivatives: ERC20Info[];
  erc20Config: ERC20Config;
  displayName: string;
}) => {
  // nativeToken and WToken are in fact sharing the same pool
  // here we avoid calculating it twice
  const tokenDerivativesWithoutWToken = tokenDerivatives.filter(each => !each.isWrappedNativeToken);

  return {
    ...tokenDerivatives[0],
    balance: sumBy(tokenDerivatives, each => each.balance || zero),
    balanceInUsd: sumBy(tokenDerivatives, ({ balance, priceInUsd }) => {
      if (balance && priceInUsd) return balance.times(priceInUsd);
      return zero;
    }),
    supplyApyRate: weightedAverageBy(
      tokenDerivativesWithoutWToken,
      each => {
        const { apy } = erc20Config[each.symbol]!;
        return calculateEffectiveApy(apy ?? zero, each.supplyApyRate);
      },
      each => BigNumberJs.sum(each.totalDebt || zero, each.availableLiquidity || zero)
    ),
    borrowApyRate: weightedAverageBy(
      tokenDerivativesWithoutWToken,
      each => {
        const { apy } = erc20Config[each.symbol]!;
        return calculateEffectiveApy(apy ?? zero, each.borrowApyRate);
      },
      each => BigNumberJs.sum(each.totalDebt || zero, each.availableLiquidity || zero)
    ),
    totalDebt: sumBy(tokenDerivativesWithoutWToken, each => each.totalDebt || zero),
    availableLiquidity: sumBy(
      tokenDerivativesWithoutWToken,
      each => each.availableLiquidity || zero
    ),

    displayName,
    displayIcon,
    subRows: tokenDerivatives,
    rowExpandable: true
  };
};
