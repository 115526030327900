import { useMemo } from 'react';

import { useLiquidationInfoFormatter } from './useInLiquidationInfoFormatter';

import {
  AccountInLiquidationInfo,
  useGetInLiquidationAccountsLazyQuery
} from '@/apps/paraspace/generated/graphql';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMount } from '@/apps/paraspace/hooks/useMount';

const REFRESH_DATA_INTERVAL = 20000;

export const useInLiquidationDashboardState = (filterCollections: ERC721Symbol[]) => {
  const [, { data, loading, client, refetch }] = useGetInLiquidationAccountsLazyQuery({
    pollInterval: REFRESH_DATA_INTERVAL
  });

  useMount(() => {
    refetch();
  });

  const liquidations = useMemo(
    () => (data?.inLiquidationAccounts || []) as AccountInLiquidationInfo[],
    [data?.inLiquidationAccounts]
  );

  const { liquidations: sortedLiquidations } = useLiquidationInfoFormatter(
    liquidations,
    filterCollections,
    false
  );

  return {
    loading,
    client,
    liquidations: sortedLiquidations
  };
};
