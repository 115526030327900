import BigNumber from 'bignumber.js';

import { ApeListItem } from '../../ApeListProvider';
import { STAKE_LIMIT } from '../../../consts';

import { ApeStakeInfo, BakcStakeInfo } from './type';
import { MIN_STAKE_CAPE } from './consts';

import { zero } from '@/apps/paraspace/consts/values';
import { ApeStakingMainTokenSymbol } from '@/apps/paraspace/typings';

export const assignCapeToApes = (tokens: ApeListItem[], capeAmount: BigNumber): ApeStakeInfo[] => {
  const { result } = tokens.reduce(
    (acc, curr) => {
      const remainingCape = capeAmount.minus(acc.usedCape);
      if (remainingCape.lte(0)) {
        return acc;
      }
      const remainingStakeLimit = (
        curr.stakeLimit ?? new BigNumber(STAKE_LIMIT[curr.symbol])
      ).minus(curr.stakedAmount ?? 0);
      const assignedAmount = BigNumber.min(remainingStakeLimit, remainingCape);
      if (assignedAmount.lt(MIN_STAKE_CAPE)) {
        return acc;
      }
      return {
        result: acc.result.concat([
          {
            symbol: curr.symbol,
            tokenId: curr.tokenId,
            stakeAmount: assignedAmount
          }
        ]),
        usedCape: acc.usedCape.plus(assignedAmount)
      };
    },
    {
      result: [] as ApeStakeInfo[],
      usedCape: zero
    }
  );
  return result;
};

export const assignCapeAndApeToBakcs = (
  bakcs: ApeListItem[],
  capeAmount: BigNumber,
  apes: ApeListItem[]
): BakcStakeInfo[] => {
  const normalizeBakcs = bakcs.map(bakc => ({
    bakcTokenId: bakc.tokenId,
    remainingStakeLimit: (bakc.stakeLimit ?? new BigNumber(STAKE_LIMIT.BAKC)).minus(
      bakc.stakedAmount ?? 0
    )
  }));

  const { selectedBakcs } = normalizeBakcs.reduce<{
    selectedBakcs: BakcStakeInfo[];
    capeUsed: BigNumber;
    remainingApes: ApeListItem[];
  }>(
    (acc, curr) => {
      if (capeAmount.lte(acc.capeUsed) || acc.remainingApes.length < 1) {
        return acc;
      }
      const assignedAmount = BigNumber.min(
        curr.remainingStakeLimit,
        capeAmount.minus(acc.capeUsed)
      );
      if (assignedAmount.lt(MIN_STAKE_CAPE)) {
        return acc;
      }
      return {
        selectedBakcs: acc.selectedBakcs.concat([
          {
            apeSymbol: acc.remainingApes[0].symbol as ApeStakingMainTokenSymbol,
            bakcTokenId: curr.bakcTokenId,
            apeTokenId: acc.remainingApes[0].tokenId,
            stakeAmount: assignedAmount
          }
        ]),
        capeUsed: acc.capeUsed.plus(assignedAmount),
        remainingApes: acc.remainingApes.slice(1)
      };
    },
    {
      selectedBakcs: [] as BakcStakeInfo[],
      capeUsed: zero,
      remainingApes: apes
    }
  );

  return selectedBakcs;
};
