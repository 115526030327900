import { memo } from 'react';
import { Stack, CardProps, H4 } from '@parallel-mono/components';
import styled from 'styled-components';

import { UniswapToken } from '../types';

import { UniSwapItem } from './UniSwapItem';

import { ERC20Symbol, WalletType } from '@/apps/paraspace/typings';

type CardForMobileProps = Omit<CardProps, 'children'> & {
  data: UniswapToken[];
  onSupply: (
    arg: { token0Symbol: ERC20Symbol; token1Symbol: ERC20Symbol },
    walletType: WalletType
  ) => void;
};

const NoDataImage = styled.span`
  font-size: 3.75rem;
  margin: 3.75rem 0;
`;

export const CardForMobile = memo(({ data, onSupply, ...others }: CardForMobileProps) => {
  return (
    <Stack width="100%" gap="1rem" {...others}>
      {data.map(row => (
        <UniSwapItem key={row.name} data={row} onSupply={onSupply} />
      ))}
      {data.length < 1 && (
        <Stack gap="0" alignItems="center">
          <NoDataImage>🙊</NoDataImage>
          <H4 skin="secondary">You don't have any Uniswap V3 token</H4>
        </Stack>
      )}
    </Stack>
  );
});
