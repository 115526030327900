import { useCallback, useState } from 'react';

import { SupportedChainId, supportedBridgeChains } from '../../../configs/networks';

import { useEOAProvider } from '@/apps/parax/contexts';

export const useNetworkHandler = (): [SupportedChainId, (network: SupportedChainId) => void] => {
  const { chainId } = useEOAProvider();
  const [selectedNetwork, setSelectedNetwork] = useState(
    supportedBridgeChains.some(it => it.chainId === (chainId as number))
      ? (chainId as number as SupportedChainId)
      : supportedBridgeChains[0].chainId
  );

  const handleUpdateNetwork = useCallback((network: SupportedChainId) => {
    setSelectedNetwork(network);
  }, []);

  return [selectedNetwork, handleUpdateNetwork];
};
