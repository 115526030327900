import { Inline, SmallText } from '@parallel-mono/components';
// import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';
import { memo } from 'react';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ValuePill } from '@/apps/paraspace/components';

export const ApeCoinBalancePanel = memo(props => {
  const { erc20InfoMap } = useMMProvider();
  const apeTokenInfos = erc20InfoMap[ERC20Symbol.APE];
  return (
    <ValuePill {...props} width="100%" justifyContent="space-between">
      <SmallText skin="secondary">My Balance</SmallText>
      <Inline gap="0.25rem">
        <SmallText fontWeight="bold">{formatNumber(apeTokenInfos?.balance ?? 0)}</SmallText>
        <SmallText fontWeight="bold" skin="secondary">
          APE
        </SmallText>
      </Inline>
    </ValuePill>
  );
});
