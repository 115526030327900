import { memo, useMemo } from 'react';

import { BuyShopItemFormData } from '..';

import { BuyWithCreditSubmitter } from './BuyWithCreditSubmitter';

import { Stepper, StepperProps } from '@/apps/paraspace/components';
import { BuyListing } from '@/apps/paraspace/pages/Shop/types';

type BuyWithCreditStepperProps = Omit<StepperProps, 'steps' | 'step' | 'onError'> & {
  onError: () => void;
  onFinish: () => void;
  listing: BuyListing;
  formData: BuyShopItemFormData;
};

export const BuyWithCreditStepper = memo(
  ({ onError, onFinish, formData, listing, ...others }: BuyWithCreditStepperProps) => {
    const steps = useMemo(
      () => [
        {
          description: 'Buy With Credit',
          content: (
            <BuyWithCreditSubmitter
              formData={formData}
              listing={listing}
              onFinish={onFinish}
              onError={onError}
            />
          )
        }
      ],
      [formData, listing, onFinish, onError]
    );

    return <Stepper {...others} steps={steps} step={0} />;
  }
);
