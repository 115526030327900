import { useCallback } from 'react';

import { useAppConfig, useContractsMap } from '@/apps/paraspace/hooks';
import { ERC721Symbol, TokenInfo } from '@/apps/paraspace/typings';

export const useTokensInfoWithForgedPunk = () => {
  const contracts = useContractsMap();
  const { erc721Config } = useAppConfig();

  return useCallback(
    (tokensInfo: Record<string, TokenInfo<string>> | null) => {
      if (!tokensInfo || !tokensInfo[ERC721Symbol.WPUNKS]) {
        return tokensInfo;
      }

      const punkData = {
        ...tokensInfo[ERC721Symbol.WPUNKS],
        displayName: erc721Config[ERC721Symbol.PUNK].collectionName,
        collectionName: erc721Config[ERC721Symbol.PUNK].collectionName,
        address: contracts.PUNKS,
        symbol: ERC721Symbol.PUNK
      };

      return {
        ...tokensInfo,
        [ERC721Symbol.PUNK]: punkData
      };
    },
    [contracts.PUNKS, erc721Config]
  );
};
