import { useState, useMemo, useEffect, useCallback, memo } from 'react';
import { Stack, Inline, H5, Text, Button, Skeleton, Alert } from '@parallel-mono/components';
import { TokenInput, InfoPanel } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';
import { floor } from 'lodash';

import { StakeInfoListItem } from '../../types';
import { useStakeInfoList } from '../../StakeInfoListProvider/StakeInfoListProvider';

import { NumberRange, NFTThumbnail, Tooltip } from '@/apps/paraspace/components';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';

type WithdrawFormProps = {
  stakeItem: StakeInfoListItem;
  onSubmit: (amount: string) => void;
};

const WithdrawForm = ({ onSubmit, stakeItem }: WithdrawFormProps) => {
  const [amount, setAmount] = useState<BigNumber | null>(null);
  const { stakingInfoList } = useStakeInfoList();

  const {
    tokenId,
    symbol,
    stakedAmount: stakedValue,
    pendingRewards,
    mainTokenId,
    mainTokenSymbol
  } = stakeItem;

  const ownApe = useMemo(
    () =>
      !mainTokenId ||
      stakingInfoList.find(i => i.tokenId === mainTokenId && i.symbol === mainTokenSymbol),
    [mainTokenId, mainTokenSymbol, stakingInfoList]
  );

  const handleValueChange = useCallback((value: number | null) => {
    if (value) {
      setAmount(BigNumber(value));
      return;
    }
    setAmount(null);
  }, []);

  const onMaxClick = useCallback(() => setAmount(stakedValue || null), [stakedValue]);

  useEffect(() => {
    if (!ownApe) {
      onMaxClick();
    }
  }, [onMaxClick, ownApe]);

  const amountNumber = useMemo(() => (amount ? amount.toNumber() : null), [amount]);
  const stakedValueNumber = useMemo(
    () => (stakedValue ? Number(stakedValue) : undefined),
    [stakedValue]
  );

  const errorMsg: string = useMemo(() => {
    if (amountNumber && stakedValueNumber !== undefined && amountNumber > stakedValueNumber) {
      return 'Exceeded the staked amount.';
    }
    return '';
  }, [amountNumber, stakedValueNumber]);

  const handleWithdraw = useCallback(() => {
    if (amount && amount.toNumber() > 0) {
      onSubmit(amount.toString());
    }
  }, [onSubmit, amount]);

  const disableButton = useMemo(() => !!errorMsg || !amount, [amount, errorMsg]);

  const newStakedValue = useMemo(
    () =>
      amountNumber && stakedValueNumber! >= amountNumber
        ? stakedValueNumber! - amountNumber
        : stakedValueNumber!,
    [amountNumber, stakedValueNumber]
  );

  const isWithdrawingAll = useMemo(
    () => amountNumber === stakedValueNumber,
    [amountNumber, stakedValueNumber]
  );

  const totalStakePanel = useMemo(
    () => ({
      title: 'Total Stake',
      value: stakedValue ? (
        <NumberRange
          start={stakedValueNumber || 0}
          end={newStakedValue}
          formatter={formatBalance}
          symbol="APE"
        />
      ) : (
        <Skeleton.Button height="1.5rem" width="6rem" variant="round" />
      )
    }),
    [newStakedValue, stakedValue, stakedValueNumber]
  );
  const rewardsPanel = useMemo(
    () => ({
      title: 'Rewards',
      value: (
        <NumberRange
          start={pendingRewards?.toNumber() || 0}
          end={0}
          formatter={formatBalance}
          symbol="APE"
        />
      )
    }),
    [pendingRewards]
  );
  const panelInfos = useMemo(
    () => (isWithdrawingAll ? [totalStakePanel, rewardsPanel] : [totalStakePanel]),
    [isWithdrawingAll, rewardsPanel, totalStakePanel]
  );

  return (
    <Stack gap="1.5rem">
      <Stack alignItems="center" gap="0.5rem">
        <NFTThumbnail size="xLarge" symbol={symbol} tokenId={tokenId} showDescription />
      </Stack>
      {ownApe && (
        <Stack gap="0.75rem">
          <TokenInput
            label={<H5>Amount</H5>}
            hint={
              <Inline gap=".3rem">
                {stakedValueNumber !== undefined ? (
                  <Text skin="secondary">Staked: {formatBalance(stakedValueNumber)} APE</Text>
                ) : (
                  <Skeleton.Button height="1.5rem" width="6rem" variant="round" />
                )}
                <Tooltip placement="top" content="Total amount of APE staked with this NFT." />
              </Inline>
            }
            token="APE"
            placeholder="0"
            value={amountNumber && floor(amountNumber, MAXIMUM_BALANCE_DECIMALS)}
            decimals={MAXIMUM_BALANCE_DECIMALS}
            error={errorMsg}
            onChange={handleValueChange}
            actionButtonText="Max"
            onAction={onMaxClick}
            inputProps={{
              inputProps: {
                autoFocus: true
              }
            }}
          />
        </Stack>
      )}
      <InfoPanel skin="primary" infos={panelInfos} />
      {!ownApe && (
        <Alert>
          You can only withdraw all because you don’t own either the BAYC or BAKC in this pair.
        </Alert>
      )}
      <Button block size="large" disabled={disableButton} onClick={handleWithdraw}>
        Withdraw
      </Button>
    </Stack>
  );
};

export default memo(WithdrawForm);
