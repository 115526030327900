import { useState, useCallback } from 'react';

import { JoinListingWithApeModalProps } from '../JoinListingWithApe';
import { ApeCoinListing } from '../../../types';
import { initialApeCoinListing } from '../../../consts';

import { WalletType } from '@/apps/paraspace/typings';

const defaultJoinListingWithBakcProps: JoinListingWithApeModalProps = {
  walletType: 'AA',
  isOpen: false,
  apeCoinListing: initialApeCoinListing
};

export const useJoinListingWithApe = (): [
  JoinListingWithApeModalProps,
  (apeCoinListing: ApeCoinListing, walletType: WalletType) => Promise<void>
] => {
  const [joinListingWithApeProps, setJoinListingWithApeProps] =
    useState<JoinListingWithApeModalProps>(defaultJoinListingWithBakcProps);

  const joinListingHandle = useCallback(
    (apeCoinListing: ApeCoinListing, walletType: WalletType) => {
      return new Promise<void>((resolve, reject) => {
        setJoinListingWithApeProps(curr => ({
          ...curr,
          isOpen: true,
          apeCoinListing,
          walletType,
          onClose: () => {
            setJoinListingWithApeProps(prev => ({
              ...prev,
              isOpen: false
            }));
            reject();
          },
          onFinish: () => {
            resolve();
          },
          onError: () => {
            reject();
          }
        }));
      });
    },
    []
  );

  return [joinListingWithApeProps, joinListingHandle];
};
