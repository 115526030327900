import { Modal, ModalProps, useBreakpoints } from '@parallel-mono/components';
import { FC } from 'react';

import { ReStakeForm } from './ReStakeForm';
import { ReStakeFormData } from './type';
import { ReStakeSteppers } from './ReStakeSteppers';

import {
  useCollectAndSubmitProcedure,
  CollectAndSubmitProcedurePhase,
  SuccessState,
  ErrorState
} from '@/apps/paraspace/components';

export type ReStakeModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

export const ReStakeModal: FC<ReStakeModalProps> = props => {
  const { isOpen, onClose, ...others } = props;

  const { phase, submittedFormData, handleFormSubmit, handleSubmitSuccess, handleSubmitFailed } =
    useCollectAndSubmitProcedure<ReStakeFormData>({
      running: isOpen
    });

  const { mobile } = useBreakpoints();

  return (
    <Modal
      {...others}
      onClose={onClose}
      isOpen={isOpen}
      title="Re-Stake Your NFT"
      size={mobile ? '100%' : '42rem'}
    >
      {phase === CollectAndSubmitProcedurePhase.Collecting && (
        <ReStakeForm onSubmit={handleFormSubmit} />
      )}
      {phase === CollectAndSubmitProcedurePhase.Submitting && (
        <ReStakeSteppers
          formData={submittedFormData!}
          onFinish={handleSubmitSuccess}
          onError={handleSubmitFailed}
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
      {phase === CollectAndSubmitProcedurePhase.Success && (
        <SuccessState desc="Re-staked successfully" onAction={onClose} />
      )}
    </Modal>
  );
};
