import { useMemo, useState } from 'react';
import { zipObject } from 'lodash';

import { ApeCoinSourceToJoinApeListing, ApeListing, BakcListing } from '../../../../../types';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { ApeStakingMainTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

const useSuppliedTokenMap = () => {
  const { nftInfoMap } = useMMProvider();

  return useMemo(() => {
    const symbols = [ERC721Symbol.MAYC, ERC721Symbol.BAKC, ERC721Symbol.BAYC];
    return zipObject(
      symbols,
      symbols.map(s => nftInfoMap[s].nftSuppliedList)
    );
  }, [nftInfoMap]);
};

export const useListingInputs = ({
  apeListing,
  bakcListing
}: {
  apeListing: Maybe<ApeListing>;
  bakcListing: Maybe<BakcListing>;
}) => {
  const suppliedTokenMap = useSuppliedTokenMap();

  const [apeCoinSourceInput, setApeCoinSourceInput] = useState<ApeCoinSourceToJoinApeListing>(
    ApeCoinSourceToJoinApeListing.APE_BALANCE
  );

  const [apeListingTokenInput, setApeListingTokenInput] = useState<
    Maybe<{
      symbol: ApeStakingMainTokenSymbol;
      tokenId: number;
      supplied: boolean;
    }>
  >(
    apeListing
      ? {
          symbol: apeListing.symbol as ApeStakingMainTokenSymbol,
          tokenId: apeListing.tokenId,
          supplied: suppliedTokenMap[apeListing.symbol]?.includes(apeListing.tokenId) ?? false
        }
      : null
  );

  const [bakcListingTokenInput, setBakcListingTokenInput] = useState<
    Maybe<{
      symbol: ERC721Symbol.BAKC;
      tokenId: number;
      supplied: boolean;
    }>
  >(
    bakcListing
      ? {
          symbol: ERC721Symbol.BAKC,
          tokenId: bakcListing.tokenId,
          supplied: suppliedTokenMap[ERC721Symbol.BAKC]?.includes(bakcListing.tokenId) ?? false
        }
      : null
  );

  return {
    apeCoinSourceInput,
    apeListingTokenInput: useMemo(
      () =>
        apeListingTokenInput
          ? {
              ...apeListingTokenInput,
              supplied:
                apeListingTokenInput?.symbol &&
                (suppliedTokenMap[apeListingTokenInput.symbol]?.includes(
                  apeListingTokenInput.tokenId
                ) ??
                  false)
            }
          : null,
      [apeListingTokenInput, suppliedTokenMap]
    ),
    bakcListingTokenInput: useMemo(
      () =>
        bakcListingTokenInput
          ? {
              ...bakcListingTokenInput,
              supplied:
                bakcListingTokenInput?.symbol &&
                (suppliedTokenMap[bakcListingTokenInput.symbol]?.includes(
                  bakcListingTokenInput.tokenId
                ) ??
                  false)
            }
          : null,
      [bakcListingTokenInput, suppliedTokenMap]
    ),
    handleApeCoinSourceInputChange: setApeCoinSourceInput,
    handleApeListingTokenInputChange: setApeListingTokenInput,
    handleBakcListingTokenInputChange: setBakcListingTokenInput
  };
};
