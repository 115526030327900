import { memo, useCallback, useMemo } from 'react';
import { InfoPanel } from '@parallel-mono/business-components';
import {
  Alert,
  Button,
  H5,
  Inline,
  SmallText,
  Stack,
  StackProps,
  Tag,
  Text,
  Image,
  Responsive,
  useBreakpoints
} from '@parallel-mono/components';
import BigNumberJs from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';
import { chain } from 'lodash';

import { ApeCoinPill } from '../SupplyNftModal/components';
import { useCheckV1TimelockStatus } from '../../../hooks/useCheckV1TimelockStatus';

import { useApeStakingInfo, useInfos, usePredictedBorrowInfo, useSelectionManager } from './hooks';
import { MINIMUM_REPAY_AMOUNT } from './consts';
import { ItemsContainer, BorrowedContainer } from './styledComponents';

import { emptyArray, zero } from '@/apps/paraspace/consts/values';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import {
  DEFAULT_MULTIPLIER,
  HEALTHY_FACTOR_WITH_CUSHION_FOR_WITHDRAW
} from '@/apps/paraspace/pages/config';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import {
  TimelockTerms,
  useTimelockTermsCheck,
  NFTThumbnailCheck,
  BorrowLimitBar
} from '@/apps/paraspace/components';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { useAccountLiquidationStatus } from '@/apps/paraspace/pages/hooks/useAccountLiquidationStatus';
import heartHalfContained from '@/apps/paraspace/assets/heart-half-contained.svg';

type WithdrawERC721FormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  name: string;
  symbol: ERC721Symbol;
  onSubmit: (tokenIds: number[]) => void;
};

/* eslint max-lines-per-function: ["error", { "max": 275 }] */
export const WithdrawERC721Form = memo(
  ({ name, symbol, onSubmit, ...others }: WithdrawERC721FormProps) => {
    const { overviewUserInfo, nftInfoMap } = useMMProvider();
    const {
      auctionedTokens = emptyArray,
      tokenTraitMultipliers,
      nftCollateralList = emptyArray,
      address
    } = nftInfoMap[symbol];

    const {
      selectedItemIds,
      handleItemChange,
      selectableItems,
      handleSelectAll,
      handleUnSelectAll
    } = useSelectionManager(symbol);

    const { borrowLimitInUsd } = overviewUserInfo;
    const { inLiquidation } = useAccountLiquidationStatus();

    const {
      bakcStakingInfo,
      stakingInfo,
      totalStakingInfo,
      bakcTotalStakingAmount,
      bakcTotalRewardAmount
    } = useApeStakingInfo({ symbol, tokenIds: selectedItemIds });

    const {
      totalBorrowedPositionInUsd: newBorrowAmount,
      borrowLimitInUsd: newBorrowLimitInUsd,
      liquidationThresholdInUsd: newLiquidationThresholdInUsd,
      totalCollateralPositionInUsd: newTotalCollateralPositionInUsd
    } = usePredictedBorrowInfo({
      symbol,
      selectedItemIds,
      stakingInfo,
      bakcStakingInfo
    });

    const infos = useInfos({
      borrowLimitInUsd,
      newBorrowLimitInUsd,
      symbol,
      totalStakingInfo,
      bakcTotalStakingAmount,
      bakcTotalRewardAmount
    });

    const { isLoading: isV1TimelockLoading, tokensStatus: tokenInTimelockStatus } =
      useCheckV1TimelockStatus(
        address,
        selectableItems.map(v => v.id)
      );

    const showApeStakingInfo = isNFTinvolvedWithApeStaking(symbol);

    const minimumRepayBeforeWithdraw = useMemo(() => {
      if (selectedItemIds.every(each => !nftCollateralList.includes(each))) {
        return zero;
      }
      const result = newBorrowAmount
        .times(HEALTHY_FACTOR_WITH_CUSHION_FOR_WITHDRAW)
        .minus(newLiquidationThresholdInUsd);
      return result.gt(0) ? result : zero;
    }, [newBorrowAmount, newLiquidationThresholdInUsd, nftCollateralList, selectedItemIds]);

    const selectedButOnAuction = useMemo(
      () => selectedItemIds.filter(each => auctionedTokens.includes(each)),
      [auctionedTokens, selectedItemIds]
    );

    const handleSubmit = useCallback(() => {
      onSubmit(selectedItemIds);
    }, [onSubmit, selectedItemIds]);

    const { timelockTermsChecked, handleTimelockTermsCheck, claimableTime } = useTimelockTermsCheck(
      selectedItemIds.length,
      symbol
    );

    const redeemDisable =
      minimumRepayBeforeWithdraw.gt(0) ||
      selectedItemIds.length === 0 ||
      selectedButOnAuction.length !== 0 ||
      !timelockTermsChecked;

    const getDisabledTip = useCallback(
      (
        id: number,
        isInPair: boolean,
        inV1TimelockStatus?: {
          isClaimed: boolean;
          expectedRelease: string;
          isReleaseTimeReached: boolean;
        }
      ) => {
        const { isReleaseTimeReached, isClaimed, expectedRelease } = inV1TimelockStatus ?? {};
        const isInTimelock = Boolean(inV1TimelockStatus) && !isClaimed;

        const shouldDisableSelection =
          isInTimelock || isInPair || (inLiquidation && nftCollateralList.includes(id));
        if (!shouldDisableSelection) return undefined;
        if (isInPair) {
          return `This ${symbol} cannot be withdrawn because it’s matched on share pool staking. You have to unmatch first before withdrawing.`;
        }
        if (isInTimelock) {
          return `This ${symbol} cannot be withdrawn temporarily, it is still in the timelock of ParaSpace V1, please ${
            !isReleaseTimeReached ? `withdraw after ${expectedRelease!}.` : 'wait patiently.'
          } `;
        }
        return `This ${symbol} is currently in liquidation`;
      },
      [inLiquidation, nftCollateralList, symbol]
    );

    const selectableTokens = useMemo(
      () =>
        chain(selectableItems)
          .map(v => {
            const v1TimelockTargetToken = tokenInTimelockStatus?.find(
              item => v.id === item.tokenId
            );
            const disableTip = getDisabledTip(v.id, v.isInPair, v1TimelockTargetToken);
            return {
              ...v,
              isV1IntimeLock: Boolean(v1TimelockTargetToken),
              v1TimelockStatus: v1TimelockTargetToken,
              disabled: Boolean(disableTip),
              disableTip
            };
          })
          .sortBy(['isInPair', 'isV1IntimeLock', 'id'])
          .value(),
      [getDisabledTip, selectableItems, tokenInTimelockStatus]
    );

    const { mobile } = useBreakpoints();
    const isAllSelected = useMemo(
      () => selectableTokens.filter(v => !v.disabled).length === selectedItemIds.length,
      [selectableTokens, selectedItemIds.length]
    );
    return (
      <Stack gap="1rem" {...others}>
        {showApeStakingInfo && (
          <Inline gap=".5rem" alignItems="flex-start">
            <Image src={heartHalfContained} />
            <Text fontWeight="bold">
              Withdrawing {name} NFTs will also un-commit your NFTs from staking and claim all the
              rewards for you.
            </Text>
          </Inline>
        )}
        <ItemsContainer>
          {selectableTokens.map(({ id, disableTip, disabled }) => {
            return (
              <NFTThumbnailCheck
                tokenId={id}
                symbol={symbol}
                key={symbol + id}
                checked={selectedItemIds.includes(id)}
                disabled={disabled}
                disabledTip={disableTip}
                onChange={handleItemChange}
                floatingTag={
                  tokenTraitMultipliers?.[id].gt(DEFAULT_MULTIPLIER) && (
                    <Tag skin="success" size="small">
                      {tokenTraitMultipliers?.[id].toString()}x Boost
                    </Tag>
                  )
                }
              >
                <Stack gap="0.5rem" alignItems="center" justifyContent="center">
                  <Text>#{id}</Text>
                  {showApeStakingInfo && (
                    <ApeCoinPill
                      value={
                        symbol === ERC721Symbol.BAKC
                          ? bakcStakingInfo[id]?.stakedAmount || zero
                          : BigNumberJs.sum(
                              stakingInfo[id]?.stakedAmount || zero,
                              bakcStakingInfo[id]?.stakedAmount || zero
                            )
                      }
                    />
                  )}
                </Stack>
              </NFTThumbnailCheck>
            );
          })}
        </ItemsContainer>
        {newBorrowAmount.gt(0) && (
          <BorrowedContainer>
            <SmallText skin="secondary">Borrowed</SmallText>
            <H5>{formatToCurrency(newBorrowAmount)}</H5>
            <BorrowLimitBar
              borrowedValue={newBorrowAmount}
              borrowLimitValue={newBorrowLimitInUsd}
              liquidationPointValue={newLiquidationThresholdInUsd}
              totalSuppliedValue={newTotalCollateralPositionInUsd}
            />
          </BorrowedContainer>
        )}
        {minimumRepayBeforeWithdraw.gt(0) && (
          <Alert type="error">
            You need to repay $
            {minimumRepayBeforeWithdraw.lte(MINIMUM_REPAY_AMOUNT)
              ? `${MINIMUM_REPAY_AMOUNT} `
              : formatNumber(minimumRepayBeforeWithdraw)}{' '}
            to be able to withdraw your NFT.
          </Alert>
        )}
        {selectedButOnAuction.length !== 0 && (
          <Alert type="error">
            Items {selectedButOnAuction.map(each => `#${each}`).join(', ')} are currently on auction
            !
          </Alert>
        )}
        <InfoPanel skin="primary" infos={infos} />
        <TimelockTerms
          onTermsCheck={handleTimelockTermsCheck}
          checked={timelockTermsChecked}
          claimableTime={claimableTime}
        />
        <Stack gap="1.5rem">
          <Responsive breakPoint="tablet">
            <Button
              disabled={selectableTokens.every(v => v.disabled) || isV1TimelockLoading}
              size="large"
              block={mobile}
              skin="secondary"
              onClick={isAllSelected ? handleUnSelectAll : handleSelectAll}
            >
              {isAllSelected ? 'Unselect All' : 'Select All'}
            </Button>
            <Button
              loading={isV1TimelockLoading}
              skin="primary"
              size="large"
              block
              disabled={redeemDisable}
              onClick={handleSubmit}
            >
              Withdraw {selectedItemIds.length || ''} NFT
            </Button>
          </Responsive>
        </Stack>
      </Stack>
    );
  }
);
