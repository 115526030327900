import { Stack, StackProps, useBreakpoints } from '@parallel-mono/components';
import { memo, ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { MobileSideMenu } from './MobileSideMenu';
import { DesktopSideMenu } from './DesktopSideMenu';

export type Menu = {
  icon: ReactElement;
  name: string;
  path: string;
  disabled?: boolean;
  subMenus?: Omit<Menu, 'icon' | 'subMenus'>[];
};

type SideMenusProps = {
  menus: Menu[];
} & Omit<StackProps, 'children'>;

export const SideMenus = memo(({ menus, ...others }: SideMenusProps) => {
  const navigate = useNavigate();
  const { mobile } = useBreakpoints();

  const handleMenuClick = useCallback(
    (menu: Menu) => {
      if (!menu.disabled) {
        navigate(menu.path);
      }
    },
    [navigate]
  );

  return (
    <Stack {...others}>
      {mobile ? (
        <MobileSideMenu menus={menus} onMenuClick={handleMenuClick} />
      ) : (
        <DesktopSideMenu menus={menus} onMenuClick={handleMenuClick} />
      )}
    </Stack>
  );
});

export { MenuItem } from './styledComponents';
