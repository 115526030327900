import { useCallback, useEffect, useRef, useState } from 'react';
import { chain, isNil, uniqueId } from 'lodash';
import BigNumber from 'bignumber.js';
import { StaticJsonRpcProvider } from '@ethersproject/providers';
import { ERC20Config, ERC20Symbol } from '@parallel-utils/contracts-registry';

import { ERC20 } from '../Contracts';
import { BridgeNetworks, SupportedChainId, parallelChain } from '../configs/networks';
import { parallelTokensMap } from '../configs/erc20s';

import { useNetworkProvider } from './useNetworkProvider';

import { Maybe } from '@/apps/parax/typings/basic';
import { useOracleClient, useStabilizedSnapshot } from '@/apps/parax/hooks';
import { useEOAProvider } from '@/apps/parax/contexts';
import { FetchingStatus } from '@/apps/parax/typings';
import { zero } from '@/apps/parax/consts';

export const useERC20Balances = (
  erc20Configs: Pick<ERC20Config<ERC20Symbol>, 'symbol' | 'decimals' | 'address'>[],
  provider: Maybe<StaticJsonRpcProvider>,
  chainId?: SupportedChainId
): {
  balanceMap: Maybe<Record<string, { balance: BigNumber; priceInUsd: BigNumber }>>;
  fetchBalance: () => Promise<void> | undefined;
  fetchingStatus: FetchingStatus;
} => {
  const parallelProvider = useNetworkProvider(parallelChain.chainId);
  const addresses = useStabilizedSnapshot(erc20Configs);
  const { account } = useEOAProvider();
  const { getTokenPrices } = useOracleClient(parallelProvider);

  const [fetchingStatus, setFetchingStatus] = useState<FetchingStatus>(FetchingStatus.INIT);
  const [balanceMap, setBalanceMap] =
    useState<Maybe<Record<string, { balance: BigNumber; priceInUsd: BigNumber }>>>(null);

  const latestTaskIdRef = useRef<string>();

  const fetchBalance = useCallback(async () => {
    if (provider === null || isNil(chainId)) {
      return;
    }
    const taskId = uniqueId();
    latestTaskIdRef.current = taskId;
    setBalanceMap(null);
    setFetchingStatus(FetchingStatus.FETCHING);
    try {
      const [balances, prices, nativeTokenBalance, [nativeTokenPrice = zero]] = await Promise.all([
        Promise.all<{
          symbol: string;
          decimals: number;
          balance: BigNumber;
        }>(
          addresses.map(async erc20 => {
            const erc20Service = new ERC20(erc20.address, provider, erc20.decimals);
            const balance = await erc20Service.getBalance(account);
            return { ...erc20, balance: BigNumber(balance.toString()) };
          })
        ),
        getTokenPrices(addresses.map(it => parallelTokensMap[it.symbol].address)).catch(() => []),
        provider?.getBalance(account),
        getTokenPrices([parallelTokensMap.WETH.address]).catch(() => [])
      ]);
      const nativeTokenInfo = BridgeNetworks[chainId]?.nativeCurrency;
      if (taskId === latestTaskIdRef.current) {
        const balanceInfoMap = chain(balances)
          .map((v, index) => ({ ...v, balance: v.balance, priceInUsd: prices[index] ?? zero }))
          .keyBy(v => v.symbol)
          .mapValues(v => ({ balance: v.balance, priceInUsd: v.priceInUsd }))
          .value();

        setBalanceMap(
          nativeTokenInfo
            ? {
                ...balanceInfoMap,
                [nativeTokenInfo.symbol]: {
                  balance: new BigNumber(nativeTokenBalance.toString()).shiftedBy(
                    -nativeTokenInfo.decimals
                  ),
                  priceInUsd: nativeTokenPrice
                }
              }
            : balanceInfoMap
        );
        if (taskId === latestTaskIdRef.current) {
          setFetchingStatus(FetchingStatus.SUCCESS);
        }
      }
    } catch (e) {
      console.error('Fetch ERC20 balance failed. error: ', e);
      if (taskId === latestTaskIdRef.current) {
        setFetchingStatus(FetchingStatus.FAIL);
      }
    }
  }, [account, addresses, chainId, provider, getTokenPrices]);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  return { balanceMap, fetchBalance, fetchingStatus };
};
