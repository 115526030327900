import { memo, useCallback, useState } from 'react';

import { IntroducingParaX, IntroducingParaXProps } from './components';

type IntroducingParaXBannerProps = IntroducingParaXProps;

const introducingParaxBannerMarkKey = 'introducing_parax_viewed';

const alreadyViewed = localStorage.getItem(introducingParaxBannerMarkKey) === 'true';

export const IntroducingParaXBanner = memo(({ onClose, ...props }: IntroducingParaXBannerProps) => {
  const [visible, setVisible] = useState(!alreadyViewed);

  const handleClose = useCallback(() => {
    setVisible(false);
    localStorage.setItem(introducingParaxBannerMarkKey, 'true');
    onClose?.();
  }, [onClose, setVisible]);

  if (!visible) return null;

  return <IntroducingParaX onClose={handleClose} {...props} />;
});
