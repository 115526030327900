import { memo, FC } from 'react';

import { ApeListItem } from '@/apps/paraspace/pages/ApePairing/contexts';
import { InProgress } from '@/apps/paraspace/components';
import { useParallelToast } from '@/apps/paraspace/contexts';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';

type Props = {
  formData: { bakcToken: ApeListItem; baycOrMaycToken: ApeListItem };
  onSuccess: () => void;
  onFailed: () => void;
  onMint: Function;
};

export const MintSubmitter: FC<Props> = memo(({ formData, onSuccess, onFailed, onMint }) => {
  const transactionToast = useParallelToast();
  useAsyncEffect(async () => {
    const { bakcToken, baycOrMaycToken } = formData;
    try {
      await onMint(
        { id: baycOrMaycToken.tokenId, symbol: baycOrMaycToken.symbol },
        { id: bakcToken.tokenId, symbol: bakcToken.symbol }
      );
      onSuccess();
    } catch (e) {
      onFailed();
    }
  }, [formData, onFailed, onMint, onSuccess, transactionToast]);

  return <InProgress tip="" />;
});
