import { FC, useMemo } from 'react';
import { Tag } from '@parallel-mono/components';
import BigNumberJS from 'bignumber.js';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { one, zero } from '@/apps/paraspace/consts/values';
import { formatToPercentage } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const AutoRepayTag: FC<{ autoSellPercentage: Maybe<BigNumberJS> }> = ({
  autoSellPercentage
}) => {
  const { erc20InfoMap } = useMMProvider();

  const capeTokenInfos = erc20InfoMap[ERC20Symbol.CAPE];

  const totalBorrowedCApe = useMemo(
    () => capeTokenInfos.borrowedAmount ?? zero,
    [capeTokenInfos.borrowedAmount]
  );

  if (totalBorrowedCApe.eq(0)) {
    return (
      <Tag skin="primary" size="small">
        No Debt
      </Tag>
    );
  }

  if (autoSellPercentage?.eq(one)) {
    return (
      <Tag skin="primary" size="small">
        Off
      </Tag>
    );
  }

  return (
    <Tag skin="success" size="small">
      {formatToPercentage(one.minus(autoSellPercentage ?? zero))}
    </Tag>
  );
};
