import {
  Button,
  H4,
  H5,
  Icon,
  Inline,
  Responsive,
  SmallText,
  Stack,
  StackProps,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import styled from 'styled-components';
import { InfoPanel } from '@parallel-mono/business-components';
import { memo, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
  TokenOption as BaseTokenOption,
  Value
} from '@parallel-mono/business-components/components/SelectableTokenInput/types';
import { ethers } from 'ethers';

import { MyListingItem } from '../useMyListingData';

import { TokenInputValue, TokenOption } from './types';
import { useListingValidate } from './validation';
import { ListEditFormData } from './ListingEditModal';

import { NFTThumbnail, Select, SelectableTokenInput } from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Duration, durationList } from '@/apps/paraspace/consts/duration';
import { formatBalance, formatToCurrency, formatToPercentage } from '@/apps/paraspace/utils/format';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { MAXIMUM_LISTING_DECIMALS } from '@/apps/paraspace/pages/config';
import { CollectionFees } from '@/apps/paraspace/utils/calculateFees';
import { shiftedLeftBy } from '@/apps/paraspace/utils/calculations';

type ListingEditFormProps = Omit<StackProps, 'children' | 'id' | 'onError'> & {
  listing: MyListingItem;
  collectionFees?: CollectionFees;
  onCancelListing: (formData: ListEditFormData) => void;
  onUpdateListing: (formData: ListEditFormData) => void;
};

const CancelTag = styled(Inline)`
  cursor: pointer;
`;
const WrapInline = styled(Inline)`
  flex-wrap: wrap;
`;

export const ListingEditForm = memo(
  ({
    listing,
    collectionFees,
    onUpdateListing,
    onCancelListing,
    ...props
  }: ListingEditFormProps) => {
    const { symbol, tokenId, price, priceInUSD } = listing;
    const { paraSpaceFeePoint, creatorFeePoint } = collectionFees || {};
    const [duration, setDuration] = useState(listing.duration);
    const [selectedTokenValue, setSelectedTokenValue] = useState({
      amount: null,
      token: {}
    } as TokenInputValue);
    const errMessage = useListingValidate({
      listedAmount: price,
      inputValue: selectedTokenValue.amount
    });
    const { nftInfoMap, erc20InfoMap } = useMMProvider();
    const nftToken = nftInfoMap[symbol];

    const floorUsdValue = nftToken ? formatToCurrency(nftToken?.priceInUsd) : '-';
    const floorAmount = formatBalance(nftToken?.priceInETH);

    const finalPaymentTokens: TokenOption[] = useMemo(
      () => [
        {
          name: 'ETH',
          symbol: ERC20Symbol.ETH,
          address: ethers.constants.AddressZero,
          balance: null,
          displayBalance: false,
          priceInUSD: erc20InfoMap[ERC20Symbol.WETH]?.priceInUsd.toNumber()
        }
      ],
      [erc20InfoMap]
    );

    const handleTokenChange = (obj: Value<BaseTokenOption>) => {
      setSelectedTokenValue(obj as TokenInputValue);
    };

    const durationChange = (option: Duration | null) => {
      setDuration(option!);
    };
    const handleListingEdit = () => {
      onUpdateListing({
        tokenValue: selectedTokenValue,
        duration
      });
    };
    const handleListingCancel = () => {
      onCancelListing({
        tokenValue: selectedTokenValue,
        duration
      });
    };

    const { desktop } = useBreakpoints();

    return (
      <Stack {...props}>
        <Responsive
          breakPoint="desktop"
          alignItems={desktop ? 'center' : 'flex-start'}
          gap="1.5rem"
        >
          <NFTThumbnail symbol={symbol} tokenId={tokenId} />
          <Stack gap="0.25rem">
            <H4>
              {nftToken.collectionName} #{tokenId}
            </H4>
            <WrapInline alignItems="center" gap="0.1rem">
              <H5>Floor Price: {floorAmount} ETH</H5>
              <H5 skin="secondary">({floorUsdValue})</H5>
            </WrapInline>
            <WrapInline alignItems="center" gap="0.1rem">
              <H5>
                Listed: {formatBalance(price)} {ERC20Symbol.ETH}
              </H5>
              <H5 skin="secondary">({priceInUSD ? formatToCurrency(priceInUSD) : '-'})</H5>
            </WrapInline>
            <CancelTag gap="0.25rem" alignItems="center" onClick={handleListingCancel}>
              <Icon name="trash" strokeWidth={2} />
              <Text fontWeight="medium">Cancel Listing</Text>
            </CancelTag>
          </Stack>
        </Responsive>
        <Stack gap="0.75rem">
          <H5>Price</H5>
          <SelectableTokenInput
            tokens={finalPaymentTokens}
            defaultValue={{
              amount: price.toNumber(),
              token: finalPaymentTokens?.[0]
            }}
            onChange={handleTokenChange}
            decimals={MAXIMUM_LISTING_DECIMALS}
            hasError={!!errMessage}
          />
          {!!errMessage && <Text skin="error">{errMessage}</Text>}
        </Stack>
        <Inline justifyContent="space-between" alignItems="center">
          <Stack gap="0">
            <H5>Duration</H5>
            <SmallText skin="secondary">
              {`Ends on ${dayjs()
                .add(duration.value, 'day')
                .utc()
                .format('MMM DD, YYYY [at] hh:mma')} UTC`}
            </SmallText>
          </Stack>
          <Select
            height="fit-content"
            options={durationList}
            value={duration?.value}
            onChange={durationChange}
          />
        </Inline>
        <InfoPanel
          skin="primary"
          infos={[
            {
              title: 'ParaSpace Fees',
              value: paraSpaceFeePoint
                ? formatToPercentage(shiftedLeftBy(paraSpaceFeePoint.toString(), 4))
                : '-'
            },
            {
              title: 'Creator Royalties',
              value: creatorFeePoint
                ? formatToPercentage(shiftedLeftBy(creatorFeePoint.toString(), 4))
                : '-'
            }
          ]}
        />
        <Button
          block
          onClick={handleListingEdit}
          size="large"
          disabled={!selectedTokenValue.amount || !!errMessage || !collectionFees}
        >
          {selectedTokenValue.amount === null ? 'Make a Change' : 'Update Listing'}
        </Button>
      </Stack>
    );
  }
);
