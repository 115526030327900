import { useCallback, useMemo, useState } from 'react';
import { Stack, Stepper } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import { StakingType } from 'paraspace-utilities-contract-helpers';
import numbro from 'numbro';

import { ApeCoinSourceToJoinApeListing } from '../../../../types';
import { CreateListingSubmitter, SignListingSubmitter } from '../../../../submitters';

import {
  ApeStakingMainAssetSymbol,
  ApeStakingTokenSymbol,
  ERC20Symbol,
  ERC721Symbol,
  WalletType
} from '@/apps/paraspace/typings';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/OfficialPairing/ApeStakingManagerProvider/types';
import { CreateListingReturnType } from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import {
  ApproveERC721FromEOASubmitter,
  DepositERC20Submitter,
  SupplyERC721FromEOASubmitter
} from '@/apps/paraspace/components';
import { useContractsMap, useERC20, useERC721 } from '@/apps/paraspace/hooks';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';
import { useAutoCompoundApe } from '@/apps/paraspace/hooks/useAutoCompoundApe';
import { formatBalance } from '@/apps/paraspace/utils/format';

type Props = {
  offerSymbol: ApeStakingMainAssetSymbol;
  seekSymbol: ApeStakingTokenSymbol;
  walletType: WalletType;
  stakingType: StakingType;
  tokens?: { tokenId: number; supplied: boolean }[];
  numberOfListings?: number;
  apeCoinSource?: ApeCoinSourceToJoinApeListing;
  wantedPercentage: number;
  duration: number;
  onSuccess: () => void;
  onError: () => void;
};

export const CreateListingStepper = ({
  offerSymbol,
  tokens = [],
  seekSymbol,
  walletType,
  stakingType,
  numberOfListings,
  apeCoinSource,
  wantedPercentage,
  duration,
  onSuccess,
  onError
}: Props) => {
  const [step, setStep] = useState(0);
  const [listingsInfo, setListingsInfo] = useState<CreateListingReturnType[] | null>(null);

  const contracts = useContractsMap();

  const onStepFinish = useCallback(() => {
    setStep(prevStep => prevStep + 1);
  }, []);

  const handleSignFinish = useCallback(
    (listingsData: CreateListingReturnType) => {
      setListingsInfo(data => (data ? [...data, listingsData] : [listingsData]));
      onStepFinish();
    },
    [onStepFinish]
  );

  const amount = useMemo(
    () => new BigNumber((numberOfListings ?? 0) * STAKE_LIMIT[seekSymbol as ApeStakingTokenSymbol]),
    [numberOfListings, seekSymbol]
  );

  const tokenIds = useMemo(
    () =>
      offerSymbol === ERC20Symbol.APE
        ? new Array(numberOfListings).fill(0)
        : tokens.map(it => it.tokenId),
    [tokens, numberOfListings, offerSymbol]
  );

  const SignListingSteps = useMemo(
    () =>
      tokenIds.map((tokenId, index) => ({
        description: `Sign to create ${numbro(index + 1).format({ output: 'ordinal' })} listing`,
        content: (
          <SignListingSubmitter
            key={index}
            formData={{
              offerSymbol,
              stakingType,
              tokenId,
              wantedPercentage: new BigNumber(wantedPercentage),
              duration
            }}
            onFinish={handleSignFinish}
            onError={onError}
          />
        ),
        skip: false
      })),
    [duration, handleSignFinish, offerSymbol, onError, stakingType, tokenIds, wantedPercentage]
  );

  const { createApproval: genApprovalForAPE } = useERC20(contracts.APE);
  const { createApproval: genApprovalForCAPE } = useERC20(contracts.cAPE);
  const { genApprovalForAll: genApprovalForApe, genSupplyERC721Txs: genSupplyERC721TxsForApe } =
    useERC721(offerSymbol !== ERC20Symbol.APE ? offerSymbol : ERC721Symbol.BAYC);

  const { genStakingAutoCompoundApe } = useAutoCompoundApe();
  const createApeListingSteps = useMemo(() => {
    const inBalanceTokenIds = tokens.filter(it => !it.supplied).map(it => it.tokenId);
    const supplyStepRequired = inBalanceTokenIds.length > 0;

    const batchTxs = [
      {
        tx: genApprovalForApe({ ids: inBalanceTokenIds }),
        skip: !supplyStepRequired || walletType === 'EOA'
      },
      {
        tx: genSupplyERC721TxsForApe({ tokenIds: inBalanceTokenIds }),
        skip: !supplyStepRequired || walletType === 'EOA'
      }
    ];

    return [
      {
        description: `Approve ${offerSymbol}`,
        content: (
          <ApproveERC721FromEOASubmitter
            formData={{
              name: offerSymbol,
              assetAddress: contracts[offerSymbol]
            }}
            onError={onError}
            onFinish={onStepFinish}
          />
        ),
        skip: !supplyStepRequired || walletType !== 'EOA'
      },
      {
        description: `Supply ${offerSymbol}`,
        content: (
          <SupplyERC721FromEOASubmitter
            formData={{
              tokenIds: inBalanceTokenIds,
              symbol: offerSymbol as ERC721Symbol
            }}
            onError={onError}
            onFinish={onStepFinish}
          />
        ),
        skip: !supplyStepRequired || walletType !== 'EOA'
      },
      {
        description: `Approve and supply ${offerSymbol}`,
        content: (
          <BatchTransactionsSubmitter
            batchTxs={batchTxs}
            inProgressMessage="Approving and Supplying NFTs"
            onError={onError}
            onFinish={onStepFinish}
          />
        ),
        skip: batchTxs.every(({ skip }) => skip)
      },
      ...SignListingSteps,
      {
        description: 'Creating Listings',
        content: (
          <CreateListingSubmitter formData={listingsInfo!} onFinish={onSuccess} onError={onError} />
        ),
        skip: false
      }
    ].filter(it => it !== null);
  }, [
    tokens,
    offerSymbol,
    contracts,
    onError,
    onStepFinish,
    SignListingSteps,
    listingsInfo,
    onSuccess,
    walletType,
    genApprovalForApe,
    genSupplyERC721TxsForApe
  ]);

  const createApeCoinListingSteps = useMemo(() => {
    const batchTxs = [
      {
        tx: genApprovalForAPE({ spender: contracts.cAPE, amount }),
        skip: apeCoinSource === ApeCoinSourceToJoinApeListing.CAPE_BALANCE
      },
      {
        tx: genStakingAutoCompoundApe({ amount: amount.toString(10) }),
        skip: apeCoinSource === ApeCoinSourceToJoinApeListing.CAPE_BALANCE
      },
      {
        tx: genApprovalForCAPE({ spender: contracts.P2PPairStaking, amount })
      }
    ];

    return [
      {
        description: `Deposit ${formatBalance(amount)} APE to your Parallel account`,
        content: (
          <DepositERC20Submitter
            formData={{
              address: contracts.APE,
              symbol: ERC20Symbol.APE,
              value: amount
            }}
            onFinish={onStepFinish}
            onError={onError}
          />
        ),
        skip: walletType !== 'EOA' || apeCoinSource === ApeCoinSourceToJoinApeListing.CAPE_BALANCE
      },
      {
        description: `Deposit ${formatBalance(amount)} CAPE to your Parallel account`,
        content: (
          <DepositERC20Submitter
            formData={{
              address: contracts.cAPE,
              symbol: ERC20Symbol.CAPE,
              value: amount
            }}
            onFinish={onStepFinish}
            onError={onError}
          />
        ),
        skip: walletType !== 'EOA' || apeCoinSource === ApeCoinSourceToJoinApeListing.APE_BALANCE
      },
      {
        description: 'Approve and stake APE',
        content: (
          <BatchTransactionsSubmitter
            batchTxs={batchTxs}
            inProgressMessage="Approving and Staking APE"
            onFinish={onStepFinish}
            onError={onError}
          />
        ),
        skip: batchTxs.every(({ skip }) => skip)
      },
      ...SignListingSteps,
      {
        description: 'Creating Listings',
        content: (
          <CreateListingSubmitter formData={listingsInfo!} onFinish={onSuccess} onError={onError} />
        ),
        skip: false
      }
    ];
  }, [
    apeCoinSource,
    contracts.APE,
    contracts.cAPE,
    contracts.P2PPairStaking,
    onStepFinish,
    onError,
    amount,
    SignListingSteps,
    listingsInfo,
    onSuccess,
    walletType,
    genApprovalForAPE,
    genApprovalForCAPE,
    genStakingAutoCompoundApe
  ]);

  const steps = (
    offerSymbol === ERC20Symbol.APE ? createApeCoinListingSteps : createApeListingSteps
  )
    .filter(({ skip }) => !skip)
    .map((it, index, arr) => ({
      title: index === arr.length - 1 ? 'Finally' : `Step ${index + 1}`,
      description: it!.description,
      content: it!.content
    }));

  return (
    <Stack>
      <Stepper steps={steps} step={step} />
      {steps[step]?.content}
    </Stack>
  );
};
