import { isNil } from 'lodash';
import { ComponentProps, memo, ReactNode, useCallback, useMemo } from 'react';
import { Area, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { useBreakpoints } from '@parallel-mono/components';
import styled, { useTheme } from 'styled-components';

import { renderTooltipContent } from './renderTooltipContent';
import { createData, legendFormatter } from './utils';

import { Maybe } from '@/apps/paraspace/typings/basic';

type EstimatedProfitsChartProps = Omit<ComponentProps<typeof ComposedChart>, 'data'> & {
  paraSpaceEstimatedProfitsByDay: Maybe<(day: number) => number>;
  horizenLabsEstimatedProfitsByDay: Maybe<(day: number) => number>;
  anyAPREstimatedProfitsByDay: Maybe<(day: number) => number>;
  loading?: boolean;
  loadingMessage?: ReactNode;
};

const StyledChart = styled(ComposedChart)`
  .recharts-legend-wrapper .recharts-legend-item {
    margin-right: 1.75rem !important;
  }
`;

export const EstimatedProfitsChart = memo(
  ({
    paraSpaceEstimatedProfitsByDay,
    horizenLabsEstimatedProfitsByDay,
    anyAPREstimatedProfitsByDay,
    loading = false,
    loadingMessage = 'loading...',
    ...others
  }: EstimatedProfitsChartProps) => {
    const data = useMemo(
      () =>
        createData({
          paraSpaceEstimatedProfitsByDay,
          horizenLabsEstimatedProfitsByDay,
          anyAPREstimatedProfitsByDay
        }),
      [
        paraSpaceEstimatedProfitsByDay,
        horizenLabsEstimatedProfitsByDay,
        anyAPREstimatedProfitsByDay
      ]
    );

    const { mobile } = useBreakpoints();

    const tickFormatter = useCallback(
      (_, index) => {
        if (index === 0) return 'Today';
        if (index === data.length - 1) return '1 Year';
        return '';
      },
      [data.length]
    );

    const noValidData =
      isNil(paraSpaceEstimatedProfitsByDay) &&
      isNil(horizenLabsEstimatedProfitsByDay) &&
      isNil(anyAPREstimatedProfitsByDay);

    const textColor = useTheme().skin.text.main;
    return (
      <StyledChart margin={{ top: 10, left: 5, right: 20 }} data={data} {...others}>
        <defs>
          <linearGradient id="gradientGreen" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#11BC91" stopOpacity={1} />
            <stop offset="100%" stopColor="#11BC91" stopOpacity={0} />
          </linearGradient>
        </defs>
        <text fill={textColor} dominantBaseline="hanging" fontSize="1.125rem" fontWeight="bold">
          Estimated Profits over time
        </text>
        {!mobile && (
          <Legend
            iconSize={8}
            formatter={legendFormatter}
            iconType="circle"
            align="right"
            verticalAlign="top"
            height={36}
          />
        )}
        <XAxis
          dataKey="week"
          interval={0}
          tickLine={false}
          tickMargin={10}
          minTickGap={0}
          tickFormatter={tickFormatter}
          tick={{ fontSize: 14 }}
        />
        {!mobile && <YAxis tickLine={false} tickMargin={10} tick={{ fontSize: 14 }} />}
        <Tooltip
          content={renderTooltipContent as ComponentProps<typeof Tooltip>['content']}
          wrapperStyle={{ outline: 'none' }}
        />
        {!isNil(paraSpaceEstimatedProfitsByDay) && !loading && (
          <Area
            type="monotone"
            dataKey="paraSpace"
            stroke="#11BC91"
            fillOpacity={1}
            fill="url(#gradientGreen)"
          />
        )}
        {!isNil(anyAPREstimatedProfitsByDay) && !loading && (
          <Line type="monotone" dataKey="anyAPR" dot={false} stroke="#2F2F2F" />
        )}
        {!isNil(horizenLabsEstimatedProfitsByDay) && !loading && (
          <Line type="monotone" dataKey="horizenLabs" dot={false} stroke="#9BA4AE" />
        )}
        {noValidData && !loading && (
          <text
            fill={textColor}
            fontSize="1rem"
            y="50%"
            x="50%"
            dominantBaseline="middle"
            textAnchor="middle"
          >
            Enter a stake amount above to see projections
          </text>
        )}
        {loading && (
          <text
            fill={textColor}
            fontSize="1rem"
            y="50%"
            x="50%"
            dominantBaseline="middle"
            textAnchor="middle"
          >
            {loadingMessage}
          </text>
        )}
      </StyledChart>
    );
  }
);
