import { memo, useCallback } from 'react';
import BigNumber from 'bignumber.js';

import { FormSubmitter } from '../../FormSubmitter';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useAppConfig, useTransfer } from '@/apps/paraspace/hooks';

type ERC20TokenAsset = {
  address: string;
  value: BigNumber;
  symbol: ERC20Symbol;
};

export const DepositERC20Submitter = memo(
  ({
    formData,
    onError,
    onFinish
  }: {
    formData: ERC20TokenAsset;
    onError: (err: any) => void;
    onFinish: () => void;
  }) => {
    const { authentication, account } = useWeb3Context();
    const { erc20Config } = useAppConfig();
    const { genTxTransferETH, genTxDataTransferERC20 } = useTransfer();

    const submit = useCallback(() => {
      // ETH
      if (!formData.address) {
        return authentication.meta.provider
          .getSigner()
          .sendTransaction(
            genTxTransferETH(
              account,
              formData.value.shiftedBy(erc20Config[formData.symbol].decimals).toString(10)
            )
          );
      }

      return authentication.meta.provider
        .getSigner()
        .sendTransaction(
          genTxDataTransferERC20(
            account,
            formData.address,
            formData.value.shiftedBy(erc20Config[formData.symbol].decimals).toString(10)
          )
        );
    }, [authentication, genTxTransferETH, genTxDataTransferERC20, formData, erc20Config, account]);

    return (
      <FormSubmitter
        submit={submit}
        onError={onError}
        onFinish={onFinish}
        inProgressMessage="Deposit ERC20"
      />
    );
  }
);
