import { ERC721Symbol } from '@/apps/paraspace/typings';

export const COLLECTION_EXPECTED_ORDER = [
  ERC721Symbol.BAYC,
  ERC721Symbol.PPG,
  ERC721Symbol.PUNK,
  ERC721Symbol.WPUNKS,
  ERC721Symbol.MAYC,
  ERC721Symbol.OTHERDEED,
  ERC721Symbol.AZUKI,
  ERC721Symbol.BAKC,
  ERC721Symbol.SF_VLDR,
  ERC721Symbol.MOONBIRD,
  ERC721Symbol.CLONEX,
  ERC721Symbol.DOODLE,
  ERC721Symbol.MEEBITS,
  ERC721Symbol.SEWER,
  ERC721Symbol.BEANZ,
  ERC721Symbol.BLOCKS,
  ERC721Symbol.EXP,
  ERC721Symbol.VSL,
  ERC721Symbol.KODA,
  ERC721Symbol.HV_MTL,
  ERC721Symbol.ELEM
];
