import { isNil, random } from 'lodash';
import { StaticJsonRpcProvider } from '@ethersproject/providers';
import { useMemo } from 'react';

import { BridgeNetworks, SupportedChainId } from '../configs';

import { Maybe } from '@/apps/parax/typings/basic';

export const useNetworkProvider = (chainId?: SupportedChainId): Maybe<StaticJsonRpcProvider> => {
  return useMemo(() => {
    if (isNil(chainId)) {
      return null;
    }
    const networkConfig = BridgeNetworks[chainId];
    if (!networkConfig) {
      return null;
    }
    const urls = networkConfig.publicJsonRPCUrl || [];
    return new StaticJsonRpcProvider({ url: urls[random(urls.length - 1)], skipFetchSetup: true });
  }, [chainId]);
};
