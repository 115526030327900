import { Modal, ModalProps } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import { memo, useCallback, useMemo } from 'react';

import { parallelChain } from '../../configs';

import { BridgeSubmitterStepper } from './BridgeSubmitterStepper';

import {
  CollectAndSubmitProcedurePhase,
  ErrorState,
  SuccessState,
  useCollectAndSubmitProcedure
} from '@/apps/parax/components';

export type BridgeSubmitterModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  formData: {
    symbol: string;
    amount: BigNumber;
    receiver: string;
    l1GasFeeInWei?: BigNumber;
    l2GasFeeInWei?: BigNumber;
    toChainEid: number;
  };
  onFinish?: () => void;
  onError?: () => void;
  onClose: () => void;
};

export const BridgeTokenModal = memo(
  ({
    onFinish,
    onError,
    formData: { symbol, amount, receiver, l1GasFeeInWei, l2GasFeeInWei, toChainEid },
    isOpen,
    onClose,
    ...others
  }: BridgeSubmitterModalProps) => {
    const { phase, handleSubmitSuccess, handleSubmitFailed } = useCollectAndSubmitProcedure<
      void,
      void,
      void
    >({
      defaultPhase: CollectAndSubmitProcedurePhase.Submitting,
      onFinish,
      onError,
      running: isOpen
    });

    const handleError = useCallback(() => {
      handleSubmitFailed(null);
    }, [handleSubmitFailed]);

    const isDeposit = useMemo(() => toChainEid === parallelChain.eId, [toChainEid]);
    return (
      <Modal isOpen={isOpen} onClose={onClose} {...others}>
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <BridgeSubmitterStepper
            formData={{
              symbol,
              amount,
              receiver,
              toChainEid,
              l1GasFeeInWei,
              l2GasFeeInWei
            }}
            onFinish={handleSubmitSuccess}
            onError={handleError}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            desc={isDeposit ? 'Bridge Success' : 'Withdraw Success'}
            onAction={onClose}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && (
          <ErrorState
            errorMsg={isDeposit ? 'Bridge Failed' : 'Withdraw Failed'}
            closeModal={onClose}
          />
        )}
      </Modal>
    );
  }
);
