import { Button, Inline, Stack, StackProps, useBreakpoints } from '@parallel-mono/components';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { groupBy } from 'lodash';

import { useBuyCartProvider } from './context';
import { BuyCartAsset } from './context/types';
import { FilterPanelToggle } from './components/FilterPanel/FilterPanelToggle';
import { Filter } from './types';
import {
  CollectionOverview,
  DESKTOP_FILTER_PANEL_WIDTH,
  FilterPanel,
  ItemsExplore,
  LayoutType,
  LayoutTypeSelection,
  MakeCollectionOfferModal,
  BuyCart
} from './components';
import { useItemsQueryFilter, useShopItems } from './hooks';

import { useCollectionByContract } from '@/apps/paraspace/pages/Shop/contexts';
import {
  Marketplace,
  ShopItemSort,
  useDeleteListingMutation,
  useFulfillListingsMutation
} from '@/apps/paraspace/generated/graphql';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useScrollContainer, useWeb3Context } from '@/apps/paraspace/contexts';
import { useUnMount } from '@/apps/paraspace/hooks/useUnmount';
import { GoBack, Select, SelectBaseOption } from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';

type CollectionItemsShopProps = {
  contractAddress: string;
  supportLayoutTypes: LayoutType[];
} & Omit<StackProps, 'children'>;

const MakeOfferButton = styled(Button)`
  height: 2.5rem;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
  `};
`;

const StyledItemsExplore = styled(ItemsExplore)`
  flex: 1;
  height: 100rem;
  border: 1px solid #ccc;
`;

const StyledFilterPanelToggle = styled(FilterPanelToggle)<{ opened: boolean }>`
  ${({ theme, opened }) => theme.breakpoints.up('tablet')`
    margin-left: 1rem;
    min-width: ${opened ? DESKTOP_FILTER_PANEL_WIDTH : '0rem'};
    transition: all 0.2s;
  `};
`;

const Container = styled(Stack)`
  position: relative;
`;

const useMakeOfferButton = () => {
  const [openMakeOfferModal, setOpenMakeOfferModal] = useState(false);
  const handleMakeOfferClick = useCallback(() => {
    setOpenMakeOfferModal(true);
  }, []);

  const makeOfferButton = useMemo(
    () => (
      <MakeOfferButton skin="secondary" onClick={handleMakeOfferClick}>
        Make Collection Offer
      </MakeOfferButton>
    ),
    [handleMakeOfferClick]
  );

  return {
    openMakeOfferModal,
    setOpenMakeOfferModal,
    makeOfferButton
  };
};

const sortOptions = [
  {
    value: ShopItemSort.PriceAsc,
    label: 'Price Low to High'
  },
  { value: ShopItemSort.PriceDesc, label: 'Price High to Low' }
];

export const CollectionItemsShop = memo((props: CollectionItemsShopProps) => {
  const { load: updateUserData } = useMMProvider();
  const { contractAddress, supportLayoutTypes, ...others } = props;
  const { sort, handleSortChange, filter, handleFilterChange, queryParams } =
    useItemsQueryFilter(contractAddress);
  const [layoutType, setLayoutType] = useState(supportLayoutTypes[0]);
  const { clearBuyCartList } = useBuyCartProvider();
  const { openMakeOfferModal, setOpenMakeOfferModal, makeOfferButton } = useMakeOfferButton();
  const { mobile } = useBreakpoints();
  const [filterPanelOpen, setFilterPanelOpen] = useState(!mobile);
  const collectionDetail = useCollectionByContract(contractAddress);

  const scrollContainer = useScrollContainer();

  const handleFilterValueChange = useCallback(
    async (latestFilter: Filter) => {
      handleFilterChange(latestFilter);
      scrollContainer!.scrollTop = 0;
      if (latestFilter.marketplace !== filter.marketplace) {
        clearBuyCartList();
      }
    },
    [clearBuyCartList, filter.marketplace, handleFilterChange, scrollContainer]
  );

  const handleToggleFilterPanel = useCallback(
    open => {
      setFilterPanelOpen(open);
    },
    [setFilterPanelOpen]
  );

  const { account } = useWeb3Context();
  const [deleteOpenseaListing] = useDeleteListingMutation();
  const [fulfillParaspaceListing] = useFulfillListingsMutation();
  const { shopItems, client, refreshShopItems, hasMorePage, loadMoreItems } =
    useShopItems(queryParams);

  useUnMount(() => {
    client.resetStore();
  });

  const refreshData = useCallback(async () => {
    await refreshShopItems();
  }, [refreshShopItems]);

  const handleBuyWithCredit = useCallback(
    async (assetList: BuyCartAsset[]) => {
      if (updateUserData) updateUserData();
      const assetsGroup = groupBy(assetList, 'platform');
      await Promise.all(
        Object.keys(assetsGroup).map(platform => {
          if (platform === Marketplace.ParaSpace) {
            return fulfillParaspaceListing({
              variables: {
                assets: assetsGroup[platform].map(asset => ({
                  contractAddress: asset.contractAddress,
                  identifierOrCriteria: asset.tokenId.toString(),
                  newOwner: account
                }))
              }
            });
          }
          if (platform === Marketplace.OpenSea) {
            // TODO: can it be removed?
            return deleteOpenseaListing({
              variables: {
                assets: assetsGroup[platform].map(asset => ({
                  contractAddress: asset.contractAddress,
                  identifierOrCriteria: asset.tokenId.toString()
                }))
              }
            });
          }
          return Promise.resolve();
        })
      );

      refreshData();
    },
    [account, deleteOpenseaListing, fulfillParaspaceListing, refreshData, updateUserData]
  );

  const handleEnterDetailPage = useCallback(() => {
    const scrollTop = scrollContainer?.scrollTop;
    sessionStorage.setItem('filters', JSON.stringify({ filter, sort, position: scrollTop }));
  }, [filter, sort, scrollContainer]);

  useEffect(() => {
    const filters = JSON.parse(sessionStorage.getItem('filters') || '{}');
    const { filter: persistFilter, sort: persistSort = ShopItemSort.PriceAsc, position } = filters;
    sessionStorage.removeItem('filters');
    handleSortChange(persistSort!);
    handleFilterChange(currentFilter => ({
      ...currentFilter,
      ...persistFilter
    }));
    setTimeout(() => {
      // Apollo cache return DEFAULT_PAGE_LIMIT items first, and then it return all cached data
      // So there need to set a microtask to wait the latest scrollHeight of the container had been set
      scrollContainer!.scrollTop = position;
    }, 0);
  }, [handleFilterChange, handleSortChange, scrollContainer]);

  const showLayoutSection = useMemo(
    () => supportLayoutTypes.length > 1,
    [supportLayoutTypes.length]
  );

  return (
    <Container width="100%" {...others}>
      <GoBack />
      {collectionDetail && <CollectionOverview collection={collectionDetail} />}
      <Inline width="100%" gap={mobile ? '0.5rem' : '1rem'}>
        <StyledFilterPanelToggle opened={filterPanelOpen} onToggle={handleToggleFilterPanel} />
        <Inline
          width="100%"
          gap="0"
          justifyContent={mobile && !showLayoutSection ? 'flex-end' : 'space-between'}
        >
          <Select
            options={sortOptions}
            value={sort}
            onChange={(option: Maybe<SelectBaseOption>) => {
              handleSortChange(option?.value);
            }}
          />

          <Inline gap="4.5rem">
            {showLayoutSection && (
              <LayoutTypeSelection
                gap={mobile ? '0.5rem' : '1rem'}
                layoutType={layoutType}
                onLayoutTypeChange={value => setLayoutType(value)}
              />
            )}

            {!mobile && makeOfferButton}
          </Inline>
        </Inline>
      </Inline>
      {mobile && makeOfferButton}
      <Inline alignItems="flex-start" inset={mobile ? '0 0 8rem 0' : '0'}>
        <FilterPanel
          opened={filterPanelOpen}
          onCloseFilterPanel={() => setFilterPanelOpen(false)}
          defaultFilter={filter}
          onFilterChange={handleFilterValueChange}
        />
        <StyledItemsExplore
          layoutType={layoutType}
          filter={filter}
          assets={shopItems}
          hasNextPage={hasMorePage}
          load={loadMoreItems}
          handleEnterDetailPage={handleEnterDetailPage}
        />
        <BuyCart handleBuy={handleBuyWithCredit} />
      </Inline>
      <MakeCollectionOfferModal
        data={{ collection: collectionDetail! }}
        closeModal={() => {
          setOpenMakeOfferModal(false);
        }}
        isOpen={openMakeOfferModal}
        updateCollection={() => refreshData()}
      />
    </Container>
  );
});
