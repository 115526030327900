import { useCallback, FC, useMemo, createContext, useState, useContext } from 'react';

import { ListingToken } from '../../types';
import { useListTokens } from '../ListTokensContext';

import { ListingModal } from './ListingModal';

type ContextValue = { listTokens: (listingTokens: ListingToken[]) => Promise<void> };

const ListTokensContext = createContext<ContextValue>({
  listTokens: _ => Promise.reject(new Error('No implement yet.'))
} as ContextValue);

type ListTokensModalProps = {
  isOpen: boolean;
  listingTokens: ListingToken[];
  onFinish?: () => void;
  onError?: () => void;
  onClose?: () => void;
};

export const ListTokensManagerProvider: FC = ({ children }) => {
  const [modalProps, setModalProps] = useState<ListTokensModalProps>({
    isOpen: false,
    listingTokens: []
  });

  const { clearSelectedTokens } = useListTokens();

  const handleCloseModal = useCallback(() => {
    setModalProps(prev => ({ ...prev, isOpen: false, listingTokens: [] }));
  }, []);

  const listTokens = useCallback(
    (listingTokens: ListingToken[]) => {
      return new Promise<void>((resolve, reject) => {
        setModalProps(curr => ({
          ...curr,
          isOpen: true,
          listingTokens,
          onClose: handleCloseModal,
          onFinish: () => {
            resolve();
            clearSelectedTokens();
          },
          onError: reject
        }));
      });
    },
    [clearSelectedTokens, handleCloseModal]
  );

  const value = useMemo(
    () => ({
      listTokens
    }),
    [listTokens]
  );

  return (
    <ListTokensContext.Provider value={value}>
      <ListingModal {...modalProps} />
      {children}
    </ListTokensContext.Provider>
  );
};

export const useListTokensManager = () => useContext(ListTokensContext);
