import { memo, useCallback } from 'react';
import { Inline, Icon, Image, SmallText } from '@parallel-mono/components';

import etherscan from '../../../../Images/etherscan.svg';
import { useErc721ExplorerLinkMap } from '../hook';

import { Link, NonBorderSelect } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';

export const ExplorerLink = memo(({ symbol }: { symbol: ERC721Symbol }) => {
  const erc721ExplorerLinkMap = useErc721ExplorerLinkMap();

  const explorerLinRenderItem = useCallback(
    ({ label, value }) => (
      <Link href={value} target="_blank">
        <Inline justifyContent="space-between" alignItems="center" inset="1rem 1.5rem">
          {label}
          <Icon name="externalLink" size="1rem" />
        </Inline>
      </Link>
    ),
    []
  );

  const explorerLinkRenderDisplay = useCallback(() => {
    return (
      <Inline alignItems="center" gap="0.25rem">
        <Image width="1rem" height="1rem" src={etherscan} />
        <SmallText skin="secondary">Etherscan</SmallText>
      </Inline>
    );
  }, []);

  return (
    <NonBorderSelect
      options={erc721ExplorerLinkMap[symbol]}
      renderItem={explorerLinRenderItem}
      placeholder=""
      renderDisplay={explorerLinkRenderDisplay}
    />
  );
});
