import { UiPoolDataProvider, WalletBalanceProvider } from 'paraspace-utilities-contract-helpers';
import { assign, findKey, omit, zipObject } from 'lodash';
import BigNumber from 'bignumber.js';
import { ERC721Symbol } from 'paraspace-configs-v2';
import { JsonRpcProvider } from '@ethersproject/providers';

import { getNTokensDataMap, StakeFishInfo } from '../utils';

import { ERC20Config, ERC721Config } from '@/apps/paraspace/config';
import { ERC20Symbol } from '@/apps/paraspace/typings';

type ERC721BalanceMap = Record<
  ERC721Symbol,
  {
    balance: number[];
    tokenTraitMultipliers: Record<string, BigNumber>;
    stakeFishInfoMap: Record<string, StakeFishInfo>;
  }
>;

type ERC20BalanceMap = Record<ERC20Symbol, BigNumber>;

const getUserERC20Balance = async ({
  provider,
  erc20Config,
  account
}: {
  provider: WalletBalanceProvider;
  erc20Config: Partial<ERC20Config>;
  account: string;
}) => {
  const tokens = Object.values(erc20Config);
  const tokensWithoutNativeToken = tokens.filter(it => !it.isNativeToken);
  const balances = await provider.batchBalanceOf(
    [account],
    tokensWithoutNativeToken.map(token => token.address)
  );
  return zipObject(
    tokensWithoutNativeToken.map(token => token.symbol),
    balances.map((balance, index) =>
      new BigNumber(balance.toString()).shiftedBy(-tokensWithoutNativeToken[index].decimals)
    )
  ) as ERC20BalanceMap;
};

export const getUserERC721BalanceMap = async ({
  provider,
  erc721Config,
  account,
  lendingPoolAddressProvider
}: {
  provider: UiPoolDataProvider;
  erc721Config: ERC721Config;
  account: string;
  lendingPoolAddressProvider: string;
}) => {
  const tokens = Object.values(erc721Config);
  const balances = await Promise.all(
    tokens.map(token =>
      provider.getERC721Balance({
        user: account,
        nft: token.address,
        nftType: token.nftEnumerableType,
        viewerStep: token.selfIncrementTokenId ? 3000 : 10000,
        useTotalSupplyAsMaxId: token.selfIncrementTokenId
      })
    )
  );

  const nTokenInfos = tokens
    .map((it, index) => ({
      underlyingAsset: it.address,
      xTokenAddress: it.xTokenAddress,
      symbol: it.symbol,
      nftSuppliedList: balances[index]
    }))
    .filter(it => it.nftSuppliedList.length > 0);

  const nTokensData = await getNTokensDataMap({
    provider,
    lendingPoolAddressProvider,
    tokens: nTokenInfos
  });

  return zipObject(
    tokens.map(token => token.symbol),
    tokens.map(({ symbol }, index) => ({
      balance: balances[index],
      tokenTraitMultipliers: nTokensData[symbol]?.tokenTraitMultipliers ?? {},
      stakeFishInfoMap: nTokensData[symbol]?.stakeFishInfoMap
    }))
  ) as ERC721BalanceMap;
};

export const getUserERC20BalanceMap = async ({
  provider,
  account,
  erc20Config,
  walletBalanceProvider
}: {
  walletBalanceProvider: WalletBalanceProvider;
  provider: JsonRpcProvider;
  account: string;
  erc20Config: ERC20Config;
}) => {
  const nativeTokenSymbol = findKey(erc20Config, token => token.isNativeToken) as ERC20Symbol;

  const erc20ConfigWithoutNativeToken = omit(erc20Config, nativeTokenSymbol);
  const [erc20BalanceMap, nativeTokenBalance] = await Promise.all([
    getUserERC20Balance({
      provider: walletBalanceProvider,
      erc20Config: erc20ConfigWithoutNativeToken,
      account
    }),
    provider
      .getBalance(account)
      .then(res =>
        new BigNumber(res.toString()).shiftedBy(-erc20Config[nativeTokenSymbol].decimals)
      )
  ]);

  return assign(erc20BalanceMap, { [nativeTokenSymbol]: nativeTokenBalance });
};
