import BigNumberJs from 'bignumber.js';
import { useMemo } from 'react';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';

export const useCustomizedApeCoinRewardsInfo = (stakedAmount: number, inputRawApr: number) => {
  const { erc20InfoMap, basicInfoLoaded } = useMMProvider();
  return useMemo(() => {
    if (!basicInfoLoaded) {
      return {
        customizedApr: null,
        customizedYearlyRewardsInApe: null,
        customizedYearlyRewardsInUsd: null
      };
    }
    const aprInBigJs = new BigNumberJs(inputRawApr);
    const { priceInUsd } = erc20InfoMap[ERC20Symbol.APE];
    return {
      customizedApr: aprInBigJs,
      customizedYearlyRewardsInApe: aprInBigJs.times(stakedAmount),
      customizedYearlyRewardsInUsd: aprInBigJs.times(stakedAmount).times(priceInUsd)
    };
  }, [basicInfoLoaded, inputRawApr, erc20InfoMap, stakedAmount]);
};
