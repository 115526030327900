import { memo, useCallback, useMemo, useState } from 'react';
import { formatNumber } from '@parallel-mono/utils';
import { Modal, ModalProps } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';

import { WithdrawERC20Form, WithdrawERC20Steps } from './components';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import {
  CollectAndSubmitProcedurePhase,
  ErrorState,
  SuccessState,
  TimelockResultDesc,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { useIsNativeTokenCheck } from '@/apps/paraspace/pages/hooks/useIsNativeTokenCheck';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type WithdrawERC20ModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  symbol: ERC20Symbol;
  onClose: () => void;
  onFinish: () => void;
  onError: () => void;
};

type FormData = {
  amount: BigNumber;
  symbol: ERC20Symbol;
};

export const WithdrawERC20Modal = memo(
  ({ symbol, onFinish, onError, isOpen, onClose, ...others }: WithdrawERC20ModalProps) => {
    const [finalSymbol, setFinalSymbol] = useState(symbol);
    const {
      phase,
      handleFormSubmit,
      handleSubmitFailed,
      handleSubmitSuccess,
      submittedFormData,
      submittingResult: claimed,
      submittingError
    } = useCollectAndSubmitProcedure<FormData, boolean, Maybe<ErrorConfig>>({
      onError,
      onFinish,
      running: isOpen
    });

    const { checkIsNativeTokenSymbol } = useIsNativeTokenCheck();
    const { erc20Config } = useAppConfig();

    const tokenDisplayName = useMemo(() => {
      const { displayName, contractName } = erc20Config[finalSymbol] ?? {};
      return displayName || contractName || finalSymbol;
    }, [erc20Config, finalSymbol]);

    const tokenName = useMemo(
      () => (checkIsNativeTokenSymbol(finalSymbol) ? finalSymbol : tokenDisplayName),
      [checkIsNativeTokenSymbol, finalSymbol, tokenDisplayName]
    );

    const handleSymbolChange = useCallback((curSymbol: ERC20Symbol) => {
      setFinalSymbol(curSymbol);
    }, []);

    return (
      <Modal
        size="500px"
        isOpen={isOpen}
        onClose={onClose}
        title={`Withdraw ${tokenName}`}
        {...others}
      >
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <WithdrawERC20Form
            onSubmit={handleFormSubmit}
            onChange={handleSymbolChange}
            data={{ symbol }}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <WithdrawERC20Steps
            data={submittedFormData!}
            onError={handleSubmitFailed}
            onFinish={handleSubmitSuccess}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            desc="Withdrawn Successfully"
            tip={
              <>
                You have withdrawn{' '}
                {formatNumber(submittedFormData?.amount || 0, {
                  decimal: MAXIMUM_BALANCE_DECIMALS
                })}{' '}
                {tokenName} {!claimed && <TimelockResultDesc />}
              </>
            }
            actionButtonText={claimed ? 'Done' : 'View Queue'}
            onAction={onClose}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && (
          <ErrorState closeModal={onClose} errorMsg={submittingError?.userFriendlyErrorMsg} />
        )}
      </Modal>
    );
  }
);
