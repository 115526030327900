import { Responsive, Stack } from '@parallel-mono/components';
import { memo, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { PlaceholderInline, ApeCoinBalancePanel } from '../../components';
import { useV1cAPEConvertProvider } from '../../../contexts/V1cAPEConvertManagerProvider';

import { ApeCoinPool, PoolTab, V1cAPEBalanceInfos } from './components';

const Container = styled(Responsive)`
  position: relative;
`;

const SideBar = styled(Stack)`
  flex: 0 0 18.75rem;
  height: fit-content;
  position: sticky;
  top: calc(var(--header-height-pc) + 1rem);
  ${({ theme }) => theme.breakpoints.down('desktop')`
    width: 100%;
    order: initial;
  `};
`;

const StyledPlaceholderInline = styled(PlaceholderInline)`
  flex: 0 1 8rem;
`;

export const AutoCompoundContainer = memo(() => {
  const { tab = 'supply' } = useParams<{ tab: string }>();
  const activeTab: PoolTab = tab.toLowerCase() === 'supply' ? 'supply' : 'withdraw';

  const navigateTo = useNavigate();
  const handleTabChange = useCallback(
    tabToBe => {
      navigateTo(`${tabToBe}`, { replace: true });
    },
    [navigateTo]
  );

  const { balanceInfo } = useV1cAPEConvertProvider();

  return (
    <Container width="100%" justifyContent="space-around">
      <StyledPlaceholderInline />
      <ApeCoinPool tab={activeTab} onTabChange={handleTabChange} />
      <SideBar>
        <ApeCoinBalancePanel />
        {balanceInfo && balanceInfo.balance.gt(0) && (
          <V1cAPEBalanceInfos balance={balanceInfo.balance} />
        )}
      </SideBar>
    </Container>
  );
});
