import { Stack } from '@parallel-mono/components';
import { useCallback } from 'react';

import { useUpdateOrder } from '../useUpdateOrder';
import { ListEditFormData } from '..';

import { FormSubmitter } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { CollectionFees } from '@/apps/paraspace/utils/calculateFees';

type UpdateListingStepperData = {
  tokenId: number;
  symbol: ERC721Symbol;
  contractAddress: string;
  fees?: CollectionFees;
} & ListEditFormData;
type UpdateListingStepperProps = {
  data: UpdateListingStepperData;
  onFinish: () => void;
  onError: () => void;
};

export const UpdateListingStepper = ({
  data: { symbol, tokenId, contractAddress, duration, tokenValue, fees },
  onFinish,
  onError
}: UpdateListingStepperProps) => {
  const updateOrder = useUpdateOrder({
    symbol,
    tokenId,
    collectionAddress: contractAddress,
    selectedTokenValue: tokenValue,
    fees,
    duration
  });
  const handleListingEdit = useCallback(() => updateOrder(), [updateOrder]);

  return (
    <Stack>
      <FormSubmitter onError={onError} onFinish={onFinish} submit={handleListingEdit} />
    </Stack>
  );
};
