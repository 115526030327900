import { memo, useCallback, useMemo } from 'react';
import { ceil } from 'lodash';
import { sumBy } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';

import { BuyCartAsset, BuyCartAssetForParaSpace } from '../pages/CollectionItemsShop/context/types';

import { FormSubmitter } from '@/apps/paraspace/components';
import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { ERC20Symbol, Platform } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type Payment = {
  currency: ERC20Symbol;
  amount: number;
};

type BatchBuyWithCreditSubmitterProps = {
  onError: (error: Maybe<ErrorConfig>) => void;
  onFinish: () => void;
  formData: {
    assets: BuyCartAsset[];
    payNow: Payment;
    payLater: Payment;
  };
};

export const BatchBuyWithCreditSubmitter = memo(
  ({
    onError,
    onFinish,
    formData: { payNow, payLater, assets }
  }: BatchBuyWithCreditSubmitterProps) => {
    const { erc20InfoMap } = useMMProvider();
    const { batchBuyWithCredit } = usePool();
    const { submitTransactions } = useWeb3Context();

    const { decimals } = erc20InfoMap[payLater.currency];
    const totalPrice = useMemo(() => sumBy(assets, it => it.price), [assets]);
    const payLaterAmounts = useMemo(
      () =>
        assets.map(item => {
          const res = new BigNumber(payLater.amount).times(item.price.div(totalPrice));
          return ceil(res.toNumber(), decimals).toString();
        }),
      [assets, payLater.amount, decimals, totalPrice]
    );

    // TODO: run a other progress for blur or opensea listing

    const submit = useCallback(async () => {
      return batchBuyWithCredit({
        buyNowAmount: String(payNow),
        payLaterAmounts,
        platforms: assets.map(item => item.platform.toUpperCase()) as Platform[],
        marketProtocolData: (assets as BuyCartAssetForParaSpace[]).map(item => item.protocolData),
        protocolContracts: (assets as BuyCartAssetForParaSpace[]).map(
          item => item.protocolContract
        ),
        protocolVersions: (assets as BuyCartAssetForParaSpace[]).map(item => item.protocolVersion)
      }).then(txs => submitTransactions(txs));
    }, [batchBuyWithCredit, payNow, payLaterAmounts, assets, submitTransactions]);
    return <FormSubmitter submit={submit} onError={onError} onFinish={onFinish} />;
  }
);
