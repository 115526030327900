import { useMemo } from 'react';

import { useGetInLiquidationAccountsQuery } from '@/apps/paraspace/generated/graphql';

const INTERVAL_TIME = 20000;

export type LiquidationAsset = {
  tokenId: number;
  contractAddress: string;
};

export const useInLiquidationAssets = () => {
  const { data } = useGetInLiquidationAccountsQuery({
    pollInterval: INTERVAL_TIME
  });
  const liquidationAssets: LiquidationAsset[] = useMemo(() => {
    if (!data?.inLiquidationAccounts) return [] as LiquidationAsset[];
    return data.inLiquidationAccounts.reduce((set: LiquidationAsset[], item) => {
      if (!item.assets) return set;
      const { assets } = item;
      const formattedAssets = assets.map(it => ({
        tokenId: Number(it.identifierOrCriteria),
        contractAddress: it.contractAddress
      }));

      return [...set, ...formattedAssets];
    }, []);
  }, [data]);

  return {
    liquidationAssets
  };
};
