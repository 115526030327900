import { Button, H3, Stack, StackProps } from '@parallel-mono/components';
import { FC, useCallback, useMemo } from 'react';
import { CryptoIcon } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol } from '@/apps/paraspace/typings';

type Props = {
  onClaim: (claimedRewards: BigNumber) => void;
  tokenId: number;
};

export const ETHValidatorClaimForm: FC<Props & Omit<StackProps, 'children'>> = ({
  onClaim,
  tokenId
}) => {
  const { nftInfoMap } = useMMProvider();

  const rewards = useMemo(
    () => nftInfoMap?.[ERC721Symbol.SF_VLDR]?.tokenSpecificInfos?.[tokenId]?.unclaimedPendingReward,
    [nftInfoMap, tokenId]
  );

  const handleClaim = useCallback(() => {
    onClaim(rewards);
  }, [onClaim, rewards]);

  return (
    <Stack alignItems="center">
      <CryptoIcon size="3.75rem" symbol="ETH" />
      <H3>{formatNumber(rewards)} ETH</H3>
      <Button onClick={handleClaim} size="large" block disabled={rewards?.lte(0)}>
        Claim
      </Button>
    </Stack>
  );
};
