import { H3, Icon, Inline, Modal, ModalProps } from '@parallel-mono/components';
import { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import BigNumberJs from 'bignumber.js';

import { BuyShopItem } from './BuyShopItemForm';
import { UnwrapWETH } from './UnwrapWETHForm';

import { CollectionInfo } from '@/apps/paraspace/pages/Shop/contexts';
import { BuyListing } from '@/apps/paraspace/pages/Shop/types';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { FAQ } from '@/apps/paraspace/pages/Shop/components';

export type BuyShopItemPayload = {
  collection: CollectionInfo;
  lowestPriceListing: BuyListing;
  tokenId: number;
  refetch?: any;
  multiplier: BigNumberJs;
};

const UnwrapWETHTitle = styled(Inline)`
  cursor: pointer;
`;

const Container = styled(Modal)`
  .customModal {
    overflow: visible;
    ${({ theme }) => theme.breakpoints.only('mobile')`
      width: 100%;
    `}
  }
`;

export type BuyShopItemModalProps = Omit<ModalProps, 'children' | 'onClose' | 'title'> & {
  payload: Maybe<BuyShopItemPayload>;
  onError?: () => void;
  onFinish?: () => void;
  onClose: () => void;
};

export const BuyShopItemModal = memo(
  ({ isOpen, payload, onClose, onError, onFinish, ...others }: BuyShopItemModalProps) => {
    const [showUnwrapWETH, setShowUnwrapWETH] = useState(false);
    const [showFaqs, setShowFaqs] = useState(false);

    const hideUnwrapWETHForm = useCallback(() => {
      setShowUnwrapWETH(false);
    }, []);

    const showUnwrapWETHForm = useCallback(() => {
      setShowUnwrapWETH(true);
    }, []);

    return (
      <Container
        classNames={{
          modal: 'customModal'
        }}
        isOpen={isOpen}
        onClose={onClose}
        title={
          showUnwrapWETH ? (
            <UnwrapWETHTitle gap="1rem" alignItems="center" onClick={hideUnwrapWETHForm}>
              <Icon name="arrowLeft" />
              <H3>Unwrap wETH</H3>
            </UnwrapWETHTitle>
          ) : (
            'Buy Now'
          )
        }
        {...others}
      >
        {showUnwrapWETH && (
          <UnwrapWETH
            onClose={onClose}
            onSuccessAction={hideUnwrapWETHForm}
            onError={onError}
            onFinish={onFinish}
            isOpen={isOpen}
          />
        )}

        {!showUnwrapWETH && (
          <BuyShopItem
            isOpen={isOpen}
            payload={payload!}
            onError={onError}
            onFinish={onFinish}
            onClose={onClose}
            onUnwrapWETHClick={showUnwrapWETHForm}
          />
        )}
        <FAQ showFaqs={showFaqs} setShowFaqs={setShowFaqs} />
      </Container>
    );
  }
);
