import { UserNtokenData } from 'paraspace-utilities-contract-helpers';

import { StakefishAssetValidatorInfo } from '../types';

import { ValidatorInfo } from '@/apps/paraspace/hooks/useStakeFishData';
import { StakeFishNTokenData } from '@/apps/paraspace/typings';
import { shiftedLeftBy } from '@/apps/paraspace/utils/calculations';
import { erc20Decimals } from '@/apps/paraspace/config/AppConfig/erc20DecimalsConfig';

export const combineValidatorTokenInfo = (
  tokenInfo: UserNtokenData & { stakefishNTokenData: StakeFishNTokenData },
  validatorInfo?: ValidatorInfo
) => {
  const { tokenId, stakefishNTokenData } = tokenInfo;
  const { pendingFeePoolReward } = stakefishNTokenData;

  return {
    tokenId: tokenId.toNumber(),
    unclaimedPendingReward: shiftedLeftBy(pendingFeePoolReward[0].toString(), erc20Decimals.ETH),
    claimedPendingReward: shiftedLeftBy(pendingFeePoolReward[1].toString(), erc20Decimals.ETH),
    ...(validatorInfo ?? {})
  } as StakefishAssetValidatorInfo;
};
