import { useCallback } from 'react';
import { ReserveInterestRateStrategy } from 'paraspace-utilities-contract-helpers';
import {
  calculateApyFromInterestRate,
  calculateImpactInterestParams
} from 'paraspace-utilities-math-utils';
import BigNumberJs from 'bignumber.js';

import { useReservesAndIncentives } from './useReservesAndIncentives';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { shiftedRightBy } from '@/apps/paraspace/utils/calculations';

export const useSupplyAndBorrowApy = () => {
  const { provider } = useWeb3Context();
  const { getReserveData } = useReservesAndIncentives();

  const getSupplyApy = useCallback(
    async (symbol: string, amount: string) => {
      const reserveData = await getReserveData();
      if (!reserveData) return null;
      const currentTimestamp = (new Date().getTime() / 1000).toFixed(0);
      const targetItem = reserveData.reservesData.filter(
        item => item.symbol.toUpperCase() === symbol
      )[0];
      const rst = calculateImpactInterestParams(
        targetItem,
        Number(currentTimestamp),
        new BigNumberJs(amount).times(10 ** targetItem.decimals),
        0
      );
      const strategy = new ReserveInterestRateStrategy({
        reserveStrategy: targetItem.interestRateStrategyAddress,
        provider
      });
      const result = await strategy.calculateInterestRates(rst);
      const newSupplyApy = calculateApyFromInterestRate(result.currentLiquidityRate);

      return { newSupplyApy };
    },
    [getReserveData, provider]
  );

  const getBorrowApy = useCallback(
    async (symbol: string, amount: string) => {
      const reserveData = await getReserveData();
      if (!reserveData) return null;
      const currentTimestamp = (new Date().getTime() / 1000).toFixed(0);
      const targetItem = reserveData.reservesData.filter(
        item => item.symbol.toUpperCase() === symbol
      )[0];

      const rst = calculateImpactInterestParams(
        targetItem,
        Number(currentTimestamp),
        0,
        shiftedRightBy(BigNumberJs(amount), targetItem.decimals).toString()
      );
      const strategy = new ReserveInterestRateStrategy({
        reserveStrategy: targetItem.interestRateStrategyAddress,
        provider
      });
      const result = await strategy.calculateInterestRates(rst);
      const newBorrowApy = calculateApyFromInterestRate(result.currentVariableBorrowRate);
      return { newBorrowApy };
    },
    [getReserveData, provider]
  );

  return { getSupplyApy, getBorrowApy };
};
