import { zipObject } from 'lodash';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactHooks, initializeConnector } from '@web3-react/core';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { GnosisSafe } from '@web3-react/gnosis-safe';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { useCallback, useMemo } from 'react';
import { Network as NetworkConnector } from '@web3-react/network';
import { Environment, WalletTypeEnum } from 'parax-sdk';
import { Connector } from '@web3-react/types';
import { MetaMask } from '@web3-react/metamask';

import { EIP6963 } from './eip6963';
import { EIP6963_PROVIDER_MANAGER } from './eip6963/providers';

import config, { Network, SUPPORTED_NETWORKS, networkConfigs } from '@/apps/parax/config';
import { env } from '@/env';

type HooksData = {
  chainId: number | undefined;
  provider: Web3Provider | undefined;
  account: string;
  isActive: boolean;
};

const DefaultConnectorPayload = initializeConnector(actions => {
  const urlMap = zipObject(
    SUPPORTED_NETWORKS,
    SUPPORTED_NETWORKS.map(network => {
      const urls = networkConfigs[network]?.publicJsonRPCUrl || [];
      return urls;
    })
  );

  return new NetworkConnector({
    actions,
    urlMap,
    defaultChainId: {
      [Environment.DEVELOPMENT]: Network.SEPOLIA,
      [Environment.STAGING]: Network.SEPOLIA,
      [Environment.PRODUCTION]: Network.MAINNET
    }[env]
  });
});

const optionalChains = SUPPORTED_NETWORKS.filter(it => it !== Network.MAINNET);

const defaultChain = config.isProductionOrForkMainnet ? Network.MAINNET : SUPPORTED_NETWORKS[0];
const ConnectorMap: Record<WalletTypeEnum, ReturnType<typeof initializeConnector>> = {
  [WalletTypeEnum.METAMASK]: initializeConnector(actions => new MetaMask({ actions })),
  [WalletTypeEnum.BITGET]: initializeConnector(actions => new EIP6963({ actions })),
  [WalletTypeEnum.WALLET_CONNECT]: initializeConnector(
    actions =>
      new WalletConnectV2({
        defaultChainId: defaultChain,
        actions,
        options: {
          projectId: config.walletConnectProjectId,
          chains: [defaultChain, ...optionalChains],
          showQrModal: true,
          optionalChains: [defaultChain, ...optionalChains],
          metadata: {
            name: 'Parallel',
            description: 'Parallel',
            url: window.location.origin,
            icons: []
          }
        }
      })
  ),
  [WalletTypeEnum.OKX_WALLET]: initializeConnector(actions => {
    return new EIP6963({ actions });
  }),
  [WalletTypeEnum.GNOSIS_SAFE]: initializeConnector(actions => new GnosisSafe({ actions })),
  [WalletTypeEnum.COINBASE_WALLET]: initializeConnector(
    // it's fine to keep url (default rpc point) empty
    // https://github.com/coinbase/coinbase-wallet-sdk/blob/master/packages/wallet-sdk/src/CoinbaseWalletSDK.ts#L136
    actions => new CoinbaseWallet({ actions, options: { appName: 'ParaSpace', url: '' } })
  ),
  // fallback
  [WalletTypeEnum.NETWORK]: DefaultConnectorPayload
};

const useConnectorHooksData = (hooks: Web3ReactHooks): HooksData => {
  const { useProvider, useChainId, useAccount, useIsActive } = hooks;
  const chainId = useChainId();
  const provider = useProvider();
  const account = useAccount() || '';
  const isActive = useIsActive();

  return useMemo(
    () => ({
      chainId,
      provider,
      account,
      isActive
    }),
    [chainId, provider, account, isActive]
  );
};

export const useWalletConnectorsAndData = () => {
  // 0: connector  1: hooks
  const metamask = ConnectorMap[WalletTypeEnum.METAMASK];
  const metaMaskData = useConnectorHooksData(metamask[1]);

  const bitget = ConnectorMap[WalletTypeEnum.BITGET];
  const bitgetData = useConnectorHooksData(bitget[1]);

  const walletConnect = ConnectorMap[WalletTypeEnum.WALLET_CONNECT];
  const walletConnectData = useConnectorHooksData(walletConnect[1]);

  const okxWallet = ConnectorMap[WalletTypeEnum.OKX_WALLET];
  const okxWalletData = useConnectorHooksData(okxWallet[1]);

  const coinbaseWallet = ConnectorMap[WalletTypeEnum.COINBASE_WALLET];
  const coinbaseWalletData = useConnectorHooksData(coinbaseWallet[1]);

  const gnosis = ConnectorMap[WalletTypeEnum.GNOSIS_SAFE];
  const gonosisData = useConnectorHooksData(gnosis[1]);

  const network = ConnectorMap[WalletTypeEnum.NETWORK];
  const networkData = useConnectorHooksData(network[1]);

  const getProviderDetail = useCallback((walletType: WalletTypeEnum) => {
    return EIP6963_PROVIDER_MANAGER.list.find(
      v =>
        v.info.name ===
        (
          {
            [WalletTypeEnum.METAMASK]: 'MetaMask',
            [WalletTypeEnum.OKX_WALLET]: 'OKX Wallet',
            [WalletTypeEnum.BITGET]: 'Bitget Wallet'
          } as Record<WalletTypeEnum, string>
        )[walletType]
    );
  }, []);

  const wrapConnector = useCallback(
    (walletConnector: Connector, walletType: WalletTypeEnum) => {
      return {
        ...walletConnector,
        activate: (chain: Network) => {
          const providerDetail = getProviderDetail(walletType);
          if (!providerDetail) {
            throw new Error(`Can not find the ${walletType} injected provider`);
          }
          (walletConnector as EIP6963).provider.setCurrentProvider(providerDetail.info.rdns);
          return walletConnector.activate(chain);
        }
      };
    },
    [getProviderDetail]
  );

  return useMemo(
    () =>
      ({
        [WalletTypeEnum.NETWORK]: {
          connector: network[0],
          data: networkData
        },
        [WalletTypeEnum.WALLET_CONNECT]: {
          connector: walletConnect[0],
          data: walletConnectData
        },
        [WalletTypeEnum.OKX_WALLET]: {
          connector: wrapConnector(okxWallet[0], WalletTypeEnum.OKX_WALLET),
          data: okxWalletData
        },
        [WalletTypeEnum.COINBASE_WALLET]: {
          connector: coinbaseWallet[0],
          data: coinbaseWalletData
        },
        [WalletTypeEnum.METAMASK]: {
          connector: metamask[0],
          data: metaMaskData
        },
        [WalletTypeEnum.GNOSIS_SAFE]: {
          connector: gnosis[0],
          data: gonosisData
        },
        [WalletTypeEnum.BITGET]: {
          connector: wrapConnector(bitget[0], WalletTypeEnum.BITGET),
          data: bitgetData
        }
      } as Record<WalletTypeEnum, { connector: Connector; data: any }>),
    [
      network,
      networkData,
      walletConnect,
      walletConnectData,
      wrapConnector,
      okxWallet,
      okxWalletData,
      coinbaseWallet,
      coinbaseWalletData,
      metamask,
      metaMaskData,
      gnosis,
      gonosisData,
      bitget,
      bitgetData
    ]
  );
};
