import { AutoCompoundApe } from 'paraspace-utilities-contract-helpers';
import { useCallback, useMemo } from 'react';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

export const useAutoCompoundApe = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();

  const service = useMemo(() => {
    const apeStakingService = new AutoCompoundApe(provider, contracts.cAPE);
    return apeStakingService;
  }, [contracts.cAPE, provider]);

  const genStakingAutoCompoundApe = useCallback(
    async (params: { amount: string }) => {
      const { amount } = params;
      if (!service || !provider) return null;

      const tx = service.deposit({ user: account, amount });
      return tx;
    },
    [service, provider, account]
  );

  return {
    genStakingAutoCompoundApe
  };
};
