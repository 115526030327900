import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import { useTimelock } from './useTimelock';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';

export const useTimelockClaimable = () => {
  const { getContractFrozenStatus } = useTimelock();

  const { features } = useAppConfig();

  const autoClaimEnabled = useMemo(() => features.includes(Feature.TimelockAutoClaim), [features]);

  const getTimelockClaimable = useCallback(
    async ({
      agreementId,
      releaseTime,
      minWaitTime
    }: {
      agreementId: Maybe<string>;
      releaseTime: Maybe<Date>;
      minWaitTime: number;
    }) => {
      const waitTime = dayjs(releaseTime).diff(new Date(), 'seconds');
      const claimable = agreementId !== null && releaseTime !== null && waitTime <= minWaitTime;

      if (!claimable) return claimable;

      const isContractFrozen = await getContractFrozenStatus();
      return claimable && !isContractFrozen;
    },
    [getContractFrozenStatus]
  );

  return {
    autoClaimEnabled,
    getTimelockClaimable
  };
};
