import { ReactNode, createContext, memo, useContext, useMemo } from 'react';

import { NftInfo, Offer } from '../../types';

import { AcceptOfferModal, CancelOfferModal, ViewAllOffersModal } from './modals';
import { useAcceptOffer, useCancelOffer, useViewAllOffers } from './hooks';

type ShopActionsContextValue = {
  cancelOffer: (offer: Offer) => Promise<void>;
  acceptOffer: (offer: Offer, nftInfo: NftInfo) => Promise<void>;
  viewAllOffers: (offer: Offer, nftInfo: NftInfo, inLiquidation: boolean) => Promise<void>;
};

const defaultShopActionsContextValue: ShopActionsContextValue = {
  cancelOffer: () => {
    throw new Error('Not implemented yet');
  },
  acceptOffer: () => {
    throw new Error('Not implemented yet');
  },
  viewAllOffers: () => {
    throw new Error('Not implemented yet');
  }
};

const ShopActionsContext = createContext<ShopActionsContextValue>(defaultShopActionsContextValue);

type ShopActionsProviderProps = { children: ReactNode };

export const ShopActionsProvider = memo(({ children }: ShopActionsProviderProps) => {
  const [cancelOfferModalProps, cancelOffer] = useCancelOffer();
  const [acceptOfferModalProps, acceptOffer] = useAcceptOffer();
  const [viewAllOffersModalProps, viewAllOffers] = useViewAllOffers();
  const contextValue = useMemo(
    () => ({ cancelOffer, acceptOffer, viewAllOffers }),
    [cancelOffer, acceptOffer, viewAllOffers]
  );
  return (
    <ShopActionsContext.Provider value={contextValue}>
      <CancelOfferModal {...cancelOfferModalProps} />
      <AcceptOfferModal {...acceptOfferModalProps} />
      <ViewAllOffersModal {...viewAllOffersModalProps} />
      {children}
    </ShopActionsContext.Provider>
  );
});

export const useShopActions = () => useContext(ShopActionsContext);
