import { Card, H5, Inline, Responsive, Stack } from '@parallel-mono/components';
import { FC, memo } from 'react';
import BigNumber from 'bignumber.js';

import { ApeCoinListing, ApeListing, BakcListing, ListingType } from '../../../types';
import { ListingInfo } from '../../../components';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { NFTCollectionThumbnail } from '@/apps/paraspace/components';

type MatchPairCardProps = {
  apeListing: ApeListing;
  apeCoinListing: ApeCoinListing;
  bakcListing: Maybe<BakcListing>;
  poolApy: BigNumber;
  account: string;
};

export const MatchPairCard: FC<MatchPairCardProps> = memo(
  ({ apeListing, apeCoinListing, bakcListing, poolApy, account }) => {
    const { stakingPool } = apeListing;

    return (
      <Card border>
        <Stack gap="2rem">
          <Inline justifyContent="space-between" alignItems="flex-end">
            <Inline alignItems="center" gap="0.5rem">
              <NFTCollectionThumbnail symbol={stakingPool!} size="small" />
              <H5>{stakingPool} Pool</H5>
            </Inline>
          </Inline>
          <Responsive width="100%" gap="1.5rem" breakPoint="tablet">
            {stakingPool === ERC721Symbol.BAKC && bakcListing && (
              <ListingInfo
                stakingPool={stakingPool}
                listingType={ListingType.BakcListing}
                listing={bakcListing}
                poolApy={poolApy}
                account={account}
              />
            )}
            <ListingInfo
              stakingPool={stakingPool}
              listingType={ListingType.ApeListing}
              listing={apeListing}
              poolApy={poolApy}
              account={account}
            />
            <ListingInfo
              stakingPool={stakingPool}
              listingType={ListingType.ApeCoinListing}
              listing={apeCoinListing}
              poolApy={poolApy}
              account={account}
            />
          </Responsive>
        </Stack>
      </Card>
    );
  }
);
