import { Modal, ModalProps } from '@parallel-mono/components';
import { memo } from 'react';
import BigNumber from 'bignumber.js';

import { StakeApeForm } from './StakeApeForm';
import { StakeApeStepper } from './StakeApeStepper';
import StakingSuccess from './StakingSuccess';

import { ApeStakingMainTokenSymbol, WalletType } from '@/apps/paraspace/typings';
import {
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure,
  ErrorState
} from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type StakeApeInfo = {
  apy: BigNumber;
  stakeLimit: BigNumber;
  stakedAmount: BigNumber;
  apeCoinSource: WalletType;
  apeSource: Maybe<WalletType>;
  pendingRewards: BigNumber;
  symbol: ApeStakingMainTokenSymbol;
  tokenId: number;
  supplied: boolean;
};

export type StakeApeFormData = {
  type: ApeStakingMainTokenSymbol;
  tokenId: number;
  borrowAmount: number;
  cashAmount: number;
  supplied: boolean;
  apeCoinSource: WalletType;
  apeSource: Maybe<WalletType>;
};

export type ApeStakeModalProps = {
  nftInfo: StakeApeInfo;
  isOpen: boolean;
  onClose: () => void;
  onFinish: () => void;
  onError: () => void;
} & Omit<ModalProps, 'children' | 'onClose' | 'title'>;

export const ApeStakeModal = memo((props: ApeStakeModalProps) => {
  const { nftInfo, isOpen, onClose, onFinish, onError } = props;
  const { phase, submittedFormData, handleFormSubmit, handleSubmitFailed, handleSubmitSuccess } =
    useCollectAndSubmitProcedure<StakeApeFormData>({
      running: isOpen,
      onFinish,
      onError
    });

  return (
    <Modal size="32.5rem" title="Stake" onClose={onClose} isOpen={isOpen}>
      {phase === CollectAndSubmitProcedurePhase.Collecting && (
        <StakeApeForm apeInfo={nftInfo} onSubmit={handleFormSubmit} />
      )}
      {phase === CollectAndSubmitProcedurePhase.Submitting && (
        <StakeApeStepper
          onFinish={handleSubmitSuccess}
          onError={handleSubmitFailed}
          formData={submittedFormData!}
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
      {phase === CollectAndSubmitProcedurePhase.Success && (
        <StakingSuccess
          formData={submittedFormData!}
          onClose={onClose}
          stakedAmount={nftInfo.stakedAmount}
        />
      )}
    </Modal>
  );
});
