import { Button, Inline, SmallText, Stack, useBreakpoints, Image } from '@parallel-mono/components';
import styled from 'styled-components';
import { ReactElement, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Environment } from 'parax-sdk';

import yugaLabContract from '../assets/yuga-lab.svg';
import { useStakeInfoList } from '../StakeInfoListProvider/StakeInfoListProvider';

import MintLink from './MintLink';

import apeCoinLogo from '@/apps/paraspace/assets/ape-coin-right.png';
import Bg from '@/apps/paraspace/assets/bg.png';
import { Link } from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useWeb3Context } from '@/apps/paraspace/contexts';

const MessageContainer = styled(Inline).attrs(() => ({}))`
  background: url(${Bg});
  flex: 1;
  padding: 1.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 0.75rem;

  ${({ theme }) => theme.breakpoints.down('desktop')`
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.5rem;
  `};
`;

const SideBarWrapper = styled(Stack).attrs(({ theme }) => ({
  gap: '1rem',
  alignItems: theme.breakpoints.down('desktop') ? 'center' : 'start'
}))<{ usApe: boolean }>`
  width: 100%;
  position: sticky;
  top: ${props =>
    props.usApe
      ? `calc(var(--header-height-pc) + 3.75rem)`
      : `calc(var(--header-height-pc) + 10rem)`};
  flex: 1;
  height: fit-content;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    position: relative;
    top: 0;
    margin-top: 1rem;
  `};
`;

type ApeCoinMessageProps = {
  icon: ReactElement | string;
  message: ReactNode;
};

const ApeCoinMessage = ({ icon, message }: ApeCoinMessageProps) => {
  return (
    <MessageContainer gap="1rem" alignItems="flex-start">
      <Inline inset="0.5rem 0">{icon}</Inline>
      <Stack gap="0">{message}</Stack>
    </MessageContainer>
  );
};

const ApeCoinList = () => {
  const { desktop } = useBreakpoints();
  const navigate = useNavigate();
  return (
    <>
      <ApeCoinMessage
        icon={<Image width="2rem" src={yugaLabContract} />}
        message={
          <SmallText skin="secondary">
            You are staking through Horizen Labs contract. Rewards starts on Dec 12, 2022.{' '}
            <Link
              href="https://forum.apecoin.com/t/aip-21-staking-process-with-caps-1x-drop-process/5074"
              target="_b"
            >
              Read more.
            </Link>
          </SmallText>
        }
      />
      <ApeCoinMessage
        icon={<Image width={desktop ? '3rem' : '2rem'} src={apeCoinLogo} />}
        message={
          <Stack gap="1rem">
            <SmallText skin="secondary">Need more Ape Coins? Use the ParaSpace App.</SmallText>
            <Inline justifyContent={desktop ? 'start' : 'center'}>
              <Button
                onClick={() => navigate('/')}
                size={desktop ? 'medium' : 'small'}
                skin="secondary"
              >
                Borrow APE
              </Button>
            </Inline>
          </Stack>
        }
      />
    </>
  );
};

export const ApeStakingSideBar = () => {
  const { env } = useWeb3Context();
  const { basicInfoLoaded } = useMMProvider();
  const { refresh } = useStakeInfoList();
  const { pathname } = useLocation();

  const { desktop } = useBreakpoints();

  if (!basicInfoLoaded) return null;
  return desktop ? (
    <SideBarWrapper usApe={pathname === '/'}>
      <ApeCoinList />
      {env !== Environment.PRODUCTION && (
        <MintLink
          mintCallback={refresh}
          customizedFaucetAddr="0x8c23cd9d7dcc871fc1f101fbbd9d391d41218b35" // hard-coded for now in ape staking case
        />
      )}
    </SideBarWrapper>
  ) : (
    <SideBarWrapper usApe={pathname === '/'}>
      {env !== Environment.PRODUCTION && (
        <MintLink
          mintCallback={refresh}
          customizedFaucetAddr="0x8c23cd9d7dcc871fc1f101fbbd9d391d41218b35" // hard-coded for now in ape staking case
        />
      )}
      <Inline>
        <ApeCoinList />
      </Inline>
    </SideBarWrapper>
  );
};
