import { UiPoolDataProvider } from 'paraspace-utilities-contract-helpers';
import BigNumberJs, { BigNumber } from 'bignumber.js';
import { keyBy, zipObject } from 'lodash';

import { ERC721Symbol, StakeFishNTokenData } from '@/apps/paraspace/typings';
import { shiftedLeftBy } from '@/apps/paraspace/utils/calculations';
import { erc20Decimals } from '@/apps/paraspace/config/AppConfig/erc20DecimalsConfig';

export const getNTokensDataMap = async ({
  provider,
  lendingPoolAddressProvider,
  tokens
}: {
  provider: UiPoolDataProvider;
  lendingPoolAddressProvider: string;
  tokens: {
    underlyingAsset: string;
    xTokenAddress: string;
    symbol: ERC721Symbol;
    nftSuppliedList: number[];
  }[];
}) => {
  const userNTokensData = await provider.getNtokenData({
    lendingPoolAddressProvider,
    nTokenAddresses: tokens.map(it => it.xTokenAddress),
    tokenIds: tokens.map(it => it.nftSuppliedList)
  });

  const userNTokensDataWithSymbol = userNTokensData.map((data, index) => ({
    symbol: tokens[index].symbol,
    auctionedTokens: data.filter(it => it.isAuctioned).map(it => it.tokenId.toNumber()),
    nftCollateralList: data.filter(it => it.useAsCollateral).map(it => it.tokenId.toNumber()),
    tokenTraitMultipliers: zipObject(
      data.map(it => it.tokenId.toString()),
      data.map(it => new BigNumber(it.multiplier.toString()).shiftedBy(-18))
    ),
    stakeFishInfoMap:
      tokens[index].symbol === ERC721Symbol.SF_VLDR
        ? zipObject(
            data.map(it => it.tokenId.toString()),
            data.map(it => {
              const {
                withdrawnBalance,
                nftArtUrl,
                pubkey,
                validatorIndex,
                stateHistory,
                pendingFeePoolReward
              } = (it as any).stakefishNTokenData as StakeFishNTokenData;
              return {
                withdrawnBalance: new BigNumberJs(withdrawnBalance.toString()),
                nftArtUrl,
                pubkey,
                validatorIndex: new BigNumberJs(validatorIndex.toString()),
                state: stateHistory[stateHistory.length - 1].state,
                unclaimedPendingReward: shiftedLeftBy(
                  pendingFeePoolReward[0].toString(),
                  erc20Decimals.ETH
                ),
                claimedPendingReward: shiftedLeftBy(
                  pendingFeePoolReward[1].toString(),
                  erc20Decimals.ETH
                )
              };
            })
          )
        : {}
  }));

  const userNTokensDataMap = keyBy(userNTokensDataWithSymbol, it => it.symbol);
  return userNTokensDataMap;
};

export * from './types';
