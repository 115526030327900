import { FC, memo, useMemo, useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';

import { StakeApeFormData } from '.';

import {
  Stepper,
  StepperProps,
  DepositERC20Submitter,
  DepositERC721Submitter
} from '@/apps/paraspace/components';
import { useContractsMap, useERC20, useERC721, usePoolApeStaking } from '@/apps/paraspace/hooks';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

interface StepProps extends Omit<StepperProps, 'steps' | 'step' | 'onError'> {
  onError: () => void;
  onFinish: () => void;
  formData: StakeApeFormData;
}

const StakingStep: FC<StepProps> = ({ onError, onFinish, formData, ...others }) => {
  const { type, tokenId, cashAmount, borrowAmount, apeCoinSource, apeSource } = formData;
  const { nftInfoMap } = useMMProvider();
  const apeAddress = nftInfoMap[type]?.address ?? '';
  const contracts = useContractsMap();
  const [step, setStep] = useState(0);
  const { genStakingApe } = usePoolApeStaking();
  const { createApproval: genApprovalForAPE } = useERC20(contracts.APE);
  const { genApprovalForAll, genSupplyERC721Txs } = useERC721(type);

  const handleNextStep = useCallback(() => {
    setStep(prevStep => prevStep + 1);
  }, []);

  const stakeApeCoinTips = useMemo(
    () => (cashAmount ? 'Securely Staking $APE' : 'Get $cAPE from ParaSpace Money Market'),
    [cashAmount]
  );

  const batchTxs = useMemo(
    () => [
      { tx: genApprovalForAll({ ids: [tokenId] }), skip: apeSource === null },
      { tx: genSupplyERC721Txs({ tokenIds: [tokenId] }), skip: apeSource === null },
      {
        tx: genApprovalForAPE({ amount: cashAmount }),
        skip: cashAmount <= 0
      },
      {
        tx: genStakingApe({ type, borrowAmount, cashAmount, tokenId })
      }
    ],
    [
      apeSource,
      cashAmount,
      tokenId,
      type,
      borrowAmount,
      genStakingApe,
      genApprovalForAll,
      genSupplyERC721Txs,
      genApprovalForAPE
    ]
  );

  const steps = useMemo(
    () => [
      {
        description: `Deposit ${formatBalance(
          new BigNumber(cashAmount)
        )} APE to your Parallel account`,
        content: (
          <DepositERC20Submitter
            formData={{
              address: contracts.APE,
              symbol: ERC20Symbol.APE,
              value: new BigNumber(cashAmount)
            }}
            onFinish={handleNextStep}
            onError={onError}
          />
        ),
        skip: apeCoinSource !== 'EOA' || cashAmount <= 0
      },
      {
        description: `Deposit ${type} #${tokenId} to your Parallel account`,
        content: (
          <DepositERC721Submitter
            formData={{
              tokenId,
              address: apeAddress
            }}
            onFinish={handleNextStep}
            onError={onError}
          />
        ),
        skip: apeSource !== 'EOA'
      },
      {
        description: 'Stake $APE',
        content: (
          <BatchTransactionsSubmitter
            batchTxs={batchTxs}
            onFinish={onFinish}
            onError={onError}
            inProgressMessage={stakeApeCoinTips}
          />
        )
      }
    ],
    [
      apeAddress,
      apeCoinSource,
      cashAmount,
      contracts,
      handleNextStep,
      onError,
      onFinish,
      stakeApeCoinTips,
      tokenId,
      type,
      apeSource,
      batchTxs
    ]
  );

  return <Stepper steps={steps} step={step} {...others} />;
};

export const StakeApeStepper = memo(StakingStep);
