import { Pill } from '@parallel-mono/business-components';
import { Inline, Stack, StackProps } from '@parallel-mono/components';
import { memo, ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';

import { NftDelegationProvider } from './contexts';
import { InitiateDelegation, ManageDelegation } from './tabs';

type NftDelegationProps = Omit<StackProps, 'children'>;

const Container = styled(Stack)`
  overflow: auto;
`;

const StyledPill = styled(Pill).attrs({
  skin: 'primary'
})`
  border: none;
  background: ${({ active, theme }) =>
    active ? theme.skin.primary.contrastBackground : 'transparent'};
  padding: 0.5rem 1rem;
  height: auto;
`;

type Tab = {
  label: string;
  key: string;
  component: ReactNode;
};

const tabs: Tab[] = [
  {
    key: 'initiate-delegation',
    label: 'Initiate',
    component: <InitiateDelegation />
  },
  {
    label: 'Manage',
    key: 'manage-delegation',
    component: <ManageDelegation />
  }
];

export const NftDelegation = memo((props: NftDelegationProps) => {
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);
  const createToggleTagHandler = useCallback(
    (tab: Tab) => () => {
      setActiveTab(tab);
    },
    [setActiveTab]
  );
  return (
    <Container width="100%" {...props}>
      <NftDelegationProvider>
        <Inline>
          {tabs.map(tab => (
            <StyledPill
              key={tab.key}
              label={tab.label}
              active={activeTab === tab}
              onToggle={createToggleTagHandler(tab)}
            />
          ))}
        </Inline>
        {activeTab.component}
      </NftDelegationProvider>
    </Container>
  );
});
