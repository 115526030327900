import { memo, useCallback } from 'react';
import { TransactionReceipt } from '@ethersproject/providers';
import BigNumber from 'bignumber.js';
import ReactGA from 'react-ga4';

import { FormSubmitter } from '../FormSubmitter';

import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useTimelock } from '@/apps/paraspace/pages/hooks/Timelock';
import { TimelockStepIntro } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';

export type BorrowEthDerivatesFormData = {
  amount: BigNumber;
  derivative: ERC20Symbol;
  variableDebtTokenAddress: string;
  address: string;
};

type WithdrawEthDerivativeSubmitterProps = {
  formData: BorrowEthDerivatesFormData;
  onFinish: (agreementId: Maybe<string>, releaseTime: Maybe<Date>) => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const BorrowNativeTokenDerivativeSubmitter = memo(
  ({ formData, onFinish, onError }: WithdrawEthDerivativeSubmitterProps) => {
    const { extractEventData } = useTimelock();
    const { submitTransactions } = useWeb3Context();
    const { borrowERC20 } = usePool();

    const handleBorrow = useCallback(async () => {
      const tx = await borrowERC20(formData.address, formData.amount.toString());
      return submitTransactions([tx!]);
    }, [borrowERC20, formData, submitTransactions]);

    const handleFinish = useCallback(
      ([result]: Maybe<TransactionReceipt>[]) => {
        const { logs = [] } = result ?? {};
        const [{ releaseTime = null, agreementId = null } = {}] = extractEventData<{
          releaseTime: number;
          agreementId: string;
        }>('AgreementCreated', logs);
        ReactGA.event({
          action: 'borrow',
          category: 'erc20',
          label: formData.derivative,
          value: formData.amount.toNumber() ?? undefined
        });
        onFinish?.(agreementId, releaseTime === null ? null : new Date(releaseTime * 1000));
      },
      [extractEventData, formData.derivative, formData.amount, onFinish]
    );

    return (
      <FormSubmitter
        inProgressMessage="Securely Withdrawing"
        onError={onError}
        onFinish={handleFinish}
        submit={handleBorrow}
      >
        <TimelockStepIntro />
      </FormSubmitter>
    );
  }
);
