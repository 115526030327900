import { FC, memo } from 'react';
import { TypographyVariant, Image, Inline } from '@parallel-mono/components';

import { Link } from '../Link';

import chainlinkIcon from './images/chainlink-icon.svg';

import { ERC721Symbol } from '@/apps/paraspace/typings';

const SymbolPriceFromChainLink: Partial<Record<ERC721Symbol, string>> = {
  // [ERC721Symbol.AZUKI]: 'coinbase-azuki-floor-price-eth',
  // [ERC721Symbol.BEANZ]: 'coinbase-beanz-official-floor-price',
  // [ERC721Symbol.BAYC]: 'coinbase-bored-ape-yacht-club-floor-price-eth',
  // [ERC721Symbol.CLONEX]: 'coinbase-clonex-floor-price',
  // [ERC721Symbol.CRYPTOPUNKS]: 'coinbase-cryptopunks-floor-price-eth',
  // [ERC721Symbol.OTHERDEED]: 'coinbase-otherdeed-for-otherside-floor-price',
  // [ERC721Symbol.PUNK]: 'coinbase-otherdeed-for-otherside-floor-price',
  // [ERC721Symbol.DOODLE]: 'coinbase-doodles-floor-price',
  // [ERC721Symbol.MAYC]: 'coinbase-mayc-floor-price',
  // [ERC721Symbol.MOONBIRD]: 'coinbase-moonbirds-floor-price',
  // [ERC721Symbol.PPG]: 'coinbase-pudgy-penguins-floor-price'
};

interface ChainlinkLogoProps {
  symbol: ERC721Symbol;
  showName?: boolean;
}

const CHAINLINK_FLOOR_PRICE_URL = 'https://data.chain.link/feeds/ethereum/mainnet/';

export const ChainlinkLogo: FC<ChainlinkLogoProps> = memo(({ symbol, showName = true }) => {
  const uri = SymbolPriceFromChainLink[symbol];
  if (uri) {
    return (
      <Link
        href={`${CHAINLINK_FLOOR_PRICE_URL}/${uri}`}
        target="_blank"
        underline={false}
        variant={TypographyVariant.bodySmall}
        skin="secondary"
      >
        <Inline alignItems="center" gap="0.25rem">
          <Image width="1rem" height="1rem" src={chainlinkIcon} />
          {showName && <span>Chainlink</span>}
        </Inline>
      </Link>
    );
  }
  return null;
});
