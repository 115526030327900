import { memo, useCallback, useState } from 'react';

import { TimelockBanner, TimelockBannerProps } from './TimelockBanner';

type SingleUseTimelockBannerProps = TimelockBannerProps;

const timelockBannerMarkKey = 'timelock_banner_viewed';

const alreadyViewed = localStorage.getItem(timelockBannerMarkKey) === 'true';

export const SingleUseTimelockBanner = memo(
  ({ onClose, ...others }: SingleUseTimelockBannerProps) => {
    const [visible, setVisible] = useState(!alreadyViewed);

    const handleClose = useCallback(() => {
      setVisible(false);
      localStorage.setItem(timelockBannerMarkKey, 'true');
      onClose?.();
    }, [onClose, setVisible]);

    if (!visible) return null;

    return <TimelockBanner onClose={handleClose} {...others} />;
  }
);
