import { memo, useCallback, useMemo, useState } from 'react';
import {
  Button,
  H4,
  Inline,
  Modal,
  ModalProps,
  Stack,
  StackProps,
  Typography,
  TypographyVariant
} from '@parallel-mono/components';
import styled from 'styled-components';

import { FormState } from '../types';

import Success from './TransferSuccess';
import TransferStep from './TransferStep';

import { ErrorState, NFTThumbnail } from '@/apps/paraspace/components';
import { ApeListItem } from '@/apps/paraspace/pages/ApePairing/contexts';

export type TransferNftParams = Pick<
  ApeListItem,
  'symbol' | 'tokenId' | 'supplied' | 'mainTokenId' | 'mainTokenSymbol'
> & {
  mainTokenSupplied?: boolean;
};

export type TransferModalProps = Omit<ModalProps, 'children'> & TransferNftParams;

type TransferModalContentProps = Omit<StackProps, 'children'> &
  TransferNftParams & {
    onFinish?: () => void;
  };

const Desc = styled(Typography)`
  color: ${({ theme }) => theme.skin.grey['700']};
  text-align: center;
`;

const TransferNftModalContent = memo(
  ({
    symbol,
    tokenId,
    supplied,
    mainTokenId,
    mainTokenSymbol,
    mainTokenSupplied,
    onFinish,
    ...others
  }: TransferModalContentProps) => {
    const [status, setStatus] = useState<FormState>(FormState.MAIN_TOKEN_FORM);

    // collect data
    const handleSubmit = useCallback(() => {
      setStatus(FormState.STEPPER);
    }, []);

    const handleError = useCallback(() => {
      setStatus(FormState.ERROR);
    }, []);

    const handleSuccess = useCallback(() => {
      setStatus(FormState.SUCCESS);
    }, []);

    const images = useMemo(() => {
      const res = [];
      if (!supplied) {
        res.push({
          tokenId,
          symbol
        });
      }

      if (mainTokenSymbol && mainTokenId && !mainTokenSupplied)
        res.push({
          symbol: mainTokenSymbol,
          tokenId: mainTokenId
        });
      return res;
    }, [mainTokenId, mainTokenSupplied, mainTokenSymbol, supplied, symbol, tokenId]);

    if (status === FormState.MAIN_TOKEN_FORM) {
      return (
        <Stack alignItems="center" {...others}>
          {status === FormState.MAIN_TOKEN_FORM && (
            <>
              <Inline gap=".5rem" justifyContent="center">
                {images.map(({ tokenId: id, symbol: renderSymbol }, index) => (
                  <NFTThumbnail
                    key={id + index}
                    symbol={renderSymbol}
                    tokenId={id}
                    size="large"
                    showDescription
                  />
                ))}
              </Inline>
              <Stack gap=".25rem" alignItems="center">
                <H4>Enjoy Higher APY</H4>
                <Desc variant={TypographyVariant.body}>
                  Transfer this NFT from ApeStake to ParaSpace to earn compound APY.
                </Desc>
              </Stack>
              <Button block onClick={handleSubmit} size="large">
                Transfer
              </Button>
            </>
          )}
        </Stack>
      );
    }

    if (status === FormState.STEPPER) {
      return (
        <TransferStep
          onError={handleError}
          onSuccess={handleSuccess}
          data={{ symbol, tokenId, supplied, mainTokenId, mainTokenSymbol, mainTokenSupplied }}
        />
      );
    }

    if (status === FormState.ERROR) {
      return <ErrorState closeModal={onFinish!} />;
    }

    if (status === FormState.SUCCESS) {
      return (
        <Success
          images={images}
          desc="You just transferred NFT to Auto Compound Pool!"
          onClose={onFinish!}
        />
      );
    }

    return null;
  }
);

export const TransferModal = memo(
  ({
    symbol,
    tokenId,
    supplied,
    mainTokenId,
    mainTokenSymbol,
    mainTokenSupplied,
    onClose,
    ...others
  }: TransferModalProps) => {
    return (
      <Modal {...others} onClose={onClose} title="Transfer to Auto Compound">
        <TransferNftModalContent
          onFinish={onClose}
          symbol={symbol}
          tokenId={tokenId}
          supplied={supplied}
          mainTokenId={mainTokenId}
          mainTokenSymbol={mainTokenSymbol}
          mainTokenSupplied={mainTokenSupplied}
        />
      </Modal>
    );
  }
);
