import { Network } from 'paraspace-configs-v2';

import { SupportedNetworks } from '../../typings';

export const duneLinkMap: { [key in SupportedNetworks]: string } = {
  [Network.MAINNET]: 'https://dune.com/parax_dune_owner/parax',
  [Network.SEPOLIA]: 'https://dune.com/parax_dune_owner/parax',
  [Network.ZKSYNC_ERA]: 'https://dune.com/parax_dune_owner/parax',
  [Network.ZKSYNC_GOERLI]: 'https://dune.com/parax_dune_owner/parax',
  [Network.ARBITRUM_ONE]: 'https://dune.com/paraspace_dune_owner/arbitrum/paraspace',
  [Network.ARBITRUM_GOERLI]: 'https://dune.com/paraspace_dune_owner/arbitrum/paraspace',
  [Network.PARALLEL_L3_TESTNET]: 'https://dune.com/paraspace_dune_owner/parax' // TODO: need to be updated
};
