import { ReactNode } from 'react';
import { zipObject } from 'lodash';
import { Navigate } from 'react-router-dom';

import { CollectionItemsShop, Details as NFTDetails, Explore, Offers } from '../pages/Shop/pages';
import { Shop } from '../pages/Shop';
import { useAppConfig } from '../hooks';
import Credit from '../pages/Credit';
import {
  LiquidationDashboard,
  InLiquidation,
  NearLiquidation,
  PausedLiquidation
} from '../pages/Liquidation';
import { NftDelegation, UserInfo, EmailActivation, ProfileSetting } from '../pages/UserInfo';
import { NotFound } from '../components';
import {
  MyListings,
  List,
  ListDashboard,
  ListPricesSetting
} from '../pages/Shop/pages/ListDashboard';
import {
  Airdrop,
  ApePairing,
  ApeStakingCalculator,
  ApeTable,
  AutoCompoundContainer,
  Mint,
  MyApes,
  MyEarnings,
  ActiveMatches,
  MyPositions as MySharePositions,
  PoolInfos,
  SharePool,
  SharePoolCreate,
  SharePoolListing
} from '../pages/ApePairing';
import { OfficialPairing } from '../pages/OfficialPairing';
import { Feature } from '../config';
import { useToggles } from '../contexts';
import { BnplVolume, DepositTvl, LeaderBoard } from '../pages';
import { Lending } from '../pages/Credit/pages';
import { Toggles } from '../toggles';

import { routeNames } from '@/apps/paraspace/App/routeConfig';

type RouteConfig = {
  path: string;
  element: ReactNode;
  featureKey?: Feature;
  toggleKey?: string;
  children?: RouteConfig[];
};

const fullRoutes: RouteConfig[] = [
  { path: '*', element: <NotFound /> },
  {
    path: '/',
    element: <Navigate to={routeNames.LENDING.index} replace />,
    featureKey: Feature.Lending
  },
  {
    path: routeNames.LENDING.index,
    element: <Credit />,
    featureKey: Feature.Lending,
    children: [
      {
        path: '',
        element: <Lending />
      },
      {
        path: routeNames.LENDING.LIQUIDATION.index,
        element: <LiquidationDashboard />,
        featureKey: Feature.LiquidationDashboard,
        children: [
          {
            path: '',
            element: <Navigate to={routeNames.LENDING.LIQUIDATION.IN_LIQUIDATION} replace />
          },
          {
            path: routeNames.LENDING.LIQUIDATION.IN_LIQUIDATION,
            element: <InLiquidation />
          },
          {
            path: routeNames.LENDING.LIQUIDATION.NEAR_LIQUIDATION,
            element: <NearLiquidation />
          },
          {
            path: routeNames.LENDING.LIQUIDATION.PAUSED_LIQUIDATION,
            element: <PausedLiquidation />
          }
        ]
      },
      {
        path: routeNames.LENDING.USER_INFO.index,
        element: <UserInfo />,
        featureKey: Feature.UserInfo,
        children: [
          {
            path: routeNames.LENDING.USER_INFO.NFT_DELEGATION,
            featureKey: Feature.NftDelegation,
            element: <NftDelegation />
          },
          {
            path: routeNames.LENDING.USER_INFO.PROFILE,
            featureKey: Feature.ProfileSetting,
            element: <ProfileSetting />
          }
        ]
      }
    ]
  },
  {
    path: routeNames.SHOP.index,
    element: <Shop />,
    featureKey: Feature.NftShop,
    children: [
      { path: '', element: <Navigate to={routeNames.SHOP.BUY} replace /> },
      { path: routeNames.SHOP.collectionShop, element: <CollectionItemsShop /> },
      { path: routeNames.SHOP.BUY, element: <Explore /> },
      {
        path: routeNames.SHOP.LIST.index,
        element: <ListDashboard />,
        children: [
          {
            path: '',
            element: <List />
          },
          {
            path: routeNames.SHOP.LIST.MY_LISTINGS,
            element: <MyListings />
          },
          {
            path: routeNames.SHOP.LIST.LIST_PRICES_SETTING,
            element: <ListPricesSetting />
          }
        ]
      },
      { path: routeNames.SHOP.OFFERS, element: <Offers /> }
    ]
  },
  { path: routeNames.NFT_DETAIL, element: <NFTDetails />, featureKey: Feature.NftDetail },
  {
    path: routeNames.APE_STAKING.legacyIndex,
    featureKey: Feature.ApeStaking,
    element: <Navigate to={routeNames.APE_STAKING.index} replace />
  },
  {
    path: routeNames.APE_STAKING.index,
    element: <ApePairing />,
    featureKey: Feature.ApeStaking,
    children: [
      {
        path: '',
        element: <Navigate to={routeNames.APE_STAKING.AUTO_COMPOUND} replace />
      },
      {
        path: routeNames.APE_STAKING.AUTO_COMPOUND,
        element: <PoolInfos />
      },
      {
        path: routeNames.APE_STAKING.APE_SHARE_POOLS.index,
        element: <SharePool />,
        children: [
          {
            path: '',
            element: <SharePoolListing />
          },
          {
            path: routeNames.APE_STAKING.APE_SHARE_POOLS.ACTIVE_MATCHES,
            element: <ActiveMatches />
          },
          {
            path: routeNames.APE_STAKING.APE_SHARE_POOLS.MY_POSITION,
            element: <MySharePositions />
          }
        ]
      },
      {
        path: routeNames.APE_STAKING.APE_SHARE_POOLS_CREATE,
        element: <SharePoolCreate />
      },
      {
        path: routeNames.APE_STAKING.NFT_POOLS,
        element: <MyApes />
      },
      {
        path: routeNames.APE_STAKING.POOL_STATS,
        element: <ApeTable />
      },
      {
        path: routeNames.APE_STAKING.COIN_POOL.index,
        element: <AutoCompoundContainer />,
        children: [
          {
            path: routeNames.APE_STAKING.COIN_POOL.TAB,
            element: <AutoCompoundContainer />
          }
        ]
      },
      {
        path: routeNames.APE_STAKING.HORIZEN_STAKING,
        element: <OfficialPairing />
      },
      {
        path: routeNames.APE_STAKING.MY_EARNINGS,
        element: <MyEarnings />
      },
      {
        path: routeNames.APE_STAKING.CALCULATOR,
        element: <ApeStakingCalculator />
      },
      {
        path: routeNames.APE_STAKING.MINT,
        element: <Mint />,
        toggleKey: 'APE_MINT'
      },
      {
        path: routeNames.APE_STAKING.AIRDROP,
        element: <Airdrop />,
        toggleKey: 'APE_AIRDROP'
      }
    ]
  },
  {
    path: routeNames.EMAIL_ACTIVATION,
    element: <EmailActivation />
  },
  {
    path: routeNames.LEADER_BOARD.index,
    element: <LeaderBoard />,
    featureKey: Feature.LeaderBoard,
    children: [
      {
        path: '',
        element: <Navigate to={routeNames.LEADER_BOARD.DEPOSIT_TVL} replace />
      },
      {
        path: routeNames.LEADER_BOARD.DEPOSIT_TVL,
        featureKey: Feature.LeaderBoardDepositTvl,
        element: <DepositTvl />
      },
      {
        path: routeNames.LEADER_BOARD.BNPL_VOLUME,
        featureKey: Feature.LeaderBoardBnplVolume,
        element: <BnplVolume />
      }
    ]
  }
];

const filterRoutesByFeatureAndToggle = (
  routes: RouteConfig[],
  features: Partial<Record<Feature, boolean>>,
  toggles: Toggles
): RouteConfig[] => {
  return routes
    .filter(it => {
      if (it.featureKey && !features[it.featureKey]) {
        return false;
      }

      if (it.toggleKey && !toggles[it.toggleKey]) {
        return false;
      }

      return true;
    })
    .map(
      it =>
        ({
          ...it,
          children: filterRoutesByFeatureAndToggle(it.children ?? [], features, toggles)
        } as RouteConfig)
    );
};

export const useAppRoutes = () => {
  const { features } = useAppConfig();

  const featuresMap = zipObject(
    features,
    features.map(() => true)
  );

  const toggles = useToggles();

  return filterRoutesByFeatureAndToggle(fullRoutes, featuresMap, toggles);
};
