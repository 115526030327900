import { OrderWithCounter } from 'paraspace-seaport-js/lib/types';

// TODO: delete once our own offer listing has been migrated to 1.4
export const convertListOrderTo14Compatible = (order: OrderWithCounter) => {
  return {
    ...order,
    parameters: {
      ...order.parameters,
      counter: String(order.parameters.counter)
    }
  };
};
