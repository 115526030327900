import { memo, createContext, ReactNode, useMemo, useContext } from 'react';

import { DelegationMap } from '../types';

import {
  InitiateNftDelegationModal,
  InitiateNftDelegationModalFormTokensMap,
  RevokeNftDelegationModal
} from './DelegateNftModal';
import {
  useAccountDelegationInfo,
  useInitiateNftDelegation,
  useRevokeNftDelegation
} from './hooks';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

type NftDelegationContextValue = {
  initiateNftDelegation: (tokens: InitiateNftDelegationModalFormTokensMap) => Promise<void>;
  revokeNftDelegation: (
    param: { symbol: ERC721Symbol; tokenId: number; delegate: string }[]
  ) => Promise<void>;
  delegationMapLoaded: boolean;
  delegationMap: Maybe<DelegationMap>;
  refreshDelegationMap: () => Promise<void>;
};

const NftDelegationContext = createContext<NftDelegationContextValue>({
  initiateNftDelegation: () => {
    throw new Error('not implemented yet');
  },
  revokeNftDelegation: () => {
    throw new Error('not implemented yet');
  },
  delegationMap: null,
  delegationMapLoaded: false,
  refreshDelegationMap: () => {
    throw new Error('not implemented yet');
  }
});

export const NftDelegationProvider = memo(({ children }: { children: ReactNode }) => {
  const [delegateNftModalProps, initiateNftDelegation] = useInitiateNftDelegation();

  const [revokeNftDelegationModalProps, revokeNftDelegation] = useRevokeNftDelegation();

  const {
    delegationMap,
    refreshDelegationMap,
    loading: loadingDelegationMap
  } = useAccountDelegationInfo();

  const contextValue: NftDelegationContextValue = useMemo(
    () => ({
      initiateNftDelegation,
      revokeNftDelegation,
      delegationMap,
      delegationMapLoaded: !loadingDelegationMap,
      refreshDelegationMap
    }),
    [
      initiateNftDelegation,
      revokeNftDelegation,
      delegationMap,
      refreshDelegationMap,
      loadingDelegationMap
    ]
  );

  return (
    <NftDelegationContext.Provider value={contextValue}>
      <InitiateNftDelegationModal {...delegateNftModalProps} />
      <RevokeNftDelegationModal {...revokeNftDelegationModalProps} />
      {children}
    </NftDelegationContext.Provider>
  );
});

export const useNftDelegation = () => useContext(NftDelegationContext);
