import { noop } from 'lodash';
import { createContext, memo, useCallback, useContext, useMemo, useState } from 'react';

type ContextValue = {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
};

const AppSelectorContext = createContext<ContextValue>({
  isOpen: false,
  handleOpen: noop,
  handleClose: noop
});
export const AppSelectorProvider = memo(({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const value = useMemo(
    () => ({ isOpen, handleOpen, handleClose }),
    [handleClose, handleOpen, isOpen]
  );
  return <AppSelectorContext.Provider value={value}>{children}</AppSelectorContext.Provider>;
});

export const useAppSelector = () => useContext(AppSelectorContext);
