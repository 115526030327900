import BigNumberJs from 'bignumber.js';

import { DEFAULT_MULTIPLIER, MAX_BORROW_PERCENTAGE } from '../config';

import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

const useCreditBalance = ({
  symbol,
  creditBasedCurrencyValueInUsd,
  multiplier = new BigNumberJs(DEFAULT_MULTIPLIER)
}: {
  symbol?: string;
  creditBasedCurrencyValueInUsd: BigNumberJs;
  multiplier?: BigNumberJs;
}) => {
  const {
    overviewUserInfo: { borrowLimitInUsd, totalBorrowedPositionInUsd },
    nftInfoMap
  } = useMMProvider();

  const { priceInUsd, baseLTVasCollateral, reserveLiquidationThreshold } =
    nftInfoMap[symbol!] || {};

  const {
    borrowLimitInUsd: newBorrowLimitInUsd,
    liquidationThresholdInUsd: newLiquidationThresholdInUsd,
    totalCollateralPositionInUsd: newTotalCollateralPositionInUsd
  } = useLendingSimulation([
    {
      type: ActionTypeEnum.SUPPLY,
      targetAsset: {
        id: `${symbol}`,
        value: priceInUsd?.times(multiplier),
        LTV: baseLTVasCollateral,
        reserveLiquidationThreshold
      }
    }
  ]);

  const curAvailableCredit = BigNumberJs.max(
    borrowLimitInUsd.minus(totalBorrowedPositionInUsd).div(creditBasedCurrencyValueInUsd),
    new BigNumberJs(0)
  );

  const futureAvailableCredit = BigNumberJs.max(
    newBorrowLimitInUsd
      .minus(totalBorrowedPositionInUsd)
      .div(creditBasedCurrencyValueInUsd)
      .times(MAX_BORROW_PERCENTAGE),
    new BigNumberJs(0)
  );

  return {
    curAvailableCredit,
    curborrowLimitInUsd: borrowLimitInUsd,
    futureAvailableCredit,
    futureBorrowLimitInUsd: newBorrowLimitInUsd,
    futureLiquidationThreshold: newLiquidationThresholdInUsd,
    futureTotalCollateralPositionInUsd: newTotalCollateralPositionInUsd
  };
};

export default useCreditBalance;
