import { useState, useCallback, useMemo } from 'react';
import {
  TokenOption,
  Value
} from '@parallel-mono/business-components/components/SelectableTokenInput/types';
import BigNumber from 'bignumber.js';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { zero } from '@/apps/paraspace/consts/values';

const decimals = 4;

export const useAssociatedPays = ({
  defaultToken,
  futureAvailableCredit,
  totalPriceInEth
}: {
  defaultToken: TokenOption;
  futureAvailableCredit: BigNumber;
  totalPriceInEth: BigNumber;
}) => {
  const { erc20InfoMap } = useMMProvider();

  const getMaxPayNowTokenAmount = useCallback(
    (token: ERC20Symbol) => {
      const { balance = zero, priceInETH = zero } = erc20InfoMap[token];
      return BigNumber.min(totalPriceInEth.div(priceInETH), balance);
    },
    [erc20InfoMap, totalPriceInEth]
  );

  const getMaxPayLaterTokenAmount = useCallback(
    (token: ERC20Symbol) => {
      const { priceInETH = zero } = erc20InfoMap[token];
      return BigNumber.min(futureAvailableCredit.div(priceInETH), totalPriceInEth.div(priceInETH));
    },
    [erc20InfoMap, futureAvailableCredit, totalPriceInEth]
  );

  const defaultPayLaterTokenAmount = getMaxPayLaterTokenAmount(ERC20Symbol.ETH)
    .decimalPlaces(decimals)
    .toNumber();
  const [payLater, setPayLater] = useState<Value<TokenOption>>({
    token: defaultToken,
    amount: defaultPayLaterTokenAmount
  });

  const calculateRestPaymenyAmount = useCallback(
    (currency: ERC20Symbol, presentedPay: Value<TokenOption>, maxAmount: BigNumber) => {
      const { amount: presentedPayAmount } = presentedPay;
      const { symbol: presentedPaySymbol } = presentedPay.token!;
      const presentedPayTokenPriceInEth = erc20InfoMap[presentedPaySymbol].priceInETH;
      const paymentTokenPriceInEth = erc20InfoMap[currency].priceInETH;

      return BigNumber.min(
        maxAmount,
        totalPriceInEth
          .minus(presentedPayTokenPriceInEth.times(presentedPayAmount ?? 0))
          .div(paymentTokenPriceInEth)
      )
        .decimalPlaces(decimals)
        .toNumber();
    },
    [erc20InfoMap, totalPriceInEth]
  );

  const [payNow, setPayNow] = useState<Value<TokenOption>>({
    token: defaultToken,
    amount: calculateRestPaymenyAmount(
      ERC20Symbol.ETH,
      payLater,
      getMaxPayNowTokenAmount(ERC20Symbol.ETH)
    )
  });

  const maxPayLaterTokenAmount = useMemo(
    () => getMaxPayLaterTokenAmount(payLater.token!.symbol as ERC20Symbol).decimalPlaces(decimals),
    [getMaxPayLaterTokenAmount, payLater.token]
  );

  const maxPayNowTokenAmount = useMemo(
    () => getMaxPayNowTokenAmount(payNow.token!.symbol as ERC20Symbol).decimalPlaces(decimals),
    [getMaxPayNowTokenAmount, payNow.token]
  );

  const handlePayLaterChange = useCallback(
    (value: Value<TokenOption>) => {
      setPayLater(value);

      setPayNow(currPayNow => ({
        token: currPayNow.token,
        amount: calculateRestPaymenyAmount(
          currPayNow.token!.symbol as ERC20Symbol,
          value,
          maxPayNowTokenAmount
        )
      }));
    },
    [calculateRestPaymenyAmount, maxPayNowTokenAmount]
  );

  const handlePayNowChange = useCallback(
    (value: Value<TokenOption>) => {
      setPayNow(value);
      setPayLater(currPayLater => ({
        token: currPayLater.token,
        amount: calculateRestPaymenyAmount(
          currPayLater.token!.symbol as ERC20Symbol,
          value,
          maxPayLaterTokenAmount
        )
      }));
    },
    [calculateRestPaymenyAmount, maxPayLaterTokenAmount]
  );

  const handleMaximizePayLater = useCallback(() => {
    const payLaterToBe = {
      token: payLater.token,
      amount: maxPayLaterTokenAmount.toNumber()
    };
    setPayLater(payLaterToBe);
    setPayNow(currPayNow => ({
      token: currPayNow.token,
      amount: calculateRestPaymenyAmount(
        currPayNow.token!.symbol as ERC20Symbol,
        payLaterToBe,
        maxPayNowTokenAmount
      )
    }));
  }, [maxPayLaterTokenAmount, payLater.token, calculateRestPaymenyAmount, maxPayNowTokenAmount]);

  const handleMaximizePayNow = useCallback(() => {
    const payNowToBe = {
      token: payNow.token,
      amount: maxPayNowTokenAmount.toNumber()
    };
    setPayNow(payNowToBe);
    setPayLater(currPayLater => ({
      token: currPayLater.token,
      amount: calculateRestPaymenyAmount(
        currPayLater.token!.symbol as ERC20Symbol,
        payNowToBe,
        maxPayLaterTokenAmount
      )
    }));
  }, [maxPayNowTokenAmount, payNow.token, calculateRestPaymenyAmount, maxPayLaterTokenAmount]);

  return {
    handlePayLaterChange,
    handlePayNowChange,
    handleMaximizePayLater,
    handleMaximizePayNow,
    maxPayNowTokenAmount,
    maxPayLaterTokenAmount,
    payNow,
    payLater
  };
};
