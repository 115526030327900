import { Modal, ModalProps, Stack } from '@parallel-mono/components';
import { memo, FC, useState, useCallback } from 'react';

import { MintSewerPassSubmitter } from './MintSewerPassSubmitter';

import type { ApeListItem } from '@/apps/paraspace/pages/ApePairing/contexts';
import { ErrorState, SuccessState } from '@/apps/paraspace/components';

export type MintStepperProps = Omit<ModalProps, 'children'> & {
  onMint: Function;
  mintTokens: ApeListItem[];
  onClose: () => void;
};

enum Phase {
  Idle,
  Submitting,
  Success,
  Failed
}

export const MintContent: FC<Omit<MintStepperProps, 'isOpen'>> = memo(({ onClose, ...props }) => {
  const [phase, setPhase] = useState<Phase>(Phase.Submitting);
  const [mintTokens] = useState(props.mintTokens);

  const handleMintSucceed = useCallback(() => {
    setPhase(Phase.Success);
  }, []);
  const handleMintFailed = useCallback(() => {
    setPhase(Phase.Failed);
  }, []);

  return (
    <Stack>
      {phase === Phase.Submitting && (
        <MintSewerPassSubmitter
          formData={{ mintTokens }}
          onMint={props.onMint}
          onSuccess={handleMintSucceed}
          onFailed={handleMintFailed}
        />
      )}
      {phase === Phase.Success && (
        <SuccessState
          actionButtonText="Done"
          desc="Mint Sewer Pass Successfully"
          onAction={onClose}
        />
      )}
      {phase === Phase.Failed && <ErrorState closeModal={onClose} />}
    </Stack>
  );
});

export const MintStepper: FC<MintStepperProps> = memo(({ isOpen, onClose, ...others }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <MintContent {...others} onClose={onClose} />
    </Modal>
  );
});
