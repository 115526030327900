import {
  ApeListingOrderStatus,
  P2PListing,
  P2PPairStakingService
} from 'paraspace-utilities-contract-helpers';
import { useCallback, useMemo } from 'react';
import { ethers, Signature } from 'ethers';
import BigNumber from 'bignumber.js';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import submitTransaction from '@/apps/paraspace/utils/submitTransaction';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useContractsMap } from '@/apps/paraspace/hooks';

export { StakingType } from 'paraspace-utilities-contract-helpers';
export type { P2PListing } from 'paraspace-utilities-contract-helpers';

export type CreateListingReturnType = Signature & P2PListing;

type P2PListingInfo = P2PListing & {
  v: number;
  s: string;
  r: string;
};

const useP2PPairStaking = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new P2PPairStakingService(provider, contracts.P2PPairStaking);
  }, [contracts.P2PPairStaking, provider]);

  const createListing = useCallback(
    async (listing: P2PListing): Promise<Maybe<CreateListingReturnType>> => {
      if (!service) {
        return null;
      }

      const signer = provider.getSigner(account);
      const result = await service.createListing(signer, listing);

      return result;
    },
    [account, provider, service]
  );

  const cancelListing = useCallback(
    async (listing: P2PListingInfo) => {
      if (!service) {
        return null;
      }
      const tx = service.cancelListing(listing, account);
      return submitTransaction({ provider, tx });
    },
    [account, service, provider]
  );

  const unmatchListing = useCallback(
    async (orderHash: string) => {
      if (!service) {
        return null;
      }
      const tx = service.breakUpMatchedOrder(orderHash, account);
      return submitTransaction({ provider, tx });
    },
    [account, service, provider]
  );

  const getPendingCApeReward = useCallback(async (): Promise<BigNumber | null> => {
    if (!service) {
      return null;
    }

    const result = await service.pendingCApeReward(account);
    return result;
  }, [account, service]);

  const claimCApeReward =
    useCallback(async (): Promise<ethers.providers.TransactionResponse | null> => {
      if (!service) {
        return null;
      }

      const tx = service.claimCApeReward(account);
      return submitTransaction({ provider, tx });
    }, [account, provider, service]);

  const matchPairStakingList = useCallback(
    async (apeListing: P2PListingInfo, apeCoinListing: P2PListingInfo) => {
      if (!service) {
        return null;
      }
      const tx = service.matchPairStakingList(apeListing, apeCoinListing, account);

      return submitTransaction({ provider, tx });
    },
    [account, service, provider]
  );

  const matchBakcPairStakingList = useCallback(
    async (
      apeListing: P2PListingInfo,
      bakcListing: P2PListingInfo,
      apeCoinListing: P2PListingInfo
    ) => {
      if (!service) {
        return null;
      }
      const tx = service.matchBAKCPairStakingList(apeListing, bakcListing, apeCoinListing, account);

      return submitTransaction({ provider, tx });
    },
    [account, service, provider]
  );

  const isListingPending = useCallback(
    async (listingHash: string): Promise<boolean> => {
      if (!service) {
        return false;
      }

      const result = await service.instance.listingOrderStatus(listingHash);
      return result === ApeListingOrderStatus.PENDING;
    },
    [service]
  );

  return {
    createListing,
    cancelListing,
    getPendingCApeReward,
    claimCApeReward,
    matchPairStakingList,
    unmatchListing,
    matchBakcPairStakingList,
    isListingPending
  };
};

export default useP2PPairStaking;
