import { H3, H5, Inline, Responsive, SmallText, useBreakpoints } from '@parallel-mono/components';
import BigNumberJs from 'bignumber.js';
import { FC, memo } from 'react';
import { formatNumber } from '@parallel-mono/utils';

import { ValuePill } from '@/apps/paraspace/components';

type TimelockQueueHeaderProps = {
  locked: BigNumberJs;
  released: BigNumberJs;
};

const TimelockQueueHeader: FC<TimelockQueueHeaderProps> = memo(({ locked, released }) => {
  const { mobile } = useBreakpoints();
  return (
    <Responsive
      breakPoint="tablet"
      width="100%"
      gap={mobile ? '1rem' : '0'}
      justifyContent={mobile ? 'flex-start' : 'space-between'}
      alignItems={mobile ? 'flex-start' : 'center'}
    >
      <H3 fontWeight="bold">Timelock Queue</H3>
      <Inline gap="1rem" width={mobile ? '100%' : 'auto'}>
        <ValuePill gap="0.5rem">
          <SmallText skin="secondary">Locked</SmallText>
          <H5>{formatNumber(locked)}</H5>
        </ValuePill>
        <ValuePill gap="0.5rem">
          <SmallText>Released</SmallText>
          <H5>{formatNumber(released)}</H5>
        </ValuePill>
      </Inline>
    </Responsive>
  );
});

export default TimelockQueueHeader;
