import { useEffect, useState } from 'react';

import {
  SYMBOL_TO_STAKING_INFO_MAP_KEY,
  StakingInfoMap,
  useApeStaking
} from '@/apps/paraspace/pages/hooks/ApeStaking/useApeStaking';
import { ApeStakingTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';

export const useApeStakingPosition = ({
  showApeStakingInfo,
  symbol
}: {
  showApeStakingInfo: boolean;
  symbol: ERC721Symbol;
}) => {
  const { getStakingInfoByUserAddress } = useApeStaking();
  const [stakingInfoMap, setStakingInfoMap] = useState<StakingInfoMap>({});
  useEffect(() => {
    const loadStakingInfo = async () => {
      const res = await getStakingInfoByUserAddress();

      if (res) {
        setStakingInfoMap(
          res[SYMBOL_TO_STAKING_INFO_MAP_KEY[symbol as ApeStakingTokenSymbol] as keyof typeof res]
        );
      }
    };
    if (showApeStakingInfo) {
      loadStakingInfo();
    }
  }, [getStakingInfoByUserAddress, showApeStakingInfo, symbol]);

  return { stakingInfoMap };
};
