import { memo, useMemo, useState, useCallback } from 'react';
import BigNumber from 'bignumber.js';

import { StakeBakcFormData } from '../StakeModal';
import { useOfficialApeStaking } from '../../../hook';
import { OFFICIAL_STAKE_CONFIG } from '../../../config';

import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';
import { useAppConfig, useERC20 } from '@/apps/paraspace/hooks';
import {
  DepositERC20Submitter,
  DepositERC721Submitter,
  Stepper
} from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type BakcStakeFormSubmitterProps = {
  formData: StakeBakcFormData;
  onError: () => void;
  onFinish: () => void;
};

export const PairApeFormSubmitter = memo(
  ({ formData, onError, onFinish }: BakcStakeFormSubmitterProps) => {
    const { env } = useWeb3Context();
    const { createApproval } = useERC20(OFFICIAL_STAKE_CONFIG[env].APE_COIN_ADDR);
    const { nftInfoMap } = useMMProvider();
    const { genStakingBakcTx } = useOfficialApeStaking();
    const { contracts } = useAppConfig();
    const {
      amountFromBalance,
      symbol,
      tokenId,
      bakcTokenId,
      apeSource,
      apeCoinSource,
      bakcSource
    } = formData;

    const batchTxs = useMemo(() => {
      return [
        {
          tx: createApproval({
            spender: OFFICIAL_STAKE_CONFIG[env].APE_STAKING_ADDR,
            amount: amountFromBalance
          })
        },
        {
          tx: genStakingBakcTx([formData])
        }
      ];
    }, [createApproval, genStakingBakcTx, formData, amountFromBalance, env]);

    const [step, setStep] = useState(0);
    const handleNext = useCallback(() => {
      setStep(curr => curr + 1);
    }, []);

    const apeAddress = nftInfoMap[symbol]?.address ?? '';
    const bakcAddress = nftInfoMap[ERC721Symbol.BAKC]?.address ?? '';
    const steps = useMemo(
      () => [
        {
          description: `Deposit ${symbol} #${tokenId} to your Parallel account`,
          content: (
            <DepositERC721Submitter
              formData={{
                tokenId,
                address: apeAddress
              }}
              onError={onError}
              onFinish={handleNext}
            />
          ),
          skip: apeSource !== 'EOA'
        },
        {
          description: `Deposit BAKC #${bakcTokenId} to your Parallel account`,
          content: (
            <DepositERC721Submitter
              formData={{
                tokenId: bakcTokenId,
                address: bakcAddress
              }}
              onError={onError}
              onFinish={handleNext}
            />
          ),
          skip: bakcSource !== 'EOA'
        },
        {
          description: `Deposit ${formatBalance(amountFromBalance)} APE to your Parallel account `,
          content: (
            <DepositERC20Submitter
              formData={{
                address: contracts.APE,
                symbol: ERC20Symbol.APE,
                value: new BigNumber(amountFromBalance)
              }}
              onError={onError}
              onFinish={handleNext}
            />
          ),
          skip: apeCoinSource !== 'EOA'
        },
        {
          description: 'Staking',
          content: (
            <BatchTransactionsSubmitter batchTxs={batchTxs} onError={onError} onFinish={onFinish} />
          )
        }
      ],
      [
        symbol,
        tokenId,
        apeAddress,
        apeSource,
        bakcTokenId,
        bakcAddress,
        bakcSource,
        contracts,
        amountFromBalance,
        onError,
        handleNext,
        apeCoinSource,
        batchTxs,
        onFinish
      ]
    );

    return <Stepper steps={steps} step={step} />;
  }
);
