import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Alert, Button, H4, H5, Inline, SmallText, Stack, Tag } from '@parallel-mono/components';
import { CryptoIcon, InfoPanel } from '@parallel-mono/business-components';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { NumberRange, NFTThumbnail, BorrowLimitBar } from '@/apps/paraspace/components';
import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';
import { useParallelToast } from '@/apps/paraspace/contexts';
import usePool from '@/apps/paraspace/pages/hooks/usePool';
import {
  DEFAULT_MULTIPLIER,
  HEALTHY_FACTOR_WITH_CUSHION_FOR_WITHDRAW
} from '@/apps/paraspace/pages/config';
import { emptyArray } from '@/apps/paraspace/consts/values';
import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { ERC721Symbol } from '@/apps/paraspace/typings';

const BorrowedTip = styled(Inline)`
  display: flex;
  align-items: center;
`;

const BorrowedLimitContainer = styled(Stack)`
  padding: 0.5rem 0.75rem 1rem;
  border: 1px solid #eeeeee;
  border-radius: 0.5rem;
`;

interface options {
  data: { id: number; symbol: string; isNft: boolean };
  closeModal: () => void;
}

const CollateralModal = ({ data: { id, symbol, isNft }, closeModal }: options) => {
  const {
    overviewUserInfo: {
      borrowLimitInUsd,
      liquidationThresholdInUsd,
      totalBorrowedPositionInUsd,
      totalCollateralPositionInUsd
    },
    nftInfoMap,
    erc20InfoMap,
    load
  } = useMMProvider();
  const toast = useParallelToast();
  const nftToken = nftInfoMap[symbol];
  const erc20Token = erc20InfoMap[symbol];
  const {
    priceInUsd,
    baseLTVasCollateral,
    reserveLiquidationThreshold,
    address,
    hasTokenSpecificInfos,
    tokenSpecificInfos,
    tokenTraitMultipliers,
    auctionedTokens = emptyArray
  } = {
    ...nftToken,
    ...erc20Token
  };

  const isAuctionedToken = useMemo(() => {
    return auctionedTokens.includes(id);
  }, [auctionedTokens, id]);
  const isCollateral = isNft
    ? (nftToken.nftCollateralList || []).includes(id)
    : erc20Token.usageAsCollateralEnabledOnUser;
  const { setERC721Collateral, setERC20Collateral } = usePool();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const nftValue = hasTokenSpecificInfos
    ? tokenSpecificInfos[id].priceInUsd
    : priceInUsd.times(tokenTraitMultipliers?.[id] || DEFAULT_MULTIPLIER);

  const erc20Value = priceInUsd.times(erc20Token?.suppliedAmount || 0);

  const {
    borrowLimitInUsd: newBorrowLimitInUsd,
    liquidationThresholdInUsd: newLiquidationThresholdInUsd,
    totalCollateralPositionInUsd: newTotalCollateralPositionInUsd
  } = useLendingSimulation([
    {
      type: isCollateral ? ActionTypeEnum.WITHDRAW : ActionTypeEnum.SUPPLY,
      targetAsset: {
        id: `${symbol}`,
        value: isNft ? nftValue : erc20Value,
        LTV: hasTokenSpecificInfos
          ? tokenSpecificInfos[id].baseLTVasCollateral
          : baseLTVasCollateral,
        reserveLiquidationThreshold: hasTokenSpecificInfos
          ? tokenSpecificInfos[id].reserveLiquidationThreshold
          : reserveLiquidationThreshold
      }
    }
  ]);

  const eligibleToUnCollateral =
    (isCollateral &&
      newLiquidationThresholdInUsd.gte(
        totalBorrowedPositionInUsd.times(HEALTHY_FACTOR_WITH_CUSHION_FOR_WITHDRAW)
      )) ||
    !isCollateral;
  const handleConfirm = useCallback(async () => {
    setConfirmLoading(true);
    toast.promise(
      (isNft
        ? setERC721Collateral(address, [id], !isCollateral)
        : setERC20Collateral(address, !isCollateral)
      )
        .then(async tx => {
          closeModal();
          await tx?.wait();
          load();
        })
        .catch(err => {
          setConfirmLoading(false);
          throw getUserFriendlyError(err);
        })
    );
  }, [
    toast,
    isNft,
    setERC721Collateral,
    address,
    id,
    isCollateral,
    setERC20Collateral,
    closeModal,
    load
  ]);

  return (
    <Stack gap="1.5rem">
      <Stack gap="0.5rem" alignItems="center">
        {isNft ? (
          <>
            <NFTThumbnail
              size="xLarge"
              symbol={symbol as ERC721Symbol}
              tokenId={id}
              floatingTag={
                tokenTraitMultipliers?.[id].gt(DEFAULT_MULTIPLIER) && (
                  <Tag skin="success" size="small">
                    {tokenTraitMultipliers?.[id].toString()}x Boost
                  </Tag>
                )
              }
            />
            <H4>
              {nftToken.collectionName} #{id}
            </H4>
          </>
        ) : (
          <>
            <CryptoIcon symbol={symbol} size="large" />
            <H4>
              {erc20Token.suppliedAmount
                ? `${formatBalance(erc20Token.suppliedAmount)} ${erc20Token.displayName}`
                : '-'}
            </H4>
          </>
        )}
      </Stack>
      {totalBorrowedPositionInUsd.gt(0) && (
        <BorrowedLimitContainer gap="0.5rem">
          <BorrowedTip gap="0.25rem">
            <SmallText skin="secondary">Borrowed:</SmallText>
            <H5>{formatToCurrency(totalBorrowedPositionInUsd)}</H5>
          </BorrowedTip>
          <BorrowLimitBar
            borrowedValue={totalBorrowedPositionInUsd}
            borrowLimitValue={eligibleToUnCollateral ? newBorrowLimitInUsd : borrowLimitInUsd}
            liquidationPointValue={
              eligibleToUnCollateral ? newLiquidationThresholdInUsd : liquidationThresholdInUsd
            }
            totalSuppliedValue={
              eligibleToUnCollateral
                ? newTotalCollateralPositionInUsd
                : totalCollateralPositionInUsd
            }
          />
        </BorrowedLimitContainer>
      )}
      <InfoPanel
        skin="primary"
        infos={[
          {
            title: 'New Borrow Limit',
            value: (
              <NumberRange
                start={borrowLimitInUsd.toNumber()}
                end={(eligibleToUnCollateral ? newBorrowLimitInUsd : borrowLimitInUsd).toNumber()}
                formatter={formatToCurrency}
              />
            )
          }
        ]}
      />
      {isAuctionedToken && (
        <Alert type="error">
          You cannot remove this NFT as collateral because it's in liquidation auction.You can close
          liquidation auction.
        </Alert>
      )}
      {!eligibleToUnCollateral && !isAuctionedToken && (
        <Alert type="error">
          You cannot remove this collateral now because of your borrow balance. Repay your debt
          first to proceed.
        </Alert>
      )}
      <Button
        skin="primary"
        size="large"
        block
        loading={confirmLoading}
        disabled={!eligibleToUnCollateral || confirmLoading || isAuctionedToken}
        onClick={handleConfirm}
      >
        {(() => {
          if (!eligibleToUnCollateral || isAuctionedToken) {
            return 'Cannot Remove from Collateral';
          }
          if (confirmLoading) {
            return 'Loading';
          }
          return 'Confirm';
        })()}
      </Button>
    </Stack>
  );
};

export default CollateralModal;
