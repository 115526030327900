import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { throttle, uniqueId } from 'lodash';
import BigNumber from 'bignumber.js';
import { utils } from 'ethers';
import { StaticJsonRpcProvider } from '@ethersproject/providers';

import { BridgeNetworks, SupportedChainId } from '../configs';

import { useBridge } from './useBridge';

import { useEOAProvider } from '@/apps/parax/contexts';
import { Maybe } from '@/apps/parax/typings/basic';

export const useNetworkFee = (
  {
    symbol,
    amount,
    eid,
    to
  }: {
    symbol: string;
    amount: Maybe<number>;
    eid: number;
    to?: string;
  },
  provider: Maybe<StaticJsonRpcProvider>,
  chainId: Maybe<SupportedChainId>
) => {
  const [networkFee, setNetworkFee] = useState<Maybe<BigNumber>>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { account } = useEOAProvider();
  const bridge = useBridge(symbol, eid, provider, chainId);

  const latestTaskIdRef = useRef<string>();

  const nativeToken = useMemo(
    () => (chainId === null ? null : BridgeNetworks[chainId]?.nativeCurrency ?? null),
    [chainId]
  );

  const fetchNetworkFee = useCallback(async () => {
    try {
      if (amount === null || nativeToken === null || provider === null || chainId === null) {
        setNetworkFee(null);
        return;
      }
      setIsLoading(true);
      if (nativeToken.symbol === symbol) {
        const gasPrice = await provider.getGasPrice();
        const NATIVE_TOKEN_BRIDGE_GAS = 1e5;
        setNetworkFee(
          new BigNumber(gasPrice.mul(NATIVE_TOKEN_BRIDGE_GAS).toString()).shiftedBy(
            -nativeToken.decimals
          )
        );
        return;
      }

      const taskId = uniqueId();
      latestTaskIdRef.current = taskId;

      if (!bridge) {
        throw new Error(`No Bridge for ${symbol}`);
      }

      const encoder = new utils.AbiCoder();
      const address = encoder.encode(['address'], [to ?? account]);

      const { nativeFee } = await bridge.quoteSend({
        to: address,
        amount: BigNumber(amount ?? 0)
      });

      if (taskId === latestTaskIdRef.current) {
        setNetworkFee(new BigNumber(nativeFee.toString()));
      }
    } catch (e) {
      console.error('Fetch network fee failed. error: ', e);
    } finally {
      setIsLoading(false);
    }
  }, [amount, nativeToken, symbol, bridge, to, account, provider, chainId]);

  const throttledFetchNetworkFee = useMemo(() => throttle(fetchNetworkFee, 500), [fetchNetworkFee]);

  useEffect(() => {
    throttledFetchNetworkFee();
  }, [throttledFetchNetworkFee]);

  return {
    isLoading,
    networkFee
  };
};
