import { Modal, ModalProps } from '@parallel-mono/components';
import { memo } from 'react';

import { ApeStakeForm } from './ApeStakeForm';

import { WalletType } from '@/apps/paraspace/typings';

export type AutoCompoundApeProps = {
  apeCoinSource: WalletType;
  amount: string | null;
  enableSupplyCApe: boolean;
  onClose: () => void;
  onSuccess?: () => void;
} & Omit<ModalProps, 'children' | 'title' | 'id'>;

const AutoCompoundApeModal = memo((props: AutoCompoundApeProps) => {
  const { amount, enableSupplyCApe, onClose, onSuccess, apeCoinSource, ...others } = props;

  return (
    <Modal size="32.5rem" title="Stake" onClose={onClose} {...others}>
      {amount !== null && (
        <ApeStakeForm
          apeCoinSource={apeCoinSource}
          amount={amount}
          enableSupplyCApe={enableSupplyCApe}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
    </Modal>
  );
});

export default AutoCompoundApeModal;
