import { ItemType } from 'paraspace-seaport-js/lib/constants';
import { CreateOrderInput } from 'paraspace-seaport-js/lib/types';
import { BigNumber } from 'ethers';

import { CollectionFees } from '@/apps/paraspace/utils/calculateFees';
import { Order, OrderTarget } from '@/apps/paraspace/generated/graphql';
import { FLOAT_SCALING_FACTOR } from '@/apps/paraspace/utils/format';

export const useCreateSeaportAcceptOrderInput = (
  account: string,
  conduitKeyAddress: string,
  {
    orderData,
    feePoints,
    identifierOrCriteria
  }: { orderData: Order; feePoints?: CollectionFees; identifierOrCriteria: string }
) => {
  const { protocolData } = orderData;
  const { parameters } = protocolData!;
  const NftConsiderationRow = parameters!.consideration?.[0];
  const offer = parameters!.offer![0];
  const totalFeePoint = (feePoints?.paraSpaceFeePoint ?? 0) + (feePoints?.creatorFeePoint ?? 0);
  const sellerActualReceivedAmount = BigNumber.from(offer?.startAmount)
    .div(FLOAT_SCALING_FACTOR)
    .mul(FLOAT_SCALING_FACTOR - totalFeePoint)
    .toString();

  const createdAcceptOrderInput = {
    startTime: `${parameters!.startTime}`,
    endTime: `${parameters!.endTime}`,
    conduitKey: conduitKeyAddress,
    restrictedByZone: false,
    offerer: account,
    offer: [
      {
        itemType: ItemType.ERC721, // We only need to support the ERC721 order accept, so hardcode it to ERC721 for now.
        token: NftConsiderationRow?.token,
        identifier:
          orderData.target === OrderTarget.Collection
            ? identifierOrCriteria
            : NftConsiderationRow?.identifierOrCriteria
      }
    ],
    consideration: [
      {
        itemType: ItemType.ERC20, // Hardcode it to ERC20
        token: offer?.token,
        identifier: offer?.identifierOrCriteria,
        amount: sellerActualReceivedAmount,
        endAmount: sellerActualReceivedAmount,
        recipient: account
      }
    ]
  } as unknown as CreateOrderInput;

  return [createdAcceptOrderInput];
};
