import { CryptoIcon } from '@parallel-mono/business-components';
import {
  Button,
  Card,
  CardProps,
  Typography,
  Inline,
  useBreakpoints,
  TypographyVariant
} from '@parallel-mono/components';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';

import { useMMProvider } from '../../contexts/MMProvider';
import { useApeListStatesAndActions } from '../../ApePairing';
import { STAKE_LIMIT } from '../../ApePairing/consts';
import { useV1TimelockApeInfo } from '../../ApePairing/contexts';

import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';

type ReStakeBannerProps = Omit<CardProps, 'children'> & {};

const MIN_CAPE = 1;

const Container = styled(Inline)`
  gap: 1.5rem;
  flex-wrap: wrap;
`;

export const ReStakeBanner = memo((props: ReStakeBannerProps) => {
  const { erc20InfoMap } = useMMProvider();
  const { apesInSuppliedExcludingInP2P } = useApeListStatesAndActions();
  const { isLoading, timelockInfos } = useV1TimelockApeInfo();

  const canStakeApes = apesInSuppliedExcludingInP2P
    .map(it => ({
      ...it,
      stakeLimit: new BigNumber(STAKE_LIMIT[it.symbol])
    }))
    .filter(it => {
      return (
        it.stakeLimit.gt(it.stakedAmount ?? 0) &&
        !timelockInfos.some(
          timelockInfo => timelockInfo.symbol === it.symbol && timelockInfo.tokenId === it.tokenId
        )
      );
    });
  const cApeSupply = erc20InfoMap[ERC20Symbol.CAPE]?.suppliedAmount ?? zero;

  const noApesToReStake = canStakeApes.length === 0 && timelockInfos.length === 0;

  const navigate = useNavigate();

  const handleReStake = useCallback(() => {
    navigate(absoluteRouteNames.APE_STAKING.NFT_POOLS);
  }, [navigate]);

  const { mobile } = useBreakpoints();

  if (cApeSupply.lt(MIN_CAPE) || noApesToReStake || isLoading) {
    return null;
  }

  return (
    <Card border {...props}>
      <Container gap="0" alignItems="center" justifyContent={mobile ? 'center' : 'space-between'}>
        <Inline alignItems="center">
          <CryptoIcon symbol={ERC721Symbol.BAYC} size="3rem" />
          <Typography variant={mobile ? TypographyVariant.header6 : TypographyVariant.header4}>
            After migrating, your NFTs will temporarily be locked in ParaSpace V1 Timelock.
            <br />
            After unlocking, you can re-stake them with one click.
          </Typography>
        </Inline>

        <Button block={mobile} onClick={handleReStake} skin="primary">
          Re-stake
        </Button>
      </Container>
    </Card>
  );
});
