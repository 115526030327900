import { useCallback, useMemo } from 'react';
import { BenddaoUiProviderService } from 'paraspace-utilities-contract-helpers';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

export const useBendDaoService = () => {
  const {
    provider,
    authentication: {
      meta: { account }
    },
    chainId
  } = useWeb3Context();

  const contracts = useContractsMap();

  const benddaoService = useMemo(() => {
    if (!provider || !contracts.BenddaoUiDataProvider || !contracts.BenddaoPoolAddressProvider) {
      return null;
    }
    return new BenddaoUiProviderService(
      provider,
      contracts.BenddaoUiDataProvider,
      contracts.BenddaoPoolAddressProvider,
      contracts.UiPoolDataProvider,
      contracts.PoolAddressesProvider,
      chainId
    );
  }, [
    provider,
    chainId,
    contracts.BenddaoPoolAddressProvider,
    contracts.BenddaoUiDataProvider,
    contracts.PoolAddressesProvider,
    contracts.UiPoolDataProvider
  ]);

  const getUserReserveInfo = useCallback(
    (nTokenMap: Record<string, string>) => {
      if (!benddaoService || !account)
        return {} as Awaited<ReturnType<BenddaoUiProviderService['getUsersBenddaoReserveInfo']>>;

      return benddaoService.getUsersBenddaoReserveInfo(account, nTokenMap);
    },
    [benddaoService, account]
  );

  return {
    getUserReserveInfo
  };
};
