import BigNumber from 'bignumber.js';
import { memo, useCallback, useMemo, useState } from 'react';

import { useERC20BySymbol, useNativeCurrency } from '../../hooks';
import { parallelChain } from '../../configs';

import { BridgeERC20Submitter } from './BridgeERC20Submitter';
import { BridgeNativeTokenSubmitter } from './BridgeNativeTokenSubmitter';

import { formatBalance } from '@/apps/parax/utils';
import { ApproveERC20Submitter, Step, Stepper, StepperProps } from '@/apps/parax/components';

type BridgeSubmitterStepperProps = Omit<StepperProps, 'steps' | 'step'> & {
  formData: {
    symbol: string;
    amount: BigNumber;
    toChainEid: number;
    receiver: string;
    l1GasFeeInWei?: BigNumber;
    l2GasFeeInWei?: BigNumber;
  };
  onFinish: () => void;
  onError: () => void;
};

export const BridgeSubmitterStepper = memo(
  ({ formData, onFinish, onError }: BridgeSubmitterStepperProps) => {
    const { symbol, amount, receiver, toChainEid, l1GasFeeInWei, l2GasFeeInWei } = formData;
    const erc20 = useERC20BySymbol(symbol);
    const nativeCurrency = useNativeCurrency();
    const [step, setStep] = useState(0);
    const handleNextPage = useCallback(() => {
      setStep(curr => curr + 1);
    }, []);
    const steps: Step[] = useMemo(() => {
      const isDeposit = toChainEid === parallelChain.eId;
      const skipApproving = symbol === nativeCurrency?.symbol || !isDeposit;
      const actionText = isDeposit ? 'Bridge' : 'Withdraw';
      return [
        {
          description: `Approve ${symbol}`,
          content: erc20 ? (
            <ApproveERC20Submitter
              formData={{
                assetAddress: erc20.address,
                name: erc20.symbol,
                spender: erc20.parallelBridgeAddress!,
                amount
              }}
              onFinish={handleNextPage}
              onError={onError}
            />
          ) : null,
          skip: skipApproving
        },
        {
          description: `${actionText} ${formatBalance(amount)} ${symbol}`,
          content: (
            <BridgeERC20Submitter
              formData={{
                toChainEid,
                symbol,
                amount,
                to: receiver
              }}
              onFinish={onFinish}
              onError={onError}
            />
          ),
          skip: symbol === nativeCurrency.symbol
        },
        {
          description: `${actionText} ${formatBalance(amount)} ${nativeCurrency.name}`,
          content: (
            <BridgeNativeTokenSubmitter
              formData={{
                amount,
                to: receiver,
                l1GasFeeInWei: l1GasFeeInWei!,
                l2GasFeeInWei: l2GasFeeInWei!
              }}
              onFinish={onFinish}
              onError={onError}
            />
          ),
          skip: symbol !== nativeCurrency.symbol
        }
      ];
    }, [
      symbol,
      erc20,
      amount,
      handleNextPage,
      onError,
      nativeCurrency.symbol,
      nativeCurrency.name,
      toChainEid,
      receiver,
      onFinish,
      l1GasFeeInWei,
      l2GasFeeInWei
    ]);

    return <Stepper steps={steps} step={step} />;
  }
);
