import { memo, useCallback, useEffect, useState } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';
import ReactGA from 'react-ga4';

import { StakeBakcFormData } from '../types';
import { useApeListStatesAndActions } from '../../ApeListProvider';

import { BakcStakeForm } from './BakcStakeForm';
import { BakcStakeStepper } from './BakcStakeStepper';
import { SuccessState, SuccessStateProps } from './SuccessState';

import { ErrorState } from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { WalletType } from '@/apps/paraspace/typings';

export type BakcStakeModalProps = {
  bakcTokenId: number;
  apeCoinSource: WalletType;
  bakcSource: Maybe<WalletType>;
  onClose: () => void;
  onFinish: () => void;
  onError: () => void;
} & Omit<ModalProps, 'children' | 'onClose'>;

enum Phase {
  Inputting,
  Submitting,
  Success,
  Failed
}

export const BakcStakeModal = memo(
  ({
    bakcTokenId,
    onFinish,
    onError,
    onClose,
    isOpen,
    apeCoinSource,
    bakcSource,
    ...others
  }: BakcStakeModalProps) => {
    const [phase, setPhase] = useState<Phase>(Phase.Inputting);

    const { refresh } = useApeListStatesAndActions();

    const handleClose = useCallback(() => {
      if (phase === Phase.Inputting || phase === Phase.Failed) {
        onError();
      }
      onClose();
    }, [onClose, onError, phase]);

    useEffect(() => {
      if (isOpen) {
        setPhase(Phase.Inputting);
      }
    }, [isOpen]);

    const [formData, setFormData] = useState<Maybe<StakeBakcFormData>>(null);
    const handleSubmit = useCallback(
      (submittedFormData: StakeBakcFormData) => {
        setFormData(submittedFormData);
        setPhase(Phase.Submitting);
      },
      [setFormData]
    );

    const handleSubmitSuccess = useCallback(() => {
      setPhase(Phase.Success);
      onFinish();
      ReactGA.event({
        action: 'apestaking',
        category: 'nftstaking',
        label: 'stake',
        value: (formData?.amountFromBalance ?? 0) + (formData?.amountFromCredit ?? 0)
      });
    }, [onFinish, formData?.amountFromBalance, formData?.amountFromCredit]);

    const finishHandler = useCallback(() => {
      onClose();
      refresh();
    }, [onClose, refresh]);

    const handleSubmitFailed = useCallback(() => {
      setPhase(Phase.Failed);
      onError();
    }, [setPhase, onError]);

    return (
      <Modal isOpen={isOpen} title="Add BAKC Pairing" onClose={handleClose} {...others}>
        {phase === Phase.Inputting && (
          <BakcStakeForm
            apeCoinSource={apeCoinSource}
            bakcSource={bakcSource}
            onSubmit={handleSubmit}
            bakcTokenId={bakcTokenId}
          />
        )}
        {phase === Phase.Submitting && (
          <BakcStakeStepper
            formData={formData!}
            onFinish={handleSubmitSuccess}
            onError={handleSubmitFailed}
          />
        )}
        {phase === Phase.Failed && <ErrorState closeModal={onClose} />}
        {phase === Phase.Success && (
          <SuccessState info={formData as SuccessStateProps['info']} onFinish={finishHandler} />
        )}
      </Modal>
    );
  }
);
