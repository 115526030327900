import { useMemo, Fragment } from 'react';

import { useAppConfig } from './useAppConfig';

import { Feature } from '@/apps/paraspace/config';

export const useFeatureControlledComponent = <T>(feature: Feature, PrimitiveComponent: T) => {
  const { features } = useAppConfig();

  return useMemo(
    () => (features.includes(feature) ? PrimitiveComponent : Fragment),
    [feature, features, PrimitiveComponent]
  );
};
