import React, { createContext, memo, ReactElement, useCallback, useContext, useMemo } from 'react';
import { BigNumber } from 'bignumber.js';
import { weightedSumBy } from '@parallel-mono/utils';
import { range } from 'lodash';

import { useApeListStatesAndActions } from '../ApeListProvider';

import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { zero } from '@/apps/paraspace/consts/values';
import { calculateDailyInterest } from '@/apps/paraspace/utils/calculations';
import { DAYS_OF_YEAR } from '@/apps/paraspace/consts/fixtures';

type ContextValue = {
  nftPoolsNetApy: BigNumber | null;
  nftPoolsNetApyOnParaSpace: BigNumber | null;
  apePoolNetApy: BigNumber | null;
  weightedRewardApy: BigNumber | null;
  dailyRewardsData: number[];
  dailyInterestsData: number[];
  dailyRepaidDebtsData: number[];
};

const Context = createContext<ContextValue>({
  nftPoolsNetApy: null,
  nftPoolsNetApyOnParaSpace: null,
  apePoolNetApy: null,
  weightedRewardApy: null,
  dailyRewardsData: [],
  dailyInterestsData: [],
  dailyRepaidDebtsData: []
} as ContextValue);

export const useApeNetApy = () => useContext(Context);

/* eslint max-lines-per-function: ["error", { "max": 267 }] */
export const ApeNetApyProvider: React.FC<{ children: ReactElement }> = memo(({ children }) => {
  const { erc20InfoMap } = useMMProvider();
  const { effectiveCapeSupplyApy, effectiveCapeBorrowApy, apeStakingPoolSummary } =
    useAutoCompoundApeInfo();
  const { apesInBalanceAndInSuppliedExcludingInP2P, apesInSuppliedExcludingInP2P } =
    useApeListStatesAndActions();

  const totalNftPoolsStakedAmount = useMemo(
    () =>
      apesInBalanceAndInSuppliedExcludingInP2P.reduce(
        (total, ape) => total.plus(ape.stakedAmount || 0),
        zero
      ),
    [apesInBalanceAndInSuppliedExcludingInP2P]
  );
  const totalNftPoolsStakedAmountOnParaSpace = useMemo(
    () =>
      apesInSuppliedExcludingInP2P.reduce((total, ape) => total.plus(ape.stakedAmount || 0), zero),
    [apesInSuppliedExcludingInP2P]
  );

  const getWeightedRewardApy = useCallback(
    (totalStakedAmount: BigNumber) => {
      if (!totalStakedAmount || totalStakedAmount.toNumber() === 0) {
        return zero;
      }
      return weightedSumBy(
        apesInBalanceAndInSuppliedExcludingInP2P,
        ape => ape.stakedAmount ?? zero,
        ape =>
          ape.symbol === ERC721Symbol.BAKC
            ? apeStakingPoolSummary?.[ape.symbol]?.apy ?? zero
            : apeStakingPoolSummary?.[ape.symbol]?.compoundApy ?? zero
      ).div(totalStakedAmount);
    },
    [apeStakingPoolSummary, apesInBalanceAndInSuppliedExcludingInP2P]
  );
  const weightedRewardApy = useMemo(
    () => getWeightedRewardApy(totalNftPoolsStakedAmount),
    [getWeightedRewardApy, totalNftPoolsStakedAmount]
  );

  const getNftPoolsNetAPy = useCallback(
    (totalStakedAmount: BigNumber) => {
      const { borrowedAmount: apeBorrowedAmount, borrowApyRate: apeBorrowApyRate } =
        erc20InfoMap[ERC20Symbol.APE] ?? {};
      const { borrowedAmount: capeBorrowedAmount } = erc20InfoMap[ERC20Symbol.CAPE] ?? {};

      if (
        apeBorrowedAmount &&
        capeBorrowedAmount &&
        effectiveCapeBorrowApy &&
        apeBorrowApyRate &&
        weightedRewardApy
      ) {
        const effectiveApeBorrowedAmount = BigNumber.min(
          apeBorrowedAmount,
          BigNumber.max(totalStakedAmount.minus(capeBorrowedAmount), 0)
        );
        const effectiveCapeBorrowedAmount = BigNumber.min(capeBorrowedAmount, totalStakedAmount);

        const weightedBorrowApy = totalStakedAmount.isZero()
          ? zero
          : effectiveCapeBorrowedAmount
              .times(effectiveCapeBorrowApy)
              .plus(effectiveApeBorrowedAmount.times(apeBorrowApyRate))
              .div(totalStakedAmount);

        return weightedRewardApy.minus(weightedBorrowApy);
      }

      return null;
    },
    [effectiveCapeBorrowApy, erc20InfoMap, weightedRewardApy]
  );
  const nftPoolsNetApy = useMemo(
    () => getNftPoolsNetAPy(totalNftPoolsStakedAmount),
    [getNftPoolsNetAPy, totalNftPoolsStakedAmount]
  );
  const nftPoolsNetApyOnParaSpace = useMemo(
    () => getNftPoolsNetAPy(totalNftPoolsStakedAmountOnParaSpace),
    [getNftPoolsNetAPy, totalNftPoolsStakedAmountOnParaSpace]
  );

  const apePoolNetApy = useMemo(() => {
    const cApeSuppliedBalance = erc20InfoMap?.CAPE?.suppliedAmount;
    const cApeWalletBalance = erc20InfoMap?.CAPE?.balance;

    if (
      apeStakingPoolSummary?.APE.compoundApy &&
      effectiveCapeSupplyApy &&
      cApeSuppliedBalance &&
      cApeWalletBalance
    ) {
      return cApeWalletBalance.isZero() && cApeSuppliedBalance.isZero()
        ? zero
        : cApeWalletBalance
            .times(apeStakingPoolSummary?.APE.compoundApy)
            .plus(cApeSuppliedBalance.times(effectiveCapeSupplyApy))
            .div(cApeWalletBalance.plus(cApeSuppliedBalance));
    }

    return null;
  }, [
    apeStakingPoolSummary?.APE.compoundApy,
    effectiveCapeSupplyApy,
    erc20InfoMap?.CAPE?.balance,
    erc20InfoMap?.CAPE?.suppliedAmount
  ]);

  const DEFAULT_DAILY_DATA: number[] = range(0, 365).map(() => 0);

  /*
    Daily profit = All the income - All the interest to pay
    All the Income = cAPE reward + NFT reward
      // The NFT reward will be converted to cAPE and added in the next
      //   day's calculation
      // We need to take the auto-repay into consideration, which means the NFT
      //   reward will repay the debt first until the debt is 0
    All the interest = cAPE interest + APE interest
      // cAPE interest is the combination of the APE coin pool interest and the
      //   cAPE borrow interest
      // APE interest is the APE borrow interest
    [Important] Assumption: auto compound per day and auto repay per day;
   */
  const {
    dailyRewards: dailyRewardsData,
    dailyInterests: dailyInterestsData,
    dailyRepaidDebts: dailyRepaidDebtsData
  } = useMemo(() => {
    const apeInfo = erc20InfoMap[ERC20Symbol.APE];
    const capeInfo = erc20InfoMap[ERC20Symbol.CAPE];

    if (!(apeInfo && capeInfo && apesInSuppliedExcludingInP2P && apeStakingPoolSummary?.APE?.apy)) {
      return {
        dailyRewards: DEFAULT_DAILY_DATA,
        dailyInterests: DEFAULT_DAILY_DATA,
        dailyRepaidDebts: DEFAULT_DAILY_DATA
      };
    }

    const {
      borrowedAmount: apeBorrowedAmount,
      borrowApyRate: apeBorrowApyRate,
      supplyApyRate: apeSupplyApyRate,
      suppliedAmount: apeSuppliedAmount
    } = apeInfo;
    const {
      borrowedAmount: capeBorrowedAmount,
      borrowApyRate: capeBorrowApyRate,
      supplyApyRate: capeSupplyApyRate,
      suppliedAmount: capeSuppliedAmount,
      balance: capeBalance
    } = capeInfo;

    // APE coin pool daily rate
    const apePoolDailyApr = apeStakingPoolSummary?.APE.apy?.div(DAYS_OF_YEAR).toNumber() ?? 0;
    const apeBorrowDailyApr = calculateDailyInterest(apeBorrowApyRate).toNumber();
    const capeBorrowApr = calculateDailyInterest(capeBorrowApyRate).toNumber();
    // The cAPE borrow interest comes from lending and the APE coin pool
    const capeEffectiveBorrowDailyApr = capeBorrowApr + apePoolDailyApr;

    const apeSupplyDailyApr = calculateDailyInterest(apeSupplyApyRate).toNumber();
    const capeSupplyApr = calculateDailyInterest(capeSupplyApyRate).toNumber();
    // The cAPE supply interest comes from lending and the APE coin pool
    const capeEffectiveSupplyDailyApr = capeSupplyApr + apePoolDailyApr;

    // The daily nft rewards are almost the same for every day. And it should be
    //    added to the cAPE balance
    const dailyNftReward: number =
      apesInSuppliedExcludingInP2P
        .filter(ape => ape.stakedAmount?.gt(0))
        .reduce(
          (total, ape) =>
            (ape.stakedAmount ?? zero).times(ape.apy?.div(DAYS_OF_YEAR) ?? zero).plus(total),
          zero
        )
        ?.toNumber() ?? 0;
    /*
      We need to maintain:
        currentApeBorrowedAmount
        currentApeSuppliedAmount
        currentCapeBorrowedAmount
        currentCapeSuppliedAmount
        currentCapeBalance
      to calculation the reward at each day.
     */
    let currentApeBorrowedAmount = apeBorrowedAmount?.toNumber() ?? 0;
    let currentApeSuppliedAmount = apeSuppliedAmount?.toNumber() ?? 0;
    let currentCapeBorrowedAmount = capeBorrowedAmount?.toNumber() ?? 0;
    let currentCapeSuppliedAmount = capeSuppliedAmount?.toNumber() ?? 0;
    let currentCapeBalance = capeBalance?.toNumber() ?? 0;

    const dailyRewards: number[] = [];
    const dailyInterests: number[] = [];
    const dailyRepaidDebts: number[] = [];
    let day = 1;
    while (day <= DAYS_OF_YEAR) {
      const apeBorrowInterest = apeBorrowDailyApr * currentApeBorrowedAmount;
      const apeSupplyReward = apeSupplyDailyApr * currentApeSuppliedAmount;
      const capeBorrowInterest = currentCapeBorrowedAmount * capeEffectiveBorrowDailyApr;
      const capeSupplyReward = currentCapeSuppliedAmount * capeEffectiveSupplyDailyApr;
      const capeReward = currentCapeBalance * apePoolDailyApr;

      const dailyReward = apeSupplyReward + capeSupplyReward + capeReward + dailyNftReward;
      const dailyInterest = apeBorrowInterest + capeBorrowInterest;
      dailyRewards.push(dailyReward);
      dailyInterests.push(dailyInterest);

      currentApeBorrowedAmount += apeBorrowInterest;
      currentApeSuppliedAmount += apeSupplyReward;
      currentCapeSuppliedAmount += capeSupplyReward;
      currentCapeBalance += capeReward;

      // If the daily nft reward is not enough to repay the debt and interest
      //    then use all the reward to repay the debt.
      if (currentCapeBorrowedAmount + capeBorrowInterest > dailyNftReward) {
        currentCapeBorrowedAmount = currentCapeBorrowedAmount + capeBorrowInterest - dailyNftReward;
        dailyRepaidDebts.push(dailyNftReward);
      } else {
        dailyRepaidDebts.push(currentCapeBorrowedAmount + capeBorrowInterest);
        currentCapeBorrowedAmount = 0;
        // Supply the left reward to the cape pool after paying out debt
        currentCapeSuppliedAmount +=
          dailyNftReward - currentCapeBorrowedAmount - capeBorrowInterest;
      }

      day += 1;
    }
    return {
      dailyRewards,
      dailyInterests,
      dailyRepaidDebts
    };
  }, [
    DEFAULT_DAILY_DATA,
    apeStakingPoolSummary?.APE.apy,
    apesInSuppliedExcludingInP2P,
    erc20InfoMap
  ]);
  const value = useMemo(
    () => ({
      nftPoolsNetApy,
      apePoolNetApy,
      weightedRewardApy,
      nftPoolsNetApyOnParaSpace,
      dailyRewardsData,
      dailyInterestsData,
      dailyRepaidDebtsData
    }),
    [
      apePoolNetApy,
      nftPoolsNetApy,
      nftPoolsNetApyOnParaSpace,
      weightedRewardApy,
      dailyRewardsData,
      dailyInterestsData,
      dailyRepaidDebtsData
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
});
