import { memo, useCallback } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { RevokeNFTDelegationSubmitter } from '../../submitters';
import { DelegationInfo } from '../../types';

import {
  ErrorState,
  SuccessState,
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';

export type RevokeNftDelegationModalProps = Omit<ModalProps, 'children'> & {
  delegations: DelegationInfo[];
  onFinish?: () => void;
  onError?: () => void;
};

export const RevokeNftDelegationModal = memo(
  ({
    onError,
    delegations,
    onFinish,
    onClose,
    isOpen,
    ...others
  }: RevokeNftDelegationModalProps) => {
    const { phase, handleSubmitSuccess, handleSubmitFailed } = useCollectAndSubmitProcedure<
      void,
      void,
      void
    >({
      running: isOpen,
      onError,
      onFinish,
      defaultPhase: CollectAndSubmitProcedurePhase.Submitting
    });

    const handleClose = useCallback(() => {
      if (phase === CollectAndSubmitProcedurePhase.Submitting) {
        onError?.();
      }
      onClose?.();
    }, [onError, onClose, phase]);

    return (
      <Modal
        closeOnBackdropClick={false}
        isOpen={isOpen}
        title="Revoke"
        onClose={handleClose}
        {...others}
      >
        {phase === CollectAndSubmitProcedurePhase.Submitting && isOpen && (
          <RevokeNFTDelegationSubmitter
            formData={{ delegations }}
            onError={handleSubmitFailed}
            onFinish={handleSubmitSuccess}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            actionButtonText="Done"
            onAction={handleClose}
            desc={`Successfully revoked delegations of ${delegations.length} NFT${
              delegations.length > 1 ? 's' : ''
            }`}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={handleClose} />}
      </Modal>
    );
  }
);
