import {
  Button,
  Card,
  CardProps,
  Collapse,
  H5,
  H6,
  Inline,
  Skeleton,
  SmallText,
  Stack,
  Text,
  Toggle
} from '@parallel-mono/components';
import { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { SupplyPositionsRow } from '../types';
import { getEffectiveApy } from '../../utils';
import { SupplyERC20DropdownMenu } from '../../../components';

import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { getEffectiveSupplyApyTip } from '@/apps/paraspace/pages/Credit/MoneyMarket/utils/getEffectiveApyTip';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { ExpandToggle } from '@/apps/paraspace/pages/Credit/MoneyMarket/StyledComponents';
import { useAppConfig } from '@/apps/paraspace/hooks';

type ERC20CardProps = Omit<CardProps, 'children'> & {
  data: SupplyPositionsRow;
  inLiquidation: boolean;
  onSupplyERC20FromAA: (symbol: ERC20Symbol) => void;
  onSupplyERC20FromEOA: (symbol: ERC20Symbol) => void;
  onWithdraw: (row: SupplyPositionsRow) => void;
  onToggleCollateral: (arg: { symbol: string }) => void;
};

const TokensSectionContainer = styled(Collapse)`
  padding-top: 1rem;
`;

const CardWithoutShadow = styled(Card)`
  box-shadow: unset !important;
`;

export const ERC20Card = memo((props: ERC20CardProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const {
    data: { symbol, suppliedAmount, suppliedValue, cover, name, supplyApyRate, usedAsCollateral },
    data,
    onSupplyERC20FromEOA,
    onSupplyERC20FromAA,
    onWithdraw,
    onToggleCollateral,
    inLiquidation,
    ...others
  } = props;

  const { effectiveCapeSupplyApy } = useAutoCompoundApeInfo();

  const toggleExpandable = useCallback(() => {
    setIsOpen(v => !v);
  }, [setIsOpen]);

  const expandable = data.subRows && data.subRows?.length > 0;

  const handleWithdraw = useCallback(() => {
    onWithdraw(data);
  }, [onWithdraw, data]);

  const handleToggleCollateral = useCallback(() => {
    onToggleCollateral({ symbol });
  }, [onToggleCollateral, symbol]);

  const Wrapper = props?.border === false ? CardWithoutShadow : Card;

  const { erc20Config, nativeTokenAndDerivatives } = useAppConfig();

  const isNativeTokenOrDerivatives = useMemo(
    () => nativeTokenAndDerivatives.includes(symbol as ERC20Symbol),
    [nativeTokenAndDerivatives, symbol]
  );

  const effectiveSupplyApy = useMemo(
    () =>
      getEffectiveApy({
        baseApy: supplyApyRate,
        effectiveCapeApy: effectiveCapeSupplyApy,
        symbol: symbol as ERC20Symbol,
        apy: erc20Config[symbol as ERC20Symbol]?.apy,
        isNativeTokenOrDerivatives
      }),
    [effectiveCapeSupplyApy, supplyApyRate, symbol, erc20Config, isNativeTokenOrDerivatives]
  );

  const effectiveSupplyApyTooltip = useMemo(
    () =>
      getEffectiveSupplyApyTip({
        symbol: symbol as ERC20Symbol,
        baseApy: supplyApyRate,
        tooltipPlacement: 'left',
        apy: erc20Config[symbol as ERC20Symbol]?.apy ?? null,
        isNativeTokenOrDerivatives
      }),
    [supplyApyRate, symbol, erc20Config, isNativeTokenOrDerivatives]
  );

  return (
    <Wrapper border={props?.border !== false} {...others}>
      <Stack gap="1rem">
        <Inline justifyContent="space-between" alignItems="center">
          <Inline gap="0.5rem" alignItems="center">
            {cover}
            <H5>{name}</H5>
          </Inline>
          {expandable && <ExpandToggle onClick={toggleExpandable} open={isOpen} />}
        </Inline>

        <Inline justifyContent="space-between">
          <H6 skin="secondary" fontWeight="medium">
            Supplied
          </H6>
          <Stack gap="0" alignItems="flex-end">
            <Text>{suppliedAmount ? formatBalance(suppliedAmount) : '-'}</Text>
            {suppliedValue?.gt(0) ? (
              <SmallText skin="secondary">{formatToCurrency(suppliedValue)}</SmallText>
            ) : (
              <Skeleton.Button height="1rem" width="2rem" />
            )}
          </Stack>
        </Inline>
        <Inline justifyContent="space-between">
          <H6 skin="secondary" fontWeight="medium">
            APY
          </H6>
          <Stack gap="0" alignItems="flex-end">
            <Inline alignItems="center" gap="0.25rem">
              {expandable && '~'}
              <Text>{effectiveSupplyApy}</Text>
              {effectiveSupplyApyTooltip}
            </Inline>
          </Stack>
        </Inline>
        {!expandable && (
          <>
            <Inline justifyContent="space-between">
              <H6 skin="secondary" fontWeight="medium">
                Collateral
              </H6>
              <Toggle
                disabled={inLiquidation && usedAsCollateral!}
                checked={usedAsCollateral!}
                onChange={handleToggleCollateral}
              />
            </Inline>
            <Inline gap="0.75rem" justifyContent="space-between">
              <SupplyERC20DropdownMenu
                symbol={symbol as ERC20Symbol}
                placement="bottom-start"
                menuTrigger={
                  <Button skin="secondary" block>
                    Supply
                  </Button>
                }
                eoaWalletOption={{
                  onClick: () => onSupplyERC20FromEOA(data.symbol as ERC20Symbol)
                }}
                aaWalletOption={{
                  onClick: () => onSupplyERC20FromAA(data.symbol as ERC20Symbol)
                }}
              />
              <Button skin="secondary" disabled={inLiquidation} block onClick={handleWithdraw}>
                Withdraw
              </Button>
            </Inline>
          </>
        )}
      </Stack>
      <TokensSectionContainer open={isOpen}>
        {data.subRows?.map((each, index) => (
          <ERC20Card
            border={false}
            key={each.symbol + index}
            data={each}
            inLiquidation={inLiquidation}
            onSupplyERC20FromEOA={onSupplyERC20FromEOA}
            onSupplyERC20FromAA={onSupplyERC20FromAA}
            onWithdraw={onWithdraw}
            onToggleCollateral={onToggleCollateral}
          />
        ))}
      </TokensSectionContainer>
    </Wrapper>
  );
});
