import { ERC20Symbol } from '@/apps/paraspace/typings';

export const erc20Decimals: Record<ERC20Symbol, number> = {
  [ERC20Symbol.BLUR]: 18,
  [ERC20Symbol.SAPE]: 18,
  [ERC20Symbol.ETH]: 18,
  [ERC20Symbol.WETH]: 18,
  [ERC20Symbol.DAI]: 18,
  [ERC20Symbol.USDC]: 6,
  [ERC20Symbol.USDT]: 6,
  [ERC20Symbol.APE]: 18,
  [ERC20Symbol.WBTC]: 8,
  [ERC20Symbol.STETH]: 18,
  [ERC20Symbol.WSTETH]: 18,
  [ERC20Symbol.AWETH]: 18,
  [ERC20Symbol.CAPE]: 18,
  [ERC20Symbol.CBETH]: 18,
  [ERC20Symbol.RETH]: 18,
  [ERC20Symbol.FRAX]: 18,
  [ERC20Symbol.ARB]: 18,
  [ERC20Symbol.GMX]: 18,
  [ERC20Symbol.LINK]: 18,
  [ERC20Symbol.UNI]: 18,
  [ERC20Symbol.AAVE]: 18,
  [ERC20Symbol.RDNT]: 18,
  [ERC20Symbol.MATIC]: 18,
  [ERC20Symbol.STMATIC]: 18,
  [ERC20Symbol.CRV]: 18,
  [ERC20Symbol.BAL]: 18,
  [ERC20Symbol.WMATIC]: 18,
  [ERC20Symbol.GLMR]: 18,
  [ERC20Symbol.WGLMR]: 18,
  [ERC20Symbol.XCDOT]: 10,
  [ERC20Symbol.STDOT]: 10,
  [ERC20Symbol.XCUSDT]: 6,
  [ERC20Symbol.PARA]: 12,
  [ERC20Symbol.UBAYC]: 18,
  [ERC20Symbol.UPPG]: 18,
  [ERC20Symbol.AUSDC]: 6,
  [ERC20Symbol['USDC.E']]: 6
};
