import { FC, memo } from 'react';
import { render } from 'react-dom';
import { ColorMode } from '@parallel-mono/components';
import { ParaXProvider, AppRenderParams, AppPackage, Environment } from 'parax-sdk';

import { App } from './App';
import { Infrastructure } from './Infrastructure';

const ParaspaceApp: FC<{
  colorMode: ColorMode;
  baseRoute: string;
  provider: ParaXProvider;
  account: string;
  authentication: AppRenderParams['state']['authentication'];
  chainId: number;
  apis: AppRenderParams['apis'];
  scrollContainer: HTMLDivElement;
  env: Environment;
}> = memo(
  ({
    colorMode,
    baseRoute,
    provider,
    account,
    chainId,
    apis,
    scrollContainer,
    authentication,
    env
  }) => {
    return (
      <Infrastructure
        colorMode={colorMode}
        baseRoute={baseRoute}
        provider={provider}
        account={account}
        authentication={authentication}
        chainId={chainId}
        apis={apis}
        env={env}
        scrollContainer={scrollContainer}
      >
        <App />
      </Infrastructure>
    );
  }
);

export default {
  render: ({
    state: { colorMode, baseRoute, provider, account, chainId, authentication },
    apis,
    container,
    env
  }: AppRenderParams) => {
    render(
      (
        <ParaspaceApp
          colorMode={colorMode}
          baseRoute={baseRoute}
          provider={provider}
          account={account}
          authentication={authentication}
          chainId={chainId}
          apis={apis}
          env={env}
          scrollContainer={container as HTMLDivElement}
        />
      ) as any,
      container as any
    );
  },
  icon: 'paraspace',
  name: 'ParaSpace'
} as AppPackage;
