import { Icon, Inline } from '@parallel-mono/components';
import { useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';

import { useLiquidationSummaryData } from '../contexts/PlatformSummaryProvider';

import { SummaryInfoBanner } from '@/apps/paraspace/components';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';

export const Stats = () => {
  const liquidationSummaryData = useLiquidationSummaryData();
  const { features } = useAppConfig();

  const values = useMemo(() => {
    return [
      {
        label: 'In Liquidation',
        value: liquidationSummaryData ? formatNumber(liquidationSummaryData.inLiquidation) : '-'
      },
      {
        label: 'In Auction',
        value: liquidationSummaryData ? formatToCurrency(liquidationSummaryData.inAuction) : '-'
      },
      // Dune does not support the Moonbeam network, so we can not fetch the Historical Auction data.
      features.includes(Feature.LiquidationDashboardAuctionData)
        ? {
            label: 'Historical Auction',
            value: liquidationSummaryData
              ? formatToCurrency(liquidationSummaryData.totalAuction)
              : '-'
          }
        : null
    ].filter(notEmpty);
  }, [features, liquidationSummaryData]);

  return (
    <Inline width="100%" justifyContent="center">
      <SummaryInfoBanner
        width="100%"
        title="Liquidation"
        description=""
        icon={<Icon name="droplet" />}
        valueList={values}
      />
    </Inline>
  );
};
