import { memo, useCallback } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';
import ReactGA from 'react-ga4';

import { ApeCoinListing, ApeCoinSourceToJoinApeListing, ApeListing } from '../../../types';
import { JoinListingSuccessState } from '../components';

import { JoinListingWithApeCoinForm } from './JoinListingWithApeCoinForm';
import { JoinListingWithApeCoinFormsSubmitter } from './JoinListingWithApeCoinFormSubmitter';
import { ErrorState } from './ErrorState';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import {
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { WalletType } from '@/apps/paraspace/typings';

export type JoinListingWithApeCoinModalProps = Omit<ModalProps, 'children'> & {
  apeListing: ApeListing;
  walletType: WalletType;
  onFinish?: () => void;
  onError?: () => void;
};

export const JoinListingWithApeCoinModal = memo(
  ({
    apeListing,
    walletType,
    onFinish,
    isOpen,
    onError,
    onClose,
    ...others
  }: JoinListingWithApeCoinModalProps) => {
    const finalHandleFinish = useCallback(() => {
      ReactGA.event({
        action: 'apestaking',
        category: 'p2pstaking',
        label: 'join'
      });
      onFinish?.();
    }, [onFinish]);
    const {
      submittedFormData: apeCoinSource,
      submittingResult: createdApeCoinListing,
      submittingError,
      phase,
      handleSubmitSuccess,
      handleSubmitFailed,
      handleFormSubmit
    } = useCollectAndSubmitProcedure<ApeCoinSourceToJoinApeListing, ApeCoinListing>({
      onFinish: finalHandleFinish,
      onError,
      running: isOpen
    });

    const { account } = useWeb3Context();

    return (
      <Modal
        closeOnBackdropClick={false}
        isOpen={isOpen}
        title="Join Staking"
        onClose={onClose}
        {...others}
      >
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <JoinListingWithApeCoinForm
            walletType={walletType}
            apeListing={apeListing}
            onSubmit={handleFormSubmit}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <JoinListingWithApeCoinFormsSubmitter
            formData={{
              apeListing,
              apeCoinSource: apeCoinSource!
            }}
            walletType={walletType}
            onFinish={handleSubmitSuccess}
            onError={handleSubmitFailed}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && (
          <ErrorState
            account={account}
            apeListing={apeListing}
            onFinish={onClose!}
            errorConfig={submittingError}
            errLabel={apeListing.symbol}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <JoinListingSuccessState
            apeCoinListing={createdApeCoinListing}
            apeListing={apeListing}
            bakcListing={null}
            onFinish={onClose}
          />
        )}
      </Modal>
    );
  }
);
