import { H5, Inline, Stack, StackProps, Text, Button, Icon, H6 } from '@parallel-mono/components';
import { map } from 'lodash';
import { FC } from 'react';
import styled from 'styled-components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';

import { ListingTableRowData } from '../types';
import { ApeCoinListing, ListingType, Listing } from '../../../types';

import { AccountPill, NFTThumbnail } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';

const ListingTypeColumn = styled(Inline)`
  flex: 0 0 8.5rem;
`;
const ButtonColumn = styled(Inline)`
  flex: 0 0 4rem;
`;

type Props = Omit<StackProps, 'children'> & {
  data: ListingTableRowData[];
  onSelectedListingChange: (data: Listing) => void;
};

export const MobileListingDataGrid: FC<Props> = ({ data, onSelectedListingChange, ...props }) => {
  const listingType = data?.[0].listingType === ListingType.ApeCoinListing ? 'ApeCoin' : 'NFT';
  return (
    <Stack {...props}>
      <Inline>
        <ListingTypeColumn>
          <H6 skin="secondary">{listingType}</H6>
        </ListingTypeColumn>

        <H6 skin="secondary">Lister % Share</H6>
      </Inline>
      {map(data, listing => {
        const isApeCoinListing = listing.listingType === ListingType.ApeCoinListing;
        return (
          <Inline gap="0" justifyContent="" alignItems="center">
            <ListingTypeColumn gap="0.75rem">
              {isApeCoinListing ? (
                <CryptoIcon symbol="APE" size="2rem" />
              ) : (
                <NFTThumbnail
                  symbol={listing.symbol as ERC721Symbol}
                  tokenId={listing.tokenId}
                  width="3rem"
                  size="small"
                />
              )}

              <Stack gap="0">
                {isApeCoinListing ? (
                  <Text>{formatNumber(BigNumber((listing as ApeCoinListing).amount))} APE</Text>
                ) : (
                  <Text>#{listing.tokenId}</Text>
                )}

                <AccountPill address={listing.offerer} />
              </Stack>
            </ListingTypeColumn>
            <Inline width="100%" inset="0 0 0 1.5rem">
              <H5>{formatNumber(listing.share, { output: 'percent' })}</H5>
            </Inline>

            <ButtonColumn>
              <Button
                size="small"
                block
                skin={listing.isSelected ? 'primary' : 'secondary'}
                onClick={() => onSelectedListingChange(listing)}
              >
                {listing.isSelected ? <Icon name="check" /> : 'Select'}
              </Button>
            </ButtonColumn>
          </Inline>
        );
      })}
    </Stack>
  );
};
