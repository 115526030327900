import { SmallText } from '@parallel-mono/components';
import { FC, memo, ReactNode } from 'react';
import styled from 'styled-components';

import { ValuePill, ValuePillProps } from '../ValuePill';
import { RoundProfilePicture } from '../PesudoProfilePicture';

const WALLET_ADDRESS_DISPLAY_LENGTH = 5;

const UserInline = styled(ValuePill)<{ isOwner: boolean }>`
  width: fit-content;
  min-width: 5rem;
  padding: 0.125rem 0.25rem;
  ${({ isOwner, theme }) =>
    isOwner
      ? {
          borderColor: theme.skin.primary.main,
          backgroundColor: theme.skin.primary.main
        }
      : {}}
`;

const YouText = styled(SmallText)`
  color: ${({ theme }) => theme.skin.grey[500]};
`;

const AddressText = styled(SmallText)<{ isOwner: boolean }>`
  color: ${({ theme, isOwner }) =>
    isOwner ? theme.skin.primary.contrastText : theme.skin.primary.secondaryContrastText};
`;

type AccountPillProps = {
  address: string;
  isOwner?: boolean;
  addressFormatter?: (address: string) => ReactNode;
} & Omit<ValuePillProps, 'children'>;

const defaultAddressFormatter = (address: string) =>
  address.slice(0, WALLET_ADDRESS_DISPLAY_LENGTH);

export const AccountPill: FC<AccountPillProps> = memo(
  ({ address, isOwner = false, addressFormatter = defaultAddressFormatter, ...props }) => {
    return (
      <UserInline gap="0.25rem" alignItems="center" size="small" {...props} isOwner={isOwner}>
        <RoundProfilePicture width="1rem" height="1rem" />
        <AddressText isOwner={isOwner}>{addressFormatter(address)}</AddressText>
        {isOwner && <YouText>you</YouText>}
      </UserInline>
    );
  }
);
