import { Button, ButtonProps } from '@parallel-mono/components';
import { memo, useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { ERC721Symbol } from 'paraspace-configs-v2';

import { useWeb3Context } from '../../contexts';
import { useMMProvider } from '../../pages/contexts/MMProvider';
import { useAppConfig } from '../../hooks';
import { ERC20Symbol } from '../../typings';
import { Maybe } from '../../typings/basic';

type AddAssetsButtonProps = ButtonProps & {
  context: {
    erc721Assets?: {
      symbol: ERC721Symbol;
      tokenIds?: number[];
    }[];
    erc20Assets?: {
      symbol: ERC20Symbol;
      amount: Maybe<BigNumber | number>;
    }[];
  };
};

export const AddAssetsButton = memo(({ onClick, context, ...others }: AddAssetsButtonProps) => {
  const { addAssets } = useWeb3Context();
  const { loadUserAccountData, loadERC20Balance } = useMMProvider();
  const { erc721Config, erc20Config } = useAppConfig();
  const organizedContext = useMemo(
    () => ({
      erc721Assets: context.erc721Assets?.map(it => ({
        address: erc721Config[it.symbol].address,
        symbol: it.symbol,
        tokenIds: it.tokenIds
      })),
      erc20Assets: context.erc20Assets?.map(it => ({
        symbol: it.symbol,
        address: erc20Config[it.symbol].address,
        amount: BigNumber.isBigNumber(it.amount) ? it.amount.toNumber() : it.amount
      }))
    }),
    [context, erc721Config, erc20Config]
  );
  const handleClick = useCallback(
    e => {
      addAssets(organizedContext).then(() => {
        loadUserAccountData();
        loadERC20Balance();
      });
      onClick?.(e);
    },
    [addAssets, organizedContext, onClick, loadUserAccountData, loadERC20Balance]
  );

  return (
    <Button onClick={handleClick} block size="large" {...others}>
      Add Assets
    </Button>
  );
});
