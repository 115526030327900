import { memo, useCallback, useState } from 'react';
import {
  Button,
  H2,
  Modal,
  ModalProps,
  Skeleton,
  Stack,
  StackProps
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';

import { StakeInfoListItem } from '../types';
import { useLegacyOfficialApeStaking } from '../hook/useLegacyOfficialApeStaking';

import { CommonFormState } from './types';
import Success from './component/CommonSuccess';

import { ApproveWallet, InProgress, ErrorState } from '@/apps/paraspace/components';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

export type ClaimRewardsModalProps = Omit<ModalProps, 'children'> & {
  stakeItem: StakeInfoListItem;
};

type ClaimRewardsModalContentProps = Omit<StackProps, 'children'> & {
  stakeItem: StakeInfoListItem;
  onFinish?: () => void;
};

const ClaimRewardsModalContent = memo(
  ({ stakeItem, onFinish, ...others }: ClaimRewardsModalContentProps) => {
    const [status, setStatus] = useState<CommonFormState>(CommonFormState.MAINFORM);

    const { claimRewards } = useLegacyOfficialApeStaking();

    const parallelToast = useParallelToast();

    const claim = useCallback(() => {
      const claimPromise = claimRewards(stakeItem)
        .then(tx => {
          setStatus(CommonFormState.PROCESSING);
          return tx?.wait();
        })
        .then(() => {
          setStatus(CommonFormState.SUCCESS);
        })
        .catch(err => {
          setStatus(CommonFormState.ERROR);
          throw getUserFriendlyError(err);
        });

      parallelToast.promise(claimPromise);
    }, [claimRewards, stakeItem, parallelToast]);

    const handleClaim = useCallback(() => {
      setStatus(CommonFormState.APPROVE);
      claim();
    }, [claim]);

    const rewards = stakeItem.pendingRewards;

    return (
      <Stack alignItems="center" {...others}>
        {status === CommonFormState.MAINFORM && (
          <>
            <CryptoIcon symbol="APE" size="5rem" />
            {rewards ? (
              <H2>{formatBalance(rewards)} APE</H2>
            ) : (
              <Skeleton.Button variant="round" height="2.25rem" width="6rem" />
            )}
            <Button block onClick={handleClaim}>
              Claim
            </Button>
          </>
        )}
        {status === CommonFormState.APPROVE && <ApproveWallet />}
        {status === CommonFormState.PROCESSING && <InProgress tip="Claiming your rewards" />}
        {status === CommonFormState.SUCCESS && (
          <Success
            desc={`You just claimed ${formatBalance(rewards ?? null)} APE coins!`}
            tip="Come back for more later!"
            onClose={onFinish!}
          />
        )}
        {status === CommonFormState.ERROR && <ErrorState closeModal={onFinish!} />}
      </Stack>
    );
  }
);

export const ClaimRewardsModal = memo(
  ({ stakeItem, onClose, ...others }: ClaimRewardsModalProps) => {
    const { symbol, tokenId } = stakeItem;
    return (
      <Modal {...others} onClose={onClose} title="Claim Rewards">
        {symbol !== null && tokenId !== null && (
          <ClaimRewardsModalContent onFinish={onClose} stakeItem={stakeItem} />
        )}
      </Modal>
    );
  }
);
