import { memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BigText,
  Button,
  Card,
  CardProps,
  Inline,
  ProgressBar,
  Responsive,
  SmallText,
  Stack,
  StackProps,
  Text,
  useBreakpoints,
  Image
} from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import { Link as RouterLink } from 'react-router-dom';
import { formatNumber } from '@parallel-mono/utils';
import { isNil } from 'lodash';

import CompoundApe from '../../../../Images/compound-ape.svg';
import { useApeListStatesAndActions, ApeListItem } from '../../../../contexts';

import { StakeFromDropDownButton } from './StakeApeDropDownButton';

import { ApeStakingTokenSymbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/OfficialPairing/ApeStakingManagerProvider/types';
import { Link, NFTThumbnail, Tooltip } from '@/apps/paraspace/components';
import {
  DEFAULT_PERCENT_DECIMAL,
  HIDDEN_DECIMAL_PERCENT,
  MAX_PERCENT,
  MIN_PERCENT_DECIMAL
} from '@/apps/paraspace/pages/config';
import { useNftActualOwners } from '@/apps/paraspace/pages/hooks/useAcutualOwners';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useNftCurrentOwners } from '@/apps/paraspace/pages/hooks/useCurrentOwners';
import { TransferNftParams } from '@/apps/paraspace/pages/ApePairing/contexts/ApeStakingManagerProvider/TransferModal';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';

const Container = styled(Card)`
  min-height: 15rem;
`;

const ContentWrapper = styled(Responsive)`
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
`;

const ApeThumbnailContainer = styled.div`
  position: relative;
  width: 25%;
  border-radius: 1rem;
  flex-shrink: 0;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  `};
`;

const ApeThumbnail = styled(NFTThumbnail).attrs<{ ratio?: string }>(({ ratio = '100%' }) => ({
  width: ratio,
  height: ratio
}))<{ topOffset?: string; leftOffset?: string; ratio?: string }>`
  position: absolute;
  top: ${({ topOffset = '0' }) => topOffset};
  left: ${({ leftOffset = '0' }) => leftOffset};
`;

const GreyBigText = styled(BigText)`
  color: ${({ theme }) => theme.skin.grey[500]};
`;

const FullWidthTip = styled(Tooltip)`
  width: 100%;

  & > * {
    width: 100%;
  }
`;

type InfoSectionProps = { title: ReactNode; value: ReactNode; tipContent?: ReactNode } & Omit<
  StackProps,
  'children'
>;
const InfoSection = memo(({ title, value, tipContent, ...others }: InfoSectionProps) => (
  <Stack gap="0.125rem" {...others}>
    <Inline gap="0.125rem" alignItems="center">
      <SmallText as="div" skin="secondary">
        {title}
      </SmallText>
      {tipContent && <Tooltip content={tipContent} />}
    </Inline>
    <BigText as="div" fontWeight="bold">
      {value}
    </BigText>
  </Stack>
));

type ApeItemCardProps = {
  tokenInfo: ApeListItem;
  inLiquidation: boolean;
  v1TimelockInfo?: Maybe<{
    isReleaseTimeReached: boolean;
    expectedRelease: string;
  }>;
  onStakeApe: (apeInfo: ApeListItem, apeCoinSource: WalletType) => void;
  onWithdraw: (apeInfo: ApeListItem, isSupplied: boolean) => void;
  onTransfer: (apeInfo: TransferNftParams) => void;
  onStakeBAKC: (apeInfo: ApeListItem, apeCoinSource: WalletType) => void;
  nftCompoundAPY: {
    [key in ApeStakingTokenSymbol]: {
      maxApy: number;
      minApy: number;
    };
  };
} & Omit<CardProps, 'children'>;

// Consider move out to reuse it
const formatApy = (apy: number | BigNumber): string =>
  formatNumber(apy, {
    output: 'percent',
    decimal: new BigNumber(apy).gte(HIDDEN_DECIMAL_PERCENT)
      ? MIN_PERCENT_DECIMAL
      : DEFAULT_PERCENT_DECIMAL,
    threshold: {
      max: MAX_PERCENT
    }
  });

/* eslint max-lines-per-function: ["error", { "max": 400 }] */
export const ApeItemCard = memo(
  ({
    tokenInfo,
    onStakeApe,
    onWithdraw,
    onTransfer,
    onStakeBAKC,
    inLiquidation,
    nftCompoundAPY,
    v1TimelockInfo,
    ...others
  }: ApeItemCardProps) => {
    const {
      symbol,
      tokenId,
      supplied,
      stakedAmount = null,
      pendingRewards = null,
      apy,
      mainTokenSymbol,
      mainTokenId,
      source
    } = tokenInfo;
    const { apesInBalanceAndInSuppliedExcludingInP2P } = useApeListStatesAndActions();
    const isBAKCTypeCard = tokenInfo.symbol === ERC721Symbol.BAKC;
    const mainTokenInfo = apesInBalanceAndInSuppliedExcludingInP2P.find(
      i => i.tokenId === tokenInfo.mainTokenId && i.symbol === tokenInfo.mainTokenSymbol
    );

    const [isOwnBAKC, setIsOwnBAKC] = useState(false);
    const [isNotOwnedMainTokenSupplied, setIsNotOwnedMainTokenSupplied] = useState(false);

    const { mobile } = useBreakpoints();

    const isSuppliedNft = mainTokenInfo?.supplied ?? supplied;
    const isPairedAndSuppliedBakc = !!mainTokenInfo?.pairedBakc?.supplied;

    const handleStakeApe = useCallback(
      (apeCoinSource: WalletType) => {
        onStakeApe(tokenInfo, apeCoinSource);
      },
      [onStakeApe, tokenInfo]
    );

    const handleTransfer = useCallback(() => {
      onTransfer({
        symbol,
        tokenId,
        supplied,
        mainTokenSymbol,
        mainTokenId,
        mainTokenSupplied: isSuppliedNft
      });
    }, [isSuppliedNft, mainTokenId, mainTokenSymbol, onTransfer, supplied, symbol, tokenId]);

    const handleWithdraw = useCallback(() => {
      onWithdraw(tokenInfo, isSuppliedNft || isNotOwnedMainTokenSupplied);
    }, [onWithdraw, tokenInfo, isSuppliedNft, isNotOwnedMainTokenSupplied]);

    const handleStakeBAKC = useCallback(
      (apeCoinSource: WalletType) => {
        onStakeBAKC(tokenInfo, apeCoinSource);
      },
      [onStakeBAKC, tokenInfo]
    );

    const disableWithdraw = useMemo(
      () => !stakedAmount?.gt(0) || inLiquidation || !isSuppliedNft,
      [inLiquidation, stakedAmount, isSuppliedNft]
    );
    const hasPairableApeToken = useMemo(
      () =>
        apesInBalanceAndInSuppliedExcludingInP2P
          .filter(i => i.symbol !== ERC721Symbol.BAKC)
          .filter(i => !i.pairedBakc).length > 0,
      [apesInBalanceAndInSuppliedExcludingInP2P]
    );

    const stakeLimit = useMemo(() => new BigNumber(STAKE_LIMIT[symbol] ?? 0), [symbol]);

    const formattedCompoundApy = formatApy(nftCompoundAPY[symbol].maxApy);
    const rewardApy = formatApy(apy ?? 0);

    const isOfficialStaking = !isSuppliedNft && stakedAmount?.gt(0);
    const isParaSpaceStaking = isSuppliedNft && stakedAmount?.gt(0);

    // transferred bakc check
    const { nftInfoMap } = useMMProvider();
    const { account, authentication } = useWeb3Context();
    const { getNftActualOwners } = useNftActualOwners();
    const { getNftCurrentOwners } = useNftCurrentOwners();
    useEffect(() => {
      // own mainToken, check if own BAKC
      if (isBAKCTypeCard && tokenInfo.tokenId) {
        getNftActualOwners([
          { symbol: ERC721Symbol.BAKC, tokenId: String(tokenInfo.tokenId) }
        ]).then(res => {
          setIsOwnBAKC(res[0] === account.toLocaleLowerCase());
        });
      }

      // own bakc, not own mainToken, check if the mainToken is supplied
      if (isBAKCTypeCard && !mainTokenInfo && tokenInfo.mainTokenSymbol) {
        const { xTokenAddress } = nftInfoMap[ERC721Symbol[tokenInfo.mainTokenSymbol]];
        getNftCurrentOwners([
          { symbol: tokenInfo.mainTokenSymbol, tokenId: String(tokenInfo.mainTokenId) }
        ]).then(res => {
          setIsNotOwnedMainTokenSupplied(res[0] === xTokenAddress.toLocaleLowerCase());
        });
      }
    }, [
      account,
      getNftActualOwners,
      getNftCurrentOwners,
      isBAKCTypeCard,
      isSuppliedNft,
      mainTokenInfo,
      nftInfoMap,
      tokenInfo
    ]);

    // only official staked bayc/mayc is eligible to enable auto compound after transfer to paraspace
    const showAutoCompoundTransferButton = useMemo(() => {
      if (isOfficialStaking) {
        return tokenInfo.mainTokenSymbol ? !isSuppliedNft && !!mainTokenInfo : !supplied;
      }
      return false;
    }, [isOfficialStaking, isSuppliedNft, mainTokenInfo, supplied, tokenInfo.mainTokenSymbol]);

    const showStakeButton =
      (!isBAKCTypeCard && stakeLimit?.gt(stakedAmount ?? 0)) ||
      (isBAKCTypeCard &&
        // !!mainTokenInfo && isOwnBAKC === true represents own both
        ((stakeLimit?.gt(stakedAmount ?? 0) && !!mainTokenInfo && isOwnBAKC) ||
          stakedAmount?.eq(0)));

    const leftOperationButton = useMemo(() => {
      if (showAutoCompoundTransferButton) {
        return (
          <Inline width="100%">
            <Button size={mobile ? 'small' : 'medium'} block onClick={handleTransfer}>
              Earn {formattedCompoundApy} APY Instead
            </Button>
          </Inline>
        );
      }

      const disabledBAKCStake = isBAKCTypeCard && !hasPairableApeToken && isNil(mainTokenInfo);
      if (showStakeButton) {
        return (
          <Inline width="100%">
            <Tooltip
              style={{ width: '100%' }}
              disabled={!v1TimelockInfo}
              content={`This ${symbol} is still in the timelock of ParaSpace V1, please ${
                !v1TimelockInfo?.isReleaseTimeReached
                  ? `try again after ${v1TimelockInfo?.expectedRelease!}.`
                  : 'wait patiently.'
              } `}
            >
              <StakeFromDropDownButton
                disabled={disabledBAKCStake || !!v1TimelockInfo}
                onStake={isBAKCTypeCard ? handleStakeBAKC : handleStakeApe}
              />
            </Tooltip>
          </Inline>
        );
      }
      return null;
    }, [
      showAutoCompoundTransferButton,
      isBAKCTypeCard,
      hasPairableApeToken,
      mainTokenInfo,
      showStakeButton,
      mobile,
      handleTransfer,
      formattedCompoundApy,
      handleStakeBAKC,
      handleStakeApe,
      symbol,
      v1TimelockInfo
    ]);

    const rightOperationButton = useMemo(() => {
      if (
        // ParaSpace BAYC/MAYC
        (isParaSpaceStaking && !isBAKCTypeCard) ||
        // ParaSpace BAKC own both
        (isParaSpaceStaking && isBAKCTypeCard && isOwnBAKC)
      ) {
        return (
          <Inline width="100%">
            <FullWidthTip
              content={
                <Text skin="secondary">
                  {inLiquidation ? 'Your account is in liquidation' : 'No staked amount'}
                </Text>
              }
              disabled={!disableWithdraw}
              placement="top"
              gap="0"
            >
              <Button
                size={mobile ? 'small' : 'medium'}
                block
                skin="secondary"
                onClick={handleWithdraw}
                disabled={disableWithdraw}
              >
                Withdraw
              </Button>
            </FullWidthTip>
          </Inline>
        );
      }
      // ParaSpace BAKC own mainToken, not own BAKC
      if (isParaSpaceStaking && isBAKCTypeCard && !isOwnBAKC) {
        return (
          <Button
            size={mobile ? 'small' : 'medium'}
            block
            skin="secondary"
            onClick={handleWithdraw}
          >
            Withdraw
          </Button>
        );
      }

      // US ape BAYC/MAYC/BAKC
      if (
        // BAKC own both
        (isOfficialStaking && isBAKCTypeCard && mainTokenInfo && !isPairedAndSuppliedBakc) ||
        // BAYC/MAYC
        (isOfficialStaking && !isBAKCTypeCard)
      ) {
        return (
          <Button
            size={mobile ? 'small' : 'medium'}
            block
            skin="secondary"
            onClick={handleWithdraw}
          >
            Withdraw
          </Button>
        );
      }

      // BAYC/MAYC and BAKC staked bakcPool in US, only supplied BAKC
      if (isOfficialStaking && isBAKCTypeCard && mainTokenInfo && isPairedAndSuppliedBakc) {
        return (
          <Inline width="100%">
            <FullWidthTip
              content={
                <Text skin="secondary">
                  Please move your BAYC/MAYC to ParaSpace before withdrawing.
                </Text>
              }
              placement="top"
              gap="0"
            >
              <Button
                size={mobile ? 'small' : 'medium'}
                block
                skin="secondary"
                onClick={handleWithdraw}
                disabled
              >
                Withdraw
              </Button>
            </FullWidthTip>
          </Inline>
        );
      }

      return null;
    }, [
      disableWithdraw,
      handleWithdraw,
      inLiquidation,
      isBAKCTypeCard,
      isOfficialStaking,
      isOwnBAKC,
      isParaSpaceStaking,
      isPairedAndSuppliedBakc,
      mainTokenInfo,
      mobile
    ]);

    const platformTag = useMemo(() => {
      if (isSuppliedNft || isNotOwnedMainTokenSupplied) {
        return 'paraspace';
      }
      if (source === 'AA') {
        return 'parax';
      }
      if (source === 'EOA') {
        return authentication?.meta.walletIcon ?? '';
      }
      return stakedAmount?.gt(0) ? 'apestake' : undefined;
    }, [
      isNotOwnedMainTokenSupplied,
      isSuppliedNft,
      stakedAmount,
      source,
      authentication?.meta.walletIcon
    ]);
    const platformDescription = useMemo(() => {
      if (platformTag === 'paraspace') {
        return 'This NFT is staking in Ape Stake official contract through ParaSpace.';
      }
      if (platformTag === 'parax') {
        return 'This NFT is in the AA Wallet.';
      }
      return `This NFT is in your ${authentication.meta.walletType} Wallet.`;
    }, [platformTag, authentication]);

    return (
      <Container inset="1rem" border {...others}>
        <ContentWrapper inset={mobile ? '0' : '1.5rem'} breakPoint="tablet">
          <ApeThumbnailContainer>
            {isBAKCTypeCard && (
              <ApeThumbnail
                ratio="55%"
                platform={platformTag}
                platformTooltip={<Text>{platformDescription}</Text>}
                symbol={mainTokenSymbol || ERC721Symbol.BAYC}
                tokenId={mainTokenSymbol ? mainTokenId : undefined}
                showDescription={!!mainTokenId}
              />
            )}
            <ApeThumbnail
              topOffset={isBAKCTypeCard ? '40%' : '0'}
              leftOffset={isBAKCTypeCard ? '55%' : '0'}
              platform={isBAKCTypeCard ? undefined : platformTag}
              platformTooltip={<Text>{platformDescription}</Text>}
              ratio={isBAKCTypeCard ? '55%' : '100%'}
              symbol={symbol}
              tokenId={tokenId}
              showDescription
            />
          </ApeThumbnailContainer>
          <Stack
            inset={mobile ? '2rem 0.5rem 0.5rem 0.5rem' : '1.5rem 0.5rem'}
            gap={mobile ? '1rem' : '1.25rem'}
            width="100%"
          >
            <Inline grow>
              <InfoSection
                title={isOfficialStaking ? 'APR' : 'Compound APY'}
                tipContent={
                  !isOfficialStaking &&
                  'This is the maximum compounding yield, without accounting for borrow rate.'
                }
                value={isOfficialStaking ? `${rewardApy}` : `${formattedCompoundApy}`}
              />
              <InfoSection
                title="Rewards"
                value={
                  <Inline gap="0.25rem">
                    <BigText fontWeight="bold">{formatBalance(pendingRewards ?? 0)}</BigText>
                    <GreyBigText fontWeight="bold">APE</GreyBigText>
                  </Inline>
                }
              />
            </Inline>
            <Stack width="100%" gap="0.25rem">
              <Text skin="secondary">
                {formatBalance(stakedAmount ?? 0)}/{formatBalance(stakeLimit)}
              </Text>
              <ProgressBar
                value={stakedAmount?.multipliedBy(100)?.div(stakeLimit!)?.toNumber() ?? 0}
              />
            </Stack>
            <Inline>
              {leftOperationButton}
              {rightOperationButton}
            </Inline>
          </Stack>
          {isParaSpaceStaking && (
            <StyledTooltip
              placement="top"
              content={
                <>
                  All rewards are supplied to ParaSpace ApeCoin Pool for auto-compounding.{' '}
                  <Link as={RouterLink} to={absoluteRouteNames.APE_STAKING.COIN_POOL.index}>
                    View in ApeCoin Pool
                  </Link>
                </>
              }
            >
              <Image src={CompoundApe} width="1.5rem" height="1.5rem" />
            </StyledTooltip>
          )}
        </ContentWrapper>
      </Container>
    );
  }
);
