import { HTMLAttributes, memo, useMemo } from 'react';
import BigNumberJs from 'bignumber.js';
import { InfoPanel } from '@parallel-mono/business-components';
import { Text, SmallText, Stack, Inline } from '@parallel-mono/components';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';

import { CollectionInfo, NFT } from '../SupplyNftForm';

import { NumberRange, ChainlinkLogo } from '@/apps/paraspace/components';
import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';
import { zero } from '@/apps/paraspace/consts/values';
import { useAppConfig } from '@/apps/paraspace/hooks';

type NftItemDetailProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  selectedItems: NFT[];
  collectionInfo: CollectionInfo;
  currentBorrowPosition: BigNumberJs;
  futureBorrowPosition: BigNumberJs;
};

const StyledInfoPanel = styled(InfoPanel)`
  .panel-row {
    align-items: flex-start;
    text-align: right;
  }
`;

export const NftItemDetail = memo((props: NftItemDetailProps) => {
  const { selectedItems, collectionInfo, currentBorrowPosition, futureBorrowPosition } = props;
  const {
    marketCurrencyTokenInfo: { name: marketCurrencyTokenName }
  } = useAppConfig();
  const extraTotalMultiplier = useMemo(
    () =>
      selectedItems.reduce((prev, cur) => {
        return prev.plus(cur.multiplier.minus(1));
      }, zero),
    [selectedItems]
  );

  const newBorrowLimitDisplay = useMemo(
    () => (
      <NumberRange
        start={currentBorrowPosition.toNumber()}
        end={futureBorrowPosition.toNumber()}
        formatter={formatToCurrency}
      />
    ),
    [currentBorrowPosition, futureBorrowPosition]
  );

  const floorPrice = useMemo(() => {
    const specificPriceInUsd = selectedItems
      .map(each => each.specificInfo?.priceInUsd)
      .filter((each): each is BigNumberJs => !!each);

    if (specificPriceInUsd.length > 0) {
      const totalValue = BigNumberJs.sum(...specificPriceInUsd);
      return formatToCurrency(totalValue);
    }
    return `${formatNumber(collectionInfo.priceInETH)} ${marketCurrencyTokenName}`;
  }, [collectionInfo.priceInETH, marketCurrencyTokenName, selectedItems]);

  const floorPriceTitle = useMemo(
    () =>
      collectionInfo.hasTokenSpecificInfos ? (
        'Value'
      ) : (
        <Inline gap="0.25rem" alignItems="center">
          <Text>Floor Price</Text>
          <ChainlinkLogo symbol={collectionInfo.symbol} showName={false} />
        </Inline>
      ),
    [collectionInfo.hasTokenSpecificInfos, collectionInfo.symbol]
  );

  const infos = useMemo(
    () => [
      {
        title: floorPriceTitle,
        value: floorPrice
      },
      {
        title: 'Collateral Value',
        value: (
          <Stack gap="0">
            <Text>{formatToCurrency(futureBorrowPosition.minus(currentBorrowPosition))}</Text>
            {extraTotalMultiplier.gt(0) && !collectionInfo.hasTokenSpecificInfos && (
              <SmallText>
                including {formatBalance(extraTotalMultiplier.multipliedBy(floorPrice))}{' '}
                {marketCurrencyTokenName} boost value
              </SmallText>
            )}
          </Stack>
        )
      },
      { title: 'New Borrow Limit', value: newBorrowLimitDisplay }
    ],
    [
      marketCurrencyTokenName,
      collectionInfo.hasTokenSpecificInfos,
      currentBorrowPosition,
      extraTotalMultiplier,
      floorPrice,
      floorPriceTitle,
      futureBorrowPosition,
      newBorrowLimitDisplay
    ]
  );

  return <StyledInfoPanel classNames={{ row: 'panel-row' }} skin="primary" infos={infos} />;
});
