import { memo, useCallback, useMemo } from 'react';
import { Stack, StackProps } from '@parallel-mono/components';

import { UniSwapV3Item } from '../../../types';

import { TokenItem } from './TokenItem';

import { emptyArray } from '@/apps/paraspace/consts/values';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useUniSwapV3Manager } from '@/apps/paraspace/pages/contexts/UniSwapV3ManagerProvider/useUniSwapV3Manager';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useContractAddressBySymbol } from '@/apps/paraspace/hooks';

export type TokensSectionProps = Omit<StackProps, 'children'> & {
  symbol: ERC721Symbol;
  inLiquidation: boolean;
  onToggleCollateral: (tokenId: number) => void;
};

export const UniSwapV3TokensSection = memo(
  ({ symbol, inLiquidation, onToggleCollateral, ...others }: TokensSectionProps) => {
    const { nftInfoMap } = useMMProvider();
    const {
      nftSuppliedList = emptyArray,
      nftCollateralList = emptyArray,
      tokenSpecificInfos = {}
    } = nftInfoMap[symbol];
    const { imageMap } = useUniSwapV3Manager();

    const { addLiquidity, removeLiquidity } = useUniSwapV3Manager();

    const handleAddLiquidity = useCallback(
      (tokenId: number) => {
        addLiquidity(symbol, tokenId);
      },
      [addLiquidity, symbol]
    );

    const contractAddress = useContractAddressBySymbol(symbol);

    const items: UniSwapV3Item[] = useMemo(() => {
      return nftSuppliedList.map(id => ({
        id,
        symbol,
        asCollateral: nftCollateralList.includes(id),
        name: imageMap?.[id]?.name ?? '',
        isInRange: tokenSpecificInfos[id].isInRange,
        isClosed: tokenSpecificInfos[id].isClosed
      }));
    }, [nftSuppliedList, symbol, nftCollateralList, imageMap, tokenSpecificInfos]);

    const handleToggleCollateral = useCallback(
      (tokenId: number) => {
        onToggleCollateral(tokenId);
      },
      [onToggleCollateral]
    );

    const handleRemoveLiquidity = useCallback(
      async (tokenId: number) => {
        await removeLiquidity(symbol, tokenId);
      },
      [removeLiquidity, symbol]
    );

    return (
      <Stack alignItems="center" {...others}>
        {items.map(it => {
          return (
            <TokenItem
              key={it.id}
              width="100%"
              inLiquidation={inLiquidation}
              tokenId={it.id}
              name={it.name}
              asCollateral={it.asCollateral}
              isInRange={it.isInRange}
              isClosed={it.isClosed}
              onToggleCollateral={handleToggleCollateral}
              contractAddress={contractAddress}
              onRemoveLiquidity={handleRemoveLiquidity}
              onAddLiquidity={handleAddLiquidity}
            />
          );
        })}
      </Stack>
    );
  }
);
