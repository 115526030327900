import { HTMLAttributes, memo, useMemo } from 'react';
import { Pagination, Stack, useBreakpoints } from '@parallel-mono/components';
import { map, orderBy } from 'lodash';
import BigNumberJs from 'bignumber.js';
import includes from 'lodash/includes';

import TableForDesktop from './TableForDesktop';
import CardForMobile from './Mobile';

import { ERC721Symbol, FetchingStatus, WalletType } from '@/apps/paraspace/typings';
import { usePagination } from '@/apps/paraspace/hooks/usePagination';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import { COLLECTION_EXPECTED_ORDER } from '@/apps/paraspace/consts/collectionSort';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { useNativeTokenSymbol } from '@/apps/paraspace/pages/hooks';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';
import { DELISTED_ERC721_SYMBOLS } from '@/apps/paraspace/config';

type WalletBalanceMap = {
  balances: { symbol: ERC721Symbol; tokenId: number }[];
  loading: boolean;
};
export type CollectionRow = {
  symbol: ERC721Symbol;
  collectionName: string;
  balanceMap: {
    EOA: WalletBalanceMap;
    AA: WalletBalanceMap;
  };
  floorPrice: BigNumberJs;
  ltv: BigNumberJs;
  totalSupplied: BigNumberJs;
  isComingSoon: boolean;
  balanceLoadingStatus: FetchingStatus;
  floorPriceInMarketCurrency: BigNumberJs;
};

export type BlueChipsProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  onSupply: (nft: { collectionName: string; symbol: string }, walletType: WalletType) => void;
  onSupplyFromOtherProtocols: (nft: { collectionName: string; symbol: string }) => void;
};

const PAGE_SIZE = 6;
const NOT_SUPPORTED_SYMBOLS: ERC721Symbol[] = [];

export const BlueChips = memo(({ onSupply, onSupplyFromOtherProtocols }: BlueChipsProps) => {
  const { mobile } = useBreakpoints();
  const { nftInfoMap, userInfoLoaded, erc20InfoMap } = useMMProvider();
  const { erc721BalanceMap, erc721BalanceMapPollingStatus } = useEOABalances();
  const isEOABalanceLoading =
    erc721BalanceMap === null && erc721BalanceMapPollingStatus === FetchingStatus.FETCHING;

  const { erc721Config } = useAppConfig();
  const { p2pStakingSummary } = useP2PInfo();
  const nativeTokenSymbol = useNativeTokenSymbol();
  const {
    marketCurrencyTokenInfo: { isNativeTokenForNetWork }
  } = useAppConfig();

  const collections: CollectionRow[] = useMemo(
    () =>
      orderBy(
        Object.values(erc721Config)
          .filter(
            collection =>
              !DELISTED_ERC721_SYMBOLS.includes(collection.symbol) &&
              !collection.unlisted &&
              collection.symbol !== ERC721Symbol.UNISWAP_LP &&
              collection.symbol !== ERC721Symbol.SF_VLDR
          )
          .map(collection => {
            const { collectionName, symbol } = collection;
            const {
              balance: paraXAccountBalance,
              baseLTVasCollateral,
              availableLiquidity,
              priceInETH: floorPriceInMarketCurrency
            } = nftInfoMap[symbol] ?? {};
            const { priceInUsd: nativeTokenPriceInUsd } = erc20InfoMap[nativeTokenSymbol] ?? {};
            const expectedOrder = COLLECTION_EXPECTED_ORDER.indexOf(symbol);
            const stakedAmountInP2PPool = isNFTinvolvedWithApeStaking(symbol)
              ? p2pStakingSummary?.[symbol].amount ?? 0
              : 0;
            const floorPriceInNativeToken = isNativeTokenForNetWork
              ? floorPriceInMarketCurrency
              : floorPriceInMarketCurrency?.div(nativeTokenPriceInUsd);

            const balanceInfoLoading = isEOABalanceLoading && !userInfoLoaded;

            const balanceMap = {
              EOA: {
                balances:
                  map(erc721BalanceMap?.[symbol]?.balance, tokenId => ({
                    symbol,
                    tokenId: Number(tokenId)
                  })) ?? [],
                loading: isEOABalanceLoading
              },

              AA: {
                balances:
                  map(paraXAccountBalance, tokenId => ({ symbol, tokenId: Number(tokenId) })) ?? [],
                loading: !userInfoLoaded
              }
            };

            return {
              symbol,
              collectionName,
              balanceMap,
              floorPrice: floorPriceInNativeToken,
              floorPriceInMarketCurrency,
              ltv: baseLTVasCollateral,
              totalSupplied: availableLiquidity?.plus(stakedAmountInP2PPool),
              isComingSoon: includes(NOT_SUPPORTED_SYMBOLS, symbol),
              order: expectedOrder >= 0 ? expectedOrder : Number.MAX_VALUE,
              balanceLoadingStatus: balanceInfoLoading
                ? FetchingStatus.FETCHING
                : FetchingStatus.SUCCESS
            };
          }),
        ['order', 'isComingSoon', 'balance'],
        ['asc', 'asc', 'desc']
      ),
    [
      erc721Config,
      nftInfoMap,
      erc20InfoMap,
      nativeTokenSymbol,
      p2pStakingSummary,
      isNativeTokenForNetWork,
      isEOABalanceLoading,
      userInfoLoaded,
      erc721BalanceMap
    ]
  );

  const { pageData, totalPage, currentPage, setCurrentPage } = usePagination(
    collections,
    PAGE_SIZE
  );

  return (
    <Stack>
      {mobile ? (
        <CardForMobile
          data={pageData}
          onSupply={onSupply}
          onSupplyFromOtherProtocols={onSupplyFromOtherProtocols}
        />
      ) : (
        <TableForDesktop
          data={pageData}
          onSupply={onSupply}
          onSupplyFromOtherProtocols={onSupplyFromOtherProtocols}
        />
      )}
      {totalPage > 0 && (
        <Pagination
          total={totalPage}
          page={currentPage}
          onChange={setCurrentPage}
          siblingCount={0}
          startBoundaryCount={3}
        />
      )}
    </Stack>
  );
});
