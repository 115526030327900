import { ethers } from 'ethers';
import { providers as multicallProviders } from '@0xsequence/multicall';
import { ApprovalAction, CreateInputItem } from 'paraspace-seaport-js/lib/types';
import { mapInputItemToOfferItem } from 'paraspace-seaport-js/lib/utils/order';
import { getApprovalActions as getApprovalActionsInSeaport } from 'paraspace-seaport-js/lib/utils/approval';
import { getBalancesAndApprovals } from 'paraspace-seaport-js/lib/utils/balanceAndApprovalCheck';

import { validateOfferBalancesAndApprovals } from './balanceAndApprovalCheck';

type GetApprovalActionsOptions = {
  provider: ethers.providers.JsonRpcProvider;
  offer: readonly CreateInputItem[];
  spender: string;
};
export const getApproveActions = async (
  { offer, provider, spender }: GetApprovalActionsOptions,
  accountAddress: string
) => {
  const signer = provider.getSigner(accountAddress);
  const offerItems = offer.map(mapInputItemToOfferItem);
  const multicallProvider = new multicallProviders.MulticallProvider(provider!);
  const operator = spender;

  const balancesAndApprovals = await getBalancesAndApprovals({
    owner: accountAddress,
    items: offerItems,
    criterias: [],
    multicallProvider,
    operator
  });
  const insufficientApprovals = validateOfferBalancesAndApprovals({
    offer: offerItems,
    criterias: [],
    balancesAndApprovals,
    throwOnInsufficientBalances: false,
    operator
  }).filter(each => each.requiredApprovedAmount.gt(0));
  const approvalActions = await getApprovalActionsInSeaport(insufficientApprovals, signer);

  return approvalActions;
};

export const executeApprovalActions = async (
  approveActions: ApprovalAction[],
  callbackFn?: {
    beforeApprove?: () => void;
    afterApprove?: () => void;
  }
) => {
  if (approveActions?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < approveActions.length; i++) {
      const action = approveActions[i];
      callbackFn?.beforeApprove?.();
      // eslint-disable-next-line no-await-in-loop
      const tx = await action.transactionMethods.transact();
      callbackFn?.afterApprove?.();
      // eslint-disable-next-line no-await-in-loop
      await tx.wait();
    }
  }
};
