import { Network } from 'paraspace-configs-v2';

import { ERC721Symbol, SupportedNetworks } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type NftTraitBoost = Record<ERC721Symbol, { label: string; value: number }[]>;

const NFT_TRAIT_BOOST_FOR_ETHEREUM = {
  [ERC721Symbol.BAYC]: [
    { label: 'Trippy Fur', value: 2.5 },
    { label: 'Blue Beams Eyes', value: 1.6 },
    { label: 'Laser Eyes', value: 1.2 }
  ],
  [ERC721Symbol.MAYC]: [
    { label: 'M2 Solid Gold', value: 2.6 },
    { label: 'M2 Trippy', value: 1.4 },
    { label: 'M1 Solid Gold', value: 1.2 },
    { label: 'M2 Blue Beams Eyes', value: 1.8 },
    { label: 'M2 Black Suit', value: 1.2 }
  ],
  [ERC721Symbol.OTHERDEED]: [{ label: 'Koda', value: 8 }],
  [ERC721Symbol.BAKC]: [
    { label: 'Solid Gold Fur ', value: 2 },
    { label: 'Trippy Fur', value: 1.2 },
    { label: 'Blue Beams Eyes', value: 1.2 }
  ],
  [ERC721Symbol.AZUKI]: [
    { label: 'Special Water', value: 1.5 },
    { label: 'Special Lightning', value: 1.5 },
    { label: 'Special Fox Fire', value: 1.5 },
    { label: 'Special Fire', value: 1.5 },
    { label: 'Special Earth', value: 1.5 },
    { label: 'Special Fireflies', value: 1.5 },
    { label: 'Golden Fishing Rod', value: 1.3 }
  ]
} as NftTraitBoost;

export const NFT_TRAIT_BOOST_FOR_NETWORK: Record<SupportedNetworks, Maybe<NftTraitBoost>> = {
  [Network.SEPOLIA]: NFT_TRAIT_BOOST_FOR_ETHEREUM,
  [Network.MAINNET]: NFT_TRAIT_BOOST_FOR_ETHEREUM,
  [Network.ZKSYNC_ERA]: null,
  [Network.ZKSYNC_GOERLI]: null,
  [Network.ARBITRUM_ONE]: null,
  [Network.ARBITRUM_GOERLI]: null,
  [Network.PARALLEL_L3_TESTNET]: null
};
