import { useMemo } from 'react';
import { isEmpty, isNil, mapKeys, mapValues, sortBy } from 'lodash';

import { formatLiquidation } from '../utils';
import { InLiquidationRow } from '../types';
import { useNativeTokenSymbol } from '../../hooks';

import { AccountInLiquidationInfo } from '@/apps/paraspace/generated/graphql';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useAppConfig, useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';

export const useLiquidationInfoFormatter = (
  liquidations: AccountInLiquidationInfo[],
  filterCollections: ERC721Symbol[],
  isPaused: boolean
) => {
  const { nftInfoMap, erc20InfoMap } = useMMProvider();
  const { marketCurrencyTokenInfo } = useAppConfig();
  const nativeTokenSymbol = useNativeTokenSymbol();
  const { priceInETH: nativeTokenPriceInMarketCurrency } = erc20InfoMap[nativeTokenSymbol] || {};
  const contractAddressToNTokenAddressMap = useMemo(() => {
    return mapValues(
      mapKeys(nftInfoMap, ({ address }) => address),
      'xTokenAddress'
    );
  }, [nftInfoMap]);

  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  const formattedLiquidations = useMemo(() => {
    return liquidations
      .map(liquidation => ({ ...liquidation, isPaused }))
      .map(liquidation =>
        formatLiquidation(
          liquidation!,
          contractAddressToNTokenAddressMap,
          getSymbolByContractAddress,
          marketCurrencyTokenInfo,
          nativeTokenPriceInMarketCurrency
        )
      );
  }, [
    liquidations,
    isPaused,
    contractAddressToNTokenAddressMap,
    getSymbolByContractAddress,
    marketCurrencyTokenInfo,
    nativeTokenPriceInMarketCurrency
  ]);

  const filteredLiquidations = useMemo(
    () =>
      filterCollections.length > 0
        ? formattedLiquidations
            .filter(liq => liq.assets.some(asset => filterCollections.includes(asset.symbol)))
            .filter(
              liq => !isEmpty(liq.assets) && !isNil(liq.nftHealthFactor)
              // liq.nftHealthFactor < RECOVERY_NFT_HF_THRESHOLD
            )
        : formattedLiquidations,
    [formattedLiquidations, filterCollections]
  );

  const sortedLiquidations: InLiquidationRow[] = useMemo(
    () => sortBy(filteredLiquidations, 'healthFactor'),
    [filteredLiquidations]
  );

  return {
    liquidations: sortedLiquidations
  };
};
