import { H3, Icon, Stack, StackProps, Text } from '@parallel-mono/components';
import { FC, memo } from 'react';

import { ConnectWallet } from '@/apps/paraspace/components';

type Props = {
  description?: string;
};

export const NotConnect: FC<Props & Omit<StackProps, 'children'>> = memo(
  ({ description, ...others }) => {
    return (
      <Stack alignItems="center" justifyContent="center" {...others}>
        <Icon size="5.25rem" name="purse" strokeWidth={1} />
        <Stack alignItems="center" gap="0.5rem">
          <H3>Connect Wallet to View</H3>
          {description && <Text skin="secondary">{description}</Text>}
        </Stack>
        <ConnectWallet block={false} btnTxt="Connect" />
      </Stack>
    );
  }
);
