import {
  FC,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import Web3Token from 'web3-token';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';

const LOCAL_STORAGE_WEB3_TOKENS_MAP_CACHE = 'LOCAL_STORAGE_WEB3_TOKENS_MAP_CACHE';
const getWeb3TokensMapFromStorage = () => {
  const web3TokensFromStorage = localStorage.getItem(LOCAL_STORAGE_WEB3_TOKENS_MAP_CACHE) || '{}';
  const theTokenMap = JSON.parse(web3TokensFromStorage);
  return theTokenMap as Record<string, string>;
};

type ContextValue = {
  web3TokensMap: Record<string, string>;
  verifyAuthToken: (walletAddress: string, authToken: string) => Promise<Boolean>;
  initUserAuthentication: () => Promise<void>;
  web3Token: string;
  isValidToken: boolean;
  isVerifying: boolean;
  walletConnected: boolean;
};

const Web3TokenAuthContext = createContext<ContextValue>({
  web3TokensMap: {},
  verifyAuthToken: async () => {
    return false;
  },
  initUserAuthentication: async () => {
    throw new Error('Not implemented yet');
  },
  web3Token: '',
  isValidToken: false,
  isVerifying: false,
  walletConnected: false
});

export const Web3TokenAuthProvider: FC = memo(({ children }) => {
  const { isUsingUserWallet, account, provider } = useWeb3Context();
  const [web3TokensMap, setWeb3TokensMap] = useState<Record<string, string>>(
    getWeb3TokensMapFromStorage()
  );

  // update the local storage when web3TokensMap changes
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_WEB3_TOKENS_MAP_CACHE, JSON.stringify(web3TokensMap));
  }, [web3TokensMap]);

  const clearWeb3Token = useCallback((walletAddress: string) => {
    setWeb3TokensMap(prev => ({ ...prev, [walletAddress]: '' }));
  }, []);

  const verifyAuthToken = useCallback(
    async (walletAddress: string, authToken: string): Promise<boolean> => {
      try {
        return provider.verifyToken(authToken);
      } catch (err) {
        clearWeb3Token(walletAddress);
        return false;
      }
    },
    [clearWeb3Token, provider]
  );

  const initUserAuthentication = useCallback(async () => {
    if (!isUsingUserWallet || !account) {
      return;
    }

    try {
      const signer = provider.getSigner();
      const token = await Web3Token.sign((msg: string) => signer.signMessage(msg), {
        domain: 'para.space',
        expires_in: '7 days'
      });
      setWeb3TokensMap(prev => ({ ...prev, [account]: token }));
    } catch (err) {
      setWeb3TokensMap(prev => ({ ...prev, [account]: '' }));
      throw getUserFriendlyError(err);
    }
  }, [account, isUsingUserWallet, provider]);

  const web3Token = useMemo(() => web3TokensMap[account] ?? '', [account, web3TokensMap]);

  const [tokenState, setTokenState] = useState<{
    isVerifying: boolean;
    isValidToken: boolean;
    walletConnected: boolean;
  }>({
    isVerifying: false,
    isValidToken: Boolean(web3Token),
    walletConnected: !!account
  });

  useAsyncEffect(async () => {
    setTokenState({
      isVerifying: true,
      isValidToken: Boolean(web3Token),
      walletConnected: !!account
    });

    try {
      const isValid = await verifyAuthToken(account, web3Token);
      setTokenState({
        isVerifying: false,
        isValidToken: isValid,
        walletConnected: !!account
      });
    } catch {
      setTokenState({
        isVerifying: false,
        isValidToken: false,
        walletConnected: !!account
      });
    }
  }, [verifyAuthToken, account, web3Token]);

  const value = useMemo(
    () => ({
      web3TokensMap,
      verifyAuthToken,
      initUserAuthentication,
      web3Token,
      ...tokenState
    }),
    [initUserAuthentication, tokenState, verifyAuthToken, web3Token, web3TokensMap]
  );

  return <Web3TokenAuthContext.Provider value={value}>{children}</Web3TokenAuthContext.Provider>;
});

export const useWeb3TokenAuth = () => useContext(Web3TokenAuthContext);
