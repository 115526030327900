import { useCallback, useMemo } from 'react';
import { MysteryBeanService } from 'paraspace-utilities-contract-helpers';
import { Environment } from 'parax-sdk';

import { useWeb3Context } from '@/apps/paraspace/contexts';

export const mysteryBeanContractAddressMap = {
  [Environment.DEVELOPMENT]: '0x7E43618DE087fc5137f633f81865b1439ac92390',
  [Environment.STAGING]: '0x69aB4C12fdec0b51c596353CF79FF7E83d2380f0',
  [Environment.PRODUCTION]: '0x3Af2A97414d1101E2107a70E7F33955da1346305'
};

export const getMysteryBeanContractAddress = (env: Environment) =>
  mysteryBeanContractAddressMap[env];

export const useMysteryBean = () => {
  const { provider, env } = useWeb3Context();

  const mysteryBeanService = useMemo(
    () => new MysteryBeanService(provider, getMysteryBeanContractAddress(env)),
    [provider, env]
  );

  const filterClaimableTokenIds = useCallback(
    (tokenIds: number[]) => {
      return mysteryBeanService.filterClaimableTokenIds(tokenIds);
    },
    [mysteryBeanService]
  );

  const contractInstance = useMemo(
    () => mysteryBeanService.getContractInstance(mysteryBeanService.contractAddress),
    [mysteryBeanService]
  );

  return { filterClaimableTokenIds, contractInstance };
};
