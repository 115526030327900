import BigNumberJs from 'bignumber.js';
import { useCallback, useState } from 'react';

import { tryRoundUpCapeBalance } from '../utils';

import useLegacyERC20 from '@/apps/paraspace/pages/hooks/useLegacyERC20';
import {
  ApeCoinListing,
  ApeListing,
  BakcListing
} from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/types';
import useP2PPairStaking from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import useLegacyERC721 from '@/apps/paraspace/pages/hooks/useLegacyERC721';
import { useCheckApeStakedState } from '@/apps/paraspace/pages/hooks/ApeStaking/useCheckApeStakedState';
import { ApeStakingTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useContractsMap } from '@/apps/paraspace/hooks';

export const useApeCoinListingValidate = () => {
  // 1. cape balance
  // 2. cape allowance
  // 3. listing status
  // 4. listing start & end time

  const contracts = useContractsMap();
  const { getBalance, getAllowance } = useLegacyERC20(contracts.cAPE);
  const { isListingPending } = useP2PPairStaking();
  const [reason, setReason] = useState<Maybe<string>>(null);

  const validate = useCallback(
    async ({ offerer, listingHash, startDate, expirationDate, amount }: ApeCoinListing) => {
      try {
        const [balance, allowance, listingPending] = await Promise.all([
          getBalance(offerer),
          getAllowance(contracts.P2PPairStaking, offerer),
          isListingPending(listingHash)
        ]);

        if (tryRoundUpCapeBalance(balance.shiftedBy(-18)).lt(amount)) {
          setReason('Insufficient cAPE balance from ApeCoin offer owner');
        } else if (new BigNumberJs(allowance).lt(amount)) {
          setReason('Insufficient cAPE allowance from ApeCoin offer owner');
        } else if (!listingPending) {
          setReason('The ApeCoin offer has already been fulfilled or cancelled');
        } else if (!(Date.now() >= startDate.valueOf())) {
          setReason('The ApeCoin offer has not started yet');
        } else if (!(expirationDate.valueOf() > Date.now())) {
          setReason('The ApeCoin offer has already been expired');
        }
      } catch (e) {
        console.error('Error while validate listing', e);
      }
    },
    [contracts.P2PPairStaking, getAllowance, getBalance, isListingPending]
  );

  return [validate, reason] as [typeof validate, string];
};

export const useApeOrBakcListingValidate = (apeSymbol: ApeStakingTokenSymbol) => {
  // 1. ape supplied
  // 2. listing status
  // 3. listing start & end time
  // 4. ape not staked
  const { nftInfoMap } = useMMProvider();
  const { isListingPending } = useP2PPairStaking();
  const { xTokenAddress } = nftInfoMap[apeSymbol];
  const { ownerOf } = useLegacyERC721(xTokenAddress);
  const apeStakedStatus = useCheckApeStakedState();

  const [reason, setReason] = useState('');

  const validate = useCallback(
    async ({
      offerer,
      tokenId,
      listingHash,
      startDate,
      expirationDate,
      stakingPool
    }: ApeListing | BakcListing) => {
      try {
        const [ownerOfNToken, listingPending, apeStakedStatusList] = await Promise.all([
          ownerOf(tokenId),
          isListingPending(listingHash),
          apeStakedStatus([
            {
              tokenId,
              symbol: apeSymbol,
              targetPool: stakingPool
            }
          ])
        ]);
        const { isStaked, isInP2P, isPaired } = apeStakedStatusList[0];

        const isAlreadyStaked =
          stakingPool === ERC721Symbol.BAKC && apeSymbol !== ERC721Symbol.BAKC
            ? isStaked && !isInP2P
            : isStaked;

        if (isAlreadyStaked) {
          setReason('The BAYC/MAYC/BAKC provided by other party has already been staked');
        } else if (ownerOfNToken !== offerer) {
          setReason(
            'The BAYC/MAYC/BAKC provided by other party has already been transferred to others'
          );
        } else if (!listingPending) {
          setReason('The BAYC/MAYC/BAKC offer has already been matched or cancelled');
        } else if (!(Date.now() >= startDate.valueOf())) {
          setReason('The BAYC/MAYC/BAKC offer has not started yet');
        } else if (!(expirationDate.valueOf() > Date.now())) {
          setReason('The BAYC/MAYC/BAKC offer has already been expired');
        } else if (isPaired) {
          setReason('The BAYC/MAYC provided by other party has already been paired with BAKC');
        }
      } catch (e) {
        console.error('Error while validate listing', e);
      }
    },
    [apeStakedStatus, apeSymbol, isListingPending, ownerOf]
  );
  return [validate, reason] as [typeof validate, string];
};
