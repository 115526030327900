import { ColorMode } from '@parallel-mono/components';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { ThemedString } from '@/apps/consts';

export const getThemedString = (string: ThemedString, mode: ColorMode) => {
  if (typeof string === 'string') {
    return string;
  }
  if (mode === ColorMode.dark) {
    return string.dark;
  }
  return string.light;
};

export const useThemedString = (string: ThemedString) => {
  const { mode } = useTheme();
  return useMemo(() => getThemedString(string, mode), [mode, string]);
};
