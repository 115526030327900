import { HostedImage } from '@parallel-mono/business-components';
import { Image, Inline, InlineProps } from '@parallel-mono/components';
import { memo, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';

import { Tooltip } from '@/apps/parax/components';
import { getThemedString } from '@/apps/parax/hooks';
import { AppConfig } from '@/apps/consts';

const AppItem = styled(Inline)<{ active: boolean; disabled?: boolean }>`
  height: 5rem;
  width: 5rem;
  background: ${({ active, theme }) => {
    if (!active) return 'transparent';
    return theme.skin.background.sub1;
  }};
  opacity: ${({ disabled, theme }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

type Props = Omit<InlineProps, 'children'> & {
  icon: string;
  active: boolean;
  app: AppConfig;
  onAppChange: (app: AppConfig, path?: string) => void;
  disabled?: boolean;
};

export const AppCard = memo(({ onAppChange, icon, app, active, disabled, ...others }: Props) => {
  const handleAppChange = useCallback(() => {
    if (!disabled) {
      onAppChange(app);
    }
  }, [disabled, onAppChange, app]);

  const handleGoToDomainApp = useCallback(
    (path: string) => {
      if (!disabled) {
        onAppChange(app, path);
      }
    },
    [disabled, onAppChange, app]
  );

  const { mode: colorMode } = useTheme();
  if (app.type === 'internal' && (app.domainApps?.length ?? 0) > 0) {
    return (
      <>
        {app.domainApps!.map((domainApp, index) => (
          <AppItem
            key={`${domainApp.path}-${index}`}
            onClick={() => handleGoToDomainApp(domainApp.path)}
            disabled={disabled}
            active={false}
            alignItems="center"
            justifyContent="center"
          >
            <Tooltip placement="right-start" disabled={!disabled} content="Coming Soon...">
              <HostedImage
                height="2rem"
                width="2rem"
                name={getThemedString(domainApp.icon, colorMode)}
                fallback={
                  <Image
                    src={getThemedString(domainApp.icon, colorMode)}
                    height="2rem"
                    width="2rem"
                  />
                }
              />
            </Tooltip>
          </AppItem>
        ))}
      </>
    );
  }

  return (
    <AppItem
      onClick={handleAppChange}
      disabled={disabled}
      active={active}
      alignItems="center"
      justifyContent="center"
      {...others}
    >
      <Tooltip placement="right-start" disabled={!disabled} content="Coming Soon...">
        <HostedImage
          height="2rem"
          width="2rem"
          name={icon}
          fallback={<Image src={icon} height="2rem" width="2rem" />}
        />
      </Tooltip>
    </AppItem>
  );
});
