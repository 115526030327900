import { useState, useMemo, useCallback } from 'react';

import { emptyArray } from '@/apps/paraspace/consts/values';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';

export const useSelectionManager = (symbol: ERC721Symbol) => {
  const { nftInfoMap } = useMMProvider();
  const { nftSuppliedList = emptyArray, nftCollateralList = emptyArray } = nftInfoMap[symbol];
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const { checkIfTokenInPairing } = useP2PInfo();

  const selectableItems = useMemo(() => {
    return nftSuppliedList.map(id => {
      return {
        id,
        isCollateral: nftCollateralList.includes(id),
        isInPair: isNFTinvolvedWithApeStaking(symbol) ? checkIfTokenInPairing(symbol, id) : false
      };
    });
  }, [nftSuppliedList, nftCollateralList, checkIfTokenInPairing, symbol]);

  const handleItemChange = useCallback((checked, { tokenId }) => {
    setSelectedItemIds(prev => {
      return checked ? [...prev, tokenId] : prev.filter(it => it !== tokenId);
    });
  }, []);

  const handleSelectAll = useCallback(() => {
    setSelectedItemIds(selectableItems.map(v => v.id));
  }, [selectableItems]);

  const handleUnSelectAll = useCallback(() => {
    setSelectedItemIds([]);
  }, []);

  return {
    selectedItemIds,
    handleItemChange,
    selectableItems,
    handleSelectAll,
    handleUnSelectAll
  };
};
