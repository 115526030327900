import { memo, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { BigText, Button, Icon, Inline, Stack, StackProps, Text } from '@parallel-mono/components';
import { useNavigate } from 'react-router-dom';

import { ContractBanner } from '../../../components/ContractBanner';
import { ApeCoinListing, ApeListing, BakcListing } from '../../../types';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type SuccessStateProps = Omit<StackProps, 'children'> & {
  apeCoinListing: Maybe<ApeCoinListing>;
  apeListing: Maybe<ApeListing>;
  bakcListing?: Maybe<BakcListing>;
  onFinish?: () => void;
};

const CenteredText = styled(Text)`
  text-align: center;
`;

export const JoinListingSuccessState = memo(
  ({ apeListing, bakcListing, apeCoinListing, onFinish, ...others }: SuccessStateProps) => {
    const { account } = useWeb3Context();

    const navigate = useNavigate();

    const handleViewPosition = useCallback(() => {
      navigate('my-positions');
      onFinish?.();
    }, [onFinish, navigate]);

    const theme = useTheme();

    return (
      <Stack alignItems="center" {...others}>
        <ContractBanner
          account={account}
          bakcListing={bakcListing}
          apeListing={apeListing}
          apeCoinListing={apeCoinListing}
        />
        <Stack alignItems="center" gap="0">
          <Icon name="checkContained" size="xlarge" color={theme.skin.success.main} />
          <BigText fontWeight="bold">Staking Successful</BigText>
          <CenteredText skin="secondary">You can view your position any time.</CenteredText>
        </Stack>
        <Inline width="100%">
          <Button size="large" block skin="primary" onClick={onFinish}>
            Done
          </Button>
          <Button size="large" block skin="secondary" onClick={handleViewPosition}>
            View Position
          </Button>
        </Inline>
      </Stack>
    );
  }
);
