import { useCallback, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';

import { useLegacyOfficialApeStaking } from './useLegacyOfficialApeStaking';

import { ApeStakingTokenSymbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type ApeStakingInfo = {
  stakeLimit: BigNumber;
  stakedAmount: BigNumber;
  pendingRewards: BigNumber;
  apy: BigNumber;
};

export const useApeTokenStakingInfo = ({
  symbol,
  tokenId,
  owner
}: {
  symbol: Maybe<ApeStakingTokenSymbol>;
  tokenId: string | null;
  owner: string;
}): [ApeStakingInfo | null, () => void] => {
  const [apeStakingInfo, setApeStakingInfo] = useState<ApeStakingInfo | null>(null);
  const { getStakingInfoByTokenInfo } = useLegacyOfficialApeStaking();

  const refresh = useCallback(() => {
    if (!symbol || !tokenId) {
      return;
    }
    getStakingInfoByTokenInfo(symbol, tokenId, owner)
      .then(res => {
        if (res) {
          setApeStakingInfo({
            stakeLimit: res.stakeLimit,
            stakedAmount: res.stakedAmount,
            pendingRewards: res.pendingRewards,
            apy: new BigNumber(res.apy.toString())
          });
        }
      })
      .catch(null);
  }, [getStakingInfoByTokenInfo, symbol, tokenId, owner]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [apeStakingInfo, refresh];
};
