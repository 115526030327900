import { H4, H5, H6, Inline, SmallText, Stack, Tag, Tabs } from '@parallel-mono/components';
import styled from 'styled-components';
import { memo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';

import { CollapseWithShadow, StackWithBorder } from './OperationCard';

import { ERC20Symbol } from '@/apps/paraspace/typings';

const SmallTextWithAlign = styled(SmallText)`
  text-align: center;
`;

interface IContentProps {
  token0Symbol: ERC20Symbol;
  token1Symbol: ERC20Symbol;
  min: BigNumber;
  max: BigNumber;
  current: BigNumber;
}

const Content = ({ token0Symbol, token1Symbol, min, max, current }: IContentProps) => {
  return (
    <Stack gap="1rem">
      <Inline gap="1rem">
        <StackWithBorder gap=".5rem" alignItems="center">
          <H6 skin="secondary" fontWeight="medium">
            Min Price
          </H6>
          <Inline gap=".25rem" alignItems="center">
            <H4>{formatNumber(min, { decimal: 4 })}</H4>
            <H6 skin="secondary" fontWeight="medium">
              {token1Symbol} per {token0Symbol}
            </H6>
          </Inline>
          <SmallTextWithAlign skin="secondary">
            Your position will be 100% {token0Symbol} at this price
          </SmallTextWithAlign>
        </StackWithBorder>
        <StackWithBorder gap=".5rem" alignItems="center">
          <H6 skin="secondary" fontWeight="medium">
            Max Price
          </H6>
          <Inline gap=".25rem" alignItems="center">
            <H4>{max.isFinite() ? formatNumber(max, { decimal: 4 }) : '∞'}</H4>
            <H6 skin="secondary" fontWeight="medium">
              {token1Symbol} per {token0Symbol}
            </H6>
          </Inline>
          <SmallTextWithAlign skin="secondary">
            Your position will be 100% {token1Symbol} at this price
          </SmallTextWithAlign>
        </StackWithBorder>
      </Inline>
      <StackWithBorder gap=".5rem" alignItems="center">
        <H6 skin="secondary" fontWeight="medium">
          Current Price
        </H6>
        <Inline gap=".25rem" alignItems="center">
          <H4>{formatNumber(current, { decimal: 4 })}</H4>
          <H6 skin="secondary" fontWeight="medium">
            {token1Symbol} per {token0Symbol}
          </H6>
        </Inline>
      </StackWithBorder>
    </Stack>
  );
};

const RelativeWrapper = styled.div`
  position: relative;
  margin-top: 1.5rem;
`;

const StyledTabs = styled(Tabs)`
  justify-content: right;
  margin-bottom: 1.5rem;
  .tab {
    padding: 0.25rem;
    width: 6.25rem;
  }
`;

const TagWithPosition = styled(Tag)`
  position: absolute;
  top: 0;
  left: 0;
`;

interface IPriceRangeProps {
  isInRange: boolean;
  isClosed: boolean;
  token0Symbol: ERC20Symbol;
  token1Symbol: ERC20Symbol;
  token0ConvertToken1CurrentPrice: BigNumber;
  token0ConvertToken1MaxPrice: BigNumber;
  token0ConvertToken1MinPrice: BigNumber;
  token1ConvertToken0CurrentPrice: BigNumber;
  token1ConvertToken0MaxPrice: BigNumber;
  token1ConvertToken0MinPrice: BigNumber;
}

const PriceRange = ({
  isClosed,
  isInRange,
  token0Symbol,
  token1Symbol,
  token0ConvertToken1CurrentPrice,
  token0ConvertToken1MaxPrice,
  token0ConvertToken1MinPrice,
  token1ConvertToken0CurrentPrice,
  token1ConvertToken0MaxPrice,
  token1ConvertToken0MinPrice
}: IPriceRangeProps) => {
  const tabs = [
    {
      title: <H5>{token0Symbol}</H5>,
      content: (
        <Content
          token0Symbol={token0Symbol}
          token1Symbol={token1Symbol}
          min={token0ConvertToken1MinPrice}
          max={token0ConvertToken1MaxPrice}
          current={token0ConvertToken1CurrentPrice}
        />
      )
    },
    {
      title: <H5>{token1Symbol}</H5>,
      content: (
        <Content
          token0Symbol={token1Symbol}
          token1Symbol={token0Symbol}
          min={token1ConvertToken0MinPrice}
          max={token1ConvertToken0MaxPrice}
          current={token1ConvertToken0CurrentPrice}
        />
      )
    }
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <CollapseWithShadow header="Price Range" defaultOpen>
      <RelativeWrapper>
        <StyledTabs
          classNames={{ tab: 'tab' }}
          tabs={tabs}
          activeTab={tabs[selectedIndex]}
          onActiveTabChange={(_, index) => setSelectedIndex(index)}
        />
        {tabs[selectedIndex].content}
        {isClosed ? (
          <TagWithPosition skin="neutral" size="small">
            Closed
          </TagWithPosition>
        ) : (
          <TagWithPosition skin={isInRange ? 'success' : 'warning'} size="small">
            {isInRange ? 'In Range' : 'Out of Range'}
          </TagWithPosition>
        )}
      </RelativeWrapper>
    </CollapseWithShadow>
  );
};

export const PriceRangeSection = memo(PriceRange);
