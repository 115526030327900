import { Network } from 'paraspace-configs-v2';
import { Environment } from 'parax-sdk';

import { SupportedNetworks } from '../../typings';

type EnvironmentEndpoints = Record<Environment, string>;

const ENDPOINT_MAP: EnvironmentEndpoints = {
  [Environment.PRODUCTION]: 'https://api.para.space/graphql/ethereum',
  [Environment.STAGING]: 'https://api-staging.para.space/graphql/ethereum',
  [Environment.DEVELOPMENT]: 'https://api-dev.para.space/graphql/ethereum'
};

const ENDPOINT_MAP_ZKSYNC: EnvironmentEndpoints = {
  [Environment.PRODUCTION]: 'https://api.para.space/graphql/zksync',
  [Environment.STAGING]: 'https://api-staging.para.space/graphql/zksync',
  [Environment.DEVELOPMENT]: 'https://api-dev.para.space/graphql/zksync'
};

const ENDPOINT_MAP_ARBITRUM: EnvironmentEndpoints = {
  [Environment.PRODUCTION]: 'https://api.para.space/graphql/arbitrum',
  [Environment.STAGING]: 'https://api-staging.para.space/graphql/arbitrum',
  [Environment.DEVELOPMENT]: 'https://api-dev.para.space/graphql/arbitrum'
};

const ENDPOINT_MAP_PARALLEL_L3: EnvironmentEndpoints = {
  [Environment.PRODUCTION]: 'https://api-prod-para-l3.para.space/graphql',
  [Environment.STAGING]: 'https://api-staging.para.space/graphql/parallel-l3',
  [Environment.DEVELOPMENT]: 'https://api-dev.para.space/graphql/parallel-l3'
};

export const GRAPHQL_ENDPOINT_FOR_NETWORK_MAP: Record<SupportedNetworks, EnvironmentEndpoints> = {
  [Network.SEPOLIA]: ENDPOINT_MAP,
  [Network.MAINNET]: ENDPOINT_MAP,
  [Network.ZKSYNC_GOERLI]: ENDPOINT_MAP_ZKSYNC,
  [Network.ZKSYNC_ERA]: ENDPOINT_MAP_ZKSYNC,
  [Network.ARBITRUM_GOERLI]: ENDPOINT_MAP_ARBITRUM,
  [Network.ARBITRUM_ONE]: ENDPOINT_MAP_ARBITRUM,
  [Network.PARALLEL_L3_TESTNET]: ENDPOINT_MAP_PARALLEL_L3
};
