import React, {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { map, pick } from 'lodash';
import { DeploylessViewerClient } from 'deployless-view';

import { ApeStakingTokenSymbol, ERC721Symbol, FetchingStatus } from '@/apps/paraspace/typings';
import { createStableSnapshotFromPreviousSnapshot } from '@/apps/paraspace/utils/createStableSnapshotFromPreviousSnapshot';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { Feature } from '@/apps/paraspace/config';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useAppConfig, useContractsMap } from '@/apps/paraspace/hooks';

type P2PStakingSummary = {
  [k in ApeStakingTokenSymbol]: {
    tokenIds: number[];
    amount: number;
  };
};

type P2PInfoValue = {
  p2pStakingSummary: Maybe<P2PStakingSummary>;
  poll: () => Promise<void>;
  checkIfTokenInPairing: (symbol: ERC721Symbol, tokenId: number) => boolean;
  pollingStatus: FetchingStatus;
};

const P2PInfoContext = React.createContext<P2PInfoValue>({
  p2pStakingSummary: null,
  poll: async () => {
    throw new Error('Not implemented yet');
  },
  checkIfTokenInPairing: () => false,
  pollingStatus: FetchingStatus.INIT
});

type P2PInfoProviderProps = {
  children: ReactNode;
  pollingInterval: number;
};

export const P2PInfoProvider = memo(({ children, pollingInterval }: P2PInfoProviderProps) => {
  const { provider } = useWeb3Context();
  const { features } = useAppConfig();
  const apeStakingEnabled = useMemo(() => features.includes(Feature.ApeStaking), [features]);
  const contracts = useContractsMap();

  const viewer = useMemo(() => {
    if (!provider) {
      return null;
    }

    return new DeploylessViewerClient(provider);
  }, [provider]);

  const [contextData, setContextData] = useState<
    Omit<P2PInfoValue, 'poll' | 'checkIfTokenInPairing'>
  >({
    p2pStakingSummary: null,
    pollingStatus: FetchingStatus.INIT
  });

  const { erc721Config } = useAppConfig();

  const loadBalance = useCallback(async () => {
    if (!provider || !viewer) {
      return null;
    }

    const apesConfigInfo = pick(erc721Config, [
      ERC721Symbol.BAYC,
      ERC721Symbol.MAYC,
      ERC721Symbol.BAKC
    ]);

    try {
      const nftInfo = map(apesConfigInfo, ({ contractName, nftEnumerableType }) => {
        return {
          nftType: nftEnumerableType,
          nft: contracts[contractName]
        };
      });
      const rawData = await viewer.batchGetAllTokensByOwner(contracts.P2PPairStaking, nftInfo);
      const nftBalanceList = [...rawData.tokenInfos[0][0]];
      return {
        [ERC721Symbol.BAYC]: {
          tokenIds: nftBalanceList[0],
          amount: nftBalanceList[0].length
        },
        [ERC721Symbol.MAYC]: {
          tokenIds: nftBalanceList[1],
          amount: nftBalanceList[1].length
        },
        [ERC721Symbol.BAKC]: {
          tokenIds: nftBalanceList[2],
          amount: nftBalanceList[2].length
        }
      };
    } catch (e) {
      console.error('Error occurred in P2PInfoProvider#loadBalance');
      console.error(e);
    }
    return null;
  }, [contracts, provider, viewer, erc721Config]);

  const p2pInfoLoad = useCallback(async () => {
    setContextData(curr => ({
      ...curr,
      pollingStatus: FetchingStatus.FETCHING
    }));
    try {
      const balance = await loadBalance();
      setContextData(curr =>
        createStableSnapshotFromPreviousSnapshot(
          {
            p2pStakingSummary: balance,
            pollingStatus: FetchingStatus.SUCCESS
          },
          curr
        )
      );
    } catch {
      setContextData(curr => ({
        ...curr,
        pollingStatus: FetchingStatus.FAIL
      }));
    }
  }, [loadBalance]);

  const checkIfTokenInPairing = useCallback(
    (symbol: ERC721Symbol, tokenId: number) =>
      contextData.p2pStakingSummary?.[symbol as ApeStakingTokenSymbol]?.tokenIds?.includes(
        tokenId
      ) ?? false,
    [contextData.p2pStakingSummary]
  );

  useEffect(() => {
    if (!apeStakingEnabled) return undefined;
    if (contextData.pollingStatus === FetchingStatus.INIT) {
      p2pInfoLoad();
      return undefined;
    }
    if (contextData.pollingStatus !== FetchingStatus.FETCHING) {
      const timer = setTimeout(p2pInfoLoad, pollingInterval);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [p2pInfoLoad, contextData.pollingStatus, pollingInterval, apeStakingEnabled]);

  const contextValue = useMemo(
    () => ({
      ...contextData,
      poll: p2pInfoLoad,
      checkIfTokenInPairing
    }),
    [contextData, p2pInfoLoad, checkIfTokenInPairing]
  );

  return <P2PInfoContext.Provider value={contextValue}>{children}</P2PInfoContext.Provider>;
});

export const useP2PInfo = () => useContext(P2PInfoContext);
