import { Stack, Inline, H4, H5, Skeleton, Text, H1, Image } from '@parallel-mono/components';
import styled from 'styled-components';
import { useMemo } from 'react';
import { CryptoIcon } from '@parallel-mono/business-components';
import { useSpring, animated } from 'react-spring';
import { formatNumber } from '@parallel-mono/utils';

import apeBg from '../../Images/ape-bg.svg';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { StackedIcons, ConnectWallet } from '@/apps/paraspace/components';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';

const Container = styled(Stack)`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 100%;
  height: fit-content;
`;

const StyledText = styled(Text)`
  max-width: 31.25rem;
  text-align: center;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    max-width: 100%;
  `};
`;

const StyledIconWrapper = styled(Inline)`
  position: relative;
  width: 9.375rem;
  height: 9.375rem;
`;

const StyledIconBg = styled(Image)`
  position: absolute;
  margin: 0;
`;

const assets = [
  {
    symbol: ERC721Symbol.BAYC,
    tokenId: 7029
  },
  {
    symbol: ERC721Symbol.MAYC,
    tokenId: 24058
  },
  {
    symbol: ERC721Symbol.MAYC,
    tokenId: 24059
  }
];

export const ContentContainer = () => {
  const { apeStakingPoolSummary } = useAutoCompoundApeInfo();

  const apeNftAmount = useMemo(() => {
    return apeStakingPoolSummary?.BAYC?.amount?.plus(apeStakingPoolSummary?.MAYC.amount);
  }, [apeStakingPoolSummary?.BAYC?.amount, apeStakingPoolSummary?.MAYC.amount]);

  const props = useSpring({
    from: { opacity: 0, width: '70%', height: '70%', transform: 'rotate(360deg)' },
    to: async next => {
      await next({
        to: { opacity: 1, width: '100%', height: '100%' },
        delay: 1800
      });
      await next({
        from: { transform: 'rotate(360deg)' },
        to: [
          { transform: 'rotate(270deg)', delay: 1000 },
          { transform: 'rotate(180deg)', delay: 1000 },
          { transform: 'rotate(360deg)', delay: 1000 }
        ],
        config: { duration: 2000 },
        loop: true
      });
    }
  });

  return (
    <Container alignItems="center" gap="1.5rem">
      <StyledIconWrapper alignItems="center" justifyContent="center">
        <CryptoIcon symbol="APE" size="70%" />
        <StyledIconBg src={apeBg} as={animated.img} style={props} />
      </StyledIconWrapper>
      <Stack alignItems="center" gap="1.5rem">
        <Stack alignItems="center" justifyContent="center" gap="0.75rem">
          <H1>Earn More Auto-Compounding</H1>
          <StyledText skin="secondary">
            Earn more rewards across APE Staking pools, manage all your APE staking, and access
            instant APE liquidity for staking.
          </StyledText>
        </Stack>
        <Inline width="20rem">
          <ConnectWallet skin="primary" btnSize="large" btnTxt="Connect Wallet" />
        </Inline>
        <Inline gap="0.75rem">
          <StackedIcons assets={assets} />
          <Stack gap="0">
            {apeNftAmount ? (
              <H4>{formatNumber(apeNftAmount ?? 0)} BAYC and MAYC</H4>
            ) : (
              <Skeleton.Button height="1.5rem" variant="round" />
            )}
            <H5 skin="secondary" fontWeight="medium">
              Staked on ParaSpace
            </H5>
          </Stack>
        </Inline>
      </Stack>
    </Container>
  );
};
