import styled from 'styled-components';
import { SmallText, Tag, Text } from '@parallel-mono/components';

export const PendingTag = styled(Tag).attrs({
  size: 'small'
})`
  padding: 0.125rem 0.75rem;
  width: fit-content;
`;

export const GreyText = styled(SmallText)`
  color: ${({ theme }) => theme.skin.grey[500]};
`;

export const CenteredText = styled(Text)`
  text-align: center;
`;
