import { memo } from 'react';
import { Alert, AlertProps } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import { useAccountLiquidationStatus } from '../hooks/useAccountLiquidationStatus';
import { RECOVERY_NFT_HF_THRESHOLD } from '../config';

type LiquidationAlertProps = Omit<AlertProps, 'children'>;

export const LiquidationAlert = memo((props: LiquidationAlertProps) => {
  const { inLiquidation, healthFactor } = useAccountLiquidationStatus();
  if (!inLiquidation) {
    return null;
  }

  return (
    <Alert type="error" {...props}>
      Your assets are in liquidation because your health factor is low (
      {formatNumber(healthFactor?.toNumber() ?? 0)}). Repay your debt or add more collateral to
      recover your health factor to above {RECOVERY_NFT_HF_THRESHOLD} to remove these assets from
      liquidation.
    </Alert>
  );
});
