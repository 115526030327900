import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
  Button,
  H3,
  Icon,
  Inline,
  SmallText,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import { useTheme } from 'styled-components';
import { InfoPanel } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import { ContractBanner } from '../../../components/ContractBanner';
import { ApeCoinListing, ApeCoinSourceToJoinApeListing, ApeListing } from '../../../types';
import { ApeCoinSourceSelect } from '../../../components';
import { tryRoundUpCapeBalance } from '../../../utils';
import { InvalidListingAlert } from '../components';
import { useApeOrBakcListingValidate } from '../../../hooks';

import { CenteredText } from './styledComponents';

import { ERC20Symbol, WalletType } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/ApePairing/consts';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { one } from '@/apps/paraspace/consts/values';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';

type JoinListingWithApeCoinFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  apeListing: ApeListing;
  walletType: WalletType;
  onSubmit: (source: ApeCoinSourceToJoinApeListing) => void;
};

export const JoinListingWithApeCoinForm = memo(
  ({ apeListing, walletType, onSubmit, ...others }: JoinListingWithApeCoinFormProps) => {
    const { erc20InfoMap, userInfoLoaded } = useMMProvider();
    const { erc20BalanceMap } = useEOABalances();
    const { nftPoolsCompoundApy } = useAutoCompoundApeInfo();
    const { expirationDate, offerer, share, stakingPool } = apeListing;
    const requiredApeCoinAmount = STAKE_LIMIT[stakingPool];
    const apy = nftPoolsCompoundApy?.[stakingPool]?.toNumber() ?? 0;

    const apeBalance =
      walletType === 'AA'
        ? erc20InfoMap[ERC20Symbol.APE]?.balance
        : erc20BalanceMap?.[ERC20Symbol.APE];

    const cApeBalance =
      walletType === 'AA'
        ? erc20InfoMap[ERC20Symbol.CAPE]?.balance
        : erc20BalanceMap?.[ERC20Symbol.CAPE];

    const [apeCoinSource, setApeCoinSource] = useState<ApeCoinSourceToJoinApeListing>(
      ApeCoinSourceToJoinApeListing.APE_BALANCE
    );

    const balance = useMemo(() => {
      if (apeCoinSource === ApeCoinSourceToJoinApeListing.APE_BALANCE) {
        return apeBalance;
      }
      return cApeBalance ? tryRoundUpCapeBalance(cApeBalance) : cApeBalance;
    }, [apeBalance, apeCoinSource, cApeBalance]);

    const { account } = useWeb3Context();

    const theme = useTheme();

    const timeBeforeExpiration = useMemo(
      () => dayjs(expirationDate).fromNow(true),
      [expirationDate]
    );

    const yourShare = useMemo(
      () => one.minus(offerer === account ? 0 : share),
      [account, offerer, share]
    );

    const infos = useMemo(
      () => [
        {
          title: 'Your APY',
          value: (
            <Stack gap="0" alignItems="flex-end">
              <Text skin="success">
                {formatNumber(yourShare.multipliedBy(apy), {
                  output: 'percent'
                })}
              </Text>
              <SmallText skin="secondary">
                {formatNumber(yourShare, { output: 'percent' })} of total{' '}
                {formatNumber(apy, { output: 'percent' })} pool APY
              </SmallText>
            </Stack>
          )
        },
        {
          title: 'Stake Amount',
          value: <SmallText fontWeight="bold">{formatNumber(requiredApeCoinAmount)} APE</SmallText>
        }
      ],
      [yourShare, apy, requiredApeCoinAmount]
    );

    const insufficientBalance = !balance?.gte(requiredApeCoinAmount);

    const buttonContent = useMemo(() => {
      if (!userInfoLoaded) {
        return 'Checking Your Balance';
      }

      if (insufficientBalance) {
        return 'Insufficient Balance';
      }

      return 'Confirm';
    }, [insufficientBalance, userInfoLoaded]);

    const [apeListingValidator, validationResult] = useApeOrBakcListingValidate(apeListing.symbol);

    useEffect(() => {
      apeListingValidator(apeListing);
    }, [apeListing, apeListingValidator]);

    const disableConfirmButton = useMemo(() => {
      return insufficientBalance || !!validationResult;
    }, [insufficientBalance, validationResult]);

    const handleSubmit = useCallback(() => {
      onSubmit(apeCoinSource);
    }, [onSubmit, apeCoinSource]);

    const apeCoinListingToBe: Partial<ApeCoinListing> = useMemo(
      () => ({
        offerer: account
      }),
      [account]
    );

    return (
      <Stack gap="1rem" {...others}>
        <ContractBanner
          apeListing={apeListing}
          apeCoinListing={apeCoinListingToBe}
          bakcListing={null}
          account={account}
        />
        <Inline justifyContent="center">
          <SmallText skin="secondary">Offer expires in {timeBeforeExpiration}</SmallText>
        </Inline>
        <Stack gap="0.25rem">
          <Inline justifyContent="center">
            <H3>Want To Ape In?</H3>
          </Inline>
          <CenteredText skin="secondary">
            Your ApeCoin rewards will be supplied to the auto-compound pool for additional APY. You
            can withdraw your position at any time.
          </CenteredText>
        </Stack>
        <Inline alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold">Join Using</Text>
          <ApeCoinSourceSelect
            walletType={walletType}
            value={apeCoinSource}
            onChange={setApeCoinSource}
          />
        </Inline>
        <InfoPanel skin="primary" infos={infos} />
        {validationResult && <InvalidListingAlert reason={validationResult} />}
        <Button
          block
          size="large"
          disabled={disableConfirmButton}
          skin={disableConfirmButton ? 'secondary' : 'primary'}
          onClick={handleSubmit}
        >
          {buttonContent}
        </Button>
        <Inline gap="0.5rem" justifyContent="center" alignItems="center">
          {insufficientBalance && (
            <SmallText skin="secondary">
              {formatNumber(requiredApeCoinAmount)} APE required to join
            </SmallText>
          )}
          {!insufficientBalance && (
            <>
              <Icon color={theme.skin.primary.main} name="heartContained" />
              <SmallText skin="secondary">Enjoy 0 fee and low gas!</SmallText>
            </>
          )}
        </Inline>
      </Stack>
    );
  }
);
