import { memo } from 'react';
import { Button, H4, Icon, Stack, StackProps, Text } from '@parallel-mono/components';
import styled from 'styled-components';

import { ContractBanner } from '../../../components/ContractBanner';
import { ApeCoinListing, ApeListing, BakcListing } from '../../../types';

import { Maybe } from '@/apps/paraspace/typings/basic';

const ErrorIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'closeContained',
  size: 'xlarge',
  color: theme.skin.error.main
}))``;

const ConfirmButton = styled(Button).attrs({
  skin: 'secondary'
})`
  margin-top: 1.5rem;
  width: 100%;
`;

const StyledUl = styled.ul`
  list-style: disc;
  padding: 0 2.75rem;

  & > * {
    line-height: 1.5;
    margin-top: 0.25rem;
  }
`;

type ErrorStateProps = Omit<StackProps, 'children'> & {
  onFinish?: () => void;
  account: string;
  apeListing: Maybe<Partial<ApeListing>>;
  bakcListing: Maybe<Partial<BakcListing>>;
  apeCoinListing: Maybe<Partial<ApeCoinListing>>;
};

export const ErrorState = memo(
  ({ onFinish, apeListing, apeCoinListing, bakcListing, account, ...others }: ErrorStateProps) => {
    return (
      <Stack justifyContent="center" alignItems="center" {...others}>
        <ContractBanner
          apeListing={apeListing}
          bakcListing={bakcListing}
          apeCoinListing={apeCoinListing}
          account={account}
        />
        <ErrorIcon />
        <Stack gap="0" alignItems="center">
          <H4>An error has occurred. Possible errors:</H4>
          <StyledUl>
            {apeCoinListing && apeCoinListing!.offerer !== account && (
              <Text as="li" skin="secondary">
                Insufficient cAPE from the other party.
              </Text>
            )}
            {!apeCoinListing ||
              (apeCoinListing!.offerer === account && (
                <Text as="li" skin="secondary">
                  Insufficient cAPE.
                </Text>
              ))}
            <Text as="li" skin="secondary">
              This offer has been taken by other users.
            </Text>
            {apeListing?.symbol && (
              <Text as="li" skin="secondary">
                This {apeListing!.symbol}/BAKC has staked amount on other staking products.
              </Text>
            )}
            {apeListing?.symbol && (
              <Text as="li" skin="secondary">
                This {apeListing!.symbol}/BAKC has been withdrawn.
              </Text>
            )}
            <Text as="li" skin="secondary">
              This offer has been taken by other users.
            </Text>
          </StyledUl>
        </Stack>
        <ConfirmButton onClick={onFinish}>Done</ConfirmButton>
      </Stack>
    );
  }
);
