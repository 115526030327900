import { Pool } from 'paraspace-utilities-contract-helpers';
import { useCallback } from 'react';
import { ParaXProvider } from 'parax-sdk';

import submitTransaction from '@/apps/paraspace/utils/submitTransaction';

const useLiquidationAPI = (service: Pool | null, provider: ParaXProvider, account: string) => {
  const setAuctionValidityTime = useCallback(
    async (user: string) => {
      if (!service || !provider) return null;
      const tx = (
        await service.setAuctionValidityTime({
          user,
          from: account
        })
      )[0];
      return submitTransaction({ provider, tx });
    },
    [service, provider, account]
  );

  const getAuctionData = useCallback(
    async (contractAddress: string, tokenId: string) => {
      if (!service) return null;
      return service.getAuctionData({
        asset: contractAddress,
        tokenId
      });
    },
    [service]
  );

  const startAuction = useCallback(
    async (user: string, asset: string, tokenId: number) => {
      if (!service || !provider) return null;
      const tx = (
        await service.startAuction({
          user,
          collateralAsset: asset,
          collateralTokenId: tokenId,
          from: account
        })
      )[0];
      return submitTransaction({ provider, tx });
    },
    [service, provider, account]
  );

  const endAuction = useCallback(
    async (user: string, asset: string, tokenId: number) => {
      if (!service || !provider) return null;
      const tx = (
        await service.endAuction({
          user,
          collateralAsset: asset,
          collateralTokenId: tokenId,
          from: account
        })
      )[0];
      return submitTransaction({ provider, tx });
    },
    [service, provider, account]
  );

  const updateERC721HFValidityTime = useCallback(
    async (user: string) => {
      if (!service || !provider) return null;
      const tx = (
        await service.setAuctionValidityTime({
          user,
          from: account
        })
      )[0];
      return submitTransaction({ provider, tx });
    },
    [service, provider, account]
  );

  const liquidationERC721 = useCallback(
    async ({
      user,
      asset,
      tokenId,
      price
    }: {
      user: string;
      asset: string;
      tokenId: number;
      price: string;
    }) => {
      if (!service || !provider) return null;
      const tx = (
        await service.liquidationERC721({
          from: account,
          user,
          collateralAsset: asset,
          collateralTokenId: tokenId,
          liquidationAmount: price,
          receiveNToken: true
        })
      )[0];
      return submitTransaction({ provider, tx });
    },
    [service, provider, account]
  );

  return {
    setAuctionValidityTime,
    getAuctionData,
    startAuction,
    endAuction,
    updateERC721HFValidityTime,
    liquidationERC721
  };
};

export default useLiquidationAPI;
