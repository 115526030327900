import { memo, useCallback, useMemo } from 'react';
import {
  DomEmergenceTaskTrigger,
  H3,
  Icon,
  Inline,
  InlineProps,
  Spinner,
  Stack,
  Text
} from '@parallel-mono/components';
import styled from 'styled-components';
import { isEmpty, isNil, uniqueId } from 'lodash';

import { BuyCartAssetForParaSpace, BuyCartAssetForReservoir } from '../context/types';
import { useBuyCartProvider } from '../context';

import { ListItemsExplore } from './ListItemsExplore';
import { GridItemsExplore } from './GridItemsExplore';

import { LayoutType } from '.';

import {
  Filter,
  ShopItemOnListingPage
} from '@/apps/paraspace/pages/Shop/pages/CollectionItemsShop/types';
import { Marketplace } from '@/apps/paraspace/generated/graphql';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useScrollContainer } from '@/apps/paraspace/contexts';

const StyledSpinner = styled(Spinner)`
  display: block;
  margin: 0 auto;
`;

const LoadMoreTrigger = styled(DomEmergenceTaskTrigger<number>)`
  text-align: center;
  padding: 2rem;
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  min-height: 600px;
`;

const TailContainer = styled(Stack)`
  max-width: 22.25rem;
  margin: 10rem auto;
`;

const NoNftText = styled(Text)`
  text-align: center;
`;

type ItemsExploreProps = {
  filter: Filter;
  assets: ShopItemOnListingPage[];
  hasNextPage: boolean;
  load: (offset?: number) => Promise<void>;
  handleEnterDetailPage: () => void;
  layoutType: LayoutType;
} & Omit<InlineProps, 'children'>;

export const ItemsExplore = memo(
  ({ filter, assets, hasNextPage, load, handleEnterDetailPage, layoutType }: ItemsExploreProps) => {
    const { basicInfoLoaded, userInfoLoaded } = useMMProvider();
    const { changeBuyCartList } = useBuyCartProvider();

    const loadMoreTriggerKey = useMemo(
      () => uniqueId(`loadMoreTriggerWith${assets.length}ExistingAssets-`),
      [assets]
    );

    const handleSelectAssets = useCallback(
      (selectedAssets: ShopItemOnListingPage[]) => {
        const buyCartAssets = selectedAssets.map(asset => {
          const { lowestPriceListing, contractAddress, tokenId, symbol, multiplier } = asset;

          const basedInfo = {
            contractAddress,
            tokenId,
            symbol,
            multiplier,
            price: lowestPriceListing?.price!,
            platform: lowestPriceListing?.platform!,
            orderHash: lowestPriceListing?.orderHash!
          };

          if (lowestPriceListing?.platform === Marketplace.ParaSpace) {
            return {
              ...basedInfo,
              protocolData: lowestPriceListing?.protocolData!,
              protocolVersion: lowestPriceListing?.protocolVersion!,
              protocolContract: lowestPriceListing?.protocolContract!
            } as BuyCartAssetForParaSpace;
          }
          return basedInfo as BuyCartAssetForReservoir;
        });

        changeBuyCartList(buyCartAssets);
      },
      [changeBuyCartList]
    );

    const scrollContainer = useScrollContainer();

    const renderTail = () => {
      const isTokenIdFilterEmpty = isNil(filter.tokenId) || filter.tokenId.trim() === '';
      const isResultEmpty = assets.length === 0;

      if (hasNextPage) {
        return (
          <LoadMoreTrigger
            key={loadMoreTriggerKey}
            root={scrollContainer}
            threshold={0.5}
            task={load}
            params={assets.length}
            rootMargin="1000px"
          >
            {!isTokenIdFilterEmpty && <StyledSpinner />}
            {isTokenIdFilterEmpty && (
              <Text skin="secondary" fontWeight="bold">
                Loading More...
              </Text>
            )}
          </LoadMoreTrigger>
        );
      }

      if (!isResultEmpty) {
        return (
          <Inline justifyContent="center" inset="2rem">
            <Text skin="secondary" fontWeight="bold">
              No more data.
            </Text>
          </Inline>
        );
      }

      if (isResultEmpty) {
        if (isTokenIdFilterEmpty) {
          return (
            <Inline justifyContent="center" inset="2rem">
              <Text skin="secondary" fontWeight="bold">
                No data.
              </Text>
            </Inline>
          );
        }
        return (
          <TailContainer alignItems="center" justifyContent="center">
            <Icon name="search" size="60px" />
            <Stack alignItems="center" gap="0.5rem">
              <H3>No Results</H3>
              <NoNftText skin="secondary">
                You can only search for NFT identifier within the collection.
              </NoNftText>
            </Stack>
          </TailContainer>
        );
      }

      return null;
    };

    if (!basicInfoLoaded || !userInfoLoaded) {
      return (
        <Inline width="100%" justifyContent="center" inset="2rem">
          <Spinner size="large" />
        </Inline>
      );
    }

    return (
      <Container>
        {layoutType === LayoutType.Grid && (
          <GridItemsExplore
            assets={assets}
            onEnterDetail={handleEnterDetailPage}
            onSelectAssets={handleSelectAssets}
          />
        )}
        {layoutType === LayoutType.List && !isEmpty(assets) && (
          <ListItemsExplore
            assets={assets}
            handleEnterDetailPage={handleEnterDetailPage}
            onSelectAssets={handleSelectAssets}
          />
        )}

        {renderTail()}
      </Container>
    );
  }
);
