import { Button, Inline, Stack, Text, Icon } from '@parallel-mono/components';
import styled from 'styled-components';

const Container = styled(Inline)`
  margin-top: 0.5rem;
  width: 5rem;
  height: 5rem;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadows.secondary};
`;

const BAYC_USER_PENDING_REWARD_LIMIT = 250;
const BAYC_TOKEN_PENDING_REWARD_LIMIT = 80;
const MAYC_USER_PENDING_REWARD_LIMIT = 100;
const MAYC_TOKEN_PENDING_REWARD_LIMIT = 30;
const BAKC_USER_PENDING_REWARD_LIMIT = 50;
const BAKC_TOKEN_PENDING_REWARD_LIMIT = 20;

export const AutoCompoundWorkInfo = ({ closeModal }: { closeModal: () => void }) => {
  return (
    <Stack alignItems="center" gap="2rem">
      <Container>
        <Icon width="100%" height="100%" name="fileText" />
      </Container>
      <Stack gap="2rem">
        <Text>
          ApeCoin pool compounds every time there is a new transaction of ApeCoin being deposited
          into the pool.
        </Text>
        <Text>
          BAYC compounds when both the total unclaimed rewards exceeds{' '}
          <strong>{BAYC_USER_PENDING_REWARD_LIMIT} APE</strong> and when the individual NFT has over{' '}
          <strong>{BAYC_TOKEN_PENDING_REWARD_LIMIT} APE</strong> rewards unclaimed.
        </Text>
        <Text>
          MAYC compounds when both the total unclaimed rewards exceeds{' '}
          <strong>{MAYC_USER_PENDING_REWARD_LIMIT} APE</strong> and when the individual NFT has over{' '}
          <strong>{MAYC_TOKEN_PENDING_REWARD_LIMIT} APE</strong> rewards unclaimed.
        </Text>
        <Text>
          BAKC compounds when both the total unclaimed rewards exceeds{' '}
          <strong>{BAKC_USER_PENDING_REWARD_LIMIT} APE</strong> and when the individual NFT has over{' '}
          <strong>{BAKC_TOKEN_PENDING_REWARD_LIMIT} APE</strong> rewards unclaimed.
        </Text>
      </Stack>
      <Button block skin="secondary" size="large" onClick={closeModal}>
        Done
      </Button>
    </Stack>
  );
};
