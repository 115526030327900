import { useState, useCallback, useMemo } from 'react';
import { Stack, Inline, H5, Text, Button } from '@parallel-mono/components';
import { TokenInput, InfoPanel } from '@parallel-mono/business-components';
import { floor } from 'lodash';

import { FormState, STAKE_LIMIT } from '../types';
import { useEOABalances, useUserBalances } from '../../../contexts';

import { NumberRange, Tooltip } from '@/apps/paraspace/components';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';
import { ApeStakingTokenSymbol, ERC20Symbol, WalletType } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';

type StakeContainerProps = {
  symbol: ApeStakingTokenSymbol;
  stakedValue: number;
  stakedLimitValue: number;
  apeCoinSource: WalletType;
  apy: number;
  onSubmit: ({
    data: { borrowAmount, cashAmount },
    nextState
  }: {
    data: {
      borrowAmount: number;
      cashAmount: number;
    };
    nextState: FormState;
  }) => void;
};

export const StackContainer = ({
  symbol,
  stakedValue,
  stakedLimitValue,
  onSubmit,
  apeCoinSource,
  apy
}: StakeContainerProps) => {
  const { erc20BalanceMap: eoaErc20BalanceMap } = useEOABalances();
  const { erc20BalanceMap: aaErc20BalanceMap } = useUserBalances();
  const eoaBalance = eoaErc20BalanceMap?.[ERC20Symbol.APE] ?? zero;
  const aaBalance = aaErc20BalanceMap?.[ERC20Symbol.APE] ?? zero;
  const balance = apeCoinSource === 'AA' ? aaBalance : eoaBalance;

  const [walletAmount, setWalletAmount] = useState<number | null>(null);

  const currentStakeLimit = useMemo(() => STAKE_LIMIT[symbol] - stakedValue, [stakedValue, symbol]);

  const walletInputErrorMsg = useMemo(() => {
    if (walletAmount && walletAmount > currentStakeLimit) {
      return `Total staking on each NFT cannot exceed ${formatBalance(currentStakeLimit)} APE.`;
    }
    if (walletAmount && walletAmount < 1) {
      return `You need to stake at least 1 APE.`;
    }
    return '';
  }, [currentStakeLimit, walletAmount]);

  const handleWalletValueChange = useCallback((value: number | null) => {
    setWalletAmount(value);
  }, []);

  const buttonErrorMsg = useMemo(() => {
    if (balance && balance.lt(Number(walletAmount))) {
      return 'Insufficient balance';
    }
    return '';
  }, [balance, walletAmount]);

  const disableStake = useMemo(
    () => !!walletInputErrorMsg || !!buttonErrorMsg || !walletAmount,
    [buttonErrorMsg, walletAmount, walletInputErrorMsg]
  );

  const handleConfirm = useCallback(() => {
    onSubmit({
      data: {
        borrowAmount: Number(0),
        cashAmount: Number(walletAmount)
      },
      nextState: FormState.STEPPER
    });
  }, [onSubmit, walletAmount]);

  const maxValue = Math.min(balance?.toNumber() || 0, stakedLimitValue - stakedValue);

  return (
    <Stack gap="1.5rem">
      <TokenInput
        label={<H5>Amount</H5>}
        hint={
          <Inline gap=".3rem">
            <Text skin="secondary">Max: {formatBalance(maxValue!)} APE</Text>
          </Inline>
        }
        token="APE"
        placeholder="0"
        value={walletAmount && floor(walletAmount, MAXIMUM_BALANCE_DECIMALS)}
        decimals={MAXIMUM_BALANCE_DECIMALS}
        onChange={handleWalletValueChange}
        error={walletInputErrorMsg}
        actionButtonText="Max"
        onAction={() => setWalletAmount(maxValue)}
      />
      <InfoPanel
        skin="primary"
        infos={[
          {
            title: 'Total Staked',
            value: (
              <NumberRange
                start={stakedValue}
                end={walletAmount ? walletAmount + stakedValue : stakedValue}
                formatter={formatBalance}
                symbol="APE"
              />
            )
          },
          {
            title: (
              <Inline gap=".25rem">
                <Text skin="secondary">APY</Text>
                <Tooltip
                  placement="bottom"
                  content="The staking APY provided by the official apecoin staking contract."
                />
              </Inline>
            ),
            value: formatToPercentage(apy, 2)
          }
        ]}
      />
      <Button block size="large" disabled={disableStake} onClick={handleConfirm}>
        {buttonErrorMsg || 'Stake'}
      </Button>
    </Stack>
  );
};
