import { memo, useCallback, useMemo } from 'react';
import {
  Card,
  CellRenderContext,
  Checkbox,
  DataGridColumn,
  H3,
  H5,
  H6,
  Inline,
  InlineProps,
  SmallText,
  Stack,
  useBreakpoints
} from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { useAssetsStakefishInfo } from '../../../hooks';
import { ShopItemOnListingPage } from '../types';

import { DataGrid, NFTThumbnail } from '@/apps/paraspace/components';
import { formatBalance, formatToCurrency, formatToPercentage } from '@/apps/paraspace/utils/format';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';

type ListItemsExploreProps = {
  handleEnterDetailPage: () => void;
  assets: ShopItemOnListingPage[];
  onSelectAssets: (assets: ShopItemOnListingPage[]) => void;
} & Omit<InlineProps, 'children'>;

export const ListItemsExplore = memo(
  ({
    assets,
    handleEnterDetailPage: _handleEnterDetailPage,
    onSelectAssets
  }: ListItemsExploreProps) => {
    const { erc20InfoMap } = useMMProvider();
    const navigate = useNavigate();
    const { mobile } = useBreakpoints();

    const ethPriceInUsd = useMemo(
      () => erc20InfoMap?.[ERC20Symbol.ETH]?.priceInUsd ?? zero,
      [erc20InfoMap]
    );
    const assetIds = useMemo(() => assets.map(v => v.tokenId), [assets]);
    const { stakefishInfoMap } = useAssetsStakefishInfo(assetIds);
    const selectableAssets = useMemo(
      () =>
        assets.filter(
          asset => !asset.lowestPriceListing && !asset.inLiquidation && !asset.ownedByCurrentUser
        ),
      [assets]
    );

    const handleSelectAll = useCallback(
      e => {
        if (e.checked) {
          onSelectAssets(selectableAssets.filter(v => !v.inCart));
        } else {
          onSelectAssets(selectableAssets);
        }
      },
      [selectableAssets, onSelectAssets]
    );
    const handleAssetChange = useCallback(
      (asset: ShopItemOnListingPage) => () => {
        onSelectAssets([asset]);
      },
      [onSelectAssets]
    );
    const handleRowClick = useCallback(
      ({ data }) => {
        navigate(`/details/${data!.contractAddress}/${data.tokenId}`);
      },
      [navigate]
    );

    const allSelected = useMemo(
      () => !isEmpty(selectableAssets) && selectableAssets.every(v => v.inCart),
      [selectableAssets]
    );

    const renderNFTs = useCallback(
      ({ data: { tokenId, symbol } }: CellRenderContext<ShopItemOnListingPage>) => {
        return (
          <Inline gap="0.75rem" alignItems="center">
            <NFTThumbnail symbol={symbol} tokenId={tokenId} size="small" />
            <SmallText>#{tokenId}</SmallText>
          </Inline>
        );
      },
      []
    );

    const columns: DataGridColumn<ShopItemOnListingPage>[] = useMemo(() => {
      return [
        {
          name: 'checkbox',
          width: '0.5fr',
          title: mobile ? '' : <Checkbox checked={allSelected} onChange={handleSelectAll} />,
          render: columnData => {
            const { inCart, lowestPriceListing, inLiquidation, ownedByCurrentUser } =
              columnData.data;
            const disabled = !lowestPriceListing || inLiquidation || ownedByCurrentUser;
            return (
              <Inline width="100%" justifyContent="space-between">
                {mobile && renderNFTs(columnData)}
                <Checkbox
                  disabled={disabled}
                  checked={inCart}
                  onClick={e => e.stopPropagation()}
                  onChange={handleAssetChange(columnData.data)}
                />
              </Inline>
            );
          }
        },
        {
          name: 'NFTs',
          width: '2fr',
          title: <H5 fontWeight="medium">NFTs</H5>,
          render: renderNFTs
        },
        {
          name: 'Balance',
          width: '2fr',
          title: <H5 fontWeight="medium">Balance</H5>,
          render: ({ data: { tokenId } }) => {
            const balance = stakefishInfoMap[tokenId]?.balance ?? 0;
            return (
              <Stack gap="0">
                <SmallText>{formatNumber(balance)} ETH</SmallText>
                <SmallText skin="secondary">
                  {formatToCurrency(ethPriceInUsd.times(balance))}
                </SmallText>
              </Stack>
            );
          }
        },
        {
          name: 'MEV',
          width: '2fr',
          title: <H5 fontWeight="medium">MEV</H5>,
          render: ({ data: { tokenId } }) => {
            const balance = stakefishInfoMap[tokenId]?.balance ?? 0;
            const effectiveBalance = stakefishInfoMap[tokenId]?.effectiveBalance ?? 0;
            const protocolRewards = balance - effectiveBalance;
            return (
              <Stack gap="0">
                <SmallText>${formatNumber(protocolRewards)} ETH</SmallText>
                <SmallText skin="secondary">
                  {formatToCurrency(ethPriceInUsd.times(protocolRewards))}
                </SmallText>
              </Stack>
            );
          }
        },
        {
          name: 'APY',
          width: '2fr',
          title: <H5 fontWeight="medium">APY</H5>,
          render: ({ data: { tokenId } }) => {
            return (
              <Stack gap="0">
                <SmallText>{formatToPercentage(stakefishInfoMap[tokenId]?.protocolApy)}</SmallText>
              </Stack>
            );
          }
        },
        {
          name: 'Status',
          width: '2fr',
          title: <H5 fontWeight="medium">Status</H5>,
          render: ({ data: { tokenId } }) => {
            return <SmallText>{stakefishInfoMap[tokenId]?.nftState}</SmallText>;
          }
        },
        {
          name: 'Price',
          width: '2fr',
          title: <H5 fontWeight="medium">Price</H5>,
          render: ({ data: { lowestPriceListing } }) => {
            return (
              <H6>{lowestPriceListing ? `${formatBalance(lowestPriceListing.price)} ETH` : '-'}</H6>
            );
          }
        }
      ];
    }, [
      allSelected,
      ethPriceInUsd,
      handleAssetChange,
      handleSelectAll,
      mobile,
      renderNFTs,
      stakefishInfoMap
    ]);

    const mobileColumns = useMemo(
      () => columns.filter(column => column.name !== 'NFTs'),
      [columns]
    );

    return (
      <Card inset="1.5rem" border>
        <Stack gap="1rem">
          <Inline inset="0 1rem" justifyContent="space-between">
            <H3>{`${assets.length} NFTs Found`}</H3>
            {mobile && <Checkbox checked={allSelected} onChange={handleSelectAll} />}
          </Inline>

          <DataGrid
            classNames={{ row: 'clickable' }}
            onRowClick={handleRowClick}
            columns={mobile ? mobileColumns : columns}
            data={assets}
          />
        </Stack>
      </Card>
    );
  }
);
