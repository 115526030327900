import {
  Card,
  CardProps,
  DecoratedInput,
  H3,
  H5,
  Icon,
  Inline,
  Stack,
  Text
} from '@parallel-mono/components';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useCopyToClipboard } from 'react-use';
import { isEqual, isNil } from 'lodash';

import { AvatarSetting } from './AvatarSetting';
import { VerifyButton } from './VerifyButton';

import { useParallelToast, useWeb3Context } from '@/apps/paraspace/contexts';
import { truncateTextMid } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { isValidEmail } from '@/apps/paraspace/utils';

const StyledDecoratedInput = styled(DecoratedInput).attrs({
  Component: 'input'
})`
  ${({ disabled, theme }) =>
    disabled &&
    `background-color: ${theme.skin.grey[200]}; border-color:  ${theme.skin.grey[200]};`}
  height: 3.75rem;
  width: 100%;
`;

const StyledCopyIcon = styled(Icon)`
  cursor: pointer;
`;

type Props = {
  email: Maybe<string>;
  avatar: Maybe<string>;
  onAvatarChange: (value: string) => void;
  onSendEmail: (email: string) => Promise<void>;
};

export const AccountInfos: FC<Props & Omit<CardProps, 'children'>> = memo(
  ({ email: verifiedEmail, avatar, onAvatarChange, onSendEmail, ...others }) => {
    const [email, setEmail] = useState<Maybe<string>>(verifiedEmail);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [emailSent, setEmailSent] = useState<string>();

    const { account } = useWeb3Context();
    const theme = useTheme();
    const parallelToast = useParallelToast();

    const [copyState, copyToClipboard] = useCopyToClipboard();

    useEffect(() => {
      setEmail(prev => (!isNil(prev) ? prev : verifiedEmail));
    }, [verifiedEmail]);

    useEffect(() => {
      const { value, error } = copyState;
      if (value) {
        parallelToast.success('Wallet Address copied!');
      }
      if (error) {
        parallelToast.error(error.message);
      }
    }, [copyState, parallelToast]);

    const handleCopyAddress = useCallback(() => {
      if (account) {
        copyToClipboard(account);
      }
    }, [account, copyToClipboard]);

    const handleVerify = useCallback(async () => {
      if (email && isValidEmail(email)) {
        try {
          await onSendEmail(email);
          setIsEmailSent(true);
          setEmailSent(email);
        } catch (e) {
          parallelToast.error('Send email failed, please try it later.');
        }
      }
    }, [email, onSendEmail, parallelToast]);

    const handleEmailChange = useCallback(e => {
      setEmail(e.target.value);
    }, []);

    const showEmailErrors = useMemo(() => email && !isValidEmail(email), [email]);

    const showVerifyButton = useMemo(
      () => !verifiedEmail || !isEqual(email, verifiedEmail) || emailSent,
      [email, emailSent, verifiedEmail]
    );

    const disabledVerify = useMemo(
      () => Boolean(showEmailErrors) || !email || isEqual(email, verifiedEmail),
      [email, showEmailErrors, verifiedEmail]
    );

    const isCurrentEmailSent = useMemo(
      () => isEmailSent && isEqual(email, emailSent),
      [email, emailSent, isEmailSent]
    );

    return (
      <Card border {...others}>
        <Stack>
          <H3>Profile</H3>
          <Inline gap="10rem">
            <Stack width="26rem">
              <Stack gap="0.5rem">
                <H5>Wallet Address</H5>
                <StyledDecoratedInput
                  disabled
                  inputProps={{
                    disabled: true,
                    label: 'Wallet Address',
                    value: truncateTextMid(account, 15, 11)
                  }}
                  endAdornment={
                    <StyledCopyIcon
                      onClick={handleCopyAddress}
                      size="small"
                      strokeWidth={2}
                      name="copy"
                      color={theme.skin.text.main}
                    />
                  }
                />
              </Stack>

              <Stack gap="0.5rem">
                <H5>Email Address</H5>
                <StyledDecoratedInput
                  label="Email Address"
                  inputProps={{
                    defaultValue: verifiedEmail,
                    placeholder: 'Email...',
                    type: 'email',
                    value: isNil(email) ? verifiedEmail : email
                  }}
                  onChange={handleEmailChange}
                  endAdornment={
                    verifiedEmail ? (
                      <Icon
                        onClick={() => setEmail(verifiedEmail)}
                        size="small"
                        strokeWidth={2}
                        name="checkSquare"
                        color={theme.skin.text.main}
                      />
                    ) : null
                  }
                />
                {showEmailErrors && <Text skin="error">Please input valid email</Text>}
              </Stack>
            </Stack>
            <Stack gap="0.75rem">
              <H5>Profile Picture</H5>
              <AvatarSetting avatar={avatar} onAvatarChange={onAvatarChange} />
            </Stack>
          </Inline>
          {showVerifyButton && (
            <Inline>
              {isCurrentEmailSent && (
                <Inline alignItems="center" gap="0.25rem">
                  <Icon name="check" strokeWidth={2} />
                  <Text>Verification email sent</Text>
                </Inline>
              )}

              <VerifyButton disabled={disabledVerify} onClick={handleVerify}>
                {isCurrentEmailSent ? 'Resend' : 'Verify'}
              </VerifyButton>
            </Inline>
          )}
        </Stack>
      </Card>
    );
  }
);
