import { memo } from 'react';
import styled from 'styled-components';
import { Text } from '@parallel-mono/components';

import { ERC721TokenSelector, ERC721TokenSelectorProps } from '@/apps/paraspace/components';

const EmptyState = styled.div`
  background: ${({ theme }) => theme.skin.secondary.main};
  border-radius: 0.5rem;
  padding: 2.5rem 0.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ApeSelector = memo(({ tokenList, loading, ...others }: ERC721TokenSelectorProps) => {
  if (!loading && tokenList.length === 0) {
    return (
      <EmptyState>
        <Text fontWeight="bold">Sad...</Text>
        <Text skin="secondary">no BAYC/MAYC found in your wallet</Text>
      </EmptyState>
    );
  }

  return <ERC721TokenSelector tokenList={tokenList} loading={loading} {...others} />;
});
