import { DataGridColumn, Inline, SmallText } from '@parallel-mono/components';
import { memo, useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';

import { Collapse, DataGrid } from '@/apps/paraspace/components';
import { Traits } from '@/apps/paraspace/generated/graphql';

const DEFAULT_COLLECTION_ITEMS_COUNT = 10000;

const Properties = ({
  data: { traits, itemCount }
}: {
  data: { traits?: (Traits | null)[] | null; itemCount?: number };
}) => {
  const propertiesColumns: DataGridColumn<Traits>[] = useMemo(
    () => [
      {
        name: 'type',
        title: 'Traits',
        width: '1fr'
      },
      {
        name: 'value',
        title: 'Variants',
        width: '1fr'
      },
      {
        name: 'traitCount',
        title: 'Rarity',
        width: '1fr',
        render: ({ data: { traitCount } }) => {
          return (
            <Inline gap=".25rem">
              <SmallText fontWeight="bold">
                {formatNumber(Number(traitCount) / (itemCount || DEFAULT_COLLECTION_ITEMS_COUNT), {
                  decimal: 2,
                  output: 'percent'
                })}
              </SmallText>
              <SmallText>have this trait</SmallText>
            </Inline>
          );
        }
      }
    ],
    [itemCount]
  );

  return (
    <Collapse border header="Properties" defaultOpen>
      <DataGrid columns={propertiesColumns} data={(traits ?? []) as Traits[]} />
    </Collapse>
  );
};

export const PropertiesSection = memo(Properties);
