import {
  Button,
  Card,
  CardProps,
  DataGridColumn,
  H3,
  H5,
  Icon,
  Inline,
  SmallText,
  Stack,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { getListingPricesDataGrid } from '../components';

import { MyListingItem, useMyListingData } from './useMyListingData';
import { ListingEditModal } from './ListingEditModal';

import { NFTThumbnail, SupportedSymbol } from '@/apps/paraspace/components';
import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';

const StyledDataGrid = getListingPricesDataGrid<MyListingItem>();

type Props = Omit<CardProps, 'children'>;

export const NftItem = ({ tokenId, symbol }: { tokenId: number; symbol: SupportedSymbol }) => {
  return (
    <Inline gap="0.75rem">
      <NFTThumbnail size="small" tokenId={tokenId} symbol={symbol} />
      <Stack gap="0" justifyContent="center">
        <Text>#{tokenId}</Text>
      </Stack>
    </Inline>
  );
};

export const MyListings: FC<Props> = memo(props => {
  const { myListings, loading, refetch } = useMyListingData();
  const [isOpen, setIsOpens] = useState(false);
  const [listingEditData, setListingEditData] = useState(null);
  const openListingEditModal = useCallback(listingData => {
    setListingEditData(listingData);
    setIsOpens(true);
  }, []);
  const closeListingEditModal = useCallback(() => {
    refetch();
    setIsOpens(false);
  }, [refetch]);

  const { mobile } = useBreakpoints();

  const columns: DataGridColumn<MyListingItem>[] = useMemo(
    () => [
      {
        name: 'nfts',
        title: mobile ? null : <H5 skin="secondary">NFTs</H5>,
        render: ({ data: { tokenId, symbol } }) => {
          return <NftItem tokenId={tokenId} symbol={symbol} />;
        }
      },
      {
        name: 'listingPrice',
        title: <H5 skin="secondary">Listing Price</H5>,
        render: ({ data: { price, priceInUSD } }) => {
          return (
            <Stack gap="0.125rem">
              <Text>{formatBalance(price)} ETH</Text>
              <SmallText skin="secondary">
                {priceInUSD ? formatToCurrency(priceInUSD) : '-'}
              </SmallText>
            </Stack>
          );
        }
      },
      {
        name: 'expiration',
        title: <H5 skin="secondary">Expiration</H5>,
        render: ({ data: { expirationTime } }) => {
          return <Text>{dayjs(expirationTime).fromNow()}</Text>;
        }
      },
      {
        name: 'updateListing',
        title: '',
        className: mobile ? 'hiddenTitleLine' : '',
        render: ({ data }) => {
          return (
            <Inline width="100%" justifyContent="flex-end">
              <Button
                skin={mobile ? 'secondary' : 'primary'}
                block={mobile}
                onClick={() => openListingEditModal(data)}
              >
                Update Listing
              </Button>
            </Inline>
          );
        }
      }
    ],
    [mobile, openListingEditModal]
  );

  return (
    <Card border {...props}>
      {!loading && isEmpty(myListings) ? (
        <Stack inset="2rem" alignItems="center">
          <Icon name="boxOpen" size="4rem" />
          <Stack gap="0.25rem" alignItems="center">
            <H3>No Listings</H3>
            <Text skin="secondary">You have not listed any NFTs.</Text>
          </Stack>
        </Stack>
      ) : (
        <StyledDataGrid
          loading={loading}
          title={<H3>{myListings.length || '-'} Listings</H3>}
          columns={columns}
          data={myListings}
        />
      )}
      {listingEditData && (
        <ListingEditModal
          listing={listingEditData!}
          isOpen={isOpen}
          onClose={closeListingEditModal}
        />
      )}
    </Card>
  );
});
