import BigNumberJS from 'bignumber.js';
import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import { OfferFormValue } from '../../../../../components/MakeOfferForm';

import { useBalanceAndFutureCredit } from './useBalanceAndFutureCredit';
import { useSelectPaymentToken } from './useSelectPaymentToken';
import { useMakeOfferInput } from './useMakeOfferInput';
import { useOfferInfoTip } from './useOfferInfoTip';

import { useShowApeStakingWarning } from '@/apps/paraspace/components';
import { CollectionInfo } from '@/apps/paraspace/pages/Shop/contexts/CollectionProvider';
import useSeaport from '@/apps/paraspace/pages/hooks/useSeaport';
import { useAppConfig } from '@/apps/paraspace/hooks';

export const useMakeOfferValueAndHandlers = ({
  collection,
  tokenId,
  multiplier,
  topOfferPriceInUsd,
  onSubmitForm
}: {
  collection: CollectionInfo;
  tokenId?: number;
  multiplier?: BigNumberJS;
  topOfferPriceInUsd?: BigNumberJS;
  onSubmitForm?: (value: OfferFormValue) => void;
}) => {
  const { erc20Config } = useAppConfig();
  const { paymentTokens, symbol } = collection || {};

  const { seaportSDK } = useSeaport();

  const { paymentTokenOptions, handlePaymentTokenChange, selectedPaymentToken } =
    useSelectPaymentToken({ paymentTokens });

  const { walletBalance, futureAvailableCredit, selectedTokenPriceInUsd } =
    useBalanceAndFutureCredit({
      selectedPaymentToken,
      symbol,
      multiplier
    });

  const {
    walletAmount,
    borrowAmount,
    offerAmount,
    selectedBidDuration,
    handleBorrowAmountChange,
    handleMaxBorrowAmount,
    handleMaxWalletAmount,
    handleWalletAmountChange,
    handleBidDurationChange
  } = useMakeOfferInput({ futureAvailableCredit, walletBalance });

  const offerTip = useOfferInfoTip({ selectedTokenPriceInUsd, offerAmount, topOfferPriceInUsd });

  const token = useMemo(
    () => ({
      symbol: selectedPaymentToken.value,
      address: selectedPaymentToken.address,
      decimal: erc20Config[selectedPaymentToken.value].decimals,
      walletAmount: walletAmount ?? 0,
      borrowAmount: borrowAmount ?? 0,
      offerAmount: offerAmount.toNumber()
    }),
    [
      borrowAmount,
      erc20Config,
      offerAmount,
      selectedPaymentToken.address,
      selectedPaymentToken.value,
      walletAmount
    ]
  );

  const handleConfirm = useCallback(() => {
    if (!seaportSDK) return;
    const startTime = dayjs().unix();
    const endTime = dayjs.unix(startTime).add(selectedBidDuration.value, 'day').unix();
    onSubmitForm?.({
      token,
      startTime,
      endTime
    });
  }, [seaportSDK, selectedBidDuration.value, onSubmitForm, token]);

  const showApeStakingWarning = useShowApeStakingWarning(
    symbol && tokenId ? [{ symbol, tokenId }] : []
  );

  return {
    paymentTokenOptions,
    walletBalance,
    futureAvailableCredit,
    selectedPaymentToken,
    handlePaymentTokenChange,
    walletAmount,
    borrowAmount,
    offerAmount,
    handleBorrowAmountChange,
    handleMaxBorrowAmount,
    handleMaxWalletAmount,
    handleWalletAmountChange,
    offerTip,
    selectedBidDuration,
    handleBidDurationChange,
    handleConfirm,
    showApeStakingWarning
  };
};
