import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import relateTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

import { ParaX } from './apps/parax';

dayjs.extend(utc);
dayjs.extend(relateTime);
dayjs.extend(LocalizedFormat);

ReactDOM.render(<ParaX />, document.getElementById('root'));
