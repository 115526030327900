import { useCallback, useMemo } from 'react';
import { ethers } from 'ethers';
import {
  ERC20Service,
  EthereumTransactionTypeExtended
} from 'paraspace-utilities-contract-helpers';
import { BigNumber } from 'bignumber.js';

import { ERC20Symbol } from '../typings';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useAppConfig, useContractsMap, useSymbolByContractAddress } from '@/apps/paraspace/hooks';

const MAX_ALLOWANCE = ethers.constants.MaxUint256.toString();

export const useERC20 = (assetAddr: string) => {
  const { erc20Config } = useAppConfig();
  const symbol = useSymbolByContractAddress(assetAddr);
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();
  const service = useMemo(() => {
    return new ERC20Service(provider);
  }, [provider]);

  const getAllowance = useCallback(
    async ({ user = account, spender = contracts.PoolProxy }) => {
      if (!service) {
        return '0';
      }
      return service.getAllowance({
        user,
        token: assetAddr,
        spender
      });
    },
    [assetAddr, contracts.PoolProxy, account, service]
  );

  const createApproval = useCallback(
    async ({
      user = account,
      spender = contracts.PoolProxy,
      amount = MAX_ALLOWANCE,
      approveMax = false
    }: {
      user?: string;
      spender?: string;
      amount?: string | number | BigNumber;
      approveMax?: boolean;
    } = {}) => {
      const txs: EthereumTransactionTypeExtended[] = [];
      const normalizedAmount = BigNumber.min(amount, MAX_ALLOWANCE);
      const allowance = await getAllowance({ user, spender });

      if (BigNumber(allowance).lt(normalizedAmount)) {
        if (
          BigNumber(allowance)?.gt(0) &&
          erc20Config[symbol as ERC20Symbol]?.needResetAllowanceBeforeApprove
        ) {
          const tx = await service.approve({
            user,
            spender,
            token: assetAddr,
            amount: '0'
          });
          txs.push(tx);
        }

        const tx = await service.approve({
          user,
          spender,
          token: assetAddr,
          amount: approveMax ? MAX_ALLOWANCE : normalizedAmount.toString(10)
        });
        txs.push(tx);
      }
      return txs;
    },
    [account, contracts.PoolProxy, service, getAllowance, erc20Config, symbol, assetAddr]
  );

  const getBalance = useCallback(
    async ({ user = account, token = assetAddr }) => {
      if (!service) {
        return new BigNumber(0);
      }
      return new BigNumber((await service.balanceOf({ user, token })).toString());
    },
    [assetAddr, account, service]
  );

  return { createApproval, getAllowance, getBalance };
};
