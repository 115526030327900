import { memo } from 'react';
import styled from 'styled-components';
import { Button, Inline, Text, Stack, StackProps } from '@parallel-mono/components';

import { BuyShopItemPayload } from '..';

import { BuyShopItemFormData } from '.';

import { LinkButton, NFTThumbnail } from '@/apps/paraspace/components';

const CenterText = styled(Text)`
  text-align: center;
`;

const ButtonGroup = styled(Inline)`
  > * {
    flex: 1;
  }
`;

interface SuccessStateProps extends Omit<StackProps, 'children'> {
  payload: BuyShopItemPayload;
  formData: BuyShopItemFormData;
  onClose: () => void;
}

export const SuccessState = memo(({ formData, payload, onClose }: SuccessStateProps) => {
  const {
    collection: { symbol, collectionName },
    tokenId
  } = payload;
  const buyWithCredit = !!(formData.borrowAmount && formData.borrowAmount > 0);

  return (
    <Stack alignItems="center">
      <NFTThumbnail symbol={symbol} tokenId={tokenId} size="large" />
      <CenterText>
        Successfully bought {collectionName} #{tokenId}!
      </CenterText>
      {buyWithCredit && (
        <>
          <CenterText skin="secondary">
            Because you used credit to buy, this NFT is supplied to Money Market as collateral.'
          </CenterText>
          <ButtonGroup width="100%">
            <Button size="large" skin="secondary" onClick={onClose}>
              Done
            </Button>
            <LinkButton size="large" skin="primary" to="/">
              View Position
            </LinkButton>
          </ButtonGroup>
        </>
      )}
      {!buyWithCredit && (
        <>
          <CenterText skin="secondary">You can view your NFT on your wallet.</CenterText>
          <Button onClick={onClose}>Done</Button>
        </>
      )}
    </Stack>
  );
});
