import React, { ReactElement, useMemo } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as OriginApolloProvider,
  createHttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { useGraphqlConfig } from '../../hooks/useGraphqlConfig';

export const ApolloProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const { graphqlEndpoint } = useGraphqlConfig();
  const apolloHttpLink = useMemo(
    () =>
      createHttpLink({
        uri: graphqlEndpoint
      }),
    [graphqlEndpoint]
  );

  const apolloAuthContext = useMemo(
    () =>
      setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            Authorization: ''
          }
        };
      }),
    []
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        link: apolloAuthContext.concat(apolloHttpLink),
        cache: new InMemoryCache()
      }),
    [apolloAuthContext, apolloHttpLink]
  );
  return <OriginApolloProvider client={client}>{children}</OriginApolloProvider>;
};
