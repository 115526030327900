import { ChildToParentMessageStatus, ChildToParentTransactionEvent } from '@arbitrum/sdk';
import BigNumber from 'bignumber.js';

export type Event = {
  contract?: string;
  blockNumber: number;
  status: 'confirmed' | 'failed' | 'waiting' | 'pending';
  timestamp: number;
  transactionHash: string;
};

export const L1StatusMapping = {
  [ChildToParentMessageStatus.UNCONFIRMED]: 'pending',
  [ChildToParentMessageStatus.EXECUTED]: 'confirmed',
  [ChildToParentMessageStatus.CONFIRMED]: 'waiting'
};

export type ActivityDetail = {
  id: string;
  type: 'withdraw' | 'deposit';
  chains: {
    from: number;
    to: number;
  };
  metadata: {
    amount: BigNumber;
    symbol: string;
    from: string;
    to: string;
    event?: ChildToParentTransactionEvent;
  };
  sendEvent: Event;
  receivedEvent?: Partial<Event>;
};

export type L2ToL1Event = {
  id: string;
  caller: string;
  destination: string;
  position: number;
  transactionHash: string;
  transactionStatus: string;
  arbBlockNum: number;
  ethBlockNum: number;
  timestamp: number;
  value: string;
  createdAt: string;
  updatedAt: string;
};
