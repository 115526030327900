import { Card, Stack, Responsive, useBreakpoints } from '@parallel-mono/components';
import { memo, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get, isNil } from 'lodash';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';

import { combineValidatorTokenInfo } from '../../utils';
import { StakefishAssetValidatorInfo } from '../../types';

import {
  StakefishNFTBaseInfo,
  StakefishStakingInfo,
  Container,
  AboutSection,
  CollectionSection,
  OfferGrid,
  OperationCard
} from './component';
import { useAssetDetail, useAssetLiquidationInfo } from './hooks';

import { NFTThumbnail } from '@/apps/paraspace/components';
import { ERC721Symbol, StakeFishNTokenData } from '@/apps/paraspace/typings';
import { useContractsMap, useStakeFishData } from '@/apps/paraspace/hooks';
import { zero } from '@/apps/paraspace/consts/values';
import { useNtokenData } from '@/apps/paraspace/pages/hooks/useNtokenData';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { DAYS_OF_YEAR } from '@/apps/paraspace/consts/fixtures';

const SectionRow = styled(Responsive)`
  > * {
    flex: 1;
  }
`;

export const StakeFishDetail = memo(({ disableShopFeature }: { disableShopFeature: boolean }) => {
  const [stakeFishValidator, setStakeFishValidator] =
    useState<Maybe<StakefishAssetValidatorInfo>>(null);

  const { contractAddr, tokenId } = useParams();
  const contracts = useContractsMap();

  const { asset, loading: assetLoading, refetch } = useAssetDetail(contractAddr!, tokenId!);
  const { handleCloseLiquidation, handleTriggerAuction, liquidationInfoForAsset } =
    useAssetLiquidationInfo(contractAddr!, tokenId!);

  const { mobile } = useBreakpoints();

  const { getNTokens } = useNtokenData<{ stakefishNTokenData: StakeFishNTokenData }>(
    contracts.nSFVLDR
  );

  const { fetchValidatorDetail } = useStakeFishData();

  useAsyncEffect(async () => {
    const nTokenDetails = await getNTokens([Number(tokenId)]);
    const validatorIndex = (
      get(nTokenDetails, '[0].stakefishNTokenData.validatorIndex') as BigNumber | undefined
    )?.toNumber();

    if (validatorIndex) {
      const stakeFishValidatorData = await fetchValidatorDetail(validatorIndex);
      const result = combineValidatorTokenInfo(nTokenDetails[0], stakeFishValidatorData);
      setStakeFishValidator(result);
    }
  }, [tokenId, getNTokens, fetchValidatorDetail]);

  const isLoading = useMemo(
    () => assetLoading || isNil(stakeFishValidator),
    [assetLoading, stakeFishValidator]
  );

  const unClaimedRewards = useMemo(
    () => get(stakeFishValidator, 'unclaimedPendingReward', zero),
    [stakeFishValidator]
  );

  const claimedRewards = useMemo(
    () => get(stakeFishValidator, 'claimedPendingReward', zero),
    [stakeFishValidator]
  );

  const annualTipOrMEVApy = useMemo(() => {
    if (stakeFishValidator?.activatedAt) {
      const activeDays = dayjs(Date.now()).diff(stakeFishValidator.activatedAt, 'day');
      return unClaimedRewards
        .plus(claimedRewards)
        .div(activeDays)
        .times(DAYS_OF_YEAR)
        .div(stakeFishValidator.effectiveBalance)
        .toNumber();
    }
    return null;
  }, [claimedRewards, stakeFishValidator, unClaimedRewards]);

  return (
    <Container loading={isLoading}>
      {!isNil(stakeFishValidator) && !isNil(asset) && (
        <Stack>
          <Helmet>
            <meta
              property="twitter:title"
              content={`ParaSpace - ${asset.collection.collectionName} - #${tokenId}`}
            />
          </Helmet>
          <Card border>
            <Responsive alignItems="center" gap={mobile ? '2rem' : '3rem'}>
              <NFTThumbnail
                width="14.5rem"
                size="xLarge"
                symbol={ERC721Symbol.SF_VLDR}
                tokenId={Number(tokenId!)}
                platform="paraspace"
                twitterPreview
              />
              <OperationCard
                disableShopFeature={disableShopFeature}
                assetData={asset}
                liquidation={liquidationInfoForAsset}
                refetch={refetch}
                onCloseLiquidation={handleCloseLiquidation}
                onTriggerAuction={handleTriggerAuction}
              >
                <StakefishNFTBaseInfo
                  assetData={asset}
                  effectiveBalance={stakeFishValidator!.effectiveBalance}
                  balance={stakeFishValidator!.balance}
                  annualProtocolApy={stakeFishValidator!.protocolApy}
                  annualTipOrMEVApy={annualTipOrMEVApy}
                  unClaimedRewards={unClaimedRewards}
                  claimedRewards={claimedRewards}
                />
              </OperationCard>
            </Responsive>
          </Card>
          <SectionRow>
            <Stack width="100%">
              <StakefishStakingInfo
                validatorIndex={stakeFishValidator.validatorIndex}
                publicKey={stakeFishValidator.publicKey}
                activatedAt={stakeFishValidator.activatedAt}
                status={stakeFishValidator.nftState}
              />
              <AboutSection
                data={{
                  listings: asset.listings,
                  contractAddress: contractAddr!,
                  tokenId: tokenId!,
                  standard: get(asset, 'collection.standard') as string,
                  name: get(asset, 'collection.name')
                }}
              />
            </Stack>
            <Stack width="100%">
              <OfferGrid
                isOwner={asset.isOwner}
                inLiquidation={!!liquidationInfoForAsset}
                refetch={refetch}
              />
              <CollectionSection
                data={{
                  name: get(asset, 'collection.name', ''),
                  externalUrl: asset?.collection?.externalUrl,
                  description: get(asset, 'collection.description', '')
                }}
              />
            </Stack>
          </SectionRow>
        </Stack>
      )}
    </Container>
  );
});
