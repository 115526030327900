import { useCallback, useEffect, useMemo, useState } from 'react';
import { Pool, SewerPassClaim, UserFlashClaimService } from 'paraspace-utilities-contract-helpers';

import {
  useApeListStatesAndActions,
  ApeListItem
} from '@/apps/paraspace/pages/ApePairing/contexts';
import { useWeb3Context, useParallelToast } from '@/apps/paraspace/contexts';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import submitTransaction from '@/apps/paraspace/utils/submitTransaction';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useContractsMap } from '@/apps/paraspace/hooks';
import { Network } from '@/apps/paraspace/config';

const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000';

const FLASH_CLAIM_CONTRACTS = {
  [Network.MAINNET]: {
    userRegistry: '0xfCD4E8Fe44207407c16AA187F9E61c1f32E54d6e',
    sewerPassClaim: '0xBA5a9E9CBCE12c70224446C24C111132BECf9F1d',
    sewerPass: '0x764AeebcF425d56800eF2c84F2578689415a2DAa'
  },
  [Network.FORK]: {
    userRegistry: '0xfCD4E8Fe44207407c16AA187F9E61c1f32E54d6e',
    sewerPassClaim: '0xBA5a9E9CBCE12c70224446C24C111132BECf9F1d',
    sewerPass: '0x764AeebcF425d56800eF2c84F2578689415a2DAa'
  },
  [Network.SEPOLIA]: {
    userRegistry: '0xF1702B4ED9bB28097eb28928E53Ee61e650C649c',
    sewerPassClaim: '0x4594ebe3db884de299bd2a1ac37682fcfb070a8d',
    sewerPass: '0x3aa026cd539fa1f6ae58ae238a10e2f1cf831454'
  }
} as Record<Network, { userRegistry: string; sewerPassClaim: string; sewerPass: string }>;

export const useMintSewerPass = () => {
  const toast = useParallelToast();
  const { provider, account, chainId } = useWeb3Context();
  const contracts = useContractsMap();
  const flashClaimContracts = FLASH_CLAIM_CONTRACTS[chainId];
  const [receiverEnabled, setReceiverEnabled] = useState<boolean | null>(null);
  const [contractEnabling, setContractEnabling] = useState(false);
  const [minting, setMinting] = useState(false);
  const [availableApes, setAvailableApes] = useState<ApeListItem[] | null>(null);
  const [availableBakcs, setAvailableBakcs] = useState<ApeListItem[] | null>(null);

  const { apesInBalanceAndInSuppliedExcludingInP2P, apeInfoListLoaded } =
    useApeListStatesAndActions();

  const userRegistry = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new UserFlashClaimService(provider, flashClaimContracts.userRegistry);
  }, [flashClaimContracts, provider]);

  const pool = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new Pool(provider, {
      POOL: contracts.PoolProxy,
      WETH_GATEWAY: contracts.WETHGatewayProxy
    });
  }, [contracts.PoolProxy, contracts.WETHGatewayProxy, provider]);

  const sewerPassClaim = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new SewerPassClaim(provider, flashClaimContracts.sewerPassClaim);
  }, [flashClaimContracts.sewerPassClaim, provider]);

  const getReceiverAddr = useCallback(async () => {
    if (!userRegistry) {
      return null;
    }
    return userRegistry.getUserReceivers(account);
  }, [account, userRegistry]);

  const getTokenClaimed = useCallback(
    async (ape: ApeListItem) => {
      if (!sewerPassClaim) {
        return null;
      }
      if (ape.symbol === ERC721Symbol.BAYC) {
        return sewerPassClaim.baycClaimed(String(ape.tokenId));
      }
      if (ape.symbol === ERC721Symbol.MAYC) {
        return sewerPassClaim.maycClaimed(String(ape.tokenId));
      }
      if (ape.symbol === ERC721Symbol.BAKC) {
        return sewerPassClaim.bakcClaimed(String(ape.tokenId));
      }
      return null;
    },
    [sewerPassClaim]
  );

  const refreshTokens = useCallback(async () => {
    const suppliedApes = apesInBalanceAndInSuppliedExcludingInP2P.filter(
      ape => (ape.symbol === ERC721Symbol.BAYC || ape.symbol === ERC721Symbol.MAYC) && ape.supplied
    );
    const allBakcs = apesInBalanceAndInSuppliedExcludingInP2P.filter(
      ape => ape.symbol === ERC721Symbol.BAKC
    );

    const unclaimedApes = (
      await Promise.all(
        suppliedApes.map(async ape => ({ ape, claimed: await getTokenClaimed(ape) }))
      )
    )
      .filter(({ claimed }) => !claimed)
      .map(({ ape }) => ape);
    const unclaimedBakcs = (
      await Promise.all(allBakcs.map(async ape => ({ ape, claimed: await getTokenClaimed(ape) })))
    )
      .filter(({ claimed }) => !claimed)
      .map(({ ape }) => ape);

    setAvailableApes(unclaimedApes);
    setAvailableBakcs(unclaimedBakcs);
  }, [apesInBalanceAndInSuppliedExcludingInP2P, getTokenClaimed]);

  const refresh = useCallback(async () => {
    const receiverAddr = await getReceiverAddr();
    setReceiverEnabled(receiverAddr !== EMPTY_ADDRESS);
  }, [getReceiverAddr]);

  const enableContract = useCallback(async () => {
    if (!provider || !userRegistry) {
      return null;
    }
    const transaction = userRegistry.createReceiver(account)[0];
    const txRes = submitTransaction({ provider, tx: transaction });
    setContractEnabling(true);
    return toast.promise(
      txRes
        .then(async tx => {
          await tx?.wait();
          setContractEnabling(false);
        })
        .catch((e: Error) => {
          setContractEnabling(false);
          throw getUserFriendlyError(e);
        })
        .finally(async () => {
          await refresh();
        })
    );
  }, [account, provider, refresh, toast, userRegistry]);

  const getClaimEncodeData = useCallback(
    (ape: { id: number; symbol: ERC721Symbol }, bakc?: { id: number; symbol: ERC721Symbol }) => {
      if (!sewerPassClaim) {
        return null;
      }
      if (ape.symbol === ERC721Symbol.BAYC) {
        if (bakc) {
          return sewerPassClaim.instance.interface.encodeFunctionData('claimBaycBakc', [
            ape.id,
            bakc.id
          ]);
        }
        return sewerPassClaim.instance.interface.encodeFunctionData('claimBayc', [ape.id]);
      }
      if (ape.symbol === ERC721Symbol.MAYC) {
        if (bakc) {
          return sewerPassClaim.instance.interface.encodeFunctionData('claimMaycBakc', [
            ape.id,
            bakc.id
          ]);
        }
        return sewerPassClaim.instance.interface.encodeFunctionData('claimMayc', [ape.id]);
      }
      return null;
    },
    [sewerPassClaim]
  );

  const mint = useCallback(
    async (
      ape: { id: number; symbol: ERC721Symbol },
      bakc?: { id: number; symbol: ERC721Symbol }
    ) => {
      const receiverAddr = await getReceiverAddr();
      if (!provider || !pool || !receiverAddr) {
        return null;
      }
      const apeAssetContract = ape.symbol === ERC721Symbol.BAYC ? contracts.BAYC : contracts.MAYC;
      const nftAssets = bakc ? [apeAssetContract, contracts.BAKC] : [apeAssetContract];
      const tokenIds = bakc ? [[ape.id], [bakc.id]] : [[ape.id]];
      const transaction = (
        await pool.flashClaim({
          user: account,
          receiver: receiverAddr,
          nftAssets,
          tokenIds,
          airdropContractAddr: flashClaimContracts.sewerPassClaim,
          airdropTokenTypes: [2],
          airdropTokenAddrs: [flashClaimContracts.sewerPass],
          airdropTokenIds: [ape.id],
          airdropEncodedData: getClaimEncodeData(ape, bakc) ?? ''
        })
      )[0];
      const txRes = submitTransaction({ provider, tx: transaction });
      setMinting(true);
      return toast.promise(
        txRes
          .then(async tx => {
            await tx?.wait();
            setMinting(false);
          })
          .catch((e: Error) => {
            setMinting(false);
            throw getUserFriendlyError(e);
          })
          .finally(async () => {
            await refreshTokens();
          })
      );
    },
    [
      account,
      contracts,
      flashClaimContracts,
      getClaimEncodeData,
      getReceiverAddr,
      pool,
      provider,
      refreshTokens,
      toast
    ]
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    refreshTokens();
  }, [refreshTokens]);

  return {
    loaded:
      apeInfoListLoaded &&
      receiverEnabled !== null &&
      availableApes !== null &&
      availableBakcs !== null,
    isProxyCreated: !!receiverEnabled,
    contractEnabling,
    upgradeUserProxy: enableContract,
    availableApes,
    availableBakcs,
    mint,
    minting,
    refresh
  };
};
