import { supportedERC20sMap } from '../configs/erc20s';
import { SupportedChainId } from '../configs';

import { Maybe } from '@/apps/parax/typings/basic';
import { useEOAProvider } from '@/apps/parax/contexts';
import { emptyArray } from '@/apps/parax/consts';

export const useSupportedERC20s = (chainId?: Maybe<number>) => {
  const { chainId: connectedChainId } = useEOAProvider();
  if (chainId === null) {
    return [];
  }
  return (
    supportedERC20sMap[
      chainId === undefined
        ? (connectedChainId as number as SupportedChainId)
        : (chainId as number as SupportedChainId)
    ] ?? emptyArray
  );
};
