import { Outlet, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

import { ApeStakingNotConnect, Menus } from '../components';
import { ApeListProvider } from '../contexts/ApeListProvider';
import { ApeNetApyProvider } from '../contexts/ApeNetApyProvider';
import { StakingInfosProvider } from '../contexts/StakingInfosProvider';
import { ApeStakingManagerProvider, V1TimelockApeInfoProvider } from '../contexts';
import { StatsBar } from '../StatsBar';

import { ListedOnShopProvider } from './P2PStaking/contexts/ListedOnShopProvider';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { PageLayout } from '@/apps/paraspace/components';

export const statsBarHeight = '11.25rem';

export const ApePairing = () => {
  const { account } = useWeb3Context();
  const location = useLocation();

  // for these two sub routes we need to display the full content.
  const isFullPage = useMemo(
    () =>
      [absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS_CREATE].some(path =>
        location.pathname.startsWith(path)
      ),
    [location.pathname]
  );

  return (
    <StakingInfosProvider>
      <V1TimelockApeInfoProvider>
        <ApeListProvider>
          <ApeStakingManagerProvider>
            <ApeNetApyProvider>
              <ListedOnShopProvider>
                {account ? (
                  <PageLayout
                    showSideMenu={!isFullPage}
                    sideMenu={<Menus />}
                    banner={<StatsBar />}
                    mainContent={<Outlet />}
                  />
                ) : (
                  <ApeStakingNotConnect />
                )}
              </ListedOnShopProvider>
            </ApeNetApyProvider>
          </ApeStakingManagerProvider>
        </ApeListProvider>
      </V1TimelockApeInfoProvider>
    </StakingInfosProvider>
  );
};
