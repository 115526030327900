import { memo, useMemo } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { NftInfo, Offer } from '../../../../types';

import { AcceptOfferForm } from './AcceptOfferForm';
import { AcceptOfferSteppers } from './steppers';

import {
  CollectAndSubmitProcedurePhase,
  ErrorState,
  SuccessState,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';

export type AcceptOfferModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  offer?: Offer;
  nftInfo?: NftInfo;
  onFinish: () => void;
  onError: () => void;
  onClose: () => void;
};

export const AcceptOfferModal = memo(
  ({ onClose, isOpen, offer, nftInfo, onFinish, onError, ...others }: AcceptOfferModalProps) => {
    const { contractAddress, tokenId, symbol } = nftInfo ?? {};
    const { orderItem, order } = offer ?? {};
    const { phase, handleFormSubmit, handleSubmitFailed, handleSubmitSuccess } =
      useCollectAndSubmitProcedure<void>({
        running: isOpen,
        onError,
        onFinish
      });

    const formData = useMemo(
      () => ({
        order: order!,
        contractAddress: contractAddress!,
        tokenId: tokenId!
      }),
      [order, contractAddress, tokenId]
    );
    return (
      <Modal title="Accept Offer" size="32.5rem" onClose={onClose} isOpen={isOpen} {...others}>
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <AcceptOfferForm
            nftInfo={nftInfo!}
            offer={offer!}
            onCloseModal={onClose}
            onAcceptOffer={handleFormSubmit}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <AcceptOfferSteppers
            formData={formData}
            onError={handleSubmitFailed}
            onSuccess={handleSubmitSuccess}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            actionButtonText="Done"
            desc={`Sold ${symbol} #${tokenId} for ${orderItem!.amount.toString()} ${
              orderItem!.symbol
            }!`}
            tip="The payment you received has been supplied to ParaSpace lending to maintain your health factor."
            onAction={onClose}
          />
        )}
      </Modal>
    );
  }
);
