import BigNumber from 'bignumber.js';

import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  WalletType
} from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

export enum APE_STAKE_STATUS {
  DEFAULT = 'default',
  CONFIRMING = 'confirming',
  UNSTAKE_SUCCESS = 'unstakeSuccess',
  STAKE_SUCCESS = 'stakeSuccess'
}

export enum STAKE_APPROVE_STATUS {
  UNAPPROVED = 'unapproved',
  APPROVING = 'approving',
  APPROVED = 'approved',
  STAKING = 'staking'
}

export enum ManageApeStakingOperation {
  STAKE = 'stake',
  UNSTAKE = 'unstake'
}

export type ManageApeStakingFormData = {
  operation: ManageApeStakingOperation;
  amount: number;
};

export enum FormState {
  MAIN_TOKEN_FORM = 0,
  STEPPER = 1,
  ERROR = 2,
  SUCCESS = 3
}

export enum CommonFormState {
  MAINFORM = 0,
  APPROVE = 1,
  PROCESSING = 2,
  ERROR = 3,
  SUCCESS = 4,
  EXTRA_BAKC_APPROVAL = 5
}

export type SupplyFormData = {
  tokenId: number;
  symbol: ApeStakingTokenSymbol;
  supplied: boolean;
};

export type StakeBakcFormData = {
  apeTokenId: number;
  apeSymbol: ApeStakingMainTokenSymbol;
  apeCoinSource: WalletType;
  bakcSource: Maybe<WalletType>;
  apeSource: Maybe<WalletType>;
  bakcTokenId: number;
  amountFromCredit: Maybe<number>;
  amountFromBalance: Maybe<number>;
};

export type ApproveERC721FormData = {
  name: string;
  assetAddress: string;
  spender?: string;
};

export type ApproveERC20FormData = {
  assetAddress: string;
  name: string;
  amount: number | BigNumber;
  spender?: string;
};
