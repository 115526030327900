import { H5, Toggle } from '@parallel-mono/components';
import { useCallback, useMemo, useState } from 'react';

export const useConvertToggle = (desc: string, defaultValue = false) => {
  const [toggleOpened, setToggleOpened] = useState(defaultValue);

  const handleToggleChange = useCallback(value => {
    setToggleOpened(value);
  }, []);

  const toggleComponent = useMemo(() => {
    return (
      <>
        <H5>{desc}</H5>
        <Toggle checked={toggleOpened} onChange={handleToggleChange} />
      </>
    );
  }, [desc, handleToggleChange, toggleOpened]);

  return { toggleOpened, toggleComponent };
};
