import { memo, useCallback } from 'react';

import { useNativeTokenGateway } from '@/apps/paraspace/hooks';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { FormSubmitter } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type RepayNativeTokenFromEOASubmitterProps = {
  formData: { amount: string; max: boolean };
  onFinish: () => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

const RepayNativeTokenFromEOASubmitter = memo(
  ({ formData, onFinish, onError }: RepayNativeTokenFromEOASubmitterProps) => {
    const { amount, max } = formData;
    const { repayNativeToken } = useNativeTokenGateway();
    const { submitEOATransactions, eoaAccount } = useWeb3Context();

    const submit = useCallback(async () => {
      const tx = await repayNativeToken({ amount, max, from: eoaAccount });
      return submitEOATransactions(tx!);
    }, [amount, eoaAccount, max, repayNativeToken, submitEOATransactions]);

    return (
      <FormSubmitter
        inProgressMessage="Repaying"
        submit={submit}
        onFinish={onFinish}
        onError={onError}
      />
    );
  }
);

export default RepayNativeTokenFromEOASubmitter;
