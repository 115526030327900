import { memo } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { Listing } from '../../../types';

import { CancelListingForm } from './CancelListingForm';

import { useWeb3Context } from '@/apps/paraspace/contexts';

export type CancelListingModalProps = Omit<ModalProps, 'children'> & {
  listing: Listing;
  onConfirm?: () => void;
  disabled?: boolean;
};

export const CancelListingModal = memo(
  ({ listing, onConfirm, disabled, ...others }: CancelListingModalProps) => {
    const { account } = useWeb3Context();
    return (
      <Modal title="Cancel Listing" {...others}>
        <CancelListingForm
          listing={listing}
          account={account}
          onSubmit={onConfirm}
          disabled={disabled}
        />
      </Modal>
    );
  }
);
