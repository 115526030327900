import {
  DataGridColumn,
  Inline,
  Text,
  Button,
  H5,
  Spinner,
  Pagination,
  useBreakpoints,
  Stack,
  SmallText,
  H3
} from '@parallel-mono/components';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { MarketplaceIcon } from '@parallel-mono/business-components';

import { ReceivedOffer, useAccountReceivedOffers } from '../hooks';
import { AcceptOfferActionTooltip } from '../../../components';

import { DataGrid, Link, NFTThumbnail } from '@/apps/paraspace/components';
import { formatBalance, formatToCurrency, truncateAddress } from '@/apps/paraspace/utils/format';
import { useNetworkConfig } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import { useShopActions } from '@/apps/paraspace/pages/Shop/contexts';

const StyledH5 = styled(H5)`
  margin-left: 1rem;
`;
const StyledContainer = styled.div`
  position: relative;
  margin-top: 3rem;
`;

const ActionButton = styled(Button)`
  padding: 0.5rem 1rem;
`;

export const OfferReceived = ({ inLiquidation }: { inLiquidation: boolean }) => {
  const {
    offers,
    currentPage,
    totalPage,
    totalCount,
    handlePageChange: handleNextPage,
    loading
  } = useAccountReceivedOffers();
  const { account } = useWeb3Context();

  const { viewAllOffers } = useShopActions();

  const { acceptOffer } = useShopActions();

  const { checkIfTokenInPairing } = useP2PInfo();

  const {
    explorerLink: [explorerLinkBaseUrl]
  } = useNetworkConfig();

  const { mobile } = useBreakpoints();

  const offerReceivedColumns: DataGridColumn<ReceivedOffer>[] = useMemo(
    () => [
      {
        name: 'NFT',
        title: mobile ? '' : 'NFT',
        width: '2fr',
        render: ({
          data: {
            nftInfo: { tokenId, symbol, name }
          }
        }) => {
          return (
            <Inline alignItems="center" gap="0rem">
              <NFTThumbnail symbol={symbol} tokenId={tokenId} size="small" round />
              <StyledH5>{name}</StyledH5>
            </Inline>
          );
        }
      },
      {
        name: 'Highest Offer',
        title: 'Highest Offer',
        width: '1fr',
        render: ({
          data: {
            topOffer: { orderItem }
          }
        }) => {
          return (
            <Stack gap="0.25rem">
              <Inline alignItems="center" gap=".5rem">
                <Text>{formatBalance(orderItem.amount)}</Text>
                <Text>{orderItem.symbol}</Text>
              </Inline>
              <SmallText skin="secondary">{formatToCurrency(orderItem.priceInUsd)}</SmallText>
            </Stack>
          );
        }
      },
      {
        name: 'expiration',
        title: 'Expiration',
        width: '1fr',
        render: ({
          data: {
            topOffer: { expirationTime }
          }
        }) => {
          return dayjs(expirationTime).fromNow();
        }
      },
      {
        name: 'from',
        title: 'From',
        width: '1fr',
        render: ({
          data: {
            topOffer: { maker, platform }
          }
        }) => {
          return (
            <Inline gap="0.25rem" alignItems="center">
              <Link href={`${explorerLinkBaseUrl}/address/${maker}`} target="_blank">
                {truncateAddress(maker)}
              </Link>
              <MarketplaceIcon name={platform.toLowerCase()} size="xsmall" />
            </Inline>
          );
        }
      },
      {
        name: '',
        title: '',
        width: '1.5fr',
        render: ({ data: { topOffer, offerCount, nftInfo } }) => {
          const isP2PStaked = checkIfTokenInPairing(
            topOffer.considerationItem.symbol,
            topOffer.considerationItem.tokenId ?? 0
          );
          return (
            <Inline justifyContent="space-between" width="100%" gap="0.5rem">
              <AcceptOfferActionTooltip isP2PStaked={isP2PStaked}>
                <ActionButton
                  skin="secondary"
                  disabled={inLiquidation || topOffer.maker === account || isP2PStaked}
                  onClick={() => acceptOffer(topOffer, nftInfo)}
                >
                  Accept Highest
                </ActionButton>
              </AcceptOfferActionTooltip>
              <ActionButton
                variant="link"
                onClick={() => viewAllOffers(topOffer, nftInfo, inLiquidation)}
              >
                View All ({offerCount})
              </ActionButton>
            </Inline>
          );
        }
      }
    ],
    [
      mobile,
      explorerLinkBaseUrl,
      checkIfTokenInPairing,
      inLiquidation,
      account,
      acceptOffer,
      viewAllOffers
    ]
  );

  return (
    <StyledContainer>
      {loading && (
        <Inline width="100%" justifyContent="center" inset="2rem 0 0">
          <Spinner size="small" />
        </Inline>
      )}
      {!loading && (
        <>
          <H3>{totalCount} Offers Received</H3>
          <DataGrid<ReceivedOffer> columns={offerReceivedColumns} data={offers} />
          <Pagination
            total={totalPage}
            onChange={handleNextPage}
            siblingCount={0}
            startBoundaryCount={3}
            page={currentPage}
          />
        </>
      )}
    </StyledContainer>
  );
};
