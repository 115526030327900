import { useState, useEffect, useMemo } from 'react';
import { keyBy, sumBy } from 'lodash';
import BigNumber from 'bignumber.js';

import { useApeStaking } from '@/apps/paraspace/pages/hooks/ApeStaking/useApeStaking';
import { ApeStakingTokenSymbol } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts';

export type StakingInfo = {
  stakeLimit: BigNumber;
  stakedAmount: BigNumber;
  pendingRewards: BigNumber;
  apy: BigNumber;
  tokenId: number;
};

const useBatchApeStakingInfo = (
  symbol: string,
  suppliedItems: number[],
  selectedItemIds: number[],
  showApeStakingInfo: boolean
) => {
  const { account } = useWeb3Context();
  const [stakingInfo, setStakingInfo] = useState<Record<string, StakingInfo>>({});
  const { getStakingInfoByTokenInfo } = useApeStaking();

  useEffect(() => {
    if (showApeStakingInfo) {
      Promise.all(
        suppliedItems.map(async id => {
          const info = (await getStakingInfoByTokenInfo(
            symbol as ApeStakingTokenSymbol,
            String(id),
            account
          )) as StakingInfo;
          return { ...info, tokenId: id };
        })
      ).then(res => {
        setStakingInfo(
          keyBy(
            res.filter(({ stakedAmount }) => !!stakedAmount.toNumber()),
            'tokenId'
          )
        );
      });
    }
  }, [account, getStakingInfoByTokenInfo, showApeStakingInfo, suppliedItems, symbol]);

  const totalStakingInfo = useMemo(() => {
    const selectedInfo = Object.values(stakingInfo).filter(({ tokenId }) =>
      selectedItemIds.includes(tokenId)
    );
    const totalAmount = sumBy(selectedInfo, item => item.stakedAmount.toNumber());
    const totalRewards = sumBy(selectedInfo, item => item.pendingRewards.toNumber());
    return { totalAmount, totalRewards };
  }, [selectedItemIds, stakingInfo]);

  return { stakingInfo, totalStakingInfo };
};

export default useBatchApeStakingInfo;
