import { useCallback } from 'react';
import { CreateOrderInput } from 'paraspace-seaport-js/lib/types';
import { ParaXProvider } from 'parax-sdk';

import { getApproveActions } from '@/apps/paraspace/utils/approve/getApproveActions';
import useSeaport from '@/apps/paraspace/pages/hooks/useSeaport';
import { useERC721 } from '@/apps/paraspace/hooks';

export const useApproveSeaportConduit = (
  provider: ParaXProvider,
  signer: string,
  conduitAddress: string
) => {
  const { getAllowanceForAll, approvalForAll } = useERC721();
  const { seaportSDK } = useSeaport();

  const genApproveSeaportConduit = useCallback(
    async (createdAcceptOrderInput: CreateOrderInput) => {
      if (!provider || !seaportSDK) {
        return null;
      }
      const approveActions = await getApproveActions(
        {
          provider,
          offer: createdAcceptOrderInput.offer,
          spender: conduitAddress
        },
        signer
      );

      const approveSeaportConduitTxs = await Promise.all(
        approveActions.map(async action => {
          const {
            token: collectionAddress,
            identifierOrCriteria: tokenId,
            operator: spender
          } = action;

          const allowance = await getAllowanceForAll({
            ids: [Number(tokenId)],
            spender,
            user: signer,
            tokenAddress: collectionAddress
          });
          if (allowance) return null;
          return [await approvalForAll({ user: signer, spender, tokenAddress: collectionAddress })];
        })
      );
      return approveSeaportConduitTxs.flat();
    },
    [signer, provider, seaportSDK, approvalForAll, conduitAddress, getAllowanceForAll]
  );

  return {
    genApproveSeaportConduit
  };
};
