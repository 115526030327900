import { H5, Inline, SmallText, Responsive, useBreakpoints } from '@parallel-mono/components';
import { CryptoIcon, Pill } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';
import { FC } from 'react';
import styled from 'styled-components';
import { BigNumber } from 'bignumber.js';

import { ERC721Symbol, ApeStakingTokenSymbol } from '@/apps/paraspace/typings';
import { ValuePill } from '@/apps/paraspace/components';

const StyledPill = styled(Pill)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    padding: 0;
    height: 2.5rem;
  `};
`;

const pillLabelList = [
  ERC721Symbol.BAYC,
  ERC721Symbol.MAYC,
  ERC721Symbol.BAKC
] as Array<ApeStakingTokenSymbol>;

interface PillBarProps {
  compoundApy: BigNumber;
  targetPool: ApeStakingTokenSymbol;
  onHandleLabelSelect: (label: ApeStakingTokenSymbol) => void;
}

export const PillBar: FC<PillBarProps> = props => {
  const { compoundApy, targetPool: labelSelected, onHandleLabelSelect } = props;
  const { mobile } = useBreakpoints();

  return (
    <Responsive alignItems={mobile ? 'flex-start' : 'center'} justifyContent="space-between">
      <Inline
        width={mobile ? '100%' : 'fit-content'}
        gap="0.5rem"
        justifyContent={mobile ? 'space-between' : 'flex-start'}
      >
        {pillLabelList.map(label => (
          <StyledPill
            key={label}
            label={
              <Inline gap="0.35rem">
                <CryptoIcon symbol={label} size="1.25rem" />
                <H5>{label}</H5>
              </Inline>
            }
            active={labelSelected === label}
            onToggle={() => onHandleLabelSelect(label)}
            skin="neutral"
          />
        ))}
      </Inline>
      <ValuePill
        width={mobile ? '100%' : 'fit-content'}
        justifyContent={mobile ? 'space-between' : 'center'}
        gap="0.5rem"
        alignItems="center"
      >
        <SmallText skin="secondary">{labelSelected} Comp. APY</SmallText>
        <SmallText skin="success">{formatNumber(compoundApy, { output: 'percent' })}</SmallText>
      </ValuePill>
    </Responsive>
  );
};
