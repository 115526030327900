import { FC, useState } from 'react';
import BigNumberJs from 'bignumber.js';

import { OfferFormValue } from '../MakeOfferForm';

import { ApproveWallet, Loading } from '.';

import { getApproveActions } from '@/apps/paraspace/utils/approve/getApproveActions';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import useLegacyERC20 from '@/apps/paraspace/pages/hooks/useLegacyERC20';
import { useContractsMap } from '@/apps/paraspace/hooks';
import { shiftedRightBy } from '@/apps/paraspace/utils/calculations';

interface ApproveProps {
  onFinish?: () => void;
  onError?: (e: any) => void;
  offerFormValue: OfferFormValue;
}

const Approve: FC<ApproveProps> = ({ offerFormValue, onError, onFinish }) => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();
  const { token } = offerFormValue;
  const [waitingApprove, setWaitingApprove] = useState<Boolean>(true);
  const { approve } = useLegacyERC20(token.address);

  useAsyncEffect(async () => {
    try {
      const offerTokenList = [
        {
          amount: shiftedRightBy(BigNumberJs(token.offerAmount), token.decimal).toString(),
          token: token.address
        }
      ];
      const actions = await getApproveActions(
        {
          provider: provider!,
          offer: offerTokenList,
          spender: contracts.Conduit
        },
        account
      );

      if (actions.length > 0) {
        setWaitingApprove(true);
        await Promise.all(actions.map(each => approve(each.operator)));
        setWaitingApprove(false);
      }
      onFinish?.();
    } catch (e: any) {
      onError?.(e);
    }
  }, []);

  return waitingApprove ? <ApproveWallet /> : <Loading tip="Approving" />;
};

export default Approve;
