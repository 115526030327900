import { TokenInput } from '@parallel-mono/business-components';
import { useCallback, useMemo, useState } from 'react';
import { Card, H4, Text } from '@parallel-mono/components';
import { ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { every, isNil, memoize } from 'lodash';
import BigNumberJs, { BigNumber } from 'bignumber.js';

import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { ProjectedRewardsBox } from '@/apps/paraspace/pages/ApePairing/pages/Calculator/components/ProjectedRewardsBox';
import { EstimatedProfitsChart } from '@/apps/paraspace/pages/ApePairing/pages/Calculator/components/EstimatedProfitsChart';
import { useCustomizedApeCoinRewardsInfo } from '@/apps/paraspace/pages/ApePairing/pages/Calculator/hooks/useCustomizedApeCoinRewardsInfo';
import { useOfficialRewardsInfo } from '@/apps/paraspace/pages/ApePairing/pages/Calculator/hooks/useOfficialRewardsInfo';
import { ApeStakingMainTokenSymbol, ERC20Symbol } from '@/apps/paraspace/typings';
import { useApePoolApy } from '@/apps/paraspace/pages/hooks/ApeStaking/useApePoolApy';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { calculateDailyInterest } from '@/apps/paraspace/utils/calculations';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { DAYS_OF_YEAR } from '@/apps/paraspace/consts/fixtures';

const FullWidthInput = styled(TokenInput)`
  width: 100%;
`;

const StyledText = styled(Text)`
  margin-top: 1.5rem;
`;

export const NFTCalculator = ({ symbol }: { symbol: ApeStakingMainTokenSymbol }) => {
  const { apeStakingPoolSummary } = useAutoCompoundApeInfo();
  const defaultAmount = useMemo(
    () => apeStakingPoolSummary?.[symbol].stakeLimit.toNumber() ?? 0,
    [apeStakingPoolSummary, symbol]
  );
  const [stakedAmount, setStakedAmount] = useState(defaultAmount);

  const [inputRawApr, setInputRawApr] = useState(0.25);
  const [borrowPosition, setBorrowPosition] = useState(0);

  const handleAmountChange = useCallback(newAmount => {
    setStakedAmount(newAmount || 0);
  }, []);

  const navigate = useNavigate();
  const handleNav = useCallback(() => {
    navigate(absoluteRouteNames.APE_STAKING.NFT_POOLS);
    navigate(absoluteRouteNames.APE_STAKING.NFT_POOLS);
  }, [navigate]);

  const handleInputRawAprChange = useCallback((newApr: number) => {
    setInputRawApr(newApr || 0);
  }, []);

  const { erc20InfoMap = {} } = useMMProvider();
  const { priceInUsd, borrowApyRate } = erc20InfoMap[ERC20Symbol.APE] || {};
  const [apePoolApr] = useApePoolApy(ERC20Symbol.APE);
  const { effectiveCapeBorrowApy, nftPoolsCompoundApy } = useAutoCompoundApeInfo();
  const compoundAPY = nftPoolsCompoundApy?.[symbol] ?? BigNumber(0);

  const netCompoundAPY = useMemo(() => {
    if (stakedAmount === 0) {
      return BigNumber(0);
    }
    return compoundAPY
      .times(stakedAmount ?? 0)
      .minus((effectiveCapeBorrowApy ?? BigNumber(0)).times(stakedAmount * borrowPosition))
      .div(stakedAmount);
  }, [compoundAPY, borrowPosition, effectiveCapeBorrowApy, stakedAmount]);

  const paraspaceRewardsInfo = useMemo(
    () => ({
      apy: netCompoundAPY.isZero() ? compoundAPY : netCompoundAPY,
      yearlyRewardsInApe: netCompoundAPY.times(stakedAmount),
      yearlyRewardsInUsd: netCompoundAPY.times(stakedAmount).times(priceInUsd ?? 0)
    }),
    [compoundAPY, netCompoundAPY, priceInUsd, stakedAmount]
  );

  const customizedNFTRewardsInfo = useCustomizedApeCoinRewardsInfo(stakedAmount, inputRawApr);
  const officialNFTRewardsInfo = useOfficialRewardsInfo(stakedAmount, symbol);

  const loadingCalculationParams = useMemo(
    () =>
      every(paraspaceRewardsInfo, v => isNil(v)) ||
      every(customizedNFTRewardsInfo, v => isNil(v)) ||
      every(officialNFTRewardsInfo, v => isNil(v)),
    [paraspaceRewardsInfo, customizedNFTRewardsInfo, officialNFTRewardsInfo]
  );

  const graphProfitFormula = useMemo(() => {
    if (!stakedAmount || loadingCalculationParams) {
      return {
        paraSpaceEstimatedProfitsByDay: null,
        anyAPREstimatedProfitsByDay: null,
        horizenLabsEstimatedProfitsByDay: null
      };
    }

    const dailyNftPoolReward = officialNFTRewardsInfo
      .officialApr!.div(DAYS_OF_YEAR)
      .times(stakedAmount);
    const apeBorrowDailyInterest = calculateDailyInterest(borrowApyRate);
    const apePoolDailyInterest = apePoolApr?.div(DAYS_OF_YEAR);

    const memorizedCalc = memoize(
      (
        targetDay: number
      ): {
        totalProfit: BigNumberJs;
        apePoolPrincipal: BigNumberJs;
      } => {
        if (targetDay <= 0) {
          return {
            totalProfit: BigNumber(0),
            apePoolPrincipal: BigNumber(0)
          };
        }

        const {
          totalProfit: accumulatedProfitTillYesterday,
          apePoolPrincipal: accumulatedApePoolPrincipalTillYesterday
        } = memorizedCalc(targetDay - 1);
        const currentApePoolPrincipal =
          accumulatedApePoolPrincipalTillYesterday.plus(dailyNftPoolReward);
        // ape pool reward
        const dailyApePoolReward = currentApePoolPrincipal.times(apePoolDailyInterest ?? 0);
        // borrowed ape amount
        const apeBorrowAmount = stakedAmount * borrowPosition;
        const dailyApeBorrowAmount = apeBorrowDailyInterest.times(apeBorrowAmount);

        // ape pool principal
        const apePoolPrincipal = currentApePoolPrincipal.plus(dailyApePoolReward);

        return {
          totalProfit: dailyNftPoolReward
            .plus(dailyApePoolReward)
            .plus(accumulatedProfitTillYesterday)
            .minus(dailyApeBorrowAmount),
          apePoolPrincipal
        };
      }
    );
    const paraSpaceEstimatedProfitsByDay = (day: number) => {
      return memorizedCalc(day).totalProfit.toNumber();
    };
    const anyAPREstimatedProfitsByDay = (day: number) =>
      stakedAmount *
      customizedNFTRewardsInfo.customizedApr!.times(day).div(DAYS_OF_YEAR).toNumber();
    const horizenLabsEstimatedProfitsByDay = (day: number) =>
      stakedAmount * officialNFTRewardsInfo.officialApr!.times(day).div(DAYS_OF_YEAR).toNumber();

    return {
      paraSpaceEstimatedProfitsByDay,
      anyAPREstimatedProfitsByDay,
      horizenLabsEstimatedProfitsByDay
    };
  }, [
    apePoolApr,
    borrowApyRate,
    borrowPosition,
    customizedNFTRewardsInfo.customizedApr,
    loadingCalculationParams,
    officialNFTRewardsInfo.officialApr,
    stakedAmount
  ]);

  const handleBorrowSliderChange = useCallback((value: number) => {
    setBorrowPosition(value);
  }, []);

  return (
    <div>
      <FullWidthInput
        label={<H4>Stake Amount</H4>}
        token="APE"
        value={stakedAmount}
        onChange={handleAmountChange}
        decimals={MAXIMUM_BALANCE_DECIMALS}
      />

      <ProjectedRewardsBox
        paraspaceRewardsInfo={paraspaceRewardsInfo}
        customizedRewardsInfo={customizedNFTRewardsInfo}
        officialRewardsInfo={officialNFTRewardsInfo}
        inputRawApr={inputRawApr}
        handleInputRawAprChange={handleInputRawAprChange}
        handleNav={handleNav}
        symbol={symbol}
        onBorrowSliderChange={handleBorrowSliderChange}
      />

      <Card border>
        <ResponsiveContainer width="100%" aspect={2.2}>
          <EstimatedProfitsChart {...graphProfitFormula} />
        </ResponsiveContainer>
      </Card>
      <StyledText skin="secondary">
        *for amount below 1000 APE, actual compound frequency and net APY may be lower than shown.
      </StyledText>
    </div>
  );
};
