import { Alert, Icon } from '@parallel-mono/components';
import { memo } from 'react';

import { AuctionStatus } from '@/apps/paraspace/pages/Liquidation/types';
import { LiquidationInfoForAsset } from '@/apps/paraspace/generated/graphql';
import { RECOVERY_NFT_HF_THRESHOLD } from '@/apps/paraspace/pages/config';

const LiquidationTip = ({ liquidation }: { liquidation: LiquidationInfoForAsset }) => {
  const {
    accountInfo: { nftHealthFactor },
    asset: { auctionStatus }
  } = liquidation;
  if (auctionStatus === AuctionStatus.NotStarted && nftHealthFactor < 1) {
    return (
      <Alert type="error" icon={<Icon name="droplet" strokeWidth={2} />}>
        This NFT is to be liquidated.
      </Alert>
    );
  }
  if (nftHealthFactor > RECOVERY_NFT_HF_THRESHOLD && auctionStatus === AuctionStatus.NotStarted) {
    return null;
  }
  if (nftHealthFactor > RECOVERY_NFT_HF_THRESHOLD && auctionStatus !== AuctionStatus.NotStarted) {
    return <Alert type="info">This NFT just recovered from liquidation.</Alert>;
  }
  return (
    <Alert type="error" icon={<Icon name="droplet" strokeWidth={2} />}>
      This NFT is in Liquidation. Liquidation Price drops regularly until it’s sold.
    </Alert>
  );
};

export default memo(LiquidationTip);
