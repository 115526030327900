import React, { ReactNode, memo, useCallback, useContext, useMemo } from 'react';
import { UiPoolDataProvider, WalletBalanceProvider } from 'paraspace-utilities-contract-helpers';
import BigNumber from 'bignumber.js';
import { Network } from 'paraspace-configs-v2';
import { JsonRpcProvider } from '@ethersproject/providers';
import { omitBy } from 'lodash';

import { useAutoPolledState } from '../hooks';
import { StakeFishInfo } from '../utils';

import { getUserERC721BalanceMap, getUserERC20BalanceMap } from './utils';

import { ERC20Symbol, ERC721Symbol, FetchingStatus } from '@/apps/paraspace/typings';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';
import { DELISTED_ERC721_SYMBOLS, ERC721Config } from '@/apps/paraspace/config';

type UserBalanceContextValue = {
  erc20BalanceMap: Maybe<Record<ERC20Symbol, BigNumber>>;
  erc721BalanceMap: Maybe<
    Record<
      ERC721Symbol,
      {
        balance: number[];
        tokenTraitMultipliers: Record<string, BigNumber>;
        stakeFishInfoMap: Record<string, StakeFishInfo>;
      }
    >
  >;
  refreshERC20BalanceMap: () => Promise<void>;
  refreshERC721BalanceMap: () => Promise<void>;
  erc20BalanceMapPollingStatus: FetchingStatus;
  erc721BalanceMapPollingStatus: FetchingStatus;
};

type UserBalanceProviderProps = {
  children: ReactNode;
  pollingInterval: number;
  account: string;
  provider: JsonRpcProvider;
  chainId: Network;
};

export const createUserBalanceProvider = (displayName: string) => {
  const UserBalanceContext = React.createContext<UserBalanceContextValue>({
    erc20BalanceMap: null,
    erc721BalanceMap: null,
    refreshERC20BalanceMap: async () => {
      throw new Error('Not implemented yet');
    },
    refreshERC721BalanceMap: async () => {
      throw new Error('Not implemented yet');
    },
    erc20BalanceMapPollingStatus: FetchingStatus.INIT,
    erc721BalanceMapPollingStatus: FetchingStatus.INIT
  });

  const UserBalanceProvider = memo(
    ({ children, pollingInterval, account, provider, chainId }: UserBalanceProviderProps) => {
      const { erc20Config, erc721Config, contracts } = useAppConfig();

      const walletBalanceProvider = useMemo(
        () =>
          new WalletBalanceProvider({
            walletBalanceProviderAddress: contracts.WalletBalanceProvider,
            provider
          }),
        [provider, contracts.WalletBalanceProvider]
      );

      const uiPoolDataProvider = useMemo(
        () =>
          new UiPoolDataProvider({
            uiPoolDataProviderAddress: contracts.UiPoolDataProvider,
            provider,
            chainId
          }),
        [provider, chainId, contracts.UiPoolDataProvider]
      );

      const pollUserERC20BalancesMap = useCallback(async (): Promise<
        UserBalanceContextValue['erc20BalanceMap']
      > => {
        if (!account) {
          return null;
        }
        return getUserERC20BalanceMap({
          provider,
          account,
          walletBalanceProvider,
          erc20Config
        });
      }, [account, erc20Config, walletBalanceProvider, provider]);

      const pollUserERC721BalancesMap = useCallback(async (): Promise<
        UserBalanceContextValue['erc721BalanceMap']
      > => {
        if (!account) {
          return null;
        }
        return getUserERC721BalanceMap({
          provider: uiPoolDataProvider,
          lendingPoolAddressProvider: contracts.PoolAddressesProvider,
          account,
          erc721Config: omitBy(erc721Config, v =>
            DELISTED_ERC721_SYMBOLS.includes(v.symbol)
          ) as ERC721Config
        });
      }, [account, uiPoolDataProvider, erc721Config, contracts.PoolAddressesProvider]);

      const {
        pollingStatus: erc20BalanceMapPollingStatus,
        state: erc20BalanceMap,
        refresh: refreshERC20BalanceMap
      } = useAutoPolledState({
        pollingInterval,
        pollFn: pollUserERC20BalancesMap,
        initialState: null
      });

      const {
        pollingStatus: erc721BalanceMapPollingStatus,
        state: erc721BalanceMap,
        refresh: refreshERC721BalanceMap
      } = useAutoPolledState({
        pollingInterval,
        pollFn: pollUserERC721BalancesMap,
        initialState: null
      });

      const value = useMemo(
        () => ({
          erc20BalanceMap,
          erc721BalanceMap,
          erc20BalanceMapPollingStatus,
          erc721BalanceMapPollingStatus,
          refreshERC20BalanceMap,
          refreshERC721BalanceMap
        }),
        [
          erc20BalanceMap,
          erc721BalanceMap,
          erc20BalanceMapPollingStatus,
          erc721BalanceMapPollingStatus,
          refreshERC20BalanceMap,
          refreshERC721BalanceMap
        ]
      );

      const stabilizedValue = useStabilizedSnapshot(value);

      return (
        <UserBalanceContext.Provider value={stabilizedValue}>
          {children}
        </UserBalanceContext.Provider>
      );
    }
  );

  UserBalanceProvider.displayName = displayName;

  return {
    useUserBalances: () => useContext(UserBalanceContext),
    UserBalanceProvider
  };
};
