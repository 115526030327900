import { useMemo } from 'react';
import BigNumberJs from 'bignumber.js';

import { validate } from './validate';

import {
  ApeCoinListing,
  ApeCoinSourceToJoinApeListing
} from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/types';
import {
  ApeStakingMainTokenSymbol,
  ERC20Symbol,
  ERC721Symbol,
  WalletType
} from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { zero } from '@/apps/paraspace/consts/values';
import { tryRoundUpCapeBalance } from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/utils';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';

export const useErrors = ({
  apeCoinListing,
  apeCoinSourceInput,
  apeListingTokenInput,
  bakcListingTokenInput,
  shareOfBakc,
  shareOfApeCoin,
  shareOfApe,
  walletType
}: {
  apeCoinListing: Maybe<ApeCoinListing>;
  apeCoinSourceInput: ApeCoinSourceToJoinApeListing;
  apeListingTokenInput: Maybe<{
    symbol: ApeStakingMainTokenSymbol;
    tokenId: number;
    supplied: boolean;
  }>;
  bakcListingTokenInput: Maybe<{
    symbol: ERC721Symbol.BAKC;
    tokenId: number;
    supplied: boolean;
  }>;
  shareOfApe: Maybe<BigNumberJs>;
  shareOfApeCoin: Maybe<BigNumberJs>;
  shareOfBakc: Maybe<BigNumberJs>;
  walletType: WalletType;
}) => {
  const { erc20InfoMap } = useMMProvider();
  const { erc20BalanceMap } = useEOABalances();

  const apeBalance =
    walletType === 'EOA'
      ? erc20BalanceMap?.[ERC20Symbol.APE] ?? zero
      : erc20InfoMap?.[ERC20Symbol.APE].balance ?? zero;
  const cApeBalance =
    walletType === 'EOA'
      ? erc20BalanceMap?.[ERC20Symbol.CAPE] ?? zero
      : tryRoundUpCapeBalance(erc20InfoMap?.[ERC20Symbol.CAPE].balance ?? zero);

  return useMemo(
    () =>
      validate({
        apeCoinListing,
        apeCoinSource: apeCoinSourceInput,
        apeListingToken: apeListingTokenInput,
        bakcListingToken: bakcListingTokenInput,
        apeBalance,
        cApeBalance,
        shareOfApeCoin,
        shareOfApe,
        shareOfBakc
      }),
    [
      apeCoinListing,
      apeCoinSourceInput,
      apeListingTokenInput,
      bakcListingTokenInput,
      apeBalance,
      cApeBalance,
      shareOfApeCoin,
      shareOfApe,
      shareOfBakc
    ]
  );
};
