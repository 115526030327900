import { memo, useCallback, useState } from 'react';
import {
  Button,
  H6,
  Inline,
  Responsive,
  Stack,
  StackProps,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import styled from 'styled-components';
import { InfoPanel } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import { useInfos, useSelectableTokens } from './hooks';
import { UniswapItem } from './types';
import { uniswapInterface } from './consts';

import { LinkButton, NFTThumbnailCheck, NoNFTs } from '@/apps/paraspace/components';
import { MIN_BALANCE_THRESHOLD } from '@/apps/paraspace/utils/format';
import { ERC20Symbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';

type UniSwapV3SupplyFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  pair: [ERC20Symbol, ERC20Symbol];
  walletType: WalletType;
  onSubmit: (tokenIds: number[]) => void;
};

const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  max-height: 18.75rem;
  overflow: auto;
  ${({ theme }) => theme.breakpoints.down('md')`
  grid-template-columns: repeat(2, 1fr);
`};
`;

const ConnectSymbol = styled.span`
  font-family: 'Inter', sans-serif;
`;

export const UniSwapV3SupplyForm = memo(
  ({ onSubmit, pair, walletType, ...others }: UniSwapV3SupplyFormProps) => {
    const selectableTokens = useSelectableTokens(pair, walletType);
    const [selectedTokens, setSelectedTokens] = useState<UniswapItem[]>([]);
    const createHandleItemClicked = useCallback(
      (item: UniswapItem) => (checked: boolean) => {
        setSelectedTokens(curr => {
          if (checked) {
            return curr.concat([item]);
          }
          return curr.filter(it => it.tokenId !== item.tokenId);
        });
      },
      []
    );

    const infos = useInfos(selectedTokens);

    const { mobile } = useBreakpoints();

    const handleSelectAllClick = useCallback(() => {
      setSelectedTokens(items =>
        items.length === selectableTokens.length ? [] : selectableTokens
      );
    }, [selectableTokens]);

    const handleSupply = useCallback(() => {
      onSubmit(selectedTokens.map(it => it.tokenId));
    }, [onSubmit, selectedTokens]);

    if (selectableTokens.length === 0) {
      return (
        <Stack gap="1.5rem" alignItems="center" {...others}>
          <NoNFTs
            gap="0.25rem"
            description={
              <>
                There’s no{' '}
                <Text as="span" fontWeight="bold">
                  {pair[0]}/{pair[1]}
                </Text>{' '}
                LP on this wallet.
              </>
            }
          />
          <Inline>
            <LinkButton
              as="a"
              to=""
              target="_blank"
              rel="noopener noreferrer"
              href={`${uniswapInterface}/#/add`}
              size="large"
              skin="secondary"
            >
              Get LP Tokens
            </LinkButton>
          </Inline>
        </Stack>
      );
    }

    return (
      <Stack gap="1.5rem" {...others}>
        <ItemsContainer>
          {selectableTokens.map(tokenItem => {
            const { tokenId, token0ConvertToken1MaxPrice, token0ConvertToken1MinPrice } = tokenItem;
            return (
              <NFTThumbnailCheck
                key={tokenId}
                symbol={ERC721Symbol.UNISWAP_LP}
                tokenId={tokenId}
                checked={selectedTokens.some(it => it.tokenId === tokenId)}
                onChange={createHandleItemClicked(tokenItem)}
              >
                <H6 fontWeight="bold">
                  {formatNumber(token0ConvertToken1MinPrice, {
                    decimal: MAXIMUM_BALANCE_DECIMALS,
                    threshold: { min: MIN_BALANCE_THRESHOLD }
                  })}
                  <ConnectSymbol>⟷</ConnectSymbol>
                  {formatNumber(token0ConvertToken1MaxPrice, {
                    decimal: MAXIMUM_BALANCE_DECIMALS,
                    threshold: { min: MIN_BALANCE_THRESHOLD }
                  })}
                </H6>
              </NFTThumbnailCheck>
            );
          })}
        </ItemsContainer>
        <InfoPanel skin="primary" infos={infos} />

        <Responsive breakPoint="tablet">
          <Button block={mobile} skin="secondary" size="large" onClick={handleSelectAllClick}>
            {selectedTokens.length === selectableTokens.length ? 'Unselect All' : 'Select All'}
          </Button>
          <Button
            skin="primary"
            size="large"
            block
            disabled={selectedTokens.length <= 0}
            onClick={handleSupply}
          >
            {`Supply ${selectedTokens.length || ''} ${
              selectedTokens.length > 1 ? 'Uniswaps' : 'Uniswap'
            }`}
          </Button>
        </Responsive>
      </Stack>
    );
  }
);
