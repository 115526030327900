import { createContext, memo, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';
import BigNumberJS from 'bignumber.js';

import { ClaimRewardsModal, ClaimRewardsModalProps } from './ClaimRewardsModal';
import { ApeStakeModal, ApeStakeModalProps, StakeApeInfo } from './ApeStakeModal';
import WithdrawModal, { WithdrawModalProps } from './WithdrawModal';
import { BakcStakeModal, BakcStakeModalProps } from './BakcStakeModal';
import {
  ClaimAllRewardsModal,
  ClaimAllRewardsModalProps,
  ClaimingType
} from './ClaimAllRewardsModal';
import AutoCompoundApeModal from './AutoCompoundApeModal';
import { TransferNftParams, TransferModal, TransferModalProps } from './TransferModal';
import { AutoSellModal } from './AutoSellModal';
import { ReStakeModal, ReStakeModalProps } from './ReStakeModal';
import { SetAutoSellParams, useSetAutoSellStrategy } from './hooks';

import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC721Symbol,
  WalletType
} from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

type ApeStakeManagerContextProps = {
  withdrawApe: (withdrawParams: {
    symbol: ApeStakingTokenSymbol;
    tokenId: number;
    mainTokenId?: number;
    mainTokenSymbol?: ApeStakingMainTokenSymbol;
    stakedAmount?: BigNumberJS;
    pendingRewards: BigNumberJS | undefined;
  }) => Promise<void>;
  stakeApe: (nftInfo: StakeApeInfo) => Promise<void>;
  transferNft: (transferParams: TransferNftParams) => Promise<void>;
  claimRewards: (claimParams: {
    symbol: ApeStakingTokenSymbol;
    tokenId: number | null;
    mainTokenId?: number;
    mainTokenSymbol?: ApeStakingMainTokenSymbol;
    pendingRewards?: BigNumberJS;
  }) => Promise<void>;
  stakeBakc: (
    bakcTokenId: number,
    apeSource: Maybe<WalletType>,
    apeCoinSource: WalletType
  ) => Promise<void>;
  claimAllRewards: (claimType: ClaimingType) => Promise<void>;
  stakeAutoCompoundApe: ({
    amount,
    enableSupplyCApe,
    onSuccess,
    apeCoinSource
  }: {
    amount: string | null;
    enableSupplyCApe: boolean;
    onSuccess?: () => void;
    apeCoinSource: WalletType;
  }) => Promise<void>;
  setAutoSellStrategy: (params: SetAutoSellParams) => Promise<void>;
  setReStake: () => Promise<void>;
};

const ApeStakeManagerContext = createContext<ApeStakeManagerContextProps>({
  stakeApe: () => {
    throw new Error('ApeStakingManagerProvider not found');
  },
  withdrawApe: () => {
    throw new Error('ApeStakingManagerProvider not found');
  },
  claimRewards: () => {
    throw new Error('ApeStakingManagerProvider not found');
  },
  transferNft: () => {
    throw new Error('ApeStakingManagerProvider not found');
  },
  stakeBakc: () => {
    throw new Error('ApeStakingManagerProvider not found');
  },
  claimAllRewards: () => {
    throw new Error('ApeStakingManagerProvider not found');
  },
  stakeAutoCompoundApe: () => {
    throw new Error('ApeStakingManagerProvider not found');
  },
  setAutoSellStrategy: () => {
    throw new Error('SetAutoSellProvider not found');
  },
  setReStake: () => {
    throw new Error('ApeStakingManagerProvider not found');
  }
});

type ApeStakingManagerProviderProps = {
  children: ReactNode;
};

const defaultManageStakeModalProps: ApeStakeModalProps = {
  isOpen: false,
  nftInfo: {} as StakeApeInfo,
  onClose: noop,
  onFinish: noop,
  onError: noop
};

const defaultWithdrawModalProps: WithdrawModalProps = {
  isOpen: false,
  symbol: null,
  tokenId: null,
  onClose: () => {}
};

const defaultClaimRewardsModalProps: ClaimRewardsModalProps = {
  isOpen: false,
  symbol: null,
  tokenId: null
};

const defaultTransferModalProps: TransferModalProps = {
  isOpen: false,
  symbol: ERC721Symbol.BAYC,
  tokenId: 0,
  supplied: false
};

const defaultClaimAllRewardsModalProps: { isOpen: boolean; onClose: () => void } = {
  isOpen: false,
  onClose: () => {}
};

const defaultBakcStakeModalProps: BakcStakeModalProps = {
  apeCoinSource: 'AA',
  bakcSource: null,
  isOpen: false,
  bakcTokenId: 0,
  onClose: noop,
  onFinish: noop,
  onError: noop
};

const defaultStakeAutoCompoundApeModalProps: {
  apeCoinSource: WalletType;
  isOpen: boolean;
  amount: string | null;
  enableSupplyCApe: boolean;
  onClose: () => void;
  onSuccess?: () => void;
} = {
  apeCoinSource: 'AA',
  isOpen: false,
  enableSupplyCApe: false,
  amount: null,
  onClose: noop,
  onSuccess: noop
};

const defaultReStakeModalProps: ReStakeModalProps = {
  isOpen: false,
  onClose: noop
};

export const ApeStakingManagerProvider = memo(({ children }: ApeStakingManagerProviderProps) => {
  const [autoSellModalProps, setAutoSellStrategy] = useSetAutoSellStrategy();

  const [manageStakeModalProps, setManageStakeModalProps] = useState<ApeStakeModalProps>(
    defaultManageStakeModalProps
  );

  const stakeApe = useCallback(
    (nftInfo: StakeApeInfo) => {
      if (manageStakeModalProps.isOpen) {
        throw new Error('There is a ape stake in progress');
      }
      return new Promise<void>((resolve, reject) => {
        setManageStakeModalProps({
          isOpen: true,
          nftInfo,
          onClose: () => {
            setManageStakeModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
          },
          onFinish: resolve,
          onError: reject
        });
      });
    },
    [manageStakeModalProps]
  );

  const [withdrawModalProps, setWithdrawModalProps] =
    useState<WithdrawModalProps>(defaultWithdrawModalProps);

  const withdrawApe = useCallback(
    ({ symbol, tokenId, mainTokenId, mainTokenSymbol, stakedAmount, pendingRewards }) => {
      if (withdrawModalProps.isOpen) {
        throw new Error('There is a withdraw modal in progress');
      }
      return new Promise<void>(resolve => {
        setWithdrawModalProps({
          isOpen: true,
          symbol,
          tokenId,
          mainTokenId,
          mainTokenSymbol,
          stakedAmount,
          pendingRewards,
          onClose: () => {
            setWithdrawModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
            resolve();
          }
        });
      });
    },
    [withdrawModalProps]
  );

  const [claimRewardsModalProps, setClaimRewardsModalProps] = useState<ClaimRewardsModalProps>(
    defaultClaimRewardsModalProps
  );
  const [transferModalProps, setTransferModalProps] =
    useState<TransferModalProps>(defaultTransferModalProps);

  const claimRewards = useCallback(
    ({ symbol, tokenId, mainTokenId, mainTokenSymbol, pendingRewards }) => {
      return new Promise<void>(resolve => {
        setClaimRewardsModalProps({
          isOpen: true,
          symbol,
          tokenId,
          mainTokenId,
          mainTokenSymbol,
          pendingRewards,
          onClose: () => {
            setClaimRewardsModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
            resolve();
          }
        });
      });
    },
    [setClaimRewardsModalProps]
  );

  const transferNft = useCallback(
    ({ symbol, tokenId, supplied, mainTokenId, mainTokenSymbol }: TransferNftParams) => {
      return new Promise<void>(resolve => {
        setTransferModalProps({
          isOpen: true,
          symbol,
          tokenId,
          supplied,
          mainTokenId,
          mainTokenSymbol,
          onClose: () => {
            setTransferModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
            resolve();
          }
        });
      });
    },
    []
  );

  const [claimAllRewardsModalProps, setClaimAllRewardsModalProps] =
    useState<ClaimAllRewardsModalProps>(defaultClaimAllRewardsModalProps);

  const claimAllRewards = useCallback(
    (claimingType: ClaimingType) => {
      return new Promise<void>(resolve => {
        setClaimAllRewardsModalProps({
          isOpen: true,
          claimingType,
          onClose: () => {
            setClaimAllRewardsModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
            resolve();
          }
        });
      });
    },
    [setClaimAllRewardsModalProps]
  );

  const [bakcStakeModalProps, setBakcStakeModalProps] = useState(defaultBakcStakeModalProps);

  const stakeBakc = useCallback(
    (bakcTokenId: number, bakcSource: Maybe<WalletType>, apeCoinSource: WalletType) => {
      if (bakcStakeModalProps.isOpen) {
        throw new Error('There is a BAKC adding in progress');
      }
      return new Promise<void>((resolve, reject) => {
        setBakcStakeModalProps({
          apeCoinSource,
          bakcSource,
          isOpen: true,
          bakcTokenId,
          onClose: () => {
            setBakcStakeModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
          },
          onError: reject,
          onFinish: resolve
        });
      });
    },
    [setBakcStakeModalProps, bakcStakeModalProps]
  );

  const [stakeAutoCompoundApeProps, setStakeAutoCompoundApeProps] = useState(
    defaultStakeAutoCompoundApeModalProps
  );
  const stakeAutoCompoundApe = useCallback(
    ({
      amount,
      onSuccess,
      enableSupplyCApe,
      apeCoinSource
    }: {
      amount: string | null;
      onSuccess?: () => void;
      enableSupplyCApe: boolean;
      apeCoinSource: WalletType;
    }) => {
      if (stakeAutoCompoundApeProps.isOpen) {
        throw new Error('There is one auto compound Ape Staking in progress.');
      }
      return new Promise<void>(() => {
        setStakeAutoCompoundApeProps({
          isOpen: true,
          amount,
          enableSupplyCApe,
          onSuccess,
          apeCoinSource,
          onClose: () => {
            setStakeAutoCompoundApeProps(curr => ({
              ...curr,
              isOpen: false
            }));
          }
        });
      });
    },
    [stakeAutoCompoundApeProps.isOpen]
  );

  const [reStakeModalProps, setReStakeModalProps] =
    useState<ReStakeModalProps>(defaultReStakeModalProps);

  const setReStake = useCallback(() => {
    return new Promise<void>(resolve => {
      setReStakeModalProps({
        isOpen: true,
        onClose: () => {
          setReStakeModalProps(curr => ({
            ...curr,
            isOpen: false
          }));
          resolve();
        }
      });
    });
  }, []);

  const contextValue: ApeStakeManagerContextProps = useMemo(
    () => ({
      stakeApe,
      claimRewards,
      withdrawApe,
      transferNft,
      stakeBakc,
      claimAllRewards,
      stakeAutoCompoundApe,
      setAutoSellStrategy,
      setReStake
    }),
    [
      stakeApe,
      claimRewards,
      withdrawApe,
      transferNft,
      stakeBakc,
      claimAllRewards,
      stakeAutoCompoundApe,
      setAutoSellStrategy,
      setReStake
    ]
  );

  return (
    <ApeStakeManagerContext.Provider value={contextValue}>
      <ApeStakeModal {...manageStakeModalProps} />
      <ClaimRewardsModal {...claimRewardsModalProps} />
      <TransferModal {...transferModalProps} />
      <ClaimAllRewardsModal {...claimAllRewardsModalProps} />
      <WithdrawModal {...withdrawModalProps} />
      <BakcStakeModal {...bakcStakeModalProps} />
      <AutoCompoundApeModal {...stakeAutoCompoundApeProps} />
      <AutoSellModal {...autoSellModalProps} />
      <ReStakeModal {...reStakeModalProps} />
      {children}
    </ApeStakeManagerContext.Provider>
  );
});

export const useApeStakeManager = () => useContext(ApeStakeManagerContext);
