import { memo, useCallback, useState } from 'react';
import {
  Button,
  Card,
  Collapse,
  H3,
  H5,
  Inline,
  Skeleton,
  SmallText,
  Stack
} from '@parallel-mono/components';

import { useSupplyPositionsData } from '../hooks/useSupplyPositionsData';
import { CollectionPosition, TokenPosition } from '../types';
import { ExpandToggle } from '../../StyledComponents';
import { CloseLiquidationTooltip } from '../components';

import { ERC721Card } from './ERC721Card/ERC721Card';
import { ERC20Card } from './ERC20Card';

import { ValuePill } from '@/apps/paraspace/components';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { useBendDaoBalanceMap, useWalletBalanceMap } from '@/apps/paraspace/pages/Credit/hooks';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useAccountLiquidationStatus } from '@/apps/paraspace/pages/hooks/useAccountLiquidationStatus';
import { useAccountLiquidation } from '@/apps/paraspace/pages/hooks';
import { zero } from '@/apps/paraspace/consts/values';

type SupplyPositionsMobileProps = {
  nftPositions: CollectionPosition[];
  tokenPositions: TokenPosition[];
};

export const SupplyPositionsMobile = memo((props: SupplyPositionsMobileProps) => {
  const { nftPositions, tokenPositions } = props;
  const { inLiquidation } = useAccountLiquidationStatus();
  const { inAuction, disableCloseLiquidation, handleCloseLiquidation } = useAccountLiquidation();

  const {
    data,
    totalNetWorthPositionInUsd,
    totalSuppliedPositionsInUsd,
    modalHolders,
    handlers: {
      handleSupply,
      handleSupplyERC20FromAA,
      handleSupplyERC20FromEOA,
      handleSupplyFromOtherProtocols,
      handleWithdraw,
      handleToggleCollateral
    }
  } = useSupplyPositionsData({
    inLiquidation,
    nftPositions,
    tokenPositions
  });

  const [isOpen, setIsOpen] = useState(true);

  const walletBalanceMap = useWalletBalanceMap();
  const benDaoBalanceMap = useBendDaoBalanceMap();

  const toggleExpandable = useCallback(() => {
    setIsOpen(v => !v);
  }, [setIsOpen]);

  const handleToggleERC721Collateral = useCallback(
    ({ symbol, tokenId }: { symbol: string; tokenId: number }) => {
      handleToggleCollateral({ symbol, id: tokenId, isNft: true });
    },
    [handleToggleCollateral]
  );

  const handleToggleERC20Collateral = useCallback(
    ({ symbol }: { symbol: string }) => {
      handleToggleCollateral({ symbol, isNft: false });
    },
    [handleToggleCollateral]
  );

  return (
    <Card border>
      <Stack>
        <Stack gap="1rem">
          <Inline justifyContent="space-between">
            <H3>My Supply Positions</H3>
            <ExpandToggle onClick={toggleExpandable} open={isOpen} />
          </Inline>
          <ValuePill gap="0.5rem" width="fit-content">
            <SmallText skin="secondary">Net Worth</SmallText>
            {totalNetWorthPositionInUsd.gt(zero) ? (
              <H5>{formatToCurrency(totalNetWorthPositionInUsd)}</H5>
            ) : (
              <Skeleton.Button height="1rem" width="2rem" />
            )}
          </ValuePill>
          <ValuePill gap="0.5rem" width="fit-content">
            <SmallText skin="secondary">Supplied</SmallText>
            {totalSuppliedPositionsInUsd.gt(zero) ? (
              <H5>{formatToCurrency(totalSuppliedPositionsInUsd)}</H5>
            ) : (
              <Skeleton.Button height="1rem" width="2rem" />
            )}
          </ValuePill>
          {inAuction && (
            <CloseLiquidationTooltip disabled={!disableCloseLiquidation}>
              <Button onClick={handleCloseLiquidation} disabled={disableCloseLiquidation}>
                Close Liquidation
              </Button>
            </CloseLiquidationTooltip>
          )}
        </Stack>
        <Collapse open={isOpen}>
          <Stack gap="1rem">
            {data.map((row, index) => {
              if (row.type === 'ERC721') {
                return (
                  <ERC721Card
                    key={row.symbol + index}
                    data={row}
                    onSupply={handleSupply}
                    onSupplyFromOtherProtocols={handleSupplyFromOtherProtocols}
                    onWithdraw={handleWithdraw}
                    inLiquidation={inLiquidation}
                    onToggleCollateral={handleToggleERC721Collateral}
                    walletBalanceMap={{
                      AA: walletBalanceMap.AA?.[row.symbol as ERC721Symbol]?.length ?? 0,
                      EOA: walletBalanceMap.EOA?.[row.symbol as ERC721Symbol]?.length ?? 0
                    }}
                    bendDaoBalance={benDaoBalanceMap[row.symbol as ERC721Symbol] ?? 0}
                  />
                );
              }
              return (
                <ERC20Card
                  key={row.symbol + index}
                  data={row}
                  inLiquidation={inLiquidation}
                  onSupplyERC20FromAA={handleSupplyERC20FromAA}
                  onSupplyERC20FromEOA={handleSupplyERC20FromEOA}
                  onWithdraw={handleWithdraw}
                  onToggleCollateral={handleToggleERC20Collateral}
                />
              );
            })}
          </Stack>
        </Collapse>
      </Stack>
      {modalHolders}
    </Card>
  );
});
