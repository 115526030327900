import BigNumber from 'bignumber.js';
import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';

import { RemoveLiquidityModal, RemoveLiquidityModalProps } from './RemoveLiquidityModal';

import { useParallelToast } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import usePool from '@/apps/paraspace/pages/hooks/usePool';

type RemoveLiquidityContextValue = {
  removeLiquidity: (symbol: string, id: number) => Promise<void>;
};

const RemoveLiquidityContext = createContext<RemoveLiquidityContextValue>({
  removeLiquidity: async () => {
    throw new Error('RemoveLiquidityProvider not found');
  }
});

type RemoveLiquidityProviderProps = {
  children: ReactNode;
};

type PromiseController = {
  resolve: (value: void) => void;
  reject: () => void;
};

export type FormData = {
  liquidityDecrease: string;
  amount0Min: BigNumber;
  amount1Min: BigNumber;
};

const defaultModalProps: RemoveLiquidityModalProps = {
  isOpen: false,
  symbol: null,
  id: null
};

export const RemoveLiquidityProvider = memo(({ children }: RemoveLiquidityProviderProps) => {
  const [modalProps, setModalProps] = useState<RemoveLiquidityModalProps>(defaultModalProps);

  const promiseControllerRef = useRef<PromiseController | null>(null);

  const { removeUniswapV3Liquidity } = usePool();

  const closeModal = useCallback(() => {
    setModalProps(curr => ({
      ...curr,
      isOpen: false
    }));
  }, [setModalProps]);

  const handleClose = useCallback(() => {
    closeModal();
    promiseControllerRef.current?.reject();
    promiseControllerRef.current = null;
  }, [closeModal]);

  const removeLiquidity = useCallback(
    (symbol: string, id: number) => {
      if (modalProps.isOpen) {
        throw new Error('there is a Liquidity Removal in progress');
      }
      return new Promise<void>((resolve, reject) => {
        promiseControllerRef.current = {
          resolve,
          reject
        };
        setModalProps({
          onClose: handleClose,
          isOpen: true,
          symbol,
          id
        });
      });
    },
    [modalProps, handleClose]
  );

  const contextValue: RemoveLiquidityContextValue = useMemo(
    () => ({
      removeLiquidity
    }),
    [removeLiquidity]
  );

  const parallelToast = useParallelToast();

  const handleRemoveLiquidity = useCallback(
    async ({ liquidityDecrease, amount0Min, amount1Min }: FormData) => {
      const transactionPromise = removeUniswapV3Liquidity(
        modalProps.id!.toString(),
        liquidityDecrease,
        amount0Min,
        amount1Min
      )
        .then(tx => {
          closeModal();
          return tx?.wait();
        })
        .catch(e => {
          throw getUserFriendlyError(e);
        });

      await parallelToast.promise(transactionPromise);
      promiseControllerRef.current?.resolve();
      promiseControllerRef.current = null;
    },
    [removeUniswapV3Liquidity, modalProps.id, parallelToast, closeModal]
  );

  return (
    <RemoveLiquidityContext.Provider value={contextValue}>
      <RemoveLiquidityModal {...modalProps} onRemoveLiquidity={handleRemoveLiquidity} />
      {children}
    </RemoveLiquidityContext.Provider>
  );
});

export const useRemoveLiquidity = () => useContext(RemoveLiquidityContext).removeLiquidity;
