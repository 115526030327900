import { Button, H5, Input, Modal, ModalProps, Stack } from '@parallel-mono/components';
import { utils } from 'ethers';
import { ChangeEvent, FC, memo, useCallback, useMemo, useState } from 'react';

export const AddressSelectModal: FC<
  Omit<ModalProps, 'children'> & { onAddressSave: (address: string) => void }
> = memo(({ onAddressSave, onClose, ...others }) => {
  const [address, setAddress] = useState<string>();

  const errorMessage = useMemo(() => {
    if (address && !utils.isAddress(address)) {
      return 'Please enter a valid address';
    }
    return null;
  }, [address]);
  const handleSave = useCallback(() => {
    if (address && !errorMessage) {
      onAddressSave(address);
      onClose?.();
    }
  }, [address, errorMessage, onAddressSave, onClose]);

  const handleAddressChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  }, []);

  return (
    <Modal onClose={onClose} {...others}>
      <Stack gap="1.5rem">
        <Stack gap="0.5rem">
          <H5>To address</H5>
          <Stack gap="0.25rem">
            <Input value={address} onChange={handleAddressChange} />
          </Stack>
        </Stack>
        <Button
          size="large"
          block
          disabled={Boolean(errorMessage) || !address}
          onClick={handleSave}
        >
          {errorMessage || 'Save'}
        </Button>
      </Stack>
    </Modal>
  );
});
