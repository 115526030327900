import { memo } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { Image } from '@parallel-mono/components';

const StyledPoolIconWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 100px;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
`;

const StyledPoolIconBg = styled(Image)`
  width: 70%;
  height: 70%;
  opacity: 0;
`;

export const RotateIcon = memo(({ iconName, bg }: { iconName: string; bg: string }) => {
  const props = useSpring({
    from: { opacity: 0, width: '70%', height: '70%', transform: 'rotate(360deg)' },
    to: async next => {
      await next({
        to: { opacity: 1, width: '100%', height: '100%' },
        delay: 1800
      });
      await next({
        from: { transform: 'rotate(360deg)' },
        to: [
          { transform: 'rotate(270deg)', delay: 1000 },
          { transform: 'rotate(180deg)', delay: 1000 },
          { transform: 'rotate(360deg)', delay: 1000 }
        ],
        config: { duration: 2000 },
        loop: true
      });
    }
  });
  return (
    <StyledPoolIconWrapper>
      <StyledPoolIconBg as={animated.img} src={bg} style={props} />
      <CryptoIcon symbol={iconName} size="70%" />
    </StyledPoolIconWrapper>
  );
});
