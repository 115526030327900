import { useCallback, useMemo } from 'react';
import { ReservesDataHumanized, UiPoolDataProvider } from 'paraspace-utilities-contract-helpers';
import { Environment } from 'parax-sdk';

import { ContractMap } from '@/apps/paraspace/config';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { convertToChecksumAddress } from '@/apps/paraspace/utils/convertToChecksumAddress';
import { useContractsMap } from '@/apps/paraspace/hooks';

const formatReservedData = (
  reservedData: ReservesDataHumanized,
  contracts: ContractMap,
  env: Environment
): ReservesDataHumanized => {
  return {
    // TODO update
    baseCurrencyData: {
      ...reservedData.baseCurrencyData,
      marketReferenceCurrencyPriceInUsd: reservedData.baseCurrencyData.networkBaseTokenPriceInUsd
    },
    // for the aWETH token, the symbol are different between mainnet(aWETH) and goerli(aWEthWETH),
    // So we need to format the NON_PRODUCTION into the same symbol: aWETH
    reservesData: reservedData.reservesData.map(reservesDataItem => {
      const isProduction = env === Environment.PRODUCTION;
      if (
        !isProduction &&
        convertToChecksumAddress(reservesDataItem.underlyingAsset) ===
          convertToChecksumAddress(contracts.aWETH)
      ) {
        return { ...reservesDataItem, symbol: 'aWETH' };
      }
      return { ...reservesDataItem };
    })
  };
};

export const useReservesAndIncentives = () => {
  const { provider, chainId, env } = useWeb3Context();
  const contracts = useContractsMap();

  const uiPoolData = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new UiPoolDataProvider({
      uiPoolDataProviderAddress: contracts.UiPoolDataProvider,
      provider,
      chainId
    });
  }, [chainId, contracts.UiPoolDataProvider, provider]);

  const getReserveData = useCallback(async () => {
    if (!uiPoolData) {
      return null;
    }
    const reserveDataRes = await uiPoolData.getReservesHumanized({
      lendingPoolAddressProvider: contracts.PoolAddressesProvider
    });

    const formattedReservedData = formatReservedData(reserveDataRes, contracts, env);
    return formattedReservedData;
  }, [contracts, uiPoolData, env]);

  const batchGetUniswapV3LpTokenData = useCallback(
    async (tokenIds: number[]) => {
      if (!uiPoolData) return null;
      const payload = await uiPoolData.batchGetUniswapV3LpTokenData({
        oracleAddress: contracts.ParaSpaceOracle,
        poolAddressProvider: contracts.PoolAddressesProvider,
        tokenAddress: contracts['UNI-V3-POS'],
        tokenIds
      });
      return payload;
    },
    [contracts, uiPoolData]
  );

  return { getReserveData, batchGetUniswapV3LpTokenData };
};
