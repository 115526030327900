import { memo, useCallback, useMemo } from 'react';
import { Inline, SmallText, Stack, Text } from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import { ApeCoinSourceToJoinApeListing } from '../types';

import { Select, SelectBaseOption, SelectProps } from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol, WalletType } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { tryRoundUpCapeBalance } from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/utils';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';

type ApeCoinSourceSelectProps = Omit<SelectProps, 'options' | 'onChange'> & {
  walletType: WalletType;
  onChange?: (v: ApeCoinSourceToJoinApeListing) => void;
};

type Option = SelectBaseOption & { symbol: ERC20Symbol };

const renderDisplay = (option: Maybe<Option>) => {
  if (!option) {
    return null;
  }
  return (
    <Inline gap="0.675rem" alignItems="center">
      {option.icon}
      <Text fontWeight="medium">{option.symbol}</Text>
    </Inline>
  );
};

export const ApeCoinSourceSelect = memo(
  ({ onChange, walletType, ...others }: ApeCoinSourceSelectProps) => {
    const { erc20InfoMap } = useMMProvider();
    const { erc20BalanceMap } = useEOABalances();
    const apeBalance =
      walletType === 'AA'
        ? erc20InfoMap[ERC20Symbol.APE]?.balance
        : erc20BalanceMap?.[ERC20Symbol.APE];
    const rawCApeBalance =
      walletType === 'AA'
        ? erc20InfoMap[ERC20Symbol.CAPE]?.balance
        : erc20BalanceMap?.[ERC20Symbol.CAPE];
    const cApeBalance = rawCApeBalance ? tryRoundUpCapeBalance(rawCApeBalance) : undefined;

    const options = useMemo(() => {
      return [
        {
          symbol: ERC20Symbol.APE,
          icon: <CryptoIcon size="small" symbol={ERC20Symbol.APE} />,
          value: ApeCoinSourceToJoinApeListing.APE_BALANCE,
          label: (
            <Inline width="100%" alignItems="center" justifyContent="space-between">
              <Text>{ERC20Symbol.APE}</Text>
              <SmallText>{apeBalance ? formatNumber(apeBalance) : '-'}</SmallText>
            </Inline>
          )
        },
        {
          symbol: ERC20Symbol.CAPE,
          icon: <CryptoIcon size="small" symbol={ERC20Symbol.CAPE} />,
          value: ApeCoinSourceToJoinApeListing.CAPE_BALANCE,
          label: (
            <Inline width="100%" alignItems="center" justifyContent="space-between">
              <Stack gap="0">
                <Text fontWeight="medium">{ERC20Symbol.CAPE}</Text>
                <SmallText>Lower gas</SmallText>
              </Stack>
              <SmallText>{cApeBalance ? formatNumber(cApeBalance) : '-'}</SmallText>
            </Inline>
          )
        }
      ];
    }, [apeBalance, cApeBalance]);

    const wrappedOnChange = useCallback(
      (p: Maybe<Option>) => {
        onChange?.(p?.value ?? null);
      },
      [onChange]
    );

    return (
      <Select
        options={options}
        onChange={wrappedOnChange}
        renderDisplay={renderDisplay}
        {...others}
      />
    );
  }
);
