import { development, production, staging } from 'paraspace-configs-v2';
import { Environment } from 'parax-sdk';

import { env } from '@/env';

const configsMap = {
  [Environment.DEVELOPMENT]: development,
  [Environment.STAGING]: staging,
  [Environment.PRODUCTION]: production
};

export const contractsConfig = configsMap[env];
