import BigNumber from 'bignumber.js';
import { findKey, groupBy, keys, range, sortBy, toNumber, zipObject } from 'lodash';

import {
  InLiquidationRow,
  NearLiquidationAssetType,
  NearLiquidationERC20Asset,
  NearLiquidationERC721Asset
} from './types';

import {
  AccountInLiquidationInfo,
  AccountNearLiquidationInfo,
  Erc20AssetNearLiquidation
} from '@/apps/paraspace/generated/graphql';
import { emptyArray } from '@/apps/paraspace/consts/values';
import { ERC20Config } from '@/apps/paraspace/config';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { shiftedLeftBy } from '@/apps/paraspace/utils/calculations';
import { MarketCurrencyTokenInfo } from '@/apps/paraspace/config/AppConfig/marketCurrcyTokenInfoMap';

export const calculatePriceInNativeToken = ({
  priceInMarketCurrencyWithDecimal,
  marketCurrencyTokenInfo,
  nativeTokenPriceInMarketCurrency
}: {
  priceInMarketCurrencyWithDecimal: string;
  marketCurrencyTokenInfo: MarketCurrencyTokenInfo;
  nativeTokenPriceInMarketCurrency?: BigNumber;
}) => {
  const { decimal, isNativeTokenForNetWork } = marketCurrencyTokenInfo;
  const priceInMarketCurrency = shiftedLeftBy(priceInMarketCurrencyWithDecimal, decimal);
  return nativeTokenPriceInMarketCurrency && !isNativeTokenForNetWork
    ? priceInMarketCurrency.div(nativeTokenPriceInMarketCurrency)
    : priceInMarketCurrency;
};

const convertWNativeTokenToNativeTokenByNetwork = (
  symbol: ERC20Symbol,
  erc20Config: ERC20Config
) => {
  const isWNativeToken = erc20Config[symbol]?.isWrappedNativeToken;
  if (isWNativeToken) {
    const nativeToken = findKey(erc20Config, item => item.isNativeToken) as ERC20Symbol;
    return nativeToken;
  }
  return symbol;
};

export const formatLiquidation = (
  liquidation: AccountInLiquidationInfo & { isPaused: boolean },
  contractAddressToNTokenAddressMap: Record<string, string>,
  getSymbolByContractAddress: (addr: string) => Maybe<ERC20Symbol | ERC721Symbol>,
  marketCurrencyTokenInfo: MarketCurrencyTokenInfo,
  nativeTokenPriceInMarketCurrency?: BigNumber
): InLiquidationRow => {
  return {
    ...liquidation.accountInfo,
    isPaused: liquidation.isPaused,
    assets: liquidation!.assets
      .map(asset => {
        if (!asset) return null;
        const floorPriceInNativeToken = calculatePriceInNativeToken({
          priceInMarketCurrencyWithDecimal: asset.floorPrice!,
          marketCurrencyTokenInfo,
          nativeTokenPriceInMarketCurrency
        });
        const buyNowPriceInNativeToken = calculatePriceInNativeToken({
          priceInMarketCurrencyWithDecimal: asset.currentPrice!,
          marketCurrencyTokenInfo,
          nativeTokenPriceInMarketCurrency
        });

        return {
          collectionName: asset.collectionName,
          symbol: getSymbolByContractAddress(asset.contractAddress) as ERC721Symbol,
          contractAddress: asset.contractAddress,
          nTokenAddress: contractAddressToNTokenAddressMap[asset.contractAddress],
          identifierOrCriteria: toNumber(asset.identifierOrCriteria),
          floorPrice: floorPriceInNativeToken,
          buyNowPrice: buyNowPriceInNativeToken,
          floorPriceInUsd: new BigNumber(asset.floorPriceInUSD!),
          buyNowPriceInUsd: new BigNumber(asset.currentPriceInUSD!),
          status: asset.auctionStatus,
          stepLinear: shiftedLeftBy(asset.stepLinear, 18).toNumber(),
          stepExp: shiftedLeftBy(asset.stepExp, 18).toNumber(),
          currentPriceMultiplier: shiftedLeftBy(asset.currentPriceMultiplier, 18).toNumber(),
          maxPriceMultiplier: shiftedLeftBy(asset.maxPriceMultiplier, 18).toNumber(),
          minPriceMultiplier: shiftedLeftBy(asset.minPriceMultiplier, 18).toNumber(),
          minExpPriceMultiplier: shiftedLeftBy(asset.minExpPriceMultiplier, 18).toNumber(),
          startTime: asset.startTime * 1000,
          traitMultiplier: shiftedLeftBy(asset.traitMultiplier, 18)
        };
      })
      .filter(notEmpty)
  };
};

export const formatNearLiquidationInfo = ({
  nearLiquidationInfo,
  erc20Configs,
  getSymbolByContractAddress,
  marketCurrencyTokenInfo,
  nativeTokenPriceInMarketCurrency
}: {
  nearLiquidationInfo: AccountNearLiquidationInfo;
  erc20Configs: ERC20Config;
  getSymbolByContractAddress: (addr: string) => Maybe<ERC20Symbol | ERC721Symbol>;
  marketCurrencyTokenInfo: MarketCurrencyTokenInfo;
  nativeTokenPriceInMarketCurrency?: BigNumber;
}) => {
  const { assets, accountInfo } = nearLiquidationInfo;
  const formattedAssets = assets
    ? nearLiquidationInfo.assets.map(asset => {
        // eslint-disable-next-line no-underscore-dangle
        if (asset.__typename === 'ERC721AssetNearLiquidation') {
          const floorPriceInNativeToken = calculatePriceInNativeToken({
            priceInMarketCurrencyWithDecimal: asset.floorPrice!,
            marketCurrencyTokenInfo,
            nativeTokenPriceInMarketCurrency
          });
          return {
            type: NearLiquidationAssetType.ERC721,
            amount: 1,
            collectionName: asset.collectionName,
            symbol: getSymbolByContractAddress(asset.contractAddress),
            contractAddress: asset.contractAddress,
            identifierOrCriteria: toNumber(asset.identifierOrCriteria),
            floorPrice: floorPriceInNativeToken,
            floorPriceInUsd: new BigNumber(asset.floorPriceInUSD!),
            traitMultiplier: shiftedLeftBy(asset.traitMultiplier, 18)
          } as NearLiquidationERC721Asset;
        }
        const erc20Asset = asset as Erc20AssetNearLiquidation;
        return {
          type: NearLiquidationAssetType.ERC20,
          amount: shiftedLeftBy(erc20Asset.amount, erc20Asset.decimal).toNumber(),
          symbol: convertWNativeTokenToNativeTokenByNetwork(
            erc20Asset.symbol as ERC20Symbol,
            erc20Configs
          ),
          value: erc20Asset.valueInUSD
        } as NearLiquidationERC20Asset;
      })
    : [];

  const { erc20: erc20Assets = emptyArray, erc721: erc721Assets = emptyArray } = groupBy(
    formattedAssets,
    it => (it.type === NearLiquidationAssetType.ERC20 ? 'erc20' : 'erc721')
  );

  const erc20OrderMap = zipObject(keys(erc20Configs), range(keys(erc20Configs).length));

  const sortedERC20Assets = sortBy(erc20Assets, it => erc20OrderMap[it.symbol]);
  return {
    accountAddress: accountInfo.address,
    collateral: accountInfo.collateral,
    debt: accountInfo.debt,
    healthFactor: accountInfo.healthFactor,
    nftHealthFactor: accountInfo.nftHealthFactor,
    assets: erc721Assets.concat(sortedERC20Assets)
  };
};
