import {
  CardProps,
  Inline,
  Stack,
  Card,
  H6,
  H5,
  Button,
  Text,
  H3,
  useBreakpoints,
  Responsive
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { FC, memo, useCallback, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';
import { isNil } from 'lodash';

import { ApeCoinListing, ApeListing, ListingType } from '../../../types';

import { SelectedListingPill } from './ListingPill';
import { JoinP2PListingDropdownMenu } from './JoinP2PListingDropdownMenu';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { ERC20Symbol, WalletType } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { one } from '@/apps/paraspace/consts/values';
import { ValuePill } from '@/apps/paraspace/components';

type ListingSelectProps = {
  compoundApy: BigNumber;
  onJoinListing: (listing: ApeListing | ApeCoinListing, walletType: WalletType) => Promise<void>;
  selectedListing: Maybe<ApeListing | ApeCoinListing>;
} & Omit<CardProps, 'children'>;

const StyledCard = styled(Card)`
  padding: 0.75rem 1rem;
`;

const StyledListingField = styled(Stack)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    > * + * {
      margin-top: 0rem;
    }
  `};
`;
const EmptySelectPillText = styled(Text)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    font-size: 0.75rem;
  `};
`;

export const ApeListingSelector: FC<ListingSelectProps> = memo(
  ({ selectedListing, onJoinListing, compoundApy, ...props }) => {
    const { account } = useWeb3Context();
    const { mobile } = useBreakpoints();

    const handleJoinStaking = useCallback(
      (source: WalletType) => {
        if (selectedListing) {
          onJoinListing(selectedListing, source);
        }
      },
      [onJoinListing, selectedListing]
    );

    const isOwnedListing = useMemo(
      () => selectedListing?.offerer === account,
      [account, selectedListing?.offerer]
    );

    const yourShare = useMemo(() => {
      if (selectedListing) {
        return isOwnedListing ? one : one.minus(selectedListing.share);
      }
      return null;
    }, [isOwnedListing, selectedListing]);

    const yourApy = useMemo(
      () => yourShare && compoundApy.times(yourShare),
      [compoundApy, yourShare]
    );

    const provideListingSymbol = useMemo(() => {
      if (selectedListing) {
        return selectedListing.listingType === ListingType.ApeListing
          ? ERC20Symbol.APE
          : selectedListing.stakingPool;
      }
      return null;
    }, [selectedListing]);

    const theme = useTheme();

    return (
      <Stack gap="1.5rem">
        <StyledCard border {...props}>
          <Responsive justifyContent="space-between">
            <StyledListingField gap="0.5rem">
              <H6 fontWeight="medium" skin="secondary">
                Join
              </H6>
              <Inline gap=".5rem">
                {isNil(selectedListing) ? (
                  <ValuePill>
                    <EmptySelectPillText skin="secondary">Select an offer</EmptySelectPillText>
                  </ValuePill>
                ) : (
                  <ValuePill borderColor={theme.skin.text.main}>
                    <SelectedListingPill
                      tokenId={
                        selectedListing.listingType === ListingType.ApeListing
                          ? selectedListing.tokenId
                          : null
                      }
                      symbol={selectedListing.symbol}
                    />
                  </ValuePill>
                )}
              </Inline>
            </StyledListingField>
            <StyledListingField gap={provideListingSymbol ? '0.5rem' : '1rem'}>
              <H6 fontWeight="medium" skin="secondary">
                You Provide
              </H6>
              {!isNil(selectedListing) && provideListingSymbol ? (
                <ValuePill gap="0.5rem" alignItems="center">
                  <CryptoIcon
                    symbol={provideListingSymbol}
                    size="1.5rem"
                    key={provideListingSymbol}
                  />
                  <H5 fontWeight="medium">{provideListingSymbol}</H5>
                </ValuePill>
              ) : (
                <H3>-</H3>
              )}
            </StyledListingField>
            <StyledListingField gap="1rem">
              <H6 fontWeight="medium" skin="secondary">
                Your % Share
              </H6>
              <H3>{yourShare ? formatNumber(yourShare, { output: 'percent' }) : '-%'}</H3>
            </StyledListingField>
            <StyledListingField gap="1rem">
              <H6 fontWeight="medium" skin="secondary">
                Your APY
              </H6>
              <H3>
                {yourApy
                  ? formatNumber(yourApy, {
                      output: 'percent'
                    })
                  : '-%'}
              </H3>
            </StyledListingField>
            <StyledListingField justifyContent="center">
              <JoinP2PListingDropdownMenu
                menuTrigger={
                  <Button block={mobile} disabled={isNil(selectedListing)}>
                    Next
                  </Button>
                }
                clickHandler={source => {
                  handleJoinStaking(source);
                }}
              />
            </StyledListingField>
          </Responsive>
        </StyledCard>
      </Stack>
    );
  }
);
