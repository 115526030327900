import { Link } from '../Link';

import { timelockDocLink } from './consts';

export const TimelockResultDesc = () => {
  return (
    <>
      Due to pool limit, this transaction is added to your Timelock Queue as a protocol security
      measure.{' '}
      <Link href={timelockDocLink} target="_blank">
        Learn More
      </Link>
    </>
  );
};
