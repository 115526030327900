import { Button, H1, H5, Inline, Stack } from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';
import { memo, useState } from 'react';
import styled from 'styled-components';

import { CollapseWithShadow, StackWithBorder } from './OperationCard';

import { formatBalance } from '@/apps/paraspace/utils/format';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { ERC20Symbol } from '@/apps/paraspace/typings';

const StackWithMargin = styled(Stack)`
  margin-top: 1.5rem;
`;

interface IProps {
  tokenId: string;
  token0Symbol: ERC20Symbol;
  token1Symbol: ERC20Symbol;
  token0Fee: BigNumber | undefined;
  token1Fee: BigNumber | undefined;
}

const UnclaimedFees = ({ tokenId, token0Symbol, token1Symbol, token0Fee, token1Fee }: IProps) => {
  const { erc20InfoMap, load } = useMMProvider();
  const { removeUniswapV3Liquidity } = usePool();
  const transationToast = useParallelToast();

  const [collecting, setCollecting] = useState(false);

  const handleCollectFee = () => {
    setCollecting(true);
    transationToast.promise(
      removeUniswapV3Liquidity(tokenId, '0', token0Fee!, token1Fee!)
        .then(async transaction => {
          await transaction?.wait();
          await load();
        })
        .catch((e: Error) => {
          throw getUserFriendlyError(e);
        })
        .finally(() => {
          setCollecting(false);
        })
    );
  };

  const liquidity =
    token0Fee && token1Fee && token0Symbol && token1Symbol
      ? token0Fee.times(erc20InfoMap[token0Symbol]?.priceInUsd).toNumber() +
        token1Fee.times(erc20InfoMap[token1Symbol]?.priceInUsd).toNumber()
      : 0;

  return (
    <CollapseWithShadow header="Unclaimed Fees" defaultOpen>
      <StackWithMargin>
        <Inline justifyContent="space-between" alignItems="center">
          <H1>${formatBalance(liquidity)}</H1>
          <Button
            skin="secondary"
            onClick={handleCollectFee}
            disabled={collecting || liquidity === 0}
          >
            Collect Fees
          </Button>
        </Inline>
        <StackWithBorder gap="1rem">
          {[
            { token: token0Symbol, fee: token0Fee },
            { token: token1Symbol, fee: token1Fee }
          ].map(({ token, fee }) => (
            <Inline justifyContent="space-between" key={token}>
              <Inline gap=".75rem" alignItems="center" width="6rem">
                <CryptoIcon symbol={token} size="small" />
                <H5>{token}</H5>
              </Inline>
              <H5>
                {formatBalance(fee ?? 0)} {token}
              </H5>
            </Inline>
          ))}
        </StackWithBorder>
      </StackWithMargin>
    </CollapseWithShadow>
  );
};

export const UnclaimedFeesSection = memo(UnclaimedFees);
