import { memo } from 'react';
import { Icon, Stack, StackProps, Text } from '@parallel-mono/components';
import styled from 'styled-components';

import { Link } from '../Link';

import { timelockDocLink } from './consts';

import { NETWORK_NAME_MAP } from '@/apps/paraspace/config';
import { useTimelockClaimable } from '@/apps/paraspace/pages/hooks/Timelock/useTimelockClaimable';
import { useWeb3Context } from '@/apps/paraspace/contexts';

const IntroContainer = styled(Stack).attrs({
  alignItems: 'center',
  gap: '0.5rem'
})`
  padding: 1rem 1.5rem;
  background: ${({ theme }) => theme.skin.primary.contrastBackground};
  border-radius: ${({ theme }) => theme.border.radius.medium};
`;

type TimelockStepIntroProps = Omit<StackProps, 'children' | 'gap'>;

export const TimelockStepIntro = memo((props: TimelockStepIntroProps) => {
  const { autoClaimEnabled } = useTimelockClaimable();
  const { chainId } = useWeb3Context();

  return (
    <IntroContainer {...props}>
      <Icon name="shieldCheck" />
      <Stack gap="0.5rem">
        <Text>
          Timelock provides enhanced security to protect funds from flash loan attacks and other
          malicious activities.{' '}
          <Link href={timelockDocLink} target="_blank">
            Learn More
          </Link>
        </Text>
        {!autoClaimEnabled && (
          <Text>
            Due to a {NETWORK_NAME_MAP[chainId]} timestamp update issue, there will be a 1～2
            minutes time delay for claiming from Timelock.
          </Text>
        )}
      </Stack>
    </IntroContainer>
  );
});
