import {
  Button,
  Card,
  H5,
  Icon,
  Inline,
  Spinner,
  Stack,
  Tabs,
  Text
} from '@parallel-mono/components';
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Tab } from '@parallel-mono/components/components/Tabs/TabsBase';

import { useScaffoldContainerRef } from '../../App/Scaffold';
import { sleep } from '../../utils';
import { Tooltip } from '../../components';
import { useToggle } from '../../contexts/TogglesContext';

import {
  Activity,
  BalanceUpdaterImperative,
  Bridge,
  Header,
  MintBanner,
  Withdraw
} from './components';
import { BridgeTokenProvider } from './contexts';
import { usePendingActivities } from './hooks/useActivity';

const StyledCard = styled(Card)`
  width: 30rem;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
  `}
`;
const StyledTabs = styled(Tabs)`
  .slot {
    width: 100%;
    height: 2.5rem;
  }
  .tab {
    height: 32px;
  }
`;

const StyledActivityButton = styled(Button)`
  background: linear-gradient(90deg, #827bef 0%, #cd75b2 100%);
  color: #ffffff;
  padding: 0.5rem 1rem;
  .chevronDown {
    transition: transform 0.2s ease-in-out;
  }
  &:hover {
    color: #ffffff;
    background: linear-gradient(90deg, #4a43bd 0%, #9b2b79 100%);
    .chevronDown {
      transform: rotate(-180deg);
    }
  }
`;
const StyledSpinner = styled(Spinner)`
  width: 18px;
  height: 18px;
  svg {
    color: #fff;
  }
`;
const ActivityTag = styled(Inline)`
  padding: 0px 0.5rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 100px;
`;

const ContentWrapper = styled.div<{ isActivity: boolean }>`
  position: relative;
  overflow: hidden;
  margin-top: 0;
  height: calc(100vh - 116px);
  min-height: 800px;
  ${({ theme, isActivity }) => theme.breakpoints.only('mobile')`
    min-height: ${isActivity ? 'calc(100vh - 60px)' : '750px'};
  `}
`;

const BounceWrapper = styled(Inline)<{ visible: boolean }>`
  ${({ visible }) =>
    visible
      ? 'animation: bounceInUp ease-out 1.2s forwards'
      : 'animation: fadeOut ease-out 0.8s forwards'};

  @keyframes bounceInUp {
    0% {
      transform: translateY(200vh);
    }
    40% {
      transform: translateY(-120px);
    }
    60% {
      transform: translateY(60px);
    }
    80% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    20% {
      opacity: 0.6;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      display: none;
      transform: translateY(100vh);
    }
  }
`;

const PositionWrapper = styled.div<{ active: boolean }>`
  z-index: ${({ active }) => (active ? 99 : 9)};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
    padding: 0 1rem;
  `}
`;

const BridgePage = memo(
  // eslint-disable-next-line react/no-unused-prop-types
  forwardRef<BalanceUpdaterImperative, { onBridgeSuccess: (isNativeToken: boolean) => void }>(
    ({ onBridgeSuccess }, ref) => {
      const [selectedIndex, setSelectedIndex] = useState(0);

      const depositEnabled = useToggle('BRIDGE_DEPOSIT');

      const handleActiveTabChange = useCallback(
        (_: Tab, index: number) => {
          if (!depositEnabled && index === 1) {
            return;
          }
          setSelectedIndex(index);
        },
        [depositEnabled]
      );

      const tabs = useMemo(
        () => [
          {
            title: <H5>Withdraw</H5>,
            content: <Withdraw />
          },
          {
            title: (
              <Tooltip disabled={depositEnabled} placement="bottom" content="Bridge disabled">
                <H5>Bridge</H5>
              </Tooltip>
            ),
            content: <Bridge ref={ref} onBridgeSuccess={onBridgeSuccess} />
          }
        ],
        [onBridgeSuccess, ref, depositEnabled]
      );
      return (
        <StyledCard>
          <Stack>
            <StyledTabs
              classNames={{ tab: 'tab', slot: 'slot' }}
              tabs={tabs}
              activeTab={tabs[selectedIndex]}
              onActiveTabChange={handleActiveTabChange}
            />
            <Stack>{tabs[selectedIndex].content}</Stack>
          </Stack>
        </StyledCard>
      );
    }
  )
);

export const Home = () => {
  const [pageToggle, setPageToggle] = useState<'bridge' | 'activity'>('bridge');
  const [shouldRenderActivity, setShouldRenderActivity] = useState(false);
  const { count, refetch: refreshActivityCount } = usePendingActivities();

  const scaffoldContainerRef = useScaffoldContainerRef();

  const handleToggleActivity = useCallback(() => {
    setShouldRenderActivity(true);
    setPageToggle('activity');
  }, []);

  const shouldShowBridge = useMemo(() => pageToggle === 'bridge', [pageToggle]);
  const shouldShowActivity = useMemo(() => pageToggle === 'activity', [pageToggle]);

  const activityPanelRef = useRef<{
    refreshERC20Activities?: () => void;
    refreshNativeCurrencyActivities?: () => void;
  }>(null);

  const handleBridgeSuccess = useCallback(
    async (isNativeToken: boolean) => {
      await sleep(1000);
      if (isNativeToken) {
        activityPanelRef.current?.refreshNativeCurrencyActivities?.();
      }

      activityPanelRef.current?.refreshERC20Activities?.();
      refreshActivityCount();
    },
    [refreshActivityCount]
  );

  useEffect(() => {
    const timer = setInterval(() => {
      refreshActivityCount();
    }, 1000 * 30);

    return clearInterval(timer);
  }, [refreshActivityCount]);

  const balanceUpdaterRef = useRef<{ refetchBalance: () => void }>(null);

  const handleUpdateBalance = useCallback(() => {
    balanceUpdaterRef?.current?.refetchBalance();
  }, []);

  return (
    <Stack style={{ overflow: 'auto', height: '100vh' }} width="100%">
      <Header bodyContainer={scaffoldContainerRef} />
      <MintBanner mintCallBack={handleUpdateBalance} />
      <BridgeTokenProvider>
        <ContentWrapper isActivity={shouldShowActivity}>
          <PositionWrapper active={shouldShowBridge}>
            <BounceWrapper justifyContent="center" visible={shouldShowBridge}>
              <Stack width="100%" gap="2rem" alignItems="center" justifyContent="center">
                <BridgePage ref={balanceUpdaterRef} onBridgeSuccess={handleBridgeSuccess} />
                <StyledActivityButton skin="secondary" onClick={handleToggleActivity}>
                  <Inline gap="0.25rem" alignItems="center">
                    <Inline gap="0.5rem">
                      <Text fontWeight="bold">Activity</Text>
                      {Boolean(count) && (
                        <ActivityTag alignItems="center" gap="0.5rem">
                          <Text fontWeight="bold">{count}</Text>
                          <StyledSpinner />
                        </ActivityTag>
                      )}
                    </Inline>
                    <Icon
                      className="chevronDown"
                      size="1.5rem"
                      strokeWidth="2"
                      name="chevronDown"
                    />
                  </Inline>
                </StyledActivityButton>
              </Stack>
            </BounceWrapper>
          </PositionWrapper>
          {shouldRenderActivity && (
            <PositionWrapper active={shouldShowActivity}>
              <BounceWrapper justifyContent="center" visible={shouldShowActivity}>
                <Activity ref={activityPanelRef} onClose={() => setPageToggle('bridge')} />
              </BounceWrapper>
            </PositionWrapper>
          )}
        </ContentWrapper>
      </BridgeTokenProvider>
    </Stack>
  );
};
