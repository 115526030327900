import { CryptoIcon, MarketplaceIcon } from '@parallel-mono/business-components';
import {
  Alert,
  Button,
  Card,
  DataGridColumn,
  H3,
  H5,
  Inline,
  Modal,
  ModalProps,
  Pagination,
  Responsive,
  Spinner,
  Stack,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { useGetOffers } from '../../../../hooks';
import { AcceptOfferActionTooltip } from '../../../../components';
import { useShopActions } from '../../ShopActionsProvider';

import { Link, NFTThumbnail, DataGrid } from '@/apps/paraspace/components';
import { Marketplace } from '@/apps/paraspace/generated/graphql';
import { formatBalance, formatToCurrency, truncateAddress } from '@/apps/paraspace/utils/format';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import { useNetworkConfig } from '@/apps/paraspace/hooks';
import { CheckedOffer, Offer } from '@/apps/paraspace/pages/Shop/types';
import { ERC721Symbol } from '@/apps/paraspace/typings';

const StyledCard = styled(Card)`
  position: relative;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    padding: 0;
    box-shadow: none;
  `}
`;
const StyledSpinner = styled(Spinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const StyledButton = styled(Button)`
  height: 40px;
`;
const PAGE_SIZE = 7;

export type ViewAllOffersModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  topOffer?: Offer;
  nftInfo?: { contractAddress: string; symbol: ERC721Symbol; name: string; tokenId: number };
  inLiquidation: boolean;
  onClose: () => void;
};

export const ViewAllOffersModal = memo(
  ({ inLiquidation, topOffer, nftInfo, onClose, ...others }: ViewAllOffersModalProps) => {
    const { account } = useWeb3Context();
    const { contractAddress, tokenId } = nftInfo ?? {};

    const { acceptOffer } = useShopActions();

    const { offers, loading, handlePageChange, totalPage, currentPage } = useGetOffers({
      filter: {
        contractAddress,
        identifierOrCriteria: tokenId?.toString() ?? ''
      },
      pageLimit: PAGE_SIZE
    });

    const { mobile } = useBreakpoints();

    const { checkIfTokenInPairing } = useP2PInfo();
    const isP2PStakedForTopOffer = useMemo(
      () =>
        !!topOffer &&
        checkIfTokenInPairing(
          topOffer.considerationItem.symbol,
          topOffer.considerationItem.tokenId ?? 0
        ),
      [checkIfTokenInPairing, topOffer]
    );

    const handleAccept = useCallback(
      (row: Offer) => {
        onClose();
        // Todo: handle accept blur offers
        if (row.platform === Marketplace.ParaSpace) {
          acceptOffer(row, nftInfo!);
        }
      },
      [onClose, nftInfo, acceptOffer]
    );

    const {
      explorerLink: [explorerLinkBaseUrl]
    } = useNetworkConfig();

    const columns: DataGridColumn<CheckedOffer>[] = useMemo(
      () => [
        {
          name: 'Offers',
          title: 'Offers',
          width: '1.5fr',
          render: ({ data: { orderItem } }) => {
            return (
              <Inline alignItems="center" gap="0.5rem">
                <CryptoIcon symbol={orderItem.symbol} size="xsmall" />
                <Text>
                  {formatBalance(orderItem.amount)} {orderItem.symbol}
                </Text>
              </Inline>
            );
          }
        },
        {
          name: 'USD Value',
          title: 'USD Value',
          width: '1fr',
          render: ({ data: { orderItem } }) => formatToCurrency(orderItem.priceInUsd)
        },
        {
          name: 'expiration',
          title: 'Expiration',
          width: '0.8fr',
          render: ({ data: { expirationTime } }) => dayjs(expirationTime).fromNow()
        },
        {
          name: 'From',
          title: 'From',
          width: '1fr',
          render: ({ data: { maker, platform } }) => {
            return (
              <Inline gap="0.25rem" alignItems="center">
                <Link href={`${explorerLinkBaseUrl}/address/${maker}`} target="_blank">
                  {truncateAddress(maker)}
                </Link>
                <MarketplaceIcon name={platform.toLowerCase()} size="xsmall" />
              </Inline>
            );
          }
        },
        {
          name: '',
          title: '',
          width: '0.6fr',
          render: ({ data }) => {
            const { isMakerUnhealthy, isMakerInsufficientBorrowLimit, maker, considerationItem } =
              data;
            const isP2PStakedForIndividual = checkIfTokenInPairing(
              considerationItem.symbol,
              considerationItem.tokenId ?? 0
            );
            return (
              <AcceptOfferActionTooltip
                isP2PStaked={isP2PStakedForIndividual}
                isMakerUnhealthy={isMakerUnhealthy}
                isMakerInsufficientBorrowLimit={isMakerInsufficientBorrowLimit}
              >
                <Button
                  block={mobile}
                  disabled={
                    isMakerUnhealthy ||
                    isMakerInsufficientBorrowLimit ||
                    inLiquidation ||
                    maker === account ||
                    isP2PStakedForIndividual
                  }
                  skin="secondary"
                  onClick={() => handleAccept(data)}
                >
                  Accept
                </Button>
              </AcceptOfferActionTooltip>
            );
          }
        }
      ],
      [account, checkIfTokenInPairing, explorerLinkBaseUrl, handleAccept, inLiquidation, mobile]
    );

    const topOfferDisabled = useMemo(() => {
      const targetOffer = offers.find(v => v.order.hash === topOffer?.order.hash);

      const { isMakerUnhealthy, isMakerInsufficientBorrowLimit } = targetOffer ?? {};
      return (
        inLiquidation ||
        targetOffer?.maker === account ||
        isP2PStakedForTopOffer ||
        isMakerUnhealthy ||
        isMakerInsufficientBorrowLimit ||
        loading
      );
    }, [account, inLiquidation, isP2PStakedForTopOffer, loading, offers, topOffer]);

    return (
      <Modal title="All Offers" size="55.125rem" onClose={onClose} {...others}>
        {topOffer && (
          <Stack gap="1.56rem">
            {inLiquidation && (
              <Alert type="error">
                You cannot accept any offer because your NFTs are in liquidation, repay your debt to
                recover.
              </Alert>
            )}
            <Responsive gap="1rem" justifyContent="space-between">
              <Inline>
                <NFTThumbnail
                  symbol={topOffer.considerationItem.symbol}
                  tokenId={tokenId!}
                  size="medium"
                />
                <Stack gap="0.25rem">
                  <H3>{topOffer.considerationItem.name}</H3>
                  <Inline gap="0.5rem">
                    <H5>
                      Floor Price: {`${formatBalance(topOffer.considerationItem.floorPrice)} ETH`}
                    </H5>
                    <Text skin="secondary">
                      ({formatToCurrency(topOffer.considerationItem.floorPriceInUsd)})
                    </Text>
                  </Inline>
                </Stack>
              </Inline>
              <AcceptOfferActionTooltip isP2PStaked={isP2PStakedForTopOffer}>
                <StyledButton
                  block={mobile}
                  disabled={topOfferDisabled}
                  skin="secondary"
                  onClick={() => handleAccept(topOffer)}
                >
                  Accept Highest Offer
                </StyledButton>
              </AcceptOfferActionTooltip>
            </Responsive>
            <StyledCard border={!mobile}>
              {loading && <StyledSpinner size="small" />}

              {!loading && (
                <>
                  <DataGrid<CheckedOffer> data={offers} columns={columns} pageSize={0} />
                  <Pagination
                    total={totalPage}
                    onChange={handlePageChange}
                    siblingCount={0}
                    startBoundaryCount={3}
                    page={currentPage}
                  />
                </>
              )}
            </StyledCard>
          </Stack>
        )}
      </Modal>
    );
  }
);
