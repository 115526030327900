import { useCallback, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';

import { useApeStaking } from './useApeStaking';

import { ApeStakingTokenSymbol } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts';

export type OwnerApeStaking = {
  stakedAmount: BigNumber;
  pendingRewards: BigNumber;
};

type OwnerApeStakingParams = {
  symbol: ApeStakingTokenSymbol;
  tokenId: number;
  owner?: string;
};

export const useOwnerApeStakingData = ({ symbol, tokenId, owner }: OwnerApeStakingParams) => {
  const { account } = useWeb3Context();
  const [ownerApeStaking, setOwnerApeStaking] = useState<OwnerApeStaking | null>(null);
  const { getStakingInfoByTokenInfo } = useApeStaking();

  const refresh = useCallback(() => {
    setOwnerApeStaking(null);
    getStakingInfoByTokenInfo(symbol, tokenId.toString(), owner ?? account)
      .then(res => {
        if (res) {
          setOwnerApeStaking({
            stakedAmount: res.stakedAmount,
            pendingRewards: res.pendingRewards
          });
        }
      })
      .catch(() => {
        setOwnerApeStaking(null);
      });
  }, [getStakingInfoByTokenInfo, symbol, tokenId, owner, account]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { ownerApeStaking, refresh };
};
