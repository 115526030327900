import { useMemo } from 'react';

import { useImageMap } from './ImageMapProvider';
import { useAddLiquidity } from './AddLiquidityProvider';
import { useRemoveLiquidity } from './RemoveLiquidityProvider';
import { useSupplyUniswapV3 } from './SupplyUniSwapV3Provider';

export const useUniSwapV3Manager = () => {
  const addLiquidity = useAddLiquidity();
  const removeLiquidity = useRemoveLiquidity();
  const supplyUniSwapV3 = useSupplyUniswapV3();
  const imageMap = useImageMap();

  return useMemo(
    () => ({
      addLiquidity,
      removeLiquidity,
      supplyUniSwapV3,
      imageMap
    }),
    [addLiquidity, removeLiquidity, supplyUniSwapV3, imageMap]
  );
};
