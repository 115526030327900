import { memo, useCallback, useMemo, useState } from 'react';

import { BuyCartAsset, Payment } from '../../types';

import { ApproveERC20Submitter, StepperProps, Stepper } from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { BatchBuyWithCreditSubmitter } from '@/apps/paraspace/pages/Shop/submitters';

type CheckoutStepperSubmitterProps = Omit<StepperProps, 'steps' | 'step' | 'onError'> & {
  formData: {
    assets: BuyCartAsset[];
    payNow: Payment;
    payLater: Payment;
  };
  onFinish: () => void;
  onError: () => void;
};

export const CheckoutStepperSubmitter = memo(
  ({
    formData: { assets, payNow },
    formData,
    onFinish,
    onError,
    ...others
  }: CheckoutStepperSubmitterProps) => {
    const { erc20InfoMap } = useMMProvider();
    const payNowAssetAddress = erc20InfoMap[payNow.currency].address;

    const [step, setStep] = useState(0);

    const handleNext = useCallback(() => {
      setStep(curr => curr + 1);
    }, [setStep]);

    const steps = useMemo(
      () => [
        {
          description: `Approve ${payNow.currency}`,
          content: (
            <ApproveERC20Submitter
              formData={{
                assetAddress: payNowAssetAddress,
                amount: payNow.amount,
                name: payNow.currency
              }}
              onFinish={handleNext}
              onError={onError}
            />
          ),
          skip: payNow.amount <= 0
        },
        {
          description: `Purchase ${assets.length} NFT${assets.length > 1 ? 's' : ''}`,
          content: (
            <BatchBuyWithCreditSubmitter
              formData={formData}
              onFinish={onFinish}
              onError={onError}
            />
          )
        }
      ],
      [payNow, assets, onError, onFinish, handleNext, formData, payNowAssetAddress]
    );

    return <Stepper steps={steps} step={step} {...others} />;
  }
);
