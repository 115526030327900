import { memo, useCallback, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  H5,
  Inline,
  Stack,
  Text,
  SmallText
} from '@parallel-mono/components';
import { InfoPanel, TokenInput } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';
import { useUpdateEffect } from 'react-use';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { MAXIMUM_BALANCE_DECIMALS } from '../../../config';
import { validate } from '../../pages/Details/component/MakeOfferModal/validation';
import { useMakeOfferValueAndHandlers } from '../../pages/Details/component/MakeOfferModal/hooks';

import { formatBalance } from '@/apps/paraspace/utils/format';
import { durationList } from '@/apps/paraspace/consts/duration';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { Select, Tooltip, WarningForBuyingApeStaking } from '@/apps/paraspace/components';
import { CollectionInfo } from '@/apps/paraspace/pages/Shop/contexts/CollectionProvider';

const SelectWrapper = styled(Inline)`
  height: 2.75rem;
`;

export type SelectedToken = {
  symbol: string;
  address: string;
  decimal: number;
  walletAmount: number;
  borrowAmount: number;
  offerAmount: number;
};

export type OfferFormValue = {
  token: SelectedToken;
  startTime: number;
  endTime: number;
};

type MakeOfferFormProps = {
  collection: CollectionInfo;
  tokenId?: number;
  topOfferPriceInUsd?: BigNumber;
  multiplier?: BigNumber;
  onAddWETHClick: () => void;
  onSubmitForm?: (value: OfferFormValue) => void;
};

export const MakeOfferForm = memo(
  ({
    collection,
    tokenId,
    topOfferPriceInUsd,
    multiplier,
    onAddWETHClick,
    onSubmitForm
  }: MakeOfferFormProps) => {
    const { account } = useWeb3Context();
    const {
      paymentTokenOptions,
      walletBalance,
      futureAvailableCredit,
      selectedPaymentToken,
      handlePaymentTokenChange,
      walletAmount,
      borrowAmount,
      handleBorrowAmountChange,
      handleMaxBorrowAmount,
      handleWalletAmountChange,
      handleMaxWalletAmount,
      offerAmount,
      offerTip,
      selectedBidDuration,
      handleBidDurationChange,
      handleConfirm,
      showApeStakingWarning
    } = useMakeOfferValueAndHandlers({
      collection,
      multiplier,
      topOfferPriceInUsd,
      onSubmitForm,
      tokenId
    });

    const [walletChecked, setWalletChecked] = useState(false);
    const [tickApeStakingWarning, setTickApeStakingWarning] = useState(false);

    useUpdateEffect(() => {
      handleWalletAmountChange(null);
    }, [walletChecked]);

    useUpdateEffect(() => {
      handleBorrowAmountChange(null);
      setWalletChecked(false);
    }, [selectedPaymentToken]);

    const errMessage = useMemo(
      () => validate({ borrowAmount, walletAmount, selectedPaymentToken }),
      [borrowAmount, selectedPaymentToken, walletAmount]
    );

    const confirmBtnDisabled = useMemo(
      () =>
        !account ||
        !selectedBidDuration ||
        offerAmount.lte(0) ||
        !!errMessage ||
        !selectedPaymentToken ||
        (showApeStakingWarning && !tickApeStakingWarning),
      [
        account,
        errMessage,
        offerAmount,
        selectedBidDuration,
        selectedPaymentToken,
        showApeStakingWarning,
        tickApeStakingWarning
      ]
    );

    const summaries = useMemo(
      () =>
        [
          {
            title: 'Total Offer',
            value: (
              <Text>
                {formatBalance(offerAmount)} {selectedPaymentToken.value}
              </Text>
            )
          }
        ].concat(
          topOfferPriceInUsd && offerAmount.gt(0)
            ? [{ title: '', value: <Text skin="secondary">{offerTip}</Text> }]
            : []
        ),
      [offerAmount, offerTip, selectedPaymentToken.value, topOfferPriceInUsd]
    );

    const handleTickApeStakingWarning = useCallback(({ checked }: { checked: boolean }) => {
      setTickApeStakingWarning(checked);
    }, []);

    return (
      <Stack>
        <Inline gap="1rem" alignItems="center">
          <H5>Offer in</H5>
          <Select
            options={paymentTokenOptions}
            value={selectedPaymentToken?.value}
            onChange={handlePaymentTokenChange}
            menuAlign="left"
          />
        </Inline>
        <TokenInput
          label={<H5>Make Offer with Credit</H5>}
          hint={
            <Inline gap="0.3rem" alignItems="center">
              <Text skin="secondary">
                Credit: {formatBalance(futureAvailableCredit)} {selectedPaymentToken?.value}
              </Text>
              <Tooltip content="This is the amount of credit you have access to, including the available borrow amount from your existing loan positions as well as the amount you can borrow by collateralizing the NFT you are purchasing." />
            </Inline>
          }
          hasError={!!errMessage && borrowAmount !== null}
          token={selectedPaymentToken?.value}
          value={borrowAmount}
          onChange={handleBorrowAmountChange}
          placeholder="0"
          decimals={MAXIMUM_BALANCE_DECIMALS}
          actionButtonText="MAX"
          onAction={handleMaxBorrowAmount}
          inputProps={{
            inputProps: {
              autoFocus: true
            }
          }}
        />
        <Checkbox checked={walletChecked} onChange={target => setWalletChecked(target.checked)}>
          <H5 skin="secondary">Add from Wallet Balance</H5>
        </Checkbox>
        {walletChecked && (
          <TokenInput
            label={<H5>Wallet</H5>}
            hint={
              <Inline gap="0.3rem" alignItems="center">
                <Text skin="secondary">
                  Balance: {formatBalance(walletBalance)} {selectedPaymentToken?.value}
                </Text>
                <Tooltip content="This is the amount of credit you have access to, including the available borrow amount from your existing loan positions as well as the amount you can borrow by collateralizing the NFT you are purchasing." />
              </Inline>
            }
            hasError={!!errMessage && walletAmount !== null}
            token={selectedPaymentToken?.value}
            value={walletAmount}
            onChange={handleWalletAmountChange}
            placeholder="0"
            decimals={MAXIMUM_BALANCE_DECIMALS}
            actionButtonText="MAX"
            onAction={handleMaxWalletAmount}
            inputProps={{
              inputProps: {
                autoFocus: true
              }
            }}
          />
        )}
        {errMessage && <Alert type="error">{errMessage}</Alert>}
        <Inline justifyContent="space-between">
          <Stack gap="0">
            <H5>Duration</H5>
            <SmallText skin="secondary">
              {`Ends on ${dayjs()
                .add(selectedBidDuration.value, 'day')
                .utc()
                .format('MMM DD, YYYY [at] hh:mma')} UTC`}
            </SmallText>
          </Stack>
          <SelectWrapper>
            <Select
              options={durationList}
              onChange={handleBidDurationChange}
              value={selectedBidDuration.value}
            />
          </SelectWrapper>
        </Inline>
        <InfoPanel skin="primary" infos={summaries} />
        {showApeStakingWarning && (
          <WarningForBuyingApeStaking
            checked={tickApeStakingWarning}
            onChange={handleTickApeStakingWarning}
          />
        )}
        <Inline>
          <Button
            size="large"
            skin="primary"
            block
            disabled={confirmBtnDisabled}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
          <Button block onClick={onAddWETHClick} size="large">
            Add wETH
          </Button>
        </Inline>
      </Stack>
    );
  }
);
