import { useCallback, useMemo } from 'react';
import { ApeStakingService, POOL_ID_MAP } from 'paraspace-utilities-contract-helpers';
import { providers as multicallProviders } from '@0xsequence/multicall';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { ERC721Symbol, ApeStakingTokenSymbol } from '@/apps/paraspace/typings';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import { useContractsMap } from '@/apps/paraspace/hooks';

export const useCheckApeStakedState = () => {
  const { provider } = useWeb3Context();
  const contracts = useContractsMap();
  const { checkIfTokenInPairing } = useP2PInfo();

  const service = useMemo(() => {
    const multicallProvider = new multicallProviders.MulticallProvider(provider);
    return new ApeStakingService(multicallProvider, contracts.ApeCoinStaking);
  }, [contracts.ApeCoinStaking, provider]);

  return useCallback(
    async (
      apes: {
        tokenId: number;
        symbol: ApeStakingTokenSymbol;
        targetPool: ApeStakingTokenSymbol;
      }[]
    ) => {
      return Promise.all(
        apes.map(async ({ symbol, targetPool, tokenId }) => {
          const targetPoolId = POOL_ID_MAP[targetPool];
          const isInP2P = checkIfTokenInPairing(symbol, tokenId);
          if (targetPoolId === POOL_ID_MAP.BAKC && symbol !== ERC721Symbol.BAKC) {
            const pairingStatus = await service.instance.mainToBakc(POOL_ID_MAP[symbol], tokenId);
            const position = await service.instance.nftPosition(POOL_ID_MAP[symbol], tokenId);

            return {
              tokenId,
              isStaked: position.stakedAmount.gt(0),
              isPaired: pairingStatus.isPaired,
              isInP2P
            };
          }
          const position = await service.instance.nftPosition(targetPoolId, tokenId);
          return {
            tokenId,
            isStaked: position.stakedAmount.gt(0),
            isInP2P
          };
        })
      );
    },
    [checkIfTokenInPairing, service.instance]
  );
};
