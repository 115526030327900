import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  H6,
  Inline,
  Stack,
  StackProps,
  Tag,
  Text,
  Toggle
} from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { NFTThumbnail } from '@/apps/paraspace/components';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';

export type TokenItemProps = Omit<StackProps, 'children'> & {
  symbol: ERC721Symbol;
  contractAddress: string;
  tokenId: number;
  asCollateral: boolean;
  inLiquidation: boolean;
  inAuction: boolean;
  onToggleCollateral: (tokenId: number) => void;
};

export const TokenItem = memo(
  ({
    tokenId,
    symbol,
    asCollateral,
    onToggleCollateral,
    inLiquidation,
    inAuction,
    contractAddress,
    ...others
  }: TokenItemProps) => {
    const navigate = useNavigate();
    const { nftInfoMap } = useMMProvider();

    const handleDetailClick = useCallback(() => {
      // HACK: fix me when the nft owner can be correctly distinguished
      navigate(`/details/${contractAddress}/${tokenId}`, { state: { from: 'supply' } });
    }, [navigate, contractAddress, tokenId]);

    const handleToggleCollateral = useCallback(() => {
      onToggleCollateral(tokenId);
    }, [onToggleCollateral, tokenId]);

    const apeStakingEnabled = isNFTinvolvedWithApeStaking(symbol);
    const { checkIfTokenInPairing } = useP2PInfo();

    const traitMultiplier =
      nftInfoMap?.[symbol]?.tokenTraitMultipliers?.[tokenId] ?? new BigNumber(DEFAULT_MULTIPLIER);

    return (
      <Stack {...others} gap="1rem">
        <Inline onClick={handleDetailClick} alignItems="center">
          <NFTThumbnail symbol={symbol} tokenId={tokenId} size="small" round />
          <Stack gap="0.25rem">
            <Text fontWeight="bold">#{tokenId}</Text>
            {traitMultiplier.gt(DEFAULT_MULTIPLIER) && (
              <Tag skin="success" size="small">
                {formatNumber(traitMultiplier)}x Boost
              </Tag>
            )}
            {inAuction && (
              <Tag skin="error" size="small">
                In Liquidation
              </Tag>
            )}
          </Stack>
        </Inline>
        <Inline justifyContent="space-between">
          <H6 skin="secondary" fontWeight="medium">
            Collateral
          </H6>
          <Toggle
            checked={asCollateral}
            disabled={asCollateral && inLiquidation}
            onChange={handleToggleCollateral}
          />
        </Inline>
        <Inline>
          {symbol === ERC721Symbol.MOONBIRD && (
            <Button skin="secondary" block onClick={handleDetailClick}>
              View Nesting
            </Button>
          )}
          {apeStakingEnabled && (
            <Button
              block
              skin="secondary"
              onClick={() => {
                navigate(
                  checkIfTokenInPairing(symbol, tokenId)
                    ? absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS.MY_POSITION
                    : absoluteRouteNames.APE_STAKING.index
                );
              }}
            >
              Stake
            </Button>
          )}
        </Inline>
      </Stack>
    );
  }
);
