import { mapValues } from 'lodash';

export const routeNames = {
  index: '',
  LENDING: {
    index: 'credit',
    LIQUIDATION: {
      index: 'liquidation',
      IN_LIQUIDATION: 'in-liquidation',
      NEAR_LIQUIDATION: 'near-liquidation',
      PAUSED_LIQUIDATION: 'paused-liquidation'
    },
    USER_INFO: {
      index: 'user-info',
      PROFILE: 'profile',
      EARNINGS: 'earnings',
      NFT_DELEGATION: 'nft-delegation'
    }
  },
  FLASH_CLAIM: 'flash-claim', // not used yet
  SHOP: {
    index: 'shop',
    collectionShop: ':contractAddress',
    BUY: 'buy',
    LIST: {
      index: 'list',
      MY_LISTINGS: 'my-listings',
      LIST_PRICES_SETTING: 'set-prices'
    },
    OFFERS: 'offers'
  },
  APE_STAKING: {
    index: 'ape-staking',
    legacyIndex: 'apeStaking',
    AUTO_COMPOUND: 'auto-compound',
    APE_SHARE_POOLS_CREATE: 'share-pools-create',
    APE_SHARE_POOLS: {
      index: 'share-pools',
      ACTIVE_MATCHES: 'active-matches',
      MY_POSITION: 'my-positions'
    },
    NFT_POOLS: 'nft-pools',
    POOL_STATS: 'pool-stats',
    COIN_POOL: { index: 'ape-coin-pool', TAB: ':tab' },
    HORIZEN_STAKING: 'horizen-staking',
    MY_EARNINGS: 'my-earnings',
    CALCULATOR: 'calculator',
    MINT: 'mint',
    AIRDROP: 'airdrop'
  },
  NFT_DETAIL: 'details/:contractAddr/:tokenId',
  EMAIL_ACTIVATION: 'email-activation',
  LEADER_BOARD: {
    index: 'leaderboard',
    DEPOSIT_TVL: 'deposit-tvl',
    BNPL_VOLUME: 'bnpl-volumn'
  }
};

type NameMap = {
  [key: string]: string | NameMap;
} & {
  index: string;
  legacyIndex?: string;
};

const getAbsoluteRouteNames = (
  nameMap: string | NameMap,
  contextRoute: string
): string | NameMap => {
  if (typeof nameMap === 'string') {
    return `${contextRoute}${nameMap}`;
  }
  const { index } = nameMap;
  return mapValues<NameMap, string | NameMap>(nameMap, (v: string | NameMap, k: string) => {
    if (k === 'index' || k === 'legacyIndex') {
      return `${contextRoute}${v}`;
    }
    return getAbsoluteRouteNames(v, index === '' ? contextRoute : `${contextRoute}${index}/`);
  }) as NameMap;
};

export const absoluteRouteNames = getAbsoluteRouteNames(routeNames, '/') as typeof routeNames;
