import { useMemo } from 'react';

import { useGetContractAddressBySymbol } from './useGetContractAddressBySymbol';

import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';

export const useContractAddressBySymbol = (symbol: ERC20Symbol | ERC721Symbol) => {
  const getContractAddressBySymbol = useGetContractAddressBySymbol();

  return useMemo(() => getContractAddressBySymbol(symbol), [getContractAddressBySymbol, symbol]);
};
