import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ChainId,
  ERC20Config,
  ERC20Symbol,
  ParallelERC20Symbol
} from '@parallel-utils/contracts-registry';
import BigNumber from 'bignumber.js';
import axios from 'axios';
import { chain, isEmpty, mapValues } from 'lodash';
import { Environment } from 'parax-sdk';

import {
  BridgeNetworks,
  SupportedChainId,
  parallelChain,
  supportedBridgeChains
} from '../../../configs/networks';
import { supportedERC20sMap } from '../../../configs/erc20s';
import { useERC20Balances } from '../../../hooks';
import { useNetworkProvider } from '../../../hooks/useNetworkProvider';
import { BRIDGE_SERVICE } from '../../../configs';
import { NetworkConfig } from '../../../types';

import { useEOAProvider } from '@/apps/parax/contexts';
import { Maybe } from '@/apps/parax/typings/basic';
import { zero } from '@/apps/parax/consts';
import { FetchingStatus } from '@/apps/parax/typings';
import { env } from '@/env';

const l1NativeTokenWithdrawalNetwork = {
  [Environment.DEVELOPMENT]: [BridgeNetworks[ChainId.Sepolia]],
  [Environment.STAGING]: [BridgeNetworks[ChainId.Sepolia]],
  [Environment.PRODUCTION]: [BridgeNetworks[ChainId.Ethereum]]
}[env];

type SupportedToken = ERC20Config<ParallelERC20Symbol> & {
  balance: Maybe<BigNumber>;
  priceInUsd: Maybe<BigNumber>;
  balanceStatus: FetchingStatus;
};

export const useNetworkHandler = (
  token: Maybe<string>
): {
  nativeTokenBalance: BigNumber;
  selectedNetwork: Maybe<SupportedChainId>;
  setSelectedNetwork: (network: number) => void;
  supportedNetworks: NetworkConfig[];
  networksLiquidity: Maybe<Record<ChainId, BigNumber>>;
  isLoadingLiquidity: boolean;
  tokens: {
    supportedTokens: SupportedToken[];
    refetchSupportedTokens: () => Promise<void> | undefined;
  };
} => {
  const [networksLiquidity, setNetworksLiquidity] =
    useState<Maybe<Record<ChainId, BigNumber>>>(null);
  const [isLoadingLiquidity, setIsLoadingLiquidity] = useState(false);
  const { chainId } = useEOAProvider();
  const [selectedNetwork, setSelectedNetwork] = useState<Maybe<SupportedChainId>>(null);

  const currentNetworkRef = useRef(chainId);

  useEffect(() => {
    currentNetworkRef.current = chainId;
  }, [chainId]);

  const targetNetworkSupportedERC20s = useMemo(
    () => supportedERC20sMap[parallelChain.chainId].map(v => v.symbol),
    []
  );
  const parallelNetworkERC20s = useMemo(
    () =>
      supportedERC20sMap[parallelChain.chainId].filter(v =>
        targetNetworkSupportedERC20s.includes(v.symbol)
      ),
    [targetNetworkSupportedERC20s]
  );

  const provider = useNetworkProvider(parallelChain.chainId);

  const {
    balanceMap: tokenBalanceMap,
    fetchingStatus,
    fetchBalance
  } = useERC20Balances(parallelNetworkERC20s, provider, parallelChain.chainId);

  const nativeTokenBalance = useMemo(
    () => tokenBalanceMap?.[parallelChain.nativeCurrency.symbol].balance ?? zero,
    [tokenBalanceMap]
  );

  const supportedTokens = useMemo(() => {
    return chain(parallelNetworkERC20s)
      .omitBy(v => v.symbol === 'WETH')
      .map(
        v =>
          ({
            ...v,
            balance: tokenBalanceMap?.[v.symbol].balance ?? null,
            priceInUsd: tokenBalanceMap?.[v.symbol].priceInUsd ?? null,
            balanceStatus: fetchingStatus
          } as SupportedToken)
      )
      .concat({
        symbol: ERC20Symbol.ETH,
        address: '',
        aTokenAddress: '',
        aDebtTokenAddress: '',
        parallelBridgeAddress: '',
        decimals: 18,
        isNativeToken: true,
        displayName: 'ETH',
        balance: nativeTokenBalance,
        priceInUsd: tokenBalanceMap?.[ERC20Symbol.ETH].priceInUsd ?? zero,
        balanceStatus: FetchingStatus.SUCCESS
      })
      .value();
  }, [fetchingStatus, nativeTokenBalance, parallelNetworkERC20s, tokenBalanceMap]);

  const supportedNetworks = useMemo(() => {
    if (token === 'ETH') {
      return l1NativeTokenWithdrawalNetwork;
    }
    return token
      ? supportedBridgeChains.filter(v =>
          supportedERC20sMap[v.chainId]?.find(config => config.symbol === token)
        )
      : [];
  }, [token]);

  useEffect(() => {
    if (!isEmpty(supportedNetworks)) {
      setSelectedNetwork(supportedNetworks[0].chainId);
    }
  }, [supportedNetworks, token]);

  const fetchNetworksLiquidity = useCallback(async () => {
    const address = parallelNetworkERC20s.find(v => v.symbol === token)?.address;
    if (!token || !address) {
      setNetworksLiquidity(null);
      return;
    }

    try {
      setIsLoadingLiquidity(true);
      const response = await axios.get<{
        data: Record<number, string>;
      }>(`${BRIDGE_SERVICE}/public/liquidity?tokenAddress=${address}`);

      setNetworksLiquidity(
        mapValues(response.data.data, value => BigNumber(value)) as Record<ChainId, BigNumber>
      );
    } catch (e) {
      console.error('Fetch networks liquidity failed, error', e);
    } finally {
      setIsLoadingLiquidity(false);
    }
  }, [parallelNetworkERC20s, token]);

  useEffect(() => {
    fetchNetworksLiquidity();
  }, [fetchNetworksLiquidity]);

  return {
    nativeTokenBalance,
    selectedNetwork,
    setSelectedNetwork,
    supportedNetworks,
    networksLiquidity,
    isLoadingLiquidity,
    tokens: {
      supportedTokens,
      refetchSupportedTokens: fetchBalance
    }
  };
};
