import BigNumber from 'bignumber.js';
import { ReactNode, useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import { NumberRange } from '@/apps/paraspace/components';
import { formatToCurrency } from '@/apps/paraspace/utils/format';

export const useInfos = ({
  symbol,
  borrowLimitInUsd,
  newBorrowLimitInUsd,
  bakcTotalStakingAmount,
  totalStakingInfo,
  bakcTotalRewardAmount
}: {
  symbol: ERC721Symbol;
  borrowLimitInUsd: BigNumber;
  newBorrowLimitInUsd: BigNumber;
  bakcTotalStakingAmount: BigNumber;
  totalStakingInfo: {
    totalAmount: number;
    totalRewards: number;
  };
  bakcTotalRewardAmount: BigNumber;
}) => {
  const showApeStakingInfo = isNFTinvolvedWithApeStaking(symbol);

  return useMemo(
    () =>
      [
        {
          title: 'New Borrow Limit',
          value: (
            <NumberRange
              start={borrowLimitInUsd.toNumber()}
              end={newBorrowLimitInUsd.toNumber()}
              formatter={formatToCurrency}
            />
          ) as ReactNode
        }
      ].concat(
        showApeStakingInfo && symbol !== ERC721Symbol.BAKC
          ? [
              {
                title: 'APE Unstaked',
                value: `${formatNumber(
                  bakcTotalStakingAmount.plus(totalStakingInfo.totalAmount).toNumber()
                )} APE`
              },
              {
                title: 'Staking Rewards',
                value: `${formatNumber(
                  bakcTotalRewardAmount.plus(totalStakingInfo.totalRewards).toNumber(),
                  {
                    threshold: {
                      min: 0.001
                    },
                    decimal: 4
                  }
                )} APE`
              }
            ]
          : []
      ),
    [
      symbol,
      borrowLimitInUsd,
      newBorrowLimitInUsd,
      bakcTotalStakingAmount,
      totalStakingInfo,
      bakcTotalRewardAmount,
      showApeStakingInfo
    ]
  );
};
