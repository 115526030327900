import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

import { RESERVED_GAS_FEE } from '../../../configs';

import { Maybe } from '@/apps/parax/typings/basic';

// export const useBridgeImplementation = (symbol: Maybe<string>) => {
//   const { provider, chainId } = useEOAProvider();
//   const bridge = useBridge(symbol, parallelChain.eid, provider.getSigner(), chainId);
//   const nativeCurrencyBridge = useNativeCurrencyBridge();
//   const { approveIfNecessary } = useERC20(symbol);
//   const [bridgingStatus, setBridgingStatus] = useState(FetchingStatus.INIT);

//   const toast = useParaXToast();

//   const bridgeToken = useCallback(
//     async (amount: BigNumber, toAddress: string) => {
//       if (!bridge) {
//         throw new Error('bridge not ready');
//       }
//       setBridgingStatus(FetchingStatus.FETCHING);
//       try {
//         await toast.promise(approveIfNecessary(amount), {
//           pending: 'Approving'
//         });
//         await toast.promise(bridge.send({ to: toAddress, amount }), {
//           pending: 'Bridging your assets to Parallel'
//         });
//         setBridgingStatus(FetchingStatus.SUCCESS);
//       } catch (error) {
//         console.error(error);
//         setBridgingStatus(FetchingStatus.FAIL);
//       }
//     },
//     [bridge, approveIfNecessary, toast]
//   );

//   const bridgeNativeToken = useCallback(
//     async (
//       amount: BigNumber,
//       toAddress: string,
//       l1GasFeeInWei: BigNumber,
//       l2GasFeeInWei: BigNumber
//     ) => {
//       if (!nativeCurrencyBridge) {
//         throw new Error('bridge not ready');
//       }
//       setBridgingStatus(FetchingStatus.FETCHING);
//       try {
//         await toast.promise(
//           nativeCurrencyBridge.send({ to: toAddress, amount, l1GasFeeInWei, l2GasFeeInWei }),
//           {
//             pending: 'Bridging your assets to Parallel'
//           }
//         );
//         setBridgingStatus(FetchingStatus.SUCCESS);
//       } catch (error) {
//         console.error(error);
//         setBridgingStatus(FetchingStatus.FAIL);
//       }
//     },
//     [nativeCurrencyBridge, toast]
//   );

//   return {
//     bridgeToken,
//     bridgingStatus,
//     bridgeNativeToken
//   };
// };

export const useValidation = ({
  amount,
  token
}: {
  amount: Maybe<number>;
  token: Maybe<{
    symbol: string;
    balance: number | null;
  }>;
}) => {
  const errorMessage = useMemo(() => {
    if (amount === null || amount === 0) {
      return 'Invalid amount';
    }
    if (
      token?.symbol === 'ETH' &&
      BigNumber(amount)
        .plus(RESERVED_GAS_FEE)
        .gt(token?.balance ?? 0)
    ) {
      return `Insufficient ${token?.symbol} for gas`;
    }
    if (BigNumber(amount).gt(token?.balance ?? 0)) {
      return `Insufficient ${token?.symbol} balance.`;
    }
    return null;
  }, [token, amount]);

  return errorMessage;
};
