import {
  Button,
  Card,
  CardProps,
  H3,
  H6,
  Icon,
  Inline,
  SmallText,
  Stack
} from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useP2PStaking } from '../../../contexts';
import { CommonSummaryItems } from '../../../components';

import { RewardExplanation } from './RewardExplanation';

import useP2PPairStaking from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Tooltip } from '@/apps/paraspace/components';

const StyledCard = styled(Card)`
  width: 100%;
  height: fit-content;
`;

type Props = {
  apePoolStaked: BigNumber;
  maycPoolStaked: BigNumber;
  baycPoolStaked: BigNumber;
  bakcPoolStaked: BigNumber;
  dailyRewards: BigNumber;
};
export const SideBar: FC<Props & Omit<CardProps, 'children'>> = memo(
  ({ apePoolStaked, maycPoolStaked, baycPoolStaked, bakcPoolStaked, dailyRewards, ...others }) => {
    const { load } = useMMProvider();
    const { getPendingCApeReward } = useP2PPairStaking();
    const { claimAllReward } = useP2PStaking();
    const [unclaimedRewards, setUnclaimedRewards] = useState<null | BigNumber>(null);

    const refreshUnClaimedRewards = useCallback(async () => {
      const result = await getPendingCApeReward();
      setUnclaimedRewards(result);
    }, [getPendingCApeReward]);

    useEffect(() => {
      refreshUnClaimedRewards();
    }, [refreshUnClaimedRewards]);

    const disabledClaimAll = useMemo(
      () => !unclaimedRewards || (unclaimedRewards && unclaimedRewards.lte(0)),
      [unclaimedRewards]
    );

    const claimAll = useCallback(() => {
      if (!unclaimedRewards) return;
      claimAllReward(unclaimedRewards, () => {
        load();
        refreshUnClaimedRewards();
      });
    }, [claimAllReward, load, refreshUnClaimedRewards, unclaimedRewards]);

    return (
      <StyledCard inset="1.5rem" {...others} border>
        <Stack>
          <Inline gap="0.5rem">
            <Icon name="trendingUp" strokeWidth={2} />
            <H6>Summary</H6>
          </Inline>
          <Card border inset="1rem">
            <Stack alignItems="center" gap="0.5rem">
              <Inline justifyContent="center" gap="0.25rem">
                <H3 skin="success">{unclaimedRewards ? formatNumber(unclaimedRewards) : '-'}</H3>
                <H3 skin="secondary">cAPE</H3>
              </Inline>
              <Inline gap="0.125rem">
                <SmallText skin="secondary">Compounding Rewards</SmallText>
                <Tooltip content="Harvested rewards are currently compounding. Use Withdraw to enable withdrawal via Lending" />
              </Inline>
            </Stack>
          </Card>
          <Button disabled={disabledClaimAll} block skin="primary" onClick={claimAll}>
            Withdraw
          </Button>
          <CommonSummaryItems
            totalBAYCStaked={baycPoolStaked}
            totalMAYCStaked={maycPoolStaked}
            totalBAKCStaked={bakcPoolStaked}
            totalApeCoinStaked={apePoolStaked}
            dailyRewards={dailyRewards}
          />
          <RewardExplanation />
        </Stack>
      </StyledCard>
    );
  }
);
