import { useCallback, useMemo } from 'react';
import { ERC721Symbol } from 'paraspace-configs-v2';

import { ImageSize, getERC721Image } from '../utils/getERC721Image';

import { useAppConfig } from './useAppConfig';

export const useGetERC721Image = () => {
  const { nftImageApi } = useAppConfig();
  return useCallback(
    ({
      symbol,
      tokenId,
      size = 'small'
    }: {
      symbol: ERC721Symbol;
      tokenId: number | string;
      size?: ImageSize;
    }) => getERC721Image({ api: nftImageApi, tokenId: tokenId.toString(), size, symbol }),
    [nftImageApi]
  );
};

export const useERC721Image = (symbol: ERC721Symbol, tokenId: number | string, size: ImageSize) => {
  const wrappedGetERC721Image = useGetERC721Image();
  return useMemo(
    () => wrappedGetERC721Image({ tokenId: tokenId.toString(), size, symbol }),
    [symbol, tokenId, size, wrappedGetERC721Image]
  );
};
