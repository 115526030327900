import { memo } from 'react';
import {
  Inline,
  H3,
  H5,
  InlineProps,
  SmallText,
  Button,
  ButtonProps,
  Skeleton
} from '@parallel-mono/components';
import BigNumberJS from 'bignumber.js';
import { omit } from 'lodash';

import { CloseLiquidationTooltip } from './components';

import { ValuePill, StackedIcons } from '@/apps/paraspace/components';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { zero } from '@/apps/paraspace/consts/values';

type TitleProps = {
  totalValue: BigNumberJS;
  netWorth: BigNumberJS;
  assets: { symbol: ERC721Symbol | ERC20Symbol; tokenId?: number }[];
  collapsed?: boolean;
  closeLiquidationButtonProps: ButtonProps & { show: boolean };
} & Omit<InlineProps, 'children'>;

export const SupplyPositionsHeader = memo(
  ({
    assets,
    totalValue,
    netWorth,
    collapsed,
    closeLiquidationButtonProps,
    ...others
  }: TitleProps) => {
    return (
      <Inline gap="0" justifyContent="space-between" width="100%" alignItems="center" {...others}>
        <H3 fontWeight="bold">My Supplied Positions</H3>
        <Inline gap="1rem">
          {collapsed && <StackedIcons assets={assets} />}
          <ValuePill gap="0.5rem">
            <SmallText skin="secondary">Net Worth</SmallText>
            {netWorth.gt(zero) ? (
              <H5>{formatToCurrency(netWorth)}</H5>
            ) : (
              <Skeleton.Button height="1rem" width="2rem" />
            )}
          </ValuePill>
          <ValuePill gap="0.5rem">
            <SmallText skin="secondary">Supplied</SmallText>
            {totalValue.gt(zero) ? (
              <H5>{formatToCurrency(totalValue)}</H5>
            ) : (
              <Skeleton.Button height="1rem" width="2rem" />
            )}
          </ValuePill>
          {closeLiquidationButtonProps.show && (
            <CloseLiquidationTooltip disabled={!closeLiquidationButtonProps.disabled}>
              <Button {...omit(closeLiquidationButtonProps, 'show')}>
                {closeLiquidationButtonProps.children}
              </Button>
            </CloseLiquidationTooltip>
          )}
        </Inline>
      </Inline>
    );
  }
);
