import { ReactNode, memo, useCallback } from 'react';
import styled from 'styled-components';
import { Card, H3, H5, Icon, Inline, Modal, Stack, Text } from '@parallel-mono/components';
import { NETWORK_NAME_MAP } from 'paraspace-configs-v2';

import { useEOAProvider, useParaXToast } from '../../contexts';
import { sortedNetworks } from '../../config';

const NetworksSection = styled(Card)`
  width: 100%;
  overflow: hidden;
`;

const NetworkItem = styled(Inline).attrs({
  inset: '1.5rem',
  justifyContent: 'space-between'
})`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.skin.action.hoverMask};
  }
`;

type NetworkCheckProps = {
  children?: ReactNode;
};

export const NetworkCheck = memo(({ children }: NetworkCheckProps) => {
  const { chainId, switchNetwork } = useEOAProvider();

  const toast = useParaXToast();

  const createSwitchNetworkHandler = useCallback(
    network => () => {
      const networkName = NETWORK_NAME_MAP[network];
      toast.promise(switchNetwork(network), {
        pending: `Connecting to ${networkName}`,
        success: `Successfully connected to ${networkName}`,
        error: `Failed to connect to ${networkName}`
      });
    },
    [switchNetwork, toast]
  );

  const networkNotSupported = chainId !== null && !sortedNetworks.includes(chainId);
  return (
    <>
      {!networkNotSupported && children}
      <Modal size="32rem" isOpen={networkNotSupported} closable={false}>
        <Stack gap="1rem" alignItems="center">
          <H3>You are connected to the wrong network</H3>
          <Stack alignItems="center" gap="0">
            <Text skin="secondary">Your wallet is connected to a network we don’t support.</Text>
            <Text skin="secondary">You can switch to a supported network</Text>
          </Stack>
          <NetworksSection inset="0" border>
            {sortedNetworks.map(network => (
              <NetworkItem onClick={createSwitchNetworkHandler(network)} key={network}>
                <H5>{NETWORK_NAME_MAP[network]}</H5>
                <Icon name="arrowRight" />
              </NetworkItem>
            ))}
          </NetworksSection>
        </Stack>
      </Modal>
    </>
  );
});
