import { useCallback, useState } from 'react';

import { CancelListingModalProps } from '../CancelListingModal';
import { ApeListing, Listing } from '../../../types';
import { initialApeListing } from '../../../consts';

import { useParallelToast } from '@/apps/paraspace/contexts';
import { useCancelP2PApeStakingListingMutation } from '@/apps/paraspace/generated/graphql';
import useP2PPairStaking from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { FLOAT_SCALING_FACTOR } from '@/apps/paraspace/utils/format';

const defaultCancelListingModalProps: CancelListingModalProps = {
  listing: initialApeListing,
  isOpen: false
};

export const useCancelListing = (): [
  CancelListingModalProps,
  (listing: Listing) => Promise<void>
] => {
  const [cancelListingModalProps, setCancelListingModalProps] = useState<CancelListingModalProps>(
    defaultCancelListingModalProps
  );

  const parallelToast = useParallelToast();

  const { cancelListing: cancelListingFromContract } = useP2PPairStaking();
  const [cancelP2PApeStakingListingFromService] = useCancelP2PApeStakingListingMutation();

  const cancelListingImpl = useCallback(
    (listing: Listing) => {
      setCancelListingModalProps(curr => ({ ...curr, disabled: true }));
      return cancelListingFromContract({
        stakingType: listing.stakingType,
        offerer: listing.offerer,
        token: listing.token,
        tokenId: (listing as ApeListing).tokenId ?? 0,
        share: listing.share.multipliedBy(FLOAT_SCALING_FACTOR).decimalPlaces(0).toNumber(),
        startTime: Math.floor(listing.startDate.getTime() / 1000),
        endTime: Math.floor(listing.expirationDate.getTime() / 1000),
        v: listing.v,
        s: listing.s,
        r: listing.r
      })
        .then(async result => {
          setCancelListingModalProps(curr => ({
            ...curr,
            isOpen: false
          }));
          await result?.wait();
          await cancelP2PApeStakingListingFromService({
            variables: {
              listingHash: listing.listingHash
            }
          });
        })
        .finally(() => {
          setCancelListingModalProps(curr => ({ ...curr, disabled: false }));
        });
    },
    [cancelListingFromContract, cancelP2PApeStakingListingFromService]
  );

  const cancelListing = useCallback(
    (listing: Listing) => {
      return new Promise<void>((resolve, reject) => {
        setCancelListingModalProps(curr => ({
          ...curr,
          isOpen: true,
          listing,
          onClose: () => {
            setCancelListingModalProps(prev => ({
              ...prev,
              isOpen: false
            }));
            reject();
          },
          onConfirm: () => {
            parallelToast
              .promise(
                cancelListingImpl(listing).catch(error => {
                  throw getUserFriendlyError(error);
                })
              )
              .then(() => resolve())
              .catch(reject);
          }
        }));
      });
    },
    [parallelToast, setCancelListingModalProps, cancelListingImpl]
  );

  return [cancelListingModalProps, cancelListing];
};
