import BigNumberJS from 'bignumber.js';

import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ERC721Symbol } from '@/apps/paraspace/typings';

export const useNetApy = (amountFromCredit: Maybe<number>, amountFromBalance: Maybe<number>) => {
  const { effectiveCapeBorrowApy: borrowApyRateInBigNumber, nftPoolsCompoundApy } =
    useAutoCompoundApeInfo();

  const borrowApyRate = borrowApyRateInBigNumber?.toNumber() ?? 0;

  const totalAmount = (amountFromCredit ?? 0) + (amountFromBalance ?? 0);

  const compoundPoolApy = (nftPoolsCompoundApy?.[ERC721Symbol.BAKC] ?? BigNumberJS(0)).toNumber();
  const netApy = compoundPoolApy - (borrowApyRate * (amountFromCredit ?? 0)) / totalAmount;

  return { netApy, compoundPoolApy, borrowApyRate };
};
