import { Button, H4, Inline, Stack } from '@parallel-mono/components';
import styled from 'styled-components';

import { NFTThumbnail } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';

const Title = styled(H4)`
  text-align: center;
`;

const TransferSuccess = ({
  images,
  desc,
  onClose
}: {
  images: { tokenId: number; symbol: ERC721Symbol }[];
  desc: string;
  onClose: () => void;
}) => {
  return (
    <Stack gap="2rem" alignItems="center">
      <Inline gap=".5rem" justifyContent="center">
        {images.map(({ symbol, tokenId }, index) => (
          <NFTThumbnail
            key={index + tokenId}
            symbol={symbol}
            tokenId={tokenId}
            size="large"
            showDescription
          />
        ))}
      </Inline>
      <Stack gap=".25rem" alignItems="center">
        <Title>{desc}</Title>
      </Stack>
      <Button size="large" block skin="secondary" onClick={onClose}>
        Done
      </Button>
    </Stack>
  );
};

export default TransferSuccess;
