import { Card, CardProps, H3, Spinner, Stack } from '@parallel-mono/components';
import { FC, memo, useCallback, useMemo } from 'react';
import { isEmpty, sumBy } from 'lodash';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useListTokens } from '../contexts';
import { ListableToken } from '../types';

import {
  CollapsibleTokensSelector,
  FixedActionFooter,
  LinkButton,
  NoNFTs
} from '@/apps/paraspace/components';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { useMount } from '@/apps/paraspace/hooks/useMount';

const EmptyWrapper = styled(Card)`
  width: 100%;
`;
const ListWrapper = styled(Stack)`
  min-height: 25rem;
`;

type Props = Omit<CardProps, 'children'>;

export const List: FC<Props> = memo(({ ...props }) => {
  const {
    listableCollections,
    handleSelectListTokens: handleSelectedListTokens,
    refreshSelectedTokens,
    selectedListTokens,
    loading
  } = useListTokens();

  const navigate = useNavigate();

  const handleSetPrices = useCallback(
    () => navigate(absoluteRouteNames.SHOP.LIST.LIST_PRICES_SETTING),
    [navigate]
  );

  useMount(() => {
    refreshSelectedTokens();
  });

  const availableTokensCount = useMemo(
    () => sumBy(listableCollections, collection => collection.tokens.length),
    [listableCollections]
  );

  return (
    <Stack alignItems="center" {...props}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {isEmpty(listableCollections) && (
            <EmptyWrapper inset="4rem 0" border>
              <Stack alignItems="center">
                <NoNFTs
                  title={<H3>No NFT Available for Listing</H3>}
                  description="You have no NFT available for listing at the moment."
                />
                <LinkButton size="large" skin="secondary" to={absoluteRouteNames.SHOP.index}>
                  Shop NFTs
                </LinkButton>
              </Stack>
            </EmptyWrapper>
          )}
          {!isEmpty(listableCollections) && (
            <ListWrapper alignItems="flex-end" justifyContent="space-between" width="100%">
              <Stack width="100%">
                {listableCollections.map(({ tokens, symbol, collectionName }) => {
                  return (
                    <CollapsibleTokensSelector<ListableToken>
                      key={symbol}
                      headerHint={`Available to list: ${
                        tokens.filter(({ disabled }) => !disabled).length
                      }`}
                      collectionName={collectionName}
                      symbol={symbol}
                      tokens={tokens}
                      selectedTokens={selectedListTokens.filter(token => token.symbol === symbol)}
                      onSelectionChange={tokenList => handleSelectedListTokens(symbol, tokenList)}
                    />
                  );
                })}
              </Stack>
              <FixedActionFooter
                actionLabel="Set Prices"
                selectedCount={selectedListTokens.length}
                onClick={handleSetPrices}
                availableCount={availableTokensCount}
              />
            </ListWrapper>
          )}
        </>
      )}
    </Stack>
  );
});
