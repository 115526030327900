import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { MyListingItem } from '../useMyListingData';
import { useCollectionByContract } from '../../../../contexts';

import { ListingEditForm } from './ListingEditForm';
import { Duration, TokenInputValue } from './types';
import { CancelListingStepper } from './Stepper/CancelListingStepper';
import { UpdateListingStepper } from './Stepper/UpdateListingStepper';

import {
  useCollectAndSubmitProcedure,
  CollectAndSubmitProcedurePhase,
  ErrorState,
  NFTThumbnail,
  SuccessState
} from '@/apps/paraspace/components';

type ListingEditModalProps = Omit<ModalProps, 'children' | 'id' | 'onError'> & {
  onClose: () => void;
  listing: MyListingItem;
};

enum SubmitType {
  UpdateListingSubmitting,
  CancelListingSubmitting
}

export type ListEditFormData = {
  tokenValue: TokenInputValue;
  duration: Duration;
};

export const ListingEditModal = memo(
  ({ listing, isOpen, onClose, ...others }: ListingEditModalProps) => {
    const [submitType, setSubmitType] = useState<SubmitType>(SubmitType.UpdateListingSubmitting);
    const { symbol, tokenId, contractAddress } = listing;
    const { fees } = useCollectionByContract(contractAddress) || {};
    const { phase, handleFormSubmit, submittedFormData, handleSubmitFailed, handleSubmitSuccess } =
      useCollectAndSubmitProcedure<ListEditFormData>({
        running: isOpen
      });

    useEffect(() => {
      if (!isOpen) {
        setSubmitType(() => SubmitType.UpdateListingSubmitting);
      }
    }, [isOpen]);

    const handleUpdateListing = useCallback(
      (formData: ListEditFormData) => {
        setSubmitType(SubmitType.UpdateListingSubmitting);
        handleFormSubmit(formData);
      },
      [handleFormSubmit]
    );
    const handleCancelListing = useCallback(
      (formData: ListEditFormData) => {
        setSubmitType(SubmitType.CancelListingSubmitting);
        handleFormSubmit(formData);
      },
      [handleFormSubmit]
    );

    const modalTitle = useMemo(
      () => (submitType === SubmitType.CancelListingSubmitting ? 'Cancel Listing' : 'Edit Listing'),
      [submitType]
    );

    return (
      <Modal isOpen={isOpen} onClose={onClose} title={modalTitle} {...others}>
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <ListingEditForm
            listing={listing}
            collectionFees={fees}
            onUpdateListing={handleUpdateListing}
            onCancelListing={handleCancelListing}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting &&
          submitType === SubmitType.UpdateListingSubmitting && (
            <UpdateListingStepper
              data={{ symbol, tokenId, contractAddress, fees, ...submittedFormData! }}
              onFinish={handleSubmitSuccess}
              onError={handleSubmitFailed}
            />
          )}
        {phase === CollectAndSubmitProcedurePhase.Submitting &&
          submitType === SubmitType.CancelListingSubmitting && (
            <CancelListingStepper
              listing={listing}
              onFinish={handleSubmitSuccess}
              onError={handleSubmitFailed}
            />
          )}
        {phase === CollectAndSubmitProcedurePhase.Failed && (
          <ErrorState width="100%" closeModal={onClose} />
        )}
        {phase === CollectAndSubmitProcedurePhase.Success &&
          submitType === SubmitType.UpdateListingSubmitting && (
            <SuccessState
              icon={<NFTThumbnail symbol={symbol} tokenId={tokenId} />}
              actionButtonText="Close"
              actionButtonSkin="secondary"
              onAction={onClose}
              desc="Successfully Updated Listing"
              tip="Keep track of your listings in the Offers tab for updates on any sales."
            />
          )}
        {phase === CollectAndSubmitProcedurePhase.Success &&
          submitType === SubmitType.CancelListingSubmitting && (
            <SuccessState
              icon={<NFTThumbnail symbol={symbol} tokenId={tokenId} />}
              actionButtonText="Close"
              actionButtonSkin="secondary"
              onAction={onClose}
              desc="Successfully Cancelled Listing"
            />
          )}
      </Modal>
    );
  }
);
