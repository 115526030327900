import { Inline, TypographyVariant, Image } from '@parallel-mono/components';
import { FC, memo } from 'react';

import { Link, chainLinkIconSrc } from '@/apps/paraspace/components';
import { CHINALINK_FLOOR_PRICE_LINK } from '@/apps/paraspace/consts/externalLinks';

export const UniChainlink: FC<{}> = memo(() => {
  return (
    <Link
      href={CHINALINK_FLOOR_PRICE_LINK}
      target="_blank"
      underline={false}
      variant={TypographyVariant.bodySmall}
      skin="secondary"
    >
      <Inline alignItems="center" gap="0.25rem">
        <Image width="1rem" height="1rem" src={chainLinkIconSrc} />
        <span>Chainlink</span>
      </Inline>
    </Link>
  );
});
