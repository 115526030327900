import { FC } from 'react';
import BigNumberJS from 'bignumber.js';
import { Tag } from '@parallel-mono/components';

import { SellToken } from '../hooks';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { formatToPercentage } from '@/apps/paraspace/utils/format';

export const AutoSellTag: FC<{
  sellPercentage: Maybe<BigNumberJS>;
  sellToken: Maybe<SellToken>;
}> = ({ sellPercentage, sellToken }) => {
  if (sellPercentage) {
    return (
      <Tag skin={sellPercentage.gt(0) ? 'success' : 'primary'} size="small">
        {`${formatToPercentage(sellPercentage)} to ${sellToken}`}
      </Tag>
    );
  }

  return (
    <Tag skin="primary" size="small">
      -
    </Tag>
  );
};
