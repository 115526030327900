import { memo, useCallback, useState } from 'react';
import { StackProps, Stack } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';

import { ETHValidatorClaimForm } from './ETHValidatorClaimForm';

import { useStakeFishNToken } from '@/apps/paraspace/pages/hooks/useStakeFishNToken';
import { ErrorState, SuccessState, ConfirmingState } from '@/apps/paraspace/components';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol } from '@/apps/paraspace/typings';

export type ClaimETHValidatorModalProps = {
  onClose: () => void;
  tokenId: number;
} & Omit<StackProps, 'children'>;

enum Phase {
  Inputting,
  Submitting,
  Success,
  Failed
}

export const ClaimETHValidatorModal = memo(
  ({ onClose, tokenId, ...others }: ClaimETHValidatorModalProps) => {
    const [phase, setPhase] = useState<Phase>(Phase.Inputting);
    const [claimedValue, setClaimedValue] = useState<BigNumber>();
    const { nftInfoMap } = useMMProvider();
    const { claim } = useStakeFishNToken(nftInfoMap[ERC721Symbol.SF_VLDR].xTokenAddress);

    const parallelToast = useParallelToast();

    const handleClaim = useCallback(
      async (claimedRewards: BigNumber) => {
        setClaimedValue(claimedRewards);
        setPhase(Phase.Submitting);
        parallelToast.promise(
          claim([tokenId.toString()], [claimedRewards.toString()])
            .then(async tx => {
              await tx?.wait();
              setPhase(Phase.Success);
            })
            .catch(e => {
              setPhase(Phase.Failed);
              throw getUserFriendlyError(e);
            })
        );
      },
      [claim, parallelToast, tokenId]
    );

    const handleClose = useCallback(() => {
      onClose?.();
    }, [onClose]);

    return (
      <Stack width="100%" {...others}>
        {phase === Phase.Inputting && (
          <ETHValidatorClaimForm tokenId={tokenId} onClaim={handleClaim} />
        )}
        {phase === Phase.Submitting && <ConfirmingState />}
        {phase === Phase.Success && (
          <SuccessState
            actionButtonText="Done"
            onAction={handleClose}
            desc={`${claimedValue} ETH Claimed`}
          />
        )}
        {phase === Phase.Failed && <ErrorState closeModal={handleClose} />}
      </Stack>
    );
  }
);
