import { sumBy, weightedSumBy } from '@parallel-mono/utils';
import BigNumberJs from 'bignumber.js';

import { zero } from '@/apps/paraspace/consts/values';

export type Asset = {
  id: string;
  value: BigNumberJs;
  LTV: BigNumberJs;
  reserveLiquidationThreshold: BigNumberJs;
};

export const calculateBorrowLimitPoint = (assets: Asset[]) => {
  const totalAssetValue = BigNumberJs.max(
    sumBy(
      assets.filter(each => each?.LTV?.gt(0)),
      'value'
    ),
    zero
  );
  const averageReserveLiquidationThreshold = totalAssetValue.isZero()
    ? new BigNumberJs(0)
    : weightedSumBy(assets, 'value', 'reserveLiquidationThreshold')
        .div(totalAssetValue)
        .decimalPlaces(4, BigNumberJs.ROUND_DOWN);

  const averageLTV = totalAssetValue.isZero()
    ? new BigNumberJs(0)
    : weightedSumBy(assets, 'value', 'LTV')
        .div(totalAssetValue)
        .decimalPlaces(4, BigNumberJs.ROUND_DOWN);

  return {
    borrowLimit: totalAssetValue.times(averageLTV),
    totalCollateral: totalAssetValue,
    liquidationPoint: totalAssetValue.times(averageReserveLiquidationThreshold)
  };
};
