import { Stack, H4, Button, Text } from '@parallel-mono/components';
import styled from 'styled-components';
import { BigNumber } from 'bignumber.js';

import { StakingApeProps } from '../../hook/useLegacyOfficialApeStaking';

import { formatBalance } from '@/apps/paraspace/utils/format';
import { NFTThumbnail } from '@/apps/paraspace/components';

const CenteredText = styled(Text)`
  text-align: center;
`;

const StakingSuccess = ({
  formData,
  stakedValue,
  onClose
}: {
  formData: StakingApeProps;
  stakedValue?: BigNumber;
  onClose: () => void;
}) => {
  const { cashAmount, tokenId, type } = formData;

  return (
    <Stack gap="2rem" alignItems="center">
      <NFTThumbnail symbol={type} tokenId={tokenId} width="7.5rem" />
      <Stack gap=".25rem" alignItems="center">
        <H4>
          You have staked {formatBalance(cashAmount)} ApeCoin on #{tokenId}!
        </H4>
        <CenteredText skin="secondary">
          Your total staked amount is {formatBalance(stakedValue || cashAmount)} ApeCoin on #
          {tokenId}.
        </CenteredText>
      </Stack>
      <Button block size="large" onClick={onClose}>
        Done
      </Button>
    </Stack>
  );
};

export default StakingSuccess;
