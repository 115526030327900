import BigNumber from 'bignumber.js';

import { useGetUniswapV3BasicInfosQuery } from '@/apps/paraspace/generated/graphql';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { FLOAT_SCALING_FACTOR } from '@/apps/paraspace/utils/format';
import { useConvertSymbolWithNetwork } from '@/apps/paraspace/pages/hooks';

export const useUniswapBasicInfos = () => {
  const { data: { uniswapV3BasicInfos = [] } = {}, ...others } = useGetUniswapV3BasicInfosQuery();
  const { convertNativeTokenToWToken } = useConvertSymbolWithNetwork();
  const formattedData = uniswapV3BasicInfos.map(it => ({
    token0Symbol: convertNativeTokenToWToken(it.token0Symbol as ERC20Symbol),
    token1Symbol: convertNativeTokenToWToken(it.token1Symbol as ERC20Symbol),
    totalSupplied: parseInt(it.totalSupplied, 10),
    totalUsd: new BigNumber(it.totalUsd),
    ltv: new BigNumber(it.ltv).div(FLOAT_SCALING_FACTOR)
  }));

  return {
    data: formattedData,
    ...others
  };
};
