import BigNumberJs from 'bignumber.js';
import { StakingType } from 'paraspace-utilities-contract-helpers';

import { ListingType, Listing } from './types';

import { ApeStakingTokenSymbol, ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { FLOAT_SCALING_FACTOR } from '@/apps/paraspace/utils/format';

export const stakingTypeToPoolNameMap: Record<number, ApeStakingTokenSymbol> = {
  0: ERC721Symbol.BAYC,
  1: ERC721Symbol.MAYC,
  2: ERC721Symbol.BAKC
};

// workaround for the precision loss from contract side
// https://parallelfi.slack.com/archives/C03EDPLH9BM/p1676015242978029
const CAPE_BALANCE_ROUND_UP_THRESHOLD = 0.001;
export const tryRoundUpCapeBalance = (balance: BigNumberJs) => {
  const roundUpValue = new BigNumberJs(balance.toFixed(0, BigNumberJs.ROUND_UP));
  return roundUpValue.minus(balance).lt(CAPE_BALANCE_ROUND_UP_THRESHOLD) ? roundUpValue : balance;
};

export const selectedPoolToStakingType = {
  [ERC721Symbol.BAYC]: StakingType.BAYCStaking,
  [ERC721Symbol.MAYC]: StakingType.MAYCStaking,
  [ERC721Symbol.BAKC]: StakingType.BAKCPairStaking
};

export const SEEK_SYMBOL_TO_LISTING_TYPE_MAP = {
  [ERC721Symbol.BAYC]: ListingType.ApeListing,
  [ERC721Symbol.MAYC]: ListingType.ApeListing,
  [ERC721Symbol.BAKC]: ListingType.BakcListing,
  [ERC20Symbol.CAPE]: ListingType.ApeCoinListing
};

export const formatListingInput = (listing: Listing) => ({
  stakingType: listing.stakingType,
  offerer: listing.offerer,
  token: listing.token,
  tokenId: listing.listingType === ListingType.ApeCoinListing ? 0 : listing.tokenId,
  share: BigNumberJs(listing.share).multipliedBy(FLOAT_SCALING_FACTOR).decimalPlaces(0).toNumber(),
  startTime: Math.floor(listing.startDate.getTime() / 1000),
  endTime: Math.floor(listing.expirationDate.getTime() / 1000),
  v: listing.v,
  s: listing.s,
  r: listing.r
});

export const formateSelectedListings = (listings: Listing[]) =>
  listings.map(item => ({
    token: item.token,
    listingHash: item.listingHash,
    offerer: item.offerer,
    share: item.share,
    startDate: item.startDate,
    expirationDate: item.expirationDate,
    symbol: item.symbol,
    v: item.v,
    r: item.r,
    s: item.s,
    stakingType: item.stakingType,
    stakingPool: stakingTypeToPoolNameMap[item.stakingType],
    listingType: item.listingType,
    tokenId: item.listingType === ListingType.ApeCoinListing ? undefined : item.tokenId,
    amount: item.listingType === ListingType.ApeCoinListing ? item.amount : undefined
  }));
