/**
 * 1. NFT#ownerOf(tokenId)
 * 2. NTOKEN#ownerOf(tokenId)
 */
import { ERC721Service } from 'paraspace-utilities-contract-helpers';
import { useCallback, useMemo } from 'react';
import { providers } from '@0xsequence/multicall';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

type NftInfo = {
  symbol: string;
  tokenId: string;
};
export const useNftCurrentOwners = () => {
  const { provider } = useWeb3Context();
  const { nftInfoMap } = useMMProvider();
  const multicallProvider = useMemo(() => {
    return new providers.MulticallProvider(provider);
  }, [provider]);

  const getNftCurrentOwners = useCallback(
    async (nftInfos: NftInfo[]) => {
      const service = new ERC721Service(multicallProvider);
      const results = await Promise.all(
        nftInfos.map(async ({ symbol, tokenId }) => {
          const { address } = nftInfoMap[symbol];
          const owner = await service.getContractInstance(address).ownerOf(tokenId);
          return owner;
        })
      );
      return results.map(each => each.toLowerCase());
    },
    [multicallProvider, nftInfoMap]
  );

  return { getNftCurrentOwners };
};
