import ethTokens from '../../Images/ethTokens.svg';
// import maticTokens from '../../Images/maticTokens.svg';
// import glmrTokens from '../../Images/glmrTokens.svg';
// import dotTokens from '../../Images/dotTokens.svg';
import flooringTokens from '../../Images/flooring.svg';

import { TokenCategory } from '@/apps/paraspace/config';

export const getExpandableTokensInfo = (key: TokenCategory) => {
  const map = {
    ETH: {
      icon: ethTokens,
      displayName: 'ETH Tokens'
    },
    Flooring: {
      icon: flooringTokens,
      displayName: 'Flooring Protocol'
    }
    // [ERC20Symbol.MATIC]: maticTokens,
    // [ERC20Symbol.GLMR]: glmrTokens
    // [ERC20Symbol.DOT]: dotTokens
  };
  return map[key];
};
