import { memo, useCallback, useMemo, useState } from 'react';
import { Stack, StackProps } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';

import { V1cAPEConvertSubmitter } from '../submitters/V1cAPEConvertSubmitter';
import { ApproveV1cAPESubmitter } from '../submitters';

import { useContractsMap } from '@/apps/paraspace/hooks';
import { getV1ContractConfig } from '@/apps/paraspace/config';
import { Stepper } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type V1cAPEConvertSteppersProps = Omit<StackProps, 'children' | 'onError'> & {
  formData: {
    amount: BigNumber;
    from: string;
    to: string;
  };
  onFinish: () => void;
  onError: () => void;
};

export const V1cAPEConvertSteppers = memo(
  ({ onFinish, onError, formData, ...others }: V1cAPEConvertSteppersProps) => {
    const { env } = useWeb3Context();
    const contracts = useContractsMap();

    const [step, setStep] = useState(0);

    const handleNext = useCallback(() => {
      setStep(curr => curr + 1);
    }, []);

    const steps = useMemo(
      () => [
        {
          description: 'Approve Your cAPE',
          content: (
            <ApproveV1cAPESubmitter
              formData={{
                name: 'cAPE',
                assetAddress: getV1ContractConfig(env).cAPE,
                amount: formData.amount,
                spender: contracts.HelperContract
              }}
              onError={onError}
              onFinish={handleNext}
            />
          )
        },
        {
          description: 'Convert Your cAPE',
          content: (
            <V1cAPEConvertSubmitter formData={formData} onError={onError} onFinish={onFinish} />
          )
        }
      ],
      [contracts, onError, handleNext, formData, onFinish, env]
    );

    return (
      <Stack {...others}>
        <Stepper steps={steps} step={step} />
      </Stack>
    );
  }
);
