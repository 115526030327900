import { memo, useEffect } from 'react';

import { CreateListingReturnType } from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { useCreateP2PApeStakingListingsMutation } from '@/apps/paraspace/generated/graphql';
import { InProgress } from '@/apps/paraspace/components';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

type Props = {
  formData: CreateListingReturnType[];
  onFinish: () => void;
  onError: () => void;
};

export const CreateListingSubmitter = memo(({ formData, onFinish, onError }: Props) => {
  const parallelToast = useParallelToast();
  const [createP2PApeStakingListings] = useCreateP2PApeStakingListingsMutation();

  useEffect(() => {
    if (!formData) {
      return;
    }
    const inputListings = formData.map(
      ({ stakingType, offerer, token, share, startTime, endTime, tokenId, r, s, v }) => ({
        stakingType,
        offerer,
        token,
        tokenId,
        share,
        startTime,
        endTime,
        r,
        s,
        v
      })
    );

    parallelToast.promise(
      createP2PApeStakingListings({
        variables: {
          inputListings
        }
      })
        .then(() => {
          onFinish();
        })
        .catch(error => {
          onError();
          throw getUserFriendlyError(error);
        }),
      {
        pending: 'Creating listings',
        success: 'Create successfully!',
        error: 'Create failed'
      }
    );
  }, [createP2PApeStakingListings, formData, onError, onFinish, parallelToast]);

  return <InProgress tip="Creating listings" />;
});
