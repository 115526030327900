import { useCallback } from 'react';
import { isEqual } from 'lodash';

import { useNftActualOwners } from '@/apps/paraspace/pages/hooks/useAcutualOwners';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

export const NFT_SOLD_ERROR_MESSAGE =
  'The NFT(s) has just been sold. Please try again with another item.';
export const SELLER_HEALTH_FACTOR_IS_TOO_LOW =
  'This NFT can only be sold after the seller has repaid the loan. Please select another NFT to purchase';

export const useNFTBuyErrorHandler = () => {
  const { getNftActualOwners } = useNftActualOwners();

  const handleSingleNFTErrors = useCallback(
    async (e, seller: string, { symbol, tokenId }: { symbol: ERC721Symbol; tokenId: number }) => {
      const currentOwner = await getNftActualOwners([{ symbol, tokenId: String(tokenId) }]);

      if (!currentOwner.includes(seller)) {
        throw new Error(NFT_SOLD_ERROR_MESSAGE);
      }

      // For this error the root reason is that the seller's health factor is lower to sell the NFT
      // But for the buyer, we should display a buyer friendly error rather than seller friendly
      if (e.reason === 'execution reverted: 35') {
        throw new Error(SELLER_HEALTH_FACTOR_IS_TOO_LOW);
      }
      throw getUserFriendlyError(e);
    },
    [getNftActualOwners]
  );

  const handleNFTsErrors = useCallback(
    async (e, sellers: string[], tokens: { symbol: ERC721Symbol; tokenId: number }[]) => {
      const currentOwners = await getNftActualOwners(
        tokens.map(({ symbol, tokenId }) => ({
          symbol,
          tokenId: String(tokenId)
        }))
      );

      if (!isEqual(sellers, currentOwners)) {
        throw new Error(NFT_SOLD_ERROR_MESSAGE);
      }

      if (e.reason === 'execution reverted: 35') {
        throw new Error(SELLER_HEALTH_FACTOR_IS_TOO_LOW);
      }
      throw getUserFriendlyError(e);
    },
    [getNftActualOwners]
  );

  return { handleSingleNFTErrors, handleNFTsErrors };
};
