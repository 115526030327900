import { memo } from 'react';
import { TokenInput } from '@parallel-mono/business-components';
import { Stack, H5, StackProps } from '@parallel-mono/components';

import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { ConnectWallet } from '@/apps/paraspace/components';

type ConnectWalletMessageProps = Omit<StackProps, 'children'> & {
  token: string;
  onConnectWallet: () => void;
};

export const ConnectWalletMessage = memo(
  ({ token, onConnectWallet, ...others }: ConnectWalletMessageProps) => {
    return (
      <Stack gap="2rem" {...others}>
        <Stack gap="1.5rem">
          <TokenInput
            label={<H5>Amount</H5>}
            token={token}
            placeholder="0"
            decimals={MAXIMUM_BALANCE_DECIMALS}
            inputProps={{
              inputProps: {
                autoFocus: true
              }
            }}
          />
          <ConnectWallet btnTxt="Connect Wallet to Begin" onConnectWallet={onConnectWallet} />
        </Stack>
      </Stack>
    );
  }
);
