import styled from 'styled-components';
import { Card, Image } from '@parallel-mono/components';

export const StyledImage = styled(Image)`
  width: fit-content;
  max-width: 100%;
  ${({ theme }) => theme.mode === 'dark' && 'filter: invert(1);'}
  ${({ theme }) => theme.breakpoints.down('md')`
    margin-bottom: ${theme.spacing(6)};
  `}
`;

export const StyledCard = styled(Card)`
  ${({ theme }) => theme.breakpoints.down('md')`
    padding: ${theme.spacing(6)};
  `}
`;
