import { memo, useCallback, useMemo } from 'react';
import { sum } from 'lodash';
import { Modal, ModalProps } from '@parallel-mono/components';

import { InitiateNFTDelegationSubmitter } from '../../submitters';

import { DelegateNftForm } from './DelegateNftForm';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import {
  ErrorState,
  SuccessState,
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';

export type InitiateNftDelegationModalFormTokensMap = Partial<{
  [key in ERC721Symbol]: { tokenIds: number[]; collectionName: string };
}>;
export type InitiateNftDelegationModalProps = Omit<ModalProps, 'children'> & {
  tokensMap: InitiateNftDelegationModalFormTokensMap;
  onFinish?: () => void;
  onError?: () => void;
};

export const InitiateNftDelegationModal = memo(
  ({
    onError,
    tokensMap,
    onFinish,
    onClose,
    isOpen,
    ...others
  }: InitiateNftDelegationModalProps) => {
    const {
      phase,
      submittedFormData: delegate,
      handleFormSubmit,
      handleSubmitFailed,
      handleSubmitSuccess
    } = useCollectAndSubmitProcedure<string>({
      running: isOpen,
      onFinish,
      onError
    });

    const handleClose = useCallback(() => {
      if (
        phase === CollectAndSubmitProcedurePhase.Collecting ||
        phase === CollectAndSubmitProcedurePhase.Submitting
      ) {
        onError?.();
      }
      onClose?.();
    }, [onError, onClose, phase]);

    const totalTokens = useMemo(
      () => sum(Object.values(tokensMap).map(item => item.tokenIds.length)),
      [tokensMap]
    );

    const modalTitle = useMemo(() => {
      if (phase === CollectAndSubmitProcedurePhase.Collecting) {
        return 'Enter Delegation Address';
      }
      return 'Delegate';
    }, [phase]);
    return (
      <Modal
        closeOnBackdropClick={false}
        isOpen={isOpen}
        title={modalTitle}
        onClose={handleClose}
        {...others}
      >
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <DelegateNftForm totalTokens={totalTokens} onSubmit={handleFormSubmit} />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && isOpen && (
          <InitiateNFTDelegationSubmitter
            formData={{
              tokensMap,
              delegate: delegate!
            }}
            onError={handleSubmitFailed}
            onFinish={handleSubmitSuccess}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            onAction={handleClose}
            actionButtonText="Done"
            desc={`Successfully delegated ${totalTokens} NFTs to`}
            tip={delegate}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={handleClose} />}
      </Modal>
    );
  }
);
