import { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { Button, CardProps, H5, H6, Inline, Skin, useBreakpoints } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import { LiquidationAsset } from './types';

import { ValuePill, StackedIcons } from '@/apps/paraspace/components';
import { formatToCurrency } from '@/apps/paraspace/utils/format';

type AccountLiquidationDataProps = {
  isClosingLiquidation?: boolean;
  collateral: string;
  debt: string;
  healthFactor: number;
  assets: LiquidationAsset[];
  open?: boolean;
  isPaused: boolean;
  handleCloseLiquidation?: (() => void) | null;
} & Omit<CardProps, 'children' | 'border'>;

const StyledInline = styled(Inline)`
  ${({ theme }) => theme.breakpoints.down('desktop')`
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 0;
    > * {
      margin: 0.75rem 0.75rem 0 0;
    }
  `};
`;

const StyledButton = styled(Button)`
  padding: 0.5rem 1rem;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    margin-top: 1rem;
  `};
`;

const Badge = memo(
  ({ title, value, valueSkin }: { title: ReactNode; value: ReactNode; valueSkin?: Skin }) => (
    <ValuePill>
      <H6 skin="secondary">{title}</H6>
      <H5 skin={valueSkin || 'primary'}>{value}</H5>
    </ValuePill>
  )
);

export const AccountLiquidationGrid = (props: AccountLiquidationDataProps) => {
  const {
    healthFactor,
    collateral,
    debt,
    open,
    isPaused,
    assets,
    handleCloseLiquidation,
    isClosingLiquidation = false,
    ...others
  } = props;
  const { desktop } = useBreakpoints();
  return (
    <StyledInline justifyContent="space-between" alignItems="center" gap="0.5rem" {...others}>
      {desktop && !open && (
        <StackedIcons
          assets={assets.map(asset => ({
            symbol: asset.symbol,
            tokenId: asset.identifierOrCriteria
          }))}
        />
      )}
      <Badge title="Collateral" value={formatToCurrency(Number(collateral))} />
      <Badge title="Debt" value={formatToCurrency(Number(debt))} />
      <Badge title="Health Factor" valueSkin="warning" value={formatNumber(healthFactor)} />
      {!desktop && !open && (
        <StackedIcons
          assets={assets.map(asset => ({
            symbol: asset.symbol,
            tokenId: asset.identifierOrCriteria
          }))}
        />
      )}
      {handleCloseLiquidation && !isPaused && (
        <StyledButton
          loading={isClosingLiquidation}
          disabled={isClosingLiquidation}
          onClick={handleCloseLiquidation}
        >
          Close Liquidation
        </StyledButton>
      )}
    </StyledInline>
  );
};
