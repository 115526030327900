import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import BigNumberJS from 'bignumber.js';
import { noop, omit } from 'lodash';

import { useApeStaking } from '@/apps/paraspace/pages/hooks/ApeStaking/useApeStaking';
import { ApeStakingMainAssetSymbol, ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  calculateCompoundApyByApr,
  calculateEffectiveApy,
  calculateNFTCompoundApy
} from '@/apps/paraspace/utils/calculateApy';
import { Maybe } from '@/apps/paraspace/typings/basic';

type PoolsCompoundApy = {
  [ERC20Symbol.APE]: BigNumberJS;
  [ERC721Symbol.MAYC]: BigNumberJS;
  [ERC721Symbol.BAYC]: BigNumberJS;
  [ERC721Symbol.BAKC]: BigNumberJS;
};

type ApeCoinPoolSummary = {
  amount: BigNumberJS;
  apy: BigNumberJS;
  compoundApy: BigNumberJS;
  rewardPerHour: BigNumberJS;
};

type ApeAndBakcPoolSummary = ApeCoinPoolSummary & {
  stakedApeAmount: BigNumberJS;
  stakeLimit: BigNumberJS;
};

export type ApeStakingPoolSummary = {
  [key in ApeStakingMainAssetSymbol]: key extends ERC20Symbol.APE
    ? ApeCoinPoolSummary
    : ApeAndBakcPoolSummary;
};

const Context = createContext<{
  apeCoinCompoundApy: Maybe<BigNumberJS>;
  effectiveCapeSupplyApy: Maybe<BigNumberJS>;
  effectiveCapeBorrowApy: Maybe<BigNumberJS>;
  refresh: () => void;
  nftPoolsCompoundApy?: Omit<PoolsCompoundApy, ERC20Symbol.APE>;
  apeStakingPoolSummary: Maybe<ApeStakingPoolSummary>;
}>({
  apeCoinCompoundApy: null,
  effectiveCapeSupplyApy: null,
  effectiveCapeBorrowApy: null,
  refresh: () => {},
  apeStakingPoolSummary: null
});

export const useAutoCompoundApeInfo = () => useContext(Context);

export const AutoCompoundApeProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const { getPlatformStakingOverview } = useApeStaking();
  const { erc20InfoMap } = useMMProvider();

  // pure daily compound apy
  const [poolsCompoundApy, setPoolsCompoundApy] = useState<PoolsCompoundApy>();
  const [apeStakingPoolSummary, setApeStakingPoolSummary] =
    useState<Maybe<ApeStakingPoolSummary>>(null);

  // daily compound apy & taking lending (supply/borrow) apy into account
  // this is the maximum apy in theory
  const [effectiveCapeSupplyApy, setEffectiveCapeSupplyApy] = useState<BigNumberJS | null>(null);
  const [effectiveCapeBorrowApy, setEffectiveCapeBorrowApy] = useState<BigNumberJS | null>(null);

  const cApeSupplyApy = erc20InfoMap[ERC20Symbol.CAPE]?.supplyApyRate ?? null;
  const cApeBorrowApy = erc20InfoMap[ERC20Symbol.CAPE]?.borrowApyRate ?? null;

  const refresh = useCallback(async () => {
    try {
      const overviewData = await getPlatformStakingOverview();
      if (overviewData) {
        const { APE, MAYC, BAYC, BAKC } = overviewData;
        const apePoolCompoundApy = calculateCompoundApyByApr(APE.apy);
        const maycPoolCompoundApy = calculateNFTCompoundApy(APE.apy, MAYC.apy, MAYC.capPerPosition);
        const baycPoolCompoundApy = calculateNFTCompoundApy(APE.apy, BAYC.apy, BAYC.capPerPosition);
        const bakcPoolCompoundApy = calculateNFTCompoundApy(APE.apy, BAKC.apy, BAKC.capPerPosition);
        setPoolsCompoundApy({
          [ERC20Symbol.APE]: apePoolCompoundApy,
          [ERC721Symbol.MAYC]: maycPoolCompoundApy,
          [ERC721Symbol.BAYC]: baycPoolCompoundApy,
          [ERC721Symbol.BAKC]: bakcPoolCompoundApy
        });
        setApeStakingPoolSummary({
          [ERC20Symbol.APE]: {
            amount: APE.paraStakedApeAmount,
            apy: APE.apy,
            compoundApy: apePoolCompoundApy,
            rewardPerHour: APE.rewardsPerHour
          },
          [ERC721Symbol.BAYC]: {
            apy: BAYC.apy,
            amount: BigNumberJS(BAYC.paraStakedNFTAmount),
            stakedApeAmount: BAYC.paraStakedApeAmount,
            compoundApy: baycPoolCompoundApy,
            stakeLimit: BAYC.capPerPosition,
            rewardPerHour: BAYC.rewardsPerHour
          },
          [ERC721Symbol.MAYC]: {
            apy: MAYC.apy,
            amount: BigNumberJS(MAYC.paraStakedNFTAmount),
            stakedApeAmount: MAYC.paraStakedApeAmount,
            compoundApy: maycPoolCompoundApy,
            stakeLimit: MAYC.capPerPosition,
            rewardPerHour: MAYC.rewardsPerHour
          },
          [ERC721Symbol.BAKC]: {
            apy: BAKC.apy,
            amount: BigNumberJS(BAKC.paraStakedNFTAmount),
            stakedApeAmount: BAKC.paraStakedApeAmount,
            compoundApy: bakcPoolCompoundApy,
            stakeLimit: BAKC.capPerPosition,
            rewardPerHour: BAKC.rewardsPerHour
          }
        });
        if (cApeSupplyApy && cApeBorrowApy) {
          // (compoundApy + 1) * (cApeLendingApy + 1) - 1
          setEffectiveCapeSupplyApy(calculateEffectiveApy(apePoolCompoundApy, cApeSupplyApy));
          setEffectiveCapeBorrowApy(calculateEffectiveApy(apePoolCompoundApy, cApeBorrowApy));
        }
      }
    } catch (e) {
      console.log('getPlatformStakingOverview failed');
    }
  }, [getPlatformStakingOverview, cApeSupplyApy, cApeBorrowApy]);

  useEffect(() => {
    refresh().catch(noop);
  }, [refresh]);

  const value = useMemo(
    () => ({
      apeCoinCompoundApy: poolsCompoundApy?.APE || null,
      effectiveCapeBorrowApy,
      effectiveCapeSupplyApy,
      refresh,
      nftPoolsCompoundApy: omit(poolsCompoundApy, 'APE'),
      apeStakingPoolSummary
    }),
    [
      poolsCompoundApy,
      effectiveCapeBorrowApy,
      effectiveCapeSupplyApy,
      refresh,
      apeStakingPoolSummary
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
