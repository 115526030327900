import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';

export const useParaspaceApeCoinRewardsInfo = (stakedAmount: number) => {
  const { erc20InfoMap, basicInfoLoaded } = useMMProvider();
  const { effectiveCapeSupplyApy } = useAutoCompoundApeInfo();

  if (!basicInfoLoaded || !effectiveCapeSupplyApy) {
    return {
      apy: null,
      yearlyRewardsInApe: null,
      yearlyRewardsInUsd: null
    };
  }

  const { priceInUsd } = erc20InfoMap[ERC20Symbol.APE];

  const yearlyRewardsInApe = effectiveCapeSupplyApy.times(stakedAmount);
  const yearlyRewardsInUsd = yearlyRewardsInApe.times(priceInUsd);

  return {
    apy: effectiveCapeSupplyApy,
    yearlyRewardsInApe,
    yearlyRewardsInUsd
  };
};
