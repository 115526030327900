import { memo } from 'react';
import { Inline, SmallText, Stack, StackProps } from '@parallel-mono/components';
import styled from 'styled-components';

import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';

type NetApyExplanationProps = Omit<StackProps, 'children'> & {
  amountFromBalance: Maybe<number>;
  amountFromCredit: Maybe<number>;
  rewardApyRate: Maybe<number>;
  borrowApyRate: Maybe<number>;
  netApyRate: Maybe<number>;
};

const Container = styled(Stack)`
  width: 16.625rem;
`;

const InfoText = styled(SmallText).attrs({ skin: 'secondary' })``;

export const NetApyExplanation = memo(
  ({
    amountFromBalance,
    amountFromCredit,
    rewardApyRate,
    borrowApyRate,
    netApyRate,
    ...others
  }: NetApyExplanationProps) => {
    const totalAmount = (amountFromCredit ?? 0) + (amountFromBalance ?? 0);
    return (
      <Container gap="1rem" {...others}>
        <SmallText skin="primary">
          Net APY is a weighted APY based on your Staking APY and Borrow APY.
        </SmallText>
        <Stack gap="0.5rem">
          <Inline justifyContent="space-between">
            <InfoText>Reward APY</InfoText>
            <InfoText>
              {formatBalance(totalAmount)} x {formatToPercentage(rewardApyRate ?? 0)}
            </InfoText>
          </Inline>
          {!!amountFromCredit && (
            <Inline justifyContent="space-between">
              <InfoText>Borrow APY</InfoText>
              <InfoText>
                {formatBalance(amountFromCredit)} x {formatToPercentage(borrowApyRate ?? 0)}
              </InfoText>
            </Inline>
          )}
          <Inline justifyContent="space-between">
            <InfoText>Net APY</InfoText>
            <SmallText fontWeight="bold">{formatToPercentage(netApyRate ?? 0)}</SmallText>
          </Inline>
        </Stack>
      </Container>
    );
  }
);
