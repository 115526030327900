import { Modal, ModalProps } from '@parallel-mono/components';
import { memo } from 'react';
import BigNumberJS from 'bignumber.js';

import { AutoSellForm } from './AutoSellForm';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { SellToken } from '@/apps/paraspace/pages/ApePairing/pages/MyApes/components/SideBar/AutoSellPanel/hooks';

export type AutoSellModalProps = {
  sellPercentage: Maybe<BigNumberJS>;
  sellToken: Maybe<SellToken>;
  onClose: () => void;
} & Omit<ModalProps, 'children' | 'title' | 'id'>;

export const AutoSellModal = memo((props: AutoSellModalProps) => {
  const { sellPercentage, sellToken, onClose, ...others } = props;

  return (
    <Modal size="32.5rem" title="Auto Profits" onClose={onClose} {...others}>
      {sellPercentage && sellToken && (
        <AutoSellForm curPercentage={sellPercentage} curToken={sellToken} onClose={onClose} />
      )}
    </Modal>
  );
});
