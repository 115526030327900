import { Image, ImageProps } from '@parallel-mono/components';
import { WalletTypeEnum, WalletIconMap } from 'parax-sdk';
import { memo } from 'react';

export type WalletIconProps = Omit<ImageProps, 'src' | 'name'> & {
  wallet: WalletTypeEnum;
};

export const WalletIcon = memo(({ wallet, ...others }: WalletIconProps) => {
  if (wallet === WalletTypeEnum.NETWORK) return null;
  return <Image src={WalletIconMap[wallet]} {...others} />;
});
