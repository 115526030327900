import {
  DataGrid as RawDataGrid,
  Stack,
  H5,
  stackTwoColors,
  Icon
} from '@parallel-mono/components';
import cx from 'classnames';
import styled from 'styled-components';

const EmptyStateWrapper = styled(Stack)`
  padding: 1.25rem 1.875rem;
`;
const StyledH5 = styled(H5)`
  color: ${({ theme }) => theme.skin.grey[500]};
`;

const StyledEmptyGridIcon = styled(Icon).attrs({
  size: '8rem',
  name: 'boxOpen'
})`
  color: ${({ theme }) => theme.skin.primary.secondaryContrastText};
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
  `}
`;

export const DataGrid = styled(RawDataGrid).attrs(({ classNames, noDataMessage }) => ({
  classNames: {
    ...classNames,
    cell: cx('styled-datagrid-cell', classNames?.cell),
    headerCell: cx('styled-datagrid-header-cell', classNames?.headerCell),
    title: cx('styled-datagrid-title', classNames?.title),
    row: cx('styled-datagrid-row', classNames?.row),
    dataCardItemValueCell: cx('styled-data-card-item-value-cell', classNames?.dataCardItemValueCell)
  },
  noDataMessage: (
    <EmptyStateWrapper alignItems="center" justifyContent="center" gap="1rem">
      <StyledH5>{noDataMessage}</StyledH5>
      <StyledEmptyGridIcon />
    </EmptyStateWrapper>
  )
}))`
  width: 100%;
  .styled-datagrid-cell {
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
  }

  .styled-datagrid-header-cell {
    font-size: 1rem;
    font-weight: var(--font-weight-regular);
  }

  .styled-datagrid-title {
    padding: 0 0.5rem;
  }

  .styled-datagrid-row.clickable:hover > * {
    cursor: pointer;
    background: ${({ theme }) =>
      stackTwoColors(theme.skin.background.main, theme.skin.action.hoverMask)};
  }
  .styled-data-card-item-value-cell:first-child:last-child {
    width: 100%;
  }
` as typeof RawDataGrid;
