import { useCallback, useState } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { BorrowNativeTokenDerivativesForm } from './BorrowNativeTokenDerivativesForm';
import { BorrowNativeTokenDerivativesFormData } from './types';
import { BorrowNativeTokenDerivativesSteppers } from './BorrowNativeTokenDerivativesSteppers';

import {
  ErrorState,
  SuccessState,
  TimelockResultDesc,
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

export type BorrowNativeTokenDerivativesModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  defaultDerivative: ERC20Symbol;
  onError: () => void;
  onClose: () => void;
  onFinish: () => void;
};

export const BorrowNativeTokenDerivativesModal = ({
  defaultDerivative,
  isOpen,
  onError,
  onFinish,
  onClose,
  ...others
}: BorrowNativeTokenDerivativesModalProps) => {
  const {
    submittedFormData,
    submittingResult: claimed,
    phase,
    handleSubmitSuccess,
    handleSubmitFailed,
    handleFormSubmit
  } = useCollectAndSubmitProcedure<BorrowNativeTokenDerivativesFormData, boolean, void>({
    running: isOpen,
    onError,
    onFinish
  });
  const { erc20InfoMap } = useMMProvider();

  const [derivative, setDerivative] = useState<ERC20Symbol>(defaultDerivative);

  const handleFormDataChange = useCallback((formData: BorrowNativeTokenDerivativesFormData) => {
    setDerivative(formData.derivative);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      closeOnBackdropClick={false}
      title={`Borrow ${erc20InfoMap[derivative]?.displayName ?? derivative}`}
      onClose={onClose}
      {...others}
    >
      {phase === CollectAndSubmitProcedurePhase.Collecting && (
        <BorrowNativeTokenDerivativesForm
          onCancel={onClose}
          defaultDerivative={defaultDerivative}
          onSubmit={handleFormSubmit}
          onFormDataChange={handleFormDataChange}
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Submitting && (
        <BorrowNativeTokenDerivativesSteppers
          formData={submittedFormData!}
          onFinish={handleSubmitSuccess}
          onError={handleSubmitFailed}
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
      {phase === CollectAndSubmitProcedurePhase.Success && (
        <SuccessState
          desc="Borrowed Successfully"
          tip={
            <>
              You have borrowed {submittedFormData!.amount.toString()}{' '}
              {erc20InfoMap[derivative]?.displayName}. {!claimed && <TimelockResultDesc />}
            </>
          }
          onAction={onClose}
          actionButtonText={claimed ? 'Done' : 'View Queue'}
        />
      )}
    </Modal>
  );
};
