import { mapValues } from 'lodash';
import { Network } from 'paraspace-configs-v2';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import { useContractsMap, useNetworkConfig } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';

const CONTRACT_ROUTER_FOR_NETWORK = {
  [Network.MAINNET]: '#inventory',
  [Network.SEPOLIA]: '#inventory'
} as Record<Network, string>;

export const useErc721ExplorerLinkMap = () => {
  const { nftInfoMap } = useMMProvider();
  const { chainId } = useWeb3Context();

  const {
    explorerLink: [explorerLinkBaseUrl]
  } = useNetworkConfig();

  const contractsMap = useContractsMap();
  const p2pStakingAddress = contractsMap.P2PPairStaking;

  return mapValues(nftInfoMap, item => {
    if (isNFTinvolvedWithApeStaking(item.symbol as ERC721Symbol)) {
      return [
        {
          label: 'NFT Staking Pool',
          value: `${explorerLinkBaseUrl}/token/${item.address}?a=${item.xTokenAddress}${
            CONTRACT_ROUTER_FOR_NETWORK[chainId] || ''
          }`
        },
        {
          label: 'P2P Contract',
          value: `${explorerLinkBaseUrl}/token/${item.address}?a=${p2pStakingAddress}${
            CONTRACT_ROUTER_FOR_NETWORK[chainId] || ''
          }`
        }
      ];
    }

    return [
      {
        label: 'Token Contract',
        value: `${explorerLinkBaseUrl}/token/${item.address}?a=${item.xTokenAddress}${
          CONTRACT_ROUTER_FOR_NETWORK[chainId] || ''
        }`
      }
    ];
  });
};
