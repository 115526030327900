import { isNil } from 'lodash';

import { ApeStakingTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';

const poolIdToSymbolMap: {
  [id: string]: ApeStakingTokenSymbol;
} = {
  1: ERC721Symbol.BAYC,
  2: ERC721Symbol.MAYC,
  3: ERC721Symbol.BAKC
};

export const poolIdToSymbol = (poolId?: number | string | null) => {
  if (isNil(poolId)) {
    return null;
  }
  return poolIdToSymbolMap[poolId];
};
