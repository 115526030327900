import { useDebounce, useUpdateEffect } from 'react-use';
import BigNumberJs, { BigNumber } from 'bignumber.js';
import { useState } from 'react';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useSupplyAndBorrowApy } from '@/apps/paraspace/pages/hooks/useSupplyAndBorrowApy';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const useDebounceInterestRate = ({
  borrowApyRate,
  borrowAmount,
  disabled
}: {
  borrowAmount: Maybe<number>;
  borrowApyRate: BigNumber;
  disabled: Boolean;
}) => {
  const [isInterestRateLoading, setIsInterestRateLoading] = useState(false);
  const [interestRate, setInterestRate] = useState(borrowApyRate);
  const { getBorrowApy } = useSupplyAndBorrowApy();

  useUpdateEffect(() => {
    setIsInterestRateLoading(true);
  }, [borrowAmount]);

  useDebounce(
    () => {
      const handle = async () => {
        if (borrowAmount && !disabled) {
          const result = await getBorrowApy(ERC20Symbol.WETH, borrowAmount.toString());
          setInterestRate(result ? BigNumberJs(result.newBorrowApy) : borrowApyRate);
          setIsInterestRateLoading(false);
          return;
        }
        setInterestRate(borrowApyRate);
        setIsInterestRateLoading(false);
      };
      handle();
    },
    500,
    [borrowAmount, getBorrowApy, disabled, setInterestRate, borrowApyRate]
  );
  return { interestRate, isInterestRateLoading };
};
