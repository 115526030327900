import { useCallback } from 'react';
import BigNumber from 'bignumber.js';

import { useApproval } from './useApproval';
import { useERC20 } from './useERC20';

import { Maybe } from '@/apps/parax/typings/basic';

export const useERC20Approval = ({
  token,
  amount,
  spender,
  needResetAllowanceBeforeApprove
}: {
  token: string;
  amount: number | BigNumber | null;
  spender: string;
  needResetAllowanceBeforeApprove?: boolean;
}) => {
  const { approve: approveToken, getAllowance } = useERC20(token);

  const checkAllowance = useCallback(
    (allowance: Maybe<BigNumber>) => allowance?.gte(amount ?? 0) ?? false,
    [amount]
  );

  const createApproveTransaction = useCallback(
    async (allowance: Maybe<BigNumber>) => {
      if (allowance?.gt(0) && needResetAllowanceBeforeApprove) {
        const tx = await approveToken(spender, '0');
        await tx?.wait();
      }

      return approveToken(spender);
    },
    [approveToken, spender, needResetAllowanceBeforeApprove]
  );

  const getAllowanceAsBigNumber = useCallback(
    () => getAllowance(spender).then(str => new BigNumber(str)),
    [spender, getAllowance]
  );

  return useApproval<BigNumber>({
    createApproveTransaction,
    checkAllowance,
    getAllowance: getAllowanceAsBigNumber
  });
};
