import { FC, memo, useState } from 'react';

import { ApproveWallet, InProgress } from '@/apps/paraspace/components';
import { useParallelToast } from '@/apps/paraspace/contexts';
import useP2PPairStaking from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';

interface ClaimRewardProps {
  onFinish: () => void;
  onError: (e: any) => void;
  tip: string;
}

const ClaimReward: FC<ClaimRewardProps> = ({ tip, onFinish, onError }) => {
  const [approving, setApproving] = useState(false);

  const parallelToast = useParallelToast();
  const { claimCApeReward } = useP2PPairStaking();

  useAsyncEffect(() => {
    parallelToast.promise(
      claimCApeReward()
        .then(async tx => {
          setApproving(true);
          await tx?.wait();
          onFinish();
        })
        .catch(e => {
          onError(e);
          throw getUserFriendlyError(e);
        })
    );
  }, []);

  return approving ? <InProgress tip={tip} /> : <ApproveWallet />;
};

export default memo(ClaimReward);
