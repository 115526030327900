import { useBreakpoints } from '@parallel-mono/components';
import { FC, memo } from 'react';
import styled from 'styled-components';

import { Filter } from '../../types';

import { MobileFilterPanel } from './MobileFilterPanel';
import { CommonFilters } from './CommonFilters';

export const DESKTOP_FILTER_PANEL_WIDTH = '16rem';

const StyledDesktopFilterPanel = styled(CommonFilters)<{ $expand: boolean }>`
  width: ${({ $expand }) => ($expand ? DESKTOP_FILTER_PANEL_WIDTH : 0)};
  ${({ $expand }) => ($expand ? '' : 'padding-left: 0; padding-right: 0;')}
  transition: all 0.3s;
  white-space: nowrap;
  overflow: hidden;
  position: sticky;
  top: var(--header-height-pc);
`;

export type FilterPanelProps = {
  opened: boolean;
  onCloseFilterPanel: () => void;
  defaultFilter: Filter;
  onFilterChange: (filter: Filter) => void;
};

export const FilterPanel: FC<FilterPanelProps> = memo(props => {
  const { mobile } = useBreakpoints();
  return mobile ? (
    <MobileFilterPanel {...props} />
  ) : (
    <StyledDesktopFilterPanel $expand={props.opened} {...props} />
  );
});
