import { Environment } from 'parax-sdk';

import { env } from '@/env';

export const BRIDGE_SERVICE = {
  [Environment.DEVELOPMENT]:
    process.env.REACT_APP_BRIDGE_API ?? 'https://bridge-api-staging.parallel.fi',
  [Environment.STAGING]: 'https://bridge-api-staging.parallel.fi',
  [Environment.PRODUCTION]: 'https://bridge-api.parallel.fi'
}[env];
