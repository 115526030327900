import { HTMLAttributes, memo, useState } from 'react';
import styled from 'styled-components';
import { H3, H5, Inline, Stack, Tag } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import {
  FAQ,
  MakeOfferForm,
  MakeOfferProcessingModal,
  OfferFormValue
} from '../../../../components';
import { MakeOfferModalPayload } from '../../../../types';

import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import {
  NFTThumbnail,
  ErrorState,
  SuccessState,
  TraitBoostTip,
  ConnectWallet
} from '@/apps/paraspace/components';

const ItemDetail = styled(Stack)`
  padding-left: 1.5rem;
  ${({ theme }) => theme.breakpoints.down('lg')`
    overflow: hidden;
  `};
`;

const StyledH3 = styled(H3)`
  ${({ theme }) => theme.breakpoints.down('lg')`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `};
`;

export type MakeOfferModalProps = HTMLAttributes<HTMLDivElement> & {
  data: MakeOfferModalPayload;
  closeModal: () => void;
  onAddWETHClick: () => void;
};
export type ProcessStatus = 'init' | 'processing' | 'success' | 'error';

const MakeOfferMainForm = memo((props: MakeOfferModalProps) => {
  const {
    data: { collection, tokenId, topOffer, lowestPriceListing, multiplier },
    closeModal,
    onAddWETHClick,
    ...others
  } = props;

  const { collectionName, symbol } = collection || {};
  const isShowTraitMultiplier = multiplier && multiplier.gt(DEFAULT_MULTIPLIER);

  const { isUsingUserWallet } = useWeb3Context();

  const [showFaqs, setShowFaqs] = useState(false);
  const [processStatus, setProcessStatus] = useState<ProcessStatus>('init');
  const [offerFormValue, setOfferFormValue] = useState<OfferFormValue>();

  return (
    <Stack {...others}>
      {(() => {
        if (offerFormValue && processStatus === 'processing') {
          return (
            <MakeOfferProcessingModal
              offerFormValue={offerFormValue}
              onError={() => setProcessStatus('error')}
              onFinish={() => {
                setProcessStatus('success');
              }}
              payload={props.data}
            />
          );
        }
        if (processStatus === 'error') {
          return <ErrorState closeModal={closeModal} />;
        }
        if (processStatus === 'success') {
          return (
            <SuccessState
              actionButtonText="Done"
              icon={<NFTThumbnail tokenId={Number(tokenId)} symbol={symbol} size="large" />}
              desc={`Successfully placed a bid for ${collectionName} #${tokenId}!`}
              onAction={closeModal}
            />
          );
        }
        return (
          <>
            <Inline alignItems="center" gap="0">
              <NFTThumbnail
                symbol={symbol}
                tokenId={Number(tokenId)}
                size="large"
                floatingTag={
                  isShowTraitMultiplier && (
                    <Tag skin="success" size="small">
                      {formatNumber(multiplier)}x Boost
                    </Tag>
                  )
                }
              />
              <ItemDetail gap="0.75rem">
                <Stack gap="0.25rem">
                  <StyledH3>
                    {collectionName} #{tokenId}
                  </StyledH3>
                  {lowestPriceListing ? (
                    <Inline alignItems="center" gap="0.25rem">
                      <H5 fontWeight="bold">
                        Price: {formatBalance(lowestPriceListing.price)} ETH
                      </H5>
                      <H5 fontWeight="bold" skin="secondary">
                        ({formatToCurrency(lowestPriceListing.priceInUsd)})
                      </H5>
                    </Inline>
                  ) : null}
                  {topOffer ? (
                    <Inline alignItems="center" gap="0.25rem">
                      <H5 fontWeight="bold">
                        Top Offer: {formatBalance(topOffer.orderItem.amount)}{' '}
                        {topOffer.orderItem.symbol}
                      </H5>
                      <H5 fontWeight="bold" skin="secondary">
                        ({formatToCurrency(topOffer.orderItem.priceInUsd)})
                      </H5>
                    </Inline>
                  ) : null}
                </Stack>
                {isShowTraitMultiplier && <TraitBoostTip />}
              </ItemDetail>
            </Inline>
            {isUsingUserWallet ? (
              <MakeOfferForm
                multiplier={multiplier}
                collection={collection}
                tokenId={Number(tokenId)}
                topOfferPriceInUsd={topOffer?.orderItem.priceInUsd}
                onAddWETHClick={onAddWETHClick}
                onSubmitForm={value => {
                  setProcessStatus('processing');
                  setOfferFormValue(value);
                }}
              />
            ) : (
              <ConnectWallet btnTxt="Connect Wallet to Bid" />
            )}
            <FAQ showFaqs={showFaqs} setShowFaqs={setShowFaqs} />
          </>
        );
      })()}
    </Stack>
  );
});

export default MakeOfferMainForm;
