import styled from 'styled-components';
import { memo } from 'react';

import { Select, SelectProps } from '../Select';

const StyledSelectClassNames: SelectProps['classNames'] = {
  itemBlock: 'multiple-select-item-block',
  display: 'multiple-select-display'
};

const StyledSelect = styled(Select).attrs({
  classNames: StyledSelectClassNames
})`
  max-width: 50rem;
  padding-left: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.down('desktop')`
    width: 100%;
    margin: ${theme.spacing(4)} 0 0;
    max-width: 100%;
  `};

  .${StyledSelectClassNames.display} {
    & > div {
      flex-wrap: wrap;
    }
  }

  .${StyledSelectClassNames.itemBlock} {
    background: ${({ theme }) => theme.skin.primary.contrastBackground};
    color: ${({ theme }) => theme.skin.primary.main};
    border-radius: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    margin: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(0.5)}`};

    & > *:first-child {
      width: 1.5rem;
      height: auto;
    }
  }
`;

const renderDisplay: SelectProps['renderDisplay'] = option => {
  if (option) {
    return option.icon || option.label;
  }
  return null;
};

export type MultipleSelectProps = Omit<
  SelectProps,
  'multiselect' | 'clearable' | 'renderDisplay' | 'renderItem'
>;

export const MultipleSelect = memo((props: MultipleSelectProps) => {
  return <StyledSelect clearable multiselect renderDisplay={renderDisplay} {...props} />;
});
