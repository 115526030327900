import {
  H2,
  H5,
  H6,
  Inline,
  SmallText,
  Stack,
  Text,
  TypographyVariant,
  Image
} from '@parallel-mono/components';
import { memo, useMemo } from 'react';
import { CryptoIcon } from '@parallel-mono/business-components';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';

import { useCreditPrice } from '../../../hooks';

import SlashImage from '@/apps/paraspace/pages/Shop/images/slash.svg';
import OwnerImage from '@/apps/paraspace/pages/Shop/images/owner.svg';
import { AuctionStatus } from '@/apps/paraspace/pages/Liquidation/types';
import { shiftedLeftBy } from '@/apps/paraspace/utils/calculations';
import { formatBalance, truncateTextMid } from '@/apps/paraspace/utils/format';
import { Link } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useAppConfig, useNetworkConfig, useSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { BuyListing } from '@/apps/paraspace/pages/Shop/types';
import { AssetInLiquidation } from '@/apps/paraspace/generated/graphql';
import { useWNativeTokenSymbol } from '@/apps/paraspace/pages/hooks';

const StyledText = styled(Text)<{ lineThrough?: boolean }>`
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 3.25rem;
  ${({ lineThrough }) => lineThrough && `text-decoration: line-through;`}
  ${({ theme }) => theme.breakpoints.only('mobile')`
    ${theme.typography.header2}
  `}
`;

const CollectionName = styled(H2)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    ${theme.typography.header3}
  `}
`;

const CollectionBaseInfoWrapper = styled(Inline)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    > * + * {
      margin-left:0;
    }
  `}
`;

export const InlineWithHeight = styled(Inline).attrs({ alignItems: 'center' })`
  height: 3.25rem;
`;

const NftBaseInfo = ({
  disableShopFeature,
  ownedBy,
  assetInLiquidation,
  lowestPriceListing,
  isOwner,
  multiplier,
  collectionName
}: {
  ownedBy: string;
  disableShopFeature: boolean;
  assetInLiquidation?: Maybe<AssetInLiquidation>;
  lowestPriceListing: Maybe<BuyListing>;
  isOwner: boolean;
  multiplier: BigNumber;
  collectionName: string;
}) => {
  const { account } = useWeb3Context();
  const { erc20Config } = useAppConfig();
  const wNativeTokenSymbol = useWNativeTokenSymbol();

  const ownedByAddress = isOwner ? account : ownedBy;
  const {
    explorerLink: [explorerLinkBaseUrl]
  } = useNetworkConfig();
  const addressLink = `${explorerLinkBaseUrl}/address/${ownedByAddress || ''}`;

  const { contractAddr } = useParams();
  const symbol = useSymbolByContractAddress(contractAddr || '') ?? '';

  const buyWithCreditPrice = useCreditPrice({
    symbol,
    price: lowestPriceListing?.price,
    multiplier
  });

  const price = useMemo(() => {
    if (assetInLiquidation) {
      return (
        <Stack gap="0">
          <H6 skin="secondary" fontWeight="medium">
            Liquidation Price
          </H6>
          <Inline gap=".5rem" alignItems="center">
            {assetInLiquidation.auctionStatus === AuctionStatus.StartedAndCanBeLiquidated ? (
              <>
                <CryptoIcon symbol={wNativeTokenSymbol} size="2rem" />
                <StyledText>
                  {formatBalance(
                    shiftedLeftBy(assetInLiquidation.currentPrice ?? '0', erc20Config.WETH.decimals)
                  )}
                </StyledText>
              </>
            ) : (
              <StyledText>-</StyledText>
            )}
          </Inline>
        </Stack>
      );
    }

    if (disableShopFeature) {
      return null;
    }

    return (
      <>
        {lowestPriceListing && (
          <Stack gap="0">
            <H6 skin="secondary" fontWeight="medium">
              Min. Pay Now
            </H6>
            <Inline alignItems="center" gap=".5rem">
              <CryptoIcon symbol="ETH" size="2rem" />
              <StyledText>{formatBalance(buyWithCreditPrice)}</StyledText>
            </Inline>
          </Stack>
        )}
        <Stack gap="0">
          <H6 skin="secondary" fontWeight="medium">
            Listed Price
          </H6>
          {lowestPriceListing ? (
            <Inline alignItems="center" gap=".5rem">
              <CryptoIcon symbol="ETH" size="2rem" />
              <StyledText lineThrough>{formatBalance(lowestPriceListing.price)}</StyledText>
            </Inline>
          ) : (
            <InlineWithHeight gap=".25rem">
              <Image src={SlashImage} width="1.5rem" />
              <H5>Not Listed</H5>
            </InlineWithHeight>
          )}
        </Stack>
      </>
    );
  }, [
    assetInLiquidation,
    buyWithCreditPrice,
    erc20Config.WETH.decimals,
    lowestPriceListing,
    wNativeTokenSymbol,
    disableShopFeature
  ]);

  return (
    <Stack>
      <CollectionName>{collectionName}</CollectionName>
      <CollectionBaseInfoWrapper grow justifyContent="space-between">
        {price}
        <Stack gap="0">
          <H6 skin="secondary" fontWeight="medium">
            Owned By
          </H6>
          <InlineWithHeight gap=".5rem">
            <Image src={OwnerImage} width="1.5rem" />
            {ownedByAddress && addressLink ? (
              <>
                <Link href={addressLink} target="_blank" variant={TypographyVariant.header5}>
                  {truncateTextMid(ownedByAddress)}
                </Link>
                {isOwner && <SmallText skin="secondary">(you)</SmallText>}
              </>
            ) : (
              <H5>-</H5>
            )}
          </InlineWithHeight>
        </Stack>
      </CollectionBaseInfoWrapper>
    </Stack>
  );
};

export const NftBaseInfoSection = memo(NftBaseInfo);
