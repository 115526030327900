import { useCallback } from 'react';
import { findKey } from 'lodash';

import { useAppConfig } from '@/apps/paraspace/hooks';
import { ERC20Symbol } from '@/apps/paraspace/typings';

export const useConvertSymbolWithNetwork = () => {
  const { erc20Config } = useAppConfig();

  const nativeTokenSymbol = findKey(
    erc20Config,
    tokenInfo => tokenInfo.isNativeToken
  ) as ERC20Symbol;
  const wNativeTokenSymbol = findKey(
    erc20Config,
    tokenInfo => tokenInfo.isWrappedNativeToken
  ) as ERC20Symbol;

  const convertNativeTokenToWToken = useCallback(
    (symbol: ERC20Symbol) => {
      if (symbol === nativeTokenSymbol) {
        return wNativeTokenSymbol;
      }

      return symbol;
    },
    [nativeTokenSymbol, wNativeTokenSymbol]
  );

  const convertWTokenToNativeToken = useCallback(
    (symbol: ERC20Symbol) => {
      if (symbol === wNativeTokenSymbol) {
        return nativeTokenSymbol;
      }

      return symbol;
    },
    [nativeTokenSymbol, wNativeTokenSymbol]
  );

  return {
    convertNativeTokenToWToken,
    convertWTokenToNativeToken
  };
};
