import { useMemo, useState, useEffect } from 'react';

import { BendDaoNft } from '../types';

import {
  useLendingSimulation,
  ActionTypeEnum
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC20Symbol,
  ERC721Symbol
} from '@/apps/paraspace/typings';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import {
  SYMBOL_TO_STAKING_INFO_MAP_KEY,
  StakingInfoMap,
  useApeStaking
} from '@/apps/paraspace/pages/hooks/ApeStaking/useApeStaking';
import { useMainToBakcMap } from '@/apps/paraspace/pages/hooks/ApeStaking/useMainToBakcMap';
import { zero } from '@/apps/paraspace/consts/values';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

// TODO refactor this to extract ApeStaking related logic
export const usePredictedBorrowPosition = (
  tokensToTransfer: BendDaoNft[],
  symbol: ERC721Symbol
) => {
  const { erc20InfoMap } = useMMProvider();
  const sAPEInfo = erc20InfoMap[ERC20Symbol.SAPE];

  const showApeStakingInfo = isNFTinvolvedWithApeStaking(symbol);

  const { getStakingInfoByUserAddress } = useApeStaking();
  const [stakingInfoMap, setStakingInfoMap] = useState<StakingInfoMap>({});
  useEffect(() => {
    const loadStakingInfo = async () => {
      const res = await getStakingInfoByUserAddress();

      if (res) {
        setStakingInfoMap(
          res[SYMBOL_TO_STAKING_INFO_MAP_KEY[symbol as ApeStakingTokenSymbol] as keyof typeof res]
        );
      }
    };
    if (showApeStakingInfo) {
      loadStakingInfo();
    }
  }, [getStakingInfoByUserAddress, showApeStakingInfo, symbol]);

  const mainTokens = useMemo(() => {
    return showApeStakingInfo
      ? tokensToTransfer.map(({ tokenId }) => {
          return {
            tokenId,
            symbol: symbol as ApeStakingMainTokenSymbol
          };
        })
      : [];
  }, [tokensToTransfer, showApeStakingInfo, symbol]);

  const [mainToBakcMap] = useMainToBakcMap(mainTokens);

  const bakcStakingInfo = useMemo(() => {
    return showApeStakingInfo ? mainToBakcMap?.[symbol] || {} : {};
  }, [mainToBakcMap, showApeStakingInfo, symbol]);

  return useLendingSimulation(
    tokensToTransfer
      .map(it => ({
        type: ActionTypeEnum.SUPPLY,
        targetAsset: {
          id: String(it.tokenId),
          value: it.priceInUsd.times(it.multiplier),
          LTV: it.ltv,
          reserveLiquidationThreshold: it.reserveLiquidationThreshold
        }
      }))
      .concat(
        tokensToTransfer.map(it => ({
          type: ActionTypeEnum.BORROW,
          targetAsset: {
            id: String(it.tokenId),
            value: it.debtInUsd,
            LTV: it.ltv,
            reserveLiquidationThreshold: it.reserveLiquidationThreshold
          }
        }))
      )
      .concat(
        showApeStakingInfo && symbol !== ERC721Symbol.BAKC
          ? tokensToTransfer.map(({ tokenId }) => {
              const mainStakingAndRewards =
                stakingInfoMap[tokenId]?.stakedAmount.plus(
                  stakingInfoMap[tokenId].pendingRewards
                ) || zero;
              // bakc staking part
              const bakcStaking = bakcStakingInfo[tokenId]?.stakedAmount || zero;
              return {
                type: ActionTypeEnum.SUPPLY,
                targetAsset: {
                  id: `${ERC20Symbol.SAPE}`,
                  value: sAPEInfo.priceInUsd.times(mainStakingAndRewards.plus(bakcStaking)),
                  LTV: sAPEInfo.baseLTVasCollateral,
                  reserveLiquidationThreshold: sAPEInfo.reserveLiquidationThreshold
                }
              };
            })
          : []
      )
  );
};
