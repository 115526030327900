import { ComponentType, ReactNode, memo } from 'react';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createSwitchableProvider<P extends { children: ReactNode }>(
  Provider: ComponentType<P>
) {
  return memo(({ children, enable, ...others }: Omit<P, 'enable'> & { enable: boolean }) => {
    if (!enable) {
      return <>{children}</>;
    }
    // TODO fix typing with others as P
    return <Provider {...(others as unknown as P)}>{children}</Provider>;
  });
}
