import { Card, H5, H6, Inline, SmallText, Stack } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import numbro from 'numbro';

import { GraphDataPoint } from '.';

type Props = {
  payload: {
    payload: GraphDataPoint;
  }[];
};
export const renderTooltipContent = (props: Props) => {
  const { payload } = props.payload?.[0] || {};
  const weekLabel =
    payload?.period === 0
      ? 'Today'
      : `${numbro(payload?.period).format({ output: 'ordinal' })} week`;
  return (
    <Card inset="1.5rem" shadow="secondary" border>
      <Stack width="13rem" gap="1rem">
        <Inline justifyContent="space-between" alignItems="baseline">
          <H6>Projected</H6>
          <SmallText skin="secondary">{weekLabel}</SmallText>
        </Inline>
        <Stack width="13rem" gap="0.5em">
          <Inline justifyContent="space-between" alignItems="baseline">
            <SmallText skin="secondary">Daily Profit</SmallText>
            <Inline gap="0.25rem">
              <H5>{formatNumber(payload?.dailyProfit ?? 0)}</H5>
              <H5 skin="secondary">APE</H5>
            </Inline>
          </Inline>
          <Inline justifyContent="space-between" alignItems="baseline">
            <H6 skin="secondary" fontWeight="regular">
              - Daily Reward
            </H6>
            <Inline gap="0.25rem">
              <H6>{formatNumber(payload?.dailyReward ?? 0)}</H6>
              <H6 skin="secondary">APE</H6>
            </Inline>
          </Inline>
          <Inline justifyContent="space-between" alignItems="baseline">
            <H6 skin="secondary" fontWeight="regular">
              - Daily Interest
            </H6>
            <Inline gap="0.25rem">
              <H6>{formatNumber(payload?.dailyInterest ?? 0)}</H6>
              <H6 skin="secondary">APE</H6>
            </Inline>
          </Inline>
        </Stack>
      </Stack>
    </Card>
  );
};
