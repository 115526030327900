import { ReactNode, createContext, memo, useContext, useMemo } from 'react';

import {
  useBorrowNativeTokenDerivatives,
  useWithdrawERC721,
  useSupplyNft,
  useSupplyERC20,
  useBorrowERC20,
  useWithdrawERC20
} from '../hooks';
import {
  BorrowNativeTokenDerivativesModal,
  WithdrawERC721Modal,
  NFTSupplyModal,
  SupplyERC20Modal,
  BorrowERC20Modal,
  WithdrawERC20Modal,
  SupplyType
} from '../modals';
import { BendDaoMoverProvider, useSupplyNftFromBendDao } from '../BendDaoMoverProvider';
import { useMMProvider } from '../../../contexts/MMProvider';
import { useTokensInfo } from '../../../contexts/TokensInfoProvider';

import { ERC20Symbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';

type MoneyMarketActionsContextValue = {
  borrowNativeTokenDerivatives: (derivative: ERC20Symbol) => Promise<void>;
  withdrawERC721: (symbol: ERC721Symbol, name: string) => Promise<void>;
  supplyNft: (symbol: ERC721Symbol, collectionName: string, wallet: WalletType) => Promise<void>;
  supplyERC20: (symbol: ERC20Symbol, type: SupplyType) => Promise<void>;
  borrowERC20: (symbol: ERC20Symbol) => Promise<void>;
  withdrawERC20: (symbol: ERC20Symbol) => Promise<void>;
};

const MoneyMarketActionsContext = createContext<MoneyMarketActionsContextValue>({
  borrowNativeTokenDerivatives: () => {
    throw new Error('Not implemented yet');
  },
  withdrawERC721: () => {
    throw new Error('Not implemented yet');
  },
  supplyNft: () => {
    throw new Error('Not implemented yet');
  },
  supplyERC20: () => {
    throw new Error('Not implemented yet');
  },
  borrowERC20: () => {
    throw new Error('Not implemented yet');
  },
  withdrawERC20: () => {
    throw new Error('Not implemented yet');
  }
});

export const MoneyMarketActionsProvider = memo(({ children }: { children: ReactNode }) => {
  const [borrowNativeTokenDerivativesModalProps, borrowNativeTokenDerivatives] =
    useBorrowNativeTokenDerivatives();
  const [withdrawERC721ModalProps, withdrawERC721] = useWithdrawERC721();
  const [supplyNftModalProps, supplyNft] = useSupplyNft();
  const [supplyERC20ModalProps, supplyERC20] = useSupplyERC20();
  const [borrowERC20ModalProps, borrowERC20] = useBorrowERC20();
  const [withdrawERC20ModalProps, withdrawERC20] = useWithdrawERC20();
  const { refetch: pollTokensInfo } = useTokensInfo();
  const { features } = useAppConfig();
  const { loadUserERC20Position, loadUserERC721Positions, loadERC20Balance, loadERC721Balance } =
    useMMProvider();

  const contextValue = useMemo(
    () => ({
      borrowNativeTokenDerivatives: (derivative: ERC20Symbol) =>
        borrowNativeTokenDerivatives(derivative).then(loadUserERC20Position),
      withdrawERC721: (symbol: ERC721Symbol, name: string) =>
        withdrawERC721(symbol, name).then(loadUserERC721Positions),
      supplyNft: (symbol: ERC721Symbol, collectionName: string, walletType: WalletType) =>
        supplyNft(symbol, collectionName, walletType).then(() => {
          loadUserERC721Positions();
          loadERC721Balance();
        }),
      supplyERC20: (symbol: ERC20Symbol, type: SupplyType) =>
        supplyERC20(symbol, type).then(() => {
          loadERC20Balance();
          loadUserERC20Position();
          pollTokensInfo();
        }),
      borrowERC20: (symbol: ERC20Symbol) => borrowERC20(symbol).then(loadUserERC20Position),
      withdrawERC20: (symbol: ERC20Symbol) => withdrawERC20(symbol).then(loadUserERC20Position)
    }),
    [
      borrowNativeTokenDerivatives,
      withdrawERC721,
      supplyNft,
      supplyERC20,
      borrowERC20,
      withdrawERC20,
      loadUserERC20Position,
      loadUserERC721Positions,
      loadERC20Balance,
      loadERC721Balance,
      pollTokensInfo
    ]
  );

  return (
    <MoneyMarketActionsContext.Provider value={contextValue}>
      <BorrowNativeTokenDerivativesModal
        key={borrowNativeTokenDerivativesModalProps.defaultDerivative}
        {...borrowNativeTokenDerivativesModalProps}
      />
      <SupplyERC20Modal
        key={`SupplyERC20Modal-${supplyERC20ModalProps.defaultSymbol}`}
        {...supplyERC20ModalProps}
      />
      <WithdrawERC721Modal {...withdrawERC721ModalProps} />
      <NFTSupplyModal {...supplyNftModalProps} />
      <BorrowERC20Modal
        key={`BorrowERC20Modal-${borrowERC20ModalProps.symbol}`}
        {...borrowERC20ModalProps}
      />
      <WithdrawERC20Modal
        key={`WithdrawERC20Modal-${withdrawERC20ModalProps.symbol}`}
        {...withdrawERC20ModalProps}
      />
      {features.includes(Feature.GetCreditFromBlueChips) &&
      features.includes(Feature.BendaoMover) ? (
        <BendDaoMoverProvider>{children}</BendDaoMoverProvider>
      ) : (
        children
      )}
    </MoneyMarketActionsContext.Provider>
  );
});

export const useMoneyMarketActions = () => {
  const {
    borrowNativeTokenDerivatives,
    withdrawERC721,
    supplyNft,
    supplyERC20,
    borrowERC20,
    withdrawERC20
  } = useContext(MoneyMarketActionsContext);
  const supplyNftFromBendDao = useSupplyNftFromBendDao();

  return useMemo(
    () => ({
      withdrawERC20,
      borrowNativeTokenDerivatives,
      withdrawERC721,
      supplyNft,
      supplyNftFromBendDao,
      supplyERC20,
      borrowERC20
    }),
    [
      borrowNativeTokenDerivatives,
      withdrawERC721,
      supplyNft,
      supplyNftFromBendDao,
      supplyERC20,
      borrowERC20,
      withdrawERC20
    ]
  );
};
