import { useMemo } from 'react';
import { utils } from 'ethers';

import { Network } from '../../config';
import { useControlledLocalStorage } from '../../hooks';
import { Maybe } from '../../typings/basic';

import { getAAAccountsCacheKey } from './utils';

export const useCachedAAAccounts = (
  chainId: Network,
  eoaAccount: string
): [string[], (v: Maybe<string[]>) => void] => {
  const cacheKey = useMemo(() => getAAAccountsCacheKey(chainId, eoaAccount), [chainId, eoaAccount]);

  const [cachedAccounts, updateValue] = useControlledLocalStorage<string[]>(cacheKey);

  const validAccounts = useMemo(
    () => cachedAccounts?.filter(it => utils.isAddress(it)) ?? [],
    [cachedAccounts]
  );

  return [validAccounts, updateValue];
};
