import { useCallback, useMemo } from 'react';
import { MoonBirdsService } from 'paraspace-utilities-contract-helpers';

import { useMMProvider } from '../contexts/MMProvider';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useContractsMap } from '@/apps/paraspace/hooks';

const useMoonBirds = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();
  const { nftInfoMap } = useMMProvider();
  const { xTokenAddress } = nftInfoMap[ERC721Symbol.MOONBIRD] ?? {};

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new MoonBirdsService(provider, contracts.MOONBIRD);
  }, [contracts, provider]);

  const supplyMoonBirds = useCallback(
    async (tokenIds: number[]) => {
      if (!provider || !service) {
        return null;
      }

      const txs = service.safeTransferWhileNesting({
        from: account,
        to: xTokenAddress,
        tokenIds
      });
      return txs;
    },
    [provider, service, xTokenAddress, account]
  );

  const safeTransferWhileNesting = useCallback(
    async (from: string, to: string, tokenId: number) => {
      const tx = {
        to: contracts.MOONBIRD,
        data: service
          ?.getContractInstance(contracts.MOONBIRD)
          .interface.encodeFunctionData('safeTransferWhileNesting', [from, to, tokenId]),
        value: '0'
      };

      return tx;
    },
    [contracts.MOONBIRD, service]
  );

  return { supplyMoonBirds, safeTransferWhileNesting };
};

export default useMoonBirds;
