import { useCallback, useMemo } from 'react';
import { ethers } from 'ethers';
import { ERC20Service } from 'paraspace-utilities-contract-helpers';
import BigNumberJs from 'bignumber.js';

import { useEOAProvider } from '@/apps/parax/contexts';

const MAX_ALLOWANCE = ethers.constants.MaxUint256.toString();

export const useERC20 = (assetAddr: string) => {
  const { provider, account, submitTransaction } = useEOAProvider();
  const service = useMemo(() => {
    if (!provider || !assetAddr) {
      return null;
    }

    return new ERC20Service(provider);
  }, [assetAddr, provider]);

  const approve = useCallback(
    async (spender: string, amount: string = MAX_ALLOWANCE) => {
      if (!provider || !service) {
        return null;
      }

      const normalizedAmount = new BigNumberJs(amount).gt(MAX_ALLOWANCE) ? MAX_ALLOWANCE : amount;
      const tx = await service.approve({
        user: account,
        spender,
        token: assetAddr,
        amount: normalizedAmount
      });
      const txRes = submitTransaction(tx);
      return txRes;
    },
    [provider, service, account, assetAddr, submitTransaction]
  );

  const getAllowance = useCallback(
    async (spender: string, user = account) => {
      if (!service) {
        return '0';
      }
      return service.getAllowance({
        user,
        token: assetAddr,
        spender
      });
    },
    [assetAddr, account, service]
  );

  const getBalance = useCallback(
    async (user: string = account, token: string = assetAddr) => {
      if (!service) {
        return new BigNumberJs(0);
      }
      return new BigNumberJs((await service.balanceOf({ user, token })).toString());
    },
    [assetAddr, account, service]
  );

  return { approve, getAllowance, getBalance };
};
