import {
  Inline,
  Stack,
  H5,
  H2,
  Icon,
  Button,
  H6,
  CircularProgressBar,
  Image
} from '@parallel-mono/components';
import styled, { useTheme } from 'styled-components';
import { useContext } from 'react';

import StrawImg from '../image/straw.svg';
import GoldImg from '../image/gold.svg';
import BronzeImg from '../image/bronze.svg';
import SilverImg from '../image/silver.svg';

import { CalculatedNestingData, LEVELS } from './useMoonBirdsData';

import { Context } from '.';

import { formatSecondsWithRound } from '@/apps/paraspace/utils/format';

const StyledCircularProgressBar = styled(CircularProgressBar)`
  width: 11.625rem;
  max-height: 11.625rem;
`;

const StyledImage = styled(Image)`
  width: 100%;
  padding: 0.25rem;
  border-radius: 50%;
  background: black;
`;

const InlineWrapper = styled(Inline)`
  height: 3rem;
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.skin.primary.contrastBackground};
  color: ${({ theme }) => theme.skin.primary.secondaryContrastText};
  border-radius: 6.25rem;
`;

export const Line = styled.p`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.skin.grey[200]};
`;

export const levelImages = {
  Straw: StrawImg,
  Bronze: BronzeImg,
  Silver: SilverImg,
  Gold: GoldImg,
  // FIXME: replace me with the right Diamond image
  Diamond: '💎'
};

interface IProps {
  data: CalculatedNestingData;
  handleUnNestClick?: () => void;
  showUnNest?: boolean;
}

const NestingInProgress = ({ data, handleUnNestClick, showUnNest }: IProps) => {
  const { nestingStreak, totalTime, level, daysToNextLevel, progress } = data;
  const { isOwner } = useContext(Context);
  const theme = useTheme();
  return (
    <Stack gap="1.5rem">
      <Inline gap="2.5rem" alignItems="center">
        <StyledCircularProgressBar color="#7ad383" background="#232337" value={progress * 100}>
          <StyledImage src={levelImages[LEVELS[level] as keyof typeof levelImages]} />
        </StyledCircularProgressBar>
        <Stack gap="1rem">
          <Stack gap=".25rem">
            <H5 skin="secondary">NEST LEVEL</H5>
            <H2>{LEVELS[level]} Nest</H2>
            <Inline gap=".25rem">
              <H5 skin="secondary">🔥 Nesting Streak:</H5>
              <H5>{nestingStreak}</H5>
            </Inline>
          </Stack>
          <InlineWrapper gap=".25rem" alignItems="center">
            <Icon name="clock" size="large" color={theme.skin.grey[700]} />
            <H5>{LEVELS[level + 1]} Nest in:</H5>
            <H5 skin="primary" fontWeight="bold">
              {daysToNextLevel} days
            </H5>
          </InlineWrapper>
          {showUnNest && (
            <Button variant="link" onClick={handleUnNestClick} disabled={!isOwner}>
              Un-nest
            </Button>
          )}
        </Stack>
      </Inline>
      <Line />
      <Stack gap="0.25rem">
        <H6 skin="secondary" fontWeight="medium">
          Total Lifetime Nesting Time
        </H6>
        <H5>{formatSecondsWithRound(totalTime)}</H5>
      </Stack>
    </Stack>
  );
};

export default NestingInProgress;
