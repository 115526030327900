import { HelperContractService } from 'paraspace-utilities-contract-helpers';
import { useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { ERC20_CONFIG_FOR_NETWORK_MAP } from '@/apps/parax/config';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useContractsMap } from '@/apps/paraspace/hooks';
import { shiftedRightBy } from '@/apps/paraspace/utils/calculations';

export const useV1cAPEConvert = () => {
  const { provider, chainId, submitEOATransactions } = useWeb3Context();
  const contracts = useContractsMap();

  const ERC20Config = useMemo(() => ERC20_CONFIG_FOR_NETWORK_MAP[chainId], [chainId]);

  const helperService = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new HelperContractService(provider, contracts.HelperContract);
  }, [contracts.HelperContract, provider]);

  const convertCAPEFromV1 = useCallback(
    async (amount: BigNumber, from: string, to: string) => {
      if (!helperService || !ERC20Config) {
        return null;
      }
      const { decimals } = ERC20Config.CAPE;
      const amountStr = shiftedRightBy(amount, decimals).toString(10);
      const tx = await helperService.cApeMigration(amountStr, from, to);
      return submitEOATransactions(tx);
    },
    [ERC20Config, helperService, submitEOATransactions]
  );

  return convertCAPEFromV1;
};
