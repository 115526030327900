import { FC, memo, useMemo } from 'react';
import { Image, Text } from '@parallel-mono/components';
import { HostedImage } from '@parallel-mono/business-components';

import { useMMProvider } from '../../contexts/MMProvider';
import { useEOABalances } from '../../contexts';

import { DropdownMenu, DropdownMenuProps } from '@/apps/paraspace/components';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { formatBalance } from '@/apps/paraspace/utils/format';

type OptionItem = { onClick: () => void };
type Props = {
  symbol: ERC20Symbol;
  eoaWalletOption: OptionItem;
  aaWalletOption: OptionItem;
};
export const SupplyERC20DropdownMenu: FC<Props & Omit<DropdownMenuProps, 'options'>> = memo(
  ({
    symbol,
    eoaWalletOption,
    aaWalletOption,
    menuTrigger,
    placement = 'bottom-end',
    ...others
  }) => {
    const {
      authentication: { meta }
    } = useWeb3Context();
    const { erc20InfoMap } = useMMProvider();
    const { erc20BalanceMap: EOABalanceMap } = useEOABalances();

    const options = useMemo(
      () =>
        [
          {
            icon: (
              <HostedImage
                name="design/PDS_V3/logo/parallel-v2-logo"
                height="1.25rem"
                width="1.25rem"
              />
            ),
            label: <Text>AA Wallet</Text>,
            onClick: (e: any) => {
              e.stopPropagation();
              aaWalletOption.onClick();
            },
            value: formatBalance(erc20InfoMap[symbol].balance ?? 0)
          },
          {
            icon: <Image src={meta.walletIcon!} width="1.25rem" height="1.25rem" />,
            label: <Text>{meta.walletType}</Text>,
            onClick: (e: any) => {
              e.stopPropagation();
              eoaWalletOption.onClick();
            },
            value: formatBalance(EOABalanceMap?.[symbol] ?? 0)
          }
        ].filter(notEmpty),
      [
        erc20InfoMap,
        symbol,
        meta.walletIcon,
        meta.walletType,
        EOABalanceMap,
        aaWalletOption,
        eoaWalletOption
      ]
    );

    return (
      <DropdownMenu
        placement={placement}
        title="Supply From"
        options={options}
        menuTrigger={menuTrigger}
        {...others}
      />
    );
  }
);
