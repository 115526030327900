import { useState, useCallback } from 'react';

import { JoinListingWithApeCoinModalProps } from '../JoinListingWithApeCoinModal';
import { ApeListing } from '../../../types';
import { initialApeListing } from '../../../consts';

import { WalletType } from '@/apps/paraspace/typings';

const defaultJoinListingWithApeCoinModalProps: JoinListingWithApeCoinModalProps = {
  isOpen: false,
  apeListing: initialApeListing,
  walletType: 'AA'
};

export const useJoinListingWithApeCoin = (): [
  JoinListingWithApeCoinModalProps,
  (apeListing: ApeListing, walletType: WalletType) => Promise<void>
] => {
  const [joinListingWithApeCoinModalProps, setJoinListingWithApeCoinModalProps] =
    useState<JoinListingWithApeCoinModalProps>(defaultJoinListingWithApeCoinModalProps);

  const joinStaking = useCallback((apeListing: ApeListing, walletType: WalletType) => {
    return new Promise<void>((resolve, reject) => {
      setJoinListingWithApeCoinModalProps(curr => ({
        ...curr,
        isOpen: true,
        apeListing,
        walletType,
        onClose: () => {
          setJoinListingWithApeCoinModalProps(prev => ({
            ...prev,
            isOpen: false
          }));
          reject();
        },
        onFinish: () => {
          resolve();
        },
        onError: () => {
          reject();
        }
      }));
    });
  }, []);

  return [joinListingWithApeCoinModalProps, joinStaking];
};
