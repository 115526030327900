import { memo, useCallback, useMemo, useState } from 'react';
import { Stack, StackProps } from '@parallel-mono/components';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '@parallel-mono/utils';
import { CryptoIcon } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';

import { WithdrawSteppers } from './WithdrawSteppers';

import { ErrorState, SuccessState, TimelockResultDesc } from '@/apps/paraspace/components';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';

export type WithdrawModalProps = Omit<StackProps, 'children'> & {
  refresh: () => void;
  reset: () => void;
  onClose: () => void;
  data: { lendingAmount: number; balanceAmount: number; shouldClaimToEOA: boolean };
};

enum WithdrawStatus {
  withdrawing = 0,
  success = 1,
  failed = 2
}

export const WithdrawModalContent = memo(
  ({
    refresh,
    reset,
    onClose,
    data: { lendingAmount, balanceAmount, shouldClaimToEOA },
    ...others
  }: WithdrawModalProps) => {
    const [status, setStatus] = useState<WithdrawStatus>(WithdrawStatus.withdrawing);
    const [claimed, setClaimed] = useState(false);
    const navigate = useNavigate();

    const handleSuccess = useCallback(
      instantlyClaimed => {
        setClaimed(instantlyClaimed);
        setStatus(WithdrawStatus.success);
        ReactGA.event({
          action: 'apestaking',
          category: 'cape',
          label: 'withdraw',
          value: BigNumber(lendingAmount).plus(balanceAmount).toNumber()
        });
        refresh();
      },
      [balanceAmount, lendingAmount, refresh]
    );

    const handleSuccessModalClose = useCallback(() => {
      reset();
      onClose();
      if (!claimed) {
        navigate(absoluteRouteNames.index);
      }
    }, [claimed, navigate, onClose, reset]);

    const handleFailed = useCallback(() => {
      setStatus(WithdrawStatus.failed);
      refresh();
    }, [refresh]);

    const formData = useMemo(
      () => ({
        lendingAmount: BigNumber(lendingAmount),
        balanceAmount: BigNumber(balanceAmount),
        shouldClaimToEOA
      }),
      [balanceAmount, lendingAmount, shouldClaimToEOA]
    );

    return (
      <Stack {...others} alignItems="center">
        {status === WithdrawStatus.withdrawing && (
          <WithdrawSteppers data={formData} onFinish={handleSuccess} onError={handleFailed} />
        )}
        {status === WithdrawStatus.failed && <ErrorState width="100%" closeModal={onClose} />}
        {status === WithdrawStatus.success && (
          <SuccessState
            width="100%"
            icon={<CryptoIcon symbol="APE" size="5rem" />}
            desc="Withdrawn Successfully"
            tip={
              <>
                You have withdrawn{' '}
                {formatNumber(BigNumber(balanceAmount).plus(lendingAmount), {
                  decimal: MAXIMUM_BALANCE_DECIMALS
                })}{' '}
                ApeCoin. {!claimed && <TimelockResultDesc />}
              </>
            }
            actionButtonText={claimed ? 'Done' : 'View Queue'}
            onAction={handleSuccessModalClose}
          />
        )}
      </Stack>
    );
  }
);
