import { memo } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { BuyCartAsset, Payment } from '../../types';

import { CheckoutForm } from './CheckoutForm';
import { CheckoutStepperSubmitter } from './CheckoutStepperSubmitter';
import { SuccessState } from './SuccessState';

import {
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure,
  ErrorState
} from '@/apps/paraspace/components';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type CheckoutModalProps = Omit<ModalProps, 'children'> & {
  assets: BuyCartAsset[];
  onError?: (err?: Maybe<ErrorConfig>) => void;
  onFinish?: () => void;
};

export const CheckoutModal = memo(
  ({ assets, isOpen, onClose, onError, onFinish, ...others }: CheckoutModalProps) => {
    const { phase, handleFormSubmit, submittedFormData, handleSubmitFailed, handleSubmitSuccess } =
      useCollectAndSubmitProcedure<{
        assets: BuyCartAsset[];
        payNow: Payment;
        payLater: Payment;
      }>({
        running: isOpen,
        onError,
        onFinish
      });

    return (
      <Modal title="Check Out" isOpen={isOpen} onClose={onClose} {...others}>
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <CheckoutForm onSubmit={handleFormSubmit} assets={assets} />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <CheckoutStepperSubmitter
            formData={submittedFormData!}
            onError={handleSubmitFailed}
            onFinish={handleSubmitSuccess}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose!} />}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState assets={assets} onClose={onClose} />
        )}
      </Modal>
    );
  }
);
