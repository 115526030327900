import { FC, useCallback } from 'react';
import { MutationHookOptions } from '@apollo/client';

import {
  FulfillOrderMutation,
  FulfillOrderMutationVariables,
  Order,
  useFulfillOrderMutation
} from '@/apps/paraspace/generated/graphql';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useNftActualOwners } from '@/apps/paraspace/pages/hooks/useAcutualOwners';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { FormSubmitter } from '@/apps/paraspace/components';

type Props = {
  onFinish: () => void;
  onError: () => void;
  mutationOptions: MutationHookOptions<FulfillOrderMutation, FulfillOrderMutationVariables>;
  order: Order;
  identifierOrCriteria?: string;
  symbol: ERC721Symbol;
};

export const FulfillOrderSubmitter: FC<Props> = ({
  onFinish,
  onError,
  mutationOptions,
  order,
  symbol,
  identifierOrCriteria
}) => {
  const [fulfillOrderMutation] = useFulfillOrderMutation({
    ...mutationOptions
  });

  const { account } = useWeb3Context();
  const { getNftActualOwners } = useNftActualOwners();

  const { hash } = order;
  const newOwner = order.protocolData?.parameters?.consideration?.[0]?.recipient;
  const tokenId =
    identifierOrCriteria?.toString() ?? order?.considerationItems?.[0]?.identifierOrCriteria!;

  const handleSubmit = useCallback(async () => {
    try {
      await fulfillOrderMutation({
        variables: {
          hash,
          newOwner,
          identifierOrCriteria: String(tokenId!)
        }
      });
    } catch (e: any) {
      if (e.message.includes('allowance')) {
        throw e;
      }
      // 1. pool liquidity checked

      // 2. bought by someone
      const realOwners = await getNftActualOwners([{ symbol, tokenId }]);

      if (!realOwners.includes(account.toLowerCase())) {
        throw getUserFriendlyError(
          e?.error ?? e,
          'The NFT(s) has just been sold. Please check your wallet.'
        );
      }
      // 3. my's HF checked

      // 4. offerer's HF
      throw getUserFriendlyError(
        e?.error ?? e,
        `This offer is no longer valid. Please accept another offer.`
      );
    }
  }, [account, fulfillOrderMutation, getNftActualOwners, hash, newOwner, symbol, tokenId]);

  return <FormSubmitter submit={handleSubmit} onError={onError} onFinish={onFinish} />;
};
