import BigNumberJs from 'bignumber.js';

import useCreditBalance from '../../hooks/useCreditBalance';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';

export const useCreditPrice = ({
  symbol,
  price = zero,
  multiplier
}: {
  symbol: string;
  price?: BigNumberJs;
  multiplier: BigNumberJs;
}) => {
  const { erc20InfoMap } = useMMProvider();
  const { priceInUsd } = erc20InfoMap[ERC20Symbol.WETH] || {};
  const { futureAvailableCredit } = useCreditBalance({
    symbol,
    creditBasedCurrencyValueInUsd: priceInUsd,
    multiplier
  });
  return BigNumberJs.max(price.minus(futureAvailableCredit), zero);
};
