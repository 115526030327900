import { Stack, H3, Text, Button, Inline } from '@parallel-mono/components';
import { FC, memo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useUpdateEffect } from 'react-use';

import { CustomError } from '../ErrorBoundary/useErrorHandler';
import { LogoIcon } from '../Logo';

import { ReactComponent as NotFoundImage } from '@/apps/paraspace/assets/not-found.svg';

const ErrorViewWrapper = styled(Stack)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 5rem));
`;

const Logo = styled(LogoIcon)`
  position: absolute;
  top: 2rem;
  left: 5rem;
`;

type Props = {
  resetError: () => void;
  error?: CustomError | Error;
};

const RefreshButton = styled(Inline)`
  cursor: pointer;
`;

export const UnknownError: FC<Props> = ({ resetError, error: _error }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useUpdateEffect(() => {
    resetError();
  }, [location.pathname]);

  const goBackHomePage = useCallback(() => {
    resetError();
    navigate('/');
  }, [navigate, resetError]);

  const refresh = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <>
      <Logo />
      <ErrorViewWrapper alignItems="center" gap="2rem">
        <NotFoundImage width="20rem" />
        <Stack alignItems="center" gap="0">
          <H3>We Are Sorry, Something Isn’t Right.</H3>
          <Text>Please try again later.</Text>
        </Stack>
        <Button onClick={goBackHomePage} size="large">
          Back to Homepage
        </Button>
        <RefreshButton onClick={refresh}>Refresh</RefreshButton>
      </ErrorViewWrapper>
    </>
  );
};

export default memo(UnknownError);
