import { memo, useCallback } from 'react';

import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { Maybe } from '@/apps/paraspace/typings/basic';
import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { FormSubmitter } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type MovePositionFromBendDAOSubmitterProps = {
  formData: string[];
  onFinish: () => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const MovePositionFromBendDAOSubmitter = memo(
  ({ formData: loanIds, onFinish, onError }: MovePositionFromBendDAOSubmitterProps) => {
    const { movePositionFromBendDAO } = usePool();
    const {
      account,
      authentication: {
        meta: { account: EOAAccount }
      }
    } = useWeb3Context();

    const submit = useCallback(
      () => movePositionFromBendDAO(loanIds, EOAAccount, account),
      [movePositionFromBendDAO, loanIds, EOAAccount, account]
    );

    return (
      <FormSubmitter
        inProgressMessage={`Transferring ${loanIds.length} NFT from BendDAO to Paraspace`}
        submit={submit}
        onFinish={onFinish}
        onError={onError}
      />
    );
  }
);
