import { useCallback, useMemo, useState } from 'react';

import { Filter } from '../types';

import { Marketplace, ShopItemSort } from '@/apps/paraspace/generated/graphql';

const defaultFilter: Filter = {
  tokenId: null,
  priceRange: {
    min: null,
    max: null
  },
  marketplace: Marketplace.OpenSea,
  inLiquidation: false
};

export const useItemsQueryFilter = (contractAddress: string) => {
  const [sort, setSort] = useState<ShopItemSort>(ShopItemSort.PriceAsc);
  const [filter, setFilter] = useState<Filter>(defaultFilter);

  const handleSortChange = useCallback((value: ShopItemSort) => {
    setSort(value);
  }, []);

  const queryParams = useMemo(() => {
    return {
      contractAddress,
      marketplace: filter.marketplace,
      filter: {
        identifierOrCriteria: filter.tokenId,
        minPrice: filter.priceRange.min,
        maxPrice: filter.priceRange.max,
        sort
      }
    };
  }, [
    contractAddress,
    filter.marketplace,
    filter.priceRange.max,
    filter.priceRange.min,
    filter.tokenId,
    sort
  ]);

  return { sort, handleSortChange, filter, handleFilterChange: setFilter, queryParams };
};
