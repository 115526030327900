import { useCallback, useState } from 'react';
import { BigNumber as EthersBigNumber } from 'ethers';

import { useAAAccountFactory } from './useAAAccountFactory';

import { useAAProvider, useEOAProvider, useParaXToast } from '@/apps/parax/contexts';
import { getUserFriendlyError } from '@/apps/parax/utils';

export const useCreateParaAccount = () => {
  const [loading, setLoading] = useState(false);

  const parallelToast = useParaXToast();
  const { createParaAccount, factory } = useAAAccountFactory();
  const { account: EOAAccount } = useEOAProvider();
  const { mockAccount, switchAccount } = useAAProvider();

  const createParaAccountHandle = useCallback(async () => {
    if (factory === null) {
      throw new Error('Factory not ready');
    }
    setLoading(true);
    const submitPromise = createParaAccount()
      .then(async tx => {
        const result = await tx?.wait();

        const aaCreatedLog =
          result?.logs.filter(
            (log: any) => log.address.toLowerCase() === factory.instance.address.toLowerCase()
          )[0] ?? null;

        const accountCreatedEventData = aaCreatedLog
          ? factory.instance.interface.decodeEventLog('AccountCreated', aaCreatedLog.data)
          : null;
        const createdAccount = accountCreatedEventData?.accountAddress ?? '';
        if (createdAccount) {
          mockAccount({
            EOAAccount,
            paraAccount: createdAccount,
            createdBlockHeight: result?.blockNumber ?? 0,
            createdHash: result?.blockHash ?? '',
            salt: (accountCreatedEventData!.salt as EthersBigNumber).toString()
          });
          switchAccount(createdAccount);
        }
        return createdAccount as string;
      })
      .catch(err => {
        throw getUserFriendlyError(err);
      })
      .finally(() => setLoading(false));
    return parallelToast.promise(submitPromise);
  }, [createParaAccount, parallelToast, mockAccount, EOAAccount, factory, switchAccount]);

  return {
    loading,
    createParaAccountHandle
  };
};
