import { useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { filter, find, mapKeys } from 'lodash';

import { StakeBakcFormData } from '../../../types';

import { validate } from './validate';
import { useApeTokenSelector } from './useApeTokenSelector';
import { useBalanceAndCreditAmount } from './useBalanceAndCreditAmount';
import { useNetApy } from './useNetApy';

import { ApeListItem } from '@/apps/paraspace/pages/ApePairing/contexts/ApeListProvider';
import { ApeStakingMainTokenSymbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/OfficialPairing/ApeStakingManagerProvider/types';
import { useCheckV1TimelockStatus } from '@/apps/paraspace/pages/Credit';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';

const stakeLimit = new BigNumber(STAKE_LIMIT.BAKC);

export const useStatesAndHandlers = ({
  onSubmit,
  bakcTokenId,
  apesAndBakc,
  apeCoinSource,
  bakcSource
}: {
  onSubmit: (formData: StakeBakcFormData) => void;
  bakcTokenId: number;
  apesAndBakc: ApeListItem[];
  apeCoinSource: WalletType;
  bakcSource: Maybe<WalletType>;
}) => {
  const bakcToken = useMemo(
    () => find(apesAndBakc, i => i.symbol === ERC721Symbol.BAKC && i.tokenId === bakcTokenId),
    [apesAndBakc, bakcTokenId]
  );

  const { nftInfoMap } = useMMProvider();

  const { isLoading: isLoadingBaycV1TimelockStatus, tokensStatus: baycV1TimelockStatus } =
    useCheckV1TimelockStatus(
      nftInfoMap[ERC721Symbol.BAYC].address,
      nftInfoMap[ERC721Symbol.BAYC].nftSuppliedList ?? []
    );
  const baycV1TimelockStatusMap = useMemo(
    () =>
      mapKeys(
        baycV1TimelockStatus?.filter(it => !it.isClaimed),
        it => `${ERC721Symbol.BAYC}_${it.tokenId}`
      ),
    [baycV1TimelockStatus]
  );

  const { isLoading: isLoadingMaycV1TimelockStatus, tokensStatus: maycV1TimelockStatus } =
    useCheckV1TimelockStatus(
      nftInfoMap[ERC721Symbol.MAYC].address,
      nftInfoMap[ERC721Symbol.MAYC].nftSuppliedList ?? []
    );
  const maycV1TimelockStatusMap = useMemo(
    () =>
      mapKeys(
        maycV1TimelockStatus?.filter(it => !it.isClaimed),
        it => `${ERC721Symbol.MAYC}_${it.tokenId}`
      ),
    [maycV1TimelockStatus]
  );

  const tokenV1TimelockStatusMap = useMemo(
    () => ({
      ...baycV1TimelockStatusMap,
      ...maycV1TimelockStatusMap
    }),
    [baycV1TimelockStatusMap, maycV1TimelockStatusMap]
  );

  const apeTokens = useMemo(
    () =>
      filter(apesAndBakc, i => i.symbol !== ERC721Symbol.BAKC && !i.pairedBakc).map(it => {
        const v1TimelockInfo = tokenV1TimelockStatusMap[`${it.symbol}_${it.tokenId}`];
        return {
          ...it,
          disabled: !!v1TimelockInfo,
          disabledTip: `This ${it.symbol} is still in the timelock of ParaSpace V1, please ${
            !v1TimelockInfo?.isReleaseTimeReached
              ? `try again after ${v1TimelockInfo?.expectedRelease!}.`
              : 'wait patiently.'
          } `
        };
      }),
    [apesAndBakc, tokenV1TimelockStatusMap]
  );

  const defaultApeToken = useMemo(
    () =>
      apesAndBakc.find(
        ({ tokenId, symbol }) =>
          tokenId === bakcToken?.mainTokenId && symbol === bakcToken.mainTokenSymbol
      ) ?? apeTokens?.[0],
    [apeTokens, apesAndBakc, bakcToken?.mainTokenId, bakcToken?.mainTokenSymbol]
  );

  const { apeToken, handleApeTokenChange } = useApeTokenSelector(apeTokens, defaultApeToken);

  const {
    amountFromBalance,
    amountFromCredit,
    handleBalanceAmountChange,
    handleCreditAmountChange,
    handleMaxAmountFromBalance,
    handleMaxAmountFromCredit,
    maxAmountFromBalance,
    maxAmountFromCredit,
    borrowLimit,
    apeCoinBalance,
    cApeLiquidity,
    restrictToBorrowApe
  } = useBalanceAndCreditAmount(apeToken, apeCoinSource, bakcToken);

  const stakedAmount = useMemo(
    () => bakcToken?.stakedAmount?.toNumber() ?? 0,
    [bakcToken?.stakedAmount]
  );

  const { netApy, compoundPoolApy, borrowApyRate } = useNetApy(amountFromCredit, amountFromBalance);

  const errors = useMemo(
    () =>
      validate(
        {
          apeTokenId: apeToken?.tokenId,
          amountFromBalance,
          amountFromCredit,
          stakedAmount
        },
        {
          stakeLimit: stakeLimit?.toNumber() ?? null,
          borrowLimit: borrowLimit.toNumber(),
          balance: apeCoinBalance?.toNumber() ?? null,
          liquidity: cApeLiquidity.toNumber(),
          restrictToBorrowApe
        }
      ),
    [
      apeToken?.tokenId,
      amountFromBalance,
      amountFromCredit,
      stakedAmount,
      borrowLimit,
      apeCoinBalance,
      cApeLiquidity,
      restrictToBorrowApe
    ]
  );

  const handleSubmit = useCallback(() => {
    onSubmit({
      apeCoinSource,
      bakcTokenId,
      bakcSource,
      apeSymbol: apeToken?.symbol! as ApeStakingMainTokenSymbol,
      apeTokenId: apeToken?.tokenId!,
      apeSource: apeToken?.source ?? null,
      amountFromBalance,
      amountFromCredit
    });
  }, [
    bakcSource,
    amountFromBalance,
    amountFromCredit,
    apeToken,
    bakcTokenId,
    onSubmit,
    apeCoinSource
  ]);

  return {
    isLoading: isLoadingBaycV1TimelockStatus || isLoadingMaycV1TimelockStatus,
    apeTokens,
    defaultApeToken,
    netApy,
    compoundPoolApy,
    borrowApyRate,
    errors,
    bakcSource,
    stakedAmount,
    amountFromBalance,
    amountFromCredit,
    balance: apeCoinBalance,
    maxAmountFromCredit,
    maxAmountFromBalance,
    handleSubmit,
    handleBalanceAmountChange,
    handleCreditAmountChange,
    handleApeTokenChange,
    handleMaxAmountFromBalance,
    handleMaxAmountFromCredit
  };
};
