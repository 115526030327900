import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { useGraphqlConfig } from '@/apps/parax/hooks';
import { UserPositions, useUserMigrationAssetsLazyQuery } from '@/apps/parax/generated/graphql';
import { useEOAProvider } from '@/apps/parax/contexts';

export type V1UserContextType = {
  isV1User: boolean;
  hasV1TimeLock: boolean;
  isNewUser: boolean;
  userPositions: UserPositions | null;
};

export const ON_BOARDING_VIEWED_KEY = 'on_boarding_viewed';
export const V1UserContext = createContext<V1UserContextType>({} as V1UserContextType);

export const V1UserContextProvider = ({ children }: { children: ReactNode }) => {
  const { account: EOAAccount } = useEOAProvider();
  const { graphqlEndpointV1 } = useGraphqlConfig();
  const [isV1User, setIsV1User] = useState(false);
  const [hasV1TimeLock, setHasV1TimeLock] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [userPositionsData, setUserPositionsData] = useState<UserPositions | null>(null);

  const [getUserAssets, { data }] = useUserMigrationAssetsLazyQuery({
    variables: {
      walletAddress: EOAAccount
    },
    context: { uri: graphqlEndpointV1 }
  });

  useEffect(() => {
    const {
      timelockQueues = false,
      pairedP2PTokens = false,
      p2pUnclaimedRewards = false,
      userPositions = null
    } = data?.userAssets ?? {};

    const needMigration = pairedP2PTokens || p2pUnclaimedRewards || !!userPositions;
    setHasV1TimeLock(timelockQueues);
    setIsV1User(needMigration);
    setIsNewUser(!needMigration);
    setUserPositionsData(userPositions);
  }, [data]);

  useEffect(() => {
    getUserAssets();
  }, [getUserAssets]);

  const contextValue = useMemo(
    () => ({
      isV1User,
      isNewUser,
      hasV1TimeLock,
      userPositions: userPositionsData
    }),
    [isNewUser, isV1User, userPositionsData, hasV1TimeLock]
  );

  return <V1UserContext.Provider value={contextValue}>{children}</V1UserContext.Provider>;
};

export const useV1UserProvider = () => {
  const context = useContext(V1UserContext);

  if (context === undefined) {
    throw new Error('V1UserContext must be used within a V1UserContextProvider');
  }

  return context;
};
