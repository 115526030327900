import { useState, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { useDebounce } from 'react-use';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { useSupplyAndBorrowApy } from '@/apps/paraspace/pages/hooks/useSupplyAndBorrowApy';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useConvertSymbolWithNetwork } from '@/apps/paraspace/pages/hooks';

export const useDebouncedSupplyApyByAmount = ({
  amount,
  defaultSupplyApyRate,
  disabled,
  symbol
}: {
  amount: Maybe<number>;
  defaultSupplyApyRate: BigNumber;
  disabled: boolean;
  symbol: ERC20Symbol;
}) => {
  const { getSupplyApy } = useSupplyAndBorrowApy();

  const [supplyApy, setSupplyApy] = useState(defaultSupplyApyRate);
  const [isApyLoading, setIsApyLoading] = useState(false);

  const amountAsString = amount?.toString() ?? '';

  const { convertNativeTokenToWToken } = useConvertSymbolWithNetwork();
  const convertedTokenSymbol = useMemo(
    () => convertNativeTokenToWToken(symbol),
    [convertNativeTokenToWToken, symbol]
  );

  useDebounce(
    () => {
      const handle = async () => {
        if (amountAsString && !disabled) {
          const result = await getSupplyApy(convertedTokenSymbol, amountAsString);
          setSupplyApy(result ? new BigNumber(result.newSupplyApy) : defaultSupplyApyRate);
          setIsApyLoading(false);
          return;
        }
        setSupplyApy(defaultSupplyApyRate);
        setIsApyLoading(false);
      };
      handle();
    },
    500,
    [amountAsString, getSupplyApy, disabled, defaultSupplyApyRate, symbol]
  );

  return { supplyApy, isApyLoading };
};
