import { Container } from '@parallel-mono/components';
import styled from 'styled-components';

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  max-height: 18.75rem;
  overflow: auto;
  ${({ theme }) => theme.breakpoints.down('md')`
  grid-template-columns: repeat(2, 1fr);
`};
`;

export const BorrowedContainer = styled(Container).attrs({
  border: true
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
`;
