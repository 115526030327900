import { Button, H4, Stack, Text, Image } from '@parallel-mono/components';

import apeCoinLogo from '@/apps/paraspace/assets/ape-coin-right.png';

const Success = ({ desc, onClose, tip }: { desc: string; onClose: () => void; tip?: string }) => {
  return (
    <Stack gap="2rem" alignItems="center">
      <Image src={apeCoinLogo} width="7.5rem" height="7.5rem" />
      <Stack gap=".25rem">
        <H4>{desc}</H4>
        {tip && <Text skin="secondary">{tip}</Text>}
      </Stack>
      <Button size="large" block skin="secondary" onClick={onClose}>
        Done
      </Button>
    </Stack>
  );
};

export default Success;
