import { useCallback, useMemo, useState } from 'react';
import { ReverseRecordsService } from 'paraspace-utilities-contract-helpers';
import { isNil } from 'lodash';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useContractsMap } from '@/apps/paraspace/hooks';
import {
  LeadboardSortBy,
  useGetLeaderboardDataQuery,
  useGetPersonalLeaderboardDataQuery
} from '@/apps/paraspace/generated/graphql';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { formatToCurrency, truncateTextEnd } from '@/apps/paraspace/utils/format';

const PAGE_SIZE = 10;

const isNotNull = <T>(maybeNull: T | null): maybeNull is T => maybeNull !== null;

export const LeaderboardTypes = {
  DEPOSIT_TVL: 'DepositTvl',
  BNPL_VOLUME: 'BnplVolume'
} as const;

export type LeaderboardType = (typeof LeaderboardTypes)[keyof typeof LeaderboardTypes];

export const useLeaderboard = (type: LeaderboardType) => {
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [userEnsName, setUserEnsName] = useState<string | null>(null);
  const [ensNames, setEnsNames] = useState<string[] | null>(null);
  const [ensNamesLoading, setEnsNamesLoading] = useState<boolean>(false);
  const { account, provider } = useWeb3Context();
  const contracts = useContractsMap();
  const reverseRecords = useMemo(() => {
    if (!provider || !contracts.ReverseRecords) return null;
    return new ReverseRecordsService(provider, contracts.ReverseRecords);
  }, [provider, contracts]);

  const sortBy =
    type === LeaderboardTypes.DEPOSIT_TVL ? LeadboardSortBy.DepositDesc : LeadboardSortBy.BnplDesc;
  const { data: groupData, loading: groupLoading } = useGetLeaderboardDataQuery({
    variables: {
      pageInfo: {
        limit: PAGE_SIZE,
        offset
      },
      sortBy
    }
  });

  const { data: personalData, loading: personalLoading } = useGetPersonalLeaderboardDataQuery({
    variables: {
      walletAddress: account,
      sortBy
    }
  });
  const validGroupData = useMemo(() => {
    if (groupData) {
      return {
        leadboard: {
          ...groupData.leadboard,
          items: groupData.leadboard.items.filter(isNotNull)
        }
      };
    }
    return null;
  }, [groupData]);

  const getEnsNames = useCallback(
    async (addressList: string[]) => {
      try {
        setEnsNamesLoading(true);
        const resolvedNames = await reverseRecords!.getNames(addressList);
        setEnsNamesLoading(false);
        return resolvedNames;
      } catch (error) {
        console.error('get EnsNames error:', error);
        setEnsNamesLoading(false);
        return null;
      }
    },
    [reverseRecords]
  );

  useAsyncEffect(async () => {
    if (validGroupData) {
      setEnsNames(null);
      setTotalCount(validGroupData.leadboard.totalCount);
      if (reverseRecords) {
        const resolvedNames = await getEnsNames(validGroupData.leadboard.items.map(i => i.address));
        setEnsNames(resolvedNames);
      }
    }
  }, [validGroupData, reverseRecords]);

  useAsyncEffect(async () => {
    if (personalData && personalData.personalLeadboard && reverseRecords) {
      const ensName = await getEnsNames([personalData.personalLeadboard.address]);
      if (ensName) {
        setUserEnsName(ensName[0]);
      }
    }
  }, [personalData, provider, reverseRecords]);

  const currentPage = useMemo(() => offset / PAGE_SIZE + 1, [offset]);
  const handlePageChange = useCallback(
    (page: number) => setOffset((page - 1) * PAGE_SIZE),
    [setOffset]
  );
  const totalPage = useMemo(() => Math.ceil(totalCount / PAGE_SIZE), [totalCount]);
  const data = useMemo(() => {
    if (validGroupData) {
      return validGroupData.leadboard.items.map(
        ({ position, address, deposited, BNPLVolume }, index) => ({
          position,
          address,
          display: ensNames
            ? ensNames[index] || truncateTextEnd(address, 6)
            : truncateTextEnd(address, 6),
          value: formatToCurrency(
            parseFloat(type === LeaderboardTypes.DEPOSIT_TVL ? deposited : BNPLVolume)
          )
        })
      );
    }
    return [];
  }, [validGroupData, type, ensNames]);
  const userData = useMemo(() => {
    if (personalData && personalData.personalLeadboard) {
      return {
        position: personalData.personalLeadboard.position,
        address: personalData.personalLeadboard.address,
        display: userEnsName || truncateTextEnd(personalData.personalLeadboard.address, 6),
        value: formatToCurrency(
          parseFloat(
            type === LeaderboardTypes.DEPOSIT_TVL
              ? personalData.personalLeadboard.deposited
              : personalData.personalLeadboard.BNPLVolume
          )
        ),
        tier: personalData.personalLeadboard.positionTier
      };
    }
    return null;
  }, [personalData, type, userEnsName]);

  const loading = useMemo(
    () => groupLoading || personalLoading || (isNil(ensNames) && ensNamesLoading),
    [groupLoading, personalLoading, ensNames, ensNamesLoading]
  );
  return {
    totalPage,
    currentPage,
    loading,
    data,
    userData,
    handlePageChange
  };
};
