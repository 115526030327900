import { Stack, useBreakpoints } from '@parallel-mono/components';
import { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import { difference, filter, map } from 'lodash';

import { TableForDesktop } from './TableForDesktop';
import { UniswapToken } from './types';
import { CardForMobile } from './Mobile';
import { useUniswapBasicInfos } from './useUniswapBasicInfos';

import { useUniSwapV3Manager } from '@/apps/paraspace/pages/contexts/UniSwapV3ManagerProvider/useUniSwapV3Manager';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  ERC20Symbol,
  ERC721Symbol,
  FetchingStatus,
  UniswapSpecificInfo,
  WalletType
} from '@/apps/paraspace/typings';
import { useConvertSymbolWithNetwork } from '@/apps/paraspace/pages/hooks/useConvertSymbolWithNetwork';
import { useEOABalances, useUniswapInfos } from '@/apps/paraspace/pages/contexts';

type UniswapV3Props = Omit<HTMLAttributes<HTMLDivElement>, 'children'>;

const isMatchingPair = (
  tokenId: string,
  uniswapInfoMap: Record<string, UniswapSpecificInfo>,
  token0Symbol: ERC20Symbol,
  token1Symbol: ERC20Symbol
) => {
  const { token0Symbol: extractedToken0Symbol, token1Symbol: extractedToken1Symbol } =
    uniswapInfoMap?.[tokenId] ?? {};
  return (
    difference([extractedToken0Symbol, extractedToken1Symbol], [token0Symbol, token1Symbol])
      .length <= 0
  );
};

export const UniswapV3 = memo((props: UniswapV3Props) => {
  const { mobile } = useBreakpoints();
  const { convertWTokenToNativeToken } = useConvertSymbolWithNetwork();

  const { supplyUniSwapV3 } = useUniSwapV3Manager();

  const handleSupply = useCallback(
    (
      { token0Symbol, token1Symbol }: { token0Symbol: ERC20Symbol; token1Symbol: ERC20Symbol },
      walletType: WalletType
    ) => {
      supplyUniSwapV3([token0Symbol, token1Symbol], walletType).then(() => {});
    },
    [supplyUniSwapV3]
  );

  const { nftInfoMap, userInfoLoaded } = useMMProvider();
  const { erc721BalanceMap: eoaErc721BalanceMap, erc721BalanceMapPollingStatus } = useEOABalances();
  const { infoMap: uniswapInfoMap } = useUniswapInfos();

  const isEOABalanceLoading =
    eoaErc721BalanceMap === null && erc721BalanceMapPollingStatus === FetchingStatus.FETCHING;

  const { data: uniswapV3BasicInfos, loading } = useUniswapBasicInfos();

  const data: UniswapToken[] = useMemo(
    () =>
      uniswapV3BasicInfos.map(({ token0Symbol, token1Symbol, totalSupplied, totalUsd, ltv }) => {
        const { balance = [] } = nftInfoMap[ERC721Symbol.UNISWAP_LP] ?? {};
        const inParaXBalanceTokens: number[] =
          balance.filter(tokenId =>
            isMatchingPair(String(tokenId), uniswapInfoMap, token0Symbol, token1Symbol)
          ) ?? [];

        const inEOABalanceTokens = filter(
          eoaErc721BalanceMap?.[ERC721Symbol.UNISWAP_LP]?.balance,
          tokenId => isMatchingPair(String(tokenId), uniswapInfoMap, token0Symbol, token1Symbol)
        );

        const balanceMap = {
          EOA: {
            balances: map(inEOABalanceTokens, tokenId => ({
              tokenId,
              symbol: ERC721Symbol.UNISWAP_LP
            })),
            loading: isEOABalanceLoading
          },

          AA: {
            balances: map(inParaXBalanceTokens, tokenId => ({
              tokenId,
              symbol: ERC721Symbol.UNISWAP_LP
            })),
            loading: !userInfoLoaded
          }
        };

        const isBalanceLoading = !userInfoLoaded && isEOABalanceLoading;

        return {
          name: `${convertWTokenToNativeToken(token0Symbol)}/${convertWTokenToNativeToken(
            token1Symbol
          )}`,
          token1Symbol,
          token0Symbol,
          balanceMap,
          totalSupplied,
          totalUsd,
          ltv,
          balanceLoadingStatus: isBalanceLoading ? FetchingStatus.FETCHING : FetchingStatus.SUCCESS
        };
      }),
    [
      eoaErc721BalanceMap,
      uniswapInfoMap,
      convertWTokenToNativeToken,
      isEOABalanceLoading,
      nftInfoMap,
      uniswapV3BasicInfos,
      userInfoLoaded
    ]
  );

  return (
    <Stack justifyContent="center" alignItems="center" {...props}>
      {!mobile && <TableForDesktop loading={loading} data={data} onSupply={handleSupply} />}
      {mobile && <CardForMobile data={data} onSupply={handleSupply} />}
    </Stack>
  );
});
