import { ComponentProps, memo, useMemo } from 'react';
import {
  Button,
  H5,
  Icon,
  Inline,
  SmallText,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import dayjs from 'dayjs';
import { InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import { useTheme } from 'styled-components';

import { ApeCoinListing, ApeListing, BakcListing } from '../../../../types';
import { ApeCoinSourceSelect, ContractBanner } from '../../../../components';
import { ApeStakingTokenSelector } from '../../components/ApeStakingTokenSelector';
import { ShareInput } from '../ShareInput';
import { shareToPercentage } from '../utils';
import { JoinBAKCPairListingsFormData } from '../types';
import { InvalidListingAlert } from '../../components/InvalidListingAlert';

import { useJoinBAKCPairListingsFormStateAndHandlers } from './hooks';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ERC721Symbol, WalletType } from '@/apps/paraspace/typings';

type JoinBAKCPairListingsFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  apeListing: Maybe<ApeListing>;
  apeCoinListing: Maybe<ApeCoinListing>;
  bakcListing: Maybe<BakcListing>;
  walletType: WalletType;
  onSubmit: (formData: JoinBAKCPairListingsFormData) => void;
};

export const JoinBAKCPairListingsForm = memo(
  ({
    apeListing,
    apeCoinListing,
    bakcListing,
    walletType,
    onSubmit,
    ...others
  }: JoinBAKCPairListingsFormProps) => {
    const { account } = useWeb3Context();

    const {
      predictedApeListing,
      predictedApeCoinListing,
      predictedBakcListing,
      shareLeft,
      shareOfBakc,
      shareOfApe,
      shareOfApeCoin,
      handleShareOfBakcChanged,
      handleShareOfApeChanged,
      handleShareOfApeCoinChanged,
      apeCoinSourceInput,
      apeListingTokenInput,
      bakcListingTokenInput,
      handleApeCoinSourceInputChange,
      handleApeListingTokenInputChange,
      handleBakcListingTokenInputChange,
      infos,
      errors,
      expirationDate,
      handleConfirm,
      validationResult
    } = useJoinBAKCPairListingsFormStateAndHandlers({
      apeListing,
      apeCoinListing,
      bakcListing,
      walletType,
      onSubmit
    });

    const timeBeforeExpiration = useMemo(
      () => dayjs(expirationDate).fromNow(true),
      [expirationDate]
    );

    const showShareInput =
      [apeListing, apeCoinListing, bakcListing].filter(it => it === null).length > 1;

    const theme = useTheme();

    return (
      <Stack gap="1rem" {...others}>
        <ContractBanner
          account={account}
          apeListing={predictedApeListing}
          apeCoinListing={predictedApeCoinListing}
          bakcListing={predictedBakcListing}
        />
        <Inline justifyContent="center">
          <SmallText skin="secondary">Offer expires in {timeBeforeExpiration}</SmallText>
        </Inline>
        {apeCoinListing === null && (
          <Inline alignItems="center" justifyContent="space-between">
            <Text fontWeight="bold">Join Using</Text>
            <ApeCoinSourceSelect
              walletType={walletType}
              value={apeCoinSourceInput}
              onChange={handleApeCoinSourceInputChange}
            />
          </Inline>
        )}
        {apeListing === null && (
          <Stack alignItems="center" gap="1rem">
            <H5>Select a MAYC/BAYC</H5>
            <ApeStakingTokenSelector
              walletType={walletType}
              symbols={[ERC721Symbol.MAYC, ERC721Symbol.BAYC]}
              stakingPool={ERC721Symbol.BAKC}
              selectedToken={apeListingTokenInput}
              onChange={
                handleApeListingTokenInputChange as ComponentProps<
                  typeof ApeStakingTokenSelector
                >['onChange']
              }
            />
          </Stack>
        )}
        {bakcListing === null && (
          <Stack alignItems="center" gap="1rem">
            <H5>Select a BAKC</H5>
            <ApeStakingTokenSelector
              walletType={walletType}
              symbols={[ERC721Symbol.BAKC]}
              stakingPool={ERC721Symbol.BAKC}
              selectedToken={bakcListingTokenInput}
              onChange={
                handleBakcListingTokenInputChange as ComponentProps<
                  typeof ApeStakingTokenSelector
                >['onChange']
              }
            />
          </Stack>
        )}
        {showShareInput && (
          <Inline>
            {bakcListing === null && (
              <Stack width="100%" gap="0.5rem">
                <H5>Share of BAKC</H5>
                <ShareInput
                  placeholder="0"
                  decimals={1}
                  max={shareToPercentage(shareLeft)!}
                  value={shareToPercentage(shareOfBakc)}
                  onChange={handleShareOfBakcChanged}
                />
              </Stack>
            )}
            {apeCoinListing === null && (
              <Stack width="100%" gap="0.5rem">
                <H5>Share of ApeCoin</H5>
                <ShareInput
                  placeholder="0"
                  decimals={1}
                  max={shareToPercentage(shareLeft)!}
                  value={shareToPercentage(shareOfApeCoin)}
                  onChange={handleShareOfApeCoinChanged}
                />
              </Stack>
            )}
            {apeListing === null && (
              <Stack width="100%" gap="0.5rem">
                <H5>Share of MAYC/BAYC</H5>
                <ShareInput
                  placeholder="0"
                  decimals={1}
                  max={shareToPercentage(shareLeft)!}
                  value={shareToPercentage(shareOfApe)}
                  onChange={handleShareOfApeChanged}
                />
              </Stack>
            )}
          </Inline>
        )}
        <InfoPanel skin="primary" infos={infos as InfoPanelProps['infos']} />
        {validationResult && <InvalidListingAlert reason={validationResult} />}
        <Button
          block
          size="large"
          skin={errors || validationResult ? 'secondary' : 'primary'}
          disabled={!!errors || !!validationResult}
          onClick={handleConfirm}
        >
          {errors || 'Confirm'}
        </Button>
        {!!errors && (
          <Inline gap="0.5rem" justifyContent="center" alignItems="center">
            <Icon color={theme.skin.primary.main} name="heartContained" />
            <SmallText skin="secondary">Enjoy 0 platform fee and low gas!</SmallText>
          </Inline>
        )}
      </Stack>
    );
  }
);
