import { ReactNode, memo, useCallback, useEffect, useRef } from 'react';
import BigNumber from 'bignumber.js';

import { ApprovingStatus, useERC20Approval } from '../../hooks';
import { ApproveWallet, InProgress } from '../../components';

import { useParaXToast } from '@/apps/parax/contexts';
import { getUserFriendlyError } from '@/apps/parax/utils';

type ApproveERC20FormData = {
  assetAddress: string;
  name: string;
  amount: number | BigNumber;
  spender: string;
};

type ApproveERC20SubmitterProps = {
  formData: ApproveERC20FormData;
  approvingTips?: ReactNode;
  onFinish: () => void;
  onError: () => void;
};

export const ApproveERC20Submitter = memo(
  ({ onFinish, onError, formData, approvingTips }: ApproveERC20SubmitterProps) => {
    const { assetAddress, amount, name, spender } = formData;

    const {
      approve,
      status: approvingStatus,
      check
    } = useERC20Approval({
      token: assetAddress,
      amount,
      spender
    });

    useEffect(() => {
      check();
    }, [check]);

    const onFinishRef = useRef<ApproveERC20SubmitterProps['onFinish'] | null>(null);
    onFinishRef.current = onFinish;
    const onErrorRef = useRef<ApproveERC20SubmitterProps['onError'] | null>(null);
    onErrorRef.current = onError;

    const paraxToast = useParaXToast();

    const handleApprove = useCallback(() => {
      paraxToast.promise(
        approve()
          .then()
          .catch(e => {
            throw getUserFriendlyError(e);
          })
      );
    }, [paraxToast, approve]);

    useEffect(() => {
      if (approvingStatus === ApprovingStatus.APPROVED) {
        onFinishRef.current?.();
      } else if (
        approvingStatus === ApprovingStatus.ERROR_APPROVING ||
        approvingStatus === ApprovingStatus.ERROR_CHECKING
      ) {
        onErrorRef.current?.();
      } else if (approvingStatus === ApprovingStatus.NOT_APPROVED) {
        handleApprove();
      }
    }, [approvingStatus, handleApprove]);

    if (approvingStatus === ApprovingStatus.CHECKING || approvingStatus === ApprovingStatus.IDLE) {
      return <InProgress message={`Checking your allowance for ${name}`} />;
    }

    if (
      approvingStatus === ApprovingStatus.SIGNING ||
      approvingStatus === ApprovingStatus.NOT_APPROVED
    ) {
      return <ApproveWallet>{approvingTips}</ApproveWallet>;
    }

    if (
      approvingStatus === ApprovingStatus.APPROVING ||
      approvingStatus === ApprovingStatus.APPROVED
    ) {
      return <InProgress message={`Approving ${name} Securely`} />;
    }

    return null;
  }
);
