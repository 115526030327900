import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, H5, Inline, Skeleton, Stack, Text } from '@parallel-mono/components';
import { InfoPanel, TokenInput } from '@parallel-mono/business-components';
import { floor } from 'lodash';
import { formatNumber } from '@parallel-mono/utils';

import { NFTThumbnail, NumberRange, Tooltip } from '@/apps/paraspace/components';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { useApeListStatesAndActions } from '@/apps/paraspace/pages/ApePairing/contexts/ApeListProvider';
import { useNftActualOwners } from '@/apps/paraspace/pages/hooks/useAcutualOwners';
import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC721Symbol
} from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type WithdrawFormProps = {
  mainTokenId: number | undefined;
  mainTokenSymbol?: ApeStakingMainTokenSymbol;
  tokenId: number;
  symbol: ApeStakingTokenSymbol;
  stakedValue: number | undefined;
  pendingRewards: number | undefined;
  onSubmit: (amount: number) => void;
};

const WithdrawForm = ({
  mainTokenId,
  mainTokenSymbol,
  tokenId,
  symbol,
  stakedValue,
  pendingRewards,
  onSubmit
}: WithdrawFormProps) => {
  const [amount, setAmount] = useState<number | null>(null);
  const [isOwnBAKC, setIsOwnBAKC] = useState(true);
  const { apesInBalanceAndInSuppliedExcludingInP2P } = useApeListStatesAndActions();

  const ownApe = useMemo(
    () =>
      !mainTokenId ||
      (isOwnBAKC &&
        apesInBalanceAndInSuppliedExcludingInP2P.find(
          i => i.tokenId === mainTokenId && i.symbol === mainTokenSymbol
        )),
    [apesInBalanceAndInSuppliedExcludingInP2P, isOwnBAKC, mainTokenId, mainTokenSymbol]
  );

  const handleValueChange = useCallback((value: number | null) => setAmount(value), []);

  const { account } = useWeb3Context();
  const { getNftActualOwners } = useNftActualOwners();
  useEffect(() => {
    // When widthdrawing BAKC, check if own mainToken but not own bakc
    if (mainTokenSymbol) {
      getNftActualOwners([{ symbol: ERC721Symbol.BAKC, tokenId: String(tokenId) }]).then(res => {
        setIsOwnBAKC(res[0] === account.toLocaleLowerCase());
      });
    }
  }, [account, getNftActualOwners, tokenId, mainTokenSymbol]);

  const onMaxClick = useCallback(
    () => setAmount(floor(stakedValue ?? 0, MAXIMUM_BALANCE_DECIMALS)),
    [stakedValue]
  );

  useEffect(() => {
    if (!ownApe) {
      onMaxClick();
    }
  }, [onMaxClick, ownApe]);

  const errorMsg: string = useMemo(() => {
    if (amount && stakedValue !== undefined && amount > stakedValue) {
      return 'Exceeded the staked amount.';
    }
    return '';
  }, [amount, stakedValue]);

  const handleWithdraw = useCallback(() => {
    if (amount) {
      onSubmit(amount);
    }
  }, [onSubmit, amount]);

  const disableButton = useMemo(() => !!errorMsg || !amount, [amount, errorMsg]);

  const newStakedValue = useMemo(
    () => (amount && stakedValue! >= amount ? stakedValue! - amount : stakedValue!),
    [amount, stakedValue]
  );

  const isWithdrawingAll = useMemo(() => amount === stakedValue, [amount, stakedValue]);

  const totalStakePanel = useMemo(
    () => ({
      title: 'Total Stake',
      value: stakedValue ? (
        <NumberRange
          start={stakedValue || 0}
          end={newStakedValue}
          formatter={formatNumber}
          symbol="APE"
        />
      ) : (
        <Skeleton.Button height="1.5rem" width="6rem" variant="round" />
      )
    }),
    [newStakedValue, stakedValue]
  );
  const rewardsPanel = useMemo(
    () => ({
      title: 'Rewards',
      value: (
        <NumberRange start={pendingRewards || 0} end={0} formatter={formatNumber} symbol="APE" />
      )
    }),
    [pendingRewards]
  );
  const panelInfos = useMemo(
    () => (isWithdrawingAll ? [totalStakePanel, rewardsPanel] : [totalStakePanel]),
    [isWithdrawingAll, rewardsPanel, totalStakePanel]
  );

  return (
    <Stack gap="1.5rem" alignItems="center">
      <NFTThumbnail size="xLarge" symbol={symbol} tokenId={tokenId} showDescription />
      {ownApe && (
        <TokenInput
          width="100%"
          label={<H5>Amount</H5>}
          hint={
            <Inline gap=".3rem">
              {stakedValue !== undefined ? (
                <Text skin="secondary">Staked: {formatNumber(stakedValue)} APE</Text>
              ) : (
                <Skeleton.Button height="1.5rem" width="6rem" variant="round" />
              )}
              <Tooltip placement="top" content="Total amount of APE staked with this NFT." />
            </Inline>
          }
          token="APE"
          placeholder="0"
          value={amount}
          decimals={MAXIMUM_BALANCE_DECIMALS}
          error={errorMsg}
          onChange={handleValueChange}
          actionButtonText="Max"
          onAction={onMaxClick}
          inputProps={{
            inputProps: {
              autoFocus: true
            }
          }}
        />
      )}
      <InfoPanel width="100%" skin="primary" infos={panelInfos} />
      {!ownApe && (
        <Alert width="100%" type="info">
          You can only withdraw all because you don’t own either the BAYC or BAKC in this pair.
        </Alert>
      )}
      <Button block size="large" disabled={disableButton} onClick={handleWithdraw}>
        Withdraw
      </Button>
    </Stack>
  );
};

export default memo(WithdrawForm);
