import { memo, useMemo } from 'react';
import styled from 'styled-components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { Inline, InlineProps, SmallText, Stack, StackProps, Text } from '@parallel-mono/components';
import BigNumberJS from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';

import ApeCoinPlaceholderSvg from '../../../../Images/ape-coin-placeholder.svg';
import { ApeCoinListing, ApeListing, BakcListing } from '../../types';
import { stakingTypeToPoolNameMap } from '../../utils';

import { PendingTag } from './styledComponents';

import { ImageThumbnail, NFTThumbnail, AccountPill } from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { StakingType } from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { ApeStakingMainAssetSymbol, ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/ApePairing/consts';

type AssetItemProps = Omit<StackProps, 'children'> & {
  symbol?: ApeStakingMainAssetSymbol;
  tokenId?: number;
  account: string;
  stakingType: StakingType;
  owner?: string;
};
type BannerProps = Omit<InlineProps, 'children'> & {
  apeListing: Maybe<Partial<ApeListing>>;
  apeCoinListing: Maybe<Partial<ApeCoinListing>>;
  bakcListing?: Maybe<Partial<BakcListing>>;
  account: string;
};

const Container = styled(Inline)`
  border: ${({ theme }) => `${theme.border.width.medium} solid ${theme.skin.grey[200]}`};
  border-radius: ${({ theme }) => theme.border.radius.large};
  width: 100%;
`;

const Description = styled(Text)`
  text-align: center;
`;

const AssetItem = memo(
  ({ symbol, tokenId, account, owner, stakingType, ...others }: AssetItemProps) => {
    const isApeCoinListing = symbol === ERC20Symbol.APE;
    const stakingPoolName = stakingTypeToPoolNameMap[stakingType];
    const stakeLimit = useMemo(
      () => BigNumberJS(STAKE_LIMIT[stakingPoolName] ?? 0),
      [stakingPoolName]
    );
    const estimatedApeSymbol =
      !symbol && stakingType === StakingType.BAKCPairStaking ? 'BAYC/MAYC' : stakingPoolName;

    return (
      <Stack gap="1rem" alignItems="center" {...others}>
        {isApeCoinListing && (
          <Stack gap="0.25rem">
            {owner ? (
              <CryptoIcon size="5rem" symbol={ERC20Symbol.APE} />
            ) : (
              <ImageThumbnail src={ApeCoinPlaceholderSvg} width="5rem" />
            )}
            <Description>
              {formatNumber(stakeLimit)} {ERC20Symbol.APE}
            </Description>
          </Stack>
        )}
        {!isApeCoinListing && (
          <NFTThumbnail
            gap="0.25rem"
            symbol={symbol || ERC721Symbol.BAYC}
            size="medium"
            tokenId={tokenId}
            showDescription
            description={<Description>{tokenId ? `#${tokenId}` : estimatedApeSymbol}</Description>}
          />
        )}
        {owner && <AccountPill address={owner} isOwner={account === owner} />}
        {!owner && (
          <PendingTag skin="warning">
            <SmallText fontWeight="bold">Pending</SmallText>
          </PendingTag>
        )}
      </Stack>
    );
  }
);

export const ContractBanner = memo(
  ({ apeListing, apeCoinListing, bakcListing, account, ...others }: BannerProps) => {
    const apeOwner = apeListing?.offerer;
    const apeCoinOwner = apeCoinListing?.offerer;
    const bakcOwner = bakcListing?.offerer;
    const stakingType =
      apeCoinListing?.stakingType ?? apeListing?.stakingType ?? bakcListing?.stakingType;

    return (
      <Container {...others} inset="1.5rem" grow justifyContent="space-between">
        {stakingType === StakingType.BAKCPairStaking && (
          <AssetItem
            symbol={ERC721Symbol.BAKC}
            tokenId={bakcListing?.tokenId}
            owner={bakcOwner}
            account={account}
            stakingType={stakingType!}
          />
        )}
        <AssetItem
          symbol={apeListing?.symbol}
          tokenId={apeListing?.tokenId}
          owner={apeOwner}
          account={account}
          stakingType={stakingType!}
        />
        <AssetItem
          symbol={ERC20Symbol.APE}
          owner={apeCoinOwner}
          account={account}
          stakingType={stakingType!}
        />
      </Container>
    );
  }
);
