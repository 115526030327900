import { StackProps } from '@parallel-mono/components';
import { memo } from 'react';
import { useOutletContext } from 'react-router-dom';

import { usePausedLiquidationDashboardState } from './hooks';
import { LiquidationOutletContext } from './LiquidationDashboard';
import { LiquidationPage } from './LiquidationPage';

type PausedLiquidationProps = Omit<StackProps, 'children'>;

export const PausedLiquidation = memo(({ ...others }: PausedLiquidationProps) => {
  const { selectedCollectionSymbols } = useOutletContext<LiquidationOutletContext>();
  const { liquidations, loading, client } =
    usePausedLiquidationDashboardState(selectedCollectionSymbols);

  return (
    <LiquidationPage {...others} liquidations={liquidations} loading={loading} client={client} />
  );
});
