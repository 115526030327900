import { Platform } from '@/apps/paraspace/typings';

export const getMarketplaceId = (platform: Platform, version?: string) => {
  if (platform === Platform.OPENSEA) {
    if (version === '1.1') {
      return 'OPENSEA_SEAPORT_V11_ID';
    }
    if (version === '1.4') {
      return 'OPENSEA_SEAPORT_V14_ID';
    }
    if (version === '1.5') {
      return 'OPENSEA_SEAPORT_V15_ID';
    }
    throw new Error(`Could not find supported marketplace Id for ${platform}#${version}`);
  } else if (platform === Platform.PARASPACE) {
    // update once v1.4 has been supported
    return 'PARASPACE_SEAPORT_V11_ID';
  } else {
    return 'LOOKSRARE_V11_ID';
  }
};

export const getMarketplaceIds = (platforms: Platform[], versions: string[]) => {
  if (platforms.length !== versions.length) {
    throw new Error(`Could not find supported marketplace Id because of missing versions data`);
  }

  return platforms.map((platform, index) => getMarketplaceId(platform, versions[index]));
};
