import { Stack, Button, H4, Text } from '@parallel-mono/components';
import styled from 'styled-components';

import { NFTThumbnail } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';

type ListingSuccessProps = {
  symbol: ERC721Symbol;
  tokenId: number;
  successInfo: string;
  closeModal: () => void;
  showSmallText?: boolean;
};
const StyledStack = styled(Stack)`
  text-align: center;
`;

export const ListingSuccess = ({
  symbol,
  tokenId,
  successInfo,
  closeModal,
  showSmallText = true
}: ListingSuccessProps) => {
  return (
    <StyledStack gap="1.5rem" alignItems="center">
      <NFTThumbnail symbol={symbol} tokenId={tokenId} />
      <Stack gap="0.25rem">
        <H4>{successInfo}</H4>
        {showSmallText && (
          <Text skin="secondary">
            Keep track of your listings in the Offers tab for updates on any sales.
          </Text>
        )}
      </Stack>
      <Button skin="secondary" onClick={closeModal} block>
        Close
      </Button>
    </StyledStack>
  );
};
