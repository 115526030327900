import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { find, orderBy } from 'lodash';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import {
  ProtocolData,
  ShopListingSort,
  useGetShopListingsQuery
} from '@/apps/paraspace/generated/graphql';
import { useAppConfig, useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { shiftedLeftBy } from '@/apps/paraspace/utils/calculations';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { durationList, Duration } from '@/apps/paraspace/consts/duration';

export type MyListingItem = {
  orderHash: string;
  tokenId: number;
  symbol: ERC721Symbol;
  price: BigNumber;
  priceInUSD: Maybe<BigNumber>;
  expirationTime: dayjs.Dayjs;
  contractAddress: string;
  protocolData: ProtocolData;
  duration: Duration;
};

export const useMyListingData = () => {
  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  const { erc20InfoMap } = useMMProvider();
  const { priceInUsd } = erc20InfoMap.ETH || {};
  const { account } = useWeb3Context();
  const { erc20Config } = useAppConfig();
  const { data, loading, refetch } = useGetShopListingsQuery({
    variables: {
      filter: {
        makers: [account],
        sort: ShopListingSort.PriceAsc,
        lowestListing: true
      }
    },
    fetchPolicy: 'no-cache'
  });

  const formattedListings: MyListingItem[] = useMemo(() => {
    if (loading || !data) return [];
    return orderBy(
      data.shopListings.map(listing => {
        const price = shiftedLeftBy(listing.listingPrice, erc20Config.ETH.decimals);
        return {
          orderHash: listing.orderHash,
          contractAddress: listing.contractAddress,
          tokenId: Number(listing.identifierOrCriteria),
          symbol: getSymbolByContractAddress(listing.contractAddress) as ERC721Symbol,
          price,
          expirationTime: dayjs(listing.expirationTime),
          priceInUSD: priceInUsd ? price.times(priceInUsd) : null,
          protocolData: listing.protocolData!,
          duration:
            find(durationList, {
              value: dayjs(listing.expirationTime).diff(listing.listingTime, 'day')
            }) || durationList[1]
        };
      }),
      item => item.expirationTime.unix(),
      ['asc']
    );
  }, [data, erc20Config.ETH.decimals, getSymbolByContractAddress, loading, priceInUsd]);

  return { myListings: formattedListings, loading, refetch };
};
