import { MarketplaceIcon } from '@parallel-mono/business-components';
import { H6 } from '@parallel-mono/components';
import styled, { css } from 'styled-components';

export const PositionThumbnailLogo = css`
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
`;

export const StyledThumbnailLogo = css`
  padding: 1px;
  background-color: #c4d2d8;
  border: 1px solid white;
  border-radius: 100px;
`;

export const StyledMarketplaceIcon = styled(MarketplaceIcon)<{ notPosition?: boolean }>`
  ${item => (item.notPosition ? '' : PositionThumbnailLogo)}
  ${StyledThumbnailLogo}
  width: 1.5rem;
  height: 1.5rem;
`;

export const StyledApeStakeIcon = styled(H6).attrs({
  fontWeight: 'medium'
})`
  ${PositionThumbnailLogo}
  ${StyledThumbnailLogo}
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(3)};
`;
