import {
  Alert,
  Button,
  H2,
  H4,
  H5,
  Inline,
  RangeSlider,
  SmallText,
  Stack,
  StackProps,
  Tag
} from '@parallel-mono/components';
import { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { LPTokenInfo } from './LPTokenInfo';
import { FormData } from './RemoveLiquidityProvider';
import { presetPercentages, slippageTolerance } from './config';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { formatToCurrency, formatToPercentage } from '@/apps/paraspace/utils/format';
import { calculateBorrowLimitPoint } from '@/apps/paraspace/pages/hooks/useLendingSimulation/calculateBorrowLimitPoint';
import useSuppliedAssets from '@/apps/paraspace/pages/hooks/useLendingSimulation/useSuppliedAssets';
import { StackedIcons, BorrowLimitBar } from '@/apps/paraspace/components';

type RemoveLiquidityFormProps = {
  onSubmit: (formData: FormData) => Promise<void>;
  symbol: string;
  id: number;
} & Omit<StackProps, 'children' | 'gap' | 'justifyContent' | 'alignItems' | 'onSubmit' | 'id'>;

const PercentageSlider = styled(RangeSlider)`
  width: 100%;
`;

const HealthBarContainer = styled(Stack)`
  border: 1px solid ${({ theme }) => theme.skin.grey[200]};
  padding: 0.5rem 1rem 0.75rem;
  border-radius: 0.5rem;
`;

const LearnMoreButton = styled(Button)`
  margin: 0.25rem;
`;
const overLiquidationThresholdWarningMessage = (
  <>
    Removing this amount will lead put your account over Liquidation Threshold, please repay some
    debt or add more credit first.
    <LearnMoreButton variant="link">Learn More</LearnMoreButton>
  </>
);

export const RemoveLiquidityForm = memo((props: RemoveLiquidityFormProps) => {
  const { onSubmit, symbol, id, ...others } = props;
  const [percentage, setPercentage] = useState(20);

  const handlePercentageChange = useCallback(
    (percentageToBe: number) => {
      setPercentage(percentageToBe);
    },
    [setPercentage]
  );

  const {
    overviewUserInfo: { totalBorrowedPositionInUsd },
    nftInfoMap,
    erc20InfoMap
  } = useMMProvider();

  const { tokenSpecificInfos } = nftInfoMap[symbol];

  const {
    token0Symbol,
    token1Symbol,
    feeRate,
    isInRange,
    liquidity,
    liquidityToken0Amount,
    liquidityToken1Amount,
    lpFeeToken0Amount,
    lpFeeToken1Amount
  } = tokenSpecificInfos[id];
  const token0CurrentPrice = erc20InfoMap[token0Symbol].priceInUsd;
  const token1CurrentPrice = erc20InfoMap[token1Symbol].priceInUsd;

  const currentAssets = useSuppliedAssets();

  const assetsToBe = useMemo(
    () =>
      currentAssets.map(asset => {
        if (asset.id === `${symbol}-${id}`) {
          const pooledToken0InUsd = token0CurrentPrice.times(liquidityToken0Amount);
          const pooledToken1InUsd = token1CurrentPrice.times(liquidityToken1Amount);
          const remainingRatio = (100 - percentage) / 100;
          const predictedValue = pooledToken0InUsd.plus(pooledToken1InUsd).times(remainingRatio);
          return {
            ...asset,
            value: predictedValue
          };
        }
        return asset;
      }),
    [
      currentAssets,
      symbol,
      id,
      percentage,
      liquidityToken0Amount,
      liquidityToken1Amount,
      token0CurrentPrice,
      token1CurrentPrice
    ]
  );

  const {
    totalCollateral: totalSuppliedToBe,
    borrowLimit: borrowLimitToBe,
    liquidationPoint: liquidationPointToBe
  } = useMemo(() => calculateBorrowLimitPoint(assetsToBe), [assetsToBe]);

  const overLiquidationThreshold = useMemo(
    () => liquidationPointToBe.isLessThan(totalBorrowedPositionInUsd),
    [liquidationPointToBe, totalBorrowedPositionInUsd]
  );

  const [removing, setRemoving] = useState(false);
  const handleRemove = useCallback(async () => {
    const formData: FormData = {
      liquidityDecrease: liquidity
        .times(percentage / 100)
        .decimalPlaces(0)
        .toString(10),
      amount0Min: liquidityToken0Amount.times(percentage / 100).times(1 - slippageTolerance),
      amount1Min: liquidityToken1Amount.times(percentage / 100).times(1 - slippageTolerance)
    };
    setRemoving(true);
    onSubmit(formData).finally(() => {
      setRemoving(false);
    });
  }, [liquidity, percentage, liquidityToken0Amount, liquidityToken1Amount, onSubmit, setRemoving]);

  const noPooledLiquidity = liquidityToken0Amount.eq(0) && liquidityToken1Amount.eq(0);
  return (
    <Stack width="100%" {...others}>
      <Inline justifyContent="space-between">
        <Inline gap="0.5rem" alignItems="center">
          <StackedIcons
            assets={[{ symbol: token0Symbol }, { symbol: token1Symbol }]}
            size="small"
          />
          <H4>
            {token0Symbol}/{token1Symbol}
          </H4>
        </Inline>
        <Inline gap="0.5rem">
          {isInRange && (
            <Tag skin="success" size="small">
              In Range
            </Tag>
          )}
          {!isInRange && (
            <Tag skin="warning" size="small">
              Out of Range
            </Tag>
          )}
          <Tag skin="neutral" size="small">
            {formatToPercentage(feeRate.toNumber())}
          </Tag>
        </Inline>
      </Inline>
      <Stack alignItems="center" gap="1.5rem">
        <H2>{percentage}%</H2>
        <PercentageSlider value={percentage} onChange={handlePercentageChange} />
        <Inline gap="0.5rem" width="100%" justifyContent="space-between">
          {presetPercentages.map((presetPercentage, index) => (
            <Button
              key={index}
              size="medium"
              skin="secondary"
              onClick={() => {
                setPercentage(presetPercentage);
              }}
            >
              {presetPercentage}%
            </Button>
          ))}
        </Inline>
        <HealthBarContainer gap="0.5rem" width="100%">
          <Inline gap="0.125rem">
            <SmallText>Borrowing:</SmallText>
            <H5>{formatToCurrency(totalBorrowedPositionInUsd.toNumber())}</H5>
          </Inline>
          <BorrowLimitBar
            borrowedValue={totalBorrowedPositionInUsd}
            borrowLimitValue={borrowLimitToBe}
            liquidationPointValue={liquidationPointToBe}
            totalSuppliedValue={totalSuppliedToBe}
          />
        </HealthBarContainer>
        {overLiquidationThreshold && (
          <Alert type="error">{overLiquidationThresholdWarningMessage}</Alert>
        )}
        <LPTokenInfo
          percentage={percentage}
          token1Amount={liquidityToken0Amount}
          token1Symbol={token0Symbol}
          token1LPFee={lpFeeToken0Amount}
          token2Amount={liquidityToken1Amount}
          token2Symbol={token1Symbol}
          token2LPFee={lpFeeToken1Amount}
        />
        {!overLiquidationThreshold && (
          <Button
            block
            size="large"
            onClick={handleRemove}
            disabled={noPooledLiquidity || removing}
          >
            {noPooledLiquidity ? 'Insufficient Liquidity' : 'Remove'}
          </Button>
        )}
      </Stack>
    </Stack>
  );
});
