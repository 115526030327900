import { memo, useMemo } from 'react';
import {
  Button,
  DataGridColumn,
  DataGridProps,
  H5,
  H6,
  Inline,
  Skeleton,
  SmallText,
  Stack,
  Text
} from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import { isEmpty } from 'lodash';

import { SupplyERC721DropdownMenu, WalletsBalanceDropdown } from '../../../components';

import { UniswapToken } from './types';
import { UniChainlink } from './components';

import { DataGrid, StackedIcons } from '@/apps/paraspace/components';
import { ERC20Symbol, FetchingStatus, WalletType } from '@/apps/paraspace/typings';
import { formatToCurrency } from '@/apps/paraspace/utils/format';

type TableForDesktopProps = Omit<DataGridProps<UniswapToken>, 'columns'> & {
  onSupply: (
    arg: { token0Symbol: ERC20Symbol; token1Symbol: ERC20Symbol },
    walletType: WalletType
  ) => void;
};

const createColumns = (
  onSupply: (
    arg: { token0Symbol: ERC20Symbol; token1Symbol: ERC20Symbol },
    walletType: WalletType
  ) => void
): DataGridColumn<UniswapToken>[] => [
  {
    name: 'pairs',
    width: '1.5fr',
    title: <H5 fontWeight="medium">Pairs</H5>,
    render: ({ data: { token0Symbol, token1Symbol, name } }) => {
      return (
        <Inline gap="0.75rem" alignItems="center">
          <StackedIcons assets={[{ symbol: token0Symbol }, { symbol: token1Symbol }]} />
          <H5>{name}</H5>
        </Inline>
      );
    }
  },
  {
    name: 'yourWallet',
    width: '1fr',
    title: <H5 fontWeight="medium">Your Wallet</H5>,
    render: ({
      data: {
        balanceMap: { AA, EOA },
        balanceLoadingStatus
      }
    }) => {
      if (balanceLoadingStatus === FetchingStatus.FETCHING) return <Skeleton.Title />;
      if (isEmpty(AA.balances) && isEmpty(EOA.balances)) {
        return <SmallText>-</SmallText>;
      }
      return (
        <Stack gap="0.5rem">
          <StackedIcons assets={AA.balances.concat(EOA.balances)} variant="list" size="small" />
          <Inline>
            <WalletsBalanceDropdown
              EOABalance={{
                loading: EOA.loading,
                value: <H6>{EOA.balances.length}</H6>
              }}
              paraXBalance={{ loading: AA.loading, value: <H6>{AA.balances.length}</H6> }}
            />
          </Inline>
        </Stack>
      );
    }
  },
  {
    name: 'oracleSource',
    width: '1fr',
    title: <H5 fontWeight="medium">Oracle Source</H5>,
    render: () => <UniChainlink />
  },
  {
    name: 'ltv',
    width: '1fr',
    title: <H5 fontWeight="medium">Loan To Value </H5>,
    render: ({ data: { ltv } }) => {
      if (!ltv) return <SmallText>-</SmallText>;
      return <Text>{formatNumber(ltv, { output: 'percent' })}</Text>;
    }
  },
  {
    name: 'totalSupplied',
    width: '1fr',
    title: <H5 fontWeight="medium">Total Supplied</H5>,
    render: ({ data: { totalSupplied, totalUsd } }) => {
      return (
        <Stack gap="0">
          <Text>{formatNumber(totalSupplied)}</Text>
          <SmallText skin="secondary">{formatToCurrency(totalUsd)}</SmallText>
        </Stack>
      );
    }
  },
  {
    name: 'action',
    width: '1fr',
    title: '',
    render: ({
      data: {
        token0Symbol,
        token1Symbol,
        balanceMap: { AA: paraXBalance, EOA: EOABalance }
      }
    }) => {
      return (
        <Inline width="100%" justifyContent="flex-end">
          <SupplyERC721DropdownMenu
            menuTrigger={<Button skin="secondary">Supply</Button>}
            paraXWalletOption={{
              onClick: () => onSupply({ token0Symbol, token1Symbol }, 'AA'),
              balance: paraXBalance.balances.length ?? 0
            }}
            EOAWalletOption={{
              onClick: () => onSupply({ token0Symbol, token1Symbol }, 'EOA'),
              balance: EOABalance.balances.length ?? 0
            }}
          />
        </Inline>
      );
    }
  }
];

export const TableForDesktop = memo(({ onSupply, ...others }: TableForDesktopProps) => {
  const columns: DataGridColumn<UniswapToken>[] = useMemo(
    () => createColumns(onSupply),
    [onSupply]
  );
  return <DataGrid<UniswapToken> {...others} columns={columns} />;
});
