import { useCallback, useEffect, useMemo, useState } from 'react';

import { Maybe } from '../typings/basic';

export const useControlledLocalStorage = <T>(key: string): [Maybe<T>, (v: Maybe<T>) => void] => {
  const [value, setValue] = useState(localStorage.getItem(key));

  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key === key) {
        setValue(event.newValue);
      }
    };
    window.addEventListener('storage', handleStorageEvent);
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [key]);

  const storageValue = useMemo(() => {
    if (value === null) {
      return null;
    }
    try {
      return JSON.parse(value) as T;
    } catch {
      return null;
    }
  }, [value]);

  const updateStorageValue = useCallback(
    (v: Maybe<T>) => {
      if (v === null) {
        localStorage.removeItem(key);
        window.dispatchEvent(new StorageEvent('storage', { key, oldValue: value, newValue: null }));
      } else {
        const newValue = JSON.stringify(v);
        localStorage.setItem(key, JSON.stringify(v));
        window.dispatchEvent(new StorageEvent('storage', { key, oldValue: value, newValue }));
      }
    },
    [key, value]
  );

  return [storageValue, updateStorageValue];
};
