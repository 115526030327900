import { NumericInput } from '@parallel-mono/components';
import styled from 'styled-components';

export const ShareInput = styled(NumericInput)`
  width: 100%;
  height: 4rem;
  font-size: 1.25rem;
  background: none;
  font-weight: bold;
`;
