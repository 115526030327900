import { Icon, Inline, InlineProps } from '@parallel-mono/components';
import { FC, memo, useCallback } from 'react';
import styled from 'styled-components';

import { Pill } from './FilterPill';

const StyledPill = styled(Pill)`
  padding: 0.5rem;
`;

export enum LayoutType {
  List,
  Grid
}

type Props = {
  layoutType: LayoutType;
  onLayoutTypeChange: (type: LayoutType) => void;
};

export const LayoutTypeSelection: FC<Props & Omit<InlineProps, 'children'>> = memo(
  ({ onLayoutTypeChange, layoutType, ...others }) => {
    const handleToggleLayoutType = useCallback(
      (type: LayoutType) => () => onLayoutTypeChange(type),
      [onLayoutTypeChange]
    );

    return (
      <Inline gap="1rem" {...others}>
        <StyledPill
          active={layoutType === LayoutType.List}
          onClick={handleToggleLayoutType(LayoutType.List)}
        >
          <Icon name="list" width="1.25rem" strokeWidth={2} />
        </StyledPill>
        <StyledPill
          active={layoutType === LayoutType.Grid}
          onClick={handleToggleLayoutType(LayoutType.Grid)}
        >
          <Icon name="grid" size="1.25rem" strokeWidth={2} />
        </StyledPill>
      </Inline>
    );
  }
);
