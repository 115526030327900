import { useState, useCallback, memo } from 'react';
import { formatNumber } from '@parallel-mono/utils';

import { CommonFormState } from '../types';
import Success from '../component/CommonSuccess';
import { useLegacyOfficialApeStaking } from '../../hook/useLegacyOfficialApeStaking';
import { StakeInfoListItem } from '../../types';

import WithdrawForm from './WithdrawForm';

import { ApproveWallet, InProgress, ErrorState } from '@/apps/paraspace/components';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { MIN_BALANCE_THRESHOLD } from '@/apps/paraspace/utils/format';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';

type WithdrawFormProps = {
  stakeItem: StakeInfoListItem;
  onClose: () => void;
};

const Form = ({ stakeItem, onClose }: WithdrawFormProps) => {
  const [formState, setFormState] = useState(CommonFormState.MAINFORM);
  const [withdrawAmount, setWithdrawAmount] = useState(0);

  const toast = useParallelToast();
  const { withdraw } = useLegacyOfficialApeStaking();

  const withdrawToken = useCallback(
    (amount: string) => {
      setFormState(CommonFormState.APPROVE);
      toast.promise(
        withdraw(stakeItem, amount)
          .then(async tx => {
            setFormState(CommonFormState.PROCESSING);
            await tx?.wait();
            setFormState(CommonFormState.SUCCESS);
          })
          .catch(err => {
            setFormState(CommonFormState.ERROR);
            throw getUserFriendlyError(err);
          })
      );
    },
    [stakeItem, toast, withdraw]
  );

  const handleWithdraw = useCallback(
    (amount: string) => {
      setWithdrawAmount(Number(amount));

      withdrawToken(amount);
    },
    [withdrawToken]
  );

  if (formState === CommonFormState.MAINFORM) {
    return <WithdrawForm stakeItem={stakeItem} onSubmit={handleWithdraw} />;
  }

  if (formState === CommonFormState.APPROVE) {
    return <ApproveWallet />;
  }

  if (formState === CommonFormState.PROCESSING) {
    return <InProgress tip="Withdrawing" />;
  }

  if (formState === CommonFormState.ERROR) {
    return <ErrorState closeModal={onClose} />;
  }

  if (formState === CommonFormState.SUCCESS) {
    return (
      <Success
        desc={`Successfully withdraw ${formatNumber(withdrawAmount, {
          decimal: MAXIMUM_BALANCE_DECIMALS,
          threshold: { min: MIN_BALANCE_THRESHOLD }
        })} APE coins.`}
        onClose={onClose}
      />
    );
  }

  return null;
};

export default memo(Form);
