import { memo, FC, useMemo, useState, useCallback } from 'react';

import { MintSubmitter } from './MintSubmitter';

import { ApeListItem } from '@/apps/paraspace/pages/ApePairing/contexts';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import {
  ApproveERC721Submitter,
  SupplyLegacyERC721Submitter,
  Stepper,
  StepperProps
} from '@/apps/paraspace/components';
import { useContractsMap } from '@/apps/paraspace/hooks';

type Props = Omit<StepperProps, 'steps' | 'step'> & {
  formData: { mintTokens: ApeListItem[] };
  onMint: Function;
  onSuccess: () => void;
  onFailed: () => void;
};

export const MintSewerPassSubmitter: FC<Props> = memo(
  ({ formData, onFailed, onSuccess, onMint, ...others }) => {
    const [step, setStep] = useState(0);

    const contracts = useContractsMap();

    const handleNextStep = useCallback(() => {
      setStep(prevStep => prevStep + 1);
    }, []);

    const bakcToken = useMemo(
      () => formData.mintTokens.find(token => token.symbol === ERC721Symbol.BAKC)!,
      [formData.mintTokens]
    );

    const baycOrMaycToken = useMemo(
      () =>
        formData.mintTokens.find(token =>
          [ERC721Symbol.BAYC, ERC721Symbol.MAYC].includes(token.symbol)
        )!,
      [formData.mintTokens]
    );

    const steps = useMemo(() => {
      const { symbol, tokenId } = bakcToken;
      return [
        {
          title: 'Step 1',
          description: `Approve ${symbol}`,
          content: (
            <ApproveERC721Submitter
              formData={{
                name: symbol,
                assetAddress: contracts[symbol]
              }}
              onFinish={handleNextStep}
              onError={onFailed}
            />
          )
        },
        {
          title: 'Step 2',
          description: `Supply ${symbol}#${tokenId} to ParaSpace`,
          content: (
            <SupplyLegacyERC721Submitter
              formData={{
                symbol,
                tokenIds: [tokenId]
              }}
              onFinish={handleNextStep}
              onError={onFailed}
            />
          )
        },
        {
          title: 'Step 3',
          description: 'Mint Sewer Pass',
          content: (
            <MintSubmitter
              formData={{
                bakcToken,
                baycOrMaycToken
              }}
              onMint={onMint}
              onSuccess={onSuccess}
              onFailed={onFailed}
            />
          )
        }
      ];
    }, [bakcToken, baycOrMaycToken, contracts, handleNextStep, onFailed, onMint, onSuccess]);

    return <Stepper step={step} steps={steps} {...others} />;
  }
);
