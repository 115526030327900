import {
  Button,
  DataGridColumn,
  H5,
  Inline,
  Skeleton,
  SmallText,
  Stack,
  Tag,
  Text,
  Toggle,
  Typography,
  TypographyVariant
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import {
  CollectionPosition,
  NftPosition,
  SupplyPositionsRow,
  SupplyPositionsRowType,
  TokenPosition
} from '../types';
import { getEffectiveApy } from '../../utils';

import { NFTCollectionThumbnail, Tooltip } from '@/apps/paraspace/components';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';
import {
  ERC20InfoMap,
  ERC20Symbol,
  ERC721Symbol,
  NFTInfo,
  NFTInfoMap,
  WalletType
} from '@/apps/paraspace/typings';
import { emptyArray, zero } from '@/apps/paraspace/consts/values';
import { getEffectiveSupplyApyTip } from '@/apps/paraspace/pages/Credit/MoneyMarket/utils/getEffectiveApyTip';
import { BendDaoBalanceMap, WalletBalanceMap } from '@/apps/paraspace/pages/Credit/hooks';
import {
  SupplyERC20DropdownMenu,
  SupplyERC721DropdownMenu
} from '@/apps/paraspace/pages/Credit/components';
import { DELISTED_ERC721_SYMBOLS, ERC20Config } from '@/apps/paraspace/config';

const StyledH5 = styled(H5)`
  word-break: keep-all;
`;

const Desc = styled(Typography).attrs({ variant: TypographyVariant.bodySmall })`
  color: ${({ theme }) => theme.skin.grey['700']};
`;

export const createColumns = ({
  inLiquidation,
  effectiveCapeSupplyApy,
  onSupply,
  onSupplyERC20FromAA,
  onSupplyERC20FromEOA,
  onWithdraw,
  onToggleCollateral,
  walletBalanceMap,
  bendDaoBalanceMap,
  onSupplyFromOtherProtocols,
  erc20Config,
  nativeTokenAndDerivatives
}: {
  inLiquidation: boolean;
  effectiveCapeSupplyApy: BigNumber | null;
  onSupply: (data: SupplyPositionsRow, walletTyoe: WalletType) => void;
  onSupplyFromOtherProtocols: (data: SupplyPositionsRow) => void;
  onSupplyERC20FromAA: (symbol: ERC20Symbol) => void;
  onSupplyERC20FromEOA: (symbol: ERC20Symbol) => void;
  onWithdraw: (data: SupplyPositionsRow) => void;
  onToggleCollateral: (data: SupplyPositionsRow) => void;
  walletBalanceMap: WalletBalanceMap;
  bendDaoBalanceMap: BendDaoBalanceMap;
  erc20Config: Partial<ERC20Config>;
  nativeTokenAndDerivatives: ERC20Symbol[];
}): DataGridColumn<SupplyPositionsRow>[] => {
  return [
    {
      name: 'name',
      title: 'Assets',
      width: '1.3fr',
      render: ({ data: { name, cover, symbol, position, type }, expanded }) => {
        const showInLiquidationTag =
          type === SupplyPositionsRowType.ERC721 &&
          !isEmpty((position as NftPosition).auctionedTokens) &&
          !expanded;
        return (
          <Inline alignItems="center">
            {cover}
            <div>
              <Inline>
                <Stack gap="0.5rem">
                  <Inline gap="0.5rem">
                    <StyledH5 fontWeight="bold">{name}</StyledH5>
                    {symbol === ERC721Symbol.WPUNKS && (
                      <Tooltip content="The CryptoPunks you supply will be Wrapped Cryptopunks in our contract, you can choose to CryptoPunks when you withdraw." />
                    )}
                  </Inline>
                  {showInLiquidationTag && (
                    <Inline>
                      <Tag skin="error" size="small">
                        In Liquidation
                      </Tag>
                    </Inline>
                  )}
                </Stack>
              </Inline>
              {symbol === ERC20Symbol.SAPE && <Desc>Includ. rewards</Desc>}
            </div>
          </Inline>
        );
      }
    },
    {
      name: 'suppliedAmount',
      title: 'Supplied',
      width: '0.7fr',
      render: ({ data: { suppliedAmount, suppliedValue } }) => (
        <Stack gap="0">
          <Text>{suppliedAmount ? formatBalance(suppliedAmount) : '-'}</Text>
          {suppliedValue?.gt(zero) ? (
            <SmallText skin="secondary">
              {suppliedValue ? formatToCurrency(suppliedValue) : '-'}
            </SmallText>
          ) : (
            <Skeleton.Button height="1rem" width="2rem" />
          )}
        </Stack>
      )
    },
    {
      name: 'apy',
      title: 'APY',
      width: '0.5fr',
      render: ({ data: { supplyApyRate, type, symbol, position } }) => {
        const isERC20Token = type === SupplyPositionsRowType.ERC20;
        if (!isERC20Token) {
          return '-';
        }
        const isNativeTokenOrDerivatives = nativeTokenAndDerivatives.includes(
          symbol as ERC20Symbol
        );
        const effectiveSupplyApy = getEffectiveApy({
          baseApy: supplyApyRate,
          effectiveCapeApy: effectiveCapeSupplyApy,
          symbol: symbol as ERC20Symbol,
          apy: erc20Config[symbol as ERC20Symbol]?.apy,
          isNativeTokenOrDerivatives
        });
        const effectiveSupplyApyTooltip = getEffectiveSupplyApyTip({
          symbol: symbol as ERC20Symbol,
          baseApy: supplyApyRate,
          apy: erc20Config[symbol as ERC20Symbol]?.apy ?? null,
          isNativeTokenOrDerivatives
        });
        return (
          <Stack gap="0">
            <Inline alignItems="center" gap="0.25rem">
              <Text>
                {
                  // exclude ETH derivatives parent row
                  isEmpty((position as TokenPosition)?.subRows) && '~'
                }
                {effectiveSupplyApy}
              </Text>
              {effectiveSupplyApyTooltip}
            </Inline>
          </Stack>
        );
      }
    },
    {
      name: 'collateral',
      title: 'Collateral',
      width: '7rem',
      render: ({ data: { type, usedAsCollateral, position }, data }) => {
        if (
          type === SupplyPositionsRowType.ERC20 &&
          !erc20Config[data.symbol as ERC20Symbol]!.isFakeToken &&
          // exclude ETH derivatives parent row
          !(position as TokenPosition)?.subRows?.length
        ) {
          return (
            <Toggle
              disabled={inLiquidation && usedAsCollateral!}
              checked={usedAsCollateral!}
              onChange={() => onToggleCollateral(data)}
            />
          );
        }
        return null;
      }
    },
    {
      name: 'actions',
      title: '',
      width: '26rem',
      render: ({ data: { symbol, type }, data }) => {
        const isAllCollateral =
          (data.type === SupplyPositionsRowType.ERC20 && data.usedAsCollateral) ||
          (data.type === SupplyPositionsRowType.ERC721 &&
            (data.position as unknown as NFTInfo).nftSuppliedList?.length ===
              (data.position as unknown as NFTInfo).nftCollateralList?.length);
        return erc20Config[data.symbol as ERC20Symbol]?.isFakeToken ? null : (
          <Inline width="100%" gap="1rem" alignItems="center" justifyContent="flex-end">
            {
              // TODO: extract this as a config (i.g. nftType)
              type === SupplyPositionsRowType.ERC721 ? (
                !DELISTED_ERC721_SYMBOLS.includes(data.symbol as ERC721Symbol) && (
                  <SupplyERC721DropdownMenu
                    menuTrigger={<Button skin="secondary">Supply More</Button>}
                    paraXWalletOption={{
                      onClick: () => onSupply(data, 'AA'),
                      balance: walletBalanceMap.AA[symbol as ERC721Symbol]?.length ?? 0
                    }}
                    EOAWalletOption={{
                      onClick: () => onSupply(data, 'EOA'),
                      balance: walletBalanceMap.EOA[symbol as ERC721Symbol]?.length ?? 0
                    }}
                    otherProtocolOption={
                      [ERC721Symbol.UNISWAP_LP, ERC721Symbol.SF_VLDR].includes(
                        data.symbol as ERC721Symbol
                      )
                        ? undefined
                        : {
                            onClick: () => onSupplyFromOtherProtocols(data),
                            balance: bendDaoBalanceMap[symbol as ERC721Symbol] ?? 0
                          }
                    }
                  />
                )
              ) : (
                <SupplyERC20DropdownMenu
                  symbol={symbol as ERC20Symbol}
                  menuTrigger={<Button skin="secondary">Supply More</Button>}
                  eoaWalletOption={{
                    onClick: () => onSupplyERC20FromEOA(data.symbol as ERC20Symbol)
                  }}
                  aaWalletOption={{
                    onClick: () => onSupplyERC20FromAA(data.symbol as ERC20Symbol)
                  }}
                />
              )
            }
            <Button
              skin="secondary"
              onClick={() => onWithdraw(data)}
              disabled={inLiquidation && isAllCollateral}
            >
              Withdraw
            </Button>
          </Inline>
        );
      }
    }
  ];
};

export const nftPositionToSupplyPositionRow = (
  nftPosition: CollectionPosition,
  nftInfoMap: NFTInfoMap
) => {
  const {
    priceInUsd,
    nftSuppliedList = emptyArray,
    supplyRewardRate,
    collectionName,
    tokenSpecificInfos,
    hasTokenSpecificInfos,
    tokenTraitMultipliers
  } = nftInfoMap[nftPosition.symbol];

  const totalValue = hasTokenSpecificInfos
    ? nftSuppliedList.reduce((sum, id) => {
        return sum.plus(tokenSpecificInfos[id].priceInUsd);
      }, new BigNumber(0))
    : nftSuppliedList.reduce((sum, id) => {
        return sum.plus(priceInUsd?.times(tokenTraitMultipliers?.[id] ?? DEFAULT_MULTIPLIER));
      }, new BigNumber(0));

  return {
    type: SupplyPositionsRowType.ERC721,
    symbol: nftPosition.symbol,
    address: nftPosition.address,
    name: collectionName,
    cover: <NFTCollectionThumbnail symbol={nftPosition.symbol as ERC721Symbol} size="small" />,
    suppliedAmount: new BigNumber(nftSuppliedList.length),
    suppliedValue: totalValue,
    supplyApyRate: null,
    rewardApy: supplyRewardRate.times(nftSuppliedList.length),
    usedAsCollateral: null,
    position: nftPosition
  };
};

export const tokenPositionToSupplyPositionRow = (
  tokenPos: TokenPosition,
  erc20InfoMap: ERC20InfoMap
) => {
  const { symbol, subRows } = tokenPos;
  const {
    displayName,
    suppliedAmount,
    suppliedAmountInUsd,
    usageAsCollateralEnabledOnUser,
    supplyApyRate,
    supplyRewardRate,
    displayIcon
  } = {
    ...erc20InfoMap[symbol],
    ...tokenPos
  };

  const supplyPositionsRow: SupplyPositionsRow[] =
    subRows?.map(each =>
      tokenPositionToSupplyPositionRow(each as unknown as TokenPosition, erc20InfoMap)
    ) || [];

  return {
    type: SupplyPositionsRowType.ERC20,
    symbol,
    address: null,
    name: displayName,
    cover: displayIcon || <CryptoIcon symbol={symbol} />,
    suppliedAmount: suppliedAmount ?? null,
    suppliedValue: suppliedAmountInUsd ?? null,
    supplyApyRate,
    rewardApy: supplyRewardRate,
    usedAsCollateral: usageAsCollateralEnabledOnUser ?? null,
    position: tokenPos,
    subRows: supplyPositionsRow
  };
};
