import { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';

import { getTimelockQueueState, TimelockEventState, TimelockQueueState } from '../util';

import { useGetNetworkBatchBlockInfo } from './useNetworkBatchBlockInfo';

import { useTimelockQueuesLazyQuery } from '@/apps/paraspace/generated/graphql';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useTimelock } from '@/apps/paraspace/pages/hooks/Timelock';
import { useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';

export enum TimelockActionType {
  BORROW,
  WITHDRAW
}

export const TIMELOCK_ACTION_MAP: Record<TimelockActionType, string> = {
  [TimelockActionType.WITHDRAW]: 'Withdraw',
  [TimelockActionType.BORROW]: 'Borrow'
};

export enum AssetType {
  ERC20,
  ERC721
}

export type TimelockQueueRow = {
  claiming: boolean;
  agreementId: number;
  timeAdded: Date;
  actionType: TimelockActionType;
  actionTypeName: string;
  symbol: ERC721Symbol | ERC20Symbol;
  assetInfo: {
    type: AssetType;
    token: string;
    tokenIds?: Maybe<number[]>;
    amount?: Maybe<BigNumber>;
  };
  transaction: string;
  expectedRelease: Date;
  state: TimelockQueueState;
};

export const useTimelockData = (account: string) => {
  const [isContractFrozen, setIsContractFrozen] = useState<Maybe<boolean>>(null);
  const [getTimelockQueues, { refetch, data }] = useTimelockQueuesLazyQuery();
  const { shouldTimelockCompareWithBatchBlock, batchBlockDetail } = useGetNetworkBatchBlockInfo();

  const { erc20InfoMap, basicInfoLoaded } = useMMProvider();
  const { getContractFrozenStatus } = useTimelock();

  useEffect(() => {
    if (account) {
      getTimelockQueues({
        variables: {
          filter: { address: account }
        },
        pollInterval: 10000
      });
    }
  }, [account, getTimelockQueues]);

  useEffect(() => {
    const refetchContractFrozenStatus = async () => {
      const result = await getContractFrozenStatus();
      setIsContractFrozen(result);
    };
    refetchContractFrozenStatus();
  }, [getContractFrozenStatus]);

  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  const timelockData: Maybe<TimelockQueueRow[]> = useMemo(() => {
    if (!data || !basicInfoLoaded || isContractFrozen === null) return null;

    return (
      data.timelockQueues
        // TODO remove claimed timelock filter
        .filter(item => item?.status !== TimelockEventState.CLAIMED)
        .map(item => {
          const { assetInfo, status, expectedRelease, actionType } = item;
          const symbol = getSymbolByContractAddress(assetInfo.token)!;
          const amount =
            assetInfo.type === AssetType.ERC20
              ? BigNumber(assetInfo.amount ?? 0).shiftedBy(
                  (erc20InfoMap?.[symbol as ERC20Symbol]?.decimals ?? 0) * -1
                )
              : null;

          // For the zkSync the batch block time is later than the expectedRelease,
          // So according to our tests, the release time often needs to be delayed by 1 minute
          const releaseTime = shouldTimelockCompareWithBatchBlock
            ? dayjs(expectedRelease).add(2, 'm')
            : expectedRelease;

          return {
            ...item,
            claiming: false,
            symbol,
            actionTypeName: TIMELOCK_ACTION_MAP[actionType as TimelockActionType],
            assetInfo: {
              ...assetInfo,
              amount
            },
            expectedRelease: releaseTime,
            state: getTimelockQueueState(
              status,
              isContractFrozen,
              expectedRelease,
              shouldTimelockCompareWithBatchBlock,
              shouldTimelockCompareWithBatchBlock ? batchBlockDetail?.timestamp : undefined
            )
          };
        })
    );
  }, [
    data,
    basicInfoLoaded,
    isContractFrozen,
    getSymbolByContractAddress,
    erc20InfoMap,
    shouldTimelockCompareWithBatchBlock,
    batchBlockDetail?.timestamp
  ]);

  return { timelockData, refetch };
};
