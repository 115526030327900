import BigNumberJS from 'bignumber.js';

import { SellToken } from '@/apps/paraspace/pages/ApePairing/pages/MyApes/components/SideBar/AutoSellPanel/hooks';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const validation = ({
  curPercentage,
  newPercentage,
  curToken,
  newToken
}: {
  curPercentage: BigNumberJS;
  newPercentage: Maybe<BigNumberJS>;
  curToken: SellToken;
  newToken: SellToken;
}) => {
  const notChangedPercentage = newPercentage?.eq(curPercentage) && curToken === newToken;
  if (notChangedPercentage) {
    return 'Make a change';
  }
  if (!newPercentage) {
    return 'Set a percentage';
  }
  return null;
};
