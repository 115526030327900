import { memo } from 'react';
import { formatNumber } from '@parallel-mono/utils';
import { Modal, ModalProps } from '@parallel-mono/components';

import { BorrowERC20Stepper } from './BorrowERC20Stepper';
import { BorrowERC20Form } from './BorrowERC20Form';
import { BorrowERC20FormData } from './types';

import {
  CollectAndSubmitProcedurePhase,
  ErrorState,
  SuccessState,
  TimelockResultDesc,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';

export type BorrowERC20ModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  onClose: () => void;
  onFinish: () => void;
  onError: () => void;
  symbol: ERC20Symbol;
};

export const BorrowERC20Modal = memo(
  ({ symbol, onClose, isOpen, onFinish, onError, ...others }: BorrowERC20ModalProps) => {
    const { erc20InfoMap } = useMMProvider();

    const {
      phase,
      handleFormSubmit,
      submittedFormData,
      submittingResult: claimed,
      handleSubmitSuccess,
      handleSubmitFailed
    } = useCollectAndSubmitProcedure<BorrowERC20FormData, boolean, ErrorConfig>({
      running: isOpen,
      onFinish,
      onError
    });

    const tokenName = erc20InfoMap[symbol]?.displayName ?? symbol;

    return (
      <Modal isOpen={isOpen} title={`Borrow ${tokenName}`} onClose={onClose} {...others}>
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <BorrowERC20Form defaultSymbol={symbol} onSubmit={handleFormSubmit} onCancel={onClose} />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <BorrowERC20Stepper
            formData={submittedFormData!}
            onFinish={handleSubmitSuccess}
            onError={handleSubmitFailed}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            desc="Borrowed Successfully"
            tip={
              <>
                You have borrowed{' '}
                {formatNumber(submittedFormData!.amount || 0, {
                  decimal: MAXIMUM_BALANCE_DECIMALS
                })}{' '}
                {tokenName}. {!claimed && <TimelockResultDesc />}
              </>
            }
            actionButtonText={claimed ? 'Done' : 'View Queue'}
            onAction={onClose}
          />
        )}
      </Modal>
    );
  }
);
