import { FC, useMemo, useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';

import { useMMProvider } from '../../../../contexts/MMProvider';
import { useIsNativeTokenCheck } from '../../../../hooks/useIsNativeTokenCheck';

import RepayNonNativeTokenFromEOASubmitter from './RepayNonNativeTokenFromEOASubmitter';
import RepayNativeTokenFromEOASubmitter from './RepayNativeTokenFromEOASubmitter';

import { RepayFormData, Repayment } from '.';

import { Stepper, StepperProps, ApproveERC20Submitter } from '@/apps/paraspace/components';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';
import { useERC20, useNativeTokenGateway } from '@/apps/paraspace/hooks';
import usePool from '@/apps/paraspace/pages/hooks/usePool';

interface StepProps extends Omit<StepperProps, 'steps' | 'step' | 'onError'> {
  onError: () => void;
  onFinish: () => void;
  formData: RepayFormData;
}

const RepayStepper: FC<StepProps> = ({ onError, onFinish, formData, ...others }) => {
  const { amount, symbol, repayment, isRepayAll } = formData;

  const [step, setStep] = useState(0);
  const { erc20InfoMap } = useMMProvider();
  const { eoaAccount, account } = useWeb3Context();
  const { address } = erc20InfoMap[symbol];
  const { checkIsNativeTokenSymbol } = useIsNativeTokenCheck();
  const { repayNativeToken } = useNativeTokenGateway();
  const { repayERC20, repayERC20WithPTokens } = usePool();
  const { createApproval } = useERC20(address);
  const isNativeToken = checkIsNativeTokenSymbol(symbol);

  const handleNextStep = useCallback(() => {
    setStep(prevStep => prevStep + 1);
  }, []);

  const steps = useMemo(
    () => [
      {
        description: `Approve ${symbol}`,
        content: (
          <ApproveERC20Submitter
            formData={{
              assetAddress: address,
              name: symbol,
              amount,
              from: eoaAccount
            }}
            onFinish={handleNextStep}
            onError={onError}
          />
        ),
        skip: repayment !== Repayment.EOA || isNativeToken
      },
      {
        description: `Repay ${formatBalance(new BigNumber(amount))} ${
          erc20InfoMap[symbol].displayName
        }`,
        content: (
          <RepayNonNativeTokenFromEOASubmitter
            formData={{
              address,
              amount: amount.toString(),
              max: isRepayAll,
              from: eoaAccount
            }}
            onFinish={onFinish}
            onError={onError}
          />
        ),
        skip: repayment !== Repayment.EOA || isNativeToken
      },
      {
        description: `Repay ${formatBalance(new BigNumber(amount))} ${
          erc20InfoMap[symbol].displayName
        }`,
        content: (
          <RepayNativeTokenFromEOASubmitter
            formData={{
              amount: amount.toString(),
              max: isRepayAll
            }}
            onFinish={onFinish}
            onError={onError}
          />
        ),
        skip: repayment !== Repayment.EOA || !isNativeToken
      },
      {
        description: `Repay ${formatBalance(new BigNumber(amount))} ${
          erc20InfoMap[symbol].displayName
        }`,
        content: (
          <BatchTransactionsSubmitter
            batchTxs={[
              {
                tx: createApproval(),
                skip: repayment === Repayment.Supplied || isNativeToken
              },
              {
                tx: repayNativeToken({ amount: amount.toString(10), max: isRepayAll }),
                skip: repayment === Repayment.Supplied || !isNativeToken
              },
              {
                tx: repayERC20({
                  assetAddr: address,
                  amount: amount.toString(10),
                  max: isRepayAll,
                  from: account
                }),
                skip: repayment !== Repayment.AA || isNativeToken
              },
              {
                tx: repayERC20WithPTokens({
                  assetAddr: address,
                  amount: amount.toString(),
                  max: isRepayAll
                }),
                skip: repayment !== Repayment.Supplied
              }
            ]}
            onFinish={onFinish}
            onError={onError}
            inProgressMessage="Repaying"
          />
        ),
        skip: repayment === Repayment.EOA
      }
    ],
    [
      account,
      address,
      amount,
      createApproval,
      eoaAccount,
      erc20InfoMap,
      handleNextStep,
      isNativeToken,
      isRepayAll,
      onError,
      onFinish,
      repayERC20,
      repayERC20WithPTokens,
      repayNativeToken,
      repayment,
      symbol
    ]
  );

  return <Stepper steps={steps} step={step} {...others} />;
};

export default RepayStepper;
