import { useEffect } from 'react';

import {
  useApeCoinListingValidate,
  useApeOrBakcListingValidate
} from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/hooks/useListingValidation';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import {
  ApeCoinListing,
  ApeListing,
  BakcListing
} from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/types';

export const useListingCheck = ({
  apeListing,
  bakcListing,
  apeCoinListing
}: {
  apeListing: Maybe<ApeListing>;
  bakcListing: Maybe<BakcListing>;
  apeCoinListing: Maybe<ApeCoinListing>;
}) => {
  const [apeListingValidator, apeListingFailedReason] = useApeOrBakcListingValidate(
    apeListing?.symbol ?? ERC721Symbol.BAYC
  );

  const [bakcListingValidator, bakcListingFailedReason] = useApeOrBakcListingValidate(
    bakcListing?.symbol ?? ERC721Symbol.BAKC
  );

  const [apeCoinListingValidator, apeCoinListingFailedReason] = useApeCoinListingValidate();

  useEffect(() => {
    if (apeListing) {
      apeListingValidator(apeListing);
    }
    if (apeCoinListing) {
      apeCoinListingValidator(apeCoinListing);
    }
    if (bakcListing) {
      bakcListingValidator(bakcListing);
    }
  }, [
    apeCoinListing,
    apeCoinListingValidator,
    apeListing,
    apeListingValidator,
    bakcListing,
    bakcListingValidator
  ]);

  return apeListingFailedReason || bakcListingFailedReason || apeCoinListingFailedReason;
};
