import { isNil } from 'lodash';
import { formatNumber } from '@parallel-mono/utils';

import { StakeBakcFormData } from '../../../types';

import { Maybe } from '@/apps/paraspace/typings/basic';

const validateTotalAmount = (totalAmount: number, stakeLimit: number | null) => {
  if (stakeLimit !== null && totalAmount > stakeLimit) {
    return 'Total amount exceeds stake limit';
  }
  if (totalAmount === 0) {
    return '';
  }
  return null;
};

const validateNewStakeAmount = (newStakeAmount: number) => {
  if (newStakeAmount !== 0 && newStakeAmount < 1) {
    return 'You need to stake at least 1 APE';
  }
  return null;
};

const validateAmountFromBalance = (amount: number, balance: number) => {
  if (amount > balance) {
    return 'Insufficient balance.';
  }
  return null;
};

const validateAmountFromCredit = (
  amount: number,
  {
    borrowLimit,
    liquidity,
    restrictToBorrowApe
  }: { borrowLimit: number; liquidity: number; restrictToBorrowApe: boolean }
) => {
  if (amount > liquidity) {
    return `Borrow amount exceeds market liquidity, please enter an amount below ${formatNumber(
      liquidity
    )} cAPE.`;
  }
  if (amount > borrowLimit) {
    return 'Exceed borrow limit.';
  }
  if (amount && restrictToBorrowApe) {
    return 'Borrow is disabled due to extreme high utilization.';
  }
  return null;
};

export const validate = (
  {
    apeTokenId,
    amountFromBalance,
    amountFromCredit,
    stakedAmount
  }: {
    apeTokenId?: number;
    stakedAmount: number;
    amountFromBalance: Maybe<number>;
    amountFromCredit: Maybe<number>;
  },
  {
    balance,
    stakeLimit,
    borrowLimit,
    liquidity,
    restrictToBorrowApe
  }: {
    stakeLimit: Maybe<number>;
    borrowLimit: Maybe<number>;
    liquidity: Maybe<number>;
    balance: Maybe<number>;
    restrictToBorrowApe: boolean;
  }
): Partial<
  { [key in keyof StakeBakcFormData]: Maybe<string> } & {
    totalAmount: Maybe<string>;
    newStakeAmount: Maybe<string>;
  }
> => {
  const newStakeAmount = (amountFromBalance ?? 0) + (amountFromCredit ?? 0);
  const totalAmount = stakedAmount + newStakeAmount;

  return {
    apeTokenId: isNil(apeTokenId) ? 'Please select a BAYC or MAYC token' : null,
    amountFromCredit: validateAmountFromCredit(amountFromCredit ?? 0, {
      borrowLimit: borrowLimit ?? 0,
      liquidity: liquidity ?? 0,
      restrictToBorrowApe
    }),
    amountFromBalance: validateAmountFromBalance(amountFromBalance ?? 0, balance ?? 0),
    totalAmount: validateTotalAmount(totalAmount, stakeLimit),
    newStakeAmount: validateNewStakeAmount(newStakeAmount)
  };
};
