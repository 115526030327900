import { CryptoIcon } from '@parallel-mono/business-components';
import { H6 } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';
import { FC } from 'react';

import { ValuePill } from '@/apps/paraspace/components';

export const ApeCoinPill: FC<{ value: BigNumber }> = ({ value }) => (
  <ValuePill size="small" gap="0.25rem">
    <CryptoIcon symbol="APE" size="1rem" />
    <H6 fontWeight="medium">{formatNumber(value)}</H6>
  </ValuePill>
);

export default ApeCoinPill;
