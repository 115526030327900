import { FC, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { Text, H5, ProgressBar } from '@parallel-mono/components';
import BigNumberJs from 'bignumber.js';

import { formatToCurrency } from '@/apps/paraspace/utils/format';

type BorrowLimitBarProps = {
  borrowedValue: BigNumberJs;
  borrowLimitValue: BigNumberJs;
  liquidationPointValue: BigNumberJs;
  totalSuppliedValue: BigNumberJs;
  width?: string;
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const BorrowLimitBar: FC<BorrowLimitBarProps> = ({
  borrowedValue,
  borrowLimitValue,
  liquidationPointValue,
  totalSuppliedValue,
  width = '100%'
}) => {
  const roundBorrowedValue = borrowedValue.decimalPlaces(2, BigNumberJs.ROUND_DOWN).toNumber();
  const roundBorrowLimitValue = borrowLimitValue
    .decimalPlaces(2, BigNumberJs.ROUND_DOWN)
    .toNumber();
  const roundLiquidationValue = liquidationPointValue
    .decimalPlaces(2, BigNumberJs.ROUND_DOWN)
    .toNumber();
  const roundTotalSuppliedValue = totalSuppliedValue
    .decimalPlaces(2, BigNumberJs.ROUND_DOWN)
    .toNumber();

  const borrowedPercentage = useMemo(
    () => roundBorrowedValue / roundTotalSuppliedValue,
    [roundBorrowedValue, roundTotalSuppliedValue]
  );
  const borrowLimitPercentage = useMemo(
    () => roundBorrowLimitValue / roundTotalSuppliedValue,
    [roundBorrowLimitValue, roundTotalSuppliedValue]
  );
  const liquidationThreshold = useMemo(
    () => roundLiquidationValue / roundTotalSuppliedValue,
    [roundLiquidationValue, roundTotalSuppliedValue]
  );

  const theme = useTheme();
  const barColor = useMemo(() => {
    if (roundBorrowedValue < roundBorrowLimitValue) {
      return theme.skin.success.main;
    }
    if (roundBorrowedValue >= roundBorrowLimitValue && roundBorrowedValue < roundLiquidationValue) {
      return theme.skin.warning.main;
    }
    return theme.skin.error.main;
  }, [roundBorrowLimitValue, roundBorrowedValue, roundLiquidationValue, theme]);

  const barMarkers = useMemo(
    () => [
      {
        value: borrowLimitPercentage * 100,
        color: theme.skin.warning.main,
        tip: {
          content: (
            <>
              <Text>Your borrow limit is at</Text>
              <H5>{formatToCurrency(borrowLimitValue.toNumber())}</H5>
            </>
          )
        }
      },
      {
        value: liquidationThreshold * 100,
        color: theme.skin.error.main,
        tip: {
          content: (
            <>
              <Text>Your liquidation point is at</Text>
              <H5> {formatToCurrency(liquidationPointValue.toNumber())}</H5>
            </>
          )
        }
      }
    ],
    [borrowLimitPercentage, borrowLimitValue, liquidationThreshold, liquidationPointValue, theme]
  );

  return (
    <Container style={{ width }}>
      <ProgressBar
        background={theme.skin.background.slot}
        color={barColor}
        value={borrowedPercentage * 100}
        markers={barMarkers}
      />
    </Container>
  );
};
