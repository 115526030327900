import { noop } from 'lodash';
import { useCallback, useState } from 'react';

import { SupplyERC20ModalProps, SupplyType } from '../modals';

import { ERC20Symbol } from '@/apps/paraspace/typings';

const defaultModalProps: SupplyERC20ModalProps = {
  isOpen: false,
  type: 'AA',
  defaultSymbol: ERC20Symbol.AAVE,
  onClose: noop
};

export const useSupplyERC20 = (): [
  SupplyERC20ModalProps,
  (symbol: ERC20Symbol, type: SupplyType) => Promise<void>
] => {
  const [modalProps, setModalProps] = useState<SupplyERC20ModalProps>(defaultModalProps);

  const supplyERC20 = useCallback(
    (symbol: ERC20Symbol, type: SupplyType) => {
      if (modalProps.isOpen) {
        throw new Error('There is a supplying in progress');
      }
      return new Promise<void>((resolve, reject) => {
        setModalProps(curr => ({
          ...curr,
          isOpen: true,
          defaultSymbol: symbol,
          type,
          onError: reject,
          onFinish: resolve,
          onClose: () => {
            setModalProps(prev => ({ ...prev, isOpen: false }));
            resolve();
          }
        }));
      });
    },
    [modalProps.isOpen]
  );

  return [modalProps, supplyERC20];
};
