import { Pool } from 'paraspace-utilities-contract-helpers';
import { useCallback, useMemo } from 'react';

import submitTransaction from '@/apps/paraspace/utils/submitTransaction';
import { ApeStakingMainTokenSymbol } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

enum ApeCompoundType {
  SwapAndSupply
}

export enum ApeCompoundSellToken {
  USDC,
  ETH // In contract side it should be WETH
}

export type StakingApeProps = {
  type: ApeStakingMainTokenSymbol;
  tokenId: number;
  borrowAmount: number;
  cashAmount: number;
  bakcTokenId?: number;
  bakcBorrowAmount?: number;
  bakcCashAmount?: number;
};
/**
 * @deprecated use usePoolApeStaking hook instead
 * @returns
 */
export const useLegacyPoolApeStaking = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new Pool(provider, {
      POOL: contracts.PoolProxy,
      WETH_GATEWAY: contracts.WETHGatewayProxy
    });
  }, [contracts.PoolProxy, contracts.WETHGatewayProxy, provider]);

  const stakingApe = useCallback(
    async ({
      type,
      tokenId,
      borrowAmount,
      cashAmount,
      bakcTokenId,
      bakcBorrowAmount,
      bakcCashAmount
    }: StakingApeProps) => {
      if (!service || !provider) return null;

      const tx = await service.depositApeCoin({
        address: contracts[type],
        apeCoinAddress: contracts.cAPE,
        tokenId,
        borrowAmount,
        cashAmount,
        bakcTokenId,
        bakcBorrowAmount,
        bakcCashAmount,
        user: account
      });
      return submitTransaction({ provider, tx });
    },
    [service, provider, contracts, account]
  );

  const unstakingApe = useCallback(
    async (
      type: ApeStakingMainTokenSymbol,
      nfts: {
        tokenId: number;
        amount: number;
      }[]
    ) => {
      if (!service || !provider) return null;

      const tx = await service.withdrawApeCoin(contracts[type], nfts, account);
      return submitTransaction({ provider, tx });
    },
    [contracts, provider, service, account]
  );

  const claimApe = useCallback(
    async (type: ApeStakingMainTokenSymbol, tokenIds: number[]) => {
      if (!service || !provider) return null;
      const tx = service.claimApecoin(contracts[type], tokenIds, account);
      return submitTransaction({ provider, tx });
    },
    [contracts, provider, service, account]
  );

  const withdrawBAKC = useCallback(
    async (
      type: ApeStakingMainTokenSymbol,
      tokenId: number,
      bakcTokenId: number,
      bakcAmount: number,
      isUncommit: boolean
    ) => {
      if (!service || !provider) return null;

      const tx = service.withdrawBAKC(contracts[type], account, {
        mainTokenId: tokenId,
        bakcTokenId,
        amount: bakcAmount,
        isUncommit
      });
      return submitTransaction({ provider, tx });
    },
    [contracts, provider, service, account]
  );

  const claimBAKC = useCallback(
    async (type: ApeStakingMainTokenSymbol, tokenId: number, bakcTokenId: number) => {
      if (!service || !provider) return null;
      const tx = service.claimBAKC(contracts[type], account, [
        {
          mainTokenId: tokenId,
          bakcTokenId
        }
      ]);
      return submitTransaction({ provider, tx });
    },
    [contracts, provider, service, account]
  );

  const batchClaimBAKC = useCallback(
    async (
      type: ApeStakingMainTokenSymbol,
      // all those pairs must share the same main token type
      pairs: { mainTokenId: number; bakcTokenId: number }[]
    ) => {
      if (!service || !provider) return null;
      const tx = service.claimBAKC(contracts[type], account, pairs);
      return submitTransaction({ provider, tx });
    },
    [contracts, provider, service, account]
  );

  const getUserApeCompoundStrategy = useCallback(async () => {
    if (!service || !provider) return null;
    return service.getUserApeCompoundStrategy(account);
  }, [account, provider, service]);

  const setUserApeCompoundStrategy = useCallback(
    async (swapPercent: number, swapTokenOut: ApeCompoundSellToken) => {
      if (!service || !provider) return null;
      const tx = service.setApeCompoundStrategy(
        {
          ty: ApeCompoundType.SwapAndSupply,
          swapTokenOut,
          swapPercent
        },
        account
      );
      return submitTransaction({ provider, tx });
    },
    [account, provider, service]
  );

  return {
    stakingApe,
    unstakingApe,
    claimApe,
    withdrawBAKC,
    claimBAKC,
    batchClaimBAKC,
    getUserApeCompoundStrategy,
    setUserApeCompoundStrategy
  };
};
