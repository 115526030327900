import { memo, useMemo } from 'react';
import { isNil, values } from 'lodash';
import { InfoPanel, TokenInput } from '@parallel-mono/business-components';
import { Alert, Button, Stack, StackProps, Text, Spinner } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import { NetApyExplanation } from '../../component/NetApyExplanation';
import { StakeBakcFormData } from '../../types';

import { ApeSelector } from './ApeSelector';
import { useStatesAndHandlers } from './hooks';

import { NumberRange } from '@/apps/paraspace/components';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { isNumeric } from '@/apps/paraspace/utils';
import { useApeListStatesAndActions } from '@/apps/paraspace/pages/ApePairing/contexts';
import { ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

type BakcStackFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  bakcTokenId: number;
  apeCoinSource: WalletType;
  bakcSource: Maybe<WalletType>;
  onSubmit: (formData: StakeBakcFormData) => void;
};

export const BakcStakeForm = memo(
  ({ bakcTokenId, apeCoinSource, bakcSource, onSubmit, ...others }: BakcStackFormProps) => {
    const { apesInBalanceAndInSuppliedExcludingInP2P, apeInfoListLoaded } =
      useApeListStatesAndActions();
    const {
      isLoading,
      netApy,
      compoundPoolApy,
      borrowApyRate,
      errors,
      balance,
      amountFromBalance,
      amountFromCredit,
      stakedAmount,
      maxAmountFromCredit,
      handleSubmit,
      handleBalanceAmountChange,
      handleCreditAmountChange,
      handleApeTokenChange,
      handleMaxAmountFromCredit,
      handleMaxAmountFromBalance,
      apeTokens,
      defaultApeToken
    } = useStatesAndHandlers({
      bakcTokenId,
      onSubmit,
      apesAndBakc: apesInBalanceAndInSuppliedExcludingInP2P,
      apeCoinSource,
      bakcSource
    });

    const isPaired = useMemo(
      () =>
        apesInBalanceAndInSuppliedExcludingInP2P
          .find(({ tokenId, symbol }) => tokenId === bakcTokenId && symbol === ERC721Symbol.BAKC)
          ?.stakedAmount?.gt(0),
      [apesInBalanceAndInSuppliedExcludingInP2P, bakcTokenId]
    );

    const totalAmount = stakedAmount + (amountFromCredit ?? 0) + (amountFromBalance ?? 0);

    const formInfos = useMemo(() => {
      return [
        {
          title: 'Total Staked',
          value: (
            <NumberRange
              start={stakedAmount}
              end={totalAmount}
              formatter={formatNumber}
              symbol="APE"
            />
          )
        },
        {
          title: 'Net APY',
          value: formatNumber(isNumeric(netApy) ? netApy : 0, { output: 'percent' }),
          tip: (
            <NetApyExplanation
              amountFromBalance={amountFromBalance}
              amountFromCredit={amountFromCredit}
              rewardApyRate={compoundPoolApy}
              netApyRate={netApy}
              borrowApyRate={borrowApyRate}
            />
          )
        }
      ];
    }, [
      stakedAmount,
      totalAmount,
      netApy,
      amountFromBalance,
      amountFromCredit,
      compoundPoolApy,
      borrowApyRate
    ]);

    const hasError = useMemo(() => values(errors).some(error => !isNil(error)), [errors]);

    const errMsg = useMemo(() => {
      const { totalAmount: totalAmountMsg, newStakeAmount: newStakeAmountMsg } = errors;
      return totalAmountMsg || newStakeAmountMsg;
    }, [errors]);

    if (isLoading) {
      return (
        <Stack inset="2rem" alignItems="center" {...others}>
          <Spinner size="large" />
        </Stack>
      );
    }

    return (
      <Stack {...others}>
        {!isPaired && (
          <ApeSelector
            onChange={handleApeTokenChange}
            loading={!apeInfoListLoaded}
            tokenList={apeTokens}
            defaultSelectedToken={defaultApeToken}
          />
        )}

        <TokenInput
          inputProps={{
            inputProps: {
              autoFocus: true,
              disabled: balance?.lte(0)
            }
          }}
          value={amountFromBalance}
          actionButtonText="Max"
          hint={<Text skin="secondary">Balance: {formatNumber(balance ?? 0)} APE</Text>}
          label={<Text fontWeight="bold">Wallet</Text>}
          token="APE"
          decimals={MAXIMUM_BALANCE_DECIMALS}
          onChange={handleBalanceAmountChange}
          onAction={handleMaxAmountFromBalance}
          error={errors.amountFromBalance}
        />
        <TokenInput
          value={amountFromCredit}
          actionButtonText="Max"
          hint={<Text skin="secondary">MAX: {formatNumber(maxAmountFromCredit)} cAPE</Text>}
          label={<Text fontWeight="bold">Pair from $cAPE Pools</Text>}
          token="CAPE"
          decimals={MAXIMUM_BALANCE_DECIMALS}
          onChange={handleCreditAmountChange}
          onAction={handleMaxAmountFromCredit}
          error={errors.amountFromCredit}
        />
        <InfoPanel skin="primary" infos={formInfos} />
        <Alert type="info">
          Selling either of the NFTs in the pair prior to unstaking will result in the following:
          BAYC/MAYC owners are entitled the staked amount, with BAKC owners entitled to unclaimed
          rewards. Only users who own both NFTs have rights to the entire staking position.
        </Alert>
        {errMsg && <Alert type="error">{errMsg}</Alert>}
        <Button disabled={hasError} block size="large" onClick={handleSubmit}>
          {errors.apeTokenId ?? 'Pair'}
        </Button>
      </Stack>
    );
  }
);
