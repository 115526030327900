import BigNumberJS from 'bignumber.js';
import { useMemo } from 'react';

import { formatToPercentage } from '@/apps/paraspace/utils/format';

export const useOfferInfoTip = ({
  topOfferPriceInUsd,
  selectedTokenPriceInUsd,
  offerAmount
}: {
  topOfferPriceInUsd?: BigNumberJS;
  selectedTokenPriceInUsd: BigNumberJS;
  offerAmount: BigNumberJS;
}) => {
  const offerPriceInUsd = useMemo(
    () => selectedTokenPriceInUsd?.times(offerAmount),
    [selectedTokenPriceInUsd, offerAmount]
  );

  return useMemo(() => {
    if (offerPriceInUsd.gte(topOfferPriceInUsd ?? 0)) {
      return '100% higher than top bid';
    }
    const percentage = topOfferPriceInUsd
      ? topOfferPriceInUsd.minus(offerPriceInUsd).div(topOfferPriceInUsd)
      : 0;
    return `${formatToPercentage(percentage)} lower than top bid`;
  }, [offerPriceInUsd, topOfferPriceInUsd]);
};
