import { useCallback, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';

import { ListingType, ListingTypeToListingMap } from '../types';
import { selectedPoolToStakingType } from '../utils';

import { formatApeCoinListing, formatApeListing } from './utils';

import {
  P2PApeStakingListing,
  useGetP2PApeStakingListingsLazyQuery
} from '@/apps/paraspace/generated/graphql';
import { ApeStakingTokenSymbol, ApeStakingMainAssetSymbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useContractsMap, useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { convertToChecksumAddress } from '@/apps/paraspace/utils/convertToChecksumAddress';

const DEFAULT_PAGE_LIMIT = 6;

export const useListings = <T extends ListingType>(
  stakingPool: ApeStakingTokenSymbol,
  listingType: T,
  symbol: ApeStakingMainAssetSymbol[]
): {
  listings: ListingTypeToListingMap<T>[];
  loadMore: () => Promise<void>;
  refresh: () => Promise<void>;
  loading: boolean;
  hasMore: boolean;
  updateCachedData: (
    updater: (cachedData: P2PApeStakingListing[]) => P2PApeStakingListing[]
  ) => boolean;
} => {
  const contracts = useContractsMap();

  const { erc20InfoMap } = useMMProvider();
  const stakingType = selectedPoolToStakingType[stakingPool];

  const token = useMemo(
    () =>
      listingType === ListingType.ApeCoinListing
        ? [erc20InfoMap?.CAPE?.address, convertToChecksumAddress(erc20InfoMap?.CAPE?.address)]
        : symbol.map(i => contracts[i]),
    [erc20InfoMap?.CAPE?.address, listingType, symbol, contracts]
  );

  const [hasMore, setHasMore] = useState(true);

  const [, { fetchMore, loading, refetch, data, client }] = useGetP2PApeStakingListingsLazyQuery({
    variables: {
      listingType,
      filter: {
        stakingType: {
          eq: stakingType
        },
        token: {
          in: token
        }
      },
      pageInfo: {
        limit: DEFAULT_PAGE_LIMIT,
        offset: 0
      }
    },
    notifyOnNetworkStatusChange: true
  });

  const loadMore = useCallback(() => {
    return fetchMore({
      variables: {
        stakingType,
        token: {
          in: token
        },
        pageInfo: {
          limit: DEFAULT_PAGE_LIMIT,
          offset: data?.P2PApeStakingListings?.length ?? 0
        }
      }
    }).then(result => {
      if ((result.data?.P2PApeStakingListings?.length ?? 0) < DEFAULT_PAGE_LIMIT) {
        setHasMore(false);
      }
    });
  }, [fetchMore, stakingType, token, data?.P2PApeStakingListings?.length]);

  const updateCachedData = useCallback(
    (updater: (cachedData: P2PApeStakingListing[]) => P2PApeStakingListing[]) =>
      client.cache.modify({
        fields: {
          P2PApeStakingListings: updater as any
        }
      }),
    [client]
  );

  const getSymbolByContractAddress = useGetSymbolByContractAddress();

  const listings = useMemo(() => {
    if (listingType !== ListingType.ApeCoinListing) {
      return uniqBy(
        data?.P2PApeStakingListings?.map(listing =>
          formatApeListing(listing, getSymbolByContractAddress)
        ) ?? [],
        'listingHash'
      );
    }
    return uniqBy(
      data?.P2PApeStakingListings?.map(listing => formatApeCoinListing(listing)) ?? [],
      'listingHash'
    );
  }, [
    data?.P2PApeStakingListings,
    listingType,
    getSymbolByContractAddress
  ]) as ListingTypeToListingMap<T>[];

  const refresh = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return { listings, loadMore, refresh, loading, hasMore, updateCachedData };
};
