import React, { FC, ReactElement } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { Text, Inline, H3, Button, Stack, Icon } from '@parallel-mono/components';

import { Collapse, Link } from '@/apps/paraspace/components';
import { DEVELOPER_DOCS_LINK } from '@/apps/paraspace/consts/externalLinks';

const FaqButton = styled(Inline).attrs({
  justifyContent: 'center',
  alignItems: 'center'
})`
  position: absolute;
  background-color: var(--clr-blue);
  width: 2.5rem;
  height: 2.5rem;
  right: -2.5rem;
  top: 3rem;
  cursor: pointer;
  border-radius: 0rem 0.75rem 0.75rem 0;
  margin-top: 0;
`;

const FaqWrapper = styled(animated.div)`
  position: absolute;
  top: 0rem;
  height: 100%;
  width: 24.5rem;
  padding: 2rem;
  padding-left: 3rem;
  background-color: ${({ theme }) => theme.skin.background.main};
  z-index: -1;
  margin-top: 0;
  overflow-y: scroll;
`;
const FaqList = styled(Stack)`
  & > div:not(:last-child) {
    border-radius: 0;
    border-bottom: 1px solid ${({ theme }) => theme.skin.grey[200]};
  }
`;

type Faq = { title: string; detail: string | ReactElement };
const faqs: Record<string, Faq[]> = {
  buyNow: [
    {
      title: 'What is Buy With Credit?',
      detail:
        'Buy With Credit allows everyone to buy their dream NFT with just a small upfront payment, and pay it off later at their own schedule. The NFT would be collateralised after the purchase until the outstanding debt (amount borrowed to purchase the NFT) is fully paid off. This makes even the most sought after NFTs more accessible to the wider communities.'
    },
    {
      title: 'What are the benefits of using Buy With Credit?',
      detail:
        'The main benefit of Buy With Credit is letting users to purchase NFTs at a fraction of the original costs, allowing more people to capture the potential upside of the NFTs with a lower barrier to entry. '
    },
    {
      title: 'What are the risks?',
      detail:
        'The biggest risk of Buy With Credit is the liquidation risk caused by the decrease in NFT asset price. Once the floor price of the collection drops to certain levels, liquidation of the asset might take place in order to repay the outstanding debt.'
    },
    {
      title: 'Where can I learn more?',
      detail: (
        <>
          You can find more about the Buy With Credit feature and other documentations on other
          aspects of Para.space on our official Gitbook{' '}
          <Link
            target="_blank"
            href={`${DEVELOPER_DOCS_LINK}/paraspace-as-first-cross-margin-nft-lending-protocol`}
          >
            here.
          </Link>
        </>
      )
    }
  ]
};

type Props = {
  showFaqs: boolean;
  setShowFaqs: (showFaqs: boolean) => void;
};

export const FAQ: FC<Props> = ({ showFaqs, setShowFaqs: toggleFaqs }) => {
  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showFaqs ? 1 : 0,
    right: showFaqs ? '-23.5rem' : '0'
  });
  if (!showFaqs) {
    return (
      <FaqButton onClick={() => toggleFaqs(true)}>
        <Icon name="questionCircle" width="1.5rem" color="#FFFFFF" strokeWidth={2} />
      </FaqButton>
    );
  }
  return (
    <FaqWrapper style={animation}>
      <div>
        <Inline justifyContent="space-between">
          <Inline gap="0.25rem" alignItems="center">
            <Icon name="questionCircle" width="1.5rem" strokeWidth={2} />
            <H3>Common Questions</H3>
          </Inline>

          <Button variant="link" onClick={() => toggleFaqs(false)}>
            Hide
          </Button>
        </Inline>
        <FaqList gap="0" inset="1rem 0">
          {faqs.buyNow.map((faq, index) => (
            <Collapse key={faq.title} inset="1rem 0" header={faq.title} defaultOpen={index === 0}>
              <Text skin="secondary">{faq.detail}</Text>
            </Collapse>
          ))}
        </FaqList>
      </div>
    </FaqWrapper>
  );
};
