import { Button, ButtonProps } from '@parallel-mono/components';
import { useCallback } from 'react';

import { useWeb3Context } from '@/apps/paraspace/contexts';

export type ConnectWalletProps = {
  btnTxt: string;
  btnSize?: ButtonProps['size'];
  skin?: ButtonProps['skin'];
  block?: ButtonProps['block'];
  buttonProps?: ButtonProps;
  onConnectWallet?: () => void;
};

export const ConnectWallet = ({
  btnTxt,
  btnSize = 'large',
  skin = 'secondary',
  block = true,
  buttonProps,
  onConnectWallet
}: ConnectWalletProps) => {
  const { connectWallet } = useWeb3Context();
  const handleClick = useCallback(() => {
    onConnectWallet?.();
    connectWallet();
  }, [connectWallet, onConnectWallet]);

  return (
    <Button size={btnSize} onClick={handleClick} skin={skin} block={block} {...buttonProps}>
      {btnTxt}
    </Button>
  );
};
