import styled, { CSSProperties, useTheme } from 'styled-components';
import {
  Button,
  ButtonProps,
  Card,
  H1,
  H2,
  H3,
  H4,
  Inline,
  RangeSlider,
  Responsive,
  SmallText,
  Stack,
  Text,
  Toggle,
  Typography,
  TypographyVariant,
  useBreakpoints
} from '@parallel-mono/components';
import { FC, useCallback, useState } from 'react';
import { formatNumber } from '@parallel-mono/utils';
import BigNumberJs from 'bignumber.js';

import {
  DEFAULT_PERCENT_DECIMAL,
  HIDDEN_DECIMAL_PERCENT,
  MAX_PERCENT,
  MIN_PERCENT_DECIMAL
} from '@/apps/paraspace/pages/config';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { zero } from '@/apps/paraspace/consts/values';
import { ApeStakingTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { Link, LogoIcon, PercentageInput, Tooltip } from '@/apps/paraspace/components';
import { DEVELOPER_DOCS_LINK } from '@/apps/paraspace/consts/externalLinks';

const ProjectedRewardsTitle = styled(H4)`
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
`;
const ProjectedRewardsContainer = styled(Card).attrs({ block: true, border: true })`
  width: 100%;
  display: flex;
  margin-bottom: 1.5rem;
  padding: 0;

  ${({ theme }) => theme.breakpoints.down('tablet')`
    flex-direction: column;
  `};
`;

const ProjectedRewardsColumn = styled(Stack).attrs({
  alignItems: 'center',
  gap: '0'
})`
  padding: 2.5rem 0;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('tablet')`
    padding: 2rem 1.5rem;
  `};

  &:not(:last-child) {
    border-right: ${({ theme }) => `1px solid ${theme.skin.grey['200']};`};
    ${({ theme }) => theme.breakpoints.down('tablet')`
      border-right: none;
      border-bottom: 1px solid ${theme.skin.grey['200']};
    `};
  }
`;

const Title = styled(H3)``;

const GreyText = styled(H4)`
  color: ${({ theme }) => `${theme.skin.grey[500]};`};
`;

const GreyHint = styled(Typography).attrs({
  variant: TypographyVariant.body
})`
  color: ${({ theme }) => `${theme.skin.grey[700]};`};
`;

const ResponsiveButton: FC<ButtonProps> = ({ children, ...rest }) => {
  const { tablet } = useBreakpoints();
  return (
    <Button {...rest} size={tablet ? 'small' : 'medium'}>
      {children}
    </Button>
  );
};

const StyledButton = styled(ResponsiveButton)`
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
`;

const StyledWarnButton = styled(StyledButton).attrs({ disabled: true })`
  margin-top: auto;
  color: ${({ theme }) => `${theme.skin.error.main} !important;`};
  background: ${({ theme }) => `${theme.skin.error.contrastText} !important;`};
`;

const Gap = styled.div<{ height: CSSProperties['height'] }>`
  height: ${({ height }) => height};
`;

const StyledRangeSlider = styled(RangeSlider)`
  width: 100%;
`;

const ResponsiveHeader: FC<any> = ({ children, ...rest }) => {
  const { mobile } = useBreakpoints();
  return mobile ? <H2 {...rest}>{children}</H2> : <H1 {...rest}>{children}</H1>;
};

const toPercentage = (apy: BigNumberJs) => {
  return formatNumber(apy, {
    output: 'percent',
    decimal:
      apy.toNumber() >= HIDDEN_DECIMAL_PERCENT ? MIN_PERCENT_DECIMAL : DEFAULT_PERCENT_DECIMAL,
    threshold: {
      max: MAX_PERCENT
    }
  });
};

const ANY_APR_UPPER_BOUND = 20;

export const ProjectedRewardsBox = ({
  symbol,
  onBorrowSliderChange,
  paraspaceRewardsInfo,
  customizedRewardsInfo,
  officialRewardsInfo,

  inputRawApr,
  handleInputRawAprChange,
  handleNav
}: {
  symbol?: ApeStakingTokenSymbol;
  onBorrowSliderChange?: (value: number) => void;
  paraspaceRewardsInfo: {
    apy: Maybe<BigNumberJs>;
    yearlyRewardsInApe: Maybe<BigNumberJs>;
    yearlyRewardsInUsd: Maybe<BigNumberJs>;
  };
  customizedRewardsInfo: {
    customizedApr: Maybe<BigNumberJs>;
    customizedYearlyRewardsInApe: Maybe<BigNumberJs>;
    customizedYearlyRewardsInUsd: Maybe<BigNumberJs>;
  };
  officialRewardsInfo: {
    officialApr: Maybe<BigNumberJs>;
    officialYearlyRewardsInApe: Maybe<BigNumberJs>;
    officialYearlyRewardsInUsd: Maybe<BigNumberJs>;
  };

  inputRawApr: number;
  handleInputRawAprChange: (newApr: number) => void;
  handleNav: () => void;
}) => {
  const { apy, yearlyRewardsInApe, yearlyRewardsInUsd } = paraspaceRewardsInfo;

  const { customizedApr, customizedYearlyRewardsInApe, customizedYearlyRewardsInUsd } =
    customizedRewardsInfo;

  const { officialApr, officialYearlyRewardsInApe, officialYearlyRewardsInUsd } =
    officialRewardsInfo;

  const customLessThanPara = (apy ?? zero).minus(customizedApr ?? zero);
  const officialLessThanPara = (apy ?? zero).minus(officialApr ?? zero);

  const handleSetInputRawApr = useCallback(
    newValue => {
      handleInputRawAprChange(newValue);
    },
    [handleInputRawAprChange]
  );
  const [borrorPosition, setBorrowPosition] = useState(0);
  const handleBorrowSliderChange = useCallback(
    (value: number) => {
      setBorrowPosition(value);
      onBorrowSliderChange?.(value);
    },
    [onBorrowSliderChange]
  );

  const isApeMainToken = [ERC721Symbol.BAYC, ERC721Symbol.MAYC].includes(symbol as ERC721Symbol);

  const theme = useTheme();

  return (
    <>
      <ProjectedRewardsTitle>Projected Rewards</ProjectedRewardsTitle>
      <ProjectedRewardsContainer>
        <Responsive breakPoint="tablet" width="100%" gap="0">
          {/* paraspace apy */}
          <ProjectedRewardsColumn>
            <LogoIcon />
            <Gap height="2.5rem" />
            <ResponsiveHeader skin="success">{toPercentage(apy ?? zero)}</ResponsiveHeader>
            <GreyHint>{isApeMainToken ? 'Net Compound APY' : 'Compound APY'}</GreyHint>
            <Gap height="1.5rem" />
            <Inline gap="0.5rem" alignItems="baseline">
              <ResponsiveHeader skin="success">
                {formatNumber(yearlyRewardsInApe ?? zero)}
              </ResponsiveHeader>
              <GreyText>APE</GreyText>
            </Inline>
            <GreyHint>${formatNumber(yearlyRewardsInUsd ?? zero)} yearly</GreyHint>
            <Gap height="2.5rem" />
            {isApeMainToken && (
              <>
                <Stack gap=".75rem" width="100%" inset="0 2.5rem">
                  <Inline gap="0" justifyContent="space-between">
                    <Inline gap=".25rem">
                      <SmallText skin="secondary">Borrow</SmallText>
                      <Tooltip
                        content={
                          <Text>
                            You can stake even if you don’t have any $APE by borrowing from
                            ParaSpace.{' '}
                            <Link
                              href={`${DEVELOPER_DOCS_LINK}/staking-and-derivative-token-yield-management/apecoin-staking-with-bored-ape-yacht-club-nfts#deposit-and-stake-unstake-and-withdraw`}
                              target="_blank"
                            >
                              Learn more
                            </Link>
                          </Text>
                        }
                      />
                    </Inline>
                    <SmallText skin="secondary">
                      {formatNumber(borrorPosition, { output: 'percent' })} of Position
                    </SmallText>
                  </Inline>
                  <StyledRangeSlider
                    defaultValue={0}
                    max={1}
                    min={0}
                    onChange={handleBorrowSliderChange}
                    step={0.01}
                    sliderStyle={{ background: theme.skin.primary.main }}
                  />
                  <Inline gap="0" justifyContent="space-between">
                    <Inline gap=".25rem">
                      <SmallText skin="secondary">Repay Weekly</SmallText>
                      <Tooltip content="Maximise your yield by repaying regularly to lower borrow interests. Calculation assumes weekly repayment of outstanding interests." />
                    </Inline>
                    <Tooltip content="Coming soon">
                      <Toggle disabled />
                    </Tooltip>
                  </Inline>
                </Stack>
                <Gap height="2.5rem" />
              </>
            )}
            <StyledButton onClick={handleNav}>Earn Compound APY</StyledButton>
          </ProjectedRewardsColumn>

          {/* any apr */}
          <ProjectedRewardsColumn>
            <Title>Calculate Any APR</Title>
            <Gap height="2.5rem" />

            <PercentageInput
              width="7.75rem"
              max={ANY_APR_UPPER_BOUND}
              decimals={4}
              value={inputRawApr}
              onChange={handleSetInputRawApr}
              typography={TypographyVariant.header1}
            />

            <Gap height="1.5rem" />
            <Inline gap="0.5rem" alignItems="baseline">
              <ResponsiveHeader>
                {formatNumber(customizedYearlyRewardsInApe ?? zero)}
              </ResponsiveHeader>
              <GreyText>APE</GreyText>
            </Inline>
            <GreyHint>${formatNumber(customizedYearlyRewardsInUsd ?? zero)} yearly</GreyHint>
            <Gap height="2.5rem" />
            {customLessThanPara.gt(0) && (
              <StyledWarnButton>
                {toPercentage(customLessThanPara)} less than ParaSpace
              </StyledWarnButton>
            )}
          </ProjectedRewardsColumn>

          {/* official apr */}
          <ProjectedRewardsColumn>
            <Title>Horizen Labs</Title>
            <Gap height="2.5rem" />
            <ResponsiveHeader>{toPercentage(officialApr ?? zero)}</ResponsiveHeader>
            <GreyHint>APR</GreyHint>
            <Gap height="1.5rem" />
            <Inline gap="0.5rem" alignItems="baseline">
              <ResponsiveHeader>
                {formatNumber(officialYearlyRewardsInApe ?? zero)}
              </ResponsiveHeader>
              <GreyText>APE</GreyText>
            </Inline>
            <GreyHint>${formatNumber(officialYearlyRewardsInUsd ?? zero)} yearly</GreyHint>
            <Gap height="2.5rem" />
            {officialLessThanPara.gt(0) && (
              <StyledWarnButton>
                {toPercentage(officialLessThanPara)} less than ParaSpace
              </StyledWarnButton>
            )}
          </ProjectedRewardsColumn>
        </Responsive>
      </ProjectedRewardsContainer>
    </>
  );
};
