import { useMemo } from 'react';
import { BigNumber } from 'bignumber.js';

import { emptyArray } from '@/apps/paraspace/consts/values';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ApeStakingMainTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import { useMainToBakcMap } from '@/apps/paraspace/pages/hooks/ApeStaking/useMainToBakcMap';
import useBatchApeStakingInfo from '@/apps/paraspace/pages/Credit/MoneyMarket/Modal/hook/useBatchApeStakingInfo';

export const useApeStakingInfo = ({
  symbol,
  tokenIds
}: {
  symbol: ERC721Symbol;
  tokenIds: number[];
}) => {
  const {
    nftInfoMap: { [symbol]: { nftCollateralList = emptyArray, nftSuppliedList = emptyArray } = {} }
  } = useMMProvider();

  const showApeStakingInfo = isNFTinvolvedWithApeStaking(symbol);

  const { stakingInfo, totalStakingInfo } = useBatchApeStakingInfo(
    symbol,
    nftCollateralList,
    tokenIds,
    showApeStakingInfo
  );

  const mainTokens = useMemo(() => {
    return nftSuppliedList
      .map(tokenId => {
        if (showApeStakingInfo) {
          return {
            tokenId,
            symbol: symbol as ApeStakingMainTokenSymbol
          };
        }
        return null;
      })
      .filter((v): v is { tokenId: number; symbol: ApeStakingMainTokenSymbol } => !!v);
  }, [nftSuppliedList, showApeStakingInfo, symbol]);

  const [mainToBakcMap] = useMainToBakcMap(mainTokens);

  const bakcStakingInfo = useMemo(() => {
    if (!showApeStakingInfo) {
      return {};
    }
    return symbol === ERC721Symbol.BAKC ? stakingInfo : mainToBakcMap?.[symbol] || {};
  }, [mainToBakcMap, showApeStakingInfo, stakingInfo, symbol]);

  const [bakcTotalStakingAmount, bakcTotalRewardAmount] = useMemo(() => {
    return [
      BigNumber.sum(...tokenIds.map(id => bakcStakingInfo[id]?.stakedAmount || new BigNumber(0))),
      BigNumber.sum(...tokenIds.map(id => bakcStakingInfo[id]?.pendingRewards || new BigNumber(0)))
    ];
  }, [bakcStakingInfo, tokenIds]);

  return {
    stakingInfo,
    totalStakingInfo,
    bakcStakingInfo,
    bakcTotalStakingAmount,
    bakcTotalRewardAmount
  };
};
