import { Icon, Inline, InlineProps } from '@parallel-mono/components';
import { memo, useCallback } from 'react';
import styled from 'styled-components';

export type FilterPillProps = {
  showArrow?: boolean;
  active: boolean;
  onToggle: (active: boolean) => void;
} & Omit<InlineProps, 'children' | 'onClick'>;

const Arrow = styled(Icon)<{ direction: 'left' | 'right' }>`
  transform-origin: center;
  transform: rotate(${({ direction }) => (direction === 'left' ? '90' : '-90')}deg);
  transition: transform 0.2s;
`;

export const Pill = styled(Inline).attrs({
  alignItems: 'center'
})<{ active: boolean }>`
  height: 2.5rem;
  padding: 0 1rem;
  &:hover {
    cursor: pointer;
  }
  border: 2px solid
    ${({ theme, active }) => (active ? theme.skin.primary.main : theme.skin.grey[200])};
  background: ${({ theme }) => theme.skin.background.main};
  border-radius: 10rem;
`;

export const FilterPill = memo((props: FilterPillProps) => {
  const { active, onToggle, showArrow = true, ...others } = props;
  const handleClick = useCallback(() => {
    onToggle(!active);
  }, [active, onToggle]);
  return (
    <Pill active={active} gap="0.5rem" {...others} onClick={handleClick}>
      {showArrow && (
        <Arrow name="chevronDown" size="0.875rem" direction={active ? 'left' : 'right'} />
      )}
      <Icon name="filter" size="1.25rem" strokeWidth={2} />
    </Pill>
  );
});
