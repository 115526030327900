import { useCallback, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { isNil } from 'lodash';

import { Maybe } from '@/apps/paraspace/typings/basic';

export const useFormHandler = ({
  listingPrice,
  walletBalance,
  futureAvailableCredit
}: {
  listingPrice: BigNumber;
  walletBalance: BigNumber;
  futureAvailableCredit: BigNumber;
}) => {
  const [payAmount, setPayAmount] = useState<Maybe<number>>(null);
  const [borrowAmount, setBorrowAmount] = useState<Maybe<number>>(null);

  const [usingBalance, setUsingBalance] = useState(futureAvailableCredit.lt(listingPrice));

  const handleSetMaxPayAmount = useCallback(() => {
    const requiredPayAmount = BigNumber.minimum(walletBalance, listingPrice).decimalPlaces(
      4,
      BigNumber.ROUND_FLOOR
    );
    setPayAmount(requiredPayAmount.toNumber());
    const requiredBorrowAmount = BigNumber.maximum(listingPrice.minus(requiredPayAmount), 0);

    setBorrowAmount(requiredBorrowAmount.toNumber());
  }, [walletBalance, listingPrice]);

  const handlePayAmountChange = useCallback(
    (value: number | null) => {
      if (value && BigNumber.minimum(listingPrice, walletBalance).lte(value)) {
        handleSetMaxPayAmount();
        return;
      }
      setPayAmount(value);
      const requiredBorrowAmount = listingPrice.minus(value ?? 0);
      setBorrowAmount(requiredBorrowAmount.toNumber());
    },
    [handleSetMaxPayAmount, listingPrice, walletBalance]
  );

  const handleSetMaxBorrowAmount = useCallback(() => {
    const requiredBorrowAmount = BigNumber.minimum(
      futureAvailableCredit,
      listingPrice
    ).decimalPlaces(4, BigNumber.ROUND_FLOOR);
    setBorrowAmount(requiredBorrowAmount.toNumber());

    const requiredPayAmount = BigNumber.maximum(listingPrice.minus(requiredBorrowAmount), 0);
    if (requiredPayAmount.gt(0)) {
      setUsingBalance(true);
    }
    setPayAmount(requiredPayAmount.toNumber());
  }, [futureAvailableCredit, listingPrice]);

  const handleBorrowAmountChange = useCallback(
    (value: number | null) => {
      if (value && BigNumber.minimum(futureAvailableCredit, listingPrice).lte(value)) {
        handleSetMaxBorrowAmount();
        return;
      }
      setBorrowAmount(value);
      const requiredPayAmount = listingPrice.minus(value ?? 0);
      if (requiredPayAmount.gt(0)) {
        setUsingBalance(true);
      }
      setPayAmount(requiredPayAmount.toNumber());
    },
    [futureAvailableCredit, handleSetMaxBorrowAmount, listingPrice]
  );

  const handleUsingBalance = useCallback(
    ({ checked }: { checked: boolean }) => {
      setUsingBalance(checked);
      if (!checked) {
        handlePayAmountChange(0);
      }
    },
    [handlePayAmountChange]
  );

  useEffect(() => {
    if (isNil(borrowAmount) && isNil(payAmount)) {
      handleSetMaxBorrowAmount();
    }
  }, [borrowAmount, handleSetMaxBorrowAmount, payAmount]);

  return {
    payAmount,
    borrowAmount,
    handlePayAmountChange,
    handleBorrowAmountChange,
    handleSetMaxPayAmount,
    handleSetMaxBorrowAmount,
    usingBalance,
    handleUsingBalance
  };
};
