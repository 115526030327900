import { Stack, H5, Image, StackProps } from '@parallel-mono/components';
import { FC } from 'react';

import metamask from '@/apps/paraspace/assets/meta-mask.svg';

export const ApproveWallet: FC<Omit<StackProps, 'children'>> = ({ children, ...others }) => (
  <Stack alignItems="center" {...others}>
    <Stack alignItems="center" gap="1rem">
      <Image src={metamask} width="2rem" height="2rem" />
      <H5>Please approve on your wallet.</H5>
    </Stack>
    {children}
  </Stack>
);
