import BigNumberJs from 'bignumber.js';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { SummaryData, useFetchPlatformSummaryLazyQuery } from '@/apps/paraspace/generated/graphql';
import { Network } from '@/apps/paraspace/config';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useAppConfig } from '@/apps/paraspace/hooks';

type PlatformSummaryContextData = {
  lending: Maybe<{
    totalBorrow: BigNumberJs;
    totalSuppliedNFT: BigNumberJs;
    totalSupply: BigNumberJs;
  }>;
  apeStaking: Maybe<{
    apeCoinStaked: BigNumberJs;
    bakcStaked: BigNumberJs;
    baycStaked: BigNumberJs;
    maycStaked: BigNumberJs;
  }>;
  liquidation: Maybe<{
    inAuction: BigNumberJs;
    inLiquidation: BigNumberJs;
    totalAuction: BigNumberJs;
  }>;
  shop: Maybe<{
    availableNFT: BigNumberJs;
    volume: BigNumberJs;
    purchased: BigNumberJs;
  }>;
};

type PlatformSummaryProviderProps = {
  children: ReactNode;
};

const PlatFormSummaryContext = createContext<PlatformSummaryContextData>({
  apeStaking: null,
  lending: null,
  shop: null,
  liquidation: null
});

const summaryQuery = gql`
  query fetchPlatformSummary {
    summary {
      lending {
        totalBorrow
        totalSuppliedNFT
        totalSupply
      }
      apeStaking {
        apeCoinStaked
        bakcStaked
        baycStaked
        maycStaked
      }
      liquidation {
        inAuction
        inLiquidation
        totalAuction
      }
      shop {
        availableNFT
        purchased
        volume
      }
    }
  }
`;
export const PlatformSummaryProvider = ({ children }: PlatformSummaryProviderProps) => {
  const { chainId } = useWeb3Context();
  const [summaries, setSummaries] = useState<{
    v1Summary?: SummaryData;
    v2Summary?: SummaryData;
  }>({});

  const [getSummary] = useFetchPlatformSummaryLazyQuery();
  const { v1EthereumGraphqlEndPoint } = useAppConfig();

  const { v1Summary, v2Summary } = summaries;

  const newClient = useMemo(
    () =>
      new ApolloClient({
        uri: v1EthereumGraphqlEndPoint,
        cache: new InMemoryCache()
      }),
    [v1EthereumGraphqlEndPoint]
  );

  const updateSummaries = useCallback(async () => {
    const [v2, v1] = await Promise.all([
      getSummary({ fetchPolicy: 'no-cache' }),
      [Network.MAINNET, Network.SEPOLIA].includes(chainId)
        ? newClient.query({
            query: summaryQuery,
            fetchPolicy: 'no-cache'
          })
        : undefined
    ]);
    setSummaries({
      v1Summary: v1?.data?.summary,
      v2Summary: v2.data?.summary
    });
  }, [chainId, getSummary, newClient]);

  useEffect(() => {
    updateSummaries();
    const timer = setInterval(() => {
      updateSummaries();
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [updateSummaries]);

  const contextData = useMemo(
    () => ({
      lending: {
        totalBorrow: BigNumberJs(v2Summary?.lending?.totalBorrow ?? 0).plus(
          v1Summary?.lending?.totalBorrow ?? 0
        ),
        totalSuppliedNFT: BigNumberJs(v2Summary?.lending?.totalSuppliedNFT ?? 0).plus(
          v1Summary?.lending?.totalSuppliedNFT ?? 0
        ),
        totalSupply: BigNumberJs(v2Summary?.lending?.totalSupply ?? 0).plus(
          v1Summary?.lending?.totalSupply ?? 0
        )
      },
      apeStaking: {
        apeCoinStaked: BigNumberJs(v2Summary?.apeStaking?.apeCoinStaked ?? 0).plus(
          v1Summary?.apeStaking?.apeCoinStaked ?? 0
        ),
        bakcStaked: BigNumberJs(v2Summary?.apeStaking?.bakcStaked ?? 0).plus(
          v1Summary?.apeStaking?.bakcStaked ?? 0
        ),
        baycStaked: BigNumberJs(v2Summary?.apeStaking?.baycStaked ?? 0).plus(
          v1Summary?.apeStaking?.baycStaked ?? 0
        ),
        maycStaked: BigNumberJs(v2Summary?.apeStaking?.maycStaked ?? 0).plus(
          v1Summary?.apeStaking?.maycStaked ?? 0
        )
      },
      liquidation: {
        inAuction: BigNumberJs(v2Summary?.liquidation?.inAuction ?? 0).plus(
          v1Summary?.liquidation?.inAuction ?? 0
        ),
        inLiquidation: BigNumberJs(v2Summary?.liquidation?.inLiquidation ?? 0).plus(
          v1Summary?.liquidation?.inLiquidation ?? 0
        ),
        totalAuction: BigNumberJs(v2Summary?.liquidation?.totalAuction ?? 0).plus(
          v1Summary?.liquidation?.totalAuction ?? 0
        )
      },
      shop: {
        availableNFT: BigNumberJs(v2Summary?.shop?.availableNFT ?? 0).plus(
          v1Summary?.shop?.availableNFT ?? 0
        ),
        purchased: BigNumberJs(v2Summary?.shop?.purchased ?? 0).plus(
          v1Summary?.shop?.purchased ?? 0
        ),
        volume: BigNumberJs(v2Summary?.shop?.volume ?? 0).plus(v1Summary?.shop?.volume ?? 0)
      }
    }),
    [v2Summary, v1Summary]
  );

  return (
    <PlatFormSummaryContext.Provider value={contextData}>
      {children}
    </PlatFormSummaryContext.Provider>
  );
};

export const useLiquidationSummaryData = () => useContext(PlatFormSummaryContext).liquidation;
export const useLendingSummaryData = () => useContext(PlatFormSummaryContext).lending;
export const useApeStakingSummaryData = () => useContext(PlatFormSummaryContext).apeStaking;
export const useShopSummaryData = () => useContext(PlatFormSummaryContext).shop;
