import { ReactNode, createContext, memo, useContext } from 'react';

import { Maybe } from '../typings/basic';

type ScrollContainerContextValue = Maybe<HTMLDivElement>;

const scrollContainerContext = createContext<ScrollContainerContextValue>(null);

export const ScrollContainerProvider = memo(
  ({ children, value }: { children: ReactNode; value: HTMLDivElement }) => {
    return (
      <scrollContainerContext.Provider value={value}>{children}</scrollContainerContext.Provider>
    );
  }
);

export const useScrollContainer = () => useContext(scrollContainerContext);
