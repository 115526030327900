import { findKey, pickBy } from 'lodash';

import { ERC20Symbol, TokenInfo } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ERC20Config } from '@/apps/paraspace/config';

export const getTokensInfoWithForgedNativeToken = (
  tokensInfo: Maybe<Record<string, TokenInfo>>,
  erc20Config: ERC20Config
) => {
  if (!tokensInfo || !erc20Config) return null;

  const nativeTokenSymbol = findKey(
    erc20Config,
    tokenInfo => tokenInfo.isNativeToken
  ) as ERC20Symbol;
  const wNativeTokenSymbol = findKey(
    erc20Config,
    tokenInfo => tokenInfo.isWrappedNativeToken
  ) as ERC20Symbol;

  const tokens = pickBy(
    {
      ...tokensInfo,
      [nativeTokenSymbol]: tokensInfo[wNativeTokenSymbol]
        ? {
            ...tokensInfo[wNativeTokenSymbol],
            symbol: nativeTokenSymbol,
            displayName: nativeTokenSymbol
          }
        : null
    },
    value => value
  );

  return tokens as Record<string, TokenInfo>;
};
