import { StakingType } from 'paraspace-utilities-contract-helpers';

import { ApeCoinListing, ApeListing, ListingType } from './types';

import { zero } from '@/apps/paraspace/consts/values';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';

const defaultListing = {
  token: '',
  expirationDate: new Date(),
  listingHash: '',
  offerer: '',
  share: zero,
  startDate: new Date(),
  v: 0,
  r: '',
  s: ''
};

export const initialApeListing: ApeListing = {
  ...defaultListing,
  tokenId: 0,
  listingType: ListingType.ApeListing,
  symbol: ERC721Symbol.MAYC,
  stakingPool: ERC721Symbol.MAYC,
  stakingType: StakingType.MAYCStaking
};

export const initialApeCoinListing: ApeCoinListing = {
  ...defaultListing,
  listingType: ListingType.ApeCoinListing,
  symbol: ERC20Symbol.APE,
  stakingPool: ERC721Symbol.MAYC,
  stakingType: StakingType.MAYCStaking,
  amount: zero
};
