import { memo } from 'react';
import { Stack, StackProps } from '@parallel-mono/components';

import { useUniswapInfos } from '../../../UniswapInfoProvider';

import LiquidityInputForm from './LiquidityInputForm';
import TokenInfoContainer from './TokenInfoContainer';

export type ModalContentProps = Omit<StackProps, 'children'> & {
  symbol: string;
  tokenId: number | string;
  onFinish?: () => void;
  onTransactionCreated?: () => void;
};

export const ModalContent = memo(
  ({ symbol, tokenId, onFinish, onTransactionCreated, ...others }: ModalContentProps) => {
    const { infoMap } = useUniswapInfos();
    const {
      token0Symbol: baseTokenSymbol,
      token1Symbol: convertTokenSymbol,
      token0: baseTokenAddress,
      token1: convertTokenAddress,
      token0ConvertToken1MinPrice: baseTokenLowPrice,
      token0ConvertToken1MaxPrice: baseTokenUpPrice,
      token0ConvertToken1CurrentPrice: baseTokenCurrentPrice,
      token1ConvertToken0CurrentPrice: convertTokenCurrentPrice,
      liquidityToken0Amount: liquidityBaseTokenBalance,
      liquidityToken1Amount: liquidityConvertTokenBalance,
      lpFeeToken0Amount: lpFeeBaseTokenBalance,
      lpFeeToken1Amount: lpFeeConvertTokenBalance,
      feeRate,
      isInRange,
      isClosed
    } = infoMap[tokenId];

    return (
      <Stack gap="1.5rem" {...others}>
        <TokenInfoContainer
          baseTokenLowPrice={baseTokenLowPrice}
          baseTokenUpPrice={baseTokenUpPrice}
          baseTokenCurrentPrice={baseTokenCurrentPrice}
          convertTokenSymbol={convertTokenSymbol}
          baseTokenSymbol={baseTokenSymbol}
          feeRate={feeRate}
          isInRange={isInRange}
          isClosed={isClosed}
        />
        <LiquidityInputForm
          tokenId={`${tokenId}`}
          symbol={symbol}
          baseTokenLowPrice={baseTokenLowPrice}
          baseTokenUpPrice={baseTokenUpPrice}
          baseTokenAddress={baseTokenAddress}
          convertTokenAddress={convertTokenAddress}
          baseTokenCurrentPrice={baseTokenCurrentPrice}
          convertTokenCurrentPrice={convertTokenCurrentPrice}
          convertTokenSymbol={convertTokenSymbol}
          baseTokenSymbol={baseTokenSymbol}
          liquidityBaseTokenBalance={liquidityBaseTokenBalance}
          liquidityConvertTokenBalance={liquidityConvertTokenBalance}
          lpFeeBaseTokenBalance={lpFeeBaseTokenBalance}
          lpFeeConvertTokenBalance={lpFeeConvertTokenBalance}
          isInRange={isInRange}
          onFinish={onFinish}
          onTransactionCreated={onTransactionCreated}
        />
      </Stack>
    );
  }
);
