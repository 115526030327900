import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

import { useAppConfig, useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { useV1TimelockQueuesForAssetsLazyQuery } from '@/apps/parax/generated/graphql';
import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';

export const useCheckV1TimelockStatus = (contractAddress: string, tokenIds: number[]) => {
  const stabilizedTokenIds = useStabilizedSnapshot(tokenIds);
  const { v1EthereumGraphqlEndPoint } = useAppConfig();

  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  const symbol = useMemo(
    () => getSymbolByContractAddress(contractAddress),
    [contractAddress, getSymbolByContractAddress]
  );

  const [fetchV1TimelockStatus, { data, loading }] = useV1TimelockQueuesForAssetsLazyQuery({
    context: { uri: v1EthereumGraphqlEndPoint },
    fetchPolicy: 'no-cache'
  });

  const refresh = useCallback(async () => {
    if (!contractAddress || isEmpty(stabilizedTokenIds)) {
      return;
    }
    await fetchV1TimelockStatus({
      variables: {
        assets: {
          contractAddress,
          tokenIds: stabilizedTokenIds.map(v => v.toString())
        }
      }
    });
  }, [fetchV1TimelockStatus, stabilizedTokenIds, contractAddress]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const tokensStatus = useMemo(() => {
    return data?.timelockQueuesForAssets.map(
      ({ tokenId, isClaimed, expectedRelease, contractAddress: tokenContractAddress }) => ({
        tokenId: Number(tokenId),
        isClaimed,
        expectedRelease: dayjs(expectedRelease).fromNow(true),
        isReleaseTimeReached: dayjs(expectedRelease).isBefore(Date.now()),
        contractAddress: tokenContractAddress,
        symbol
      })
    );
  }, [data, symbol]);

  return { isLoading: isEmpty(data) && loading, tokensStatus, refresh };
};
