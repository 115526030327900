import { Inline, Tag, useBreakpoints, SmallText } from '@parallel-mono/components';
import { memo } from 'react';

import { Logo } from '../Logo';

import config from '@/apps/parax/config';

export const LeftHeader = memo(() => {
  const { desktop: isDesktop } = useBreakpoints();

  return (
    <Inline gap="0.75rem" alignItems="center">
      <Logo />
      {!config.isProductionOrForkMainnet && (
        <Tag skin="warning" size={isDesktop ? 'medium' : 'small'}>
          <SmallText>Testnet</SmallText>
        </Tag>
      )}
    </Inline>
  );
});
