import { useMemo } from 'react';
import { difference } from 'lodash';

import { useUniSwapV3Manager } from '../../../useUniSwapV3Manager';
import { UniswapItem } from '../types';
import { useEOABalances } from '../../../../UserBalanceProvider';
import { useUniswapInfos } from '../../../../UniswapInfoProvider';

import { emptyArray, zero } from '@/apps/paraspace/consts/values';
import { ERC20Symbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

export const useSelectableTokens = (
  pair: [ERC20Symbol, ERC20Symbol],
  walletType: WalletType
): UniswapItem[] => {
  const { nftInfoMap, erc20InfoMap } = useMMProvider();
  const { imageMap } = useUniSwapV3Manager();
  const { erc721BalanceMap } = useEOABalances();
  const { infoMap: uniswapInfoMap } = useUniswapInfos();

  const walletTokens =
    (walletType === 'AA'
      ? nftInfoMap[ERC721Symbol.UNISWAP_LP].balance
      : erc721BalanceMap?.[ERC721Symbol.UNISWAP_LP].balance) ?? emptyArray;

  return useMemo(
    () =>
      walletTokens
        .filter(tokenId => {
          const { token0Symbol, token1Symbol } = uniswapInfoMap[tokenId] ?? {};
          return difference([token0Symbol, token1Symbol], pair).length === 0;
        })
        .map(tokenId => {
          const {
            lpFeeToken0Amount = zero,
            lpFeeToken1Amount = zero,
            priceInUsd = zero,
            token0ConvertToken1MinPrice = zero,
            token0ConvertToken1MaxPrice = zero,
            token0Symbol,
            token1Symbol,
            baseLTVasCollateral,
            reserveLiquidationThreshold
          } = uniswapInfoMap[tokenId] ?? {};

          const unclaimedFee = lpFeeToken0Amount
            .times(erc20InfoMap[token0Symbol]?.priceInUsd ?? zero)
            .plus(lpFeeToken1Amount.times(erc20InfoMap[token1Symbol]?.priceInUsd ?? zero));

          return {
            tokenId,
            image: imageMap?.[tokenId]?.image,
            priceInUsd,
            token0ConvertToken1MinPrice,
            token0ConvertToken1MaxPrice,
            unclaimedFee,
            baseLTVasCollateral,
            reserveLiquidationThreshold
          };
        }),
    [imageMap, walletTokens, pair, erc20InfoMap, uniswapInfoMap]
  );
};
