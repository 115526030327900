import { useMemo } from 'react';

import { useSupportedERC20s } from './useSupportedERC20s';

import { useEOAProvider } from '@/apps/parax/contexts';

export const useERC20BySymbol = (symbol: string) => {
  const { chainId } = useEOAProvider();
  const supportedERC20s = useSupportedERC20s(chainId);

  const targetERC20 = useMemo(
    () => supportedERC20s.find(it => it.symbol === symbol) ?? null,
    [symbol, supportedERC20s]
  );

  return targetERC20;
};
