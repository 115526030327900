import { AppPackage } from 'parax-sdk';
import { Network } from 'paraspace-configs-v2';
import wallet from 'parax-wallet';

import {
  PARALLEL_KUSAMA_APP_LINK,
  PARALLEL_POLKADOT_APP_LINK,
  PARALLEL_KUSAMA_CROWDLOAN_APP_LINK,
  PARALLEL_POLKADOT_CROWDLOAN_APP_LINK
} from '../paraspace/consts/externalLinks';
import config from '../parax/config';

import { BLAST_NETWORK_LINK } from './blastNetworkLink';

import paraspace from '@/apps/paraspace';

export type ThemedString = string | { dark: string; light: string };

type DomainApp = {
  name: string;
  path: string;
  icon: ThemedString;
  supportedNetworks: Network[];
  link?: string;
};
export type InternalAppConfig = {
  type: 'internal';
  getAppPackage: () => Promise<AppPackage>;
  domainApps?: DomainApp[];
  name: string;
  icon: string;
  route: string;
  supportedNetworks: Network[];
  disabled?: boolean;
  toggle?: string;
  default?: boolean;
};

export type SiblingAppConfig = {
  type: 'sibling';
  name: string;
  icon: string;
  route: string;
  toggle?: string;
  disabled?: boolean;
  supportedNetworks: Network[];
};

export type ExternalAppConfig = {
  type: 'external';
  name: string;
  domainApps?: DomainApp[];
  icon: string;
  link: string;
  disabled?: boolean;
  toggle?: string;
  supportedNetworks: Network[];
};

export type AppConfig = InternalAppConfig | ExternalAppConfig | SiblingAppConfig;

export const isInternalApp = (appConfig: AppConfig): appConfig is InternalAppConfig =>
  appConfig.type === 'internal';

export const isExternalApp = (appConfig: AppConfig): appConfig is ExternalAppConfig =>
  appConfig.type === 'external';

export const apps: Array<AppConfig> = [
  {
    getAppPackage: async () => ({
      ...wallet,
      icon: 'https://biz-assets.parallel.fi/default/design/PDS_V3/chain_products/overview.b7ca83e07cbde01b6e4c0a290e910025bd44be4a8bbe225b3ae0f9e181f64c5e.svg'
    }),
    route: 'wallet',
    name: 'Wallet',
    icon: 'https://biz-assets.parallel.fi/default/design/PDS_V3/chain_products/overview.b7ca83e07cbde01b6e4c0a290e910025bd44be4a8bbe225b3ae0f9e181f64c5e.svg',
    supportedNetworks: [
      Network.MAINNET,
      Network.SEPOLIA,
      Network.ZKSYNC_ERA,
      Network.ZKSYNC_GOERLI,
      Network.ARBITRUM_GOERLI,
      Network.ARBITRUM_ONE,
      Network.PARALLEL_L3_TESTNET,
      Network.PARALLEL
    ],
    type: 'internal'
  },
  {
    getAppPackage: async () => ({ ...paraspace, icon: `design/PDS_V3/logo/${paraspace.icon}` }),
    route: 'paraspace',
    name: 'ParaSpace',
    icon: `design/PDS_V3/logo/${paraspace.icon}`,
    default: true,
    domainApps: [
      {
        name: 'Lending',
        path: 'credit',
        supportedNetworks: [
          Network.MAINNET,
          Network.SEPOLIA,
          Network.ZKSYNC_ERA,
          Network.ZKSYNC_GOERLI,
          Network.ARBITRUM_GOERLI,
          Network.ARBITRUM_ONE
        ],
        icon: 'https://biz-assets.parallel.fi/default/design/PDS_V3/chain_products/polkadot/money-market.08e37c7a5475c12156e4e25964678ddda79f965d0569f8def9915e5443072e94.svg'
      },
      {
        name: 'Ape Staking',
        path: 'ape-staking',
        supportedNetworks: [Network.MAINNET, Network.SEPOLIA],
        icon: 'https://biz-assets.parallel.fi/default/design/PDS_V3/chain_products/staking.bb1094f3825671705fd19a9adba952e0e4234aa14a62b09b4fd836afa87494b5.svg'
      },
      {
        name: 'Shop',
        path: 'shop/buy',
        supportedNetworks: [Network.MAINNET, Network.SEPOLIA],
        icon: 'https://biz-assets.parallel.fi/default/design/PDS_V3/chain_products/polkadot/swap.fef4bf6aa84628b67f590445704dd63b4ab2e04b71ac6b871e19661233231fd6.svg'
      }
    ],
    supportedNetworks: [
      Network.MAINNET,
      Network.SEPOLIA,
      Network.ZKSYNC_ERA,
      Network.ZKSYNC_GOERLI,
      Network.ARBITRUM_GOERLI,
      Network.ARBITRUM_ONE
    ],
    type: 'internal'
  },
  {
    type: 'sibling',
    name: 'Bridge',
    icon: 'https://biz-assets.parallel.fi/default/design/PDS_V3/chain_products/cross-chain.113a879f34e4b712245f2fa5ea54c60b36c759a94697288808dbbcb21428ad49.svg',
    route: '/bridge',
    supportedNetworks: [Network.PARALLEL_L3_TESTNET, Network.PARALLEL]
  },
  {
    getAppPackage: async () => import('parax-points').then(it => it.default),
    route: 'points',
    name: 'Airdrop',
    icon: 'https://biz-assets.parallel.fi/default/design/PDS_V3/chain_products/crowdloan.f4ff4e59ea459b538e28c894ed74b89055e4b49efdae2ee0ec81fb527f70c2d8.svg',
    supportedNetworks: [
      Network.MAINNET,
      Network.SEPOLIA,
      Network.ZKSYNC_ERA,
      Network.ZKSYNC_GOERLI,
      Network.ARBITRUM_GOERLI,
      Network.ARBITRUM_ONE
    ],
    type: 'internal'
  },
  {
    name: 'ParaSpace',
    icon: `https://biz-assets.parallel.fi/dark/design/PDS_V3/logo/paraspace.a459888f26f9c79708111a7b6141e44b060a9d58e6b4d1d475b5285781995d14.svg`,
    link: config.siteUrlV1,
    supportedNetworks: [Network.MAINNET, Network.SEPOLIA],
    type: 'external',
    domainApps: [
      {
        name: 'ParaSpace',
        icon: {
          dark: 'https://biz-assets.parallel.fi/dark/design/PDS_V3/logo/paraspace.a459888f26f9c79708111a7b6141e44b060a9d58e6b4d1d475b5285781995d14.svg',
          light:
            'https://biz-assets.parallel.fi/default/design/PDS_V3/logo/paraspace.be1b03c69d546ca2eda87ed13a15ef3e444a48538bdd05dae88555071757c24b.svg'
        },
        link: `${config.siteUrlV1}`,
        supportedNetworks: [Network.MAINNET, Network.SEPOLIA],
        path: ''
      }
    ]
  },
  {
    getAppPackage: async () => import('parax-airdrop').then(it => it.default),
    name: 'Airdrop',
    icon: 'design/PDS_V3/chain_products/crowdloan',
    route: 'airdrop',
    supportedNetworks: [Network.PARALLEL_L3_TESTNET, Network.PARALLEL],
    type: 'internal'
  },
  {
    getAppPackage: async () => import('parax-perp').then(it => it.default),
    route: 'trade',
    name: 'Perp',
    icon: 'design/PDS_V3/logo/parax-perp',
    supportedNetworks: [Network.PARALLEL_L3_TESTNET],
    toggle: 'PERP_APP',
    type: 'internal'
  },
  {
    getAppPackage: async () => import('parax-aave').then(it => it.default),
    route: 'credit',
    name: 'Lending',
    icon: 'https://biz-assets.parallel.fi/default/design/PDS_V3/chain_products/polkadot/money-market.08e37c7a5475c12156e4e25964678ddda79f965d0569f8def9915e5443072e94.svg',
    supportedNetworks: [Network.PARALLEL_L3_TESTNET, Network.PARALLEL],
    toggle: 'AAVE_APP',
    type: 'internal'
  },
  {
    getAppPackage: () => import('parax-uniswap-v3').then(it => it.default),
    name: 'Uniswap',
    icon: 'https://biz-assets.parallel.fi/default/icons/marketplace/uniswap.2653e4bf877ff9ee2f805c14a8fdd7c4f93e290f1fb141968d6552051e22d7d9.png',
    route: 'uniswap',
    supportedNetworks: [Network.MAINNET, Network.SEPOLIA],
    type: 'internal'
  },
  {
    getAppPackage: () => import('parax-uniswap-v3').then(it => it.default),
    name: 'Swap',
    icon: 'https://biz-assets.parallel.fi/default/design/PDS_V3/chain_products/polkadot/swap.fef4bf6aa84628b67f590445704dd63b4ab2e04b71ac6b871e19661233231fd6.svg',
    route: 'swap',
    toggle: 'SWAP',
    supportedNetworks: [Network.PARALLEL, Network.PARALLEL_L3_TESTNET],
    type: 'internal'
  }
];

const polkadotChainProductIconPath = 'design/PDS_V3/chain_products/polkadot/';
const kusumaChainProductIconPath = 'design/PDS_V3/chain_products/kusama/';
export const externalNetworks: AppConfig[] = [
  {
    name: 'Blast',
    icon: 'https://biz-assets.parallel.fi/default/icons/chain/blast.2a59b03b80a9d41b305e062adfdc19dad6d25898ced176ba99220f7be4cbc763.svg',
    link: BLAST_NETWORK_LINK,
    type: 'external',
    supportedNetworks: [],
    domainApps: [
      {
        name: 'Lending',
        icon: 'https://biz-assets.parallel.fi/default/design/PDS_V3/logo/pac-logo.8325444e8891e5fb17df53740b8b512a1700f11f2999111f0ae5e08fac7531fb.svg',
        link: `${BLAST_NETWORK_LINK}`,
        path: '',
        supportedNetworks: []
      },
      {
        name: 'Leaderboard',
        icon: `https://biz-assets.parallel.fi/default/design/PDS_V3/logo/pac-logo.8325444e8891e5fb17df53740b8b512a1700f11f2999111f0ae5e08fac7531fb.svg`,
        link: `${BLAST_NETWORK_LINK}/leaderboard`,
        path: '',
        supportedNetworks: []
      }
    ]
  },
  {
    name: 'Polkadot',
    icon: 'https://biz-assets.parallel.fi/icons/crypto/DOT.66969f6d2d55d646e2302f74c7c19af594e4ed35a5427abddc4ae371038cd230.svg',
    link: PARALLEL_POLKADOT_APP_LINK,
    type: 'external',
    supportedNetworks: [],
    domainApps: [
      {
        name: 'Overview',
        icon: `${polkadotChainProductIconPath}overview`,
        link: `${PARALLEL_POLKADOT_APP_LINK}/overview`,
        path: '',
        supportedNetworks: []
      },
      {
        name: 'Swap',
        icon: `${polkadotChainProductIconPath}swap`,
        link: `${PARALLEL_POLKADOT_APP_LINK}/swap`,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'Money Market',
        icon: `${polkadotChainProductIconPath}money-market`,
        link: `${PARALLEL_POLKADOT_APP_LINK}/lendAndBorrow`,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'Stake',
        icon: `${polkadotChainProductIconPath}staking`,
        link: `${PARALLEL_POLKADOT_APP_LINK}/staking`,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'Cross Chain',
        icon: `${polkadotChainProductIconPath}cross-chain`,
        link: `${PARALLEL_POLKADOT_APP_LINK}/cross-chain`,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'Farm',
        icon: `${polkadotChainProductIconPath}farm`,
        link: `${PARALLEL_POLKADOT_APP_LINK}/farm`,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'Crowdloan',
        icon: `${polkadotChainProductIconPath}crowdloan`,
        link: PARALLEL_POLKADOT_CROWDLOAN_APP_LINK,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'DAO Fi',
        icon: `${polkadotChainProductIconPath}dao-fi`,
        link: `${PARALLEL_POLKADOT_APP_LINK}/stream`,
        supportedNetworks: [],
        path: ''
      }
    ]
  },
  {
    name: 'Kusama',
    icon: 'https://biz-assets.parallel.fi/icons/crypto/KSM.15fc6f0899f22222be2a9a8ef7a76261740da39e117f2659b672cddcb67c0db8.svg',
    link: PARALLEL_POLKADOT_APP_LINK,
    type: 'external',
    supportedNetworks: [],
    domainApps: [
      {
        name: 'Overview',
        icon: `${kusumaChainProductIconPath}overview`,
        link: `${PARALLEL_KUSAMA_APP_LINK}/overview`,
        path: '',
        supportedNetworks: []
      },
      {
        name: 'Swap',
        icon: `${kusumaChainProductIconPath}swap`,
        link: `${PARALLEL_KUSAMA_APP_LINK}/swap`,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'Money Market',
        icon: `${kusumaChainProductIconPath}money-market`,
        link: `${PARALLEL_KUSAMA_APP_LINK}/lendAndBorrow`,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'Stake',
        icon: `${kusumaChainProductIconPath}staking`,
        link: `${PARALLEL_KUSAMA_APP_LINK}/staking`,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'Cross Chain',
        icon: `${kusumaChainProductIconPath}cross-chain`,
        link: `${PARALLEL_KUSAMA_APP_LINK}/cross-chain`,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'Farm',
        icon: `${kusumaChainProductIconPath}farm`,
        link: `${PARALLEL_KUSAMA_APP_LINK}/farm`,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'Crowdloan',
        icon: `${kusumaChainProductIconPath}crowdloan`,
        link: PARALLEL_KUSAMA_CROWDLOAN_APP_LINK,
        supportedNetworks: [],
        path: ''
      },
      {
        name: 'DAO Fi',
        icon: `${kusumaChainProductIconPath}dao-fi`,
        link: `${PARALLEL_KUSAMA_APP_LINK}/stream`,
        supportedNetworks: [],
        path: ''
      }
    ]
  }
];
