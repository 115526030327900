import { memo } from 'react';
import styled from 'styled-components';
import { BigText, Card, Stack, StackProps, Text } from '@parallel-mono/components';

const CenteredText = styled(Text)`
  text-align: center;
`;

const StyledText = styled(Text)`
  width: 3.75rem;
  height: 3.75rem;
  font-size: 3.75rem;
  line-height: 3.75rem;
  text-align: center;
`;

type EmptyStateProps = Omit<StackProps, 'children'>;

export const EmptyState = memo((props: EmptyStateProps) => {
  // const navigate = useNavigate();
  // const handleShopApes = useCallback(() => {
  //   navigate('/shop');
  // }, [navigate]);
  return (
    <Card border {...props}>
      <Stack alignItems="center">
        <StyledText>🙊</StyledText>
        <Stack gap="0.25rem" alignItems="center">
          <BigText fontWeight="bold">No Apes Found</BigText>
          <CenteredText skin="secondary">
            To start staking $APE, you have to own at Bored Ape Yacht Club or Mutant Ape Yacht Club
            NFTs.
          </CenteredText>
        </Stack>
        {/* <Button disabled skin="secondary" block size="large" onClick={handleShopApes}> */}
        {/*  Shop Apes */}
        {/* </Button> */}
      </Stack>
    </Card>
  );
});
