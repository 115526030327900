import { H4, Spinner, Text } from '@parallel-mono/components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const StyledLoadingSpinner = styled(Spinner)`
  margin: 0 0.5rem;
`;

const Label = styled(H4)`
  margin: 1.5rem 0 0.25rem;
`;

export const ConfirmingState = () => {
  return (
    <Container>
      <StyledLoadingSpinner size="large" />
      <Label>Processing</Label>
      <Text skin="secondary">This may take up to 20 seconds. Please do not close the modal.</Text>
    </Container>
  );
};
