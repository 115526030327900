import { memo, useCallback, useMemo, useState } from 'react';

import { SupplyERC20FormData } from '../../types';

import {
  Stepper,
  ApproveERC20Submitter,
  StepperProps,
  SupplyERC20FromEOASubmitter
} from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { useIsNativeTokenCheck } from '@/apps/paraspace/pages/hooks/useIsNativeTokenCheck';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type SupplyERC20FromEOAFormSubmitterProps = Omit<StepperProps, 'steps' | 'step' | 'onError'> & {
  onError: () => void;
  onFinish: (formData: SupplyERC20FormData) => void;
  formData: SupplyERC20FormData;
};

export const SupplyERC20FromEOAFormSubmitter = memo(
  ({
    onError,
    onFinish,
    formData: { symbol, amount },
    ...others
  }: SupplyERC20FromEOAFormSubmitterProps) => {
    const [step, setStep] = useState(0);

    const { erc20InfoMap } = useMMProvider();
    const { eoaAccount } = useWeb3Context();
    const { address: assetAddress, displayName } = erc20InfoMap[symbol] ?? {};

    const handleNextStep = useCallback(() => {
      setStep(curr => curr + 1);
    }, []);

    const { checkIsNativeTokenSymbol } = useIsNativeTokenCheck();

    const steps = useMemo(
      () =>
        [
          {
            description: 'Approve Token',
            skip: checkIsNativeTokenSymbol(symbol),
            content: (
              <ApproveERC20Submitter
                formData={{
                  assetAddress,
                  name: displayName,
                  amount,
                  from: eoaAccount
                }}
                onFinish={handleNextStep}
                onError={onError}
              />
            )
          },
          {
            description: `Supply Token`,
            content: (
              <SupplyERC20FromEOASubmitter
                formData={{
                  symbol,
                  amount
                }}
                onFinish={onFinish}
                onError={onError}
              />
            )
          }
        ].filter(notEmpty),
      [
        checkIsNativeTokenSymbol,
        symbol,
        assetAddress,
        displayName,
        amount,
        eoaAccount,
        handleNextStep,
        onError,
        onFinish
      ]
    );

    return <Stepper {...others} steps={steps} step={step} />;
  }
);
