import { memo, useCallback, useMemo } from 'react';
import { Stack, StackProps } from '@parallel-mono/components';

import { TokenItem } from './TokenItem';

import { emptyArray } from '@/apps/paraspace/consts/values';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useContractAddressBySymbol } from '@/apps/paraspace/hooks';

export type TokensSectionProps = Omit<StackProps, 'children'> & {
  symbol: ERC721Symbol;
  inLiquidation: boolean;
  onToggleCollateral: (tokenId: number) => void;
};

export const TokensSection = memo(
  ({ symbol, inLiquidation, onToggleCollateral, ...others }: TokensSectionProps) => {
    const { nftInfoMap } = useMMProvider();
    const {
      nftSuppliedList = emptyArray,
      nftCollateralList = emptyArray,
      auctionedTokens
    } = nftInfoMap[symbol];

    const items = useMemo(() => {
      return nftSuppliedList.map(id => ({
        symbol,
        id,
        asCollateral: nftCollateralList.includes(id),
        inAuction: auctionedTokens?.includes(id) ?? false
      }));
    }, [nftSuppliedList, symbol, nftCollateralList, auctionedTokens]);

    const contractAddress = useContractAddressBySymbol(symbol);

    const handleToggleCollateral = useCallback(
      (tokenId: number) => {
        onToggleCollateral(tokenId);
      },
      [onToggleCollateral]
    );

    return (
      <Stack gap="1rem" alignItems="center" {...others}>
        {items.map(it => {
          return (
            <TokenItem
              key={it.id}
              width="100%"
              inLiquidation={inLiquidation}
              inAuction={it.inAuction}
              contractAddress={contractAddress}
              symbol={symbol}
              tokenId={it.id}
              asCollateral={it.asCollateral}
              onToggleCollateral={handleToggleCollateral}
            />
          );
        })}
      </Stack>
    );
  }
);
