import { Contract, BigNumber as EthersBigNumber, Signer } from 'ethers';
import BigNumber from 'bignumber.js';
import { Provider } from '@ethersproject/providers';

import abi from './erc20ABI.json';

export class ERC20 {
  private contract: Contract;

  private decimals: number;

  constructor(address: string, signerOrProvider: Signer | Provider, decimals: number) {
    this.contract = new Contract(address, abi, signerOrProvider);
    this.decimals = decimals;
  }

  public async approveIfNecessary(amount: BigNumber, owner: string, spender: string) {
    const allowance = await this.getAllowance(owner, spender);
    if (amount.gt(allowance)) {
      const result = await this.contract.approve(
        spender,
        amount.shiftedBy(this.decimals).toString(10)
      );
      await result.wait();
    }
  }

  public async getAllowance(owner: string, spender: string): Promise<BigNumber> {
    return this.contract
      .allowance(owner, spender)
      .then((r: EthersBigNumber) => new BigNumber(r.toString()).shiftedBy(-this.decimals));
  }

  public async getBalance(owner: string): Promise<BigNumber> {
    return this.contract
      .balanceOf(owner)
      .then((r: EthersBigNumber) => new BigNumber(r.toString()).shiftedBy(-this.decimals));
  }
}
