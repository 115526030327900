import { ReactNode, createContext, memo, useCallback, useContext, useMemo } from 'react';

import { useCheckV1TimelockStatus } from '@/apps/paraspace/pages/Credit';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol } from '@/apps/paraspace/typings';

type V1TimelockApeInfoContextValue = {
  timelockInfos: {
    tokenId: number;
    expectedRelease: string;
    isReleaseTimeReached: boolean;
    contractAddress: string;
    symbol: ERC721Symbol;
  }[];
  isLoading: boolean;
  refresh: () => Promise<void>;
};

const V1TimelockApeInfoContext = createContext<V1TimelockApeInfoContextValue>({
  timelockInfos: [],
  isLoading: false,
  refresh: () => Promise.resolve()
});

export const V1TimelockApeInfoProvider = memo(({ children }: { children: ReactNode }) => {
  const { nftInfoMap } = useMMProvider();

  const { address: baycAddress = '', nftSuppliedList: baycSuppliedList = [] } =
    nftInfoMap[ERC721Symbol.BAYC] ?? {};
  const {
    isLoading: isLoadingBaycAddress,
    tokensStatus: baycV1TimelockStatus = [],
    refresh: refreshBaycV1TimelockStatus
  } = useCheckV1TimelockStatus(baycAddress, baycSuppliedList);

  const { address: maycAddress = '', nftSuppliedList: maycSuppliedList = [] } =
    nftInfoMap[ERC721Symbol.MAYC] ?? {};
  const {
    isLoading: isLoadingMaycAddress,
    tokensStatus: maycV1TimelockStatus = [],
    refresh: refreshMaycV1TimelockStatus
  } = useCheckV1TimelockStatus(maycAddress, maycSuppliedList);

  const { address: bakcAddress = '', nftSuppliedList: bakcSuppliedList = [] } =
    nftInfoMap[ERC721Symbol.BAKC] ?? {};
  const {
    isLoading: isLoadingBakcAddress,
    tokensStatus: bakcV1TimelockStatus = [],
    refresh: refreshBakcV1TimelockStatus
  } = useCheckV1TimelockStatus(bakcAddress, bakcSuppliedList);

  const allUnclaimedApeTokens = useMemo(
    () =>
      baycV1TimelockStatus
        .concat(maycV1TimelockStatus)
        .concat(bakcV1TimelockStatus)
        .filter(it => !it.isClaimed)
        .map(it => ({
          tokenId: it.tokenId,
          expectedRelease: it.expectedRelease,
          isReleaseTimeReached: it.isReleaseTimeReached,
          contractAddress: it.contractAddress,
          symbol: it.symbol as ERC721Symbol
        })),
    [baycV1TimelockStatus, maycV1TimelockStatus, bakcV1TimelockStatus]
  );

  const refresh = useCallback(async () => {
    await Promise.all([
      refreshBaycV1TimelockStatus(),
      refreshMaycV1TimelockStatus(),
      refreshBakcV1TimelockStatus()
    ]);
  }, [refreshBaycV1TimelockStatus, refreshMaycV1TimelockStatus, refreshBakcV1TimelockStatus]);

  const contextValue = useMemo(
    () => ({
      isLoading: isLoadingBaycAddress || isLoadingMaycAddress || isLoadingBakcAddress,
      timelockInfos: allUnclaimedApeTokens,
      refresh
    }),
    [
      isLoadingBaycAddress,
      isLoadingMaycAddress,
      isLoadingBakcAddress,
      allUnclaimedApeTokens,
      refresh
    ]
  );

  return (
    <V1TimelockApeInfoContext.Provider value={contextValue}>
      {children}
    </V1TimelockApeInfoContext.Provider>
  );
});

export const useV1TimelockApeInfo = () => useContext(V1TimelockApeInfoContext);
