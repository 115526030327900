import { memo, useMemo } from 'react';

import { useNTokenService } from '@/apps/paraspace/pages/hooks/useNtokenService';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';

type FormData = {
  tokens: { symbol: ERC721Symbol; tokenIds: number[] }[];
  delegate: string;
  enableDelegation: boolean;
};

type NFTDelegationSubmitterForSpecificCollectionProps = {
  formData: FormData;
  onFinish: () => void;
  onError: () => void;
};

export const NFTDelegationSubmitter = memo(
  ({
    formData: { tokens, delegate },
    onError,
    onFinish
  }: NFTDelegationSubmitterForSpecificCollectionProps) => {
    const { nftInfoMap } = useMMProvider();

    const { initiateDelegateForTokens } = useNTokenService();

    const delegationTxs = useMemo(() => {
      const txs = tokens.map(({ symbol, tokenIds }) =>
        initiateDelegateForTokens(tokenIds, delegate, nftInfoMap[symbol].xTokenAddress)
      );
      return { tx: txs };
    }, [delegate, initiateDelegateForTokens, nftInfoMap, tokens]);

    return (
      <BatchTransactionsSubmitter
        batchTxs={[delegationTxs]}
        onError={onError}
        onFinish={onFinish}
      />
    );
  }
);
