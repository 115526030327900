import { memo, ReactNode, createContext, useMemo, useContext, useCallback } from 'react';
import BigNumber from 'bignumber.js';
import { TransactionResponse } from '@ethersproject/providers';
import { EthereumTransactionTypeExtended } from 'paraspace-utilities-contract-helpers';
import {
  ParaXProvider,
  OrganizedAddAssetsContext,
  AppRenderParams,
  Route,
  WalletTypeEnum,
  Environment
} from 'parax-sdk';

import { SupportedNetworks } from '../../typings';
import { Network } from '../../config';
import { submitEOATransaction } from '../../utils/submitTransaction';

import { rewriteGasEstimation, withoutGasEstimation } from './utils';

import { Maybe } from '@/apps/paraspace/typings/basic';

type Web3ContextValue = Maybe<{
  connectWallet: () => void;
  addAssets: (context: OrganizedAddAssetsContext) => Promise<void>;
  updateRoutes: (routes: Route[]) => void;
  walletType: WalletTypeEnum;
  provider: ParaXProvider;
  chainId: SupportedNetworks;
  account: string;
  eoaAccount: string;
  authentication: AppRenderParams['state']['authentication'];
  isUsingUserWallet: boolean;
  submitTransactions: (txs: EthereumTransactionTypeExtended[]) => Promise<TransactionResponse>;
  submitStrippedTransactions: (
    txs: { to: string; value: string; data: string }[]
  ) => Promise<TransactionResponse>;
  submitEOATransactions: (tx: EthereumTransactionTypeExtended) => Promise<TransactionResponse>;
  env: Environment;
}>;

const Web3Context = createContext<Web3ContextValue>(null);

type Web3ProviderProps = {
  children: ReactNode;
  provider: ParaXProvider;
  account: string;
  authentication: AppRenderParams['state']['authentication'];
  chainId: SupportedNetworks;
  connectWallet: () => void;
  addAssets: (context: OrganizedAddAssetsContext) => Promise<void>;
  updateRoutes: (routes: Route[]) => void;
  env: Environment;
};

export const Web3Provider = memo(
  ({
    children,
    account,
    authentication,
    provider,
    chainId,
    connectWallet,
    addAssets,
    updateRoutes,
    env
  }: Web3ProviderProps) => {
    const {
      meta: { account: eoaAccount, provider: EOAProvider }
    } = authentication;

    const submitStrippedTransactions = useCallback(
      async (params: { to: string; value: string; data: string }[]) => {
        const estimateGas = await provider.estimateGasForTransactions(params);
        return (provider as ParaXProvider).submitTransactionsWithParaAccount(params, {
          gasLimit: estimateGas.mul(10).div(8)
        });
      },
      [provider]
    );

    const submitTransactions = useCallback(
      async (txs: EthereumTransactionTypeExtended[]) => {
        const extendedTxDataArr = await withoutGasEstimation(async () =>
          Promise.all(txs.map(tx => tx.tx()))
        );
        const params = extendedTxDataArr.map(extendedTxData => {
          const { to, value, data } = extendedTxData;
          return { to: to!, value: new BigNumber(value ?? 0).toString(), data: data! };
        });

        return submitStrippedTransactions(params);
      },
      [submitStrippedTransactions]
    );

    const submitEOATransactions = useCallback(
      async (tx: EthereumTransactionTypeExtended) => {
        if (!EOAProvider) {
          throw new Error('EOAProvider not ready');
        }
        return submitEOATransaction({ provider: EOAProvider, tx });
      },
      [EOAProvider]
    );

    const value: Web3ContextValue = useMemo(() => {
      if (!provider) {
        return null;
      }

      // dangerous: mutable code to alter provider.estimateGas
      // gas estimation with AA account doesn't work on zkSync
      rewriteGasEstimation(provider, [Network.ZKSYNC_ERA, Network.ZKSYNC_GOERLI].includes(chainId));

      return {
        connectWallet,
        addAssets,
        updateRoutes,
        walletType: authentication.meta.walletType,
        provider,
        chainId: chainId!,
        account,
        eoaAccount,
        authentication: {
          ...authentication
        },
        isUsingUserWallet: account !== '',
        submitTransactions,
        submitStrippedTransactions,
        submitEOATransactions,
        env
      };
    }, [
      provider,
      connectWallet,
      addAssets,
      updateRoutes,
      chainId,
      account,
      eoaAccount,
      authentication,
      submitTransactions,
      submitStrippedTransactions,
      submitEOATransactions,
      env
    ]);

    if (!value) {
      return null;
    }

    return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
  }
);

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context);

  if (web3Context === null) {
    throw new Error('web3Context not ready');
  }

  return web3Context;
};
