import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { floor, isNil } from 'lodash';
import {
  Alert,
  Button,
  H5,
  Inline,
  SmallText,
  Stack,
  Text,
  Toggle
} from '@parallel-mono/components';
import { BigNumber } from 'bignumber.js';
import {
  InfoPanel,
  SelectableToken,
  SelectableTokenValue
} from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';
import { useNavigate } from 'react-router-dom';

import { Repayment, RepayFormData, RepayModalData } from './index';

import {
  MIN_BALANCE_THRESHOLD,
  formatBalance,
  formatToCurrency
} from '@/apps/paraspace/utils/format';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { MAXIMUM_BALANCE_DECIMALS, MINIMUM_ACCOUNTABLE_NUM } from '@/apps/paraspace/pages/config';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { NumberRange, SelectableTokenInput, BorrowLimitBar } from '@/apps/paraspace/components';
import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { zero } from '@/apps/paraspace/consts/values';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { useIsNativeTokenCheck } from '@/apps/paraspace/pages/hooks/useIsNativeTokenCheck';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';

const BorrowedLimitContainer = styled(Stack)`
  padding: 0.5rem 0.75rem 1rem;
  border: 1px solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 0.5rem;
`;

const RepayAllLabel = styled(H5)`
  margin-left: 0.5rem;
`;

const validateErrorMessage = (amount: BigNumber, balance: BigNumber, borrowedAmount: BigNumber) => {
  if (!BigNumber(amount).isFinite() || amount.lte(0)) {
    return 'Invalid input';
  }
  if (balance.lt(amount)) {
    return 'Insufficient balance';
  }
  if (borrowedAmount.lt(amount)) {
    return 'Repay amount exceeds your borrowed position';
  }
  return '';
};

interface options {
  data: RepayModalData;
  onSubmit: (formData: RepayFormData) => void;
}
/* eslint max-lines-per-function: ["error", { "max": 340 }] */
const RepayForm = ({ data: { symbol, repayment }, onSubmit }: options) => {
  const [amount, setAmount] = useState<BigNumber | null>(null);
  const { overviewUserInfo, erc20InfoMap } = useMMProvider();
  const navigate = useNavigate();
  const [curSymbol, setCurSymbol] = useState(symbol);
  const { checkIsNativeTokenSymbol } = useIsNativeTokenCheck();
  const { erc20BalanceMap } = useEOABalances();

  const {
    displayName,
    priceInUsd,
    borrowedAmount = zero,
    suppliedAmount = zero,
    reserveLiquidationThreshold,
    baseLTVasCollateral,
    usageAsCollateralEnabledOnUser
  } = erc20InfoMap[curSymbol];

  const balance = useMemo(() => {
    if (repayment === Repayment.AA) return erc20InfoMap[curSymbol].balance ?? zero;
    if (repayment === Repayment.EOA) return erc20BalanceMap?.[curSymbol] ?? zero;
    return erc20InfoMap[curSymbol].suppliedAmount ?? zero;
  }, [curSymbol, erc20BalanceMap, erc20InfoMap, repayment]);

  const isETHorWETH = [ERC20Symbol.WETH, ERC20Symbol.ETH].includes(curSymbol);

  const [forceToRepayAll, setForceToRepayAll] = useState(false);
  const { totalBorrowedPositionInUsd, borrowLimitInUsd } = overviewUserInfo;
  const shouldDisableInput = useMemo(() => forceToRepayAll, [forceToRepayAll]);
  const errorMessage = useMemo(() => {
    if (!isNil(amount) && balance && !shouldDisableInput) {
      return validateErrorMessage(amount, balance, borrowedAmount);
    }
    return null;
  }, [amount, borrowedAmount, balance, shouldDisableInput]);

  const [curAmountInUsd, newBorrowedPositionInUsd] = useMemo(() => {
    try {
      const decrease = errorMessage || isNil(amount) ? 0 : priceInUsd.times(amount);

      const result = totalBorrowedPositionInUsd.minus(decrease);
      return [decrease, result.gt(MINIMUM_ACCOUNTABLE_NUM) ? result : new BigNumber(0)];
    } catch {
      return [zero, totalBorrowedPositionInUsd];
    }
  }, [amount, priceInUsd, errorMessage, totalBorrowedPositionInUsd]);

  const isDisableRepayAllToggle = useMemo(() => {
    if (repayment === Repayment.Supplied) return false;
    const isNativeToken = checkIsNativeTokenSymbol(curSymbol);
    if (!isNativeToken) return balance?.lt(borrowedAmount ?? 0);
    return balance.minus(isNativeToken ? MIN_BALANCE_THRESHOLD : 0)?.lt(borrowedAmount ?? 0);
  }, [balance, borrowedAmount, repayment, curSymbol, checkIsNativeTokenSymbol]);

  const isDisableRepay = useMemo(() => {
    if (errorMessage || (amount ?? zero).eq(0)) return true;

    return false;
  }, [amount, errorMessage]);

  const handleValueChange = useCallback((value: number | null) => {
    setAmount(!isNil(value) ? BigNumber(value) : null);
  }, []);

  const handleInputChange = useCallback(
    ({ amount: newAmount, token }: SelectableTokenValue<SelectableToken>) => {
      const { symbol: newSymbol } = token!;
      if (newSymbol !== curSymbol) {
        setCurSymbol(newSymbol as ERC20Symbol);
      }

      handleValueChange(newAmount);
    },
    [curSymbol, handleValueChange]
  );

  const handleRepay = useCallback(async () => {
    if (!amount) return;

    onSubmit({ amount, symbol: curSymbol, isRepayAll: forceToRepayAll, repayment });
  }, [amount, forceToRepayAll, onSubmit, repayment, curSymbol]);

  const handleToggleRepayAll = useCallback(
    newValue => {
      setForceToRepayAll(newValue);
      if (!newValue) {
        setAmount(null);
        return;
      }
      setAmount(BigNumber.min(balance, borrowedAmount) ?? null);
    },
    [borrowedAmount, balance]
  );
  const curSelectedTokenInfo = useMemo(() => {
    return {
      token: {
        name: displayName,
        symbol: curSymbol,
        balance: balance?.toNumber() || 0,
        priceInUSD: priceInUsd.toNumber(),
        displayBalance: formatBalance(balance ?? 0)
      },
      amount:
        amount?.decimalPlaces(MAXIMUM_BALANCE_DECIMALS, BigNumber.ROUND_DOWN).toNumber() ?? null
    };
  }, [amount, balance, curSymbol, displayName, priceInUsd]);

  const tokensInfo = useMemo(() => {
    if (!isETHorWETH) {
      return [
        {
          name: displayName,
          symbol: curSymbol,
          balance: balance?.toNumber() || 0,
          priceInUSD: priceInUsd.toNumber(),
          displayBalance: formatBalance(balance)
        }
      ];
    }

    const ethInfo = erc20InfoMap[ERC20Symbol.ETH];
    const wethInfo = erc20InfoMap[ERC20Symbol.WETH];
    const ethBalanceMap = {
      [Repayment.AA]: ethInfo.balance,
      [Repayment.EOA]: erc20BalanceMap?.[ERC20Symbol.ETH],
      [Repayment.Supplied]: ethInfo.suppliedAmount
    };
    const wethBalanceMap = {
      [Repayment.AA]: wethInfo.balance,
      [Repayment.EOA]: erc20BalanceMap?.[ERC20Symbol.WETH],
      [Repayment.Supplied]: wethInfo.suppliedAmount
    };

    return [
      ethInfo
        ? {
            name: ethInfo?.displayName,
            symbol: ethInfo?.symbol,
            balance: ethBalanceMap[repayment]?.toNumber(),
            priceInUSD: ethInfo?.priceInUsd?.toNumber(),
            displayBalance: formatBalance(ethBalanceMap[repayment] ?? 0)
          }
        : null,
      {
        name: wethInfo.displayName,
        symbol: wethInfo.symbol,
        balance: wethBalanceMap[repayment]?.toNumber(),
        priceInUSD: wethInfo.priceInUsd.toNumber(),
        displayBalance: formatBalance(wethBalanceMap[repayment] ?? 0)
      }
    ].filter(item => item) as SelectableToken[];
  }, [
    isETHorWETH,
    erc20InfoMap,
    erc20BalanceMap,
    repayment,
    displayName,
    curSymbol,
    balance,
    priceInUsd
  ]);

  const updateErc20Asset = useMemo(() => {
    const targetAsset = {
      id: curSymbol,
      value: Number.isFinite(amount) ? priceInUsd.times(amount!) : new BigNumber(0),
      LTV: baseLTVasCollateral,
      reserveLiquidationThreshold
    };

    const withdrawValue =
      usageAsCollateralEnabledOnUser &&
      Number.isFinite(amount) &&
      suppliedAmount &&
      suppliedAmount.gte(amount ?? 0)
        ? priceInUsd.times(amount!)
        : new BigNumber(0);

    if (repayment === Repayment.Supplied) {
      return [
        { type: ActionTypeEnum.REPAY, targetAsset },
        { type: ActionTypeEnum.WITHDRAW, targetAsset: { ...targetAsset, value: withdrawValue } }
      ];
    }
    return [{ type: ActionTypeEnum.REPAY, targetAsset }];
  }, [
    amount,
    baseLTVasCollateral,
    curSymbol,
    priceInUsd,
    repayment,
    reserveLiquidationThreshold,
    suppliedAmount,
    usageAsCollateralEnabledOnUser
  ]);

  const {
    borrowLimitInUsd: newBorrowLimitInUsd,
    totalCollateralPositionInUsd,
    liquidationThresholdInUsd
  } = useLendingSimulation(updateErc20Asset);

  const newSupplyAmount = useMemo(() => {
    if (!suppliedAmount) return new BigNumber(0);

    return suppliedAmount.gte(amount ?? 0) ? suppliedAmount.minus(amount ?? 0) : suppliedAmount;
  }, [amount, suppliedAmount]);

  const { features } = useAppConfig();

  return (
    <Stack gap="1.5rem">
      <Inline justifyContent="space-between">
        <H5>Amount</H5>
        <Text skin="secondary">
          Borrowing: {borrowedAmount ? `${formatBalance(borrowedAmount)} ${displayName}` : '-'}
        </Text>
      </Inline>
      <SelectableTokenInput
        value={curSelectedTokenInfo}
        tokens={tokensInfo}
        decimals={MAXIMUM_BALANCE_DECIMALS}
        onChange={handleInputChange}
        onActionButtonClicked={
          shouldDisableInput || !borrowedAmount
            ? undefined
            : () => {
                handleValueChange(
                  floor(
                    Math.min(borrowedAmount.toNumber(), balance?.toNumber()),
                    MAXIMUM_BALANCE_DECIMALS
                  )
                );
                if (balance.gt(borrowedAmount)) {
                  setForceToRepayAll(true);
                }
              }
        }
        actionButtonText="Max"
        inputProps={{
          disabled: shouldDisableInput,
          autoFocus: true,
          title: curAmountInUsd ? `$${formatBalance(curAmountInUsd)}` : ''
        }}
        classNames={{
          tokenSelect: {
            menu: 'token-select-menu',
            item: 'token-select-item'
          }
        }}
      />
      <Toggle
        disabled={isDisableRepayAllToggle}
        checked={forceToRepayAll}
        onChange={handleToggleRepayAll}
        label={<RepayAllLabel>Repay All</RepayAllLabel>}
      />
      {!forceToRepayAll && (
        <Alert>
          If you want to repay all, please turn the toggle on. Otherwise some debt balance may
          remain.
        </Alert>
      )}
      <BorrowedLimitContainer gap="0.5rem">
        <Inline gap="0.25rem">
          <SmallText skin="secondary">Borrowed:</SmallText>
          <H5>${formatNumber(totalBorrowedPositionInUsd)}</H5>
        </Inline>
        <BorrowLimitBar
          borrowedValue={newBorrowedPositionInUsd}
          borrowLimitValue={newBorrowLimitInUsd}
          liquidationPointValue={liquidationThresholdInUsd}
          totalSuppliedValue={totalCollateralPositionInUsd}
        />
      </BorrowedLimitContainer>
      <InfoPanel
        skin="primary"
        infos={
          repayment !== Repayment.Supplied
            ? [
                {
                  title: 'New Borrowed Value',
                  value: (
                    <NumberRange
                      start={totalBorrowedPositionInUsd.toNumber()}
                      end={newBorrowedPositionInUsd.toNumber()}
                      formatter={formatToCurrency}
                    />
                  )
                }
              ]
            : [
                {
                  title: `Supplied ${curSymbol}`,
                  value: (
                    <NumberRange
                      start={suppliedAmount?.toNumber() ?? 0}
                      end={newSupplyAmount.toNumber()}
                      formatter={formatNumber}
                    />
                  )
                },
                {
                  title: 'New Borrow Limit',
                  value: (
                    <NumberRange
                      start={borrowLimitInUsd.toNumber()}
                      end={newBorrowLimitInUsd.toNumber()}
                      formatter={formatToCurrency}
                    />
                  )
                }
              ]
        }
      />
      {features.includes(Feature.RepayDelay) && (
        <Alert type="warning">
          Due to the zkSync timestamp issue, please ensure that the Repay and the Borrow are
          separated by 1～2 minutes
        </Alert>
      )}

      <Button size="large" block skin="primary" disabled={isDisableRepay} onClick={handleRepay}>
        {errorMessage || 'Repay'}
      </Button>

      {repayment === Repayment.Supplied && amount && amount.gt(suppliedAmount ?? zero) && (
        <Button
          size="large"
          block
          onClick={() => {
            navigate(absoluteRouteNames.APE_STAKING.COIN_POOL.index);
          }}
        >
          Supply More
        </Button>
      )}
    </Stack>
  );
};
export default RepayForm;
