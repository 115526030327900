import { noop } from 'lodash';
import { useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';

import { BridgeSubmitterModalProps } from '../BridgeTokenModal';
import { parallelChain } from '../../../configs';

import { zero } from '@/apps/parax/consts';

export const useBridgeToken = (): [
  BridgeSubmitterModalProps,
  (params: {
    symbol: string;
    amount: BigNumber;
    receiver: string;
    l1GasFeeInWei?: BigNumber;
    l2GasFeeInWei?: BigNumber;
    toChainEid: number;
  }) => Promise<void>
] => {
  const [modalProps, setModalProps] = useState<BridgeSubmitterModalProps>({
    isOpen: false,
    formData: {
      symbol: '',
      amount: zero,
      receiver: '',
      toChainEid: parallelChain.eId!
    },
    onClose: noop
  });

  const bridgeToken = useCallback(
    ({
      symbol,
      amount,
      receiver,
      l1GasFeeInWei,
      l2GasFeeInWei,
      toChainEid
    }: {
      symbol: string;
      amount: BigNumber;
      receiver: string;
      l1GasFeeInWei?: BigNumber;
      l2GasFeeInWei?: BigNumber;
      toChainEid: number;
    }) => {
      return new Promise<void>((resolve, reject) => {
        setModalProps({
          isOpen: true,
          formData: {
            symbol,
            amount,
            receiver,
            l1GasFeeInWei,
            l2GasFeeInWei,
            toChainEid
          },
          onFinish: resolve,
          onError: reject,
          onClose: () => setModalProps(curr => ({ ...curr, isOpen: false }))
        });
      });
    },
    []
  );

  return [modalProps, bridgeToken];
};
