import { memo, useEffect, useRef, useState } from 'react';
import { Inline, Spinner } from '@parallel-mono/components';
import { last } from 'lodash';
import { StakingType } from 'paraspace-utilities-contract-helpers';
import { Maybe } from 'graphql/jsutils/Maybe';

import { ApeCoinListing, ApeListing, BakcListing } from '../types';
import { formatListingInput } from '../utils';

import { useMatchP2PApeStakingListingMutation } from '@/apps/paraspace/generated/graphql';
import { useWeb3Context, useParallelToast } from '@/apps/paraspace/contexts';
import useP2PPairStaking from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { getUserFriendlyError, ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useContractsMap } from '@/apps/paraspace/hooks';

type MatchListingsSubmitterProps = {
  formData: {
    apeListing: ApeListing;
    apeCoinListing: ApeCoinListing;
    bakcListing?: Maybe<BakcListing>;
  };
  onFinish?: () => void;
  onError?: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const MatchListingsSubmitter = memo(
  ({ onFinish, onError, formData }: MatchListingsSubmitterProps) => {
    const { apeCoinListing, apeListing, bakcListing } = formData;
    const [matching, setMatching] = useState(false);

    const contracts = useContractsMap();

    const { account } = useWeb3Context();

    const parallelToast = useParallelToast();

    const {
      matchPairStakingList: matchPairStakingListFromContract,
      matchBakcPairStakingList: matchBakcPairStakingListFromContract
    } = useP2PPairStaking();
    const [matchP2PApeStakingListingFromService] = useMatchP2PApeStakingListingMutation();

    const onFinishRef = useRef<MatchListingsSubmitterProps['onFinish'] | null>(null);
    onFinishRef.current = onFinish;
    const onErrorRef = useRef<MatchListingsSubmitterProps['onError'] | null>(null);
    onErrorRef.current = onError;

    useEffect(() => {
      setMatching(true);
      const apeListingInput = formatListingInput(apeListing);
      const apeCoinListingInput = formatListingInput(apeCoinListing);
      const bakcListingInput = bakcListing ? formatListingInput(bakcListing) : null;

      parallelToast.promise(
        (apeListingInput.stakingType === StakingType.BAKCPairStaking
          ? matchBakcPairStakingListFromContract(
              apeListingInput,
              bakcListingInput!,
              apeCoinListingInput
            )
          : matchPairStakingListFromContract(apeListingInput, apeCoinListingInput)
        )
          .then(res => res?.wait())
          .then(txReceipt => {
            const matchedHash = last(txReceipt?.logs)?.data ?? '';
            const blockHeight = txReceipt?.blockNumber ?? '';
            matchP2PApeStakingListingFromService({
              variables: {
                blockHeight: blockHeight.toString(),
                matchedHash,
                matchedListings: {
                  apeCoinListing: {
                    ...apeCoinListingInput,
                    listingHash: apeCoinListing.listingHash
                  },
                  apeListing: {
                    ...apeListingInput,
                    listingHash: apeListing.listingHash
                  },
                  bakcListing: bakcListingInput
                    ? {
                        ...bakcListingInput,
                        listingHash: bakcListing?.listingHash ?? ''
                      }
                    : null
                },
                stakingType: apeListing.stakingType
              }
            });
          })
          .then(() => {
            setMatching(false);
            onFinishRef.current?.();
          })
          .catch(error => {
            setMatching(false);
            const errConfig = getUserFriendlyError<ErrorConfig>(error, undefined, 'errObj');
            onErrorRef.current?.(errConfig ?? null);

            throw getUserFriendlyError(error);
          })
      );
    }, [
      apeListing,
      apeCoinListing,
      setMatching,
      matchP2PApeStakingListingFromService,
      account,
      parallelToast,
      matchPairStakingListFromContract,
      contracts,
      bakcListing,
      matchBakcPairStakingListFromContract
    ]);

    if (matching) {
      return (
        <Inline justifyContent="center">
          <Spinner size="large" />
        </Inline>
      );
    }

    return null;
  }
);
