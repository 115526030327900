import { Card, Stack, H5, useBreakpoints } from '@parallel-mono/components';
import styled from 'styled-components';
import { useCallback, useMemo, useState } from 'react';
import BigNumberJs from 'bignumber.js';
import ReactGA from 'react-ga4';

import { useBuyCartProvider } from '../../context/BuyCartProvider';
import { BuyCartAsset, BuyCartAssetForParaSpace } from '../../context/types';
import useBatchNftCredit from '../../context/useBatchNftCredit';

import { PayLaterEditModal } from './PayLaterEditModal';
import { BuyCartForm } from './BuyCartForm';
import { BuyCartNftList } from './BuyCartNftList';
import { BatchBuyModal } from './BatchBuyModal';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol, Platform } from '@/apps/paraspace/typings';
import { Marketplace } from '@/apps/paraspace/generated/graphql';
import { useParallelToast, useWeb3Context } from '@/apps/paraspace/contexts';
import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { useNFTBuyErrorHandler } from '@/apps/paraspace/pages/Shop/hooks';

const DesktopCart = styled(Card)`
  position: sticky;
  top: calc(var(--header-height-pc) + 1.5rem);
  width: 18rem;
  max-height: calc(100vh - calc(var(--header-height-pc) + 3rem));
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  margin-left: 1rem;
`;

const MobileCart = styled(Stack).attrs({ inset: '1.5rem' })`
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100vw;
  background-color: ${({ theme }) => theme.skin.background.main};
  border-top: ${({ theme }) => `${theme.border.width.small} solid ${theme.skin.grey[200]}`};
  animation: mobile-cart-animation ease-out 0.2s forwards;

  @keyframes mobile-cart-animation {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

export type BuyCartProps = {
  handleBuy?: (buyNowList: BuyCartAsset[]) => void;
};

export const BuyCart = (props: BuyCartProps) => {
  const { handleBuy } = props;
  const [isPayLaterEditModalOpen, setIsPayLaterEditModalOpen] = useState(false);
  const [payLaterValue, setPayLaterValue] = useState<number | null>(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isBuyLoading, setIsBuyLoading] = useState(false);

  const { batchBuyWithCredit } = usePool();
  const { submitTransactions } = useWeb3Context();
  const { handleNFTsErrors } = useNFTBuyErrorHandler();
  const toast = useParallelToast();
  const { isShowCart } = useBuyCartProvider();
  const { erc20InfoMap } = useMMProvider();
  const { totalPrice, buyCartList, removeBuyCartList } = useBuyCartProvider();
  const { priceInUsd: ethPriceInUsd, availableLiquidity } = erc20InfoMap[ERC20Symbol.ETH] || {};

  const futureAvailableCredit = useBatchNftCredit({
    tokenList: buyCartList,
    creditBasedCurrencyValueInUsd: ethPriceInUsd
  });

  const creditAmount = useMemo(() => {
    return payLaterValue === null
      ? BigNumberJs.min(futureAvailableCredit, totalPrice)
      : BigNumberJs.min(payLaterValue, totalPrice);
  }, [futureAvailableCredit, payLaterValue, totalPrice]);

  const handleClosePayLaterEditModal = useCallback(() => {
    setIsPayLaterEditModalOpen(false);
  }, []);

  const handleOpenPayLaterEditModal = useCallback(() => {
    setIsPayLaterEditModalOpen(true);
  }, []);
  const buyNowAmount = useMemo(() => totalPrice.minus(creditAmount), [creditAmount, totalPrice]);

  const buySuccessHandler = useCallback(async () => {
    if (handleBuy) handleBuy(buyCartList);
    setIsBuyLoading(false);
    removeBuyCartList(buyCartList);
    buyCartList.forEach(item =>
      ReactGA.event({
        action: 'bnpl',
        category: 'erc721',
        label: item.symbol,
        value: item.tokenId
      })
    );
  }, [buyCartList, handleBuy, removeBuyCartList]);

  const buyErrorHandler = useCallback(
    async (e: any, txData: any) => {
      const originOwners = (txData as BuyCartAssetForParaSpace[])?.map(each =>
        (each.protocolData?.parameters?.offerer || '').toLowerCase()
      );
      setIsBuyLoading(false);
      return handleNFTsErrors(
        e,
        originOwners,
        buyCartList.map(({ symbol, tokenId }) => ({ symbol, tokenId }))
      );
    },
    [buyCartList, handleNFTsErrors]
  );

  const handlePay = useCallback(() => {
    setIsBuyLoading(true);
    // for now, only same marketplace can batch together
    if (buyCartList[0].platform !== Marketplace.ParaSpace) {
      setIsPaymentModalOpen(true);
      return;
    }

    // paraspace listing
    const payLaterAmounts = buyCartList.map(item => {
      return new BigNumberJs(creditAmount).times(item.price.div(totalPrice)).toString();
    });
    const platforms = buyCartList.map(item => item.platform.toUpperCase()) as Platform[];
    const protocolVersions = (buyCartList as BuyCartAssetForParaSpace[]).map(
      item => item.protocolVersion
    );
    const protocolContracts = (buyCartList as BuyCartAssetForParaSpace[]).map(
      item => item.protocolContract
    );
    const marketProtocolData = (buyCartList as BuyCartAssetForParaSpace[]).map(
      item => item.protocolData
    );
    toast.promise(
      batchBuyWithCredit({
        buyNowAmount: buyNowAmount.toString(),
        payLaterAmounts,
        platforms,
        marketProtocolData,
        protocolVersions,
        protocolContracts
      })
        .then(txs => submitTransactions(txs))
        .then(async tx => {
          await tx?.wait();
          await buySuccessHandler();
        })
        .catch(async (error: Error) => buyErrorHandler(error, buyCartList))
    );
  }, [
    buyCartList,
    toast,
    batchBuyWithCredit,
    submitTransactions,
    buyNowAmount,
    creditAmount,
    buySuccessHandler,
    buyErrorHandler,
    totalPrice
  ]);

  const handleBatchBuyClose = useCallback(() => {
    setIsPaymentModalOpen(false);
  }, []);

  const handleBatchBuyError = useCallback(
    (error: any) => {
      buyErrorHandler(error, buyCartList);
    },
    [buyCartList, buyErrorHandler]
  );

  const payload = useMemo(
    () => ({
      buyNowAmount,
      creditAmount,
      platform: buyCartList[0]?.platform
    }),
    [buyCartList, buyNowAmount, creditAmount]
  );

  const { mobile } = useBreakpoints();

  if (!isShowCart) {
    return null;
  }

  return (
    <>
      <PayLaterEditModal
        isOpen={isPayLaterEditModalOpen}
        title="Pay Later"
        size="30rem"
        payload={{
          credit: futureAvailableCredit,
          totalPrice,
          availableLiquidity,
          defaultValue: creditAmount.toNumber()
        }}
        onClose={handleClosePayLaterEditModal}
        onFinish={setPayLaterValue}
      />
      <BatchBuyModal
        isOpen={isPaymentModalOpen}
        payload={payload}
        onFinish={buySuccessHandler}
        onClose={handleBatchBuyClose}
        onError={handleBatchBuyError}
      />
      {mobile ? (
        <MobileCart>
          <BuyCartForm
            isBuyLoading={isBuyLoading}
            onEditPayLater={handleOpenPayLaterEditModal}
            creditAmount={creditAmount}
            onClickPay={handlePay}
          />
        </MobileCart>
      ) : (
        <DesktopCart border>
          <Stack gap="1.5rem">
            <H5>Buy</H5>
            <BuyCartNftList />
            <BuyCartForm
              isBuyLoading={isBuyLoading}
              onEditPayLater={handleOpenPayLaterEditModal}
              creditAmount={creditAmount}
              onClickPay={handlePay}
            />
          </Stack>
        </DesktopCart>
      )}
    </>
  );
};
