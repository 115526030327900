import BigNumberJs from 'bignumber.js';
import { Inline, H6 } from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

type PriceContainerProps = {
  price: BigNumberJs;
  currency: string;
};

export const PriceContainer = ({ price, currency }: PriceContainerProps) => {
  return (
    <Inline gap="0.25rem" alignItems="center">
      <CryptoIcon symbol={currency} size="1.25rem" />
      <H6>{formatNumber(price)}</H6>
    </Inline>
  );
};
