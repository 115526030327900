import { Inline, Pagination, Spinner, Stack, Responsive } from '@parallel-mono/components';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { groupBy, mapKeys, mapValues } from 'lodash';

import { useApeListStatesAndActions, ApeListItem } from '../../../../contexts/ApeListProvider';
import { LiquidationTag } from '../../../../components';
import { useApeStakeManager } from '../../../../contexts';

import { EmptyState } from './EmptyState';
import { ApeItemCard } from './ApeItemCard';

import { useApeStakeManager as useOfficialApeStakeManager } from '@/apps/paraspace/pages/OfficialPairing/ApeStakingManagerProvider';
import { usePagination } from '@/apps/paraspace/hooks/usePagination';
import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC721Symbol,
  WalletType
} from '@/apps/paraspace/typings';
import { useAccountLiquidationStatus } from '@/apps/paraspace/pages/hooks/useAccountLiquidationStatus';
import { StakeInfoListItem } from '@/apps/paraspace/pages/OfficialPairing/types';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { TransferNftParams } from '@/apps/paraspace/pages/ApePairing/contexts/ApeStakingManagerProvider/TransferModal';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import {
  MultipleSelect,
  NFTCollectionThumbnail,
  SelectBaseOption
} from '@/apps/paraspace/components';
import { zero } from '@/apps/paraspace/consts/values';
import { useCheckV1TimelockStatus } from '@/apps/paraspace/pages/Credit';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';
import { useGetERC721Image } from '@/apps/paraspace/hooks';

const stakePoolOptions = [ERC721Symbol.BAYC, ERC721Symbol.MAYC, ERC721Symbol.BAKC].map(symbol => ({
  icon: <NFTCollectionThumbnail symbol={symbol} size="small" round />,
  label: `${symbol} Pool`,
  value: symbol
}));

const PAGE_SIZE = 8;

export const ApeList = memo(props => {
  const { loadERC20Balance } = useMMProvider();
  const { refreshERC20BalanceMap } = useEOABalances();
  const { withdrawApe: withdrawNotSuppliedApe } = useOfficialApeStakeManager();
  const { stakeApe, withdrawApe, transferNft, stakeBakc } = useApeStakeManager();
  const { apesInBalanceAndInSuppliedExcludingInP2P, apeInfoListLoaded, refresh } =
    useApeListStatesAndActions();
  const { inLiquidation } = useAccountLiquidationStatus();
  const { nftPoolsCompoundApy } = useAutoCompoundApeInfo();

  const [selectedPools, setSelectedPools] = useState<SelectBaseOption[]>([]);
  const selectedCollectionSymbols = useMemo(
    () => selectedPools.map(op => op.value),
    [selectedPools]
  );
  const { checkIfTokenInPairing } = useP2PInfo();
  const apeInfoList = useMemo(
    () =>
      (selectedCollectionSymbols.length
        ? apesInBalanceAndInSuppliedExcludingInP2P.filter(info =>
            selectedCollectionSymbols.includes(info.symbol)
          )
        : apesInBalanceAndInSuppliedExcludingInP2P
      ).filter(each => !checkIfTokenInPairing(each.symbol, each.tokenId)),
    [selectedCollectionSymbols, apesInBalanceAndInSuppliedExcludingInP2P, checkIfTokenInPairing]
  );

  const refreshRef = useRef<(() => void) | null>(null);
  refreshRef.current = refresh;

  const handleStakeApe = useCallback(
    (apeInfo: ApeListItem, apeCoinSource: WalletType) => {
      const stakeParam = {
        apy: apeInfo.apy!,
        stakeLimit: apeInfo.stakeLimit!,
        stakedAmount: apeInfo.stakedAmount ?? zero,
        pendingRewards: apeInfo.pendingRewards ?? zero,
        symbol: apeInfo.symbol as ApeStakingMainTokenSymbol,
        tokenId: apeInfo.tokenId,
        supplied: apeInfo.supplied,
        apeSource: apeInfo.source ?? null,
        apeCoinSource
      };
      stakeApe(stakeParam).then(() => {
        if (apeCoinSource === 'AA') {
          loadERC20Balance();
        } else {
          refreshERC20BalanceMap();
        }
        refreshRef.current?.();
      });
    },
    [stakeApe, loadERC20Balance, refreshERC20BalanceMap]
  );

  const { pageData, totalPage, currentPage, setCurrentPage } = usePagination(
    apeInfoList,
    PAGE_SIZE
  );

  // calculate nft compound apy
  const { erc20InfoMap, nftInfoMap } = useMMProvider();

  const { isLoading: isLoadingBaycV1TimelockStatus, tokensStatus: baycV1TimelockStatus } =
    useCheckV1TimelockStatus(
      nftInfoMap[ERC721Symbol.BAYC]?.address ?? '',
      nftInfoMap[ERC721Symbol.BAYC]?.nftSuppliedList ?? []
    );
  const baycV1TimelockStatusMap = useMemo(
    () =>
      mapKeys(
        baycV1TimelockStatus?.filter(it => !it.isClaimed),
        it => `${ERC721Symbol.BAYC}_${it.tokenId}`
      ),
    [baycV1TimelockStatus]
  );

  const { isLoading: isLoadingMaycV1TimelockStatus, tokensStatus: maycV1TimelockStatus } =
    useCheckV1TimelockStatus(
      nftInfoMap[ERC721Symbol.MAYC]?.address ?? '',
      nftInfoMap[ERC721Symbol.MAYC]?.nftSuppliedList ?? []
    );
  const maycV1TimelockStatusMap = useMemo(
    () =>
      mapKeys(
        maycV1TimelockStatus?.filter(it => !it.isClaimed),
        it => `${ERC721Symbol.MAYC}_${it.tokenId}`
      ),
    [maycV1TimelockStatus]
  );

  const { isLoading: isLoadingBakcV1TimelockStatus, tokensStatus: bakcV1TimelockStatus } =
    useCheckV1TimelockStatus(
      nftInfoMap[ERC721Symbol.BAKC]?.address ?? '',
      nftInfoMap[ERC721Symbol.BAKC]?.nftSuppliedList ?? []
    );
  const bakcV1TimelockStatusMap = useMemo(
    () =>
      mapKeys(
        bakcV1TimelockStatus?.filter(it => !it.isClaimed),
        it => `${ERC721Symbol.BAKC}_${it.tokenId}`
      ),
    [bakcV1TimelockStatus]
  );

  const tokenV1TimelockStatusMap = useMemo(
    () => ({
      ...baycV1TimelockStatusMap,
      ...maycV1TimelockStatusMap,
      ...bakcV1TimelockStatusMap
    }),
    [baycV1TimelockStatusMap, maycV1TimelockStatusMap, bakcV1TimelockStatusMap]
  );

  const handleTransfer = useCallback(
    (params: TransferNftParams) => {
      transferNft(params).then(() => {
        refresh();
      });
    },
    [refresh, transferNft]
  );

  const getERC721Image = useGetERC721Image();

  const handleWithdraw = useCallback(
    (tokenInfo: ApeListItem, isSupplied: boolean) => {
      const { symbol, tokenId, mainTokenId, mainTokenSymbol, stakedAmount, pendingRewards } =
        tokenInfo;

      if (isSupplied) {
        withdrawApe({
          symbol,
          tokenId,
          mainTokenId,
          mainTokenSymbol,
          stakedAmount,
          pendingRewards
        }).then(() => {
          refresh();
        });
        return;
      }

      withdrawNotSuppliedApe({
        ...tokenInfo,
        thumbnail: getERC721Image({ symbol, tokenId })
      } as StakeInfoListItem).then(() => {
        refresh();
      });
    },
    [withdrawApe, withdrawNotSuppliedApe, refresh, getERC721Image]
  );

  const handleStakeBAKC = useCallback(
    (tokenInfo: ApeListItem, apeCoinSource: WalletType) => {
      stakeBakc(tokenInfo.tokenId, tokenInfo.source ?? null, apeCoinSource).then(() => {
        if (apeCoinSource === 'AA') {
          loadERC20Balance();
        } else {
          refreshERC20BalanceMap();
        }
      });
    },
    [stakeBakc, loadERC20Balance, refreshERC20BalanceMap]
  );

  const { borrowApyRate } = erc20InfoMap.APE || {};

  const nftCompoundAPY: {
    [key in ApeStakingTokenSymbol]: {
      maxApy: number;
      minApy: number;
    };
  } = useMemo(() => {
    const groups = groupBy(apesInBalanceAndInSuppliedExcludingInP2P, 'symbol') as {
      [key in ApeStakingTokenSymbol]: ApeListItem[];
    };
    return mapValues(groups, item => {
      const { symbol } = item[0];
      const compoundApy = nftPoolsCompoundApy?.[symbol];
      const minApy = compoundApy?.minus(borrowApyRate);
      return { maxApy: compoundApy?.toNumber() ?? 0, minApy: minApy?.toNumber() ?? 0 };
    });
  }, [apesInBalanceAndInSuppliedExcludingInP2P, borrowApyRate, nftPoolsCompoundApy]);

  const showLoading = useMemo(
    () =>
      !apeInfoListLoaded ||
      isLoadingBaycV1TimelockStatus ||
      isLoadingMaycV1TimelockStatus ||
      isLoadingBakcV1TimelockStatus,
    [
      apeInfoListLoaded,
      isLoadingBakcV1TimelockStatus,
      isLoadingBaycV1TimelockStatus,
      isLoadingMaycV1TimelockStatus
    ]
  );

  return (
    <Stack gap="1rem" {...props}>
      {showLoading && (
        <Inline width="100%" justifyContent="center" inset="2rem">
          <Spinner size="large" />
        </Inline>
      )}
      {!showLoading && (
        <>
          <Responsive justifyContent="space-between">
            <MultipleSelect
              placeholder="Pool"
              options={stakePoolOptions}
              value={selectedCollectionSymbols}
              onChange={setSelectedPools}
              menuAlign="left"
            />
            <LiquidationTag />
          </Responsive>

          <Stack>
            {pageData.map(ape => (
              <ApeItemCard
                key={`${ape.symbol}_${ape.tokenId}`}
                inLiquidation={inLiquidation}
                onStakeApe={handleStakeApe}
                onWithdraw={handleWithdraw}
                onTransfer={handleTransfer}
                onStakeBAKC={handleStakeBAKC}
                tokenInfo={ape}
                v1TimelockInfo={tokenV1TimelockStatusMap[`${ape.symbol}_${ape.tokenId}`]}
                nftCompoundAPY={nftCompoundAPY}
              />
            ))}
            {totalPage > 0 && (
              <Pagination
                total={totalPage}
                page={currentPage}
                onChange={setCurrentPage}
                siblingCount={0}
                startBoundaryCount={3}
              />
            )}
            {apeInfoListLoaded && apeInfoList.length === 0 && <EmptyState />}
          </Stack>
        </>
      )}
    </Stack>
  );
});
