import { H5, Inline } from '@parallel-mono/components';
import { memo, ReactNode } from 'react';
import styled from 'styled-components';

export type InfoPanelProps = {
  infos: {
    label: ReactNode;
    value: ReactNode;
  }[];
};

const Panel = styled.div`
  background-color: ${({ theme }) => theme.skin.secondary.main};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.skin.text.sub1};
`;

export const InfoPanel = memo((props: InfoPanelProps) => {
  const { infos } = props;
  return (
    <Panel>
      {infos.map(({ label, value }, index) => (
        <Inline key={index} justifyContent="space-between">
          <H5 fontWeight="regular" skin="secondary">
            {label}
          </H5>
          <H5>{value}</H5>
        </Inline>
      ))}
    </Panel>
  );
});
