import styled from 'styled-components';
import { Stack, Inline, H4, H6, Alert, Tag } from '@parallel-mono/components';
import BigNumberJs from 'bignumber.js';
import { CryptoIcon } from '@parallel-mono/business-components';

import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';

type TokenInfoContainerProps = {
  baseTokenLowPrice: BigNumberJs;
  baseTokenUpPrice: BigNumberJs;
  baseTokenCurrentPrice: BigNumberJs;
  convertTokenSymbol: string;
  baseTokenSymbol: string;
  feeRate: BigNumberJs;
  isInRange: boolean;
  isClosed: boolean;
};

type PriceContainerProps = {
  label: string;
  price: BigNumberJs;
  convertToken: string;
  baseToken: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 0.125rem solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 0.75rem;
`;

const PriceText = styled(H6)`
  font-weight: var(--font-weight-medium);
  color: ${({ theme }) => theme.skin.grey[700]};
`;

const PriceContainer = ({ label, price, convertToken, baseToken }: PriceContainerProps) => (
  <Container>
    <PriceText>{label} Price</PriceText>
    <Inline gap="0.25rem" alignItems="center">
      <H4>{price.isFinite() ? formatBalance(price) : '∞'}</H4>
      <PriceText>
        {convertToken} per {baseToken}
      </PriceText>
    </Inline>
  </Container>
);

const TokenInfoContainer = ({
  baseTokenLowPrice,
  baseTokenUpPrice,
  baseTokenCurrentPrice,
  convertTokenSymbol,
  baseTokenSymbol,
  feeRate,
  isInRange,
  isClosed
}: TokenInfoContainerProps) => {
  return (
    <>
      <Inline alignItems="center" justifyContent="space-between" gap="0">
        <Inline gap="0.5rem" alignItems="center" justifyContent="flex-start">
          <Inline gap="-0.5rem">
            <CryptoIcon symbol={baseTokenSymbol} size="small" />
            <CryptoIcon symbol={convertTokenSymbol} size="small" />
          </Inline>
          <H4>
            {baseTokenSymbol}/{convertTokenSymbol}
          </H4>
        </Inline>
        <Inline gap="0.5rem" alignItems="center">
          {isClosed ? (
            <Tag skin="neutral" size="small">
              Closed
            </Tag>
          ) : (
            <Tag skin={isInRange ? 'success' : 'warning'} size="small">
              {isInRange ? 'In Range' : 'Out of Range'}
            </Tag>
          )}
          <Tag skin="neutral" size="small">
            {formatToPercentage(feeRate.toNumber())} Fee
          </Tag>
        </Inline>
      </Inline>
      <Stack gap="1rem">
        <Inline gap="0.5rem" alignItems="center" justifyContent="space-between" grow>
          <PriceContainer
            label="Min"
            price={baseTokenLowPrice}
            convertToken={convertTokenSymbol}
            baseToken={baseTokenSymbol}
          />
          <PriceContainer
            label="Max"
            price={baseTokenUpPrice}
            convertToken={convertTokenSymbol}
            baseToken={baseTokenSymbol}
          />
        </Inline>
        <PriceContainer
          label="Current"
          price={baseTokenCurrentPrice}
          convertToken={convertTokenSymbol}
          baseToken={baseTokenSymbol}
        />
      </Stack>
      {!isInRange && (
        <Alert type="error">
          This market price is outside of your price range. Single deposit only.
        </Alert>
      )}
    </>
  );
};

export default TokenInfoContainer;
