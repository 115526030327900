import { useMemo } from 'react';
import BigNumberJs from 'bignumber.js';

import { NFT } from '../SupplyNftForm';

import { ApeStakingMainTokenSymbol, ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useMainToBakcMap } from '@/apps/paraspace/pages/hooks/ApeStaking/useMainToBakcMap';
import { StakingInfoMap } from '@/apps/paraspace/pages/hooks/ApeStaking/useApeStaking';
import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { zero } from '@/apps/paraspace/consts/values';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

export const useFutureBorrowPosition = ({
  symbol,
  showApeStakingInfo,
  selectedItems,
  stakingInfoMap
}: {
  showApeStakingInfo: boolean;
  symbol: ERC721Symbol;
  selectedItems: NFT[];
  stakingInfoMap: StakingInfoMap;
}) => {
  const {
    erc20InfoMap,
    overviewUserInfo: { borrowLimitInUsd }
  } = useMMProvider();

  const mainTokens = useMemo(() => {
    return showApeStakingInfo
      ? selectedItems.map(({ tokenId }) => {
          return {
            tokenId,
            symbol: symbol as ApeStakingMainTokenSymbol
          };
        })
      : [];
  }, [showApeStakingInfo, selectedItems, symbol]);

  const [mainToBakcMap] = useMainToBakcMap(mainTokens);
  const bakcStakingInfo = useMemo(() => {
    return showApeStakingInfo ? mainToBakcMap?.[symbol] || {} : {};
  }, [mainToBakcMap, showApeStakingInfo, symbol]);

  const { borrowLimitInUsd: newBorrowLimitInUsd } = useLendingSimulation(
    selectedItems
      .map(({ tokenId, ltv, reserveLiquidationThreshold, priceInUsd, multiplier }) => {
        const sAPEInfo = erc20InfoMap[ERC20Symbol.SAPE];
        const mainStakingAndRewards =
          stakingInfoMap[tokenId]?.stakedAmount.plus(stakingInfoMap[tokenId].pendingRewards) ||
          zero;
        // bakc staking part
        const bakcStaking = bakcStakingInfo[tokenId]?.stakedAmount || new BigNumberJs(0);

        return [
          // nft
          {
            type: ActionTypeEnum.SUPPLY,
            targetAsset: {
              id: `${tokenId}`,
              value: priceInUsd.times(multiplier),
              LTV: ltv,
              reserveLiquidationThreshold
            }
          },
          // bakc's staking won't have any effect on borrow limit
          showApeStakingInfo &&
            symbol !== ERC721Symbol.BAKC && {
              type: ActionTypeEnum.SUPPLY,
              targetAsset: {
                id: `${ERC20Symbol.SAPE}`,
                value: sAPEInfo.priceInUsd.times(mainStakingAndRewards.plus(bakcStaking)),
                LTV: sAPEInfo.baseLTVasCollateral,
                reserveLiquidationThreshold: sAPEInfo.reserveLiquidationThreshold
              }
            }
        ].filter((v): v is any => !!v);
      })
      .flat()
  );

  return {
    originalBorrowLimitInUsd: borrowLimitInUsd,
    newBorrowLimitInUsd
  };
};
