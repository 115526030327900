import { memo } from 'react';
import {
  Button,
  H5,
  H6,
  Inline,
  Skeleton,
  SmallText,
  Stack,
  StackProps,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import { isEmpty } from 'lodash';

import { UniswapToken } from '../types';
import { UniChainlink } from '../components';
import { SupplyERC721DropdownMenu, WalletsBalanceDropdown } from '../../../../components';

import { ERC20Symbol, FetchingStatus, WalletType } from '@/apps/paraspace/typings';
import { StackedIcons } from '@/apps/paraspace/components';
import { formatToCurrency } from '@/apps/paraspace/utils/format';

type UniSwapItemProps = Omit<StackProps, 'children'> & {
  onSupply: (
    arg: { token0Symbol: ERC20Symbol; token1Symbol: ERC20Symbol },
    walletType: WalletType
  ) => void;
  data: UniswapToken;
};

export const UniSwapItem = memo(({ onSupply, data, ...others }: UniSwapItemProps) => {
  const {
    name,
    balanceMap: {
      AA: { balances: paraXBalances, loading: paraXBalanceLoading },
      EOA: { balances: EOABalances, loading: EOABalanceLoading }
    },
    token0Symbol,
    token1Symbol,
    ltv,
    totalSupplied,
    totalUsd,
    balanceLoadingStatus
  } = data;
  const { mobile } = useBreakpoints();
  return (
    <Stack gap="1rem" {...others}>
      <Inline gap="0.5rem" alignItems="center">
        <Inline gap="-0.25rem" alignItems="center">
          <StackedIcons assets={[{ symbol: token0Symbol }, { symbol: token1Symbol }]} />
        </Inline>
        <H5>{name}</H5>
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Your Wallet
        </H6>
        {balanceLoadingStatus === FetchingStatus.FETCHING && <Skeleton.Title />}
        {balanceLoadingStatus !== FetchingStatus.FETCHING &&
          isEmpty(paraXBalances) &&
          isEmpty(EOABalances) && <Text>-</Text>}
        {balanceLoadingStatus !== FetchingStatus.FETCHING &&
          paraXBalances.concat(EOABalances).length > 0 && (
            <Stack gap="0.5rem" alignItems="flex-end">
              <StackedIcons
                assets={paraXBalances.concat(EOABalances)}
                variant="list"
                size="small"
              />
              <Inline>
                <WalletsBalanceDropdown
                  placement="bottom-end"
                  EOABalance={{
                    loading: EOABalanceLoading,
                    value: <H6>{EOABalances.length}</H6>
                  }}
                  paraXBalance={{
                    loading: paraXBalanceLoading,
                    value: <H6>{paraXBalances.length}</H6>
                  }}
                />
              </Inline>
            </Stack>
          )}
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Oracle Source
        </H6>
        <UniChainlink />
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Loan To Value
        </H6>
        {!ltv ? <Text>-</Text> : <Text>{formatNumber(ltv.toNumber(), { output: 'percent' })}</Text>}
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Total Supplied
        </H6>
        <Stack gap="0">
          <Text>{formatNumber(totalSupplied)}</Text>
          <SmallText skin="secondary">{formatToCurrency(totalUsd)}</SmallText>
        </Stack>
      </Inline>

      <SupplyERC721DropdownMenu
        menuTrigger={
          <Button block={mobile} skin="secondary">
            Supply
          </Button>
        }
        paraXWalletOption={{
          onClick: () => onSupply({ token0Symbol, token1Symbol }, 'AA'),
          balance: paraXBalances.length ?? 0
        }}
        EOAWalletOption={{
          onClick: () => onSupply({ token0Symbol, token1Symbol }, 'EOA'),
          balance: EOABalances.length ?? 0
        }}
      />
    </Stack>
  );
});
