import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { createContext, FC, useCallback, useContext } from 'react';
import { H5, Icon, Inline, InlineProps } from '@parallel-mono/components';

const BackLink = styled(Inline)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  cursor: pointer;
  width: fit-content;

  :hover {
    text-decoration: underline;
  }

  ${({ theme }) => theme.breakpoints.down('desktop')`
    position: unset;
    align-self: baseline;
    margin-bottom: 1.5rem;
  `};
`;

type GoBackProps = {
  goBack?: Function;
};

const ctx = createContext<{ path: string }>({ path: '/' });
const useGoBackContext = () => {
  return useContext(ctx);
};

export const GoBack: FC<GoBackProps & Omit<InlineProps, 'children'>> = ({ goBack, ...others }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { path } = useGoBackContext();
  const handleClick = useCallback(() => {
    if (goBack) {
      goBack();
      // https://stackoverflow.com/questions/37385570/how-to-know-if-react-router-can-go-back-to-display-back-button-in-react-app
    } else if (location.key && location.key !== 'default') {
      navigate(-1);
    } else {
      // fallback
      navigate(path);
    }
  }, [goBack, location.key, navigate, path]);

  return (
    <BackLink gap="0.5rem" onClick={handleClick} {...others}>
      <Icon name="arrowLeft" />
      <H5 fontWeight="medium">Back</H5>
    </BackLink>
  );
};

export const GoBackProvider = ctx.Provider;
