import { useCallback, useState } from 'react';

export enum CustomErrorType {
  NOT_FOUND = 'NOT_FOUND',
  UNKNOWN = 'UNKNOWN'
}

export class CustomError extends Error {
  type: CustomErrorType;

  stack?: string;

  constructor(type: CustomErrorType, message: string, stack?: string) {
    super(message);
    this.type = type;
    this.stack = stack;
  }
}

export const useErrorHandler = (): {
  setError: (type: CustomErrorType, error: Error) => void;
} => {
  const [error, setError] = useState<CustomError | null>(null);
  if (error != null) throw error;

  const errorHandler = useCallback((type: CustomErrorType, e: Error) => {
    const customError = new CustomError(type, e.message, e.stack);
    setError(customError);
  }, []);

  return { setError: errorHandler };
};
