import { Responsive } from '@parallel-mono/components';
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { filter } from 'lodash';

import { ListingType, ApeCoinListing, ApeListing, BakcListing, Listing } from '../../../types';
import { SEEK_SYMBOL_TO_LISTING_TYPE_MAP, formateSelectedListings } from '../../../utils';
import { useP2PStaking } from '../../../contexts';

import { BakcListingSelector } from './BakcListingSelector';
import { ListingTable, UpdateListingHandle } from './ListingTable';

import { ERC20Symbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';

type Props = {
  defaultSelectedListings: Listing[];
  onSelectListingChange: (listings: Listing[]) => void;
};
export const BakcListings: FC<Props> = memo(
  ({ defaultSelectedListings, onSelectListingChange }) => {
    const [selectedListings, setSelectedListings] = useState(defaultSelectedListings);
    const { nftPoolsCompoundApy } = useAutoCompoundApeInfo();
    const navigate = useNavigate();

    const compoundApy = useMemo(
      () => nftPoolsCompoundApy?.[ERC721Symbol.BAKC] ?? BigNumber(0),
      [nftPoolsCompoundApy]
    );
    const bakcPoolRef = useRef<UpdateListingHandle>(null);
    const apePoolRef = useRef<UpdateListingHandle>(null);
    const apeCoinPoolRef = useRef<UpdateListingHandle>(null);

    const { selectedBakcListing, selectedApeListing, selectedApeCoinListing } = useMemo(() => {
      return {
        selectedBakcListing:
          selectedListings.find(listing => listing.listingType === ListingType.BakcListing) ?? null,
        selectedApeListing:
          selectedListings.find(listing => listing.listingType === ListingType.ApeListing) ?? null,
        selectedApeCoinListing:
          selectedListings.find(listing => listing.listingType === ListingType.ApeCoinListing) ??
          null
      };
    }, [selectedListings]);

    useEffect(() => {
      onSelectListingChange(selectedListings);
    }, [onSelectListingChange, selectedListings]);

    const { joinBAKCPairListings } = useP2PStaking();

    const handleSelectedListings = useCallback((listing: Listing) => {
      setSelectedListings(prevState => {
        // unselecting listing if the same listing already.
        if (prevState.find(item => item.listingHash === listing.listingHash)) {
          return filter(prevState, item => item.listingHash !== listing.listingHash);
        }
        // Replace the old listing if there is the same type of listing.
        if (prevState.find(item => item.listingType === listing.listingType)) {
          const newListing = prevState.map(item => {
            return item.listingType === listing.listingType ? listing : item;
          });
          return newListing;
        }
        // Delete the earliest selected listing and add the new listing
        if (prevState.length >= 2) {
          return [prevState[1], listing];
        }
        return [...prevState, listing];
      });
    }, []);

    const handleJoinListing = useCallback(
      async (listings: Listing[], walletType: WalletType) => {
        const formattedListings = formateSelectedListings(listings);
        const apeListing =
          (formattedListings.find(it => it.listingType === ListingType.ApeListing) as ApeListing) ??
          null;
        const apeCoinListing =
          (formattedListings.find(
            it => it.listingType === ListingType.ApeCoinListing
          ) as ApeCoinListing) ?? null;
        const bakcListing =
          (formattedListings.find(
            it => it.listingType === ListingType.BakcListing
          ) as BakcListing) ?? null;

        joinBAKCPairListings({
          apeListing,
          apeCoinListing,
          bakcListing,
          walletType
        }).then(() => {
          setSelectedListings([]);
          if (apeListing && apePoolRef.current?.updateListing) {
            apePoolRef.current.updateListing(apeListing.listingHash);
          }
          if (apeCoinListing && apeCoinPoolRef.current?.updateListing) {
            apeCoinPoolRef.current.updateListing(apeCoinListing.listingHash);
          }
          if (bakcListing && bakcPoolRef.current?.updateListing) {
            bakcPoolRef.current.updateListing(bakcListing.listingHash);
          }
        });
      },
      [joinBAKCPairListings, setSelectedListings]
    );

    return (
      <>
        <BakcListingSelector
          compoundApy={compoundApy}
          selectedListings={selectedListings}
          onJoinListing={handleJoinListing}
        />
        <Responsive gap="1rem" justifyContent="space-between">
          <ListingTable
            density="small"
            ref={bakcPoolRef}
            label="BAKC"
            stakingPool={ERC721Symbol.BAKC}
            listingType={SEEK_SYMBOL_TO_LISTING_TYPE_MAP[ERC721Symbol.BAKC]}
            tokens={[ERC721Symbol.BAKC]}
            onHandleCreateListing={(source: WalletType) => {
              navigate(absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS_CREATE, {
                state: {
                  p2pToken: ERC721Symbol.BAKC,
                  p2pPool: ERC721Symbol.BAKC,
                  walletType: source
                }
              });
            }}
            onSelectedListingChange={handleSelectedListings}
            selectedJoinListing={selectedBakcListing}
          />
          <ListingTable
            density="small"
            ref={apePoolRef}
            label="BAYC/MAYC"
            stakingPool={ERC721Symbol.BAKC}
            listingType={ListingType.ApeListing}
            tokens={[ERC721Symbol.BAYC, ERC721Symbol.MAYC]}
            onHandleCreateListing={(source: WalletType) => {
              navigate(absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS_CREATE, {
                state: {
                  p2pToken: ERC721Symbol.BAYC,
                  p2pPool: ERC721Symbol.BAKC,
                  walletType: source
                }
              });
            }}
            onSelectedListingChange={handleSelectedListings}
            selectedJoinListing={selectedApeListing}
          />
          <ListingTable
            density="small"
            ref={apeCoinPoolRef}
            label="ApeCoin"
            stakingPool={ERC721Symbol.BAKC}
            listingType={ListingType.ApeCoinListing}
            tokens={[ERC20Symbol.APE]}
            onHandleCreateListing={(source: WalletType) => {
              navigate(absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS_CREATE, {
                state: {
                  p2pToken: 'APE',
                  p2pPool: ERC721Symbol.BAKC,
                  walletType: source
                }
              });
            }}
            onSelectedListingChange={handleSelectedListings}
            selectedJoinListing={selectedApeCoinListing}
          />
        </Responsive>
      </>
    );
  }
);
