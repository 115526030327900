import { H4, Stack, StackProps, Text } from '@parallel-mono/components';
import { FC, ReactElement, ReactNode, memo } from 'react';
import styled from 'styled-components';

const NoNftIcon = styled(Text)`
  width: 3.75rem;
  height: 3.75rem;
  font-size: 3.75rem;
  line-height: 3.75rem;
`;

type Props = {
  description?: ReactNode;
  title?: ReactElement;
};

export const NoNFTs: FC<Props & Omit<StackProps, 'children' | 'title'>> = memo(
  ({ description, title, ...props }) => {
    return (
      <Stack alignItems="center" {...props}>
        <NoNftIcon>🙊</NoNftIcon>
        <Stack alignItems="center" gap="0.5rem">
          {title || <H4>You don’t have this NFT</H4>}
          {description && <Text skin="secondary">{description}</Text>}
        </Stack>
      </Stack>
    );
  }
);
