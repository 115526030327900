import { FC } from 'react';
import { ItemType } from 'paraspace-seaport-js/lib/constants';
import { ConsiderationInputItem } from 'paraspace-seaport-js/lib/types';
import BigNumberJs from 'bignumber.js';

import { OfferFormValue } from '../MakeOfferForm';

import { ApproveWallet, Payload, TransmitData } from '.';

import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import useSeaport from '@/apps/paraspace/pages/hooks/useSeaport';
import { useContractsMap } from '@/apps/paraspace/hooks';
import { shiftedRightBy } from '@/apps/paraspace/utils/calculations';

interface MakeOfferProps {
  onFinish?: (transmitData: TransmitData) => void;
  onError?: (e: any) => void;
  offerFormValue: OfferFormValue;
  payload: Payload;
  transmitData: TransmitData;
}

const MakeOffer: FC<MakeOfferProps> = ({
  offerFormValue,
  payload,
  transmitData,
  onError,
  onFinish
}) => {
  const { token, startTime, endTime } = offerFormValue;
  const { collection, tokenId } = payload;
  const { fees, contractAddress } = collection || {};
  const { account } = useWeb3Context();
  const { seaportSDK } = useSeaport();
  const { standard } = collection || {};
  const contracts = useContractsMap();

  useAsyncEffect(async () => {
    const feeList = fees
      ? [
          {
            recipient: fees.paraSpaceFeeAddress,
            basisPoints: fees.paraSpaceFeePoint
          },

          { recipient: fees.creatorFeeAddress, basisPoints: fees.creatorFeePoint }
        ].filter(({ recipient, basisPoints }) => recipient && basisPoints)
      : [];
    const offer = [
      {
        amount: shiftedRightBy(BigNumberJs(token.offerAmount), token.decimal).toString(),
        token: token.address
      }
    ];
    let consideration;
    if (payload?.tokenId) {
      consideration = [
        {
          itemType: ItemType[(standard ?? 'ERC721') as keyof typeof ItemType],
          token: contractAddress!,
          identifier: `${tokenId}`,
          recipient: contracts.PoolProxy
        } as ConsiderationInputItem
      ];
    } else {
      // collection bid
      consideration = [
        {
          itemType: ItemType[(standard ?? 'ERC721') as keyof typeof ItemType],
          token: contractAddress!,
          identifiers: [],
          recipient: contracts.PoolProxy
        } as ConsiderationInputItem
      ];
    }
    try {
      if (seaportSDK) {
        const { executeAllActions } = await seaportSDK.createOrder(
          {
            startTime: `${startTime}`,
            endTime: `${endTime}`,
            offer,
            consideration,
            conduitKey: contracts.ConduitKey,
            fees: feeList,
            offerer: account
          },
          account
        );
        const order = await executeAllActions();
        onFinish?.({
          ...transmitData,
          order
        });
      }
    } catch (e: any) {
      onError?.(e);
    }
  }, []);
  return <ApproveWallet />;
};

export default MakeOffer;
