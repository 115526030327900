import { TokenInput } from '@parallel-mono/business-components';
import { H5, Stack, StackProps } from '@parallel-mono/components';
import { memo } from 'react';

import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { ConnectWallet as ConnectWalletButton } from '@/apps/paraspace/components';
import { ERC20Symbol } from '@/apps/paraspace/typings';

type ConnectWalletProps = Omit<StackProps, 'children'> & {
  onConnectWallet?: () => void;
  symbol: ERC20Symbol;
};

export const ConnectWallet = memo(({ onConnectWallet, symbol, ...others }: ConnectWalletProps) => {
  return (
    <Stack gap="2rem" {...others}>
      <Stack gap="1.5rem">
        <TokenInput
          label={<H5>Amount</H5>}
          token={symbol.toUpperCase()}
          decimals={MAXIMUM_BALANCE_DECIMALS}
          placeholder="0"
          error=""
          inputProps={{
            inputProps: {
              autoFocus: true
            }
          }}
        />
        <ConnectWalletButton btnTxt="Connect Wallet to Begin" onConnectWallet={onConnectWallet} />
      </Stack>
    </Stack>
  );
});
