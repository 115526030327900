import { CryptoIcon } from '@parallel-mono/business-components';
import { Inline, InlineProps, SmallText, useBreakpoints, Image } from '@parallel-mono/components';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { isNil } from 'lodash';

import { useGetERC721Image } from '../../hooks';

import { NftIconByContract } from './NftIconByContract';

import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useConvertSymbolWithNetwork } from '@/apps/paraspace/pages/hooks';

export type Asset = {
  symbol: ERC20Symbol | ERC721Symbol;
  tokenId?: number;
};

const sizeMap = {
  small: '1.5rem',
  medium: '2.5rem'
};

type Variant = 'list' | 'stack';
type Size = keyof typeof sizeMap;

type StackedIconsProps = {
  maxShow?: number;
  assets: Asset[];
  variant?: Variant;
  size?: Size;
} & Omit<InlineProps, 'children'>;

const AvatarStyle = css<{ index: number; variant: Variant }>`
  border-radius: ${({ variant }) => (variant === 'stack' ? '50%' : '0.25rem')};
  position: relative;
  z-index: ${({ index }) => `${index}`};
  ${({ theme }) => theme.breakpoints.down('desktop')`
    width: 1.5rem;
    height: 1.5rem;
  `};
`;

const StackedContainer = styled(Inline)<{ variant: Variant }>`
  > * + * {
    margin-left: ${({ variant }) => (variant === 'stack' ? '-0.5rem' : '0.25rem')};
  }
`;

const IconCollapseContainer = styled(Inline)<{ variant: Variant; size: string }>`
  min-width: ${({ variant }) => (variant === 'stack' ? '2.5rem' : 'none')};
  height: ${({ size }) => size ?? '100%'};
  padding: ${({ variant }) => (variant === 'stack' ? '0.2rem 0.2rem' : '0')};
  border-radius: ${({ variant }) => (variant === 'stack' ? '50%' : 'none')};
  background: ${({ variant, theme }) =>
    variant === 'stack' ? theme.skin.background.slot : 'none'};
  color: ${({ variant, theme }) => (variant === 'stack' ? theme.skin.text.slot : 'none')};
  white-space: nowrap;
  box-sizing: content-box;
  display: inline-block;
  text-align: center;
  line-height: 1;

  ${({ theme }) => theme.breakpoints.down('desktop')`
    min-width: 1.5rem;
    height: auto;
  `};
  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
    height: 0;
  }
`;
const StyledSmallText = styled(SmallText)`
  display: inline-block;
  vertical-align: middle;
`;

const Avatar = styled(Image)<{ index: number; variant: Variant }>`
  ${AvatarStyle}
`;

const NftIconByContractAvatar = styled(NftIconByContract)<{
  index: number;
  variant: Variant;
}>`
  ${AvatarStyle}
`;

const StyledCryptoAsset = styled(CryptoIcon)<{ index: number }>`
  position: relative;
  z-index: ${({ index }) => `${index}`};
`;

export const StackedIcons = ({
  maxShow = 3,
  assets,
  variant = 'stack',
  size = 'medium'
}: StackedIconsProps) => {
  const { desktop } = useBreakpoints();
  const $size = sizeMap[size];
  const { convertWTokenToNativeToken } = useConvertSymbolWithNetwork();

  const slicedAssets = useMemo(() => assets.slice(0, maxShow), [assets, maxShow]);
  const getERC721Image = useGetERC721Image();

  const icons = useMemo(
    () =>
      slicedAssets.map((asset, index) => {
        const { tokenId, symbol } = asset;
        if (!isNil(tokenId)) {
          if (symbol === ERC721Symbol.UNISWAP_LP || symbol === ERC721Symbol.SF_VLDR) {
            return (
              <NftIconByContractAvatar
                width={$size}
                height={$size}
                symbol={symbol}
                variant={variant}
                key={symbol + tokenId}
                tokenId={tokenId}
                index={slicedAssets.length - index}
              />
            );
          }
          const imgSrc = getERC721Image({ symbol: symbol as ERC721Symbol, tokenId });
          return (
            <Avatar
              src={imgSrc}
              key={symbol + tokenId}
              index={slicedAssets.length - index}
              variant={variant}
              width={$size}
              height={$size}
            />
          );
        }
        return (
          <StyledCryptoAsset
            key={convertWTokenToNativeToken(symbol as ERC20Symbol)}
            size={desktop ? $size : '1.5rem'}
            symbol={convertWTokenToNativeToken(symbol as ERC20Symbol)}
            index={slicedAssets.length - index}
          />
        );
      }),
    [slicedAssets, convertWTokenToNativeToken, desktop, $size, variant, getERC721Image]
  );

  return (
    <StackedContainer alignItems="center" variant={variant}>
      {icons}
      {assets.length > maxShow && (
        <IconCollapseContainer
          alignItems="center"
          justifyContent="center"
          variant={variant}
          size={$size}
        >
          <StyledSmallText skin="secondary">+{assets.length - maxShow}</StyledSmallText>
        </IconCollapseContainer>
      )}
    </StackedContainer>
  );
};
