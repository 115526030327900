import { H3, Icon, Inline, Modal } from '@parallel-mono/components';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { MakeOfferModalPayload } from '../../../../types';
import { AddWETHForm } from '../../../../components';

import MakeOfferMainForm from './MakeOfferContainer';

const InlineWithCursor = styled(Inline)`
  cursor: pointer;
`;

const StyledModal = styled(Modal)`
  .customModal {
    overflow: visible;
    ${({ theme }) => theme.breakpoints.only('mobile')`
      width: 100%;
    `}
  }
`;

export const MakeOfferModal = ({
  isOpen,
  closeModal,
  data
}: {
  isOpen: boolean;
  closeModal: () => void;
  data: MakeOfferModalPayload;
}) => {
  const [showAddWETH, setShowAddWETH] = useState(false);

  const hideAddWETHForm = useCallback(() => {
    setShowAddWETH(false);
  }, []);

  const showAddWETHForm = useCallback(() => {
    setShowAddWETH(true);
  }, []);

  return (
    <StyledModal
      classNames={{
        modal: 'customModal'
      }}
      isOpen={isOpen}
      onClose={closeModal}
      keepMounted={false}
      closeOnBackdropClick={false}
      title={
        showAddWETH ? (
          <InlineWithCursor gap="1rem" alignItems="center" onClick={hideAddWETHForm}>
            <Icon name="arrowLeft" />
            <H3>Add wETH</H3>
          </InlineWithCursor>
        ) : (
          'Make Offer'
        )
      }
    >
      {showAddWETH ? (
        <AddWETHForm hideAddWETHForm={hideAddWETHForm} />
      ) : (
        <MakeOfferMainForm data={data} closeModal={closeModal} onAddWETHClick={showAddWETHForm} />
      )}
    </StyledModal>
  );
};
