import {
  Button,
  Collapse,
  H3,
  Icon,
  Image,
  Inline,
  InlineProps,
  SmallText,
  Spinner,
  Stack,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { isEmpty, upperFirst } from 'lodash';
import { ChainIcon, CryptoIcon } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';
import { ChildToParentMessageStatus } from '@arbitrum/sdk';
import { Environment } from 'parax-sdk';
import { ethereumChainConfig, sepoliaChainConfig } from '@parallel-utils/contracts-registry';

import { BridgeNetworks, SupportedChainId } from '../../../configs';
import deposit from '../images/deposit.svg';
import withdraw from '../images/withdraw.svg';
import { ActivityDetail, Event } from '../../../hooks';
import { useBridgeTokenImpl } from '../../../contexts';

import { Link } from '@/apps/parax/components';
import { MAXIMUM_BALANCE_DECIMALS, sleep } from '@/apps/parax/utils';
import { useEOAProvider, useParaXToast } from '@/apps/parax/contexts';
import { env } from '@/env';

const NetworkWrapper = styled(Inline)`
  position: relative;
  width: 3rem;
  height: 3rem;
`;

const TypeIcon = styled(Image)`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const IconWrapper = styled(Inline)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.skin.background.sub1};
  width: 20px;
  height: 20px;
`;

const ExpandIcon = styled(Icon)<{ expand: boolean }>`
  color: ${({ theme }) => theme.skin.grey[700]};
  transform: ${({ expand }) => `rotate(${expand ? -180 : 0}deg)`};
  transition: all 0.3s;
`;

const StyledTag = styled(Inline)<{ type: Event['status'] }>`
  width: fit-content;
  padding: 2px 0.5rem;
  border-radius: 100px;
  background-color: ${({ type, theme }) =>
    ({
      confirmed: '#E2FBE8',
      pending: theme.skin.grey[300],
      waiting: theme.skin.grey[300],
      failed: '#F9E4E4'
    }[type])};
  color: ${({ type, theme }) =>
    ({
      confirmed: '#56C664',
      pending: theme.skin.grey[500],
      waiting: theme.skin.grey[500],
      failed: '#FF7A7A'
    }[type])};
`;
const StyledLink = styled(Link)`
  width: fit-content;
  line-height: 1.25rem;
  text-decoration: none;
`;

const RowWrapper = styled(Inline)`
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.skin.grey[200]}`};
  padding-bottom: 1rem;
`;

const PendingIcon = styled(Spinner)`
  width: 18px;
  height: 18px;
`;

const WaitingIcon = styled(Inline)`
  width: 18px;
  height: 18px;
  border: ${({ theme }) => `${theme.border.width.large} solid ${theme.skin.grey[500]}`};
  border-radius: 50%;
`;

const chainNameMap: Record<string, string> = {
  parallel: 'parallel-l3'
};

const getChainName = (symbol: string) => {
  return chainNameMap[symbol] ?? symbol;
};

const withdrawalL1ChainConfig = {
  [Environment.DEVELOPMENT]: sepoliaChainConfig,
  [Environment.STAGING]: sepoliaChainConfig,
  [Environment.PRODUCTION]: ethereumChainConfig
}[env];

export const ActivityRow: FC<
  InlineProps & {
    detail: ActivityDetail;
    onUpdateEventStatus?: (id: string, status: ChildToParentMessageStatus) => void;
  }
> = memo(({ detail, onUpdateEventStatus, ...others }) => {
  const [expand, setExpand] = useState(false);
  const { claimWithdrawal } = useBridgeTokenImpl();
  const toast = useParaXToast();
  const { chainId: connectedChainId, switchNetwork } = useEOAProvider();
  const [isClaiming, setIsClaiming] = useState(false);

  const claimWithdrawalRef = useRef(claimWithdrawal);
  claimWithdrawalRef.current = claimWithdrawal;

  const handleClaim = useCallback(async () => {
    try {
      setIsClaiming(true);
      if (connectedChainId !== (withdrawalL1ChainConfig.chainId as number)) {
        await switchNetwork(withdrawalL1ChainConfig.chainId as number);
      }

      await sleep(1000);
      const event = detail.metadata.event!;
      await toast
        .promise(
          claimWithdrawalRef.current({
            ...event,
            data: '0x'
          })
        )
        .then(() => {
          onUpdateEventStatus?.(detail.id, ChildToParentMessageStatus.EXECUTED);
        });
    } finally {
      setIsClaiming(false);
    }
  }, [
    connectedChainId,
    detail.id,
    detail.metadata.event,
    onUpdateEventStatus,
    switchNetwork,
    toast
  ]);

  const [fromChain, toChain] = useMemo(
    () => [
      BridgeNetworks[detail.chains.from as SupportedChainId],
      BridgeNetworks[detail.chains.to as SupportedChainId]
    ],
    [detail.chains.from, detail.chains.to]
  );
  const { mobile } = useBreakpoints();
  const theme = useTheme();

  return (
    <RowWrapper justifyContent="space-between" {...others}>
      {!mobile && (
        <NetworkWrapper>
          <CryptoIcon symbol={detail.metadata.symbol} height="3rem" width="3rem" />
          <TypeIcon width="1.25rem" height="1.25rem" src={{ deposit, withdraw }[detail.type]} />
        </NetworkWrapper>
      )}
      <Stack gap="0" width="100%">
        <Inline gap="0.5rem" justifyContent="space-between">
          <Stack gap="0">
            <Text fontWeight="bold">{upperFirst(detail.type)}</Text>
            <Inline alignItems="center" gap="4px">
              <Inline alignItems="center" gap="2px">
                <ChainIcon width="1rem" height="1rem" name={getChainName(fromChain.symbol)} />
                <SmallText skin="secondary">{fromChain.name}</SmallText>
              </Inline>

              <Icon size="1rem" name="arrowRight" color="#D4D4D8" strokeWidth="2" />
              <Inline alignItems="center" gap="2px">
                <ChainIcon width="1rem" height="1rem" name={getChainName(toChain.symbol)} />
                <SmallText skin="secondary">{toChain.name}</SmallText>
              </Inline>
            </Inline>
          </Stack>
          <Stack alignItems="flex-end" gap="0">
            <H3>
              {formatNumber(detail.metadata.amount, {
                averageMinLength: 6,
                decimal: MAXIMUM_BALANCE_DECIMALS
              })}{' '}
              {detail.metadata.symbol}
            </H3>
            <IconWrapper justifyContent="center" alignItems="center">
              <ExpandIcon
                expand={expand}
                onClick={() => setExpand(prev => !prev)}
                width="1.5rem"
                height="1.5rem"
                strokeWidth={3}
                name="chevronDown"
              />
            </IconWrapper>
          </Stack>
        </Inline>
        <Collapse open={expand}>
          <Stack gap="0.5rem">
            {[
              { event: detail.sendEvent, type: 'Sent' },
              {
                event: {
                  deposit: detail.receivedEvent,
                  withdraw: {
                    ...detail.receivedEvent
                  }
                }[detail.type],
                type: 'Receive'
              }
            ]
              .filter(it => !!it.event)
              .map(({ event, type }, index) => {
                const { explorerLink } = [fromChain, toChain][index];
                const showLink =
                  ['confirmed', 'failed'].includes(event?.status ?? '') && explorerLink;
                const statusTag = (
                  <Inline justifyContent="space-between">
                    <StyledTag type={event?.status ?? 'pending'}>
                      <Inline gap="6px" alignItems="center">
                        {
                          {
                            confirmed: <Icon size="18px" name="checkContained" />,
                            waiting: <WaitingIcon />,
                            pending: <PendingIcon color={theme.skin.grey[500]} />,
                            failed: <Icon size="18px" name="closeContained" />
                          }[event?.status ?? 'pending']
                        }
                        {type === 'Receive' && event?.status === 'pending' ? (
                          <SmallText fontWeight="bold">Challenge period</SmallText>
                        ) : (
                          <SmallText fontWeight="bold">
                            {type === 'Sent' ? fromChain.name : toChain.name}{' '}
                            {upperFirst(event!.status === 'waiting' ? 'claimable' : event!.status)}
                          </SmallText>
                        )}

                        {event!.transactionHash && showLink && (
                          <Icon strokeWidth="2" size="1rem" name="externalLink" />
                        )}
                      </Inline>
                    </StyledTag>
                    {event?.status === 'waiting' && (
                      <Button
                        disabled={isClaiming}
                        loading={isClaiming}
                        size="small"
                        onClick={handleClaim}
                      >
                        Claim
                      </Button>
                    )}
                  </Inline>
                );
                return showLink && event!.transactionHash ? (
                  <StyledLink
                    key={event!.transactionHash}
                    href={`${explorerLink[0]}/tx/${event!.transactionHash}`}
                    target="_blank"
                  >
                    {statusTag}
                  </StyledLink>
                ) : (
                  statusTag
                );
              })}
            {isEmpty(detail.receivedEvent) && detail.type === 'deposit' && (
              <StyledTag type="waiting">
                <Inline gap="6px" alignItems="center">
                  <PendingIcon color={theme.skin.grey[500]} />
                  <SmallText fontWeight="bold">Pending</SmallText>
                </Inline>
              </StyledTag>
            )}
          </Stack>
        </Collapse>
      </Stack>
    </RowWrapper>
  );
});
