import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { H3, H5, Inline, Responsive, Stack, useBreakpoints, Text } from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';

import { CreateApeCoinSelector } from './CreateApeCoinListing';
import { CreateApeSelector } from './CreateApeListing';

import { useApePoolApy } from '@/apps/paraspace/pages/hooks/ApeStaking/useApePoolApy';
import {
  ApeStakingMainAssetSymbol,
  ApeStakingMainTokenSymbol,
  ERC20Symbol,
  ERC721Symbol
} from '@/apps/paraspace/typings';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { zero } from '@/apps/paraspace/consts/values';
import { toPercentageCompoundAPY } from '@/apps/paraspace/utils/format';
import { Select, SelectBaseOption } from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type SelectedValue = CreateApeCoinSelector | CreateApeSelector;

const POOL_SYMBOL_LIST = [ERC721Symbol.BAYC, ERC721Symbol.MAYC, ERC721Symbol.BAKC, ERC20Symbol.APE];

const symbolSelectOptions = POOL_SYMBOL_LIST.map(symbol => ({
  icon: <CryptoIcon symbol={symbol} size="small" />,
  label: symbol,
  value: symbol
}));

const poolSelectOptions = POOL_SYMBOL_LIST.map(symbol => ({
  icon: <CryptoIcon symbol={symbol} size="small" />,
  label: `${symbol} Pool`,
  value: symbol
}));

export const ListingSelector: FC<{
  defaultValue: SelectedValue;
  onHandleChange: (val: SelectedValue) => void;
}> = memo(props => {
  const { defaultValue, onHandleChange } = props;

  const { mobile } = useBreakpoints();
  const [apePoolApr] = useApePoolApy(ERC20Symbol.APE);

  const [walletType] = useState(defaultValue.walletType);
  const [selectedToken, setSelectedToken] = useState(defaultValue.p2pToken);
  const [selectedPool, setSelectedPool] = useState(defaultValue.p2pPool);

  const [originalPoolList, setOriginalPoolList] = useState(poolSelectOptions);

  // restrict select items
  const getRenderPoolListBySelectedToken = useCallback(
    (token: string, list: SelectBaseOption[]) => {
      if (token === ERC721Symbol.BAKC) {
        return list.filter(i => i.value === token);
      }
      if (token === ERC20Symbol.APE) {
        return list.filter(i => i.value !== token);
      }

      return list.filter(i => i.value === token || i.value === ERC721Symbol.BAKC);
    },
    []
  );

  const { nftPoolsCompoundApy } = useAutoCompoundApeInfo();

  // initialization, update the apy field of the pool list item asynchronously when mounted
  useEffect(() => {
    const poolListWithAPY = poolSelectOptions.map(option => ({
      ...option,
      apy: toPercentageCompoundAPY(
        nftPoolsCompoundApy?.[option.value as ApeStakingMainTokenSymbol] ?? zero
      )
    }));
    setOriginalPoolList(poolListWithAPY);
  }, [apePoolApr, nftPoolsCompoundApy, onHandleChange]);

  const renderItem = useCallback(option => {
    return (
      <Inline gap="0.5rem" alignItems="center" inset="1rem 1.5rem">
        {option.icon}
        <Stack gap="0">
          <Text>{option.value} Pool</Text>
          {option.apy && <Text skin="success">{option.apy}</Text>}
        </Stack>
      </Inline>
    );
  }, []);

  const handleSelectPool = useCallback((option: SelectBaseOption | null) => {
    setSelectedPool(option?.value);
  }, []);

  const renderPoolSelectOptions = useMemo(
    () => getRenderPoolListBySelectedToken(selectedToken, originalPoolList),
    [getRenderPoolListBySelectedToken, originalPoolList, selectedToken]
  );

  const handleSelectToken = useCallback((value: ApeStakingMainAssetSymbol) => {
    setSelectedToken(value);
  }, []);

  useEffect(() => {
    setSelectedPool(oldValue => {
      return renderPoolSelectOptions.map(each => each.value).includes(oldValue)
        ? oldValue
        : renderPoolSelectOptions[0].value;
    });
  }, [renderPoolSelectOptions]);

  useEffect(() => {
    onHandleChange({
      walletType,
      p2pToken: selectedToken,
      p2pPool: selectedPool,
      poolAPY: nftPoolsCompoundApy?.[selectedPool]
    });
  }, [nftPoolsCompoundApy, onHandleChange, selectedPool, selectedToken, walletType]);

  return (
    <>
      <H3>Create Listing</H3>
      <Responsive breakPoint="tablet" alignItems={mobile ? 'flex-start' : 'center'} gap="1rem">
        <H5>I want to offer</H5>
        <Inline alignItems="center" justifyContent="center" gap="1rem">
          <Select
            options={symbolSelectOptions}
            value={selectedToken}
            menuAlign="left"
            onChange={(option: Maybe<SelectBaseOption>) => {
              handleSelectToken(option?.value);
            }}
          />
          <H5>for</H5>
          <Select
            value={selectedPool}
            renderItem={renderItem}
            options={renderPoolSelectOptions}
            menuAlign="left"
            onChange={(option: Maybe<SelectBaseOption>) => {
              handleSelectPool(option);
            }}
          />
        </Inline>
      </Responsive>
    </>
  );
});
