import { flatten, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useBreakpoints } from '@parallel-mono/components';
import BigNumberJs from 'bignumber.js';

import { useMoneyMarketActions } from '../../contexts';

import MyBorrowedPositionsTable from './MyBorrowedPositionsTable';
import BorrowedPositionsMobile from './Mobile';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useAppConfig } from '@/apps/paraspace/hooks';

export type BorrowPositionRow = {
  symbol: ERC20Symbol;
  displayName: string;
  borrowApyRate: BigNumberJs;
  borrowRewardRate: BigNumberJs;
  borrowedAmount: BigNumberJs;
  borrowedAmountInUsd: BigNumberJs;
  utilization: BigNumberJs;
};

export type Handlers = {
  handleBorrow: (data: BorrowPositionRow) => void;
};

const MyBorrowedPositions = () => {
  const { erc20InfoMap } = useMMProvider();
  const { mobile } = useBreakpoints();

  const { borrowERC20 } = useMoneyMarketActions();

  const handleBorrow = useCallback(
    (data: BorrowPositionRow) => {
      borrowERC20(data.symbol);
    },
    [borrowERC20]
  );

  const handlers = useMemo(
    () => ({
      handleBorrow
    }),
    [handleBorrow]
  );

  const { erc20Config } = useAppConfig();

  const positions = useMemo(() => {
    const nestedList = Object.keys(erc20Config).map(key => {
      if (erc20Config[key as ERC20Symbol].isWrappedNativeToken) {
        return [];
      }

      const token = erc20InfoMap[key];
      if (token && token.borrowedAmount?.gt(0)) {
        const utilization = token.totalDebt.div(token.totalDebt.plus(token.availableLiquidity));
        return [
          {
            ...token,
            id: token.displayName,
            assetName: token.displayName,
            amount: token.borrowedAmount,
            value: token.borrowedAmountInUsd,
            apy: token.borrowApyRate,
            rewardApy: token.borrowRewardRate,
            utilization
          }
        ];
      }
      return [];
    });
    return flatten(nestedList);
  }, [erc20Config, erc20InfoMap]) as BorrowPositionRow[];

  return (
    <>
      {!isEmpty(positions) &&
        (mobile ? (
          <BorrowedPositionsMobile positions={positions} handlers={handlers} />
        ) : (
          <MyBorrowedPositionsTable positions={positions} handlers={handlers} />
        ))}
    </>
  );
};

export default MyBorrowedPositions;
