import { useCallback, useState } from 'react';
import { Stack } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';

import { FormState } from '../types';
import { StakingApeProps } from '../../hook/useLegacyOfficialApeStaking';

import { StackContainer } from './StackContainer';
import StakingStep from './StakingStep';
import StakingSuccess from './StakingSuccess';

import { NFTThumbnail, ErrorState } from '@/apps/paraspace/components';
import { ApeStakingTokenSymbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { useStakeInfoList } from '@/apps/paraspace/pages/OfficialPairing/StakeInfoListProvider/StakeInfoListProvider';

type ApeStakeFormProps = {
  symbol: ApeStakingTokenSymbol;
  id: number;
  apeSource: WalletType;
  apeCoinSource: WalletType;
  onClose: () => void;
  stakedValue: BigNumber;
  stakedLimitValue: BigNumber;
  apy: BigNumber;
};

export const ApeStakeForm = ({
  symbol,
  id,
  apeSource,
  apeCoinSource,
  onClose,
  stakedValue,
  stakedLimitValue,
  apy
}: ApeStakeFormProps) => {
  const { refresh, stakingInfoList, bakcInfoList } = useStakeInfoList();
  const targetNft =
    symbol === ERC721Symbol.BAKC
      ? bakcInfoList.find(item => item.tokenId === id)
      : stakingInfoList.find(item => item.symbol === symbol && item.tokenId === id);

  const [formState, setFormState] = useState<FormState>(0);

  const [formData, setFormData] = useState<StakingApeProps>({
    type: symbol,
    tokenId: id,
    apeSource,
    apeCoinSource,
    cashAmount: 0
  });

  // collect data
  const handleSubmit = useCallback(
    ({
      data,
      nextState
    }: {
      data: { borrowAmount: number; cashAmount: number };
      nextState: FormState;
    }) => {
      setFormData(oldData => ({ ...oldData, ...data }));
      setFormState(nextState);
    },
    []
  );

  const handleError = useCallback(() => {
    setFormState(FormState.ERROR);
  }, []);

  const handleSuccess = useCallback(() => {
    setFormState(FormState.SUCCESS);
    refresh();
  }, [refresh]);

  if (formState === FormState.MAINFORM) {
    return (
      <Stack gap="1.5rem">
        <Stack alignItems="center" gap=".5rem">
          <NFTThumbnail size="xLarge" symbol={symbol} tokenId={id} showDescription />
        </Stack>
        <StackContainer
          symbol={symbol}
          stakedValue={stakedValue?.toNumber()}
          stakedLimitValue={stakedLimitValue.toNumber()}
          apeCoinSource={apeCoinSource}
          onSubmit={handleSubmit}
          apy={apy.toNumber()}
        />
      </Stack>
    );
  }

  if (formState === FormState.STEPPER) {
    return <StakingStep onError={handleError} onSuccess={handleSuccess} formData={formData} />;
  }

  if (formState === FormState.ERROR) {
    return <ErrorState closeModal={onClose} />;
  }

  if (formState === FormState.SUCCESS) {
    return (
      <StakingSuccess formData={formData} onClose={onClose} stakedValue={targetNft?.stakedAmount} />
    );
  }

  return null;
};
