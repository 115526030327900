import { ReactElement, useMemo } from 'react';
import {
  Image,
  DataGrid,
  Inline,
  Text,
  Pagination,
  Stack,
  CellRenderContext,
  DataGridColumn,
  DataGridProps
} from '@parallel-mono/components';
import styled from 'styled-components';
import classNames from 'classnames';

import first from '../assets/first.svg';
import second from '../assets/second.svg';
import third from '../assets/thrid.svg';

import { Maybe } from '@/apps/paraspace/typings/basic';

type RankGridData = {
  position: string;
  address: string;
  tier?: string;
};

const dataGridClassNames = {
  body: 'rankGridBody',
  headerCell: 'data-grid-header-cell'
};
type GridProps<T> = {
  userData: Maybe<T & RankGridData>;
  data: (T & RankGridData)[];
  columns: DataGridColumn<T & RankGridData>[];
  loading: boolean;
  totalPage: number;
  currentPage: number;
  handlePageChange: (page: number) => void;
};

const StyledDataGrid: <R = object>(
  props: DataGridProps<R> & {
    borderRow: Maybe<number>;
  }
) => ReactElement = styled(DataGrid)<{
  borderRow: Maybe<number>;
}>`
  .${dataGridClassNames.body} {
    ${({ borderRow, theme }) =>
      borderRow &&
      `
      & > tr:nth-child(${borderRow}) {
        & > td {
          border-top: ${theme.border.width.large} solid ${theme.skin.grey[900]};
          border-bottom: ${theme.border.width.large} solid ${theme.skin.grey[900]};
          & * {
            font-weight: bold;
          }
        }; 
        td:first-child {
          border-top-left-radius: 100px;
          border-bottom-left-radius: 100px;
          border-left: ${theme.border.width.large} solid ${theme.skin.grey[900]};
        };
        & > td + td:last-child {
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
          border-right: ${theme.border.width.large} solid ${theme.skin.grey[900]};
        };
      };
    `}
  }
  .data-grid-header-cell {
    border: none;
  }
` as typeof DataGrid;

const PositionWrapper = styled(Inline)<{ position: string; isCurrent: boolean }>`
  width: ${({ isCurrent }) => (isCurrent ? 'auto' : '2rem')};
  min-width: 2rem;
  height: 2rem;
  padding: 0 ${({ isCurrent, theme }) => (isCurrent ? theme.spacing(2) : '0')};
  border-radius: 100px;
  color: ${({ theme, position }) => {
    switch (position) {
      case '1':
      case '2':
      case '3':
        return theme.skin.background.main;
      default:
        return theme.skin.text.main;
    }
  }};
  background: ${({ theme, position }) => {
    switch (position) {
      case '1':
        return theme.skin.grey[900];
      case '2':
        return theme.skin.grey[700];
      case '3':
        return theme.skin.grey[500];
      default:
        return theme.skin.grey[200];
    }
  }};
`;

const generatePositionIcon = (position: string) => {
  switch (position) {
    case '1':
      return <Image src={first} />;
    case '2':
      return <Image src={second} />;
    case '3':
      return <Image src={third} />;
    default:
      return null;
  }
};

export const RankGrid = <T,>({
  columns,
  data,
  userData,
  loading,
  totalPage,
  currentPage,
  handlePageChange
}: GridProps<T>) => {
  const enhanceColumns = useMemo(() => {
    const [firstColumn, ...others] = columns;
    return [
      {
        ...firstColumn,
        render: (renderContext: CellRenderContext<T & RankGridData>) => {
          const {
            data: { position, address }
          } = renderContext;
          const originColumns = firstColumn?.render?.(renderContext);

          const isCurrent = address === userData?.address;

          const content = isCurrent
            ? [userData?.position, userData?.tier].filter(Boolean).join(' | ')
            : position;
          return (
            <Inline
              alignItems="center"
              gap="0.5rem"
              className={isCurrent ? classNames('current', 'current-left') : ''}
            >
              <PositionWrapper
                position={position}
                isCurrent={isCurrent}
                alignItems="center"
                justifyContent="center"
              >
                <Text>{content}</Text>
              </PositionWrapper>
              {generatePositionIcon(position)}
              <div>{originColumns}</div>
            </Inline>
          );
        }
      },
      ...others
    ];
  }, [columns, userData]);

  const gridDataWithUserData = useMemo(() => {
    if (!userData) return data;
    return data.map(row => row.address).includes(userData.address) ? data : [userData, ...data];
  }, [data, userData]);

  const borderRow = useMemo(() => {
    if (loading) return null;
    const index = gridDataWithUserData.findIndex(v => v.address === userData?.address);
    return index < 0 ? null : index + 1;
  }, [gridDataWithUserData, loading, userData?.address]);

  return (
    <Stack gap="1rem">
      <StyledDataGrid<T & RankGridData>
        loading={loading}
        columns={enhanceColumns}
        data={gridDataWithUserData}
        borderRow={borderRow}
        classNames={{
          body: dataGridClassNames.body,
          headerCell: dataGridClassNames.headerCell
        }}
      />
      <Pagination
        total={totalPage}
        page={currentPage}
        onChange={handlePageChange}
        startBoundaryCount={3}
      />
    </Stack>
  );
};
