import { FC, memo } from 'react';
import { StackProps } from '@parallel-mono/components';

import { Tab } from './components/Tab';
import { NFTCalculator } from './components/NFTCalculator';

import { ApeCoinCalculator } from '@/apps/paraspace/pages/ApePairing/pages/Calculator/components/ApeCoinCalculator';
import { ERC721Symbol } from '@/apps/paraspace/typings';

type Props = Omit<StackProps, 'children'>;

const TABS_DATA = [
  { id: 'ape', text: 'Ape Coin Pool', component: <ApeCoinCalculator /> },
  {
    id: 'bayc',
    text: 'BAYC Pool',
    component: <NFTCalculator symbol={ERC721Symbol.BAYC} key="BAYC-Pool-Calculator" />
  },
  {
    id: 'mayc',
    text: 'MAYC Pool',
    component: <NFTCalculator symbol={ERC721Symbol.MAYC} key="MAYC-Pool-Calculator" />
  }
];
export const ApeStakingCalculator: FC<Props> = memo(() => {
  return <Tab tabsData={TABS_DATA} />;
});
