import { memo, useCallback, useMemo, useState } from 'react';
import { BigNumber } from 'bignumber.js';

import {
  ApeCoinListing,
  ApeCoinSourceToJoinApeListing,
  ApeListing,
  ListingType
} from '../../../../types';
import { MatchListingsSubmitter, SignListingSubmitter } from '../../../../submitters';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/ApePairing/consts';
import { ERC20Symbol, WalletType } from '@/apps/paraspace/typings';
import { DepositERC20Submitter, Stepper, StepperProps } from '@/apps/paraspace/components';
import { FLOAT_SCALING_FACTOR } from '@/apps/paraspace/utils/format';
import { CreateListingReturnType } from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { one } from '@/apps/paraspace/consts/values';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useContractsMap, useERC20 } from '@/apps/paraspace/hooks';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';
import { useAutoCompoundApe } from '@/apps/paraspace/hooks/useAutoCompoundApe';

type JoinListingWithApeCoinFormSubmitterProps = Omit<StepperProps, 'steps' | 'step' | 'onError'> & {
  formData: {
    apeListing: ApeListing;
    apeCoinSource?: ApeCoinSourceToJoinApeListing;
  };
  walletType: WalletType;
  onError: (errorConfig?: Maybe<ErrorConfig>) => void;
  onFinish: (createdListing: ApeCoinListing) => void;
};

export const JoinListingWithApeCoinFormsSubmitter = memo(
  ({
    formData: { apeListing, apeCoinSource },
    walletType,
    onError,
    onFinish,
    ...others
  }: JoinListingWithApeCoinFormSubmitterProps) => {
    const [step, setStep] = useState<number>(0);
    const [createdListing, setCreatedListing] = useState<Maybe<ApeCoinListing>>(null);

    const contracts = useContractsMap();
    const { createApproval: genApprovalForAPE } = useERC20(contracts.APE);
    const { createApproval: genApprovalForCAPE } = useERC20(contracts.cAPE);
    const { genStakingAutoCompoundApe } = useAutoCompoundApe();

    const handleNextStep = useCallback(() => {
      setStep(curr => curr + 1);
    }, [setStep]);

    const handleListingCreated = useCallback(
      (createdListings: CreateListingReturnType) => {
        const { offerer, share, startTime, endTime, v, r, s, token } = createdListings ?? {};
        setCreatedListing({
          listingHash: '',
          offerer,
          share: new BigNumber(share).dividedBy(FLOAT_SCALING_FACTOR),
          startDate: new Date(startTime * 1000),
          expirationDate: new Date(endTime * 1000),
          v,
          r,
          s,
          stakingType: apeListing.stakingType,
          listingType: ListingType.ApeCoinListing,
          token
        } as ApeCoinListing);
        handleNextStep();
      },
      [handleNextStep, setCreatedListing, apeListing.stakingType]
    );

    const apeCoinAmount = useMemo(
      () => new BigNumber(STAKE_LIMIT[apeListing.stakingPool]),
      [apeListing.stakingPool]
    );

    const batchTxs = useMemo(
      () => [
        {
          tx: genApprovalForAPE({ spender: contracts.cAPE, amount: apeCoinAmount }),
          skip: apeCoinSource === ApeCoinSourceToJoinApeListing.CAPE_BALANCE
        },
        {
          tx: genStakingAutoCompoundApe({ amount: apeCoinAmount.toString(10) }),
          skip: apeCoinSource === ApeCoinSourceToJoinApeListing.CAPE_BALANCE
        },
        {
          tx: genApprovalForCAPE({
            spender: contracts.P2PPairStaking,
            amount: apeCoinAmount
          })
        }
      ],
      [
        apeCoinSource,
        contracts,
        apeCoinAmount,
        genApprovalForCAPE,
        genApprovalForAPE,
        genStakingAutoCompoundApe
      ]
    );

    const stakeApeCoinSteps = useMemo(
      () => [
        {
          description: 'Deposit APE',
          content: (
            <DepositERC20Submitter
              formData={{
                address: contracts.APE,
                symbol: ERC20Symbol.APE,
                value: new BigNumber(apeCoinAmount)
              }}
              onFinish={handleNextStep}
              onError={onError}
            />
          ),
          skip: walletType !== 'EOA' || apeCoinSource === ApeCoinSourceToJoinApeListing.CAPE_BALANCE
        },
        {
          description: 'Deposit cAPE',
          content: (
            <DepositERC20Submitter
              formData={{
                address: contracts.cAPE,
                symbol: ERC20Symbol.CAPE,
                value: new BigNumber(apeCoinAmount)
              }}
              onFinish={handleNextStep}
              onError={onError}
            />
          ),
          skip: walletType !== 'EOA' || apeCoinSource === ApeCoinSourceToJoinApeListing.APE_BALANCE
        },
        {
          description: 'Approve and stake APE',
          content: (
            <BatchTransactionsSubmitter
              batchTxs={batchTxs}
              onFinish={handleNextStep}
              inProgressMessage="Approving and staking APE"
              onError={onError}
            />
          ),
          skip: batchTxs.every(({ skip }) => skip)
        }
      ],
      [
        contracts.APE,
        contracts.cAPE,
        apeCoinAmount,
        handleNextStep,
        onError,
        walletType,
        apeCoinSource,
        batchTxs
      ]
    );

    const handleFinish = useCallback(() => {
      onFinish(createdListing!);
    }, [onFinish, createdListing]);

    const steps = useMemo(() => {
      return [
        ...stakeApeCoinSteps,
        {
          description: 'Sign to create listing',
          content: (
            <SignListingSubmitter
              formData={{
                stakingType: apeListing.stakingType,
                offerSymbol: ERC20Symbol.APE,
                tokenId: 0,
                wantedPercentage: one.minus(apeListing.share),
                duration: 1 / 12
              }}
              onError={onError}
              onFinish={handleListingCreated}
            />
          )
        },
        {
          description: 'Matching Order',
          content: (
            <MatchListingsSubmitter
              formData={{
                apeCoinListing: createdListing!,
                apeListing,
                bakcListing: null
              }}
              onError={onError}
              onFinish={handleFinish}
            />
          )
        }
      ];
    }, [
      stakeApeCoinSteps,
      apeListing,
      onError,
      handleListingCreated,
      createdListing,
      handleFinish
    ]);

    return <Stepper step={step} steps={steps} {...others} />;
  }
);
