import { Card, Stack, Button, H3, Text, Image } from '@parallel-mono/components';
import { FC, isValidElement, memo, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { CryptoIcon } from '@parallel-mono/business-components';

const animationDuration = '0.3s';

const ImageWrapper = styled(Stack)`
  position: relative;
  width: 100%;
`;

const BgWrapper = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    transition: transform ${animationDuration} linear;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  transition: transform ${animationDuration} linear;
`;

const StyledStack = styled(Stack).attrs({
  alignItems: 'center',
  gap: '0.25rem'
})`
  text-align: center;
`;

const FullHeightStack = styled(Stack)`
  height: 100%;
`;

const ContentWrapper = styled(Stack).attrs({
  inset: '1.5rem'
})`
  flex: 1;
  margin-top: 3rem;
  transition: margin-top ${animationDuration} linear;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    padding: 2rem 0.5rem 1rem 0.5rem;
  `};
  ${({ theme }) => theme.breakpoints.only('tablet')`
    padding: 1rem;
  `};
`;

const StyledCard = styled(Card)`
  border-radius: 1rem;
  box-shadow: none;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    width: 100%;
  `};
  ${({ theme }) => theme.breakpoints.only('tablet')`
    width: 22rem;
  `};

  transition: box-shadow ${animationDuration} linear;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.secondary};
    transition: box-shadow ${animationDuration} linear;
    ${BgWrapper} {
      img {
        transition: transform ${animationDuration} linear;
        transform: scale(1.2);
      }
    }
    ${IconWrapper} {
      transition: transform ${animationDuration} liner;
      transform: translate(-50%, 50%) scale(1.2);
    }

    ${ContentWrapper} {
      margin-top: 4rem;
      transition: margin-top ${animationDuration} linear;
    }
  }
`;

type Props = {
  icon: string | ReactElement;
  bg: string | ReactElement;
  title: string;
  description: ReactNode;
  button: {
    text: string;
    action: () => void;
  };
};

export const PoolInfoCard: FC<Props> = memo(
  ({ title, description, button, bg, icon, children }) => {
    return (
      <StyledCard border inset="0">
        <FullHeightStack gap="0">
          <ImageWrapper gap="0">
            <BgWrapper>{isValidElement(bg) ? bg : <Image src={bg as string} />}</BgWrapper>
            <IconWrapper>
              {isValidElement(icon) ? icon : <CryptoIcon symbol={icon as string} size="100px" />}
            </IconWrapper>
          </ImageWrapper>

          <ContentWrapper>
            <StyledStack>
              <H3>{title}</H3>
              <Text skin="secondary" fontWeight="regular" as="div">
                {description}
              </Text>
            </StyledStack>
            {children}
            <Button block size="large" onClick={button.action}>
              {button.text}
            </Button>
          </ContentWrapper>
        </FullHeightStack>
      </StyledCard>
    );
  }
);
