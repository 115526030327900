import { memo, ReactNode, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  CardProps,
  Inline,
  Stack,
  Card,
  SmallText,
  Text,
  BigText,
  StackProps,
  ProgressBar,
  Button,
  Icon,
  useBreakpoints,
  Responsive
} from '@parallel-mono/components';

import { StakeInfoListItem } from '../types';
import { useApeStakeManager } from '../ApeStakingManagerProvider';

import { StakeFromDropDownButton } from './StakeFromDropDownButton';

import { ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';
import { useStakeInfoList } from '@/apps/paraspace/pages/OfficialPairing/StakeInfoListProvider/StakeInfoListProvider';
import { useStakingInfos } from '@/apps/paraspace/pages/ApePairing/contexts/StakingInfosProvider';
import { DropdownMenu, Link, NFTThumbnail, Tooltip } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type ApeItemCardProps = {
  tokenInfo: StakeInfoListItem;
  mainTokenInfo?: StakeInfoListItem;
} & Omit<CardProps, 'children'>;

const Container = styled(Card)`
  min-height: 17rem;
`;

const ContentWrapper = styled(Responsive)`
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const ApeThumbnailContainer = styled.div`
  position: relative;
  width: 25%;
  border-radius: 1rem;
  flex-shrink: 0;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  `};
`;

const ApeThumbnail = styled(NFTThumbnail).attrs<{ ratio?: string }>(({ ratio = '100%' }) => ({
  width: ratio,
  height: ratio
}))<{ topOffset?: string; leftOffset?: string; ratio?: string }>`
  position: absolute;
  top: ${({ topOffset = '0' }) => topOffset};
  left: ${({ leftOffset = '0' }) => leftOffset};
`;

type InfoSectionProps = { title: ReactNode; value: ReactNode } & Omit<StackProps, 'children'>;
const InfoSection = memo(({ title, value, ...others }: InfoSectionProps) => (
  <Stack gap="0.125rem" {...others}>
    <SmallText as="div" skin="secondary">
      {title}
    </SmallText>
    <BigText as="div" fontWeight="bold">
      {value}
    </BigText>
  </Stack>
));

const GreyBigText = styled(BigText)`
  color: ${({ theme }) => theme.skin.grey[500]};
`;

const ButtonWrapper = styled(Inline)`
  & > * {
    flex: 1;
  }
`;

export const ApeItemCard = memo(({ tokenInfo, ...others }: ApeItemCardProps) => {
  const { manageApeStake, claimRewards, withdrawApe, stakeBakc } = useApeStakeManager();
  const { authentication } = useWeb3Context();
  const {
    symbol,
    stakedAmount = null,
    stakeLimit,
    pendingRewards = null,
    mainTokenId,
    source
  } = tokenInfo;
  const { refresh, stakingInfoList } = useStakeInfoList();
  const { suppliedStakingInfo } = useStakingInfos();

  const isBAKCTypeCard = symbol === ERC721Symbol.BAKC;

  const handlePairingApe = useCallback(
    (apeCoinSource: WalletType) => {
      manageApeStake(tokenInfo, apeCoinSource);
    },
    [manageApeStake, tokenInfo]
  );

  const { mobile } = useBreakpoints();

  const handleClaimRewards = useCallback(() => {
    claimRewards(tokenInfo).finally(() => {
      refresh();
    });
  }, [refresh, claimRewards, tokenInfo]);

  const handleWithdraw = useCallback(() => {
    withdrawApe(tokenInfo).finally(() => {
      refresh();
    });
  }, [refresh, tokenInfo, withdrawApe]);

  const handleStakeBAKC = useCallback(
    (apeCoinSource: WalletType) => {
      stakeBakc(tokenInfo, apeCoinSource).finally(() => {
        refresh();
      });
    },
    [refresh, stakeBakc, tokenInfo]
  );

  const enableStake = useMemo(
    () => stakedAmount && stakeLimit && stakedAmount.lt(stakeLimit),
    [stakeLimit, stakedAmount]
  );

  const disableClaim = useMemo(() => !pendingRewards || pendingRewards.lte(0), [pendingRewards]);

  const noStakedValue = useMemo(() => !stakedAmount || stakedAmount.lte(0), [stakedAmount]);

  const isMainTokenSupplied = useMemo(() => {
    if (isBAKCTypeCard && mainTokenId) {
      const mainToken = suppliedStakingInfo?.find(v => v.tokenId === mainTokenId);
      return Boolean(mainToken);
    }
    return false;
  }, [isBAKCTypeCard, suppliedStakingInfo, mainTokenId]);

  const disableWithdraw = useMemo(
    () => noStakedValue || isMainTokenSupplied,
    [isMainTokenSupplied, noStakedValue]
  );

  const disablePairedBakc = useMemo(
    () => stakingInfoList.filter(i => !i.pairedBakc).length === 0,
    [stakingInfoList]
  );

  const dropdownMenus = useMemo(() => {
    return [
      {
        icon: <Icon name="medal" />,
        label: 'Claim Rewards',
        tooltip: <Text skin="secondary">No pending rewards</Text>,
        disabled: disableClaim,
        onClick: handleClaimRewards
      },
      {
        icon: <Icon name="downloadCloud" />,
        label: 'Withdraw',
        tooltip: (
          <>
            {noStakedValue && <Text skin="secondary">No staked amount</Text>}
            {isMainTokenSupplied && (
              <Text skin="secondary">
                Please withdraw from{' '}
                <Link href="/ape-staking/nft-pools">ParaSpace NFT Staking Pool</Link>
              </Text>
            )}
          </>
        ),
        disabled: disableWithdraw,
        onClick: handleWithdraw
      }
    ];
  }, [
    disableClaim,
    handleClaimRewards,
    noStakedValue,
    isMainTokenSupplied,
    disableWithdraw,
    handleWithdraw
  ]);

  const hideManageButton = useMemo(
    () => disableClaim && disableWithdraw,
    [disableClaim, disableWithdraw]
  );

  const isBAKCToPairApe = useMemo(
    () => isBAKCTypeCard && !tokenInfo.mainTokenSymbol,
    [isBAKCTypeCard, tokenInfo.mainTokenSymbol]
  );

  const [platformTag, platformDescription] = useMemo(() => {
    if (source === 'AA') {
      return ['parax', 'This NFT is in the AA Wallet.'];
    }
    if (source === 'EOA') {
      return [
        authentication?.meta.walletIcon ?? '',
        `This NFT is in your ${authentication.meta.walletType} Wallet.`
      ];
    }
    return [undefined, ''];
  }, [source, authentication?.meta.walletIcon, authentication?.meta.walletType]);

  return (
    <Container inset="1rem" border {...others}>
      <ContentWrapper inset={mobile ? '0' : '1.5rem'} breakPoint="tablet">
        <ApeThumbnailContainer>
          {isBAKCTypeCard && (
            <ApeThumbnail
              platform={platformTag}
              platformTooltip={platformDescription}
              size="medium"
              ratio="52%"
              symbol={tokenInfo.mainTokenSymbol || ERC721Symbol.BAYC}
              tokenId={tokenInfo.mainTokenSymbol ? tokenInfo.mainTokenId : undefined}
              showDescription={!!tokenInfo.mainTokenId}
            />
          )}
          <ApeThumbnail
            platform={isBAKCTypeCard ? undefined : platformTag}
            platformTooltip={platformDescription}
            size={isBAKCTypeCard ? 'medium' : 'xLarge'}
            topOffset={isBAKCTypeCard ? '42%' : '0'}
            leftOffset={isBAKCTypeCard ? '53%' : '0'}
            ratio={isBAKCTypeCard ? '52%' : '100%'}
            symbol={tokenInfo.symbol}
            tokenId={tokenInfo.tokenId}
            showDescription
          />
        </ApeThumbnailContainer>
        <Stack
          inset={mobile ? '2rem 0.5rem 0.5rem 0.5rem' : '1.5rem 0.5rem'}
          gap={mobile ? '1rem' : '1.25rem'}
          width="100%"
        >
          <Inline grow>
            <InfoSection title="APY" value={formatToPercentage(tokenInfo.apy?.toNumber() ?? 0)} />
            <InfoSection
              title="Rewards"
              value={
                <Inline gap="0.25rem">
                  <BigText fontWeight="bold">
                    {formatBalance(tokenInfo.pendingRewards ?? null)}
                  </BigText>
                  <GreyBigText fontWeight="bold">APE</GreyBigText>
                </Inline>
              }
            />
          </Inline>
          <Stack width="100%" gap="0.25rem">
            <Text skin="secondary">
              {formatBalance(tokenInfo.stakedAmount ?? null)}/
              {formatBalance(tokenInfo.stakeLimit ?? null)}
            </Text>
            <ProgressBar
              value={
                tokenInfo.stakedAmount
                  ?.multipliedBy(100)
                  ?.div(tokenInfo?.stakeLimit!)
                  ?.toNumber() ?? 0
              }
            />
          </Stack>
          <ButtonWrapper>
            {enableStake && (
              <Tooltip
                content={<Text skin="secondary">No Ape can be staked</Text>}
                disabled={!(isBAKCToPairApe && disablePairedBakc)}
                placement="top"
                gap="0"
              >
                <StakeFromDropDownButton
                  disabled={isBAKCToPairApe && disablePairedBakc}
                  onStake={isBAKCToPairApe ? handleStakeBAKC : handlePairingApe}
                />
              </Tooltip>
            )}
            {!hideManageButton && (
              <DropdownMenu
                menuTrigger={
                  <Button size={mobile ? 'small' : 'medium'} block skin="secondary">
                    Manage
                  </Button>
                }
                options={dropdownMenus}
              />
            )}
          </ButtonWrapper>
        </Stack>
      </ContentWrapper>
    </Container>
  );
});
