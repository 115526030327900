import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Inline, SmallText, Icon, Stack } from '@parallel-mono/components';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import BigNumberJs from 'bignumber.js';

import { NftBaseInfoSection } from '../NftBaseInfo';
import { MakeOfferModal } from '../MakeOfferModal';
import { AssetDetail } from '../../type';
import { useShopActions } from '../../contexts';

import OperationButtons from './OperationButtons';
import LiquidationTip from './LiquidationTip';

import { LiquidationInfoForAsset } from '@/apps/paraspace/generated/graphql';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { LiquidationBuyModal } from '@/apps/paraspace/components';
import { useToggle } from '@/apps/paraspace/contexts';
import { useSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { shiftedLeftBy } from '@/apps/paraspace/utils/calculations';

const StackWithGrow = styled(Stack)`
  flex-grow: 1;
  width: 100%;
`;

export type OperationCardProps = {
  disableShopFeature: boolean;
  assetData: AssetDetail;
  refetch: any;
  liquidation?: LiquidationInfoForAsset | null;
  onCloseLiquidation: (account: string) => Promise<void>;
  onTriggerAuction: (account: string) => Promise<void>;
  children?: ReactNode;
};

export const OperationCard = ({
  assetData,
  refetch,
  liquidation,
  onCloseLiquidation,
  onTriggerAuction,
  children,
  disableShopFeature
}: OperationCardProps) => {
  const { buyShopItem } = useShopActions();

  const { topOffer, ownedBy, collection, lowestPriceListing, multiplier, isOwner } = assetData;

  const [openMakeOfferModal, setOpenMakeOfferModal] = useState(false);
  const [openLiquidationBuyModal, setOpenLiquidationBuyModal] = useState(false);

  const { tokenId } = useParams();

  const onBuyNowClick = useCallback(() => {
    buyShopItem({
      tokenId: Number(tokenId),
      collection: collection!,
      lowestPriceListing: lowestPriceListing!,
      refetch,
      multiplier
    });
  }, [buyShopItem, collection, lowestPriceListing, multiplier, refetch, tokenId]);

  const handleMakeOfferClick = () => {
    setOpenMakeOfferModal(true);
  };

  const handleLiquidationBuy = () => {
    setOpenLiquidationBuyModal(true);
  };

  const enableMoonBirdListing = useToggle('MOONBIRD_LISTING');
  const symbol = useSymbolByContractAddress(collection?.contractAddress!);
  const enableOffersAndListing = useMemo(
    () => symbol !== ERC721Symbol.MOONBIRD || enableMoonBirdListing,
    [symbol, enableMoonBirdListing]
  );

  return (
    <StackWithGrow>
      <NftBaseInfoSection
        disableShopFeature={disableShopFeature}
        collectionName={`${assetData.collection.collectionName} #${tokenId}`}
        isOwner={isOwner}
        ownedBy={ownedBy}
        assetInLiquidation={liquidation?.asset}
        lowestPriceListing={lowestPriceListing}
        multiplier={multiplier}
      />
      {liquidation && <LiquidationTip liquidation={liquidation} />}
      {children}
      {!disableShopFeature &&
      !isOwner &&
      lowestPriceListing &&
      lowestPriceListing.expirationTime ? (
        <Inline gap="0.375rem">
          <Icon name="watch" size="small" />
          <SmallText skin="secondary">
            Sale ends{' '}
            {dayjs(lowestPriceListing.expirationTime).format('MMM DD, YYYY [at] hh:mma Z')}
          </SmallText>
        </Inline>
      ) : null}
      {enableOffersAndListing && (
        <OperationButtons
          disableShopFeature={disableShopFeature}
          onCloseLiquidation={onCloseLiquidation}
          onTriggerAuction={onTriggerAuction}
          onBuyLiquidationAsset={handleLiquidationBuy}
          liquidation={liquidation}
          isOwner={isOwner}
          lowestPriceListing={lowestPriceListing}
          onBuyNowClick={onBuyNowClick}
          handleMakeOfferClick={handleMakeOfferClick}
        />
      )}
      {!disableShopFeature && (
        <MakeOfferModal
          isOpen={openMakeOfferModal}
          closeModal={() => {
            setOpenMakeOfferModal(false);
          }}
          data={{
            tokenId: tokenId!,
            collection: collection!,
            lowestPriceListing,
            topOffer,
            ownedBy: ownedBy ?? '',
            mutationOptions: {
              refetchQueries: ['getAsset', 'getOrders']
            },
            multiplier
          }}
        />
      )}
      {liquidation && (
        <LiquidationBuyModal
          isOpen={openLiquidationBuyModal}
          closeModal={() => {
            setOpenLiquidationBuyModal(false);
          }}
          data={{
            tokenId: tokenId!,
            currentPrice: shiftedLeftBy(liquidation.asset.currentPrice ?? '0', 18).toString(),
            currentPriceInUsd: liquidation.asset.currentPriceInUSD,
            name: collection?.collectionName || '',
            address: collection?.contractAddress || '',
            traitMultiplier: shiftedLeftBy(liquidation.asset.traitMultiplier, 18),
            buyParameters: {
              user: liquidation.accountInfo.address,
              buyNowPrice: new BigNumberJs(liquidation.asset.currentPrice),
              floorPrice: new BigNumberJs(liquidation.asset.floorPrice)
            }
          }}
        />
      )}
    </StackWithGrow>
  );
};
