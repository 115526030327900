import { H6, Icon, Inline } from '@parallel-mono/components';
import styled, { useTheme } from 'styled-components';

import { SECURITY_AUDITS } from '@/apps/paraspace/pages/config';
import { Link } from '@/apps/paraspace/components';

const StyledLink = styled(Inline)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    display: none;
  `};
`;

const SecurityLink = () => {
  const theme = useTheme();
  return (
    <StyledLink alignItems="center" justifyContent="flex-start" gap="0.625rem">
      <Icon name="shield" size="1.25rem" color={theme.skin.grey[700]} strokeWidth={2} />
      <H6 fontWeight="medium">Security Audits: {SECURITY_AUDITS.COUNT} Reports</H6>
      <Link href={SECURITY_AUDITS.LINK} target="_blank">
        <Icon name="externalLink" size="1.25rem" />
      </Link>
    </StyledLink>
  );
};

export default SecurityLink;
