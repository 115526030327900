import { memo, useCallback } from 'react';
import { TransactionReceipt } from '@ethersproject/providers';

import { FormSubmitter } from '../FormSubmitter';

import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { TimelockStepIntro } from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useTimelock } from '@/apps/paraspace/pages/hooks/Timelock';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type WithdrawERC721SubmitterProps = {
  formData: {
    tokenIds: number[];
    symbol: ERC721Symbol;
  };
  onFinish: (agreementId: Maybe<string>, releaseTime: Maybe<Date>) => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const WithdrawERC721Submitter = memo(
  ({ formData, onFinish, onError }: WithdrawERC721SubmitterProps) => {
    const { withdrawERC721 } = usePool();
    const { submitTransactions } = useWeb3Context();
    const { symbol, tokenIds } = formData;

    const { extractEventData } = useTimelock();

    const {
      nftInfoMap: { [symbol]: { address = '' } = {} }
    } = useMMProvider();

    const submit = useCallback(async () => {
      const tx = await withdrawERC721(
        address,
        tokenIds.map(tokenId => String(tokenId))
      );
      return submitTransactions([tx!]);
    }, [tokenIds, address, withdrawERC721, submitTransactions]);

    const handleFinish = useCallback(
      ([result]: Maybe<TransactionReceipt>[]) => {
        const { logs = [] } = result ?? {};
        const [{ releaseTime = null, agreementId = null } = {}] = extractEventData<{
          releaseTime: number;
          agreementId: string;
        }>('AgreementCreated', logs);

        onFinish?.(agreementId, releaseTime === null ? null : new Date(releaseTime * 1000));
      },
      [extractEventData, onFinish]
    );

    return (
      <FormSubmitter
        inProgressMessage="Securely Withdrawing"
        onError={onError}
        onFinish={handleFinish}
        submit={submit}
      >
        <TimelockStepIntro />
      </FormSubmitter>
    );
  }
);
