import { memo, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Card,
  H4,
  Inline,
  Responsive,
  SmallText,
  Stack,
  Typography,
  TypographyVariant,
  useBreakpoints
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';

import { useV1cAPEConvertProvider } from '../../../contexts/V1cAPEConvertManagerProvider';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { zero } from '@/apps/paraspace/consts/values';

export const V1cAPEConvert = memo(() => {
  const { balanceInfo, convertV1cAPE } = useV1cAPEConvertProvider();
  const [isConverting, setIsConverting] = useState(false);
  const {
    authentication: {
      meta: { account: EOAAccount }
    },
    account
  } = useWeb3Context();

  const v1cAPEBalance = useMemo(() => balanceInfo?.balance ?? zero, [balanceInfo?.balance]);

  const handleConvert = useCallback(() => {
    setIsConverting(true);
    convertV1cAPE(v1cAPEBalance, EOAAccount, account).finally(() => {
      setIsConverting(false);
    });
  }, [EOAAccount, account, convertV1cAPE, v1cAPEBalance]);

  const { mobile } = useBreakpoints();

  if (!v1cAPEBalance.gt(0)) {
    return null;
  }

  return (
    <Card border>
      <Responsive breakPoint="tablet" justifyContent="space-between">
        <Inline alignItems="center" gap="1rem">
          {!mobile && <CryptoIcon symbol="CAPE" size="large" />}
          <Typography variant={mobile ? TypographyVariant.header6 : TypographyVariant.header4}>
            Convert your cAPE from ParaSpace V1 to your current Parallel Account
          </Typography>
        </Inline>
        <Responsive breakPoint="tablet" width={mobile ? '100%' : 'auto'} alignItems="center">
          <Inline width="100%">
            {mobile && <CryptoIcon symbol="CAPE" size="large" />}
            <Stack gap="0">
              <SmallText skin="secondary">V1 cAPE Balance</SmallText>
              <Inline gap="0.25rem" justifyContent={mobile ? 'flex-start' : 'flex-end'}>
                <H4>{formatBalance(v1cAPEBalance.toNumber())}</H4>
                <H4 skin="secondary">cAPE</H4>
              </Inline>
            </Stack>
          </Inline>

          <Stack width="100%">
            <Button
              loading={isConverting}
              disabled={isConverting || !v1cAPEBalance.gt(0)}
              onClick={handleConvert}
              block={mobile}
            >
              Convert
            </Button>
          </Stack>
        </Responsive>
      </Responsive>
    </Card>
  );
});
