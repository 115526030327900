import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import BigNumber from 'bignumber.js';
import { mapKeys, mapValues } from 'lodash';

import { MULTIPLIER_UNIT } from './consts';

import { useBendDaoService } from '@/apps/paraspace/pages/hooks/useBendDaoService';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';
import { convertToChecksumAddress } from '@/apps/paraspace/utils/convertToChecksumAddress';
import { useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

export enum LoanState {
  // We need a default that is not 'Created' - this is the zero value
  None,
  // The loan data is stored, but not initiated yet.
  Created,
  // The loan has been initialized, funds have been delivered to the borrower and the collateral is held.
  Active,
  // The loan is in auction, higest price liquidator will got chance to claim it.
  Auction,
  // The loan has been repaid, and the collateral has been returned to the borrower. This is a terminal state.
  Repaid,
  // The loan was delinquent and collateral claimed by the liquidator. This is a terminal state.
  Defaulted
}

type BendDaoPositionInfoMap = Partial<{
  [key in ERC721Symbol]: {
    tokenId: number;
    symbol: ERC721Symbol;
    debt: BigNumber;
    debtSymbol: ERC20Symbol;
    loanId: Maybe<string>;
    multiplier: BigNumber;
    state: LoanState;
  }[];
}>;

type BendDaoPositionContextValue = {
  infoMap: BendDaoPositionInfoMap;
  refresh: () => Promise<void>;
  loaded: boolean;
};

export const BendDaoPositionContext = createContext<BendDaoPositionContextValue>({
  infoMap: {},
  refresh: async () => {
    throw new Error('Not implemented yet');
  },
  loaded: false
});

export const BendDaoPositionProvider = memo(({ children }: { children: ReactNode }) => {
  const [infoMap, setInfoMap] = useState<BendDaoPositionInfoMap>({});
  const [loaded, setLoaded] = useState(false);
  const { getUserReserveInfo } = useBendDaoService();
  const { nftInfoMap } = useMMProvider();
  const nTokenMap = useMemo(
    () =>
      mapValues(
        mapKeys(nftInfoMap, it => convertToChecksumAddress(it.address)),
        it => convertToChecksumAddress(it.xTokenAddress)
      ),
    [nftInfoMap]
  );
  const stabilizedNTokenMap = useStabilizedSnapshot(nTokenMap);

  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  const refresh = useCallback(async () => {
    setLoaded(false);
    const userReserveInfo = await getUserReserveInfo(stabilizedNTokenMap);

    const symbolIndexedUserReserveInfo = mapKeys(userReserveInfo, (_v, k) =>
      getSymbolByContractAddress(k)
    );

    setInfoMap(
      mapValues(
        symbolIndexedUserReserveInfo,
        ({ suppliedList, loansDataMap, tokenTraitMultipliers }, symbol) =>
          suppliedList.map(tokenId => ({
            tokenId,
            symbol: symbol as ERC721Symbol,
            debt: new BigNumber(loansDataMap[tokenId].totalDebtInReserve.toString()),
            debtSymbol: getSymbolByContractAddress(loansDataMap[tokenId].reserveAsset),
            loanId: loansDataMap[tokenId]?.loanId?.toString() ?? null,
            state: loansDataMap[tokenId]?.state.toNumber() ?? LoanState.None,
            multiplier: tokenTraitMultipliers[tokenId]
              ? new BigNumber(tokenTraitMultipliers[tokenId]?.toString()).div(MULTIPLIER_UNIT)
              : new BigNumber(DEFAULT_MULTIPLIER)
          }))
      )
    );

    setLoaded(true);
  }, [getUserReserveInfo, stabilizedNTokenMap, getSymbolByContractAddress]);

  const contextValue = useMemo(
    () => ({
      refresh,
      infoMap,
      loaded
    }),
    [refresh, infoMap, loaded]
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <BendDaoPositionContext.Provider value={contextValue}>
      {children}
    </BendDaoPositionContext.Provider>
  );
});

export const useBendDaoPosition = () => useContext(BendDaoPositionContext);
