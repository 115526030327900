import styled from 'styled-components';
import { SmallText, Tag, Text } from '@parallel-mono/components';

export const StyledTag = styled(Tag).attrs({
  size: 'small'
})`
  display: flex;
  border: ${({ theme, skin }) =>
    `${theme.border.width.medium} solid ${
      skin === 'neutral' ? theme.skin.grey[200] : theme.skin[skin].contrastBackground
    }`};
  padding: 0.125rem;
  padding-right: 0.5rem;
`;

export const GreyText = styled(SmallText)`
  color: ${({ theme }) => theme.skin.grey[500]};
`;

export const CenteredText = styled(Text)`
  text-align: center;
`;
