import { memo, useCallback } from 'react';

import { FormSubmitter } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useERC721 } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';

type SupplyNftSubmitterProps = {
  formData: {
    chunkTokenIds: number[];
    allTokenIds: number[];
    symbol: ERC721Symbol;
  };
  waitConfirmations?: number;
  onFinish: () => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const SupplyNftSubmitter = memo(
  ({
    formData: { chunkTokenIds, allTokenIds, symbol },
    onFinish,
    onError,
    waitConfirmations
  }: SupplyNftSubmitterProps) => {
    const { submitTransactions } = useWeb3Context();
    const { genApprovalForAll, genSupplyERC721Txs } = useERC721(symbol);

    const handleFinish = useCallback(() => {
      onFinish();
    }, [onFinish]);

    const submit = useCallback(async () => {
      const approveAllERC721Txs = (await genApprovalForAll({ ids: allTokenIds })) ?? [];
      const supplyERC721Txs = (await genSupplyERC721Txs({ tokenIds: chunkTokenIds })) ?? [];
      return submitTransactions([...approveAllERC721Txs, ...supplyERC721Txs].filter(notEmpty));
    }, [allTokenIds, chunkTokenIds, genApprovalForAll, genSupplyERC721Txs, submitTransactions]);

    return (
      <FormSubmitter
        inProgressMessage="Securely Supplying"
        waitConfirmations={waitConfirmations}
        onError={onError}
        onFinish={handleFinish}
        submit={submit}
      />
    );
  }
);
