import {
  Button,
  Card,
  DataGrid,
  DataGridColumn,
  DomEmergenceTaskTrigger,
  H5,
  H6,
  Icon,
  Inline,
  Spinner,
  Stack,
  StackProps,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import {
  forwardRef,
  HTMLAttributes,
  memo,
  MutableRefObject,
  useCallback,
  useMemo,
  useState,
  useImperativeHandle,
  ForwardedRef
} from 'react';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';
import { isFunction, isObject, uniqueId } from 'lodash';
import cx from 'classnames';
import { CryptoIcon } from '@parallel-mono/business-components';

import { useListings } from '../../../hooks';
import { ListingTableRowData } from '../types';
import { Listing, ListingType } from '../../../types';

import { MobileListingDataGrid } from './MobileListingGrid';
import { CreateListingFromDropDownButton } from './CreateListingDropDownButton';

import { NFTThumbnail, SupportedSymbol, AccountPill } from '@/apps/paraspace/components';
import {
  ApeStakingTokenSymbol,
  ApeStakingMainAssetSymbol,
  WalletType
} from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/generated/graphql';

enum TableDensity {
  Small = 'small',
  Normal = 'normal'
}

type ListingsExploreProps = {
  density: `${TableDensity}`;
  listings: ListingTableRowData[];
  stakingPool: ApeStakingTokenSymbol;
  listingType: ListingType;
  hasNextPage: boolean;
  onHandleCreateListing: (source: WalletType) => void;
  label: string;
  onSelectedListingChange: (data: Listing) => void;
  scrollContainer: HTMLDivElement | null;
  load: (offset?: number) => Promise<void>;
};

const StyledCard = styled(Card)`
  flex: 1;
  height: fit-content;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    width: 100%;
  `};
`;
const NoDataStack = styled(Stack)`
  padding: 2.5rem 0;
`;
const LoadMoreTrigger = styled(DomEmergenceTaskTrigger<number>)`
  text-align: center;
  padding: 0.5rem;
`;
const ScrollContainer = styled.div`
  max-height: 28.125rem;
  overflow: auto;
  overflow-y: overlay;
`;
const StyledSpinner = styled(Spinner)`
  display: block;
  margin: 0 auto;
`;
const ListerShare = styled(H6)`
  white-space: nowrap;
`;

const StyledDataGrid = styled(DataGrid<ListingTableRowData>)`
  .styled-datagrid-cell {
    padding: 1rem 0rem;
    display: flex;
    align-items: center;
  }
`;

const createListingColumns: (
  listingType: ListingType,
  onSelectListing: (listing: ListingTableRowData) => void,
  density: `${TableDensity}`
) => DataGridColumn<ListingTableRowData>[] = (listingType, onSelectListing, density) => {
  return [
    {
      name: 'asset',
      title: <H6>{listingType !== ListingType.ApeCoinListing ? 'NFT' : 'ApeCoin'}</H6>,
      render: ({ data }) => {
        if (data.listingType !== ListingType.ApeCoinListing) {
          return (
            <Inline gap="0.75rem" alignItems="center">
              <NFTThumbnail
                size="small"
                width={density === TableDensity.Small ? '2rem' : '3rem'}
                tokenId={data.tokenId}
                symbol={data.symbol as SupportedSymbol}
              />
              <Stack gap="0">
                <Text>#{data.tokenId}</Text>
                <AccountPill address={data.offerer} />
              </Stack>
            </Inline>
          );
        }
        return (
          <Inline gap="0.75rem" alignItems="center">
            <CryptoIcon symbol="APE" size="2rem" />
            <Stack gap="0">
              <Text>{formatNumber(BigNumber(data.amount ?? 0))} APE</Text>
              <AccountPill address={data.offerer} />
            </Stack>
          </Inline>
        );
      }
    },
    {
      name: 'askShare',
      title: <ListerShare>Lister % Share</ListerShare>,
      render: ({ data: { share } }) => {
        return (
          <Stack inset={density === TableDensity.Small ? '0 0 0 1rem' : '0'} gap="0">
            <H5>{formatNumber(share, { output: 'percent' })}</H5>
          </Stack>
        );
      }
    },
    {
      name: 'join',
      title: null,
      justifyContent: 'flex-end',
      width: density === TableDensity.Small ? '4rem' : '8rem',
      render: ({ data, data: { isSelected } }) => {
        return (
          <Button
            block
            size={density === TableDensity.Small ? 'small' : 'medium'}
            onClick={() => {
              onSelectListing(data);
            }}
            skin={isSelected ? 'primary' : 'secondary'}
          >
            {isSelected ? <Icon name="check" /> : 'Select'}
          </Button>
        );
      }
    }
  ];
};

export const ListingsExplore = memo(
  ({
    density,
    hasNextPage,
    listings,
    label,
    stakingPool,
    listingType,
    onSelectedListingChange,
    load,
    onHandleCreateListing,
    scrollContainer
  }: ListingsExploreProps) => {
    const loadMoreTriggerKey = useMemo(
      () => uniqueId(`loadMoreTriggerWith${uniqueId()}-${listings.length}ExistingOffers`),
      [listings]
    );

    const { mobile } = useBreakpoints();

    const columns = useMemo(
      () => createListingColumns(listingType, onSelectedListingChange, density),
      [listingType, onSelectedListingChange, density]
    );

    const ListingDataGrid = useMemo(() => {
      return mobile ? (
        <MobileListingDataGrid
          width="100%"
          data={listings}
          onSelectedListingChange={onSelectedListingChange}
        />
      ) : (
        <StyledDataGrid
          classNames={{
            cell: density === TableDensity.Small ? cx('styled-datagrid-cell') : undefined
          }}
          data={listings}
          columns={columns}
        />
      );
    }, [columns, listings, mobile, onSelectedListingChange, density]);

    const LoadMore = useMemo(() => {
      if (hasNextPage) {
        return (
          <LoadMoreTrigger
            key={loadMoreTriggerKey}
            root={scrollContainer}
            threshold={0.5}
            task={load}
            params={listings.length}
            rootMargin="100px"
          >
            {listings.length === 0 ? (
              <StyledSpinner />
            ) : (
              <Text skin="secondary" fontWeight="bold">
                Loading More...
              </Text>
            )}
          </LoadMoreTrigger>
        );
      }

      if (listings.length === 0) {
        return (
          <NoDataStack alignItems="center">
            <Icon name="boxOpen" size="4rem" />
            <Stack gap="0" alignItems="center">
              <H5 fontWeight="bold">No {label} Offer</H5>
              <Text skin="secondary">You can wait or make your own offer!</Text>
            </Stack>
            <CreateListingFromDropDownButton
              stakingPool={stakingPool}
              listingType={listingType}
              onCreateListing={onHandleCreateListing}
            />
          </NoDataStack>
        );
      }

      if (listings.length > 0 && !hasNextPage) {
        return (
          <Inline justifyContent="center">
            <Text skin="secondary">No more data.</Text>
          </Inline>
        );
      }

      return null;
    }, [
      hasNextPage,
      listings.length,
      loadMoreTriggerKey,
      scrollContainer,
      load,
      label,
      onHandleCreateListing,
      listingType,
      stakingPool
    ]);

    return (
      <>
        {listings.length > 0 && ListingDataGrid}
        {LoadMore}
      </>
    );
  }
);

export type UpdateListingHandle = {
  updateListing: (listingHash: string) => void;
};

type ListingTableProps<T> = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  density?: `${TableDensity}`;
  listingType: T;
  label: string;
  stakingPool: ApeStakingTokenSymbol;
  tokens: ApeStakingMainAssetSymbol[];
  onHandleCreateListing: (source: WalletType) => void;
  onSelectedListingChange: (data: Listing) => void;
  selectedJoinListing: Maybe<Listing>;
};
type Props<T> = Omit<StackProps, 'children'> & ListingTableProps<T>;

const ListingTableComponent = <T extends ListingType>(
  {
    density = TableDensity.Normal,
    label,
    listingType,
    stakingPool,
    tokens,
    selectedJoinListing,
    onHandleCreateListing,
    onSelectedListingChange,
    ...props
  }: Props<T>,
  ref: ForwardedRef<HTMLDivElement | UpdateListingHandle>
) => {
  const { listings, loadMore, hasMore, updateCachedData } = useListings(
    stakingPool,
    listingType,
    tokens
  );

  useImperativeHandle(ref, () => ({
    updateListing: (listingHash: string) => {
      updateCachedData(curr => curr.filter(it => it.listingHash !== listingHash));
    }
  }));

  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);
  const handleRef = useCallback(
    (ele: HTMLDivElement) => {
      setScrollContainer(ele);
      if (isFunction(ref)) {
        ref(ele);
      } else if (isObject(ref)) {
        (ref as MutableRefObject<HTMLDivElement>).current = ele;
      }
    },
    [ref]
  );

  const rows = useMemo(
    () =>
      listings.map(
        listing =>
          ({
            ...listing,
            isSelected: selectedJoinListing?.listingHash === listing.listingHash
          } as ListingTableRowData)
      ),
    [listings, selectedJoinListing]
  );

  return (
    <StyledCard inset={density === TableDensity.Small ? '1rem' : '1.5rem'} border {...props}>
      <Stack>
        <Inline justifyContent="space-between" alignItems="center">
          <H5>{label} Offers</H5>
          <CreateListingFromDropDownButton
            listingType={listingType}
            stakingPool={stakingPool}
            onCreateListing={onHandleCreateListing}
          />
        </Inline>
        <ScrollContainer ref={handleRef}>
          <ListingsExplore
            density={density}
            scrollContainer={scrollContainer}
            stakingPool={stakingPool}
            listingType={listingType}
            onSelectedListingChange={onSelectedListingChange}
            label={label}
            hasNextPage={hasMore}
            listings={rows}
            load={loadMore}
            onHandleCreateListing={onHandleCreateListing}
          />
        </ScrollContainer>
      </Stack>
    </StyledCard>
  );
};

export const ListingTable = memo(forwardRef(ListingTableComponent));
