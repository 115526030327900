import { FC, memo, useMemo } from 'react';
import { Image, Text } from '@parallel-mono/components';
import { HostedImage } from '@parallel-mono/business-components';
import styled from 'styled-components';

import { DropdownMenu, DropdownMenuProps } from '@/apps/paraspace/components';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { WalletType } from '@/apps/paraspace/typings';

type Props = {
  clickHandler: (walletType: WalletType) => void;
};

const StyledDropdownMenu = styled(DropdownMenu)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
  `}
`;
export const JoinP2PListingDropdownMenu: FC<Props & Omit<DropdownMenuProps, 'options'>> = memo(
  ({ clickHandler, menuTrigger, placement = 'bottom-end', ...others }) => {
    const {
      authentication: {
        meta: { walletIcon, walletType }
      }
    } = useWeb3Context();

    const options = useMemo(
      () =>
        [
          {
            icon: (
              <HostedImage
                name="design/PDS_V3/logo/parallel-v2-logo"
                height="1.25rem"
                width="1.25rem"
              />
            ),
            label: <Text>AA Wallet</Text>,
            onClick: (e: any) => {
              e.stopPropagation();
              clickHandler('AA');
            }
          },
          {
            icon: <Image src={walletIcon!} width="1.25rem" height="1.25rem" />,
            label: <Text>{walletType}</Text>,
            onClick: (e: any) => {
              e.stopPropagation();
              clickHandler('EOA');
            }
          }
        ].filter(notEmpty),
      [walletIcon, walletType, clickHandler]
    );

    return (
      <StyledDropdownMenu
        placement={placement}
        width="224px"
        title="Join Staking from"
        subTitle="Select where you want to use your balance"
        options={options}
        menuTrigger={menuTrigger}
        {...others}
      />
    );
  }
);
