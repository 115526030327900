import { memo, useCallback, useState } from 'react';

import { JoinBAKCPairListingsFormData } from '../types';
import { ApeCoinListing, ApeListing, BakcListing } from '../../../../types';

import { useJoinBAKCPairListingsFormSubmitterSteps } from './useJoinBAKCPairListingsFormSubmitterSteps';

import { Stepper, StepperProps } from '@/apps/paraspace/components';
import { WalletType } from '@/apps/paraspace/typings';

type JoinBAKCPairListingsFormSubmitterProps = Omit<
  StepperProps,
  'steps' | 'step' | 'onSubmit' | 'onError'
> & {
  formData: JoinBAKCPairListingsFormData;
  walletType: WalletType;
  onError: (error: {
    apeListing: Partial<ApeListing>;
    apeCoinListing: Partial<ApeCoinListing>;
    bakcListing: Partial<BakcListing>;
  }) => void;
  onFinish: (result: {
    apeListing: ApeListing;
    apeCoinListing: ApeCoinListing;
    bakcListing: BakcListing;
  }) => void;
};

export const JoinBAKCPairListingsFormSubmitter = memo(
  ({
    formData,
    walletType,
    onError,
    onFinish,
    ...others
  }: JoinBAKCPairListingsFormSubmitterProps) => {
    const [step, setStep] = useState<number>(0);

    const handleNext = useCallback(() => {
      setStep(curr => curr + 1);
    }, [setStep]);

    const steps = useJoinBAKCPairListingsFormSubmitterSteps({
      formData,
      walletType,
      onNext: handleNext,
      onError,
      onFinish
    });

    return <Stepper step={step} steps={steps} {...others} />;
  }
);
