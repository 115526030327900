import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { memoize, uniqueId } from 'lodash';

import { FetchingStatus, UserERC20BalanceInfo } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type ERC20Balance = Record<string, UserERC20BalanceInfo>;

type Props = {
  pollingInterval: number;
  pollFn: () => Promise<void>;
  autoStart?: boolean;
};

const generateTaskIdFromSeeds = memoize((_seed: any) => uniqueId());

export const usePolling = ({ pollingInterval, pollFn, autoStart = true }: Props) => {
  const [pollingStatus, setPollingStatus] = useState(FetchingStatus.INIT);

  const [manualTaskId, setManualTaskId] = useState<Maybe<string>>(null);

  const taskIdSeed = useMemo(
    () => ({
      pollFn,
      manualTaskId,
      pollingInterval
    }),
    [manualTaskId, pollFn, pollingInterval]
  );

  const taskId = useMemo(() => generateTaskIdFromSeeds(taskIdSeed), [taskIdSeed]);

  const task = useMemo(
    () => ({
      taskId,
      execute: pollFn,
      interval: pollingInterval
    }),
    [pollFn, pollingInterval, taskId]
  );

  const taskIdRef = useRef<Maybe<string>>(null);
  taskIdRef.current = task.taskId;

  const timerRef = useRef<Maybe<number>>(null);

  const loopPollingTask = useCallback(() => {
    setPollingStatus(FetchingStatus.FETCHING);
    task
      .execute()
      .then(() => {
        if (task.taskId === taskIdRef.current) {
          setPollingStatus(FetchingStatus.SUCCESS);
        }
      })
      .catch(() => {
        if (task.taskId === taskIdRef.current) {
          setPollingStatus(FetchingStatus.FAIL);
        }
      })
      .finally(() => {
        if (task.taskId === taskIdRef.current) {
          timerRef.current = window.setTimeout(loopPollingTask, task.interval);
        }
      });
  }, [task]);

  useEffect(() => {
    if (!autoStart) return undefined;
    loopPollingTask();
    return () => {
      taskIdRef.current = null;
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
    };
  }, [loopPollingTask, autoStart]);

  const poll = useCallback(() => {
    setManualTaskId(uniqueId());
  }, []);

  const resetStatus = useCallback(() => {
    taskIdRef.current = null;
    setPollingStatus(FetchingStatus.INIT);
  }, []);

  return { pollingStatus, poll, resetStatus };
};
