import { useMemo } from 'react';
import { minBy } from 'lodash';
import BigNumberJs from 'bignumber.js';

import { AssetDetail } from '../type';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import {
  ShopBidByPlatform,
  ShopListingByPlatform,
  useShopItemDetailQuery
} from '@/apps/paraspace/generated/graphql';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useIsTokenSupplierCheck } from '@/apps/paraspace/pages/hooks/useIsTokenSupplierCheck';
import { useIsTokenOwnerCheck } from '@/apps/paraspace/pages/hooks/useIsTokenOwnerCheck';
import { useAppConfig, useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { calculateFees } from '@/apps/paraspace/utils/calculateFees';
import { useFormatEntity } from '@/apps/paraspace/pages/Shop/hooks';

export const useAssetDetail = (contractAddr: string, tokenId: string) => {
  const { account } = useWeb3Context();
  const { formatOrder, formatListing } = useFormatEntity();
  const { checkIsTokenSupplier } = useIsTokenSupplierCheck();
  const { checkIsTokenOwner } = useIsTokenOwnerCheck();

  const { loading, data, refetch } = useShopItemDetailQuery({
    variables: {
      contractAddress: contractAddr!,
      identifierOrCriteria: tokenId
    }
  });

  const { erc721Config } = useAppConfig();
  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  const asset = useMemo(() => {
    if (!data?.shopItemDetail) return null;
    const symbol = getSymbolByContractAddress(contractAddr ?? '') as ERC721Symbol;
    const collectionConfig = erc721Config[symbol];
    const { shopItemDetail } = data;
    const collectionFees = calculateFees(shopItemDetail.collection?.fees);

    const listings = shopItemDetail.listings.map(listing =>
      formatListing(listing as ShopListingByPlatform)
    );

    return {
      tokenId: Number(shopItemDetail.identifierOrCriteria),
      collection: {
        ...shopItemDetail.collection,
        ...collectionConfig,
        fees: collectionFees
      },
      lowestPriceListing: listings.length > 0 ? minBy(listings, 'price') : null,
      listings,
      multiplier: BigNumberJs(shopItemDetail.multiplier ?? DEFAULT_MULTIPLIER),
      ownedBy: shopItemDetail.ownedBy!,
      isOwner:
        checkIsTokenOwner(contractAddr, shopItemDetail.identifierOrCriteria) ||
        account === (shopItemDetail.ownedBy ?? ''),
      isSupplier: checkIsTokenSupplier(contractAddr, shopItemDetail.identifierOrCriteria),
      traits: shopItemDetail.traits!,
      topOffer: shopItemDetail.topOffer
        ? formatOrder(shopItemDetail.topOffer as ShopBidByPlatform)
        : null
    } as AssetDetail;
  }, [
    data,
    getSymbolByContractAddress,
    contractAddr,
    erc721Config,
    checkIsTokenOwner,
    account,
    checkIsTokenSupplier,
    formatOrder,
    formatListing
  ]);

  return { asset, loading, refetch };
};
