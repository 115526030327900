import { InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import { Skeleton } from '@parallel-mono/components';
import { memo, useMemo } from 'react';
import { BigNumber } from 'bignumber.js';

import { NumberRange } from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  useLendingSimulation,
  ActionTypeEnum
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { formatToCurrency } from '@/apps/paraspace/utils/format';

type InfosProps = Omit<InfoPanelProps, 'infos'> & {
  apyList: {
    label: string;
    load: boolean;
    value: string;
  }[];
  symbol: ERC20Symbol;
  amount: Maybe<number>;
};

export const Infos = memo(({ apyList, symbol, amount, ...others }: InfosProps) => {
  const {
    overviewUserInfo: { borrowLimitInUsd },
    erc20InfoMap
  } = useMMProvider();
  const {
    priceInUsd,
    baseLTVasCollateral,
    reserveLiquidationThreshold,
    usageAsCollateralEnabledOnUser,
    suppliedAmount
  } = erc20InfoMap[symbol];

  const asCollateral = usageAsCollateralEnabledOnUser || suppliedAmount?.eq(0);

  const { borrowLimitInUsd: newBorrowLimitInUsd } = useLendingSimulation([
    {
      type: ActionTypeEnum.SUPPLY,
      targetAsset: {
        id: `${symbol}`,
        value:
          asCollateral && Number.isFinite(amount) ? priceInUsd.times(amount!) : new BigNumber(0),
        LTV: baseLTVasCollateral,
        reserveLiquidationThreshold
      }
    }
  ]);

  const infos = useMemo(
    () => [
      ...apyList.map(item => ({
        title: item.label,
        value: item.load ? <Skeleton.Button height="1.5rem" variant="round" /> : item.value
      })),
      {
        title: 'New Borrow Limit',
        value: (
          <NumberRange
            start={borrowLimitInUsd.toNumber()}
            end={newBorrowLimitInUsd.toNumber()}
            formatter={formatToCurrency}
          />
        )
      }
    ],
    [apyList, borrowLimitInUsd, newBorrowLimitInUsd]
  );
  return <InfoPanel infos={infos} {...others} />;
});
