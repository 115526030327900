import styled from 'styled-components';
import { DataGridClassNames } from '@parallel-mono/components';
import { TokenInput } from '@parallel-mono/business-components';

import { DataGrid } from '@/apps/paraspace/components';

export const dataGridClassNames: DataGridClassNames = {
  cell: 'spt-cell',
  headerCell: 'header-cell'
};

export const StyledPriceTokenInput = styled(TokenInput)`
  width: 8.75rem;
  padding: 0;
  input {
    padding: 0;
    line-height: 1.5rem;
    margin-left: 0.5rem;
  }
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const getListingPricesDataGrid = <T extends {}>() => styled(DataGrid<T>).attrs({
  classNames: dataGridClassNames
})`
  .${dataGridClassNames.cell} {
    border-style: solid;
    border-color: ${({ theme }) => theme.skin.grey[200]};
    border-width: ${({ theme }) => theme.border.width.medium} 0;
    margin-bottom: 1rem;

    &:first-child {
      border-left-width: ${({ theme }) => theme.border.width.medium};
      border-top-left-radius: ${({ theme }) => theme.border.radius.large};
      border-bottom-left-radius: ${({ theme }) => theme.border.radius.large};
    }

    &:last-child {
      border-bottom-right-radius: ${({ theme }) => theme.border.radius.large};
      border-right-width: ${({ theme }) => theme.border.width.medium};
      border-top-right-radius: ${({ theme }) => theme.border.radius.large};
    }
  }

  .${dataGridClassNames.headerCell} {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: normal;
    border: none;
  }

  .hiddenTitleLine {
    width: 100%;
  }
`;
