import {
  Card,
  CardProps,
  Icon,
  Inline,
  Stack,
  Tabs,
  useBreakpoints
} from '@parallel-mono/components';
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { registerCustomArbitrumNetwork } from '@arbitrum/sdk';

import { parallelNetworkBridgeConfig } from '../../configs';

import { ERC20Activity } from './ERC20Activity';
import { NativeTokenActivity } from './NativeTokenActivity';

const StyledTabs = styled(Tabs)`
  .tab {
    padding: 2px 1.5rem;
  }
`;

export const Activity = memo(
  forwardRef<
    {
      refreshERC20Activities?: () => void;
      refreshNativeCurrencyActivities?: () => void;
    },
    Omit<CardProps, 'children'> & {
      // eslint-disable-next-line react/no-unused-prop-types
      onClose: () => void;
    }
  >(({ onClose, ...others }, ref) => {
    const erc20ActivityRef = useRef<{ refresh: () => void }>(null);
    const nativeTokenActivityRef = useRef<{ refresh: () => void }>(null);
    useImperativeHandle(ref, () => ({
      refreshERC20Activities: erc20ActivityRef.current?.refresh,
      refreshNativeCurrencyActivities: nativeTokenActivityRef.current?.refresh
    }));
    const [activeTab, setActiveTab] = useState(0);

    const tabs = useMemo(
      () => [
        { title: 'ERC20', content: <ERC20Activity ref={erc20ActivityRef} /> },
        { title: 'ETH', content: <NativeTokenActivity ref={nativeTokenActivityRef} /> }
      ],
      []
    );

    useEffect(() => {
      registerCustomArbitrumNetwork(parallelNetworkBridgeConfig);
    }, []);

    const { mobile } = useBreakpoints();

    return (
      <Card inset="2rem 0" width={mobile ? '100%' : '480px'} {...others}>
        <Stack>
          <Inline inset="0 2rem" justifyContent="space-between">
            <StyledTabs
              classNames={{ tab: 'tab' }}
              activeTab={tabs[activeTab]}
              onActiveTabChange={(_, index) => setActiveTab(index)}
              tabs={tabs}
            />
            <Inline onClick={onClose}>
              <Icon
                style={{ cursor: 'pointer' }}
                size="1.5rem"
                strokeWidth="2"
                name="chevronDown"
              />
            </Inline>
          </Inline>
          {tabs[activeTab].content}
        </Stack>
      </Card>
    );
  })
);
