import { H5 } from '@parallel-mono/components';
import { ComponentProps, FC, ReactElement, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { MenuItem } from '@/apps/paraspace/components';

const TabWrapper = styled.div`
  width: 100%;
`;

const TabPanel = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const TabButtonText = styled(H5)`
  font-weight: 500;
`;

export const TabButton: FC<
  ComponentProps<typeof MenuItem> & {
    isActive: boolean;
    text: string;
    id: string;
    handleClick: (newActiveId: string) => void;
  }
> = ({ isActive, text, id, handleClick, ...others }) => {
  const onClick = useCallback(() => {
    handleClick(id);
  }, [handleClick, id]);
  return (
    <MenuItem width="auto" onClick={onClick} selected={isActive} {...others}>
      <TabButtonText> {text} </TabButtonText>
    </MenuItem>
  );
};

export const Tab = ({
  tabsData,
  ...others
}: {
  tabsData: { id: string; text: string; component: ReactElement }[] &
    ComponentProps<typeof TabWrapper>;
}) => {
  const [curActiveTab, setCurActiveTab] = useState('ape');

  const handleClick = useCallback(newId => {
    setCurActiveTab(newId);
  }, []);

  const Content = useMemo(() => {
    return tabsData.find(each => each.id === curActiveTab)?.component;
  }, [curActiveTab, tabsData]);

  return (
    <TabWrapper {...others}>
      <TabPanel>
        {tabsData.map(({ id, text }) => {
          return (
            <TabButton
              key={id}
              id={id}
              handleClick={handleClick}
              isActive={curActiveTab === id}
              text={text}
            />
          );
        })}
      </TabPanel>
      {Content}
    </TabWrapper>
  );
};
