import { memo } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';

import { V1cAPEConvertSteppers } from './V1cAPEConvertSteppers';

import {
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure,
  ErrorState,
  SuccessState
} from '@/apps/paraspace/components';

export type V1cAPEConvertModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  formData: {
    amount: BigNumber;
    from: string;
    to: string;
  };
  onClose: () => void;
  onError?: () => void;
  onFinish?: () => void;
};

export const V1cAPEConvertModal = memo(
  ({ isOpen, onError, formData, onFinish, onClose, ...others }: V1cAPEConvertModalProps) => {
    const { phase, handleSubmitFailed, handleSubmitSuccess } = useCollectAndSubmitProcedure<
      number[]
    >({
      running: isOpen,
      onError,
      onFinish,
      defaultPhase: CollectAndSubmitProcedurePhase.Submitting
    });

    return (
      <Modal
        title="Convert your cAPE"
        closeOnBackdropClick={false}
        size="30rem"
        isOpen={isOpen}
        onClose={onClose}
        {...others}
      >
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <V1cAPEConvertSteppers
            formData={formData}
            onFinish={handleSubmitSuccess}
            onError={handleSubmitFailed}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            onAction={onClose}
            desc="Successfully convert your cAPE"
            actionButtonText="Done"
          />
        )}
      </Modal>
    );
  }
);
