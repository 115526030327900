import { FC, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { isNil } from 'lodash';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { useWeb3TokenAuth } from '@/apps/paraspace/contexts';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import {
  useGetUserProfileLazyQuery,
  useSendActivationEmailMutation,
  useUpdateProfileMutation,
  Notification as NotificationType
} from '@/apps/paraspace/generated/graphql';

export type Notification = Omit<NotificationType, '__typename'>;

export type Profile = {
  walletAddress: string;
  email?: Maybe<string>;
  avatar?: Maybe<string>;
  notifications: Notification[];
};

type ContextValue = {
  profile: {
    avatar: Maybe<string>;
    notifications: Notification[];
    email: Maybe<string>;
  };
  handleUpdateProfile: (profile: {
    avatar?: string;
    notifications: Notification[];
  }) => Promise<void>;
  handleSendActivationEmail: (email: string) => Promise<void>;
  updateProfileLoading: boolean;
  profileLoading: boolean;
};

export const ProfileContext = createContext<ContextValue>({
  profile: { avatar: null, email: null, notifications: [] },
  handleUpdateProfile: async () => {
    throw new Error('No implemented yet');
  },
  updateProfileLoading: false,
  profileLoading: false,
  handleSendActivationEmail: async () => {
    throw new Error('No implemented yet');
  }
});

export const ProfileContextProvider: FC = ({ children }) => {
  const { web3Token } = useWeb3TokenAuth();

  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
  const [profile, setProfile] = useState<Maybe<Profile>>(null);

  const [getUserProfile, { data: profileData, loading: getProfileLoading }] =
    useGetUserProfileLazyQuery({ fetchPolicy: 'no-cache' });

  const [updateProfile] = useUpdateProfileMutation();

  const [sendActivationEmail] = useSendActivationEmailMutation();

  const handleSendActivationEmail = useCallback(
    async (email: string) => {
      await sendActivationEmail({ variables: { email } });
    },
    [sendActivationEmail]
  );

  const handleGetUserProfile = useCallback(async () => {
    try {
      const result = await getUserProfile();
      setProfile(result.data?.profile ?? null);
    } catch (e) {
      setProfile(null);
      console.error(`Fetch the profile failed, error: ${e}`);
      throw e;
    }
  }, [getUserProfile]);

  const handleUpdateProfile = useCallback(
    async ({ avatar, notifications }: { avatar?: string; notifications: Notification[] }) => {
      setUpdateProfileLoading(true);
      try {
        const notificationIds = notifications.filter(it => it.value).map(it => it.id);
        await updateProfile({
          variables: {
            inputProfile: {
              avatar,
              notifications: notificationIds
            }
          }
        });
        await handleGetUserProfile();
      } catch (e) {
        console.error(`Update the profile failed, error: ${e}`);
        throw e;
      } finally {
        setUpdateProfileLoading(false);
      }
    },
    [handleGetUserProfile, updateProfile]
  );

  useAsyncEffect(async () => {
    handleGetUserProfile();
  }, [handleGetUserProfile, web3Token]);

  const profileLoading = useMemo(
    () => isNil(profileData) && getProfileLoading,
    [getProfileLoading, profileData]
  );

  const profileValue = useMemo(
    () => ({
      avatar: profile?.avatar ?? null,
      email: profile?.email ?? null,
      notifications: profile?.notifications ?? []
    }),
    [profile]
  );

  const value = useMemo(
    () => ({
      profile: profileValue,
      handleUpdateProfile,
      handleSendActivationEmail,
      updateProfileLoading,
      profileLoading
    }),
    [
      profileValue,
      handleUpdateProfile,
      handleSendActivationEmail,
      updateProfileLoading,
      profileLoading
    ]
  );

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => useContext(ProfileContext);
