import { memo, useState } from 'react';
import { CardProps } from '@parallel-mono/components';

import { StyledSupplyPositionDataGrid } from '../StyledComponents';
import { handleCollapseRow } from '../utils';

import { SupplyPositionsHeader } from './SupplyPositionsHeader';
import { CollectionPosition, TokenPosition } from './types';
import { useSupplyPositionsData } from './hooks/useSupplyPositionsData';

import { Collapse } from '@/apps/paraspace/components';
import { useAccountLiquidationStatus } from '@/apps/paraspace/pages/hooks/useAccountLiquidationStatus';
import { useAccountLiquidation } from '@/apps/paraspace/pages/hooks';

type SupplyPositionsProps = {
  nftPositions: CollectionPosition[];
  tokenPositions: TokenPosition[];
} & Omit<CardProps, 'children'>;

export const SupplyPositions = memo(
  ({ nftPositions, tokenPositions, ...others }: SupplyPositionsProps) => {
    const { inLiquidation } = useAccountLiquidationStatus();
    const {
      columns,
      data,
      assets,
      totalSuppliedPositionsInUsd,
      totalNetWorthPositionInUsd,
      expandableMeta,
      modalHolders
    } = useSupplyPositionsData({
      nftPositions,
      tokenPositions,
      inLiquidation
    });

    const { inAuction, disableCloseLiquidation, handleCloseLiquidation } = useAccountLiquidation();

    const [open, setOpen] = useState(true);

    return (
      <Collapse
        border
        open={open}
        onToggle={setOpen}
        header={
          <SupplyPositionsHeader
            collapsed={!open}
            totalValue={totalSuppliedPositionsInUsd}
            netWorth={totalNetWorthPositionInUsd}
            assets={assets}
            closeLiquidationButtonProps={{
              show: inAuction,
              children: 'Close Liquidation',
              onClick: e => {
                e.stopPropagation();
                handleCloseLiquidation();
              },
              disabled: disableCloseLiquidation
            }}
          />
        }
        {...others}
      >
        {modalHolders}
        <StyledSupplyPositionDataGrid
          expandable={expandableMeta}
          columns={columns}
          data={data}
          onRowClick={handleCollapseRow}
        />
      </Collapse>
    );
  }
);
