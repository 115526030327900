import {
  DataGridColumn,
  H4,
  Icon,
  Inline,
  SmallText,
  Spinner,
  Stack,
  StackProps,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';
import { Dictionary, chain, isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { useListTokens, useListTokensManager } from '../contexts';
import { ListableToken, ListingToken } from '../types';
import { StyledPriceTokenInput, getListingPricesDataGrid } from '../components';

import {
  FixedActionFooter,
  GoBack,
  GoBackProvider,
  NFTThumbnail,
  Select,
  SelectBaseOption
} from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { MAXIMUM_LISTING_DECIMALS } from '@/apps/paraspace/pages/config';
import { durationList } from '@/apps/paraspace/consts/duration';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';

const StyledGoBack = styled(GoBack)`
  top: 2rem;
`;

const StyledDataGrid = getListingPricesDataGrid<ListableToken>();

const StyledSelect = styled(Select)`
  height: 2.5rem;
`;

const DeleteListingIcon = styled(Icon).attrs({
  strokeWidth: 2
})`
  color: ${({ theme }) => theme.skin.grey[500]};
  cursor: pointer;
`;

type PricedToken = {
  tokenId: number;
  symbol: ERC721Symbol;
  price: Maybe<number>;
  duration: number;
};

const Wrapper = styled(Stack)`
  min-height: 25rem;
  padding-bottom: 8rem;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    padding-bottom: 10rem;
  `}
`;

const DEFAULT_DURATION = durationList[1].value;

export const ListPricesSetting: FC<Omit<StackProps, 'children'>> = memo(({ ...props }) => {
  const { nftInfoMap } = useMMProvider();
  const navigate = useNavigate();
  const { selectedListTokens, handleDeleteListToken, loading } = useListTokens();

  const [pricedTokenMap, setPricedTokenMap] = useState<Dictionary<PricedToken>>(
    chain(selectedListTokens)
      .mapValues(value => {
        return { ...value, duration: DEFAULT_DURATION, price: null };
      })
      .mapKeys(value => `${value.symbol}-${value.tokenId}`)
      .value()
  );

  const { listTokens } = useListTokensManager();

  useEffect(() => {
    if (isEmpty(selectedListTokens)) {
      navigate(absoluteRouteNames.SHOP.LIST.index);
    }
  }, [navigate, selectedListTokens]);

  const handleFillPrice = useCallback((token: ListableToken, price: Maybe<number>) => {
    setPricedTokenMap(prev => {
      const key = `${token.symbol}-${token.tokenId}`;
      return { ...prev, [key]: { ...prev[key], price } };
    });
  }, []);

  const handleDurationChange = useCallback((token: ListableToken, value: number) => {
    setPricedTokenMap(prev => {
      const key = `${token.symbol}-${token.tokenId}`;
      return { ...prev, [key]: { ...prev[key], duration: value } };
    });
  }, []);

  const pricedTokens = useMemo(
    () =>
      selectedListTokens.filter(
        ({ symbol, tokenId }) =>
          pricedTokenMap[`${symbol}-${tokenId}`]?.price &&
          pricedTokenMap[`${symbol}-${tokenId}`]?.duration
      ),
    [pricedTokenMap, selectedListTokens]
  );

  const { mobile } = useBreakpoints();

  const columns: DataGridColumn<ListableToken>[] = useMemo(
    () => [
      {
        title: mobile ? null : 'NFTs',
        name: 'NFTs',
        className: mobile ? 'hiddenTitleLine' : '',
        render: ({ data: { tokenId, symbol }, data }) => {
          return (
            <Inline width="100%" justifyContent="space-between">
              <Inline alignItems="center" gap="0.5rem">
                <NFTThumbnail size="small" tokenId={tokenId} symbol={symbol} />
                <Stack gap="0">
                  <Text>{`${nftInfoMap?.[symbol]?.collectionName ?? symbol}`}</Text>
                  <Text>{`#${tokenId}`}</Text>
                </Stack>
              </Inline>
              {mobile && (
                <DeleteListingIcon onClick={() => handleDeleteListToken(data)} name="close" />
              )}
            </Inline>
          );
        }
      },
      {
        title: 'Floor Price',
        name: 'floorPrice',
        render: ({ data: { symbol } }) => (
          <Stack gap="0">
            <Text>{formatNumber(nftInfoMap?.[symbol]?.priceInETH)} ETH</Text>
            <SmallText skin="secondary">
              {formatToCurrency(nftInfoMap?.[symbol]?.priceInUsd)}
            </SmallText>
          </Stack>
        )
      },
      {
        title: 'Price',
        name: 'price',
        render: ({ data, data: { tokenId, symbol } }) => (
          <StyledPriceTokenInput
            value={pricedTokenMap[`${symbol}-${tokenId}`]?.price ?? null}
            token={ERC20Symbol.ETH}
            onChange={(value: Maybe<number>) => {
              handleFillPrice(data, value);
            }}
            placeholder="0"
            decimals={MAXIMUM_LISTING_DECIMALS}
          />
        )
      },
      {
        title: 'Duration',
        name: 'duration',
        width: '9rem',
        render: ({ data, data: { tokenId, symbol } }) => (
          <StyledSelect
            options={durationList}
            value={pricedTokenMap[`${symbol}-${tokenId}`]?.duration ?? DEFAULT_DURATION}
            defaultValue={DEFAULT_DURATION}
            onChange={(option: SelectBaseOption | null) => {
              if (option) {
                handleDurationChange(data, option?.value);
              }
            }}
          />
        )
      },
      {
        title: '',
        width: '5rem',
        name: 'close button',
        hidden: mobile,
        render: ({ data }) => (
          <DeleteListingIcon onClick={() => handleDeleteListToken(data)} name="close" />
        )
      }
    ],
    [
      handleDeleteListToken,
      handleDurationChange,
      handleFillPrice,
      mobile,
      nftInfoMap,
      pricedTokenMap
    ]
  );

  const handleListNow = useCallback(
    () =>
      listTokens(
        selectedListTokens.map(
          ({ symbol, tokenId }) => pricedTokenMap[`${symbol}-${tokenId}`]
        ) as ListingToken[]
      ),
    [listTokens, pricedTokenMap, selectedListTokens]
  );

  return (
    <Wrapper gap="0" {...props}>
      {!mobile && (
        <GoBackProvider value={{ path: absoluteRouteNames.SHOP.LIST.index }}>
          <StyledGoBack />
        </GoBackProvider>
      )}

      <Stack>
        <Stack gap="1rem" alignItems="center" width="100%">
          <H4>Set Listing Prices</H4>
          <Stack alignItems="center" width="100%">
            {loading ? (
              <Inline inset="2rem 0 0">
                <Spinner />
              </Inline>
            ) : (
              <StyledDataGrid columns={columns} data={selectedListTokens} />
            )}
          </Stack>
        </Stack>
        {!loading && (
          <FixedActionFooter
            actionLabel="List Now"
            textLabel="Price Set"
            selectedCount={pricedTokens.length}
            onClick={handleListNow}
            availableCount={selectedListTokens.length}
          />
        )}
      </Stack>
    </Wrapper>
  );
});
