import { H3, Button, Inline, InlineProps } from '@parallel-mono/components';
import { memo, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { useScrollContainer } from '@/apps/paraspace/contexts';

type ActionFooterProps = Omit<InlineProps, 'children'> & {
  selectedCount: number;
  availableCount: number;
  onClick: () => Promise<void> | void;
  actionLabel: string;
  textLabel?: string;
};

const Container = styled(Inline)`
  position: sticky;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid ${({ theme }) => theme.skin.grey[200]};
  background-color: ${({ theme }) => theme.skin.background.main};

  ${({ theme }) => theme.breakpoints.only('mobile')`
    bottom: 0;
  `}
`;

export const FixedActionFooter = memo(
  ({
    selectedCount,
    availableCount,
    onClick,
    actionLabel,
    textLabel,
    ...others
  }: ActionFooterProps) => {
    const [onClicking, setOnClicking] = useState(false);

    const scrollContainer = useScrollContainer();

    const handleButtonClicked = useCallback(() => {
      setOnClicking(true);
      Promise.resolve(onClick()).finally(() => {
        setOnClicking(false);
      });
    }, [onClick]);

    return createPortal(
      <Container inset="1rem" justifyContent="flex-end" alignItems="center" {...others}>
        <H3>
          {selectedCount}/{availableCount} {textLabel ?? 'Selected'}
        </H3>
        <Button
          size="large"
          disabled={onClicking || selectedCount <= 0}
          loading={onClicking}
          onClick={handleButtonClicked}
        >
          {actionLabel}
        </Button>
      </Container>,
      scrollContainer!
    );
  }
);
