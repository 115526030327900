import { useCallback, useEffect, useMemo, useState } from 'react';
import { get, uniqBy } from 'lodash';
import BigNumberJs from 'bignumber.js';

import { DEFAULT_PAGE_LIMIT } from '../constant';
import { useBuyCartProvider } from '../context';

import {
  Marketplace,
  ShopItem,
  ShopItemsInputFilter,
  useGetShopItemsLazyQuery,
  useGetShopItemsOfParaSpaceLazyQuery
} from '@/apps/paraspace/generated/graphql';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { useFormatEntity, useInLiquidationAssets } from '@/apps/paraspace/pages/Shop/hooks';
import { useScrollContainer, useWeb3Context } from '@/apps/paraspace/contexts';
import { useIsTokenOwnerCheck } from '@/apps/paraspace/pages/hooks/useIsTokenOwnerCheck';
import { useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';

type ShopItemQueryParams = {
  contractAddress: string;
  marketplace: Marketplace;
  filter?: ShopItemsInputFilter;
};

export const useShopItems = (queryParams: ShopItemQueryParams) => {
  const { formatListing, formatOrder } = useFormatEntity();
  const getSymbolByContractAddress = useGetSymbolByContractAddress();

  const { liquidationAssets } = useInLiquidationAssets();
  const { isInBuyCart } = useBuyCartProvider();
  const { account } = useWeb3Context();
  const { checkIsTokenOwner } = useIsTokenOwnerCheck();
  const [hasMorePage, setHasMorePage] = useState(true);

  useEffect(() => {
    setHasMorePage(true);
  }, [queryParams]);

  const [, { data: shopItemsRawData, fetchMore: fetchShopItemsMore, client: shopItemClient }] =
    useGetShopItemsLazyQuery({
      variables: {
        ...queryParams,
        pageInfo: {
          limit: DEFAULT_PAGE_LIMIT
        }
      },
      notifyOnNetworkStatusChange: true
    });

  const [
    ,
    {
      data: shopItemsOfParaspaceRawData,
      fetchMore: fetchShopItemsOfParaspaceMore,
      client: shopItemOfParaspaceClient
    }
  ] = useGetShopItemsOfParaSpaceLazyQuery({
    variables: {
      ...queryParams,
      pageInfo: {
        limit: DEFAULT_PAGE_LIMIT,
        offset: 0
      }
    },
    notifyOnNetworkStatusChange: true
  });

  const [rawShopItems, pageInfo, client, refreshHandler] = useMemo(
    () =>
      queryParams.marketplace === Marketplace.ParaSpace
        ? [
            get(shopItemsOfParaspaceRawData, 'shopItemsOfParaSpace')?.items || [],
            null,
            shopItemOfParaspaceClient,
            'getShopItemsOfParaSpace'
          ]
        : [
            get(shopItemsRawData, 'shopItems')?.items || [],
            get(shopItemsRawData, 'shopItems')?.continuation,
            shopItemClient,
            'getShopItems'
          ],
    [
      queryParams.marketplace,
      shopItemClient,
      shopItemOfParaspaceClient,
      shopItemsRawData,
      shopItemsOfParaspaceRawData
    ]
  );

  const enrichedShopItems = useMemo(() => {
    return uniqBy(
      rawShopItems as ShopItem[],
      each => `${each?.contractAddress!}${each?.identifierOrCriteria}`
    ).map(asset => {
      const tokenId = Number(asset?.identifierOrCriteria!);
      const contract = queryParams.contractAddress;
      const symbol = getSymbolByContractAddress(queryParams.contractAddress) as ERC721Symbol;

      const inLiquidation = liquidationAssets.some(
        item => item.contractAddress === contract && item.tokenId === tokenId
      );
      const ownedByCurrentUser =
        checkIsTokenOwner(asset?.contractAddress!, tokenId.toString()) ||
        account === (asset?.ownedBy ?? '');

      const inCart = isInBuyCart(contract, tokenId);

      return {
        contractAddress: contract,
        multiplier: BigNumberJs(asset?.multiplier ?? DEFAULT_MULTIPLIER),
        tokenId,
        symbol,
        inLiquidation,
        ownedByCurrentUser,
        inCart,
        lowestPriceListing: asset.lowestPriceListing
          ? formatListing(asset.lowestPriceListing)
          : null,
        ownedBy: asset.ownedBy!,
        topOffer: asset.topOffer ? formatOrder(asset.topOffer) : null
      };
    });
  }, [
    rawShopItems,
    queryParams.contractAddress,
    getSymbolByContractAddress,
    liquidationAssets,
    checkIsTokenOwner,
    account,
    isInBuyCart,
    formatListing,
    formatOrder
  ]);

  const scrollContainer = useScrollContainer();

  const refreshShopItems = useCallback(async () => {
    await client.clearStore();
    await client.refetchQueries({ include: [refreshHandler] });
    scrollContainer?.scrollTo({ top: 0 });
  }, [client, refreshHandler, scrollContainer]);

  const loadMoreItems = useCallback(
    async (offset: number = 0) => {
      if (queryParams.marketplace === Marketplace.ParaSpace) {
        await fetchShopItemsOfParaspaceMore({
          variables: {
            pageInfo: {
              limit: DEFAULT_PAGE_LIMIT,
              offset
            }
          }
        }).then(result => {
          setHasMorePage(result.data.shopItemsOfParaSpace.items.length >= DEFAULT_PAGE_LIMIT);
        });
      } else {
        await fetchShopItemsMore({
          variables: {
            pageInfo: {
              limit: DEFAULT_PAGE_LIMIT,
              continuation: pageInfo
            }
          }
        }).then(result => setHasMorePage(!!result.data.shopItems.continuation));
      }
    },
    [fetchShopItemsMore, fetchShopItemsOfParaspaceMore, pageInfo, queryParams.marketplace]
  );

  return {
    shopItems: enrichedShopItems,
    refreshShopItems,
    client,
    hasMorePage,
    loadMoreItems
  };
};
