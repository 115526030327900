import { EIP6963AnnounceProviderEvent, EIP6963Event, EIP6963ProviderDetail } from './types';
import { isEIP6963ProviderDetail } from './utils';

type MutableInjectedProviderMap = Map<string, EIP6963ProviderDetail>;
type InjectedProviderMap = ReadonlyMap<string, EIP6963ProviderDetail>;

class EIP6963ProviderManager {
  public listeners = new Set<() => void>();

  private providerMap: MutableInjectedProviderMap = new Map();

  private providerList: EIP6963ProviderDetail[] = [];

  constructor() {
    window.addEventListener(
      EIP6963Event.ANNOUNCE_PROVIDER,
      // eslint-disable-next-line no-undef
      this.onAnnounceProvider.bind(this) as EventListener
    );
    window.dispatchEvent(new Event(EIP6963Event.REQUEST_PROVIDER));
  }

  private onAnnounceProvider(event: EIP6963AnnounceProviderEvent) {
    if (!isEIP6963ProviderDetail(event.detail)) return;

    const { detail } = event;

    // Ignore duplicate announcements if we've already stored a provider detail for the given rdns
    if (this.providerMap.get(detail.info.rdns)) {
      if (this.providerMap.get(detail.info.rdns)?.provider !== detail.provider) {
        console.warn(
          `Duplicate provider announcement with different provider for injected wallet with rdns: ${detail.info.rdns}`
        );
      }
      return;
    }

    this.providerMap.set(detail.info.rdns, detail);
    this.providerList = [...this.providerList, detail]; // re-create array to trigger re-render from useInjectedProviderDetails
    this.listeners.forEach(listener => listener());
  }

  public get map(): InjectedProviderMap {
    return this.providerMap;
  }

  public get list(): readonly EIP6963ProviderDetail[] {
    return this.providerList;
  }
}

export const EIP6963_PROVIDER_MANAGER = new EIP6963ProviderManager();
