import { H3, Stack, StackProps } from '@parallel-mono/components';
import { FC } from 'react';

import { Handlers } from '..';

import ERC20Card from './ERC20Card';

import { ERC20TableRow } from '@/apps/paraspace/pages/Credit/MoneyMarket/ERC20Table/types';

export type CardForMobileProps = Omit<StackProps, 'children'> & {
  tokens: ERC20TableRow[];
  handlers: Handlers;
};

const CardForMobile: FC<CardForMobileProps> = ({ tokens, handlers, ...others }) => {
  return (
    <Stack gap="1rem" {...others}>
      <H3>ERC-20 Tokens</H3>
      {tokens.map((token, index) => (
        <ERC20Card key={token.symbol + index} data={token} handlers={handlers} />
      ))}
    </Stack>
  );
};

export default CardForMobile;
