import { uniqueId } from 'lodash';
import { memo, SVGAttributes, useMemo } from 'react';
import styled from 'styled-components';

export type PesudoProfilePictureProps = {
  colors?: [string, string, string];
  angle?: number;
} & SVGAttributes<SVGElement>;

export const PesudoProfilePicture = memo((props: PesudoProfilePictureProps) => {
  const {
    colors: [c1 = 'red', c2 = 'green', c3 = 'blue'] = ['red', 'green', 'blue'],
    angle = 0,
    ...others
  } = props;

  const patternIds = useMemo(
    () => [`pattern-${uniqueId()}`, `pattern-${uniqueId()}`, `pattern-${uniqueId()}`],
    []
  );

  const randomPickedPattern = useMemo(() => Math.floor(Math.random() * 3), []);
  return (
    <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg" {...others}>
      <defs>
        <pattern
          id={patternIds[0]}
          patternUnits="userSpaceOnUse"
          width="40"
          height="40"
          patternTransform={`scale(2) rotate(${angle})`}
        >
          <rect x="0" y="0" width="100%" height="100%" fill={c1} />
          <path d="M20 0L0 20h20V0zm0 20v20l20-20H20z" strokeWidth="1" stroke="none" fill={c2} />
          <path d="M20 0l20 20V0zM0 20v20h20z" strokeWidth="1" stroke="none" fill={c3} />
        </pattern>
        <pattern
          id={patternIds[1]}
          patternUnits="userSpaceOnUse"
          width="20"
          height="40"
          patternTransform={`scale(2) rotate(${angle})`}
        >
          <rect x="0" y="0" width="100%" height="100%" fill={c1} />
          <path
            d="M10-10V0l10 10V0L10-10zm0 10L0 10v10l10-10V0zm0 30v10l10 10V40L10 30z"
            strokeWidth="1"
            stroke="none"
            fill={c2}
          />
          <path
            d="M10 10l10 10v10L10 20zm0 10L0 30v10l10-10z"
            strokeWidth="1"
            stroke="none"
            fill={c3}
          />
        </pattern>
        <pattern
          id={patternIds[2]}
          patternUnits="userSpaceOnUse"
          width="30"
          height="60"
          patternTransform="scale(2) rotate(0)"
        >
          <rect x="0" y="0" width="100%" height="100%" fill={c1} />
          <path
            d="M9.27 0 0 6.48v23.49l15 10V60h5.16L30 53.46V29.97L15 19.96V0Zm5.83 0L30 9.9V6.48L20.26 0ZM15 23.4l9.9 6.57-9.9 6.58-9.9-6.58ZM0 50.1v3.36l9.22 6.48.1.06h5.6l-.1-.06z"
            strokeWidth="1"
            stroke="none"
            fill={c2}
          />
          <path
            d="M0 0v3.4L5 0zm24.48 0L30 3.4V0zM15 26.2l-5.68 3.77L15 33.73l5.68-3.76Zm15 30.2L24.48 60H30Zm-30 0V60h5z"
            strokeWidth="1"
            stroke="none"
            fill={c3}
          />
        </pattern>
      </defs>
      <rect
        width="800%"
        height="800%"
        transform="translate(0,0)"
        fill={`url(#${patternIds[randomPickedPattern]})`}
      />
    </svg>
  );
});

const randomInt = (max: number) => Math.ceil(Math.random() * max);
const profileAngle = randomInt(360);
const profileColors: [string, string, string] = [
  `rgb(${randomInt(255)},${randomInt(255)},${randomInt(255)})`,
  `rgb(${randomInt(255)},${randomInt(255)},${randomInt(255)})`,
  `rgb(${randomInt(255)},${randomInt(255)},${randomInt(255)})`
];

export const RoundProfilePicture = styled(PesudoProfilePicture).attrs({
  angle: profileAngle,
  colors: profileColors
})<{ width: string; height: string }>`
  width: ${({ width }) => width ?? '2rem'};
  height: ${({ height }) => height ?? '2rem'};
  border-radius: 50%;
`;
