import { useCallback, useMemo } from 'react';
import { WalletBalanceProvider } from 'paraspace-utilities-contract-helpers';
import BigNumber from 'bignumber.js';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

const useUserWalletBalance = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();
  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new WalletBalanceProvider({
      walletBalanceProviderAddress: contracts.WalletBalanceProvider,
      provider
    });
  }, [contracts.WalletBalanceProvider, provider]);

  const getERC20BalanceByAddress = useCallback(
    async (assets: string[]) => {
      if (!provider || !service || !account) {
        return null;
      }
      const balances = await service.batchBalanceOf([account], assets);

      return balances.map(item => new BigNumber(item.toString()));
    },
    [account, service, provider]
  );

  return {
    getERC20BalanceByAddress
  };
};

export default useUserWalletBalance;
