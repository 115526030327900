import { useMemo } from 'react';

import { formatApeCoinListing, formatApeListing } from '../../../hooks';
import { MatchPair } from '../types';
import { ApeListing, BakcListing } from '../../../types';

import { useGetP2PApeStakingMatchesQuery } from '@/apps/paraspace/generated/graphql';
import { useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';

export const PAGE_SIZE = 6;

export const useActiveMatches = (page: number = 1) => {
  const { data, loading, refetch } = useGetP2PApeStakingMatchesQuery({
    variables: {
      pageInfo: {
        offset: Math.max(0, (page - 1) * PAGE_SIZE),
        limit: PAGE_SIZE
      }
    }
  });

  const getSymbolByContractAddress = useGetSymbolByContractAddress();

  const activeMatches: { totalCount: number; nodes: MatchPair[] } = useMemo(
    () => ({
      totalCount: data?.P2PApeStakingMatches.totalCount ?? 0,
      nodes: data?.P2PApeStakingMatches
        ? data?.P2PApeStakingMatches.nodes.map(item => ({
            matchedHash: item.matchedHash,
            apeListing: formatApeListing(item.apeListing, getSymbolByContractAddress) as ApeListing,
            apeCoinListing: formatApeCoinListing(item.apeCoinListing),
            bakcListing: item.bakcListing
              ? (formatApeListing(item.bakcListing, getSymbolByContractAddress) as BakcListing)
              : null
          }))
        : []
    }),
    [data?.P2PApeStakingMatches, getSymbolByContractAddress]
  );

  return { loading, refetch, data: activeMatches };
};
