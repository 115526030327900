import { FC, useState } from 'react';
import { OrderWithCounter } from 'paraspace-seaport-js/lib/types';
import { H5, Spinner, Stack, Stepper, Text, Image } from '@parallel-mono/components';
import styled from 'styled-components';

import { OfferFormValue } from '..';
import { MakeOfferModalPayload } from '../../types';

import Approve from './Approve';
import MakeOffer from './MakeOffer';
import BorrowingFromPool from './BorrowingFromPool';

import metamask from '@/apps/paraspace/assets/meta-mask.svg';

export type Payload = MakeOfferModalPayload;

type MakeOfferProcessingModalProps = {
  offerFormValue: OfferFormValue;
  onError?: (e: any) => void;
  onFinish?: () => void;
  payload: Payload;
};

export type TransmitData = {
  order?: OrderWithCounter;
};

const StyledSpinner = styled(Spinner)`
  margin-bottom: 1rem;
`;

export const ApproveWallet = () => (
  <Stack alignItems="center" gap="1rem">
    <Image src={metamask} width="2rem" height="2rem" />
    <H5>Please approve on your wallet.</H5>
  </Stack>
);

export const Loading = ({ tip }: { tip: string }) => {
  return (
    <Stack gap="0.25rem" alignItems="center">
      <StyledSpinner size="large" />
      <H5>{tip}</H5>
      <Text skin="secondary">This may take up to 20s. Please do not close the modal.</Text>
    </Stack>
  );
};

export const MakeOfferProcessingModal: FC<MakeOfferProcessingModalProps> = props => {
  const [step, setStep] = useState(0);
  const [transmitData, setTransmitData] = useState<TransmitData>({});
  const { onFinish, ...rest } = props;
  const { token } = rest.offerFormValue;

  const steps = [
    {
      title: 'Step 1',
      description: 'Approve',
      content: <Approve {...rest} onFinish={() => setStep(1)} />
    },
    {
      title: 'Step 2',
      description: 'Create Bids',
      content: (
        <MakeOffer
          {...rest}
          transmitData={transmitData}
          onFinish={value => {
            setTransmitData(value);
            setStep(2);
          }}
        />
      )
    },
    {
      title: 'Step 3',
      description: token.borrowAmount ? 'Borrowing from pool' : 'Giving our pool a grant',
      content: <BorrowingFromPool {...rest} transmitData={transmitData} onFinish={onFinish} />
    }
  ];

  return (
    <Stack gap="2.5rem">
      <Stepper steps={steps} step={step} />
      {steps[step].content}
    </Stack>
  );
};
