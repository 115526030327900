import { useMemo } from 'react';
import styled from 'styled-components';
import { Container, H5, SmallText } from '@parallel-mono/components';
import BigNumberJs from 'bignumber.js';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { BorrowLimitBar } from '@/apps/paraspace/components';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { calculateBorrowLimitPoint } from '@/apps/paraspace/pages/hooks/useLendingSimulation/calculateBorrowLimitPoint';
import useSuppliedAssets from '@/apps/paraspace/pages/hooks/useLendingSimulation/useSuppliedAssets';

type LiquidityBorrowContainerProps = {
  tokenId: string;
  symbol: string;
  futureLiquidityUsdValue: BigNumberJs;
};

const BorrowedContainer = styled(Container).attrs({
  border: true
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
`;

const BorrowedLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const BorrowedValue = styled(H5)`
  margin-left: 0.25rem;
`;

const LiquidityBorrowLimitBar = ({
  tokenId,
  symbol,
  futureLiquidityUsdValue
}: LiquidityBorrowContainerProps) => {
  const {
    overviewUserInfo: { totalBorrowedPositionInUsd }
  } = useMMProvider();

  const currentAllAssets = useSuppliedAssets();
  const futureAssets = useMemo(
    () =>
      currentAllAssets.map(asset => {
        if (asset.id === `${symbol}-${tokenId}`) {
          return {
            ...asset,
            value: futureLiquidityUsdValue
          };
        }
        return asset;
      }),
    [currentAllAssets, symbol, tokenId, futureLiquidityUsdValue]
  );

  const { borrowLimit, totalCollateral, liquidationPoint } = useMemo(
    () => calculateBorrowLimitPoint(futureAssets),
    [futureAssets]
  );

  return (
    <BorrowedContainer>
      <BorrowedLabelContainer>
        <SmallText skin="secondary">Borrowing:</SmallText>
        <BorrowedValue>{formatToCurrency(totalBorrowedPositionInUsd.toNumber())}</BorrowedValue>
      </BorrowedLabelContainer>
      <BorrowLimitBar
        borrowedValue={totalBorrowedPositionInUsd}
        borrowLimitValue={borrowLimit}
        liquidationPointValue={liquidationPoint}
        totalSuppliedValue={totalCollateral}
      />
    </BorrowedContainer>
  );
};

export default LiquidityBorrowLimitBar;
