import styled from 'styled-components';
import { Stack, StackProps } from '@parallel-mono/components';
import { FC } from 'react';
import { Environment } from 'parax-sdk';

import { PlaceholderInline } from '../../../../components';

import MintLink from './MintLink';
import StakingRewardPanel from './StakingRewardsPanel';
import BorrowedPanel from './BorrowedPanel';
import { AutoSellPanel } from './AutoSellPanel';
import SecurityLink from './SecurityLink';
import RawReStakePanel from './ReStakePanel';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ApeCoinBalancePanel } from '@/apps/paraspace/pages/ApePairing/components/ApeBalancePanel';
import { useWeb3Context } from '@/apps/paraspace/contexts';

const PanelCardWrapper = styled(Stack)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
    overflow-x: scroll;
    flex-direction: row;
    > * + * {
      margin-top: 0;
      margin-left: 1rem;
    }
  `};
  ${({ theme }) => theme.breakpoints.only('tablet')`
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    > * {
      margin: 0 0 1rem 0;
    }
  `};
`;

const MessageWrapper = styled(Stack)`
  justify-content: center;
  ${({ theme }) => theme.breakpoints.only('tablet')`
    width: calc(50% - 0.5rem);
    > * + * {
      margin-top: 0.5rem;
    }
  `};
  ${({ theme }) => theme.breakpoints.only('mobile')`
    display: none;
  `};
`;

export type SideBarContainerProps = Omit<StackProps, 'children'>;

export const SideBar: FC<SideBarContainerProps> = props => {
  const { env } = useWeb3Context();
  const { basicInfoLoaded } = useMMProvider();

  if (!basicInfoLoaded) return <PlaceholderInline />;

  return (
    <Stack gap="1rem" {...props}>
      <ApeCoinBalancePanel />
      <PanelCardWrapper gap="1rem">
        <RawReStakePanel />
        <StakingRewardPanel />
        <BorrowedPanel />
        <AutoSellPanel />
        <MessageWrapper>{env !== Environment.PRODUCTION && <MintLink />}</MessageWrapper>
        <SecurityLink />
      </PanelCardWrapper>
    </Stack>
  );
};
