import { FC, memo, useMemo } from 'react';
import {
  Button,
  CardProps,
  DataGridColumn,
  Inline,
  SmallText,
  Stack,
  Tag,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { formatNumber } from '@parallel-mono/utils';

import TimelockQueueHeader from './TimelockQueueHeader';
import { TimelockQueueRow, AssetType } from './hooks';
import { TimelockQueueState } from './util';

import { Collapse, Link, DataGrid, StackedIcons, Tooltip } from '@/apps/paraspace/components';
import { truncateTextMid } from '@/apps/paraspace/utils/format';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { useGetSymbolByContractAddress, useNetworkConfig } from '@/apps/paraspace/hooks';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useConvertSymbolWithNetwork } from '@/apps/paraspace/pages/hooks';
import { useTimelockClaimable } from '@/apps/paraspace/pages/hooks/Timelock/useTimelockClaimable';

export type TimelockQueueTableProps = Omit<CardProps, 'children'> & {
  queue: TimelockQueueRow[];
  locked: BigNumber;
  released: BigNumber;
  claimHandle: (data: TimelockQueueRow) => void;
};

const TimelockQueueTable: FC<TimelockQueueTableProps> = memo(
  ({ queue, released, locked, claimHandle, ...others }) => {
    const { autoClaimEnabled } = useTimelockClaimable();
    const {
      explorerLink: [explorerLinkBaseUrl]
    } = useNetworkConfig();

    const getSymbolByContractAddress = useGetSymbolByContractAddress();
    const { convertWTokenToNativeToken } = useConvertSymbolWithNetwork();
    const { mobile } = useBreakpoints();

    const columns: DataGridColumn<TimelockQueueRow>[] = useMemo(
      () => [
        {
          name: 'timeAdded',
          title: 'Time Added',
          render: ({ data: { timeAdded } }) => (
            <Stack gap="0">
              <Text>{dayjs(timeAdded).utc().format('MMM D, YYYY')}</Text>
              <SmallText skin="secondary">
                {dayjs(timeAdded).utc().format('HH:mm:ss')} UTC
              </SmallText>
            </Stack>
          )
        },
        {
          name: 'type',
          title: 'Type',
          render: ({ data: { actionTypeName } }) => <Text>{actionTypeName}</Text>
        },
        {
          name: 'value',
          title: 'Value',
          render: ({ data: { assetInfo } }) => {
            const symbol = getSymbolByContractAddress(assetInfo.token);

            if (assetInfo.type === AssetType.ERC20) {
              return (
                <Inline gap="0.25rem" alignItems="center">
                  {symbol && (
                    <CryptoIcon
                      size="xsmall"
                      symbol={convertWTokenToNativeToken(symbol as ERC20Symbol)}
                    />
                  )}
                  <Text>
                    {formatNumber(BigNumber(assetInfo.amount ?? 0), {
                      decimal: MAXIMUM_BALANCE_DECIMALS
                    })}
                  </Text>
                </Inline>
              );
            }

            const assetList = assetInfo.tokenIds!.map(id => ({
              symbol: symbol as ERC721Symbol,
              tokenId: id
            }));
            return <StackedIcons assets={assetList} variant="list" size="small" />;
          }
        },
        {
          name: 'transaction',
          title: 'Transaction',
          render: ({ data: { transaction } }) => (
            <Link href={`${explorerLinkBaseUrl}/tx/${transaction}`} target="_blank">
              {truncateTextMid(transaction, 4, 4)}
            </Link>
          )
        },
        {
          name: 'expectedRelease',
          title: (
            <Inline gap="0.25rem">
              <Text>Expected Release</Text>
              {!autoClaimEnabled && (
                <Tooltip content="Due to a zkSync timestamp update issue, there will be a 1~2 minutes time delay for claiming from Timelock." />
              )}
            </Inline>
          ),
          render: ({ data: { state, expectedRelease } }) => {
            if (state === TimelockQueueState.FREEZED) {
              return (
                <Tag skin="error" size="small">
                  Withheld
                </Tag>
              );
            }
            if (state === TimelockQueueState.RELEASED) {
              return (
                <Tag skin="success" size="small">
                  Released
                </Tag>
              );
            }
            return <Text>{dayjs(expectedRelease).fromNow()}</Text>;
          }
        },
        {
          name: 'claim',
          title: '',
          justifyContent: 'flex-end',
          render: ({ data: { state, claiming }, data }) => {
            if (state === TimelockQueueState.FREEZED) return null;

            return (
              <Inline gap="1rem" alignItems="center">
                <Button
                  block={mobile}
                  loading={claiming}
                  disabled={state !== TimelockQueueState.RELEASED || claiming}
                  onClick={() => claimHandle(data)}
                >
                  Claim
                </Button>
              </Inline>
            );
          }
        }
      ],
      [
        autoClaimEnabled,
        getSymbolByContractAddress,
        convertWTokenToNativeToken,
        explorerLinkBaseUrl,
        mobile,
        claimHandle
      ]
    );

    return (
      <Collapse
        border
        header={<TimelockQueueHeader locked={locked} released={released} />}
        defaultOpen
        {...others}
      >
        <DataGrid columns={columns} data={queue} />
      </Collapse>
    );
  }
);

export default TimelockQueueTable;
