import { Card, ColorMode, Inline, Stack } from '@parallel-mono/components';
import styled, { DefaultTheme, css } from 'styled-components';

import { Link } from '../Link';

export const BANNER_SCROLLING_THRESHOLD = 40; // px;

export const GradientIconWrapper = styled(Inline).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})`
  background-color: ${({ theme }) => theme.skin.primary.main};
  padding: 0.75rem;
  border-radius: 50%;
  color: ${({ theme }) => theme.skin.primary.contrastText};

  svg,
  img {
    width: 2.5rem;
    height: 2.5rem;
  }

  ${({ theme }) => theme.breakpoints.not('desktop')`
    svg, img {
      width: 2rem;
      height: 2rem;
    }
  `}

  &.shrinking {
    padding: 0.5rem;
    svg,
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

export const GridBackground = css`
  overflow: hidden;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    content: '';
    background-size: ${({ theme }: { theme: DefaultTheme }) =>
      `${theme.spacing(6)} ${theme.spacing(6)}`};
    background-image: ${({ theme }: { theme: DefaultTheme }) => `
      linear-gradient(to right, ${theme.skin.grey[200]} 1px,
        transparent 1px),
      linear-gradient(to bottom, ${theme.skin.grey[200]} 1px,
        transparent 1px)
      `};
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(
      180deg,
      ${({ theme }) =>
          theme.mode === ColorMode.dark ? 'rgba(47, 47, 47, 0)' : 'rgba(255, 255, 255, 0)'}
        0%,
      ${({ theme }) =>
          theme.mode === ColorMode.dark ? 'rgba(47, 47, 47, 0.35)' : 'rgba(255, 255, 255, 0.35)'}
        46.24%,
      ${({ theme }) =>
          theme.mode === ColorMode.dark ? 'rgba(47, 47, 47, 1)' : 'rgba(255, 255, 255, 1)'}
        100%
    );
  }
`;

export const BANNER_HEIGHT = '8rem';
export const SHRINKING_BANNER_HEIGHT = '4rem';

export const Wrapper = styled.div<{ shrinking: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  ${({ theme, shrinking }) => theme.breakpoints.not('desktop')`
    padding-bottom:${shrinking ? '7.5rem' : '13.5rem'};
    transition: all 0.2s ease;
  `}

  ${({ theme, shrinking }) => theme.breakpoints.only('tablet')`
    padding-bottom:${shrinking ? '7.5rem' : '11rem'};
  `}
  padding-bottom: ${({ shrinking }) => (shrinking ? '7.5rem' : '7.5rem')};
`;

export const BannerContainer = styled(Inline)`
  ${({ theme }) => theme.breakpoints.not('desktop')`
    padding-top: 0;
  `}
  position: absolute;
  height: 100%;
  &.shrinking {
    padding-top: ${SHRINKING_BANNER_HEIGHT};
  }
  pointer-events: none;
`;

export const BannerCard = styled(Card)`
  position: absolute;
  top: 0;
  z-index: 98;
  border-top: none;
  pointer-events: all;
  width: 100%;
  height: ${BANNER_HEIGHT};
  max-width: var(--max-app-width-pc);

  transition: padding 0.2s ease;

  ${({ theme }) => theme.breakpoints.not('desktop')`
    position: relative;
    height: fit-content;
    &.shrinking {
      left: 0;
      right: 0;
    }
  `}

  /* ${GridBackground}; */

  &.shrinking {
    position: sticky;
    top: 0;
    height: ${SHRINKING_BANNER_HEIGHT};
    padding: 0.75rem;
    background-color: ${({ theme }) => theme.skin.background.main};
    &::before {
      background-image: none;
    }
    ${({ theme }) => theme.breakpoints.not('desktop')`
      top: 0;
    `}
  }
`;

export const ValueListWrapper = styled(Inline)`
  ${({ theme }) => theme.breakpoints.not('desktop')`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    > * + * {
      margin-left: 0;
    }

    &.shrinking {
      display: none;
    }
  `}
  ${({ theme }) => theme.breakpoints.only('tablet')`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`;

export const BannerTitle = styled(Stack)`
  h2,
  p {
    transition: all 0.2s ease;
  }

  ${({ theme }) => theme.breakpoints.not('desktop')`
    align-items: flex-start;
    h2 {
      ${theme.typography.header3};
    }
    p {
      ${theme.typography.bodySmall}
    }
  `}
  &.shrinking {
    h2 {
      ${({ theme }) => theme.typography.header4};
    }
  }
`;

export const ValueItem = styled(Stack)`
  h2,
  p {
    transition: all 0.2s ease;
  }
  ${({ theme }) => theme.breakpoints.not('desktop')`
    align-items: flex-start;
    h2 {
      ${theme.typography.header4};
    }
    p {
      ${theme.typography.bodySmall}
    }
  `}
  &.shrinking {
    flex-direction: row;
    > * + * {
      margin-left: 0.5rem;
    }
    h2 {
      ${({ theme }) => theme.typography.header5};
    }
    p {
      ${({ theme }) => theme.typography.bodySmall};
    }
  }
`;

export const ExternalLink = styled(Link)`
  display: flex;
`;
