import { memo, useEffect, useMemo } from 'react';
import {
  H2,
  H4,
  Inline,
  InlineProps,
  Stack,
  StackProps,
  Tag,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import styled from 'styled-components';
import BigNumberJS from 'bignumber.js';

import { ApeStakingTokenSymbol } from '@/apps/paraspace/typings';
import { Collapse, CollapseProps, LinkButton } from '@/apps/paraspace/components';
import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';
import { useOwnerApeStakingData } from '@/apps/paraspace/pages/hooks/ApeStaking/useOwnerApeStakingData';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { zero } from '@/apps/paraspace/consts/values';
import { useIsTokenSupplierCheck } from '@/apps/paraspace/pages/hooks/useIsTokenSupplierCheck';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useContractAddressBySymbol } from '@/apps/paraspace/hooks';

export type StakingInfoProps = Omit<CollapseProps, 'children' | 'header'> & {
  symbol: ApeStakingTokenSymbol;
  tokenId: number;
  ownedBy?: Maybe<string>;
  isRefresh?: boolean;
};

type CollapseHeaderProps = Omit<InlineProps, 'children'> & {
  apy?: BigNumberJS;
};

const CollapseHeader = memo(({ apy, ...others }: CollapseHeaderProps) => {
  return (
    <Inline gap="0.75rem" {...others}>
      <H4>Ape Staking</H4>
      <Tag size="small" skin="success">
        {formatToPercentage(apy ?? zero)} APY
      </Tag>
    </Inline>
  );
});

type InfoBlockProps = {
  title: string;
  value: BigNumberJS;
} & Omit<StackProps, 'children'>;

const GreyH2 = styled(H2)`
  color: ${({ theme }) => theme.skin.grey[500]};
`;

const InfoBlock = ({ title, value, ...others }: InfoBlockProps) => (
  <Stack gap="0" {...others}>
    <Text skin="secondary" fontWeight="bold">
      {title}
    </Text>
    <Inline gap="0.25rem">
      <H2 fontWeight="bold">{formatBalance(value)}</H2>
      <GreyH2 fontWeight="bold">APE</GreyH2>
    </Inline>
  </Stack>
);

const Content = styled(Inline)`
  padding: 2rem 0 0.625rem;
`;

export const ApeStakingInfo = memo((props: StakingInfoProps) => {
  const { symbol, tokenId, isRefresh = false, ownedBy, ...others } = props;

  const { nftInfoMap } = useMMProvider();
  const { checkIfTokenInPairing } = useP2PInfo();

  const isTokenInPairing = checkIfTokenInPairing(symbol, tokenId);
  const { ownerApeStaking, refresh } = useOwnerApeStakingData({ symbol, tokenId });
  const { apeStakingPoolSummary } = useAutoCompoundApeInfo();
  const { apy, compoundApy } = apeStakingPoolSummary?.[symbol] || {};
  const { checkIsTokenSupplier } = useIsTokenSupplierCheck();

  const { mobile } = useBreakpoints();

  const contractAddress = useContractAddressBySymbol(symbol);
  const isSupplier = checkIsTokenSupplier(contractAddress, tokenId.toString());

  // no account-related variable, it checks if the nft is supplied to our platform
  const isSuppliedToParaSpace = useMemo(() => {
    return nftInfoMap[symbol]?.xTokenAddress === ownedBy;
  }, [nftInfoMap, ownedBy, symbol]);

  const collapseHeader = useMemo(() => {
    return <CollapseHeader apy={isSuppliedToParaSpace ? compoundApy : apy} />;
  }, [apy, compoundApy, isSuppliedToParaSpace]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  if (!ownerApeStaking) {
    return null;
  }

  return (
    <Collapse defaultOpen header={collapseHeader} border {...others}>
      <Content alignItems="flex-start">
        <CryptoIcon size={mobile ? '3rem' : '5.5rem'} symbol="APE" />
        <Stack width="100%">
          <Inline gap="0" justifyContent="space-evenly">
            <InfoBlock width="100%" title="Staked" value={ownerApeStaking.stakedAmount} />
            <InfoBlock width="100%" title="Rewards" value={ownerApeStaking.pendingRewards} />
          </Inline>
          {isSupplier && (
            <Inline grow>
              <LinkButton
                to={
                  isTokenInPairing
                    ? absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS.MY_POSITION
                    : absoluteRouteNames.APE_STAKING.NFT_POOLS
                }
              >
                Manage Staking
              </LinkButton>
            </Inline>
          )}
        </Stack>
      </Content>
    </Collapse>
  );
});
