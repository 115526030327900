import { memo, useMemo } from 'react';
import styled from 'styled-components';

import { LIQUIDATION_SUPPORTED_COLLECTIONS } from './configs';

import {
  NFTCollectionThumbnail,
  MultipleSelect,
  MultipleSelectProps
} from '@/apps/paraspace/components';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';

export interface CollectionSelectProps extends Omit<MultipleSelectProps, 'options'> {}

const StyledMultipleSelect = styled(MultipleSelect)`
  height: 2.5rem;
`;

export const CollectionSelect = memo((props: CollectionSelectProps) => {
  const { erc721Config } = useAppConfig();

  const { chainId } = useWeb3Context();

  const options = useMemo(
    () =>
      LIQUIDATION_SUPPORTED_COLLECTIONS[chainId].map(symbol => ({
        icon: <NFTCollectionThumbnail symbol={symbol} size="small" round />,
        label: erc721Config[symbol].collectionName,
        value: symbol
      })),
    [chainId, erc721Config]
  );
  return (
    <StyledMultipleSelect
      menuAlign="left"
      placeholder="Filter Collections"
      options={options}
      {...props}
    />
  );
});
