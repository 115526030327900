import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { floor, isEmpty, isNil } from 'lodash';
import { Button, H5, Inline, SmallText, Text, Stack } from '@parallel-mono/components';
import BigNumberJs, { BigNumber } from 'bignumber.js';
import {
  InfoPanel,
  SelectableTokenValue,
  SelectableToken
} from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import {
  formatToCurrency,
  MIN_BALANCE_THRESHOLD,
  formatBalance
} from '@/apps/paraspace/utils/format';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  HEALTHY_FACTOR_WITH_CUSHION_FOR_WITHDRAW,
  MAXIMUM_BALANCE_DECIMALS,
  MINIMUM_ACCOUNTABLE_NUM
} from '@/apps/paraspace/pages/config';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import {
  TimelockTerms,
  useTimelockTermsCheck,
  NumberRange,
  SelectableTokenInput,
  BorrowLimitBar
} from '@/apps/paraspace/components';
import { useExpandableTokens } from '@/apps/paraspace/pages/Credit/hooks';

const BorrowedContainer = styled(Stack)`
  box-sizing: border-box;
  border: 0.0625rem solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 0.5rem;
`;

type Props = {
  data: { symbol: ERC20Symbol };
  onSubmit: (data: { amount: BigNumber; symbol: ERC20Symbol }) => void;
  onChange: (symbol: ERC20Symbol) => void;
};
/* eslint max-lines-per-function: ["error", { "max": 275 }] */
export const WithdrawERC20Form = memo(({ data: { symbol }, onSubmit, onChange }: Props) => {
  const [curSymbol, setCurSymbol] = useState<ERC20Symbol>(symbol);
  const {
    erc20InfoMap,
    overviewUserInfo: { totalBorrowedPositionInUsd, borrowLimitInUsd, liquidationThresholdInUsd }
  } = useMMProvider();
  const {
    displayName,
    suppliedAmount = new BigNumberJs(0),
    priceInUsd,
    usageAsCollateralEnabledOnUser,
    baseLTVasCollateral,
    reserveLiquidationThreshold,
    availableLiquidity,
    balance
  } = erc20InfoMap[curSymbol];
  const [amount, setAmount] = useState<number | null>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const { claimableTime, timelockTermsChecked, handleTimelockTermsCheck } = useTimelockTermsCheck(
    amount ?? 0,
    curSymbol
  );
  const availableToWithdraw = useMemo(() => {
    // liquidiationPoint - x * threshold * priceInUsd >= borrowedUsd * 1.01
    return BigNumberJs.maximum(
      0,
      BigNumberJs.minimum(
        suppliedAmount,
        usageAsCollateralEnabledOnUser && totalBorrowedPositionInUsd.gt(0)
          ? liquidationThresholdInUsd
              .minus(totalBorrowedPositionInUsd.times(HEALTHY_FACTOR_WITH_CUSHION_FOR_WITHDRAW))
              .div(priceInUsd)
              .div(reserveLiquidationThreshold)
          : suppliedAmount,
        availableLiquidity
      )
    );
  }, [
    availableLiquidity,
    liquidationThresholdInUsd,
    reserveLiquidationThreshold,
    suppliedAmount,
    totalBorrowedPositionInUsd,
    usageAsCollateralEnabledOnUser,
    priceInUsd
  ]);

  const remainingSupplied = useMemo(() => {
    if (errorMsg || !amount) return suppliedAmount;
    const result = suppliedAmount.minus(amount);
    return result.gte(MINIMUM_ACCOUNTABLE_NUM) ? result : new BigNumberJs(0);
  }, [amount, errorMsg, suppliedAmount]);

  const {
    borrowLimitInUsd: newBorrowLimitInUsd,
    liquidationThresholdInUsd: newLiquidationThresholdInUsd,
    totalCollateralPositionInUsd: newTotalCollateralPositionInUsd
  } = useLendingSimulation([
    {
      type: ActionTypeEnum.WITHDRAW,
      targetAsset: {
        id: `${symbol}`,
        value:
          usageAsCollateralEnabledOnUser && Number.isFinite(amount)
            ? priceInUsd.times(amount!)
            : new BigNumberJs(0),
        LTV: baseLTVasCollateral,
        reserveLiquidationThreshold
      }
    }
  ]);

  const isDisableWithdraw = useMemo(
    () => Boolean(errorMsg) || !amount || !timelockTermsChecked,
    [amount, errorMsg, timelockTermsChecked]
  );

  const validate = useCallback(() => {
    if (amount === null) {
      setErrorMsg('');
    } else if (!Number.isFinite(amount) || amount <= 0) {
      setErrorMsg('Invalid input.');
    } else if (availableToWithdraw.lt(amount)) {
      setErrorMsg('Exceed eligible withdraw amount.');
    } else if (availableLiquidity.lt(amount)) {
      setErrorMsg(
        `Withdraw amount exceeds market liquidity, please enter an amount below ${formatNumber(
          availableLiquidity,
          { decimal: MAXIMUM_BALANCE_DECIMALS, threshold: { min: MIN_BALANCE_THRESHOLD } }
        )} ${displayName}.`
      );
    } else {
      setErrorMsg('');
    }
  }, [amount, availableLiquidity, availableToWithdraw, displayName]);

  useEffect(() => {
    validate();
  }, [validate]);

  const handleWithdraw = useCallback(async () => {
    if (!isNil(amount) && amount > 0) {
      onSubmit({
        amount: BigNumber(amount),
        symbol: curSymbol
      });
    }
  }, [amount, curSymbol, onSubmit]);

  const curSelectedTokenInfo = useMemo(() => {
    return {
      token: {
        name: displayName,
        symbol: curSymbol,
        balance: balance?.toNumber() || 0,
        priceInUSD: priceInUsd.toNumber(),
        displayBalance: formatBalance(balance ?? 0)
      },
      amount
    };
  }, [amount, balance, curSymbol, displayName, priceInUsd]);

  const expandableTokenSymbols = useExpandableTokens(symbol);

  const tokensInfo = useMemo(() => {
    if (!isEmpty(expandableTokenSymbols)) {
      return expandableTokenSymbols.map(item => {
        const tokenInfo = erc20InfoMap[item] ?? {};
        return {
          name: tokenInfo.displayName,
          symbol: tokenInfo.symbol,
          balance: tokenInfo.balance?.toNumber() ?? 0,
          priceInUSD: tokenInfo.priceInUsd?.toNumber() ?? 0,
          displayBalance: formatBalance(tokenInfo.balance ?? 0)
        };
      });
    }
    return [
      {
        name: displayName,
        symbol: curSymbol,
        balance: balance?.toNumber() || 0,
        priceInUSD: priceInUsd.toNumber(),
        displayBalance: formatBalance(balance ?? 0)
      }
    ];
  }, [expandableTokenSymbols, displayName, curSymbol, balance, priceInUsd, erc20InfoMap]);

  const handleInputChange = useCallback(
    ({ amount: newAmount, token }: SelectableTokenValue<SelectableToken>) => {
      const { symbol: newSymbol } = token!;
      if (newSymbol !== curSymbol) {
        setCurSymbol(newSymbol as ERC20Symbol);
        onChange(newSymbol as ERC20Symbol);
      }
      setAmount(newAmount);
    },
    [curSymbol, onChange]
  );

  const buttonText = useMemo(
    () => (errorMsg || !amount ? 'Enter an amount' : `Withdraw ${amount} ${displayName}`),
    [amount, displayName, errorMsg]
  );
  return (
    <Stack gap="1.5rem">
      <Stack gap="0">
        <Inline justifyContent="space-between">
          <H5>Amount</H5>
          <Text skin="secondary">
            Available:{' '}
            {formatNumber(availableToWithdraw, {
              decimal: MAXIMUM_BALANCE_DECIMALS,
              threshold: { min: MINIMUM_ACCOUNTABLE_NUM }
            })}{' '}
            {displayName}
          </Text>
        </Inline>
        <SelectableTokenInput
          value={curSelectedTokenInfo}
          tokens={tokensInfo}
          decimals={MAXIMUM_BALANCE_DECIMALS}
          onChange={handleInputChange}
          hasError={!!errorMsg}
          onActionButtonClicked={() => {
            setAmount(floor(availableToWithdraw.toNumber(), MAXIMUM_BALANCE_DECIMALS));
          }}
          actionButtonText="Max"
          inputProps={{
            autoFocus: true,
            title: amount
              ? `$${formatNumber(amount, {
                  decimal: MAXIMUM_BALANCE_DECIMALS,
                  threshold: { min: MIN_BALANCE_THRESHOLD }
                })}`
              : ''
          }}
          classNames={{ tokenSelect: { menu: 'token-select-menu', item: 'token-select-item' } }}
        />
        <Text skin="error">{errorMsg}</Text>
      </Stack>
      {totalBorrowedPositionInUsd.gt(0) && (
        <BorrowedContainer inset="0.625rem 1rem">
          <Inline gap="0.25rem">
            <SmallText skin="secondary">Borrowed:</SmallText>
            <H5>${formatNumber(totalBorrowedPositionInUsd)}</H5>
          </Inline>
          <BorrowLimitBar
            borrowedValue={totalBorrowedPositionInUsd}
            borrowLimitValue={newBorrowLimitInUsd}
            liquidationPointValue={newLiquidationThresholdInUsd}
            totalSuppliedValue={newTotalCollateralPositionInUsd}
          />
        </BorrowedContainer>
      )}
      <InfoPanel
        skin="primary"
        infos={[
          {
            title: 'Remaining supply',
            value: `${formatNumber(remainingSupplied, { decimal: 2 })} ${displayName}`
          },
          {
            title: 'New Borrow Limit',
            value: (
              <NumberRange
                start={borrowLimitInUsd.toNumber()}
                end={newBorrowLimitInUsd.toNumber()}
                formatter={value => formatToCurrency(value)}
              />
            )
          }
        ]}
      />
      <TimelockTerms
        checked={timelockTermsChecked}
        claimableTime={claimableTime}
        onTermsCheck={handleTimelockTermsCheck}
      />

      <Button
        skin="primary"
        size="large"
        block
        disabled={isDisableWithdraw}
        onClick={handleWithdraw}
      >
        {buttonText}
      </Button>
    </Stack>
  );
});
