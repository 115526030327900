import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Stack, StackProps, Spinner, Inline, Card } from '@parallel-mono/components';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import { AccountInfos, Notifications, NotConnect, NotSign } from '../components';
import { ProfileContextProvider, Notification, useProfile } from '../contexts';

import { useParallelToast, useWeb3TokenAuth } from '@/apps/paraspace/contexts';
import { Maybe } from '@/apps/paraspace/typings/basic';

const StyledCard = styled(Card).attrs({
  border: true
})`
  width: 100%;
`;

const PageContent: FC<Omit<StackProps, 'children'>> = memo(({ ...others }) => {
  const {
    profile: { avatar: profileAvatar, notifications: profileNotifications, email },
    handleUpdateProfile,
    handleSendActivationEmail,
    updateProfileLoading,
    profileLoading
  } = useProfile();
  const toast = useParallelToast();

  const [avatar, setAvatar] = useState<Maybe<string>>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    if (!updateProfileLoading) {
      setAvatar(profileAvatar);
      setNotifications(prev => {
        return isEqual(prev, profileNotifications) ? prev : profileNotifications;
      });
    }
  }, [profileAvatar, profileNotifications, updateProfileLoading]);

  const handleAvatarChange = useCallback((value: string) => {
    setAvatar(value);
  }, []);

  const handleNotificationsChange = useCallback((values: Notification[]) => {
    setNotifications(values);
  }, []);

  const disabledSaveButton = useMemo(
    () =>
      isEqual([avatar, notifications], [profileAvatar, profileNotifications]) ||
      updateProfileLoading,
    [avatar, notifications, profileAvatar, profileNotifications, updateProfileLoading]
  );

  const handleSaveProfile = useCallback(async () => {
    const newProfile = {
      avatar: isEqual(avatar, profileAvatar) ? undefined : avatar!,
      notifications
    };
    toast.promise(handleUpdateProfile(newProfile), {
      success: 'Update profile successfully!',
      error: 'Update failed, please try it later.',
      pending: 'Updating your profile'
    });
  }, [avatar, handleUpdateProfile, notifications, profileAvatar, toast]);

  if (profileLoading) {
    return (
      <Inline inset="4rem" width="100%" justifyContent="center">
        <Spinner />
      </Inline>
    );
  }

  return (
    <Stack width="100%" {...others}>
      <Stack width="100%">
        <AccountInfos
          email={email}
          onSendEmail={handleSendActivationEmail}
          onAvatarChange={handleAvatarChange}
          avatar={avatar}
        />
        <Notifications
          notifications={notifications}
          onNotificationsChange={handleNotificationsChange}
          onSave={handleSaveProfile}
          disabledSaveButton={disabledSaveButton}
        />
      </Stack>
    </Stack>
  );
});

export const ProfileSetting = memo(() => {
  const { isValidToken, isVerifying, walletConnected } = useWeb3TokenAuth();
  if (!walletConnected) {
    return (
      <StyledCard>
        <NotConnect description="Please connect to a wallet to view profile settings." />
      </StyledCard>
    );
  }
  if (isVerifying) {
    return (
      <StyledCard>
        <Stack alignItems="center" inset="2rem">
          <Spinner />
          Verifying your account
        </Stack>
      </StyledCard>
    );
  }
  if (!isValidToken) {
    return (
      <StyledCard>
        <NotSign />
      </StyledCard>
    );
  }
  return (
    <ProfileContextProvider>
      <PageContent />
    </ProfileContextProvider>
  );
});
