import { useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import { RECOVERY_NFT_HF_THRESHOLD } from '../config';

import usePool from './usePool';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useParallelToast, useWeb3Context } from '@/apps/paraspace/contexts';
import { useRemoveAccountAuctionsMutation } from '@/apps/paraspace/generated/graphql';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

export const useAccountLiquidation = () => {
  const [closingLiquidation, setClosingLiquidation] = useState(false);
  const {
    overviewUserInfo: { nftHealthFactor },
    nftInfoMap,
    load: refreshPosition
  } = useMMProvider();
  const { erc721Config } = useAppConfig();

  const { account } = useWeb3Context();
  const { setAuctionValidityTime } = usePool();

  const nftPositions = useMemo(() => {
    return Object.keys(erc721Config)
      .map(key => nftInfoMap[key])
      .filter(token => token && !isEmpty(token.nftSuppliedList));
  }, [nftInfoMap, erc721Config]);

  const [removeAccountLiquidationFromServer] = useRemoveAccountAuctionsMutation();

  const toast = useParallelToast();

  const inAuction = useMemo(
    () => nftPositions.some(nftInfo => !isEmpty(nftInfo.auctionedTokens)),
    [nftPositions]
  );

  const disableCloseLiquidation = useMemo(() => {
    return (inAuction && nftHealthFactor?.lte(RECOVERY_NFT_HF_THRESHOLD)) || closingLiquidation;
  }, [inAuction, nftHealthFactor, closingLiquidation]);

  const closeLiquidation = useCallback(async () => {
    setClosingLiquidation(true);
    try {
      let txHash = '';
      await setAuctionValidityTime(account).then(async tx => {
        txHash = tx?.hash ?? '';
        await tx?.wait();
      });
      await removeAccountLiquidationFromServer({
        variables: {
          user: account,
          txHash
        }
      });
    } catch (e) {
      throw getUserFriendlyError(e);
    } finally {
      setClosingLiquidation(false);
      refreshPosition();
    }
  }, [account, refreshPosition, removeAccountLiquidationFromServer, setAuctionValidityTime]);

  const handleCloseLiquidation = useCallback(() => {
    toast.promise(closeLiquidation());
  }, [closeLiquidation, toast]);

  return { inAuction, disableCloseLiquidation, handleCloseLiquidation, closingLiquidation };
};
