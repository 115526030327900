import { Stack, StackProps } from '@parallel-mono/components';
import { memo } from 'react';

import TableForDesktop from './TableForDesktop';
import { useEthValidatorTableData } from './useEthValidatorTableData';

import { useSupplyETHValidator } from '@/apps/paraspace/pages/contexts/ETHValidatorProvider/SupplyETHValidatorManager';

type EthValidatorProps = Omit<StackProps, 'children'>;

export const EthValidator = memo((props: EthValidatorProps) => {
  const { data, loading } = useEthValidatorTableData();

  const { supplyETHValidator } = useSupplyETHValidator();

  return (
    <Stack {...props}>
      <TableForDesktop data={data} loading={loading} onSupply={supplyETHValidator} />
    </Stack>
  );
});
