import { useMemo } from 'react';
import { DefaultTimeLockStrategyService } from 'paraspace-utilities-contract-helpers';
import BigNumber from 'bignumber.js';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ERC20Symbol, ERC721Symbol, TimeLockStrategyData } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const useTimelockParams = (
  amount: number,
  symbol: ERC20Symbol | ERC721Symbol
): { waitTime: Maybe<number>; strategyData: Maybe<TimeLockStrategyData> } => {
  const { provider } = useWeb3Context();
  const { erc20InfoMap, nftInfoMap } = useMMProvider();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new DefaultTimeLockStrategyService(provider);
  }, [provider]);

  const strategyData: Maybe<TimeLockStrategyData> = useMemo(() => {
    const assetMap = erc20InfoMap[symbol] ?? nftInfoMap[symbol];
    return assetMap?.timeLockStrategyData || null;
  }, [erc20InfoMap, nftInfoMap, symbol]);

  const waitTime = useMemo(() => {
    if (service && amount && amount > 0) {
      return service.calculateTimeLockParamsOffChain({
        amount: BigNumber(amount),
        timeLockStrategyData: strategyData
      });
    }
    return null;
  }, [amount, service, strategyData]);

  return {
    waitTime,
    strategyData
  };
};
