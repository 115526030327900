import { FC, memo, useCallback, useState } from 'react';
import { useMount } from 'react-use';

import { SupplyETHValidatorFormData } from './types';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  ApproveERC721Submitter,
  SupplyLegacyERC721Submitter,
  Step,
  Stepper,
  StepperProps,
  ApproveERC721FromEOASubmitter,
  SupplyERC721FromEOASubmitter
} from '@/apps/paraspace/components';
import { useContractsMap } from '@/apps/paraspace/hooks';

interface StepProps extends Omit<StepperProps, 'steps' | 'step' | 'onError'> {
  onError: () => void;
  onSuccess: () => void;
  formData: SupplyETHValidatorFormData;
}

const SupplyStakeFishStepper: FC<StepProps> = ({ onError, onSuccess, formData, ...others }) => {
  const [steps, setSteps] = useState<Step[]>([]);
  const [step, setStep] = useState(0);
  const contracts = useContractsMap();
  const address = contracts[ERC721Symbol.SF_VLDR];
  const { nftInfoMap } = useMMProvider();
  const collectionName = nftInfoMap[ERC721Symbol.SF_VLDR].displayName;

  const onStepFinish = useCallback(() => {
    setStep(prevStep => prevStep + 1);
  }, []);

  useMount(() => {
    const fn = async () => {
      const result = {
        AA: [
          {
            description: `Approve ${collectionName} to ParaSpace`,
            content: (
              <ApproveERC721Submitter
                formData={{
                  name: collectionName,
                  assetAddress: address
                }}
                onFinish={onStepFinish}
                onError={onError}
              />
            )
          },
          {
            description: `Supply ${collectionName} to ParaSpace`,
            content: (
              <SupplyLegacyERC721Submitter
                formData={formData}
                onFinish={onSuccess}
                onError={onError}
              />
            )
          }
        ],
        EOA: [
          {
            description: `Approve ${collectionName} to ParaSpace`,
            content: (
              <ApproveERC721FromEOASubmitter
                formData={{
                  name: collectionName,
                  assetAddress: address
                }}
                onFinish={onStepFinish}
                onError={onError}
              />
            )
          },
          {
            description: `Supply ${collectionName} to ParaSpace`,
            content: (
              <SupplyERC721FromEOASubmitter
                formData={formData}
                onFinish={onSuccess}
                onError={onError}
              />
            )
          }
        ]
      }[formData.walletType];
      setSteps(result);
    };
    fn();
  });

  return <Stepper steps={steps} step={step} {...others} />;
};

export default memo(SupplyStakeFishStepper);
