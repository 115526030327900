import { Stack, Card, H4, Text, Button, Responsive } from '@parallel-mono/components';
import { memo, FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EmptyMyEarnings } from '../../../Images/empty-my-earnings.svg';
import { LiquidationTag } from '../../../components';

import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';

const StyledButton = styled(Button)`
  width: 15rem;
  ${({ theme }) => theme.breakpoints.down('desktop')`
     width: 100%;
  `};
`;
const TextWrapper = styled(Stack)`
  text-align: center;
`;

export const EmptyState: FC = memo(() => {
  const navigate = useNavigate();
  return (
    <Card border>
      <Stack>
        <Responsive breakPoint="tablet" width="100%" justifyContent="space-between">
          <H4>My ParaSpace APE Earnings</H4>
          <LiquidationTag />
        </Responsive>
        <Card border>
          <Stack alignItems="center">
            <EmptyMyEarnings width="6.125rem" />
            <TextWrapper gap="0" alignItems="center">
              <H4>You Don’t Have Any Staking Position</H4>
              <Text skin="secondary">Start staking with ParaSpace to earn compound interest.</Text>
            </TextWrapper>

            <Responsive justifyContent="center" breakPoint="tablet" width="100%">
              <StyledButton
                size="large"
                onClick={() => navigate(absoluteRouteNames.APE_STAKING.COIN_POOL.index)}
              >
                Start Staking
              </StyledButton>
              <StyledButton
                size="large"
                skin="secondary"
                onClick={() => navigate(absoluteRouteNames.APE_STAKING.CALCULATOR)}
              >
                Calculate Earnings
              </StyledButton>
            </Responsive>
          </Stack>
        </Card>
      </Stack>
    </Card>
  );
});
