import { HostedImage } from '@parallel-mono/business-components';
import { Image, Inline, InlineProps, SmallText, Stack } from '@parallel-mono/components';
import { FC, ReactNode, memo, useCallback } from 'react';
import styled, { CSSProperties, useTheme } from 'styled-components';
import { Network } from 'paraspace-configs-v2';

import { getThemedString } from '@/apps/parax/hooks';
import { AppConfig, ThemedString } from '@/apps/consts';

const StyledGrid = styled(Inline)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    display: grid;
    grid-template-columns: repeat(auto-fill, 4rem);
    column-gap: 3rem;
    row-gap: 1.5rem;
    & > * + *{
      margin-left: 0;
    }
  `}
`;

const ImageWrapper = styled(Inline).attrs({
  justifyContent: 'center',
  alignItems: 'center'
})`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  background-color: var(--clr-white);
  box-shadow: 0px 2px 6px rgba(58, 78, 88, 0.1);
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 16px rgba(58, 78, 88, 0.15);
  }
`;

const NoWrapText = styled(SmallText)`
  white-space: nowrap;
`;

const MenuItem: FC<{ icon: ReactNode; name: string; onAppChange: () => void }> = memo(
  ({ icon, name, onAppChange }) => {
    return (
      <Stack onClick={onAppChange} gap="0.75rem" alignItems="center">
        <ImageWrapper>{icon}</ImageWrapper>
        <NoWrapText>{name}</NoWrapText>
      </Stack>
    );
  }
);

export const MenuIcon: FC<{ icon: ThemedString; size?: CSSProperties['width'] }> = memo(
  ({ icon, size = '2rem' }) => {
    const { mode: colorMode } = useTheme();
    return (
      <HostedImage
        height="2rem"
        width="2rem"
        name={getThemedString(icon, colorMode)}
        fallback={<Image src={getThemedString(icon, colorMode)} height={size} width={size} />}
      />
    );
  }
);

export const AppMenus: FC<
  InlineProps & {
    apps: AppConfig[];
    network?: Network;
    onAppChange: (appConfig: AppConfig, chainId: Network, path?: string) => void;
    onExternalAppChange: (link: string) => void;
  }
> = memo(
  ({ apps, onAppChange, onExternalAppChange: onOtherNetworkAppChange, network, ...others }) => {
    const handleAppChange = useCallback(
      ({
        app,
        chainId,
        path,
        link
      }: {
        app: AppConfig;
        chainId?: Network;
        path?: string;
        link?: string;
      }) => {
        if (app.type === 'external' && !chainId && link) {
          onOtherNetworkAppChange(link);
        } else {
          onAppChange(app, chainId!, path);
        }
      },
      [onAppChange, onOtherNetworkAppChange]
    );

    return (
      <StyledGrid width="100%" justifyContent="space-around" {...others}>
        {apps.map((app, index) => {
          if (['internal', 'sibling'].includes(app.type)) {
            if (app.type === 'internal' && app.domainApps?.length) {
              return (
                <>
                  {app.domainApps?.map(domainApp => {
                    return (
                      <MenuItem
                        key={`${domainApp.name}-${domainApp.path}`}
                        icon={<MenuIcon icon={domainApp.icon} />}
                        name={domainApp.name}
                        onAppChange={() =>
                          handleAppChange({ app, chainId: network, path: domainApp.path })
                        }
                      />
                    );
                  })}
                </>
              );
            }
            return (
              <MenuItem
                key={`${app.name}-${index}`}
                icon={<MenuIcon icon={app.icon} />}
                name={app.name}
                onAppChange={() => handleAppChange({ app, chainId: network })}
              />
            );
          }
          if (app.type === 'external' && app?.domainApps?.length) {
            return (
              <>
                {app.domainApps?.map(domainApp => {
                  return (
                    <MenuItem
                      key={`${domainApp.name}-${domainApp.path}`}
                      icon={<MenuIcon icon={domainApp.icon} />}
                      name={domainApp.name}
                      onAppChange={() => onOtherNetworkAppChange(domainApp.link!)}
                    />
                  );
                })}
              </>
            );
          }
          return null;
        })}
      </StyledGrid>
    );
  }
);
