import { useMemo } from 'react';

import { useGetSymbolByContractAddress } from './useGetSymbolByContractAddress';

import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const useSymbolByContractAddress = <T extends ERC721Symbol | ERC20Symbol>(
  contractAddress: string
): Maybe<T> => {
  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  return useMemo(
    () => getSymbolByContractAddress<T>(contractAddress),
    [getSymbolByContractAddress, contractAddress]
  );
};
