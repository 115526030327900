export const NFT_THUMBNAIL_SIZE_MAPPING = {
  xLarge: {
    width: '10.875rem',
    borderRadius: '1rem'
  },
  large: {
    width: '7.5rem',
    borderRadius: '0.625rem'
  },
  medium: {
    width: '5rem',
    borderRadius: '0.5rem'
  },
  small: {
    width: '2.5rem',
    borderRadius: '0.25rem'
  },
  xSmall: {
    width: '1.5rem',
    borderRadius: '0.25rem'
  }
};
