import { Inline, Stack, StackProps } from '@parallel-mono/components';
import { FC, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import { MenuItem, MenuName } from './styledComponents';

import { Menu } from '.';

const IconWrapper = styled(Inline)`
  flex: 0 0 1.5rem;
`;

const SubMenuItem = styled(MenuName)<{ active: boolean; disabled: boolean }>`
  cursor: pointer;
  opacity: ${({ disabled, theme }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
  ::before {
    content: '';
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    margin-bottom: 0.125rem;
    border-width: ${({ active }) => (active ? '0.25rem' : '0.125rem')};
    border-style: solid;
    border-color: ${({ theme, active }) =>
      active ? theme.skin.grey['900'] : theme.skin.grey['300']};
    box-sizing: border-box;
  }
`;

type Props = { menus: Menu[]; onMenuClick: (menu: Menu) => void };

export const DesktopSideMenu: FC<Props & Omit<StackProps, 'children'>> = memo(
  ({ menus, onMenuClick, ...others }) => {
    const location = useLocation();

    return (
      <Stack {...others}>
        {menus.map(menu => {
          const subMenus = menu.subMenus?.map(subMenu => {
            const { path, name, disabled } = subMenu;
            return (
              <SubMenuItem
                active={location.pathname.startsWith(path)}
                key={`${path}-${name}`}
                onClick={() => onMenuClick(subMenu as Menu)}
                disabled={disabled}
              >
                {name}
              </SubMenuItem>
            );
          });

          const { path, icon, name, disabled } = menu;
          const mainMenu = (
            <MenuItem
              key={`${path}-${name}`}
              data-path={path}
              selected={location.pathname.startsWith(path)}
              disabled={disabled}
              onClick={() => onMenuClick(menu)}
            >
              <IconWrapper>{icon}</IconWrapper>
              <MenuName>{name}</MenuName>
            </MenuItem>
          );

          return !isEmpty(subMenus) ? (
            <Stack gap="2rem" key={`${path}-${name}`}>
              {mainMenu}
              <Stack gap="1.5rem" inset="0 0 0 1.5rem">
                {subMenus}
              </Stack>
            </Stack>
          ) : (
            mainMenu
          );
        })}
      </Stack>
    );
  }
);
