import { StakeBakcFormData } from '../StakeModal';

const validateTotalAmount = (totalAmount: number, stakeLimit: number) => {
  if (stakeLimit !== null && totalAmount > stakeLimit) {
    return 'Total amount exceeds stake limit';
  }
  if (totalAmount !== 0 && totalAmount < 1) {
    return 'You need to stake at least 1 APE';
  }
  if (totalAmount === 0) {
    return '';
  }
  return null;
};

export const validate = (
  {
    amountFromBalance,
    tokenId
  }: {
    amountFromBalance: number | null;
    tokenId?: number;
  },
  {
    stakeLimit
  }: {
    stakeLimit: number;
  }
): Partial<{ [key in keyof StakeBakcFormData]: string | null }> => {
  return {
    amountFromBalance: validateTotalAmount(amountFromBalance ?? 0, stakeLimit),
    tokenId: tokenId ? null : 'Please select a BAYC / MAYC'
  };
};
