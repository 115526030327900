import { FC, useCallback, useMemo, useState } from 'react';
import { Stack } from '@parallel-mono/components';
import { sumBy } from '@parallel-mono/utils';
import { chain, map } from 'lodash';

import { ListingToken } from '../../../types';
import { CreateOrderResponseData } from '../../../hooks';
import { formatOrderCreatingTokenParams } from '../utils';
import { CreateOrdersSubmitter } from '../submitters/CreateOrdersSubmitter';

import { Stepper } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useContractsMap, useERC20, useERC721 } from '@/apps/paraspace/hooks';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';

type Props = {
  formData: {
    listingTokens: ListingToken[];
  };
  onError: () => void;
  onFinish: (results: CreateOrderResponseData[]) => void;
};

export const BatchListTokensSteppers: FC<Props> = ({
  onError,
  onFinish,
  formData: { listingTokens }
}) => {
  const { erc20InfoMap, nftInfoMap } = useMMProvider();
  const contracts = useContractsMap();
  const [step, setStep] = useState(0);

  const handleNextStep = useCallback(() => {
    setStep(prev => prev + 1);
  }, []);

  const totalPrice = useMemo(
    () => sumBy(listingTokens, ({ price }) => price).toNumber(),
    [listingTokens]
  );

  const { createApproval } = useERC20(erc20InfoMap.ETH.address);
  const { genApprovalForAll, genSupplyERC721Txs } = useERC721();

  const groupedTokens = useMemo(
    () =>
      chain(listingTokens)
        .groupBy('symbol')
        .map((value, key) => ({
          symbol: key as ERC721Symbol,
          ids: value.map(({ tokenId }) => tokenId)
        }))
        .value(),
    [listingTokens]
  );

  const approveNativeTokenTxs = useMemo(
    () => [{ tx: createApproval({ amount: totalPrice }) }],
    [createApproval, totalPrice]
  );
  const approveERC721Txs = useMemo(
    () =>
      map(groupedTokens, ({ symbol, ids }) => ({
        tx: genApprovalForAll({ ids, address: nftInfoMap[symbol].address })
      })),
    [genApprovalForAll, groupedTokens, nftInfoMap]
  );
  const supplyERC721Txs = useMemo(
    () =>
      map(groupedTokens, ({ symbol, ids: tokenIds }) => ({
        tx: genSupplyERC721Txs({ tokenIds, tokenAddress: nftInfoMap[symbol].address })
      })),
    [genSupplyERC721Txs, groupedTokens, nftInfoMap]
  );
  const approveNTokenTxs = useMemo(
    () =>
      map(groupedTokens, ({ symbol, ids }) => ({
        tx: genApprovalForAll({
          address: nftInfoMap[symbol].xTokenAddress,
          ids,
          spender: contracts?.Conduit
        })
      })),
    [contracts?.Conduit, genApprovalForAll, groupedTokens, nftInfoMap]
  );

  const batchTxs = useMemo(
    () => [...approveNativeTokenTxs, ...approveERC721Txs, ...supplyERC721Txs, ...approveNTokenTxs],
    [approveERC721Txs, approveNTokenTxs, approveNativeTokenTxs, supplyERC721Txs]
  );

  const steppers = useMemo(
    () => [
      {
        description: `Approve and supply to create listing`,
        content: (
          <BatchTransactionsSubmitter
            batchTxs={batchTxs}
            onFinish={handleNextStep}
            onError={onError}
          />
        )
      },
      {
        description: `Sign to create ${
          listingTokens.length > 1 ? `${listingTokens.length} listings` : 'listing'
        }`,
        content: (
          <CreateOrdersSubmitter
            formData={{
              listingTokens: formatOrderCreatingTokenParams(listingTokens)
            }}
            onFinish={onFinish}
            onError={onError}
          />
        )
      }
    ],
    [batchTxs, handleNextStep, onError, listingTokens, onFinish]
  );

  return (
    <Stack>
      <Stepper steps={steppers} step={step} />
    </Stack>
  );
};
