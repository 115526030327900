import { memo, useCallback } from 'react';
import { isEmpty } from 'lodash';

import { FormSubmitter } from '../FormSubmitter';

import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import useWPunk from '@/apps/paraspace/pages/hooks/useWPunk';
import useLegacyERC721 from '@/apps/paraspace/pages/hooks/useLegacyERC721';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type SupplyERC721FromEOASubmitterProps = {
  formData: {
    tokenIds: number[];
    symbol: ERC721Symbol;
  };
  waitConfirmations?: number;
  onFinish: () => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const SupplyERC721FromEOASubmitter = memo(
  ({
    formData: { tokenIds, symbol },
    onFinish,
    onError,
    waitConfirmations
  }: SupplyERC721FromEOASubmitterProps) => {
    const { supplyERC721 } = usePool();
    const { supplyPunks } = useWPunk();
    const { account, eoaAccount, submitEOATransactions } = useWeb3Context();

    const { nftInfoMap } = useMMProvider();
    const { ownerOf } = useLegacyERC721(nftInfoMap[symbol].xTokenAddress);

    const handleFinish = useCallback(() => {
      onFinish();
    }, [onFinish]);

    const submit = useCallback(async () => {
      const tokensSuppliedStatus = await Promise.all(
        tokenIds.map(async tokenId => {
          const owner = await ownerOf(tokenId);
          return owner === account;
        })
      );

      const notSuppliedTokenIds = tokenIds.filter((_, index) => !tokensSuppliedStatus[index]);

      if (isEmpty(notSuppliedTokenIds)) {
        return null;
      }

      if (symbol === ERC721Symbol.PUNK) {
        const tx = await supplyPunks(notSuppliedTokenIds, eoaAccount);
        return submitEOATransactions(tx!);
      }
      if (symbol === ERC721Symbol.MOONBIRD) {
        console.warn('Not supported!');
        return null;
      }

      const txs = await supplyERC721(
        nftInfoMap[symbol]?.address,
        notSuppliedTokenIds.map(each => String(each)),
        eoaAccount
      );

      return submitEOATransactions(txs!);
    }, [
      tokenIds,
      symbol,
      supplyERC721,
      nftInfoMap,
      eoaAccount,
      submitEOATransactions,
      ownerOf,
      account,
      supplyPunks
    ]);

    return (
      <FormSubmitter
        inProgressMessage="Securely Supplying"
        waitConfirmations={waitConfirmations}
        onError={onError}
        onFinish={handleFinish}
        submit={submit}
      />
    );
  }
);
