import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
  Button,
  H3,
  Icon,
  Inline,
  SmallText,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import { useTheme } from 'styled-components';
import { InfoPanel } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import { CenteredText, ContractBanner } from '../../../components';
import { ApeCoinListing } from '../../../types';
import { ApeStakingTokenSelector } from '../components/ApeStakingTokenSelector';
import { useApeCoinListingValidate } from '../../../hooks';
import { InvalidListingAlert } from '../components';

import { ChangeSelectedHandler } from '@/apps/paraspace/components';
import { ApeStakingMainTokenSymbol, WalletType } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { one } from '@/apps/paraspace/consts/values';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';

type JoinListingWithApeFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  apeCoinListing: ApeCoinListing;
  walletType: WalletType;
  onSubmit: (token: { symbol: ApeStakingMainTokenSymbol; tokenId: number }) => void;
};

export const JoinListingWithApeForm = memo(
  ({ apeCoinListing, walletType, onSubmit, ...others }: JoinListingWithApeFormProps) => {
    const { nftPoolsCompoundApy } = useAutoCompoundApeInfo();
    const { account } = useWeb3Context();
    const { stakingPool, stakingType } = apeCoinListing;
    const apy = nftPoolsCompoundApy?.[stakingPool]?.toNumber() ?? 0;

    const theme = useTheme();

    const [selectedToken, setSelectedToken] =
      useState<Maybe<{ symbol: ApeStakingMainTokenSymbol; tokenId: number }>>(null);

    const timeBeforeExpiration = useMemo(
      () => dayjs(apeCoinListing.expirationDate).fromNow(true),
      [apeCoinListing.expirationDate]
    );

    const predictedApeShare = one.minus(apeCoinListing.share);

    const predictedTotalShare = predictedApeShare.plus(
      apeCoinListing.offerer === account ? apeCoinListing.share : 0
    );

    const [apeCoinListingValidator, validationResult] = useApeCoinListingValidate();

    useEffect(() => {
      apeCoinListingValidator(apeCoinListing);
    }, [apeCoinListing, apeCoinListingValidator]);

    const infos = useMemo(
      () => [
        {
          title: 'Your APY',
          value: (
            <Stack gap="0" alignItems="flex-end">
              <Text skin="success">
                {formatNumber(predictedTotalShare.multipliedBy(apy), {
                  output: 'percent'
                })}
              </Text>
              <SmallText skin="secondary">
                {formatNumber(predictedTotalShare, {
                  output: 'percent'
                })}{' '}
                of total {formatNumber(apy, { output: 'percent' })} pool APY
              </SmallText>
            </Stack>
          )
        }
      ],
      [apy, predictedTotalShare]
    );

    const handleConfirm = useCallback(() => {
      onSubmit(selectedToken!);
    }, [onSubmit, selectedToken]);

    const predictedApeListing = useMemo(
      () => ({
        stakingType,
        offerer: account
      }),
      [account, stakingType]
    );

    const joinButtonDisabled = useMemo(
      () => selectedToken === null || Boolean(validationResult),
      [selectedToken, validationResult]
    );

    return (
      <Stack gap="1rem" {...others}>
        <ContractBanner
          account={account}
          apeListing={predictedApeListing}
          apeCoinListing={apeCoinListing}
          bakcListing={null}
        />
        <Inline justifyContent="center">
          <SmallText skin="secondary">Offer expires in {timeBeforeExpiration}</SmallText>
        </Inline>
        <Stack gap="0.25rem">
          <Inline justifyContent="center">
            <H3>Select a {stakingPool}</H3>
          </Inline>
          <CenteredText skin="secondary">
            Select your {stakingPool} to stake with this ApeCoin owner and earn a share of the total
            staking rewards.
          </CenteredText>
        </Stack>
        <ApeStakingTokenSelector
          stakingPool={stakingPool}
          symbols={[stakingPool]}
          selectedToken={selectedToken}
          walletType={walletType}
          onChange={setSelectedToken as ChangeSelectedHandler}
        />
        <InfoPanel skin="primary" infos={infos} />
        {validationResult && <InvalidListingAlert reason={validationResult} />}
        <Button block size="large" disabled={joinButtonDisabled} onClick={handleConfirm}>
          Confirm
        </Button>
        {!joinButtonDisabled && (
          <Inline gap="0.5rem" justifyContent="center" alignItems="center">
            <Icon color={theme.skin.primary.main} name="heartContained" />
            <SmallText skin="secondary">Enjoy 0 platform fee and low gas!</SmallText>
          </Inline>
        )}
      </Stack>
    );
  }
);
