import { memo, FC } from 'react';
import { Stack, H5 } from '@parallel-mono/components';

import { PercentageInput } from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';

interface ShareApyInputProps {
  percentage: Maybe<number>;
  onPercentageChange: (value: Maybe<number>) => void;
}

const ShareApyInput: FC<ShareApyInputProps> = ({ onPercentageChange, percentage }) => {
  return (
    <Stack gap=".5rem">
      <H5>Share of APY I want</H5>
      <PercentageInput
        value={percentage}
        placeholder="0"
        onChange={onPercentageChange}
        decimals={1}
      />
    </Stack>
  );
};

export default memo(ShareApyInput);
