import { MarketplaceIcon, HostedImage } from '@parallel-mono/business-components';
import {
  Stack,
  StackProps,
  H4,
  Inline,
  Text,
  RadioGroup,
  Radio,
  useBreakpoints,
  Button,
  NumericInput
} from '@parallel-mono/components';
import { ChangeEvent, FC, KeyboardEvent, memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';

import { FilterPanelProps } from '.';

import { SearchInput } from '@/apps/paraspace/components';
import { Marketplace } from '@/apps/paraspace/generated/graphql';
import { useToggle } from '@/apps/paraspace/contexts';
import { PriceRange } from '@/apps/paraspace/pages/Shop/pages/CollectionItemsShop/types';

const Container = styled(Stack)`
  padding: 1rem;
`;

const PriceInput = styled(NumericInput).attrs({ decimals: 4 })`
  min-width: 0;
  font-size: 1rem;
  background: none;
`;

(window as any).HostedImage = HostedImage;

const organizePriceRange = (range: PriceRange) => {
  const { min, max } = range;
  if ((max ?? Infinity) < (min ?? -Infinity)) {
    return {
      min: max,
      max: min
    };
  }
  return {
    min,
    max
  };
};

type Props = Omit<FilterPanelProps, 'opened' | 'onCloseFilterPanel'>;

export const CommonFilters: FC<Props & Omit<StackProps, 'children'>> = memo(
  ({ defaultFilter, onFilterChange, ...others }) => {
    const [tokenId, setTokenId] = useState(defaultFilter.tokenId);
    const [marketplace, setMarketplace] = useState(defaultFilter.marketplace);
    const [priceRange, setPriceRange] = useState(defaultFilter.priceRange);
    const { mobile } = useBreakpoints();

    useEffect(() => {
      setTokenId(defaultFilter.tokenId);
      setMarketplace(defaultFilter.marketplace);
      setPriceRange(defaultFilter.priceRange);
    }, [defaultFilter]);

    const handleMarketplaceChange = useCallback(
      (newMarketplace: Marketplace) => {
        setMarketplace(newMarketplace);
        if (!mobile) {
          onFilterChange({ ...defaultFilter, marketplace: newMarketplace });
        }
      },
      [defaultFilter, mobile, onFilterChange]
    );

    const handleKeywordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setTokenId(e.target.value);
    }, []);

    const handleKeywordInputKeydown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (['Enter', 'NumpadEnter'].includes(e.code)) {
          onFilterChange({
            ...defaultFilter,
            tokenId: (e.target as HTMLInputElement).value
          });
        }
      },
      [defaultFilter, onFilterChange]
    );

    const handleMinPriceChange = useCallback(
      (v: number | null) => {
        setPriceRange(range => ({
          ...range,
          min: v
        }));
      },
      [setPriceRange]
    );

    const handleMaxPriceChange = useCallback(
      (v: number | null) => {
        setPriceRange(range => ({
          ...range,
          max: v
        }));
      },
      [setPriceRange]
    );

    const handlePriceInputKeydown = useCallback(
      (e: KeyboardEvent) => {
        if (['Enter', 'NumpadEnter'].includes(e.code)) {
          const organizedPriceRange = organizePriceRange(priceRange);
          setPriceRange(organizedPriceRange);
          onFilterChange({
            ...defaultFilter,
            priceRange: organizedPriceRange
          });
        }
      },
      [priceRange, setPriceRange, defaultFilter, onFilterChange]
    );

    const handleSupply = useCallback(() => {
      onFilterChange({
        ...defaultFilter,
        priceRange,
        tokenId,
        marketplace
      });
    }, [defaultFilter, tokenId, marketplace, onFilterChange, priceRange]);

    const enableBlur = useToggle('BLUR_INTEGRATION');

    return (
      <Container gap="2.5rem" alignItems="stretch" justifyContent="flex-start" {...others}>
        <Stack alignItems="stretch" gap="0.75rem">
          <H4>Search</H4>
          <SearchInput
            inputProps={{
              value: tokenId,
              onChange: handleKeywordChange,
              placeholder: 'Item ID',
              onKeyDown: mobile ? noop : handleKeywordInputKeydown
            }}
          />
        </Stack>
        <Stack gap="0.75rem">
          <H4>Marketplace</H4>
          <RadioGroup value={marketplace} onChange={handleMarketplaceChange}>
            <Stack>
              {enableBlur && (
                <Inline as="label" justifyContent="space-between" alignItems="center">
                  <Inline alignItems="center" gap="1rem">
                    <MarketplaceIcon size="small" name="blur" />
                    <Text fontWeight="medium">Blur</Text>
                  </Inline>
                  <Radio value="Blur" />
                </Inline>
              )}

              <Inline as="label" justifyContent="space-between" alignItems="center">
                <Inline alignItems="center" gap="1rem">
                  <MarketplaceIcon size="small" name="opensea" />
                  <Text fontWeight="medium">OpenSea</Text>
                </Inline>
                <Radio value="OpenSea" />
              </Inline>
              <Inline as="label" justifyContent="space-between" alignItems="center">
                <Inline alignItems="center" gap="1rem">
                  <MarketplaceIcon size="small" name="paraspace" />
                  <Text fontWeight="medium">ParaSpace</Text>
                </Inline>
                <Radio value="ParaSpace" />
              </Inline>
            </Stack>
          </RadioGroup>
        </Stack>

        <Stack gap="0.75rem">
          <H4>Price Range</H4>
          <Inline gap="0.5rem" justifyContent="space-between" alignItems="center">
            <PriceInput
              placeholder="Min"
              value={priceRange.min}
              onChange={handleMinPriceChange}
              onKeyDown={mobile ? noop : handlePriceInputKeydown}
            />
            <Text>:</Text>
            <PriceInput
              placeholder="Max"
              value={priceRange.max}
              onChange={handleMaxPriceChange}
              onKeyDown={mobile ? noop : handlePriceInputKeydown}
            />
          </Inline>
        </Stack>
        {mobile && (
          <Button onClick={handleSupply} block>
            Apply
          </Button>
        )}
      </Container>
    );
  }
);
