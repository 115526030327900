import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { Nesting, useGetMoonbirdNestingLazyQuery } from '@/apps/paraspace/generated/graphql';
import { formatSecondsWithRound } from '@/apps/paraspace/utils/format';
import useNTokenMoonbirds from '@/apps/paraspace/pages/hooks/useNTokenMoonbirds';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { Network } from '@/apps/paraspace/config';

export const LEVELS = ['Straw', 'Bronze', 'Silver', 'Gold', 'Diamond'];
const DAYS_TO_EACH_LEVEL = [0, 30, 90, 180];

export interface CalculatedNestingData {
  nesting: boolean;
  nestingStreak: string;
  totalTime: number;
  level: number;
  daysToNextLevel: number;
  progress: number;
  loading?: boolean;
}

// Because the current nesting time can be interrupted, but is included in the total, the calculation here is incorrect
const getNestingLevel = (seconds: number) => {
  const totalDays = Math.round(seconds / 60 / 60 / 24);
  const level = DAYS_TO_EACH_LEVEL.findIndex(
    (_, index) =>
      totalDays >= DAYS_TO_EACH_LEVEL[index] && totalDays < DAYS_TO_EACH_LEVEL[index + 1]
  );
  const daysToNextLevel = DAYS_TO_EACH_LEVEL[level + 1] - totalDays;
  return { level, daysToNextLevel };
};

const calcByContractData = (data: {
  nesting: boolean;
  current: number;
  total: number;
}): CalculatedNestingData => {
  const { total, current } = data;

  const nestingStreak = formatSecondsWithRound(current);

  const { level, daysToNextLevel } = getNestingLevel(total);
  const currentLevelProgress =
    current / 60 / 60 / 24 / (DAYS_TO_EACH_LEVEL[level + 1] - DAYS_TO_EACH_LEVEL[level]);

  return {
    nesting: data.nesting,
    nestingStreak,
    totalTime: total,
    level,
    daysToNextLevel,
    progress: currentLevelProgress
  };
};

const calcByApiData = (data: Nesting): CalculatedNestingData => {
  const { nested, currentDuration, totalDuration, nextNestLevelAt, nestLevel, nestProgress } = data;
  const nestingStreak = formatSecondsWithRound(currentDuration ?? 0);
  const daysToNextLevel = Math.round(dayjs(nextNestLevelAt || undefined).diff(dayjs(), 'd', true));

  return {
    nesting: nested!,
    nestingStreak,
    totalTime: totalDuration!,
    level: LEVELS.indexOf(nestLevel!.toLowerCase().replace(/^\w{1}/, match => match.toUpperCase())),
    daysToNextLevel,
    progress: nestProgress ?? 0
  };
};

const useMoonBirdsData = (tokenId: string, refetch: boolean) => {
  const { chainId } = useWeb3Context();
  const [calculatedData, setCalculatedData] = useState({
    loading: true
  } as CalculatedNestingData);

  const [getMoonbirdData] = useGetMoonbirdNestingLazyQuery();
  const { getNestingPeriod } = useNTokenMoonbirds();

  const setDataFromContract = useCallback(
    async (id: string) => {
      const res = await getNestingPeriod(id);
      if (res) {
        const { nesting, current, total } = res;
        setCalculatedData(
          calcByContractData({
            nesting,
            current: current.toNumber(),
            total: total.toNumber()
          })
        );
      }
    },
    [getNestingPeriod]
  );

  useEffect(() => {
    const getData = async () => {
      // FIXME: remove the test toggle when deploying
      if (
        Network.MAINNET === chainId ||
        window.location.href.includes('moonbird_level_test=true')
      ) {
        const { data } = await getMoonbirdData({
          variables: {
            tokenId
          }
        });

        if (data?.moonbird?.nesting?.nestLevel) {
          setCalculatedData(calcByApiData(data.moonbird.nesting));
        } else {
          setDataFromContract(tokenId);
        }
      } else {
        setDataFromContract(tokenId);
      }
    };
    getData();
  }, [getMoonbirdData, getNestingPeriod, tokenId, chainId, refetch, setDataFromContract]);

  return calculatedData;
};

export default useMoonBirdsData;
