import { useCallback, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';

import { useApeStaking } from './useApeStaking';

import { ApeStakingTokenSymbol, ERC20Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

export const useApePoolApy = (
  type: ApeStakingTokenSymbol | ERC20Symbol.APE
): [Maybe<BigNumber>, () => Promise<BigNumber>] => {
  const [poolApy, setPoolApy] = useState<Maybe<BigNumber>>(null);

  const { getPoolApy } = useApeStaking();

  const refresh = useCallback(() => {
    return getPoolApy(type)
      .then(apy => {
        setPoolApy(apy);
        return apy;
      })
      .catch(err => {
        setPoolApy(null);
        throw getUserFriendlyError(err);
      });
  }, [type, getPoolApy]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [poolApy, refresh];
};
