import { memo, useMemo } from 'react';
import { Icon } from '@parallel-mono/components';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { CollectionProvider, ShopActionsProvider } from './contexts';
import { Stats } from './components';

import { PageLayout, SideMenus } from '@/apps/paraspace/components';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';

const menus = [
  {
    name: 'Buy',
    path: absoluteRouteNames.SHOP.BUY,
    icon: <Icon name="shoppingCart" strokeWidth={2} />
  },
  {
    name: 'List',
    path: absoluteRouteNames.SHOP.LIST.index,
    icon: <Icon name="tag" strokeWidth={2} />
  },
  {
    name: 'Offers',
    path: absoluteRouteNames.SHOP.OFFERS,
    icon: <Icon name="fileText" strokeWidth={2} />
  }
];

export const Shop = memo(() => {
  const location = useLocation();
  const { contractAddress } = useParams();
  const isFullPage = useMemo(
    () =>
      [
        `${absoluteRouteNames.SHOP.index}/${contractAddress}`,
        absoluteRouteNames.SHOP.LIST.LIST_PRICES_SETTING
      ].includes(location.pathname),
    [contractAddress, location.pathname]
  );

  return (
    <CollectionProvider>
      <ShopActionsProvider>
        <PageLayout
          banner={<Stats />}
          showBanner={!isFullPage}
          showSideMenu={!isFullPage}
          sideMenu={<SideMenus menus={menus} />}
          mainContent={<Outlet />}
        />
      </ShopActionsProvider>
    </CollectionProvider>
  );
});
