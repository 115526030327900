import { useCallback, useMemo } from 'react';
import axios, { AxiosInstance } from 'axios';
import { Environment } from 'parax-sdk';
import { get, isArray, isEmpty, omit, upperFirst, zipWith } from 'lodash';

import { DAYS_OF_YEAR } from '../consts/fixtures';
import { useWeb3Context } from '../contexts';

import { Maybe } from '@/apps/paraspace/typings/basic';

const VALIDATOR = '/api/v1/validator';
const EPOCH_INFO = '/api/v1/epoch';

interface APIResponse<T> {
  data: T;
  status: string;
}

type APIValidatorResponse = {
  activationeligibility_epoch: number;
  activationepoch: number;
  balance: number;
  effectivebalance: number;
  exitepoch: number;
  lastattestation_slot: number;
  name: string;
  pubkey: string;
  slashed: boolean;
  status: string;
  validatorindex: number;
  withdrawableepoch: number;
  withdrawalcredentials: string;
};

type ApiValidatorPerformanceResponse = {
  balance: number;
  performance1d: number;
  performance31d: number;
  performance365d: number;
  performance7d: number;
  rank7d: number;
  validatorindex: number;
};

type APIEpochResponse = {
  attestationscount: number;
  attesterslashingscount: number;
  averagevalidatorbalance: number;
  blockscount: number;
  depositscount: number;
  eligibleether: number;
  epoch: number;
  finalized: boolean;
  globalparticipationrate: number;
  missedblocks: number;
  orphanedblocks: number;
  proposedblocks: number;
  proposerslashingscount: number;
  rewards_exported: number;
  scheduledblocks: number;
  totalvalidatorbalance: number;
  ts: string;
  validatorscount: number;
  voluntaryexitscount: number;
  votedether: number;
  withdrawalcount: number;
};

export type ValidatorInfo = {
  publicKey: string;
  balance: number;
  effectiveBalance: number;
  validatorIndex: number;
  protocolApy: number;
  activatedAt: Maybe<string>;
  nftState: string;
};

const getEpochTime = async (instance: AxiosInstance, epoch: number | 'finalized') => {
  const res = await instance.get<APIResponse<APIEpochResponse>>(`${EPOCH_INFO}/${epoch}`);
  return res.data.data;
};

const getValidatorsInfo = async (instance: AxiosInstance, index: number[]) => {
  const validatorInfoRes = await instance.get<
    APIResponse<APIValidatorResponse[] | APIValidatorResponse>
  >(`${VALIDATOR}/${index.join(',')}`);
  const { data } = validatorInfoRes.data;
  const validators = isArray(data) ? data : [data];

  return validators.map(
    ({ pubkey, balance, effectivebalance, validatorindex, status, activationepoch }) => {
      return {
        publicKey: pubkey,
        balance: balance / 1e9,
        effectiveBalance: effectivebalance / 1e9,
        validatorIndex: validatorindex,
        activationepoch,
        // activatedAt: activeEpoch.ts,
        nftState: upperFirst(status.split('_')[0])
      };
    }
  );
};

const getValidatorsAPR = async (instance: AxiosInstance, index: number[]) => {
  const validatorInfoRes = await instance.get<APIResponse<ApiValidatorPerformanceResponse[]>>(
    `${VALIDATOR}/${index.join(',')}/performance`
  );

  const apyValues = get(validatorInfoRes, 'data.data', []).map(
    (v: ApiValidatorPerformanceResponse) => ((v.performance7d / 1e9 / 7) * DAYS_OF_YEAR) / 32
  );
  return apyValues;
};

const useStakeFishData = () => {
  const { env } = useWeb3Context();

  const instance = useMemo(
    () =>
      axios.create({
        baseURL:
          env === Environment.PRODUCTION ? 'https://beaconcha.in' : 'https://goerli.beaconcha.in',
        timeout: 10000
      }),
    [env]
  );

  const fetchValidatorList = useCallback(
    async (validatorsIndex: number[]) => {
      if (isEmpty(validatorsIndex)) return [];

      const [validatorsInfoRes, protocolApyValues] = await Promise.all([
        getValidatorsInfo(instance, validatorsIndex),
        getValidatorsAPR(instance, validatorsIndex)
      ]);

      return zipWith(
        validatorsInfoRes,
        protocolApyValues,
        (validatorInfo, protocolApy: number) => ({
          ...omit(validatorInfo, 'activationepoch'),
          protocolApy,
          activatedAt: null
        })
      );
    },
    [instance]
  );

  const fetchValidatorDetail = useCallback(
    async validatorIndex => {
      const [validatorInfoRes, protocolApy] = await Promise.all([
        getValidatorsInfo(instance, [validatorIndex]),
        getValidatorsAPR(instance, [validatorIndex])
      ]);

      const activeEpoch = await getEpochTime(instance, validatorInfoRes[0]?.activationepoch);

      return {
        ...validatorInfoRes[0],
        protocolApy: protocolApy[0],
        activatedAt: activeEpoch.ts
      };
    },
    [instance]
  );

  return { fetchValidatorList, fetchValidatorDetail };
};

export default useStakeFishData;
