import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { AppPackage, Route } from 'parax-sdk';
import { noop, orderBy } from 'lodash';

import { Maybe } from '../../typings/basic';
import { FetchingStatus } from '../../typings';
import { useValidApps } from '../../hooks';
import { useEOAProvider } from '../EOAProvider';
import { getChainKey } from '../../config';

import { useLazyApp } from './useLazyApp';

import { InternalAppConfig, ThemedString } from '@/apps/consts';

type ContextValue = {
  currentApp: Maybe<{
    appPackage: Maybe<AppPackage>;
    route: string;
    name: string;
    icon: string;
    domainApp?: { icon: ThemedString; path?: string };
  }>;
  fetchingStatus: FetchingStatus;
  visible: boolean;
  updateRoutes: (routes: Route[]) => void;
};
const AppsContext = createContext<ContextValue>({
  currentApp: null,
  visible: false,
  fetchingStatus: FetchingStatus.INIT,
  updateRoutes: () => {
    throw new Error('Not implemented yet');
  }
});

export const AppsContextProvider = memo(({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const { chainId } = useEOAProvider();
  const chainPath = getChainKey(chainId);

  const { internalApps } = useValidApps();
  const currentAppConfig = useMemo(
    () => internalApps.find(app => pathname.startsWith(`/${chainPath}/apps/${app.route}`)) ?? null,
    [pathname, internalApps, chainPath]
  );

  const [app, fetchingStatus] = useLazyApp(currentAppConfig);

  const visible = useMemo(() => pathname.startsWith(`/${chainPath}/apps`), [pathname, chainPath]);

  const [routesOverrides, setRoutesOverrides] =
    useState<Maybe<{ app: InternalAppConfig; routes: Route[] }>>(null);

  const updateRoutes = useCallback(
    (routes: Route[]) => {
      if (currentAppConfig === null) {
        throw new Error('Can not update routes on a not mounted app');
      }
      setRoutesOverrides({
        app: currentAppConfig,
        routes
      });
    },
    [currentAppConfig]
  );

  useEffect(() => {
    setRoutesOverrides(null);
  }, [currentAppConfig]);

  const finalApp = useMemo(() => {
    if (currentAppConfig === null) {
      return null;
    }

    const sortedApps =
      orderBy((currentAppConfig as InternalAppConfig).domainApps, it =>
        it.path === '' ? -1 : it.path.split('/').length
      ).reverse() ?? [];

    const domainApp = sortedApps.find(it =>
      pathname.startsWith(`/${chainPath}/apps/${currentAppConfig.route}/${it.path}`)
    );

    const matchedApp = currentAppConfig === app?.config ? app.appPackage : null;

    const appWithOverridingRoutes =
      currentAppConfig === routesOverrides?.app && matchedApp !== null
        ? ({
            ...matchedApp,
            routing: {
              ...matchedApp.routing,
              routes: routesOverrides.routes,
              onSwitchRoute: matchedApp.routing?.onSwitchRoute ?? noop
            }
          } as AppPackage)
        : matchedApp;

    return {
      route: currentAppConfig.route,
      name: currentAppConfig.name,
      icon: currentAppConfig.icon,
      appPackage: appWithOverridingRoutes,
      domainApp: domainApp ?? undefined
    };
  }, [routesOverrides, currentAppConfig, app, pathname, chainPath]);

  const value = useMemo(
    () => ({
      currentApp: finalApp,
      visible,
      fetchingStatus,
      updateRoutes
    }),
    [finalApp, visible, updateRoutes, fetchingStatus]
  );

  return <AppsContext.Provider value={value}>{children}</AppsContext.Provider>;
});

AppsContextProvider.displayName = 'AppsContextProvider';

export const useAppsContext = () => useContext(AppsContext);
