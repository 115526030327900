import { forwardRef, memo, useCallback, useImperativeHandle, useState } from 'react';
import { H6, Inline, Pagination, Skeleton } from '@parallel-mono/components';
import { isEmpty } from 'lodash';

import { useERC20Activities } from '../../hooks';

import { ActivityList, ActivityRow } from './components';

export const ERC20Activity = memo(
  forwardRef<{ refresh: () => void }>((_, ref) => {
    const [currentPage, setCurrentPage] = useState(1);

    const { activities, isLoading, pageInfo, refetch } = useERC20Activities();
    useImperativeHandle(ref, () => ({
      refresh: () => {
        refetch(0);
      }
    }));

    const handlePageChange = useCallback(
      (page: number) => {
        setCurrentPage(page);
        refetch(page);
      },
      [refetch]
    );
    return (
      <>
        {isLoading && (
          <Inline inset="0 2rem">
            <Skeleton inset="0" width="100%" />
          </Inline>
        )}
        {!isLoading && isEmpty(activities) && (
          <Inline justifyContent="center">
            <H6 skin="secondary">Empty Activity</H6>
          </Inline>
        )}
        {!isLoading && !isEmpty(activities) && (
          <ActivityList inset="0 2rem">
            {activities?.map(activity => {
              return <ActivityRow key={activity.id} detail={activity} />;
            })}
          </ActivityList>
        )}
        <Pagination
          onChange={handlePageChange}
          total={pageInfo?.totalPage ?? 1}
          page={currentPage}
        />
      </>
    );
  })
);
