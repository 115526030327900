import { Network } from 'paraspace-configs-v2';
import { omit } from 'lodash';
import { ChainId, ERC721Symbol } from '@parallel-utils/contracts-registry';

import { contractsConfig } from './contractsConfig';

export const getERC721Config = (chainId: Network) => {
  const erc721Config = [ChainId.Ethereum, ChainId.Sepolia].includes(chainId as number)
    ? omit(contractsConfig.nft[chainId]?.collection, ERC721Symbol.MBEAN)
    : contractsConfig.nft[chainId]?.collection;
  return erc721Config ?? {};
};
