import { useMemo } from 'react';

import { useLiquidationInfoFormatter } from './useInLiquidationInfoFormatter';

import {
  AccountInLiquidationInfo,
  useGetPausedLiquidationAccountsQuery
} from '@/apps/paraspace/generated/graphql';
import { ERC721Symbol } from '@/apps/paraspace/typings';

const REFRESH_DATA_INTERVAL = 20000;

export const usePausedLiquidationDashboardState = (filterCollections: ERC721Symbol[]) => {
  const { data, loading, client } = useGetPausedLiquidationAccountsQuery({
    pollInterval: REFRESH_DATA_INTERVAL
  });
  const liquidations = useMemo(
    () => (data?.pausedLiquidationAccounts || []) as AccountInLiquidationInfo[],
    [data?.pausedLiquidationAccounts]
  );

  const { liquidations: sortedLiquidations } = useLiquidationInfoFormatter(
    liquidations,
    filterCollections,
    true
  );

  return {
    loading,
    client,
    liquidations: sortedLiquidations
  };
};
