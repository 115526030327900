import { useCallback, useMemo } from 'react';
import { DataGrid, DataGridColumn, DataGridProps } from '@parallel-mono/components';
import { chain, isEmpty } from 'lodash';

import {
  CollectionPosition,
  NftPosition,
  SupplyPositionsRow,
  SupplyPositionsRowType,
  TokenPosition
} from '../types';
import { NFTCollectionItemsGrid } from '../subGrids/NFTCollectionItemsGrid';
import { UniSwapV3ItemsGrid } from '../subGrids/UniSwapV3ItemsGrid';

import {
  createColumns,
  nftPositionToSupplyPositionRow,
  tokenPositionToSupplyPositionRow
} from './utils';
import { useSupplyPositionModals } from './useSupplyPositionModals';

import { WalletType, ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { useSupplyETHValidator } from '@/apps/paraspace/pages/contexts/ETHValidatorProvider/SupplyETHValidatorManager';
import { useBendDaoBalanceMap, useWalletBalanceMap } from '@/apps/paraspace/pages/Credit/hooks';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { useMoneyMarketActions } from '@/apps/paraspace/pages/Credit/contexts';
import { COLLECTION_EXPECTED_ORDER } from '@/apps/paraspace/consts/collectionSort';
/* eslint max-lines-per-function: ["error", { "max": 275 }] */
export const useSupplyPositionsData = ({
  nftPositions,
  tokenPositions,
  inLiquidation
}: {
  inLiquidation: boolean;
  nftPositions: CollectionPosition[];
  tokenPositions: TokenPosition[];
}) => {
  const {
    overviewUserInfo: { totalSuppliedPositionsInUsd, totalBorrowedPositionInUsd },
    nftInfoMap,
    erc20InfoMap
  } = useMMProvider();

  const { withdrawERC721, supplyNftFromBendDao } = useMoneyMarketActions();

  const { effectiveCapeSupplyApy } = useAutoCompoundApeInfo();

  const {
    openERC20SupplyModal,
    withdrawERC20,
    collateralOpenModal,
    collateralHolder,
    openNFTSupplyModal
  } = useSupplyPositionModals();

  const assets = useMemo(() => {
    const nftRowIcons = nftPositions.flatMap(row =>
      row.items.map(it => ({ symbol: it.symbol as ERC721Symbol, tokenId: it.tokenId }))
    );
    const tokenRowIcons = tokenPositions.map(row => ({ symbol: row.symbol }));
    return [...nftRowIcons, ...tokenRowIcons];
  }, [nftPositions, tokenPositions]);

  const nftRows: SupplyPositionsRow[] = useMemo(
    () =>
      chain(nftPositions)
        .map(nftPos => {
          const expectedOrder = COLLECTION_EXPECTED_ORDER.indexOf(nftPos.symbol);
          return {
            order: expectedOrder >= 0 ? expectedOrder : Number.MAX_VALUE,
            ...(nftPositionToSupplyPositionRow(nftPos, nftInfoMap) as unknown as SupplyPositionsRow)
          };
        })
        .orderBy(['order'], ['asc'])
        .value(),
    [nftPositions, nftInfoMap]
  );

  const tokenRows: SupplyPositionsRow[] = useMemo(
    () => tokenPositions.map(tokenPos => tokenPositionToSupplyPositionRow(tokenPos, erc20InfoMap)),
    [tokenPositions, erc20InfoMap]
  );

  const data: SupplyPositionsRow[] = useMemo(() => nftRows.concat(tokenRows), [nftRows, tokenRows]);

  const totalNetWorthPositionInUsd = useMemo(
    () => totalSuppliedPositionsInUsd.minus(totalBorrowedPositionInUsd),
    [totalSuppliedPositionsInUsd, totalBorrowedPositionInUsd]
  );

  const { supplyETHValidator } = useSupplyETHValidator();

  const handleSupply = useCallback(
    (row: SupplyPositionsRow, walletType?: WalletType) => {
      if (row.symbol === ERC721Symbol.SF_VLDR) {
        supplyETHValidator(walletType!);
      } else {
        openNFTSupplyModal?.(row.position as NftPosition, walletType!);
      }
    },
    [supplyETHValidator, openNFTSupplyModal]
  );
  const handleSupplyFromOtherProtocols = useCallback(
    ({ symbol }: SupplyPositionsRow) => {
      supplyNftFromBendDao(symbol as ERC721Symbol);
    },
    [supplyNftFromBendDao]
  );

  const handleWithdraw = useCallback(
    (row: SupplyPositionsRow) => {
      if (row.type === SupplyPositionsRowType.ERC20) {
        withdrawERC20(row.position.symbol as ERC20Symbol);
      } else {
        withdrawERC721(row.symbol as ERC721Symbol, row.name);
      }
    },
    [withdrawERC20, withdrawERC721]
  );

  const walletBalanceMap = useWalletBalanceMap();
  const bendDaoBalanceMap = useBendDaoBalanceMap();

  const { erc20Config, nativeTokenAndDerivatives } = useAppConfig();
  const columns: DataGridColumn<SupplyPositionsRow>[] = useMemo(
    () =>
      createColumns({
        inLiquidation,
        effectiveCapeSupplyApy,
        onSupply: handleSupply,
        onSupplyFromOtherProtocols: handleSupplyFromOtherProtocols,
        onSupplyERC20FromAA: symbol => openERC20SupplyModal(symbol, 'AA'),
        onSupplyERC20FromEOA: symbol => openERC20SupplyModal(symbol, 'EOA'),
        onWithdraw: handleWithdraw,
        walletBalanceMap,
        bendDaoBalanceMap,
        onToggleCollateral: (row: SupplyPositionsRow) => {
          collateralOpenModal({ ...row, isNft: false });
        },
        erc20Config,
        nativeTokenAndDerivatives
      }),
    [
      inLiquidation,
      effectiveCapeSupplyApy,
      handleSupply,
      handleSupplyFromOtherProtocols,
      openERC20SupplyModal,
      handleWithdraw,
      walletBalanceMap,
      bendDaoBalanceMap,
      collateralOpenModal,
      erc20Config,
      nativeTokenAndDerivatives
    ]
  );

  const expandableMeta: DataGridProps<SupplyPositionsRow>['expandable'] = useMemo(
    () => ({
      rowExpandable: ({ type, position }) => {
        // nft or eth derivatives
        return (
          type === SupplyPositionsRowType.ERC721 || !isEmpty((position as TokenPosition).subRows)
        );
      },
      renderExpandable: ({ data: { type, symbol, position, subRows } }) => {
        if (type === SupplyPositionsRowType.ERC20) {
          return <DataGrid hideColumnsTitle columns={columns} data={subRows || []} />;
        }
        const ItemsGrid =
          symbol === ERC721Symbol.UNISWAP_LP ? UniSwapV3ItemsGrid : NFTCollectionItemsGrid;

        return (
          <ItemsGrid
            symbol={symbol as ERC721Symbol}
            onToggleItemCollateral={id => {
              collateralOpenModal({ ...position, id, isNft: true });
            }}
            inLiquidation={inLiquidation}
          />
        );
      }
    }),
    [inLiquidation, columns, collateralOpenModal]
  );

  const handleToggleCollateral = collateralOpenModal;

  return {
    nftInfoMap,
    erc20InfoMap,
    columns,
    data,
    assets,
    totalSuppliedPositionsInUsd,
    totalNetWorthPositionInUsd,
    expandableMeta,
    inLiquidation,
    handlers: {
      handleSupply,
      handleSupplyERC20FromAA: (symbol: ERC20Symbol) => openERC20SupplyModal(symbol, 'AA'),
      handleSupplyERC20FromEOA: (symbol: ERC20Symbol) => openERC20SupplyModal(symbol, 'EOA'),
      handleSupplyFromOtherProtocols,
      handleWithdraw,
      handleToggleCollateral
    },
    modalHolders: [collateralHolder]
  };
};
