import { useState, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import { useTimelock, useTimelockParams } from '@/apps/paraspace/pages/hooks/Timelock';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { useWeb3Context } from '@/apps/paraspace/contexts';

export const useTimelockTermsCheck = (amount: number, symbol: ERC20Symbol | ERC721Symbol) => {
  const [timelockTermsChecked, setTimelockTermsChecked] = useState(false);
  const [isInTimelockWhiteList, setIsInTimelockWhiteList] = useState(false);
  const { account } = useWeb3Context();

  const { isTimeLockWhiteListed } = useTimelock();

  const handleTimelockTermsCheck = useCallback((value: boolean) => {
    setTimelockTermsChecked(value);
  }, []);

  const { waitTime, strategyData } = useTimelockParams(amount, symbol);

  useAsyncEffect(async () => {
    const isInWhiteList = (await isTimeLockWhiteListed([account]))?.[0];
    setIsInTimelockWhiteList(isInWhiteList ?? false);
  }, [account]);

  const claimableTime = useMemo(() => {
    if (waitTime) {
      return dayjs().add(waitTime, 'second').toDate();
    }
    return dayjs().toDate();
  }, [waitTime]);

  const shouldAddToTimelockQueue = useMemo(() => {
    if (isInTimelockWhiteList) {
      return false;
    }
    if (waitTime && strategyData) {
      return waitTime > strategyData.minWaitTime;
    }
    return false;
  }, [isInTimelockWhiteList, strategyData, waitTime]);

  return {
    timelockTermsChecked,
    handleTimelockTermsCheck,
    claimableTime,
    shouldAddToTimelockQueue,
    strategyData
  };
};
