import { FC, memo } from 'react';
import { Icon, H1, Stack, Text, CardProps, Card } from '@parallel-mono/components';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { Link } from '@/apps/paraspace/components';

const Container = styled(Stack)`
  max-width: 650px;
  margin: 40px auto;
`;

const Description = styled(Text)`
  text-align: center;
`;

const Title = styled(H1)`
  text-align: center;
`;

type MaintenanceProps = Omit<CardProps, 'children'>;

export const Maintenance: FC<MaintenanceProps> = memo(props => {
  return (
    <Card border {...props}>
      <Container justifyContent="center" alignItems="center">
        <Icon size="5rem" name="wrench" />
        <Stack alignItems="center">
          <Title>We are working on reducing gas fee</Title>
          <Description>
            We heard you and we are making some upgrades to further optimize gas fees. Please check
            back in 36-48 hours. In the meantime, if you have any active staking or listing, please
            cancel them under{' '}
            <Link as={RouterLink} to={absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS.MY_POSITION}>
              My Position
            </Link>
            .
          </Description>
        </Stack>
      </Container>
    </Card>
  );
});
