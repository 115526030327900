import BigNumberJs from 'bignumber.js';

import { ApeCoinListing, ApeCoinSourceToJoinApeListing } from '../../../../../types';

import { ApeStakingMainTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/ApePairing/consts';

export const validate = ({
  apeCoinListing,
  apeCoinSource,
  apeListingToken,
  bakcListingToken,
  apeBalance,
  cApeBalance,
  shareOfApe,
  shareOfBakc,
  shareOfApeCoin
}: {
  apeCoinListing: Maybe<ApeCoinListing>;
  apeCoinSource: ApeCoinSourceToJoinApeListing;
  apeListingToken: Maybe<{
    symbol: ApeStakingMainTokenSymbol;
    tokenId: number;
  }>;
  bakcListingToken: Maybe<{
    symbol: ERC721Symbol.BAKC;
    tokenId: number;
  }>;
  apeBalance: BigNumberJs;
  cApeBalance: BigNumberJs;
  shareOfApe: Maybe<BigNumberJs>;
  shareOfApeCoin: Maybe<BigNumberJs>;
  shareOfBakc: Maybe<BigNumberJs>;
}) => {
  if (apeListingToken === null) {
    return 'Please select a MAYC/BAYC token';
  }
  if (bakcListingToken === null) {
    return 'Please select a BAKC token';
  }
  if (apeCoinListing === null) {
    const amount = STAKE_LIMIT[ERC721Symbol.BAKC];
    if (apeCoinSource === ApeCoinSourceToJoinApeListing.APE_BALANCE && apeBalance.lt(amount)) {
      return 'Insufficient APE balance';
    }

    if (apeCoinSource === ApeCoinSourceToJoinApeListing.CAPE_BALANCE && cApeBalance.lt(amount)) {
      return 'Insufficient CAPE balance';
    }
  }

  if (shareOfBakc === null || shareOfApe === null || shareOfApeCoin === null) {
    return 'Please input the share you want';
  }

  return null;
};
