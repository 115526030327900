import { memo, useCallback, useRef } from 'react';
import BigNumber from 'bignumber.js';

import { FormSubmitter } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useAppConfig, useCAPE, useContractsMap } from '@/apps/paraspace/hooks';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useParaAccountTransfer } from '@/apps/paraspace/pages/hooks';

type FormData = { amount: BigNumber; shouldClaimToEOA: boolean };

type WithdrawCAPESubmitterProps = {
  formData: FormData;
  onFinish: () => void;
  onError: () => void;
};

export const WithdrawCAPESubmitter = memo(
  ({ formData, onFinish, onError }: WithdrawCAPESubmitterProps) => {
    const formDataRef = useRef(formData);
    const {
      provider,
      authentication: {
        meta: { account: EOAAccount }
      }
    } = useWeb3Context();
    const { erc20Config } = useAppConfig();
    const contracts = useContractsMap();

    const { transferERC20 } = useParaAccountTransfer();

    const { withdrawCAPE } = useCAPE();

    const generateWithdrawTx = useCallback(async () => {
      return withdrawCAPE(formDataRef.current.amount.toString(10));
    }, [withdrawCAPE]);

    const generateTransferTx = useCallback(() => {
      const { decimals, contractName } = erc20Config[ERC20Symbol.APE];
      const contractAddress = contracts[contractName];
      const transferAmount = BigNumber(formData.amount).shiftedBy(decimals).toString(10);
      return transferERC20(EOAAccount, contractAddress, transferAmount);
    }, [EOAAccount, contracts, erc20Config, formData.amount, transferERC20]);

    const submit = useCallback(async () => {
      const withdrawTx = generateWithdrawTx();
      let withdrawAndTransferTxs = [withdrawTx];
      if (formData.shouldClaimToEOA) {
        const transferTxs = generateTransferTx();
        withdrawAndTransferTxs = withdrawAndTransferTxs.concat(transferTxs!);
      }

      return Promise.all(withdrawAndTransferTxs).then(async txArray => {
        const txs = txArray.map(it => ({
          to: it?.to ?? '',
          value: it?.value?.toString() ?? '0',
          data: it?.data ?? ''
        }));
        return provider.submitTransactionsWithParaAccount(txs);
      });
    }, [generateWithdrawTx, formData.shouldClaimToEOA, generateTransferTx, provider]);

    return (
      <FormSubmitter
        inProgressMessage="Withdraw cAPE from Balance"
        onError={onError}
        onFinish={onFinish}
        submit={submit}
      />
    );
  }
);
