import { memo } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { ConnectWalletMessage } from './ConnectWalletMessage';
import { UniSwapV3SupplyForm } from './UniSwapV3SupplyForm';
import { UniSwapV3SupplyStepper } from './UniSwapV3SupplyStepper';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ERC20Symbol, WalletType } from '@/apps/paraspace/typings';
import {
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure,
  ErrorState,
  SuccessState
} from '@/apps/paraspace/components';

export type UniSwapV3SupplyModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  pair: [ERC20Symbol, ERC20Symbol];
  walletType: WalletType;
  onClose: () => void;
  onError?: () => void;
  onFinish?: () => void;
};

export const UniSwapV3SupplyModal = memo(
  ({
    isOpen,
    pair,
    walletType,
    onError,
    onFinish,
    onClose,
    ...others
  }: UniSwapV3SupplyModalProps) => {
    const { isUsingUserWallet } = useWeb3Context();
    const { phase, submittedFormData, handleFormSubmit, handleSubmitFailed, handleSubmitSuccess } =
      useCollectAndSubmitProcedure<number[]>({
        running: isOpen,
        onError,
        onFinish
      });

    if (!isUsingUserWallet) {
      return (
        <Modal onClose={onClose} isOpen={isOpen} size="500px" title="Supply NFT">
          <ConnectWalletMessage />
        </Modal>
      );
    }

    return (
      <Modal
        title={`Supply Uniswap ${pair[0]}/${pair[1]}`}
        closeOnBackdropClick={false}
        size={phase === CollectAndSubmitProcedurePhase.Collecting ? '45rem' : '30rem'}
        isOpen={isOpen}
        onClose={onClose}
        {...others}
      >
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <UniSwapV3SupplyForm pair={pair} walletType={walletType} onSubmit={handleFormSubmit} />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <UniSwapV3SupplyStepper
            formData={{ tokenIds: submittedFormData!, walletType }}
            onFinish={handleSubmitSuccess}
            onError={handleSubmitFailed}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            onAction={onClose}
            desc={`Successfully supplied ${submittedFormData?.length} Uniswap.`}
            actionButtonText="Done"
          />
        )}
      </Modal>
    );
  }
);
