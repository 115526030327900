import { useLayoutEffect, memo } from 'react';
import { Container } from '@parallel-mono/components';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import { RouteObject, useLocation, useRoutes } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { MainWrapper } from '../components';
import { AutoCompoundApeProvider } from '../pages/contexts/AutoCompoundApeProvider';
import { useScrollContainer, useToggle } from '../contexts';
import { ETHValidatorManagerProvider } from '../pages/contexts/ETHValidatorProvider';

import { useAppRoutes } from './routes';

import { Feature } from '@/apps/paraspace/config';
import { useAppConfig, useFeatureControlledComponent } from '@/apps/paraspace/hooks';

const MemoRoutes = ({ routes }: { routes: RouteObject[] }) => {
  const routeElements = useRoutes(routes);
  return routeElements;
};

const AppContainer = styled(Container).attrs({
  borderRadius: 0
})`
  min-height: 100%;
  position: relative;
  background-color: transparent;
`;

export const App = memo(() => {
  const { lastCommit } = useAppConfig();
  const { pathname, search } = useLocation();
  const scrollContainer = useScrollContainer();

  useLayoutEffect(() => {
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  }, [pathname, scrollContainer]);

  useDebounce(() => ReactGA.send({ hitType: 'pageview', page: pathname + search }), 500, [
    pathname,
    search
  ]);

  const routes = useAppRoutes();

  const toggleLastCommit = useToggle('TOGGLE_LAST_COMMIT');

  const FeatureControlledAutoCompoundApeProvider = useFeatureControlledComponent(
    Feature.ApeStaking,
    AutoCompoundApeProvider
  );

  const FeatureControlledETHValidatorManagerProvider = useFeatureControlledComponent(
    Feature.EthValidator,
    ETHValidatorManagerProvider
  );

  return (
    <FeatureControlledAutoCompoundApeProvider>
      <FeatureControlledETHValidatorManagerProvider>
        <AppContainer>
          <MainWrapper>
            <MemoRoutes routes={routes} />
            {toggleLastCommit && lastCommit && <div>Last Commit: {lastCommit}</div>}
          </MainWrapper>
        </AppContainer>
      </FeatureControlledETHValidatorManagerProvider>
    </FeatureControlledAutoCompoundApeProvider>
  );
});
