import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { find } from 'lodash';

import { BRIDGE_SERVICE, BridgeNetworks, SupportedChainId } from '../../configs';
import { supportedERC20sMap } from '../../configs/erc20s';

import { ActivityDetail, Event } from './types';

import { Maybe } from '@/apps/parax/typings/basic';
import { useEOAProvider } from '@/apps/parax/contexts';

type Chain = {
  chainId?: number;
  eId: number;
};

type ActivityResponse = {
  totalCount: number;
  pagination: {
    page: number;
    size: number;
  };
  items: {
    id: string;
    type: 'withdraw' | 'deposit';
    fromChain: Chain;
    toChain: Chain;
    metadata: {
      data: {
        amount: string;
      };
      from: string;
      to: string;
    };
    receivedEvent?: Event;
    sendEvent: Event;
  }[];
};

const getChainId = (chain: Chain) => {
  if (chain.chainId) {
    return chain.chainId;
  }
  return find(BridgeNetworks, v => v.eId === chain.eId)!.chainId;
};

const DEFAULT_PAGE_SIZE = 5;

export const useERC20Activities = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activities, setActivities] = useState<Maybe<ActivityDetail[]>>(null);
  const [pageInfo, setPageInfo] =
    useState<Maybe<{ page: number; size: number; totalPage: number }>>(null);

  const { account } = useEOAProvider();

  const fetchActivities = useCallback(
    async (page: number) => {
      try {
        setIsLoading(true);
        const response = await axios.get<ActivityResponse>(
          `${BRIDGE_SERVICE}/activity/${account}?page=${page}&size=${DEFAULT_PAGE_SIZE}`
        );
        const { totalCount, pagination, items } = response.data;

        const formattedActivities = items.map(activity => {
          const fromChainId: SupportedChainId = getChainId(activity.fromChain);
          const toChainId: SupportedChainId = getChainId(activity.toChain);

          const fromChainERC20s = supportedERC20sMap[fromChainId];

          const targetERC20 = fromChainERC20s.find(v => {
            if (activity.type === 'deposit') {
              return v.parallelBridgeAddress === activity.sendEvent.contract;
            }
            return v.address === activity.sendEvent.contract;
          });
          if (!targetERC20) {
            throw new Error('Failed to find the target erc20');
          }

          const amount = BigNumber(activity.metadata.data.amount).shiftedBy(-targetERC20.decimals);
          return {
            id: activity.id,
            type: activity.type,
            chains: {
              from: fromChainId,
              to: toChainId
            },
            metadata: {
              amount,
              symbol: targetERC20.symbol,
              from: activity.metadata.from,
              to: activity.metadata.to
            },
            sendEvent: activity.sendEvent,
            receivedEvent: activity.receivedEvent
          };
        });

        setPageInfo({
          totalPage: Math.ceil(totalCount / DEFAULT_PAGE_SIZE),
          page: pagination.page,
          size: DEFAULT_PAGE_SIZE
        });
        setActivities(formattedActivities);
      } catch (e) {
        console.error('Fetch Bridge Activity failed, error: ', e);
      } finally {
        setIsLoading(false);
      }
    },
    [account]
  );

  useEffect(() => {
    fetchActivities(1);
  }, [fetchActivities]);

  return { isLoading, activities, refetch: fetchActivities, pageInfo };
};
