import { memo, useMemo, useCallback } from 'react';
import { TransactionReceipt } from '@ethersproject/providers';
import BigNumber from 'bignumber.js';

import { FormSubmitter } from '../FormSubmitter';

import { MINIMUM_ACCOUNTABLE_NUM } from '@/apps/paraspace/pages/config';
import { TimelockStepIntro } from '@/apps/paraspace/components';
import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useTimelock } from '@/apps/paraspace/pages/hooks/Timelock';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type WithdrawERC20SubmitterProps = {
  formData: {
    amount: BigNumber;
    symbol: ERC20Symbol;
  };
  onFinish: (agreementId: Maybe<string>, releaseTime: Maybe<Date>) => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const WithdrawERC20Submitter = memo(
  ({ formData, onFinish, onError }: WithdrawERC20SubmitterProps) => {
    const { withdrawERC20 } = usePool();
    const { erc20InfoMap } = useMMProvider();
    const { submitTransactions } = useWeb3Context();
    const { symbol, amount } = formData;
    const { suppliedAmount, address } = erc20InfoMap[symbol];
    const { extractEventData } = useTimelock();

    const useMax = useMemo(
      () =>
        suppliedAmount
          ?.minus(amount ?? 0)
          .abs()
          .lt(MINIMUM_ACCOUNTABLE_NUM),
      [amount, suppliedAmount]
    );

    const handleFinish = useCallback(
      ([result]: Maybe<TransactionReceipt>[]) => {
        const { logs = [] } = result ?? {};
        const [{ releaseTime = null, agreementId = null } = {}] = extractEventData<{
          releaseTime: number;
          agreementId: string;
        }>('AgreementCreated', logs);

        onFinish?.(agreementId, releaseTime === null ? null : new Date(releaseTime * 1000));
      },
      [extractEventData, onFinish]
    );

    const submit = useCallback(async () => {
      const tx = await withdrawERC20(address, amount.toString(10), useMax);
      return submitTransactions([tx!]);
    }, [address, amount, useMax, withdrawERC20, submitTransactions]);

    return (
      <FormSubmitter
        inProgressMessage="Securely Withdrawing"
        onError={onError}
        onFinish={handleFinish}
        submit={submit}
      >
        <TimelockStepIntro />
      </FormSubmitter>
    );
  }
);
