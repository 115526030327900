import { useCallback, useMemo } from 'react';
import { FaucetService } from 'paraspace-utilities-contract-helpers';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';
import submitTransaction from '@/apps/paraspace/utils/submitTransaction';

const useMockTokenFaucet = (customizedFaucetAddr?: string) => {
  const { provider } = useWeb3Context();
  const contracts = useContractsMap();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new FaucetService(provider, customizedFaucetAddr || contracts.MockTokenFaucet);
  }, [contracts.MockTokenFaucet, customizedFaucetAddr, provider]);

  const mintTokenToAddress = useCallback(
    async (userAddress: string) => {
      if (!provider || !service) {
        return null;
      }
      const tx = (await service.mint({ userAddress }))[0];
      const txRes = submitTransaction({ provider, tx });
      return txRes;
    },
    [provider, service]
  );

  return { mintTokenToAddress };
};

export default useMockTokenFaucet;
