import { Inline, Icon, H6, InlineProps, Skeleton, Spinner } from '@parallel-mono/components';
import { memo, useCallback } from 'react';
import styled from 'styled-components';

import { useAAProvider, useEOAProvider } from '../../../contexts';

import { useCreateParaAccount } from '@/apps/parax/hooks';

type CreateParaAccountProps = Omit<InlineProps, 'children'>;

const Container = styled(Inline)<{ disabled?: boolean }>`
  opacity: ${({ theme, disabled }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
  min-width: 6.75rem;
`;

const NoWrapH6 = styled(H6)`
  white-space: nowrap;
`;

export const CreateParaAccount = memo(({ ...props }: CreateParaAccountProps) => {
  const { account: eoaAccount } = useEOAProvider();
  const { loading: isLoadingAccount } = useAAProvider();
  const { loading: isCreating, createParaAccountHandle } = useCreateParaAccount();

  const createHandle = useCallback(() => {
    if (isCreating || isLoadingAccount || !eoaAccount) return;
    createParaAccountHandle();
  }, [isCreating, isLoadingAccount, eoaAccount, createParaAccountHandle]);

  return (
    <Container
      data-escape-account-drawer
      alignItems="center"
      justifyContent="center"
      inset="6px 1rem 6px 0.5rem"
      gap="0.25rem"
      onClick={createHandle}
      disabled={isCreating || isLoadingAccount}
      {...props}
    >
      {isCreating && (
        <Inline justifyContent="center" gap="0.5rem">
          <Spinner size="small" />
          <H6>Creating</H6>
        </Inline>
      )}
      {isLoadingAccount && <Skeleton.Title height="1.5rem" width="5rem" />}
      {!(isCreating || isLoadingAccount) && (
        <>
          <Icon name="plusCircle" strokeWidth={2} size="1.25rem" />
          <NoWrapH6>Create Parallel Account</NoWrapH6>
        </>
      )}
    </Container>
  );
});
