export enum FormState {
  MAINFORM = 0,
  BAKC = 1,
  REVIEW = 2,
  STEPPER = 3,
  ERROR = 4,
  SUCCESS = 5
}

export enum STAKE_LIMIT {
  BAYC = 10094,
  MAYC = 2042,
  BAKC = 856
}

export enum CommonFormState {
  MAINFORM = 0,
  APPROVE = 1,
  PROCESSING = 2,
  ERROR = 3,
  SUCCESS = 4
}
