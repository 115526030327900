import { memo } from 'react';
import { H3, Text, Stack, Button, StackProps, Icon } from '@parallel-mono/components';
import { useTheme } from 'styled-components';

type ClaimAllFormProps = Omit<StackProps, 'children'> & {
  onSuccess: () => void;
  tip: string;
  describe: string;
};

export const SuccessState = memo(({ tip, describe, onSuccess, ...props }: ClaimAllFormProps) => {
  const theme = useTheme();
  return (
    <Stack alignItems="center" {...props}>
      <Icon name="checkContained" size="xlarge" color={theme.skin.success.main} />
      <Stack alignItems="center" gap="0.25rem">
        <H3>{tip}</H3>
        <Text skin="secondary">{describe}</Text>
      </Stack>
      <Button block size="large" onClick={onSuccess} skin="secondary">
        Done
      </Button>
    </Stack>
  );
});
