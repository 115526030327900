import { FC } from 'react';

import { ListTokensContextProvider } from './ListTokensContext';
import { ListTokensManagerProvider } from './ListTokensManageProvider';

export const ListTokensProvider: FC = ({ children }) => {
  return (
    <ListTokensContextProvider>
      <ListTokensManagerProvider>{children}</ListTokensManagerProvider>
    </ListTokensContextProvider>
  );
};
