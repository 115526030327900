import { Environment } from 'parax-sdk';
import { development, production, staging } from 'paraspace-configs-v2';

const configsMap = {
  [Environment.DEVELOPMENT]: development,
  [Environment.STAGING]: staging,
  [Environment.PRODUCTION]: production
};

export const getContractsConfig = (env: Environment) => configsMap[env];
