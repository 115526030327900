import { noop } from 'lodash';
import { useCallback, useState } from 'react';

import { SupplyNftFromBendDAOModalProps } from '../../modals';

import { ERC721Symbol } from '@/apps/paraspace/typings';

const defaultModalProps: SupplyNftFromBendDAOModalProps = {
  isOpen: false,
  symbol: ERC721Symbol.MAYC,
  onFinish: noop,
  onError: noop,
  onClose: noop
};

export const useSupplyNftFromBendDAO = (): [
  SupplyNftFromBendDAOModalProps,
  (symbol: ERC721Symbol) => Promise<void>
] => {
  const [modalProps, setModalProps] = useState<SupplyNftFromBendDAOModalProps>(defaultModalProps);

  const supplyNftFromBendDao = useCallback((symbol: ERC721Symbol) => {
    return new Promise<void>((resolve, reject) => {
      setModalProps(curr => ({
        ...curr,
        isOpen: true,
        symbol,
        onError: reject,
        onFinish: resolve,
        onClose: () => {
          setModalProps(prev => ({ ...prev, isOpen: false }));
        }
      }));
    });
  }, []);

  return [modalProps, supplyNftFromBendDao];
};
