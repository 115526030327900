export const TWITTER_URL = 'https://twitter.com/ParallelFi';
export const DISCORD_URL = 'https://discord.gg/rdjVz8zavF';
export const GITHUB_URL = 'https://github.com/para-space/paraspace-core';
export const DOCS_URL = 'https://docs.parax.ai';
export const DEVELOPER_DOCS_LINK = `${DOCS_URL}/paraspace`;
export const LANDING_PAGE_URL = 'https://parallel.fi';
export const CHINALINK_FLOOR_PRICE_LINK = 'https://docs.chain.link/data-feeds/nft-floor-price';
export const BEACONCHA_URL = `https://beaconcha.in`;
export const PARALLEL_POLKADOT_APP_LINK = 'https://app-polkadot.parallel.fi';
export const PARALLEL_KUSAMA_APP_LINK = 'https://app-kusama.parallel.fi';
export const PARALLEL_POLKADOT_CROWDLOAN_APP_LINK = 'https://crowdloan.parallel.fi';
export const PARALLEL_KUSAMA_CROWDLOAN_APP_LINK = 'https://crowdloan-kusama.parallel.fi';
export const PARALLEL_ASSET_URL = 'https://biz-assets.parallel.fi';
