import styled from 'styled-components';
import { Button, H5, Icon, Inline } from '@parallel-mono/components';
import { ChainId } from '@parallel-utils/contracts-registry';

import { getUserFriendlyError } from '../../../utils';
import useTokenFaucet from '../hooks/useFaucet';

import { useEOAProvider, useParaXToast } from '@/apps/parax/contexts';
import config from '@/apps/parax/config';

const MintBannerWrapper = styled(Inline).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})`
  width: 100%;
  min-height: 3rem;
  align-self: center;
  background-color: #ffbc0e;
  ${({ theme }) => theme.breakpoints.down('lg')`
      width: 100%;
      height: auto;
      margin-top: 0;
  `};
`;

const StyledButton = styled(Button)`
  color: var(--clr-blue);
  text-decoration: underline;
`;

export const MintBanner = ({ mintCallBack }: { mintCallBack?: () => void }) => {
  const { account, isUsingUserWallet, chainId, switchNetwork } = useEOAProvider();
  const { mintTokenToAddress } = useTokenFaucet('0x9A2C5733758C9E7E29Ae632EebA88F077DBCfde2');
  const toast = useParaXToast();

  const onMintClick = async () => {
    if (!isUsingUserWallet) {
      return;
    }
    if ((chainId as number) !== ChainId.Sepolia) {
      await toast.promise(switchNetwork(ChainId.Sepolia as number));
    }
    await toast.promise(
      mintTokenToAddress(account)
        .then(async tx => {
          await tx?.wait(2);
          if (mintCallBack) {
            mintCallBack();
          }
          return { message: 'Mint successfully!' };
        })
        .catch(e => {
          throw getUserFriendlyError(e);
        })
    );
  };

  if (!config.isProductionOrForkMainnet) {
    return (
      <MintBannerWrapper gap=".75rem">
        <Icon name="purse" size="2rem" strokeWidth={2} />
        <H5 fontWeight="medium">
          <StyledButton disabled={!account} variant="link" onClick={onMintClick}>
            Get Sepolia network test tokens
          </StyledButton>
        </H5>
      </MintBannerWrapper>
    );
  }

  return null;
};
