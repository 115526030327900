import { Button, H6, Inline, Icon, SmallText } from '@parallel-mono/components';
import { useCallback } from 'react';

import { useApeStakeManager } from '../../../../../contexts';
import PanelCard from '../PanelCard';

import { AutoRepayTag, AutoSellTag } from './components';
import { useAutoSellStrategy } from './hooks';

import { Link, Tooltip } from '@/apps/paraspace/components';
import { DEVELOPER_DOCS_LINK } from '@/apps/paraspace/consts/externalLinks';

export const AutoSellPanel = () => {
  const { sellPercentage, sellToken, fetchAutoSellStrategy } = useAutoSellStrategy();

  const { setAutoSellStrategy } = useApeStakeManager();

  const handleSetAutoSellPercentage = useCallback(() => {
    setAutoSellStrategy({ sellPercentage, sellToken }).then(() => {
      fetchAutoSellStrategy();
    });
  }, [fetchAutoSellStrategy, sellPercentage, sellToken, setAutoSellStrategy]);

  return (
    <PanelCard
      title={
        <Inline gap="0.5rem" alignItems="center">
          <Icon name="clock" size="1.25rem" />
          <H6>Auto Profits</H6>
        </Inline>
      }
      coreContent={
        <SmallText skin="secondary">
          Auto profit settings are executed per{' '}
          <Link
            target="_blank"
            href={`${DEVELOPER_DOCS_LINK}/product-guide-and-walkthrough/ape-staking-guide#how-often-are-bayc-mayc-bakc-rewards-compounded`}
          >
            compound cycle
          </Link>
          .
        </SmallText>
      }
      items={[
        {
          key: 'autoRepay',
          field: (
            <Inline gap="0.25rem" alignItems="center">
              <SmallText skin="secondary">Auto Repay</SmallText>
              <Tooltip
                placement="top"
                content="Repays your outstanding cAPE debt automatically with your rewards at every compounding to maximize your yield."
              />
            </Inline>
          ),
          value: <AutoRepayTag autoSellPercentage={sellPercentage} />
        },
        {
          key: 'autoSell',
          field: (
            <Inline gap="0.25rem" alignItems="center">
              <SmallText skin="secondary">Auto Sell</SmallText>
              <Tooltip
                placement="top"
                content="Selling rewards to USDC via Uniswap and supply it to Lending automatically at every compounding."
              />
            </Inline>
          ),
          value: <AutoSellTag sellPercentage={sellPercentage} sellToken={sellToken} />
        }
      ]}
      actionButton={
        <Button block onClick={handleSetAutoSellPercentage} skin="secondary">
          {sellPercentage ? 'Change Sell Percentage' : 'Set Sell Percentage'}
        </Button>
      }
    />
  );
};
