import { Modal } from '@parallel-mono/components';

import { StakeInfoListItem } from '../../types';

import Form from './Form';

export type WithdrawModalProps = {
  isOpen: boolean;
  stakeItem: StakeInfoListItem;
  onClose: () => void;
};

const WithdrawModal = ({ stakeItem, onClose, ...others }: WithdrawModalProps) => {
  return (
    <Modal title="Withdraw" onClose={onClose} {...others}>
      <Form stakeItem={stakeItem} onClose={onClose} />
    </Modal>
  );
};

export default WithdrawModal;
