import { Environment } from 'parax-sdk';

import { networkConfigs } from '../networksConfig';
import { ContractMap } from '../types';

import { Feature, FEATURE_FOR_NETWORK_MAP } from './Features';
import { getContractsMap } from './contractsMap';
import { ERC20Config, getERC20Config, getNativeTokenOrTokenDerivatives } from './erc20Configs';
import { ERC721Config, getErc721Config } from './erc721Config';
import { GRAPHQL_ENDPOINT_FOR_NETWORK_MAP } from './graphqlEndpoint';
import { V1_ETHEREUM_GRAPHQL_ENDPOINT_MAP } from './V1GraphqlEndPointMap';
import { duneLinkMap } from './duneLinkMap';
import {
  MARKET_CURRENCY_INFO_WITH_NETWORK,
  MarketCurrencyTokenInfo
} from './marketCurrcyTokenInfoMap';
import { V1TimeLockContractAddressMap } from './V1TimeLockContractAddressMap';
import { nftImageApiMap } from './nftImageApiMap';

import { ERC20Symbol, SupportedNetworks } from '@/apps/paraspace/typings';

export type AppConfig = {
  erc20Config: ERC20Config;
  nativeTokenAndDerivatives: ERC20Symbol[];
  erc721Config: ERC721Config;
  contracts: ContractMap;
  graphqlEndpoint: string;
  explorerLinkBaseUrl: string;
  features: Feature[];
  duneLink: string;
  marketCurrencyTokenInfo: MarketCurrencyTokenInfo;
  v1TimeLockContractAddress: string;
  lastCommit: string;
  v1EthereumGraphqlEndPoint: string;
  nftImageApi: string;
};

export const getAppConfig = ({
  chainId,
  env
}: {
  chainId: SupportedNetworks;
  env: Environment;
}): AppConfig => ({
  erc20Config: getERC20Config(chainId, env),
  nativeTokenAndDerivatives: getNativeTokenOrTokenDerivatives(chainId, env),
  erc721Config: getErc721Config(chainId, env),
  contracts: getContractsMap(chainId, env),
  graphqlEndpoint: GRAPHQL_ENDPOINT_FOR_NETWORK_MAP[chainId][env],
  explorerLinkBaseUrl: networkConfigs[chainId].explorerLink[0],
  features: FEATURE_FOR_NETWORK_MAP[chainId],
  duneLink: duneLinkMap[chainId],
  marketCurrencyTokenInfo: MARKET_CURRENCY_INFO_WITH_NETWORK[chainId],
  v1TimeLockContractAddress: V1TimeLockContractAddressMap[env],
  lastCommit: process.env.REACT_APP_LAST_COMMIT as string,
  v1EthereumGraphqlEndPoint: V1_ETHEREUM_GRAPHQL_ENDPOINT_MAP[env],
  nftImageApi: nftImageApiMap[env]
});
