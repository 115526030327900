import { memo, useMemo } from 'react';
import { Button, Image, useBreakpoints } from '@parallel-mono/components';
import { HostedImage } from '@parallel-mono/business-components';
import styled from 'styled-components';

import { DropdownMenu, DropdownMenuProps } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol, WalletType } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';

type StakeFromDropDownButtonProps = Omit<DropdownMenuProps, 'options' | 'menuTrigger'> & {
  disabled?: boolean;
  onStake: (source: WalletType) => void;
};

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;
`;

export const StakeFromDropDownButton = memo(
  ({ disabled, onStake, ...others }: StakeFromDropDownButtonProps) => {
    const { authentication } = useWeb3Context();
    const { erc20InfoMap } = useMMProvider();
    const { erc20BalanceMap } = useEOABalances();

    const eoaBalance = erc20BalanceMap?.[ERC20Symbol.APE] ?? zero;
    const aaBalance = erc20InfoMap?.[ERC20Symbol.APE]?.balance ?? zero;

    const options = useMemo(
      () => [
        {
          icon: (
            <HostedImage
              name="design/PDS_V3/logo/parallel-v2-logo"
              height="1.25rem"
              width="1.25rem"
            />
          ),
          label: 'AA Wallet',
          value: formatBalance(aaBalance),
          onClick: () => {
            onStake('AA');
          }
        },
        {
          icon: (
            <Image
              src={authentication.meta.walletIcon ?? undefined}
              height="1.25rem"
              width="1.25rem"
            />
          ),
          label: authentication.meta.walletType,
          value: formatBalance(eoaBalance),
          onClick: () => {
            onStake('EOA');
          }
        }
      ],
      [authentication, onStake, aaBalance, eoaBalance]
    );

    const { mobile } = useBreakpoints();

    const menuTrigger = useMemo(
      () => (
        <Button disabled={disabled} size={mobile ? 'small' : 'medium'} block>
          Stake
        </Button>
      ),
      [disabled, mobile]
    );
    return (
      <StyledDropdownMenu
        placement="bottom"
        gap="0"
        options={options}
        menuTrigger={menuTrigger}
        title="Stake From"
        {...others}
      />
    );
  }
);
