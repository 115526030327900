import { memo, useCallback, useMemo, useState } from 'react';
import { Stack, StackProps } from '@parallel-mono/components';
import { chunk } from 'lodash';

import { WITHDRAW_NFT_CHUNK_SIZE } from './consts';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import {
  ApproveERC721Submitter,
  Stepper,
  WithdrawERC721Submitter
} from '@/apps/paraspace/components';
import { useContractsMap } from '@/apps/paraspace/hooks';

type WithdrawERC721SteppersProps = Omit<StackProps, 'children'> & {
  formData: {
    symbol: ERC721Symbol;
    name: string;
    tokenIds: number[];
  };
  onFinish: (claimed: boolean) => void;
  onError: () => void;
};

export const WithdrawERC721Steppers = memo(
  ({ formData, onFinish, onError, ...others }: WithdrawERC721SteppersProps) => {
    const { symbol, name, tokenIds } = formData;

    const {
      nftInfoMap: { [ERC721Symbol.CRYPTOPUNKS]: { xTokenAddress = '' } = {} }
    } = useMMProvider();

    const contracts = useContractsMap();

    const [step, setStep] = useState(0);

    const handleWithdrawn = useCallback(async () => {
      onFinish(false);
    }, [onFinish]);

    const handleNext = useCallback(() => {
      setStep(curr => curr + 1);
    }, []);

    const withdrawChunks = useMemo(() => chunk(tokenIds, WITHDRAW_NFT_CHUNK_SIZE), [tokenIds]);

    const steps = useMemo(
      () => [
        {
          description: `Approve ${name}`,
          content: (
            <ApproveERC721Submitter
              formData={{
                name,
                assetAddress: xTokenAddress,
                spender: contracts.WPunkGatewayProxy
              }}
              onError={onError}
              onFinish={handleNext}
            />
          ),
          skip: symbol !== ERC721Symbol.CRYPTOPUNKS
        },
        ...withdrawChunks.map((nftChunk, index) => {
          return {
            description:
              withdrawChunks.length > 1
                ? `Withdraw ${name}, Chunk ${index + 1}`
                : `Withdraw ${name}`,
            content: (
              <WithdrawERC721Submitter
                key={`${[nftChunk[0]]}-${index}`}
                formData={{
                  symbol,
                  tokenIds: nftChunk
                }}
                onFinish={index === withdrawChunks.length - 1 ? handleWithdrawn : handleNext}
                onError={onError}
              />
            )
          };
        })
      ],
      [
        name,
        xTokenAddress,
        contracts.WPunkGatewayProxy,
        onError,
        handleNext,
        symbol,
        withdrawChunks,
        handleWithdrawn
      ]
    );

    return (
      <Stack width="100%" {...others}>
        <Stepper step={step} steps={steps} />
      </Stack>
    );
  }
);
