import { ReactNode } from 'react';
import BigNumber from 'bignumber.js';

import { ERC20Info, ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';

export type NftPosition = {
  symbol: ERC721Symbol;
  collectionName: string;
  tokenId: number;
  auctionedTokens: number[];
};

export type CollectionPosition = {
  symbol: ERC721Symbol;
  collectionName: string;
  address: string;
  items: NftPosition[];
};

export type TokenPosition = {
  symbol: ERC20Symbol;
  subRows?: ERC20Info[];
  displayIcon?: ReactNode;
  rowExpandable?: boolean;
};

export enum SupplyPositionsRowType {
  ERC721 = 'ERC721',
  ERC20 = 'ERC20'
}

export type UniSwapV3Item = {
  name: string;
  id: number;
  symbol: ERC721Symbol;
  asCollateral: boolean;
  isInRange: boolean;
  isClosed: boolean;
};

export type SupplyPositionsRow = {
  type: SupplyPositionsRowType;
  name: string;
  cover: ReactNode;
  suppliedAmount: BigNumber | null;
  suppliedValue: BigNumber | null;
  supplyApyRate: BigNumber | null;
  rewardApy: BigNumber;
  symbol: ERC721Symbol | ERC20Symbol;
  address: string | null;
  usedAsCollateral: boolean | null;
  position: NftPosition | TokenPosition;

  subRows?: SupplyPositionsRow[];
};
