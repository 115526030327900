import { memo, useCallback } from 'react';

import usePool from '../../../../hooks/usePool';

import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { FormSubmitter } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';
import { useSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { ERC20Symbol } from '@/apps/paraspace/typings';

type RepayNonNativeTokenFromEOASubmitterProps = {
  formData: { amount: string; max: boolean; address: string; from?: string };
  onFinish: () => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

const RepayNonNativeTokenFromEOASubmitter = memo(
  ({ formData, onFinish, onError }: RepayNonNativeTokenFromEOASubmitterProps) => {
    const { amount, max, address, from } = formData;
    const { submitEOATransactions } = useWeb3Context();
    const { erc20BalanceMap } = useEOABalances();
    const { repayERC20 } = usePool();
    const symbol = useSymbolByContractAddress<ERC20Symbol>(address)!;

    const submit = useCallback(async () => {
      // The contract has a conditional judgment for the EOA repayment logic,
      // which requires (amountSent != type(unit256).max).
      // Therefore, when max is true here, special handling is performed, using balance to repay all the debt.
      const balance = erc20BalanceMap?.[symbol].toString(10) ?? '0';
      const normalizedAmount = max ? balance : amount;
      const repayTx = await repayERC20({ assetAddr: address, amount: normalizedAmount, from });

      return submitEOATransactions(repayTx!);
    }, [address, amount, erc20BalanceMap, from, max, repayERC20, submitEOATransactions, symbol]);

    return (
      <FormSubmitter
        inProgressMessage="Repaying"
        submit={submit}
        onFinish={onFinish}
        onError={onError}
      />
    );
  }
);

export default RepayNonNativeTokenFromEOASubmitter;
