import { find, flatMap, isEmpty } from 'lodash';
import { ERC721Symbol } from 'paraspace-configs-v2';
import { createContext, FC, memo, useCallback, useContext, useMemo } from 'react';

import { useGetListedStatusOnShopQuery } from '@/apps/paraspace/generated/graphql';
import {
  useGetContractAddressBySymbol,
  useGetSymbolByContractAddress
} from '@/apps/paraspace/hooks';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

type AssetsWithListedStatus = {
  symbol: ERC721Symbol;
  contractAddress: string;
  identifierOrCriteria: string;
  isListedOnParaSpace: boolean;
};

type ListedOnShopContextValue = {
  loading: boolean;
  getListedShopStatusByToken: (symbol: ERC721Symbol, tokenId: number) => boolean;
};

const ListedOnShopContext = createContext<ListedOnShopContextValue>({
  loading: true,
  getListedShopStatusByToken: () => false
});

export const ListedOnShopProvider: FC = memo(({ children }) => {
  const { nftInfoMap } = useMMProvider();
  const getContractAddressBySymbol = useGetContractAddressBySymbol();
  const getSymbolByContractAddress = useGetSymbolByContractAddress();

  const assets = useMemo(() => {
    const tokens = flatMap(
      [ERC721Symbol.BAYC, ERC721Symbol.BAKC, ERC721Symbol.MAYC],
      symbol =>
        nftInfoMap?.[symbol]?.nftSuppliedList?.map(identifierOrCriteria => ({
          contractAddress: getContractAddressBySymbol(symbol),
          identifierOrCriteria: String(identifierOrCriteria)
        })) || []
    );
    return isEmpty(tokens) ? null : tokens;
  }, [getContractAddressBySymbol, nftInfoMap]);

  const { data, loading } = useGetListedStatusOnShopQuery({
    variables: {
      assets
    },
    fetchPolicy: 'no-cache',
    pollInterval: 60000
  });

  const assetsWithListedStatus = useMemo(() => {
    return ((data?.checkNftListedOnParaSpace as AssetsWithListedStatus[]) || []).map(
      ({ contractAddress, identifierOrCriteria, isListedOnParaSpace }) => ({
        contractAddress,
        identifierOrCriteria,
        isListedOnParaSpace,
        symbol: getSymbolByContractAddress(contractAddress) as ERC721Symbol
      })
    );
  }, [data?.checkNftListedOnParaSpace, getSymbolByContractAddress]);

  const getListedShopStatusByToken = useCallback(
    (symbol: ERC721Symbol, tokenId: number) => {
      const isSupplied = nftInfoMap?.[symbol]?.nftSuppliedList?.includes(tokenId);
      return isSupplied
        ? !!find(assetsWithListedStatus, {
            symbol,
            identifierOrCriteria: String(tokenId)
          })?.isListedOnParaSpace
        : false;
    },
    [assetsWithListedStatus, nftInfoMap]
  );

  const isLoading = useMemo(
    () => isEmpty(assetsWithListedStatus) && loading,
    [assetsWithListedStatus, loading]
  );

  const contextValue: ListedOnShopContextValue = useMemo(
    () => ({
      loading: isLoading,
      getListedShopStatusByToken
    }),
    [getListedShopStatusByToken, isLoading]
  );

  return (
    <ListedOnShopContext.Provider value={contextValue}>{children}</ListedOnShopContext.Provider>
  );
});

export const useListedOnShop = () => useContext(ListedOnShopContext);
