import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Inline, Responsive, Spinner } from '@parallel-mono/components';
import styled from 'styled-components';
import { sumBy } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';
import { StakingType } from 'paraspace-utilities-contract-helpers';

import { useP2PStaking } from '../../contexts';
import { ApeCoinListing, ApeListing, BakcListing, Listing } from '../../types';

import { PositionList, SideBar } from './components';
import { EmptyPositionCard } from './components/EmptyPositionCard';
import { usePositions } from './hooks';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { zero } from '@/apps/paraspace/consts/values';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/OfficialPairing/ApeStakingManagerProvider/types';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { useApeStaking } from '@/apps/paraspace/pages/hooks/ApeStaking/useApeStaking';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { DAYS_OF_YEAR } from '@/apps/paraspace/consts/fixtures';

const SideBarWrapper = styled(Inline)`
  position: sticky;
  height: fit-content;
  top: calc(var(--header-height-pc) + 4.75rem);
  flex: 0 0 18.875rem;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    position: relative;
    top: 0;
    margin-bottom: 1rem;
    width: 100%;
  `};
`;

const StyledResponsive = styled(Responsive)`
  ${({ theme }) => theme.breakpoints.down('desktop')`
    flex-direction: column-reverse;
  `};
`;

export const MyPositions: FC = memo(() => {
  const { account } = useWeb3Context();
  const { getPoolApy } = useApeStaking();
  const [dailyRewards, setDailyRewards] = useState<BigNumber>();

  const { positions, loading, refetch } = usePositions();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const stakedInfos = useMemo(() => {
    const matchedListings = positions.filter(position => position.matched) ?? [];
    const apeStaked = sumBy(matchedListings, ({ apeCoinListing }) => {
      if (!apeCoinListing || apeCoinListing.offerer !== account) return 0;
      return STAKE_LIMIT[apeCoinListing.stakingPool];
    });
    const maycStaked = sumBy(matchedListings, ({ apeListing }) => {
      if (
        !apeListing ||
        apeListing.offerer !== account ||
        apeListing.stakingType !== StakingType.MAYCStaking
      )
        return 0;
      return 1;
    });
    const baycStaked = sumBy(matchedListings, ({ apeListing }) => {
      if (
        !apeListing ||
        apeListing.offerer !== account ||
        apeListing.stakingType !== StakingType.BAYCStaking
      )
        return 0;
      return 1;
    });
    const bakcStaked = sumBy(matchedListings, ({ bakcListing }) =>
      bakcListing && bakcListing.offerer === account ? 1 : 0
    );
    return {
      [ERC20Symbol.APE]: apeStaked,
      [ERC721Symbol.BAYC]: baycStaked,
      [ERC721Symbol.MAYC]: maycStaked,
      [ERC721Symbol.BAKC]: bakcStaked
    };
  }, [account, positions]);

  useAsyncEffect(async () => {
    const dailyPoolsApr = {
      [ERC721Symbol.MAYC]: (await getPoolApy(ERC721Symbol.MAYC)).div(DAYS_OF_YEAR),
      [ERC721Symbol.BAYC]: (await getPoolApy(ERC721Symbol.BAYC)).div(DAYS_OF_YEAR),
      [ERC721Symbol.BAKC]: (await getPoolApy(ERC721Symbol.BAKC)).div(DAYS_OF_YEAR)
    };
    const matchedPositions = positions.filter(position => position.matched) ?? [];
    const totalDailyRewards = sumBy(
      matchedPositions,
      ({ apeListing, apeCoinListing, bakcListing }) => {
        // BAKC pool listing
        if (bakcListing && apeCoinListing && apeListing) {
          const share = sumBy(
            [bakcListing, apeListing, apeCoinListing].filter(v => v.offerer === account),
            'share'
          );
          return dailyPoolsApr[ERC721Symbol.BAKC]
            .times(STAKE_LIMIT[ERC721Symbol.BAKC])
            .times(share);
        }
        // BAYC/MAYC pool listing
        if (!bakcListing && apeListing && apeCoinListing) {
          const isOwnedListing = apeListing.offerer === apeCoinListing.offerer;

          const accountShare =
            apeCoinListing.offerer === account ? apeCoinListing.share : apeListing.share;

          const totalShare = isOwnedListing ? 1 : accountShare;

          const capPerPosition = STAKE_LIMIT[apeListing.stakingPool];
          const dailyApr = dailyPoolsApr[apeListing.stakingPool];

          return dailyApr.times(capPerPosition).times(totalShare);
        }
        return 0;
      }
    );

    setDailyRewards(totalDailyRewards);
  }, [positions]);

  const { cancelListing, unmatchListing } = useP2PStaking();
  const handleCancelListing = useCallback(
    async (listing: Listing) => {
      await cancelListing(listing);
      refetch({
        walletAddress: account
      });
    },
    [cancelListing, refetch, account]
  );

  const handleUnmatchListing = useCallback(
    async ({
      blockHeight,
      matchedHash,
      apeListing,
      apeCoinListing,
      bakcListing
    }: {
      blockHeight: string;
      matchedHash: string;
      apeListing: ApeListing;
      apeCoinListing: ApeCoinListing;
      bakcListing: Maybe<BakcListing>;
    }) => {
      await unmatchListing({ blockHeight, matchedHash, apeListing, apeCoinListing, bakcListing });
      refetch({
        walletAddress: account
      });
    },
    [unmatchListing, refetch, account]
  );

  return (
    <StyledResponsive breakPoint="tablet">
      {loading && (
        <Inline width="100%" justifyContent="center">
          <Spinner />
        </Inline>
      )}
      {!loading && (
        <>
          {positions.length > 0 ? (
            <PositionList
              positions={positions}
              onCancelListing={handleCancelListing}
              onUnmatchListing={handleUnmatchListing}
              account={account}
            />
          ) : (
            <EmptyPositionCard />
          )}
          <SideBarWrapper>
            <SideBar
              apePoolStaked={stakedInfos.APE}
              maycPoolStaked={stakedInfos.MAYC}
              baycPoolStaked={stakedInfos.BAYC}
              bakcPoolStaked={stakedInfos.BAKC}
              dailyRewards={dailyRewards ?? zero}
            />
          </SideBarWrapper>
        </>
      )}
    </StyledResponsive>
  );
});

export { usePositions as useSharedPoolPositions } from './hooks';
export type { Position as SharedPoolPosition } from './types';
export { PositionCard as SharedPoolPositionCard } from './components';
