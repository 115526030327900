import { useCallback, useEffect, useRef, useState } from 'react';
import { uniqueId } from 'lodash';

import { FetchingStatus } from '@/apps/paraspace/typings';

type Props<S> = {
  pollingInterval: number;
  pollFn: () => Promise<S>;
  initialState: S;
  keepStateOnSwitchContext?: boolean;
};

export const useAutoPolledState = <State>({
  pollingInterval,
  pollFn,
  initialState,
  keepStateOnSwitchContext
}: Props<State>) => {
  const initialStateRef = useRef(initialState);
  const [pollingStatus, setPollingStatus] = useState<FetchingStatus>(FetchingStatus.INIT);
  const [state, setState] = useState<State>(initialState);

  const taskIdRef = useRef<string>();
  const refresh = useCallback(async () => {
    const taskId = uniqueId();
    taskIdRef.current = taskId;
    setPollingStatus(FetchingStatus.FETCHING);
    try {
      const result = await pollFn();
      if (taskIdRef.current === taskId) {
        setPollingStatus(FetchingStatus.SUCCESS);
        setState(result);
      }
    } catch {
      setPollingStatus(FetchingStatus.FAIL);
    }
  }, [pollFn]);

  useEffect(() => {
    setPollingStatus(FetchingStatus.INIT);
    if (!keepStateOnSwitchContext) {
      setState(initialStateRef.current);
    }
    refresh();
    const intervalId = setInterval(refresh, pollingInterval, {});
    return () => {
      clearInterval(intervalId);
    };
  }, [refresh, pollingInterval, keepStateOnSwitchContext]);

  return { refresh, state, pollingStatus };
};
