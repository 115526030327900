import { useCallback, useState } from 'react';

import { RevokeNftDelegationModalProps } from '../DelegateNftModal';
import { DelegationInfo } from '../../types';

const defaultModalProps: RevokeNftDelegationModalProps = {
  delegations: [],
  isOpen: false
};

export const useRevokeNftDelegation = (): [
  RevokeNftDelegationModalProps,
  (delegations: DelegationInfo[]) => Promise<void>
] => {
  const [modalProps, setModalProps] = useState<RevokeNftDelegationModalProps>(defaultModalProps);

  const closeModal = useCallback(() => {
    setModalProps(curr => ({
      ...curr,
      isOpen: false
    }));
  }, [setModalProps]);

  const revokeNftDelegation = useCallback(
    (delegations: DelegationInfo[]) => {
      return new Promise<void>((resolve, reject) => {
        setModalProps(curr => ({
          ...curr,
          isOpen: true,
          delegations,
          onClose: closeModal,
          onFinish: resolve,
          onError: reject
        }));
      });
    },
    [closeModal, setModalProps]
  );

  return [modalProps, revokeNftDelegation];
};
