import { assign, isNil, mapValues } from 'lodash';

import { StakeFishInfo } from '../utils';

import {
  BasicNFTSpecificInfo,
  ERC20InfoMap,
  ERC721Symbol,
  NFTInfoMap,
  NFTSpecificInfo,
  UniswapSpecificInfo
} from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';

export const blendInUniSwapV3Info = (
  primitiveNftInfoMap: NFTInfoMap,
  uniswapInfoMap: Record<number, BasicNFTSpecificInfo & UniswapSpecificInfo>
) => {
  const uniswapInfo = primitiveNftInfoMap[ERC721Symbol.UNISWAP_LP];
  return {
    ...primitiveNftInfoMap,
    [ERC721Symbol.UNISWAP_LP]: {
      ...uniswapInfo,
      hasTokenSpecificInfos: true,
      balance: uniswapInfo?.balance?.filter(id => id in uniswapInfoMap) ?? [],
      nftSuppliedList: uniswapInfo?.nftSuppliedList?.filter(id => id in uniswapInfoMap) ?? [],
      nftSuppliedBalanceInUsd: uniswapInfo?.nftSuppliedList?.reduce((sum, id) => {
        return sum.plus(uniswapInfoMap[id]?.priceInUsd || zero);
      }, zero),
      nftCollateralList: uniswapInfo?.nftCollateralList?.filter(id => id in uniswapInfoMap) ?? [],
      nftAsCollateralBalanceInUsd: uniswapInfo?.nftCollateralList?.reduce((sum, id) => {
        return sum.plus(uniswapInfoMap[id]?.priceInUsd || zero);
      }, zero),
      tokenSpecificInfos: uniswapInfoMap as Record<string, NFTSpecificInfo>
    }
  };
};

export const blendInStakeFishInfo = (
  primitiveNftInfoMap: NFTInfoMap,
  stakeFishInfoMap: Record<string, StakeFishInfo>
) => {
  const stakeFishInfo = primitiveNftInfoMap[ERC721Symbol.SF_VLDR];
  const {
    priceInUsd = zero,
    priceInETH = zero,
    baseLTVasCollateral = zero,
    reserveLiquidationThreshold = zero
  } = stakeFishInfo ?? {};
  return {
    ...primitiveNftInfoMap,
    [ERC721Symbol.SF_VLDR]: {
      ...stakeFishInfo,
      hasTokenSpecificInfos: true,
      tokenSpecificInfos: mapValues(stakeFishInfoMap, v => ({
        ...v,
        priceInUsd,
        priceInETH,
        baseLTVasCollateral,
        reserveLiquidationThreshold
      })) as Record<string, NFTSpecificInfo>
    }
  } as NFTInfoMap;
};

export const blendInFakeNativeTokenInfo = (erc20InfoMap: ERC20InfoMap): ERC20InfoMap => {
  const nativeTokenInfo = Object.values(erc20InfoMap).find(it => it.isNativeToken)!;
  const wrappedNativeTokenInfo = Object.values(erc20InfoMap).find(it => it.isWrappedNativeToken)!;
  if (isNil(nativeTokenInfo) || isNil(wrappedNativeTokenInfo)) return {};
  return assign(erc20InfoMap, {
    [nativeTokenInfo.symbol]: {
      ...wrappedNativeTokenInfo,
      balance: nativeTokenInfo.balance,
      displayName: nativeTokenInfo.displayName,
      collectionName: nativeTokenInfo.collectionName,
      isNativeTokenOrDerivatives: nativeTokenInfo.isNativeTokenOrDerivatives,
      isNativeToken: nativeTokenInfo.isNativeToken,
      isWrappedNativeToken: nativeTokenInfo.isWrappedNativeToken,
      tokenCategory: nativeTokenInfo.tokenCategory,
      symbol: nativeTokenInfo.symbol
    }
  });
};
