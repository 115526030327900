import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import BigNumberJs from 'bignumber.js';
import { isNil, keys, map, mapValues, merge, omitBy, zipObject } from 'lodash';
import { sum } from '@parallel-mono/utils';
import { getLinearBalance } from 'paraspace-utilities-math-utils';

import { useTokensInfo } from '../TokensInfoProvider';
import { useUserPosition } from '../UserPositionProvider';
import { useUniswapInfos } from '../UniswapInfoProvider';
import { useUserBalances } from '../UserBalanceProvider';
import useUserWalletBalance from '../../hooks/useUserWalletBalance';

import { blendInFakeNativeTokenInfo, blendInStakeFishInfo, blendInUniSwapV3Info } from './utils';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useAppConfig } from '@/apps/paraspace/hooks';
import {
  ERC20InfoMap,
  ERC20Symbol,
  ERC721Symbol,
  NFTInfo,
  NFTInfoMap,
  TokenInfo
} from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';
import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';

export type OverviewUserInfo = {
  totalSuppliedPositionsInUsd: BigNumberJs;
  totalBorrowedPositionInUsd: BigNumberJs;
  totalCollateralPositionInUsd: BigNumberJs;
  liquidationThresholdInUsd: BigNumberJs;
  borrowLimitInUsd: BigNumberJs;
  healthFactor: Maybe<BigNumberJs>;
  nftHealthFactor: Maybe<BigNumberJs>;
};

export type UserRewardsInfo = {
  somniRewardsValue: BigNumberJs;
  omniRedeemValue: BigNumberJs;
};

const DEFAULT_OVERVIEW_USER_INFO: OverviewUserInfo = {
  totalSuppliedPositionsInUsd: new BigNumberJs(0),
  totalBorrowedPositionInUsd: new BigNumberJs(0),
  totalCollateralPositionInUsd: new BigNumberJs(0),
  liquidationThresholdInUsd: new BigNumberJs(0),
  borrowLimitInUsd: new BigNumberJs(0),
  healthFactor: null,
  nftHealthFactor: null
};

const DEFAULT_USER_REWARDS_INFO: UserRewardsInfo = {
  somniRewardsValue: new BigNumberJs(0),
  omniRedeemValue: new BigNumberJs(0)
};

type ContextValue = {
  load: () => Promise<void>;
  loadERC20Balance: () => Promise<void>;
  loadERC721Balance: () => Promise<void>;
  loadUserERC20Position: () => Promise<void>;
  loadUserERC721Positions: () => Promise<void>;
  loadUserAccountData: () => Promise<void>;
  erc20InfoMap: ERC20InfoMap;
  nftInfoMap: NFTInfoMap;
  overviewUserInfo: OverviewUserInfo;
  userRewardsInfo: UserRewardsInfo;
  basicInfoLoaded: boolean;
  userInfoLoaded: boolean;
};

const Context = createContext<ContextValue>({
  load: () => {
    throw new Error('Not implemented yet');
  },
  loadERC20Balance: () => {
    throw new Error('Not implemented yet');
  },
  loadERC721Balance: () => {
    throw new Error('Not implemented yet');
  },
  loadUserERC20Position: () => {
    throw new Error('Not implemented yet');
  },
  loadUserAccountData: () => {
    throw new Error('Not implemented yet');
  },
  loadUserERC721Positions: () => {
    throw new Error('Not implemented yet');
  },
  erc20InfoMap: {},
  nftInfoMap: {},
  overviewUserInfo: DEFAULT_OVERVIEW_USER_INFO,
  userRewardsInfo: DEFAULT_USER_REWARDS_INFO,
  basicInfoLoaded: false,
  userInfoLoaded: false
});

export const useMMProvider = () => useContext(Context);

const MMProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const { isUsingUserWallet } = useWeb3Context();
  const { getERC20BalanceByAddress } = useUserWalletBalance();

  const { tokensInfo, refetch: pollTokensInfo } = useTokensInfo();
  const {
    erc20PositionMap,
    erc721PositionMap,
    accountData,
    refreshUserAccountData,
    refreshUserERC20PositionMap,
    refreshUserERC721PositionMap
  } = useUserPosition();

  const { erc20BalanceMap, erc721BalanceMap, refreshERC20BalanceMap, refreshERC721BalanceMap } =
    useUserBalances();

  const { infoMap: uniswapInfoMap, refresh: refreshUniswapInfoMap } = useUniswapInfos();

  const [erc20DebtsTokenMap, setERC20DebtsTokenMap] = useState<Record<string, BigNumberJs>>();

  const refreshAll = useCallback(async () => {
    await Promise.all([
      pollTokensInfo(),
      refreshUserAccountData(),
      refreshUserERC20PositionMap(),
      refreshUserERC721PositionMap(),
      refreshERC20BalanceMap(),
      refreshERC721BalanceMap(),
      refreshUniswapInfoMap()
    ]);
  }, [
    pollTokensInfo,
    refreshUserAccountData,
    refreshUserERC20PositionMap,
    refreshUserERC721PositionMap,
    refreshERC20BalanceMap,
    refreshERC721BalanceMap,
    refreshUniswapInfoMap
  ]);

  const { erc20Config, erc721Config } = useAppConfig();

  const primitiveNftInfoMap: NFTInfoMap = useMemo(() => {
    if (!tokensInfo) {
      return {};
    }
    return mapValues(erc721Config, ({ symbol }) => {
      const basicInfo = (tokensInfo?.[symbol] as TokenInfo<ERC721Symbol>) ?? null;
      const {
        auctionedTokens = [],
        nftSuppliedList = [],
        nftCollateralList = [],
        tokenTraitMultipliers: positionTokenTraitMultipliers = {}
      } = erc721PositionMap?.[symbol] ?? {};

      const tokenPrice = basicInfo?.priceInUsd ?? zero;

      const { balance = [], tokenTraitMultipliers: balanceTokenTraitMultipliers } =
        erc721BalanceMap?.[symbol] ?? {};

      const tokenTraitMultipliers = merge(
        {},
        balanceTokenTraitMultipliers,
        positionTokenTraitMultipliers
      );

      return {
        ...basicInfo,
        balance,
        auctionedTokens,
        nftSuppliedList,
        nftCollateralList,
        tokenTraitMultipliers,
        nftSuppliedBalanceInUsd: sum(
          nftSuppliedList.map(tokenId => tokenPrice.times(tokenTraitMultipliers[tokenId] ?? 1))
        ),
        nftAsCollateralBalanceInUsd: sum(
          nftCollateralList.map(tokenId => tokenPrice.times(tokenTraitMultipliers[tokenId] ?? 1))
        )
      } as NFTInfo;
    });
  }, [tokensInfo, erc721PositionMap, erc721BalanceMap, erc721Config]);

  const nftInfoMap = useMemo(() => {
    const { stakeFishInfoMap: inBalanceStakeFishInfoMap = {} } =
      erc721BalanceMap?.[ERC721Symbol.SF_VLDR] ?? {};

    const { stakeFishInfoMap: inPositionStakeFishInfoMap = {} } =
      erc721PositionMap?.[ERC721Symbol.SF_VLDR] ?? {};

    const stakeFishInfoMap = merge({}, inBalanceStakeFishInfoMap, inPositionStakeFishInfoMap);

    return blendInStakeFishInfo(
      blendInUniSwapV3Info(primitiveNftInfoMap, uniswapInfoMap),
      stakeFishInfoMap
    );
  }, [primitiveNftInfoMap, uniswapInfoMap, erc721BalanceMap, erc721PositionMap]);

  const stabilizedNftInfoMap = useStabilizedSnapshot(nftInfoMap);
  useEffect(() => {
    const getBalance = async () => {
      if (!tokensInfo) return;
      const erc20DebtTokenAddresses = keys(erc20Config).map(symbol => {
        const basicInfo = tokensInfo?.[symbol] as TokenInfo<ERC20Symbol>;
        return basicInfo.variableDebtTokenAddress;
      });
      const result = (await getERC20BalanceByAddress(erc20DebtTokenAddresses)) ?? [];
      const erc20DebtsTokenMapResult = zipObject(erc20DebtTokenAddresses, result);
      if (result) setERC20DebtsTokenMap(erc20DebtsTokenMapResult);
    };
    getBalance();
  }, [erc20Config, getERC20BalanceByAddress, tokensInfo]);

  const rawErc20InfoMap: ERC20InfoMap = useMemo(() => {
    if (!tokensInfo) return {};
    return mapValues(
      erc20Config,
      ({
        symbol,
        isNativeTokenOrDerivatives,
        isNativeToken,
        isWrappedNativeToken,
        tokenCategory
      }) => {
        const basicInfo = tokensInfo?.[symbol] as TokenInfo<ERC20Symbol>;
        const { scaledXTokenBalance = zero, usageAsCollateralEnabledOnUser = false } =
          erc20PositionMap?.[symbol] ?? {};

        const decimals = basicInfo.decimals ?? 0;

        const currentTimestamp = new Date().getTime() / 1000;
        const predictedBalance = getLinearBalance({
          balance: scaledXTokenBalance,
          index: basicInfo.liquidityIndex,
          rate: basicInfo.liquidityRate ?? zero,
          lastUpdateTimestamp: basicInfo?.lastUpdateTimestamp ?? currentTimestamp,
          currentTimestamp
        });
        const suppliedAmount = predictedBalance?.shiftedBy(-decimals) ?? zero;

        const borrowedAmount =
          erc20DebtsTokenMap?.[basicInfo.variableDebtTokenAddress]?.shiftedBy(-decimals) ?? zero;

        return {
          ...basicInfo,
          balance: erc20BalanceMap?.[symbol] ?? zero,
          isNativeTokenOrDerivatives,
          isNativeToken,
          isWrappedNativeToken,
          tokenCategory,
          usageAsCollateralEnabledOnUser,
          suppliedAmount,
          borrowedAmount,
          suppliedAmountInUsd: suppliedAmount.times(basicInfo?.priceInUsd ?? 0),
          borrowedAmountInUsd: borrowedAmount.times(basicInfo?.priceInUsd ?? 0)
        };
      }
    );
  }, [tokensInfo, erc20Config, erc20PositionMap, erc20DebtsTokenMap, erc20BalanceMap]);

  const erc20InfoMap = useMemo(() => {
    return blendInFakeNativeTokenInfo(rawErc20InfoMap);
  }, [rawErc20InfoMap]);

  const stabilizedErc20InfoMap = useStabilizedSnapshot(erc20InfoMap);

  const overviewUserInfo = useMemo(() => {
    if (!stabilizedNftInfoMap || !stabilizedErc20InfoMap || !accountData || !tokensInfo)
      return DEFAULT_OVERVIEW_USER_INFO;

    const erc721TotalSuppliedPositionsInUsd = sum(
      map(stabilizedNftInfoMap, it => it.nftSuppliedBalanceInUsd ?? 0)
    );

    const erc20TotalSuppliedPositionsInUsd = sum(
      map(
        omitBy(stabilizedErc20InfoMap, v => v.isNativeToken),
        it => it.suppliedAmountInUsd ?? 0
      )
    );

    const totalSuppliedPositionsInUsd = erc721TotalSuppliedPositionsInUsd.plus(
      erc20TotalSuppliedPositionsInUsd
    );

    const totalBorrowedPositionInUsd = accountData.totalDebtPriceInUSD;
    const totalCollateralPositionInUsd = accountData.totalCollateralPriceInUSD;
    return {
      totalSuppliedPositionsInUsd,
      totalBorrowedPositionInUsd,
      totalCollateralPositionInUsd: accountData.totalCollateralPriceInUSD,
      liquidationThresholdInUsd: totalCollateralPositionInUsd.times(
        accountData.currentLiquidationThreshold
      ),
      borrowLimitInUsd: totalCollateralPositionInUsd.times(accountData.ltv),
      healthFactor: accountData.healthFactor,
      nftHealthFactor: accountData.nftHealthFactor
    } as OverviewUserInfo;
  }, [stabilizedNftInfoMap, stabilizedErc20InfoMap, accountData, tokensInfo]);

  const contextValue: ContextValue = useMemo(
    () => ({
      basicInfoLoaded: tokensInfo !== null,
      userInfoLoaded:
        !isUsingUserWallet ||
        (!isNil(erc20BalanceMap) &&
          !isNil(erc20PositionMap) &&
          !isNil(erc721BalanceMap) &&
          !isNil(erc721PositionMap) &&
          !isNil(accountData)),
      nftInfoMap: stabilizedNftInfoMap,
      overviewUserInfo,
      userRewardsInfo: DEFAULT_USER_REWARDS_INFO,
      erc20InfoMap: stabilizedErc20InfoMap,
      load: refreshAll,
      loadUserERC20Position: refreshUserERC20PositionMap,
      loadERC20Balance: refreshERC20BalanceMap,
      loadERC721Balance: refreshERC721BalanceMap,
      loadUserAccountData: refreshUserAccountData,
      loadUserERC721Positions: refreshUserERC721PositionMap
    }),
    [
      tokensInfo,
      isUsingUserWallet,
      stabilizedNftInfoMap,
      stabilizedErc20InfoMap,
      erc20BalanceMap,
      erc20PositionMap,
      erc721BalanceMap,
      erc721PositionMap,
      accountData,
      refreshAll,
      refreshERC20BalanceMap,
      refreshUserAccountData,
      refreshUserERC721PositionMap,
      refreshUserERC20PositionMap,
      refreshERC721BalanceMap,
      overviewUserInfo
    ]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default MMProvider;

MMProvider.displayName = 'MMProvider';
