import { createContext, memo, ReactNode, useContext, useMemo } from 'react';
import { zipObject } from 'lodash';

import { useMMProvider } from '../MMProvider';
import { useEOABalances } from '../UserBalanceProvider';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { emptyArray } from '@/apps/paraspace/consts/values';
import { useERC721ImagesThroughContract } from '@/apps/paraspace/hooks';

type ImageMapProviderProps = {
  children: ReactNode;
};

type ImageMapContextValue = {
  [id: string]: {
    image: string;
    name: string;
    description: string;
    imageThumbnail: string;
  };
} | null;

const ImageMapContext = createContext<ImageMapContextValue>(null);

export const ImageMapProvider = memo(({ children }: ImageMapProviderProps) => {
  const { nftInfoMap } = useMMProvider();
  const { erc721BalanceMap } = useEOABalances();
  const eoaBalances = erc721BalanceMap?.[ERC721Symbol.UNISWAP_LP]?.balance ?? emptyArray;
  const { balance = emptyArray, nftSuppliedList = emptyArray } =
    nftInfoMap[ERC721Symbol.UNISWAP_LP] ?? {};

  const tokenIds = useMemo(
    () => balance?.concat(nftSuppliedList)?.concat(eoaBalances),
    [balance, nftSuppliedList, eoaBalances]
  );

  const imageInfos = useERC721ImagesThroughContract({
    tokenIds,
    symbol: ERC721Symbol.UNISWAP_LP
  });

  const imageMap = useMemo(
    () => (imageInfos ? zipObject(tokenIds, imageInfos) : null),
    [tokenIds, imageInfos]
  );

  return <ImageMapContext.Provider value={imageMap}>{children}</ImageMapContext.Provider>;
});

export const useImageMap = () => useContext(ImageMapContext);
