import { BigNumber } from 'bignumber.js';
import { StakingType } from 'paraspace-utilities-contract-helpers';

import { ApeCoinListing, ApeListing, BakcListing, ListingType } from '../types';
import { stakingTypeToPoolNameMap } from '../utils';

import { P2PApeStakingListing } from '@/apps/paraspace/generated/graphql';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/ApePairing/consts';
import { FLOAT_SCALING_FACTOR } from '@/apps/paraspace/utils/format';
import { ApeStakingTokenSymbol, ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const formatApeListing = (
  listing: P2PApeStakingListing,
  getSymbolByContractAddress: (addr: string) => Maybe<ERC20Symbol | ERC721Symbol>
): ApeListing | BakcListing => {
  const { token, tokenId, offerer, share, listingHash, endTime, startTime, v, r, s, stakingType } =
    listing;

  const basicInfo = {
    token,
    tokenId,
    offerer,
    share: new BigNumber(share).dividedBy(FLOAT_SCALING_FACTOR),
    listingHash,
    startDate: new Date(startTime * 1000),
    expirationDate: new Date(endTime * 1000),
    v,
    r,
    s,
    stakingType: stakingType as StakingType,
    stakingPool: stakingTypeToPoolNameMap[stakingType]
  };

  const symbol = getSymbolByContractAddress(token) as ApeStakingTokenSymbol;

  return symbol === ERC721Symbol.BAKC
    ? {
        ...basicInfo,
        symbol,
        listingType: ListingType.BakcListing
      }
    : {
        ...basicInfo,
        symbol,
        listingType: ListingType.ApeListing
      };
};

export const formatApeCoinListing = (listing: P2PApeStakingListing): ApeCoinListing => {
  const { token, offerer, share, listingHash, endTime, startTime, v, r, s, stakingType } = listing;
  const pool = stakingTypeToPoolNameMap[stakingType];
  return {
    token,
    offerer,
    share: new BigNumber(share).dividedBy(FLOAT_SCALING_FACTOR),
    listingHash,
    startDate: new Date(startTime * 1000),
    expirationDate: new Date(endTime * 1000),
    v,
    r,
    s,
    amount: BigNumber(STAKE_LIMIT[pool]),
    stakingType: stakingType as StakingType,
    stakingPool: pool,
    symbol: ERC20Symbol.APE,
    listingType: ListingType.ApeCoinListing
  };
};
