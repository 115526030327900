import { Button, Icon, Inline, H6, SmallText } from '@parallel-mono/components';
import { useMemo, useCallback } from 'react';
import { formatNumber } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';
import { useNavigate } from 'react-router-dom';

import PanelCard from './PanelCard';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { Tooltip } from '@/apps/paraspace/components';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import {
  DEFAULT_PERCENT_DECIMAL,
  HIDDEN_DECIMAL_PERCENT,
  MAX_PERCENT,
  MIN_PERCENT_DECIMAL
} from '@/apps/paraspace/pages/config';
import { zero } from '@/apps/paraspace/consts/values';

const BorrowedPanel = () => {
  const { effectiveCapeBorrowApy } = useAutoCompoundApeInfo();
  const { erc20InfoMap } = useMMProvider();
  const navigate = useNavigate();

  const apeTokenInfos = erc20InfoMap[ERC20Symbol.APE];
  const capeTokenInfos = erc20InfoMap[ERC20Symbol.CAPE];

  const totalBorrowedAmount = useMemo(
    () => capeTokenInfos.borrowedAmount?.plus(apeTokenInfos.borrowedAmount ?? zero) ?? zero,
    [apeTokenInfos.borrowedAmount, capeTokenInfos.borrowedAmount]
  );

  const borrowPanelItems = useMemo(() => {
    const effectiveCapeBorrowAmount = capeTokenInfos.borrowedAmount?.times(
      effectiveCapeBorrowApy ?? 0
    );
    const borrowApy =
      apeTokenInfos.borrowedAmount &&
      apeTokenInfos.borrowApyRate &&
      effectiveCapeBorrowAmount &&
      totalBorrowedAmount?.gt(zero) &&
      capeTokenInfos.borrowedAmount
        ? apeTokenInfos.borrowedAmount
            .times(apeTokenInfos.borrowApyRate)
            .plus(effectiveCapeBorrowAmount)
            .div(totalBorrowedAmount)
        : BigNumber(0);
    const items = [
      {
        key: 'borrowed',
        field: (
          <Inline gap="0.25rem">
            <SmallText skin="secondary">Borrowed</SmallText>
          </Inline>
        ),
        value: (
          <Inline gap="0.125rem">
            <H6>{formatNumber(totalBorrowedAmount ?? 0)}</H6>
            <H6 skin="secondary" fontWeight="bold">
              APE
            </H6>
            <Tooltip content="Includes both cAPE and APE borrowed amount" />
          </Inline>
        )
      },
      {
        key: 'borrowedApy',
        field: 'Borrow APY',
        value: (
          <H6>
            {formatNumber(borrowApy, {
              output: 'percent',
              decimal: borrowApy?.gte(HIDDEN_DECIMAL_PERCENT)
                ? MIN_PERCENT_DECIMAL
                : DEFAULT_PERCENT_DECIMAL,
              threshold: {
                max: MAX_PERCENT
              }
            })}
          </H6>
        )
      }
    ];

    return items;
  }, [
    capeTokenInfos.borrowedAmount,
    effectiveCapeBorrowApy,
    apeTokenInfos.borrowedAmount,
    apeTokenInfos.borrowApyRate,
    totalBorrowedAmount
  ]);

  const handleRepay = useCallback(() => {
    // since the specific tag is dynamically rendered
    // for now we can only redirect to lending page
    navigate('/');
  }, [navigate]);

  return totalBorrowedAmount.gt(0) ? (
    <PanelCard
      title={
        <Inline gap="0.5rem">
          <Icon name="disc" size="1.25rem" />
          <H6>Borrowed</H6>
        </Inline>
      }
      items={borrowPanelItems}
      actionButton={
        <Tooltip
          disabled={totalBorrowedAmount?.gt(0)}
          content="No borrow position needs to be repaid"
        >
          <Button
            block
            onClick={handleRepay}
            skin="secondary"
            disabled={!totalBorrowedAmount?.gt(0)}
          >
            Repay
          </Button>
        </Tooltip>
      }
    />
  ) : null;
};

export default BorrowedPanel;
