import { H6, Inline, SmallText, Stack } from '@parallel-mono/components';
import { memo } from 'react';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';

const SummaryItem = styled(Inline).attrs({
  justifyContent: 'space-between'
})``;

type CommonSummaryItemsProps = {
  totalBAYCStaked: BigNumber;
  totalMAYCStaked: BigNumber;
  totalBAKCStaked: BigNumber;
  totalApeCoinStaked: BigNumber;
  dailyRewards?: BigNumber;
};

export const CommonSummaryItems = memo(
  ({
    totalBAYCStaked,
    totalMAYCStaked,
    totalBAKCStaked,
    totalApeCoinStaked,
    dailyRewards
  }: CommonSummaryItemsProps) => {
    return (
      <Stack inset="0" gap="0.75rem">
        <SummaryItem>
          <SmallText skin="secondary">ApeCoin Staked</SmallText>
          <Inline gap="0.25rem">
            <H6>{formatNumber(totalApeCoinStaked)}</H6>
            <H6 skin="secondary">APE</H6>
          </Inline>
        </SummaryItem>
        <SummaryItem>
          <SmallText skin="secondary">BAYC Staked</SmallText>
          <H6>{formatNumber(totalBAYCStaked)}</H6>
        </SummaryItem>
        <SummaryItem>
          <SmallText skin="secondary">MAYC Staked</SmallText>
          <H6>{formatNumber(totalMAYCStaked)}</H6>
        </SummaryItem>
        <SummaryItem>
          <SmallText skin="secondary">BAKC Staked</SmallText>
          <H6>{formatNumber(totalBAKCStaked)}</H6>
        </SummaryItem>
        {dailyRewards && (
          <SummaryItem>
            <SmallText skin="secondary">Daily Rewards</SmallText>
            <Inline gap="0.25rem">
              <H6>{formatNumber(dailyRewards)}</H6>
              <H6 skin="secondary">APE</H6>
            </Inline>
          </SummaryItem>
        )}
      </Stack>
    );
  }
);
