import { memo, useCallback, useEffect, useState } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { WithdrawERC721Form } from './WithdrawERC721Form';
import { WithdrawERC721Steppers } from './WithdrawERC721Steppers';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import {
  ErrorState,
  SuccessState,
  StackedIcons,
  TimelockResultDesc
} from '@/apps/paraspace/components';

export type WithdrawERC721ModalProps = Omit<ModalProps, 'children'> & {
  symbol: ERC721Symbol;
  name: string;
  onClose?: () => void;
  onFinish?: () => void;
  onError?: () => void;
};

enum Phase {
  Inputting,
  Submitting,
  Success,
  Failed
}

export const WithdrawERC721Modal = memo(
  ({ symbol, name, onError, onFinish, onClose, isOpen, ...others }: WithdrawERC721ModalProps) => {
    const [phase, setPhase] = useState(Phase.Inputting);
    const [claimed, setClaimed] = useState(false);
    const [formData, setFormData] = useState<{
      tokenIds: number[];
    }>({ tokenIds: [] });

    const handleSubmit = useCallback(async (submitedTokenIds: number[]) => {
      setPhase(Phase.Submitting);
      setFormData({
        tokenIds: submitedTokenIds
      });
    }, []);

    const handleFinishSubmitting = useCallback(
      (instantlyClaimed: boolean) => {
        setClaimed(instantlyClaimed);
        onFinish?.();
        setPhase(Phase.Success);
      },
      [onFinish]
    );

    const handleFailedSubmitting = useCallback(() => {
      onError?.();
      setPhase(Phase.Failed);
    }, [onError]);

    const handleClose = useCallback(() => {
      if (phase === Phase.Inputting) {
        onError?.();
      }
      onClose?.();
    }, [onClose, onError, phase]);

    useEffect(() => {
      if (!isOpen) {
        setPhase(Phase.Inputting);
      }
    }, [isOpen]);

    return (
      <Modal
        size={phase === Phase.Inputting ? '45rem' : '30rem'}
        title={`Withdraw ${name}`}
        isOpen={isOpen}
        onClose={handleClose}
        closeOnBackdropClick={false}
        {...others}
      >
        {phase === Phase.Inputting && (
          <WithdrawERC721Form symbol={symbol} name={name} onSubmit={handleSubmit} />
        )}
        {phase === Phase.Submitting && (
          <WithdrawERC721Steppers
            formData={{
              symbol,
              name,
              ...formData
            }}
            onFinish={handleFinishSubmitting}
            onError={handleFailedSubmitting}
          />
        )}
        {phase === Phase.Failed && <ErrorState closeModal={handleClose} />}
        {phase === Phase.Success && (
          <SuccessState
            icon={<StackedIcons assets={formData.tokenIds.map(it => ({ symbol, tokenId: it }))} />}
            desc="Withdrawn Successfully"
            tip={
              <>
                You have withdrawn {formData.tokenIds.length} nfts.
                {!claimed && <TimelockResultDesc />}
              </>
            }
            actionButtonText={claimed ? 'Done' : 'View Queue'}
            onAction={handleClose}
          />
        )}
      </Modal>
    );
  }
);
