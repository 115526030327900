import { Inline, Responsive } from '@parallel-mono/components';
import styled from 'styled-components';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { ApeList } from './ApeList/ApeList';
import { Preview } from './Preview';
import { ApeStakingSideBar } from './ApeCoinManager';
import { ApeStakingManagerProvider } from './ApeStakingManagerProvider/ApeStakingManagerProvider';
import { StakeInfoListProvider } from './StakeInfoListProvider/StakeInfoListProvider';
import { USApeHeader } from './Header';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { MainWrapper } from '@/apps/paraspace/components';

const Column = styled(Inline)`
  width: 23.6%;
  min-width: 265px;
  order: 1;
  margin-left: 1rem;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    width: 100%;
    order: initial;
    margin-left: 0;
  `};
`;

const PaddingColumn = styled.div`
  width: 23.6%;
  min-width: 265px;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    width: 100%;
  `};
`;

const MainPage: FC = () => {
  const { pathname } = useLocation();
  return (
    <MainWrapper style={{ width: '100%' }}>
      <Responsive gap="1rem">
        {pathname === '/' && <PaddingColumn />}
        <Column>
          <ApeStakingSideBar />
        </Column>
        <ApeList />
      </Responsive>
    </MainWrapper>
  );
};

export const OfficialPairing = () => {
  const { account } = useWeb3Context();
  const { pathname } = useLocation();

  return account ? (
    <StakeInfoListProvider>
      <ApeStakingManagerProvider>
        {pathname === '/' && <USApeHeader />}
        <MainPage />
      </ApeStakingManagerProvider>
    </StakeInfoListProvider>
  ) : (
    <Preview />
  );
};
