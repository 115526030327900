import {
  Button,
  H1,
  Image,
  Inline,
  Modal,
  SmallText,
  Stack,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import { memo } from 'react';
import styled from 'styled-components';

import announcement from './assets/announcement.svg';
import bg from './assets/bg.svg';

const StyledModal = styled(Modal)`
  .body {
    padding: 2.5rem 0;
    background: url(${bg});
  }
  .modal {
    border-radius: 0;
  }
`;

const ContentTitle = styled(Text)`
  font-size: 32px;
  line-height: 48px;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    font-size: 20px;
    line-height: 22px;
  `}
`;

const InfoWrapper = styled(Inline)`
  padding: 1rem 0;
  background-color: rgba(0, 0, 0, 0.1);
`;
const InfosItem = styled(Stack).attrs({
  gap: '0',
  alignItems: 'center'
})``;
const ItemTitle = styled(H1)`
  font-size: 34px;
  ${({ theme }) => theme.breakpoints.only('mobile')`
   font-size: 22px;
  `}
`;
const Line = styled(Inline)`
  border-right: 1px solid #aaaaaa;
  height: 40px;
`;

const StickyButton = styled(Button)`
  width: 484px;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
  `}
`;

export const TestnetAnnouncementModal = memo(() => {
  const { mobile } = useBreakpoints();
  return (
    <StyledModal classNames={{ body: 'body', modal: 'modal' }} size="843px" isOpen closable={false}>
      <Stack>
        <Stack
          inset={mobile ? '0 1.5rem' : '0 2.5rem'}
          style={{ maxHeight: '680px', overflow: 'auto' }}
          gap="2.5rem"
        >
          <Inline gap={mobile ? '1rem' : '5rem'} width="100%" justifyContent="space-between">
            <ContentTitle fontWeight="bold">
              Successful Conclusion of Parallel Testnet Event
            </ContentTitle>
            <Image height={mobile ? '50px' : '100px'} src={announcement} />
          </Inline>
          <InfoWrapper width="100%" gap="0" alignItems="center" justifyContent="space-around">
            <InfosItem>
              <ItemTitle>141,721</ItemTitle>
              <SmallText skin="secondary">Total Accounts</SmallText>
            </InfosItem>
            <Line />
            <InfosItem>
              <ItemTitle>23,555,000</ItemTitle>
              <SmallText skin="secondary">Total Transactions</SmallText>
            </InfosItem>
            <Line />
            <InfosItem>
              <ItemTitle>135,768</ItemTitle>
              <SmallText skin="secondary">Total Contracts</SmallText>
            </InfosItem>
          </InfoWrapper>
          <Stack>
            <SmallText>The parallel testnet event has come to a successful conclusion!</SmallText>
            <SmallText>
              The parallel testnet event has garnered participation from a total of about{' '}
              <strong>141,721</strong> accounts, resulting in a cumulative count of nearly{' '}
              <strong>23,555,000</strong>
              on-chain transactions. A total of about <strong>135,768</strong> contracts have been
              deployed on the testnet. We extend our sincere gratitude for your participation and
              invaluable feedback.
            </SmallText>
            <SmallText>
              Parallel will temporarily suspend the testnet on <strong>May 31, 2024</strong> and
              will initiate the Early Access event on the parallel alpha net.
            </SmallText>
            <Stack gap="0">
              <SmallText>
                As of <strong>May 20, 2024</strong>, all calculations and updates for the points on
                the parallel testnet will be discontinued and no new points will be generated on the
                testnet after
                <strong>May 20, 2024</strong>.
              </SmallText>
              <SmallText>
                We are grateful for your involvement in the parallel testnet. Your valuable feedback
                assists us in bettering the development of the parallel alpha net. Therefore, in
                appreciation of your contributions to parallel, we are committing to a token airdrop
                for every testnet participant.
              </SmallText>
            </Stack>
            <Stack gap="0">
              <SmallText>
                We will take a snapshot of the data on the testnet as of{' '}
                <strong>May 20, 2024</strong>, and retrospectively distribute tokens to each
                participating user during the token airdrop period.
              </SmallText>
              <SmallText>
                Please note that the parallel testnet is for testing purposes. Testnet rewards will
                not be based on your points amount or the number of transactions, however, every
                testnet participant will have a token reward. Detailed airdrop rules for the testnet
                are to be announced.
              </SmallText>
            </Stack>
            <Stack gap="0">
              <SmallText>
                <strong>Timeline:</strong>
              </SmallText>
              <ul style={{ listStyle: 'disc', paddingLeft: '22px' }}>
                <li>
                  <SmallText>
                    <strong>
                      May 17: Announcement regarding the impending suspension of the parallel
                      testnet.
                    </strong>
                  </SmallText>
                </li>
                <li>
                  <SmallText>
                    <strong>
                      May 20: Cessation of the accumulation and updates for the point on the
                      parallel testnet. Parallel will take a snapshot of the testnet data on{' '}
                      <strong>May 20, 2024</strong>.
                    </strong>
                  </SmallText>
                </li>
                <li>
                  <SmallText>
                    <strong>May 31: Temporarily suspend the parallel testnet.</strong>
                  </SmallText>
                </li>
                <li>
                  <SmallText>
                    <strong>
                      Parallel will airdrop tokens to each participant of the testnet. Please follow
                      the announcements for specific airdrop timings.
                    </strong>
                  </SmallText>
                </li>
              </ul>
            </Stack>
            <Stack gap="0">
              <SmallText>
                It is imperative to note that the parallel points on the parallel alpha net will
                serve as a pivotal determinant for the parallel token airdrop. As such, the greater
                the accumulation of parallel points on the parallel alpha net, the more substantial
                the allotment of parallel tokens during the airdrop. We encourage all participants
                to shift their focus toward the parallel alpha net.
              </SmallText>
              <SmallText>We sincerely appreciate your understanding and cooperation.</SmallText>
            </Stack>

            <SmallText>
              The parallel alpha net is now accessible at{' '}
              <a href="https://app.parallel.fi/">
                <strong>https://app.parallel.fi</strong>
              </a>{' '}
              and the detailed rewards for the Early Access campaign will go live soon.
            </SmallText>
            <SmallText>Thank you.</SmallText>
          </Stack>
        </Stack>
        <Inline
          inset={mobile ? '0 1.5rem' : '0 2.5rem'}
          style={{ position: 'sticky', bottom: '0' }}
          justifyContent="center"
        >
          <StickyButton
            onClick={() => {
              window.location.href = 'https://app.parallel.fi/';
            }}
          >
            Explore Parallel Mainnet
          </StickyButton>
        </Inline>
      </Stack>
    </StyledModal>
  );
});
