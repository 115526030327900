import styled, { keyframes } from 'styled-components';
import { CryptoIcon } from '@parallel-mono/business-components';

import ApeCoinPlaceholderSvg from '@/apps/paraspace/pages/ApePairing/Images/ape-coin-placeholder.svg';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { ImageThumbnail, NFTThumbnail } from '@/apps/paraspace/components';

const blink = keyframes`
  0% { opacity: 1; }
  40% { opacity: 1; }
  60% { opacity: 0; }
  100% { opacity: 0; }
`;

const StyledPoolIconWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 100px;

  > .left {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 1rem;
    position: absolute;
    left: 0%;
    right: 28.57%;
    top: 0.36%;
    bottom: 28.21%;
    overflow: hidden;
  }

  > .right {
    width: 4rem;
    height: 4rem;
    position: absolute;
    left: 34.82%;
    right: 0%;
    top: 34.82%;
    bottom: 0%;
  }

  > .blink {
    transform: scale(1.01);
    animation-name: ${blink};
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
`;

export const CrossedIcon = () => {
  return (
    <StyledPoolIconWrapper>
      <NFTThumbnail symbol={ERC721Symbol.BAYC} className="left" />
      <NFTThumbnail symbol={ERC721Symbol.BAYC} tokenId={120} className="left blink" />
      <CryptoIcon symbol="APE" className="right" />
      <ImageThumbnail src={ApeCoinPlaceholderSvg} className="right blink" />
    </StyledPoolIconWrapper>
  );
};
