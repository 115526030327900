import { useCallback } from 'react';

import { useMMProvider } from '../contexts/MMProvider';

import { useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { ERC721Symbol } from '@/apps/paraspace/typings';

export const useIsTokenSupplierCheck = () => {
  const { nftInfoMap } = useMMProvider();

  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  const checkIsTokenSupplier = useCallback(
    (contractAddr: string, tokenId: string) => {
      const symbol = getSymbolByContractAddress(contractAddr ?? '') as ERC721Symbol;
      return nftInfoMap[symbol]?.nftSuppliedList?.some(it => String(it) === tokenId) ?? false;
    },
    [nftInfoMap, getSymbolByContractAddress]
  );

  return { checkIsTokenSupplier };
};
