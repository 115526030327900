import { useCallback, useMemo } from 'react';
import { WPunkGatewayService } from 'paraspace-utilities-contract-helpers';
import { DeploylessViewerClient } from 'deployless-view';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';
import submitTransaction from '@/apps/paraspace/utils/submitTransaction';

const PUNKS_NFT_TYPE = 2;

const useWPunk = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();
  const service = useMemo(() => {
    if (!provider) {
      return null;
    }

    return new WPunkGatewayService(provider, contracts.WPunkGatewayProxy, contracts.PUNKS);
  }, [contracts.PUNKS, contracts.WPunkGatewayProxy, provider]);

  const viewer = useMemo(() => {
    if (!provider) {
      return null;
    }

    return new DeploylessViewerClient(provider);
  }, [provider]);

  const withdrawPunks = useCallback(
    async (xpunkAddress: string, tokenIds: number[]) => {
      if (!provider || !service) {
        return null;
      }

      const txs = await service.withdrawPunk({
        token_ids: tokenIds.map(each => `${each}`),
        user: account,
        xpunkAddress
      });
      if (txs.length !== 1) {
        throw new Error('You need to approve before withdrawing !');
      }
      const txRes = submitTransaction({ provider, tx: txs[0] });
      return txRes;
    },
    [account, provider, service]
  );

  const supplyPunks = useCallback(
    async (tokenIds: number[], from?: string) => {
      if (!provider || !service) {
        return null;
      }

      const tx = (
        await service.supplyPunk({
          user: from ?? account,
          token_ids: tokenIds,
          onBehalfOf: account
        })
      )[0];

      return tx;
    },
    [account, provider, service]
  );

  const getPunksBalance = useCallback(async () => {
    if (viewer && contracts.PUNKS) {
      const rawData = await viewer.getAllTokensByOwner(account, contracts.PUNKS, PUNKS_NFT_TYPE);
      return rawData.tokenInfos as number[];
    }
    return null;
  }, [account, contracts.PUNKS, viewer]);

  return { withdrawPunks, supplyPunks, getPunksBalance };
};

export default useWPunk;
