import { Icon, Inline } from '@parallel-mono/components';
import { memo, useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';

import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { SummaryInfoBanner } from '@/apps/paraspace/components';
import { SECURITY_AUDITS } from '@/apps/paraspace/pages/config';
import { useLendingSummaryData } from '@/apps/paraspace/pages/contexts/PlatformSummaryProvider';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { Feature } from '@/apps/paraspace/config';

const Stats = memo(() => {
  const lendingSummaryData = useLendingSummaryData();
  const { duneLink, features } = useAppConfig();

  const values = useMemo(() => {
    const isSupportedSupplyNft = features.some(item =>
      [
        Feature.GetCreditFromUniswapToken,
        Feature.GetCreditFromBlueChips,
        Feature.GetCreditFromEthValidator
      ].includes(item)
    );

    return [
      isSupportedSupplyNft
        ? {
            label: 'NFTs Supplied',
            value: lendingSummaryData ? formatNumber(lendingSummaryData.totalSuppliedNFT) : '-'
          }
        : null,
      {
        label: 'Total Supply',
        value: lendingSummaryData ? formatToCurrency(lendingSummaryData.totalSupply) : '-',
        externalLink: duneLink
      },
      {
        label: 'Total Borrow',
        value: lendingSummaryData ? formatToCurrency(lendingSummaryData.totalBorrow) : '-'
      },
      {
        label: 'Audit',
        value: SECURITY_AUDITS.COUNT,
        externalLink: SECURITY_AUDITS.LINK
      }
    ].filter(notEmpty);
  }, [features, lendingSummaryData, duneLink]);

  return (
    <Inline width="100%" justifyContent="center">
      <SummaryInfoBanner
        width="100%"
        title="Lending"
        description=""
        icon={<Icon name="feather" />}
        valueList={values}
      />
    </Inline>
  );
});

export default Stats;
