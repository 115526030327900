import { useState, useCallback } from 'react';

import { JoinBAKCPairListingsModalProps } from '../JoinBAKCPairListingsModal';
import { ApeCoinListing, ApeListing, BakcListing } from '../../../types';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { WalletType } from '@/apps/paraspace/typings';

const defaultJoinListingWithBakcProps: JoinBAKCPairListingsModalProps = {
  isOpen: false,
  apeCoinListing: null,
  bakcListing: null,
  apeListing: null,
  walletType: 'AA'
};

export const useJoinBAKCPairListings = (): [
  JoinBAKCPairListingsModalProps,
  (params: {
    apeListing: Maybe<ApeListing>;
    apeCoinListing: Maybe<ApeCoinListing>;
    bakcListing: Maybe<BakcListing>;
    walletType: WalletType;
  }) => Promise<void>
] => {
  const [joinBAKCPairListingsModalProps, setJoinBAKCPairListingsModalProps] =
    useState<JoinBAKCPairListingsModalProps>(defaultJoinListingWithBakcProps);

  const joinBAKCPairListings = useCallback(
    ({
      apeListing,
      apeCoinListing,
      bakcListing,
      walletType
    }: {
      apeListing: Maybe<ApeListing>;
      apeCoinListing: Maybe<ApeCoinListing>;
      bakcListing: Maybe<BakcListing>;
      walletType: WalletType;
    }) => {
      return new Promise<void>((resolve, reject) => {
        setJoinBAKCPairListingsModalProps(curr => ({
          ...curr,
          isOpen: true,
          apeListing,
          apeCoinListing,
          bakcListing,
          walletType,
          onClose: () => {
            setJoinBAKCPairListingsModalProps(prev => ({
              ...prev,
              isOpen: false
            }));
            reject();
          },
          onFinish: () => {
            resolve();
          },
          onError: () => {
            reject();
          }
        }));
      });
    },
    []
  );

  return [joinBAKCPairListingsModalProps, joinBAKCPairListings];
};
