import { useParams } from 'react-router-dom';
import { memo, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { Stack } from '@parallel-mono/components';

import { CollectionProvider, ShopActionsProvider, useCollections } from '../../contexts';

import CommonDetail from './CommonDetail';
import UniswapDetail from './UniswapDetail';
import { StakeFishDetail } from './StakeFishDetail';
import { ShopActionsProvider as LegacyShopActionsProvider } from './contexts';

import { GoBackProvider } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useAppConfig, useSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';

const NftDetailContent = memo(() => {
  const { contractAddr } = useParams();
  const symbol = useSymbolByContractAddress(contractAddr!) ?? '';
  const { collections } = useCollections();
  const supportedInShop = useMemo(
    () => collections.some(col => col.symbol === symbol),
    [collections, symbol]
  );

  const { features } = useAppConfig();
  const shopFeatureEnabled = features.includes(Feature.NftShop);

  const pageMapping = useMemo(
    () => ({
      [ERC721Symbol.UNISWAP_LP]: <UniswapDetail />,
      [ERC721Symbol.SF_VLDR]: (
        <StakeFishDetail disableShopFeature={!supportedInShop || !shopFeatureEnabled} />
      )
    }),
    [supportedInShop, shopFeatureEnabled]
  );
  return (
    <Stack inset="1.5rem 0 0 0">
      <Helmet>
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://para.space" />
        <meta
          property="twitter:description"
          content="ParaSpace is the first protocol to unlock instant liquidity for all your digital assets."
        />
      </Helmet>
      <GoBackProvider value={{ path: `/shop/${contractAddr}` }}>
        <ShopActionsProvider>
          <LegacyShopActionsProvider>
            {get(
              pageMapping,
              symbol as ERC721Symbol,
              <CommonDetail disableShopFeature={!supportedInShop || !shopFeatureEnabled} />
            )}
          </LegacyShopActionsProvider>
        </ShopActionsProvider>
      </GoBackProvider>
    </Stack>
  );
});

export const Details = memo(() => {
  return (
    <CollectionProvider>
      <NftDetailContent />
    </CollectionProvider>
  );
});
