import { FC, ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';

import { useMMProvider } from '../../MMProvider';

import { SupplyETHValidatorModal, SupplyETHValidatorModalProps } from './SupplyETHValidatorModal';

import { WalletType } from '@/apps/paraspace/typings';

type SupplyETHValidatorProviderProps = {
  children: ReactNode;
};

type SupplyETHValidatorContextValue = {
  supplyETHValidator: (walletType: WalletType) => Promise<void>;
};
const SupplyETHValidatorContext = createContext<SupplyETHValidatorContextValue>({
  supplyETHValidator: _walletType => {
    throw new Error('not implemented yet');
  }
});

const defaultModalProps: SupplyETHValidatorModalProps = {
  isOpen: false,
  formData: {
    walletType: 'AA'
  },
  onError: noop,
  onFinish: noop,
  onClose: noop
};

export const SupplyETHValidatorProvider: FC<SupplyETHValidatorProviderProps> = ({ children }) => {
  const [modalProps, setModalProps] = useState<SupplyETHValidatorModalProps>(defaultModalProps);
  const { loadUserERC721Positions } = useMMProvider();
  const supplyETHValidator = useCallback(
    (walletType: WalletType) => {
      return new Promise<void>((resolve, reject) => {
        setModalProps({
          isOpen: true,
          formData: {
            walletType
          },
          onFinish: resolve,
          onError: reject,
          onClose: () => {
            setModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
          }
        });
      });
    },
    [setModalProps]
  );

  const contextValue = useMemo(
    () => ({
      supplyETHValidator: (walletType: WalletType) =>
        supplyETHValidator(walletType).then(loadUserERC721Positions)
    }),
    [supplyETHValidator, loadUserERC721Positions]
  );

  return (
    <SupplyETHValidatorContext.Provider value={contextValue}>
      <SupplyETHValidatorModal {...modalProps} />
      {children}
    </SupplyETHValidatorContext.Provider>
  );
};

export const useSupplyETHValidator = () => useContext(SupplyETHValidatorContext);
