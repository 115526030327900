import { memo, useMemo } from 'react';
import styled from 'styled-components';
import { H6, Inline, Stack, Tag } from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { Link as RouterLink } from 'react-router-dom';
import { formatNumber } from '@parallel-mono/utils';

import { ShopItemOnListingPage } from '../types';
import { useCreditPrice } from '../../../hooks';

import { ImageThumbnailProps, Link, NFTThumbnailCheck } from '@/apps/paraspace/components';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { formatBalance } from '@/apps/paraspace/utils/format';

const EllipsisH6 = styled(H6).attrs({
  fontWeight: 'medium',
  skin: 'secondary'
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TextDecoration = styled(EllipsisH6)`
  text-decoration: line-through;
`;

type AssetCardProps = {
  onEnterDetail: () => void;
  asset: ShopItemOnListingPage;
  onSelectAsset: (assets: ShopItemOnListingPage) => void;
};

export const AssetCard = memo(({ asset, onEnterDetail, onSelectAsset }: AssetCardProps) => {
  const { lowestPriceListing, ownedByCurrentUser, inLiquidation, symbol, topOffer, multiplier } =
    asset;

  const buyWithCreditPrice = useCreditPrice({
    symbol,
    price: lowestPriceListing?.price,
    multiplier
  });

  const eligibleToBuy = useMemo(
    () => lowestPriceListing && !ownedByCurrentUser && !inLiquidation,
    [inLiquidation, lowestPriceListing, ownedByCurrentUser]
  );

  const onClickItemContainer = () => {
    if (eligibleToBuy) {
      onSelectAsset(asset);
    }
  };

  return (
    <NFTThumbnailCheck
      checked={asset.inCart}
      readonly={!eligibleToBuy}
      onChange={onClickItemContainer}
      platform={lowestPriceListing?.platform?.toLowerCase() as ImageThumbnailProps['platform']}
      symbol={symbol}
      tokenId={asset.tokenId}
      floatingTag={
        multiplier.gt(DEFAULT_MULTIPLIER) && (
          <Tag skin="success" size="small">
            {formatNumber(multiplier)}x Boost
          </Tag>
        )
      }
    >
      <Stack gap="0rem" inset="0 0.75rem" width="100%">
        <Inline alignItems="center" justifyContent="space-between">
          <EllipsisH6>#{asset.tokenId}</EllipsisH6>
          <Link
            as={RouterLink}
            onClick={onEnterDetail}
            to={`/details/${asset.contractAddress}/${asset.tokenId}`}
          >
            Details
          </Link>
        </Inline>

        {lowestPriceListing && (
          <>
            <Inline alignItems="center" justifyContent="space-between">
              <EllipsisH6>Min. Pay</EllipsisH6>
              <Inline alignItems="center" gap="0.25rem">
                <CryptoIcon symbol="ETH" size="1rem" />
                <EllipsisH6>{formatBalance(buyWithCreditPrice)}</EllipsisH6>
              </Inline>
            </Inline>
            <Inline alignItems="center" justifyContent="space-between">
              <EllipsisH6>Listed</EllipsisH6>
              <Inline alignItems="center" gap="0.25rem">
                <CryptoIcon symbol="ETH" size="1rem" />
                <TextDecoration>{formatBalance(lowestPriceListing.price)}</TextDecoration>
              </Inline>
            </Inline>
          </>
        )}

        {!lowestPriceListing && <EllipsisH6>Not listed</EllipsisH6>}

        {!lowestPriceListing && topOffer && (
          <Inline alignItems="center" justifyContent="space-between">
            <EllipsisH6>Top Offer</EllipsisH6>
            <Inline alignItems="center" gap="0.25rem">
              <CryptoIcon symbol={topOffer.orderItem.symbol} size="1rem" />
              <EllipsisH6>{formatBalance(topOffer.orderItem.amount)}</EllipsisH6>
            </Inline>
          </Inline>
        )}
      </Stack>
    </NFTThumbnailCheck>
  );
});
