import { Stack, StackProps } from '@parallel-mono/components';
import { memo, useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { Stepper, WithdrawERC20Submitter } from '@/apps/paraspace/components';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';

type WithdrawERC20StepsProps = Omit<StackProps, 'children' | 'onError'> & {
  data: {
    symbol: ERC20Symbol;
    amount: BigNumber;
  };
  onFinish: (claimed: boolean) => void;
  onError: (errorConfig?: Maybe<ErrorConfig>) => void;
};

export const WithdrawERC20Steps = memo(
  ({ data, onError, onFinish, ...others }: WithdrawERC20StepsProps) => {
    const { erc20InfoMap } = useMMProvider();

    const handleWithdrawn = useCallback(async () => {
      onFinish(false);
    }, [onFinish]);

    const steps = useMemo(
      () => [
        {
          description: `Withdraw ${
            erc20InfoMap[data.symbol].displayName
          } and add to Timelock Queue`,
          content: (
            <WithdrawERC20Submitter formData={data} onFinish={handleWithdrawn} onError={onError} />
          )
        }
      ],
      [data, erc20InfoMap, handleWithdrawn, onError]
    );

    return (
      <Stack {...others}>
        <Stepper steps={steps} step={0} />
      </Stack>
    );
  }
);
