import { isNil } from 'lodash';
import { useMemo } from 'react';
import { StaticJsonRpcProvider } from '@ethersproject/providers';
import { Signer } from 'ethers';

import { BridgeContract } from '../Contracts';
import { BridgeNetworks, SupportedChainId } from '../configs';

import { useSupportedERC20s } from './useSupportedERC20s';

import { Maybe } from '@/apps/parax/typings/basic';

export const useBridge = (
  symbol: Maybe<string>,
  eid: Maybe<number>,
  signerOrProvider: Maybe<Signer | StaticJsonRpcProvider>,
  chainId: Maybe<SupportedChainId>
) => {
  const supportedERC20s = useSupportedERC20s(chainId);

  const nativeToken = useMemo(
    () => (chainId === null ? null : BridgeNetworks[chainId]?.nativeCurrency ?? null),
    [chainId]
  );

  const targetERC20 = useMemo(
    () => supportedERC20s.find(it => it.symbol === symbol),
    [symbol, supportedERC20s]
  );

  return useMemo(() => {
    if (isNil(targetERC20) || isNil(eid) || isNil(signerOrProvider) || isNil(nativeToken)) {
      return null;
    }
    return new BridgeContract(
      targetERC20.parallelBridgeAddress!,
      signerOrProvider,
      targetERC20.decimals,
      nativeToken.decimals,
      eid
    );
  }, [targetERC20, eid, signerOrProvider, nativeToken]);
};
