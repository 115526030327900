import { Typography, TypographyVariant } from '@parallel-mono/components';
import { ComponentProps, FC } from 'react';
import styled from 'styled-components';

export interface LinkProps extends ComponentProps<typeof Typography> {
  underline?: boolean;
}

const LinkBase = styled(Typography)<{ $underline: boolean }>`
  text-decoration: ${({ $underline }) => ($underline ? 'underline' : 'none')};
  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.skin.grey[300]};
    outline-offset: 0.125rem;
  }

  &:focus-visible {
    outline: 1px solid ${({ theme }) => theme.skin.grey[300]};
    outline-offset: 0.125rem;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

export const Link: FC<LinkProps> = ({
  variant = TypographyVariant.body,
  as = 'a',
  underline = true,
  ...props
}) => {
  return <LinkBase variant={variant} forwardedAs={as} $underline={underline} {...props} />; // https://styled-components.com/docs/api#transient-props
};
