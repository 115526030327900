import { Card, H3, H6, Icon, Inline, Stack } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import { FC, memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import { CommonSummaryItems } from '../../../components';
import { useActiveMatchesSummary } from '../hooks/useActiveMatchesSummary';
import { ActiveMatchesSummary } from '../types';

const StyledCard = styled(Card)`
  width: 100%;
  height: fit-content;
`;

export const SideBar: FC = memo(() => {
  const [summary, setSummary] = useState({} as ActiveMatchesSummary);
  const { data, loading } = useActiveMatchesSummary();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (data && !loading) {
      setSummary(data);
    }
  }, [loading, data]);

  return (
    <StyledCard inset="1.5rem" border>
      <Stack>
        <Inline gap="0.5rem">
          <Icon name="trendingUp" strokeWidth={2} />
          <H6>Summary</H6>
        </Inline>
        <Card border inset="1rem">
          <Stack alignItems="center" gap="0.5rem">
            <H3 skin="success">{formatNumber(summary.totalActiveMatches)}</H3>
            <H3 skin="secondary">Active Matches</H3>
          </Stack>
        </Card>
        <CommonSummaryItems
          totalBAYCStaked={summary.totalBAYCStaked}
          totalMAYCStaked={summary.totalMAYCStaked}
          totalBAKCStaked={summary.totalBAKCStaked}
          totalApeCoinStaked={summary.totalApeCoinStaked}
        />
      </Stack>
    </StyledCard>
  );
});
