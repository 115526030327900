import {
  Card,
  CardProps,
  H4,
  Inline,
  H3,
  Stack,
  SmallText,
  H6,
  useBreakpoints
} from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import { ComponentProps, FC, memo, useCallback, useMemo } from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Area, ResponsiveContainer } from 'recharts';
import { floor, range, sum } from 'lodash';
import styled, { useTheme } from 'styled-components';

import { renderTooltipContent } from './renderTooltipContent';

import { useApeNetApy } from '@/apps/paraspace/pages/ApePairing/contexts';
import { DAYS_OF_YEAR, DAYS_PER_WEEK } from '@/apps/paraspace/consts/fixtures';

const DAYS_PER_TWO_WEEKS = DAYS_PER_WEEK * 2;

const GraphWrapper = styled(Card)`
  width: 100%;
`;

const GraphTitle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  ${({ theme }) => theme.breakpoints.only('mobile')`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const GraphSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  `}
`;

export type GraphDataPoint = {
  period: number;
  dailyReward: number;
  dailyInterest: number;
  dailyProfit: number;
  dailyRepaidDebt: number;
};

type Props = Omit<CardProps, 'children'>;

export const ProjectedDailyGraph: FC<Props> = memo(({ ...others }) => {
  const { dailyRewardsData, dailyInterestsData, dailyRepaidDebtsData } = useApeNetApy();
  const { mobile } = useBreakpoints();

  const biweeklyProfitData = useMemo(() => {
    return range(0, DAYS_OF_YEAR, DAYS_PER_TWO_WEEKS).map(day => ({
      period: day / DAYS_PER_WEEK,
      dailyReward: dailyRewardsData[day],
      dailyInterest: dailyInterestsData[day],
      dailyProfit: dailyRewardsData[day] - dailyInterestsData[day],
      dailyRepaidDebt: dailyRepaidDebtsData[day]
    }));
  }, [dailyRewardsData, dailyInterestsData, dailyRepaidDebtsData]);

  const totalRewards = useMemo(
    () => sum(dailyRewardsData) - sum(dailyInterestsData),
    [dailyInterestsData, dailyRewardsData]
  );

  const xAxisTickFormatter = useCallback(
    (value, index) => {
      if (value === 0) return 'Today';
      if (index === floor(biweeklyProfitData.length / 2)) return '6 Month';
      if (index === biweeklyProfitData.length - 1) return '1 Year';
      return '';
    },
    [biweeklyProfitData]
  );

  const theme = useTheme();

  return (
    <GraphWrapper border {...others}>
      <Stack gap={mobile ? '0.5rem' : '2rem'}>
        <GraphTitle>
          <Stack gap="0">
            <H4>Projected Daily Profits</H4>
            <SmallText skin="secondary">Daily Profit = Daily Reward - Daily Interest</SmallText>
          </Stack>
          <GraphSubTitle>
            <Inline alignItems="baseline" gap="0.5rem">
              <H3>{formatNumber(totalRewards)}</H3>
              <H6 skin="secondary">APE</H6>
            </Inline>

            <SmallText skin="secondary">Total Profits in 1 year</SmallText>
          </GraphSubTitle>
        </GraphTitle>
        <Stack width="100%" style={{ height: '275px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              height={250}
              data={biweeklyProfitData}
              margin={{ top: 10, left: 5, right: 20 }}
            >
              <defs>
                <linearGradient id="colorBg" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={theme.skin.success.main} stopOpacity={1} />
                  <stop offset="100%" stopColor={theme.skin.success.main} stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="period"
                tickLine={false}
                tickFormatter={xAxisTickFormatter}
                interval={0}
                minTickGap={0}
                tick={{ fontSize: 14 }}
              />
              {!mobile && (
                <YAxis
                  tickLine={false}
                  dataKey="dailyProfit"
                  type="number"
                  tick={{ fontSize: 14 }}
                />
              )}
              <Tooltip
                content={renderTooltipContent as ComponentProps<typeof Tooltip>['content']}
                wrapperStyle={{ outline: 'none' }}
              />
              <Area
                type="monotone"
                dataKey="dailyProfit"
                stroke={theme.skin.success.main}
                fillOpacity={1}
                fill="url(#colorBg)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Stack>
      </Stack>
    </GraphWrapper>
  );
});
