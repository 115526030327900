import { memo, useEffect, useRef } from 'react';

import { useERC721Approval } from './hooks';
import { ApprovingStatus } from './types';

import { ApproveWallet, InProgress } from '@/apps/paraspace/components';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

type ApproveERC721FormData = {
  name: string;
  assetAddress: string;
  spender?: string;
  ids?: number[];
};

type ApproveERC721SubmitterProps = {
  formData: ApproveERC721FormData;
  onFinish: () => void;
  onError: () => void;
};

export const ApproveERC721FromEOASubmitter = memo(
  ({ onFinish, onError, formData }: ApproveERC721SubmitterProps) => {
    const { assetAddress, name, spender, ids } = formData;
    const {
      approve,
      status: approvingStatus,
      check
    } = useERC721Approval({
      token: assetAddress,
      spender,
      ids
    });

    useEffect(() => {
      check().catch(null);
    }, [check]);

    const onFinishRef = useRef<ApproveERC721SubmitterProps['onFinish'] | null>(null);
    onFinishRef.current = onFinish;
    const onErrorRef = useRef<ApproveERC721SubmitterProps['onError'] | null>(null);
    onErrorRef.current = onError;

    const parallelToast = useParallelToast();

    useEffect(() => {
      if (approvingStatus === ApprovingStatus.APPROVED) {
        onFinishRef.current?.();
      } else if (
        approvingStatus === ApprovingStatus.ERROR_APPROVING ||
        approvingStatus === ApprovingStatus.ERROR_CHECKING
      ) {
        onErrorRef.current?.();
      } else if (approvingStatus === ApprovingStatus.NOT_APPROVED) {
        parallelToast.promise(
          approve().catch(e => {
            throw getUserFriendlyError(e);
          })
        );
      }
    }, [parallelToast, approve, approvingStatus]);

    if (approvingStatus === ApprovingStatus.CHECKING || approvingStatus === ApprovingStatus.IDLE) {
      return <InProgress tip={`Checking approval for ${name}`} />;
    }

    if (
      approvingStatus === ApprovingStatus.SIGNING ||
      approvingStatus === ApprovingStatus.NOT_APPROVED
    ) {
      return <ApproveWallet />;
    }

    if (
      approvingStatus === ApprovingStatus.APPROVING ||
      approvingStatus === ApprovingStatus.APPROVED
    ) {
      return <InProgress tip={`Approving ${name}`} />;
    }

    return null;
  }
);
