import { Alert, Card, H5, Inline, Tabs, Stack } from '@parallel-mono/components';
import { useState } from 'react';
import styled from 'styled-components';

import { useAccountLiquidationStatus } from '../../../hooks/useAccountLiquidationStatus';

import { OfferReceived } from './OfferReceived';
import { OfferSent } from './OfferSent';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ConnectWallet } from '@/apps/paraspace/components';

export const StyledCard = styled(Card).attrs({
  border: true
})`
  width: 100%;
`;
enum OfferFilterValue {
  received,
  sent
}

const InLiquidationAlert = styled(Alert)`
  margin-top: 1rem;
`;

const StyledTabs = styled(Tabs)`
  justify-content: left;
  margin-bottom: 0.5rem;
  .slot {
    ${({ theme }) => theme.breakpoints.only('mobile')`
      width: 100%;
    `}
  }
  .tab {
    padding: 0.75rem;
    ${({ theme }) => theme.breakpoints.only('desktop')`
      width: 12.75rem;
    `}
  }
`;

export const Offers = () => {
  const { isUsingUserWallet } = useWeb3Context();
  const [selectedIndex, setSelectedIndex] = useState(OfferFilterValue.received);
  const { inLiquidation } = useAccountLiquidationStatus();
  const tabs = [
    { title: <H5>Received</H5>, content: <OfferReceived inLiquidation={inLiquidation} /> },
    { title: <H5>Sent</H5>, content: <OfferSent /> }
  ];
  return (
    <Stack width="100%">
      {isUsingUserWallet ? (
        <>
          {inLiquidation && (
            <InLiquidationAlert type="error">
              {selectedIndex === OfferFilterValue.received &&
                'You cannot accept any offer because your NFTs are in liquidation, repay your debt to recover.'}
              {selectedIndex === OfferFilterValue.sent &&
                'All of your offers sent is currently invalid because you are in liquidation.'}
            </InLiquidationAlert>
          )}
          <StyledCard>
            <StyledTabs
              classNames={{ tab: 'tab', slot: 'slot' }}
              tabs={tabs}
              activeTab={tabs[selectedIndex]}
              onActiveTabChange={(_, index) => setSelectedIndex(index)}
            />
            {tabs[selectedIndex].content}
          </StyledCard>
        </>
      ) : (
        <Inline justifyContent="center" inset="5rem 0 0 0">
          <ConnectWallet btnTxt="Connect Wallet to See Offers" />
        </Inline>
      )}
    </Stack>
  );
};
