import { memo } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';
import { Maybe } from 'graphql/jsutils/Maybe';

import { ApeCoinListing, ApeListing, BakcListing } from '../../../types';

import { UnmatchForm } from './UnmatchForm';

import { useWeb3Context } from '@/apps/paraspace/contexts';

export type UnmatchModalProps = Omit<ModalProps, 'children'> & {
  apeListing: ApeListing;
  apeCoinListing: ApeCoinListing;
  bakcListing: Maybe<BakcListing>;
  disabled: boolean;
  onConfirm?: () => void;
};

export const UnmatchModal = memo(
  ({
    apeListing,
    apeCoinListing,
    bakcListing,
    disabled,
    onConfirm,
    ...others
  }: UnmatchModalProps) => {
    const { account } = useWeb3Context();

    return (
      <Modal title="Unmatch" {...others}>
        <UnmatchForm
          account={account}
          apeListing={apeListing}
          apeCoinListing={apeCoinListing}
          bakcListing={bakcListing}
          onSubmit={onConfirm}
          disabled={disabled}
        />
      </Modal>
    );
  }
);
