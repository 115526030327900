import { InfoPanel, CryptoIcon, MarketplaceIcon } from '@parallel-mono/business-components';
import { Alert, Button, Card, H3, Inline, SmallText, Stack } from '@parallel-mono/components';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOffersMakerInfos } from '../../../../hooks';

import { useSimulatedSupplyActions } from './hooks';

import { Link, NFTThumbnail } from '@/apps/paraspace/components';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { formatBalance, formatToPercentage, truncateTextMid } from '@/apps/paraspace/utils/format';
import {
  Action,
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import {
  DEFAULT_MULTIPLIER,
  HEALTHY_FACTOR_WITH_CUSHION_FOR_WITHDRAW
} from '@/apps/paraspace/pages/config';
import { useCollectionByContract } from '@/apps/paraspace/pages/Shop/contexts';
import { zero } from '@/apps/paraspace/consts/values';
import { NftInfoForOrder } from '@/apps/paraspace/pages/Shop/pages/Offers/hooks';
import { Offer } from '@/apps/paraspace/pages/Shop/types';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import { DEVELOPER_DOCS_LINK } from '@/apps/paraspace/consts/externalLinks';

type Props = {
  offer: Offer;
  nftInfo: NftInfoForOrder;
  onCloseModal: () => void;
  onAcceptOffer: () => void;
};
export const AcceptOfferForm: FC<Props> = (
  { onCloseModal, onAcceptOffer, offer, nftInfo },
  ...others
) => {
  const navigate = useNavigate();
  const { isLoading: isMakerInfoLoading, offers } = useOffersMakerInfos([offer]);

  const {
    considerationItem,
    creditData,
    maker,
    orderItem,
    platform,
    platformFeePoint,
    creatorFeePoint,
    isMakerUnhealthy,
    isMakerInsufficientBorrowLimit
  } = offers[0] ?? {};

  const { nftInfoMap, overviewUserInfo, erc20InfoMap } = useMMProvider();
  const { totalBorrowedPositionInUsd } = overviewUserInfo;
  const { checkIfTokenInPairing } = useP2PInfo();

  const {
    baseLTVasCollateral,
    reserveLiquidationThreshold,
    nftCollateralList,
    tokenTraitMultipliers
  } = nftInfoMap[considerationItem.symbol] || {};

  const { tokenId: acceptNftId, symbol: acceptNftSymbol } = nftInfo;
  const { amount: offerValue, symbol: tokenSymbol } = orderItem;

  const isP2PStakedForIndividual = checkIfTokenInPairing(
    considerationItem.symbol,
    considerationItem.tokenId ?? 0
  );

  const collection = useCollectionByContract(nftInfo.contractAddress);

  const supplyActions: Action[] = useSimulatedSupplyActions([orderItem]);

  const { liquidationThresholdInUsd: newLiquidationThresholdInUsd } = useLendingSimulation([
    {
      type: ActionTypeEnum.WITHDRAW,
      targetAsset: {
        id: `${considerationItem.symbol}`,
        value: considerationItem.floorPriceInUsd.times(
          tokenTraitMultipliers?.[acceptNftId.toString()] || DEFAULT_MULTIPLIER
        ),
        LTV: baseLTVasCollateral,
        reserveLiquidationThreshold
      }
    },
    ...supplyActions
  ]);

  const minimumRepayBeforeWithdraw = useMemo(() => {
    if (nftCollateralList?.includes(acceptNftId)) {
      const result = totalBorrowedPositionInUsd
        .times(HEALTHY_FACTOR_WITH_CUSHION_FOR_WITHDRAW)
        .minus(newLiquidationThresholdInUsd);
      return result.gt(0);
    }
    return false;
  }, [newLiquidationThresholdInUsd, totalBorrowedPositionInUsd, nftCollateralList, acceptNftId]);

  const { availableLiquidity } = erc20InfoMap[ERC20Symbol.WETH] || {};

  const isInsufficientLiquidity = useMemo(
    () => availableLiquidity && availableLiquidity.lt(creditData?.amount ?? zero),
    [availableLiquidity, creditData?.amount]
  );

  const errorMessage = useMemo(() => {
    if (isInsufficientLiquidity) {
      return 'Credit amount exceeds market liquidity';
    }
    if (isP2PStakedForIndividual) {
      return "Cannot accept an offer for this NFT because it's staked on Shared Pool. Please unstake first to accept an offer.";
    }
    if (isMakerUnhealthy) {
      return 'Cannot accept an offer for this NFT because the maker is in liquidation.';
    }
    if (isMakerInsufficientBorrowLimit) {
      return "Cannot accept an offer for this NFT because the maker's borrowing limit is insufficient to cover the offer price.";
    }
    return null;
  }, [
    isInsufficientLiquidity,
    isMakerInsufficientBorrowLimit,
    isMakerUnhealthy,
    isP2PStakedForIndividual
  ]);

  const acceptOfferDisabled = useMemo(
    () =>
      Boolean(errorMessage) ||
      isMakerInfoLoading ||
      minimumRepayBeforeWithdraw ||
      !collection?.fees,
    [collection?.fees, errorMessage, isMakerInfoLoading, minimumRepayBeforeWithdraw]
  );

  const handleGoHomePage = () => {
    onCloseModal();
    navigate('/');
  };
  return (
    <Stack {...others}>
      <Card border>
        <Stack gap="1rem" justifyContent="center" alignItems="center">
          <CryptoIcon symbol={tokenSymbol as string} size="xlarge" />
          <H3>
            {formatBalance(offerValue)} {tokenSymbol}
          </H3>
        </Stack>
      </Card>
      <Inline alignItems="center" justifyContent="space-between">
        <Inline alignItems="center" gap="0.5rem">
          <NFTThumbnail tokenId={acceptNftId} symbol={acceptNftSymbol} size="xSmall" />
          <SmallText skin="secondary">
            Offer made by {truncateTextMid(maker, 4, 4)} for {acceptNftSymbol} #{acceptNftId} on{' '}
            {platform}
          </SmallText>
        </Inline>
        <MarketplaceIcon name={platform.toLowerCase()} size="xsmall" />
      </Inline>
      {minimumRepayBeforeWithdraw && (
        <>
          <Alert type="error">
            {' '}
            You cannot accept this offer because you have collateralized this NFT and withdrawing it
            would cause liquidation. Repay some debt or supply more collateral before accepting.{' '}
            <Link
              target="_blank"
              href={`${DEVELOPER_DOCS_LINK}/paraspace-as-first-cross-margin-nft-lending-protocol/supplying-nfts#how-can-i-withdraw-my-assetshow-can-i-withdraw-my-assets`}
            >
              Learn More
            </Link>
          </Alert>
          <Inline alignItems="center" justifyContent="space-between">
            <Button skin="secondary" size="large" onClick={handleGoHomePage}>
              Repay Debt
            </Button>
            <Button skin="secondary" size="large" onClick={handleGoHomePage}>
              Supply Collaterals
            </Button>
          </Inline>
        </>
      )}
      {!minimumRepayBeforeWithdraw && (
        <>
          {errorMessage && <Alert type="error">{errorMessage}</Alert>}
          {!errorMessage && (
            <InfoPanel
              skin="primary"
              infos={[
                {
                  title: 'Creator Fees',
                  value: formatToPercentage(creatorFeePoint)
                },
                {
                  title: `${platform} Protocol Fee`,
                  value: formatToPercentage(platformFeePoint)
                }
              ]}
            />
          )}
          <Button
            block
            size="large"
            skin="primary"
            disabled={acceptOfferDisabled}
            loading={isMakerInfoLoading && !errorMessage}
            onClick={onAcceptOffer}
          >
            Accept Offer
          </Button>
        </>
      )}
    </Stack>
  );
};
