import { useCallback, useState } from 'react';

import { ApeStakingTokenSymbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { useCheckApeStakedState } from '@/apps/paraspace/pages/hooks/ApeStaking/useCheckApeStakedState';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import {
  useApeListStatesAndActions,
  ApeListItem
} from '@/apps/paraspace/pages/ApePairing/contexts/ApeListProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';

type ApeAndBakcTokenStatus = {
  isStaked: boolean;
  isPairToBAKC: boolean;
  isInP2P: boolean;
  source: Maybe<WalletType>;
} & ApeListItem;

type ApeAndBakcTokenSet = Record<ApeStakingTokenSymbol, ApeAndBakcTokenStatus[]>;

/**
 * make targetPool required once listing page is ready
 */
export const useGetUserApesState = (
  targetPool?: ApeStakingTokenSymbol
): { apeAndBakcTokenSet: ApeAndBakcTokenSet; loading: boolean } => {
  const [loading, setLoading] = useState(false);
  const [apeAndBakcTokenSet, setApeAndBakcTokenSet] = useState<ApeAndBakcTokenSet>({
    [ERC721Symbol.MAYC]: [],
    [ERC721Symbol.BAYC]: [],
    [ERC721Symbol.BAKC]: []
  });

  const { apesInBalanceAndInSuppliedAndInP2P } = useApeListStatesAndActions();
  const checkApeStakedStatus = useCheckApeStakedState();

  const checkUserApesState = useCallback(
    async (symbol: ApeStakingTokenSymbol) => {
      const filteredApesBySymbol = apesInBalanceAndInSuppliedAndInP2P.filter(
        it => it.symbol === symbol
      );
      const tokens = filteredApesBySymbol.map(it => ({
        tokenId: it.tokenId,
        symbol,
        targetPool: targetPool ?? (it.symbol as ApeStakingTokenSymbol)
      }));

      const tokensWithStatus = await checkApeStakedStatus(tokens);

      return tokensWithStatus.map(it => {
        const ape = filteredApesBySymbol.find(token => token.tokenId === it.tokenId);
        return {
          ...it,
          ...(ape ?? {}),
          symbol,
          isStaked: Boolean(it.isStaked),
          supplied: Boolean(ape?.supplied),
          isPairToBAKC: Boolean(it.isPaired),
          isInP2P: it.isInP2P,
          source: ape?.source ?? null
        };
      });
    },
    [apesInBalanceAndInSuppliedAndInP2P, checkApeStakedStatus, targetPool]
  );

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      const baycTokens = await checkUserApesState(ERC721Symbol.BAYC);
      const maycTokens = await checkUserApesState(ERC721Symbol.MAYC);
      const bakcTokens = await checkUserApesState(ERC721Symbol.BAKC);
      setApeAndBakcTokenSet({
        [ERC721Symbol.BAYC]: baycTokens,
        [ERC721Symbol.MAYC]: maycTokens,
        [ERC721Symbol.BAKC]: bakcTokens
      });
    } catch (e) {
      console.error(e);
      setApeAndBakcTokenSet({
        [ERC721Symbol.BAYC]: [],
        [ERC721Symbol.MAYC]: [],
        [ERC721Symbol.BAKC]: []
      });
    } finally {
      setLoading(false);
    }
  }, [checkUserApesState]);

  return { apeAndBakcTokenSet, loading };
};
