import {
  H3,
  H5,
  Responsive,
  SmallText,
  Tooltip,
  useBreakpoints,
  Text,
  Icon,
  Inline
} from '@parallel-mono/components';
import BigNumberJs from 'bignumber.js';
import { FC, useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';
import styled, { useTheme } from 'styled-components';

import { useScrollContainer } from '../../../../contexts';

import { Factor } from './Factor';

import { ValuePill, BorrowLimitBar, Link } from '@/apps/paraspace/components';
import { zero } from '@/apps/paraspace/consts/values';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';
import {
  IN_LIQUIDATION_NFT_HF_THRESHOLD,
  RECOVERY_NFT_HF_THRESHOLD
} from '@/apps/paraspace/pages/config';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';
import { DEVELOPER_DOCS_LINK } from '@/apps/paraspace/consts/externalLinks';

const NoWrapSmallText = styled(SmallText)`
  white-space: nowrap;
`;

type BorrowedPositionsHeaderProps = {
  borrowedValue: BigNumberJs;
  borrowLimitValue: BigNumberJs;
  liquidationPointValue: BigNumberJs;
  totalSuppliedValue: BigNumberJs;
  healthFactor: Maybe<BigNumberJs>;
  nftHealthFactor: Maybe<BigNumberJs>;
  tokensInAuction: number;
  nftsInCollateral: number;
};

const liquidationDocLink = `${DEVELOPER_DOCS_LINK}/paraspace-as-first-cross-margin-nft-lending-protocol/liquidation`;

const TooltipContainer = styled(Text)`
  width: 17.75rem;
`;

const nftHealthFactorInDangerToolTipContent = (
  <TooltipContainer>
    In order to prevent your NFT from being liquidated, you need to ensure that the NFT HF is
    greater than or equal to {formatNumber(RECOVERY_NFT_HF_THRESHOLD)}.{' '}
    <Link as="a" href={liquidationDocLink} target="_blank">
      Learn more
    </Link>
  </TooltipContainer>
);

const BorrowedPositionsHeader: FC<BorrowedPositionsHeaderProps> = ({
  borrowedValue,
  borrowLimitValue,
  liquidationPointValue,
  totalSuppliedValue,
  healthFactor,
  nftHealthFactor,
  tokensInAuction,
  nftsInCollateral
}) => {
  const { mobile } = useBreakpoints();
  const { features } = useAppConfig();

  const isSupportedSupplyNft = useMemo(
    () =>
      features.some(item =>
        [
          Feature.GetCreditFromUniswapToken,
          Feature.GetCreditFromBlueChips,
          Feature.GetCreditFromEthValidator
        ].includes(item)
      ),
    [features]
  );

  const nftHealthFactorInDanger =
    nftsInCollateral > 0 &&
    (nftHealthFactor?.lt(IN_LIQUIDATION_NFT_HF_THRESHOLD) ||
      (nftHealthFactor?.lt(RECOVERY_NFT_HF_THRESHOLD) && tokensInAuction > 0));

  const theme = useTheme();

  const scrollContainer = useScrollContainer();

  return (
    <Responsive
      width="100%"
      breakPoint="tablet"
      gap={mobile ? '1rem' : '0'}
      justifyContent={mobile ? 'flex-start' : 'space-between'}
      alignItems={mobile ? 'flex-start' : 'center'}
    >
      <H3 fontWeight="bold">My Borrowed Positions</H3>
      <Responsive gap="1rem" breakPoint="tablet" width={mobile ? '100%' : 'auto'}>
        <ValuePill gap="0.5rem" width="100%">
          <SmallText skin="secondary">Borrowed</SmallText>
          <H5>{formatToCurrency(borrowedValue)}</H5>
          {borrowLimitValue.gt(0) && (
            <BorrowLimitBar
              borrowedValue={borrowedValue}
              borrowLimitValue={borrowLimitValue}
              liquidationPointValue={liquidationPointValue}
              totalSuppliedValue={totalSuppliedValue}
              width={mobile ? '100%' : '17.5rem'}
            />
          )}
        </ValuePill>
        <ValuePill gap="0.5rem">
          <NoWrapSmallText>Health Factor:</NoWrapSmallText>
          <Factor skin={healthFactor?.gt(1) ? 'success' : 'error'}>
            {formatNumber(healthFactor ?? zero, { threshold: { max: 20 } })}
          </Factor>
        </ValuePill>
        {isSupportedSupplyNft && (
          <ValuePill gap="0.5rem">
            <NoWrapSmallText>NFT Health Factor:</NoWrapSmallText>
            <Inline alignItems="center" gap="0.25rem">
              <Factor skin={nftHealthFactorInDanger ? 'error' : 'success'}>
                {formatNumber(nftHealthFactor ?? zero, { threshold: { max: 20 } })}
              </Factor>
              {nftHealthFactorInDanger && (
                <Tooltip
                  getPopupContainer={() => scrollContainer!}
                  content={nftHealthFactorInDangerToolTipContent}
                >
                  <Icon strokeWidth={2} size="small" name="info" color={theme.skin.error.main} />
                </Tooltip>
              )}
            </Inline>
          </ValuePill>
        )}
      </Responsive>
    </Responsive>
  );
};

export default BorrowedPositionsHeader;
