import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import styled from 'styled-components';
import {
  Button,
  H5,
  H6,
  Icon,
  Inline,
  Skeleton,
  SmallText,
  Stack,
  TypographyVariant,
  useBreakpoints
} from '@parallel-mono/components';
import BigNumberJs from 'bignumber.js';

import { useBuyCartProvider } from '../../../context/BuyCartProvider';
import useBatchNftCredit from '../../../context/useBatchNftCredit';

import { useErrors } from './hooks';

import { PriceContainer } from '@/apps/paraspace/pages/Shop/components';
import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useSupplyAndBorrowApy } from '@/apps/paraspace/pages/hooks/useSupplyAndBorrowApy';
import {
  WarningForBuyingApeStaking,
  useShowApeStakingWarning,
  AddAssetsButton
} from '@/apps/paraspace/components';

type BuyCartFormProps = {
  isBuyLoading: boolean;
  creditAmount: BigNumberJs;
  onEditPayLater: () => void;
  onClickPay: () => void;
};

const ItemName = styled(SmallText).attrs({ skin: 'secondary' })``;

const EditIcon = styled(Icon).attrs({
  name: 'edit2',
  size: '1.25rem',
  strokeWidth: 2
})`
  cursor: pointer;
  color: ${({ theme }) => theme.skin.grey[500]};
`;

export const BuyCartForm = ({
  isBuyLoading,
  onEditPayLater,
  onClickPay,
  creditAmount
}: BuyCartFormProps) => {
  const { totalPrice, buyCartList } = useBuyCartProvider();
  const { erc20InfoMap } = useMMProvider();

  const { getBorrowApy } = useSupplyAndBorrowApy();
  const {
    priceInUsd: ethPriceInUsd,
    balance,
    borrowApyRate,
    availableLiquidity
  } = erc20InfoMap[ERC20Symbol.ETH] || {};

  const { mobile } = useBreakpoints();
  const showApeStakingWarning = useShowApeStakingWarning(buyCartList);
  const futureAvailableCredit = useBatchNftCredit({
    tokenList: buyCartList,
    creditBasedCurrencyValueInUsd: ethPriceInUsd
  });

  const [tickApeStakingWarning, setTickApeStakingWarning] = useState(false);
  const buyNowAmount = totalPrice.minus(creditAmount);

  const isInsufficientCredit = futureAvailableCredit.lt(creditAmount);
  const isInsufficientLiquidity = availableLiquidity.lt(creditAmount);

  const walletAmount = useMemo(() => totalPrice.minus(creditAmount), [creditAmount, totalPrice]);

  const [interestRate, setInterestRate] = useState(borrowApyRate);
  const [isInterestLoading, setIsInterestLoading] = useState(false);
  useEffect(() => {
    setIsInterestLoading(true);
  }, [creditAmount]);

  useDebounce(
    () => {
      const handle = async () => {
        if (creditAmount && !isInsufficientCredit && !isInsufficientLiquidity) {
          const result = await getBorrowApy(ERC20Symbol.WETH, creditAmount.toString());
          setInterestRate(result ? new BigNumberJs(result.newBorrowApy) : borrowApyRate);
          setIsInterestLoading(false);
          return;
        }
        setInterestRate(borrowApyRate);
        setIsInterestLoading(false);
      };
      handle();
    },
    500,
    [
      creditAmount,
      getBorrowApy,
      setInterestRate,
      borrowApyRate,
      isInsufficientLiquidity,
      isInsufficientCredit
    ]
  );

  const handleUsingBalanceToggle = useCallback(({ checked }: { checked: boolean }) => {
    setTickApeStakingWarning(checked);
  }, []);

  const { buttonErrorText, alertText } = useErrors({
    futureAvailableCredit,
    availableLiquidity,
    balance,
    buyNowAmount,
    creditAmount,
    isBuyLoading
  });

  const disabledBuyNow = useMemo(
    () =>
      !!buttonErrorText ||
      !!alertText ||
      (showApeStakingWarning && !tickApeStakingWarning) ||
      isBuyLoading,
    [alertText, buttonErrorText, isBuyLoading, showApeStakingWarning, tickApeStakingWarning]
  );

  const balanceShortage = useMemo(() => buyNowAmount.minus(balance ?? 0), [buyNowAmount, balance]);
  const addAssetsContext = useMemo(
    () => ({
      erc20Assets: [{ symbol: ERC20Symbol.ETH, amount: balanceShortage }],
      erc721Assets: []
    }),
    [balanceShortage]
  );
  return (
    <Stack>
      <Stack gap="0.5rem">
        <Inline justifyContent="space-between" alignItems="center">
          <ItemName>Pay Now</ItemName>
          <PriceContainer price={buyNowAmount} currency="ETH" />
        </Inline>
        <Inline justifyContent="space-between" alignItems="center">
          <ItemName>Pay Later</ItemName>
          <Inline gap=".25rem">
            <EditIcon onClick={onEditPayLater} />
            <PriceContainer price={BigNumberJs(creditAmount)} currency="ETH" />
          </Inline>
        </Inline>
        <Inline justifyContent="space-between" alignItems="center">
          <ItemName>Interest Rate</ItemName>
          {isInterestLoading ? (
            <Skeleton.Button height="1.5rem" variant="round" />
          ) : (
            <H6>{formatToPercentage(interestRate)}</H6>
          )}
        </Inline>
      </Stack>
      {alertText && <SmallText skin="error">{alertText}</SmallText>}
      {showApeStakingWarning && (
        <WarningForBuyingApeStaking
          variant={TypographyVariant.bodySmall}
          checked={tickApeStakingWarning}
          onChange={handleUsingBalanceToggle}
        />
      )}
      <Stack gap="1rem">
        <Inline width="100%" justifyContent="space-between">
          {mobile && (
            <Inline alignItems="center" gap="0.5rem">
              <H5>Cart</H5>
              <PriceContainer price={totalPrice} currency={ERC20Symbol.ETH} />
            </Inline>
          )}
          <Button block={!mobile} disabled={disabledBuyNow} onClick={onClickPay}>
            {buttonErrorText ?? `Pay ${formatBalance(walletAmount)} ETH`}
          </Button>
        </Inline>
        {balanceShortage.gt(0) && <AddAssetsButton size="medium" context={addAssetsContext} />}
      </Stack>
    </Stack>
  );
};
