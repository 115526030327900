import {
  Card,
  Stack,
  H2,
  Inline,
  H6,
  H5,
  H1,
  Tag,
  Button,
  TypographyVariant,
  Image
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import styled from 'styled-components';
import { memo, useCallback } from 'react';
import BigNumber from 'bignumber.js';
import { floor } from 'lodash';
import { formatNumber } from '@parallel-mono/utils';

import OwnerImage from '@/apps/paraspace/pages/Shop/images/owner.svg';
import { formatToPercentage, truncateTextMid } from '@/apps/paraspace/utils/format';
import { Collapse, Link } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useUniSwapV3Manager } from '@/apps/paraspace/pages/contexts/UniSwapV3ManagerProvider/useUniSwapV3Manager';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useNetworkConfig } from '@/apps/paraspace/hooks';

const Span = styled.span<{ red: boolean }>`
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 50%;
  background-color: ${({ red, theme }) => (red ? theme.skin.error.main : theme.skin.success.main)};
`;

export const StackWithBorder = styled(Stack)`
  padding: 1rem;
  border: 2px solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 0.75rem;
`;

export const CollapseWithShadow = styled(Collapse)`
  box-shadow: ${({ theme }) => theme.shadows.primary};
`;

const StyledInline = styled(Inline)`
  > :nth-child(2) {
    flex-grow: 1;
    text-align: right;
  }
`;

interface CardProps {
  name: string;
  tokenId: string;
  token0Symbol?: string;
  token1Symbol?: string;
  liquidityToken0Amount?: BigNumber;
  liquidityToken1Amount?: BigNumber;
  token1ConvertToken0CurrentPrice?: BigNumber;
  healthFactor: number;
  threshold: number;
  isClosed: boolean;
  priceInUsd: BigNumber;
}

const calcPercentage = (
  liquidityToken0Amount?: BigNumber,
  liquidityToken1Amount?: BigNumber,
  token1ConvertToken0CurrentPrice?: BigNumber
) => {
  if (!liquidityToken0Amount) {
    return [0, 0];
  }

  const token1ConvertToken0Amount = liquidityToken1Amount!.times(token1ConvertToken0CurrentPrice!);

  const token0Percentage = floor(
    liquidityToken0Amount.div(token1ConvertToken0Amount.plus(liquidityToken0Amount)).toNumber(),
    2
  );
  const token1Percentage = 1 - token0Percentage;
  return [token0Percentage, token1Percentage];
};

const OperationCard = ({
  name,
  tokenId,
  healthFactor,
  threshold,
  token0Symbol,
  token1Symbol,
  liquidityToken0Amount,
  liquidityToken1Amount,
  token1ConvertToken0CurrentPrice,
  isClosed,
  priceInUsd
}: CardProps) => {
  const { account } = useWeb3Context();
  const {
    explorerLink: [explorerLinkBaseUrl]
  } = useNetworkConfig();
  const addressLink = `${explorerLinkBaseUrl}/address/${account}`;

  const { addLiquidity, removeLiquidity } = useUniSwapV3Manager();
  const { load } = useMMProvider();

  const handleAddLiquidityClick = useCallback(() => {
    addLiquidity(ERC721Symbol.UNISWAP_LP, tokenId);
  }, [tokenId, addLiquidity]);

  const handleRemoveLiquidity = useCallback(async () => {
    await removeLiquidity(ERC721Symbol.UNISWAP_LP, Number(tokenId));
    load();
  }, [removeLiquidity, tokenId, load]);

  const [token0Percentage, token1Percentage] = calcPercentage(
    liquidityToken0Amount,
    liquidityToken1Amount,
    token1ConvertToken0CurrentPrice
  );

  return (
    <Card>
      <Stack>
        <Stack gap="0.25rem">
          <H2>{name}</H2>
        </Stack>
        <Inline gap=".75rem">
          <Stack gap="0">
            <H6 skin="secondary" fontWeight="medium">
              Owned By
            </H6>
            <Inline gap=".25rem" alignItems="center">
              <Image src={OwnerImage} />
              {account && addressLink ? (
                <Link variant={TypographyVariant.header5} href={addressLink} target="_blank">
                  {`${truncateTextMid(account)}(you)`}
                </Link>
              ) : (
                <H5>-</H5>
              )}
              {Number.isFinite(healthFactor) && (
                <>
                  <Span red={healthFactor < threshold} />
                  <H6 skin={healthFactor < threshold ? 'error' : 'success'}>
                    {formatNumber(healthFactor)}
                  </H6>
                </>
              )}
            </Inline>
          </Stack>
        </Inline>
        <Stack gap=".25rem">
          <H5 skin="secondary" fontWeight="medium">
            Liquidity
          </H5>
          <H1>${formatNumber(priceInUsd)}</H1>
        </Stack>
        <StackWithBorder gap="1rem">
          {[
            {
              token: token0Symbol,
              amount: liquidityToken0Amount,
              percentage: token0Percentage
            },
            { token: token1Symbol, amount: liquidityToken1Amount, percentage: token1Percentage }
          ].map(({ token, amount, percentage }) => (
            <StyledInline key={token} alignItems="center">
              <Inline gap=".75rem" alignItems="center">
                <CryptoIcon symbol={token!} size="small" />
                <H5>{token}</H5>
              </Inline>
              <H5>
                {formatNumber(amount ?? 0, { decimal: 4 })} {token}
              </H5>
              <Tag skin="neutral" size="small">
                {formatToPercentage(percentage, 0)}
              </Tag>
            </StyledInline>
          ))}
        </StackWithBorder>
        <Inline gap="1rem">
          <Button block size="large" onClick={handleAddLiquidityClick}>
            Add Liquidity
          </Button>
          <Button
            skin="secondary"
            size="large"
            block
            onClick={handleRemoveLiquidity}
            disabled={isClosed}
          >
            Remove Liquidity
          </Button>
        </Inline>
      </Stack>
    </Card>
  );
};

export const UniswapOperationCard = memo(OperationCard);
