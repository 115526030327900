import { useCallback, useState } from 'react';
import { noop } from 'lodash';

import { BorrowNativeTokenDerivativesModalProps } from '../modals';

import { ERC20Symbol } from '@/apps/paraspace/typings';

const defaultBorrowNativeTokenDerivativesModalProps: BorrowNativeTokenDerivativesModalProps = {
  onClose: noop,
  onError: noop,
  onFinish: noop,
  isOpen: false,
  defaultDerivative: ERC20Symbol.ETH
};

export const useBorrowNativeTokenDerivatives = (): [
  BorrowNativeTokenDerivativesModalProps,
  (derivative: ERC20Symbol) => Promise<void>
] => {
  const [borrowNativeTokenDerivativesModalProps, setBorrowNativeTokenDerivativesModalProps] =
    useState<BorrowNativeTokenDerivativesModalProps>(defaultBorrowNativeTokenDerivativesModalProps);

  const borrowNativeTokenDerivatives = useCallback(
    (derivative: ERC20Symbol) => {
      return new Promise<void>((resolve, reject) => {
        setBorrowNativeTokenDerivativesModalProps(prev => ({
          ...prev,
          isOpen: true,
          defaultDerivative: derivative,
          onClose: () => {
            setBorrowNativeTokenDerivativesModalProps(prevState => ({
              ...prevState,
              isOpen: false
            }));
            resolve();
          },
          onFinish: resolve,
          onError: reject
        }));
      });
    },
    [setBorrowNativeTokenDerivativesModalProps]
  );
  return [borrowNativeTokenDerivativesModalProps, borrowNativeTokenDerivatives];
};
