import { createContext, memo, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { zipObject } from 'lodash';

import { useMMProvider } from '../MMProvider';

import { emptyArray } from '@/apps/paraspace/consts/values';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import {
  useERC721ImagesThroughContract,
  useGetERC721ImagesThroughContract
} from '@/apps/paraspace/hooks';

type ImageMapProviderProps = {
  children: ReactNode;
};

type ImageMap = {
  [id: string]: {
    image: string;
    name: string;
    description: string;
    imageThumbnail: string;
  };
};

type ImageMapContextValue = {
  imageMap: Maybe<ImageMap>;
  getImageThroughContract: (tokenId: number) => Promise<string>;
};

const ImageMapContext = createContext<ImageMapContextValue>({
  imageMap: null
} as ImageMapContextValue);

export const ImageMapProvider = memo(({ children }: ImageMapProviderProps) => {
  const { nftInfoMap } = useMMProvider();
  const { balance = emptyArray, nftSuppliedList = emptyArray } =
    nftInfoMap[ERC721Symbol.SF_VLDR] ?? {};
  const tokenIds = useMemo(() => balance?.concat(nftSuppliedList), [balance, nftSuppliedList]);

  const imageInfos = useERC721ImagesThroughContract({ symbol: ERC721Symbol.SF_VLDR, tokenIds });
  const imageMap = useMemo(
    () => (imageInfos ? zipObject(tokenIds, imageInfos) : null),
    [imageInfos, tokenIds]
  );

  const [cachedImageMap, setCachedImageMap] = useState<ImageMap>({});

  const getERC721ImageThroughContract = useGetERC721ImagesThroughContract();
  const getImageThroughContract = useCallback(
    async (tokenId: number) => {
      const result = await getERC721ImageThroughContract({
        tokenIds: [tokenId],
        symbol: ERC721Symbol.SF_VLDR
      });
      setCachedImageMap(prev => ({ ...prev, [tokenId]: result[0] }));
      return result[0]?.image;
    },
    [getERC721ImageThroughContract]
  );

  const mergedImageMap = useMemo(
    () => ({ ...imageMap, ...cachedImageMap }),
    [cachedImageMap, imageMap]
  );

  const value = useMemo(
    () => ({ getImageThroughContract, imageMap: mergedImageMap }),
    [getImageThroughContract, mergedImageMap]
  );

  return <ImageMapContext.Provider value={value}>{children}</ImageMapContext.Provider>;
});

export const useImageMap = () => useContext(ImageMapContext);
