import { AutoCompoundApe } from 'paraspace-utilities-contract-helpers';
import { useCallback, useMemo } from 'react';

import { withoutGasEstimation } from '../contexts/Web3Context/utils';

import submitTransaction from '@/apps/paraspace/utils/submitTransaction';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

export const useCAPE = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();

  const service = useMemo(() => {
    if (!contracts.cAPE) return null;
    const apeStakingService = new AutoCompoundApe(provider, contracts.cAPE);
    return apeStakingService;
  }, [contracts.cAPE, provider]);

  const stakingAPE = useCallback(
    async (amount: string) => {
      if (!service || !provider) return null;

      const tx = await service.deposit({ user: account, amount });
      return submitTransaction({ provider, tx });
    },
    [service, provider, account]
  );

  const withdrawCAPE = useCallback(
    async (amount: string) => {
      if (!service || !provider) return null;

      const tx = await service.withdraw({ user: account, amount });
      const extendedTxData = await withoutGasEstimation(() => tx.tx());
      return extendedTxData;
    },
    [account, provider, service]
  );
  return {
    stakingAPE,
    withdrawCAPE
  };
};
