import { memo, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { BigText, Button, Inline, Stack, StackProps, Text } from '@parallel-mono/components';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { NFTThumbnail } from '@/apps/paraspace/components';

export type SuccessStateProps = Omit<StackProps, 'children'> & {
  info: {
    amountFromBalance: number | null;
    amountFromCredit: number | null;
    bakcTokenId: number;
    stakedAmount?: number | null;
  };
  onFinish: () => void;
};

const CenteredText = styled(Text)`
  text-align: center;
`;

export const SuccessState = memo(({ info, onFinish, ...others }: SuccessStateProps) => {
  const { amountFromBalance, amountFromCredit, bakcTokenId, stakedAmount } = info;
  const totalAddedAmount = (amountFromBalance ?? 0) + (amountFromCredit ?? 0);
  const totalAmount = totalAddedAmount + (stakedAmount ?? 0);
  const navigate = useNavigate();
  const handleViewBorrow = useCallback(() => {
    onFinish();
    navigate('/');
  }, [navigate, onFinish]);
  return (
    <Stack alignItems="center" {...others}>
      <NFTThumbnail symbol={ERC721Symbol.BAKC} tokenId={bakcTokenId} />
      <Stack alignItems="center" gap="0">
        <BigText fontWeight="bold">
          You have paired {formatBalance(totalAddedAmount)} ApeCoin on #{bakcTokenId}!
        </BigText>
        <CenteredText skin="secondary">
          Your total paired amount is {formatBalance(totalAmount)} ApeCoin on #{bakcTokenId}.{' '}
          {!!amountFromCredit &&
            `Because
          you got $APE from ParaSpace Money Market, you have created a borrow position which you can
          view on your lending position.`}
        </CenteredText>
      </Stack>
      <Inline width="100%">
        <Button size="large" block skin="primary" onClick={onFinish}>
          Done
        </Button>
        {!!amountFromCredit && (
          <Button size="large" block skin="secondary" onClick={handleViewBorrow}>
            View Borrow
          </Button>
        )}
      </Inline>
    </Stack>
  );
});
