import { memo, useMemo } from 'react';

import { UnwrapWETHFormData } from '..';

import { UnwrapWETHSubmitter } from './UnwrapWETHSubmitter';

import { Stepper, StepperProps } from '@/apps/paraspace/components';

type UnwrapWETHStepperProps = Omit<StepperProps, 'steps' | 'step' | 'onError'> & {
  onError: () => void;
  onFinish: () => void;
  formData: UnwrapWETHFormData;
};

export const UnwrapWETHStepper = memo(
  ({ onError, onFinish, formData, ...others }: UnwrapWETHStepperProps) => {
    const steps = useMemo(
      () => [
        {
          description: 'Unwrapping WETH to ETH',
          content: <UnwrapWETHSubmitter formData={formData} onFinish={onFinish} onError={onError} />
        }
      ],
      [formData, onFinish, onError]
    );

    return <Stepper {...others} steps={steps} step={0} />;
  }
);
