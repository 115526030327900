import { Contract, utils, BigNumber as EthersBigNumber, Signer } from 'ethers';
import { Options } from '@layerzerolabs/lz-v2-utilities';
import { Provider } from '@ethersproject/providers';
import BigNumber from 'bignumber.js';

import { parallelChain } from '../../configs';

import parallelTokenAdapterABI from './ParallelTokenAdapterABI.json';
import parallelTokenABI from './ParallelToken.json';

const executorGas = 1000000; // Gas limit for the executor
const executorValue = 0;

export class BridgeContract {
  private contract: Contract;

  private parallelEndPointId: number;

  private decimals: number;

  private nativeTokenDecimals: number;

  constructor(
    addr: string,
    provider: Signer | Provider,
    decimals: number,
    nativeTokenDecimals: number,
    eid: number
  ) {
    const contractABI = eid === parallelChain.eId ? parallelTokenAdapterABI : parallelTokenABI;
    this.contract = new Contract(addr, contractABI, provider);
    this.parallelEndPointId = eid;
    this.decimals = decimals;
    this.nativeTokenDecimals = nativeTokenDecimals;
  }

  public async getSendTx({ to, amount }: { to: string; amount: BigNumber }) {
    const encoder = new utils.AbiCoder();
    const address = encoder.encode(['address'], [to]);

    const { lzTokenFee, nativeFee } = await this.quoteSend({ to: address, amount });
    const options = Options.newOptions().addExecutorLzReceiveOption(executorGas, executorValue);

    const lzFee = EthersBigNumber.from(
      lzTokenFee.shiftedBy(this.nativeTokenDecimals).decimalPlaces(BigNumber.ROUND_CEIL).toString()
    );
    const nFee = EthersBigNumber.from(
      nativeFee.shiftedBy(this.nativeTokenDecimals).decimalPlaces(BigNumber.ROUND_CEIL).toString()
    );

    return this.contract.send(
      {
        dstEid: this.parallelEndPointId.toString(),
        to: address,
        amountLD: amount.shiftedBy(this.decimals).toString(10),
        minAmountLD: '0',
        extraOptions: options.toHex(),
        composeMsg: '0x',
        oftCmd: '0x'
      },
      {
        lzTokenFee: lzFee,
        nativeFee: nFee
      },
      to,
      {
        value: nFee
      }
    );
  }

  public async send({ to, amount }: { to: string; amount: BigNumber }) {
    const result = await this.getSendTx({ to, amount });
    await result.wait();
  }

  public async quoteSend({
    to,
    amount
  }: {
    to: string;
    amount: BigNumber;
  }): Promise<{ lzTokenFee: BigNumber; nativeFee: BigNumber }> {
    const options = Options.newOptions().addExecutorLzReceiveOption(executorGas, executorValue);
    const { nativeFee, lzTokenFee } = await this.contract.quoteSend(
      {
        dstEid: this.parallelEndPointId.toString(),
        to,
        amountLD: amount.shiftedBy(this.decimals).toString(10),
        minAmountLD: '0',
        extraOptions: options.toHex(),
        composeMsg: '0x',
        oftCmd: '0x'
      },
      false
    );

    return {
      nativeFee: new BigNumber(nativeFee.toString()).shiftedBy(-this.nativeTokenDecimals),
      lzTokenFee: new BigNumber(lzTokenFee.toString()).shiftedBy(-this.nativeTokenDecimals)
    };
  }
}
