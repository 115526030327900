import { Button, Card, H4, H5, Stack, Text } from '@parallel-mono/components';
import { InfoPanel } from '@parallel-mono/business-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatNumber } from '@parallel-mono/utils';
import { BigNumber } from 'bignumber.js';

import Duration from '../Duration';
import ShareApyInput from '../ShareApyInput';
import CreateListingModal from '../CreateListingModal';
import { useGetUserApesState } from '../../../../contexts/P2PStakingProvider/hooks';
import { ApeStakingTokenSelector } from '../../../../contexts/P2PStakingProvider/components/ApeStakingTokenSelector';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { ChangeSelectedHandler } from '@/apps/paraspace/components';
import { ApeStakingTokenSymbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { toPercentageCompoundAPY } from '@/apps/paraspace/utils/format';
import { StakingType } from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { HOURS_PER_DAY } from '@/apps/paraspace/consts/fixtures';

export type CreateApeSelector = {
  walletType: WalletType;
  p2pToken: ApeStakingTokenSymbol;
  p2pPool: ApeStakingTokenSymbol;
  poolAPY?: BigNumber;
};

const CreateApeListing = ({ selectorValue }: { selectorValue: CreateApeSelector }) => {
  const [sharedAPY, setSharedAPY] = useState<number | null>(null);
  const [duration, setDuration] = useState(HOURS_PER_DAY * 7);
  const { userInfoLoaded, load } = useMMProvider();
  const { apeAndBakcTokenSet } = useGetUserApesState(selectorValue.p2pPool);

  const [selectedTokens, setSelectedTokens] = useState<
    {
      tokenId: number;
      symbol: ApeStakingTokenSymbol;
      supplied: boolean;
    }[]
  >([]);

  const tokens = useMemo(
    () =>
      apeAndBakcTokenSet
        ? apeAndBakcTokenSet[selectorValue.p2pToken].filter(
            ({ source }) =>
              source === selectorValue.walletType ||
              (selectorValue.walletType === 'AA' && source === null)
          )
        : [],
    [apeAndBakcTokenSet, selectorValue.p2pToken, selectorValue.walletType]
  );

  const selectedToken = useMemo(
    () =>
      selectedTokens.map(it => ({
        tokenId: it.tokenId,
        symbol: it.symbol
      })),
    [selectedTokens]
  );

  const handleSelectedTokensChange = useCallback(
    (updatedTokens: Maybe<{ symbol: ApeStakingTokenSymbol; tokenId: number }[]>) => {
      setSelectedTokens(
        updatedTokens?.map(token => tokens.find(it => it.tokenId === token.tokenId)!) ?? []
      );
    },
    [setSelectedTokens, tokens]
  );

  useEffect(() => {
    setSelectedTokens([]);
  }, [selectorValue.p2pToken]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSharedAPYChange = useCallback((value: number | null) => {
    setSharedAPY(value ?? null);
  }, []);

  const hasAvailableApe = useMemo(() => tokens.length > 0, [tokens.length]);

  const hasSelectedApe = useMemo(
    () => selectedTokens && selectedTokens.length > 0,
    [selectedTokens]
  );

  const panelInfos = useMemo(() => {
    const map = {
      [ERC721Symbol.BAYC]: 'BAKC, ApeCoin',
      [ERC721Symbol.MAYC]: 'BAKC, ApeCoin',
      [ERC721Symbol.BAKC]: 'BAYC/MAYC, ApeCoin'
    };
    const yourSeek =
      selectorValue.p2pPool === ERC721Symbol.BAKC ? map[selectorValue.p2pToken] : 'ApeCoin';
    return [
      {
        title: 'Staking Pool',
        value: selectorValue.p2pPool
      },
      {
        title: 'Pool APY',
        value: selectorValue?.poolAPY ? toPercentageCompoundAPY(selectorValue.poolAPY) : '-'
      },
      {
        title: 'You Provide',
        value: `${hasSelectedApe ? selectedTokens?.length : ''} ${selectorValue.p2pToken}`
      },
      {
        title: 'You Seek',
        value: yourSeek
      },
      {
        title: 'Your APY',
        value: (
          <Text skin="success">
            {sharedAPY && selectorValue.poolAPY
              ? `${formatNumber(selectorValue.poolAPY.times(sharedAPY), {
                  output: 'percent'
                })}/${selectorValue.p2pPool}`
              : '-'}
          </Text>
        )
      }
    ];
  }, [
    hasSelectedApe,
    selectedTokens.length,
    selectorValue.p2pPool,
    selectorValue.p2pToken,
    selectorValue.poolAPY,
    sharedAPY
  ]);

  const handleCreateListing = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleFinish = useCallback(() => {
    setSelectedTokens([]);
    load();
  }, [load]);

  const stakingType = useMemo(() => {
    const selectedPoolSymbol = selectorValue.p2pPool;
    if (selectedPoolSymbol === ERC721Symbol.BAKC) {
      return StakingType.BAKCPairStaking;
    }
    if (selectedPoolSymbol === ERC721Symbol.BAYC) {
      return StakingType.BAYCStaking;
    }
    return StakingType.MAYCStaking;
  }, [selectorValue.p2pPool]);

  return (
    <Card border>
      <Stack>
        <H4>Offer Details</H4>
        <Stack gap=".75rem">
          <Stack gap="0">
            <H5>Select any {selectorValue.p2pToken}</H5>
          </Stack>
          <ApeStakingTokenSelector
            multiselect
            symbols={[selectorValue.p2pToken]}
            walletType={selectorValue.walletType}
            stakingPool={selectorValue.p2pPool}
            selectedToken={selectedToken}
            onChange={handleSelectedTokensChange as ChangeSelectedHandler}
            loading={!userInfoLoaded}
          />
        </Stack>
        <ShareApyInput percentage={sharedAPY} onPercentageChange={handleSharedAPYChange} />
        <Duration duration={duration} onHandleDurationChange={value => setDuration(value)} />
        <InfoPanel skin="primary" infos={panelInfos} />
        <Button
          size="large"
          block
          disabled={!hasSelectedApe || !sharedAPY || isModalOpen}
          onClick={handleCreateListing}
        >
          {!hasAvailableApe ? `No ${selectorValue.p2pToken}` : 'Confirm'}
        </Button>
      </Stack>
      <CreateListingModal
        walletType={selectorValue.walletType}
        stakingType={stakingType}
        isOpen={isModalOpen}
        onFinish={handleFinish}
        onClose={handleCloseModal}
        offerSymbol={selectorValue.p2pToken}
        seekSymbol={selectorValue.p2pPool}
        tokens={selectedTokens}
        wantedPercentage={sharedAPY!}
        duration={duration}
      />
    </Card>
  );
};

export default CreateApeListing;
