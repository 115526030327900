import React, { createContext, useContext, useState, useMemo, useCallback, ReactNode } from 'react';
import { useUpdateEffect } from 'react-use';
import { filter, some, xorWith } from 'lodash';
import BigNumberJs from 'bignumber.js';

import { BuyCartAsset } from './types';
import { CheckoutModal } from './modals';
import { useCheckout } from './useCheckout';

import { useWeb3Context } from '@/apps/paraspace/contexts';

const Context = createContext<{
  buyCartList: BuyCartAsset[];
  isShowCart: boolean;
  totalPrice: BigNumberJs;
  changeBuyCartList: (assets: BuyCartAsset[]) => void;
  removeBuyCartList: (assetList: BuyCartAsset[]) => void;
  clearBuyCartList: () => void;
  isInBuyCart: (contractAddress: string, tokenId: number) => boolean;
  checkout: () => Promise<BuyCartAsset[]>;
}>({
  buyCartList: [],
  isShowCart: false,
  totalPrice: new BigNumberJs(0),
  changeBuyCartList: () => {},
  removeBuyCartList: () => {},
  clearBuyCartList: () => {},
  isInBuyCart: () => false,
  checkout: async () => {
    throw new Error('Not implemented yet');
  }
});

export const useBuyCartProvider = () => useContext(Context);

export const BuyCartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { account } = useWeb3Context();

  const [buyCartList, setBuyCartList] = useState<BuyCartAsset[]>([]);

  const isInBuyCart = useCallback(
    (contractAddress: string, tokenId: number) => some(buyCartList, { contractAddress, tokenId }),
    [buyCartList]
  );

  const changeBuyCartList = useCallback((assets: BuyCartAsset[]) => {
    setBuyCartList(list => {
      return xorWith(
        list,
        assets,
        (prev, next) =>
          prev.contractAddress === next.contractAddress && prev.tokenId === next.tokenId
      );
    });
  }, []);

  const clearBuyCartList = useCallback(() => {
    setBuyCartList([]);
  }, []);

  const removeBuyCartList = useCallback(
    (removeAssetList: BuyCartAsset[]) =>
      setBuyCartList(list =>
        filter(
          list,
          item =>
            !some(removeAssetList, { contractAddress: item.contractAddress, tokenId: item.tokenId })
        )
      ),
    []
  );
  const isShowCart = useMemo(() => buyCartList.length > 0, [buyCartList]);
  const totalPrice = useMemo(
    () => buyCartList.reduce((sum, token) => sum.plus(token.price), new BigNumberJs(0)),
    [buyCartList]
  );

  const [checkoutModalProps, checkoutImpl] = useCheckout(buyCartList);
  const checkout = useCallback(async () => {
    const results = await checkoutImpl();
    removeBuyCartList(results);
    return results;
  }, [checkoutImpl, removeBuyCartList]);

  useUpdateEffect(() => {
    setBuyCartList([]);
  }, [account]);

  const value = useMemo(
    () => ({
      buyCartList,
      isShowCart,
      totalPrice,
      changeBuyCartList,
      removeBuyCartList,
      clearBuyCartList,
      isInBuyCart,
      checkout
      // TODO: should merge with checkout in the future
    }),
    [
      buyCartList,
      isShowCart,
      totalPrice,
      changeBuyCartList,
      removeBuyCartList,
      clearBuyCartList,
      isInBuyCart,
      checkout
    ]
  );

  return (
    <Context.Provider value={value}>
      <CheckoutModal {...checkoutModalProps} />
      {children}
    </Context.Provider>
  );
};
