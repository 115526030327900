import { ReactNode, createContext, memo, useContext, useMemo } from 'react';

import { SupplyNftFromBendDAOModal } from '../modals';
import { useMMProvider } from '../../../contexts/MMProvider';

import { useSupplyNftFromBendDAO } from './hooks';
import { BendDaoPositionProvider, useBendDaoPosition } from './BendDaoPositionProvider';

import { ERC721Symbol } from '@/apps/paraspace/typings';

type BendDaoMoverContextValue = {
  supplyNftFromBendDao: (symbol: ERC721Symbol) => Promise<void>;
};

const BendDaoMoverContext = createContext<BendDaoMoverContextValue>({
  supplyNftFromBendDao: () => {
    throw new Error('Not implemented yet');
  }
});

export const BendDaoMoverManagerProvider = memo(({ children }: { children: ReactNode }) => {
  const [supplyNftFromBendDAOModalProps, supplyNftFromBendDao] = useSupplyNftFromBendDAO();
  const { loadUserERC721Positions } = useMMProvider();
  const { refresh: refreshBenDaoPosition } = useBendDaoPosition();
  const contextValue = useMemo(
    () => ({
      supplyNftFromBendDao: (symbol: ERC721Symbol) =>
        supplyNftFromBendDao(symbol).then(() => {
          loadUserERC721Positions();
          refreshBenDaoPosition();
        })
    }),
    [supplyNftFromBendDao, loadUserERC721Positions, refreshBenDaoPosition]
  );
  return (
    <BendDaoMoverContext.Provider value={contextValue}>
      <SupplyNftFromBendDAOModal {...supplyNftFromBendDAOModalProps} />
      {children}
    </BendDaoMoverContext.Provider>
  );
});

export const BendDaoMoverProvider = memo(({ children }: { children: ReactNode }) => {
  return (
    <BendDaoPositionProvider>
      <BendDaoMoverManagerProvider>{children}</BendDaoMoverManagerProvider>
    </BendDaoPositionProvider>
  );
});

export const useSupplyNftFromBendDao = () => useContext(BendDaoMoverContext).supplyNftFromBendDao;
