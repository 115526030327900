import { memo, useCallback, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';

import { StakeBakcFormData } from '../types';

import {
  Stepper,
  StepperProps,
  DepositERC20Submitter,
  DepositERC721Submitter
} from '@/apps/paraspace/components';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import {
  useAppConfig,
  useContractsMap,
  useERC20,
  useERC721,
  usePoolApeStaking
} from '@/apps/paraspace/hooks';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';

type BakcStakeStepperProps = Omit<StepperProps, 'steps' | 'step'> & {
  formData: StakeBakcFormData;
  onError: () => void;
  onFinish: () => void;
};

export const BakcStakeStepper = memo(
  ({ formData, onError, onFinish, ...others }: BakcStakeStepperProps) => {
    const { erc721Config } = useAppConfig();
    const apeAddress = erc721Config[formData.apeSymbol]?.address ?? '';
    const bakcAddress = erc721Config[ERC721Symbol.BAKC]?.address ?? '';
    const [step, setStep] = useState<number>(0);

    const handleNextStep = useCallback(() => {
      setStep(curr => curr + 1);
    }, [setStep]);

    const contracts = useContractsMap();

    const amountFromBalance = useMemo(() => {
      return new BigNumber(formData.amountFromBalance ?? 0);
    }, [formData]);

    const { createApproval: genApprovalForAPE } = useERC20(contracts.APE);
    const {
      genApprovalForAll: genApprovalForApeNFT,
      genSupplyERC721Txs: genSupplyERC721TxsForApeNFT
    } = useERC721(formData.apeSymbol);
    const { genApprovalForAll: genApprovalForBAKC, genSupplyERC721Txs: genSupplyERC721TxsForBAKC } =
      useERC721(ERC721Symbol.BAKC);
    const { genStakingApe } = usePoolApeStaking();
    const batchTxs = useMemo(
      () => [
        {
          tx: genApprovalForApeNFT({ ids: [formData.apeTokenId] }),
          skip: formData.apeSource === null
        },
        {
          tx: genSupplyERC721TxsForApeNFT({ tokenIds: [formData.apeTokenId] }),
          skip: formData.apeSource === null
        },
        {
          tx: genApprovalForBAKC({ ids: [formData.bakcTokenId] }),
          skip: formData.bakcSource === null
        },
        {
          tx: genSupplyERC721TxsForBAKC({ tokenIds: [formData.bakcTokenId] }),
          skip: formData.bakcSource === null
        },
        {
          tx: genApprovalForAPE({ amount: amountFromBalance }),
          skip: amountFromBalance.lte(0)
        },
        {
          tx: genStakingApe({
            type: formData.apeSymbol,
            bakcTokenId: formData.bakcTokenId,
            borrowAmount: 0,
            cashAmount: 0,
            tokenId: formData.apeTokenId,
            bakcBorrowAmount: formData.amountFromCredit ?? 0,
            bakcCashAmount: formData.amountFromBalance ?? 0
          })
        }
      ],
      [
        genStakingApe,
        genApprovalForAPE,
        genApprovalForApeNFT,
        genApprovalForBAKC,
        genSupplyERC721TxsForApeNFT,
        genSupplyERC721TxsForBAKC,
        formData,
        amountFromBalance
      ]
    );

    const steps = useMemo(
      () => [
        {
          description: `Deposit ${formatBalance(
            new BigNumber(amountFromBalance)
          )} APE to your Parallel account`,
          content: (
            <DepositERC20Submitter
              formData={{
                address: contracts.APE,
                symbol: ERC20Symbol.APE,
                value: new BigNumber(amountFromBalance)
              }}
              onFinish={handleNextStep}
              onError={onError}
            />
          ),
          skip: formData.apeCoinSource !== 'EOA' || amountFromBalance.lte(0)
        },
        {
          description: `Deposit ${formData.apeSymbol} #${formData.apeTokenId} to your Parallel account`,
          content: (
            <DepositERC721Submitter
              key="deposit-ape"
              formData={{
                tokenId: formData.apeTokenId,
                address: apeAddress
              }}
              onFinish={handleNextStep}
              onError={onError}
            />
          ),
          skip: formData.apeSource !== 'EOA'
        },
        {
          description: `Deposit BAKC #${formData.bakcTokenId} to your Parallel account`,
          content: (
            <DepositERC721Submitter
              key="deposit-bakc"
              formData={{
                tokenId: formData.bakcTokenId,
                address: bakcAddress
              }}
              onFinish={handleNextStep}
              onError={onError}
            />
          ),
          skip: formData.bakcSource !== 'EOA'
        },
        {
          description: 'Supply and stake NFTs to ParaSpace',
          content: (
            <BatchTransactionsSubmitter batchTxs={batchTxs} onFinish={onFinish} onError={onError} />
          )
        }
      ],
      [
        apeAddress,
        bakcAddress,
        amountFromBalance,
        contracts,
        formData,
        handleNextStep,
        onError,
        onFinish,
        batchTxs
      ]
    );

    return <Stepper step={step} steps={steps} {...others} />;
  }
);
