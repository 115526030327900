import { memo, useCallback, useState } from 'react';
import { H2, Text, Inline, InlineProps, Stack, Icon, Button } from '@parallel-mono/components';
import styled, { CSSProperties } from 'styled-components';

import { TimelockExplanationModal } from './TimelockExplanationModal';
import { TimelockIcon } from './TimelockIcon';

export type TimelockBannerProps = Omit<InlineProps, 'children'> & {
  onClose?: () => void;
};

const Container = styled(Inline)`
  background: ${({ theme }) => theme.skin.primary.main};
  border-radius: ${({ theme }) => theme.border.radius.large};
  color: ${({ theme }) => theme.skin.primary.contrastText};
  padding: ${({ theme }) => theme.spacing(8)};
`;

const SizedTimelockIcon = styled(TimelockIcon)<{ size: CSSProperties['width'] }>`
  width: {({ size }) => size};
  height: {({ size }) => size};
`;

const GreyText = styled(Text)`
  color: ${({ theme }) => theme.skin.grey[500]};
`;

const Content = styled(Inline)`
  flex: 1;
`;

const CloseButton = styled(Icon).attrs({
  name: 'close'
})`
  color: ${({ theme }) => theme.skin.grey[500]};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.skin.grey[300]};
  }
`;

export const TimelockBanner = memo(({ onClose, ...others }: TimelockBannerProps) => {
  const handleClick = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);

  const handleCloseExplanationModal = useCallback(() => {
    setIsExplanationModalOpen(false);
  }, []);

  const handleLearnMoreClicked = useCallback(() => {
    setIsExplanationModalOpen(true);
  }, []);

  return (
    <Container {...others}>
      <TimelockExplanationModal
        isOpen={isExplanationModalOpen}
        onClose={handleCloseExplanationModal}
      />
      <SizedTimelockIcon size="7.5rem" />
      <Content justifyContent="space-between">
        <Stack>
          <Stack gap="0.25rem">
            <H2>Introducing Timelock</H2>
            <GreyText skin="secondary">
              Your safety is our priority. We’ve implemented a Timelock Security Enhance to shield
              your assets from flash loan attacks, ensuring a more secure and reliable experience on
              ParaSpace.{' '}
            </GreyText>
          </Stack>
          <Button onClick={handleLearnMoreClicked} skin="secondary">
            Learn More
          </Button>
        </Stack>
        <CloseButton onClick={handleClick} />
      </Content>
    </Container>
  );
});
