import {
  Button,
  Card,
  DataGridColumn,
  H2,
  Inline,
  RowClickEventContext,
  Stack,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CryptoIcon } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';
import styled from 'styled-components';

import { CollectionInfo, useCollections } from '@/apps/paraspace/pages/Shop/contexts';
import { DataGrid, NFTCollectionThumbnail } from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol } from '@/apps/paraspace/typings';

const StyledCard = styled(Card)`
  width: 100%;
`;
export const Explore = memo(() => {
  const { collections, loading } = useCollections();

  const { nftInfoMap } = useMMProvider();

  const { mobile } = useBreakpoints();

  const navigate = useNavigate();

  const navTo = useCallback(
    ({ data: collection }: RowClickEventContext<CollectionInfo>) => {
      navigate(`/shop/${collection.contractAddress}`);
    },
    [navigate]
  );

  const columns: DataGridColumn<CollectionInfo>[] = useMemo(
    () => [
      {
        name: 'name',
        title: mobile ? '' : 'Collections',
        render: ({ data: { collectionName, name, symbol } }) => {
          return (
            <Inline gap="1rem" alignItems="center">
              <NFTCollectionThumbnail symbol={symbol as ERC721Symbol} size="small" round />
              <Text>{collectionName ?? name}</Text>
            </Inline>
          );
        }
      },
      {
        name: 'itemCount',
        title: 'Items',
        render: ({ data: { itemCount } }) => {
          return formatNumber(itemCount);
        }
      },
      {
        name: 'Available',
        title: 'Available',
        render: ({ data: { totalListedNFTs } }) => {
          return (
            <Inline gap=".25rem" alignItems="center">
              <Text>{formatNumber(totalListedNFTs?.count ?? 0)}</Text>
            </Inline>
          );
        }
      },
      {
        name: 'floorPrice',
        title: 'Floor Price',
        render: ({ data: { symbol } }) => {
          const nftInfo = nftInfoMap[symbol];
          return (
            <Inline gap=".25rem" alignItems="center">
              <CryptoIcon symbol="ETH" size="1.5rem" />
              <Text>{formatNumber(nftInfo?.priceInETH)}</Text>
            </Inline>
          );
        }
      }
    ],
    [mobile, nftInfoMap]
  );

  const mobileColumns = useMemo(
    () => [
      ...columns,
      {
        name: 'mobileActions',
        title: '',
        render: data => {
          return (
            <Button onClick={() => navTo(data)} block skin="secondary">
              Shop
            </Button>
          );
        }
      }
    ],
    [columns, navTo]
  );

  return (
    <StyledCard border>
      <Stack>
        <H2>Buy NFT with Credit</H2>
        <DataGrid
          classNames={{
            row: 'clickable'
          }}
          onRowClick={mobile ? undefined : navTo}
          columns={mobile ? mobileColumns : columns}
          data={collections}
          loading={loading && collections.length < 1}
        />
      </Stack>
    </StyledCard>
  );
});
