import {
  Button,
  Container,
  H5,
  Inline,
  Skeleton,
  SmallText,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import styled from 'styled-components';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { floor, isNil } from 'lodash';
import { InfoPanel, TokenInput } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';

import { BorrowERC20FormData } from '../types';
import { useDebouncedBorrowApy } from '../../hooks';
import { NoCollateralMessage } from '../../../../components';

import { ConnectWalletMessage } from './ConnectWalletMessage';
import { useValidation } from './useValidation';
import { ETHToggle } from './ETHToggle';

import { BorrowLimitBar, TimelockTerms, useTimelockTermsCheck } from '@/apps/paraspace/components';
import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import {
  ENABLE_OMNI_YIELD,
  MAXIMUM_BALANCE_DECIMALS,
  MAX_BORROW_PERCENTAGE,
  MAX_POOL_UTILIZATION
} from '@/apps/paraspace/pages/config';
import { useNetworkSupportedETH } from '@/apps/paraspace/pages/Credit/MoneyMarket/hooks';

type BorrowERC20FormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  onSubmit: (formData: BorrowERC20FormData) => void;
  defaultSymbol: ERC20Symbol;
  onCancel: () => void;
};

const GrayText = styled(Text).attrs({ type: 'secondary' })`
  display: inline-block;
`;

const BorrowedContainer = styled(Container).attrs({
  border: true,
  width: '100%',
  borderRadius: '0.5rem'
})`
  width: 100%;
  box-sizing: border-box;
  padding: 0.625rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const BorrowERC20Form = memo(
  ({ defaultSymbol, onSubmit, onCancel, ...others }: BorrowERC20FormProps) => {
    const [symbol, setSymbol] = useState(defaultSymbol);
    useEffect(() => {
      setSymbol(defaultSymbol);
    }, [defaultSymbol]);

    const isSupportedETH = useNetworkSupportedETH();
    const showETHWrapToggle =
      isSupportedETH && [ERC20Symbol.WETH, ERC20Symbol.ETH].includes(symbol);
    const handleToggleWrapETH = useCallback((useWETH: boolean) => {
      setSymbol(useWETH ? ERC20Symbol.WETH : ERC20Symbol.ETH);
    }, []);

    const [amount, setAmount] = useState<Maybe<number>>(null);
    const {
      erc20InfoMap,
      overviewUserInfo: {
        liquidationThresholdInUsd,
        totalBorrowedPositionInUsd,
        totalCollateralPositionInUsd,
        borrowLimitInUsd
      }
    } = useMMProvider();

    const { claimableTime, timelockTermsChecked, handleTimelockTermsCheck } = useTimelockTermsCheck(
      amount ?? 0,
      symbol
    );

    const {
      displayName,
      borrowApyRate,
      borrowRewardRate,
      availableLiquidity,
      baseLTVasCollateral,
      priceInUsd,
      reserveLiquidationThreshold,
      totalDebt
    } = erc20InfoMap[symbol];

    const { isUsingUserWallet } = useWeb3Context();

    const userHasCollateral = totalCollateralPositionInUsd.gt(0);

    const exceedMaxUtilization =
      [ERC20Symbol.APE, ERC20Symbol.CAPE].includes(symbol) &&
      totalDebt.div(availableLiquidity.plus(totalDebt)).gte(MAX_POOL_UTILIZATION);

    const borrowLimitValue = useMemo(() => {
      const result = borrowLimitInUsd.minus(totalBorrowedPositionInUsd).div(priceInUsd);
      return BigNumber.max(0, result);
    }, [borrowLimitInUsd, totalBorrowedPositionInUsd, priceInUsd]);

    const userAvailableToBorrow = useMemo(() => {
      if (exceedMaxUtilization) return new BigNumber(0);
      return borrowLimitValue.times(MAX_BORROW_PERCENTAGE);
    }, [exceedMaxUtilization, borrowLimitValue]);

    const errorMessage = useValidation({
      amount,
      borrowLimit: userAvailableToBorrow,
      availableLiquidity,
      tokenDisplayName: displayName,
      exceedMaxUtilization:
        [ERC20Symbol.APE, ERC20Symbol.CAPE].includes(symbol) &&
        totalDebt.div(availableLiquidity.plus(totalDebt)).gte(MAX_POOL_UTILIZATION)
    });

    const { totalBorrowedPositionInUsd: newBorrowed } = useLendingSimulation([
      {
        type: ActionTypeEnum.BORROW,
        targetAsset: {
          id: symbol,
          value: priceInUsd.times(amount || 0),
          LTV: baseLTVasCollateral,
          reserveLiquidationThreshold
        }
      }
    ]);

    const disableBorrow = useMemo(
      () => amount === null || !!errorMessage || !timelockTermsChecked,
      [amount, errorMessage, timelockTermsChecked]
    );

    const handleBorrow = useCallback(async () => {
      if (!isNil(amount) && amount > 0) {
        onSubmit({ amount: amount!, symbol });
      }
    }, [amount, onSubmit, symbol]);

    const borrowApy = useDebouncedBorrowApy({
      amount,
      symbol,
      defaultValue: borrowApyRate
    });

    if (!isUsingUserWallet) {
      return <ConnectWalletMessage token={displayName.toUpperCase()} onConnectWallet={onCancel} />;
    }

    if (!userHasCollateral) {
      return <NoCollateralMessage onSupplyNFT={onCancel} onSupplyERC20={onCancel} />;
    }

    return (
      <Stack {...others}>
        <TokenInput
          label={<H5>Amount</H5>}
          hint={
            <Inline gap="0.25rem" alignItems="center">
              <GrayText>
                Limit: {formatBalance(userAvailableToBorrow)} {displayName}
              </GrayText>
            </Inline>
          }
          token={symbol.toUpperCase()}
          placeholder="0"
          value={amount}
          decimals={MAXIMUM_BALANCE_DECIMALS}
          onChange={setAmount}
          error={errorMessage}
          actionButtonText="50%"
          onAction={() => {
            setAmount(floor(userAvailableToBorrow.div(2).toNumber(), MAXIMUM_BALANCE_DECIMALS));
          }}
          inputProps={{
            inputProps: {
              autoFocus: true
            }
          }}
        />
        {showETHWrapToggle && (
          <ETHToggle
            title="Wrap to WETH"
            desc={`${amount || 1} ETH : ${amount || 1} WETH`}
            checked={symbol !== ERC20Symbol.ETH}
            onCheck={handleToggleWrapETH}
          />
        )}
        <BorrowedContainer>
          <Inline alignItems="center" gap="0.25rem">
            <SmallText skin="secondary">Borrowing:</SmallText>
            <H5>${formatNumber(newBorrowed)}</H5>
          </Inline>
          <BorrowLimitBar
            borrowedValue={newBorrowed}
            borrowLimitValue={borrowLimitInUsd}
            liquidationPointValue={liquidationThresholdInUsd}
            totalSuppliedValue={totalCollateralPositionInUsd}
          />
        </BorrowedContainer>
        <InfoPanel
          skin="primary"
          infos={[
            {
              title: 'Borrow APY',
              value: isNil(borrowApy) ? (
                <Skeleton.Button height="1.5rem" variant="round" />
              ) : (
                formatToPercentage(borrowApy.toNumber(), 2)
              )
            }
          ].concat(
            ENABLE_OMNI_YIELD
              ? [
                  {
                    title: 'Reward APY',
                    value: formatToPercentage(borrowRewardRate.toNumber())
                  }
                ]
              : []
          )}
        />
        <TimelockTerms
          checked={timelockTermsChecked}
          onTermsCheck={handleTimelockTermsCheck}
          claimableTime={claimableTime}
        />

        <Stack gap="1rem">
          <Button skin="primary" size="large" block disabled={disableBorrow} onClick={handleBorrow}>
            {errorMessage || !amount
              ? 'Enter an Amount'
              : `Borrow ${formatBalance(amount)} ${displayName}`}
          </Button>
        </Stack>
      </Stack>
    );
  }
);
