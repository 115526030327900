import { memo, useState, useEffect, useCallback } from 'react';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import {
  ChangeSelectedHandler,
  ERC721TokenSelector,
  ERC721TokenSelectorProps,
  NftToken
} from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';

type BakcSelectorProps = {
  onChange: (data: { tokenId: number; symbol: ERC721Symbol } | null) => void;
} & Omit<ERC721TokenSelectorProps, 'onChange' | 'selectedToken'>;

export const BakcSelector = memo(
  ({ onChange, defaultSelectedToken, ...others }: BakcSelectorProps) => {
    const [selectedApe, setSelectedApe] = useState<Maybe<NftToken>>(
      defaultSelectedToken as NftToken
    );

    useEffect(() => {
      onChange(selectedApe);
    }, [onChange, selectedApe]);

    const createHandleClickItem = useCallback(
      token => {
        setSelectedApe(token);
        onChange(token);
      },
      [onChange]
    );

    return (
      <ERC721TokenSelector
        {...others}
        selectedToken={selectedApe}
        onChange={createHandleClickItem as ChangeSelectedHandler}
      />
    );
  }
);
