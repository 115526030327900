import { FC } from 'react';

import { ImageMapProvider } from './ImageMapProvider';
import { SupplyETHValidatorProvider } from './SupplyETHValidatorManager';
import { ClaimETHValidatorProvider } from './ClaimETHValidatorManager';

export const ETHValidatorManagerProvider: FC = ({ children }) => {
  return (
    <ImageMapProvider>
      <SupplyETHValidatorProvider>
        <ClaimETHValidatorProvider>{children}</ClaimETHValidatorProvider>
      </SupplyETHValidatorProvider>
    </ImageMapProvider>
  );
};
