import { useMemo, createContext, memo, ReactNode, useState, useContext, useEffect } from 'react';

import { useWeb3Context } from '../Web3Context';

import { getToggles, Toggles } from '@/apps/paraspace/toggles';
import { useGetFeatureTogglesLazyQuery } from '@/apps/paraspace/generated/graphql';

type TogglesContextValue = {
  toggles: Toggles;
  updateToggle: (updater: (prev: Toggles) => Toggles) => void | ((v: Toggles) => void);
};

const TogglesContext = createContext<TogglesContextValue>({
  toggles: {},
  updateToggle: () => {
    throw new Error('not implemented yet');
  }
});

export const TogglesProvider = memo(({ children }: { children: ReactNode }) => {
  const { env } = useWeb3Context();
  const [toggles, setToggles] = useState<{ [k: string]: boolean }>(getToggles(env));
  const [getFeatureToggles, { data }] = useGetFeatureTogglesLazyQuery();

  useEffect(() => {
    const serverToggles = data?.featureToggles.reduce((acc, current) => {
      acc[current.name] = current.value;
      return acc;
    }, {} as Toggles);
    setToggles(prevToggles => ({ ...prevToggles, ...serverToggles }));
  }, [data]);

  useEffect(() => {
    getFeatureToggles();
  }, [getFeatureToggles]);

  const value: TogglesContextValue = useMemo(
    () => ({
      toggles,
      updateToggle: setToggles
    }),
    [toggles, setToggles]
  );

  return <TogglesContext.Provider value={value}>{children}</TogglesContext.Provider>;
});

export const useToggle = (key: string) => {
  return useContext(TogglesContext).toggles[key];
};

export const useToggles = () => {
  return useContext(TogglesContext).toggles;
};

export const useToggleUpdater = () => {
  return useContext(TogglesContext).updateToggle;
};
