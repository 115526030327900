import BigNumber from 'bignumber.js';
import { ReactNode, createContext, memo, useCallback, useContext, useMemo } from 'react';
import { Address, encodeFunctionData } from 'viem';
import { Provider } from '@ethersproject/providers';
import { ChildToParentMessage, ChildToParentTransactionEvent } from '@arbitrum/sdk';
import { ContractReceipt } from 'ethers';

import { useNativeCurrency } from '../../hooks';
import { ArbSysAbi } from '../../Contracts';
import { useNetworkProvider } from '../../hooks/useNetworkProvider';
import { ARB_SYS, parallelChain, parallelNetworkBridgeConfig } from '../../configs';

import { useBridgeToken } from './hooks';
import { BridgeTokenModal } from './BridgeTokenModal';

import { useEOAProvider } from '@/apps/parax/contexts';
import { getUserFriendlyError } from '@/apps/parax/utils';

type BridgeTokenContextValue = {
  bridgeERC20Token: (params: {
    symbol: string;
    amount: BigNumber;
    receiver: string;
    toChainEid: number;
  }) => Promise<void>;
  bridgeNativeToken: (params: {
    amount: BigNumber;
    receiver: string;
    l1GasFeeInWei: BigNumber;
    l2GasFeeInWei: BigNumber;
    toChainEid: number;
  }) => Promise<void>;
  withdrawNativeToken: (amount: BigNumber, destination?: string) => Promise<ContractReceipt>;
  claimWithdrawal: (event: ChildToParentTransactionEvent) => Promise<ContractReceipt>;
};

const BridgeTokenContext = createContext<BridgeTokenContextValue>({
  bridgeERC20Token: () => {
    throw new Error('Not Implemented yet');
  },
  bridgeNativeToken: () => {
    throw new Error('Not Implemented yet');
  },
  withdrawNativeToken: (_amount: BigNumber, _destination?: string) => {
    throw new Error('Not Implemented yet');
  },
  claimWithdrawal: _event => {
    throw new Error('Not Implemented yet');
  }
});

export const BridgeTokenProvider = memo(({ children }: { children: ReactNode }) => {
  const [modalProps, bridgeToken] = useBridgeToken();
  const { provider } = useEOAProvider();
  const { account: eoaAccount } = useEOAProvider();
  const l1Provider = useNetworkProvider(parallelNetworkBridgeConfig.parentChainId as number);
  const l2Provider = useNetworkProvider(parallelChain.chainId);

  const nativeCurrency = useNativeCurrency();

  const bridgeERC20Token = useCallback(
    (params: { symbol: string; amount: BigNumber; receiver: string; toChainEid: number }) =>
      bridgeToken(params),
    [bridgeToken]
  );

  const bridgeNativeToken = useCallback(
    (params: {
      amount: BigNumber;
      receiver: string;
      l1GasFeeInWei: BigNumber;
      l2GasFeeInWei: BigNumber;
      toChainEid: number;
    }) => bridgeToken({ ...params, symbol: nativeCurrency!.symbol }),
    [nativeCurrency, bridgeToken]
  );

  const withdrawNativeToken = useCallback(
    async (amount: BigNumber, destination = eoaAccount) => {
      try {
        const tx = {
          to: ARB_SYS,
          // @ts-ignore
          data: encodeFunctionData({
            abi: ArbSysAbi,
            functionName: 'withdrawEth',
            args: [destination as Address]
          }),
          value: amount.shiftedBy(18).toString(10)
          // chainId: parallelTestnetChainConfig.chainId
        };
        const txResult = await provider.getSigner().sendTransaction(tx);
        return txResult.wait();
      } catch (e) {
        throw getUserFriendlyError(e);
      }
    },
    [eoaAccount, provider]
  );

  const claimWithdrawal = useCallback(
    async (event: ChildToParentTransactionEvent) => {
      try {
        const parentChainProvider = l1Provider as Provider;
        const childChainProvider = l2Provider as Provider;

        const messageWriter = ChildToParentMessage.fromEvent(
          provider.getSigner(),
          event,
          parentChainProvider
        );
        const res = await messageWriter.execute(childChainProvider);

        return res.wait();
      } catch (e) {
        throw getUserFriendlyError(e);
      }
    },
    [l1Provider, l2Provider, provider]
  );

  const value = useMemo(
    () => ({
      bridgeERC20Token,
      bridgeNativeToken,
      withdrawNativeToken,
      claimWithdrawal
    }),
    [bridgeERC20Token, bridgeNativeToken, claimWithdrawal, withdrawNativeToken]
  );

  return (
    <BridgeTokenContext.Provider value={value}>
      <BridgeTokenModal {...modalProps} />
      {children}
    </BridgeTokenContext.Provider>
  );
});

export const useBridgeTokenImpl = () => useContext(BridgeTokenContext);
