import { useMemo } from 'react';
import BigNumberJS from 'bignumber.js';

import { PaymentTokenOption } from './useSelectPaymentToken';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import useCreditBalance from '@/apps/paraspace/pages/hooks/useCreditBalance';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';

export const useBalanceAndFutureCredit = ({
  selectedPaymentToken,
  symbol,
  multiplier
}: {
  selectedPaymentToken: PaymentTokenOption;
  symbol: string;
  multiplier?: BigNumberJS;
}) => {
  const { erc20InfoMap } = useMMProvider();
  const { balance: wETHBalance } = erc20InfoMap[ERC20Symbol.WETH] || {};
  const { priceInUsd: selectedTokenPriceInUsd, balance: selectedTokenBalance } =
    erc20InfoMap[selectedPaymentToken?.value] || {};

  const { futureAvailableCredit } = useCreditBalance({
    symbol: symbol?.toUpperCase(),
    creditBasedCurrencyValueInUsd: selectedTokenPriceInUsd,
    multiplier
  });

  const walletBalance = useMemo(() => {
    if (selectedPaymentToken?.value.toUpperCase() === ERC20Symbol.WETH) {
      return wETHBalance ?? zero;
    }
    return selectedTokenBalance ?? zero;
  }, [selectedPaymentToken?.value, selectedTokenBalance, wETHBalance]);

  return {
    futureAvailableCredit,
    walletBalance,
    selectedTokenPriceInUsd,
    selectedTokenBalance
  };
};
