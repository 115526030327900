import {
  ColorMode,
  RecursivePartial,
  ThemeConfig,
  ThemeProvider,
  defaultThemeConfig
} from '@parallel-mono/components';
import { Suspense, lazy, memo, useMemo } from 'react';
import { merge } from 'lodash';
import { Route, Routes, matchPath } from 'react-router-dom';
import { useLocation } from 'react-use';

import { COLOR_MODE_STORAGE_KEY, getNetworkByChainPath } from '../config';
import { GlobalStyles } from '../theme';
import { EOAProvider, XToastProvider } from '../contexts';
import { ImperativeBrowserRouter, WalletModalContextProvider } from '../components';
import { Bridge } from '../pages';
import './app.css';
import { TogglesProvider } from '../contexts/TogglesContext';

import { AppsFramework } from './AppsFramework';
import { Scaffold } from './Scaffold';
import { DisableTestnet } from './DisableTestnet';

const alteredComponents: RecursivePartial<ThemeConfig> = {
  shadows: {
    primary: '0px 0px 16px rgba(58, 78, 88, 0.05)',
    secondary: '0px 4px 16px rgba(58, 78, 88, 0.55)'
  },
  components: {
    Typography: {
      darkSkin: {
        primary: {
          main: '#FFFFFF'
        },
        secondary: {
          main: '#BFBFBF'
        }
      }
    }
  }
};

const breakPointsOverrides: RecursivePartial<ThemeConfig> = {
  breakpoints: {
    tablet: 1024,
    desktop: 1280
  }
};

const mergedThemeConfig = merge({}, defaultThemeConfig, alteredComponents, breakPointsOverrides);

const LazyDeveloperTools = lazy(() =>
  import('../DeveloperTools').then(({ DeveloperTools }) => ({
    default: DeveloperTools
  }))
);

export const ParaX = memo(() => {
  const defaultColorMode =
    localStorage.getItem(COLOR_MODE_STORAGE_KEY) === ColorMode.dark
      ? ColorMode.dark
      : ColorMode.light;
  const location = useLocation();
  const match = useMemo(
    () => matchPath(':chainName/*', location.pathname ?? ''),
    [location.pathname]
  );
  const chainPathName = match?.params.chainName;
  const chainIdFromUrl = useMemo(() => getNetworkByChainPath(chainPathName ?? ''), [chainPathName]);
  return (
    <ThemeProvider defaultMode={defaultColorMode} themeConfig={mergedThemeConfig}>
      <GlobalStyles />
      <XToastProvider>
        <TogglesProvider>
          <Suspense fallback={null}>
            <LazyDeveloperTools />
          </Suspense>
          <EOAProvider defaultChain={chainIdFromUrl ?? undefined}>
            <WalletModalContextProvider>
              <ImperativeBrowserRouter>
                <DisableTestnet>
                  <Scaffold>
                    <Routes>
                      <Route path="/bridge/*" element={<Bridge />} />
                      <Route path="*" element={<AppsFramework />} />
                    </Routes>
                  </Scaffold>
                </DisableTestnet>
              </ImperativeBrowserRouter>
            </WalletModalContextProvider>
          </EOAProvider>
        </TogglesProvider>
      </XToastProvider>
    </ThemeProvider>
  );
});
