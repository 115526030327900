import { Stack, H3, Text, Button } from '@parallel-mono/components';
import { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as NotFoundImage } from '@/apps/paraspace/assets/not-found.svg';

const NotFoundWrapper = styled(Stack)`
  margin-top: 10rem;
`;

const Base: FC = () => {
  const navigate = useNavigate();

  const goBackHomePage = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <NotFoundWrapper alignItems="center" gap="2rem">
      <NotFoundImage width="20rem" />
      <Stack alignItems="center" gap="0">
        <H3>We Are Sorry, Something Isn’t Right.</H3>
        <Text>Please try again later.</Text>
      </Stack>
      <Button onClick={goBackHomePage} size="large">
        Back to Homepage
      </Button>
    </NotFoundWrapper>
  );
};

export const NotFound = memo(Base);
