import { H3, Icon, Inline, Modal } from '@parallel-mono/components';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { AddWETHForm } from '../../../../components';
import { MakeOfferModalPayload } from '../../../../types';

import { MakeCollectionOfferForm } from './MakeCollectionOfferForm';

const InlineWithCursor = styled(Inline)`
  cursor: pointer;
`;

export const MakeCollectionOfferModal = ({
  isOpen,
  data,
  closeModal,
  updateCollection
}: {
  isOpen: boolean;
  data: MakeOfferModalPayload;
  closeModal: () => void;
  updateCollection: () => void;
}) => {
  const [showAddWETH, setShowAddWETH] = useState(false);

  const hideAddWETHForm = useCallback(() => {
    setShowAddWETH(false);
  }, []);

  const showAddWETHForm = useCallback(() => {
    setShowAddWETH(true);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      keepMounted={false}
      closeOnBackdropClick={false}
      title={
        showAddWETH ? (
          <InlineWithCursor gap="1rem" alignItems="center" onClick={hideAddWETHForm}>
            <Icon name="arrowLeft" />
            <H3>Add wETH</H3>
          </InlineWithCursor>
        ) : (
          'Bid Collection'
        )
      }
    >
      {showAddWETH ? (
        <AddWETHForm hideAddWETHForm={hideAddWETHForm} />
      ) : (
        <MakeCollectionOfferForm
          data={data}
          closeModal={closeModal}
          onAddWETHClick={showAddWETHForm}
          updateCollection={updateCollection}
        />
      )}
    </Modal>
  );
};
