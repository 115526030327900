import {
  FC,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { isEmpty } from 'lodash';

import { useListableCollections } from '../hooks';
import { ListableCollection, ListableToken } from '../types';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type ContextValue = {
  selectedListTokens: ListableToken[];
  handleSelectListTokens: (symbol: ERC721Symbol, tokens: ListableToken[]) => void;
  clearSelectedTokens: () => void;
  refreshSelectedTokens: () => void;
  handleDeleteListToken: (token: ListableToken) => void;
  listableCollections: ListableCollection[];
  loading: boolean;
};

const ListTokensContext = createContext({
  selectedListTokens: [],
  handleSelectListTokens: _tokens => console.log('Not implement yet.'),
  clearSelectedTokens: () => console.log('Not implement yet.'),
  refreshSelectedTokens: () => console.log('Not implement yet.'),
  handleDeleteListToken: () => console.log('Not implement yet.'),
  listableCollections: [],
  loading: false
} as ContextValue);

export const ListTokensContextProvider: FC = memo(({ children }) => {
  const { account } = useWeb3Context();
  const [selectedListTokens, setSelectedListTokens] = useState<ListableToken[]>([]);

  const { listableCollections, loading: collectionsLoading } = useListableCollections();

  // reset the selected data when switching account
  useEffect(() => {
    setSelectedListTokens([]);
  }, [account]);

  const handleSelectListTokens = useCallback((symbol: ERC721Symbol, tokens: ListableToken[]) => {
    setSelectedListTokens(prev => prev.filter(token => token.symbol !== symbol).concat(tokens));
  }, []);

  const handleDeleteListToken = useCallback((listToken: ListableToken) => {
    setSelectedListTokens(prev =>
      prev.filter(
        token => !(token.symbol === listToken.symbol && token.tokenId === listToken.tokenId)
      )
    );
  }, []);

  const clearSelectedTokens = useCallback(() => {
    setSelectedListTokens([]);
  }, []);

  const refreshSelectedTokens = useCallback(() => {
    setSelectedListTokens(prevSelectedTokens => {
      if (isEmpty(prevSelectedTokens)) {
        return prevSelectedTokens;
      }
      return prevSelectedTokens.filter(
        ({ symbol: selectedTokenSymbol, tokenId: selectedTokenId }) => {
          const collection = listableCollections.find(v => v.symbol === selectedTokenSymbol);
          return collection?.tokens.find(v => v.tokenId === selectedTokenId);
        }
      );
    });
  }, [listableCollections]);

  const value = useMemo(
    () => ({
      selectedListTokens,
      handleSelectListTokens,
      handleDeleteListToken,
      clearSelectedTokens,
      refreshSelectedTokens,
      listableCollections,
      loading: collectionsLoading
    }),
    [
      selectedListTokens,
      handleSelectListTokens,
      handleDeleteListToken,
      clearSelectedTokens,
      refreshSelectedTokens,
      listableCollections,
      collectionsLoading
    ]
  );
  return <ListTokensContext.Provider value={value}>{children}</ListTokensContext.Provider>;
});

export const useListTokens = () => useContext(ListTokensContext);
