import { SVGAttributes, memo } from 'react';
import { useTheme } from 'styled-components';

type TimelockIconProps = SVGAttributes<SVGElement>;

export const TimelockIcon = memo((props: TimelockIconProps) => {
  const theme = useTheme();
  const bgColor = theme.skin.primary.main;
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_111_8673)">
        <path
          d="M60 0L60.4666 53.4767L68.5389 0.610714L61.3902 53.6095L76.904 2.43042L62.2855 53.8723L84.9249 5.42208L63.1343 54.26L92.4384 9.52479L63.9193 54.7645L99.2916 14.655L64.6245 55.3755L105.345 20.7084L65.2355 56.0807L110.475 27.5616L65.74 56.8657L114.578 35.0751L66.1277 57.7145L117.57 43.096L66.3905 58.6098L119.389 51.4611L66.5233 59.5334L120 60L66.5233 60.4666L119.389 68.5389L66.3905 61.3902L117.57 76.904L66.1277 62.2855L114.578 84.9249L65.74 63.1343L110.475 92.4384L65.2355 63.9193L105.345 99.2916L64.6245 64.6245L99.2916 105.345L63.9193 65.2355L92.4384 110.475L63.1343 65.74L84.9249 114.578L62.2855 66.1277L76.904 117.57L61.3902 66.3905L68.5389 119.389L60.4666 66.5233L60 120L59.5334 66.5233L51.4611 119.389L58.6098 66.3905L43.096 117.57L57.7145 66.1277L35.0751 114.578L56.8657 65.74L27.5616 110.475L56.0807 65.2355L20.7084 105.345L55.3755 64.6245L14.655 99.2916L54.7645 63.9193L9.52479 92.4384L54.26 63.1343L5.42208 84.9249L53.8723 62.2855L2.43042 76.904L53.6095 61.3902L0.610714 68.5389L53.4767 60.4666L0 60L53.4767 59.5334L0.610714 51.4611L53.6095 58.6098L2.43042 43.096L53.8723 57.7145L5.42208 35.0751L54.26 56.8657L9.52479 27.5616L54.7645 56.0807L14.655 20.7084L55.3755 55.3755L20.7084 14.655L56.0807 54.7645L27.5616 9.52479L56.8657 54.26L35.0751 5.42208L57.7145 53.8723L43.096 2.43042L58.6098 53.6095L51.4611 0.610714L59.5334 53.4767L60 0Z"
          fill="currentColor"
        />
        <path
          d="M59.5 92.6C59.5 92.6 85 79.68 85 60.3V37.69L59.5 28L34 37.69V60.3C34 79.68 59.5 92.6 59.5 92.6Z"
          fill={bgColor}
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69 90C77.2843 90 84 83.2843 84 75C84 66.7157 77.2843 60 69 60C60.7157 60 54 66.7157 54 75C54 83.2843 60.7157 90 69 90Z"
          fill={bgColor}
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.7568 68.8174V77.8174L72.7568 80.8174"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_111_8673">
          <rect width="120" height="120" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
});
