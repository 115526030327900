import React, {
  ReactNode,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Pool, UiPoolDataProvider } from 'paraspace-utilities-contract-helpers';
import { JsonRpcProvider } from '@ethersproject/providers';
import { Network } from 'paraspace-configs-v2';
import BigNumber from 'bignumber.js';

import { useAutoPolledState } from '../hooks';

import { getUserAccountData, getUserERC20PositionMap, getUserERC721PositionMap } from './utils';
import { AccountData, ERC20Position, ERC721Position } from './types';

import { ERC20Symbol, ERC721Symbol, FetchingStatus } from '@/apps/paraspace/typings';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';

type UserPositionContextValue = {
  erc20PositionMap: Partial<Record<ERC20Symbol, ERC20Position>> | null;
  erc721PositionMap: Partial<Record<ERC721Symbol, ERC721Position>> | null;
  accountData: Maybe<AccountData>;

  refreshUserERC20PositionMap: () => Promise<void>;
  refreshUserERC721PositionMap: () => Promise<void>;
  refreshUserAccountData: () => Promise<void>;

  erc20PositionMapPollingStatus: FetchingStatus;
  erc721PositionMapPollingStatus: FetchingStatus;
  accountDataPollingStatus: FetchingStatus;
};

type UserPositionProviderProps = {
  children: ReactNode;
  pollingInterval: number;
  account: string;
  provider: JsonRpcProvider;
  chainId: Network;
};

export const createUserPositionProvider = (displayName: string) => {
  const UserPositionContext = React.createContext<UserPositionContextValue>({
    erc20PositionMap: null,
    erc721PositionMap: null,
    accountData: null,
    refreshUserERC20PositionMap: async () => {
      throw new Error('Not implemented yet');
    },
    refreshUserERC721PositionMap: async () => {
      throw new Error('Not implemented yet');
    },
    refreshUserAccountData: async () => {
      throw new Error('Not implemented yet');
    },
    erc20PositionMapPollingStatus: FetchingStatus.INIT,
    erc721PositionMapPollingStatus: FetchingStatus.INIT,
    accountDataPollingStatus: FetchingStatus.INIT
  });

  const UserPositionProvider = memo(
    ({ children, pollingInterval, account, provider, chainId }: UserPositionProviderProps) => {
      const { erc20Config, erc721Config, contracts } = useAppConfig();
      const [marketReferenceCurrencyData, setMarketReferenceCurrencyData] = useState({
        priceInUSD: 1,
        decimals: 18
      });

      const uiPoolDataProvider = useMemo(
        () =>
          new UiPoolDataProvider({
            uiPoolDataProviderAddress: contracts.UiPoolDataProvider,
            provider,
            chainId
          }),
        [provider, chainId, contracts.UiPoolDataProvider]
      );

      useEffect(() => {
        uiPoolDataProvider
          .getReservesHumanized({
            lendingPoolAddressProvider: contracts.PoolAddressesProvider
          })
          .then(res => {
            setMarketReferenceCurrencyData({
              decimals: res.baseCurrencyData.marketReferenceCurrencyDecimals,
              priceInUSD: BigNumber(res.baseCurrencyData.marketReferenceCurrencyPriceInUsd)
                .shiftedBy(-8)
                .toNumber()
            });
          });
      }, [contracts.PoolAddressesProvider, uiPoolDataProvider]);

      const poolService = useMemo(
        () =>
          new Pool(provider, {
            POOL: contracts.PoolProxy,
            WETH_GATEWAY: contracts.WETHGatewayProxy
          }),
        [provider, contracts]
      );

      const pollUserERC20PositionMap = useCallback(async (): Promise<
        UserPositionContextValue['erc20PositionMap']
      > => {
        if (!account) {
          return null;
        }
        return getUserERC20PositionMap({
          account,
          provider: uiPoolDataProvider,
          lendingPoolAddressProvider: contracts.PoolAddressesProvider,
          erc20Config
        });
      }, [account, uiPoolDataProvider, erc20Config, contracts.PoolAddressesProvider]);

      const pollUserERC721PositionMap = useCallback(async (): Promise<
        UserPositionContextValue['erc721PositionMap']
      > => {
        if (!account) {
          return null;
        }
        return getUserERC721PositionMap({
          account,
          provider: uiPoolDataProvider,
          lendingPoolAddressProvider: contracts.PoolAddressesProvider,
          erc721Config
        });
      }, [account, uiPoolDataProvider, erc721Config, contracts.PoolAddressesProvider]);

      const pollUserAccountData = useCallback(async (): Promise<
        UserPositionContextValue['accountData']
      > => {
        if (!account) {
          return null;
        }
        return getUserAccountData({
          account,
          poolService,
          marketReferenceCurrencyData
        });
      }, [account, marketReferenceCurrencyData, poolService]);

      const {
        pollingStatus: erc20PositionMapPollingStatus,
        state: erc20PositionMap,
        refresh: refreshUserERC20PositionMap
      } = useAutoPolledState({
        pollingInterval,
        pollFn: pollUserERC20PositionMap,
        initialState: null
      });

      const {
        pollingStatus: erc721PositionMapPollingStatus,
        state: erc721PositionMap,
        refresh: refreshUserERC721PositionMap
      } = useAutoPolledState({
        pollingInterval,
        pollFn: pollUserERC721PositionMap,
        initialState: null
      });

      const {
        pollingStatus: accountDataPollingStatus,
        state: accountData,
        refresh: refreshUserAccountData
      } = useAutoPolledState({
        pollingInterval,
        pollFn: pollUserAccountData,
        initialState: null
      });

      const value = useMemo(
        () => ({
          erc20PositionMapPollingStatus,
          erc20PositionMap,
          refreshUserERC20PositionMap,
          erc721PositionMapPollingStatus,
          erc721PositionMap,
          refreshUserERC721PositionMap,
          accountDataPollingStatus,
          accountData,
          refreshUserAccountData
        }),
        [
          erc20PositionMapPollingStatus,
          erc20PositionMap,
          refreshUserERC20PositionMap,
          erc721PositionMapPollingStatus,
          erc721PositionMap,
          refreshUserERC721PositionMap,
          accountDataPollingStatus,
          accountData,
          refreshUserAccountData
        ]
      );

      const stabilizedValue = useStabilizedSnapshot(value);

      return (
        <UserPositionContext.Provider value={stabilizedValue}>
          {children}
        </UserPositionContext.Provider>
      );
    }
  );

  UserPositionProvider.displayName = displayName;

  return {
    UserPositionProvider,
    useUserPosition: () => useContext(UserPositionContext)
  };
};
