import { isNil, values } from 'lodash';
import {
  memo,
  useState,
  ReactNode,
  useCallback,
  createContext,
  useMemo,
  useContext,
  useRef,
  useEffect
} from 'react';
import { useMount } from 'react-use';
import { TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { initializeConnector } from '@web3-react/core';
import { Inline } from '@parallel-mono/components';
import { WalletTypeEnum } from 'parax-sdk';
import { BigNumber } from 'ethers';
import { EthereumTransactionTypeExtended } from 'paraspace-utilities-contract-helpers';
import { isAddress } from 'ethers/lib/utils';

import { useToggles } from '../TogglesContext';

import {
  LAST_CONNECTED_TYPE_LOCAL_SESSION_NAME,
  ORDERED_WALLET_TO_INIT,
  UNRECOGNIZED_CHAIN_ERR_CODE
} from './consts';
import { getAddChainParameters, isWalletInstalled, rewriteGasEstimation } from './utils';
import { useWalletConnectorsAndData } from './hooks';
import { NetworkSwitchingProcess, PageConnectLoading } from './components';

import { Maybe } from '@/apps/parax/typings/basic';
import { Network, SUPPORTED_NETWORKS } from '@/apps/parax/config';
import { getUserFriendlyError } from '@/apps/parax/utils';

type EOAContextValue = Maybe<{
  connectWallet: (wallet: WalletTypeEnum) => void;
  disconnectWallet: () => void;
  switchNetwork: (chainId: Network) => Promise<void>;
  walletType: WalletTypeEnum;
  connector: ReturnType<typeof initializeConnector>[0];
  provider: Web3Provider;
  chainId: Network;
  account: string;
  active: boolean;
  walletInActivating: Maybe<WalletTypeEnum>;
  isUsingUserWallet: boolean;
  isSelectingUnsupportedNetwork: boolean;
  submitTransaction: (tx: EthereumTransactionTypeExtended) => Promise<TransactionResponse>;
}>;

const EOAContext = createContext<EOAContextValue>(null);

type Web3ProviderProps = {
  children: ReactNode;
  defaultChain?: Network;
};

const memoizedWalletType = localStorage.getItem(
  LAST_CONNECTED_TYPE_LOCAL_SESSION_NAME
) as Maybe<WalletTypeEnum>;

export const EOAProvider = memo(({ children, defaultChain }: Web3ProviderProps) => {
  const [walletType, setWalletType] = useState<WalletTypeEnum>(WalletTypeEnum.NETWORK);
  const [walletInActivating, setWalletInActivating] = useState<Maybe<WalletTypeEnum>>(null);
  const [loaded, setLoaded] = useState(false);

  const connectorsAndDataMap = useWalletConnectorsAndData();
  const {
    [walletType]: {
      connector,
      data: { chainId = null, provider, isActive }
    }
  } = connectorsAndDataMap;

  const toggles = useToggles();

  const account = useMemo(() => {
    const viewAddress = toggles.VIEW_ADDRESS as string;
    if (viewAddress && isAddress(viewAddress)) {
      return viewAddress;
    }
    return connectorsAndDataMap[walletType].data.account;
  }, [connectorsAndDataMap, toggles.VIEW_ADDRESS, walletType]);

  const connectingPromiseRef = useRef<Promise<void> | void>();
  const connectWallet = useCallback(
    async (walletTypeToConnect: WalletTypeEnum, network?: Network) => {
      if (walletInActivating === walletTypeToConnect) {
        await connectingPromiseRef.current;
      }

      if (walletInActivating !== null) {
        throw new Error(
          `can not activate ${walletTypeToConnect} while ${walletInActivating} is activating`
        );
      }

      const { connector: targetConnector } = connectorsAndDataMap[walletTypeToConnect];

      try {
        setWalletInActivating(walletTypeToConnect);
        connectingPromiseRef.current = targetConnector
          .activate(
            [WalletTypeEnum.NETWORK, WalletTypeEnum.WALLET_CONNECT].includes(walletTypeToConnect) ||
              isNil(network)
              ? network
              : { ...getAddChainParameters(network), chainId: network }
          )
          ?.then(() => targetConnector.connectEagerly?.());
        await connectingPromiseRef.current;
        setWalletType(walletTypeToConnect);
        if (walletTypeToConnect !== WalletTypeEnum.GNOSIS_SAFE) {
          localStorage.setItem(LAST_CONNECTED_TYPE_LOCAL_SESSION_NAME, walletTypeToConnect);
        }
      } catch (e) {
        console.error('connect wallet error', e);
        throw e;
      } finally {
        setWalletInActivating(null);
        connectingPromiseRef.current = undefined;
      }
    },
    [walletInActivating, connectorsAndDataMap]
  );

  const disconnectWallet = useCallback(async () => {
    if (walletType === WalletTypeEnum.NETWORK) {
      return;
    }
    try {
      setWalletType(WalletTypeEnum.NETWORK);
      localStorage.removeItem(LAST_CONNECTED_TYPE_LOCAL_SESSION_NAME);
      await connector.deactivate?.();
    } catch (e) {
      console.error('disconnect error');
      console.error(e);
      throw e;
    }
  }, [walletType, connector]);

  const switchNetwork = useCallback(
    async (targetNetwork: Network) => {
      try {
        await connector.activate(
          walletType === WalletTypeEnum.NETWORK ? targetNetwork : { chainId: targetNetwork }
        );
      } catch (switchNetworkErr) {
        const { code, message } = switchNetworkErr as { code: number; message: string };
        if (code === UNRECOGNIZED_CHAIN_ERR_CODE || message.startsWith('Unrecognized chain ID')) {
          try {
            await connector?.provider?.request({
              method: 'wallet_addEthereumChain',
              params: [{ ...getAddChainParameters(targetNetwork) }]
            });
          } catch (addingNetworkErr) {
            console.error('error while adding new network');
            console.error(addingNetworkErr);
            throw addingNetworkErr;
          }
        }
        // There are some wallets does not support the network yet
        else if (message.startsWith('Cannot activate an optional chain')) {
          throw getUserFriendlyError(
            switchNetworkErr,
            'Error: The current wallet does not support this network '
          );
        } else {
          console.error('error while switching network', switchNetworkErr);
          throw switchNetworkErr;
        }
      }
    },
    [connector, walletType]
  );

  useMount(() => {
    // activate network wallet by default
    connectorsAndDataMap.network.connector.activate()?.catch(e => {
      console.warn('Default Network Connector activate failed');
      console.warn(e);
    });
  });

  // TODO: only disconnectWallet when the wallet is WalletTypeEnum.WALLET_CONNECT
  const handleConnectWalletDisconnect = useCallback(() => {
    if (walletType === WalletTypeEnum.WALLET_CONNECT) {
      disconnectWallet();
    }
  }, [disconnectWallet, walletType]);

  useEffect(() => {
    const handleChainChanged = () => {
      connector.activate();
    };

    const handleAccountsChange = (connectedAccounts: string[]) => {
      if (connectedAccounts.length === 0) {
        connectWallet(WalletTypeEnum.NETWORK);
      }
    };

    // when connected with metamask, chainChanged event won't trigger, have to subscribe using window.ethereum?.addListener
    window.ethereum?.addListener?.('chainChanged', handleChainChanged);
    connector.provider?.on('chainChanged', handleChainChanged);
    connector.provider?.on('accountsChanged', handleAccountsChange);
    connector.provider?.on('disconnect', handleConnectWalletDisconnect);
    return () => {
      window.ethereum?.removeListener?.('accountsChanged', handleChainChanged);
      connector.provider?.removeListener('chainChanged', handleChainChanged);
      connector.provider?.removeListener('accountsChanged', handleAccountsChange);
      connector.provider?.removeListener('disconnect', handleConnectWalletDisconnect);
    };
  }, [connector, connectWallet, walletType, disconnectWallet, handleConnectWalletDisconnect]);

  useMount(async () => {
    // there's no sync method to test if gnosis is installed, have to check by actually trying to activate
    try {
      await connectWallet(WalletTypeEnum.GNOSIS_SAFE, defaultChain);
    } catch {
      console.warn('eagerly connect to safe failed');
      try {
        if (memoizedWalletType !== null && values(WalletTypeEnum).includes(memoizedWalletType)) {
          await connectWallet(memoizedWalletType, defaultChain);
        } else {
          const firstValidWallet = ORDERED_WALLET_TO_INIT.filter(
            it => it !== WalletTypeEnum.GNOSIS_SAFE
          ).find(it => isWalletInstalled(it));

          await connectWallet(firstValidWallet ?? WalletTypeEnum.NETWORK, defaultChain);
        }
      } catch {
        await connectWallet(WalletTypeEnum.NETWORK).catch(null);
      }
    }
    setLoaded(true);
  });

  const submitTransaction = useCallback(
    async (tx: EthereumTransactionTypeExtended) => {
      if (isNil(provider)) {
        throw new Error('provider not ready');
      }
      const extendedTxData = await tx.tx();
      const { from, ...txData } = extendedTxData;
      const signer = provider.getSigner(from);
      const txResponse = await signer.sendTransaction({
        ...txData,
        value: txData.value ? BigNumber.from(txData.value) : undefined
      });

      return txResponse;
    },
    [provider]
  );

  const value: EOAContextValue = useMemo(() => {
    if (!provider) {
      return null;
    }

    // dangerous: mutable code to alter provider.estimateGas
    rewriteGasEstimation(provider);

    return {
      connectWallet,
      disconnectWallet,
      switchNetwork,
      walletType,
      walletInActivating,
      connector,
      provider,
      chainId: chainId!,
      account,
      active: isActive,
      isUsingUserWallet: walletType !== WalletTypeEnum.NETWORK,
      isSelectingUnsupportedNetwork: !chainId || !SUPPORTED_NETWORKS.includes(chainId),
      submitTransaction
    };
  }, [
    submitTransaction,
    connectWallet,
    disconnectWallet,
    switchNetwork,
    walletType,
    walletInActivating,
    connector,
    provider,
    chainId,
    account,
    isActive
  ]);

  if (!value) {
    return null;
  }
  if (!loaded) {
    return <PageConnectLoading />;
  }

  // TODO more intuitive flag to indicate network switching
  if (chainId === null) {
    if (!isActive) {
      return null;
    }
    return (
      <Inline inset="2rem" width="100%" justifyContent="center">
        <NetworkSwitchingProcess />
      </Inline>
    );
  }

  return <EOAContext.Provider value={value}>{children}</EOAContext.Provider>;
});

export const useEOAProvider = () => {
  const EOAContextValue = useContext(EOAContext);

  if (EOAContextValue === null) {
    throw new Error('web3Context not ready');
  }

  return EOAContextValue;
};
