import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation, useMount } from 'react-use';

import { useEOAProvider } from '../contexts';
import { getChainKey } from '../config';

export const LegacyAppsRouteSupport = memo(() => {
  const navigate = useNavigate();
  const { chainId } = useEOAProvider();
  const currentChainPath = getChainKey(chainId);

  const { pathname, search, hash } = useLocation();
  useMount(() => {
    navigate(
      {
        pathname: `/${currentChainPath}${pathname}`,
        hash,
        search
      },
      { replace: true }
    );
  });

  return null;
});
