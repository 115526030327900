import { values } from 'lodash';
import { useMemo } from 'react';

import { InBoxContract } from '../Contracts';
import { BridgeNetworks } from '../configs';

import { useEOAProvider } from '@/apps/parax/contexts';

export const useNativeCurrencyBridge = () => {
  const { provider, chainId } = useEOAProvider();

  const currentNetwork = useMemo(
    () => values(BridgeNetworks).find(it => (it.chainId as number) === chainId),
    [chainId]
  );

  return useMemo(() => {
    if (!currentNetwork) {
      return null;
    }
    return new InBoxContract(
      currentNetwork.nativeTokenBridge,
      provider.getSigner(),
      currentNetwork.nativeCurrency.decimals
    );
  }, [currentNetwork, provider]);
};
