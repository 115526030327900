import { useCallback, useMemo } from 'react';
import { ethers } from 'ethers';
import { BaseDebtToken } from 'paraspace-utilities-contract-helpers/dist/esm/baseDebtToken-contract';
import { ERC20Service } from 'paraspace-utilities-contract-helpers';

import submitTransaction from '../../utils/submitTransaction';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

const MAX_ALLOWANCE = ethers.constants.MaxUint256.toString();

const useDebtTokenBase = (debtTokenAddress: string) => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }

    const erc20Service = new ERC20Service(provider);
    return new BaseDebtToken(provider, erc20Service);
  }, [provider]);

  const approveDelegation = useCallback(async () => {
    if (!provider || !service) {
      return null;
    }

    const tx = await service.approveDelegation({
      user: account,
      delegatee: contracts.WETHGatewayProxy,
      debtTokenAddress,
      amount: MAX_ALLOWANCE
    });
    const txRes = submitTransaction({ provider, tx });
    return txRes;
  }, [provider, service, account, contracts.WETHGatewayProxy, debtTokenAddress]);

  const getDelegatedAllowance = useCallback(async () => {
    if (!service) {
      return '0';
    }

    const result = await service.getDelegatedAllowance({
      debtTokenAddress,
      allowanceGiver: account,
      allowanceReceiver: contracts.WETHGatewayProxy
    });
    return result.toString();
  }, [service, debtTokenAddress, account, contracts.WETHGatewayProxy]);

  return { approveDelegation, getDelegatedAllowance };
};

export default useDebtTokenBase;
