import { Card, CardProps, Stack, Inline, Spinner, useBreakpoints } from '@parallel-mono/components';
import { FC, memo, useState, useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { ApeListing, ApeCoinListing, Listing } from '../../types';

import { PillBar, BakcListings, ApeListings } from './components';

import { ERC721Symbol, ApeStakingTokenSymbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';

type SharePoolListingProps = Omit<CardProps, 'children'>;

export const SharePoolListing: FC<SharePoolListingProps> = memo(props => {
  const { basicInfoLoaded } = useMMProvider();
  const { mobile } = useBreakpoints();
  const { nftPoolsCompoundApy } = useAutoCompoundApeInfo();

  const [targetPool, setTargetPool] = useState<ApeStakingTokenSymbol>(ERC721Symbol.BAYC);
  const compoundApy = nftPoolsCompoundApy?.[targetPool] ?? BigNumber(0);

  const [selectedBaycPoolListing, setSelectedBaycPoolListing] = useState<
    ApeListing | ApeCoinListing | null
  >(null);
  const [selectedMaycPoolListing, setSelectedMaycPoolListing] = useState<
    ApeListing | ApeCoinListing | null
  >(null);
  const [selectedBakcPoolListings, setSelectedBakcPoolListings] = useState<Listing[]>([]);

  const ListingContentMap = useMemo(() => {
    return {
      [ERC721Symbol.BAKC]: (
        <BakcListings
          onSelectListingChange={setSelectedBakcPoolListings}
          defaultSelectedListings={selectedBakcPoolListings}
        />
      ),
      [ERC721Symbol.MAYC]: (
        <ApeListings
          targetPool={ERC721Symbol.MAYC}
          defaultSelectedListing={selectedMaycPoolListing}
          onSelectListingChange={setSelectedMaycPoolListing}
        />
      ),
      [ERC721Symbol.BAYC]: (
        <ApeListings
          targetPool={ERC721Symbol.BAYC}
          defaultSelectedListing={selectedBaycPoolListing}
          onSelectListingChange={setSelectedBaycPoolListing}
        />
      )
    };
  }, [selectedBakcPoolListings, selectedBaycPoolListing, selectedMaycPoolListing]);

  if (!basicInfoLoaded) {
    return (
      <Inline width="100%" justifyContent="center">
        <Spinner />
      </Inline>
    );
  }

  return (
    <Stack>
      <Card border {...props}>
        <Stack>
          <PillBar
            targetPool={targetPool}
            compoundApy={compoundApy}
            onHandleLabelSelect={val => {
              setTargetPool(val);
            }}
          />
          {!mobile && ListingContentMap[targetPool]}
        </Stack>
      </Card>
      {mobile && <Stack gap="2rem">{ListingContentMap[targetPool]}</Stack>}
    </Stack>
  );
});
