import { Pool } from 'paraspace-utilities-contract-helpers';
import { useCallback, useMemo } from 'react';
import { OrderWithCounter } from 'paraspace-seaport-js/lib/types';
import { BigNumberish, BytesLike, utils } from 'ethers';
import { LPAcceptBidWithCredit } from 'paraspace-utilities-contract-helpers/dist/esm/pool-contract/lendingPoolTypes';
import { ParaXProvider } from 'parax-sdk';

import { ContractMap } from '../../config';

import { Order, OrderTarget } from '@/apps/paraspace/generated/graphql';

export const useAcceptBidWithCredit = (
  provider: ParaXProvider,
  account: string,
  contracts: ContractMap
) => {
  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new Pool(provider, {
      POOL: contracts.PoolProxy,
      WETH_GATEWAY: contracts.WETHGatewayProxy
    });
  }, [contracts.PoolProxy, contracts.WETHGatewayProxy, provider]);

  const genAcceptBidWithCredit = useCallback(
    async (
      orderData: Order,
      orderParameter: OrderWithCounter,
      identifierOrCriteria: string = ''
    ) => {
      if (!service) {
        return null;
      }

      const { protocolData, creditData } = orderData;
      const payLater = {
        token: creditData?.token,
        amount: creditData?.amount as BigNumberish,
        orderId: utils.arrayify(creditData?.orderId ?? ''),
        v: Number(creditData?.vrs?.v),
        r: creditData?.vrs?.r as BytesLike,
        s: creditData?.vrs?.s as BytesLike
      };

      return (
        await service.acceptBidWithCredit({
          marketProtocolAddr: contracts.Seaport,
          orderProtocolData: {
            ...protocolData,
            numerator: 1,
            denominator: 1,
            extraData: '0x'
          } as LPAcceptBidWithCredit['orderProtocolData'],
          onBehalfOf: account,
          acceptProtocolData: { ...orderParameter, numerator: 1, denominator: 1, extraData: '0x' },
          creditData: payLater,
          isCollectionBid: orderData.target === OrderTarget.Collection,
          nftId: identifierOrCriteria
        })
      )[0];
    },
    [account, contracts.Seaport, service]
  );

  return {
    genAcceptBidWithCredit
  };
};
