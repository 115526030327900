import { SelectableTokenInput as RawSelectableTokenInput } from '@parallel-mono/business-components';
import styled from 'styled-components';

export const SelectableTokenInput = styled(RawSelectableTokenInput)`
  width: inherit;
  background: ${props => (props.inputProps?.disabled ? props.theme.skin.grey[200] : 'inherit')};

  .token-select-menu {
    left: auto;

    > div {
      max-height: 12.5rem;
    }
  }
`;
