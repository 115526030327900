import styled from 'styled-components';
import { Stack, Inline, Image } from '@parallel-mono/components';
import { useParams } from 'react-router-dom';
import { memo } from 'react';
import { Helmet } from 'react-helmet';

import { BuyListing } from '../../types';

import {
  Container,
  AboutSection,
  CollectionSection,
  UniswapOperationCard,
  UnclaimedFeesSection,
  PriceRangeSection
} from './component';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useUniSwapV3Manager } from '@/apps/paraspace/pages/contexts/UniSwapV3ManagerProvider/useUniSwapV3Manager';
import { useConvertSymbolWithNetwork } from '@/apps/paraspace/pages/hooks';

const StyledImage = styled(Image)`
  padding: 0 21%;
  border-radius: 0.75rem;
  filter: drop-shadow(${({ theme }) => theme.shadows.secondary});
  background-color: #fff;
`;

const mock = {
  listings: [] as BuyListing[],
  standard: 'ERC721',
  name: 'Uniswap V3',
  externalUrl: null
};

const UniswapDetail = () => {
  const { contractAddr, tokenId } = useParams();
  const {
    nftInfoMap,
    overviewUserInfo: { liquidationThresholdInUsd, borrowLimitInUsd, totalBorrowedPositionInUsd }
  } = useMMProvider();
  const { tokenSpecificInfos = {} } = nftInfoMap[ERC721Symbol.UNISWAP_LP] || {};
  const { imageMap } = useUniSwapV3Manager();
  const uniswapInfo = tokenSpecificInfos[tokenId!];
  const {
    token0Symbol,
    token1Symbol,
    liquidityToken0Amount,
    liquidityToken1Amount,
    lpFeeToken0Amount,
    lpFeeToken1Amount,
    isInRange,
    isClosed,
    token0ConvertToken1CurrentPrice,
    token0ConvertToken1MinPrice,
    token0ConvertToken1MaxPrice,
    token1ConvertToken0CurrentPrice,
    token1ConvertToken0MaxPrice,
    token1ConvertToken0MinPrice,
    priceInUsd
  } = uniswapInfo || {};

  const { convertWTokenToNativeToken } = useConvertSymbolWithNetwork();

  const convertToken0Symbol = convertWTokenToNativeToken(token0Symbol);
  const convertToken1Symbol = convertWTokenToNativeToken(token1Symbol);

  const healthFactor = liquidationThresholdInUsd.div(totalBorrowedPositionInUsd).toNumber();
  const threshold = liquidationThresholdInUsd.div(borrowLimitInUsd).toNumber();
  const { name, image, description } = imageMap?.[tokenId!] || {};

  return (
    <Container loading={!nftInfoMap[ERC721Symbol.UNISWAP_LP]}>
      <Helmet>
        <meta property="twitter:title" content={`ParaSpace - ${name} - #${tokenId}`} />
        <meta property="twitter:image" content={image} />
      </Helmet>
      <Inline grow>
        <Stack>
          {uniswapInfo && <StyledImage src={image} />}
          <AboutSection
            data={{ ...mock, contractAddress: contractAddr!, tokenId: tokenId!, isUniswap: true }}
          />
          <CollectionSection data={{ ...mock, description: description! }} />
        </Stack>
        <Stack>
          {uniswapInfo && (
            <>
              <UniswapOperationCard
                name={name!}
                tokenId={tokenId!}
                token0Symbol={convertToken0Symbol}
                token1Symbol={convertToken1Symbol}
                liquidityToken0Amount={liquidityToken0Amount}
                liquidityToken1Amount={liquidityToken1Amount}
                token1ConvertToken0CurrentPrice={token1ConvertToken0CurrentPrice}
                healthFactor={healthFactor}
                threshold={threshold}
                isClosed={isClosed}
                priceInUsd={priceInUsd}
              />
              <UnclaimedFeesSection
                tokenId={tokenId!}
                token0Symbol={convertToken0Symbol}
                token1Symbol={convertToken1Symbol}
                token0Fee={lpFeeToken0Amount}
                token1Fee={lpFeeToken1Amount}
              />
              <PriceRangeSection
                isInRange={isInRange}
                isClosed={isClosed}
                token0Symbol={convertToken0Symbol}
                token1Symbol={convertToken1Symbol}
                token0ConvertToken1CurrentPrice={token0ConvertToken1CurrentPrice}
                token0ConvertToken1MaxPrice={token0ConvertToken1MaxPrice}
                token0ConvertToken1MinPrice={token0ConvertToken1MinPrice}
                token1ConvertToken0CurrentPrice={token1ConvertToken0CurrentPrice}
                token1ConvertToken0MaxPrice={token1ConvertToken0MaxPrice}
                token1ConvertToken0MinPrice={token1ConvertToken0MinPrice}
              />
            </>
          )}
        </Stack>
      </Inline>
    </Container>
  );
};

export default memo(UniswapDetail);
