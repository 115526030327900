import { memo, useCallback } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';
import ReactGA from 'react-ga4';

import { ApeCoinListing, ApeListing } from '../../../types';
import { JoinListingSuccessState } from '../components';

import { JoinListingSubmitter } from './JoinListingWithApeFormSubmitter';
import { JoinListingWithApeForm } from './JoinListingWithApeForm';
import { JoinListingWithApeErrorState } from './JoinListingWithApeErrorState';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ApeStakingMainTokenSymbol, WalletType } from '@/apps/paraspace/typings';
import {
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';

export type JoinListingWithApeModalProps = Omit<ModalProps, 'children'> & {
  apeCoinListing: ApeCoinListing;
  walletType: WalletType;
  onFinish?: () => void;
  onError?: () => void;
};

export const JoinListingWithApeModal = memo(
  ({
    apeCoinListing,
    walletType,
    onFinish,
    isOpen,
    onError,
    onClose,
    ...others
  }: JoinListingWithApeModalProps) => {
    const finalHandleFinish = useCallback(() => {
      ReactGA.event({
        action: 'apestaking',
        category: 'p2pstaking',
        label: 'join'
      });
      onFinish?.();
    }, [onFinish]);

    const {
      submittedFormData: selectedToken,
      submittingResult: createdApeListing,
      submittingError,
      phase,
      handleSubmitSuccess,
      handleSubmitFailed,
      handleFormSubmit
    } = useCollectAndSubmitProcedure<
      { symbol: ApeStakingMainTokenSymbol; tokenId: number },
      ApeListing
    >({
      onError,
      onFinish: finalHandleFinish,
      running: isOpen
    });

    const { account } = useWeb3Context();

    return (
      <Modal
        closeOnBackdropClick={false}
        isOpen={isOpen}
        title="Join Staking"
        onClose={onClose}
        {...others}
      >
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <JoinListingWithApeForm
            apeCoinListing={apeCoinListing}
            walletType={walletType}
            onSubmit={handleFormSubmit}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <JoinListingSubmitter
            formData={{
              apeCoinListing,
              token: selectedToken!
            }}
            walletType={walletType}
            onFinish={handleSubmitSuccess}
            onError={handleSubmitFailed}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && (
          <JoinListingWithApeErrorState
            account={account}
            apeCoinListing={apeCoinListing}
            apeListing={createdApeListing}
            onFinish={onClose!}
            errConfig={submittingError}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <JoinListingSuccessState
            apeCoinListing={apeCoinListing}
            apeListing={createdApeListing}
            bakcListing={null}
            onFinish={onClose}
          />
        )}
      </Modal>
    );
  }
);
