import { Container } from '@parallel-mono/components';
import { HTMLAttributes, RefObject, createContext, memo, useContext, useRef } from 'react';
import styled from 'styled-components';

import mobileBg from './images/mobile-bg.svg';
import bg from './images/bg.svg';

type ScaffoldContextValue = RefObject<HTMLElement>;

const ScaffoldContext = createContext<ScaffoldContextValue>({ current: null });

const ParaXContainer = styled(Container)`
  overflow: auto;
  height: 100%;
  background: ${({ theme }) => theme.skin.background.sub1};
  background-color: transparent;

  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: -1;
    margin: auto;
    background: url(${bg});
    ${({ theme }) => theme.breakpoints.only('mobile')`
      background-image: url(${mobileBg});
    `};
    background-size: cover;
  }
`;

type ScaffoldProps = HTMLAttributes<HTMLDivElement>;

export const Scaffold = memo(({ children, ...others }: ScaffoldProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <ParaXContainer ref={containerRef} {...others}>
      <ScaffoldContext.Provider value={containerRef}>{children}</ScaffoldContext.Provider>
    </ParaXContainer>
  );
});

export const useScaffoldContainerRef = () => useContext(ScaffoldContext);
