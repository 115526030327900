import { Card, H3, H6, Inline, Stack, SmallText } from '@parallel-mono/components';
import { FC, isValidElement, memo, ReactElement } from 'react';
import styled, { keyframes } from 'styled-components';

import { Tooltip } from '@/apps/paraspace/components';

const BlinkAnimation = keyframes`
  0% { outline: 2px solid rgba(255, 165, 0, 1) }
  100% { outline: 2px solid rgba(255, 165, 0, 0) }
`;

export const PanelWrapper = styled(Inline).attrs({
  inset: '1.5rem',
  as: Card
})<{ highLight?: boolean }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.skin.grey[200]};
  ${({ theme }) => theme.breakpoints.down('desktop')`
    min-width: 18rem;
  `};
  ${({ theme }) => theme.breakpoints.only('tablet')`
    box-sizing: border-box;
    padding: 1rem;
  `};
  animation: ${BlinkAnimation};
  animation-duration: 0.8s;
  animation-iteration-count: 3;
  ${({ highLight }) => (highLight ? '' : 'animation: unset;')}
`;

const ApyContainer = styled(Stack)`
  width: 100%;
  padding: 0.75rem 0;
  border: 1px solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 0.5rem;
`;

type Props = {
  title: string | ReactElement;
  coreContent?: ReactElement;
  items?: { field: ReactElement | string; value: ReactElement | string; key: string }[];
  actionButton?: ReactElement;
  descInfo?: ReactElement;
  highLight?: boolean;
};

const MainWidget: FC<{ value: string; description: string; tooltip: string }> = ({
  value,
  description,
  tooltip,
  ...props
}) => (
  <ApyContainer gap="0" justifyContent="center" alignItems="center" {...props}>
    <H3 skin="success">{value}</H3>
    <Inline gap="0.25rem">
      <SmallText skin="secondary">{description}</SmallText>
      <Tooltip placement="bottom" content={tooltip} />
    </Inline>
  </ApyContainer>
);

const PanelCard: FC<Props> = memo(
  ({ items, actionButton, title, coreContent, descInfo, ...props }) => {
    return (
      <PanelWrapper {...props}>
        <Stack gap="1rem" width="100%">
          {isValidElement(title) ? title : <H6>{title}</H6>}
          {isValidElement(coreContent) && coreContent}
          {items && (
            <Stack gap="0.5rem">
              {items.map(item => {
                const { field, value, key } = item;
                return (
                  <Inline justifyContent="space-between" key={key}>
                    {isValidElement(field) ? (
                      field
                    ) : (
                      <SmallText skin="secondary">{field}</SmallText>
                    )}
                    {isValidElement(value) ? (
                      value
                    ) : (
                      <SmallText fontWeight="bold">{value}</SmallText>
                    )}
                  </Inline>
                );
              })}
            </Stack>
          )}
          {actionButton && isValidElement(actionButton) && actionButton}
          {descInfo && descInfo}
        </Stack>
      </PanelWrapper>
    );
  }
);

export default Object.assign(PanelCard, { MainWidget });
