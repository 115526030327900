import { InfoPanel } from '@parallel-mono/business-components';
import { FC, memo, useMemo, useState } from 'react';
import { H6, Icon, Inline, SmallText } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import { ChainId } from '@parallel-utils/contracts-registry';

import { StyledTag } from '../StyledComponents';
import { AddressSelectModal } from '../AddressSelectModal';
import { BridgeNetworks, SupportedChainId } from '../../configs';

import { formatBalance, truncateTextMid } from '@/apps/parax/utils';
import { Maybe } from '@/apps/parax/typings/basic';

const getNetworkTxTime = (chainId: SupportedChainId) => {
  if ([ChainId.Sepolia, ChainId.Ethereum].includes(chainId)) {
    return '3~5 mins';
  }
  if ([ChainId.Parallel, ChainId.ParallelTestnet].includes(chainId)) {
    return '3~5 mins';
  }
  if ([ChainId.Manta].includes(chainId)) {
    return '1~2 min';
  }
  if ([ChainId.ArbitrumOne, ChainId.ArbitrumSepolia].includes(chainId)) {
    return '1~2 min';
  }
  return '-';
};

export const InfosPanel: FC<{
  network: number;
  networkFee: Maybe<BigNumber>;
  address: string;
  amount: number;
  selectedToken: Maybe<{
    name: string;
    symbol: string;
    balance: number | null;
  }>;
  onAddressChange: (address: string) => void;
}> = memo(({ address, amount, selectedToken, networkFee, onAddressChange, network }) => {
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const infos = useMemo(
    () => [
      {
        field: 'To address',
        value: (
          <StyledTag
            onClick={() => {
              setIsAddressModalOpen(true);
            }}
            size="small"
            skin="success"
          >
            <Inline gap="0.25rem" alignItems="center">
              <H6>{truncateTextMid(address, 6)}</H6>
              <Icon size="1rem" name="checkContained" />
            </Inline>
          </StyledTag>
        )
      },
      {
        field: 'You will receive',
        value: (
          <Inline gap="0.5rem">
            {selectedToken ? (
              <H6>{`${amount ?? 0} ${selectedToken.symbol}`}</H6>
            ) : (
              <SmallText>-</SmallText>
            )}
          </Inline>
        )
      },
      {
        field: 'Transfer time',
        value: (
          <Inline gap="0.25rem">
            <H6>{getNetworkTxTime(network)}</H6>
          </Inline>
        )
      },
      {
        field: 'Network fees',
        value: (
          <H6>
            {networkFee ? formatBalance(networkFee) : '-'}{' '}
            {BridgeNetworks[network as SupportedChainId].nativeCurrency.symbol}
          </H6>
        )
      }
    ],
    [address, amount, network, networkFee, selectedToken]
  );

  return (
    <>
      <AddressSelectModal
        title="Deposit destination"
        isOpen={isAddressModalOpen}
        onClose={() => setIsAddressModalOpen(false)}
        onAddressSave={onAddressChange}
      />
      <InfoPanel
        infos={infos.map(({ field, value }) => ({
          title: (
            <SmallText skin="secondary" fontWeight="bold">
              {field}
            </SmallText>
          ),
          value
        }))}
      />
    </>
  );
});
