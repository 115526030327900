import { Card, H6, Inline, SmallText, Stack, Text } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import numbro from 'numbro';
import styled from 'styled-components';

import { legendFormatter, LegendName } from './utils';

import { Maybe } from '@/apps/paraspace/typings/basic';

type TooltipContentRenderParams = {
  payload: Maybe<
    {
      name: string;
      stroke: string;
      value: number;
    }[]
  >;
  label: number;
};

const Circle = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
`;

export const renderTooltipContent = ({ payload, label }: TooltipContentRenderParams) => {
  const weekLabel = label === 0 ? 'Today' : `${numbro(label).format({ output: 'ordinal' })} week`;
  return (
    <Card inset="1.5rem" shadow="secondary" border>
      <Stack gap="1.625rem">
        <Inline>
          <H6>Projected Profits</H6>
          <SmallText>{weekLabel}</SmallText>
        </Inline>
        <Stack gap="1rem">
          {payload?.map(item => (
            <Inline key={item.name} justifyContent="space-between">
              <Inline gap="0.25rem" alignItems="center">
                <Circle color={item.stroke} />
                <Text>{legendFormatter(item.name as LegendName)}</Text>
              </Inline>
              <Inline gap="0.25rem">
                <SmallText skin="primary" fontWeight="bold">
                  {formatNumber(item.value, { decimal: 0 })}
                </SmallText>
                <SmallText skin="secondary">APE</SmallText>
              </Inline>
            </Inline>
          )) ?? null}
        </Stack>
      </Stack>
    </Card>
  );
};
