import { memo, ReactNode, useMemo } from 'react';
import {
  Inline,
  Select as MonoSelect,
  SelectBaseOption as MonoSelectBaseOption,
  SelectProps as MonoSelectProps,
  Text
} from '@parallel-mono/components';
import styled from 'styled-components';
import { merge } from 'lodash';

export type SelectBaseOption = MonoSelectBaseOption & {
  icon?: ReactNode;
};

export type SelectProps<P extends SelectBaseOption = SelectBaseOption> = MonoSelectProps<P> & {
  menuAlign?: 'left' | 'right';
  height?: string;
};

const selectClassNames = {
  menu: 'paraspace-select-menu'
};

const StyledSelect = styled(MonoSelect).attrs(({ classNames }) => ({
  classNames: merge(selectClassNames, classNames)
}))<{ menuAlign: 'left' | 'right'; height?: string }>`
  border-radius: 10000px;
  width: fit-content;
  height: ${({ height }) => height || 'auto'};
  padding: ${({ theme }) =>
    `${theme.spacing(0.5)} ${theme.spacing(1.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)}`};

  .${selectClassNames.menu} {
    background-color: ${({ theme }) => theme.skin.background.main};
    color: ${({ theme }) => theme.skin.text.main};
    width: fit-content;
    ${({ menuAlign = 'right' }) =>
      menuAlign === 'right' ? 'right: 0; left: auto;' : 'right: auto; left: 0;'};
  }
` as typeof MonoSelect;

const IconContent = styled.div`
  line-height: 0;
`;

const LabelContent = styled(Text)`
  flex: 1;
`;

export const SelectBase = <P extends SelectBaseOption = SelectBaseOption>({
  options,
  ...others
}: SelectProps<P>) => {
  const forgedOptions = useMemo(
    () =>
      options.map(opt => ({
        ...opt,
        label: (
          <Inline gap="0.675rem" alignItems="center" justifyContent="space-between">
            <IconContent>{opt.icon ?? null}</IconContent>
            <LabelContent forwardedAs="div" fontWeight="medium">
              {opt.label}
            </LabelContent>
          </Inline>
        )
      })),
    [options]
  );

  return <StyledSelect clearable={false} options={forgedOptions} {...others} />;
};

export const Select = memo(SelectBase) as typeof SelectBase;

export const NonBorderSelect = styled(Select)`
  padding: 0;
  border: none;
  :hover {
    border: none;
  }
  &:focus-within {
    border: none;
  }
`;
