import { useCallback } from 'react';
import { NTokenService } from 'paraspace-utilities-contract-helpers';

import { useWeb3Context } from '@/apps/paraspace/contexts';

export const useNTokenService = () => {
  const { provider, account } = useWeb3Context();

  const delegateTokens = useCallback(
    ({
      tokenIds,
      delegate,
      enable,
      nTokenAddress
    }: {
      tokenIds: number[];
      nTokenAddress: string;
      delegate: string;
      enable: boolean;
    }) => {
      if (provider === null) {
        throw new Error('provider not ready yet');
      }
      const service = new NTokenService(provider, nTokenAddress);
      return service.delegateForToken({
        user: account,
        delegate,
        enable,
        tokenIds: tokenIds.map(id => id.toString())
      });
    },
    [provider, account]
  );

  const initiateDelegateForTokens = useCallback(
    (tokenIds: number[], delegate: string, nTokenAddress: string) =>
      delegateTokens({
        tokenIds,
        delegate,
        nTokenAddress,
        enable: true
      }),
    [delegateTokens]
  );

  const revokeDelegateForTokens = useCallback(
    (tokenIds: number[], delegate: string, nTokenAddress: string) =>
      delegateTokens({
        tokenIds,
        delegate,
        nTokenAddress,
        enable: false
      }),
    [delegateTokens]
  );

  return {
    initiateDelegateForTokens,
    revokeDelegateForTokens,
    delegateTokens
  };
};
