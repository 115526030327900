import {
  Button,
  H5,
  H6,
  Inline,
  Skeleton,
  SmallText,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import { memo, useCallback, useMemo } from 'react';
import { CryptoIcon } from '@parallel-mono/business-components';

import { BorrowPositionRow } from '..';
import { RepayDropdownMenu } from '../../../components';
import { getEffectiveApy } from '../../utils';

import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { getEffectiveBorrowApyTip } from '@/apps/paraspace/pages/Credit/MoneyMarket/utils/getEffectiveApyTip';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { zero } from '@/apps/paraspace/consts/values';

type ERC20CardProps = Omit<StackProps, 'children'> & {
  data: BorrowPositionRow;
  onBorrow: (row: BorrowPositionRow) => void;
};

export const ERC20Card = memo((props: ERC20CardProps) => {
  const {
    data: { symbol, displayName, borrowApyRate, borrowedAmount, borrowedAmountInUsd },
    data,
    onBorrow,
    ...others
  } = props;
  const { effectiveCapeBorrowApy } = useAutoCompoundApeInfo();

  const handleBorrow = useCallback(() => {
    onBorrow(data);
  }, [data, onBorrow]);

  const { erc20Config, nativeTokenAndDerivatives } = useAppConfig();

  const isNativeTokenOrDerivatives = useMemo(
    () => nativeTokenAndDerivatives.includes(symbol),
    [nativeTokenAndDerivatives, symbol]
  );

  const effectiveBorrowApy = useMemo(
    () =>
      getEffectiveApy({
        baseApy: borrowApyRate,
        effectiveCapeApy: effectiveCapeBorrowApy,
        symbol,
        apy: erc20Config[symbol]?.apy,
        isNativeTokenOrDerivatives
      }),
    [borrowApyRate, effectiveCapeBorrowApy, symbol, erc20Config, isNativeTokenOrDerivatives]
  );

  const effectiveBorrowApyTooltip = useMemo(
    () =>
      getEffectiveBorrowApyTip({
        symbol,
        baseApy: borrowApyRate,
        tooltipPlacement: 'left',
        apy: erc20Config[symbol]?.apy ?? null,
        isNativeTokenOrDerivatives
      }),
    [symbol, borrowApyRate, erc20Config, isNativeTokenOrDerivatives]
  );

  return (
    <Stack gap="1rem" {...others}>
      <Inline gap="0.5rem" alignItems="center">
        <CryptoIcon symbol={symbol} />
        <H5>{displayName}</H5>
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Borrowed Amount
        </H6>
        <Stack gap="0" alignItems="flex-end">
          <Text>{formatBalance(borrowedAmount)}</Text>
          {borrowedAmountInUsd.gt(zero) ? (
            <SmallText skin="secondary">
              {formatToCurrency(borrowedAmountInUsd.toNumber())}
            </SmallText>
          ) : (
            <Skeleton.Button height="1rem" width="2rem" />
          )}
        </Stack>
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          APY
        </H6>
        <Stack gap="0" alignItems="flex-end">
          <Inline alignItems="center" gap="0.25rem">
            <Text>{effectiveBorrowApy}</Text>
            {effectiveBorrowApyTooltip}
          </Inline>
        </Stack>
      </Inline>
      <Inline justifyContent="space-between">
        <Button skin="secondary" block onClick={handleBorrow} disabled={data.utilization.gt(0.8)}>
          Borrow
        </Button>
        <RepayDropdownMenu data={data}>
          <Button skin="secondary" block>
            Repay
          </Button>
        </RepayDropdownMenu>
      </Inline>
    </Stack>
  );
});
