import { memo } from 'react';
import { map } from 'lodash';

import { InitiateNftDelegationModalFormTokensMap } from '../contexts/DelegateNftModal';

import { NFTDelegationSubmitter } from './NFTDelegationSubmitter';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { StepperProps } from '@/apps/paraspace/components';

type FormData = {
  tokensMap: InitiateNftDelegationModalFormTokensMap;
  delegate: string;
};

type NFTDelegationSubmitterProps = Omit<StepperProps, 'step' | 'steps' | 'onError'> & {
  formData: FormData;
  onFinish: () => void;
  onError: () => void;
};

export const InitiateNFTDelegationSubmitter = memo(
  ({ formData: { tokensMap, delegate }, onError, onFinish }: NFTDelegationSubmitterProps) => {
    const delegationTokens = map(tokensMap, (each, symbol) => ({
      symbol: symbol as ERC721Symbol,
      tokenIds: each?.tokenIds || [],
      collectionName: each?.collectionName || symbol
    })).filter(it => (it.tokenIds?.length ?? 0) > 0);

    return (
      <NFTDelegationSubmitter
        formData={{
          enableDelegation: true,
          tokens: delegationTokens,
          delegate
        }}
        onError={onError}
        onFinish={onFinish}
      />
    );
  }
);
