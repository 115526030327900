import { memo, useCallback, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import {
  Button,
  H5,
  Inline,
  SmallText,
  Spinner,
  Stack,
  StackProps,
  Tag,
  Image,
  Alert
} from '@parallel-mono/components';
import { formatNumber, sum } from '@parallel-mono/utils';
import { groupBy, keys } from 'lodash';

import { BendDaoNft } from '../types';

import { useBendDaoNfts } from './useBendDaoNfts';
import { Infos } from './Infos';
import { usePredictedBorrowPosition } from './usePredictedBorrowPosition';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { NFTThumbnailCheck, NoNFTs, BorrowPositionCard } from '@/apps/paraspace/components';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { zero } from '@/apps/paraspace/consts/values';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { Maybe } from '@/apps/paraspace/typings/basic';
import heartHalfContained from '@/apps/paraspace/assets/heart-half-contained.svg';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useConvertSymbolWithNetwork } from '@/apps/paraspace/pages/hooks';

type SupplyNftFromBendDAOFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  symbol: ERC721Symbol;
  collectionName: string;
  onSubmit?: (formData: {
    tokens: { tokenId: number; loanId: Maybe<string> }[];
    symbol: ERC721Symbol;
    increasedBorrowLimit: BigNumber;
  }) => void;
};

const DebtPill = styled(Inline)`
  border: ${({ theme }) => theme.border.width.medium} solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 10000rem;
  padding: 0.25rem 0.75rem;
`;

const TokensContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  max-height: 18.75rem;
  overflow: auto;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    grid-template-columns: repeat(2, 1fr);
  `};
`;

export const SupplyNftFromBendDAOForm = memo(
  ({ symbol, onSubmit, collectionName, ...others }: SupplyNftFromBendDAOFormProps) => {
    const { loading, bendDaoNfts } = useBendDaoNfts(symbol);

    const [selectedTokens, setSelectedToken] = useState<BendDaoNft[]>([]);
    const { convertWTokenToNativeToken } = useConvertSymbolWithNetwork();

    const {
      overviewUserInfo: { borrowLimitInUsd },
      erc20InfoMap
    } = useMMProvider();
    const {
      borrowLimitInUsd: predictedBorrowLimitInUsd,
      totalBorrowedPositionInUsd,
      liquidationThresholdInUsd,
      totalCollateralPositionInUsd
    } = usePredictedBorrowPosition(selectedTokens, symbol);

    const increasedBorrowLimit = predictedBorrowLimitInUsd?.minus(borrowLimitInUsd ?? 0) ?? zero;

    const borrowLimitExceeded = useMemo(
      () => totalBorrowedPositionInUsd.gt(predictedBorrowLimitInUsd),
      [predictedBorrowLimitInUsd, totalBorrowedPositionInUsd]
    );

    const enoughLiquidity = useMemo(() => {
      const debtSymbolMap = groupBy(selectedTokens, ({ debtSymbol }) =>
        convertWTokenToNativeToken(debtSymbol)
      );
      return keys(debtSymbolMap).every(debtSymbol => {
        const availableLiquidity = erc20InfoMap?.[debtSymbol]?.availableLiquidity;

        if (!availableLiquidity) return false;
        return availableLiquidity.gte(sum(debtSymbolMap[debtSymbol].map(({ debt }) => debt)));
      });
    }, [selectedTokens, erc20InfoMap, convertWTokenToNativeToken]);

    const supplyButtonDisabled = useMemo(
      () => !enoughLiquidity || borrowLimitExceeded || selectedTokens.length === 0,
      [enoughLiquidity, borrowLimitExceeded, selectedTokens.length]
    );

    const alertMessage = useMemo(() => {
      if (borrowLimitExceeded) {
        return 'Borrow limit exceed. Please repay before proceeding.';
      }
      if (!enoughLiquidity) {
        return 'Insufficient liquidity in the Pool. Please try again later.';
      }
      return null;
    }, [enoughLiquidity, borrowLimitExceeded]);

    const selectableTokens = useMemo(
      () => bendDaoNfts.filter(item => !item.disabled),
      [bendDaoNfts]
    );

    const handleSelectAllClick = useCallback(() => {
      setSelectedToken(items => (items.length === selectableTokens.length ? [] : selectableTokens));
    }, [selectableTokens]);

    const handleSubmit = useCallback(() => {
      onSubmit?.({
        tokens: selectedTokens.map(it => ({ tokenId: it.tokenId, loanId: it.loanId })),
        symbol,
        increasedBorrowLimit
      });
    }, [onSubmit, increasedBorrowLimit, symbol, selectedTokens]);

    if (!loading && bendDaoNfts.length === 0) {
      return (
        <Stack {...others}>
          <NoNFTs description={`There’s no ${collectionName} NFT in this wallet.`} />
        </Stack>
      );
    }
    return (
      <Stack {...others}>
        <Inline gap="0.5rem" alignItems="flex-start">
          <Image src={heartHalfContained} />
          <H5>
            NFTs from other protocols will be unstaked, and any outstanding debt associated with
            them will be transferred to ParaSpace.
          </H5>
        </Inline>
        {loading && (
          <Inline justifyContent="center">
            <Spinner size="medium" />
          </Inline>
        )}
        {!loading && (
          <TokensContainer>
            {bendDaoNfts.map(nft => {
              const { tokenId, multiplier, debt, debtSymbol, disabled } = nft;
              return (
                <NFTThumbnailCheck
                  key={tokenId}
                  symbol={symbol}
                  tokenId={tokenId}
                  platform="benddao"
                  disabledTip="Cannot supply this NFT because the debt associated with it exceed the loan to value on ParaSpace."
                  disabled={disabled}
                  checked={selectedTokens.some(it => it.tokenId === tokenId)}
                  floatingTag={
                    multiplier.gt(DEFAULT_MULTIPLIER) ? (
                      <Tag skin="success" size="small">
                        {formatNumber(multiplier)}x Boost
                      </Tag>
                    ) : null
                  }
                  onChange={() => {
                    setSelectedToken(curr =>
                      selectedTokens.some(it => it.tokenId === tokenId)
                        ? curr.filter(it => it.tokenId !== tokenId)
                        : curr.concat([nft])
                    );
                  }}
                >
                  <Stack gap="0.5rem" width="100%" alignItems="center">
                    <SmallText>#{tokenId}</SmallText>
                    <DebtPill gap="0.25rem">
                      <SmallText as="span">Debt</SmallText>
                      <SmallText as="span" fontWeight="bold">
                        {formatBalance(debt)} {convertWTokenToNativeToken(debtSymbol)}
                      </SmallText>
                    </DebtPill>
                  </Stack>
                </NFTThumbnailCheck>
              );
            })}
          </TokensContainer>
        )}

        <BorrowPositionCard
          borrowedValueInUsd={totalBorrowedPositionInUsd}
          borrowLimitValueInUsd={predictedBorrowLimitInUsd}
          liquidationPointValueInUsd={liquidationThresholdInUsd}
          totalSuppliedValueInUsd={totalCollateralPositionInUsd}
        />

        <Infos symbol={symbol} tokensToTransfer={selectedTokens} />

        <Alert width="100%" type="warning">
          Your NFT will be supplied to the current Parallel Account by default
        </Alert>
        {alertMessage && (
          <Alert width="100%" type="error">
            {alertMessage}
          </Alert>
        )}

        <Inline>
          <Button
            skin="secondary"
            size="large"
            onClick={handleSelectAllClick}
            disabled={selectableTokens.length === 0}
          >
            {selectableTokens.length && selectedTokens.length === selectableTokens.length
              ? 'Unselect All'
              : 'Select All'}
          </Button>
          <Button size="large" block disabled={supplyButtonDisabled} onClick={handleSubmit}>
            {`Supply ${selectedTokens.length || ''} ${selectedTokens.length > 1 ? 'NFTs' : 'NFT'}`}
          </Button>
        </Inline>
      </Stack>
    );
  }
);
