import { Button, Card, H4, Stack, Text, Icon } from '@parallel-mono/components';
import styled from 'styled-components';
import { FC } from 'react';

const CentralText = styled(Text)`
  text-align: center;
`;

type Props = {
  upgradeUserProxy: () => void;
  contractEnabling: boolean;
};

export const SignProxy: FC<Props> = ({ upgradeUserProxy, contractEnabling }) => {
  return (
    <Card border>
      <Stack alignItems="center">
        <Stack alignItems="center" gap="0.5rem">
          <Icon name="penTool" size="6rem" />
          <Stack gap="0.25rem" alignItems="center">
            <H4>Create a Proxy to Mint Sewer Pass</H4>
            <CentralText skin="secondary">
              A one time setup of a Proxy is required to use Flash Claim for minting.
            </CentralText>
          </Stack>
        </Stack>
        <Button
          block
          size="large"
          onClick={upgradeUserProxy}
          loading={contractEnabling}
          disabled={contractEnabling}
        >
          Upgrade Receiver Contract
        </Button>
      </Stack>
    </Card>
  );
};
