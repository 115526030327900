import { FC, useCallback, useMemo, useState } from 'react';
import { OrderWithCounter } from 'paraspace-seaport-js/lib/types';

import { useApproveSeaportConduit } from '../hooks';
import { CheckBidderAllowanceSubmitter, SignSeaportOfferSubmitter } from '../submitters';
import { FulfillOrderSubmitter } from '../submitters/FulfillOfferSubmitter';
import { useCreateSeaportAcceptOrderInput } from '../hooks/useComposeCreateAcceptOrderInput';

import { Stepper } from '@/apps/paraspace/components';
import { useContractsMap, useERC721 } from '@/apps/paraspace/hooks';
import { Order } from '@/apps/paraspace/generated/graphql';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useIsTokenSupplierCheck } from '@/apps/paraspace/pages/hooks/useIsTokenSupplierCheck';
import { useCollectionByContract } from '@/apps/paraspace/pages/Shop/contexts';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useAcceptBidWithCredit } from '@/apps/paraspace/pages/hooks';

export type AcceptCommonOfferProps = {
  formData: {
    order: Order;
    tokenId: number;
    contractAddress: string;
  };
  onError: () => void;
  onSuccess: () => void;
};

export const AcceptCommonOfferSteppers: FC<AcceptCommonOfferProps> = ({
  onError,
  onSuccess,
  formData: { order, contractAddress, tokenId }
}) => {
  const [step, setStep] = useState(0);
  const { fees, symbol } = useCollectionByContract(contractAddress) || {};
  const { provider, account } = useWeb3Context();
  const { nftInfoMap } = useMMProvider();
  const contracts = useContractsMap();

  const { checkIsTokenSupplier } = useIsTokenSupplierCheck();
  const { genApprovalForAll: genApprovalForXCollection } = useERC721();
  const { genAcceptBidWithCredit } = useAcceptBidWithCredit(provider, account, contracts);
  const { genApproveSeaportConduit } = useApproveSeaportConduit(
    provider,
    account,
    contracts.Conduit
  );

  const [orderParams, setOrderParams] = useState<OrderWithCounter | null>(null);

  const handleNextStep = useCallback(() => {
    setStep(prev => prev + 1);
  }, []);

  const handleSignFinish = useCallback(
    (params: OrderWithCounter) => {
      setOrderParams(params);
      setStep(prev => prev + 1);
    },
    [setOrderParams, setStep]
  );

  const isTokenSupplied = useMemo(
    () => checkIsTokenSupplier(order.considerationItems?.[0]?.token ?? '', String(tokenId)),
    [checkIsTokenSupplier, order.considerationItems, tokenId]
  );

  const approveTokenId = useMemo(
    () => tokenId.toString() ?? order?.considerationItems?.[0]?.identifierOrCriteria!,
    [tokenId, order]
  );

  const [createdAcceptOrderInput] = useCreateSeaportAcceptOrderInput(
    account,
    contracts.ConduitKey,
    {
      orderData: order,
      feePoints: fees,
      identifierOrCriteria: approveTokenId
    }
  );
  const { startAmount, endAmount, token } = order.protocolData.parameters?.offer?.[0] ?? {};

  const steps = useMemo(() => {
    const batchTxs = [
      { tx: genApproveSeaportConduit(createdAcceptOrderInput) },
      {
        tx: genApprovalForXCollection({
          address: nftInfoMap[symbol!].xTokenAddress,
          spender: contracts.Conduit,
          ids: [tokenId]
        })
      },
      {
        tx: genAcceptBidWithCredit(order, orderParams!, approveTokenId),
        skip: orderParams === null
      }
    ];

    return [
      {
        description: `Check Bidder's Allowance`,
        content: (
          <CheckBidderAllowanceSubmitter
            offer={{
              amount: (startAmount || endAmount)!,
              token: token ?? '',
              offerer: order.protocolData.parameters?.offerer ?? ''
            }}
            onFinish={handleNextStep}
            onError={onError}
          />
        )
      },
      {
        description: `Signing Order`,
        content: (
          <SignSeaportOfferSubmitter
            formData={{
              signer: account,
              createdAcceptOrderInput
            }}
            onFinish={handleSignFinish}
            onError={onError}
          />
        ),
        skip: !isTokenSupplied
      },
      {
        description: `Approve And Accept Bid With Credit`,
        content: (
          <BatchTransactionsSubmitter
            batchTxs={batchTxs}
            onFinish={handleNextStep}
            onError={onError}
          />
        ),
        skip: !isTokenSupplied
      },
      {
        description: `Fulfill Offer`,
        content: (
          <FulfillOrderSubmitter
            mutationOptions={{} as any}
            order={order}
            symbol={symbol!}
            identifierOrCriteria={tokenId.toString()}
            onFinish={onSuccess}
            onError={onError}
          />
        )
      }
    ];
  }, [
    handleNextStep,
    onError,
    onSuccess,
    order,
    symbol,
    tokenId,
    approveTokenId,
    genApproveSeaportConduit,
    genApprovalForXCollection,
    genAcceptBidWithCredit,
    isTokenSupplied,
    nftInfoMap,
    contracts,
    orderParams,
    endAmount,
    startAmount,
    token,
    createdAcceptOrderInput,
    account,
    handleSignFinish
  ]);

  return <Stepper step={step} steps={steps} />;
};
