import { memo, useCallback, useMemo } from 'react';
import {
  Button,
  DataGrid,
  DataGridColumn,
  H5,
  Inline,
  Stack,
  Tag,
  Toggle
} from '@parallel-mono/components';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';

import useDevice from '@/apps/paraspace/hooks/useDevice';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { emptyArray } from '@/apps/paraspace/consts/values';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { NFTThumbnail } from '@/apps/paraspace/components';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import { useContractAddressBySymbol } from '@/apps/paraspace/hooks';

const InlineWithCursor = styled(Inline)`
  cursor: pointer;
`;

const StyledInline = styled(Inline)`
  flex-grow: 1;
  justify-content: flex-end;
`;

const CollectionId = styled(H5)`
  text-decoration: underline;
`;

type NFTCollectionItemsGridProps = {
  symbol: ERC721Symbol;
  inLiquidation: boolean;
  onToggleItemCollateral?: (id: number) => void;
};

type NftGridItem = {
  id: number;
  asCollateral: boolean;
  inAuction: boolean;
};

export const NFTCollectionItemsGrid = memo((props: NFTCollectionItemsGridProps) => {
  const { symbol, onToggleItemCollateral, inLiquidation } = props;
  const { isMobile } = useDevice();
  const { nftInfoMap } = useMMProvider();
  const {
    nftSuppliedList = emptyArray,
    nftCollateralList = emptyArray,
    auctionedTokens
  } = nftInfoMap[symbol];

  const items: NftGridItem[] = useMemo(() => {
    return nftSuppliedList.map(id => ({
      id,
      asCollateral: nftCollateralList.includes(id),
      inAuction: auctionedTokens?.includes(id) ?? false
    }));
  }, [nftSuppliedList, nftCollateralList, auctionedTokens]);

  const contractAddress = useContractAddressBySymbol(symbol);
  const navigate = useNavigate();
  const handleDetailClick = useCallback(
    clickEvent => {
      const {
        dataset: { id }
      } = clickEvent.currentTarget as HTMLElement;

      // HACK: fix me when the nft owner can be correctly distinguished
      navigate(`/details/${contractAddress}/${id}`, { state: { from: 'supply' } });
    },
    [navigate, contractAddress]
  );

  const showApeStakingInfo = isNFTinvolvedWithApeStaking(symbol);
  const { checkIfTokenInPairing } = useP2PInfo();

  const columns: DataGridColumn<NftGridItem>[] = useMemo(
    () => [
      {
        title: '',
        name: 'imageThumbnail',
        render: ({ data: { id, inAuction } }) => {
          const traitMultiplier =
            nftInfoMap?.[symbol]?.tokenTraitMultipliers?.[id] ?? new BigNumber(DEFAULT_MULTIPLIER);
          return (
            <InlineWithCursor
              gap="1rem"
              alignItems="center"
              data-id={id}
              onClick={handleDetailClick}
            >
              <NFTThumbnail tokenId={id} symbol={symbol} size="small" />
              <Stack gap="0.25rem">
                <CollectionId fontWeight="bold">#{id}</CollectionId>
                {traitMultiplier.gt(DEFAULT_MULTIPLIER) && (
                  <Inline>
                    <Tag skin="success" size="small">
                      {formatNumber(traitMultiplier)}x Boost
                    </Tag>
                  </Inline>
                )}
                {inAuction && (
                  <Tag skin="error" size="small">
                    In Liquidation
                  </Tag>
                )}
              </Stack>
            </InlineWithCursor>
          );
        }
      },
      {
        title: '',
        name: '',
        width: '7rem',
        hidden: isMobile,
        render: ({ data: { asCollateral, id } }) => (
          <Toggle
            disabled={asCollateral && inLiquidation}
            checked={asCollateral}
            onChange={() => onToggleItemCollateral && onToggleItemCollateral(id)}
          />
        )
      },
      {
        title: '',
        name: '',
        width: '26rem',
        render: ({ data: { id } }) => {
          return (
            <StyledInline gap="1rem">
              {symbol === ERC721Symbol.MOONBIRD && (
                <Button skin="secondary" data-id={id} onClick={handleDetailClick}>
                  View Nesting
                </Button>
              )}
              {showApeStakingInfo && (
                <Button
                  skin="secondary"
                  onClick={() => {
                    navigate(
                      checkIfTokenInPairing(symbol, id)
                        ? absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS.MY_POSITION
                        : absoluteRouteNames.APE_STAKING.index
                    );
                  }}
                >
                  Stake
                </Button>
              )}
            </StyledInline>
          );
        }
      }
    ],
    [
      isMobile,
      nftInfoMap,
      symbol,
      handleDetailClick,
      inLiquidation,
      onToggleItemCollateral,
      showApeStakingInfo,
      navigate,
      checkIfTokenInPairing
    ]
  );

  return <DataGrid columns={columns} data={items} hideColumnsTitle />;
});
