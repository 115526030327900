import { Modal, ModalProps } from '@parallel-mono/components';
import { memo, useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { SupplyNftFromBendDAOForm } from './SupplyNftFromBendDAOForm';
import { SupplyNftFromBendDAOFormSubmitter } from './SupplyNftFromBendDAOFormSubmitter';
import { SuccessState } from './SuccessState';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import {
  ErrorState,
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

export type SupplyNftFromBendDAOModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  symbol: ERC721Symbol;
  onError: () => void;
  onFinish: () => void;
  onClose: () => void;
};

type SupplyNftFromBendDAOFormData = Maybe<{
  tokens: { tokenId: number; loanId: Maybe<string> }[];
  symbol: ERC721Symbol;
  increasedBorrowLimit: BigNumber;
}>;

export const SupplyNftFromBendDAOModal = memo(
  ({ isOpen, onError, onFinish, onClose, symbol, ...others }: SupplyNftFromBendDAOModalProps) => {
    const { phase, submittedFormData, handleFormSubmit, handleSubmitSuccess, handleSubmitFailed } =
      useCollectAndSubmitProcedure<SupplyNftFromBendDAOFormData>({
        running: isOpen,
        onError,
        onFinish
      });

    const { nftInfoMap } = useMMProvider();
    const collectionName = nftInfoMap[symbol]?.collectionName ?? symbol;

    const tokenIds = useMemo(
      () => submittedFormData?.tokens.map(it => it.tokenId) ?? [],
      [submittedFormData]
    );

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={phase === CollectAndSubmitProcedurePhase.Collecting ? '45rem' : '30rem'}
        title={`Supply ${collectionName}`}
        {...others}
      >
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <SupplyNftFromBendDAOForm
            collectionName={collectionName}
            symbol={symbol}
            onSubmit={handleFormSubmit}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <SupplyNftFromBendDAOFormSubmitter
            formData={submittedFormData!}
            onFinish={handleSubmitSuccess}
            onError={handleSubmitFailed}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            onAction={onClose}
            tokenIds={tokenIds}
            symbol={symbol}
            increasedBorrowLimit={submittedFormData!.increasedBorrowLimit}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
      </Modal>
    );
  }
);
