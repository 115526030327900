import { Icon, Inline } from '@parallel-mono/components';
import { useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';

import { SummaryInfoBanner } from '@/apps/paraspace/components';
import { useShopSummaryData } from '@/apps/paraspace/pages/contexts/PlatformSummaryProvider';

export const Stats = () => {
  const shopSummaryData = useShopSummaryData();

  const values = useMemo(() => {
    return [
      {
        label: 'Available NFT',
        value: shopSummaryData ? formatNumber(shopSummaryData.availableNFT) : '-'
      },
      {
        label: 'Purchased',
        value: shopSummaryData ? formatNumber(shopSummaryData.purchased) : '-'
      },
      {
        label: 'Volume (ETH)',
        value: shopSummaryData ? formatNumber(shopSummaryData.volume) : '-'
      }
    ];
  }, [shopSummaryData]);

  return (
    <Inline width="100%" justifyContent="center">
      <SummaryInfoBanner
        width="100%"
        title="Shop"
        description=""
        icon={<Icon name="shoppingBag" />}
        valueList={values}
      />
    </Inline>
  );
};
