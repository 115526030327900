import { BigNumber } from 'bignumber.js';
import { Signature } from 'ethers';
import { StakingType } from 'paraspace-utilities-contract-helpers';

import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC20Symbol,
  ERC721Symbol
} from '@/apps/paraspace/typings';

export enum ListingType {
  ApeListing,
  ApeCoinListing,
  BakcListing
}

export type SymbolToListingType<S> = S extends ERC721Symbol.BAKC
  ? ListingType.BakcListing
  : S extends ERC20Symbol.APE
  ? ListingType.ApeCoinListing
  : S extends ERC721Symbol.BAYC
  ? ListingType.ApeListing
  : S extends ERC721Symbol.MAYC
  ? ListingType.ApeListing
  : never;

export interface BaseListing<S> extends Pick<Signature, 'v' | 'r' | 's'> {
  stakingType: StakingType;
  symbol: S;
  listingType: SymbolToListingType<S>;
  stakingPool: ApeStakingTokenSymbol;
  listingHash: string;
  offerer: string;
  share: BigNumber;
  startDate: Date;
  expirationDate: Date;
  token: string;
}

export interface ApeCoinListing extends BaseListing<ERC20Symbol.APE> {
  amount: BigNumber;
}

export interface ApeListing extends BaseListing<ApeStakingMainTokenSymbol> {
  tokenId: number;
}

export interface BakcListing extends BaseListing<ERC721Symbol.BAKC> {
  tokenId: number;
}

export type ListingTypeToListingMap<T> = T extends ListingType.ApeCoinListing
  ? ApeCoinListing
  : T extends ListingType.ApeListing
  ? ApeListing
  : T extends ListingType.BakcListing
  ? BakcListing
  : never;

export enum P2PApeStakingType {
  BAYCStaking,
  MAYCStaking
}

export type Listing = ApeCoinListing | ApeListing | BakcListing;

export enum ApeCoinSourceToJoinApeListing {
  APE_BALANCE,
  CAPE_BALANCE
}

export type BakcAndApeToken = {
  symbol: ApeStakingTokenSymbol;
  tokenId: number;
};
