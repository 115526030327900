import { memo } from 'react';
import styled from 'styled-components';
import { BigText, Button, Inline, Stack, StackProps, Text } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import { useStakeInfoList } from '../../StakeInfoListProvider/StakeInfoListProvider';

import { StakeBakcFormData } from './StakeModal';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { MIN_BALANCE_THRESHOLD } from '@/apps/paraspace/utils/format';
import { NFTThumbnail } from '@/apps/paraspace/components';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';

export type SuccessStateProps = Omit<StackProps, 'children'> & {
  info: StakeBakcFormData;
  bakcTokenId: number;
  onFinish: () => void;
};

const CenteredText = styled(Text)`
  text-align: center;
`;

export const SuccessState = memo(
  ({ info, bakcTokenId, onFinish, ...others }: SuccessStateProps) => {
    const { amountFromBalance } = info;
    const { bakcInfoList } = useStakeInfoList();
    const stakedAmount =
      bakcInfoList.find(i => i.tokenId === bakcTokenId)?.stakedAmount?.toNumber() ?? 0;

    const totalAmount = (amountFromBalance ?? 0) + stakedAmount;

    return (
      <Stack alignItems="center" {...others}>
        <NFTThumbnail symbol={ERC721Symbol.BAKC} tokenId={bakcTokenId} />
        <Stack alignItems="center" gap="0">
          <BigText fontWeight="bold">
            You have paired{' '}
            {formatNumber(amountFromBalance ?? 0, {
              decimal: MAXIMUM_BALANCE_DECIMALS,
              threshold: { min: MIN_BALANCE_THRESHOLD }
            })}{' '}
            ApeCoin on #{bakcTokenId}!
          </BigText>
          <CenteredText skin="secondary">
            Your total paired amount is{' '}
            {formatNumber(totalAmount ?? 0, {
              decimal: MAXIMUM_BALANCE_DECIMALS,
              threshold: { min: MIN_BALANCE_THRESHOLD }
            })}{' '}
            ApeCoin on #{bakcTokenId}.
          </CenteredText>
        </Stack>
        <Inline width="100%">
          <Button size="large" block skin="primary" onClick={onFinish}>
            Done
          </Button>
        </Inline>
      </Stack>
    );
  }
);
