import { memo, useMemo } from 'react';
import { InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import { formatNumber, sumBy } from '@parallel-mono/utils';
import { H5, SmallText, Stack } from '@parallel-mono/components';

import { BuyCartAsset } from '../../../types';

import { formatToPercentage } from '@/apps/paraspace/utils/format';
import { calculateEffectiveApy } from '@/apps/paraspace/utils/calculateApy';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useAppConfig } from '@/apps/paraspace/hooks';

type InfosProps = Omit<InfoPanelProps, 'infos'> & {
  assets: BuyCartAsset[];
  payLaterCurrency: ERC20Symbol;
};
// calculateEffectiveApy(apy, borrowApyRate)
export const Infos = memo(({ assets, payLaterCurrency, ...others }: InfosProps) => {
  const { erc20InfoMap } = useMMProvider();

  const { borrowApyRate } = erc20InfoMap[payLaterCurrency];

  const { erc20Config } = useAppConfig();
  const { apy = 0 } = erc20Config[payLaterCurrency] ?? {};

  const effectiveApy = useMemo(
    () => calculateEffectiveApy(apy, borrowApyRate),
    [apy, borrowApyRate]
  );

  const totalPaymentInEth = useMemo(() => sumBy(assets, it => it.price), [assets]);
  const infos = useMemo(
    () => [
      {
        title: 'Total Payment',
        value: <H5>{formatNumber(totalPaymentInEth)} ETH</H5>
      },
      {
        title: `${payLaterCurrency} Rate`,
        value: (
          <Stack gap="0" alignItems="flex-end">
            <H5>{formatToPercentage(borrowApyRate)}</H5>
            <SmallText skin="secondary">
              {formatToPercentage(effectiveApy)} effective rate
            </SmallText>
          </Stack>
        ),
        tip: 'The borrow APY of the chosen borrow token'
      },
      {
        title: 'Max Allowed Slippage',
        value: <H5>{formatToPercentage(0.008)}</H5>
      }
    ],
    [borrowApyRate, effectiveApy, payLaterCurrency, totalPaymentInEth]
  );
  return <InfoPanel skin="primary" {...others} infos={infos} />;
});
