import { Inline, Button } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { StakeApeFormData } from '.';

import { formatBalance } from '@/apps/paraspace/utils/format';
import { NFTThumbnail, SuccessState } from '@/apps/paraspace/components';

const StakingSuccess = ({
  formData,
  onClose,
  stakedAmount
}: {
  formData: StakeApeFormData;
  onClose: () => void;
  stakedAmount: BigNumber;
}) => {
  const { borrowAmount, cashAmount, tokenId, type } = formData;
  const totalAddedAmount = borrowAmount + cashAmount;

  const navigate = useNavigate();
  const handleViewBorrow = useCallback(() => {
    onClose();
    navigate('/');
  }, [navigate, onClose]);

  return (
    <SuccessState
      icon={<NFTThumbnail size="large" symbol={type} tokenId={tokenId} showDescription />}
      desc={`You have staked ${formatBalance(totalAddedAmount)} ApeCoin on #${tokenId}!`}
      tip={`Your total staked amount is ${formatBalance(
        stakedAmount.plus(totalAddedAmount)
      )} on #${tokenId}. ${
        borrowAmount > 0
          ? 'Because you got $APE from ParaSpace Money Market, you have created a borrow position which you can view on your lending position.'
          : ''
      }
      `}
    >
      <Inline width="100%">
        <Button block size="large" onClick={onClose}>
          Done
        </Button>
        {borrowAmount > 0 && (
          <Button skin="secondary" block size="large" onClick={handleViewBorrow}>
            View Borrow
          </Button>
        )}
      </Inline>
    </SuccessState>
  );
};

export default StakingSuccess;
