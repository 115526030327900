import { memo, useMemo } from 'react';
import { Button, H5, Icon, Image, Inline } from '@parallel-mono/components';
import { HostedImage } from '@parallel-mono/business-components';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';

import { ListingType } from '../../../types';

import { DropdownMenu, DropdownMenuProps } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  ApeStakingTokenSymbol,
  ERC20Symbol,
  ERC721Symbol,
  WalletType
} from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';
import { useGetUserApesState } from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/contexts/P2PStakingProvider/hooks';

type CreateListingFromDropDownButtonProps = Omit<DropdownMenuProps, 'options' | 'menuTrigger'> & {
  listingType: ListingType;
  stakingPool: ApeStakingTokenSymbol;
  onCreateListing: (source: WalletType) => void;
};

const StyledDropdownMenu = styled(DropdownMenu)`
  justify-content: flex-end;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
  `}
`;

export const CreateListingFromDropDownButton = memo(
  ({
    onCreateListing,
    stakingPool,
    listingType,
    ...others
  }: CreateListingFromDropDownButtonProps) => {
    const { authentication } = useWeb3Context();
    const { erc20InfoMap: AAERC20 } = useMMProvider();
    const { erc20BalanceMap: EOAERC20, erc721BalanceMap: EOANFT } = useEOABalances();
    const { apeAndBakcTokenSet } = useGetUserApesState();
    const aaApeBalance = (ape: ApeStakingTokenSymbol) =>
      new BigNumber(
        apeAndBakcTokenSet[ape].filter(
          ({ source, isStaked }) => !isStaked && (!source || source === 'AA')
        ).length
      );

    let eoaBalance = zero;
    let aaBalance = zero;
    if (listingType === ListingType.ApeCoinListing) {
      eoaBalance = EOAERC20?.[ERC20Symbol.APE] ?? zero;
      aaBalance = AAERC20?.[ERC20Symbol.APE]?.balance ?? zero;
    } else if (listingType === ListingType.BakcListing) {
      eoaBalance = new BigNumber(EOANFT?.[ERC721Symbol.BAKC]?.balance?.length ?? zero);
      aaBalance = aaApeBalance(ERC721Symbol.BAKC);
    } else if (stakingPool === ERC721Symbol.BAKC) {
      const eoaBAYCBalance = new BigNumber(EOANFT?.[ERC721Symbol.BAYC]?.balance?.length ?? zero);
      const eoaMAYCBalance = new BigNumber(EOANFT?.[ERC721Symbol.MAYC]?.balance?.length ?? zero);
      const aaBAYCBalance = aaApeBalance(ERC721Symbol.BAYC);
      const aaMAYCBalance = aaApeBalance(ERC721Symbol.MAYC);

      eoaBalance = eoaBAYCBalance.plus(eoaMAYCBalance);
      aaBalance = aaBAYCBalance.plus(aaMAYCBalance);
    } else {
      eoaBalance = new BigNumber(EOANFT?.[stakingPool]?.balance?.length ?? zero);
      aaBalance = aaApeBalance(stakingPool);
    }

    const options = useMemo(
      () => [
        {
          icon: (
            <HostedImage
              name="design/PDS_V3/logo/parallel-v2-logo"
              height="1.25rem"
              width="1.25rem"
            />
          ),
          label: 'AA Wallet',
          value: formatNumber(aaBalance, { averageMinLength: 3 }),
          onClick: () => {
            onCreateListing('AA');
          }
        },
        {
          icon: (
            <Image
              src={authentication.meta.walletIcon ?? undefined}
              height="1.25rem"
              width="1.25rem"
            />
          ),
          label: authentication.meta.walletType,
          value: formatNumber(eoaBalance, { averageMinLength: 3 }),
          onClick: () => {
            onCreateListing('EOA');
          }
        }
      ],
      [authentication, onCreateListing, aaBalance, eoaBalance]
    );

    const menuTrigger = useMemo(
      () => (
        <Button variant="link">
          <Inline alignItems="center" gap="0.25rem">
            <Icon width="1.25rem" name="plusCircle" />
            <H5>Create</H5>
          </Inline>
        </Button>
      ),
      []
    );
    return (
      <StyledDropdownMenu
        placement="bottom"
        width="15rem"
        gap="0"
        options={options}
        menuTrigger={menuTrigger}
        title="Create Listings"
        subTitle="Select where you want to use your balance"
        {...others}
      />
    );
  }
);
