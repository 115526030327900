import {
  Button,
  Card,
  Display1,
  H3,
  H4,
  H5,
  H6,
  Icon,
  IconName,
  Inline,
  SmallText,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import { FC, memo, ReactElement, useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import { sum } from 'lodash';

import { ReactComponent as GoldCoins } from '../../../Images/gold-coins.svg';
import { LiquidationTag } from '../../../components';

import { StakingBreakDown } from './StakingBreakDown';

import {
  useApeNetApy,
  useApeListStatesAndActions
} from '@/apps/paraspace/pages/ApePairing/contexts';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Link, Tooltip } from '@/apps/paraspace/components';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { DEVELOPER_DOCS_LINK } from '@/apps/paraspace/consts/externalLinks';

type MyApeEarningsProps = Omit<StackProps, 'children'>;
type ApeAmountLineProps = Omit<StackProps, 'children'> & { data: string };
type StakeDataItemProps = {
  iconName: string;
  description: string;
  title: string;
  mainContent: ReactElement;
  linkUrl?: string;
};
type DataItemProps = Omit<StackProps, 'children'> & StakeDataItemProps;

const EarningsContainer = styled(Card)`
  width: 100%;
`;

const Content = styled(Inline)`
  flex-wrap: wrap;
  gap: 1.5rem;
`;

const ProfitsContainer = styled(Card)`
  min-width: 20rem;
  width: fit-content;

  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
  `}
`;
const StakeContainer = styled(Card)`
  flex: 1;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 2rem;

  ${({ theme }) => theme.breakpoints.only('mobile')`
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: column-reverse;
  `}
`;

const IconContainer = styled(Inline)`
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.75rem;
  border: 1px solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 50%;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.only('mobile')`
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  `}
`;

const DataItem: FC<DataItemProps> = memo(
  ({ iconName, title, description, mainContent: content, linkUrl, ...props }) => {
    return (
      <Inline {...props}>
        <IconContainer>
          <Icon name={iconName as IconName} size="1.25rem" strokeWidth={2} />
        </IconContainer>
        <Stack gap="0.125rem">
          <SmallText skin="secondary">{title}</SmallText>
          <Stack gap="0">
            {content}
            {linkUrl ? (
              <Link href={linkUrl} target="_blank">
                <SmallText skin="secondary">{description}</SmallText>
              </Link>
            ) : (
              <SmallText skin="secondary">{description}</SmallText>
            )}
          </Stack>
        </Stack>
      </Inline>
    );
  }
);

export const ApeAmountLine: FC<ApeAmountLineProps> = memo(({ data, ...props }) => {
  const dataList = data.split('.');
  return (
    <Inline gap="0.25rem" alignItems="baseline" {...props}>
      <Inline gap="0" alignItems="center">
        <H3>{dataList[0]}</H3>
        {dataList[1] && <H3 skin="secondary">.{dataList[1]}</H3>}
      </Inline>
      <H6 skin="secondary">APE</H6>
    </Inline>
  );
});

export const MyApeEarnings: FC<MyApeEarningsProps> = memo(props => {
  const { erc20InfoMap } = useMMProvider();
  const { apesInSuppliedExcludingInP2P } = useApeListStatesAndActions();
  const { dailyRewardsData, dailyInterestsData } = useApeNetApy();
  const navigate = useNavigate();

  const {
    suppliedAmount: suppliedApeAmount,
    borrowedAmount: borrowedApeAmount,
    borrowedAmountInUsd: borrowedApeAmountInUsd,
    priceInUsd: apePriceInUsd
  } = erc20InfoMap.APE ?? {};
  const {
    borrowedAmount: borrowedCapeAmount,
    borrowedAmountInUsd: borrowedCapeAmountInUsd,
    balance: capeBalance,
    suppliedAmount: capeSuppliedAmount
  } = erc20InfoMap.CAPE ?? {};

  const nftPoolsStakedAmount = useMemo(
    () =>
      apesInSuppliedExcludingInP2P.reduce(
        (total, ape) => total.plus(ape.stakedAmount || 0),
        BigNumber(0)
      ),
    [apesInSuppliedExcludingInP2P]
  );
  const apePoolStakedAmount = useMemo(
    () =>
      capeBalance && capeSuppliedAmount
        ? capeBalance.plus(capeSuppliedAmount).plus(suppliedApeAmount ?? 0)
        : null,
    [capeBalance, capeSuppliedAmount, suppliedApeAmount]
  );
  const totalStakedAmount = useMemo(
    () => (apePoolStakedAmount ? nftPoolsStakedAmount.plus(apePoolStakedAmount) : null),
    [apePoolStakedAmount, nftPoolsStakedAmount]
  );

  const weightedNetApy = useMemo(() => {
    if (dailyRewardsData && dailyInterestsData && totalStakedAmount) {
      // net_reward = total_reward - total_interest
      // net_apy = net_reward / total_staked * 100%
      return BigNumber(sum(dailyRewardsData) - sum(dailyInterestsData)).div(totalStakedAmount);
    }
    return null;
  }, [dailyRewardsData, dailyInterestsData, totalStakedAmount]);

  const StakeDataList: StakeDataItemProps[] = useMemo(
    () => [
      {
        iconName: 'trendingUp',
        title: 'Net APY (-interest)',
        description: 'Learn more',
        mainContent: (
          <H3>{weightedNetApy ? formatNumber(weightedNetApy, { output: 'percent' }) : '-'}</H3>
        ),
        linkUrl: `${DEVELOPER_DOCS_LINK}/product-guide-and-walkthrough/ape-staking-guide/ape-staking-my-earnings-and-calculator-faq#how-is-my-net-apy-calculated`
      },
      {
        iconName: 'database',
        title: 'Staked/Supplied',
        description:
          totalStakedAmount && apePriceInUsd
            ? formatToCurrency(totalStakedAmount.times(apePriceInUsd))
            : '-',
        mainContent: (
          <ApeAmountLine data={totalStakedAmount ? formatNumber(totalStakedAmount) : '-'} />
        )
      },
      {
        iconName: 'creditCard',
        title: 'Borrowed',
        description:
          borrowedCapeAmountInUsd && borrowedApeAmountInUsd
            ? formatToCurrency(borrowedCapeAmountInUsd.plus(borrowedApeAmountInUsd))
            : '-',
        mainContent: (
          <ApeAmountLine
            data={
              borrowedApeAmount && borrowedCapeAmount
                ? formatNumber(borrowedApeAmount.plus(borrowedCapeAmount))
                : '-'
            }
          />
        )
      }
    ],
    [
      apePriceInUsd,
      borrowedApeAmount,
      borrowedApeAmountInUsd,
      borrowedCapeAmount,
      borrowedCapeAmountInUsd,
      totalStakedAmount,
      weightedNetApy
    ]
  );

  const currentDailyProfit = useMemo(
    () => dailyRewardsData[0] - dailyInterestsData[0],
    [dailyRewardsData, dailyInterestsData]
  );
  return (
    <EarningsContainer border {...props}>
      <Stack gap="2rem">
        <Title>
          <H4>My ParaSpace APE Earnings</H4>
          <LiquidationTag />
        </Title>
        <Content justifyContent="space-between" gap="0">
          <ProfitsContainer border>
            <Stack gap="3.125rem">
              <Stack gap="0.5rem">
                <Inline alignItems="center" gap="0.25rem">
                  <SmallText skin="secondary">Net Daily Profits</SmallText>
                  <Tooltip content="Your latest daily reward minus daily borrow interests. This represents your net earnings." />
                </Inline>
                <Stack gap="0">
                  <Inline alignItems="baseline" gap="0.25rem">
                    <Display1 skin="success" fontWeight="bold">
                      {currentDailyProfit ? formatNumber(currentDailyProfit) : '-'}
                    </Display1>
                    <H5 skin="secondary">APE</H5>
                  </Inline>
                  <Text skin="secondary">
                    {currentDailyProfit && apePriceInUsd
                      ? formatToCurrency(BigNumber(currentDailyProfit).times(apePriceInUsd))
                      : '-'}
                  </Text>
                </Stack>
              </Stack>
              <Inline justifyContent="flex-end">
                <GoldCoins />
              </Inline>
            </Stack>
          </ProfitsContainer>
          <StakeContainer border>
            <Stack gap="2rem">
              {StakeDataList.map(
                ({ iconName, title, description, mainContent: content, linkUrl }) => (
                  <DataItem
                    key={title}
                    iconName={iconName}
                    title={title}
                    description={description}
                    mainContent={content}
                    linkUrl={linkUrl}
                  />
                )
              )}
            </Stack>
          </StakeContainer>
        </Content>
        <StakingBreakDown />
        <ButtonsContainer>
          <Button block size="large" skin="secondary" onClick={() => navigate('/')}>
            Pay Down Debt
          </Button>
          <Button
            block
            size="large"
            skin="secondary"
            onClick={() => navigate(`${absoluteRouteNames.APE_STAKING.COIN_POOL.index}/withdraw`)}
          >
            Take Profits
          </Button>
        </ButtonsContainer>
      </Stack>
    </EarningsContainer>
  );
});
