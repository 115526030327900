import { createContext, memo, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { StakeInfoListItem } from '../types';

import { ClaimRewardsModalProps, ClaimRewardsModal } from './ClaimRewardsModal';
import WithdrawModal, { WithdrawModalProps } from './WithdrawModal';
import ApeStakeModal, { ApeStakeModalProps } from './ApeStakeModal';
import { StakeBakcModal, StakeBakcModalProps } from './StakeBAKCModal';

import { ERC721Symbol, WalletType } from '@/apps/paraspace/typings';

type ApeStakeManagerContextProps = {
  manageApeStake: (tokenItem: StakeInfoListItem, apeCoinSource: WalletType) => Promise<void>;
  claimRewards: (tokenItem: StakeInfoListItem) => Promise<void>;
  withdrawApe: (tokenItem: StakeInfoListItem) => Promise<void>;
  stakeBakc: (tokenItem: StakeInfoListItem, apeCoinSource: WalletType) => Promise<void>;
};

const ApeStakeManagerContext = createContext<ApeStakeManagerContextProps>({
  manageApeStake: () => {
    throw new Error('ApeStakingManagerProvider not found');
  },
  claimRewards: () => {
    throw new Error('ApeStakingManagerProvider not found');
  },
  withdrawApe: () => {
    throw new Error('ApeStakingManagerProvider not found');
  },
  stakeBakc: () => {
    throw new Error('ApeStakingManagerProvider not found');
  }
});

type ApeStakingManagerProviderProps = {
  children: ReactNode;
};

const defaultManageStakeModalProps: ApeStakeModalProps = {
  isOpen: false,
  symbol: null,
  id: null,
  apeSource: 'AA',
  apeCoinSource: 'AA',
  onClose: () => {}
};

const defaultClaimRewardsModalProps = {
  isOpen: false,
  stakeItem: {
    symbol: ERC721Symbol.BAYC,
    tokenId: 0,
    source: null
  }
} as ClaimRewardsModalProps;

const defaultWithdrawModalProps = {
  isOpen: false
} as WithdrawModalProps;

const defaultStakeBakcModalProps: StakeBakcModalProps = {
  bakcSource: 'AA',
  bakcTokenId: 0,
  isOpen: false,
  apeCoinSource: 'AA',
  onClose: () => {}
};

export const ApeStakingManagerProvider = memo(({ children }: ApeStakingManagerProviderProps) => {
  const [manageStakeModalProps, setManageStakeModalProps] = useState<ApeStakeModalProps>(
    defaultManageStakeModalProps
  );
  const manageApeStake = useCallback(
    (tokenInfo: StakeInfoListItem, apeCoinSource: WalletType) => {
      if (manageStakeModalProps.isOpen) {
        throw new Error('there is a ape stake in progress');
      }
      return new Promise<void>(resolve => {
        setManageStakeModalProps({
          isOpen: true,
          symbol: tokenInfo.symbol,
          id: tokenInfo.tokenId,
          apeSource: tokenInfo.source!,
          apeCoinSource,
          onClose: () => {
            setManageStakeModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
            resolve();
          }
        });
      });
    },
    [manageStakeModalProps]
  );

  const [claimRewardsModalProps, setClaimRewardsModalProps] = useState(
    defaultClaimRewardsModalProps
  );
  const claimRewards = useCallback(
    (stakeItem: StakeInfoListItem) => {
      return new Promise<void>(resolve => {
        setClaimRewardsModalProps({
          isOpen: true,
          stakeItem,
          onClose: () => {
            setClaimRewardsModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
            resolve();
          }
        });
      });
    },
    [setClaimRewardsModalProps]
  );

  const [withdrawModalProps, setWithdrawModalProps] =
    useState<WithdrawModalProps>(defaultWithdrawModalProps);
  const withdrawApe = useCallback(
    (stakeItem: StakeInfoListItem) => {
      if (withdrawModalProps.isOpen) {
        throw new Error('there is a withdraw modal in progress');
      }
      return new Promise<void>(resolve => {
        setWithdrawModalProps({
          isOpen: true,
          stakeItem,
          onClose: () => {
            setWithdrawModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
            resolve();
          }
        });
      });
    },
    [withdrawModalProps]
  );

  const [stakeBakcModalProps, setStakeBakcModalProps] = useState(defaultStakeBakcModalProps);
  const stakeBakc = useCallback(
    (tokenItem: StakeInfoListItem, apeCoinSource: WalletType) => {
      if (stakeBakcModalProps.isOpen) {
        throw new Error('there is a BAKC adding in progress');
      }
      return new Promise<void>(resolve => {
        setStakeBakcModalProps({
          bakcSource: tokenItem.source!,
          apeCoinSource,
          isOpen: true,
          bakcTokenId: tokenItem.tokenId,
          onClose: () => {
            setStakeBakcModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
            resolve();
          }
        });
      });
    },
    [setStakeBakcModalProps, stakeBakcModalProps]
  );

  const contextValue: ApeStakeManagerContextProps = useMemo(
    () => ({ manageApeStake, claimRewards, withdrawApe, stakeBakc }),
    [manageApeStake, claimRewards, withdrawApe, stakeBakc]
  );

  return (
    <ApeStakeManagerContext.Provider value={contextValue}>
      <ApeStakeModal {...manageStakeModalProps} />
      {claimRewardsModalProps.stakeItem && <ClaimRewardsModal {...claimRewardsModalProps} />}
      {withdrawModalProps.stakeItem && <WithdrawModal {...withdrawModalProps} />}
      <StakeBakcModal {...stakeBakcModalProps} />
      {children}
    </ApeStakeManagerContext.Provider>
  );
});

export const useApeStakeManager = () => useContext(ApeStakeManagerContext);
