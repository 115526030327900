import { memo, useCallback, useMemo, useState } from 'react';
import { Button, H2, Modal, ModalProps, Stack, StackProps } from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { flatten, groupBy, isEmpty } from 'lodash';
import { BigNumber } from 'bignumber.js';
import { EthereumTransactionTypeExtended } from 'paraspace-utilities-contract-helpers';

import Success from '../component/CommonSuccess';

import { ClaimAllSubmitter } from './ClaimAllSubmitter';

import {
  useApeListStatesAndActions,
  ApeListItem
} from '@/apps/paraspace/pages/ApePairing/contexts/ApeListProvider';
import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC721Symbol
} from '@/apps/paraspace/typings';
import { ErrorState } from '@/apps/paraspace/components';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { usePoolApeStaking } from '@/apps/paraspace/hooks';
import { useOfficialApeStaking } from '@/apps/paraspace/pages/OfficialPairing/hook';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type ClaimingType = ApeStakingTokenSymbol | 'All';

export type ClaimAllRewardsModalProps = Omit<ModalProps, 'children' | 'id'> & {
  onClose: () => void;
  claimingType?: ClaimingType;
};

type ClaimRewardsModalContentProps = Omit<StackProps, 'children' | 'id'> & {
  onFinish: () => void;
  claimingType?: ClaimingType;
};

type BakcClaimingArgs = { mainTokenId: number; bakcTokenId: number };

type ClaimingQueue = {
  title: string;
  description: string;
  method: (
    type: ApeStakingMainTokenSymbol,
    tokenIds: number[]
  ) => Maybe<EthereumTransactionTypeExtended> | Promise<Maybe<EthereumTransactionTypeExtended>>;
  args: [ApeStakingMainTokenSymbol, number[] | BakcClaimingArgs[]];
  tokenPairs: any[];
};

enum ClaimingStatus {
  idle = 0,
  claiming = 1,
  success = 2,
  failed = 3
}

const ClaimRewardsModalContent = memo(
  ({ onFinish, claimingType = 'All', ...others }: ClaimRewardsModalContentProps) => {
    const [status, setStatus] = useState<ClaimingStatus>(ClaimingStatus.idle);
    const [claimedRewards, setClaimedRewards] = useState(0);
    const { genClaimApeTx, genBatchClaimBAKCTx } = usePoolApeStaking();
    const { apesInBalanceAndInSuppliedExcludingInP2P, refresh } = useApeListStatesAndActions();
    const {
      genClaimBakcTx: genClaimOfficialBakcTx,
      genClaimBaycOrMaycTx: genClaimOfficialBaycOrMaycTx
    } = useOfficialApeStaking();

    const handleSuccess = useCallback(() => {
      setStatus(ClaimingStatus.success);
      refresh();
    }, [refresh]);

    const handleFailed = useCallback(() => {
      setStatus(ClaimingStatus.failed);
      refresh();
    }, [refresh]);

    const filteredRewardApes = useMemo(() => {
      return apesInBalanceAndInSuppliedExcludingInP2P
        .filter(ape => ape.pendingRewards?.gt(0))
        .filter(ape => {
          const { symbol, mainTokenId, supplied } = ape;

          // if the BAKC is paired and not supplied, but the main token is transferred to the others.
          if (symbol === ERC721Symbol.BAKC && !supplied && mainTokenId) {
            const mainToken = apesInBalanceAndInSuppliedExcludingInP2P.find(
              item => item.tokenId === mainTokenId
            );
            // can not find the mainToken => the main token has been sent.
            // Find the mainToken => the main token belongs to the current user.
            return Boolean(mainToken);
          }

          // if the BAKC is paired and supplied, but the main token is not supplied.
          if (symbol === ERC721Symbol.BAKC && supplied && mainTokenId) {
            const mainToken = apesInBalanceAndInSuppliedExcludingInP2P.find(
              item => item.tokenId === mainTokenId
            );
            return mainToken ? mainToken.supplied : false;
          }
          return true;
        });
    }, [apesInBalanceAndInSuppliedExcludingInP2P]);

    const availableRewards = useMemo(
      () =>
        filteredRewardApes
          .filter(ape => {
            if (claimingType === 'All') {
              return true;
            }
            return ape.symbol === claimingType;
          })
          .reduce((total, ape) => total.plus(ape?.pendingRewards || 0), BigNumber(0)),
      [claimingType, filteredRewardApes]
    );

    const handleClaim = useCallback(() => {
      setStatus(ClaimingStatus.claiming);
      setClaimedRewards(availableRewards.toNumber());
    }, [availableRewards]);

    const generateGroupKeyBySupplied = useCallback(
      (collection: ApeListItem): 'supplied' | 'notSupplied' => {
        const { symbol, mainTokenId, supplied } = collection;
        if (symbol === ERC721Symbol.BAKC) {
          const mainTokenInfo = apesInBalanceAndInSuppliedExcludingInP2P.find(
            i => i.tokenId === mainTokenId
          );
          const isMainTokenSupplied = mainTokenInfo?.supplied;
          return isMainTokenSupplied ? 'supplied' : 'notSupplied';
        }
        return supplied ? 'supplied' : 'notSupplied';
      },
      [apesInBalanceAndInSuppliedExcludingInP2P]
    );

    const groupedApesBySymbolAndSupplied = useMemo(() => {
      const groupedBySymbol = groupBy(filteredRewardApes, 'symbol');
      return {
        [ERC721Symbol.BAKC]: groupBy(
          groupedBySymbol[ERC721Symbol.BAKC],
          generateGroupKeyBySupplied
        ),
        [ERC721Symbol.MAYC]: groupBy(
          groupedBySymbol[ERC721Symbol.MAYC],
          generateGroupKeyBySupplied
        ),
        [ERC721Symbol.BAYC]: groupBy(groupedBySymbol[ERC721Symbol.BAYC], generateGroupKeyBySupplied)
      };
    }, [filteredRewardApes, generateGroupKeyBySupplied]);

    const generateBaycOrMaycQueues = useCallback(
      (symbol: ERC721Symbol.BAYC | ERC721Symbol.MAYC): ClaimingQueue[] => {
        const suppliedPairs =
          groupedApesBySymbolAndSupplied[symbol].supplied?.map(token => token.tokenId) || [];
        const notSuppliedPairs =
          groupedApesBySymbolAndSupplied[symbol].notSupplied?.map(token => token.tokenId) || [];
        // paraspace
        return [
          {
            title: symbol,
            description: `Claim ${symbol} rewards through ParaSpace`,
            method: genClaimApeTx,
            args: [symbol, suppliedPairs],
            tokenPairs: suppliedPairs
          },
          // official
          {
            title: symbol,
            description: `Claim ${symbol} rewards through Horizen Lab`,
            method: genClaimOfficialBaycOrMaycTx,
            args: [symbol, notSuppliedPairs],
            tokenPairs: notSuppliedPairs
          }
        ];
      },
      [genClaimApeTx, genClaimOfficialBaycOrMaycTx, groupedApesBySymbolAndSupplied]
    );

    const generatePairedTokenPairs = useCallback(
      (pairedList: ApeListItem[]) =>
        pairedList.map(item => ({
          mainTokenId: item.mainTokenId,
          bakcTokenId: item.tokenId
        })),
      []
    );

    const generateBakcQueues = useCallback(() => {
      // group the BAKC by the supplied main token
      const groupedBakcBySuppliedMainToken = groupBy(
        groupedApesBySymbolAndSupplied.BAKC.supplied,
        'mainTokenSymbol'
      ) as Record<ERC721Symbol.MAYC | ERC721Symbol.BAYC, ApeListItem[]>;

      // group the BAKC by the not supplied main token
      const groupedBakcByNotSuppliedMainToken = groupBy(
        groupedApesBySymbolAndSupplied.BAKC.notSupplied,
        'mainTokenSymbol'
      ) as Record<ERC721Symbol.MAYC | ERC721Symbol.BAYC, ApeListItem[]>;

      const suppliedBaycPairs = generatePairedTokenPairs(groupedBakcBySuppliedMainToken.BAYC || []);

      const suppliedMaycPairs = generatePairedTokenPairs(groupedBakcBySuppliedMainToken.MAYC || []);

      const notSuppliedBaycPairs = generatePairedTokenPairs(
        groupedBakcByNotSuppliedMainToken.BAYC || []
      );
      const notSuppliedMaycPairs = generatePairedTokenPairs(
        groupedBakcByNotSuppliedMainToken.MAYC || []
      );
      return [
        {
          title: ERC721Symbol.BAKC,
          description: `Claim ${ERC721Symbol.BAKC}(paired with ${ERC721Symbol.BAYC}) rewards through ParaSpace`,
          method: genBatchClaimBAKCTx,
          args: [ERC721Symbol.BAYC, suppliedBaycPairs],
          tokenPairs: suppliedBaycPairs,
          mainTokenSupplied: true
        },
        {
          title: ERC721Symbol.BAKC,
          description: `Claim ${ERC721Symbol.BAKC}(paired with ${ERC721Symbol.MAYC}) rewards through ParaSpace`,
          method: genBatchClaimBAKCTx,
          args: [ERC721Symbol.MAYC, suppliedMaycPairs],
          tokenPairs: suppliedMaycPairs,
          mainTokenSupplied: true
        },
        {
          symbol: ERC721Symbol.BAKC,
          description: `Claim ${ERC721Symbol.BAKC} rewards through Horizen Lab`,
          method: genClaimOfficialBakcTx,
          args: [notSuppliedBaycPairs, notSuppliedMaycPairs],
          tokenPairs: flatten([notSuppliedBaycPairs, notSuppliedMaycPairs])
        }
      ];
    }, [
      genBatchClaimBAKCTx,
      genClaimOfficialBakcTx,
      generatePairedTokenPairs,
      groupedApesBySymbolAndSupplied
    ]);

    const newClaimingQueues = useMemo(() => {
      const baycClaimingQueues = generateBaycOrMaycQueues(ERC721Symbol.BAYC);
      const maycClaimingQueues = generateBaycOrMaycQueues(ERC721Symbol.MAYC);
      const bakcClmingQueues = generateBakcQueues();
      return {
        [ERC721Symbol.BAYC]: baycClaimingQueues,
        [ERC721Symbol.MAYC]: maycClaimingQueues,
        [ERC721Symbol.BAKC]: bakcClmingQueues
      };
    }, [generateBaycOrMaycQueues, generateBakcQueues]);

    const filteredClaimingQueues = useMemo(() => {
      if (claimingType === 'All') {
        return [
          ...newClaimingQueues.MAYC,
          ...newClaimingQueues.BAYC,
          ...newClaimingQueues.BAKC
        ].filter(queue => !isEmpty(queue.tokenPairs));
      }
      return (newClaimingQueues[claimingType] as ClaimingQueue[]).filter(
        queue => !isEmpty(queue.tokenPairs)
      );
    }, [claimingType, newClaimingQueues]);

    return (
      <Stack alignItems="center" {...others}>
        {status === ClaimingStatus.claiming && (
          <ClaimAllSubmitter
            claimingQueues={filteredClaimingQueues}
            onFinish={handleSuccess}
            onError={handleFailed}
          />
        )}
        {status === ClaimingStatus.failed && <ErrorState closeModal={onFinish} />}
        {status === ClaimingStatus.idle && (
          <>
            <Stack alignItems="center">
              <CryptoIcon symbol="APE" size="5rem" />
              <H2>Available {formatBalance(availableRewards.toNumber())} APE</H2>
            </Stack>
            <Button block skin="primary" disabled={availableRewards.eq(0)} onClick={handleClaim}>
              Claim {claimingType} Rewards
            </Button>
          </>
        )}
        {status === ClaimingStatus.success && (
          <Success
            desc={`You just claimed ${formatBalance(claimedRewards)} APE coins!`}
            tip="Come back for more later!"
            onClose={onFinish!}
          />
        )}
      </Stack>
    );
  }
);

export const ClaimAllRewardsModal = memo(
  ({ onClose, claimingType, ...others }: ClaimAllRewardsModalProps) => {
    return (
      <Modal {...others} onClose={onClose} title="Claim Rewards">
        <ClaimRewardsModalContent claimingType={claimingType} onFinish={onClose} />
      </Modal>
    );
  }
);
