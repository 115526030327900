import { memo } from 'react';
import styled from 'styled-components';
import {
  Stack,
  Inline,
  Spinner,
  StackProps,
  Text,
  Icon,
  H3,
  Card,
  Pagination
} from '@parallel-mono/components';
import { useOutletContext } from 'react-router-dom';

import { usePagination } from '../../hooks/usePagination';

import { useNearLiquidationDashboardState } from './hooks/useNearLiquidationDashboardState';
import { NearLiquidationGrid } from './NearLiquidationGrid';
import { NearLiquidationAsset, NearLiquidationRow } from './types';
import { LiquidationOutletContext } from './LiquidationDashboard';

type NearLiquidationProps = Omit<StackProps, 'children'>;
const NoDataMessage = styled(Card).attrs({ border: true })`
  text-align: center;
  padding: 11.5rem;
`;

const PageContainer = styled(Stack)`
  min-height: 25rem;
`;

const PAGE_SIZE = 10;

export const NearLiquidation = memo(({ ...others }: NearLiquidationProps) => {
  const { selectedCollectionSymbols } = useOutletContext<LiquidationOutletContext>();
  const { nearLiquidationInfos, loading } =
    useNearLiquidationDashboardState(selectedCollectionSymbols);

  const { currentPage, setCurrentPage, totalPage, pageData } = usePagination(
    nearLiquidationInfos,
    PAGE_SIZE
  );

  return (
    <PageContainer width="100%" {...others}>
      {!loading && nearLiquidationInfos.length === 0 && (
        <NoDataMessage>
          <Stack alignItems="center">
            <Icon name="boxOpen" size="5rem" />
            <Stack gap="0.25rem">
              <H3 fontWeight="bold">Nothing near Liquidation</H3>
              <Text skin="secondary">Check back later for updates.</Text>
            </Stack>
          </Stack>
        </NoDataMessage>
      )}
      {loading ? (
        <Inline width="100%" justifyContent="center">
          <Spinner />
        </Inline>
      ) : (
        pageData.map((nearLiquidationInfo: NearLiquidationRow) => (
          <NearLiquidationGrid
            key={nearLiquidationInfo.accountAddress}
            profile={{
              walletAddress: nearLiquidationInfo.accountAddress
            }}
            collateral={nearLiquidationInfo.collateral}
            debt={nearLiquidationInfo.debt}
            healthFactor={nearLiquidationInfo.healthFactor}
            assets={nearLiquidationInfo.assets as NearLiquidationAsset[]}
          />
        ))
      )}
      {totalPage > 1 && (
        <Pagination
          total={totalPage}
          page={currentPage}
          onChange={setCurrentPage}
          siblingCount={0}
          startBoundaryCount={3}
        />
      )}
    </PageContainer>
  );
});
