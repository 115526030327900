import { InlineProps } from '@parallel-mono/components';
import { memo } from 'react';
import styled from 'styled-components';

import { useAppsContext, useEOAProvider } from '../../contexts';
import { FetchingStatus } from '../../typings';
import { getChainKey } from '../../config';

import { AppContainer, AppLoadingPlaceholder } from './components';

export type AppsProps = Omit<InlineProps, 'children'>;

const AppsContainer = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  height: 100%;
  justify-content: center;
`;

const StyledAppContainer = styled(AppContainer)`
  position: relative;
  width: 75%;
  min-width: 1092px;
  overflow: visible;
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('desktop')`
    padding: 0;
    width: 80%;
    min-width: 100%;
  `};
`;

StyledAppContainer.displayName = 'StyledAppContainer';

export const Apps = memo((props: AppsProps) => {
  const { chainId } = useEOAProvider();
  const chainPath = getChainKey(chainId);
  const { currentApp, visible, fetchingStatus } = useAppsContext();
  return (
    <AppsContainer visible={visible} gap="0" {...props}>
      {currentApp && fetchingStatus === FetchingStatus.FETCHING && (
        <AppLoadingPlaceholder appName={currentApp.name} appIcon={currentApp.icon} />
      )}
      {currentApp && fetchingStatus === FetchingStatus.SUCCESS && currentApp.appPackage && (
        <StyledAppContainer
          key={`${currentApp.route}-${currentApp.domainApp?.path ?? ''}`}
          app={currentApp.appPackage}
          domainApp={currentApp.domainApp}
          baseRoute={`${chainPath}/apps/${currentApp.route}`}
        />
      )}
    </AppsContainer>
  );
});
