import { Network } from 'paraspace-configs-v2';
import { orderBy } from 'lodash';
import { Environment } from 'parax-sdk';

import { getEnvToggles, Toggles } from './toggles';
import { contractsConfig } from './contractsConfig';

import { env } from '@/env';

export const COLOR_MODE_STORAGE_KEY = 'COLOR_MODE';

const lastCommit = process.env.REACT_APP_LAST_COMMIT as string;

const V1_SITE_URL_MAP: Record<Environment, string> = {
  [Environment.PRODUCTION]: 'https://app-paraspace.parallel.fi',
  [Environment.STAGING]: 'https://app-staging.para.space',
  [Environment.DEVELOPMENT]: 'https://app-dev.para.space'
};

const ENDPOINT_MAP: Record<Environment, string> = {
  [Environment.PRODUCTION]: 'https://api.para.space/graphql/ethereum',
  [Environment.STAGING]: 'https://api-staging.para.space/graphql/ethereum',
  [Environment.DEVELOPMENT]: 'https://api-dev.para.space/graphql/ethereum'
};

const ENDPOINT_MAP_ZKSYNC: Record<Environment, string> = {
  [Environment.PRODUCTION]: 'https://api.para.space/graphql/zksync',
  [Environment.STAGING]: 'https://api-staging.para.space/graphql/zksync',
  [Environment.DEVELOPMENT]: 'https://api-dev.para.space/graphql/zksync'
};

const ENDPOINT_MAP_FOR_ARBITRUM: Record<Environment, string> = {
  [Environment.PRODUCTION]: 'https://api.para.space/graphql/arbitrum',
  [Environment.STAGING]: 'https://api-staging.para.space/graphql/arbitrum',
  [Environment.DEVELOPMENT]: 'https://api-dev.para.space/graphql/arbitrum'
};

const ENDPOINT_MAP_FOR_PARALLEL_L3: Record<Environment, string> = {
  [Environment.PRODUCTION]: 'https://api-prod-para-l3.para.space/graphql',
  [Environment.STAGING]: 'https://api-staging.para.space/graphql/parallel-l3',
  [Environment.DEVELOPMENT]: 'https://api-dev.para.space/graphql/parallel-l3'
};

type SupportedNetworks =
  | Network.MAINNET
  | Network.SEPOLIA
  | Network.ZKSYNC_ERA
  | Network.ZKSYNC_GOERLI
  | Network.ARBITRUM_GOERLI
  | Network.ARBITRUM_ONE
  | Network.PARALLEL_L3_TESTNET
  | Network.PARALLEL;

const GRAPHQL_ENDPOINT_FOR_NETWORK_MAP: Record<SupportedNetworks, Record<Environment, string>> = {
  [Network.SEPOLIA]: ENDPOINT_MAP,
  [Network.MAINNET]: ENDPOINT_MAP,
  [Network.ZKSYNC_GOERLI]: ENDPOINT_MAP_ZKSYNC,
  [Network.ZKSYNC_ERA]: ENDPOINT_MAP_ZKSYNC,
  [Network.ARBITRUM_GOERLI]: ENDPOINT_MAP_FOR_ARBITRUM,
  [Network.ARBITRUM_ONE]: ENDPOINT_MAP_FOR_ARBITRUM,
  [Network.PARALLEL_L3_TESTNET]: ENDPOINT_MAP_FOR_PARALLEL_L3,
  [Network.PARALLEL]: ENDPOINT_MAP_FOR_PARALLEL_L3
};

const ENDPOINT_MAP_V1: Record<Environment, string> = {
  [Environment.PRODUCTION]: 'https://api.para.space/graphql',
  [Environment.STAGING]: 'https://api-staging.para.space/graphql',
  [Environment.DEVELOPMENT]: 'https://api-dev.para.space/graphql'
};

const GRAPHQL_ENDPOINT_FOR_NETWORK_MAP_V1: Record<
  SupportedNetworks,
  Record<Environment, string>
> = {
  [Network.SEPOLIA]: ENDPOINT_MAP_V1,
  [Network.MAINNET]: ENDPOINT_MAP_V1,
  [Network.ZKSYNC_GOERLI]: ENDPOINT_MAP_ZKSYNC,
  [Network.ZKSYNC_ERA]: ENDPOINT_MAP_ZKSYNC,
  [Network.ARBITRUM_GOERLI]: ENDPOINT_MAP_FOR_ARBITRUM,
  [Network.ARBITRUM_ONE]: ENDPOINT_MAP_FOR_ARBITRUM,
  [Network.PARALLEL_L3_TESTNET]: ENDPOINT_MAP_FOR_PARALLEL_L3,
  [Network.PARALLEL]: ENDPOINT_MAP_FOR_PARALLEL_L3
};

const siteUrlV1 = V1_SITE_URL_MAP[env];

type Config = {
  walletConnectProjectId: string;
  env: Environment;
  lastCommit: string;
  isProductionOrForkMainnet: boolean;
  siteUrlV1: string;
  toggles: Toggles;
  supportedNetworks: Network[];
};

type GraphqlConfig = {
  graphqlEndpoint: string;
  graphqlEndpointV1: string;
};

const NETWORK_ORDER: Network[] = [
  Network.MAINNET,
  Network.SEPOLIA,
  Network.PARALLEL,
  Network.PARALLEL_L3_TESTNET,
  Network.ZKSYNC_ERA,
  Network.ZKSYNC_GOERLI,
  Network.ARBITRUM_ONE,
  Network.ARBITRUM_GOERLI,
  Network.POLYGON_MAINNET,
  Network.POLYGON_MUMBAI,
  Network.MOONBEAM,
  Network.MOONBASE_ALPHA,
  Network.PARALLEL_TESTNET
];

const SUPPORTED_NETWORKS = [
  Network.MAINNET,
  Network.SEPOLIA,
  Network.ZKSYNC_ERA,
  Network.ZKSYNC_GOERLI,
  Network.ARBITRUM_GOERLI,
  Network.ARBITRUM_ONE,
  Network.PARALLEL_L3_TESTNET,
  Network.PARALLEL
];

export const sortedNetworks = orderBy(
  contractsConfig.network.supportedNetworks.filter(it => SUPPORTED_NETWORKS.includes(it)),
  chainId => {
    const networkOrder = NETWORK_ORDER.indexOf(chainId);
    return networkOrder >= 0 ? networkOrder : Number.MAX_VALUE;
  },
  ['asc']
);

export const config: Config = {
  walletConnectProjectId: 'c4c65d333c5f644c250aa15039e4e2bc',
  env,
  lastCommit,
  isProductionOrForkMainnet: env === Environment.PRODUCTION,
  siteUrlV1,
  toggles: getEnvToggles(env),
  supportedNetworks: sortedNetworks
};

export const getGraphqlConfig = ({ chainId }: { chainId: Network }): GraphqlConfig => {
  const supportedNetwork = chainId as SupportedNetworks;
  return {
    graphqlEndpoint: GRAPHQL_ENDPOINT_FOR_NETWORK_MAP[supportedNetwork][env],
    graphqlEndpointV1: GRAPHQL_ENDPOINT_FOR_NETWORK_MAP_V1[supportedNetwork][env]
  };
};
