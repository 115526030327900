import { FC, memo, useMemo } from 'react';

import type { TransferNftParams } from './index';

import { useERC721 } from '@/apps/paraspace/hooks';
import { StepperProps } from '@/apps/paraspace/components';
import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

interface TransferStepProps extends Omit<StepperProps, 'step' | 'steps'> {
  onError: () => void;
  onSuccess: () => void;
  data: TransferNftParams;
}

const TransferStep: FC<TransferStepProps> = ({ onError, onSuccess, data }) => {
  const { nftInfoMap } = useMMProvider();

  const tokenAddress = nftInfoMap[data.symbol]?.address ?? '';
  const mainTokenAddress = nftInfoMap[data.mainTokenSymbol!]?.address ?? '';

  const { genApprovalForAll } = useERC721(data.symbol);
  const { supplyERC721 } = usePool();
  const batchTxs = useMemo(() => {
    return [
      {
        tx: genApprovalForAll({ address: tokenAddress }),
        skip: data.supplied
      },
      {
        tx: supplyERC721(tokenAddress, [String([data.tokenId])]),
        skip: data.supplied
      },
      {
        tx: genApprovalForAll({ address: mainTokenAddress }),
        skip: !data.mainTokenSymbol
      },
      {
        tx: supplyERC721(mainTokenAddress, [String(data.mainTokenId!)]),
        skip: !data.mainTokenSymbol || data.mainTokenSupplied
      }
    ];
  }, [
    genApprovalForAll,
    supplyERC721,
    mainTokenAddress,
    tokenAddress,
    data.mainTokenId,
    data.mainTokenSymbol,
    data.mainTokenSupplied,
    data.supplied,
    data.tokenId
  ]);

  return <BatchTransactionsSubmitter batchTxs={batchTxs} onFinish={onSuccess} onError={onError} />;
};

export default memo(TransferStep);
