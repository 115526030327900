import { memo, useCallback, useMemo, useState } from 'react';
import { flatMap, keys, map } from 'lodash';
import { H2, Spinner, Stack, StackProps, Text } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import { CollapsableTokensSelector } from '../../components';
import { useNftDelegation } from '../../contexts';
import { TokenDelegationInfo } from '../../types';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Link, EmptyState, FixedActionFooter } from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { Maybe } from '@/apps/paraspace/typings/basic';

type ManageDelegationProps = Omit<StackProps, 'children'> & {};

export const ManageDelegation = memo((props: ManageDelegationProps) => {
  const { nftInfoMap } = useMMProvider();
  const { delegationMap, refreshDelegationMap, delegationMapLoaded } = useNftDelegation();

  const [selectedTokenMaps, setSelectedTokenMaps] = useState<
    Partial<{ [key in ERC721Symbol]: TokenDelegationInfo[] }>
  >({});

  const createSelectionChangeHandler = useCallback(
    (symbol: ERC721Symbol) => (selection: TokenDelegationInfo[]) => {
      setSelectedTokenMaps(curr => ({
        ...curr,
        [symbol]: selection
      }));
    },
    [setSelectedTokenMaps]
  );

  const availableTokensCount = useMemo(
    () =>
      flatMap(delegationMap, tokenInfos => tokenInfos.filter(info => info.delegate !== null))
        .length,
    [delegationMap]
  );
  const selectedTokensCount = useMemo(() => flatMap(selectedTokenMaps).length, [selectedTokenMaps]);

  const { revokeNftDelegation } = useNftDelegation();

  const handleRevoke = useCallback(() => {
    const delegations = flatMap(
      selectedTokenMaps,
      (tokenDelegations, symbol) =>
        tokenDelegations?.map(({ delegate, tokenId }) => ({
          symbol: symbol as ERC721Symbol,
          delegate: delegate!,
          tokenId
        })) ?? []
    );

    return revokeNftDelegation(delegations).finally(() => {
      refreshDelegationMap();
      setSelectedTokenMaps({});
    });
  }, [revokeNftDelegation, selectedTokenMaps, refreshDelegationMap]);

  const selectors = useMemo(() => {
    if (!delegationMapLoaded) {
      return [];
    }
    return map<
      ERC721Symbol,
      Maybe<{
        symbol: ERC721Symbol;
        tokens: TokenDelegationInfo[];
        collectionName: string;
      }>
    >(keys(delegationMap) as ERC721Symbol[], (symbol: ERC721Symbol) => {
      const tokens = delegationMap?.[symbol] ?? [];
      const delegatedTokens = tokens.filter(it => it.delegate !== null);
      const { collectionName } = nftInfoMap[symbol] ?? {};
      return (delegatedTokens?.length ?? 0) > 0
        ? { tokens: delegatedTokens, symbol, collectionName }
        : null;
    })
      .filter(notEmpty)
      .map(({ tokens, symbol, collectionName }, index) => (
        <CollapsableTokensSelector
          key={symbol}
          headerHint={`Delegated: ${formatNumber(tokens.length)}`}
          collectionName={collectionName}
          defaultOpen={index === 0}
          symbol={symbol}
          tokens={tokens}
          selectedTokens={selectedTokenMaps[symbol] ?? []}
          onSelectionChange={createSelectionChangeHandler(symbol)}
        />
      ));
  }, [
    delegationMap,
    delegationMapLoaded,
    createSelectionChangeHandler,
    selectedTokenMaps,
    nftInfoMap
  ]);

  const empty = selectors.length === 0;
  return (
    <Stack alignItems="center" {...props} inset="0 0 1.5rem">
      <Stack width="100%" gap="0.5rem">
        <H2>Manage Delegation</H2>
        <Text skin="secondary">
          Select NFTs to revoke delegation. Powered by{' '}
          <Link target="_blank" href="https://delegate.cash/">
            delegate.cash
          </Link>
        </Text>
      </Stack>
      {!delegationMapLoaded && <Spinner />}
      {selectors}
      {empty && delegationMapLoaded && (
        <EmptyState title="No delegated NFTs" description="No delegated NFTs to manage." />
      )}
      {!empty && delegationMapLoaded && (
        <FixedActionFooter
          selectedCount={selectedTokensCount}
          availableCount={availableTokensCount}
          onClick={handleRevoke}
          actionLabel="Revoke"
        />
      )}
    </Stack>
  );
});
