import { Stack, StackProps } from '@parallel-mono/components';
import { FC } from 'react';

import { CollectionRow } from '..';

import NftCard from './NftCard';

import { WalletType } from '@/apps/paraspace/typings';

export type CardForMobileProps = Omit<StackProps, 'children'> & {
  data: CollectionRow[];
  onSupply: (nft: { collectionName: string; symbol: string }, walletType: WalletType) => void;
  onSupplyFromOtherProtocols: (nft: { collectionName: string; symbol: string }) => void;
};

const CardForMobile: FC<CardForMobileProps> = ({
  data,
  onSupply,
  onSupplyFromOtherProtocols,
  ...others
}) => {
  return (
    <Stack gap="1rem" {...others}>
      {data.map((row, index) => (
        <NftCard
          key={row.symbol + index}
          data={row}
          onSupply={onSupply}
          onSupplyFromOtherProtocols={onSupplyFromOtherProtocols}
        />
      ))}
    </Stack>
  );
};

export default CardForMobile;
