import { useCallback, useMemo } from 'react';
import { Contract } from 'ethers';

import abi from './ABI.json';

import { useEOAProvider } from '@/apps/parax/contexts';

const useTokenFaucet = (customizedFaucetAddr: string) => {
  const { provider } = useEOAProvider();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new Contract(customizedFaucetAddr, abi, provider.getSigner());
  }, [customizedFaucetAddr, provider]);

  const mintTokenToAddress = useCallback(
    async (userAddress: string) => {
      if (!provider || !service) {
        return null;
      }
      const tx = await service.mint(userAddress);
      return tx;
    },
    [provider, service]
  );

  return { mintTokenToAddress };
};

export default useTokenFaucet;
