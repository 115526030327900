import { useCallback, useMemo } from 'react';
import { find, findKey, values } from 'lodash';

import { ERC20TokenConfigInfo } from '../../config';
import { Maybe } from '../../typings/basic';

import { useAppConfig } from '@/apps/paraspace/hooks';
import { ERC20Symbol } from '@/apps/paraspace/typings';

export const useIsNativeTokenCheck = () => {
  const { erc20Config } = useAppConfig();

  const nativeToken = useMemo(
    () => find(values(erc20Config), token => token.isNativeToken) as Maybe<ERC20TokenConfigInfo>,
    [erc20Config]
  );

  const checkIsNativeTokenSymbol = useCallback(
    (symbol: ERC20Symbol) => {
      const nativeTokenSymbol = findKey(erc20Config, token => token.isNativeToken) as ERC20Symbol;
      return symbol === nativeTokenSymbol;
    },
    [erc20Config]
  );

  return {
    checkIsNativeTokenSymbol,
    nativeToken
  };
};
