import { H6, Responsive, SmallText, Stack, TypographyVariant } from '@parallel-mono/components';
import styled from 'styled-components';
import { FC, useMemo } from 'react';

import { Logo } from '../Logo';
import { Link } from '../Link';
import {
  DEVELOPER_DOCS_LINK,
  DISCORD_URL,
  GITHUB_URL,
  TWITTER_URL
} from '../../consts/externalLinks';

import { useAppConfig, useDevice } from '@/apps/paraspace/hooks';

const StackWrapper = styled(Stack)`
  width: 100%;
  max-width: var(--max-app-width-pc);
  margin: 0 auto;
`;

const MainContent = styled(Responsive)`
  padding: 6rem;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    padding: 2rem 0;
  `}
`;

const Items = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  ${({ theme }) => theme.breakpoints.down('md')`
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
  `}
`;
const Container = styled(Responsive)`
  padding-top: 1.5rem;
  border-top: 2px solid rgba(238, 238, 238, 0.5);
`;

const InlineLogo = styled(Logo)`
  height: fit-content;
`;

export const Footer: FC = () => {
  const { isMobile } = useDevice();

  const { duneLink } = useAppConfig();

  const menus: [string, { name: string; href: string; target?: string }[]][] = useMemo(
    () => [
      [
        'Products',
        [
          {
            href: '/',
            name: 'NFT Money Market',
            target: '_self'
          }
        ]
      ],
      [
        'Community',
        [
          {
            href: TWITTER_URL,
            name: 'Twitter'
          },
          {
            href: DISCORD_URL,
            name: 'Discord'
          },
          {
            href: GITHUB_URL,
            name: 'Github'
          }
        ]
      ],
      [
        'Docs',
        [
          {
            href: `${DEVELOPER_DOCS_LINK}/paraspace-as-first-cross-margin-nft-lending-protocol`,
            name: 'Protocol Overview'
          },
          {
            href: `${DEVELOPER_DOCS_LINK}/risk-framework/asset-risk`,
            name: 'Risk Parameters'
          },
          {
            href: `${DEVELOPER_DOCS_LINK}/developer-docs`,
            name: 'Developers'
          },
          {
            href: duneLink,
            name: 'Dune'
          }
        ]
      ]
    ],
    [duneLink]
  );

  return (
    <StackWrapper gap="0">
      <MainContent gap={isMobile ? '2rem' : '12.25rem'}>
        <InlineLogo height="32" />
        <Items>
          {menus.map((menu, index) => {
            return (
              <Stack key={index} gap={isMobile ? '.25rem' : '1.5rem'}>
                <H6 fontWeight="medium">{menu[0]}</H6>
                <Stack gap={isMobile ? '.25rem' : '.75rem'} alignItems="baseline">
                  {(menu[1] as { href: string; name: string; target?: string }[]).map(
                    ({ href, name, target }, key) => {
                      return (
                        <Link
                          variant={TypographyVariant.bodySmall}
                          skin="secondary"
                          href={href}
                          key={key}
                          target={target || '_blank'}
                          underline={false}
                        >
                          {name}
                        </Link>
                      );
                    }
                  )}
                </Stack>
              </Stack>
            );
          })}
        </Items>
      </MainContent>
      <Container justifyContent="space-between" breakPoint="md">
        <SmallText skin="secondary">Parallel {new Date().getFullYear()}</SmallText>
        <SmallText skin="secondary">© All Rights Reserved</SmallText>
      </Container>
    </StackWrapper>
  );
};
