import styled from 'styled-components';
import {
  SelectableTokenInput,
  SelectableTokenInputProps
} from '@parallel-mono/business-components';

const classNames: SelectableTokenInputProps['classNames'] = {
  tokenSelect: {
    menu: 'Native-Token-Derivative-Select-menu'
  }
};

export const SelectableNativeTokenDerivativeInput = styled(SelectableTokenInput).attrs({
  classNames
})`
  width: 100%;

  .${classNames.tokenSelect?.menu} {
    left: auto;
    right: 0;
  }
`;
