import { memo, useMemo } from 'react';
import { groupBy, map } from 'lodash';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { StepperProps } from '@/apps/paraspace/components';
import { useNTokenService } from '@/apps/paraspace/pages/hooks/useNtokenService';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';

type FormData = {
  delegations: { symbol: ERC721Symbol; tokenId: number; delegate: string }[];
};

type NFTDelegationSubmitterProps = Omit<StepperProps, 'steps' | 'step' | 'onError'> & {
  formData: FormData;
  onFinish: () => void;
  onError: () => void;
};

export const RevokeNFTDelegationSubmitter = memo(
  ({ formData: { delegations }, onError, onFinish }: NFTDelegationSubmitterProps) => {
    const { revokeDelegateForTokens } = useNTokenService();
    const { nftInfoMap } = useMMProvider();

    const revokingTxs = useMemo(() => {
      const groupsToRevoke = map(
        groupBy(delegations, it => `${it.symbol}_${it.delegate}`),
        (groupMembers, groupName) => {
          const [symbol, delegate] = groupName.split('_');
          return {
            symbol: symbol as ERC721Symbol,
            delegate,
            tokenIds: groupMembers.map(it => it.tokenId)
          };
        }
      );

      const txs = groupsToRevoke.map(({ tokenIds, delegate, symbol }) =>
        revokeDelegateForTokens(tokenIds, delegate, nftInfoMap[symbol].xTokenAddress)
      );

      return txs;
    }, [delegations, revokeDelegateForTokens, nftInfoMap]);

    return (
      <BatchTransactionsSubmitter
        batchTxs={[{ tx: revokingTxs }]}
        onError={onError}
        onFinish={onFinish}
      />
    );
  }
);
