import { Card, CardProps, Collapse, Stack } from '@parallel-mono/components';
import { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { flatMap } from 'lodash';

import BorrowedPositionsHeader from '../BorrowedPositionsHeader';
import { ExpandToggle } from '../../StyledComponents';
import { BorrowPositionRow, Handlers } from '..';

import { ERC20Card } from './ERC20Card';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

const Header = styled(Stack)`
  position: relative;
`;

const StyledExpandToggle = styled(ExpandToggle)`
  position: absolute;
  top: -1.25rem;
  right: 0;
`;

export type BorrowPositionMobileProps = Omit<CardProps, 'children'> & {
  positions: BorrowPositionRow[];
  handlers: Handlers;
};

const BorrowedPositionsMobile: FC<BorrowPositionMobileProps> = ({
  positions,
  handlers,
  ...others
}) => {
  const {
    overviewUserInfo: { nftHealthFactor, healthFactor },
    nftInfoMap
  } = useMMProvider();

  const tokensInAuction = useMemo(
    () => flatMap(nftInfoMap, it => it.auctionedTokens).length,
    [nftInfoMap]
  );
  const nftsInCollateral = useMemo(
    () => flatMap(nftInfoMap, it => it.nftCollateralList).length,
    [nftInfoMap]
  );

  const {
    overviewUserInfo: {
      totalCollateralPositionInUsd,
      liquidationThresholdInUsd,
      borrowLimitInUsd,
      totalBorrowedPositionInUsd
    }
  } = useMMProvider();

  const [isOpen, setIsOpen] = useState(true);

  const toggleExpandable = useCallback(() => {
    setIsOpen(v => !v);
  }, [setIsOpen]);

  return (
    <Card border {...others}>
      <Stack gap="1rem">
        <Header>
          <BorrowedPositionsHeader
            borrowedValue={totalBorrowedPositionInUsd}
            borrowLimitValue={borrowLimitInUsd}
            liquidationPointValue={liquidationThresholdInUsd}
            totalSuppliedValue={totalCollateralPositionInUsd}
            healthFactor={healthFactor}
            nftHealthFactor={nftHealthFactor}
            tokensInAuction={tokensInAuction}
            nftsInCollateral={nftsInCollateral}
          />
          <StyledExpandToggle onClick={toggleExpandable} open={isOpen} />
        </Header>
        <Collapse open={isOpen}>
          <Stack gap="1rem">
            {positions.map((position, index) => (
              <ERC20Card
                key={position.symbol + index}
                data={position}
                onBorrow={handlers.handleBorrow}
              />
            ))}
          </Stack>
        </Collapse>
      </Stack>
    </Card>
  );
};

export default BorrowedPositionsMobile;
