import {
  Button,
  Card,
  CardProps,
  Collapse,
  H5,
  H6,
  Inline,
  Skeleton,
  SmallText,
  Stack,
  Tag,
  Text
} from '@parallel-mono/components';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';
import { memo, useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import { SupplyPositionsRow, SupplyPositionsRowType } from '../../types';
import { ExpandToggle } from '../../../StyledComponents';

import { TokensSection } from './TokensSection';
import { UniSwapV3TokensSection } from './UniSwapV3TokensSection/UniSwapV3TokensSection';

import { formatBalance } from '@/apps/paraspace/utils/format';
import { ERC721Symbol, NFTInfo, WalletType } from '@/apps/paraspace/typings';
import { SupplyERC721DropdownMenu } from '@/apps/paraspace/pages/Credit/components';
import { zero } from '@/apps/paraspace/consts/values';
import { DELISTED_ERC721_SYMBOLS } from '@/apps/paraspace/config';

type ERC721CardProps = Omit<CardProps, 'children'> & {
  data: SupplyPositionsRow;
  onSupply: (row: SupplyPositionsRow, walletType: WalletType) => void;
  onSupplyFromOtherProtocols: (row: SupplyPositionsRow) => void;
  onWithdraw: (row: SupplyPositionsRow) => void;
  onToggleCollateral: (arg: { symbol: string; tokenId: number }) => void;
  inLiquidation: boolean;
  walletBalanceMap: { AA: number; EOA: number };
  bendDaoBalance: number;
};

const TokensSectionContainer = styled(Collapse)`
  padding-top: 1rem;
`;

export const ERC721Card = memo((props: ERC721CardProps) => {
  const {
    data: { symbol, suppliedAmount, suppliedValue, cover, name, type },
    data,
    onSupply,
    onSupplyFromOtherProtocols,
    onWithdraw,
    inLiquidation,
    onToggleCollateral,
    walletBalanceMap: { AA: paraXBalance, EOA: EOABalance },
    bendDaoBalance,
    ...others
  } = props;

  const nftPosition = useMemo(() => data.position as unknown as NFTInfo, [data.position]);
  const isAllCollateral =
    nftPosition.nftSuppliedList?.length === nftPosition.nftCollateralList?.length;

  const [isOpen, setIsOpen] = useState(false);

  const toggleExpandable = useCallback(() => {
    setIsOpen(v => !v);
  }, [setIsOpen]);

  const handleSupply = useCallback(
    (walletType: WalletType) => {
      onSupply(data, walletType);
    },
    [onSupply, data]
  );

  const handleWithdraw = useCallback(() => {
    onWithdraw(data);
  }, [onWithdraw, data]);

  const handleToggleItemCollateral = useCallback(
    (tokenId: number) => {
      onToggleCollateral({
        symbol,
        tokenId
      });
    },
    [onToggleCollateral, symbol]
  );

  const showInLiquidationTag = useMemo(
    () =>
      type === SupplyPositionsRowType.ERC721 && !isEmpty(nftPosition.auctionedTokens) && !isOpen,
    [isOpen, nftPosition.auctionedTokens, type]
  );

  return (
    <Card border {...others}>
      <Stack gap="1rem">
        <Inline justifyContent="space-between" alignItems="center">
          <Inline gap="0.5rem" alignItems="center">
            {cover}
            <Stack gap="0">
              <H5>{name}</H5>
              {showInLiquidationTag && (
                <Tag skin="error" size="small">
                  In Liquidation
                </Tag>
              )}
            </Stack>
          </Inline>
          <ExpandToggle onClick={toggleExpandable} open={isOpen} />
        </Inline>
        <Inline justifyContent="space-between">
          <H6 skin="secondary" fontWeight="medium">
            Supplied
          </H6>
          <Stack gap="0" alignItems="flex-end">
            <Text>{suppliedAmount ? formatBalance(suppliedAmount) : '-'}</Text>
            {suppliedValue?.gt(zero) ? (
              <SmallText skin="secondary">${formatNumber(suppliedValue ?? 0)}</SmallText>
            ) : (
              <Skeleton.Button height="1rem" width="2rem" />
            )}
          </Stack>
        </Inline>
        <Inline justifyContent="space-between">
          {!DELISTED_ERC721_SYMBOLS.includes(data.symbol as ERC721Symbol) && (
            <SupplyERC721DropdownMenu
              placement="bottom-start"
              menuTrigger={<Button skin="secondary">Supply</Button>}
              paraXWalletOption={{
                onClick: () => handleSupply('AA'),
                balance: paraXBalance
              }}
              EOAWalletOption={{
                onClick: () => handleSupply('EOA'),
                balance: EOABalance
              }}
              otherProtocolOption={{
                onClick: () => onSupplyFromOtherProtocols(data),
                balance: bendDaoBalance
              }}
            />
          )}

          <Button
            skin="secondary"
            block
            disabled={inLiquidation && isAllCollateral}
            onClick={handleWithdraw}
          >
            Withdraw
          </Button>
        </Inline>
      </Stack>
      <TokensSectionContainer open={isOpen}>
        {symbol === ERC721Symbol.UNISWAP_LP ? (
          <UniSwapV3TokensSection
            symbol={symbol}
            inLiquidation={inLiquidation}
            onToggleCollateral={handleToggleItemCollateral}
          />
        ) : (
          <TokensSection
            inLiquidation={inLiquidation}
            symbol={symbol as ERC721Symbol}
            onToggleCollateral={handleToggleItemCollateral}
          />
        )}
      </TokensSectionContainer>
    </Card>
  );
});
