import { TokenInput } from '@parallel-mono/business-components';
import { useCallback, useMemo, useState } from 'react';
import { Card, H4, useBreakpoints } from '@parallel-mono/components';
import { ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { every, isNil, memoize } from 'lodash';
import BigNumberJs from 'bignumber.js';

import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { ProjectedRewardsBox } from '@/apps/paraspace/pages/ApePairing/pages/Calculator/components/ProjectedRewardsBox';
import { EstimatedProfitsChart } from '@/apps/paraspace/pages/ApePairing/pages/Calculator/components/EstimatedProfitsChart';
import { useParaspaceApeCoinRewardsInfo } from '@/apps/paraspace/pages/ApePairing/pages/Calculator/hooks/useParaspaceApeCoinRewardsInfo';
import { useCustomizedApeCoinRewardsInfo } from '@/apps/paraspace/pages/ApePairing/pages/Calculator/hooks/useCustomizedApeCoinRewardsInfo';
import { useOfficialRewardsInfo } from '@/apps/paraspace/pages/ApePairing/pages/Calculator/hooks/useOfficialRewardsInfo';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { DAYS_OF_YEAR } from '@/apps/paraspace/consts/fixtures';

const FullWidthInput = styled(TokenInput)`
  width: 100%;
`;

export const ApeCoinCalculator = () => {
  const [stakedAmount, setStakedAmount] = useState(1000);
  const [inputRawApr, setInputRawApr] = useState(0.25);

  const { mobile } = useBreakpoints();

  const handleAmountChange = useCallback(newAmount => {
    setStakedAmount(newAmount || 0);
  }, []);

  const navigate = useNavigate();
  const handleNav = useCallback(() => {
    navigate(absoluteRouteNames.APE_STAKING.COIN_POOL.index);
  }, [navigate]);

  const handleInputRawAprChange = useCallback((newApr: number) => {
    setInputRawApr(newApr || 0);
  }, []);

  const paraspaceApeCoinRewardsInfo = useParaspaceApeCoinRewardsInfo(stakedAmount);
  const customizedApeCoinRewardsInfo = useCustomizedApeCoinRewardsInfo(stakedAmount, inputRawApr);
  const officialApeCoinRewardsInfo = useOfficialRewardsInfo(stakedAmount, ERC20Symbol.APE);

  const loadingCalculationParams = useMemo(
    () =>
      every(paraspaceApeCoinRewardsInfo, v => isNil(v)) ||
      every(customizedApeCoinRewardsInfo, v => isNil(v)) ||
      every(officialApeCoinRewardsInfo, v => isNil(v)),
    [paraspaceApeCoinRewardsInfo, customizedApeCoinRewardsInfo, officialApeCoinRewardsInfo]
  );

  const graphProfitFormula = useMemo(() => {
    if (!stakedAmount || loadingCalculationParams) {
      return {
        paraSpaceEstimatedProfitsByDay: null,
        anyAPREstimatedProfitsByDay: null,
        horizenLabsEstimatedProfitsByDay: null
      };
    }

    const memorizedCalc = memoize((targetDay: number): BigNumberJs => {
      if (targetDay <= 0) {
        return new BigNumberJs(0);
      }

      const accumulatedProfitTillYesterday = memorizedCalc(targetDay - 1);
      const principal = accumulatedProfitTillYesterday.plus(stakedAmount);
      const dailyInterest = officialApeCoinRewardsInfo.officialApr!.div(DAYS_OF_YEAR);
      const todayProfit = dailyInterest.times(principal);
      return todayProfit.plus(accumulatedProfitTillYesterday);
    });
    const paraSpaceEstimatedProfitsByDay = (day: number) => {
      return memorizedCalc(day).toNumber();
    };
    const anyAPREstimatedProfitsByDay = (day: number) =>
      stakedAmount *
      customizedApeCoinRewardsInfo.customizedApr!.times(day).div(DAYS_OF_YEAR).toNumber();
    const horizenLabsEstimatedProfitsByDay = (day: number) =>
      stakedAmount *
      officialApeCoinRewardsInfo.officialApr!.times(day).div(DAYS_OF_YEAR).toNumber();

    return {
      paraSpaceEstimatedProfitsByDay,
      anyAPREstimatedProfitsByDay,
      horizenLabsEstimatedProfitsByDay
    };
  }, [
    customizedApeCoinRewardsInfo.customizedApr,
    officialApeCoinRewardsInfo.officialApr,
    stakedAmount,
    loadingCalculationParams
  ]);

  return (
    <div>
      <FullWidthInput
        label={<H4>Stake Amount</H4>}
        token="APE"
        value={stakedAmount}
        onChange={handleAmountChange}
        decimals={MAXIMUM_BALANCE_DECIMALS}
      />

      <ProjectedRewardsBox
        paraspaceRewardsInfo={paraspaceApeCoinRewardsInfo}
        customizedRewardsInfo={customizedApeCoinRewardsInfo}
        officialRewardsInfo={officialApeCoinRewardsInfo}
        inputRawApr={inputRawApr}
        handleInputRawAprChange={handleInputRawAprChange}
        handleNav={handleNav}
      />

      <Card border>
        <ResponsiveContainer width="100%" aspect={mobile ? 1 : 2.2}>
          <EstimatedProfitsChart loading={loadingCalculationParams} {...graphProfitFormula} />
        </ResponsiveContainer>
      </Card>
    </div>
  );
};
