import {
  ColorMode,
  Icon,
  IconProps,
  useCallbackMerge,
  useThemeConfig
} from '@parallel-mono/components';
import { memo, useCallback } from 'react';
import styled from 'styled-components';

import { COLOR_MODE_STORAGE_KEY } from '@/apps/parax/config';

type ColorModeSwitchProps = Omit<IconProps, 'name'>;

const ColorModeSwitchIcon = styled(Icon)`
  cursor: pointer;
  flex: 0 0 1.25rem;
`;

export const ColorModeSwitch = memo(({ onClick, ...others }: ColorModeSwitchProps) => {
  const { colorMode, changeColorMode } = useThemeConfig();

  const handleSwitchColorMode = useCallback(() => {
    const colorModeToBe = colorMode === ColorMode.light ? ColorMode.dark : ColorMode.light;
    changeColorMode(colorModeToBe);
    localStorage.setItem(COLOR_MODE_STORAGE_KEY, colorModeToBe);
  }, [colorMode, changeColorMode]);

  const handleIconClicked = useCallbackMerge(handleSwitchColorMode, onClick);

  return (
    <ColorModeSwitchIcon
      size="20px"
      data-escape-transfer-assets
      name={colorMode === ColorMode.dark ? 'moon' : 'sun'}
      onClick={handleIconClicked}
      {...others}
    />
  );
});
