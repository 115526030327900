import { createContext, memo, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';

import { UniSwapV3SupplyModal, UniSwapV3SupplyModalProps } from './UniSwapV3SupplyModal';

import { ERC20Symbol, WalletType } from '@/apps/paraspace/typings';

type SupplyUniSwapV3ProviderProps = {
  children: ReactNode;
};

type SupplyUniSwapV3ContextValue = {
  supplyUniSwapV3: (pair: [ERC20Symbol, ERC20Symbol], walletType: WalletType) => Promise<void>;
};

const SupplyUniSwapV3Context = createContext<SupplyUniSwapV3ContextValue>({
  supplyUniSwapV3: () => {
    throw new Error('not implemented yet');
  }
});

const defaultModalProps: UniSwapV3SupplyModalProps = {
  isOpen: false,
  pair: [ERC20Symbol.ETH, ERC20Symbol.ETH],
  walletType: 'AA',
  onClose: noop
};

export const SupplyUniSwapV3Provider = memo(({ children }: SupplyUniSwapV3ProviderProps) => {
  const [modalProps, setModalProps] = useState<UniSwapV3SupplyModalProps>(defaultModalProps);

  const supplyUniSwapV3 = useCallback(
    (pair: [ERC20Symbol, ERC20Symbol], walletType: WalletType) => {
      return new Promise<void>((resolve, reject) => {
        setModalProps({
          isOpen: true,
          pair,
          walletType,
          onFinish: resolve,
          onError: reject,
          onClose: () => {
            setModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
          }
        });
      });
    },
    [setModalProps]
  );

  const contextValue = useMemo(() => ({ supplyUniSwapV3 }), [supplyUniSwapV3]);

  return (
    <SupplyUniSwapV3Context.Provider value={contextValue}>
      <UniSwapV3SupplyModal {...modalProps} />
      {children}
    </SupplyUniSwapV3Context.Provider>
  );
});

export const useSupplyUniswapV3 = () => useContext(SupplyUniSwapV3Context).supplyUniSwapV3;
