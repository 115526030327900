import { memo, useCallback } from 'react';
import { EthereumTransactionTypeExtended } from 'paraspace-utilities-contract-helpers';
import { isEmpty } from 'lodash';

import { ErrorConfig } from '../utils/getUserFriendlyError';
import { Maybe } from '../typings/basic';

import { FormSubmitter } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';

type TxSubmitted = {
  tx:
    | Maybe<EthereumTransactionTypeExtended>
    | Promise<Maybe<EthereumTransactionTypeExtended>>
    | Maybe<EthereumTransactionTypeExtended[]>
    | Promise<Maybe<EthereumTransactionTypeExtended[]>>
    | Promise<(EthereumTransactionTypeExtended | null)[] | null>;
  skip?: boolean;
};

type BatchTransactionSubmitterProps = {
  inProgressMessage?: string;
  batchTxs: TxSubmitted[];
  onFinish: () => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const BatchTransactionsSubmitter = memo(
  ({ inProgressMessage, batchTxs, onError, onFinish }: BatchTransactionSubmitterProps) => {
    const { submitTransactions } = useWeb3Context();

    const submit = useCallback(async () => {
      const filteredTransactions = batchTxs.filter(({ skip }) => !skip).map(({ tx }) => tx);
      const instancedTxs = (await Promise.all(filteredTransactions))
        .flatMap(txOrTxs => (Array.isArray(txOrTxs) ? txOrTxs : [txOrTxs]))
        .filter(notEmpty);

      if (isEmpty(instancedTxs)) {
        return null;
      }
      return submitTransactions(instancedTxs);
    }, [batchTxs, submitTransactions]);

    return (
      <FormSubmitter
        submit={submit}
        onError={onError}
        onFinish={onFinish}
        inProgressMessage={inProgressMessage}
      />
    );
  }
);
