import { Provider } from '@web3-react/types';

import { EIP6963ProviderDetail } from './types';

const isDataURI = (uri: string): boolean => {
  return /data:(image\/[-+\w.]+)(;?\w+=[-\w]+)*(;base64)?,.*/gu.test(uri);
};

const isEip1193Provider = (value: any): value is Provider => {
  return Boolean(value.request && value.on && value.removeListener);
};

export const isEIP6963ProviderDetail = (value: any): value is EIP6963ProviderDetail => {
  return Boolean(
    value.provider &&
      isEip1193Provider(value.provider) &&
      value.info &&
      value.info.name &&
      value.info.uuid &&
      value.info.rdns &&
      value.info.icon &&
      isDataURI(value.info.icon)
  );
};
