import { useCallback } from 'react';
import BigNumberJs from 'bignumber.js';
import { ConsiderationInputItem, CreateInputItem } from 'paraspace-seaport-js/lib/types';
import { ItemType } from 'paraspace-seaport-js/lib/constants';
import dayjs from 'dayjs';

import { Duration, TokenInputValue } from './types';

import { OrderSide } from '@/apps/paraspace/consts/order';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { InputProtocolData, useCreateOrderMutation } from '@/apps/paraspace/generated/graphql';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import useLegacyERC20 from '@/apps/paraspace/pages/hooks/useLegacyERC20';
import useLegacyERC721 from '@/apps/paraspace/pages/hooks/useLegacyERC721';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { shiftedRightBy } from '@/apps/paraspace/utils/calculations';
import { convertListOrderTo14Compatible } from '@/apps/paraspace/utils/listOrderFormater';
import useSeaport from '@/apps/paraspace/pages/hooks/useSeaport';
import { CollectionFees } from '@/apps/paraspace/utils/calculateFees';
import { useAppConfig, useContractsMap } from '@/apps/paraspace/hooks';

export const useUpdateOrder = ({
  symbol,
  tokenId,
  collectionAddress,
  selectedTokenValue,
  fees,
  duration
}: {
  symbol: ERC721Symbol;
  tokenId: number;
  collectionAddress: string;
  selectedTokenValue: TokenInputValue;
  fees?: CollectionFees;
  duration: Duration;
}) => {
  const { account } = useWeb3Context();
  const { erc20InfoMap, nftInfoMap } = useMMProvider();
  const { erc20Config } = useAppConfig();
  const { seaportSDK } = useSeaport();

  const contracts = useContractsMap();

  const { isApproved, approve } = useLegacyERC721(nftInfoMap[symbol]?.xTokenAddress);
  const { getAllowance: getErc20Allowance, approve: approveErc20 } = useLegacyERC20(
    erc20InfoMap?.[selectedTokenValue.token.symbol]?.address
  );

  const [createOrderMutation] = useCreateOrderMutation({
    refetchQueries: ['getShopListings']
  });

  return useCallback(async () => {
    if (!account) {
      return;
    }
    try {
      const hasApproved = await isApproved({
        user: account,
        spender: contracts?.Conduit,
        tokenId: tokenId.toString()
      });
      if (!hasApproved) {
        await approve({
          user: account,
          spender: contracts?.Conduit,
          tokenId: tokenId.toString()
        });
      }

      const currentEthAllowance = new BigNumberJs(await getErc20Allowance());

      if (currentEthAllowance.lt(selectedTokenValue.amount ?? 0)) {
        await approveErc20();
      }

      // timestamp in second
      const startTime = dayjs().unix();
      const endTime = dayjs.unix(startTime).add(duration.value, 'day').unix();
      const selectedSymbol = selectedTokenValue.token.symbol;
      const amount = shiftedRightBy(
        BigNumberJs(selectedTokenValue.amount ?? 0),
        erc20Config[selectedSymbol].decimals
      ).toString();

      const offer = [
        {
          token: collectionAddress,
          itemType: ItemType.ERC721,
          identifier: tokenId.toString()
        } as CreateInputItem
      ];
      const consideration = [
        {
          amount,
          recipient: account
        } as ConsiderationInputItem
      ];

      if (seaportSDK) {
        const { executeAllActions } = await seaportSDK.createOrder(
          {
            startTime: `${startTime}`,
            endTime: `${endTime}`,
            offer,
            offerer: account,
            consideration,
            conduitKey: contracts.ConduitKey,
            fees: fees
              ? [
                  {
                    recipient: fees.paraSpaceFeeAddress,
                    basisPoints: fees.paraSpaceFeePoint
                  },

                  { recipient: fees.creatorFeeAddress, basisPoints: fees.creatorFeePoint }
                ].filter(fee => fee.recipient && fee.basisPoints)
              : []
          },
          account
        );

        const order = await executeAllActions();
        await createOrderMutation({
          variables: {
            inputOrder: {
              side: OrderSide.Consideration,
              // TODO: update once paraspace seaport has migrated to v1.4
              protocolVersion: '1.1',
              protocolContract: contracts.Seaport,
              protocolData: convertListOrderTo14Compatible(order) as InputProtocolData
            }
          }
        });
      }
    } catch (e) {
      console.error(`Update listing failed: ${e}`);
      throw e;
    }
  }, [
    isApproved,
    account,
    contracts?.Conduit,
    contracts.ConduitKey,
    contracts.Seaport,
    tokenId,
    getErc20Allowance,
    selectedTokenValue.amount,
    selectedTokenValue?.token.symbol,
    duration.value,
    erc20Config,
    collectionAddress,
    seaportSDK,
    approve,
    approveErc20,
    fees,
    createOrderMutation
  ]);
};
