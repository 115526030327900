import { memo, useCallback } from 'react';

import { FormSubmitter } from '../FormSubmitter';

import { useTransfer } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';

export const DepositERC721Submitter = memo(
  ({
    formData: { tokenId, address },
    onError,
    onFinish
  }: {
    formData: { tokenId: number; address: string };
    onError: () => void;
    onFinish: () => void;
  }) => {
    const {
      account,
      eoaAccount,
      authentication: {
        meta: { provider }
      }
    } = useWeb3Context();

    const { genTxDataTransferERC721 } = useTransfer();

    const submit = useCallback(
      () =>
        provider
          .getSigner()
          .sendTransaction(genTxDataTransferERC721(eoaAccount, account, address, tokenId)),
      [provider, genTxDataTransferERC721, eoaAccount, account, address, tokenId]
    );

    return (
      <FormSubmitter
        submit={submit}
        onError={onError}
        onFinish={onFinish}
        inProgressMessage="Deposit ERC721"
      />
    );
  }
);
