import {
  Card,
  H3,
  Icon,
  Inline,
  Pagination,
  Spinner,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { ApolloClient } from '@apollo/client';

import { useAccountLiquidationStatus } from '../hooks/useAccountLiquidationStatus';
import { usePagination } from '../../hooks/usePagination';

import { InLiquidationGrid } from './InLiquidationGrid';
import { InLiquidationAsset, InLiquidationRow } from './types';
import { useLiquidationDashboardActions } from './hooks';

import { useParallelToast, useWeb3Context } from '@/apps/paraspace/contexts';
import { LiquidationBuyModal } from '@/apps/paraspace/components';
import { shiftedRightBy } from '@/apps/paraspace/utils/calculations';

type LiquidationPageProps = {
  loading: boolean;
  liquidations: InLiquidationRow[];
  client: ApolloClient<any>;
} & Omit<StackProps, 'children'>;

const PageContainer = styled(Stack)`
  min-height: 25rem;
`;

const NoDataMessage = styled(Card).attrs({ border: true })`
  text-align: center;
  padding: 11.5rem;
`;

const PAGE_SIZE = 10;

export const LiquidationPage = memo(
  ({ loading, liquidations, client, ...others }: LiquidationPageProps) => {
    const parallelToast = useParallelToast();
    const [buyingAssets, setBuyingAsset] = useState<string[]>([]);

    const { inLiquidation } = useAccountLiquidationStatus();
    const [liquidationBuyModalPayload, setLiquidationBuyModalPayload] = useState<null | {
      user: string;
      asset: InLiquidationAsset;
    }>(null);

    const { closeLiquidation, triggerAuction } = useLiquidationDashboardActions(client);
    const { isUsingUserWallet, connectWallet } = useWeb3Context();

    const { currentPage, setCurrentPage, totalPage, pageData } = usePagination(
      liquidations,
      PAGE_SIZE
    );

    const handleCloseLiquidation = useCallback(
      (walletAddress: string) => {
        if (!isUsingUserWallet) {
          connectWallet();
          return null;
        }
        return parallelToast.promise(closeLiquidation(walletAddress));
      },
      [isUsingUserWallet, parallelToast, closeLiquidation, connectWallet]
    );

    const handleTriggerAuction = useCallback(
      (data: { user: string; asset: InLiquidationAsset }) => {
        if (!isUsingUserWallet) {
          connectWallet();
          return null;
        }
        return parallelToast.promise(triggerAuction(data));
      },
      [isUsingUserWallet, parallelToast, triggerAuction, connectWallet]
    );

    const handleBuyAsset = useCallback(
      async (data: { user: string; asset: InLiquidationAsset }) => {
        setLiquidationBuyModalPayload(data);
      },
      []
    );

    return (
      <PageContainer width="100%" {...others}>
        {!loading && liquidations.length === 0 && (
          <NoDataMessage>
            <Stack alignItems="center">
              <Icon name="boxOpen" size="5rem" />
              <Stack gap="0.25rem">
                <H3 fontWeight="bold">Nothing in Liquidation</H3>
                <Text skin="secondary">Check back later for updates.</Text>
              </Stack>
            </Stack>
          </NoDataMessage>
        )}
        {loading ? (
          <Inline width="100%" justifyContent="center">
            <Spinner />
          </Inline>
        ) : (
          pageData.map(liquidation => (
            <InLiquidationGrid
              buyingAssets={buyingAssets}
              inLiquidation={inLiquidation}
              onCloseLiquidation={handleCloseLiquidation}
              onTriggerAuction={handleTriggerAuction}
              onBuyAsset={handleBuyAsset}
              key={liquidation.address}
              profile={{
                walletAddress: liquidation.address!
              }}
              isPaused={liquidation.isPaused}
              collateral={liquidation.collateral!}
              debt={liquidation.debt!}
              healthFactor={liquidation.healthFactor!}
              nftHealthFactor={liquidation.nftHealthFactor!}
              assets={liquidation.assets as InLiquidationAsset[]}
            />
          ))
        )}
        {totalPage > 1 && (
          <Pagination
            total={totalPage}
            page={currentPage}
            onChange={setCurrentPage}
            siblingCount={0}
            startBoundaryCount={3}
          />
        )}
        {liquidationBuyModalPayload && (
          <LiquidationBuyModal
            isOpen={!!liquidationBuyModalPayload}
            onUpdateBuyingAssets={setBuyingAsset}
            closeModal={() => {
              setLiquidationBuyModalPayload(null);
            }}
            data={{
              tokenId: `${liquidationBuyModalPayload.asset.identifierOrCriteria}`,
              address: liquidationBuyModalPayload.asset.contractAddress,
              name: liquidationBuyModalPayload.asset.collectionName,
              currentPrice: liquidationBuyModalPayload.asset.buyNowPrice.toString(),
              currentPriceInUsd: liquidationBuyModalPayload.asset.buyNowPriceInUsd.toNumber(),
              traitMultiplier: liquidationBuyModalPayload.asset.traitMultiplier,
              buyParameters: {
                user: liquidationBuyModalPayload.user,
                buyNowPrice: shiftedRightBy(liquidationBuyModalPayload.asset.buyNowPrice, 18),
                floorPrice: shiftedRightBy(liquidationBuyModalPayload.asset.floorPrice, 18)
              }
            }}
          />
        )}
      </PageContainer>
    );
  }
);
