import { useCallback, useMemo, useState } from 'react';
import { Alert, Button, H5, Inline, Stack, Text } from '@parallel-mono/components';
import { InfoPanel, TokenInput } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';
import { floor } from 'lodash';
import { formatNumber } from '@parallel-mono/utils';

import { useApeBorrowLimit } from '../../hooks/useApeBorrowLimit';
import { NetApyExplanation } from '../../component/NetApyExplanation';
import { StakeApeFormData, StakeApeInfo } from '..';

import { validateCreditInput, validateForm, validateWalletInput } from './validation';

import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { NumberRange, NFTThumbnail } from '@/apps/paraspace/components';
import { MAXIMUM_BALANCE_DECIMALS, MAX_POOL_UTILIZATION } from '@/apps/paraspace/pages/config';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { zero } from '@/apps/paraspace/consts/values';
import { formatToPercentage } from '@/apps/paraspace/utils/format';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';

type StakeApeFormProps = {
  apeInfo: StakeApeInfo;
  onSubmit: (formData: StakeApeFormData) => void;
};

export const StakeApeForm = ({ apeInfo, onSubmit }: StakeApeFormProps) => {
  const { stakeLimit, stakedAmount, symbol, tokenId, supplied, apeCoinSource, apeSource } = apeInfo;
  const { erc20InfoMap } = useMMProvider();
  const { erc20BalanceMap } = useEOABalances();
  const eoaBalance = erc20BalanceMap?.[ERC20Symbol.APE] ?? zero;
  const { balance: aaBalance = zero } = erc20InfoMap.APE;

  const walletBalance = apeCoinSource === 'AA' ? aaBalance : eoaBalance;

  const { availableLiquidity, totalDebt } = erc20InfoMap.CAPE;

  const [borrowAmount, setBorrowAmount] = useState<number | null>(null);
  const [walletAmount, setWalletAmount] = useState<number | null>(null);

  const totalAmount = useMemo(
    () => (walletAmount ?? 0) + (borrowAmount ?? 0),
    [borrowAmount, walletAmount]
  );

  const currentStakeLimit = useMemo(
    () => stakeLimit.minus(stakedAmount),
    [stakeLimit, stakedAmount]
  );

  const borrowLimit = useApeBorrowLimit({
    symbol,
    tokenId,
    balance: walletAmount
  });

  // temporary restrictions on ape borrowing
  const restrictToBorrowApe = totalDebt
    .div(availableLiquidity.plus(totalDebt))
    .gte(MAX_POOL_UTILIZATION);
  const availableToBorrowApe = useMemo(
    () => (restrictToBorrowApe ? zero : borrowLimit),
    [borrowLimit, restrictToBorrowApe]
  );

  const borrowMaxAmount = useMemo(
    () =>
      BigNumber.max(
        BigNumber.min(
          currentStakeLimit.minus(walletAmount ?? 0),
          availableToBorrowApe,
          availableLiquidity
        ).toNumber(),
        zero
      ).toNumber(),
    [currentStakeLimit, walletAmount, availableToBorrowApe, availableLiquidity]
  );

  const handleBorrowValueChange = useCallback((value: number | null) => {
    setBorrowAmount(value);
  }, []);

  const onBorrowMaxClick = useCallback(() => {
    setBorrowAmount(floor(borrowMaxAmount, MAXIMUM_BALANCE_DECIMALS));
  }, [borrowMaxAmount]);

  const borrowInputError = useMemo(
    () =>
      validateCreditInput({
        amount: borrowAmount,
        availableLiquidity,
        borrowLimit,
        restrictToBorrowApe
      }),
    [borrowAmount, availableLiquidity, borrowLimit, restrictToBorrowApe]
  );

  const walletInputError = useMemo(
    () =>
      validateWalletInput({
        amount: walletAmount,
        walletBalance
      }),
    [walletAmount, walletBalance]
  );

  const formError = useMemo(
    () => validateForm({ borrowAmount, walletAmount, stakedAmount, stakeLimit }),
    [borrowAmount, walletAmount, stakedAmount, stakeLimit]
  );

  const walletMaxAmount = useMemo(
    () =>
      BigNumber.max(
        BigNumber.min(walletBalance, currentStakeLimit.minus(borrowAmount ?? 0)).toNumber(),
        zero
      ).toNumber(),
    [currentStakeLimit, walletBalance, borrowAmount]
  );

  const handleWalletValueChange = useCallback((value: number | null) => {
    setWalletAmount(value);
  }, []);

  const onWalletMaxClick = useCallback(() => {
    setWalletAmount(floor(walletMaxAmount, MAXIMUM_BALANCE_DECIMALS));
  }, [walletMaxAmount]);

  const disableStake = useMemo(
    () => !!borrowInputError || !!walletInputError || !!formError || !totalAmount,
    [borrowInputError, formError, totalAmount, walletInputError]
  );

  const { effectiveCapeBorrowApy, nftPoolsCompoundApy } = useAutoCompoundApeInfo();
  const compoundAPY = nftPoolsCompoundApy?.[symbol] ?? zero;

  const netApyRate = useMemo(
    () =>
      compoundAPY
        .times(totalAmount)
        .minus((effectiveCapeBorrowApy ?? zero).times(borrowAmount ?? 0))
        .div(totalAmount),
    [borrowAmount, compoundAPY, effectiveCapeBorrowApy, totalAmount]
  );

  const panelInfo = useMemo(() => {
    return [
      {
        title: 'Total Staked',
        value: (
          <NumberRange
            start={stakedAmount.toNumber()}
            end={stakedAmount
              .plus(borrowAmount ?? 0)
              .plus(walletAmount ?? 0)
              .toNumber()}
            formatter={formatNumber}
            symbol="APE"
          />
        )
      },
      {
        title: 'Net APY',
        value: formatToPercentage(netApyRate),
        tip: (
          <NetApyExplanation
            amountFromBalance={walletAmount}
            amountFromCredit={borrowAmount}
            rewardApyRate={compoundAPY?.toNumber() ?? 0}
            borrowApyRate={effectiveCapeBorrowApy?.toNumber() ?? 0}
            netApyRate={netApyRate.toNumber()}
          />
        )
      }
    ];
  }, [stakedAmount, borrowAmount, walletAmount, compoundAPY, effectiveCapeBorrowApy, netApyRate]);

  const handleConfirm = useCallback(() => {
    onSubmit({
      type: symbol,
      tokenId,
      borrowAmount: Number(borrowAmount),
      cashAmount: Number(walletAmount),
      supplied,
      apeCoinSource,
      apeSource
    });
  }, [borrowAmount, onSubmit, supplied, symbol, tokenId, walletAmount, apeCoinSource, apeSource]);

  return (
    <Stack gap="1.5rem" alignItems="center">
      <NFTThumbnail size="xLarge" symbol={symbol} tokenId={tokenId} showDescription />
      <TokenInput
        width="100%"
        label={<H5>Wallet</H5>}
        hint={
          <Inline gap=".3rem">
            <Text skin="secondary">Balance: {formatNumber(walletBalance)} APE</Text>
          </Inline>
        }
        token="APE"
        placeholder="0"
        value={walletAmount}
        decimals={MAXIMUM_BALANCE_DECIMALS}
        onChange={handleWalletValueChange}
        error={walletInputError}
        actionButtonText="Max"
        onAction={onWalletMaxClick}
        inputProps={{
          inputProps: {
            autoFocus: true
          }
        }}
      />
      <TokenInput
        label={<H5>Borrow from $cAPE pool</H5>}
        width="100%"
        hint={
          <Inline gap=".3rem">
            <Text skin="secondary">Max: {formatNumber(borrowMaxAmount)} cAPE</Text>
          </Inline>
        }
        token={ERC20Symbol.CAPE}
        placeholder="0"
        value={borrowAmount}
        decimals={MAXIMUM_BALANCE_DECIMALS}
        onChange={handleBorrowValueChange}
        error={borrowInputError}
        actionButtonText="Max"
        onAction={onBorrowMaxClick}
      />
      <InfoPanel skin="primary" infos={panelInfo} width="100%" />
      {formError && (
        <Alert type="error" width="100%">
          {formError}
        </Alert>
      )}
      <Button block size="large" disabled={disableStake} onClick={handleConfirm}>
        Confirm
      </Button>
    </Stack>
  );
};
