import { FC } from 'react';
import styled from 'styled-components';
import { Stack, Image } from '@parallel-mono/components';

import { ConnectWallet } from '@/apps/paraspace/components';
import noNFT from '@/apps/paraspace/assets/no-nft.png';

type Props = {
  close: () => void;
};

const Container = styled(Stack)`
  margin-top: 0.5rem;
`;

const NotConnectedSupplyModal: FC<Props> = ({ close }) => {
  return (
    <Container gap="2rem" alignItems="center">
      <Image src={noNFT} width="10rem" height="11.25rem" />
      <ConnectWallet btnTxt="Connect Wallet to Begin" onConnectWallet={close} />
    </Container>
  );
};

export default NotConnectedSupplyModal;
