import { mapValues } from 'lodash';
import { useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { useEOABalances } from '../../contexts';
import { StakeFishInfo } from '../../contexts/utils';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';
import { ERC721Symbol, NFTInfo, StakeFishState } from '@/apps/paraspace/typings';

export type WalletBalanceMap = {
  EOA: Record<ERC721Symbol, []>;
  AA: Record<ERC721Symbol, []>;
};

const filterEoaStakefishNFTBalance = (nft: {
  balance: number[];
  tokenTraitMultipliers: Record<string, BigNumber>;
  stakeFishInfoMap: Record<string, StakeFishInfo>;
}): number[] => {
  return (
    nft.balance?.filter(tokenId =>
      [StakeFishState.PostDeposit, StakeFishState.Active].includes(
        nft.stakeFishInfoMap?.[tokenId]?.state
      )
    ) ?? []
  );
};

const filterStakefishNFTBalance = (nft: NFTInfo): number[] => {
  return (
    nft.balance?.filter(tokenId =>
      [StakeFishState.PostDeposit, StakeFishState.Active].includes(
        nft.tokenSpecificInfos?.[tokenId]?.state
      )
    ) ?? []
  );
};

export const useWalletBalanceMap = (): WalletBalanceMap => {
  const { nftInfoMap } = useMMProvider();
  const { erc721BalanceMap } = useEOABalances();

  const walletBalanceMap = useMemo(
    () =>
      ({
        EOA: mapValues(erc721BalanceMap, (it, symbol) =>
          symbol === ERC721Symbol.SF_VLDR ? filterEoaStakefishNFTBalance(it) : it.balance ?? []
        ),
        AA: mapValues(nftInfoMap, it =>
          it.symbol === ERC721Symbol.SF_VLDR ? filterStakefishNFTBalance(it) : it.balance ?? []
        )
      } as WalletBalanceMap),
    [erc721BalanceMap, nftInfoMap]
  );
  return useStabilizedSnapshot(walletBalanceMap);
};
