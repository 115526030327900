import { FC, memo } from 'react';
import { Text, TooltipProps } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import { Link, Tooltip } from '@/apps/paraspace/components';
import { RECOVERY_NFT_HF_THRESHOLD } from '@/apps/paraspace/pages/config';
import { DEVELOPER_DOCS_LINK } from '@/apps/paraspace/consts/externalLinks';

export const CloseLiquidationTooltip: FC<TooltipProps> = memo(({ children, ...others }) => {
  return (
    <Tooltip
      content={
        <Text>
          In order to prevent your NFT from being liquidated, you need to ensure that the NFT HF is
          greater than or equal to {formatNumber(RECOVERY_NFT_HF_THRESHOLD)}.{' '}
          <Link
            href={`${DEVELOPER_DOCS_LINK}/paraspace-as-first-cross-margin-nft-lending-protocol/liquidation`}
            target="_blank"
          >
            Learn more
          </Link>
        </Text>
      }
      {...others}
    >
      {children}
    </Tooltip>
  );
});
