import styled from 'styled-components';
import { Inline, Stack, Image } from '@parallel-mono/components';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import meteorVideo from '../assets/meteor.mp4';
import usApeLogoSvg from '../assets/us-ape-logo-landing.svg';
import welcomeSvg from '../assets/welcome.svg';
import leftButtonSvg from '../assets/left-bottom.svg';
import rightButtonSvg from '../assets/right-bottom.svg';

import apeCoinLogo from '@/apps/paraspace/assets/ape-coin-right.png';
import { Link, ConnectWallet } from '@/apps/paraspace/components';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';

const FullScreen = styled.div`
  position: relative;
  min-height: 100%;
  background-color: #13012c;
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
  }
`;

const Container = styled(Stack)`
  z-index: 1;
  position: relative;
  min-height: 100%;
  width: 100%;
`;

const Nav = styled(Inline)`
  width: 100%;
  height: 5rem;
  padding: 0 5rem;
  ${({ theme }) => theme.breakpoints.down('md')`
    padding: 1.5rem;
  `};
`;

const Caption = styled(Stack)`
  width: 27.5rem;
  margin: 5.625rem auto 0;
  ${({ theme }) => theme.breakpoints.down('md')`
    width: 21.375rem;
  `};
`;

const Footer = styled(Inline)`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 5rem;
  margin-bottom: 3.25rem;
  ${({ theme }) => theme.breakpoints.down('md')`
    min-height: 15.75rem;
    padding: 2.5rem;
    margin-top: 0;
    flex-direction: column;
    align-items: center;
    position: relative;
  `};
`;

const ScaledImage = styled(Image)<{ scaled: boolean }>`
  transition: all 0.1s ease-in;
  transform: ${props => (props.scaled ? 'scale(1.05)' : 'scale(1)')};
`;

export const Preview = () => {
  const [scaled, setScale] = useState(false);
  return (
    <FullScreen>
      <video autoPlay muted loop>
        <source src={meteorVideo} type="video/mp4" />
      </video>
      <Container>
        <Nav alignItems="center">
          <Image src={usApeLogoSvg} height="40px" />
        </Nav>
        <Caption gap="3rem" alignItems="center">
          <Image src={apeCoinLogo} width="11rem" height="11rem" />
          <Image src={welcomeSvg} width="100%" height="4.46rem" />
          <ConnectWallet
            btnTxt="Connect Wallet"
            btnSize="large"
            buttonProps={{
              style: {
                padding: '0',
                background: '#fff',
                width: '100%',
                height: '3.5rem',
                boxShadow: '2px -2px 0px #00FFFF, -2px 2px 0px #FF00FF'
              }
            }}
          />
        </Caption>
        <Footer justifyContent="space-between" alignItems="flex-end">
          <Link as={RouterLink} to={absoluteRouteNames.APE_STAKING.index}>
            <ScaledImage
              scaled={scaled}
              src={leftButtonSvg}
              height="1rem"
              onMouseEnter={() => setScale(true)}
              onMouseLeave={() => setScale(false)}
            />
          </Link>
          <Image src={rightButtonSvg} height="1rem" />
        </Footer>
      </Container>
    </FullScreen>
  );
};
