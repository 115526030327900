import { Scroller, ScrollerProps } from '@parallel-mono/components';
import { FC, memo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { MenuItem, MenuName } from './styledComponents';

import { Menu } from '.';

type Props = { menus: Menu[]; onMenuClick: (menu: Menu) => void };

const MobileMenuItem = styled(MenuItem)`
  flex: 0 0 50%;
  padding: 0.5rem;
  justify-content: center;
`;

export const MobileSideMenu: FC<Props & Omit<ScrollerProps, 'children'>> = memo(
  ({ menus, onMenuClick, ...others }) => {
    const location = useLocation();

    return (
      <Scroller hideArrow showScrollFade {...others}>
        {menus.map(menu => {
          const { name, path, disabled, icon } = menu;
          return (
            <MobileMenuItem
              key={`${name}-${path}`}
              data-path={path}
              selected={location.pathname.startsWith(path)}
              disabled={disabled}
              onClick={() => onMenuClick(menu)}
            >
              {icon}
              <MenuName>{name}</MenuName>
            </MobileMenuItem>
          );
        })}
      </Scroller>
    );
  }
);
