import { useMemo } from 'react';

import { useApeRelatedActions } from './useApeRelatedActions';

import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { emptyArray, zero } from '@/apps/paraspace/consts/values';
import { ApeTokenStakingInfo } from '@/apps/paraspace/pages/hooks/ApeStaking/types';
import { StakingInfo } from '@/apps/paraspace/pages/Credit/MoneyMarket/Modal/hook/useBatchApeStakingInfo';

export const usePredictedBorrowInfo = ({
  selectedItemIds,
  symbol,
  stakingInfo,
  bakcStakingInfo
}: {
  selectedItemIds: number[];
  symbol: ERC721Symbol;
  stakingInfo: Record<string, StakingInfo>;
  bakcStakingInfo: Record<string, StakingInfo> | Record<string, ApeTokenStakingInfo>;
}) => {
  const {
    nftInfoMap: {
      [symbol]: {
        tokenTraitMultipliers = {},
        baseLTVasCollateral = zero,
        reserveLiquidationThreshold = zero,
        hasTokenSpecificInfos = false,
        tokenSpecificInfos = {},
        priceInUsd = zero,
        nftCollateralList = emptyArray
      } = {}
    }
  } = useMMProvider();

  const selectedAndAsCollateralTokens = useMemo(() => {
    return selectedItemIds.filter(id => {
      return nftCollateralList.includes(id);
    });
  }, [nftCollateralList, selectedItemIds]);

  // 1. value of selected & collateral nft
  const withdrawNFTActions = useMemo(() => {
    return selectedAndAsCollateralTokens.map(id => ({
      type: ActionTypeEnum.WITHDRAW,
      targetAsset: {
        id: `${id}`,
        value: hasTokenSpecificInfos
          ? tokenSpecificInfos[id].priceInUsd
          : priceInUsd.times(tokenTraitMultipliers?.[id] || DEFAULT_MULTIPLIER),
        LTV: hasTokenSpecificInfos
          ? tokenSpecificInfos[id].baseLTVasCollateral
          : baseLTVasCollateral,
        reserveLiquidationThreshold: hasTokenSpecificInfos
          ? tokenSpecificInfos[id].reserveLiquidationThreshold
          : reserveLiquidationThreshold
      }
    }));
  }, [
    baseLTVasCollateral,
    hasTokenSpecificInfos,
    priceInUsd,
    reserveLiquidationThreshold,
    selectedAndAsCollateralTokens,
    tokenSpecificInfos,
    tokenTraitMultipliers
  ]);

  const apeRelatedActions = useApeRelatedActions({
    symbol,
    tokenIds: selectedItemIds,
    stakingInfo,
    bakcStakingInfo
  });

  const actions = useMemo(() => {
    return [...withdrawNFTActions, ...apeRelatedActions];
  }, [apeRelatedActions, withdrawNFTActions]);

  return useLendingSimulation(actions);
};
