import { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { CollectionItemsShop as RawCollectionItemsShop } from './CollectionItemsShop';
import { LayoutType } from './components';
import { BuyCartProvider } from './context';

import { useContractsMap } from '@/apps/paraspace/hooks';
import { ERC721Symbol } from '@/apps/paraspace/typings';

export const CollectionItemsShop = memo(() => {
  const { contractAddress } = useParams<{ contractAddress: string }>();
  const contracts = useContractsMap();

  const layoutType = useMemo(
    () =>
      contractAddress === contracts[ERC721Symbol.SF_VLDR]
        ? [LayoutType.List, LayoutType.Grid]
        : [LayoutType.Grid],
    [contractAddress, contracts]
  );

  return (
    <BuyCartProvider>
      <RawCollectionItemsShop
        supportLayoutTypes={layoutType}
        contractAddress={contractAddress as string}
      />
    </BuyCartProvider>
  );
});
