import { memo, useCallback } from 'react';
import BigNumber from 'bignumber.js';

import { useBridgeImplementation } from './hooks';

import { FormSubmitter, FormSubmitterProps } from '@/apps/parax/components';

type BridgeSubmitterProps = Omit<FormSubmitterProps, 'submit'> & {
  formData: {
    amount: BigNumber;
    symbol: string;
    to: string;
    toChainEid: number;
  };
};

export const BridgeERC20Submitter = memo(
  ({ formData: { symbol, amount, to, toChainEid }, ...others }: BridgeSubmitterProps) => {
    const { genBridgeTokenTx } = useBridgeImplementation(symbol, toChainEid);
    const submit = useCallback(() => genBridgeTokenTx(amount, to), [amount, to, genBridgeTokenTx]);
    return <FormSubmitter submit={submit} inProgressMessage={`Bridging ${symbol}`} {...others} />;
  }
);
