import { memo, useMemo } from 'react';
import { InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import { formatNumber, sumBy, weightedSumBy } from '@parallel-mono/utils';

import { BendDaoNft } from '../types';

import { usePredictedBorrowPosition } from './usePredictedBorrowPosition';

import { NumberRange } from '@/apps/paraspace/components';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

type InfosProps = Omit<InfoPanelProps, 'infos'> & {
  tokensToTransfer: BendDaoNft[];
  symbol: ERC721Symbol;
};

export const Infos = memo(({ tokensToTransfer, symbol, ...others }: InfosProps) => {
  const totalFloorPrice = useMemo(
    () => sumBy(tokensToTransfer, it => it.priceInETH),
    [tokensToTransfer]
  );

  const boostedValue = useMemo(
    () =>
      weightedSumBy(
        tokensToTransfer,
        it => it.priceInETH,
        it => it.multiplier.minus(DEFAULT_MULTIPLIER)
      ),
    [tokensToTransfer]
  );

  const totalDebtTransferred = useMemo(
    () => sumBy(tokensToTransfer, it => it.debtInUsd),
    [tokensToTransfer]
  );

  const {
    overviewUserInfo: { borrowLimitInUsd }
  } = useMMProvider();
  const { borrowLimitInUsd: predictedBorrowLimitInUsd } = usePredictedBorrowPosition(
    tokensToTransfer,
    symbol
  );

  const infos = useMemo(() => {
    return [
      {
        title: 'Chainlink Floor Price',
        value: `${formatNumber(totalFloorPrice)} ETH`,
        tip: 'Chainlink NFT Floor Pricing Feeds provide a conservative and risk averse floor price estimate for an NFT collection.'
      },
      {
        title: 'Boost Value',
        value: `+${formatNumber(boostedValue)} ETH`,
        tip: 'Rare NFTs within a collection receive an increased collateral valuation.'
      },
      {
        title: 'New Borrow Limit',
        value: (
          <NumberRange
            start={borrowLimitInUsd.toNumber()}
            end={predictedBorrowLimitInUsd.toNumber()}
            formatter={formatToCurrency}
          />
        )
      },
      {
        title: 'Debt Transferred',
        value: formatToCurrency(totalDebtTransferred),
        tip: 'Total amount of outstanding debt associated with the selected NFTs from other protocols that will be transferred to ParaSpace.'
      }
    ];
  }, [
    totalFloorPrice,
    boostedValue,
    totalDebtTransferred,
    borrowLimitInUsd,
    predictedBorrowLimitInUsd
  ]);

  return <InfoPanel infos={infos} skin="primary" {...others} />;
});
