import { Network } from 'paraspace-configs-v2';

import { SupportedNetworks } from '../../typings';

export enum Feature {
  Lending = 'Lending',
  GetCreditFromUniswapToken = 'GetCreditFromUniswapToken',
  GetCreditFromBlueChips = 'GetCreditFromBlueChips',
  GetCreditFromEthValidator = 'GetCreditFromEthValidator',
  TimelockAutoClaim = 'TimelockAutoClaim',

  NftShop = 'NftShop',
  NftDetail = 'NftDetail',
  ApeStaking = 'ApeStaking',
  LiquidationDashboard = 'LiquidationDashboard',
  LiquidationDashboardAuctionData = 'LiquidationDashboardAuctionData',

  UserInfo = 'UserInfo',
  NftDelegation = 'NftDelegation',
  Security = 'Security',
  StakingCategory = 'StakingCategory',
  EthValidator = 'EthValidator',
  ProfileSetting = 'ProfileSetting',
  BendaoMover = 'BendaoMover',

  RepayDelay = 'RepayDelay',

  LeaderBoard = 'LeaderBoard',
  LeaderBoardDepositTvl = 'LeaderBoardDepositTvl',
  LeaderBoardBnplVolume = 'LeaderBoardBnplVolume'
}

const defaultFeatures = [
  Feature.Lending,
  Feature.GetCreditFromUniswapToken,
  Feature.GetCreditFromBlueChips,
  Feature.GetCreditFromEthValidator,
  Feature.TimelockAutoClaim,
  Feature.BendaoMover,

  Feature.NftShop,
  Feature.NftDetail,
  Feature.NftDelegation,
  Feature.LiquidationDashboard,
  Feature.LiquidationDashboardAuctionData,
  Feature.ApeStaking,
  Feature.UserInfo,
  Feature.Security,
  Feature.StakingCategory,
  Feature.EthValidator,
  Feature.ProfileSetting,
  Feature.LeaderBoard,
  Feature.LeaderBoardDepositTvl,
  Feature.LeaderBoardBnplVolume
];

const zkSyncFeatures = [
  Feature.Lending,
  Feature.RepayDelay,
  Feature.LeaderBoard,
  Feature.LeaderBoardDepositTvl
];

const arbFeatures = [
  Feature.Lending,
  Feature.GetCreditFromUniswapToken,
  Feature.TimelockAutoClaim,
  Feature.NftDetail,
  Feature.LeaderBoard,
  Feature.LeaderBoardDepositTvl
];

const parallelL3Features = [
  Feature.GetCreditFromBlueChips,
  Feature.Lending,
  Feature.TimelockAutoClaim
];

export const FEATURE_FOR_NETWORK_MAP: Record<SupportedNetworks, Feature[]> = {
  [Network.SEPOLIA]: defaultFeatures,
  [Network.MAINNET]: defaultFeatures,
  [Network.ZKSYNC_ERA]: zkSyncFeatures,
  [Network.ZKSYNC_GOERLI]: zkSyncFeatures,
  [Network.ARBITRUM_ONE]: arbFeatures,
  [Network.ARBITRUM_GOERLI]: arbFeatures,
  [Network.PARALLEL_L3_TESTNET]: parallelL3Features
};
