import { FC, memo } from 'react';
import { H5, H6, Inline, Stack, TypographyVariant } from '@parallel-mono/components';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Collapse, CollapseProps, Link } from '@/apps/paraspace/components';
import { truncateTextMid } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { BEACONCHA_URL } from '@/apps/paraspace/consts/externalLinks';

const StyledStack = styled(Stack).attrs({
  gap: '0.25rem'
})`
  flex: 1;
`;

type Props = {
  validatorIndex: number;
  publicKey: string;
  activatedAt: Maybe<string>;
  status: string;
};

export const StakefishStakingInfo: FC<Props & Omit<CollapseProps, 'children' | 'header'>> = memo(
  ({ validatorIndex, publicKey, activatedAt, status, ...props }) => {
    return (
      <Collapse border header="Staking Info" defaultOpen {...props}>
        <Stack inset="1.5rem 0 0 0">
          <Inline>
            <StyledStack>
              <H6 skin="secondary">Index</H6>
              <H5>{validatorIndex}</H5>
            </StyledStack>
            <StyledStack>
              <H6>Validator Public Key</H6>
              <Link
                variant={TypographyVariant.header5}
                target="_blank"
                href={`${BEACONCHA_URL}/validator/${publicKey}`}
                rel="noreferrer"
              >
                {truncateTextMid(publicKey)}
              </Link>
            </StyledStack>
          </Inline>
          <Inline>
            <StyledStack>
              <H6 skin="secondary">Activation Date</H6>
              <H5>{activatedAt ? dayjs(activatedAt).format('YYYY-MM-DD') : '-'}</H5>
            </StyledStack>
            <StyledStack>
              <H6>Status</H6>
              <Inline gap="0.25rem">
                <H5>{status}</H5>
                {/* <Tooltip content="TODO" /> */}
              </Inline>
            </StyledStack>
          </Inline>
        </Stack>
      </Collapse>
    );
  }
);
