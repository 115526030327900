import { Stack, Spinner, useBreakpoints } from '@parallel-mono/components';
import styled from 'styled-components';
import { ReactNode } from 'react';

import { GoBack } from '@/apps/paraspace/components';

const LoadingSpinnerContainer = styled.div`
  position: fixed;
  left: 50%;
`;

const StackRelative = styled(Stack)`
  position: relative;
`;

export const Container = ({ loading, children }: { loading: boolean; children: ReactNode }) => {
  const { mobile } = useBreakpoints();
  return (
    <>
      {loading ? (
        <LoadingSpinnerContainer>
          <Spinner />
        </LoadingSpinnerContainer>
      ) : (
        <StackRelative gap="3rem">
          {!mobile && <GoBack />}
          {children}
        </StackRelative>
      )}
    </>
  );
};
