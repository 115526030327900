import { memo } from 'react';
import { Card, Inline, Text, Stack, DataGridColumn, H5, H6 } from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';

import { useApeTableData, tableDataProps } from './hooks/useApeTableDate';

import { NFTCollectionThumbnail, DataGrid, Tooltip } from '@/apps/paraspace/components';
import { ERC20Symbol } from '@/apps/paraspace/typings';

const StyledCard = styled(Card)`
  width: 100%;
`;

export const ApeTable = memo(() => {
  const tableData = useApeTableData();

  const columns: DataGridColumn<tableDataProps>[] = [
    {
      name: 'Pool',
      title: <H6 fontWeight="medium">Pool</H6>,
      render: ({ data: { symbol } }) => {
        return (
          <Inline gap="0.75rem" alignItems="center">
            {symbol === ERC20Symbol.APE ? (
              <CryptoIcon symbol={symbol} size="2.5rem" />
            ) : (
              <NFTCollectionThumbnail symbol={symbol} size="small" />
            )}
            <H5>{symbol}</H5>
          </Inline>
        );
      }
    },
    {
      name: 'APR',
      title: <H6 fontWeight="medium">APR</H6>,
      render: ({ data: { apy } }) => {
        return <Text>{formatNumber(apy, { output: 'percent' })}</Text>;
      }
    },
    {
      name: 'Total Staked',
      title: <H6 fontWeight="medium">Total Staked</H6>,
      render: ({ data: { totalStaked } }) => {
        return (
          <Stack gap="0">
            <Text>{totalStaked ? formatNumber(totalStaked) : '-'}</Text>
            <Text skin="secondary">APE</Text>
          </Stack>
        );
      }
    },
    {
      name: 'On ParaSpace',
      title: <H6 fontWeight="medium">On ParaSpace</H6>,
      render: ({ data: { paraSpaceStaked } }) => {
        return (
          <Stack gap="0">
            <Text>{paraSpaceStaked ? formatNumber(paraSpaceStaked) : '-'}</Text>
            <Text skin="secondary">APE</Text>
          </Stack>
        );
      }
    },
    {
      name: 'Possible Rewards',
      title: (
        <Inline gap=".3rem">
          <H6 fontWeight="medium">Possible Rewards</H6>
          <Tooltip
            placement="top"
            content="Estimated daily rewards for 1 $APE based on the current state of the pool."
          />
        </Inline>
      ),
      render: ({ data: { possibleRewards } }) => {
        return (
          <Stack gap="0">
            <Text>{formatNumber(possibleRewards)}</Text>
            <Text skin="secondary">APE/24h</Text>
          </Stack>
        );
      }
    },
    {
      name: 'CAP',
      title: <H6 fontWeight="medium">CAP</H6>,
      render: ({ data: { cap } }) => {
        return (
          <Stack gap="0">
            {cap ? (
              <>
                <Text>{formatNumber(cap, { decimal: 0 })}</Text>
                <Text skin="secondary">APE</Text>
              </>
            ) : (
              <Text>None</Text>
            )}
          </Stack>
        );
      }
    }
  ];

  return (
    <StyledCard border>
      <DataGrid columns={columns} data={tableData ?? []} loading={tableData === null} />
    </StyledCard>
  );
});
