import { ReactNode, memo, useCallback, useEffect, useRef } from 'react';
import BigNumber from 'bignumber.js';

import { useV1cAPEApproval } from '../hooks/useV1cAPEApproval';
import { ApprovingStatus } from '../hooks/useV1Approval';

import { ApproveWallet, InProgress } from '@/apps/paraspace/components';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

type ApproveV1cAPEFormData = {
  assetAddress: string;
  name: string;
  amount: number | BigNumber;
  spender?: string;
};

type ApproveV1cAPESubmitterProps = {
  formData: ApproveV1cAPEFormData;
  approvingTips?: ReactNode;
  onFinish: () => void;
  onError: () => void;
};

export const ApproveV1cAPESubmitter = memo(
  ({ onFinish, onError, formData, approvingTips }: ApproveV1cAPESubmitterProps) => {
    const { assetAddress, amount, name, spender } = formData;

    const {
      approve,
      status: approvingStatus,
      check
    } = useV1cAPEApproval({
      token: assetAddress,
      amount,
      spender
    });

    useEffect(() => {
      check();
    }, [check]);

    const onFinishRef = useRef<ApproveV1cAPESubmitterProps['onFinish'] | null>(null);
    onFinishRef.current = onFinish;
    const onErrorRef = useRef<ApproveV1cAPESubmitterProps['onError'] | null>(null);
    onErrorRef.current = onError;

    const parallelToast = useParallelToast();

    const handleApprove = useCallback(() => {
      parallelToast.promise(
        approve()
          .then()
          .catch(e => {
            throw getUserFriendlyError(e);
          })
      );
    }, [parallelToast, approve]);

    useEffect(() => {
      if (approvingStatus === ApprovingStatus.APPROVED) {
        onFinishRef.current?.();
      } else if (
        approvingStatus === ApprovingStatus.ERROR_APPROVING ||
        approvingStatus === ApprovingStatus.ERROR_CHECKING
      ) {
        onErrorRef.current?.();
      } else if (approvingStatus === ApprovingStatus.NOT_APPROVED) {
        handleApprove();
      }
    }, [approvingStatus, handleApprove]);

    if (approvingStatus === ApprovingStatus.CHECKING || approvingStatus === ApprovingStatus.IDLE) {
      return <InProgress tip={`Checking your allowance for ${name}`} />;
    }

    if (
      approvingStatus === ApprovingStatus.SIGNING ||
      approvingStatus === ApprovingStatus.NOT_APPROVED
    ) {
      return <ApproveWallet tips={approvingTips} />;
    }

    if (
      approvingStatus === ApprovingStatus.APPROVING ||
      approvingStatus === ApprovingStatus.APPROVED
    ) {
      return <InProgress tip={`Approving ${name} Securely`} />;
    }

    return null;
  }
);
