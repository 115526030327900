import { useState, HTMLAttributes, memo, forwardRef, useCallback, useEffect, useMemo } from 'react';
import {
  Card,
  H3,
  H6,
  Inline,
  Responsive,
  Scroller,
  Stack,
  Tag,
  useBreakpoints
} from '@parallel-mono/components';
import { Pill } from '@parallel-mono/business-components';
import styled from 'styled-components';
import { noop, orderBy } from 'lodash';

import { useMoneyMarketActions } from '../../contexts';

import { BlueChips } from './BlueChips';
import { UniswapV3 } from './UniswapV3';
import { TraitBoostViewModal } from './components';
import { EthValidator } from './EthValidator';
import { NFT_TRAIT_BOOST_FOR_NETWORK } from './components/TraitBoostView/nftTraitBoostMap';

import { Tooltip, ValuePill } from '@/apps/paraspace/components';
import { ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';
import { useWeb3Context } from '@/apps/paraspace/contexts';

export enum TabLabel {
  BLUE_CHIPS = 'Blue Chips',
  UNISWAP_V3 = 'Uniswap V3',
  ETH_Validator = 'ETH Validator'
}

const StyledValuePill = styled(ValuePill)`
  padding: 0.5rem;
  cursor: pointer;
`;

const ViewButton = styled(H6)`
  padding: 0 0.5rem;
`;

const TabPill = styled(Pill)<{ disabled: boolean }>`
  opacity: ${({ disabled, theme }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
`;

type SupplyNFTsProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'>;
type SupplyNFTsHandle = HTMLDivElement | null;

const OrderedTabs = [
  {
    label: TabLabel.BLUE_CHIPS,
    featureKey: Feature.GetCreditFromBlueChips
  },
  {
    label: TabLabel.UNISWAP_V3,
    featureKey: Feature.GetCreditFromUniswapToken
  },
  {
    label: TabLabel.ETH_Validator,
    featureKey: Feature.GetCreditFromEthValidator
  }
];

export const NftTable = memo(
  forwardRef<SupplyNFTsHandle, SupplyNFTsProps>((props, ref) => {
    const { features } = useAppConfig();
    const { chainId } = useWeb3Context();
    const hasNftTraitBoost = useMemo(() => !!NFT_TRAIT_BOOST_FOR_NETWORK[chainId], [chainId]);
    const labelList = useMemo(
      () =>
        orderBy(
          [
            {
              title: TabLabel.BLUE_CHIPS,
              disabled: !features.includes(Feature.GetCreditFromBlueChips)
            },
            {
              title: TabLabel.UNISWAP_V3,
              disabled: !features.includes(Feature.GetCreditFromUniswapToken)
            },
            {
              title: TabLabel.ETH_Validator,
              disabled: !features.includes(Feature.GetCreditFromEthValidator)
            }
          ],
          it => (it.disabled ? 1 : 0)
        ),
      [features]
    );

    const firstSupportedTab = useMemo(
      () => OrderedTabs.find(it => features.includes(it.featureKey))!.label,
      [features]
    );

    const [label, setLabel] = useState<TabLabel>(firstSupportedTab);

    useEffect(() => {
      const currentTab = OrderedTabs.find(it => it.label === label);
      if (!features.includes(currentTab!.featureKey)) {
        setLabel(firstSupportedTab);
      }
    }, [features, label, firstSupportedTab]);

    const { mobile } = useBreakpoints();

    const { supplyNftFromBendDao, supplyNft } = useMoneyMarketActions();

    const handleSupply = useCallback(
      (nft: { collectionName: string; symbol: string }, walletType: WalletType) => {
        supplyNft(nft.symbol as ERC721Symbol, nft.collectionName, walletType);
      },
      [supplyNft]
    );

    const handleSupplyFromOtherProtocols = useCallback(
      (nft: { collectionName: string; symbol: string }) => {
        supplyNftFromBendDao(nft.symbol as ERC721Symbol);
      },
      [supplyNftFromBendDao]
    );

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = useCallback(() => {
      setIsModalOpen(true);
    }, []);
    const handleCloseModal = useCallback(() => {
      setIsModalOpen(false);
    }, []);

    return (
      <Card border ref={ref} {...props}>
        <Stack gap="1.5rem">
          <Responsive
            width="100%"
            justifyContent={mobile ? 'flex-start' : 'space-between'}
            alignItems={mobile ? 'flex-start' : 'center'}
            breakPoint="tablet"
          >
            <Responsive
              width={mobile ? '100%' : 'auto'}
              alignItems={mobile ? 'flex-start' : 'center'}
              gap={mobile ? '1rem' : '2rem'}
              breakPoint="tablet"
            >
              <H3>Get Credit from NFT Tokens</H3>
              <Scroller hideArrow showScrollFade width={mobile ? '100%' : 'auto'}>
                <Inline gap="0.5rem">
                  {labelList.map(({ title, disabled = false }) => (
                    <Tooltip key={title} disabled={!disabled} content="Coming Soon...">
                      <TabPill
                        active={label === title}
                        label={title}
                        skin="neutral"
                        disabled={disabled}
                        onToggle={disabled ? noop : () => setLabel(title)}
                      />
                    </Tooltip>
                  ))}
                </Inline>
              </Scroller>
            </Responsive>
            {features.includes(Feature.GetCreditFromBlueChips) && hasNftTraitBoost && (
              <StyledValuePill onClick={handleOpenModal}>
                <Tag skin="success" size="small">
                  Rarity Boost Available!
                </Tag>
                <ViewButton>View</ViewButton>
              </StyledValuePill>
            )}
          </Responsive>
          {label === TabLabel.BLUE_CHIPS && (
            <BlueChips
              onSupply={handleSupply}
              onSupplyFromOtherProtocols={handleSupplyFromOtherProtocols}
            />
          )}
          {label === TabLabel.UNISWAP_V3 && <UniswapV3 />}
          {label === TabLabel.ETH_Validator && <EthValidator />}
        </Stack>
        <TraitBoostViewModal isOpen={isModalOpen} onClose={handleCloseModal} />
      </Card>
    );
  })
);
