import { merge } from 'lodash';
import { ReactNode, Suspense, forwardRef, lazy, memo } from 'react';
import {
  ColorMode,
  RecursivePartial,
  ThemeConfig,
  ThemeProvider,
  defaultThemeConfig
} from '@parallel-mono/components';
import { ParaXProvider, AppRenderParams, Environment } from 'parax-sdk';

import { UniSwapV3ManagerProvider } from './pages/contexts/UniSwapV3ManagerProvider';
import {
  ErrorBoundary,
  OverallMaintenanceToggle,
  GoogleAnalytics,
  ImperativeBrowserRouter
} from './components';
import {
  ToastProvider,
  TogglesProvider,
  Web3TokenAuthProvider,
  Web3Provider,
  ApolloProvider,
  useWeb3Context,
  ScrollContainerProvider
} from './contexts';
import { PlatformSummaryProvider } from './pages/contexts/PlatformSummaryProvider';
import {
  BLOCK_UPDATE_INTERVAL_FOR_CONNECTED_USER,
  BLOCK_UPDATE_INTERVAL_FOR_UNCONNECTED_USER
} from './pages/config';
import MMProvider from './pages/contexts/MMProvider';
import { P2PInfoProvider } from './pages/contexts/P2PInfoProvider';
import { TokensInfoProvider } from './pages/contexts/TokensInfoProvider';
import { V1cAPEConvertProvider } from './pages/contexts/V1cAPEConvertManagerProvider';
import { RouterImperativeHandles, createSwitchableProvider } from './HOC';
import { EOABalanceProvider, UserBalanceProvider, UserPositionProvider } from './pages/contexts';
import { UniswapInfoProvider } from './pages/contexts/UniswapInfoProvider';
import { useAppConfig } from './hooks';
import { Feature } from './config';

const LazyDeveloperTools = lazy(() =>
  import('./DeveloperTools').then(({ DeveloperTools }) => ({
    default: DeveloperTools
  }))
);

const SwitchableUniswapInfoProvider = createSwitchableProvider(UniswapInfoProvider);
const SwitchableUniSwapV3ManagerProvider = createSwitchableProvider(UniSwapV3ManagerProvider);
const SwitchableV1cAPEConvertProvider = createSwitchableProvider(V1cAPEConvertProvider);
const SwitchableP2PInfoProvider = createSwitchableProvider(P2PInfoProvider);

const GlobalDataProvider = memo(({ children }: { children: ReactNode }) => {
  const { isUsingUserWallet, account, chainId, provider, eoaAccount } = useWeb3Context();
  // useVConsole();

  const { features } = useAppConfig();

  const pollingInterval = isUsingUserWallet
    ? BLOCK_UPDATE_INTERVAL_FOR_CONNECTED_USER
    : BLOCK_UPDATE_INTERVAL_FOR_UNCONNECTED_USER;

  return (
    <TokensInfoProvider pollingInterval={pollingInterval}>
      <SwitchableP2PInfoProvider
        enable={features.includes(Feature.ApeStaking)}
        pollingInterval={pollingInterval}
      >
        <UserPositionProvider
          account={isUsingUserWallet ? account : ''}
          chainId={chainId}
          provider={provider}
          pollingInterval={pollingInterval}
        >
          <UserBalanceProvider
            account={isUsingUserWallet ? account : ''}
            chainId={chainId}
            provider={provider}
            pollingInterval={pollingInterval}
          >
            <EOABalanceProvider
              account={eoaAccount}
              chainId={chainId}
              provider={provider}
              pollingInterval={pollingInterval}
            >
              <SwitchableUniswapInfoProvider
                chainId={chainId}
                provider={provider}
                pollingInterval={pollingInterval}
                enable={features.includes(Feature.GetCreditFromUniswapToken)}
              >
                <MMProvider>
                  <PlatformSummaryProvider>
                    <SwitchableUniSwapV3ManagerProvider
                      enable={features.includes(Feature.GetCreditFromUniswapToken)}
                    >
                      <SwitchableV1cAPEConvertProvider
                        enable={features.includes(Feature.ApeStaking)}
                      >
                        {children}
                      </SwitchableV1cAPEConvertProvider>
                    </SwitchableUniSwapV3ManagerProvider>
                  </PlatformSummaryProvider>
                </MMProvider>
              </SwitchableUniswapInfoProvider>
            </EOABalanceProvider>
          </UserBalanceProvider>
        </UserPositionProvider>
      </SwitchableP2PInfoProvider>
    </TokensInfoProvider>
  );
});

const alteredComponents = {
  components: {
    Typography: {
      darkSkin: {
        primary: {
          main: '#FFFFFF'
        },
        secondary: {
          main: '#BFBFBF'
        }
      }
    }
  }
};

const breakPointsOverrides: RecursivePartial<ThemeConfig> = {
  breakpoints: { tablet: 1024, desktop: 1280 }
};

const mergedThemeConfig = merge({}, defaultThemeConfig, alteredComponents, breakPointsOverrides);

export type Props = {
  children: ReactNode;
  colorMode: ColorMode;
  baseRoute: string;
  provider: ParaXProvider;
  account: string;
  authentication: AppRenderParams['state']['authentication'];
  chainId: number;
  apis: AppRenderParams['apis'];
  env: Environment;
  scrollContainer: HTMLDivElement;
};

export const Infrastructure = memo(
  forwardRef<RouterImperativeHandles, any>(
    (
      {
        children,
        colorMode,
        baseRoute,
        provider,
        account,
        authentication,
        chainId,
        apis,
        scrollContainer,
        env
      }: Props,
      ref
    ) => {
      return (
        <ThemeProvider mode={colorMode} themeConfig={mergedThemeConfig}>
          <ScrollContainerProvider value={scrollContainer}>
            <ImperativeBrowserRouter basename={baseRoute} ref={ref}>
              <ErrorBoundary>
                <ToastProvider>
                  <Web3Provider
                    provider={provider}
                    account={account}
                    authentication={authentication}
                    chainId={chainId}
                    connectWallet={apis.connectWallet}
                    addAssets={apis.addAssets}
                    updateRoutes={apis.updateRoutes}
                    env={env}
                  >
                    <Web3TokenAuthProvider>
                      <ApolloProvider>
                        <TogglesProvider>
                          <Suspense fallback={null}>
                            <LazyDeveloperTools />
                          </Suspense>
                          <OverallMaintenanceToggle>
                            <GoogleAnalytics>
                              <GlobalDataProvider>{children}</GlobalDataProvider>
                            </GoogleAnalytics>
                          </OverallMaintenanceToggle>
                        </TogglesProvider>
                      </ApolloProvider>
                    </Web3TokenAuthProvider>
                  </Web3Provider>
                </ToastProvider>
              </ErrorBoundary>
            </ImperativeBrowserRouter>
          </ScrollContainerProvider>
        </ThemeProvider>
      );
    }
  )
);
