import { memo } from 'react';

import { SupplyERC20BaseFormProps, SupplyERC20BaseForm } from './SupplyERC20FormBase';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

export const SupplyERC20FromAAForm = memo(
  (props: Omit<SupplyERC20BaseFormProps, 'erc20InfoMap'>) => {
    const { erc20InfoMap } = useMMProvider();

    return <SupplyERC20BaseForm erc20InfoMap={erc20InfoMap} {...props} />;
  }
);
