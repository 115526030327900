import { UniswapItem } from '../types';

import { zero } from '@/apps/paraspace/consts/values';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  ActionTypeEnum,
  useLendingSimulation
} from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { ERC721Symbol } from '@/apps/paraspace/typings';

export const useBorrowLimitPrediction = (tokensToSupply: UniswapItem[]) => {
  const { nftInfoMap, overviewUserInfo: { borrowLimitInUsd = zero } = {} } = useMMProvider();

  const { tokenTraitMultipliers = {} } = nftInfoMap[ERC721Symbol.UNISWAP_LP] ?? {};

  const { borrowLimitInUsd: predictedBorrowLimit } = useLendingSimulation(
    tokensToSupply.map(
      ({ tokenId, priceInUsd, baseLTVasCollateral, reserveLiquidationThreshold }) => {
        return {
          type: ActionTypeEnum.SUPPLY,
          targetAsset: {
            id: `${tokenId}`,
            value: priceInUsd.times(tokenTraitMultipliers?.[tokenId] || DEFAULT_MULTIPLIER),
            LTV: baseLTVasCollateral,
            reserveLiquidationThreshold
          }
        };
      }
    )
  );

  return {
    currentBorrowLimit: borrowLimitInUsd,
    predictedBorrowLimit
  };
};
