import { useCallback, useMemo } from 'react';
import { NTokenStakeFish } from 'paraspace-utilities-contract-helpers';

import submitTransaction from '@/apps/paraspace/utils/submitTransaction';
import { useWeb3Context } from '@/apps/paraspace/contexts';

export const useStakeFishNToken = (address: string) => {
  const { provider, account } = useWeb3Context();

  const service = useMemo(() => {
    if (!provider) return null;
    return new NTokenStakeFish(provider, address);
  }, [address, provider]);

  const claim = useCallback(
    async (tokenIds: string[], amounts: string[]) => {
      if (!provider || !service) {
        return null;
      }

      const tx = await service.claimFee(tokenIds, amounts, account, account);
      return submitTransaction({
        provider,
        tx
      });
    },
    [account, provider, service]
  );

  return { claim };
};
