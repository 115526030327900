import { memo } from 'react';
import { Stack, StackProps, Image } from '@parallel-mono/components';

import noNFT from '@/apps/paraspace/assets/no-nft.png';
import { ConnectWallet } from '@/apps/paraspace/components';

type ConnectWalletMessageProps = Omit<StackProps, 'children'> & {
  onConnectWallet?: () => void;
};

export const ConnectWalletMessage = memo(
  ({ onConnectWallet, ...others }: ConnectWalletMessageProps) => {
    return (
      <Stack alignItems="center" {...others}>
        <Image src={noNFT} width="10rem" height="11.25rem" />
        <ConnectWallet btnTxt="Connect Wallet to Begin" onConnectWallet={onConnectWallet} />
      </Stack>
    );
  }
);
