import { memo, useMemo } from 'react';
import BigNumberJs from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';

import { InfoPanel, InfoPanelProps } from './InfoPanel';

type LPTokenInfoProps = {
  token1Symbol: string;
  token1Amount: BigNumberJs;
  token1LPFee: BigNumberJs;
  token2Symbol: string;
  token2Amount: BigNumberJs;
  token2LPFee: BigNumberJs;
  percentage: number;
} & Omit<InfoPanelProps, 'infos'>;

export const LPTokenInfo = memo((props: LPTokenInfoProps) => {
  const {
    token1Symbol,
    token1Amount,
    token1LPFee,
    token2Symbol,
    token2Amount,
    token2LPFee,
    percentage,
    ...others
  } = props;
  const infos = useMemo(
    () => [
      {
        label: `Pooled ${token1Symbol}`,
        value: `${formatNumber(token1Amount.times(percentage / 100), {
          decimal: 4
        })} ${token1Symbol}`
      },
      {
        label: `Pooled ${token2Symbol}`,
        value: `${formatNumber(token2Amount.times(percentage / 100), {
          decimal: 4
        })} ${token2Symbol}`
      },
      {
        label: `${token1Symbol} Fees Earned`,
        value: `${formatNumber(token1LPFee)} ${token1Symbol}`
      },
      {
        label: `${token2Symbol} Fees Earned`,
        value: `${formatNumber(token2LPFee)} ${token2Symbol}`
      }
    ],
    [token1Symbol, token1Amount, percentage, token2Symbol, token2Amount, token1LPFee, token2LPFee]
  );
  return <InfoPanel infos={infos} {...others} />;
});
