import { CryptoIcon } from '@parallel-mono/business-components';
import {
  Card,
  H4,
  Stack,
  DataGrid,
  Pagination,
  DataGridColumn,
  H5,
  Inline,
  SmallText,
  Text,
  Icon,
  useBreakpoints
} from '@parallel-mono/components';
import { FC, memo, useMemo } from 'react';
import { isEmpty, sortBy } from 'lodash';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';

import { Link } from '@/apps/paraspace/components';
import { truncateTextMid } from '@/apps/paraspace/utils/format';
import { usePagination } from '@/apps/paraspace/hooks/usePagination';
import {
  AutoCompoundHistory,
  useGetAutoCompoundHistoryQuery
} from '@/apps/paraspace/generated/graphql';
import { useNetworkConfig } from '@/apps/paraspace/hooks';

const PAGE_SIZE = 6;

export const AutoCompoundHistoryTable: FC = memo(() => {
  const { mobile } = useBreakpoints();
  const {
    explorerLink: [explorerLinkBaseUrl]
  } = useNetworkConfig();

  const oneWeekAgoTimestamp = useMemo(() => dayjs().subtract(7, 'day').utc().format(), []);
  const { data, loading } = useGetAutoCompoundHistoryQuery({
    variables: {
      filter: {
        blockTimestampFrom: oneWeekAgoTimestamp
      }
    }
  });
  const historyData = data?.autoCompoundHistory || [];
  const { pageData, totalPage, currentPage, setCurrentPage } = usePagination(
    sortBy(historyData, item => -new Date(item.blockTimestamp).getTime()),
    PAGE_SIZE
  );

  const theme = useTheme();
  const columns: DataGridColumn<AutoCompoundHistory>[] = useMemo(
    () => [
      {
        name: 'date',
        width: '1fr',
        title: <H5 fontWeight="medium">Date</H5>,
        render: ({ data: { blockTimestamp } }) => {
          return (
            <Stack gap="0">
              <Text>{dayjs(blockTimestamp).format('MMM DD, YYYY')}</Text>
              <SmallText skin="secondary">{dayjs(blockTimestamp).format('hh:mm a')}</SmallText>
            </Stack>
          );
        }
      },
      {
        name: 'pool',
        width: '1fr',
        title: <H5 fontWeight="medium">Pool</H5>,
        render: ({ data: { nftPoolSymbol } }) => {
          return (
            <Inline gap="0.5rem">
              <CryptoIcon symbol={nftPoolSymbol} size="1.5rem" />
              <Text>{nftPoolSymbol}</Text>
            </Inline>
          );
        }
      },
      {
        name: 'triggeredBy',
        width: '1fr',
        title: <H5 fontWeight="medium">Triggered By</H5>,
        render: ({ data: { txHash } }) => {
          const accountLink = `${explorerLinkBaseUrl}/tx/${txHash}`;
          return (
            <Link href={accountLink} target="_blank" underline={false}>
              <Inline gap="0.5rem" alignItems="center">
                <Text>{truncateTextMid(txHash)}</Text>
                <Icon color={theme.skin.grey[500]} name="externalLink" size="1rem" />
              </Inline>
            </Link>
          );
        }
      }
    ],
    [explorerLinkBaseUrl, theme]
  );
  return (
    <Card border>
      <Inline
        inset={mobile ? '0 0 1rem 0' : '0'}
        alignItems="center"
        justifyContent="space-between"
      >
        <H4>Auto Compound History</H4>
      </Inline>
      <Stack>
        <DataGrid loading={loading} columns={columns} data={pageData} />
        {!isEmpty(historyData) && (
          <Pagination
            total={totalPage}
            page={currentPage}
            onChange={setCurrentPage}
            siblingCount={0}
            startBoundaryCount={3}
          />
        )}
      </Stack>
    </Card>
  );
});
