import { useCallback, useState } from 'react';
import { noop } from 'lodash';

import { Offer } from '../../../types';
import { CancelOfferModalProps } from '../modals/CancelOfferModal/CancelOfferModal';

const defaultCancelOfferModalProps: CancelOfferModalProps = {
  onClose: noop,
  onError: noop,
  onFinish: noop,
  isOpen: false
};

export const useCancelOffer = (): [CancelOfferModalProps, (offer: Offer) => Promise<void>] => {
  const [cancelOfferModalProps, setCancelOfferModalProps] = useState<CancelOfferModalProps>(
    defaultCancelOfferModalProps
  );

  const cancelOffer = useCallback(
    (offer: Offer) => {
      return new Promise<void>((resolve, reject) => {
        setCancelOfferModalProps(prev => ({
          ...prev,
          isOpen: true,
          offer,
          onClose: () => {
            setCancelOfferModalProps(prevState => ({
              ...prevState,
              isOpen: false
            }));
          },
          onFinish: resolve,
          onError: reject
        }));
      });
    },
    [setCancelOfferModalProps]
  );
  return [cancelOfferModalProps, cancelOffer];
};
