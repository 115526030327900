import { useCallback, useState } from 'react';
import { mapValues } from 'lodash';

import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { useReservesAndIncentives } from '@/apps/paraspace/pages/hooks/useReservesAndIncentives';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useAppConfig, useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { convertToChecksumAddress } from '@/apps/paraspace/utils/convertToChecksumAddress';

type CollectionLiquidationStatus = {
  isPaused: boolean;
};

export const useCollectionsLiquidationStatus = () => {
  const [collectionsLiquidationStatus, setCollectionsLiquidationStatus] =
    useState<Maybe<Record<ERC721Symbol, CollectionLiquidationStatus>>>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { erc721Config } = useAppConfig();
  const getSymbolByContractAddress = useGetSymbolByContractAddress();

  const { getReserveData } = useReservesAndIncentives();

  useAsyncEffect(async () => {
    try {
      setIsLoading(true);
      const reserveData = await getReserveData();

      const liquidationStatus = mapValues(erc721Config, config => {
        const result = reserveData?.reservesData.find(
          ({ underlyingAsset }) =>
            convertToChecksumAddress(underlyingAsset) === convertToChecksumAddress(config.address)
        );
        return { isPaused: result?.isPaused ?? false };
      });

      setCollectionsLiquidationStatus(liquidationStatus);
    } catch (e) {
      console.log(e);
      setCollectionsLiquidationStatus(null);
    } finally {
      setIsLoading(false);
    }
  }, [getReserveData]);

  const getLiquidationIsPaused = useCallback(
    (contractAddress: string) => {
      const symbol = getSymbolByContractAddress(contractAddress) as ERC721Symbol;
      const isPaused = collectionsLiquidationStatus?.[symbol]?.isPaused;
      return isPaused;
    },
    [collectionsLiquidationStatus, getSymbolByContractAddress]
  );

  return {
    collectionsLiquidationStatus,
    getLiquidationIsPaused,
    isLoading
  };
};
