import {
  DataGridColumn,
  H5,
  Inline,
  SmallText,
  Text,
  Stack,
  Button,
  DataGridProps,
  Skeleton,
  useBreakpoints,
  H6
} from '@parallel-mono/components';
import { FC, memo, useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';
import { isEmpty } from 'lodash';

import { SupplyERC721DropdownMenu, WalletsBalanceDropdown } from '../../../components';
import { useWalletBalanceMap } from '../../../hooks';

import { EthValidatorRow } from './types';

import { formatToCurrency, formatToPercentage } from '@/apps/paraspace/utils/format';
import { NFTCollectionThumbnail, DataGrid, StackedIcons } from '@/apps/paraspace/components';
import { FetchingStatus, WalletType } from '@/apps/paraspace/typings';

export type TableForDesktopProps = Omit<DataGridProps<EthValidatorRow>, 'columns'> & {
  onSupply: (walletType: WalletType) => void;
};

const TableForDesktop: FC<TableForDesktopProps> = memo(({ onSupply, ...others }) => {
  const { AA: paraXBalance, EOA: EOABalance } = useWalletBalanceMap();
  const { mobile } = useBreakpoints();
  const columns: DataGridColumn<EthValidatorRow>[] = useMemo(
    () => [
      {
        name: 'provider',
        width: '1.5fr',
        title: <H5 fontWeight="medium">Provider</H5>,
        render: ({ data: { symbol, collectionName } }) => {
          return (
            <Inline gap="0.75rem" alignItems="center">
              <NFTCollectionThumbnail symbol={symbol} size="small" round />
              <H5>{collectionName}</H5>
            </Inline>
          );
        }
      },
      {
        name: 'yourWallet',
        width: '1fr',
        title: <H5 fontWeight="medium">Your Wallet</H5>,
        render: ({
          data: {
            balanceMap: { AA, EOA },
            balanceLoadingStatus
          }
        }) => {
          if (balanceLoadingStatus === FetchingStatus.FETCHING) {
            return <Skeleton.Title />;
          }
          if (isEmpty(AA.balances) && isEmpty(EOA.balances)) {
            return <SmallText>-</SmallText>;
          }

          return (
            <Stack gap="0.5rem" alignItems={mobile ? 'flex-end' : 'initial'}>
              <StackedIcons assets={AA.balances.concat(EOA.balances)} variant="list" size="small" />
              <Inline>
                <WalletsBalanceDropdown
                  placement={mobile ? 'bottom-end' : 'bottom'}
                  EOABalance={{
                    loading: EOA.loading,
                    value: <H6>{EOA.balances.length}</H6>
                  }}
                  paraXBalance={{ loading: AA.loading, value: <H6>{AA.balances.length}</H6> }}
                />
              </Inline>
            </Stack>
          );
        }
      },
      {
        name: 'ltv',
        width: '1fr',
        title: <H5 fontWeight="medium">Loan To Value </H5>,
        render: ({ data: { ltv } }) => {
          if (!ltv) return <SmallText>-</SmallText>;
          return (
            <Stack gap="0">
              <Text>{formatToPercentage(ltv.toNumber())}</Text>
            </Stack>
          );
        }
      },
      {
        name: 'totalSupplied',
        width: '1fr',
        title: <H5 fontWeight="medium">Total Supplied</H5>,
        render: ({ data: { totalSupplied, totalUsd } }) => {
          return (
            <Stack gap="0">
              <Text>{formatNumber(totalSupplied)}</Text>
              <SmallText skin="secondary">{formatToCurrency(totalUsd)}</SmallText>
            </Stack>
          );
        }
      },
      {
        name: 'action',
        width: '1fr',
        title: '',
        render: ({ data: { symbol } }) => {
          return (
            <Inline width="100%" justifyContent="flex-end">
              <SupplyERC721DropdownMenu
                menuTrigger={
                  <Button block={mobile} skin="secondary">
                    Supply
                  </Button>
                }
                paraXWalletOption={{
                  onClick: () => onSupply('AA'),
                  balance: paraXBalance[symbol]?.length ?? 0
                }}
                EOAWalletOption={{
                  onClick: () => onSupply('EOA'),
                  balance: EOABalance[symbol]?.length ?? 0
                }}
              />
            </Inline>
          );
        }
      }
    ],
    [EOABalance, mobile, onSupply, paraXBalance]
  );

  return <DataGrid columns={columns} {...others} />;
});

export default TableForDesktop;
