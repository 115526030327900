import { useCallback } from 'react';

import { useAppConfig } from './useAppConfig';
import { useContractsMap } from './useContractsMap';

import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';

export const useGetContractAddressBySymbol = () => {
  const contractsMap = useContractsMap();
  const { erc20Config, erc721Config } = useAppConfig();

  return useCallback(
    (symbol: ERC20Symbol | ERC721Symbol) => {
      const contractName =
        erc20Config[symbol as ERC20Symbol]?.contractName ??
        erc721Config[symbol as ERC721Symbol]?.contractName;

      return contractsMap[contractName];
    },
    [erc20Config, erc721Config, contractsMap]
  );
};
