import { memo, useState } from 'react';

import { StepperProps } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useERC721 } from '@/apps/paraspace/hooks';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';

type ClaimAllSubmitterProps = Omit<StepperProps, 'steps' | 'step'> & {
  claimingQueues: any[];
  onError: () => void;
  onFinish: () => void;
};

export const ClaimAllSubmitter = memo(
  ({ claimingQueues, onError, onFinish, ...others }: ClaimAllSubmitterProps) => {
    const [queues] = useState(claimingQueues);
    const { genApprovalForAll } = useERC721(ERC721Symbol.BAKC);
    const isBakcPairedWithSuppliedMainToken = queues.some(queue => queue.mainTokenSupplied);
    return (
      <BatchTransactionsSubmitter
        inProgressMessage="Claiming all rewards"
        batchTxs={[
          {
            tx: genApprovalForAll(),
            skip: !isBakcPairedWithSuppliedMainToken
          },
          ...queues.map(queue => {
            return {
              tx: queue.method(...queue.args)
            };
          })
        ]}
        onFinish={onFinish}
        onError={onError}
        {...others}
      />
    );
  }
);
