import { BuyShopItemPayload } from '..';

import { BuyWithCreditStepper } from './BuyShopItemFormStepper';
import { SuccessState } from './SuccessState';
import { BuyShopItemForm } from './BuyShopItemForm';

import {
  CollectAndSubmitProcedurePhase,
  ErrorState,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type BuyShopItemFormData = {
  symbol: ERC721Symbol;
  tokenId: number;
  borrowAmount: Maybe<number>;
  payAmount: Maybe<number>;
};

export const BuyShopItem = ({
  payload,
  onError,
  onFinish,
  onClose,
  onUnwrapWETHClick,
  isOpen
}: {
  payload: BuyShopItemPayload;
  onError?: () => void;
  onFinish?: () => void;
  onClose: () => void;
  onUnwrapWETHClick: () => void;
  isOpen: boolean;
}) => {
  const { submittedFormData, phase, handleFormSubmit, handleSubmitFailed, handleSubmitSuccess } =
    useCollectAndSubmitProcedure<BuyShopItemFormData, void, void>({
      onError,
      onFinish,
      running: isOpen
    });

  return (
    <>
      {phase === CollectAndSubmitProcedurePhase.Collecting && (
        <BuyShopItemForm
          payload={payload!}
          onSubmit={handleFormSubmit}
          onUnwrapWETHClick={onUnwrapWETHClick}
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Submitting && (
        <BuyWithCreditStepper
          formData={submittedFormData!}
          listing={payload?.lowestPriceListing!}
          onError={handleSubmitFailed}
          onFinish={handleSubmitSuccess}
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
      {phase === CollectAndSubmitProcedurePhase.Success && (
        <SuccessState formData={submittedFormData!} payload={payload!} onClose={onClose} />
      )}
    </>
  );
};
