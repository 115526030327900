import { Environment } from 'parax-sdk';

const v1ContractAddressConfigs = {
  [Environment.DEVELOPMENT]: {
    cAPE: '0x6849c9339F812a2a3546ef88cC64B6FFD3F338Ee',
    WalletBalanceProvider: '0xc7DD5d9f1f9c2b1D82105E95C50395b07AB710f6'
  },

  [Environment.STAGING]: {
    cAPE: '0xA6CCb7667D508Adb1Ec0e4aaDbab56667076485f',
    WalletBalanceProvider: '0x2695F8eB270A414E95Ddc4d5E31C7194388ac252'
  },
  [Environment.PRODUCTION]: {
    cAPE: '0xC5c9fB6223A989208Df27dCEE33fC59ff5c26fFF',
    WalletBalanceProvider: '0x70bdf2513E5ac52fEB6665DA8f45bB516ebeCf82'
  }
};

export const getV1ContractConfig = (env: Environment) => v1ContractAddressConfigs[env];
