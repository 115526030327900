import { useMemo } from 'react';
import { chain, isNil, map } from 'lodash';
import BigNumberJs from 'bignumber.js';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

type Asset = {
  id: string;
  value: BigNumberJs;
  LTV: BigNumberJs;
  reserveLiquidationThreshold: BigNumberJs;
};

const useSuppliedAssets = () => {
  const { nftInfoMap, erc20InfoMap } = useMMProvider();

  const erc20Assets = useMemo(
    () =>
      chain(erc20InfoMap)
        .filter(it => !it?.isNativeToken) // wNativeToken & nativeToken share the same pool
        .map(token => {
          if (token && token.usageAsCollateralEnabledOnUser && token.suppliedAmountInUsd) {
            return {
              id: token.symbol,
              value: token.suppliedAmountInUsd,
              LTV: token.baseLTVasCollateral,
              reserveLiquidationThreshold: token.reserveLiquidationThreshold
            };
          }
          return null;
        })
        .filter(it => !isNil(it))
        .value() as Asset[],
    [erc20InfoMap]
  );

  const erc721Assets = useMemo(() => {
    return map(nftInfoMap, nftInfo => {
      // uniswapV3
      if (nftInfo.hasTokenSpecificInfos) {
        return (
          nftInfo.nftCollateralList?.map(tokenId => ({
            id: `${nftInfo.symbol}-${tokenId}`,
            value: nftInfo.tokenSpecificInfos[tokenId].priceInUsd,
            LTV: nftInfo.tokenSpecificInfos[tokenId].baseLTVasCollateral,
            reserveLiquidationThreshold:
              nftInfo.tokenSpecificInfos[tokenId].reserveLiquidationThreshold
          })) ?? []
        );
      }

      // nftAsCollateralBalanceInUsd has already taken trait boost into consideration
      if (nftInfo.nftAsCollateralBalanceInUsd) {
        return [
          {
            id: nftInfo.symbol,
            value: nftInfo.nftAsCollateralBalanceInUsd,
            LTV: nftInfo.baseLTVasCollateral,
            reserveLiquidationThreshold: nftInfo.reserveLiquidationThreshold
          }
        ];
      }
      return [];
    }).flat();
  }, [nftInfoMap]);

  return useMemo(() => erc20Assets.concat(erc721Assets), [erc20Assets, erc721Assets]);
};

export default useSuppliedAssets;
