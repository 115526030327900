import { Contract, Signer, BigNumber as EthersBigNumber } from 'ethers';
import BigNumber from 'bignumber.js';

import abi from './InBoxContractABI.json';

export class InBoxContract {
  private static l1GasLimit = new BigNumber(80000);

  private static l2GasLimit = new BigNumber(300000);

  private contract: Contract;

  private decimals: number;

  constructor(addr: string, signer: Signer, decimals: number) {
    this.contract = new Contract(addr, abi, signer);
    this.decimals = decimals;
  }

  public async getSendTx({
    to,
    amount,
    l1GasFeeInWei,
    l2GasFeeInWei
  }: {
    to: string;
    amount: BigNumber;
    l1GasFeeInWei: BigNumber;
    l2GasFeeInWei: BigNumber;
  }) {
    const l1GasFeeWithMarginInWei = l1GasFeeInWei.times(1.1);
    const l2GasFeeWithMarginInWei = l2GasFeeInWei.times(1.1);
    const owner = await this.contract.signer.getAddress();
    const maxSubmissionCostInWei = l1GasFeeWithMarginInWei.times(InBoxContract.l1GasLimit);
    const value = amount
      .shiftedBy(this.decimals)
      .plus(l2GasFeeWithMarginInWei.times(InBoxContract.l2GasLimit))
      .plus(maxSubmissionCostInWei);
    return this.contract.createRetryableTicket(
      to,
      EthersBigNumber.from(amount.shiftedBy(this.decimals).toString(10)),
      EthersBigNumber.from(maxSubmissionCostInWei.toString(10)),
      owner,
      owner,
      EthersBigNumber.from(InBoxContract.l2GasLimit.toString(10)),
      EthersBigNumber.from(l2GasFeeWithMarginInWei.toString(10)),
      '0x',
      { value: EthersBigNumber.from(value.toString(10)) }
    );
  }

  public async send({
    to,
    amount,
    l1GasFeeInWei,
    l2GasFeeInWei
  }: {
    to: string;
    amount: BigNumber;
    l1GasFeeInWei: BigNumber;
    l2GasFeeInWei: BigNumber;
  }) {
    const result = await this.getSendTx({
      to,
      amount,
      l1GasFeeInWei,
      l2GasFeeInWei
    });
    await result.wait();
  }
}
