import { Button, H3, Image, Stack, StackProps } from '@parallel-mono/components';
import { FC, memo } from 'react';

import metamask from '@/apps/paraspace/assets/meta-mask.svg';
import { useWeb3TokenAuth } from '@/apps/paraspace/contexts';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';

export const NotSign: FC<Omit<StackProps, 'children'>> = memo(({ ...others }) => {
  const { isValidToken, initUserAuthentication } = useWeb3TokenAuth();

  useAsyncEffect(() => {
    if (!isValidToken) {
      initUserAuthentication();
    }
  }, [isValidToken]);

  return (
    <Stack width="100%" alignItems="center" justifyContent="center" {...others}>
      <Image src={metamask} width="3.75rem" height="3.75rem" />
      <H3>Please complete the signature request to view</H3>
      <Button onClick={initUserAuthentication}>Sign</Button>
    </Stack>
  );
});
