import { ApolloClient } from '@apollo/client';
import { BigNumber } from 'bignumber.js';
import { useCallback } from 'react';
import { noop } from 'lodash';

import { AuctionStatus, InLiquidationAsset } from '../types';
import { useMMProvider } from '../../contexts/MMProvider';
import { useWNativeTokenSymbol } from '../../hooks';
import useLegacyERC20 from '../../hooks/useLegacyERC20';

import {
  useUpdateAssetAuctionStatusMutation,
  useRemoveAssetAuctionMutation,
  useRemoveAccountAuctionsMutation,
  AccountInLiquidationInfo,
  AssetInLiquidation
} from '@/apps/paraspace/generated/graphql';
import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

export const useInLiquidationDashboardActions = (client: ApolloClient<any>) => {
  const { startAuction, setAuctionValidityTime, liquidationERC721, getAuctionData } = usePool();
  const { erc20InfoMap } = useMMProvider();
  const wToken = useWNativeTokenSymbol();
  const { approve: approveWNativeToken, getAllowance: getWethAllowance } = useLegacyERC20(
    erc20InfoMap[wToken]?.address
  );

  const [updateAssetAuctionStatusInServer] = useUpdateAssetAuctionStatusMutation();
  const updateAssetAuctionInCache = useCallback(
    (
      {
        user,
        contractAddress,
        tokenId
      }: {
        user: string;
        contractAddress: string;
        tokenId: number;
      },
      updates: Partial<AssetInLiquidation>
    ) => {
      client.cache.modify({
        fields: {
          inLiquidationAccounts: (cachedLiquidations: AccountInLiquidationInfo[]) => {
            return cachedLiquidations.map(liquidation => {
              if (liquidation.accountInfo.address === user) {
                return {
                  ...liquidation,
                  assets: liquidation.assets?.map(asset => {
                    if (
                      asset.contractAddress === contractAddress &&
                      asset.identifierOrCriteria === String(tokenId)
                    ) {
                      return {
                        ...asset,
                        ...updates
                      };
                    }
                    return asset;
                  })
                };
              }
              return liquidation;
            }) as any;
          }
        }
      });
    },
    [client]
  );

  const [removeAssetAuctionFromServer] = useRemoveAssetAuctionMutation();
  const removeAssetAuctionFromCache = useCallback(
    ({
      user,
      contractAddress,
      tokenId
    }: {
      user: string;
      contractAddress: string;
      tokenId: number;
    }) => {
      client.cache.modify({
        fields: {
          liquidation: (cachedLiquidations: AccountInLiquidationInfo[]) =>
            cachedLiquidations.map(liquidation => {
              if (liquidation.accountInfo.address === user) {
                return {
                  ...liquidation,
                  assets: liquidation.assets?.filter(
                    asset =>
                      asset.contractAddress !== contractAddress ||
                      asset.identifierOrCriteria !== String(tokenId)
                  )
                };
              }
              return liquidation;
            }) as any
        }
      });
    },
    [client]
  );

  const [removeAccountLiquidationFromServer] = useRemoveAccountAuctionsMutation();
  const removeAccountLiquidationFromCache = useCallback(
    (walletAddress: string) => {
      client.cache.modify({
        fields: {
          liquidation: (cachedLiquidations: AccountInLiquidationInfo[]) =>
            cachedLiquidations.filter(
              liquidation => liquidation.accountInfo.address !== walletAddress
            ) as any
        }
      });
    },
    [client]
  );

  const closeLiquidation = useCallback(
    async (walletAddress: string) => {
      let txHash: string = '';
      await setAuctionValidityTime(walletAddress)
        .then(tx => {
          txHash = tx?.hash ?? '';
          tx?.wait();
        })
        .catch(e => {
          throw getUserFriendlyError(e);
        });
      removeAccountLiquidationFromCache(walletAddress);
      removeAccountLiquidationFromServer({
        variables: {
          user: walletAddress,
          txHash
        }
      }).catch(noop);
    },
    [setAuctionValidityTime, removeAccountLiquidationFromCache, removeAccountLiquidationFromServer]
  );

  const triggerAuction = useCallback(
    async ({
      user,
      asset: {
        contractAddress,
        identifierOrCriteria,
        floorPrice,
        floorPriceInUsd,
        maxPriceMultiplier
      }
    }: {
      user: string;
      asset: InLiquidationAsset;
    }) => {
      await startAuction(user, contractAddress, identifierOrCriteria)
        .then(tx => tx?.wait())
        .catch(e => {
          throw getUserFriendlyError(e);
        });

      const auctionUpdates: Partial<AssetInLiquidation> = {
        auctionStatus: AuctionStatus.StartedAndCanBeLiquidated,
        currentPrice: floorPrice.times(maxPriceMultiplier).toString(),
        currentPriceInUSD: floorPriceInUsd.times(maxPriceMultiplier).toNumber(),
        currentPriceMultiplier: new BigNumber(maxPriceMultiplier).shiftedBy(18).toNumber()
      };

      updateAssetAuctionInCache(
        {
          user,
          contractAddress,
          tokenId: identifierOrCriteria
        },
        auctionUpdates
      );
      updateAssetAuctionStatusInServer({
        variables: {
          contractAddress,
          identifierOrCriteria: String(identifierOrCriteria),
          auctionStatus: AuctionStatus.StartedAndCanBeLiquidated
        }
      }).catch(noop);
    },
    [startAuction, updateAssetAuctionInCache, updateAssetAuctionStatusInServer]
  );

  const buyAsset = useCallback(
    async ({
      user,
      asset: { contractAddress, identifierOrCriteria, floorPrice, buyNowPrice, nTokenAddress }
    }: {
      user: string;
      asset: InLiquidationAsset;
    }) => {
      const latestAuctionData = await getAuctionData(
        nTokenAddress,
        String(identifierOrCriteria)
      ).catch(() => null);

      const latestPriceMultiplier = latestAuctionData?.currentPriceMultiplier
        ? new BigNumber(latestAuctionData.currentPriceMultiplier.toString())
            .shiftedBy(-18)
            .integerValue(BigNumber.ROUND_CEIL)
            .toString()
        : null;

      const price = latestPriceMultiplier
        ? floorPrice.times(latestPriceMultiplier).toString()
        : buyNowPrice.toString();

      const allowance = await getWethAllowance();
      if (new BigNumber(allowance).lt(price)) {
        await approveWNativeToken();
      }

      await liquidationERC721({
        user,
        asset: contractAddress,
        tokenId: identifierOrCriteria,
        price
      })
        .then(tx => tx?.wait())
        .catch(e => {
          throw getUserFriendlyError(e);
        });
      removeAssetAuctionFromCache({
        user,
        contractAddress,
        tokenId: identifierOrCriteria
      });
      removeAssetAuctionFromServer({
        variables: {
          contractAddress,
          identifierOrCriteria: String(identifierOrCriteria)
        }
      }).catch(noop);
    },
    [
      liquidationERC721,
      removeAssetAuctionFromCache,
      removeAssetAuctionFromServer,
      approveWNativeToken,
      getWethAllowance,
      getAuctionData
    ]
  );
  return {
    closeLiquidation,
    triggerAuction,
    buyAsset
  };
};
