import { Button, ButtonProps } from '@parallel-mono/components';
import { omit } from 'lodash';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  min-width: 9rem;
`;
type Props = {
  waitingTime?: number; // second
};

export const VerifyButton: FC<Props & ButtonProps> = memo(
  ({ disabled, onClick, waitingTime: defaultWaitingTime = 60, children, ...others }) => {
    const [clickable, setClickable] = useState(true);
    const [waitTime, setWaitTime] = useState(defaultWaitingTime);

    const buttonDisabled = useMemo(() => disabled || !clickable, [clickable, disabled]);

    const handleClick = useCallback(
      e => {
        if (clickable) {
          onClick?.(e);
          setClickable(false);
        }
      },
      [clickable, onClick]
    );

    useEffect(() => {
      if (clickable === false) {
        const timer = setTimeout(() => {
          if (waitTime === 0) {
            setClickable(true);
            setWaitTime(defaultWaitingTime);
          }

          setWaitTime(prev => prev - 1);
        }, 1000);

        return () => clearTimeout(timer);
      }
      return undefined;
    }, [clickable, defaultWaitingTime, waitTime]);

    return (
      <StyledButton {...omit(others, 'disabled')} onClick={handleClick} disabled={buttonDisabled}>
        {!clickable && `${waitTime}s`} {children}
      </StyledButton>
    );
  }
);
