import { memo, useCallback, useState } from 'react';
import {
  Button,
  H2,
  Modal,
  ModalProps,
  Skeleton,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';

import { useMMProvider } from '../../../contexts/MMProvider';

import { CommonFormState } from './types';
import Success from './component/CommonSuccess';

import { ApproveWallet, InProgress, ErrorState } from '@/apps/paraspace/components';
import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC721Symbol
} from '@/apps/paraspace/typings';
import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { Maybe } from '@/apps/paraspace/typings/basic';
import useLegacyERC721 from '@/apps/paraspace/pages/hooks/useLegacyERC721';
import { useLegacyPoolApeStaking } from '@/apps/paraspace/pages/hooks/usePoolApeStaking';
import { useContractsMap } from '@/apps/paraspace/hooks';
import { useParallelToast } from '@/apps/paraspace/contexts';

export type ClaimRewardsModalProps = Omit<ModalProps, 'children'> & {
  symbol: Maybe<ApeStakingTokenSymbol>;
  tokenId: number | null;
  mainTokenId?: number;
  mainTokenSymbol?: ApeStakingMainTokenSymbol;
  pendingRewards?: BigNumber;
};

type ClaimRewardsModalContentProps = Omit<StackProps, 'children'> & {
  symbol: ApeStakingTokenSymbol;
  tokenId: number;
  mainTokenId?: number;
  mainTokenSymbol?: ApeStakingMainTokenSymbol;
  rewards?: BigNumber;
  onFinish?: () => void;
};

const ClaimRewardsModalContent = memo(
  ({
    symbol,
    tokenId,
    mainTokenId,
    mainTokenSymbol,
    rewards,
    onFinish,
    ...others
  }: ClaimRewardsModalContentProps) => {
    const [status, setStatus] = useState<CommonFormState>(CommonFormState.MAINFORM);
    const { erc20InfoMap } = useMMProvider();
    const { priceInUsd } = erc20InfoMap.APE;

    const { claimApe, claimBAKC: claimBAKCMethod } = useLegacyPoolApeStaking();
    const contracts = useContractsMap();
    const { setApprovalForAll, checkApprovalForAll } = useLegacyERC721(contracts.BAKC);

    const parallelToast = useParallelToast();

    const claimBAYCOrMAYC = useCallback(() => {
      const claimApePromise = claimApe(symbol as ApeStakingMainTokenSymbol, [tokenId])
        .then(tx => {
          setStatus(CommonFormState.PROCESSING);
          return tx?.wait();
        })
        .then(() => {
          setStatus(CommonFormState.SUCCESS);
        })
        .catch(err => {
          setStatus(CommonFormState.ERROR);
          throw getUserFriendlyError(err);
        });

      parallelToast.promise(claimApePromise);
    }, [claimApe, symbol, tokenId, parallelToast]);

    const claimBAKC = useCallback(async () => {
      setStatus(CommonFormState.EXTRA_BAKC_APPROVAL);
      const approved = await checkApprovalForAll();
      if (!approved) {
        const approvalPromise = setApprovalForAll()
          .then(async tx => {
            await tx?.wait();
            setStatus(CommonFormState.APPROVE);
          })
          .catch(err => {
            setStatus(CommonFormState.ERROR);
            throw getUserFriendlyError(err);
          });
        await parallelToast.promise(approvalPromise);
      }
      setStatus(CommonFormState.APPROVE);

      const claimApePromise = claimBAKCMethod(mainTokenSymbol!, mainTokenId!, tokenId)
        .then(tx => {
          setStatus(CommonFormState.PROCESSING);
          return tx?.wait();
        })
        .then(() => {
          setStatus(CommonFormState.SUCCESS);
        })
        .catch(err => {
          setStatus(CommonFormState.ERROR);
          throw getUserFriendlyError(err);
        });

      parallelToast.promise(claimApePromise);
    }, [
      checkApprovalForAll,
      claimBAKCMethod,
      mainTokenSymbol,
      mainTokenId,
      tokenId,
      parallelToast,
      setApprovalForAll
    ]);

    const handleClaim = useCallback(() => {
      setStatus(CommonFormState.APPROVE);

      if (symbol === ERC721Symbol.BAKC) {
        claimBAKC();
      } else {
        claimBAYCOrMAYC();
      }
    }, [symbol, claimBAKC, claimBAYCOrMAYC]);

    return (
      <Stack alignItems="center" {...others}>
        {status === CommonFormState.MAINFORM && (
          <>
            <CryptoIcon symbol="APE" size="5rem" />
            {rewards ? (
              <Stack gap=".25rem" alignItems="center">
                <H2>{formatBalance(rewards)} APE</H2>
                <Text skin="secondary">
                  {formatToCurrency(rewards.times(priceInUsd).toNumber())}
                </Text>
              </Stack>
            ) : (
              <Skeleton.Button variant="round" height="2.25rem" width="6rem" />
            )}
            <Button block onClick={handleClaim}>
              Claim
            </Button>
          </>
        )}
        {status === CommonFormState.APPROVE && <ApproveWallet />}
        {status === CommonFormState.PROCESSING && <InProgress tip="Claiming your rewards" />}
        {status === CommonFormState.EXTRA_BAKC_APPROVAL && (
          <InProgress tip="Approve your BAKC to ParaSpace" />
        )}
        {status === CommonFormState.SUCCESS && (
          <Success
            desc={`You just claimed ${formatBalance(rewards)} APE coins!`}
            tip="Come back for more later!"
            onClose={onFinish!}
          />
        )}
        {status === CommonFormState.ERROR && <ErrorState closeModal={onFinish!} />}
      </Stack>
    );
  }
);

export const ClaimRewardsModal = memo(
  ({
    symbol,
    tokenId,
    mainTokenId,
    mainTokenSymbol,
    pendingRewards,
    onClose,
    ...others
  }: ClaimRewardsModalProps) => {
    return (
      <Modal {...others} onClose={onClose} title="Claim Rewards">
        <ClaimRewardsModalContent
          onFinish={onClose}
          symbol={symbol!}
          tokenId={tokenId!}
          mainTokenId={mainTokenId}
          mainTokenSymbol={mainTokenSymbol}
          rewards={pendingRewards}
        />
      </Modal>
    );
  }
);
