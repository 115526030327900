import { CryptoIcon } from '@parallel-mono/business-components';
import {
  Card,
  DataGrid,
  DataGridColumn,
  H4,
  H6,
  Inline,
  SmallText,
  Stack,
  Text
} from '@parallel-mono/components';
import { FC, memo, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/ApePairing/consts';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { Link } from '@/apps/paraspace/components';

type PoolDetails = {
  poolName: string;
  compoundApy: BigNumber;
  yearProfits: BigNumber;
  fullyBorrowedCompoundApy: BigNumber;
  fullyBorrowedYearProfits: BigNumber;
};

const Footer = styled(SmallText)`
  text-align: center;
`;

export const StakingPoolDetails: FC = memo(() => {
  const { nftPoolsCompoundApy } = useAutoCompoundApeInfo();
  const { effectiveCapeBorrowApy } = useAutoCompoundApeInfo();

  const columns: DataGridColumn<PoolDetails>[] = useMemo(
    () => [
      {
        name: 'poolName',
        width: '1fr',
        title: <H6 skin="secondary">Pool</H6>,
        render: ({ data: { poolName } }) => {
          return (
            <Inline alignItems="center" gap="0.5rem">
              <CryptoIcon symbol={poolName} />
              <H6>{poolName} Pool</H6>
            </Inline>
          );
        }
      },
      {
        name: 'compoundApy',
        width: '1fr',
        title: <H6 skin="secondary">Compound APY</H6>,
        render: ({ data: { compoundApy } }) => {
          return <Text>{formatNumber(compoundApy, { output: 'percent' })}</Text>;
        }
      },
      {
        name: 'yearProfits',
        width: '1fr',
        title: <H6 skin="secondary">Year Profits</H6>,
        render: ({ data: { yearProfits } }) => {
          return <Text>{formatNumber(yearProfits)}</Text>;
        }
      },
      {
        name: 'fullyBorrowedCompoundApy',
        width: '1fr',
        title: (
          <Stack gap="0">
            <H6 skin="secondary">Compound APY</H6>
            <H6 skin="secondary">(fully borrowed)</H6>
          </Stack>
        ),
        render: ({ data: { fullyBorrowedCompoundApy } }) => {
          return (
            <Text>
              {formatNumber(fullyBorrowedCompoundApy, {
                output: 'percent'
              })}
            </Text>
          );
        }
      },
      {
        name: 'fullyBorrowedYearProfits',
        width: '1fr',
        title: <H6 skin="secondary">Year Profits</H6>,
        render: ({ data: { fullyBorrowedYearProfits } }) => {
          return <Text>{formatNumber(fullyBorrowedYearProfits)}</Text>;
        }
      }
    ],
    []
  );

  const poolDetailsData = useMemo(() => {
    const baycCompoundApy = nftPoolsCompoundApy?.BAYC ?? zero;
    const maycCompoundApy = nftPoolsCompoundApy?.MAYC ?? zero;
    const bakcCompoundApy = nftPoolsCompoundApy?.BAKC ?? zero;

    const baycFullyBorrowedCompoundApy = baycCompoundApy.minus(effectiveCapeBorrowApy ?? zero);
    const maycFullyBorrowedCompoundApy = maycCompoundApy.minus(effectiveCapeBorrowApy ?? zero);
    const bakcFullyBorrowedCompoundApy = bakcCompoundApy.minus(effectiveCapeBorrowApy ?? zero);

    return [
      {
        poolName: ERC721Symbol.BAYC,
        compoundApy: baycCompoundApy,
        yearProfits: baycCompoundApy.times(STAKE_LIMIT[ERC721Symbol.BAYC]),
        fullyBorrowedCompoundApy: baycFullyBorrowedCompoundApy,
        fullyBorrowedYearProfits: baycFullyBorrowedCompoundApy.times(STAKE_LIMIT[ERC721Symbol.BAYC])
      },
      {
        poolName: ERC721Symbol.MAYC,
        compoundApy: maycCompoundApy,
        yearProfits: maycCompoundApy.times(STAKE_LIMIT[ERC721Symbol.MAYC]),
        fullyBorrowedCompoundApy: maycFullyBorrowedCompoundApy,
        fullyBorrowedYearProfits: maycFullyBorrowedCompoundApy.times(STAKE_LIMIT[ERC721Symbol.MAYC])
      },
      {
        poolName: ERC721Symbol.BAKC,
        compoundApy: bakcCompoundApy,
        yearProfits: bakcCompoundApy.times(STAKE_LIMIT[ERC721Symbol.BAKC]),
        fullyBorrowedCompoundApy: bakcFullyBorrowedCompoundApy,
        fullyBorrowedYearProfits: bakcFullyBorrowedCompoundApy.times(STAKE_LIMIT[ERC721Symbol.BAKC])
      }
    ] as PoolDetails[];
  }, [effectiveCapeBorrowApy, nftPoolsCompoundApy]);

  return (
    <Card border id="nft-pool-details">
      <DataGrid
        title={
          <>
            <H4>NFT Staking Pool Details</H4>
            <SmallText skin="secondary">Assumes full stake for each pool.</SmallText>
          </>
        }
        columns={columns}
        data={poolDetailsData}
        footer={
          <Footer skin="secondary">
            For more APY calculation, go to the{' '}
            <Link as={RouterLink} to={absoluteRouteNames.APE_STAKING.CALCULATOR} skin="primary">
              Calculator
            </Link>
          </Footer>
        }
      />
    </Card>
  );
});
