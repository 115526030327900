import { useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { ActiveMatchesSummary } from '../types';

import { useGetP2PApeStakingMatchesSummaryQuery } from '@/apps/paraspace/generated/graphql';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/OfficialPairing/ApeStakingManagerProvider/types';
import { zero } from '@/apps/paraspace/consts/values';

export const PAGE_SIZE = 6;

export const useActiveMatchesSummary = () => {
  const { data, loading } = useGetP2PApeStakingMatchesSummaryQuery();

  const summary: ActiveMatchesSummary = useMemo(() => {
    if (data) {
      const { totalActiveMatches, totalBAYCStaked, totalMAYCStaked, totalBAKCStaked } =
        data.P2PApeStakingMatchesSummary;

      const totalApeCoinStaked = new BigNumber(totalBAKCStaked)
        .multipliedBy(STAKE_LIMIT.BAKC)
        .plus(new BigNumber(totalBAYCStaked).multipliedBy(STAKE_LIMIT.BAYC))
        .plus(new BigNumber(totalMAYCStaked).multipliedBy(STAKE_LIMIT.MAYC));

      return {
        totalActiveMatches: new BigNumber(totalActiveMatches),
        totalBAYCStaked: new BigNumber(totalBAYCStaked),
        totalMAYCStaked: new BigNumber(totalMAYCStaked),
        totalBAKCStaked: new BigNumber(totalBAKCStaked),
        totalApeCoinStaked
      };
    }
    return {
      totalActiveMatches: zero,
      totalBAYCStaked: zero,
      totalMAYCStaked: zero,
      totalBAKCStaked: zero,
      totalApeCoinStaked: zero
    };
  }, [data]);

  return { loading, data: summary };
};
