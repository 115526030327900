import { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { Stack, SmallText, H4, Text } from '@parallel-mono/components';
import { formatNumber, sum } from '@parallel-mono/utils';

import { STAKE_LIMIT } from '@/apps/paraspace/pages/ApePairing/consts';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import {
  ApeListing,
  ApeCoinListing,
  BakcListing
} from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/types';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';

export const useInfos = ({
  apeCoinListing,
  predictedApeListing,
  predictedApeCoinListing,
  predictedBakcListing
}: {
  apeCoinListing: Maybe<ApeCoinListing>;
  predictedApeListing: Partial<ApeListing>;
  predictedApeCoinListing: Partial<ApeCoinListing>;
  predictedBakcListing: Partial<BakcListing>;
}) => {
  const { account } = useWeb3Context();
  const { nftPoolsCompoundApy } = useAutoCompoundApeInfo();
  const apy = nftPoolsCompoundApy?.[ERC721Symbol.BAKC].toNumber() ?? 0;

  const myListings = [predictedApeListing, predictedApeCoinListing, predictedBakcListing].filter(
    it => it.offerer === account
  );
  const myShare = sum(myListings.map(it => it.share).filter(s => s !== null) as BigNumber[]);

  const infos = useMemo(
    () =>
      [
        {
          title: 'Your APY',
          value: (
            <Stack gap="0" alignItems="flex-end">
              <Text skin="success">
                {formatNumber(myShare.multipliedBy(apy), {
                  output: 'percent'
                })}
              </Text>
              <SmallText skin="secondary">
                {formatNumber(myShare, {
                  output: 'percent'
                })}{' '}
                of total {formatNumber(apy, { output: 'percent' })} pool APY
              </SmallText>
            </Stack>
          )
        },
        apeCoinListing === null
          ? {
              title: 'Stake Amount',
              value: <H4>{formatNumber(STAKE_LIMIT[ERC721Symbol.BAKC])} APE</H4>
            }
          : null
      ].filter(it => it !== null),
    [apy, myShare, apeCoinListing]
  );

  return infos;
};
