import { keys, pickBy } from 'lodash';
import { useMemo } from 'react';

import { useAppConfig } from '@/apps/paraspace/hooks';
import { ERC20Symbol } from '@/apps/paraspace/typings';

export const useExpandableTokens = (symbol: ERC20Symbol) => {
  const { erc20Config } = useAppConfig();

  const tokens = useMemo(() => {
    const tokenInfo = erc20Config[symbol];

    if (tokenInfo.tokenCategory) {
      return keys(
        pickBy(erc20Config, erc20 => erc20.tokenCategory === tokenInfo.tokenCategory)
      ) as ERC20Symbol[];
    }
    return [];
  }, [erc20Config, symbol]);

  return tokens;
};
