import { Button, Inline, Responsive } from '@parallel-mono/components';
import { memo, useCallback, useMemo, useState } from 'react';

import { ConnectWallet } from '@/apps/paraspace/components';
import { AuctionStatus } from '@/apps/paraspace/pages/Liquidation/types';
import { LiquidationInfoForAsset } from '@/apps/paraspace/generated/graphql';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import {
  IN_LIQUIDATION_HF_THRESHOLD,
  RECOVERY_NFT_HF_THRESHOLD
} from '@/apps/paraspace/pages/config';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { BuyListing } from '@/apps/paraspace/pages/Shop/types';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useCollectionsLiquidationStatus } from '@/apps/paraspace/pages/hooks';

const OperationButtons = ({
  liquidation,
  isOwner,
  lowestPriceListing,
  onBuyNowClick,
  onCloseLiquidation,
  onTriggerAuction,
  onBuyLiquidationAsset,
  handleMakeOfferClick,
  disableShopFeature
}: {
  liquidation?: LiquidationInfoForAsset | null;
  isOwner: boolean;
  lowestPriceListing: Maybe<BuyListing>;
  onBuyNowClick: () => void;
  handleMakeOfferClick: () => void;
  onCloseLiquidation: (account: string) => Promise<void>;
  onTriggerAuction: (account: string) => Promise<void>;
  onBuyLiquidationAsset: () => void;
  disableShopFeature: boolean;
}) => {
  const { isUsingUserWallet } = useWeb3Context();
  const { userInfoLoaded } = useMMProvider();
  const [pending, setPending] = useState(false);
  const handleTriggerLiq = useCallback(() => {
    setPending(true);
    onTriggerAuction(liquidation?.accountInfo.address).finally(() => {
      setPending(false);
    });
  }, [onTriggerAuction, liquidation, setPending]);
  const { getLiquidationIsPaused } = useCollectionsLiquidationStatus();

  const handleCloseLiq = useCallback(() => {
    setPending(true);
    onCloseLiquidation(liquidation?.accountInfo.address).finally(() => {
      setPending(false);
    });
  }, [onCloseLiquidation, liquidation, setPending]);

  const liquidationActionDisabled = useMemo(
    () => getLiquidationIsPaused(liquidation?.asset.contractAddress) || pending,
    [getLiquidationIsPaused, liquidation?.asset.contractAddress, pending]
  );

  const showShopRelatedButton = !disableShopFeature;

  if (!isUsingUserWallet) {
    return <ConnectWallet btnTxt="Connect to Wallet" />;
  }

  // liquidation don't care about owner
  if (liquidation) {
    const {
      accountInfo: { nftHealthFactor, healthFactor },
      asset: { auctionStatus }
    } = liquidation;

    // liquidation not started
    if (
      auctionStatus === AuctionStatus.NotStarted &&
      nftHealthFactor < IN_LIQUIDATION_HF_THRESHOLD
    ) {
      return (
        <Button
          skin="secondary"
          block
          size="large"
          onClick={handleTriggerLiq}
          disabled={liquidationActionDisabled}
        >
          Trigger Liquidation
        </Button>
      );
    }
    // liquidation can be closed
    if (nftHealthFactor > RECOVERY_NFT_HF_THRESHOLD && auctionStatus !== AuctionStatus.NotStarted) {
      return (
        <Button
          skin="secondary"
          block
          size="large"
          onClick={handleCloseLiq}
          disabled={liquidationActionDisabled}
        >
          Close Liquidation
        </Button>
      );
    }
    // liquidation started
    if (auctionStatus === AuctionStatus.StartedAndCanBeLiquidated && !isOwner) {
      return (
        <Inline justifyContent="space-between" grow>
          <Button size="large" block onClick={onBuyLiquidationAsset} disabled={pending}>
            Buy Now
          </Button>
          {showShopRelatedButton && (
            <Button skin="secondary" block size="large" onClick={handleMakeOfferClick}>
              Make Offer
            </Button>
          )}
        </Inline>
      );
    }
    if (
      healthFactor < IN_LIQUIDATION_HF_THRESHOLD &&
      nftHealthFactor > IN_LIQUIDATION_HF_THRESHOLD &&
      !isOwner &&
      !showShopRelatedButton
    ) {
      return (
        <Button
          skin="secondary"
          block
          size="large"
          loading={!userInfoLoaded}
          disabled={!userInfoLoaded}
          onClick={handleMakeOfferClick}
        >
          Make Offer
        </Button>
      );
    }
  }
  if (isOwner || !showShopRelatedButton) {
    return null;
  }
  // Other cases
  return (
    <Responsive width="100%" justifyContent="space-between" grow>
      {lowestPriceListing && (
        <Button
          skin="primary"
          block
          size="large"
          disabled={!userInfoLoaded}
          loading={!userInfoLoaded}
          onClick={onBuyNowClick}
        >
          Buy Now
        </Button>
      )}
      <Button
        skin="secondary"
        block
        size="large"
        loading={!userInfoLoaded}
        disabled={!userInfoLoaded}
        onClick={handleMakeOfferClick}
      >
        Make Offer
      </Button>
    </Responsive>
  );
};

export default memo(OperationButtons);
