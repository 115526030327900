import { memo } from 'react';
import { Card, CardProps, H5, Inline, SmallText, Stack } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';

import { BorrowLimitBar } from '../BorrowLimitBar';

import { formatToCurrency } from '@/apps/paraspace/utils/format';

type PredictedBorrowPositionProps = Omit<CardProps, 'children'> & {
  borrowedValueInUsd: BigNumber;
  borrowLimitValueInUsd: BigNumber;
  liquidationPointValueInUsd: BigNumber;
  totalSuppliedValueInUsd: BigNumber;
};

export const BorrowPositionCard = memo(
  ({
    borrowedValueInUsd,
    borrowLimitValueInUsd,
    liquidationPointValueInUsd,
    totalSuppliedValueInUsd,
    ...others
  }: PredictedBorrowPositionProps) => {
    return (
      <Card inset="0.625rem 1rem" border {...others}>
        <Stack inset="0" gap="0.5rem">
          <Inline alignItems="center" gap="0.25rem">
            <SmallText skin="secondary">Borrowing:</SmallText>
            <H5>{formatToCurrency(borrowedValueInUsd)}</H5>
          </Inline>
          <BorrowLimitBar
            borrowedValue={borrowedValueInUsd}
            borrowLimitValue={borrowLimitValueInUsd}
            liquidationPointValue={liquidationPointValueInUsd}
            totalSuppliedValue={totalSuppliedValueInUsd}
          />
        </Stack>
      </Card>
    );
  }
);
