import React, { ReactElement, useMemo, useRef } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as OriginApolloProvider,
  FieldMergeFunction,
  createHttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { useWeb3TokenAuth } from '../Web3TokenAuthContext';

import { useAppConfig } from '@/apps/paraspace/hooks';

const commonMergeFunction: FieldMergeFunction = (existing, incoming, { args }) => {
  const merged = existing ? existing.slice(0) : [];
  if (incoming) {
    const {
      pageInfo: { offset = existing.length }
    } = args || { pageInfo: {} };
    for (let i = 0; i < incoming.length; i += 1) {
      merged[offset + i] = incoming[i];
    }
  }

  return merged;
};

const reservoirMergeFunction: FieldMergeFunction = (existing, incoming) => {
  if (incoming) {
    return {
      continuation: incoming.continuation,
      items: [...(existing?.items || []), ...(incoming?.items || [])]
    };
  }
  return { ...existing };
};

export const ApolloProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const { graphqlEndpoint } = useAppConfig();
  const { web3Token } = useWeb3TokenAuth();
  const web3TokenRef = useRef<string>(web3Token);
  web3TokenRef.current = web3Token;

  const apolloHttpLink = useMemo(
    () =>
      createHttpLink({
        uri: graphqlEndpoint
      }),
    [graphqlEndpoint]
  );

  const apolloAuthContext = useMemo(
    () =>
      setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            Authorization: web3TokenRef.current ? `Bearer ${web3TokenRef.current}` : ''
          }
        };
      }),
    []
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        link: apolloAuthContext.concat(apolloHttpLink),
        cache: new InMemoryCache({
          typePolicies: {
            Query: {
              fields: {
                assets: {
                  keyArgs: ['filter', 'sort'],
                  merge: commonMergeFunction
                },
                shopItems: {
                  keyArgs: ['filter', 'marketplace', 'contractAddress'],
                  merge: reservoirMergeFunction
                },
                shopItemsOfParaSpace: {
                  keyArgs: ['filter', 'contractAddress'],
                  merge: reservoirMergeFunction
                },
                P2PApeStakingListings: {
                  keyArgs: ['filter'],
                  merge: commonMergeFunction
                }
              }
            }
          }
        })
      }),
    [apolloAuthContext, apolloHttpLink]
  );

  return <OriginApolloProvider client={client}>{children}</OriginApolloProvider>;
};
