import { UnwrapWETHForm } from './UnwrapWETHForm';
import { UnwrapWETHStepper } from './UnwrapWETHFormStepper';

import {
  CollectAndSubmitProcedurePhase,
  ErrorState,
  SuccessState,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';

export type UnwrapWETHFormData = {
  amount: number;
};

export const UnwrapWETH = ({
  onFinish,
  onError,
  onClose,
  isOpen,
  onSuccessAction
}: {
  onFinish?: () => void;
  onError?: () => void;
  onClose: () => void;
  isOpen: boolean;
  onSuccessAction: () => void;
}) => {
  const { submittedFormData, phase, handleFormSubmit, handleSubmitFailed, handleSubmitSuccess } =
    useCollectAndSubmitProcedure<UnwrapWETHFormData, void, void>({
      onError,
      onFinish,
      running: isOpen
    });

  return (
    <>
      {phase === CollectAndSubmitProcedurePhase.Collecting && (
        <UnwrapWETHForm onSubmit={handleFormSubmit} />
      )}
      {phase === CollectAndSubmitProcedurePhase.Submitting && (
        <UnwrapWETHStepper
          formData={submittedFormData!}
          onError={handleSubmitFailed}
          onFinish={handleSubmitSuccess}
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
      {phase === CollectAndSubmitProcedurePhase.Success && (
        <SuccessState
          desc="Success!"
          tip="You can now use your ETH."
          actionButtonText="Continue to Buy Now"
          onAction={onSuccessAction}
        />
      )}
    </>
  );
};
