import { memo, useCallback, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';

import { ApeCoinListing, ApeListing, ListingType } from '../../../../types';
import { SignListingSubmitter, MatchListingsSubmitter } from '../../../../submitters';

import { Maybe } from '@/apps/paraspace/typings/basic';
import {
  Stepper,
  StepperProps,
  ApproveERC721FromEOASubmitter,
  SupplyERC721FromEOASubmitter
} from '@/apps/paraspace/components';
import { FLOAT_SCALING_FACTOR } from '@/apps/paraspace/utils/format';
import { CreateListingReturnType } from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { one } from '@/apps/paraspace/consts/values';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { ApeStakingMainTokenSymbol, WalletType } from '@/apps/paraspace/typings';
import { useContractsMap, useERC721 } from '@/apps/paraspace/hooks';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';

type JoinListingSubmitterProps = Omit<StepperProps, 'steps' | 'step' | 'onError'> & {
  formData: {
    apeCoinListing: ApeCoinListing;
    apeListing?: Maybe<ApeListing>;
    token: {
      symbol: ApeStakingMainTokenSymbol;
      tokenId: number;
    };
  };
  walletType: WalletType;
  onError: (errorConfig?: Maybe<ErrorConfig>) => void;
  onFinish: (createdApeListing: ApeListing) => void;
};

export const JoinListingSubmitter = memo(
  ({
    formData: {
      apeCoinListing,
      apeListing,
      apeCoinListing: { stakingPool, share, stakingType },
      token: { symbol, tokenId }
    },
    walletType,
    onFinish,
    onError,
    ...others
  }: JoinListingSubmitterProps) => {
    const [step, setStep] = useState<number>(0);
    const [createdListing, setCreatedListing] = useState<Maybe<ApeListing>>(null);

    const { nftInfoMap } = useMMProvider();

    const contracts = useContractsMap();
    const { genApprovalForAll, genSupplyERC721Txs } = useERC721(symbol);

    const handleNextStep = useCallback(() => {
      setStep(curr => curr + 1);
    }, [setStep]);

    const handleListingCreated = useCallback(
      (createdListings: CreateListingReturnType) => {
        const {
          offerer,
          share: createdListingShare,
          startTime,
          endTime,
          v,
          r,
          s,
          token
        } = createdListings ?? {};
        setCreatedListing({
          token,
          listingHash: '',
          offerer,
          share: new BigNumber(createdListingShare).dividedBy(FLOAT_SCALING_FACTOR),
          startDate: new Date(startTime * 1000),
          expirationDate: new Date(endTime * 1000),
          tokenId,
          v,
          r,
          s,
          stakingType,
          stakingPool,
          symbol,
          listingType: ListingType.ApeListing
        });
        handleNextStep();
      },
      [tokenId, stakingType, stakingPool, symbol, handleNextStep]
    );

    const [supplied] = useState(nftInfoMap?.[symbol]?.nftSuppliedList?.includes(tokenId));

    const handleFinish = useCallback(() => {
      onFinish(createdListing!);
    }, [onFinish, createdListing]);

    const batchTxs = useMemo(
      () => [
        {
          tx: genApprovalForAll({ ids: [tokenId] }),
          skip: supplied || walletType === 'EOA'
        },
        {
          tx: genSupplyERC721Txs({ tokenIds: [tokenId] }),
          skip: supplied || walletType === 'EOA'
        }
      ],
      [walletType, supplied, genApprovalForAll, genSupplyERC721Txs, tokenId]
    );

    const steps = useMemo(
      () => [
        {
          description: `Approve ${symbol}`,
          content: (
            <ApproveERC721FromEOASubmitter
              formData={{
                name: symbol,
                assetAddress: contracts[symbol]
              }}
              onFinish={handleNextStep}
              onError={onError}
            />
          ),
          skip: walletType !== 'EOA' || supplied
        },
        {
          description: `Supply ${symbol} to ParaSpace`,
          content: (
            <SupplyERC721FromEOASubmitter
              formData={{
                tokenIds: [tokenId],
                symbol
              }}
              onFinish={handleNextStep}
              onError={onError}
            />
          ),
          skip: walletType !== 'EOA' || supplied
        },
        {
          description: `Approve and supply ${symbol}`,
          content: (
            <BatchTransactionsSubmitter
              batchTxs={batchTxs}
              inProgressMessage="Approving and supplying NFTs"
              onFinish={handleNextStep}
              onError={onError}
            />
          ),
          skip: batchTxs.every(({ skip }) => skip)
        },
        {
          description: 'Sign to create listing',
          content: (
            <SignListingSubmitter
              formData={{
                stakingType,
                offerSymbol: symbol,
                tokenId,
                wantedPercentage: one.minus(share).minus(apeListing?.share ?? 0),
                duration: 1 / 12
              }}
              onError={onError}
              onFinish={handleListingCreated}
            />
          )
        },
        {
          description: 'Matching Order',
          content: (
            <MatchListingsSubmitter
              formData={{
                apeCoinListing,
                apeListing:
                  createdListing?.listingType === ListingType.ApeListing
                    ? createdListing!
                    : apeListing!,
                bakcListing: null
              }}
              onError={onError}
              onFinish={handleFinish}
            />
          )
        }
      ],
      [
        symbol,
        contracts,
        handleNextStep,
        onError,
        walletType,
        supplied,
        tokenId,
        stakingType,
        share,
        apeListing,
        handleListingCreated,
        apeCoinListing,
        createdListing,
        handleFinish,
        batchTxs
      ]
    );

    return <Stepper step={step} steps={steps} {...others} />;
  }
);
