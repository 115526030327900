import { memo, useCallback, useRef } from 'react';
import BigNumber from 'bignumber.js';
import { EthereumTransactionTypeExtended } from 'paraspace-utilities-contract-helpers';

import { FormSubmitter } from '@/apps/paraspace/components';
import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useNativeTokenGateway, useERC20 } from '@/apps/paraspace/hooks';
import { useIsNativeTokenCheck } from '@/apps/paraspace/pages/hooks/useIsNativeTokenCheck';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';

type FormData = { amount: BigNumber; symbol: ERC20Symbol };

type SupplyERC20FromAAFormSubmitterProps = {
  formData: FormData;
  onFinish: (data: FormData) => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const SupplyERC20FromAAFormSubmitter = memo(
  ({
    formData: { amount, symbol },
    formData,
    onFinish,
    onError
  }: SupplyERC20FromAAFormSubmitterProps) => {
    const { supplyERC20 } = usePool();
    const { checkIsNativeTokenSymbol } = useIsNativeTokenCheck();
    const { supplyNativeToken } = useNativeTokenGateway();
    const { erc20InfoMap } = useMMProvider();
    const { submitTransactions } = useWeb3Context();
    const { createApproval } = useERC20(erc20InfoMap[symbol].address);

    const formDataRef = useRef(formData);

    const submit = useCallback(async () => {
      const approveTx = await createApproval({ amount });
      let supplyTx: Maybe<EthereumTransactionTypeExtended>;
      if (checkIsNativeTokenSymbol(symbol)) {
        supplyTx = await supplyNativeToken({ amount: amount.toString(10) });
      } else {
        supplyTx = await supplyERC20({
          assetAddr: erc20InfoMap[symbol].address,
          amount: amount.toString(10)
        });
      }
      const allTx = [...approveTx, supplyTx].filter(notEmpty);
      return submitTransactions(allTx);
    }, [
      createApproval,
      amount,
      checkIsNativeTokenSymbol,
      symbol,
      supplyERC20,
      erc20InfoMap,
      submitTransactions,
      supplyNativeToken
    ]);

    const handleFinish = useCallback(() => {
      onFinish(formDataRef.current);
    }, [onFinish]);

    return (
      <FormSubmitter
        inProgressMessage="Securely Supplying"
        onError={onError}
        onFinish={handleFinish}
        submit={submit}
      />
    );
  }
);
