import { FC, useCallback } from 'react';
import { useMount } from 'react-use';

import { getApproveActions } from '@/apps/paraspace/utils/approve/getApproveActions';
import { useContractsMap } from '@/apps/paraspace/hooks';
import { useParallelToast, useWeb3Context } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { InProgressMessage } from '@/apps/paraspace/components/CollectAndSubmitProcedure/FormSubmitter/components';

type Props = {
  offer: {
    amount: string;
    token: string;
    offerer: string;
  };
  onFinish: () => void;
  onError: () => void;
};

export const CheckBidderAllowanceSubmitter: FC<Props> = ({
  onFinish,
  onError,
  offer: { amount, token, offerer }
}) => {
  const { provider } = useWeb3Context();
  const contracts = useContractsMap();
  const toast = useParallelToast();

  const handleSubmit = useCallback(async () => {
    if (!provider) {
      throw new Error('no initialed web3 provider');
    }

    const offerTokenList = [
      {
        amount,
        token
      }
    ];
    const actions = await getApproveActions(
      {
        provider: provider!,
        offer: offerTokenList,
        spender: contracts.Conduit
      },
      offerer
    );
    if (actions.length !== 0) {
      throw new Error(
        "The offerer's allowance is no longer sufficient. Please accept another offer."
      );
    }
    return null;
  }, [amount, contracts.Conduit, offerer, provider, token]);

  useMount(() => {
    toast.promise(
      handleSubmit()
        .then(() => {
          onFinish();
        })
        .catch(e => {
          onError();
          throw getUserFriendlyError(e, e.message);
        })
    );
  });
  return <InProgressMessage />;
};
