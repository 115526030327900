import {
  Button,
  H5,
  H6,
  Inline,
  SmallText,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import { FC, useCallback, useMemo, useState } from 'react';
import { CryptoIcon } from '@parallel-mono/business-components';
import { formatNumber, sumBy } from '@parallel-mono/utils';
import { isEmpty, isNil } from 'lodash';

import { Handlers } from '..';
import { InvisibleButton } from '../TokensTable';
import { getEffectiveApy } from '../../utils';
import { SupplyERC20DropdownMenu, WalletsBalanceDropdown } from '../../../components';
import { DISABLE_BORROW_UTILIZATION_THRESHOLD } from '../../../configs';

import { MAXIMUM_BALANCE_DECIMALS, MINIMUM_ACCOUNTABLE_NUM } from '@/apps/paraspace/pages/config';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import {
  getEffectiveSupplyApyTip,
  getEffectiveBorrowApyTip
} from '@/apps/paraspace/pages/Credit/MoneyMarket/utils/getEffectiveApyTip';
import { ExpandToggle } from '@/apps/paraspace/pages/Credit/MoneyMarket/StyledComponents';
import { ERC20TableRow } from '@/apps/paraspace/pages/Credit/MoneyMarket/ERC20Table/types';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { ERC20Symbol, FetchingStatus } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';
import { Tooltip } from '@/apps/paraspace/components';

export type ERC20CardProps = Omit<StackProps, 'children'> & {
  data: ERC20TableRow;
  handlers: Handlers;
};

const ERC20Card: FC<ERC20CardProps> = ({ data, handlers, ...others }) => {
  const {
    symbol,
    displayName,
    balance: paraXBalance,
    priceInUsd,
    supplyApyRate,
    borrowingEnabled,
    borrowApyRate,
    totalDebt,
    availableLiquidity,
    subRows = [],
    displayIcon
  } = data;
  const { effectiveCapeSupplyApy, effectiveCapeBorrowApy } = useAutoCompoundApeInfo();
  const utilization = totalDebt.div(totalDebt.plus(availableLiquidity));
  const { erc20BalanceMap, erc20BalanceMapPollingStatus } = useEOABalances();

  const isERC20BalanceLoading =
    erc20BalanceMap === null && erc20BalanceMapPollingStatus === FetchingStatus.FETCHING;

  const [isOpen, setIsOpen] = useState(true);
  const toggleExpandable = useCallback(() => {
    setIsOpen(v => !v);
  }, [setIsOpen]);

  const expandable = subRows?.length > 0;

  const { erc20Config, nativeTokenAndDerivatives } = useAppConfig();

  const isNativeTokenOrDerivatives = useMemo(
    () => nativeTokenAndDerivatives.includes(symbol),
    [nativeTokenAndDerivatives, symbol]
  );

  const effectiveSupplyApy = useMemo(
    () =>
      getEffectiveApy({
        baseApy: supplyApyRate,
        effectiveCapeApy: effectiveCapeSupplyApy,
        symbol,
        apy: erc20Config[symbol]?.apy,
        isNativeTokenOrDerivatives
      }),
    [supplyApyRate, effectiveCapeSupplyApy, symbol, erc20Config, isNativeTokenOrDerivatives]
  );

  const effectiveBorrowApy = useMemo(
    () =>
      getEffectiveApy({
        baseApy: borrowApyRate,
        effectiveCapeApy: effectiveCapeBorrowApy,
        symbol,
        apy: erc20Config[symbol]?.apy,
        isNativeTokenOrDerivatives
      }),
    [borrowApyRate, effectiveCapeBorrowApy, symbol, erc20Config, isNativeTokenOrDerivatives]
  );

  const effectiveSupplyApyToolTip = useMemo(
    () =>
      getEffectiveSupplyApyTip({
        symbol,
        baseApy: supplyApyRate,
        tooltipPlacement: 'left',
        apy: erc20Config[symbol]?.apy ?? null,
        isNativeTokenOrDerivatives
      }),
    [symbol, supplyApyRate, erc20Config, isNativeTokenOrDerivatives]
  );
  const effectiveBorrowApyToolTip = useMemo(
    () =>
      getEffectiveBorrowApyTip({
        symbol,
        baseApy: borrowApyRate,
        tooltipPlacement: 'left',
        apy: erc20Config[symbol]?.apy ?? null,
        isNativeTokenOrDerivatives
      }),
    [symbol, borrowApyRate, erc20Config, isNativeTokenOrDerivatives]
  );

  const EOABalance = useMemo(() => erc20BalanceMap?.[symbol] ?? zero, [erc20BalanceMap, symbol]);

  const totalEOABalance = useMemo(
    () =>
      isEmpty(subRows)
        ? EOABalance
        : sumBy(subRows ?? [], item => erc20BalanceMap?.[item.symbol] ?? 0),
    [EOABalance, erc20BalanceMap, subRows]
  );

  return (
    <Stack gap="1rem" {...others}>
      <Inline gap="0.5rem" alignItems="center" justifyContent="space-between">
        <Inline gap="0.5rem" alignItems="center">
          {displayIcon || <CryptoIcon symbol={symbol} />}
          <H5 fontWeight="bold">{displayName}</H5>
        </Inline>
        {expandable && <ExpandToggle onClick={toggleExpandable} open={isOpen} />}
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Your Balance
        </H6>
        {paraXBalance || !isERC20BalanceLoading ? (
          <Stack gap="0">
            <Text>
              {formatNumber(totalEOABalance.plus(paraXBalance ?? 0), {
                decimal: MAXIMUM_BALANCE_DECIMALS,
                threshold: {
                  min: MINIMUM_ACCOUNTABLE_NUM
                }
              })}
            </Text>
            <WalletsBalanceDropdown
              placement="bottom-end"
              paraXBalance={{
                loading: false,
                value: (
                  <Stack alignItems="flex-end" gap="0">
                    <H6>
                      {formatNumber(paraXBalance ?? 0, {
                        decimal: MAXIMUM_BALANCE_DECIMALS,
                        threshold: {
                          min: MINIMUM_ACCOUNTABLE_NUM
                        }
                      })}
                    </H6>
                    <SmallText skin="secondary">
                      {formatToCurrency(paraXBalance?.times(priceInUsd) ?? 0)}
                    </SmallText>
                  </Stack>
                )
              }}
              EOABalance={{
                loading: isERC20BalanceLoading,
                value: (
                  <Stack alignItems="flex-end" gap="0">
                    <H6>
                      {formatNumber(totalEOABalance ?? 0, {
                        decimal: MAXIMUM_BALANCE_DECIMALS,
                        threshold: {
                          min: MINIMUM_ACCOUNTABLE_NUM
                        }
                      })}
                    </H6>
                    <SmallText skin="secondary">
                      {formatToCurrency(priceInUsd.times(totalEOABalance ?? 0))}
                    </SmallText>
                  </Stack>
                )
              }}
            />
          </Stack>
        ) : (
          <Text>-</Text>
        )}
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Supply APY
        </H6>
        <Stack gap="0" alignItems="flex-end">
          <Inline alignItems="center" gap="0.25rem">
            {expandable && '~'}
            <Text>{effectiveSupplyApy}</Text>
            {effectiveSupplyApyToolTip}
          </Inline>
        </Stack>
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Borrow APY
        </H6>
        {borrowingEnabled ? (
          <Stack gap="0" alignItems="flex-end">
            <Inline alignItems="center" gap="0.25rem">
              {expandable && '~'}
              <Text>{effectiveBorrowApy}</Text>
              {effectiveBorrowApyToolTip}
            </Inline>
          </Stack>
        ) : (
          <Text>-</Text>
        )}
      </Inline>
      <Inline justifyContent="space-between">
        <H6 skin="secondary" fontWeight="medium">
          Total Supply
        </H6>
        <Stack gap="0" alignItems="flex-end">
          <Text>
            {formatNumber(totalDebt.plus(availableLiquidity), {
              decimal: MAXIMUM_BALANCE_DECIMALS,
              threshold: {
                min: MINIMUM_ACCOUNTABLE_NUM
              }
            })}
          </Text>
          <SmallText skin="secondary">
            {formatNumber(utilization, { output: 'percent' })}&nbsp;utilized
          </SmallText>
        </Stack>
      </Inline>
      <Inline justifyContent="space-between">
        <SupplyERC20DropdownMenu
          symbol={data.symbol as ERC20Symbol}
          menuTrigger={<Button block>Supply</Button>}
          placement="bottom-start"
          eoaWalletOption={{
            onClick: () => handlers.handleSupplyERC20FromEOA(data.symbol as ERC20Symbol)
          }}
          aaWalletOption={{
            onClick: () => handlers.handleSupplyERC20FromAA(data.symbol as ERC20Symbol)
          }}
        />
        <Tooltip
          disabled={utilization.lt(DISABLE_BORROW_UTILIZATION_THRESHOLD)}
          content="The current utilization rate is too high. Please try again later."
        >
          <InvisibleButton
            visible={data.borrowingEnabled}
            disabled={
              !data.borrowingEnabled || utilization.gte(DISABLE_BORROW_UTILIZATION_THRESHOLD)
            }
            skin="secondary"
            block
            onClick={() => {
              if (nativeTokenAndDerivatives.includes(data.symbol) || !isNil(data.tokenCategory)) {
                handlers.handleBorrowNativeTokenDerivatives(data.symbol);
              } else {
                handlers.handleBorrow(data);
              }
            }}
          >
            Borrow
          </InvisibleButton>
        </Tooltip>
      </Inline>
      {expandable &&
        isOpen &&
        subRows.map((token, index) => (
          <ERC20Card
            key={token.symbol + index}
            data={token as unknown as ERC20TableRow}
            handlers={handlers}
          />
        ))}
    </Stack>
  );
};

export default ERC20Card;
