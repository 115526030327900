import { FC, memo, useState, useEffect } from 'react';
import { Inline, Pagination, Responsive, Spinner, Stack } from '@parallel-mono/components';
import styled from 'styled-components';

import { PAGE_SIZE, useActiveMatches } from './hooks';
import { MatchPair } from './types';
import { MatchPairCard, SideBar, EmptyMatchPairCard } from './components';

import { zero } from '@/apps/paraspace/consts/values';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { useWeb3Context } from '@/apps/paraspace/contexts';

const SideBarWrapper = styled(Inline)`
  position: sticky;
  height: fit-content;
  top: calc(var(--header-height-pc) + 4.75rem);
  flex: 0 0 18.875rem;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    position: relative;
    top: 0;
    margin-bottom: 1rem;
    width: 100%;
  `};
`;

const StyledResponsive = styled(Responsive)`
  ${({ theme }) => theme.breakpoints.down('desktop')`
    flex-direction: column-reverse;
  `};
`;

export const ActiveMatches: FC = memo(() => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [activeMatches, setActiveMatches] = useState([] as MatchPair[]);
  const { nftPoolsCompoundApy } = useAutoCompoundApeInfo();
  const { account } = useWeb3Context();

  const { data, loading } = useActiveMatches(page);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (data && !loading) {
      setTotal(data.totalCount);
      setActiveMatches(data.nodes);
    }
  }, [loading, data]);

  const handlePaginationChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <StyledResponsive breakPoint="tablet">
      {loading && (
        <Inline width="100%" justifyContent="center">
          <Spinner />
        </Inline>
      )}
      {!loading && (
        <>
          {activeMatches.length > 0 ? (
            <Stack width="100%">
              {activeMatches.map((matchPair, index) => {
                const { apeListing, apeCoinListing, bakcListing } = matchPair;
                const stakingPool =
                  apeListing?.stakingPool ??
                  apeCoinListing?.stakingPool ??
                  bakcListing?.stakingPool;
                return (
                  <MatchPairCard
                    key={index}
                    poolApy={nftPoolsCompoundApy?.[stakingPool!] ?? zero}
                    apeListing={apeListing}
                    apeCoinListing={apeCoinListing}
                    bakcListing={bakcListing}
                    account={account}
                  />
                );
              })}
              <Pagination
                total={Math.ceil((total ?? 0) / PAGE_SIZE)}
                onChange={handlePaginationChange}
                page={page}
                siblingCount={0}
                startBoundaryCount={3}
              />
            </Stack>
          ) : (
            <EmptyMatchPairCard />
          )}
          <SideBarWrapper>
            <SideBar />
          </SideBarWrapper>
        </>
      )}
    </StyledResponsive>
  );
});
