import { Modal, ModalProps, useCallbackMerge } from '@parallel-mono/components';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';

import { SupplyERC20FormData } from './types';
import {
  ConnectWallet,
  SupplyERC20FromEOAFormSubmitter,
  SupplyERC20FromAAForm,
  SupplyERC20FromEOAForm,
  SupplyERC20FromAAFormSubmitter
} from './components';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import {
  CollectAndSubmitProcedurePhase,
  ErrorState,
  SuccessState,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { useConvertSymbolWithNetwork } from '@/apps/paraspace/pages/hooks';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';

export type SupplyType = 'EOA' | 'AA';

export type SupplyERC20ModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  type: SupplyType;
  onError?: () => void;
  onFinish?: () => void;
  defaultSymbol: ERC20Symbol;
  onClose: () => void;
};

export const SupplyERC20Modal = memo(
  ({
    type,
    onError,
    onFinish,
    isOpen,
    defaultSymbol,
    onClose,
    ...others
  }: SupplyERC20ModalProps) => {
    const internalHandleFinish = useCallback((result: SupplyERC20FormData) => {
      ReactGA.event({
        action: 'supply',
        category: 'erc20',
        label: result.symbol,
        value: result.amount?.toNumber() ?? undefined
      });
    }, []);

    const { convertWTokenToNativeToken } = useConvertSymbolWithNetwork();
    const handleFinish = useCallbackMerge(internalHandleFinish, onFinish);

    const { submittedFormData, phase, handleFormSubmit, handleSubmitFailed, handleSubmitSuccess } =
      useCollectAndSubmitProcedure<SupplyERC20FormData, SupplyERC20FormData, ErrorConfig>({
        onError,
        onFinish: handleFinish,
        running: isOpen
      });
    const { erc20InfoMap } = useMMProvider();
    const { isUsingUserWallet } = useWeb3Context();

    const [currentSymbol, setCurrentSymbol] = useState(defaultSymbol);
    const symbolName = useMemo(() => {
      const finalSymbol = convertWTokenToNativeToken(currentSymbol);
      return erc20InfoMap[finalSymbol]?.displayName ?? '';
    }, [currentSymbol, erc20InfoMap, convertWTokenToNativeToken]);

    const handleFormDataChange = useCallback(({ symbol }: { symbol: ERC20Symbol }) => {
      setCurrentSymbol(symbol);
    }, []);

    useEffect(() => {
      if (isOpen) {
        setCurrentSymbol(defaultSymbol);
      }
    }, [defaultSymbol, isOpen]);

    return (
      <Modal
        title={`Supply ${symbolName} Tokens`}
        size="500px"
        isOpen={isOpen}
        onClose={onClose}
        closeOnBackdropClick={false}
        {...others}
      >
        {!isUsingUserWallet && <ConnectWallet symbol={defaultSymbol} onConnectWallet={onClose} />}
        {isUsingUserWallet &&
          phase === CollectAndSubmitProcedurePhase.Collecting &&
          (type === 'AA' ? (
            <SupplyERC20FromAAForm
              onSubmit={handleFormSubmit}
              defaultSymbol={defaultSymbol}
              onChange={handleFormDataChange}
            />
          ) : (
            <SupplyERC20FromEOAForm
              onSubmit={handleFormSubmit}
              defaultSymbol={defaultSymbol}
              onChange={handleFormDataChange}
            />
          ))}
        {isUsingUserWallet &&
          phase === CollectAndSubmitProcedurePhase.Submitting &&
          (type === 'AA' ? (
            <SupplyERC20FromAAFormSubmitter
              formData={submittedFormData!}
              onFinish={handleSubmitSuccess}
              onError={handleSubmitFailed}
            />
          ) : (
            <SupplyERC20FromEOAFormSubmitter
              formData={submittedFormData!}
              onError={handleSubmitFailed}
              onFinish={handleSubmitSuccess}
            />
          ))}
        {isUsingUserWallet && phase === CollectAndSubmitProcedurePhase.Failed && (
          <ErrorState closeModal={onClose} />
        )}
        {isUsingUserWallet && phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            desc={`Successfully Supplied ${formatBalance(submittedFormData?.amount)} ${symbolName}`}
          />
        )}
      </Modal>
    );
  }
);
