import { memo } from 'react';

import { NFTCollectionThumbnail, NFTThumbnail } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const Thumbnail = memo(
  ({
    considerationItem
  }: {
    considerationItem: { tokenId: Maybe<number>; symbol: ERC721Symbol };
  }) => {
    return considerationItem.tokenId ? (
      <NFTThumbnail
        symbol={considerationItem.symbol}
        tokenId={considerationItem.tokenId}
        size="large"
      />
    ) : (
      <NFTCollectionThumbnail symbol={considerationItem.symbol} size="large" />
    );
  }
);
