import { FC, useCallback } from 'react';
import { H5, Inline, Toggle, Typography, TypographyVariant } from '@parallel-mono/components';
import styled from 'styled-components';

const Text = styled(Typography)`
  color: ${({ theme }) => theme.skin.grey[700]};
`;

export const ETHToggle: FC<{
  title: string;
  desc: string;
  checked: boolean;
  onCheck: (check: boolean) => void;
}> = ({ title, desc, checked, onCheck, ...props }) => {
  const handleChange = useCallback(
    newValue => {
      onCheck(newValue);
    },
    [onCheck]
  );

  return (
    <Inline justifyContent="space-between" {...props}>
      <div>
        <H5>{title}</H5>
        <Text variant={TypographyVariant.body}>{desc}</Text>
      </div>
      <Toggle checked={checked} onChange={handleChange} />
    </Inline>
  );
};
