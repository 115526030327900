import {
  Button,
  Card,
  H4,
  H5,
  Inline,
  NumericInput,
  SmallText,
  Stack,
  Text
} from '@parallel-mono/components';
import styled from 'styled-components';
import { InfoPanel } from '@parallel-mono/business-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatNumber } from '@parallel-mono/utils';
import { BigNumber } from 'bignumber.js';

import { ApeCoinSourceSelect } from '../../../components';
import { ApeCoinSourceToJoinApeListing } from '../../../types';

import Duration from './Duration';
import ShareApyInput from './ShareApyInput';
import CreateListingModal from './CreateListingModal';

import { STAKE_LIMIT } from '@/apps/paraspace/pages/OfficialPairing/ApeStakingManagerProvider/types';
import {
  ApeStakingMainAssetSymbol,
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC20Symbol,
  ERC721Symbol,
  WalletType
} from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { toPercentageCompoundAPY } from '@/apps/paraspace/utils/format';
import { tryRoundUpCapeBalance } from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/utils';
import { StakingType } from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';
import { HOURS_PER_DAY } from '@/apps/paraspace/consts/fixtures';
import { zero } from '@/apps/paraspace/consts/values';

const StyledNumber = styled.div<{ selected?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border: ${({ selected, theme }) =>
    selected ? `2px solid ${theme.skin.grey[700]}` : `2px solid ${theme.skin.grey[200]}`};
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
`;

const StyledNumericInput = styled(NumericInput)`
  flex: 2;
  width: 0;
  font-size: 1rem;
  font-weight: bold;
  background: transparent;

  &::placeholder {
    text-align: center;
  }
`;

export type CreateApeCoinSelector = {
  walletType: WalletType;
  p2pToken: ERC20Symbol.APE;
  p2pPool: ApeStakingTokenSymbol;
  poolAPY?: BigNumber;
};

const CreateApeCoinListing = ({ selectorValue }: { selectorValue: CreateApeCoinSelector }) => {
  const [numberOfListings, setNumberOfListings] = useState(1);
  const [apeTokenSource, setApeTokenSource] = useState<ApeCoinSourceToJoinApeListing>(
    ApeCoinSourceToJoinApeListing.APE_BALANCE
  );
  const [selectedPoolSymbol, setSelectedPoolSymbol] = useState<ApeStakingTokenSymbol>(
    ERC721Symbol.BAYC
  );
  const [sharedAPY, setSharedAPY] = useState<number | null>(null);
  const [duration, setDuration] = useState(HOURS_PER_DAY * 7);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { erc20InfoMap } = useMMProvider();
  const { erc20BalanceMap } = useEOABalances();

  const apeBalance =
    (selectorValue.walletType === 'AA'
      ? erc20InfoMap[ERC20Symbol.APE]?.balance
      : erc20BalanceMap?.[ERC20Symbol.APE]) ?? zero;

  const cApeBalanceAmount =
    (selectorValue.walletType === 'AA'
      ? erc20InfoMap[ERC20Symbol.CAPE]?.balance
      : erc20BalanceMap?.[ERC20Symbol.CAPE]) ?? zero;

  useEffect(() => {
    setSelectedPoolSymbol(selectorValue.p2pPool as ApeStakingMainTokenSymbol);
    setNumberOfListings(1);
  }, [selectorValue.p2pPool]);

  const handleCustomNumberChange = useCallback((value: number | null) => {
    setNumberOfListings(value ?? 1);
  }, []);

  const handleSharedAPYChange = useCallback((value: number | null) => {
    setSharedAPY(value ?? null);
  }, []);

  const panelInfos = useMemo(() => {
    const youSeekValue =
      selectedPoolSymbol === ERC721Symbol.BAKC ? 'BAYC/MAYC, BAKC' : selectedPoolSymbol;
    return [
      {
        title: 'Staking Pool',
        value: selectedPoolSymbol
      },
      {
        title: 'Pool APY',
        value: selectorValue.poolAPY ? toPercentageCompoundAPY(selectorValue.poolAPY) : '-'
      },
      {
        title: 'You Provide',
        value: `${formatNumber(numberOfListings * STAKE_LIMIT[selectedPoolSymbol])} ApeCoin`
      },
      {
        title: 'You Seek',
        value: youSeekValue
      },
      {
        title: 'Your APY',
        value: (
          <Text skin="success">
            {sharedAPY && selectorValue.poolAPY
              ? formatNumber(selectorValue.poolAPY.times(sharedAPY), {
                  output: 'percent'
                })
              : '-'}
          </Text>
        )
      }
    ];
  }, [numberOfListings, selectedPoolSymbol, selectorValue.poolAPY, sharedAPY]);

  const handleCreateListing = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const balanceInUse = useMemo(() => {
    if (apeTokenSource === ApeCoinSourceToJoinApeListing.APE_BALANCE) {
      return apeBalance;
    }

    return tryRoundUpCapeBalance(cApeBalanceAmount);
  }, [apeBalance, apeTokenSource, cApeBalanceAmount]);

  const isExceedBalance = useMemo(() => {
    return balanceInUse?.lt(numberOfListings * STAKE_LIMIT[selectedPoolSymbol]);
  }, [balanceInUse, numberOfListings, selectedPoolSymbol]);

  const stakingType = useMemo(() => {
    if (selectedPoolSymbol === ERC721Symbol.BAKC) {
      return StakingType.BAKCPairStaking;
    }
    if (selectedPoolSymbol === ERC721Symbol.BAYC) {
      return StakingType.BAYCStaking;
    }
    return StakingType.MAYCStaking;
  }, [selectedPoolSymbol]);

  return (
    <Card border>
      <Stack>
        <H4>Offer Details</H4>
        <Stack gap=".75rem">
          <Stack>
            <Inline justifyContent="space-between" alignItems="center">
              <H5>Offer Using</H5>
              <ApeCoinSourceSelect
                walletType={selectorValue.walletType}
                value={apeTokenSource}
                onChange={setApeTokenSource}
              />
            </Inline>
          </Stack>
          <Stack gap="0">
            <H5>Number of Listings</H5>
            <Inline justifyContent="space-between">
              <SmallText skin="secondary">
                1 Listing = {formatNumber(STAKE_LIMIT[selectedPoolSymbol])} ApeCoin
              </SmallText>
              <SmallText skin="secondary">Balance {formatNumber(balanceInUse)} APE</SmallText>
            </Inline>
          </Stack>
          <Inline gap=".75rem">
            {[1, 2, 3, 4].map(value => (
              <StyledNumber
                key={value}
                selected={value === numberOfListings}
                onClick={() => {
                  setNumberOfListings(value);
                }}
              >
                {value}
              </StyledNumber>
            ))}
            <StyledNumericInput
              placeholder="Custom"
              decimals={0}
              min={1}
              max={100}
              onChange={handleCustomNumberChange}
            />
          </Inline>
        </Stack>
        <ShareApyInput percentage={sharedAPY} onPercentageChange={handleSharedAPYChange} />
        <Duration duration={duration} onHandleDurationChange={value => setDuration(value)} />
        <InfoPanel skin="primary" infos={panelInfos} />
        <Button
          size="large"
          block
          disabled={isExceedBalance || !sharedAPY || isModalOpen}
          onClick={handleCreateListing}
        >
          {isExceedBalance ? 'Insufficient ApeCoin' : 'Confirm'}
        </Button>
      </Stack>
      <CreateListingModal
        walletType={selectorValue.walletType}
        stakingType={stakingType}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        offerSymbol={selectorValue.p2pToken as ApeStakingMainAssetSymbol}
        seekSymbol={selectorValue.p2pPool as ApeStakingTokenSymbol}
        apeCoinSource={apeTokenSource}
        numberOfListings={numberOfListings}
        wantedPercentage={sharedAPY!}
        duration={duration}
      />
    </Card>
  );
};

export default CreateApeCoinListing;
