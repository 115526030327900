import { Inline, InlineProps, useBreakpoints } from '@parallel-mono/components';
import { NavLink } from 'react-router-dom';
import { HostedImage } from '@parallel-mono/business-components';
import styled from 'styled-components';
import { FC, memo } from 'react';

import { useValidApps } from '../../hooks';

HostedImage.displayName = 'HostedImage';
(window as any).HostedImage = HostedImage;

export const LogoIcon = styled(HostedImage).attrs({
  name: `design/PDS_V3/logo/parallel-v2-logo-with-name`
})`
  display: block;
`;

const IconContainer = styled(Inline)`
  ${({ theme }) => theme.breakpoints.down('desktop')`
    flex-wrap: wrap;
  `};
`;

const LogoLink = styled(NavLink)`
  text-decoration: none;
`;

export const Logo: FC<InlineProps> = memo(({ ...others }) => {
  const { desktop: isDesktop } = useBreakpoints();
  const { internalApps } = useValidApps();
  return (
    <IconContainer gap={isDesktop ? '1rem' : '0.5rem'} alignItems="center" {...others}>
      <LogoLink to={internalApps[0] ? `/apps/${internalApps[0].route}` : '/'}>
        <Inline gap="0.25rem" alignItems="center">
          <LogoIcon height={isDesktop ? '1.75rem' : '1.5rem'} />
        </Inline>
      </LogoLink>
    </IconContainer>
  );
});
