import { useCallback, useMemo } from 'react';
import {
  BatchEventResultCallback,
  ERC721Service,
  EthereumTransactionTypeExtended,
  Pool
} from 'paraspace-utilities-contract-helpers';
import BigNumberJs from 'bignumber.js';
import { constants } from 'ethers';
import { ParaXProvider } from 'parax-sdk';

import { notEmpty } from '../../utils/notEmpty';

import useLiquidationAPI from './useLiquidationAPI';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ERC20InfoMap, ERC721Symbol, NFTInfoMap, Platform } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { getMarketplaceId, getMarketplaceIds } from '@/apps/paraspace/utils/getMarketplaceId';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useContractsMap } from '@/apps/paraspace/hooks';
import submitTransaction from '@/apps/paraspace/utils/submitTransaction';

export type UserAccountData = {
  account: string;
  totalCollateralBase: BigNumberJs;
  totalDebtBase: BigNumberJs;
  availableBorrowsBase: BigNumberJs;
  currentLiquidationThreshold: BigNumberJs;
  ltv: BigNumberJs;
  healthFactor: BigNumberJs;
  nftHealthFactor: BigNumberJs;
};

const useMovePositionFromBendDAO = ({
  provider,
  service
}: {
  provider: ParaXProvider;
  service: Maybe<Pool>;
}) => {
  const { submitEOATransactions } = useWeb3Context();

  return useCallback(
    async (loanIds: string[], from: string, to: string) => {
      if (!provider || !service) return null;
      const tx = service.movePositionFromBendDAO(loanIds, from, to);
      return submitEOATransactions(tx);
    },
    [provider, service, submitEOATransactions]
  );
};

const useRemoveUniswapV3Liquidity = ({
  provider,
  service,
  account,
  erc20InfoMap,
  nftInfoMap
}: {
  provider: ParaXProvider;
  service: Maybe<Pool>;
  account: string;
  erc20InfoMap: ERC20InfoMap;
  nftInfoMap: NFTInfoMap;
}) =>
  useCallback(
    async (
      tokenId: string,
      liquidityDecrease: string,
      amount0Min: BigNumberJs,
      amount1Min: BigNumberJs
    ) => {
      if (!provider || !service) return null;
      const { tokenSpecificInfos, address } = nftInfoMap[ERC721Symbol.UNISWAP_LP] ?? {};
      const { token0Symbol, token1Symbol } = tokenSpecificInfos[tokenId];
      const token0Decimal = erc20InfoMap[token0Symbol].decimals;
      const token1Decimal = erc20InfoMap[token1Symbol].decimals;

      const tx = service.decreaseLiquidity({
        asset: address,
        tokenId,
        liquidityDecrease,
        amount0Min: amount0Min.times(10 ** token0Decimal).toFixed(0),
        amount1Min: amount1Min.times(10 ** token1Decimal).toFixed(0),
        onBehalfOf: account,
        // abstract this as a parameter if we need to support receive WETH
        receiveETHAsWETH: false
      });
      return submitTransaction({ provider, tx });
    },
    [provider, service, nftInfoMap, erc20InfoMap, account]
  );

const HEALTH_FACTOR_DECIMAL = 10 ** 18;
/* eslint max-lines-per-function: ["error", { "max": 590 }] */
const usePool = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();
  const { nftInfoMap, erc20InfoMap } = useMMProvider();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new Pool(provider, {
      POOL: contracts.PoolProxy,
      WETH_GATEWAY: contracts.WETHGatewayProxy
    });
  }, [contracts.PoolProxy, contracts.WETHGatewayProxy, provider]);

  const getUserAccountData = useCallback(
    async (user: string = account) => {
      if (!service) {
        return undefined;
      }
      const accountData = await service.getUserAccountData({ user });
      return {
        account: user,
        totalCollateralBase: new BigNumberJs(accountData.totalCollateralBase.toString()),
        totalDebtBase: new BigNumberJs(accountData.totalDebtBase.toString()),
        availableBorrowsBase: new BigNumberJs(accountData.availableBorrowsBase.toString()),
        currentLiquidationThreshold: new BigNumberJs(
          accountData.currentLiquidationThreshold.toString()
        ),
        ltv: new BigNumberJs(accountData.ltv.toString()),
        healthFactor: new BigNumberJs(accountData.healthFactor.toString()).dividedBy(
          HEALTH_FACTOR_DECIMAL
        ),
        nftHealthFactor: new BigNumberJs(accountData.erc721HealthFactor.toString()).dividedBy(
          HEALTH_FACTOR_DECIMAL
        )
      };
    },
    [service, account]
  );

  const getHealthFactor = useCallback(async () => {
    if (!service) {
      return undefined;
    }
    const accountData = await service.getUserAccountData({ user: account });
    return new BigNumberJs(accountData.healthFactor.toString()).dividedBy(HEALTH_FACTOR_DECIMAL);
  }, [account, service]);

  const borrowERC20 = useCallback(
    async (assetAddr: string, amount: string) => {
      if (!service) {
        return null;
      }
      return (
        await service.borrow({
          user: account,
          amount,
          reserve: assetAddr
        })
      )[0];
    },
    [account, service]
  );

  const supplyERC20 = useCallback(
    async ({
      assetAddr,
      amount,
      from,
      onBehalfOf
    }: {
      assetAddr: string;
      amount: string;
      from?: string;
      onBehalfOf?: string;
    }) => {
      if (!provider || !service) {
        return null;
      }
      const txs = await service.supply({
        user: from ?? account,
        amount,
        reserve: assetAddr,
        onBehalfOf: onBehalfOf ?? account
      });
      if (txs.length !== 1) {
        return txs[1];
      }

      return txs[0];
    },
    [account, provider, service]
  );

  const withdrawERC20 = useCallback(
    async (asset: string, amount: string, max: boolean = false) => {
      if (!service) {
        return null;
      }
      return (
        await service.withdraw({
          user: account,
          reserve: asset,
          amount: max ? '-1' : amount
        })
      )[0];
    },
    [account, service]
  );

  const repayERC20 = useCallback(
    async (params: { assetAddr: string; amount: string; max?: boolean; from?: string }) => {
      const { assetAddr, amount, max = false, from = account } = params;
      if (!provider || !service) {
        return null;
      }

      const txs = await service.repay({
        user: from,
        reserve: assetAddr,
        amount: max ? '-1' : amount,
        onBehalfOf: account
      });

      return txs[0];
    },
    [account, provider, service]
  );

  const repayERC20WithPTokens = useCallback(
    async (params: { assetAddr: string; amount: string; max: boolean; user?: string }) => {
      const { assetAddr, amount, max = false, user = account } = params;
      if (!provider || !service) {
        return null;
      }

      const txs = await service.repayWithPTokens({
        user,
        reserve: assetAddr,
        amount: max ? '-1' : amount
      });

      return txs[0];
    },
    [account, provider, service]
  );

  const supplyERC721 = useCallback(
    async (assetAddr: string, tokenIds: string[], from?: string) => {
      if (!provider || !service) {
        return null;
      }
      const txs = await service.supplyERC721({
        user: from ?? account,
        reserve: assetAddr,
        token_ids: tokenIds,
        onBehalfOf: account // pToken
      });
      if (txs.length !== 1) {
        return txs[1];
      }
      return txs[0];
    },
    [account, provider, service]
  );

  const withdrawERC721 = useCallback(
    async (assetAddr: string, tokenIds: string[]) => {
      if (!service) {
        return null;
      }

      return (
        await service.withdrawERC721({
          user: account,
          reserve: assetAddr,
          token_ids: tokenIds
        })
      )[0];
    },
    [account, service]
  );

  const setERC721Collateral = useCallback(
    async (assetAddr: string, tokenIds: number[], usageAsCollateral: boolean) => {
      if (!provider || !service) {
        return null;
      }
      const tx = (
        await service.setUserUseERC721AsCollateral({
          user: account,
          reserve: assetAddr,
          tokenIds,
          usageAsCollateral
        })
      )[0];
      return submitTransaction({ provider, tx });
    },
    [account, provider, service]
  );

  const setERC20Collateral = useCallback(
    async (assetAddr: string, usageAsCollateral: boolean) => {
      if (!provider || !service) {
        return null;
      }
      const tx = (
        await service.setUsageAsCollateral({
          user: account,
          reserve: assetAddr,
          usageAsCollateral
        })
      )[0];
      return submitTransaction({ provider, tx });
    },
    [account, provider, service]
  );

  const initiateBlurBuyWithCredit = useCallback(
    async (
      list: {
        price: string;
        payLaterAmount: string;
        collection: string;
        tokenId: number;
      }[]
    ) => {
      if (!provider || !service) {
        return null;
      }
      const tx = (await service.initiateBlurExchangeRequest(
        list.map(({ price, payLaterAmount, collection, tokenId }) => ({
          initiator: account,
          // hardcode ETH for now
          paymentToken: constants.AddressZero,
          listingPrice: price,
          borrowAmount: payLaterAmount,
          collection,
          tokenId
        })),
        account
      )) as EthereumTransactionTypeExtended;
      return submitTransaction({ provider, tx });
    },
    [account, provider, service]
  );

  const listenBlurExchangeRequestResultEvent = useCallback(
    (list: { collection: string; tokenId: number }[], callback: BatchEventResultCallback) => {
      if (!provider || !service) {
        return;
      }
      service.listenBlurExchangeRequestResultEvent(list, callback);
    },
    [provider, service]
  );

  const buyWithCredit = useCallback(
    async ({
      buyNowAmount,
      payLaterAmount,
      platform,
      protocolVersion,
      protocolContract,
      marketProtocolData
    }: {
      buyNowAmount: string;
      payLaterAmount: string;
      platform: Platform;
      protocolContract?: string;
      protocolVersion?: string;
      marketProtocolData: any;
    }) => {
      if (!provider || !service) {
        return null;
      }
      const tx = (
        await service.buyWithCredit({
          marketPlaceType: getMarketplaceId(platform, protocolVersion),
          // TODO: update once paraspace seaport has migrated to v1.4
          marketProtocolAddr: protocolContract || contracts.Seaport,
          marketProtocolData: {
            ...marketProtocolData,
            numerator: 1,
            denominator: 1,
            extraData: '0x'
          },
          onBehalfOf: account,
          buyNowAmount,
          payLaterAmount
        })
      )[0];
      return submitTransaction({ provider, tx });
    },
    [provider, service, contracts.Seaport, account]
  );

  const batchBuyWithCredit = useCallback(
    ({
      buyNowAmount,
      payLaterAmounts,
      platforms,
      marketProtocolData,
      protocolContracts,
      protocolVersions
    }: {
      buyNowAmount: string;
      payLaterAmounts: string[];
      platforms: Platform[];
      marketProtocolData: any[];
      protocolContracts: string[];
      protocolVersions: string[];
    }) => {
      if (!service) {
        throw new Error('service not ready');
      }
      return service.batchBuyWithCredit({
        buyNowAmount,
        payLaterAmount: payLaterAmounts,
        marketPlaceTypes: getMarketplaceIds(platforms, protocolVersions),
        marketProtocolAddrs: protocolContracts,
        marketProtocolData: marketProtocolData.map(item => ({
          ...item,
          numerator: 1,
          denominator: 1,
          extraData: '0x'
        })),
        onBehalfOf: account
      });
    },
    [service, account]
  );

  const makeERC721Approver = useCallback(
    async ({
      user,
      contractAddress,
      spender,
      tokenId
    }: {
      user: string;
      contractAddress: string;
      spender: string;
      tokenId: string;
    }) => {
      const erc721Service = new ERC721Service(provider);
      const hasApproved = await erc721Service.isApproved({
        user,
        token: contractAddress,
        spender,
        token_id: tokenId
      });
      if (hasApproved) {
        return;
      }
      const tx = erc721Service.approve({
        user,
        token: contractAddress,
        spender,
        token_id: tokenId
      });

      const submit = await submitTransaction({ provider, tx });
      await submit.wait();
    },
    [provider]
  );

  const {
    startAuction,
    endAuction,
    setAuctionValidityTime,
    getAuctionData,
    updateERC721HFValidityTime,
    liquidationERC721
  } = useLiquidationAPI(service, provider, account);

  const getUserConfiguration = useCallback(
    async (user: string) => {
      if (!service) return null;
      return service.getUserConfiguration({ user });
    },
    [service]
  );

  const removeUniswapV3Liquidity = useRemoveUniswapV3Liquidity({
    provider,
    service,
    account,
    erc20InfoMap,
    nftInfoMap
  });

  const movePositionFromBendDAO = useMovePositionFromBendDAO({
    provider,
    service
  });

  const reStake = useCallback(
    ({
      nftAsset,
      cApeAddress,
      pcApeAddress,
      nfts,
      pairs
    }: {
      nftAsset: string;
      cApeAddress: string;
      pcApeAddress: string;
      nfts: Array<{ tokenId: number; amount: string }>;
      pairs: Array<{
        bakcTokenId: number;
        mainTokenId: number;
        amount: string;
      }>;
    }) => {
      if (!provider || !service) {
        throw new Error('Provider or Service not ready');
      }

      const reStakeApeTx =
        nfts.length > 0
          ? service.reStake({
              nftAsset,
              cApeAddress,
              pcApeAddress,
              nfts,
              pairs: [],
              user: account
            })
          : null;

      const reStakeBakcTx =
        pairs.length > 0
          ? service.reStake({
              nftAsset,
              cApeAddress,
              pcApeAddress,
              nfts: [],
              pairs,
              user: account
            })
          : null;

      return [reStakeApeTx, reStakeBakcTx].filter(notEmpty);
    },
    [provider, service, account]
  );

  return {
    supplyERC20,
    withdrawERC20,
    repayERC20,
    repayERC20WithPTokens,
    supplyERC721,
    withdrawERC721,
    borrowERC20,
    setERC721Collateral,
    setERC20Collateral,
    getHealthFactor,
    buyWithCredit,
    movePositionFromBendDAO,
    batchBuyWithCredit,
    startAuction,
    endAuction,
    setAuctionValidityTime,
    initiateBlurBuyWithCredit,
    listenBlurExchangeRequestResultEvent,
    makeERC721Approver,
    getAuctionData,
    updateERC721HFValidityTime,
    liquidationERC721,
    getUserConfiguration,
    getUserAccountData,
    removeUniswapV3Liquidity,
    reStake
  };
};
export default usePool;
