import BigNumberJs from 'bignumber.js';
import { useMemo } from 'react';

import { formatBalance } from '@/apps/paraspace/utils/format';

export const useErrors = ({
  futureAvailableCredit,
  creditAmount,
  buyNowAmount,
  balance,
  availableLiquidity,
  isBuyLoading
}: {
  futureAvailableCredit: BigNumberJs;
  availableLiquidity: BigNumberJs;
  balance?: BigNumberJs;
  creditAmount: BigNumberJs;
  buyNowAmount: BigNumberJs;
  isBuyLoading: boolean;
}) => {
  const isInsufficientCredit = futureAvailableCredit.lt(creditAmount);
  const isInsufficientBalance = balance ? balance.lt(buyNowAmount) : true;
  const isInsufficientLiquidity = availableLiquidity && availableLiquidity.lt(creditAmount);

  const alertText = useMemo(() => {
    if (isInsufficientLiquidity) {
      if (availableLiquidity.gt(0)) {
        return `Borrow amount exceeds market liquidity, please enter an amount below ${formatBalance(
          availableLiquidity
        )} ETH.`;
      }
      return 'Borrow amount exceeds market liquidity.';
    }
    return null;
  }, [availableLiquidity, isInsufficientLiquidity]);

  const buttonErrorText = useMemo(() => {
    if (isBuyLoading) return 'Loading';
    if (isInsufficientCredit) return 'Insufficient Credit';
    if (isInsufficientBalance) return 'Insufficient Balance';
    return null;
  }, [isBuyLoading, isInsufficientBalance, isInsufficientCredit]);

  return { alertText, buttonErrorText };
};
