import { useCallback } from 'react';
import { OrderComponents } from 'paraspace-seaport-js/lib/types';

import { ProtocolData, useCancelOrdersMutation } from '@/apps/paraspace/generated/graphql';
import { useContractsMap } from '@/apps/paraspace/hooks';
import useSeaport from '@/apps/paraspace/pages/hooks/useSeaport';
import { sleep } from '@/apps/paraspace/utils/sleep';

type CancelOrderData = {
  orderHash: string;
  protocolData: ProtocolData;
};

export const useCancelOrders = (refreshHandler: string[]) => {
  const { seaportSDK, cancelOrders: cancelOrdersFromSeaport } = useSeaport();
  const contracts = useContractsMap();
  const [cancelOrdersMutation] = useCancelOrdersMutation({
    refetchQueries: refreshHandler
  });

  const checkOrderStatAndCancel = useCallback(
    async (orderHashes: string[]) => {
      const originalStatus = await Promise.all(
        orderHashes.map(orderHash => seaportSDK?.getOrderStatus(orderHash))
      );
      if (
        originalStatus.some(status => status?.isCancelled) ||
        originalStatus.some(status => status?.totalFilled === status?.totalSize)
      ) {
        await cancelOrdersMutation({
          variables: {
            orderHashes
          }
        });
        return true;
      }
      return false;
    },
    [cancelOrdersMutation, seaportSDK]
  );

  const cancelOrders = useCallback(
    async (data: CancelOrderData[]) => {
      const cancelOrderList = data.map(({ protocolData }) => ({
        ...protocolData.parameters,
        conduitKey: contracts.ConduitKey
      }));

      const orderHashes = data.map(({ orderHash }) => orderHash);

      const orderCancelStat = await checkOrderStatAndCancel(orderHashes);
      if (!orderCancelStat) {
        const cancelRet = await cancelOrdersFromSeaport(
          cancelOrderList as unknown as OrderComponents[]
        );
        await cancelRet?.wait();
        let finalOrderStat = await checkOrderStatAndCancel(orderHashes);
        while (!finalOrderStat) {
          // eslint-disable-next-line no-await-in-loop
          await sleep(1000);
          // eslint-disable-next-line no-await-in-loop
          finalOrderStat = await checkOrderStatAndCancel(orderHashes);
        }
      }
    },
    [checkOrderStatAndCancel, contracts.ConduitKey, cancelOrdersFromSeaport]
  );

  return {
    cancelOrders
  };
};
