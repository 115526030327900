import BigNumberJs from 'bignumber.js';
import { isNil } from 'lodash';
import { useMemo } from 'react';

import { Maybe } from '@/apps/paraspace/typings/basic';

export const useValidation = ({
  payAmount,
  borrowAmount,
  walletBalance,
  futureAvailableCredit,
  availableLiquidity
}: {
  payAmount: Maybe<number>;
  borrowAmount: Maybe<number>;
  walletBalance?: BigNumberJs;
  futureAvailableCredit: BigNumberJs;
  availableLiquidity: BigNumberJs;
}) =>
  useMemo(() => {
    if (!isNil(payAmount) && !isNil(walletBalance) && walletBalance.lt(payAmount)) {
      return 'Insufficient Balance';
    }
    if (!isNil(borrowAmount) && futureAvailableCredit.lt(borrowAmount)) {
      return 'Insufficient Credit';
    }
    if (!isNil(borrowAmount) && availableLiquidity.lt(borrowAmount)) {
      return 'Insufficient Liquidity';
    }
    return null;
  }, [availableLiquidity, borrowAmount, futureAvailableCredit, payAmount, walletBalance]);
