import { useCallback, useMemo } from 'react';
import { ethers } from 'ethers';
import { ERC20Service } from 'paraspace-utilities-contract-helpers';
import BigNumberJs from 'bignumber.js';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

const MAX_ALLOWANCE = ethers.constants.MaxUint256.toString();

const useV1ERC20 = (assetAddr: string) => {
  const {
    submitEOATransactions,
    authentication: {
      meta: { provider: EOAProvider, account }
    }
  } = useWeb3Context();

  const contracts = useContractsMap();
  const service = useMemo(() => {
    if (!EOAProvider || !assetAddr) {
      return null;
    }

    return new ERC20Service(EOAProvider);
  }, [assetAddr, EOAProvider]);

  const approve = useCallback(
    async (spender: string = contracts.PoolProxy, amount: string = MAX_ALLOWANCE) => {
      if (!EOAProvider || !service) {
        return null;
      }

      const normalizedAmount = new BigNumberJs(amount).gt(MAX_ALLOWANCE) ? MAX_ALLOWANCE : amount;
      const tx = await service.approve({
        user: account,
        spender,
        token: assetAddr,
        amount: normalizedAmount
      });
      const txRes = submitEOATransactions(tx);
      return txRes;
    },
    [contracts.PoolProxy, EOAProvider, service, account, assetAddr, submitEOATransactions]
  );

  const getAllowance = useCallback(
    async (spender: string = contracts.PoolProxy, user = account) => {
      if (!service) {
        return '0';
      }
      return service.getAllowance({
        user,
        token: assetAddr,
        spender
      });
    },
    [account, assetAddr, contracts.PoolProxy, service]
  );

  const getBalance = useCallback(
    async (user: string = account, token: string = assetAddr) => {
      if (!service) {
        return new BigNumberJs(0);
      }
      return new BigNumberJs((await service.balanceOf({ user, token })).toString());
    },
    [account, assetAddr, service]
  );

  return { approve, getAllowance, getBalance };
};

export default useV1ERC20;
