import { noop } from 'lodash';
import { useCallback, useState } from 'react';

import { AutoSellModalProps } from '../AutoSellModal';

export type SetAutoSellParams = Pick<AutoSellModalProps, 'sellToken' | 'sellPercentage'>;

const defaultModalProps: AutoSellModalProps = {
  isOpen: false,
  sellPercentage: null,
  sellToken: null,
  onClose: noop
};

export const useSetAutoSellStrategy = (): [
  AutoSellModalProps,
  (params: SetAutoSellParams) => Promise<void>
] => {
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const setAutoSellStrategy = useCallback(
    ({ sellToken, sellPercentage }: SetAutoSellParams) => {
      if (modalProps.isOpen) {
        throw new Error('There is a set auto sell in progress.');
      }
      return new Promise<void>(resolve => {
        setModalProps({
          isOpen: true,
          sellToken,
          sellPercentage,
          onClose: () => {
            setModalProps(curr => ({
              ...curr,
              isOpen: false
            }));
            resolve();
          }
        });
      });
    },
    [modalProps.isOpen]
  );

  return [modalProps, setAutoSellStrategy];
};
