import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, H5, Stack } from '@parallel-mono/components';
import BigNumberJs from 'bignumber.js';
import { floor } from 'lodash';
import { formatNumber } from '@parallel-mono/utils';
import {
  TokenOption,
  Value
} from '@parallel-mono/business-components/components/SelectableTokenInput/types';

import LiquidityBorrowLimitBar from './LiquidityBorrowLimitBar';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import {
  getToken0AmountForToken1Amount,
  getToken1AmountForToken0Amount
} from '@/apps/paraspace/utils/getTokenAmountToAddLiquidity';
import useLegacyERC20 from '@/apps/paraspace/pages/hooks/useLegacyERC20';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import useNtokenUniswap from '@/apps/paraspace/pages/hooks/useNtokenUniswap';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { SelectableTokenInput, AddAssetsButton } from '@/apps/paraspace/components';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { useContractsMap } from '@/apps/paraspace/hooks';
import { useConvertSymbolWithNetwork } from '@/apps/paraspace/pages/hooks';

type LiquidityInputFormProps = {
  tokenId: string;
  symbol: string;
  baseTokenSymbol: ERC20Symbol;
  convertTokenSymbol: ERC20Symbol;
  baseTokenAddress: string;
  convertTokenAddress: string;
  baseTokenLowPrice: BigNumberJs;
  baseTokenUpPrice: BigNumberJs;
  baseTokenCurrentPrice: BigNumberJs;
  convertTokenCurrentPrice: BigNumberJs;
  isInRange: boolean;
  liquidityBaseTokenBalance: BigNumberJs;
  liquidityConvertTokenBalance: BigNumberJs;
  lpFeeBaseTokenBalance: BigNumberJs;
  lpFeeConvertTokenBalance: BigNumberJs;
  onFinish?: () => void;
  onTransactionCreated?: () => void;
};

/* eslint max-lines-per-function: ["error", { "max": 380 }] */
const LiquidityInputForm = ({
  tokenId,
  symbol,
  baseTokenSymbol,
  convertTokenSymbol,
  baseTokenAddress,
  convertTokenAddress,
  baseTokenLowPrice,
  baseTokenUpPrice,
  baseTokenCurrentPrice,
  convertTokenCurrentPrice,
  isInRange,
  liquidityBaseTokenBalance,
  liquidityConvertTokenBalance,
  lpFeeBaseTokenBalance,
  lpFeeConvertTokenBalance,
  onFinish,
  onTransactionCreated
}: LiquidityInputFormProps) => {
  const { erc20InfoMap, load } = useMMProvider();
  const contracts = useContractsMap();
  const [baseTokenAmount, setBaseTokenAmount] = useState<number | null>(null);
  const [convertTokenAmount, setConvertTokenAmount] = useState<number | null>(null);
  const [baseTokenAllowance, setBaseTokenAllowance] = useState<null | BigNumberJs>(null);
  const [convertTokenAllowance, setConvertTokenAllowance] = useState<null | BigNumberJs>(null);
  const [curState, setCurState] = useState<'init' | 'ready' | 'loading'>('init');

  const { convertWTokenToNativeToken } = useConvertSymbolWithNetwork();
  const transactionToast = useParallelToast();
  const { addLiquidity } = useNtokenUniswap();
  const { getAllowance: getAllowanceBase, approve: approveBase } = useLegacyERC20(baseTokenAddress);
  const { getAllowance: getAllowanceConvert, approve: approveConvert } =
    useLegacyERC20(convertTokenAddress);

  const loadAllowance = useCallback(async () => {
    try {
      const [baseAmt, convertAmt] = await Promise.all([
        getAllowanceBase(contracts['UNI-V3-POS']),
        getAllowanceConvert(contracts['UNI-V3-POS'])
      ]);
      setBaseTokenAllowance(new BigNumberJs(baseAmt));
      setConvertTokenAllowance(new BigNumberJs(convertAmt));
      setCurState('ready');
    } catch (e) {
      console.error('Error happen while fetching uniswapV3 underlying token allowance');
      console.error(e);
    }
  }, [contracts, getAllowanceBase, getAllowanceConvert]);

  useEffect(() => {
    loadAllowance();
  }, [loadAllowance]);

  const baseTokenPriceInUsd = erc20InfoMap[baseTokenSymbol].priceInUsd;
  const convertTokenPriceInUsd = erc20InfoMap[convertTokenSymbol].priceInUsd;
  const { balance: baseTokenBalance, priceInUsd: baseTokenBalanceInUsd } =
    erc20InfoMap[convertWTokenToNativeToken(baseTokenSymbol)];
  const { balance: convertTokenBalance, priceInUsd: convertTokenBalanceInUsd } =
    erc20InfoMap[convertWTokenToNativeToken(convertTokenSymbol)];
  const tokenWithOutOfRange = useMemo(() => {
    if (!isInRange && baseTokenUpPrice.lte(baseTokenCurrentPrice)) return convertTokenSymbol;
    if (!isInRange && baseTokenLowPrice.gte(baseTokenCurrentPrice)) return baseTokenSymbol;
    return '';
  }, [
    baseTokenCurrentPrice,
    baseTokenLowPrice,
    baseTokenSymbol,
    baseTokenUpPrice,
    convertTokenSymbol,
    isInRange
  ]);
  const isShowBaseTokenInput: boolean = useMemo(() => {
    return isInRange || (!isInRange && tokenWithOutOfRange === baseTokenSymbol);
  }, [tokenWithOutOfRange, baseTokenSymbol, isInRange]);
  const isShowConvertTokenInput: boolean = useMemo(() => {
    return isInRange || (!isInRange && tokenWithOutOfRange === convertTokenSymbol);
  }, [tokenWithOutOfRange, convertTokenSymbol, isInRange]);

  const baseTokenInfo: TokenOption = useMemo(
    () => ({
      name: convertWTokenToNativeToken(baseTokenSymbol),
      symbol: convertWTokenToNativeToken(baseTokenSymbol),
      balance: baseTokenBalance?.toNumber() || null,
      displayBalance: formatNumber(baseTokenBalance || 0, { decimal: 4 }),
      priceInUSD: baseTokenBalanceInUsd?.toNumber() || null
    }),
    [baseTokenBalance, baseTokenBalanceInUsd, baseTokenSymbol, convertWTokenToNativeToken]
  );
  const convertTokenInfo: TokenOption = useMemo(
    () => ({
      name: convertWTokenToNativeToken(convertTokenSymbol),
      symbol: convertWTokenToNativeToken(convertTokenSymbol),
      balance: convertTokenBalance?.toNumber() || null,
      displayBalance: formatNumber(convertTokenBalance || 0, { decimal: 4 }),
      priceInUSD: convertTokenBalanceInUsd?.toNumber() || null
    }),
    [convertTokenBalance, convertTokenBalanceInUsd, convertTokenSymbol, convertWTokenToNativeToken]
  );

  const changeBaseAmount = useCallback(
    ({ amount }: Value<TokenOption>) => {
      const isAllRange = !baseTokenUpPrice.isFinite();
      if (isInRange && amount) {
        if (isAllRange) {
          setConvertTokenAmount(
            floor(baseTokenCurrentPrice.times(amount).toNumber(), MAXIMUM_BALANCE_DECIMALS)
          );
        } else {
          const covertResultValue = getToken1AmountForToken0Amount({
            amount: new BigNumberJs(amount),
            currentPrice: baseTokenCurrentPrice,
            minPrice: baseTokenLowPrice,
            maxPrice: baseTokenUpPrice
          });
          setConvertTokenAmount(floor(covertResultValue.toNumber(), MAXIMUM_BALANCE_DECIMALS));
        }
      }
      if (isInRange && amount === null) setConvertTokenAmount(null);

      setBaseTokenAmount(amount !== null ? floor(amount, MAXIMUM_BALANCE_DECIMALS) : null);
    },
    [baseTokenCurrentPrice, baseTokenLowPrice, baseTokenUpPrice, isInRange]
  );
  const changeCovertAmount = useCallback(
    ({ amount }: Value<TokenOption>) => {
      const isAllRange = !baseTokenUpPrice.isFinite();
      if (isInRange && amount) {
        if (isAllRange) {
          setBaseTokenAmount(
            floor(convertTokenCurrentPrice.times(amount).toNumber(), MAXIMUM_BALANCE_DECIMALS)
          );
        } else {
          const baseResultValue = getToken0AmountForToken1Amount({
            amount: new BigNumberJs(amount),
            currentPrice: baseTokenCurrentPrice,
            minPrice: baseTokenLowPrice,
            maxPrice: baseTokenUpPrice
          });
          setBaseTokenAmount(floor(baseResultValue.toNumber(), MAXIMUM_BALANCE_DECIMALS));
        }
      }
      if (isInRange && amount === null) setBaseTokenAmount(null);

      setConvertTokenAmount(amount !== null ? floor(amount, MAXIMUM_BALANCE_DECIMALS) : null);
    },
    [
      baseTokenCurrentPrice,
      baseTokenLowPrice,
      baseTokenUpPrice,
      convertTokenCurrentPrice,
      isInRange
    ]
  );

  const baseInputValid = useMemo(() => {
    if (isShowBaseTokenInput && !baseTokenAmount) return false;
    if (
      isShowBaseTokenInput &&
      baseTokenAmount &&
      baseTokenBalance &&
      baseTokenBalance.comparedTo(baseTokenAmount) < 0
    )
      return false;
    return true;
  }, [baseTokenAmount, baseTokenBalance, isShowBaseTokenInput]);

  const covertInputValid = useMemo(() => {
    if (isShowConvertTokenInput && !convertTokenAmount) return false;
    if (
      isShowConvertTokenInput &&
      convertTokenAmount &&
      convertTokenBalance &&
      convertTokenBalance.comparedTo(convertTokenAmount) < 0
    )
      return false;
    return true;
  }, [convertTokenBalance, convertTokenAmount, isShowConvertTokenInput]);

  const errText = useMemo(() => {
    if (baseTokenAllowance === null || convertTokenAllowance === null) {
      return 'Checking Allowance ...';
    }
    const baseTokenBalanceValid =
      isShowBaseTokenInput &&
      baseTokenAmount &&
      baseTokenBalance &&
      baseTokenBalance.comparedTo(baseTokenAmount) < 0;
    const covertTokenBalanceValid =
      isShowConvertTokenInput &&
      convertTokenAmount &&
      convertTokenBalance &&
      convertTokenBalance.comparedTo(convertTokenAmount) < 0;
    if (baseTokenBalanceValid || covertTokenBalanceValid) return 'Insufficient Balance';
    return '';
  }, [
    baseTokenAllowance,
    convertTokenAllowance,
    isShowBaseTokenInput,
    baseTokenAmount,
    baseTokenBalance,
    isShowConvertTokenInput,
    convertTokenAmount,
    convertTokenBalance
  ]);

  const confirmHandle = useCallback(() => {
    if (isShowBaseTokenInput && (baseTokenAmount === null || baseTokenAllowance === null)) return;
    if (isShowConvertTokenInput && (convertTokenAmount === null || convertTokenAllowance === null))
      return;

    transactionToast.promise(async () => {
      setCurState('loading');
      try {
        const approvals = [];
        if (
          isShowBaseTokenInput &&
          baseTokenAllowance &&
          !baseTokenAllowance.gte(baseTokenAmount!) &&
          baseTokenSymbol !== ERC20Symbol.WETH
        ) {
          approvals.push(approveBase(contracts['UNI-V3-POS']));
        }
        if (
          isShowConvertTokenInput &&
          convertTokenAllowance &&
          !convertTokenAllowance.gte(convertTokenAmount!) &&
          convertTokenSymbol !== ERC20Symbol.WETH
        ) {
          approvals.push(approveConvert(contracts['UNI-V3-POS']));
        }
        await Promise.all(approvals);
        const tx = await addLiquidity(
          tokenId,
          new BigNumberJs(baseTokenAmount || 0),
          new BigNumberJs(convertTokenAmount || 0)
        );
        onTransactionCreated?.();
        await tx?.wait();
        load();
        onFinish?.();
      } catch (e) {
        throw getUserFriendlyError(e);
      } finally {
        setCurState('ready');
        loadAllowance();
      }
    });
  }, [
    isShowBaseTokenInput,
    baseTokenAmount,
    baseTokenAllowance,
    isShowConvertTokenInput,
    convertTokenAmount,
    convertTokenAllowance,
    transactionToast,
    baseTokenSymbol,
    convertTokenSymbol,
    addLiquidity,
    tokenId,
    onTransactionCreated,
    onFinish,
    approveBase,
    contracts,
    approveConvert,
    loadAllowance,
    load
  ]);

  const futureLiquidityUsdValue = useMemo(() => {
    const futureBaseTokenAmount = liquidityBaseTokenBalance
      .plus(baseTokenAmount || 0)
      .plus(lpFeeBaseTokenBalance || 0)
      .times(baseTokenPriceInUsd);
    const futureConvertTokenAmount = liquidityConvertTokenBalance
      .plus(convertTokenAmount || 0)
      .plus(lpFeeConvertTokenBalance || 0)
      .times(convertTokenPriceInUsd);
    return futureBaseTokenAmount.plus(futureConvertTokenAmount);
  }, [
    baseTokenAmount,
    baseTokenPriceInUsd,
    convertTokenAmount,
    convertTokenPriceInUsd,
    liquidityBaseTokenBalance,
    liquidityConvertTokenBalance,
    lpFeeBaseTokenBalance,
    lpFeeConvertTokenBalance
  ]);

  const shouldDisableButton =
    !baseInputValid || !covertInputValid || curState !== 'ready' || !!errText;

  const addAssetsContext = useMemo(() => {
    return {
      erc20Assets: [
        {
          symbol: baseTokenInfo.symbol as ERC20Symbol,
          amount: (baseTokenAmount ?? 0) - (baseTokenBalance?.toNumber() ?? 0)
        },
        {
          symbol: convertTokenInfo.symbol as ERC20Symbol,
          amount: (convertTokenAmount ?? 0) - (convertTokenBalance?.toNumber() ?? 0)
        }
      ].filter(i => i.amount > 0)
    };
  }, [
    baseTokenAmount,
    baseTokenBalance,
    baseTokenInfo.symbol,
    convertTokenAmount,
    convertTokenBalance,
    convertTokenInfo.symbol
  ]);

  return (
    <>
      <Stack gap="0.75rem">
        <H5>Add Liquidity</H5>
        {/* TODO integrate credit */}
        {isShowBaseTokenInput && (
          <SelectableTokenInput
            value={{
              token: baseTokenInfo,
              amount: baseTokenAmount
            }}
            tokens={[baseTokenInfo]}
            decimals={MAXIMUM_BALANCE_DECIMALS}
            onChange={changeBaseAmount}
            onActionButtonClicked={() =>
              changeBaseAmount({
                token: baseTokenInfo,
                amount: baseTokenBalance?.toNumber() || null
              })
            }
            actionButtonText="Max"
          />
        )}
        {isShowConvertTokenInput && (
          <SelectableTokenInput
            value={{
              token: convertTokenInfo,
              amount: convertTokenAmount
            }}
            decimals={MAXIMUM_BALANCE_DECIMALS}
            tokens={[convertTokenInfo]}
            onChange={changeCovertAmount}
            onActionButtonClicked={() =>
              changeCovertAmount({
                token: convertTokenInfo,
                amount: convertTokenBalance?.toNumber() || null
              })
            }
            actionButtonText="Max"
          />
        )}
      </Stack>
      <LiquidityBorrowLimitBar
        tokenId={tokenId}
        symbol={symbol}
        futureLiquidityUsdValue={futureLiquidityUsdValue}
      />
      <Button size="large" block disabled={shouldDisableButton} onClick={confirmHandle}>
        {(() => {
          if (errText) return errText;
          if (curState === 'loading') return 'Confirming ...';
          return 'Confirm';
        })()}
      </Button>
      {addAssetsContext.erc20Assets.length > 0 && <AddAssetsButton context={addAssetsContext} />}
    </>
  );
};
export default LiquidityInputForm;
