import { useEffect, useMemo, useState } from 'react';
import { Spinner, Stack, Text } from '@parallel-mono/components';
import styled from 'styled-components';

import { useBuyCartProvider } from '../../../context/index';

import { BatchBuyOpenseaSubmitter } from './BatchBuyOpenseaSubmitter';

import { BatchBuyModalPayload } from '.';

import { Marketplace, useShopListingWithProtocolQuery } from '@/apps/paraspace/generated/graphql';
import { Stepper } from '@/apps/paraspace/components';

export const StyledSpinner = styled(Spinner)`
  margin-bottom: 1rem;
`;
export const StyledText = styled(Text)`
  text-align: center;
`;

export const BatchBuyOpenseaStepper = ({
  payload: { buyNowAmount, creditAmount },
  onError,
  onSuccess
}: {
  payload: BatchBuyModalPayload;
  onError: (error: Error) => void;
  onSuccess: () => void;
}) => {
  const [step, setStep] = useState(0);

  const { buyCartList } = useBuyCartProvider();

  const { data } = useShopListingWithProtocolQuery({
    variables: {
      platform: Marketplace.OpenSea,
      listings: buyCartList.map(each => ({
        contractAddress: each.contractAddress,
        identifierOrCriteria: `${each.tokenId}`,
        orderHash: each.orderHash
      }))
    }
  });

  const payload = useMemo(() => {
    return {
      buyNowAmount: buyNowAmount.toString(),
      creditAmount: creditAmount.toString(),
      protocolData: data!
    };
  }, [buyNowAmount, creditAmount, data]);

  useEffect(() => {
    if (!data) return;

    if (data.shopListingWithProtocol.length === buyCartList.length) {
      setStep(1);
    } else if (data.shopListingWithProtocol.length !== buyCartList.length) {
      const missingAssets = buyCartList
        .filter(
          each => !data.shopListingWithProtocol.find(each2 => each.orderHash === each2?.orderHash)
        )
        .map(each => `#${each.tokenId}`);
      onError(new Error(`Some of the assets (${missingAssets}) are not available`));
    }
  }, [buyCartList, data, onError]);

  const steps = useMemo(() => {
    return [
      {
        description: 'Fetching OpenSea Listing Data',
        content: (
          <Stack gap="0.25rem" alignItems="center">
            <StyledSpinner size="large" />
            <StyledText skin="secondary">This may take some time.</StyledText>
          </Stack>
        )
      },
      {
        description: 'Waiting tx confirm to finish purchasing',
        content: (
          <BatchBuyOpenseaSubmitter formData={payload} onSuccess={onSuccess} onFailed={onError} />
        )
      }
    ];
  }, [onError, onSuccess, payload]);

  return <Stepper steps={steps} step={step} />;
};
