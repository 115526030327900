import { memo, useMemo } from 'react';
import { useLocation } from 'react-use';

import config from '../config';
import { TestnetAnnouncementModal } from '../components';
import { useEOAProvider } from '../contexts';

export const DisableTestnet = memo(({ children }) => {
  const { account } = useEOAProvider();
  const location = useLocation();

  const showTestnetAnnouncement = useMemo(
    () =>
      !config.isProductionOrForkMainnet &&
      location.host?.includes('testnet.parallel.fi') &&
      account,
    [account, location.host]
  );
  return (
    <>
      {showTestnetAnnouncement && <TestnetAnnouncementModal />}
      {children}
    </>
  );
});
