import { memo, useCallback } from 'react';

import { useV1cAPEConvert } from '../hooks';

import { FormSubmitter } from '@/apps/paraspace/components';
import { zero } from '@/apps/paraspace/consts/values';

type V1cAPEConvertSubmitterProps = {
  formData: {
    from: string;
    to: string;
  };
  onFinish: () => void;
  onError: () => void;
};

export const V1cAPEConvertSubmitter = memo(
  ({ formData, onFinish, onError }: V1cAPEConvertSubmitterProps) => {
    const convertCAPEFromV1 = useV1cAPEConvert();

    const submit = useCallback(() => {
      const { from, to } = formData;
      return convertCAPEFromV1(zero, from, to);
    }, [convertCAPEFromV1, formData]);

    return (
      <FormSubmitter
        inProgressMessage="Securely Converting"
        onError={onError}
        onFinish={onFinish}
        submit={submit}
      />
    );
  }
);
