import {
  Button,
  H5,
  Inline,
  SmallText,
  Stack,
  Text,
  Toggle,
  Image
} from '@parallel-mono/components';
import { HTMLAttributes, memo, useCallback, useMemo, useState } from 'react';
import { InfoPanel, TokenInput } from '@parallel-mono/business-components';
import { floor } from 'lodash';
import { BigNumber } from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';
import styled from 'styled-components';

import { useApeStakeManager } from '../../../../contexts';
import { SupplySourceOption, ApeCoinSourceSelect } from '../../../../components';

import heartHalfContained from '@/apps/paraspace/assets/heart-half-contained.svg';
import { ERC20Symbol, WalletType } from '@/apps/paraspace/typings';
import { MAX_PERCENT, MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { NumberRange, Tooltip } from '@/apps/paraspace/components';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { zero } from '@/apps/paraspace/consts/values';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';

type SupplyApeCoinProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'>;

const StyledToggle = styled(Toggle)`
  width: 2.5rem;
`;

const ColorModeImage = styled(Image)`
  ${({ theme }) => (theme.mode === 'dark' ? 'filter: invert(1);' : '')}
`;

const SupplyApeContainer = (props: SupplyApeCoinProps) => {
  const { erc20InfoMap, load, loadERC20Balance: refreshAAERC20Balances } = useMMProvider();
  const { balance: aaBalance } = erc20InfoMap.APE ?? {};
  const { erc20BalanceMap, refreshERC20BalanceMap } = useEOABalances();

  const eoaBalance = erc20BalanceMap?.[ERC20Symbol.APE] ?? zero;

  const [apeCoinSource, setSupplySource] = useState<WalletType>('AA');
  const handleSupplySourceChange = useCallback((option: SupplySourceOption | null) => {
    setSupplySource(option?.value);
  }, []);

  const balance = apeCoinSource === 'AA' ? aaBalance : eoaBalance;

  const { apeCoinCompoundApy, effectiveCapeSupplyApy } = useAutoCompoundApeInfo();
  const { stakeAutoCompoundApe } = useApeStakeManager();

  const extraApy = useMemo(() => {
    if (!effectiveCapeSupplyApy || !apeCoinCompoundApy) {
      return null;
    }
    return effectiveCapeSupplyApy.minus(apeCoinCompoundApy);
  }, [effectiveCapeSupplyApy, apeCoinCompoundApy]);

  const stakedApeCoin = useMemo(() => {
    const { balance: cApeBalance, suppliedAmount } = erc20InfoMap.CAPE ?? {};
    if (!cApeBalance || !suppliedAmount) return BigNumber(0);
    return cApeBalance.plus(suppliedAmount);
  }, [erc20InfoMap.CAPE]);

  const [walletAmount, setWalletAmount] = useState<number | null>(null);
  const [enableSupplyCApe, setEnableSupplyCApe] = useState<boolean>(true);
  const handleWalletValueChange = useCallback((value: number | null) => {
    setWalletAmount(value);
  }, []);
  const onWalletMaxClick = useCallback(() => {
    setWalletAmount(floor(balance?.toNumber() ?? 0, MAXIMUM_BALANCE_DECIMALS));
  }, [balance]);
  const walletInputErrorMsg = useMemo(() => {
    if (!walletAmount) {
      return '';
    }
    if (balance?.lt(walletAmount)) {
      return 'Insufficient balance.';
    }
    return '';
  }, [balance, walletAmount]);

  const panelInfo = useMemo(() => {
    const info = [
      {
        title: (
          <Inline gap=".25rem">
            <Text skin="secondary">You Will Receive</Text>
            <Tooltip
              placement="bottom"
              content="cAPE represent your share of ApeCoin in the pool. cAPE can always be exchanged for APE at fixed 1:1 ratio."
            />
          </Inline>
        ),
        value: `${formatNumber(walletAmount ?? 0)} cAPE`
      },
      {
        title: 'Total Staked',
        value: (
          <NumberRange
            start={stakedApeCoin?.toNumber() ?? 0}
            end={stakedApeCoin?.plus(walletAmount ?? 0).toNumber() ?? 0}
            formatter={formatNumber}
            symbol="APE"
          />
        )
      },
      {
        title: 'Reward APY',
        value: formatNumber((enableSupplyCApe ? effectiveCapeSupplyApy : apeCoinCompoundApy) ?? 0, {
          output: 'percent'
        })
      }
    ];
    return info;
  }, [walletAmount, stakedApeCoin, enableSupplyCApe, effectiveCapeSupplyApy, apeCoinCompoundApy]);

  const disabledConfirm = useMemo(
    () => !walletAmount || !!walletInputErrorMsg || !balance,
    [balance, walletAmount, walletInputErrorMsg]
  );
  const handleConfirm = useCallback(() => {
    if (!walletAmount) return;
    stakeAutoCompoundApe({
      enableSupplyCApe,
      amount: `${walletAmount}`,
      onSuccess: load,
      apeCoinSource
    }).then(() => {
      if (apeCoinSource === 'EOA') {
        refreshERC20BalanceMap();
      } else {
        refreshAAERC20Balances();
      }
    });
    setWalletAmount(null);
  }, [
    enableSupplyCApe,
    load,
    stakeAutoCompoundApe,
    walletAmount,
    apeCoinSource,
    refreshERC20BalanceMap,
    refreshAAERC20Balances
  ]);

  return (
    <Stack width="100%" justifyContent="center" {...props}>
      <ApeCoinSourceSelect value={apeCoinSource} onChange={handleSupplySourceChange} />
      <TokenInput
        label={<H5>Amount</H5>}
        hint={
          <Inline gap=".3rem">
            <Text skin="secondary">Balance: {formatNumber(balance ?? 0)} APE</Text>
          </Inline>
        }
        token={ERC20Symbol.APE}
        placeholder="0"
        value={walletAmount}
        decimals={MAXIMUM_BALANCE_DECIMALS}
        onChange={handleWalletValueChange}
        error={walletInputErrorMsg}
        actionButtonText="Max"
        onAction={onWalletMaxClick}
        inputProps={{
          inputProps: {
            autoFocus: true
          }
        }}
      />
      <InfoPanel skin="primary" infos={panelInfo} />
      <Inline width="100%" alignItems="flex-start" justifyContent="space-between" gap="0.5rem">
        <Inline alignItems="flex-start" gap="0.5rem">
          <ColorModeImage src={heartHalfContained} />
          <SmallText>
            Supply cAPE to NFT Lending to earn an extra{' '}
            {extraApy
              ? formatNumber(extraApy.toNumber(), {
                  output: 'percent',
                  threshold: {
                    max: MAX_PERCENT
                  }
                })
              : ''}{' '}
            APY.{' '}
            <Tooltip
              placement="top"
              content="Depositing cAPE to the money market enables users to borrow from it. Please note
            that there might be delays in withdrawing cAPE from the money market if pool
            utilization is extremely high with high yield for suppliers. The cAPE money market
            is currently not open for borrowing but is open for suppliers."
            />
          </SmallText>
        </Inline>
        <Inline>
          <StyledToggle
            checked={enableSupplyCApe}
            onChange={() => setEnableSupplyCApe(data => !data)}
          />
        </Inline>
      </Inline>
      <Stack gap="1rem">
        <Button block size="large" disabled={disabledConfirm} onClick={handleConfirm}>
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
};

export default memo(SupplyApeContainer);
