import { useMemo } from 'react';

import { useImageMap } from './ImageMapProvider';
import { useSupplyETHValidator } from './SupplyETHValidatorManager';
import { useClaimETHValidator } from './ClaimETHValidatorManager';

export const useETHValidatorManager = () => {
  const { supplyETHValidator } = useSupplyETHValidator();
  const { claim } = useClaimETHValidator();
  const { imageMap, getImageThroughContract } = useImageMap();

  return useMemo(
    () => ({
      supplyETHValidator,
      claim,
      imageMap,
      getImageThroughContract
    }),
    [supplyETHValidator, claim, imageMap, getImageThroughContract]
  );
};
