import { useMemo } from 'react';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Action, ActionTypeEnum } from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { Offer } from '@/apps/paraspace/pages/Shop/types';

const autoSwapedRatio = 0.95;

export const useSimulatedSupplyActions = (offerItems: Offer['orderItem'][]): Action[] => {
  const { erc20InfoMap } = useMMProvider();
  return useMemo(() => {
    return offerItems.map(({ amount, symbol }) => {
      const { priceInUsd, baseLTVasCollateral, reserveLiquidationThreshold } =
        erc20InfoMap[symbol] ?? {};
      return {
        type: ActionTypeEnum.SUPPLY,
        targetAsset: {
          id: symbol,
          value: priceInUsd.times(amount).times(autoSwapedRatio),
          LTV: baseLTVasCollateral,
          reserveLiquidationThreshold
        }
      };
    });
  }, [erc20InfoMap, offerItems]);
};
