import BigNumber from 'bignumber.js';

import { DAYS_OF_YEAR } from '../consts/fixtures';

export const calculateCompoundApyByApr = (apy: BigNumber) =>
  apy.div(DAYS_OF_YEAR).plus(1).pow(DAYS_OF_YEAR).minus(1);

export const calculateEffectiveApy = (compoundApy: number | BigNumber, expenseApy: BigNumber) =>
  BigNumber(compoundApy).plus(1).times(expenseApy.plus(1)).minus(1);

export const calculateNFTCompoundApy = (
  apePoolApy: BigNumber | undefined,
  nftApy: BigNumber | undefined,
  nftCap: BigNumber | undefined
) => {
  if (apePoolApy && nftApy && nftCap) {
    const dailyApeCoinPoolApr = apePoolApy.div(DAYS_OF_YEAR);
    const dailyNFTPoolApr = nftApy.div(DAYS_OF_YEAR);
    const interestNFT = nftCap.times(dailyNFTPoolApr);
    const finalApeAmount = interestNFT
      .times(calculateCompoundApyByApr(apePoolApy).minus(dailyApeCoinPoolApr))
      .div(dailyApeCoinPoolApr);
    const compoundApy = finalApeAmount.div(nftCap);
    return compoundApy;
  }
  return new BigNumber(0);
};
