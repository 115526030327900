import { useCallback, useMemo } from 'react';
import { UiPoolDataProvider, UserNtokenData } from 'paraspace-utilities-contract-helpers';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

export const useNtokenData = <T>(nTokenAddresses: string) => {
  const { provider, chainId } = useWeb3Context();
  const contracts = useContractsMap();

  const uiPoolData = useMemo(
    () =>
      new UiPoolDataProvider({
        uiPoolDataProviderAddress: contracts.UiPoolDataProvider,
        provider,
        chainId
      }),
    [contracts.UiPoolDataProvider, provider, chainId]
  );

  const getNTokens = useCallback(
    async (tokenIds: number[]): Promise<(UserNtokenData & T)[]> => {
      const tokens = await uiPoolData.getNtokenData({
        lendingPoolAddressProvider: contracts.PoolAddressesProvider,
        nTokenAddresses: [nTokenAddresses],
        tokenIds: [tokenIds]
      });
      return tokens[0] as (UserNtokenData & T)[];
    },
    [contracts.PoolAddressesProvider, nTokenAddresses, uiPoolData]
  );

  return { getNTokens };
};
