import { useCallback } from 'react';
import {
  Button,
  DataGridColumn,
  H5,
  Inline,
  Pagination,
  Spinner,
  Text,
  H3
} from '@parallel-mono/components';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router-dom';
import { CryptoIcon } from '@parallel-mono/business-components';

import { useGetOffers } from '../../../hooks';
import { useShopActions } from '../../../contexts';

import { Marketplace } from '@/apps/paraspace/generated/graphql';
import { NFTCollectionThumbnail, NFTThumbnail, DataGrid, Link } from '@/apps/paraspace/components';
import { formatBalance, formatToCurrency, truncateAddress } from '@/apps/paraspace/utils/format';
import { useNetworkConfig } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { Offer } from '@/apps/paraspace/pages/Shop/types';

const StyledH5 = styled(H5)`
  margin-left: 1rem;
`;

const StyledContainer = styled.div`
  position: relative;
  margin-top: 3rem;
`;

export const OfferSent = () => {
  const { account } = useWeb3Context();
  const { offers, loading, handlePageChange, currentPage, totalPage, totalCount } = useGetOffers({
    filter: { maker: account }
  });

  const {
    explorerLink: [explorerLinkBaseUrl]
  } = useNetworkConfig();

  const { cancelOffer } = useShopActions();

  const handleCancelOffer = useCallback(
    (row: Offer) => {
      if (row.platform === Marketplace.ParaSpace) {
        cancelOffer(row);
      }
    },
    [cancelOffer]
  );

  const offerSendColumns: DataGridColumn<Offer>[] = [
    {
      name: 'NFT',
      title: 'NFT',
      width: '2fr',
      render: ({ data: { considerationItem } }) => {
        return (
          <Link
            as={RouterLink}
            to={
              considerationItem.tokenId
                ? `/details/${considerationItem.contractAddress}/${considerationItem.tokenId}`
                : `/shop/${considerationItem.contractAddress}`
            }
          >
            <Inline alignItems="center" gap="0rem">
              {considerationItem.tokenId ? (
                <NFTThumbnail
                  symbol={considerationItem.symbol}
                  tokenId={considerationItem.tokenId}
                  size="small"
                  round
                />
              ) : (
                <NFTCollectionThumbnail size="small" round symbol={considerationItem.symbol} />
              )}
              <StyledH5>{considerationItem.name}</StyledH5>
            </Inline>
          </Link>
        );
      }
    },
    {
      name: 'Offer Send',
      title: 'Offer Send',
      width: '1.5fr',
      render: ({ data: { orderItem } }) => {
        return (
          <Inline alignItems="center" gap=".5rem">
            <CryptoIcon size="xsmall" symbol={orderItem.symbol} />
            <Text>{formatBalance(orderItem.amount)}</Text>
            <Text>{orderItem.symbol}</Text>
          </Inline>
        );
      }
    },
    {
      name: 'Credit Used',
      title: 'Credit Used',
      width: '1.5fr',
      render: ({ data: { creditData } }) => {
        if (!creditData) {
          return <Text>-</Text>;
        }
        const { symbol, amount } = creditData;
        return (
          <Inline alignItems="center" gap=".5rem">
            <CryptoIcon size="xsmall" symbol={symbol} />
            <Text>{formatBalance(amount)}</Text>
            <Text>{symbol}</Text>
          </Inline>
        );
      }
    },
    {
      name: 'usdValue',
      title: 'USD Value',
      width: '1fr',
      render: ({ data: { orderItem } }) => formatToCurrency(orderItem.priceInUsd)
    },
    {
      name: 'expiration',
      title: 'Expiration',
      width: '0.8fr',
      render: ({
        data: {
          order: { expirationTime }
        }
      }) => {
        return dayjs(expirationTime).fromNow();
      }
    },
    {
      name: 'to',
      title: 'To',
      width: '0.8fr',
      render: ({ data: { to } }) => {
        if (to) {
          return (
            <Link target="_blank" href={`${explorerLinkBaseUrl}/address/${to}`}>
              {truncateAddress(to)}
            </Link>
          );
        }
        return '-';
      }
    },
    {
      name: 'Cancel',
      title: '',
      width: '0.6fr',
      render: ({ data: row }) => {
        if (row.order.platform === Marketplace.ParaSpace) {
          return (
            <Button skin="secondary" onClick={() => handleCancelOffer(row)}>
              Cancel
            </Button>
          );
        }
        return null;
      }
    }
  ];

  return (
    <StyledContainer>
      {loading && (
        <Inline width="100%" justifyContent="center" inset="2rem 0 0">
          <Spinner size="small" />
        </Inline>
      )}
      {!loading && (
        <>
          <H3>{totalCount} Offers Sent</H3>
          <DataGrid<Offer> columns={offerSendColumns} data={offers} />
          <Pagination
            total={totalPage}
            onChange={handlePageChange}
            page={currentPage}
            siblingCount={0}
            startBoundaryCount={3}
          />
        </>
      )}
    </StyledContainer>
  );
};
