import { ERC721Symbol } from 'paraspace-configs-v2';

export type { ERC721SymbolValue, OpenSeaConfig, NFTConfig } from 'paraspace-configs-v2';
export { Network, NETWORK_NAME_MAP, NFTEnumerableType } from 'paraspace-configs-v2';
export * from './AppConfig';
export * from './types';
export * from './networksConfig';
export * from './AppConfig/contractsConfig';
export * from './v1ContractsConfig';

export const DELISTED_ERC721_SYMBOLS = [ERC721Symbol.HV_MTL, ERC721Symbol.PPG];
