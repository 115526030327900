import { gql } from '@apollo/client';

// QUERIES
export const GET_PROFILE = gql`
  query Profile($authToken: String!, $walletAddress: String!) {
    getProfile(authToken: $authToken, walletAddress: $walletAddress) {
      profile {
        email
        walletAddress
        subscriptions
        avatar
        twitter
        telegram
        username
      }
      status
      message
    }
  }
`;

export const CHECK_USERNAME_AVAILABILITY = gql`
  query ($username: String!) {
    getUsernameAvailability(username: $username) {
      isUsernameAvailable
    }
  }
`;

// MUTATIONS
export const CREATE_OR_UPDATE_PROFILE = gql`
  mutation CreateProfile(
    $authToken: String!
    $walletAddress: String!
    $email: String
    $subscriptions: [NotificationSubscriptionTypesEnum]!
    $avatar: String
    $telegram: String
    $twitter: String
    $username: String
  ) {
    createOrUpdateProfile(
      authToken: $authToken
      walletAddress: $walletAddress
      email: $email
      subscriptions: $subscriptions
      avatar: $avatar
      telegram: $telegram
      twitter: $twitter
      username: $username
    ) {
      profile {
        email
        walletAddress
        subscriptions
        avatar
        telegram
        twitter
        username
      }
      status
      message
    }
  }
`;
