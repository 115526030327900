import { useState, useEffect, useCallback, memo } from 'react';

import { CommonFormState } from '../types';

import { ApproveWallet, InProgress } from '@/apps/paraspace/components';
import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC721Symbol
} from '@/apps/paraspace/typings';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useLegacyPoolApeStaking } from '@/apps/paraspace/pages/hooks/usePoolApeStaking';

export type ApproveWithdrawProps = {
  symbol: ApeStakingTokenSymbol;
  tokenId: number;
  mainTokenId?: number;
  mainTokenSymbol?: ApeStakingMainTokenSymbol;
  stakedValue: number | undefined;
  withdrawAmount: number;
  onFinish: (amount: number) => void;
  onError: () => void;
};

const ApproveWithdraw = ({
  symbol,
  tokenId,
  mainTokenId,
  mainTokenSymbol,
  stakedValue,
  withdrawAmount,
  onFinish,
  onError
}: ApproveWithdrawProps) => {
  const [formState, setFormState] = useState(CommonFormState.MAINFORM);

  const toast = useParallelToast();
  const { unstakingApe, withdrawBAKC: withdrawBAKCMethod } = useLegacyPoolApeStaking();

  const withdrawBAYCOrMAYC = useCallback(
    (amount: number) => {
      setFormState(CommonFormState.APPROVE);
      toast.promise(
        unstakingApe(symbol as ApeStakingMainTokenSymbol, [{ tokenId: tokenId!, amount }])
          .then(async tx => {
            setFormState(CommonFormState.PROCESSING);
            await tx?.wait();
            onFinish(amount);
          })
          .catch(err => {
            setFormState(CommonFormState.ERROR);
            onError();
            throw getUserFriendlyError(err);
          })
      );
    },
    [toast, unstakingApe, symbol, tokenId, onFinish, onError]
  );

  const withdrawBAKC = useCallback(
    (amount: number) => {
      if (!mainTokenId) {
        throw new Error('No BakcTokenId');
      }
      setFormState(CommonFormState.APPROVE);
      const isUncommit = amount === stakedValue;
      toast.promise(
        withdrawBAKCMethod(mainTokenSymbol!, mainTokenId!, tokenId, amount, isUncommit)
          .then(async tx => {
            setFormState(CommonFormState.PROCESSING);
            await tx?.wait();
            onFinish(amount);
          })
          .catch(err => {
            setFormState(CommonFormState.ERROR);
            onError();
            throw getUserFriendlyError(err);
          })
      );
    },
    [
      mainTokenId,
      stakedValue,
      toast,
      withdrawBAKCMethod,
      mainTokenSymbol,
      tokenId,
      onFinish,
      onError
    ]
  );

  const handleWithdraw = useCallback(
    (amount: number) => {
      if (symbol === ERC721Symbol.BAKC) {
        withdrawBAKC(amount);
      }

      if ([ERC721Symbol.BAYC, ERC721Symbol.MAYC].includes(symbol)) {
        withdrawBAYCOrMAYC(amount);
      }
    },
    [symbol, withdrawBAKC, withdrawBAYCOrMAYC]
  );

  useEffect(() => {
    handleWithdraw(withdrawAmount);
  }, [handleWithdraw, withdrawAmount]);

  if (formState === CommonFormState.APPROVE) {
    return <ApproveWallet />;
  }

  if (formState === CommonFormState.PROCESSING) {
    return <InProgress tip="Withdrawing" />;
  }

  return null;
};

export default memo(ApproveWithdraw);
