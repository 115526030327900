import { memo, useCallback } from 'react';
import {
  Icon,
  Text,
  Inline,
  InlineProps,
  Stack,
  Button,
  H2,
  ColorMode,
  useBreakpoints,
  Responsive
} from '@parallel-mono/components';
import styled from 'styled-components';

import { LogoIcon } from '../../Logo';

export type IntroducingParaXProps = Omit<InlineProps, 'children'> & {
  onClose?: () => void;
};

const ContentContainer = styled(Responsive)`
  position: relative;
  background: ${({ theme }) => theme.skin.primary.main};
  color: ${({ theme }) => theme.skin.primary.contrastText};
  border-radius: ${({ theme }) => theme.border.radius.large};
  padding: ${({ theme }) => theme.spacing(8)};
  ${({ theme }) => theme.breakpoints.only('mobile')`
    padding: ${theme.spacing(4)};
  `}
`;

const Content = styled(Inline)`
  flex: 1;
`;

const CloseButton = styled(Icon).attrs({
  name: 'close'
})`
  position: absolute;
  top: 2rem;
  right: 2rem;

  color: ${({ theme }) =>
    theme.mode === ColorMode.dark ? theme.skin.primary.contrastText : theme.skin.grey[500]};
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.only('mobile')`
    top: 1rem;
    right: 1rem;
  `}
  &:hover {
    color: ${({ theme }) => theme.skin.grey[300]};
  }
`;

export const IntroducingParaX = memo(({ onClose, ...props }: IntroducingParaXProps) => {
  const handleClick = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleLearnMoreClicked = useCallback(() => {
    window.open(
      'https://medium.com/@ParaX/parallel-finance-and-paraspace-are-merging-to-create-parax-a-web3-super-app-powered-by-account-5a5f8ae2fc5f'
    );
  }, []);

  const { mobile } = useBreakpoints();

  return (
    <ContentContainer breakPoint="tablet" {...props}>
      <LogoIcon height={mobile ? '2.5rem' : '7.5rem'} />
      <Content width="100%" justifyContent="space-between">
        <Stack width="100%">
          <Stack gap="0.25rem">
            <H2>Introducing Parallel</H2>
            {!mobile && (
              <Text>
                Parallel Finance and ParaSpace are Merging to Create Parallel: A Web3 Super App
                Powered by Account Abstraction and zkVM.
              </Text>
            )}
          </Stack>
          <Button block={mobile} onClick={handleLearnMoreClicked} skin="secondary">
            Learn More
          </Button>
        </Stack>
        <CloseButton onClick={handleClick} />
      </Content>
    </ContentContainer>
  );
});
