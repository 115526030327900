import { useCallback } from 'react';

import { BuyShopItemFormData } from '..';

import { FormSubmitter } from '@/apps/paraspace/components';
import { BuyListing, ListingFromParaSpace } from '@/apps/paraspace/pages/Shop/types';
import { Platform } from '@/apps/paraspace/typings';
import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useNftActualOwners } from '@/apps/paraspace/pages/hooks/useAcutualOwners';
import { useShopListingWithProtocolLazyQuery } from '@/apps/paraspace/generated/graphql';
import { useAppConfig } from '@/apps/paraspace/hooks';

export const BuyWithCreditSubmitter = ({
  formData,
  listing,
  onFinish,
  onError
}: {
  formData: BuyShopItemFormData;
  listing: BuyListing;
  onFinish: () => void;
  onError: () => void;
}) => {
  const { buyWithCredit } = usePool();
  const { getNftActualOwners } = useNftActualOwners();

  const { erc721Config } = useAppConfig();
  const [fetchListingWithProtocol] = useShopListingWithProtocolLazyQuery({
    variables: {
      platform: listing.platform,
      listings: {
        contractAddress: erc721Config[formData.symbol].address,
        identifierOrCriteria: `${formData.tokenId}`,
        orderHash: listing.orderHash
      }
    }
  });

  const submit = useCallback(async () => {
    const { payAmount, borrowAmount, tokenId, symbol } = formData;

    const { data } = await fetchListingWithProtocol();
    const reservoirListing = data?.shopListingWithProtocol?.[0];
    const { platform, protocolData, protocolVersion } = listing as ListingFromParaSpace;

    const result = await buyWithCredit({
      buyNowAmount: `${payAmount ?? 0}`,
      payLaterAmount: `${borrowAmount ?? 0}`,
      platform: platform?.toUpperCase() as Platform,
      marketProtocolData: reservoirListing?.protocolData ?? protocolData,
      protocolVersion: reservoirListing?.protocolVersion ?? protocolVersion
    }).catch(async (e: Error) => {
      // error after tx submit
      // check if item bought by someone
      const originSeller = (
        (reservoirListing?.protocolData ?? protocolData)?.parameters?.offerer || ''
      ).toLowerCase();

      const currentOwner = await getNftActualOwners([{ symbol, tokenId: tokenId.toString() }]);
      if (!currentOwner.includes(originSeller)) {
        throw new Error('The NFT(s) has just been sold. Please try again with another item.');
      }
      throw getUserFriendlyError(
        e,
        "The price for one or more NFTs is too low for the seller's position. Please try again with a higher offer."
      );
    });
    return result;
  }, [listing, formData, buyWithCredit, fetchListingWithProtocol, getNftActualOwners]);

  return <FormSubmitter onError={onError} onFinish={onFinish} submit={submit} />;
};
