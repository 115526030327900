import { ReactNode, memo, useMemo } from 'react';

import { Tooltip } from '@/apps/paraspace/components';

type EmptyStateProps = {
  isP2PStaked: boolean;
  isMakerUnhealthy?: boolean;
  isMakerInsufficientBorrowLimit?: boolean;
  children?: ReactNode;
};

export const AcceptOfferActionTooltip = memo(
  ({
    isP2PStaked,
    isMakerUnhealthy,
    isMakerInsufficientBorrowLimit,
    children
  }: EmptyStateProps) => {
    const msg = useMemo(() => {
      if (isP2PStaked)
        return `Cannot accept an offer for this NFT because it's staked on Shared Pool. Please unstake first to accept an offer.`;

      if (isMakerUnhealthy)
        return `Cannot accept an offer for this NFT because the maker is in liquidation.`;

      if (isMakerInsufficientBorrowLimit)
        return `Cannot accept an offer for this NFT because the maker's borrowing limit is insufficient to cover the offer price.`;

      return '';
    }, [isMakerInsufficientBorrowLimit, isMakerUnhealthy, isP2PStaked]);

    return (
      <Tooltip
        content={msg}
        disabled={!isP2PStaked && !isMakerUnhealthy && !isMakerInsufficientBorrowLimit}
      >
        {children}
      </Tooltip>
    );
  }
);
