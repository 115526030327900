import { useCallback, useState } from 'react';
import ReactGA from 'react-ga4';

import StakingStep from './StakingStep';
import StakingSuccess from './StakingSuccess';

import { ErrorState } from '@/apps/paraspace/components';
import { WalletType } from '@/apps/paraspace/typings';

type ApeStakeFormProps = {
  apeCoinSource: WalletType;
  amount: string;
  enableSupplyCApe: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};
enum FormState {
  STEPPER = 1,
  ERROR = 2,
  SUCCESS = 3
}

export const ApeStakeForm = ({
  apeCoinSource,
  amount,
  enableSupplyCApe,
  onClose,
  onSuccess
}: ApeStakeFormProps) => {
  const [formState, setFormState] = useState<FormState>(FormState.STEPPER);

  const handleError = useCallback(() => {
    setFormState(FormState.ERROR);
  }, []);

  const handleSuccess = useCallback(() => {
    setFormState(FormState.SUCCESS);
    ReactGA.event({
      action: 'apestaking',
      category: 'cape',
      label: 'supply',
      value: parseFloat(amount)
    });
    if (onSuccess) onSuccess();
  }, [amount, onSuccess]);

  if (formState === FormState.STEPPER) {
    return (
      <StakingStep
        apeCoinSource={apeCoinSource}
        enableSupplyCApe={enableSupplyCApe}
        onError={handleError}
        onSuccess={handleSuccess}
        amount={amount}
      />
    );
  }

  if (formState === FormState.ERROR) {
    return <ErrorState closeModal={onClose} />;
  }

  if (formState === FormState.SUCCESS) {
    return <StakingSuccess amount={amount} onClose={onClose} />;
  }

  return null;
};
