import { FC, ReactNode, ComponentProps, useCallback } from 'react';
import styled from 'styled-components';
import {
  Inline,
  Collapse as RawCollapse,
  Card,
  Icon,
  CardProps,
  useDualModeState,
  Stack
} from '@parallel-mono/components';

export type CollapseProps = CardProps &
  Pick<
    ComponentProps<typeof RawCollapse>,
    'open' | 'duration' | 'timing' | 'delay' | 'direction'
  > & {
    header: ReactNode;
    mobileButton?: ReactNode;
    onToggle?: (isOpen: boolean) => void;
    defaultOpen?: boolean;
  };

const Header = styled(Inline)`
  cursor: pointer;
`;

const FullWidthH5 = styled(Inline)`
  ${({ theme }) => theme.typography.header5};
  width: 100%;
`;

export const Collapse: FC<CollapseProps> = ({
  header,
  onToggle,
  children,
  open,
  defaultOpen = false,
  mobileButton,
  ...others
}) => {
  const [finalOpen, setInternalOpen] = useDualModeState(defaultOpen, open);

  const handleToggleCollapse = useCallback(() => {
    setInternalOpen(curr => !curr);
    onToggle?.(!finalOpen);
  }, [finalOpen, onToggle, setInternalOpen]);

  return (
    <Card {...others}>
      <Stack gap="1rem">
        <Header justifyContent="space-between" alignItems="center" onClick={handleToggleCollapse}>
          <FullWidthH5 forwardedAs="div">{header}</FullWidthH5>
          <Icon name={finalOpen ? 'minusCircle' : 'downContained'} />
        </Header>
        {mobileButton && <Inline>{mobileButton}</Inline>}
      </Stack>
      <RawCollapse open={finalOpen}>{children}</RawCollapse>
    </Card>
  );
};
