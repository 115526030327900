import { floor, isEmpty } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { Button, H5, Inline, Stack, StackProps, Text } from '@parallel-mono/components';
import { SelectableToken, SelectableTokenValue } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';
import { BigNumber } from 'bignumber.js';

import { Infos } from '../Infos';
import { SupplyERC20FormData } from '../../types';

import { useValidation } from './useValidation';
import { useDebouncedSupplyApyByAmount } from './useDebouncedSupplyApyByAmount';

import {
  ENABLE_OMNI_YIELD,
  MAXIMUM_BALANCE_DECIMALS,
  MINIMUM_ACCOUNTABLE_NUM
} from '@/apps/paraspace/pages/config';
import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { SelectableTokenInput } from '@/apps/paraspace/components';
import { useExpandableTokens } from '@/apps/paraspace/pages/Credit/hooks';

export type SupplyERC20BaseFormProps = Omit<StackProps, 'children' | 'onSubmit' | 'onChange'> & {
  defaultSymbol: ERC20Symbol;
  erc20InfoMap: Record<
    string,
    {
      balance?: BigNumber;
      displayName: string;
      supplyApyRate: BigNumber;
      supplyRewardRate: BigNumber;
      priceInUsd: BigNumber;
      symbol: ERC20Symbol;
    }
  >;
  onSubmit: (formData: SupplyERC20FormData) => void;
  onChange: (formData: { symbol: ERC20Symbol; amount: Maybe<number> }) => void;
};

export const SupplyERC20BaseForm = memo(
  ({ defaultSymbol, erc20InfoMap, onSubmit, onChange, ...others }: SupplyERC20BaseFormProps) => {
    const [symbol, setSymbol] = useState(defaultSymbol);
    const [amount, setAmount] = useState<number | null>(null);

    const { balance, displayName, supplyApyRate, supplyRewardRate, priceInUsd } =
      erc20InfoMap[symbol];

    const expandableTokenSymbols = useExpandableTokens(defaultSymbol);

    const tokensInfo = useMemo(() => {
      if (!isEmpty(expandableTokenSymbols)) {
        return expandableTokenSymbols.map(item => {
          const tokenInfo = erc20InfoMap[item] ?? {};
          return {
            name: tokenInfo.displayName,
            symbol: tokenInfo.symbol,
            balance: tokenInfo.balance?.toNumber() ?? 0,
            priceInUSD: tokenInfo.priceInUsd?.toNumber() ?? 0,
            displayBalance: formatBalance(tokenInfo.balance ?? 0)
          };
        });
      }

      return [
        {
          name: displayName,
          symbol,
          balance: balance?.toNumber() || 0,
          priceInUSD: priceInUsd.toNumber(),
          displayBalance: formatBalance(balance ?? 0)
        }
      ];
    }, [expandableTokenSymbols, displayName, symbol, balance, priceInUsd, erc20InfoMap]);

    const curSelectedTokenInfo = useMemo(() => {
      return {
        token: {
          name: displayName,
          symbol,
          balance: balance?.toNumber() || 0,
          priceInUSD: priceInUsd.toNumber(),
          displayBalance: formatBalance(balance ?? 0)
        },
        amount
      };
    }, [amount, balance, symbol, displayName, priceInUsd]);

    const [formTouched, setFormTouched] = useState(false);
    const handleInputBlur = useCallback(() => {
      setFormTouched(true);
    }, []);

    const handleInputChange = useCallback(
      ({ amount: newAmount, token }: SelectableTokenValue<SelectableToken>) => {
        const { symbol: newSymbol } = token!;
        if (newSymbol !== symbol) {
          setSymbol(newSymbol as ERC20Symbol);
        }

        setAmount(newAmount);
        onChange({
          symbol: newSymbol as ERC20Symbol,
          amount: newAmount ?? null
        });
        setFormTouched(true);
      },
      [symbol, onChange]
    );

    const handleActionButtonClicked = useCallback(() => {
      setAmount(
        floor(
          balance!.decimalPlaces(MAXIMUM_BALANCE_DECIMALS, BigNumber.ROUND_DOWN).toNumber(),
          MAXIMUM_BALANCE_DECIMALS
        )
      );
    }, [balance]);

    const errorMessage = useValidation({ amount, balance });

    const { supplyApy, isApyLoading } = useDebouncedSupplyApyByAmount({
      amount,
      defaultSupplyApyRate: supplyApyRate,
      disabled: !!errorMessage,
      symbol
    });

    const apyList = useMemo(
      () =>
        ENABLE_OMNI_YIELD
          ? [
              {
                label: 'Supply APY',
                load: isApyLoading,
                value: formatToPercentage(supplyApy)
              },
              {
                label: 'Reward APY',
                load: false,
                value: formatToPercentage(supplyRewardRate)
              },
              {
                label: 'Total APY',
                load: isApyLoading,
                value: formatToPercentage(supplyApy.plus(supplyRewardRate))
              }
            ]
          : [
              {
                label: 'Supply APY',
                load: isApyLoading,
                value: formatToPercentage(supplyApy)
              }
            ],
      [supplyApy, supplyRewardRate, isApyLoading]
    );

    const handleSupply = useCallback(() => {
      const useMax = balance!
        .minus(amount ?? 0)
        .abs()
        .lt(MINIMUM_ACCOUNTABLE_NUM);
      const actualSuppliedAmount = useMax ? balance! : new BigNumber(amount!);
      onSubmit({ amount: actualSuppliedAmount, symbol });
    }, [onSubmit, amount, symbol, balance]);

    return (
      <Stack {...others}>
        <Stack width="100%" gap="0">
          <Inline justifyContent="space-between">
            <H5>Amount</H5>
            <Text skin="secondary">
              Available:{' '}
              {formatNumber(balance!, {
                decimal: MAXIMUM_BALANCE_DECIMALS,
                threshold: {
                  min: MINIMUM_ACCOUNTABLE_NUM
                }
              })}{' '}
              {displayName}
            </Text>
          </Inline>
          <SelectableTokenInput
            value={curSelectedTokenInfo}
            tokens={tokensInfo}
            decimals={MAXIMUM_BALANCE_DECIMALS}
            onChange={handleInputChange}
            hasError={formTouched && !!errorMessage}
            onActionButtonClicked={handleActionButtonClicked}
            actionButtonText="Max"
            inputProps={{
              onBlur: handleInputBlur,
              autoFocus: true,
              title: amount ? `$${formatBalance(amount)}` : ''
            }}
            classNames={{
              tokenSelect: {
                menu: 'token-select-menu',
                item: 'token-select-item'
              }
            }}
          />
        </Stack>
        <Infos symbol={symbol} amount={amount} apyList={apyList} />
        <Stack gap="1rem">
          <Button
            skin="primary"
            size="large"
            block
            disabled={!!errorMessage || !amount}
            onClick={handleSupply}
          >
            {errorMessage ||
              `Supply ${formatNumber(amount!, {
                decimal: MAXIMUM_BALANCE_DECIMALS,
                threshold: {
                  min: MINIMUM_ACCOUNTABLE_NUM
                }
              })} ${displayName}`}
          </Button>
        </Stack>
      </Stack>
    );
  }
);
