import { memo, useCallback, useMemo, useState } from 'react';
import { flatMap, keys, map, mapValues } from 'lodash';
import { H2, Spinner, Stack, StackProps, Text } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import styled from 'styled-components';

import { CollapsableTokensSelector } from '../../components';
import { useNftDelegation } from '../../contexts';
import { TokenDelegationInfo } from '../../types';

import { Link, EmptyState, FixedActionFooter } from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { Maybe } from '@/apps/paraspace/typings/basic';

const Tip = styled(Text)`
  max-width: 40rem;
`;

type InitiateDelegationProps = Omit<StackProps, 'children'> & {};

export const InitiateDelegation = memo((props: InitiateDelegationProps) => {
  const { nftInfoMap } = useMMProvider();
  const { delegationMap, refreshDelegationMap, delegationMapLoaded } = useNftDelegation();

  const [selectedTokenMaps, setSelectedTokenMaps] = useState<
    Partial<{ [key in ERC721Symbol]: TokenDelegationInfo[] }>
  >({});

  const createSelectionChangeHandler = useCallback(
    (symbol: ERC721Symbol) => (selection: TokenDelegationInfo[]) => {
      setSelectedTokenMaps(curr => ({
        ...curr,
        [symbol]: selection
      }));
    },
    [setSelectedTokenMaps]
  );

  const availableTokensCount = useMemo(
    () =>
      flatMap(delegationMap, tokenInfos => tokenInfos.filter(it => it.delegate === null)).length,
    [delegationMap]
  );
  const selectedTokensCount = useMemo(() => flatMap(selectedTokenMaps).length, [selectedTokenMaps]);

  const { initiateNftDelegation } = useNftDelegation();
  const handleDelegate = useCallback(() => {
    return initiateNftDelegation(
      mapValues(selectedTokenMaps, (delegations, symbol) => ({
        collectionName: nftInfoMap[symbol as ERC721Symbol].collectionName,
        tokenIds: map(delegations, it => it.tokenId)
      }))
    ).finally(() => {
      refreshDelegationMap();
      setSelectedTokenMaps({});
    });
  }, [initiateNftDelegation, selectedTokenMaps, nftInfoMap, refreshDelegationMap]);

  const selectors = useMemo(() => {
    if (!delegationMapLoaded) {
      return [];
    }
    return map<
      ERC721Symbol,
      Maybe<{
        symbol: ERC721Symbol;
        tokens: TokenDelegationInfo[];
        collectionName: string;
      }>
    >(keys(delegationMap) as ERC721Symbol[], (symbol: ERC721Symbol) => {
      const tokens = delegationMap?.[symbol] ?? [];
      const notDelegatedTokens = tokens.filter(it => it.delegate === null);
      const { collectionName } = nftInfoMap[symbol] ?? {};
      return notDelegatedTokens.length > 0
        ? {
            symbol,
            tokens: notDelegatedTokens,
            collectionName
          }
        : null;
    })
      .filter(notEmpty)
      .map(({ symbol, tokens, collectionName }, index) => {
        return (
          <CollapsableTokensSelector
            key={symbol}
            defaultOpen={index === 0}
            headerHint={`Supplied: ${formatNumber(tokens.length)}`}
            collectionName={collectionName}
            symbol={symbol}
            tokens={tokens}
            selectedTokens={selectedTokenMaps[symbol] ?? []}
            onSelectionChange={createSelectionChangeHandler(symbol)}
          />
        );
      });
  }, [
    delegationMap,
    delegationMapLoaded,
    createSelectionChangeHandler,
    nftInfoMap,
    selectedTokenMaps
  ]);

  const empty = selectors.length === 0;

  return (
    <Stack alignItems="center" {...props} inset="0 0 1.5rem">
      <Stack width="100%" gap="0.5rem">
        <H2>Initiate Delegation</H2>
        <Tip skin="secondary">
          Select supplied NFTs and enter a delegation address. Delegated address will have access to
          utilities from NFTs. Powered by{' '}
          <Link target="_blank" href="https://delegate.cash/">
            delegate.cash
          </Link>
        </Tip>
      </Stack>
      {!delegationMapLoaded && <Spinner />}
      {selectors}
      {empty && delegationMapLoaded && (
        <EmptyState
          title="No supported NFT in this Wallet"
          description="You need to have supported NFTs to start delegating."
        />
      )}
      {!empty && delegationMapLoaded && (
        <FixedActionFooter
          selectedCount={selectedTokensCount}
          availableCount={availableTokensCount}
          onClick={handleDelegate}
          actionLabel="Delegate"
        />
      )}
    </Stack>
  );
});
