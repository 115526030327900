import {
  Stack,
  StackProps,
  Inline,
  Responsive,
  useBreakpoints,
  Scroller,
  stackTwoColors
} from '@parallel-mono/components';
import { FC, memo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { P2PStakingProvider } from '../../contexts/P2PStakingProvider';

import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { WalletType } from '@/apps/paraspace/typings';

const SharePoolContainer = styled(Responsive)`
  position: relative;
`;

const MenuItem = styled(Inline).attrs({
  inset: '0 1rem',
  gap: '0.5rem',
  alignItems: 'center'
})<{ selected?: boolean }>`
  width: fit-content;
  white-space: nowrap;
  height: 2.5rem;
  border-radius: 2rem;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, selected }) =>
      stackTwoColors(
        selected ? theme.skin.grey['200'] : theme.skin.background.main,
        theme.skin.action.hoverMask
      )};
  }
  background-color: ${props => (props.selected ? props.theme.skin.grey['200'] : 'transparent')};
`;

const subMenus = [
  {
    name: 'Listings',
    path: absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS.index
  },
  {
    name: 'Active Matches',
    path: absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS.ACTIVE_MATCHES
  },
  {
    name: 'My Positions',
    path: absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS.MY_POSITION
  }
];

type Props = Omit<StackProps, 'children'>;

export const SharePool: FC<Props> = memo(Props => {
  const navigate = useNavigate();
  const location = useLocation();
  const [walletType] = useState<WalletType>('AA');
  const { mobile } = useBreakpoints();

  return (
    <P2PStakingProvider walletType={walletType}>
      <SharePoolContainer width="100%">
        <Stack width="100%" {...Props}>
          <Responsive justifyContent="space-between" gap={mobile ? '1.5rem' : '1rem'}>
            <Scroller hideArrow showScrollFade width={mobile ? '100%' : 'auto'}>
              <Inline gap="0.5rem">
                {subMenus.map((item, index) => {
                  const { name, path } = item;
                  return (
                    <MenuItem
                      key={`${name}-${index}`}
                      selected={[path].includes(location.pathname)}
                      onClick={() => {
                        navigate(path);
                      }}
                    >
                      {name}
                    </MenuItem>
                  );
                })}
              </Inline>
            </Scroller>
          </Responsive>

          <Outlet />
        </Stack>
      </SharePoolContainer>
    </P2PStakingProvider>
  );
});
