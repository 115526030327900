import { ReactNode, memo, useCallback, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { Stack, StackProps } from '@parallel-mono/components';
import { chunk } from 'lodash';

import { SupplyNftFormData } from './SupplyNftForm';
import { SUPPLY_NFT_CHUNK_SIZE } from './consts';
import { SupplyNftSubmitter } from './SupplyNftSubmitter';
import { SupplyMoonbirdsSubmitter } from './submitters/SupplyMoonbirdsSubmitter';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import {
  ApproveERC721FromEOASubmitter,
  DepositERC721Submitter,
  Stepper,
  SupplyERC721FromEOASubmitter
} from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type SupplyNftFormStepperProps = Omit<StackProps, 'children' | 'onError'> & {
  formData: SupplyNftFormData;
  onFinish: () => void;
  onError: () => void;
};

export const SupplyNftFormStepper = memo(
  ({ onFinish, onError, formData, ...others }: SupplyNftFormStepperProps) => {
    const { supplyTokenIds, collectionName, address, symbol, walletType } = formData;
    const { account: paraXAccount } = useWeb3Context();

    const [step, setStep] = useState(0);

    const handleNext = useCallback(() => {
      setStep(curr => curr + 1);
    }, []);

    const handleFinalFinish = useCallback(() => {
      supplyTokenIds.forEach(id =>
        ReactGA.event({
          action: 'supply',
          category: 'erc721',
          label: symbol,
          value: id
        })
      );
      onFinish();
    }, [onFinish, supplyTokenIds, symbol]);

    const chunks = useMemo(() => chunk(supplyTokenIds, SUPPLY_NFT_CHUNK_SIZE), [supplyTokenIds]);

    const EOASteps = useMemo(() => {
      if (symbol === ERC721Symbol.MOONBIRD) {
        // The MOONBIRD can not be supplied as Parallel account directly, so need to approve to AA first and then deposit to AA and supply
        const shouldDepositFirst = supplyTokenIds.length === 1;
        return [
          shouldDepositFirst
            ? {
                description: `Deposit ${symbol}`,
                content: (
                  <DepositERC721Submitter
                    formData={{
                      tokenId: supplyTokenIds[0],
                      address
                    }}
                    onError={onError}
                    onFinish={handleNext}
                  />
                )
              }
            : {
                description: 'Approve ERC721',
                content: (
                  <ApproveERC721FromEOASubmitter
                    formData={{
                      name: collectionName,
                      assetAddress: address,
                      spender: paraXAccount,
                      ids: formData.supplyTokenIds
                    }}
                    onError={onError}
                    onFinish={handleNext}
                  />
                )
              },
          ...chunks.map((nftChunk, index) => ({
            description: chunks.length > 1 ? `Supply NFTs, chunk ${index + 1}` : 'Supply NFTs',
            content: (
              <SupplyMoonbirdsSubmitter
                key={`supply-chunks-${index + 1}`}
                formData={{
                  tokenIds: nftChunk,
                  symbol,
                  address
                }}
                isDeposited={shouldDepositFirst}
                onError={onError}
                onFinish={index === chunks.length - 1 ? handleFinalFinish : handleNext}
              />
            )
          }))
        ];
      }
      return [
        {
          description: 'Approve ERC721',
          content: (
            <ApproveERC721FromEOASubmitter
              formData={{
                name: collectionName,
                assetAddress: address,
                ids: supplyTokenIds
              }}
              onError={onError}
              onFinish={handleNext}
            />
          )
        },
        ...chunks.map((nftChunk, index) => ({
          description: chunks.length > 1 ? `Supply NFTs, chunk ${index + 1}` : 'Supply NFTs',
          content: (
            <SupplyERC721FromEOASubmitter
              key={`supply-chunks-${index + 1}`}
              formData={{
                symbol,
                tokenIds: nftChunk
              }}
              waitConfirmations={1}
              onError={onError}
              onFinish={index === chunks.length - 1 ? handleFinalFinish : handleNext}
            />
          )
        }))
      ];
    }, [
      address,
      chunks,
      collectionName,
      formData.supplyTokenIds,
      handleFinalFinish,
      handleNext,
      onError,
      paraXAccount,
      supplyTokenIds,
      symbol
    ]);

    const steps: {
      description: ReactNode;
      content: ReactNode;
    }[] = useMemo(
      () =>
        ({
          AA: chunks.map((nftChunkTokenIds, index) => ({
            description: chunks.length > 1 ? `Supply NFTs, chunk ${index + 1}` : 'Supply NFTs',
            content: (
              <SupplyNftSubmitter
                key={`supply-chunks-${index + 1}`}
                formData={{
                  allTokenIds: supplyTokenIds,
                  symbol,
                  chunkTokenIds: nftChunkTokenIds
                }}
                waitConfirmations={1}
                onError={onError}
                onFinish={index === chunks.length - 1 ? handleFinalFinish : handleNext}
              />
            )
          })),
          EOA: EOASteps
        }[walletType]),
      [supplyTokenIds, onError, handleNext, chunks, EOASteps, walletType, symbol, handleFinalFinish]
    );

    return (
      <Stack {...others}>
        <Stepper steps={steps} step={step} />
      </Stack>
    );
  }
);
