import { OrderParameters, InputCriteria } from 'paraspace-seaport-js/lib/types';
import {
  getSummedTokenAndIdentifierAmounts,
  TimeBasedItemParams
} from 'paraspace-seaport-js/lib/utils/item';
import {
  InsufficientApprovals,
  BalancesAndApprovals,
  InsufficientBalances
} from 'paraspace-seaport-js/lib/utils/balanceAndApprovalCheck';

const findBalanceAndApproval = (
  balancesAndApprovals: BalancesAndApprovals,
  token: string,
  identifierOrCriteria: string
) => {
  const balanceAndApproval = balancesAndApprovals.find(
    ({ token: checkedToken, identifierOrCriteria: checkedIdentifierOrCriteria }) =>
      token.toLowerCase() === checkedToken.toLowerCase() &&
      checkedIdentifierOrCriteria.toLowerCase() === identifierOrCriteria.toLowerCase()
  );

  if (!balanceAndApproval) {
    throw new Error("Balances and approvals didn't contain all tokens and identifiers");
  }

  return balanceAndApproval;
};

export const getInsufficientBalanceAndApprovalAmounts = ({
  balancesAndApprovals,
  tokenAndIdentifierAmounts,
  operator
}: {
  balancesAndApprovals: BalancesAndApprovals;
  tokenAndIdentifierAmounts: ReturnType<typeof getSummedTokenAndIdentifierAmounts>;
  operator: string;
}): {
  insufficientBalances: InsufficientBalances;
  insufficientApprovals: InsufficientApprovals;
} => {
  const tokenAndIdentifierAndAmountNeeded = [
    ...Object.entries(tokenAndIdentifierAmounts).map(([token, identifierToAmount]) =>
      Object.entries(identifierToAmount).map(
        ([identifierOrCriteria, amountNeeded]) =>
          [token, identifierOrCriteria, amountNeeded] as const
      )
    )
  ].flat();

  const filterBalancesOrApprovals = (
    filterKey: 'balance' | 'approvedAmount'
  ): InsufficientBalances =>
    tokenAndIdentifierAndAmountNeeded
      .filter(([token, identifierOrCriteria, amountNeeded]) => {
        // filter approve when amountNeeded is 0
        if (filterKey === 'approvedAmount' && amountNeeded.eq(0)) {
          return true;
        }
        return findBalanceAndApproval(balancesAndApprovals, token, identifierOrCriteria)[
          filterKey
        ].lt(amountNeeded);
      })
      .map(([token, identifierOrCriteria, amount]) => {
        const balanceAndApproval = findBalanceAndApproval(
          balancesAndApprovals,
          token,
          identifierOrCriteria
        );

        return {
          token,
          identifierOrCriteria,
          requiredAmount: amount,
          amountHave: balanceAndApproval[filterKey],
          itemType: balanceAndApproval.itemType
        };
      });

  const mapToApproval = (
    insufficientBalance: InsufficientBalances[number]
  ): InsufficientApprovals[number] => ({
    token: insufficientBalance.token,
    identifierOrCriteria: insufficientBalance.identifierOrCriteria,
    approvedAmount: insufficientBalance.amountHave,
    requiredApprovedAmount: insufficientBalance.requiredAmount,
    itemType: insufficientBalance.itemType,
    operator
  });

  const [insufficientBalances, insufficientApprovals] = [
    filterBalancesOrApprovals('balance'),
    filterBalancesOrApprovals('approvedAmount').map(mapToApproval)
  ];

  return {
    insufficientBalances,
    insufficientApprovals
  };
};

/**
 * 1. The offerer should have sufficient balance of all offered items.
 * 2. If the order does not indicate proxy utilization, the offerer should have sufficient approvals set
 *    for the Seaport contract for all offered ERC20, ERC721, and ERC1155 items.
 * 3. If the order does indicate proxy utilization, the offerer should have sufficient approvals set
 *    for their respective proxy contract for all offered ERC20, ERC721, and ERC1155 items.
 */
export const validateOfferBalancesAndApprovals = ({
  offer,
  criterias,
  balancesAndApprovals,
  timeBasedItemParams,
  throwOnInsufficientBalances = true,
  throwOnInsufficientApprovals,
  operator
}: {
  balancesAndApprovals: BalancesAndApprovals;
  timeBasedItemParams?: TimeBasedItemParams;
  throwOnInsufficientBalances?: boolean;
  throwOnInsufficientApprovals?: boolean;
  operator: string;
} & Pick<OrderParameters, 'offer'> & {
    criterias: InputCriteria[];
  }): InsufficientApprovals => {
  const { insufficientBalances, insufficientApprovals } = getInsufficientBalanceAndApprovalAmounts({
    balancesAndApprovals,

    tokenAndIdentifierAmounts: getSummedTokenAndIdentifierAmounts({
      items: offer,
      criterias,
      timeBasedItemParams: timeBasedItemParams
        ? { ...timeBasedItemParams, isConsiderationItem: false }
        : undefined
    }),
    operator
  });

  if (throwOnInsufficientBalances && insufficientBalances.length > 0) {
    throw new Error('The offerer does not have the amount needed to create or fulfill.');
  }

  if (throwOnInsufficientApprovals && insufficientApprovals.length > 0) {
    throw new Error('The offerer does not have the sufficient approvals.');
  }

  return insufficientApprovals;
};
