import { Button, Responsive, Stack, StackProps, useBreakpoints } from '@parallel-mono/components';
import { FC, useCallback, useMemo, useState } from 'react';
import { InfoPanel } from '@parallel-mono/business-components';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { SupplyETHValidatorFormData } from './types';
import { StakeFishNFT, useBorrowPositionChange, useStakeFishNfts } from './hooks';

import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { NFTThumbnailCheck, NumberRange, NoNFTs } from '@/apps/paraspace/components';
import { ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';

const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  max-height: 18.75rem;
  width: 41rem;
  overflow: auto;
  ${({ theme }) => theme.breakpoints.down('md')`
  grid-template-columns: repeat(2, 1fr);
`};
`;

type SupplyETHValidatorFormProps = {
  onSubmit: (formData: SupplyETHValidatorFormData) => void;
  walletType: WalletType;
} & Omit<StackProps, 'children' | 'onSubmit'>;

export const ETHValidatorSupplyForm: FC<SupplyETHValidatorFormProps> = ({
  onSubmit,
  walletType
}) => {
  const [selectedTokens, setSelectedTokens] = useState<StakeFishNFT[]>([]);

  const { mobile } = useBreakpoints();
  const { stakeFishNfts } = useStakeFishNfts(walletType);
  const { newCollateralValueInUsd, newBorrowLimitInUsd, originalBorrowLimitInUsd } =
    useBorrowPositionChange({ selectedTokens });

  const selectedUnclaimedRewards = useMemo(() => {
    return selectedTokens.reduce((total, { unclaimedRewards }) => {
      return total.plus(unclaimedRewards);
    }, zero);
  }, [selectedTokens]);

  const infoPanels = useMemo(() => {
    return [
      {
        title: 'Collateral Value',
        value: formatToCurrency(newCollateralValueInUsd),
        tip: 'Total principal value of all selected validators.'
      },
      {
        title: 'Unclaimed Tips/MEV Rewards',
        value: formatToCurrency(selectedUnclaimedRewards),
        tip: 'Amount of consensus and execution rewards of the selected validators.'
      },
      {
        title: 'New Borrow Limit',
        value: (
          <NumberRange
            start={originalBorrowLimitInUsd.toNumber()}
            end={newBorrowLimitInUsd.toNumber()}
            formatter={formatToCurrency}
          />
        )
      }
    ];
  }, [
    newBorrowLimitInUsd,
    newCollateralValueInUsd,
    originalBorrowLimitInUsd,
    selectedUnclaimedRewards
  ]);

  const handleItemClicked = useCallback(
    (item: StakeFishNFT) => (checked: boolean) => {
      setSelectedTokens(curr => {
        if (checked) {
          return curr.concat(item);
        }
        return curr.filter(it => it.tokenId !== item.tokenId);
      });
    },
    []
  );

  const supplyDisabled = useMemo(() => selectedTokens.length === 0, [selectedTokens.length]);

  const handleSelectAllClick = useCallback(() => {
    setSelectedTokens(items => (items.length === stakeFishNfts.length ? [] : stakeFishNfts));
  }, [stakeFishNfts]);

  const handleSupply = useCallback(() => {
    onSubmit({
      symbol: ERC721Symbol.SF_VLDR,
      tokenIds: selectedTokens.map(it => it.tokenId),
      newCollateralValue: newCollateralValueInUsd,
      walletType
    });
  }, [onSubmit, selectedTokens, newCollateralValueInUsd, walletType]);

  return (
    <Stack>
      {isEmpty(stakeFishNfts) ? (
        <NoNFTs width="100%" description="There’s no Stake.fish validator NFT on this wallet." />
      ) : (
        <>
          <ItemsContainer>
            {stakeFishNfts.map(tokenItem => {
              const { tokenId } = tokenItem;
              return (
                <NFTThumbnailCheck
                  size="large"
                  key={tokenId}
                  symbol={ERC721Symbol.SF_VLDR}
                  tokenId={tokenId}
                  checked={selectedTokens.some(it => it.tokenId === tokenId)}
                  onChange={handleItemClicked(tokenItem)}
                />
              );
            })}
          </ItemsContainer>
          <InfoPanel width="100%" skin="primary" infos={infoPanels} />
          <Responsive breakPoint="tablet">
            <Button
              skin="secondary"
              size="large"
              block={mobile}
              onClick={handleSelectAllClick}
              disabled={stakeFishNfts.length === 0}
            >
              {stakeFishNfts.length && selectedTokens.length === stakeFishNfts.length
                ? 'Unselect All'
                : 'Select All'}
            </Button>
            <Button onClick={handleSupply} size="large" block disabled={supplyDisabled}>
              {`Supply ${selectedTokens.length || ''} ${
                selectedTokens.length > 1 ? 'NFTs' : 'NFT'
              }`}
            </Button>
          </Responsive>
        </>
      )}
    </Stack>
  );
};
