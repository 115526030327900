import {
  Inline,
  Select,
  SelectBaseOption,
  SelectProps,
  Image,
  Text,
  SmallText
} from '@parallel-mono/components';
import { memo, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { HostedImage } from '@parallel-mono/business-components';
import styled from 'styled-components';
import { EOAAuthenticationMate } from 'parax-sdk';

import { useMMProvider } from '../../../contexts/MMProvider';
import { useEOABalances } from '../../../contexts';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type SupplySourceOption = SelectBaseOption & {
  balance: BigNumber;
};

type ApeCoinSourceSelectProps = Omit<SelectProps<SupplySourceOption>, 'options' | 'renderItem'>;

const selectClassNames: SelectProps['classNames'] = {
  menu: 'ape-coin-source-select-menu'
};

const StyledSelect = styled(Select).attrs({
  classNames: selectClassNames
})`
  width: fit-content;
  border: none !important;

  .${selectClassNames.menu} {
    width: fit-content !important;
    min-width: 100%;
  }

  &:hover {
    border: none !important;
  }
` as typeof Select;

const createRenderDisplay =
  ({ walletIcon, walletType }: EOAAuthenticationMate) =>
  (p: Maybe<SupplySourceOption>) => {
    if (p === null) return null;
    const { value } = p;
    return (
      <Inline alignItems="center" gap="1rem">
        {value === 'AA' && (
          <HostedImage
            name="design/PDS_V3/logo/parallel-v2-logo"
            height="1.25rem"
            width="1.25rem"
          />
        )}
        {value === 'EOA' && (
          <Image height="1.25rem" width="1.25rem" src={walletIcon ?? undefined} />
        )}
        <Text>Supply from {value === 'AA' ? 'AA Wallet' : walletType}</Text>
      </Inline>
    );
  };

export const ApeCoinSourceSelect = memo((props: ApeCoinSourceSelectProps) => {
  const { erc20InfoMap } = useMMProvider();
  const { erc20BalanceMap } = useEOABalances();
  const { authentication } = useWeb3Context();

  const eoaBalance = erc20BalanceMap?.[ERC20Symbol.APE] ?? zero;
  const aaBalance = erc20InfoMap?.[ERC20Symbol.APE]?.balance ?? zero;

  const options = useMemo(
    () => [
      {
        label: (
          <Inline alignItems="center" justifyContent="space-between">
            <Inline alignItems="center" gap="1rem">
              <HostedImage
                name="design/PDS_V3/logo/parallel-v2-logo"
                height="1.25rem"
                width="1.25rem"
              />
              <Text>AA Wallet</Text>
            </Inline>
            <SmallText>{formatBalance(aaBalance)}</SmallText>
          </Inline>
        ),
        value: 'AA',
        balance: aaBalance
      },
      {
        label: (
          <Inline alignItems="center" justifyContent="space-between">
            <Inline alignItems="center" gap="1rem">
              <Image
                height="1.25rem"
                width="1.25rem"
                src={authentication.meta.walletIcon ?? undefined}
              />
              <Text>{authentication.meta.walletType}</Text>
            </Inline>
            <SmallText>{formatBalance(eoaBalance)}</SmallText>
          </Inline>
        ),
        value: 'EOA',
        balance: eoaBalance
      }
    ],
    [eoaBalance, aaBalance, authentication]
  );

  const renderDisplay = useMemo(
    () => createRenderDisplay(authentication.meta),
    [authentication.meta]
  );

  return (
    <StyledSelect<SupplySourceOption> renderDisplay={renderDisplay} options={options} {...props} />
  );
});
