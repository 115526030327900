import { formatNumber } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';
import { BigNumber as EthereumBigNumber } from 'ethers';
import {
  ChildToParentMessageReader,
  ChildToParentMessageStatus,
  ChildToParentTransactionEvent
} from '@arbitrum/sdk';
import { Provider } from '@ethersproject/providers';
import { union } from 'lodash';

import { WITHDRAWAL_EVENTS_STATUS_CACHE_KEY } from '../hooks';

import { FetchingStatus } from '@/apps/parax/typings';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/parax/utils';

export const getDisplayBalance = (status: FetchingStatus, balance: BigNumber) => {
  if (status === FetchingStatus.FETCHING) {
    return 'loading...';
  }
  if (status === FetchingStatus.SUCCESS) {
    return formatNumber(balance, {
      decimal: MAXIMUM_BALANCE_DECIMALS
    });
  }
  if (status === FetchingStatus.FAIL) {
    return 'failed';
  }
  return null;
};

export const getOutgoingMessageState = async (
  event: ChildToParentTransactionEvent & { hash: EthereumBigNumber },
  l1Provider: Provider,
  l2Provider: Provider,
  account: string
) => {
  const cacheKey = `${WITHDRAWAL_EVENTS_STATUS_CACHE_KEY}:${account}`;
  const cachedStatus = localStorage.getItem(cacheKey);
  const parsedCachedStatus = JSON.parse(cachedStatus || '[]') as string[];
  const txHash = event.hash.toString();

  if (parsedCachedStatus.find(v => v === txHash)) {
    return ChildToParentMessageStatus.EXECUTED;
  }

  const messageReader = new ChildToParentMessageReader(l1Provider, event);
  try {
    const status = await messageReader.status(l2Provider);
    if (status === ChildToParentMessageStatus.EXECUTED) {
      localStorage.setItem(cacheKey, JSON.stringify(union([...parsedCachedStatus, txHash])));
    }
    return status;
  } catch (error) {
    return ChildToParentMessageStatus.UNCONFIRMED;
  }
};
