import { memo, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {
  BigText,
  Inline,
  DataGridColumn,
  Pagination,
  Spinner,
  Button
} from '@parallel-mono/components';
import { useParams } from 'react-router-dom';
import { CryptoIcon, MarketplaceIcon } from '@parallel-mono/business-components';

import { useGetOffers } from '../../../hooks';
import { AcceptOfferActionTooltip } from '../../../components';

import { DataGrid, Link, Collapse } from '@/apps/paraspace/components';
import { formatBalance, formatToCurrency, truncateAddress } from '@/apps/paraspace/utils/format';
import { OrderSortBy } from '@/apps/paraspace/generated/graphql';
import { useNetworkConfig, useSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useP2PInfo } from '@/apps/paraspace/pages/contexts/P2PInfoProvider';
import { NftInfo, CheckedOffer } from '@/apps/paraspace/pages/Shop/types';
import { useShopActions } from '@/apps/paraspace/pages/Shop/contexts';

const StyledSpinner = styled(Spinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const StyledContainer = styled.div`
  position: relative;
`;

export const OfferGrid = memo(
  ({
    isOwner,
    inLiquidation,
    refetch
  }: {
    isOwner: boolean;
    inLiquidation: boolean;
    refetch: () => void;
  }) => {
    const { account } = useWeb3Context();
    const { contractAddr, tokenId } = useParams();
    const {
      loading,
      totalCount,
      offers,
      handlePageChange,
      totalPage,
      currentPage,
      refetch: refetchGetOffers
    } = useGetOffers({
      filter: {
        contractAddress: contractAddr!,
        identifierOrCriteria: tokenId
      },
      sort: OrderSortBy.OfferValueDesc
    });

    const { acceptOffer } = useShopActions();

    const symbol = useSymbolByContractAddress(contractAddr || '');
    const nftInfo = useMemo(
      () => ({
        contractAddress: contractAddr,
        symbol,
        tokenId: parseInt(tokenId!, 10),
        name: symbol
      }),
      [contractAddr, symbol, tokenId]
    );

    const handleOpenAcceptModal = useCallback(
      (data: CheckedOffer) => {
        acceptOffer(data, nftInfo as NftInfo).then(() => {
          refetch();
          refetchGetOffers();
        });
      },
      [acceptOffer, nftInfo, refetch, refetchGetOffers]
    );
    const { checkIfTokenInPairing } = useP2PInfo();
    const {
      explorerLink: [explorerLinkBaseUrl]
    } = useNetworkConfig();
    const lastColumn: DataGridColumn<CheckedOffer> = isOwner
      ? {
          name: 'action',
          title: 'Action',
          width: '1fr',
          render: ({ data }) => {
            const { considerationItem, maker, isMakerUnhealthy, isMakerInsufficientBorrowLimit } =
              data;
            const isP2PStaked = checkIfTokenInPairing(
              considerationItem.symbol,
              considerationItem.tokenId ?? 0
            );

            return (
              <AcceptOfferActionTooltip
                isP2PStaked={isP2PStaked}
                isMakerUnhealthy={isMakerUnhealthy}
                isMakerInsufficientBorrowLimit={isMakerInsufficientBorrowLimit}
              >
                <Button
                  skin="secondary"
                  onClick={() => handleOpenAcceptModal(data)}
                  disabled={
                    isMakerUnhealthy ||
                    isMakerInsufficientBorrowLimit ||
                    maker === account ||
                    inLiquidation ||
                    isP2PStaked
                  }
                >
                  Accept
                </Button>
              </AcceptOfferActionTooltip>
            );
          }
        }
      : {
          name: 'from',
          title: 'From',
          width: '1fr',
          render: ({ data: { maker, platform } }) => {
            return (
              <Inline gap="0.25rem" alignItems="center">
                <Link href={`${explorerLinkBaseUrl}/address/${maker}`} target="_blank">
                  {truncateAddress(maker)}
                </Link>
                <MarketplaceIcon name={platform.toLowerCase()} size="xsmall" />
              </Inline>
            );
          }
        };
    const offersPriceColumns: DataGridColumn<CheckedOffer>[] = [
      {
        name: 'price',
        title: 'Price',
        width: '1.5fr',
        render: ({ data: { orderItem } }) => {
          return (
            <Inline gap=".5rem" alignItems="center">
              <CryptoIcon symbol={orderItem.symbol} width="auto" />
              <BigText>
                {formatBalance(orderItem.amount)} {orderItem.symbol}
              </BigText>
            </Inline>
          );
        }
      },
      {
        name: 'usdValue',
        title: 'USD Value',
        width: '1fr',
        render: ({ data: { orderItem } }) => formatToCurrency(orderItem.priceInUsd)
      },
      {
        name: 'expiration',
        title: 'Expiration',
        width: '1fr',
        render: ({ data: { expirationTime } }) => {
          return dayjs(expirationTime).fromNow();
        }
      },
      lastColumn
    ];

    return (
      <Collapse border header="Offers" defaultOpen>
        <StyledContainer>
          {loading && <StyledSpinner size="small" />}
          <DataGrid<CheckedOffer>
            columns={offersPriceColumns}
            data={offers}
            pageSize={0}
            noDataMessage="No Offers"
          />
          {totalCount ? (
            <Pagination
              total={totalPage}
              onChange={handlePageChange}
              page={currentPage}
              siblingCount={0}
              startBoundaryCount={3}
            />
          ) : null}
        </StyledContainer>
      </Collapse>
    );
  }
);
