import { H5, H6, Inline, Stack, TypographyVariant } from '@parallel-mono/components';
import { uniqBy } from 'lodash';
import styled from 'styled-components';
import { memo } from 'react';
import { MarketplaceIcon } from '@parallel-mono/business-components';

import { truncateTextMid } from '@/apps/paraspace/utils/format';
import { Collapse, Link } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { platformUrlBuilder } from '@/apps/paraspace/pages/config';
import { useNetworkConfig } from '@/apps/paraspace/hooks';
import { Network } from '@/apps/paraspace/config';
import { BuyListing } from '@/apps/paraspace/pages/Shop/types';

const StaticContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1.5rem;
`;

interface AboutProps {
  data: {
    listings: BuyListing[];
    contractAddress: string;
    tokenId: string;
    standard: string;
    name: string;
    isUniswap?: boolean;
  };
}

const About = ({
  data: { listings, contractAddress, tokenId, standard, name, isUniswap }
}: AboutProps) => {
  const { chainId } = useWeb3Context();
  const {
    explorerLink: [explorerLinkBaseUrl]
  } = useNetworkConfig();
  return (
    <Collapse border header="About" defaultOpen>
      <Stack inset="1.5rem 0 0 0">
        {listings.length > 0 && !isUniswap && (
          <Stack gap="0.25rem">
            <H6 skin="secondary" fontWeight="medium">
              Listed On
            </H6>
            <Inline gap=".5rem">
              {uniqBy(listings, 'platform').map(({ platform }) => {
                return (
                  <a
                    key={platform}
                    target="_blank"
                    href={platformUrlBuilder({
                      platform,
                      network: chainId as Network,
                      payload: {
                        type: 'asset',
                        address: contractAddress,
                        id: tokenId
                      }
                    })}
                    rel="noreferrer"
                  >
                    <MarketplaceIcon
                      name={platform!.toLowerCase()}
                      size="small"
                      alt="marketplace"
                    />
                  </a>
                );
              })}
            </Inline>
          </Stack>
        )}
        <StaticContent>
          <Stack gap="0.25rem">
            <H6 skin="secondary" fontWeight="medium">
              Collection
            </H6>
            <H5>{name}</H5>
          </Stack>
          <Stack gap="0.25rem">
            <H6 skin="secondary" fontWeight="medium">
              Contract Address
            </H6>
            <Link
              variant={TypographyVariant.header5}
              target="_blank"
              href={`${explorerLinkBaseUrl}/address/${contractAddress}`}
              rel="noreferrer"
            >
              {contractAddress && truncateTextMid(contractAddress)}
            </Link>
          </Stack>
          <Stack gap="0.25rem">
            <H6 skin="secondary" fontWeight="medium">
              Token Standard
            </H6>
            <H5>{standard}</H5>
          </Stack>
          <Stack gap="0.25rem">
            <H6 skin="secondary" fontWeight="medium">
              Token ID
            </H6>
            <H5>{tokenId}</H5>
          </Stack>
        </StaticContent>
      </Stack>
    </Collapse>
  );
};

export const AboutSection = memo(About);
