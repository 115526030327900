import { memo } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { ModalContent } from './ModalContent';

import { Maybe } from '@/apps/paraspace/typings/basic';

export type AddLiquidityModalProps = {
  tokenId: Maybe<number | string>;
  symbol: Maybe<string>;
  onFinish?: () => void;
  onTransactionCreated?: () => void;
} & Omit<ModalProps, 'children'>;

export const AddLiquidityModal = memo(
  ({ tokenId, symbol, onFinish, onTransactionCreated, ...others }: AddLiquidityModalProps) => {
    return (
      <Modal {...others}>
        {symbol !== null && tokenId !== null && (
          <ModalContent
            symbol={symbol}
            tokenId={tokenId}
            onFinish={onFinish}
            onTransactionCreated={onTransactionCreated}
          />
        )}
      </Modal>
    );
  }
);
