import { useMemo } from 'react';

import { useToggles } from '../contexts/TogglesContext';
import { useEOAProvider } from '../contexts';

import { apps, isInternalApp } from '@/apps/consts';

export const useValidApps = () => {
  const toggles = useToggles();
  const { chainId } = useEOAProvider();

  const validApps = useMemo(
    () =>
      apps
        .filter(app => app.supportedNetworks.includes(chainId))
        .filter(it => !it.toggle || toggles[it.toggle]),
    [toggles, chainId]
  );
  const internalApps = useMemo(() => validApps.filter(isInternalApp), [validApps]);
  return {
    allApps: validApps,
    internalApps
  };
};
