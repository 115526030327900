import { formatNumber } from '@parallel-mono/utils';
import { HTMLAttributes, memo, ReactNode } from 'react';
import styled from 'styled-components';

import rightArrow from './rightArrow.png';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
`;

type NumberRangeProps = HTMLAttributes<HTMLDivElement> & {
  start: number;
  end: number;
  formatter: (value: number) => ReactNode;
  symbol?: string;
};

const RightArrow = styled.img.attrs({ alt: 'right arrow', src: rightArrow })`
  width: 1.5rem;
  margin: 0 0.625rem;
`;

export const NumberRange = memo((props: NumberRangeProps) => {
  const { start, end, formatter = formatNumber, symbol, ...others } = props;
  const determinant = start === end;
  if (determinant) {
    return (
      <Container {...others}>
        {formatter(start)} {symbol}
      </Container>
    );
  }
  return (
    <Container>
      {formatter(start)} {symbol}
      <RightArrow />
      {formatter(end)} {symbol}
    </Container>
  );
});
