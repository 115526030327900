import { ComponentProps, memo } from 'react';
import { SmallText } from '@parallel-mono/components';
import styled from 'styled-components';

const FactorContainer = styled(SmallText)`
  max-width: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type FactorContainerProps = ComponentProps<typeof SmallText>;

export const Factor = memo(({ children, ...others }: FactorContainerProps) => {
  return <FactorContainer {...others}>{children}</FactorContainer>;
});
