import BigNumber from 'bignumber.js';
import { memo, useCallback, useMemo } from 'react';
import { InfoPanel } from '@parallel-mono/business-components';

import { useNFTPageUIProvider } from '@/apps/paraspace/pages/Credit/contexts';
import {
  SuccessState as RawSuccessState,
  SuccessStateProps as RawSuccessStateProps,
  StackedIcons
} from '@/apps/paraspace/components';
import { formatToCurrency } from '@/apps/paraspace/utils/format';
import { ERC721Symbol } from '@/apps/paraspace/typings';

type SuccessStateProps = Omit<RawSuccessStateProps, 'children' | 'title' | 'desc'> & {
  symbol: ERC721Symbol;
  tokenIds: number[];
  increasedBorrowLimit: BigNumber;
};

export const SuccessState = memo(
  ({ symbol, tokenIds, increasedBorrowLimit, onAction: onClose, ...others }: SuccessStateProps) => {
    const tokensIcon = useMemo(
      () => (
        <StackedIcons
          assets={tokenIds.map(tokenId => ({
            tokenId,
            symbol
          }))}
        />
      ),
      [tokenIds, symbol]
    );

    const { scrollToERC20Table } = useNFTPageUIProvider();

    const handleClose = useCallback(() => {
      onClose?.();
      scrollToERC20Table();
    }, [onClose, scrollToERC20Table]);

    return (
      <RawSuccessState
        icon={tokensIcon}
        desc={`Successfully Supplied ${tokenIds.length} NFT`}
        actionButtonText="Go to Borrow"
        tip="You have moved these NFTs and any debt associated with them from other protocols to ParaSpace. You can now use them as collateral and borrow ERC-20 assets."
        onAction={handleClose}
        {...others}
      >
        <InfoPanel
          width="100%"
          skin="primary"
          infos={[
            {
              title: 'Borrow Limit Added',
              value: formatToCurrency(increasedBorrowLimit)
            }
          ]}
        />
      </RawSuccessState>
    );
  }
);
