import { H6, Inline, Modal, Tabs, useBreakpoints, Image } from '@parallel-mono/components';
import { FC, ReactNode, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Tab } from '@parallel-mono/components/components/Tabs/TabsBase';
import tinycolor from 'tinycolor2';
import { Environment } from 'parax-sdk';

import { networkIconMap } from '../constants';
import { useAppSelector } from '../contexts';

import { AppMenus, MenuIcon } from './AppMenus';

import { ValuePill } from '@/apps/paraspace/components';
import config, { NETWORK_NAME_MAP, Network, getChainKey } from '@/apps/parax/config';
import { AppConfig, apps, isInternalApp, externalNetworks, isExternalApp } from '@/apps/consts';
import { useEOAProvider } from '@/apps/parax/contexts';
import { Maybe } from '@/apps/parax/typings/basic';
import { useToggles } from '@/apps/parax/contexts/TogglesContext';
import { env } from '@/env';

type NetworkTab = Tab & {
  content: ReactNode;
  network: Maybe<Network>;
};

const StyledModal = styled(Modal)`
  .modal {
    position: absolute;
    top: 15%;
    ${({ theme }) => theme.breakpoints.only('mobile')`
      width: 90%;
    `}
  }
  .body {
    padding: 2rem 1rem;
  }
`;
const StyledTabs = styled(Tabs<NetworkTab>)`
  .slot {
    width: 100%;
  }
  .tab {
    height: 32px;
  }
`;

const ClickablePill = styled(ValuePill)<{ disabled?: boolean }>`
  cursor: pointer;
  padding: 6px 0.75rem;
  background: ${({ theme }) => tinycolor(theme.skin.background.main).setAlpha(0.5).toString()};
  ${({ disabled, theme }) =>
    disabled
      ? 'cursor: default;'
      : `
    &:hover {
      background: ${tinycolor(theme.skin.background.main).setAlpha(0.75).toString()};
    }`}
`;

const NAVIGATION_NETWORK_NAME_MAP: Record<number, string> = {
  ...NETWORK_NAME_MAP,
  [Network.ARBITRUM_ONE]: 'Arbitrum',
  [Network.ZKSYNC_ERA]: 'zkSync',
  [Network.ARBITRUM_GOERLI]: 'Arbitrum',
  [Network.ZKSYNC_GOERLI]: 'zkSync',
  [Network.PARALLEL_TESTNET]: 'Parallel',
  [Network.PARALLEL_L3_TESTNET]: 'Parallel'
};

export const AppSelector: FC = memo(() => {
  const [activeTab, setActiveTab] = useState<NetworkTab>();
  const { supportedNetworks } = config;
  const navigate = useNavigate();
  const { chainId } = useEOAProvider();
  const { isOpen, handleClose, handleOpen } = useAppSelector();

  const handleExternalAppChange = useCallback(
    (link: string) => {
      window.open(link, '_blank');
      handleClose();
    },
    [handleClose]
  );

  const handleAppChange = useCallback(
    (appConfig: AppConfig, network: Network, path?: string) => {
      const chainPath = getChainKey(network);
      if (isInternalApp(appConfig)) {
        const newPath = `/${chainPath}/apps/${appConfig.route}/${path ?? ''}`;

        if (chainId !== network) {
          window.open(newPath, '_self');
        } else {
          navigate(newPath);
        }
      } else if (isExternalApp(appConfig)) {
        handleExternalAppChange(appConfig.link);
      } else {
        // sibling apps
        navigate(appConfig.route);
      }
      handleClose();
    },
    [chainId, handleClose, handleExternalAppChange, navigate]
  );

  const { mobile, desktop } = useBreakpoints();

  const toggles = useToggles();
  const tabs: NetworkTab[] = useMemo(() => {
    return supportedNetworks
      .map(network => {
        const supportApps = apps
          .filter(app => app.supportedNetworks.includes(network))
          .filter(it => !it.toggle || toggles[it.toggle])
          .map(app => {
            if (app.type === 'internal') {
              return {
                ...app,
                domainApps: app.domainApps?.filter(domainApp =>
                  domainApp.supportedNetworks.includes(network)
                )
              };
            }
            return app;
          });
        return {
          title: mobile ? (
            <MenuIcon size="1.5rem" icon={networkIconMap[network]} />
          ) : (
            <H6>{NAVIGATION_NETWORK_NAME_MAP[network]}</H6>
          ),
          content: (
            <AppMenus
              key={network}
              onAppChange={handleAppChange}
              onExternalAppChange={handleExternalAppChange}
              network={network}
              apps={supportApps}
            />
          ),
          network: network as Maybe<Network>
        };
      })
      .concat(
        externalNetworks.map(externalNetwork => ({
          title: mobile ? (
            <MenuIcon size="1.5rem" icon={externalNetwork.icon} />
          ) : (
            <H6>{externalNetwork.name}</H6>
          ),
          content: (
            <AppMenus
              key={externalNetwork.name}
              onExternalAppChange={handleExternalAppChange}
              onAppChange={handleAppChange}
              apps={[externalNetwork]}
            />
          ),
          network: null
        }))
      );
  }, [handleAppChange, handleExternalAppChange, mobile, supportedNetworks, toggles]);

  useEffect(() => {
    const target = tabs.find(({ network }) => network === chainId);
    const fallbackNetwork =
      env === Environment.PRODUCTION ? Network.PARALLEL : Network.PARALLEL_L3_TESTNET;
    const fallbackTab = tabs.find(it => it.network === fallbackNetwork);
    setActiveTab(target ?? fallbackTab);
  }, [chainId, tabs]);

  return (
    <>
      {desktop ? (
        <ClickablePill onClick={handleOpen}>
          <Inline gap="0.25rem" alignItems="center" justifyContent="center">
            <H6>Apps</H6>
            {chainId && <Image width="1.25rem" src={networkIconMap[chainId]} />}
          </Inline>
        </ClickablePill>
      ) : (
        <Inline onClick={handleOpen} inset="0.5rem">
          <Image width="1.25rem" src={networkIconMap[chainId]} />
        </Inline>
      )}

      <StyledModal
        closable={false}
        size="846px"
        classNames={{ modal: 'modal', body: 'body' }}
        onClose={handleClose}
        isOpen={isOpen}
      >
        <StyledTabs
          classNames={{ slot: 'slot', tab: 'tab' }}
          activeTab={activeTab ?? tabs[0]}
          onActiveTabChange={tab => {
            setActiveTab(tab);
          }}
          tabs={tabs}
        />
        <Inline inset="2rem 0 0 0" width="100%">
          {(activeTab ?? tabs[0]).content}
        </Inline>
      </StyledModal>
    </>
  );
});
