import { Environment } from 'parax-sdk';
import {
  ChainId,
  arbitrumOneChainConfig,
  arbitrumSepoliaChainConfig,
  ethereumChainConfig,
  mantaChainConfig,
  merlinChainConfig,
  parallelChainConfig,
  parallelTestnetChainConfig,
  sepoliaChainConfig
} from '@parallel-utils/contracts-registry';
import { Address } from 'viem';

import { NetworkConfig } from '../types';

import { env } from '@/env';

export type SupportedChainId =
  | ChainId.Ethereum
  | ChainId.Sepolia
  | ChainId.ArbitrumOne
  | ChainId.ArbitrumSepolia
  | ChainId.Manta
  | ChainId.Merlin
  | ChainId.Parallel
  | ChainId.ParallelTestnet;

const sepolia: NetworkConfig = {
  ...sepoliaChainConfig,
  chainId: ChainId.Sepolia,
  symbol: 'sepolia',
  nativeTokenBridge: '0x147617a1CC30A64Bc35Dc1CC6E239Db5C8e85E53'
};

const ethereum: NetworkConfig = {
  ...ethereumChainConfig,
  chainId: ChainId.Ethereum,
  symbol: 'ethereum',
  nativeTokenBridge: '0x12485B9d469c1D51d05b5C39e009D50eF0170cF7'
};

const arbitrum: NetworkConfig = {
  ...arbitrumOneChainConfig,
  chainId: ChainId.ArbitrumOne,
  symbol: 'arbitrum',
  nativeTokenBridge: ''
};

const arbitrumTest: NetworkConfig = {
  ...arbitrumSepoliaChainConfig,
  chainId: ChainId.ArbitrumSepolia,
  symbol: 'arbitrum',
  nativeTokenBridge: ''
};

const manta: NetworkConfig = {
  ...mantaChainConfig,
  chainId: ChainId.Manta,
  symbol: 'manta',
  nativeTokenBridge: ''
};

const parallelTest: NetworkConfig = {
  ...parallelTestnetChainConfig,
  chainId: ChainId.ParallelTestnet,
  symbol: 'parallel',
  nativeTokenBridge: ''
};

const parallel: NetworkConfig = {
  ...parallelChainConfig,
  chainId: ChainId.Parallel,
  symbol: 'parallel',
  nativeTokenBridge: '',
  publicJsonRPCUrl: ['https://rpc.parallel.fi'],
  explorerLink: null
};

const merlin: NetworkConfig = {
  ...merlinChainConfig,
  chainId: ChainId.Merlin,
  symbol: 'merlin',
  nativeTokenBridge: ''
};

export const BridgeNetworks: Record<SupportedChainId, NetworkConfig> = {
  [ChainId.Parallel]: parallel,
  [ChainId.ParallelTestnet]: parallelTest,
  [ChainId.Ethereum]: ethereum,
  [ChainId.Sepolia]: sepolia,
  [ChainId.ArbitrumSepolia]: arbitrumTest,
  [ChainId.ArbitrumOne]: arbitrum,
  [ChainId.Manta]: manta,
  [ChainId.Merlin]: merlin
};

export const parallelChain: NetworkConfig = {
  [Environment.DEVELOPMENT]: parallelTest,
  [Environment.STAGING]: parallelTest,
  [Environment.PRODUCTION]: parallel
}[env];

export const supportedBridgeChains: NetworkConfig[] = {
  [Environment.DEVELOPMENT]: [sepolia, arbitrumTest],
  [Environment.STAGING]: [sepolia, arbitrumTest],
  [Environment.PRODUCTION]: [ethereum, arbitrum, manta, merlin]
}[env];

const parallelTestnetNetwork = {
  chainId: parallelChain.chainId,
  confirmPeriodBlocks: 20,
  ethBridge: {
    bridge: '0x464E890e04798D17a5f0a6173Bfa85845C301844',
    inbox: '0x147617a1CC30A64Bc35Dc1CC6E239Db5C8e85E53',
    outbox: '0x92f35A6C49865539c677636948759769C6715937',
    rollup: '0x3157FAb3bEB5Dc10193E912D90fC5B625BdF7aB7',
    sequencerInbox: '0x832181131b81e64e03332EFf89Ef77A4C9ba73F3'
  },
  isCustom: true,
  name: parallelChain.name,
  parentChainId: ChainId.Sepolia,
  retryableLifetimeSeconds: 604800
};

const parallelMainnetNetwork = {
  chainId: parallelChain.chainId,
  confirmPeriodBlocks: 50000,
  ethBridge: {
    bridge: '0x5a961c7D162195a9Dc5a357Cc168b0694283382E',
    inbox: '0x12485B9d469c1D51d05b5C39e009D50eF0170cF7',
    outbox: '0xB6e0586616ebE79b2F86dDB32048c500D23b3AC3',
    rollup: '0x6594085ca55a2B3a5fAD1C57A270D060eEa99877',
    sequencerInbox: '0xb4795A0edae98d7820C37F06f6b858e7acb51DF8'
  },
  isCustom: true,
  name: parallelChain.name,
  parentChainId: ChainId.Ethereum,
  retryableLifetimeSeconds: 604800
};

export const parallelNetworkBridgeConfig = {
  [Environment.DEVELOPMENT]: parallelTestnetNetwork,
  [Environment.STAGING]: parallelTestnetNetwork,
  [Environment.PRODUCTION]: parallelMainnetNetwork
}[env];

export const ARB_SYS: Address = '0x0000000000000000000000000000000000000064';
