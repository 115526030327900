import { useCallback, useState } from 'react';
import { noop } from 'lodash';

import { NftInfo, Offer } from '../../../types';
import { AcceptOfferModalProps } from '../modals';

const defaultAcceptOfferModalProps: AcceptOfferModalProps = {
  onClose: noop,
  onError: noop,
  onFinish: noop,
  isOpen: false
};

export const useAcceptOffer = (): [
  AcceptOfferModalProps,
  (offer: Offer, nftInfo: NftInfo) => Promise<void>
] => {
  const [acceptOfferModalProps, setAcceptOfferModalProps] = useState<AcceptOfferModalProps>(
    defaultAcceptOfferModalProps
  );

  const acceptOffer = useCallback(
    (offer: Offer, nftInfo: NftInfo) => {
      return new Promise<void>((resolve, reject) => {
        setAcceptOfferModalProps(prev => ({
          ...prev,
          isOpen: true,
          offer,
          nftInfo,
          onClose: () => {
            setAcceptOfferModalProps(prevState => ({
              ...prevState,
              isOpen: false
            }));
          },
          onFinish: resolve,
          onError: reject
        }));
      });
    },
    [setAcceptOfferModalProps]
  );
  return [acceptOfferModalProps, acceptOffer];
};
