import { useMemo, createContext, memo, ReactNode, useState, useContext } from 'react';

import config from '../../config';
import { Toggles } from '../../config/toggles';

type TogglesContextValue = {
  toggles: Toggles;
  updateToggle: (updater: (prev: Toggles) => Toggles) => void | ((v: Toggles) => void);
};

const TogglesContext = createContext<TogglesContextValue>({
  toggles: {},
  updateToggle: () => {
    throw new Error('not implemented yet');
  }
});

export const TogglesProvider = memo(({ children }: { children: ReactNode }) => {
  const [toggles, setToggles] = useState<Toggles>(config.toggles);

  const value: TogglesContextValue = useMemo(
    () => ({
      toggles,
      updateToggle: setToggles
    }),
    [toggles, setToggles]
  );

  return <TogglesContext.Provider value={value}>{children}</TogglesContext.Provider>;
});

export const useToggle = (key: string) => {
  return Boolean(useContext(TogglesContext).toggles[key]);
};

export const useToggles = () => {
  return useContext(TogglesContext).toggles;
};

export const useToggleUpdater = () => {
  return useContext(TogglesContext).updateToggle;
};
