import styled from 'styled-components';
import { Stack, Card, SmallText } from '@parallel-mono/components';
import { Link as RouterLink } from 'react-router-dom';

import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { Link } from '@/apps/paraspace/components';

const StackWrapper = styled(Stack).attrs({ as: Card, border: true, inset: '.75rem', gap: '.5rem' })`
  text-align: center;
`;

export const GoApeCoinPoolWidget = () => {
  return (
    <StackWrapper>
      <SmallText skin="secondary">
        All staking rewards are supplied to ApeCoin Pool for compound interest.
      </SmallText>
      <Link as={RouterLink} to={`${absoluteRouteNames.APE_STAKING.COIN_POOL.index}/withdraw`}>
        Go to ApeCoin Pool
      </Link>
    </StackWrapper>
  );
};
