import { ReactNode, createContext, memo, useContext, useMemo } from 'react';

import { useBuyShopItem } from '../hooks';
import { BuyShopItemModal, BuyShopItemPayload } from '../modals';

type ShopActionsContextValue = {
  buyShopItem: (payload: BuyShopItemPayload) => Promise<void>;
};

const ShopActionsContext = createContext<ShopActionsContextValue>({
  buyShopItem: () => {
    throw new Error('Not implemented yet');
  }
});

export const ShopActionsProvider = memo(({ children }: { children: ReactNode }) => {
  const [buyShopItemModalProps, buyShopItem] = useBuyShopItem();

  const contextValue = useMemo(() => ({ buyShopItem }), [buyShopItem]);

  return (
    <ShopActionsContext.Provider value={contextValue}>
      <BuyShopItemModal {...buyShopItemModalProps} />
      {children}
    </ShopActionsContext.Provider>
  );
});

export const useShopActions = () => {
  const { buyShopItem } = useContext(ShopActionsContext);

  return useMemo(() => ({ buyShopItem }), [buyShopItem]);
};
