import { memo, useMemo } from 'react';
import { Icon } from '@parallel-mono/components';
import { Outlet } from 'react-router-dom';

import { PageLayout, SideMenus } from '@/apps/paraspace/components';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { Feature } from '@/apps/paraspace/config';
import { useAppConfig } from '@/apps/paraspace/hooks';

const menus = [
  {
    icon: <Icon name="setting" strokeWidth={2} />,
    name: 'Profile Settings',
    path: absoluteRouteNames.LENDING.USER_INFO.PROFILE,
    featureKey: Feature.ProfileSetting
  },
  {
    icon: <Icon name="checkSquare" strokeWidth={2} />,
    name: 'NFT Delegation',
    path: absoluteRouteNames.LENDING.USER_INFO.NFT_DELEGATION,
    featureKey: Feature.NftDelegation
  }
];

export const UserInfo = memo(() => {
  const { features } = useAppConfig();

  const filteredMenus = useMemo(
    () =>
      menus.filter(it => {
        if (it.featureKey && !features.includes(it.featureKey)) {
          return false;
        }
        return true;
      }),
    [features]
  );

  return (
    <PageLayout
      mainContent={<Outlet />}
      sideMenu={filteredMenus.length < 2 ? undefined : <SideMenus menus={filteredMenus} />}
    />
  );
});
