import { HostedImage } from '@parallel-mono/business-components';
import { H2, Text, Stack, Card, Spinner, Icon, H3 } from '@parallel-mono/components';
import { memo, useCallback, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';

import { NotConnect, NotSign } from '../components';

import { LinkButton } from '@/apps/paraspace/components';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { useConfirmProfileEmailMutation } from '@/apps/paraspace/generated/graphql';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { useWeb3TokenAuth } from '@/apps/paraspace/contexts';

enum ConfirmingStatus {
  Success,
  Failed,
  Loading
}

const StyledWrapper = styled(Stack)`
  height: 20rem;
  margin-top: 2rem;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled(Stack)`
  position: absolute;
  top: -1rem;
  right: -1rem;
  height: 2.5rem;
  z-index: 9;
  ::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background-color: ${({ theme }) => theme.skin.background.main};
  }
`;

const PageContent = memo(() => {
  const [status, setStatus] = useState<ConfirmingStatus>(ConfirmingStatus.Loading);
  const { search } = useLocation();
  const theme = useTheme();

  const [confirmProfileEmail, { data }] = useConfirmProfileEmailMutation();

  const confirmingResult = useMemo(() => data?.confirmEmail, [data?.confirmEmail]);

  const token = useMemo(() => new URLSearchParams(search).get('token') ?? '', [search]);

  const handleConfirm = useCallback(async () => {
    try {
      setStatus(ConfirmingStatus.Loading);
      const result = await confirmProfileEmail({
        variables: {
          token
        }
      });
      if (result.data?.confirmEmail.result) {
        setStatus(ConfirmingStatus.Success);
        return;
      }
      setStatus(ConfirmingStatus.Failed);
    } catch (e) {
      setStatus(ConfirmingStatus.Failed);
    }
  }, [confirmProfileEmail, token]);

  useAsyncEffect(() => {
    handleConfirm();
  }, [handleConfirm]);

  return (
    <StyledWrapper alignItems="center" justifyContent="center">
      {status === ConfirmingStatus.Loading && <Spinner size="large" />}
      {status === ConfirmingStatus.Failed && (
        <Stack alignItems="center">
          <Icon name="closeContained" size="4rem" color={theme.skin.error.main} />
          <Stack alignItems="center" gap="0">
            <H3>{confirmingResult?.title}</H3>
            <Text>{confirmingResult?.message}</Text>
          </Stack>

          <LinkButton to={absoluteRouteNames.LENDING.USER_INFO.PROFILE}>
            Back to Profile Settings
          </LinkButton>
        </Stack>
      )}
      {status === ConfirmingStatus.Success && (
        <Card style={{ marginTop: '2rem' }}>
          <Stack gap="2rem" justifyContent="center" alignItems="center">
            <ImageWrapper>
              <HostedImage name="design/PDS_V3/envelope" width="9.5rem" />
              <IconWrapper>
                <Icon
                  name="checkContained"
                  color={theme.skin.success.main}
                  size="2.5rem"
                  className="icon"
                />
              </IconWrapper>
            </ImageWrapper>
            <Stack gap="0.25rem" alignItems="center">
              <H2>{confirmingResult?.title}</H2>
              <Text>{confirmingResult?.message}</Text>
            </Stack>
            <LinkButton to={absoluteRouteNames.LENDING.USER_INFO.PROFILE}>
              View Profile Settings
            </LinkButton>
          </Stack>
        </Card>
      )}
    </StyledWrapper>
  );
});

export const EmailActivation = memo(() => {
  const { isValidToken, walletConnected } = useWeb3TokenAuth();

  if (!walletConnected) {
    return (
      <Stack inset="2rem 0">
        <Card border inset="4rem 0">
          <NotConnect />
        </Card>
      </Stack>
    );
  }
  if (!isValidToken) {
    return (
      <Stack inset="2rem 0">
        <Card border inset="4rem 0">
          <NotSign />
        </Card>
      </Stack>
    );
  }

  return <PageContent />;
});
