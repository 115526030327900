import { CryptoIcon } from '@parallel-mono/business-components';
import { Button, H4, Icon, Inline, Spinner, Stack, Text } from '@parallel-mono/components';
import styled from 'styled-components';
import { useCallback, useMemo, useState } from 'react';
import {
  TokenOption,
  Value
} from '@parallel-mono/business-components/components/SelectableTokenInput/types';
import { floor } from 'lodash';

import useWNativeToken from '@/apps/paraspace/pages/hooks/useWNativeToken';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { AddAssetsButton, SelectableTokenInput } from '@/apps/paraspace/components';
import { useContractsMap } from '@/apps/paraspace/hooks';

const StyledIcon = styled(Icon)`
  align-self: center;
`;

export const AddWETHForm = ({ hideAddWETHForm }: { hideAddWETHForm: () => void }) => {
  const [ethInputAmount, setEthInputAmount] = useState<number | null>(null);
  const [state, setState] = useState<'default' | 'pending' | 'completed'>('default');

  const toast = useParallelToast();
  const contracts = useContractsMap();
  const { depositWNativeToken: depositWETH } = useWNativeToken(contracts.WETH);
  const { erc20InfoMap } = useMMProvider();

  const handleDeposit = useCallback(() => {
    if (!ethInputAmount) return;
    setState('pending');
    toast.promise(
      depositWETH(ethInputAmount)
        .then(async tx => {
          await tx?.wait();
          setState('completed');
        })
        .catch(e => {
          setState('default');
          throw getUserFriendlyError(e);
        })
    );
  }, [toast, depositWETH, ethInputAmount]);

  const { priceInUsd, balance } = erc20InfoMap[ERC20Symbol.ETH] || {};
  const ethPriceInUSD = priceInUsd?.toNumber();
  const ethBalance = useMemo(
    () => floor(balance?.toNumber() || 0, MAXIMUM_BALANCE_DECIMALS),
    [balance]
  );

  const handleETHMaxClick = () => {
    setEthInputAmount(ethBalance);
  };

  const handleETHChange = ({ amount }: Value<TokenOption>) => {
    setEthInputAmount(amount || 0);
  };

  const balanceShortage = useMemo(
    () => (ethInputAmount ?? 0) - ethBalance,
    [ethInputAmount, ethBalance]
  );

  if (state === 'default') {
    return (
      <Stack>
        <SelectableTokenInput
          tokens={[
            {
              name: 'ETH',
              symbol: ERC20Symbol.ETH,
              balance: ethBalance,
              priceInUSD: ethPriceInUSD
            }
          ]}
          value={{
            token: {
              name: 'ETH',
              symbol: ERC20Symbol.ETH,
              balance: ethBalance,
              priceInUSD: ethPriceInUSD,
              displayBalance: `${ethBalance} ETH`
            },
            amount: ethInputAmount
          }}
          actionButtonText="Max"
          decimals={MAXIMUM_BALANCE_DECIMALS}
          onActionButtonClicked={handleETHMaxClick}
          onChange={handleETHChange}
        />
        <StyledIcon name="chevronDown" />
        <SelectableTokenInput
          tokens={[
            {
              name: 'WETH',
              symbol: ERC20Symbol.WETH,
              balance: ethBalance,
              priceInUSD: ethPriceInUSD
            }
          ]}
          value={{
            token: {
              name: 'WETH',
              symbol: ERC20Symbol.WETH,
              balance: ethBalance,
              priceInUSD: ethPriceInUSD
            },
            amount: ethInputAmount
          }}
          decimals={MAXIMUM_BALANCE_DECIMALS}
          onChange={handleETHChange}
        />
        <Button
          size="large"
          block
          onClick={handleDeposit}
          disabled={!ethInputAmount || ethInputAmount > ethBalance}
        >
          Wrap
        </Button>
        {(balanceShortage > 0 || ethBalance === 0) && (
          <AddAssetsButton
            context={{
              erc20Assets: [
                {
                  symbol: ERC20Symbol.ETH,
                  amount: balanceShortage
                }
              ],
              erc721Assets: []
            }}
          />
        )}
      </Stack>
    );
  }
  if (state === 'pending') {
    return (
      <Stack alignItems="center">
        <Spinner size="large" />
        <Stack gap=".25rem" alignItems="center">
          <H4>Wrapping ETH to wETH</H4>
          <Text skin="secondary">This may take up to 30 seconds...</Text>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack alignItems="center">
      <Inline gap="1rem" alignItems="center">
        <CryptoIcon symbol="ETH" size="large" />
        <Icon name="arrowRight" />
        <CryptoIcon symbol="WETH" size="large" />
      </Inline>
      <Stack gap=".25rem" alignItems="center">
        <H4>Success!</H4>
        <Text skin="secondary">You can now use your WETH.</Text>
      </Stack>
      <Button size="large" block skin="secondary" onClick={hideAddWETHForm}>
        Continue to Make Offer
      </Button>
    </Stack>
  );
};
