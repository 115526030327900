import { Container } from '@parallel-mono/components';
import styled, { CSSProperties } from 'styled-components';

export const HeaderWrapper = styled(Container)<{ top: CSSProperties['top'] }>`
  position: sticky;
  width: 100%;
  height: var(--header-height-pc);
  top: ${({ top }) => top};
  left: 0px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.skin.grey[200]}`};

  ${({ theme }) => theme.breakpoints.down('desktop')`
    height: auto;
    padding: 1rem;
  `};
`;
