import { ReactNode, memo, useCallback } from 'react';
import styled from 'styled-components';
import {
  useDualModeState,
  Scroller,
  ScrollerProps,
  SmallText,
  Inline,
  Spinner
} from '@parallel-mono/components';

import { NFTThumbnailCheck } from '../NFTThumbnailCheck';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type NftToken = {
  symbol: ERC721Symbol;
  tokenId: number;
  disabled?: boolean;
  disabledTip?: string;
  desc?: ReactNode;
};
export type ChangeSelectedHandler = (token: NftToken | NftToken[] | null) => void;
export type ERC721TokenSelectorProps = {
  selectedToken?: Maybe<NftToken | NftToken[]>;
  defaultSelectedToken?: Maybe<NftToken | NftToken[]>;
  tokenList: NftToken[];
  multiselect?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onChange: ChangeSelectedHandler;
} & Omit<ScrollerProps, 'children' | 'onChange'>;

const StyledScroller = styled(Scroller)`
  user-select: none;
  max-width: 100%;
`;

const SelectableTokenItem = styled(NFTThumbnailCheck)`
  width: 6.5rem;
`;

export const ERC721TokenSelector = memo(
  ({
    defaultSelectedToken,
    selectedToken,
    tokenList,
    multiselect = false,
    disabled = false,
    onChange,
    loading,
    ...others
  }: ERC721TokenSelectorProps) => {
    const [finalSelectedToken, setInternalSelectedToken] = useDualModeState<
      Maybe<NftToken> | Maybe<NftToken[] | undefined>
    >(defaultSelectedToken, selectedToken);

    const createHandleClickTokenItem = useCallback(
      (token: NftToken) => (checked: boolean) => {
        if (multiselect) {
          const prevSelectedToken = (finalSelectedToken as NftToken[]) ?? [];
          const newFinalSelectedToken = checked
            ? [...prevSelectedToken, token]
            : prevSelectedToken.filter(
                item => !(item.symbol === token.symbol && item.tokenId === token.tokenId)
              );
          setInternalSelectedToken(newFinalSelectedToken);
          onChange(newFinalSelectedToken);
          return;
        }
        setInternalSelectedToken(checked ? token : null);
        onChange(checked ? token : null);
      },
      [finalSelectedToken, multiselect, onChange, setInternalSelectedToken]
    );

    const getActiveStatus = useCallback(
      (token: NftToken) => {
        if (!finalSelectedToken) return false;
        if (multiselect) {
          return (finalSelectedToken as NftToken[]).some(
            item => item.symbol === token.symbol && item.tokenId === token.tokenId
          );
        }
        return (
          (finalSelectedToken as NftToken).symbol === token.symbol &&
          (finalSelectedToken as NftToken).tokenId === token.tokenId
        );
      },
      [finalSelectedToken, multiselect]
    );

    return (
      <StyledScroller {...others}>
        <Inline gap="0.75rem">
          {!loading &&
            tokenList?.map(token => {
              const isDisabled = disabled || Boolean(token.disabled);
              return (
                <SelectableTokenItem
                  size="small"
                  checked={getActiveStatus(token)}
                  disabled={isDisabled}
                  disabledTip={token.disabledTip}
                  key={token.symbol + token.tokenId}
                  symbol={token.symbol}
                  tokenId={token.tokenId}
                  onChange={createHandleClickTokenItem(token)}
                >
                  <SmallText>#{token.tokenId}</SmallText>
                  {token.desc}
                </SelectableTokenItem>
              );
            })}
        </Inline>
        {loading && (
          <Inline width="100%" alignItems="center" justifyContent="center">
            <Spinner />
          </Inline>
        )}
      </StyledScroller>
    );
  }
);
