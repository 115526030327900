import BigNumber from 'bignumber.js';
import { useCallback, useMemo, useState } from 'react';
import { WalletBalanceProvider } from 'paraspace-utilities-contract-helpers';

import { ERC20Symbol } from '../../../../typings';
import { getERC20Config, getV1ContractConfig } from '../../../../config';
import { Maybe } from '../../../../typings/basic';
import { useWeb3Context } from '../../../../contexts';
import useAsyncEffect from '../../../../hooks/useAsyncEffect';
import { MINIMUM_ACCOUNTABLE_NUM } from '../../../config';

import { zero } from '@/apps/paraspace/consts/values';

export type cAPEBalanceInfo = { symbol: ERC20Symbol.CAPE; address: string; balance: BigNumber };

export const useV1cAPEBalanceInfo = () => {
  const [cAPEBalanceInfo, setCapeBalanceInfo] = useState<Maybe<cAPEBalanceInfo>>(null);

  const {
    chainId,
    authentication: {
      meta: { account: EOAAccount }
    },
    provider,
    env
  } = useWeb3Context();
  const ERC20Config = useMemo(() => getERC20Config(chainId, env), [chainId, env]);

  const walletBalanceProvider = useMemo(
    () =>
      new WalletBalanceProvider({
        walletBalanceProviderAddress: getV1ContractConfig(env).WalletBalanceProvider,
        provider
      }),
    [provider, env]
  );

  const getUserV1cAPEBalanceInfos = useCallback(
    async (accountAddress: string) => {
      const symbol = ERC20Symbol.CAPE;
      const { decimals } = ERC20Config[symbol]!;
      const contractAddress = getV1ContractConfig(env).cAPE;
      try {
        const balances = await walletBalanceProvider.batchBalanceOf(
          [accountAddress],
          [contractAddress]
        );
        const balance = new BigNumber(balances[0].toString()).shiftedBy(-decimals);
        setCapeBalanceInfo({
          symbol,
          address: contractAddress,
          balance: balance.gt(MINIMUM_ACCOUNTABLE_NUM) ? balance : zero
        });
      } catch (e) {
        // There is no "v1 cAPE contract" on sepolia network, so the "cAPE convert feature" is wrong on sepolia network, but works well on mainnet.
        console.error('Failed to fetch v1 cAPE balance', e);
        setCapeBalanceInfo({
          symbol,
          address: contractAddress,
          balance: zero
        });
      }
    },
    [ERC20Config, walletBalanceProvider, env]
  );

  const refreshUserV1cAPEBalanceInfos = useCallback(() => {
    getUserV1cAPEBalanceInfos(EOAAccount);
  }, [EOAAccount, getUserV1cAPEBalanceInfos]);

  useAsyncEffect(async () => {
    if (EOAAccount) {
      await getUserV1cAPEBalanceInfos(EOAAccount);
    }
  }, [EOAAccount, getUserV1cAPEBalanceInfos]);

  return {
    balanceInfo: cAPEBalanceInfo,
    refreshUserV1cAPEBalanceInfos
  };
};
