import { Button, H6, Icon, Inline } from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { useMemo } from 'react';
import { formatNumber, sumBy } from '@parallel-mono/utils';

import { useApeStakeManager } from '../../../../contexts';

import PanelCard from './PanelCard';

import { DropdownMenu } from '@/apps/paraspace/components';
import type { ClaimingType } from '@/apps/paraspace/pages/ApePairing/contexts/ApeStakingManagerProvider/ClaimAllRewardsModal';
import { zero } from '@/apps/paraspace/consts/values';
import { useApeListStatesAndActions } from '@/apps/paraspace/pages/ApePairing/contexts';
import { GoApeCoinPoolWidget } from '@/apps/paraspace/pages/ApePairing/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';

const StakingRewardsPanel = () => {
  const { apesInBalanceAndInSuppliedExcludingInP2P } = useApeListStatesAndActions();
  const { claimAllRewards } = useApeStakeManager();

  const dropdownMenu = useMemo(() => {
    const collections = ['BAYC', 'MAYC', 'BAKC'];

    return collections
      .map(collection => ({
        label: `${collection} Rewards`,
        icon: <CryptoIcon symbol={collection} />,
        onClick: () => claimAllRewards(collection as ClaimingType)
      }))
      .concat({
        label: `All Rewards`,
        icon: <Icon size="2.5rem" name="medal" />,
        onClick: () => claimAllRewards('All')
      });
  }, [claimAllRewards]);

  const totalStakedAmount = useMemo(
    () =>
      apesInBalanceAndInSuppliedExcludingInP2P.reduce(
        (total, ape) => total.plus(ape.stakedAmount || 0),
        zero
      ),
    [apesInBalanceAndInSuppliedExcludingInP2P]
  );

  const unclaimedAmount = useMemo(
    () =>
      sumBy(
        apesInBalanceAndInSuppliedExcludingInP2P.filter(v => v.pendingRewards?.gt(0)),
        ape => {
          const { symbol, pendingRewards, supplied, mainTokenId } = ape;
          // BAKC not supplied and the main token is transferred to the others.
          if (symbol === ERC721Symbol.BAKC && !supplied && mainTokenId) {
            const mainToken = apesInBalanceAndInSuppliedExcludingInP2P.find(
              v => v.tokenId === mainTokenId
            );
            return mainToken ? pendingRewards! : zero;
          }
          // BAKC supplied & mainToken not supplied, the rewards can not be claimed.
          if (symbol === ERC721Symbol.BAKC && supplied && mainTokenId) {
            const mainToken = apesInBalanceAndInSuppliedExcludingInP2P.find(
              v => v.tokenId === mainTokenId
            );
            return mainToken && mainToken.supplied ? pendingRewards! : zero;
          }
          return ape.pendingRewards ?? zero;
        }
      ),
    [apesInBalanceAndInSuppliedExcludingInP2P]
  );

  const rewardsPanelItems = useMemo(() => {
    return [
      {
        key: 'totalStaked',
        field: 'Total Staked',
        value: (
          <Inline gap="0.125rem">
            <H6>{formatNumber(totalStakedAmount)}</H6>
            <H6 skin="secondary" fontWeight="bold">
              APE
            </H6>
          </Inline>
        )
      },
      {
        key: 'unclaimedRewards',
        field: 'Unclaimed Rewards',
        value: (
          <Inline gap="0.125rem">
            <H6>{formatNumber(unclaimedAmount)}</H6>
            <H6 skin="secondary" fontWeight="bold">
              APE
            </H6>
          </Inline>
        )
      }
    ];
  }, [totalStakedAmount, unclaimedAmount]);

  return (
    <PanelCard
      title={
        <Inline gap="0.5rem">
          <Icon name="medal" size="1.25rem" />
          <H6>Staking Rewards</H6>
        </Inline>
      }
      items={rewardsPanelItems}
      actionButton={
        <DropdownMenu
          options={dropdownMenu}
          menuTrigger={
            <Button block skin="secondary">
              Claim All
            </Button>
          }
        />
      }
      descInfo={<GoApeCoinPoolWidget />}
    />
  );
};

export default StakingRewardsPanel;
