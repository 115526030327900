import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ApeStakingTokenSymbol, ERC20Symbol } from '@/apps/paraspace/typings';
import { useApePoolApy } from '@/apps/paraspace/pages/hooks/ApeStaking/useApePoolApy';

export const useOfficialRewardsInfo = (
  stakedAmount: number,
  symbol: ApeStakingTokenSymbol | ERC20Symbol.APE
) => {
  const { erc20InfoMap, basicInfoLoaded } = useMMProvider();
  const [officialApr] = useApePoolApy(symbol);

  if (!basicInfoLoaded || !officialApr) {
    return {
      officialApr: null,
      officialYearlyRewardsInApe: null,
      officialYearlyRewardsInUsd: null
    };
  }

  const { priceInUsd } = erc20InfoMap[ERC20Symbol.APE];

  const officialYearlyRewardsInApe = officialApr.times(stakedAmount);
  const officialYearlyRewardsInUsd = officialYearlyRewardsInApe.times(priceInUsd);

  return {
    officialApr,
    officialYearlyRewardsInApe,
    officialYearlyRewardsInUsd
  };
};
