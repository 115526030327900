import { Stack, H4, Inline, Button, Image } from '@parallel-mono/components';
import { BigNumber } from 'bignumber.js';

import compoundApe from '../../../Images/compound-ape.svg';

import { formatBalance } from '@/apps/paraspace/utils/format';

const StakingSuccess = ({ amount, onClose }: { amount: string; onClose: () => void }) => {
  return (
    <Stack gap="2rem" alignItems="center">
      <Image width="6.25rem" src={compoundApe} />
      <H4>You have Staked {formatBalance(BigNumber(amount))} ApeCoin!</H4>
      <Inline width="100%">
        <Button block size="large" onClick={onClose}>
          Done
        </Button>
      </Inline>
    </Stack>
  );
};

export default StakingSuccess;
