import { H5, Stack, Text } from '@parallel-mono/components';
import styled from 'styled-components';

export const GrayText = styled(Text).attrs({ type: 'secondary' })`
  display: inline-block;
`;

export const BorrowedContainer = styled(Stack).attrs({ width: '100%', gap: '0.5rem' })`
  border: 0.0625rem solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 0.5rem;
`;

export const BorrowedValue = styled(H5)`
  margin-left: 0.25rem;
`;
