import { Modal, ModalProps } from '@parallel-mono/components';
import { memo } from 'react';

import { useApeTokenStakingInfo } from '../../hook/useApeTokenStakingInfo';

import { ApeStakeForm } from './ApeStakeForm';

import { ApeStakingTokenSymbol, WalletType } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type ApeStakeModalProps = {
  symbol: Maybe<ApeStakingTokenSymbol>;
  id: number | null;
  apeSource: WalletType;
  apeCoinSource: WalletType;
  onClose: () => void;
  thumbnail?: string;
} & Omit<ModalProps, 'children' | 'title' | 'id'>;

const ApeStakeModal = memo((props: ApeStakeModalProps) => {
  const { symbol, id, onClose, apeSource, apeCoinSource, ...others } = props;

  const { account } = useWeb3Context();
  const [stakingInfo] = useApeTokenStakingInfo({
    tokenId: id === null ? null : String(id),
    symbol,
    owner: account
  });
  const {
    stakedAmount = null,
    stakeLimit = null,
    apy = null,
    pendingRewards = null
  } = stakingInfo || {};

  return (
    <Modal size="32.5rem" title="Stake" onClose={onClose} {...others}>
      {symbol !== null &&
        id !== null &&
        stakedAmount !== null &&
        stakeLimit !== null &&
        pendingRewards !== null &&
        apy !== null && (
          <ApeStakeForm
            stakedValue={stakedAmount}
            stakedLimitValue={stakeLimit}
            apy={apy}
            symbol={symbol}
            id={id}
            apeSource={apeSource}
            apeCoinSource={apeCoinSource}
            onClose={onClose}
          />
        )}
    </Modal>
  );
});

export default ApeStakeModal;
