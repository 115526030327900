import {
  Card,
  H4,
  H6,
  Inline,
  Stack,
  StackProps,
  SmallText,
  Button,
  Responsive
} from '@parallel-mono/components';
import { FC, memo, useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';
import { isNil } from 'lodash';

import { AssetDetail } from '../../type';

import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useETHValidatorManager } from '@/apps/paraspace/pages/contexts/ETHValidatorProvider';

const StyledCard = styled(Card).attrs({
  inset: '1rem',
  border: true
})`
  flex: 1;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
  `}
`;
const BottomBorderedInline = styled(Inline)`
  border-bottom: 1px solid ${({ theme }) => theme.skin.grey['200']};
  padding-bottom: 0.5rem;
`;

type Props = {
  assetData: AssetDetail;
  effectiveBalance: number;
  balance: number;
  annualProtocolApy: number;
  annualTipOrMEVApy: Maybe<number>;
  unClaimedRewards: BigNumber;
  claimedRewards: BigNumber;
};

export const StakefishNFTBaseInfo: FC<Props & Omit<StackProps, 'children'>> = memo(
  ({
    assetData,
    effectiveBalance,
    balance,
    annualProtocolApy,
    annualTipOrMEVApy,
    unClaimedRewards,
    claimedRewards,
    ...props
  }) => {
    const { claim } = useETHValidatorManager();

    const handleClaimRewards = useCallback(() => {
      if (assetData && assetData.tokenId) {
        claim(assetData.tokenId);
      }
    }, [assetData, claim]);

    const claimDisabled = useMemo(
      () => !assetData.isSupplier || unClaimedRewards.lte(0),
      [assetData.isSupplier, unClaimedRewards]
    );

    const detailInfos = useMemo(() => {
      return [
        {
          titleName: 'Total Balance',
          titleValue: `${formatBalance(balance)} ETH`,
          fields: [
            {
              fieldsName: 'Effective Balance',
              fieldsValue: `${formatBalance(effectiveBalance)} ETH`
            },
            {
              fieldsName: 'Protocol Rewards',
              fieldsValue: `${formatBalance(BigNumber(balance).minus(effectiveBalance))} ETH`
            }
          ]
        },
        {
          titleName: 'Annual Rewards Rate',
          titleValue: formatToPercentage(BigNumber(annualProtocolApy).plus(annualTipOrMEVApy ?? 0)),
          fields: [
            {
              fieldsName: 'Annual Protocol Rewards Rate',
              fieldsValue: formatToPercentage(annualProtocolApy)
            },
            {
              fieldsName: 'Annual Tip/MEV Rewards Rate ',
              fieldsValue: !isNil(annualTipOrMEVApy) ? formatToPercentage(annualTipOrMEVApy) : '-'
            }
          ]
        },
        {
          titleName: 'Unclaimed Rewards',
          titleValue: `${formatNumber(unClaimedRewards)} ETH`,
          fields: [
            {
              fieldsName: 'Claimed Tip/mev Rewards',
              fieldsValue: `${formatNumber(claimedRewards)} ETH`
            },
            {
              fieldsName: 'Total Tip/mev Rewards',
              fieldsValue: `${formatNumber(unClaimedRewards.plus(claimedRewards))} ETH`
            }
          ],
          actionButton: assetData.isOwner
            ? {
                children: 'Claim',
                disabled: claimDisabled,
                onClick: handleClaimRewards
              }
            : null
        }
      ];
    }, [
      annualProtocolApy,
      annualTipOrMEVApy,
      assetData.isOwner,
      balance,
      claimDisabled,
      claimedRewards,
      effectiveBalance,
      handleClaimRewards,
      unClaimedRewards
    ]);

    return (
      <Responsive width="100%" justifyContent="space-between" {...props}>
        {detailInfos.map(item => (
          <StyledCard key={item.titleName}>
            <Stack gap="0.5rem">
              <BottomBorderedInline justifyContent="space-between">
                <Stack gap="0">
                  <H6 skin="secondary">{item.titleName}</H6>
                  <H4>{item.titleValue}</H4>
                </Stack>
                {item.actionButton && (
                  <div>
                    <Button {...item.actionButton}>{item.actionButton?.children}</Button>
                  </div>
                )}
              </BottomBorderedInline>

              <Stack gap="0.5rem">
                {item.fields.map(field => (
                  <Inline gap="0" justifyContent="space-between" key={field.fieldsName}>
                    <SmallText skin="secondary">{field.fieldsName}</SmallText>
                    <SmallText>{field.fieldsValue}</SmallText>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          </StyledCard>
        ))}
      </Responsive>
    );
  }
);
