import { Stack, Inline, H5, H2, Button, H6, Text, Image } from '@parallel-mono/components';
import { useContext } from 'react';

import { levelImages, Line } from './NestingInProgress';
import { LEVELS } from './useMoonBirdsData';

import { Context } from '.';

import { formatSecondsWithRound } from '@/apps/paraspace/utils/format';

interface IProps {
  level: number;
  totalTime: number;
  handleNestClick: () => void;
  inToggleNesting: boolean;
}

const NestingOnHold = ({ level, totalTime, handleNestClick, inToggleNesting }: IProps) => {
  const { isOwner } = useContext(Context);
  return (
    <Stack gap="1.5rem">
      <Inline gap="2.5rem" alignItems="center">
        <Image
          width="11.625rem"
          height="11.625rem"
          src={levelImages[LEVELS[level] as keyof typeof levelImages]}
        />
        <Stack gap="1rem">
          <Stack gap=".25rem">
            <H5 skin="secondary">NEST LEVEL</H5>
            <H2>{LEVELS[level]} Nest</H2>
            <Text skin="secondary">You can nest your Moonbird while supplying on ParaSpace.</Text>
          </Stack>
          <Button skin="secondary" onClick={handleNestClick} disabled={inToggleNesting || !isOwner}>
            Nest
          </Button>
        </Stack>
      </Inline>
      <Line />
      <Stack gap="0.25rem">
        <H6 skin="secondary" fontWeight="medium">
          Total Lifetime Nesting Time
        </H6>
        <H5>{formatSecondsWithRound(totalTime)}</H5>
      </Stack>
    </Stack>
  );
};

export default NestingOnHold;
