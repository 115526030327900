import { EthereumTransactionTypeExtended, Pool } from 'paraspace-utilities-contract-helpers';
import { useCallback, useMemo } from 'react';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';
import submitTransaction from '@/apps/paraspace/utils/submitTransaction';

export type BlurBid = {
  initiator: string;
  paymentToken: string;
  bidingPrice: string;
  marketPlaceFee: string;
  collection: string;
  tokenId: number;
  bidOrderHash: string;
};

const useAcceptBlurBid = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();

  const service = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new Pool(provider, {
      POOL: contracts.PoolProxy,
      WETH_GATEWAY: contracts.WETHGatewayProxy
    });
  }, [contracts.PoolProxy, contracts.WETHGatewayProxy, provider]);

  const initiateAcceptBlurBids = useCallback(
    async (bids: BlurBid[]) => {
      if (!provider || !service) {
        return null;
      }
      const tx = (await service.initiateAcceptBlurBidsRequest(
        bids,
        account
      )) as EthereumTransactionTypeExtended;
      const txRes = submitTransaction({ provider, tx });
      return txRes;
    },
    [provider, service, account]
  );

  const getAcceptBlurBidsRequestStatus = useCallback(
    (bid: BlurBid) => {
      if (!provider || !service) {
        return null;
      }
      return service.getAcceptBlurBidsRequestStatus(bid);
    },
    [provider, service]
  );

  return {
    initiateAcceptBlurBids,
    getAcceptBlurBidsRequestStatus
  };
};

export default useAcceptBlurBid;
