import { noop } from 'lodash';
import { useCallback, useState } from 'react';

import { BuyShopItemPayload, BuyShopItemModalProps } from '../modals';

const defaultModalProps: BuyShopItemModalProps = {
  payload: null,
  isOpen: false,
  onClose: noop
};

export const useBuyShopItem = (): [
  BuyShopItemModalProps,
  (payload: BuyShopItemPayload) => Promise<void>
] => {
  const [modalProps, setModalProps] = useState<BuyShopItemModalProps>(defaultModalProps);

  const buyShopItem = useCallback(
    (payload: BuyShopItemPayload) => {
      if (modalProps.isOpen) {
        throw new Error('There is a supplying in progress');
      }
      return new Promise<void>((resolve, reject) => {
        setModalProps(curr => ({
          ...curr,
          isOpen: true,
          payload,
          onError: reject,
          onFinish: resolve,
          onClose: () => {
            setModalProps(prev => ({ ...prev, isOpen: false }));
          }
        }));
      });
    },
    [modalProps.isOpen]
  );

  return [modalProps, buyShopItem];
};
