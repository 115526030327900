import { memo } from 'react';
import { Button, H3, H5, Inline, Stack, StackProps, Text } from '@parallel-mono/components';

import { Offer } from '../../../../types';

import { Thumbnail } from './Thumbnail';

import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';

type ConfirmCancelFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  offer: Offer;
  onCancel: () => void;
  onSubmit: () => void;
};

export const ConfirmCancelForm = memo(
  ({ offer, onCancel, onSubmit, ...others }: ConfirmCancelFormProps) => {
    const { considerationItem, orderItem } = offer;
    return (
      <Stack>
        <Inline alignItems="center" gap="1rem" {...others}>
          <Thumbnail considerationItem={considerationItem} />
          <Stack gap="0.25rem">
            <H3>{considerationItem.name}</H3>
            <Inline gap="0.5rem">
              <H5>
                Your Offer: {formatBalance(orderItem.amount)} {orderItem.symbol}
              </H5>
              <Text skin="secondary">({formatToCurrency(orderItem.priceInUsd)})</Text>
            </Inline>
          </Stack>
        </Inline>
        <Text>Are you sure you want to cancel your offer to this NFT?</Text>
        <Inline gap="1.5rem" justifyContent="space-between">
          <Button skin="secondary" size="large" onClick={onCancel}>
            Don’t Cancel
          </Button>
          <Button skin="secondary" size="large" onClick={onSubmit}>
            Cancel Offer
          </Button>
        </Inline>
      </Stack>
    );
  }
);
