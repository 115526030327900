import { CollectionConfig, production } from 'paraspace-configs-v2';
import { pickBy } from 'lodash';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Network } from '@/apps/paraspace/config';

export type ImageSize = 'small' | 'medium' | 'large';

const mainnetErc721Config = pickBy(production.nft[Network.MAINNET]?.collection, (_value, key) => {
  return ![ERC721Symbol.UNISWAP_LP, ERC721Symbol.SF_VLDR].includes(key as ERC721Symbol);
}) as Record<ERC721Symbol, CollectionConfig>;

const getImgContractNameBySymbol = (symbol: ERC721Symbol) => {
  if (!symbol || !mainnetErc721Config[symbol]) {
    console.warn(`No image for ${symbol}`);
    return null;
  }

  if ([ERC721Symbol.CRYPTOPUNKS, ERC721Symbol.PUNK].includes(symbol)) return 'PUNKS';

  const { contractName } = mainnetErc721Config[symbol];
  return contractName;
};

export const getERC721Image = ({
  symbol,
  tokenId,
  size,
  api
}: {
  symbol: ERC721Symbol;
  tokenId: string;
  size: ImageSize;
  api: string;
}) => {
  const contractName = getImgContractNameBySymbol(symbol);

  if (!contractName) {
    throw new Error(`contractName not found for ${symbol}`);
  }

  return `${api}/${contractName}/${tokenId}?size=${size}`;
};
