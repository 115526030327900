import { H5, Inline, SelectBaseOption, SmallText, Stack } from '@parallel-mono/components';
import { FC, memo } from 'react';
import dayjs from 'dayjs';

import { HOURS_PER_DAY } from '../../../../../../../../consts/fixtures';

import { Select } from '@/apps/paraspace/components';

const durationList = [
  { label: '1 hour', value: 1 },
  { label: '12 hours', value: 12 },
  { label: '1 day', value: HOURS_PER_DAY },
  { label: '7 days', value: HOURS_PER_DAY * 7 },
  { label: '14 days', value: HOURS_PER_DAY * 14 }
];

const Duration: FC<{
  duration: number;
  onHandleDurationChange: (duration: number) => void;
}> = props => {
  const { duration, onHandleDurationChange } = props;
  return (
    <Inline gap="0" justifyContent="space-between">
      <Stack gap="0">
        <H5>Offer Valid For</H5>
        <SmallText>Expires on {dayjs().add(duration, 'h').format('MMM DD, YYYY HH:mm')}</SmallText>
      </Stack>
      <Select
        options={durationList}
        defaultValue={duration}
        onChange={(option: SelectBaseOption | null) => onHandleDurationChange(option?.value)}
      />
    </Inline>
  );
};

export default memo(Duration);
