import { Card, Responsive, Stack, Tag, useBreakpoints } from '@parallel-mono/components';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { isNil } from 'lodash';
import { formatNumber } from '@parallel-mono/utils';
import styled from 'styled-components';

import {
  OperationCard,
  OfferGrid,
  Container,
  AboutSection,
  CollectionSection,
  ApeStakingInfo,
  MoonbirdsNestSection,
  PropertiesSection
} from './component';
import { useAssetDetail, useAssetLiquidationInfo } from './hooks';

import {
  ImageThumbnailProps,
  NFTThumbnail,
  CustomErrorType,
  useErrorHandler
} from '@/apps/paraspace/components';
import { ApeStakingMainTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';

const SectionRow = styled(Responsive)`
  > * {
    flex: 1;
  }
`;
type NFTDetailsProps = {
  disableShopFeature: boolean;
};
const NFTDetails = ({ disableShopFeature }: NFTDetailsProps) => {
  const { contractAddr, tokenId } = useParams();
  const { setError } = useErrorHandler();

  const { asset, refetch, loading } = useAssetDetail(contractAddr!, tokenId!);
  const { mobile } = useBreakpoints();
  const { features } = useAppConfig();

  const showApeStakingInfo = useMemo(
    () =>
      asset?.collection?.symbol &&
      isNFTinvolvedWithApeStaking(asset.collection.symbol!) &&
      !isNil(tokenId) &&
      features.includes(Feature.ApeStaking),
    [asset, features, tokenId]
  );

  useEffect(() => {
    if (!loading && asset === null) {
      setError(
        CustomErrorType.NOT_FOUND,
        new Error(`Error: Can not find the asset, tokenId ${tokenId}`)
      );
    }
  }, [asset, loading, setError, tokenId]);

  const { handleCloseLiquidation, handleTriggerAuction, liquidationInfoForAsset } =
    useAssetLiquidationInfo(contractAddr!, tokenId!);

  const [isRefreshApeStakingInfo, setIsRefreshApeStakingInfo] = useState(false);

  const refresh = useCallback(() => {
    refetch();
    setIsRefreshApeStakingInfo(value => !value);
  }, [refetch]);

  if (!asset) {
    return null;
  }

  const {
    collection,
    listings,
    traits,
    ownedBy,
    multiplier,
    isOwner,
    lowestPriceListing,
    isSupplier
  } = asset;

  return (
    <Container loading={loading}>
      <Helmet>
        <meta
          property="twitter:title"
          content={`ParaSpace - ${collection?.collectionName} - #${tokenId}`}
        />
      </Helmet>
      <Stack>
        <Card border={mobile}>
          <Responsive gap={mobile ? '2rem' : '3rem'} alignItems="center">
            <NFTThumbnail
              width="14.5rem"
              size="xLarge"
              symbol={collection?.symbol!}
              tokenId={Number(tokenId)}
              platform={
                lowestPriceListing?.platform.toLowerCase() as ImageThumbnailProps['platform']
              }
              floatingTag={
                multiplier.gt(DEFAULT_MULTIPLIER) && (
                  <Tag skin="success" size="small">
                    {formatNumber(multiplier)}x Boost
                  </Tag>
                )
              }
              twitterPreview
            />
            <OperationCard
              disableShopFeature={disableShopFeature}
              assetData={asset}
              refetch={refresh}
              onCloseLiquidation={handleCloseLiquidation}
              onTriggerAuction={handleTriggerAuction}
              liquidation={liquidationInfoForAsset}
            />
          </Responsive>
        </Card>
        <SectionRow>
          <Stack>
            <PropertiesSection data={{ traits, itemCount: collection?.itemCount }} />
            <AboutSection
              data={{
                listings,
                contractAddress: collection?.contractAddress!,
                tokenId: tokenId!,
                standard: collection?.standard!,
                name: collection?.collectionName!
              }}
            />
          </Stack>
          <Stack>
            {showApeStakingInfo && (
              <ApeStakingInfo
                symbol={collection?.symbol as ApeStakingMainTokenSymbol}
                tokenId={Number(tokenId)}
                isRefresh={isRefreshApeStakingInfo}
                ownedBy={ownedBy}
              />
            )}
            {collection?.symbol === ERC721Symbol.MOONBIRD && (
              <MoonbirdsNestSection isOwner={isSupplier} tokenId={tokenId!} />
            )}
            {!disableShopFeature && (
              <OfferGrid
                isOwner={isOwner}
                inLiquidation={!!liquidationInfoForAsset}
                refetch={refetch}
              />
            )}
            <CollectionSection
              data={{
                name: collection?.collectionName!,
                externalUrl: collection?.externalUrl,
                description: collection?.description!
              }}
            />
          </Stack>
        </SectionRow>
      </Stack>
    </Container>
  );
};

export default memo(NFTDetails);
