import { Alert, AlertProps } from '@parallel-mono/components';
import { FC } from 'react';

export const InvalidListingAlert: FC<AlertProps & { reason: string }> = ({ reason, ...props }) => {
  return (
    <Alert type="error" {...props}>
      {reason}
    </Alert>
  );
};
