import { mapValues } from 'lodash';
import { useMemo } from 'react';

import { useBendDaoPosition } from '../contexts';

import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';
import { ERC721Symbol } from '@/apps/paraspace/typings';

export type BendDaoBalanceMap = Record<ERC721Symbol, number | undefined>;

export const useBendDaoBalanceMap = (): BendDaoBalanceMap => {
  const { infoMap } = useBendDaoPosition();
  const bendDaoBalanceMap = useMemo(
    () => mapValues(infoMap, it => it?.length ?? 0) as BendDaoBalanceMap,
    [infoMap]
  );
  return useStabilizedSnapshot(bendDaoBalanceMap);
};
