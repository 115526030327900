import { Inline, Spinner, Stack, StackProps } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import { FC, memo, useMemo } from 'react';
import { isNil } from 'lodash';

import { useApeListStatesAndActions } from '../../contexts/ApeListProvider';

import { EmptyState, MyApeEarnings, ProjectedDailyGraph } from './components';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

type Props = Omit<StackProps, 'children'>;

export const MyEarnings: FC<Props> = memo(() => {
  const { erc20InfoMap } = useMMProvider();
  const { apesInBalanceAndInSuppliedExcludingInP2P, apeInfoListLoaded } =
    useApeListStatesAndActions();
  const { balance: capeBalance, suppliedAmount: capeSuppliedAmount } = erc20InfoMap.CAPE ?? {};

  const nftPoolsStakedAmount = useMemo(
    () =>
      apesInBalanceAndInSuppliedExcludingInP2P.reduce(
        (total, ape) => total.plus(ape.stakedAmount || 0),
        BigNumber(0)
      ),
    [apesInBalanceAndInSuppliedExcludingInP2P]
  );
  const apePoolStakedAmount = useMemo(
    () => (capeBalance && capeSuppliedAmount ? capeBalance.plus(capeSuppliedAmount) : null),
    [capeBalance, capeSuppliedAmount]
  );
  const totalStakedAmount = useMemo(
    () => (apePoolStakedAmount ? nftPoolsStakedAmount.plus(apePoolStakedAmount) : null),
    [apePoolStakedAmount, nftPoolsStakedAmount]
  );

  const showMyEarnings = useMemo(() => totalStakedAmount?.gt(0), [totalStakedAmount]);
  const showLoading = useMemo(
    () => !apeInfoListLoaded || isNil(apePoolStakedAmount),
    [apeInfoListLoaded, apePoolStakedAmount]
  );

  if (showLoading) {
    return (
      <Stack width="100%" alignItems="center">
        <Spinner />
      </Stack>
    );
  }
  return (
    <Stack width="100%" alignItems="center">
      <Stack width="100%">
        {showMyEarnings ? (
          <>
            <MyApeEarnings />
            <Inline width="100%">
              <ProjectedDailyGraph />
            </Inline>
          </>
        ) : (
          <EmptyState />
        )}
      </Stack>
    </Stack>
  );
});
