import { memo, useMemo } from 'react';
import { mapValues } from 'lodash';

import { SupplyERC20BaseFormProps, SupplyERC20BaseForm } from './SupplyERC20FormBase';

import { useEOABalances } from '@/apps/paraspace/pages/contexts';
import { useTokensInfo } from '@/apps/paraspace/pages/contexts/TokensInfoProvider';
import { zero } from '@/apps/paraspace/consts/values';
import { ERC20Symbol } from '@/apps/paraspace/typings';

export const SupplyERC20FromEOAForm = memo(
  (props: Omit<SupplyERC20BaseFormProps, 'erc20InfoMap'>) => {
    const { erc20BalanceMap } = useEOABalances();
    const { tokensInfo } = useTokensInfo();

    const erc20InfoMap = useMemo(() => {
      return mapValues(erc20BalanceMap, (balance, key) => {
        const {
          displayName = '',
          supplyApyRate = zero,
          supplyRewardRate = zero,
          priceInUsd = zero,
          symbol
        } = tokensInfo?.[key] ?? {};
        return {
          balance,
          displayName,
          supplyApyRate,
          supplyRewardRate,
          priceInUsd,
          symbol: symbol as ERC20Symbol
        };
      });
    }, [erc20BalanceMap, tokensInfo]);

    return <SupplyERC20BaseForm erc20InfoMap={erc20InfoMap} {...props} />;
  }
);
