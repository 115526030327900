import { CryptoIcon, HostedImage } from '@parallel-mono/business-components';
import { Image, Text } from '@parallel-mono/components';
import { FC, memo, useCallback, useState } from 'react';

import { BorrowPositionRow } from '../../MoneyMarket/MyBorrowedPositions';
import { useEOABalances } from '../../../contexts';

import RepayModal, { Repayment } from './RepayModal';

import { DropdownMenu } from '@/apps/paraspace/components';
import { zero } from '@/apps/paraspace/consts/values';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { ERC20Symbol } from '@/apps/paraspace/typings';

type Props = {
  data: BorrowPositionRow;
};

export const RepayDropdownMenu: FC<Props> = memo(({ data, children }) => {
  const [isRepayModalOpen, setRepayModalOpen] = useState(false);
  const [repayModalData, setRepayModalData] = useState({
    repayment: Repayment.AA,
    symbol: ERC20Symbol.ETH
  });
  const { erc20InfoMap } = useMMProvider();
  const { erc20BalanceMap: EOABalanceMap } = useEOABalances();
  const {
    authentication: { meta }
  } = useWeb3Context();
  const { symbol, displayName } = data;
  const { balance = zero, suppliedAmount = zero } = erc20InfoMap[symbol];
  const eoaBalance = EOABalanceMap?.[symbol] ?? zero;

  const handleRepay = useCallback(modalData => {
    setRepayModalOpen(true);
    setRepayModalData(modalData);
  }, []);

  return (
    <>
      <DropdownMenu
        title="Repay With"
        menuTrigger={children}
        placement="bottom-end"
        options={[
          {
            disabled: balance.eq(0),
            icon: (
              <HostedImage
                name="design/PDS_V3/logo/parallel-v2-logo"
                height="1.25rem"
                width="1.25rem"
              />
            ),
            label: 'AA Wallet',
            value: formatBalance(balance),
            onClick: () => handleRepay({ repayment: Repayment.AA, symbol })
          },
          {
            disabled: eoaBalance.eq(0),
            icon: <Image src={meta.walletIcon!} width="1.25rem" height="1.25rem" />,
            label: <Text>{meta.walletType}</Text>,
            onClick: () => {
              handleRepay({ repayment: Repayment.EOA, balance: eoaBalance, symbol });
            },
            value: formatBalance(eoaBalance)
          },
          {
            disabled: suppliedAmount.eq(zero),
            icon: <CryptoIcon size="1.5rem" symbol={symbol} />,
            label: `Supplied ${displayName}`,
            value: formatBalance(suppliedAmount),
            onClick: () =>
              handleRepay({ repayment: Repayment.Supplied, balance: suppliedAmount, symbol })
          }
        ]}
      />
      <RepayModal
        isOpen={isRepayModalOpen}
        data={repayModalData}
        onClose={() => setRepayModalOpen(false)}
      />
    </>
  );
});
