import { FC, memo, useCallback, useMemo } from 'react';
import {
  Card,
  CardProps,
  Checkbox,
  H5,
  H6,
  Icon,
  Inline,
  Stack,
  StackProps,
  TypographyVariant
} from '@parallel-mono/components';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { timelockDocLink } from '../consts';
import { Link } from '../../Link';

const TimelockIcon = styled(Icon).attrs({
  name: 'shieldLock',
  strokeWidth: 2
})`
  flex-shrink: 0;
`;

type TimeLockTermsProps = Omit<CardProps, 'children'> & {
  checked: boolean;
  claimableTime: Date;
  onTermsCheck: (value: boolean) => void;
};

export const TimelockTerms: FC<TimeLockTermsProps & Omit<StackProps, 'children'>> = memo(
  ({ checked, claimableTime, onTermsCheck, ...others }) => {
    const claimableTimeStr = useMemo(() => dayjs(claimableTime).fromNow(true), [claimableTime]);

    const handleTermsSelect = useCallback(
      e => {
        onTermsCheck(e.checked);
      },
      [onTermsCheck]
    );

    return (
      <Stack {...others}>
        <Card border inset="1rem">
          <Inline gap="1rem">
            <TimelockIcon />
            <Stack gap="0.5rem">
              <H5>Safer Transactions with Timelock Queue</H5>
              <H6 skin="secondary">
                For added security on withdrawals and borrow, any transaction above our safety
                threshold will be executed and added to the Timelock Queue. Simply claim your funds
                once they are released. Learn More{' '}
                <Link variant={TypographyVariant.header6} href={timelockDocLink} target="_blank">
                  Learn More
                </Link>
              </H6>
              <Inline inset="0.25rem 0" gap="0.25rem" alignItems="center">
                <Icon name="clock" size="1.25rem" strokeWidth={2} />
                <H6 fontWeight="bold">Est. claimable time: {claimableTimeStr}.</H6>
              </Inline>
            </Stack>
          </Inline>
        </Card>
        <Checkbox checked={checked} onChange={handleTermsSelect}>
          Execute transaction and add to Timelock Queue.
        </Checkbox>
      </Stack>
    );
  }
);
