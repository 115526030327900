import { Inline, InlineProps } from '@parallel-mono/components';
import { memo, useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';
import { HostedImage } from '@parallel-mono/business-components';

import { useApeStakingSummaryData } from '../contexts/PlatformSummaryProvider';

import { SummaryInfoBanner } from '@/apps/paraspace/components';

export const StatsBar = memo((props: Omit<InlineProps, 'children'>) => {
  const apeStakingSummaryData = useApeStakingSummaryData();

  const values = useMemo(() => {
    return [
      {
        label: 'BAYC Staked',
        value: apeStakingSummaryData ? formatNumber(apeStakingSummaryData.baycStaked) : '-'
      },
      {
        label: 'MAYC Staked',
        value: apeStakingSummaryData ? formatNumber(apeStakingSummaryData.maycStaked) : '-'
      },
      {
        label: 'BAKC Staked',
        value: apeStakingSummaryData ? formatNumber(apeStakingSummaryData.bakcStaked) : '-'
      },
      {
        label: 'ApeCoin Staked',
        value: apeStakingSummaryData ? formatNumber(apeStakingSummaryData.apeCoinStaked) : '-'
      }
    ];
  }, [apeStakingSummaryData]);

  return (
    <Inline width="100%" justifyContent="center">
      <SummaryInfoBanner
        width="100%"
        title="Ape Staking"
        description=""
        icon={<HostedImage name="design/PDS_V3/stats_bar/ape-staking" height={40} />}
        valueList={values}
        {...props}
      />
    </Inline>
  );
});
