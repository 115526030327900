import { memo, useMemo } from 'react';
import { Image, ImageProps } from '@parallel-mono/components';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useERC721ImagesThroughContract } from '@/apps/paraspace/hooks';

type NftIconByContractProps = Omit<ImageProps, 'name' | 'src'> & {
  tokenId: number;
  symbol: ERC721Symbol;
};

export const NftIconByContract = memo(({ tokenId, symbol, ...others }: NftIconByContractProps) => {
  const tokenIds = useMemo(() => [tokenId], [tokenId]);
  const imageInfos = useERC721ImagesThroughContract({ tokenIds, symbol });

  const src = imageInfos?.[0]?.image;

  return <Image src={src} {...others} />;
});
