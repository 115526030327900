import { memo, useCallback, useRef } from 'react';
import BigNumber from 'bignumber.js';

import { FormSubmitter } from '../FormSubmitter';

import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useNativeTokenGateway } from '@/apps/paraspace/hooks';
import { useIsNativeTokenCheck } from '@/apps/paraspace/pages/hooks/useIsNativeTokenCheck';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type FormData = { amount: BigNumber; symbol: ERC20Symbol };

type SupplyERC20FromEOASubmitterProps = {
  formData: FormData;
  onFinish: (data: FormData) => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const SupplyERC20FromEOASubmitter = memo(
  ({
    formData: { amount, symbol },
    formData,
    onFinish,
    onError
  }: SupplyERC20FromEOASubmitterProps) => {
    const { supplyERC20 } = usePool();
    const { checkIsNativeTokenSymbol } = useIsNativeTokenCheck();
    const { supplyNativeToken } = useNativeTokenGateway();
    const { erc20InfoMap } = useMMProvider();
    const { eoaAccount, submitEOATransactions, account } = useWeb3Context();

    const formDataRef = useRef(formData);

    const submit = useCallback(async () => {
      if (checkIsNativeTokenSymbol(symbol)) {
        const tx = await supplyNativeToken({
          amount: amount.toString(10),
          from: eoaAccount,
          onBehalfOf: account
        });
        return submitEOATransactions(tx!);
      }
      const tx = await supplyERC20({
        assetAddr: erc20InfoMap[symbol].address,
        amount: amount.toString(10),
        from: eoaAccount,
        onBehalfOf: account
      });
      return submitEOATransactions(tx!);
    }, [
      checkIsNativeTokenSymbol,
      symbol,
      supplyERC20,
      erc20InfoMap,
      amount,
      account,
      submitEOATransactions,
      supplyNativeToken,
      eoaAccount
    ]);

    const handleFinish = useCallback(() => {
      onFinish(formDataRef.current);
    }, [onFinish]);

    return (
      <FormSubmitter
        inProgressMessage="Securely Supplying"
        onError={onError}
        onFinish={handleFinish}
        submit={submit}
      />
    );
  }
);
