import { memo, useMemo, MouseEvent, useCallback, ReactNode } from 'react';
import { differenceBy, isEmpty, mapKeys } from 'lodash';
import styled from 'styled-components';
import {
  Button,
  ButtonProps,
  Inline,
  InlineProps,
  Pagination,
  SmallText,
  Spinner,
  Stack,
  Text,
  useBreakpoints,
  useDualModeState
} from '@parallel-mono/components';

import { useMMProvider } from '../../pages/contexts/MMProvider';

import {
  Collapse,
  NFTThumbnailCheck,
  NFTCollectionThumbnail,
  CollapseProps
} from '@/apps/paraspace/components';
import { ERC721Symbol, genericMemo } from '@/apps/paraspace/typings';
import { usePagination } from '@/apps/paraspace/hooks/usePagination';
import { useCheckV1TimelockStatus } from '@/apps/paraspace/pages/Credit';

type CollapsibleTokensSelectorProps<T> = Omit<CollapseProps, 'children' | 'header'> & {
  symbol: ERC721Symbol;
  headerHint: ReactNode;
  collectionName: string;
  tokens: T[];
  selectedTokens: T[];
  onSelectionChange: (selection: T[]) => void;
};

const StyledCollapse = styled(Collapse)`
  border: 1px solid ${({ theme }) => theme.skin.grey[200]};
  width: 100%;
`;

const TokensContainer = styled.div`
  padding-top: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    grid-template-columns: repeat(2, 2fr);
  `};
`;

const DEFAULT_PAGE_SIZE = 10;
const MOBILE_PAGE_SIZE = 4;

const stopPropagation = (e: MouseEvent) => e.stopPropagation();

type HeaderProps = Omit<InlineProps, 'children'> & {
  symbol: ERC721Symbol;
  collectionName: string;
  headerHint: ReactNode;
  headerButtonProps: ButtonProps;
};

const Header = memo(
  ({ symbol, collectionName, headerHint, headerButtonProps, ...others }: HeaderProps) => {
    const handleButtonClicked = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        stopPropagation(e);
        headerButtonProps.onClick?.(e);
      },
      [headerButtonProps]
    );
    const { mobile } = useBreakpoints();
    return (
      <Inline width="100%" justifyContent="space-between" {...others}>
        <Inline onClick={stopPropagation} gap="0.5rem">
          <NFTCollectionThumbnail symbol={symbol} size="small" />
          <Stack gap="0">
            <Text>{collectionName}</Text>
            <SmallText skin="secondary">{headerHint}</SmallText>
          </Stack>
        </Inline>
        {headerButtonProps.children && !mobile && (
          <Button onClick={handleButtonClicked} skin="secondary">
            {headerButtonProps.children}
          </Button>
        )}
      </Inline>
    );
  }
);

export const CollapsibleTokensSelector = genericMemo(
  <
    T extends {
      tokenId: number;
      symbol: ERC721Symbol;
      isSupplied?: boolean;
      disabled?: boolean;
      disabledTip?: string;
    }
  >({
    symbol,
    headerHint,
    tokens,
    collectionName,
    selectedTokens,
    onSelectionChange,
    defaultOpen,
    onToggle,
    open,
    ...others
  }: CollapsibleTokensSelectorProps<T>) => {
    const [finalOpen, setInternalOpen] = useDualModeState(defaultOpen, open);
    const handleToggleOpen = useCallback(() => {
      setInternalOpen(!finalOpen);
      onToggle?.(!finalOpen);
    }, [onToggle, finalOpen, setInternalOpen]);

    const { nftInfoMap } = useMMProvider();
    const { isLoading, tokensStatus } = useCheckV1TimelockStatus(
      nftInfoMap[symbol].address,
      tokens.filter(it => it.isSupplied).map(it => it.tokenId)
    );

    const unclaimedTokens = useMemo(
      () => tokensStatus?.filter(it => !it.isClaimed) ?? [],
      [tokensStatus]
    );
    const tokensStatusMap = useMemo(
      () => mapKeys(unclaimedTokens, it => it.tokenId),
      [unclaimedTokens]
    );

    const finalTokens = useMemo(
      () =>
        tokens.map(token => {
          if (token.tokenId in tokensStatusMap) {
            const { isReleaseTimeReached, expectedRelease } = tokensStatusMap[token.tokenId];
            return {
              ...token,
              disabled: true,
              disabledTip: `This ${symbol} cannot be listed temporarily, it is still in the timelock of ParaSpace V1, please ${
                !isReleaseTimeReached ? `try again after ${expectedRelease!}.` : 'wait patiently.'
              } `
            };
          }
          return token;
        }),
      [symbol, tokens, tokensStatusMap]
    );

    const selectableTokens = useMemo(
      () => finalTokens.filter(token => !token.disabled),
      [finalTokens]
    );
    const selectedTokenIds = useMemo(() => selectedTokens.map(it => it.tokenId), [selectedTokens]);

    const handleSelectAll = useCallback(() => {
      if (!finalOpen) {
        handleToggleOpen();
      }
      onSelectionChange(selectableTokens);
    }, [finalOpen, onSelectionChange, selectableTokens, handleToggleOpen]);

    const handleUnselectAll = useCallback(() => {
      onSelectionChange([]);
    }, [onSelectionChange]);

    const allSelected = useMemo(
      () =>
        !isEmpty(selectableTokens) &&
        differenceBy(selectableTokens, selectedTokens, it => it.tokenId).length === 0,
      [selectableTokens, selectedTokens]
    );

    const selectButtonText = useMemo(
      () => (allSelected ? 'Unselect All' : 'Select All'),
      [allSelected]
    );

    const selectButtonAction = useMemo(
      () => (allSelected ? handleUnselectAll : handleSelectAll),
      [allSelected, handleSelectAll, handleUnselectAll]
    );

    const header = useMemo(() => {
      return (
        <Header
          symbol={symbol}
          headerHint={headerHint}
          collectionName={collectionName}
          headerButtonProps={{
            children: isEmpty(selectableTokens) ? '' : selectButtonText,
            onClick: selectButtonAction
          }}
        />
      );
    }, [
      symbol,
      headerHint,
      collectionName,
      selectableTokens,
      selectButtonText,
      selectButtonAction
    ]);

    const handleToggleItem = useCallback(
      token => {
        onSelectionChange(
          selectedTokenIds.includes(token.tokenId)
            ? selectedTokens.filter(it => it.tokenId !== token.tokenId)
            : selectedTokens.concat(token)
        );
      },
      [onSelectionChange, selectedTokens, selectedTokenIds]
    );

    const { mobile } = useBreakpoints();
    const { currentPage, setCurrentPage, pageData, totalPage } = usePagination(
      finalTokens,
      mobile ? MOBILE_PAGE_SIZE : DEFAULT_PAGE_SIZE
    );

    const mobileButton = useMemo(() => {
      return mobile && selectableTokens.length > 0 ? (
        <Button block onClick={selectButtonAction} skin="secondary">
          {selectButtonText}
        </Button>
      ) : null;
    }, [mobile, selectButtonAction, selectableTokens.length, selectButtonText]);

    return (
      <StyledCollapse
        {...others}
        open={finalOpen}
        onToggle={handleToggleOpen}
        header={header}
        mobileButton={mobileButton}
      >
        {!isLoading && (
          <>
            <TokensContainer>
              {pageData.map((token: T) => {
                const { tokenId, isSupplied, disabled, disabledTip } = token;
                return (
                  <NFTThumbnailCheck
                    disabled={disabled}
                    key={tokenId}
                    platform={isSupplied ? 'paraspace' : undefined}
                    symbol={symbol}
                    tokenId={tokenId}
                    disabledTip={disabledTip}
                    checked={selectedTokenIds.includes(tokenId)}
                    onChange={() => {
                      handleToggleItem(token);
                    }}
                  >
                    <Stack alignItems="center" gap="0.5rem">
                      <Text>#{tokenId}</Text>
                    </Stack>
                  </NFTThumbnailCheck>
                );
              })}
            </TokensContainer>
            <Inline justifyContent="center" inset="1rem">
              {totalPage > 1 && (
                <Pagination
                  total={totalPage}
                  page={currentPage}
                  onChange={setCurrentPage}
                  siblingCount={0}
                  startBoundaryCount={3}
                />
              )}
            </Inline>
          </>
        )}
        {isLoading && (
          <Inline width="100%" inset="2rem" justifyContent="center">
            <Spinner />
          </Inline>
        )}
      </StyledCollapse>
    );
  }
);
