import { Pagination, Spinner, Stack, StackProps, useBreakpoints } from '@parallel-mono/components';
import { memo, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { useStakeInfoList } from '../StakeInfoListProvider/StakeInfoListProvider';

import StakingSummaryInfos from './StakingSummaryInfos';
import { EmptyState } from './EmptyState';
import { ApeItemCard } from './ApeItemCard';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { usePagination } from '@/apps/paraspace/hooks/usePagination';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import {
  MultipleSelect,
  NFTCollectionThumbnail,
  SelectBaseOption
} from '@/apps/paraspace/components';

const ApeListWrapper = styled(Stack)`
  flex: 1;
  ${({ theme }) => theme.breakpoints.down('desktop')`
      width: 100%;
  `};
`;

type ApeListProps = Omit<StackProps, 'children'>;

const PAGE_SIZE = 8;

const stakePoolOptions = [ERC721Symbol.BAYC, ERC721Symbol.MAYC, ERC721Symbol.BAKC].map(symbol => ({
  icon: <NFTCollectionThumbnail symbol={symbol} size="small" round />,
  label: `${symbol} Pool`,
  value: symbol
}));

export const ApeList = memo((props: ApeListProps) => {
  const { basicInfoLoaded } = useMMProvider();
  const { stakingInfoList, bakcInfoList, stakingInfoLoaded } = useStakeInfoList();
  const allTokenList = useMemo(
    () => [...stakingInfoList, ...bakcInfoList].filter(it => it.source !== null),
    [bakcInfoList, stakingInfoList]
  );

  const { desktop } = useBreakpoints();

  const [selectedPools, setSelectedPools] = useState<SelectBaseOption[]>([]);
  const selectedCollectionSymbols = useMemo(
    () => selectedPools.map(op => op.value as ERC721Symbol),
    [selectedPools]
  );
  const apeInfoList = useMemo(
    () =>
      selectedCollectionSymbols.length
        ? allTokenList.filter(info => selectedCollectionSymbols.includes(info.symbol))
        : allTokenList,
    [allTokenList, selectedCollectionSymbols]
  );

  const { pageData, totalPage, currentPage, setCurrentPage } = usePagination(
    apeInfoList,
    PAGE_SIZE
  );

  const allStakedValue = useMemo(() => {
    return allTokenList
      .reduce((result, stakingItem) => {
        return result.plus(stakingItem.stakedAmount?.toString() || 0);
      }, new BigNumber(0))
      .toNumber();
  }, [allTokenList]);

  const allRewards = useMemo(() => {
    return allTokenList
      .reduce((result, stakingItem) => {
        return result.plus(stakingItem.pendingRewards?.toString() || 0);
      }, new BigNumber(0))
      .toNumber();
  }, [allTokenList]);

  const showSummaryInfo = useMemo(() => {
    return allTokenList?.some(stakingItem => stakingItem.stakedAmount?.gt(0));
  }, [allTokenList]);

  if (!stakingInfoLoaded || !basicInfoLoaded) {
    return (
      <ApeListWrapper alignItems="center">
        <Spinner size={desktop ? 'large' : 'small'} />
      </ApeListWrapper>
    );
  }
  return (
    <ApeListWrapper gap="1rem">
      <MultipleSelect
        placeholder="Pool"
        options={stakePoolOptions}
        value={selectedCollectionSymbols}
        onChange={setSelectedPools}
        menuAlign="left"
      />
      {showSummaryInfo && (
        <StakingSummaryInfos stakedValue={allStakedValue} unClaimedRewards={allRewards} />
      )}
      <Stack {...props}>
        {pageData.map(ape => (
          <ApeItemCard key={`${ape.symbol}_${ape.tokenId}`} tokenInfo={ape} />
        ))}
        {totalPage > 0 && (
          <Pagination
            total={totalPage}
            page={currentPage}
            onChange={setCurrentPage}
            siblingCount={0}
            startBoundaryCount={3}
          />
        )}
        {stakingInfoLoaded && apeInfoList.length === 0 && <EmptyState />}
      </Stack>
    </ApeListWrapper>
  );
});
