import {
  EthereumTransactionTypeExtended,
  SeaportService,
  eEthereumTxType,
  transactionType
} from 'paraspace-utilities-contract-helpers';
import { useCallback, useMemo } from 'react';
import { BigNumber as EthersBigNumber } from 'ethers';
import { OrderComponents } from 'paraspace-seaport-js/lib/types';

import { notEmpty } from '../../utils/notEmpty';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';

const useSeaport = () => {
  const { provider, submitTransactions } = useWeb3Context();
  const contracts = useContractsMap();

  const seaport = useMemo(() => {
    if (!provider) {
      return null;
    }
    try {
      return new SeaportService(provider, {
        poolAddress: contracts.PoolProxy,
        seaportAddress: contracts.Seaport,
        conduitAddress: contracts.Conduit,
        conduitKey: contracts.ConduitKey
      });
    } catch (e) {
      return null;
    }
  }, [contracts.Conduit, contracts.ConduitKey, contracts.PoolProxy, contracts.Seaport, provider]);

  const cancelOrders = useCallback(
    (orders: OrderComponents[]) => {
      const seaportContract = seaport?.getSDKInstance().contract;
      if (!seaportContract) {
        throw new Error('seaportContract not ready');
      }

      const tx: EthereumTransactionTypeExtended = {
        tx: async () => {
          const rawTxData = seaportContract.interface.encodeFunctionData('cancel', [orders]);
          return {
            to: seaportContract.address,
            data: rawTxData,
            value: '0',
            gasLimit: EthersBigNumber.from(0)
          } as transactionType;
        },
        txType: eEthereumTxType.OTHERS,
        gas: () => Promise.resolve(null)
      };
      return submitTransactions([tx].filter(notEmpty));
    },
    [seaport, submitTransactions]
  );

  const signPayLaterPayload = useCallback(
    async (
      {
        token,
        amount,
        orderSignature
      }: {
        token: string;
        amount: string;
        orderSignature: string;
        address: string;
      },
      signerAddress?: string
    ) => {
      if (!provider || !seaport) {
        throw new Error('Seaport is uninitialized while signing pay later data');
      }
      const signer = provider.getSigner(signerAddress);
      return seaport.signPayLaterPayload(signer, {
        token,
        amount,
        orderSignature
      });
    },
    [provider, seaport]
  );
  return {
    seaportSDK: seaport?.getSDKInstance(),
    signPayLaterPayload,
    cancelOrders
  };
};

export default useSeaport;
