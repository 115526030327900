import { Icon, Stack, StackProps, Inline, Card, H3 } from '@parallel-mono/components';
import { memo, useMemo } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import { useWeb3Context } from '../../contexts';
import { useAppConfig } from '../../hooks';
import { absoluteRouteNames } from '../../App/routeConfig';
import { Feature } from '../../config';
import { ConnectWallet, PageLayout, SideMenus } from '../../components';

import { ReactComponent as Side } from './assets/side.svg';

export type LeaderBoardProps = Omit<StackProps, 'children'>;

const SideImage = styled(Side)`
  position: absolute;
  right: -2rem;
  bottom: 0;
  align-self: flex-end;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    display: none;
  `}
`;

const Wrapper = styled(Stack)`
  ${({ theme }) => theme.breakpoints.down('desktop')`
    align-items: center;
  `}
`;

const OutletWrapper = styled(Stack)`
  position: relative;
  z-index: 1;
  ${({ theme }) => theme.breakpoints.only('desktop')`
    min-width: 39rem;
  `}
`;

export const LeaderBoard = memo((props: LeaderBoardProps) => {
  const { isUsingUserWallet } = useWeb3Context();
  const { features } = useAppConfig();

  const menus = useMemo(
    () =>
      [
        {
          name: 'Deposit TVL',
          path: absoluteRouteNames.LEADER_BOARD.DEPOSIT_TVL,
          icon: <Icon name="download" strokeWidth={2} />,
          isShowMenus: features.includes(Feature.LeaderBoardDepositTvl)
        },
        {
          name: 'BNPL Volume',
          path: absoluteRouteNames.LEADER_BOARD.BNPL_VOLUME,
          icon: <Icon name="creditCard" strokeWidth={2} />,
          isShowMenus: features.includes(Feature.LeaderBoardBnplVolume)
        }
      ].filter(menu => menu.isShowMenus),
    [features]
  );

  return (
    <Stack {...props}>
      <PageLayout
        sideMenu={<SideMenus menus={menus} />}
        mainContent={
          <Wrapper alignItems="center" gap="0" width="100%">
            <Inline gap="2.25rem">
              <OutletWrapper gap="2rem">
                {!isUsingUserWallet && (
                  <Card border shadow="none">
                    <Stack gap="1rem" alignItems="center">
                      <Icon name="purse" strokeWidth={2} size="2.5rem" />
                      <H3>Connect Wallet to view your rank.</H3>
                      <ConnectWallet btnTxt="Connect" />
                    </Stack>
                  </Card>
                )}
                <Outlet />
              </OutletWrapper>
              <SideImage />
            </Inline>
          </Wrapper>
        }
      />
    </Stack>
  );
});
