import { FC } from 'react';
import { H2, Icon, Stack, Typography, TypographyVariant } from '@parallel-mono/components';
import styled from 'styled-components';

import { HeaderWrapper } from '../Layout';
import { Logo } from '../Logo';
import { LinkButton } from '../LinkButton';
import { DOCS_URL } from '../../consts/externalLinks';

import { useToggle } from '@/apps/paraspace/contexts';

const ENABLE_OVERALL_MAINTENANCE = 'OVERALL_MAINTENANCE';

const ContentWrapper = styled(Stack)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100% - var(--header-height-pc));
  padding-bottom: var(--header-height-pc);
`;

const Text = styled(Typography)`
  max-width: 395px;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.skin.grey[700]};
`;

const OverallMaintenance = () => {
  return (
    <>
      <HeaderWrapper top="0">
        <Logo />
      </HeaderWrapper>
      <ContentWrapper>
        <Icon name="tool" size={126} />
        <H2>We are in maintenance</H2>
        <Text variant={TypographyVariant.body}>
          We are in the middle of deployment. Services might be intermittently affected. Your funds
          are safe.
        </Text>
        <LinkButton
          as="a"
          to=""
          target="_blank"
          rel="noopener noreferrer"
          href={`${DOCS_URL}`}
          size="large"
          skin="secondary"
        >
          Read a Doc
        </LinkButton>
      </ContentWrapper>
    </>
  );
};

export const OverallMaintenanceToggle: FC = ({ children }) => {
  const enableOverallMaintenance = useToggle(ENABLE_OVERALL_MAINTENANCE);

  return enableOverallMaintenance ? <OverallMaintenance /> : <>{children}</>;
};
