import {
  Button,
  Card,
  H1,
  H3,
  Icon,
  Responsive,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import { FC, memo, useMemo } from 'react';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';

import { useAirdrop } from './hooks/useAirdrop';

type Props = Omit<StackProps, 'children'>;

const Wrapper = styled(Stack)`
  max-width: 30rem;
  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  height: 5rem;
  width: 5rem;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.secondary};
  border-radius: 50%;
`;

const CoinAmount = styled(H1)<{ positive: boolean }>`
  color: ${({ theme, positive }) => (positive ? theme.skin.success.main : theme.skin.grey[500])};
`;

const CoinName = styled.span`
  color: ${({ theme }) => theme.skin.grey[500]};
`;

const Title = styled(H3)`
  align-self: flex-start;
`;

const HiddenColumn = styled.div`
  width: 20%;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    width: 0;
  `};
`;

export const Airdrop: FC<Props> = memo(() => {
  const { claiming, claim, availableAmount } = useAirdrop();
  const buttonText = useMemo(() => (claiming ? 'Claiming' : 'Claim'), [claiming]);
  return (
    <Responsive width="100%" gap="0">
      <HiddenColumn />
      <Wrapper>
        <Card border>
          <Stack alignItems="center">
            <Title>Airdrop</Title>
            <IconWrapper>
              <Icon name="medal" size="3rem" />
            </IconWrapper>
            <Stack gap="0" alignItems="center">
              <CoinAmount positive={availableAmount > 0}>
                {formatNumber(availableAmount, { thousandSeparated: true })}{' '}
                <CoinName>APE</CoinName>
              </CoinAmount>
              <Text skin="secondary">Available to Claim</Text>
            </Stack>
            {availableAmount > 0 && (
              <Button
                size="large"
                block
                loading={claiming}
                disabled={claiming || availableAmount <= 0}
                onClick={claim}
              >
                {buttonText}
              </Button>
            )}
          </Stack>
        </Card>
      </Wrapper>
    </Responsive>
  );
});
