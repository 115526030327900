import { memo, useEffect, useState, createContext, useContext, useMemo, ReactNode } from 'react';
import { find, sortBy } from 'lodash';

import { Collection, useGetCollectionsQuery } from '@/apps/paraspace/generated/graphql';
import { CollectionConfig, ERC721Symbol } from '@/apps/paraspace/typings';
import { createStableSnapshotFromPreviousSnapshot } from '@/apps/paraspace/utils/createStableSnapshotFromPreviousSnapshot';
import { COLLECTION_EXPECTED_ORDER } from '@/apps/paraspace/consts/collectionSort';
import { useAppConfig, useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { CollectionFees, calculateFees } from '@/apps/paraspace/utils/calculateFees';

export interface CollectionInfo extends Omit<Collection, 'symbol' | 'fees'>, CollectionConfig {
  fees: CollectionFees;
}

const REFRESH_DATA_INTERVAL = 60000;

type CollectionContextValue = {
  collections: CollectionInfo[];
  loading: boolean;
};

const CollectionContext = createContext<CollectionContextValue>({
  collections: [],
  loading: true
});

export const CollectionProvider = memo(({ children }: { children: ReactNode }) => {
  const [contextData, setContextData] = useState<CollectionContextValue>({
    collections: [],
    loading: true
  });

  const { data, loading } = useGetCollectionsQuery({
    notifyOnNetworkStatusChange: true,
    pollInterval: REFRESH_DATA_INTERVAL
  });

  const { erc721Config } = useAppConfig();
  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  useEffect(() => {
    const collectionsData =
      data?.collections?.map(item => {
        const symbol = getSymbolByContractAddress(item?.contractAddress ?? '') as ERC721Symbol;
        const collectionConfig = erc721Config[symbol];
        const fees = calculateFees(item?.fees);

        return {
          ...item,
          ...collectionConfig,
          fees
        };
      }) || [];

    setContextData(curr =>
      createStableSnapshotFromPreviousSnapshot(
        {
          collections: sortBy(collectionsData, ({ symbol }) =>
            COLLECTION_EXPECTED_ORDER.indexOf(symbol)
          )
        },
        curr
      )
    );
  }, [data?.collections, erc721Config, getSymbolByContractAddress]);

  const value = useMemo(
    () => ({
      ...contextData,
      loading
    }),
    [contextData, loading]
  );

  return <CollectionContext.Provider value={value}>{children}</CollectionContext.Provider>;
});

export const useCollections = () => useContext(CollectionContext);
export const useCollectionByContract = (contract: string) =>
  find(useContext(CollectionContext).collections, { contractAddress: contract });
