import { memo, ReactNode } from 'react';

import { ImageMapProvider } from './ImageMapProvider';
import { AddLiquidityProvider } from './AddLiquidityProvider';
import { RemoveLiquidityProvider } from './RemoveLiquidityProvider';
import { SupplyUniSwapV3Provider } from './SupplyUniSwapV3Provider';

type UniSwapV3ManagerProviderProps = {
  children: ReactNode;
};

export const UniSwapV3ManagerProvider = memo(({ children }: UniSwapV3ManagerProviderProps) => {
  return (
    <RemoveLiquidityProvider>
      <AddLiquidityProvider>
        <ImageMapProvider>
          <SupplyUniSwapV3Provider>{children}</SupplyUniSwapV3Provider>
        </ImageMapProvider>
      </AddLiquidityProvider>
    </RemoveLiquidityProvider>
  );
});
