import {
  Button,
  H6,
  Inline,
  Stack,
  SmallText,
  useBreakpoints,
  Icon
} from '@parallel-mono/components';
import { memo } from 'react';
import styled, { useTheme } from 'styled-components';

import { useParallelToast, useWeb3Context } from '@/apps/paraspace/contexts';
import useMockTokenFaucet from '@/apps/paraspace/pages/hooks/useMockTokenFaucet';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';

const StyledInline = styled(Inline)`
  max-width: 18.875rem;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    justify-content: center;
  `};
`;

const UnderlineSmallText = styled(SmallText)`
  text-decoration: underline;
`;

const MintLink = ({
  mintCallback,
  customizedFaucetAddr
}: {
  customizedFaucetAddr?: string;
  mintCallback?: () => void;
}) => {
  const { account, isUsingUserWallet } = useWeb3Context();
  const { mintTokenToAddress } = useMockTokenFaucet(customizedFaucetAddr);
  const toast = useParallelToast();
  const { desktop } = useBreakpoints();

  const onMintClick = () => {
    if (!isUsingUserWallet) {
      return;
    }

    toast.promise(
      mintTokenToAddress(account)
        .then(async tx => {
          await tx?.wait();
          if (mintCallback) {
            mintCallback();
          }
          return { message: 'Mint successfully!' };
        })
        .catch(e => {
          throw getUserFriendlyError(e);
        })
    );
  };
  const theme = useTheme();
  return (
    <StyledInline width="100%" gap="0.625rem" alignItems="flex-start">
      {desktop && (
        <Icon width="1.25rem" name="smile" color={theme.skin.grey[700]} strokeWidth={2} />
      )}
      <Stack gap="0">
        {desktop && <H6 fontWeight="medium">Thanks for trying our testnet.</H6>}
        <Button onClick={onMintClick} variant="link">
          <UnderlineSmallText skin="secondary">Get Test Tokens</UnderlineSmallText>
        </Button>
      </Stack>
    </StyledInline>
  );
};

export default memo(MintLink);
