import { FC, memo, useMemo } from 'react';
import { Icon, Image, Text } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import { HostedImage } from '@parallel-mono/business-components';
import styled from 'styled-components';

import { DropdownMenu, DropdownMenuProps } from '@/apps/paraspace/components';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';
import { notEmpty } from '@/apps/paraspace/utils/notEmpty';
import { useWeb3Context } from '@/apps/paraspace/contexts';

type OptionItem = { onClick: () => void; balance: number };
type Props = {
  EOAWalletOption: OptionItem;
  otherProtocolOption?: OptionItem;
  paraXWalletOption: OptionItem;
};

const StyledDropdownMenu = styled(DropdownMenu)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100%;
  `}
`;
export const SupplyERC721DropdownMenu: FC<Props & Omit<DropdownMenuProps, 'options'>> = memo(
  ({
    paraXWalletOption,
    EOAWalletOption,
    otherProtocolOption,
    menuTrigger,
    placement = 'bottom-end',
    ...others
  }) => {
    const { features } = useAppConfig();
    const {
      authentication: {
        meta: { walletIcon, walletType }
      }
    } = useWeb3Context();

    const showBendaoMover = useMemo(() => features.includes(Feature.BendaoMover), [features]);

    const options = useMemo(
      () =>
        [
          {
            icon: (
              <HostedImage
                name="design/PDS_V3/logo/parallel-v2-logo"
                height="1.25rem"
                width="1.25rem"
              />
            ),
            label: <Text>AA Wallet</Text>,
            onClick: (e: any) => {
              e.stopPropagation();
              paraXWalletOption.onClick();
            },
            value: formatNumber(paraXWalletOption.balance)
          },
          {
            icon: <Image src={walletIcon!} width="1.25rem" height="1.25rem" />,
            label: <Text>{walletType}</Text>,
            onClick: (e: any) => {
              e.stopPropagation();
              EOAWalletOption.onClick();
            },
            value: formatNumber(EOAWalletOption.balance)
          },
          showBendaoMover && otherProtocolOption
            ? {
                icon: <Icon name="globe" size="1.5rem" strokeWidth={2} />,
                label: <Text>Other Protocols</Text>,
                onClick: (e: any) => {
                  e.stopPropagation();
                  otherProtocolOption.onClick();
                },
                value: formatNumber(otherProtocolOption.balance)
              }
            : undefined
        ].filter(notEmpty),
      [
        paraXWalletOption,
        walletIcon,
        walletType,
        EOAWalletOption,
        showBendaoMover,
        otherProtocolOption
      ]
    );

    return (
      <StyledDropdownMenu
        placement={placement}
        title="Supply From"
        options={options}
        menuTrigger={menuTrigger}
        {...others}
      />
    );
  }
);
