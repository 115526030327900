import { useCallback, useMemo } from 'react';
import { WETHService } from 'paraspace-utilities-contract-helpers';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';

const useWNativeToken = (wTokenAddress: string) => {
  const { provider, account, submitTransactions } = useWeb3Context();
  const service = useMemo(() => {
    if (!provider) {
      return null;
    }

    return new WETHService(provider, wTokenAddress);
  }, [provider, wTokenAddress]);

  const depositWNativeToken = useCallback(
    async (amount: number) => {
      if (!service) {
        return null;
      }
      const tx = await service.deposit(amount, account);
      return submitTransactions([tx]);
    },
    [service, account, submitTransactions]
  );

  const withdrawWNativeToken = useCallback(
    async (amount: number) => {
      if (!service) {
        return null;
      }
      const tx = await service.withdraw(amount, account);
      return submitTransactions([tx]);
    },
    [service, account, submitTransactions]
  );

  return { depositWNativeToken, withdrawWNativeToken };
};

export default useWNativeToken;
