import { Stack, StackProps, Inline } from '@parallel-mono/components';
import { FC, memo, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ListTokensProvider } from './contexts';

import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { MenuItem } from '@/apps/paraspace/components';

const StyledMenuItem = styled(MenuItem)`
  width: fit-content;
`;

const subMenus = [
  {
    name: 'List',
    path: absoluteRouteNames.SHOP.LIST.index
  },
  {
    name: 'My Listings',
    path: absoluteRouteNames.SHOP.LIST.MY_LISTINGS
  }
];

type Props = Omit<StackProps, 'children'>;

export const ListDashboard: FC<Props> = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();

  const hideMenu = useMemo(
    () => [absoluteRouteNames.SHOP.LIST.LIST_PRICES_SETTING].includes(location.pathname),
    [location.pathname]
  );

  return (
    <Stack width="100%">
      {!hideMenu && (
        <Inline gap="0.5rem">
          {subMenus.map((item, index) => {
            const { name, path } = item;
            return (
              <StyledMenuItem
                key={`${name}-${index}`}
                selected={[path].includes(location.pathname)}
                onClick={() => {
                  navigate(path);
                }}
              >
                {name}
              </StyledMenuItem>
            );
          })}
        </Inline>
      )}

      <ListTokensProvider>
        <Outlet />
      </ListTokensProvider>
    </Stack>
  );
});
