import { FC, memo, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Inline, InlineProps, Stack, Text, useBreakpoints } from '@parallel-mono/components';
import styled from 'styled-components';
import { zipObject } from 'lodash';

import { absoluteRouteNames } from '../../App/routeConfig';
import { MenuItem } from '../../components';
import { useAppConfig } from '../../hooks';
import { Feature } from '../../config';

const StyledMenuItem = styled(MenuItem)`
  width: auto;
  padding: 0.5rem 2rem;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    padding: 0.5rem 1rem;
  `}
`;

const SubMenu: FC<{ onNavigate: () => void; selected?: boolean } & InlineProps> = memo(
  ({ onNavigate, children, ...others }) => {
    return (
      <StyledMenuItem alignItems="center" justifyContent="center" onClick={onNavigate} {...others}>
        {children}
      </StyledMenuItem>
    );
  }
);

const SubMenuWrapper = styled(Inline)`
  overflow-x: auto;
`;

const subRouteConfigs = [
  {
    name: 'Lending',
    path: absoluteRouteNames.LENDING.index
  },
  {
    featureKey: Feature.LiquidationDashboard,
    name: 'Liquidation',
    path: absoluteRouteNames.LENDING.LIQUIDATION.index
  },
  {
    featureKey: Feature.UserInfo,
    name: 'Setting',
    path: absoluteRouteNames.LENDING.USER_INFO.PROFILE
  }
];

const filterSubRouteConfigs = (
  routes: { featureKey?: Feature; name: string; path: string }[],
  features: Partial<Record<Feature, boolean>>
) => {
  return routes.filter(it => {
    if (it.featureKey && !features[it.featureKey]) {
      return false;
    }
    return true;
  });
};

const Credit = () => {
  const { mobile } = useBreakpoints();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { features } = useAppConfig();

  const filteredRoutes = useMemo(() => {
    const featuresMap = zipObject(
      features,
      features.map(() => true)
    );
    return filterSubRouteConfigs(subRouteConfigs, featuresMap);
  }, [features]);

  return (
    <Stack>
      {filteredRoutes.length > 1 && (
        <SubMenuWrapper gap={mobile ? '0' : '0.5rem'}>
          {filteredRoutes.map(route => {
            const pathReg = new RegExp(`.*${route.path}$`, 'ig');
            return (
              <SubMenu
                key={route.name}
                selected={pathReg.test(pathname)}
                onNavigate={() => navigate(route.path)}
              >
                <Text>{route.name}</Text>
              </SubMenu>
            );
          })}
        </SubMenuWrapper>
      )}

      <Outlet />
    </Stack>
  );
};

export { useCheckV1TimelockStatus } from './hooks';

export default memo(Credit);
