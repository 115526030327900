import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';

import { useApeStaking } from '@/apps/paraspace/pages/hooks/ApeStaking/useApeStaking';
import { ApeStakingMainAssetSymbol } from '@/apps/paraspace/typings';

export type tableDataProps = {
  symbol: ApeStakingMainAssetSymbol;
  apy: BigNumber;
  cap: BigNumber;
  possibleRewards: BigNumber;
  paraSpaceStaked: BigNumber;
  totalStaked: BigNumber;
};

export const useApeTableData = () => {
  const { getPlatformStakingOverview } = useApeStaking();
  const [tableData, setTableData] = useState<null | tableDataProps[]>(null);

  useEffect(() => {
    const getTableData = async () => {
      const overviewData = await getPlatformStakingOverview();

      if (overviewData) {
        const result = (
          Object.keys(overviewData).filter(key => key !== 'total') as ApeStakingMainAssetSymbol[]
        ).map(symbol => {
          const { apy, capPerPosition: cap, rewardsPerHour } = overviewData[symbol];
          const possibleRewards = rewardsPerHour.times(24);
          return {
            apy,
            cap,
            possibleRewards,
            symbol,
            totalStaked: overviewData?.[symbol].totalStakedApeAmount,
            paraSpaceStaked: overviewData?.[symbol].paraStakedApeAmount
          };
        });

        setTableData(result);
      }
    };

    getTableData();
  }, [getPlatformStakingOverview]);

  return tableData;
};
