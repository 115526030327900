import { memo, MouseEvent, useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardProps,
  Collapse,
  H5,
  H6,
  Icon,
  Inline,
  DataGridColumn,
  Stack,
  useBreakpoints,
  Tag,
  TypographyVariant,
  Text
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { Link as RouterLink } from 'react-router-dom';
import { formatNumber } from '@parallel-mono/utils';

import { useNativeTokenSymbol } from '../hooks';

import { StyledDataGrid } from './StyledDataGrid';
import { NearLiquidationAsset, NearLiquidationAssetType } from './types';
import { AccountLiquidationGrid } from './AccountLiquidationGrid';
import { NoShrinkingNFTThumbnail } from './NoShrinkingNFTThumbnail';
import { getDisplayNameBySymbol } from './helpers';

import { Link, Tooltip, ChainlinkLogo, RoundProfilePicture } from '@/apps/paraspace/components';
import { truncateTextMid, formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import {
  useAppConfig,
  useGetSymbolByContractAddress,
  useNetworkConfig
} from '@/apps/paraspace/hooks';

export type NearLiquidationGridProps = {
  collateral: string;
  debt: string;
  healthFactor: number;
  assets: NearLiquidationAsset[];
  profile: {
    walletAddress: string;
  };
} & Omit<CardProps, 'children' | 'border'>;

const GridCard = styled(Card).attrs({
  border: true
})`
  padding: 0;
`;

const GridHeader = styled(Inline).attrs({
  justifyContent: 'space-between',
  alignItems: 'center'
})`
  padding: 1.5rem 2rem;
`;

const Toggler = styled(Icon)`
  margin-left: 1.875rem;
`;

const StyledTag = styled(Tag)`
  width: fit-content;
`;

export const NearLiquidationGrid = memo((props: NearLiquidationGridProps) => {
  const { profile, assets, healthFactor, collateral, debt, ...others } = props;
  const [open, setOpen] = useState(false);
  const handleToggleGrid = useCallback(() => {
    setOpen(v => !v);
  }, [setOpen]);
  const { desktop, mobile } = useBreakpoints();
  const { erc20Config } = useAppConfig();
  const nativeToken = useNativeTokenSymbol();

  const icons = useMemo(
    () =>
      assets.map(asset => {
        const { type, symbol } = asset;
        if (type === NearLiquidationAssetType.ERC721) {
          const { identifierOrCriteria, contractAddress } = asset;
          return {
            symbol,
            identifierOrCriteria,
            contractAddress
          };
        }
        return {
          symbol
        };
      }),
    [assets]
  );

  const handleHeaderClicked = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if ((e.target as HTMLElement).getAttribute('data-expander') === 'true') {
        setOpen(v => !v);
      }
    },
    [setOpen]
  );

  const {
    explorerLink: [explorerLinkBaseUrl]
  } = useNetworkConfig();

  const getSymbolByContractAddress = useGetSymbolByContractAddress();

  const columns: DataGridColumn<NearLiquidationAsset>[] = useMemo(
    () => [
      {
        title: mobile ? null : 'Assets',
        name: '',
        className: mobile ? 'hiddenTitleLine' : '',
        render: ({ data }) => {
          if (data.type === NearLiquidationAssetType.ERC721) {
            const { collectionName, identifierOrCriteria, contractAddress, traitMultiplier } = data;
            const symbol = getSymbolByContractAddress(contractAddress || '') as ERC721Symbol;
            return (
              <Inline alignItems="center" gap="0.75rem">
                <NoShrinkingNFTThumbnail
                  symbol={symbol}
                  tokenId={identifierOrCriteria}
                  size="small"
                />
                <Stack gap="0.25rem">
                  <Link
                    as={RouterLink}
                    variant={TypographyVariant.header5}
                    to={`/details/${contractAddress}/${identifierOrCriteria}`}
                  >
                    {collectionName} #{identifierOrCriteria}
                  </Link>
                  {traitMultiplier.gt(DEFAULT_MULTIPLIER) && (
                    <StyledTag skin="success" size="small">
                      {formatNumber(traitMultiplier)}x Boost
                    </StyledTag>
                  )}
                </Stack>
              </Inline>
            );
          }
          const { symbol } = data;
          return (
            <Inline alignItems="center">
              <CryptoIcon symbol={symbol} />
              <Text>{getDisplayNameBySymbol(erc20Config, symbol)}</Text>
            </Inline>
          );
        }
      },
      {
        title: 'Amount',
        name: 'amount',
        render: ({ data }) => {
          if (data.type === NearLiquidationAssetType.ERC721) {
            return data.amount;
          }
          return (
            <Stack gap="0.125rem" alignItems={mobile ? 'flex-end' : 'flex-start'}>
              <H5>{formatBalance(data.amount)}</H5>
              <H6 skin="secondary">{formatToCurrency(data.value)}</H6>
            </Stack>
          );
        }
      },
      {
        title: (
          <Inline gap="0.1rem">
            <H5 fontWeight="regular">TWAP Floor Price</H5>
            <Tooltip
              placement="bottom"
              content="Time-Weighted Average Price of the token over the past 6 hours to ensure accurate data. "
            />
          </Inline>
        ),
        name: '',
        render: ({ data }) => {
          if (data.type === NearLiquidationAssetType.ERC20) return '-';
          const { floorPrice, floorPriceInUsd, symbol } = data;
          return (
            <Stack gap="0.125rem" alignItems={mobile ? 'flex-end' : 'flex-start'}>
              <Inline gap="0.25rem" alignItems="center">
                <H5>
                  {formatBalance(floorPrice)} {nativeToken}
                </H5>
                <ChainlinkLogo symbol={symbol} showName={false} />
              </Inline>

              <H6 skin="secondary">{formatToCurrency(floorPriceInUsd)}</H6>
            </Stack>
          );
        }
      }
    ],
    [mobile, erc20Config, getSymbolByContractAddress, nativeToken]
  );

  return (
    <GridCard {...others}>
      <GridHeader data-expander="true" onClick={handleHeaderClicked}>
        <Stack width="100%">
          <Inline width="100%" justifyContent="space-between">
            <Inline data-expander="true" alignItems="center" gap="0.75rem">
              <RoundProfilePicture width="2.5rem" height="2.5rem" />
              <Link
                variant={TypographyVariant.header5}
                target="_blank"
                href={`${explorerLinkBaseUrl}/address/${profile.walletAddress}`}
              >
                {truncateTextMid(profile.walletAddress, 4, 4)}
              </Link>
            </Inline>
            <Inline justifyContent="space-between" alignItems="center" gap="0.5rem">
              {desktop && (
                <AccountLiquidationGrid
                  open={open}
                  assets={icons}
                  isPaused={false}
                  healthFactor={healthFactor}
                  collateral={collateral}
                  debt={debt}
                />
              )}
              <Toggler name={open ? 'minusCircle' : 'downContained'} onClick={handleToggleGrid} />
            </Inline>
          </Inline>
          {!desktop && (
            <AccountLiquidationGrid
              open={open}
              assets={icons}
              isPaused={false}
              healthFactor={healthFactor}
              collateral={collateral}
              debt={debt}
            />
          )}
        </Stack>
      </GridHeader>
      <Collapse open={open}>
        <StyledDataGrid columns={columns} data={assets} pageSize={5} />
      </Collapse>
    </GridCard>
  );
});
