import { Stack, H5, Inline, SmallText, TypographyVariant, Image } from '@parallel-mono/components';
import styled from 'styled-components';
import { memo } from 'react';

import OwnerImage from '@/apps/paraspace/pages/Shop/images/owner.svg';
import { Collapse, Link } from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';

const ImageWithBorder = styled(Image).attrs({ width: '3.5rem', height: '3.5rem' })`
  flex: 0 0 3.5rem;
  padding: 0.25rem;
  border: 1px solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 50%;
`;

const DescriptionWrapper = styled(Stack)`
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: calc(100% - 3rem);
  `}
`;
const DescriptionText = styled(SmallText)`
  white-space: break-spaces;
`;

interface CollectionProps {
  data: {
    name: string;
    externalUrl?: Maybe<string>;
    description: string;
  };
}

const Collection = ({ data: { name, externalUrl, description } }: CollectionProps) => {
  return (
    <Collapse border header="Collection" defaultOpen>
      <Inline inset="1.5rem 0 0 0" grow gap="1rem">
        <ImageWithBorder src={OwnerImage} />
        <DescriptionWrapper gap="1rem">
          <Stack gap="0.125rem">
            <H5>{name}</H5>
            {!!externalUrl && (
              <Inline gap=".25rem">
                <SmallText skin="secondary">By</SmallText>
                <Link
                  href={externalUrl}
                  target="_blank"
                  rel="noreferrer"
                  variant={TypographyVariant.bodySmall}
                >
                  {name}
                </Link>
              </Inline>
            )}
          </Stack>
          <DescriptionText skin="secondary">{description}</DescriptionText>
        </DescriptionWrapper>
      </Inline>
    </Collapse>
  );
};

export const CollectionSection = memo(Collection);
