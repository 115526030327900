import { memo } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { ClaimAllForm, ClaimAllFormProps } from './ClaimAllForm';

export type ClaimAllModalProps = Omit<ModalProps, 'children'> & ClaimAllFormProps;

export const ClaimAllModal = memo(
  ({ claimValue, onClose, onSuccess, ...props }: ClaimAllModalProps) => {
    return (
      <Modal title="Claim All" onClose={onClose} {...props}>
        <ClaimAllForm claimValue={claimValue} onClose={onClose} onSuccess={onSuccess} />
      </Modal>
    );
  }
);
