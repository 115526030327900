import { SelectableTokenInput } from '@parallel-mono/business-components';
import { Card, Inline, Tag } from '@parallel-mono/components';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.skin.background.sub1};
  padding: 1rem;
`;
export const StyledTag = styled(Tag)`
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  display: inline-block;
  cursor: pointer;
`;
export const ImageWrapper = styled(Inline)`
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  background-color: ${({ theme }) => theme.skin.primary.main};
`;

const tokenInputClassnames = {
  tokenSelect: {
    selectWrap: 'token-select',
    menu: 'bridge-token-input-menu'
  }
};

export const StyledSelectableTokenInput = styled(SelectableTokenInput).attrs({
  classNames: tokenInputClassnames
})`
  width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.skin.background.sub1};

  .${tokenInputClassnames.tokenSelect.selectWrap} {
    background-color: ${({ theme }) => theme.skin.background.main};
    border-color: ${({ theme }) => theme.skin.background.main};
    padding-left: 0.25rem;
  }

  .${tokenInputClassnames.tokenSelect.menu} {
    right: 0;
    left: auto;
  }
`;

export const ClickableIconWrapper = styled(Inline)`
  cursor: pointer;
`;
