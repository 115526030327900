import { useCallback } from 'react';
import BigNumber from 'bignumber.js';

import useLegacyERC20 from '../useLegacyERC20';

import { useApproval } from './useApproval';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useAppConfig, useSymbolByContractAddress } from '@/apps/paraspace/hooks';

export const useERC20Approval = ({
  token,
  amount,
  spender,
  from
}: {
  token: string;
  amount: number | BigNumber | null;
  spender?: string;
  from?: string;
}) => {
  const { approve: approveToken, getAllowance } = useLegacyERC20(token, from);
  const symbol = useSymbolByContractAddress(token);

  const checkAllowance = useCallback(
    (allowance: Maybe<BigNumber>) => allowance?.gte(amount ?? 0) ?? false,
    [amount]
  );

  const { erc20Config } = useAppConfig();

  const createApproveTransaction = useCallback(
    async (allowance: Maybe<BigNumber>) => {
      if (allowance?.gt(0) && erc20Config[symbol as ERC20Symbol]?.needResetAllowanceBeforeApprove) {
        const tx = await approveToken(spender, '0');
        await tx?.wait();
      }

      return approveToken(spender);
    },
    [approveToken, symbol, spender, erc20Config]
  );

  const getAllowanceAsBigNumber = useCallback(
    () => getAllowance(spender, from).then(str => new BigNumber(str)),
    [spender, from, getAllowance]
  );

  return useApproval<BigNumber>({
    createApproveTransaction,
    checkAllowance,
    getAllowance: getAllowanceAsBigNumber
  });
};
