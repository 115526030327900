import { memo, useCallback, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import {
  ClaimTimelockSubmitter,
  WithdrawERC20Submitter,
  Stepper,
  StepperProps,
  WithdrawCAPESubmitter
} from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useTimelockClaimable } from '@/apps/paraspace/pages/hooks/Timelock/useTimelockClaimable';

export type WithdrawSubmitterProps = Omit<StepperProps, 'steps' | 'step'> & {
  onError: () => void;
  onFinish: (claimed: boolean) => void;
  data: {
    lendingAmount: BigNumber;
    balanceAmount: BigNumber;
    shouldClaimToEOA: boolean;
  };
};

export const WithdrawSteppers = memo(
  ({
    onError,
    onFinish,
    data: { lendingAmount, balanceAmount, shouldClaimToEOA },
    ...others
  }: WithdrawSubmitterProps) => {
    const [step, setStep] = useState<number>(0);
    const [claimed, setClaimed] = useState(false);
    const [agreementIds, setAgreementIds] = useState<string[]>([]);

    const { erc20InfoMap } = useMMProvider();
    const strategyData = useMemo(() => {
      return erc20InfoMap.CAPE.timeLockStrategyData;
    }, [erc20InfoMap]);

    const handleNextStep = useCallback(() => {
      setStep(curr => curr + 1);
    }, [setStep]);

    const fromLending = useMemo(() => lendingAmount.gt(0), [lendingAmount]);

    const { getTimelockClaimable, autoClaimEnabled } = useTimelockClaimable();
    const handleWithdrawnLending = useCallback(
      async (agreementId: Maybe<string>, releaseTime: Maybe<Date>) => {
        const claimable = await getTimelockClaimable({
          agreementId,
          releaseTime,
          minWaitTime: strategyData.minWaitTime
        });

        if (claimable && autoClaimEnabled) {
          setAgreementIds([agreementId!]);
          handleNextStep();
        } else {
          onFinish?.(false);
        }
      },
      [getTimelockClaimable, strategyData.minWaitTime, autoClaimEnabled, handleNextStep, onFinish]
    );

    const handleClaimed = useCallback(() => {
      setClaimed(true);
      handleNextStep();
    }, [handleNextStep]);

    const steps = useMemo(
      () => [
        {
          description: `Withdraw ${ERC20Symbol.CAPE} and add to Timelock Queue`,
          content: (
            <WithdrawERC20Submitter
              formData={{
                amount: BigNumber(lendingAmount),
                symbol: ERC20Symbol.CAPE
              }}
              onError={onError}
              onFinish={handleWithdrawnLending}
            />
          ),
          skip: !fromLending
        },
        {
          description: `Releasing tokens`,
          content: (
            <ClaimTimelockSubmitter
              formData={{
                amount: lendingAmount,
                symbol: ERC20Symbol.CAPE,
                agreementIds
              }}
              onError={onError}
              onFinish={handleClaimed}
            />
          ),
          skip: !fromLending || !autoClaimEnabled
        },
        {
          description: 'Withdraw cAPE from Balance',
          content: (
            <WithdrawCAPESubmitter
              formData={{
                // Due to contract accuracy issues, withdraw 10 pcAPE will receive (10 - 1e-18) APE,
                // So should only withdraw (10 - 1e-18) cAPE to APE
                amount: balanceAmount.plus(lendingAmount.gt(0) ? lendingAmount.minus(1e-18) : 0),
                shouldClaimToEOA
              }}
              onError={onError}
              onFinish={() => onFinish(claimed)}
            />
          )
        }
      ],
      [
        lendingAmount,
        onError,
        handleWithdrawnLending,
        fromLending,
        agreementIds,
        handleClaimed,
        autoClaimEnabled,
        balanceAmount,
        shouldClaimToEOA,
        onFinish,
        claimed
      ]
    );
    return <Stepper width="100%" step={step} steps={steps} {...others} />;
  }
);
