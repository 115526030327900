import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled, { useTheme } from 'styled-components';
import { Text, Image, Icon } from '@parallel-mono/components';
import cx from 'classnames';
import { isNil } from 'lodash';

import { ImageThumbnail, ImageThumbnailProps } from '../ImageThumbnail';
import { useGetERC721Image } from '../../hooks';
import { NFT_THUMBNAIL_SIZE_MAPPING } from '../constant';

import { placeholders } from './images';

import { ERC1155Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useUniSwapV3Manager } from '@/apps/paraspace/pages/contexts/UniSwapV3ManagerProvider';
import { useETHValidatorManager } from '@/apps/paraspace/pages/contexts/ETHValidatorProvider';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { Maybe } from '@/apps/paraspace/typings/basic';

export type ThumbnailSize = keyof typeof NFT_THUMBNAIL_SIZE_MAPPING;
export type SupportedSymbol = ERC721Symbol | ERC1155Symbol;
export interface NFTThumbnailProps extends Omit<ImageThumbnailProps, 'src' | 'placeholder'> {
  symbol: SupportedSymbol;
  tokenId?: number;
  showDescription?: boolean;
  round?: boolean;
  size?: ThumbnailSize;
  twitterPreview?: boolean;
  disabled?: boolean;
}

const TokenId = styled(Text)`
  text-align: center;
  line-height: 1rem;
`;

const SizedImageThumbnail = styled(ImageThumbnail)<{
  size: ThumbnailSize;
  round: boolean;
  width?: string | number;
  disabled?: Maybe<boolean>;
}>`
  ${({ size }) => NFT_THUMBNAIL_SIZE_MAPPING[size]};
  ${({ width }) => (width ? { width } : {})};
  .imageThumbnail {
    opacity: ${({ theme, disabled }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
    border-radius: ${({ size, round }) =>
      round ? '50%' : NFT_THUMBNAIL_SIZE_MAPPING[size].borderRadius};
  }
`;

const NFT_PLACEHOLDER_MAP = {
  [ERC721Symbol.BAYC]: placeholders.ape,
  [ERC721Symbol.MAYC]: placeholders.ape,
  [ERC721Symbol.BAKC]: placeholders.bakc
} as Record<SupportedSymbol, string>;

export const NFTThumbnail = ({
  symbol,
  tokenId,
  showDescription = false,
  description,
  round = false,
  size = 'large',
  twitterPreview = false,
  disabled = false,
  ...others
}: NFTThumbnailProps) => {
  const { imageMap: uniswapImageMap } = useUniSwapV3Manager();
  const {
    imageMap: ethValidatorImageMap,
    getImageThroughContract: getStakeFishImageThroughContract
  } = useETHValidatorManager();
  const theme = useTheme();

  const [asyncSrc, setAsyncSrc] = useState<string | undefined>();

  const asyncImageSymbols: SupportedSymbol[] = useMemo(() => [ERC721Symbol.SF_VLDR], []);

  useAsyncEffect(async () => {
    if (symbol === ERC721Symbol.SF_VLDR && !isNil(tokenId)) {
      const image = await getStakeFishImageThroughContract(tokenId);
      setAsyncSrc(image);
    }
  }, [getStakeFishImageThroughContract, symbol, tokenId]);

  const getERC721Image = useGetERC721Image();

  const nftSrc = useMemo(() => {
    if (!isNil(tokenId) && !asyncImageSymbols.includes(symbol)) {
      if (symbol === ERC1155Symbol.VALIDATOR_NFT) {
        return undefined;
      }
      if (symbol === ERC721Symbol.SF_VLDR) {
        const image = ethValidatorImageMap?.[tokenId]?.image;
        if (!image) {
          getStakeFishImageThroughContract(tokenId);
        }
        return ethValidatorImageMap?.[tokenId]?.image;
      }
      if (symbol === ERC721Symbol.UNISWAP_LP) {
        return uniswapImageMap?.[tokenId]?.image;
      }
      return getERC721Image({ symbol, tokenId });
    }
    return undefined;
  }, [
    tokenId,
    asyncImageSymbols,
    symbol,
    ethValidatorImageMap,
    getStakeFishImageThroughContract,
    uniswapImageMap,
    getERC721Image
  ]);

  const descriptionSection = description ?? <TokenId>#{tokenId}</TokenId>;

  return (
    <>
      {twitterPreview && (
        <Helmet>
          <meta property="twitter:image" content={nftSrc || asyncSrc} />
        </Helmet>
      )}
      <SizedImageThumbnail
        size={size}
        round={round}
        classNames={{ imageThumbnail: cx('imageThumbnail') }}
        src={nftSrc || asyncSrc}
        disabled={disabled}
        fallback={
          NFT_PLACEHOLDER_MAP[symbol] ? (
            <Image
              width="100%"
              src={NFT_PLACEHOLDER_MAP[symbol]}
              style={{
                borderRadius: round ? '50%' : NFT_THUMBNAIL_SIZE_MAPPING[size].borderRadius
              }}
            />
          ) : undefined
        }
        placeholder={<Icon name="image" size="100%" color={theme.skin.grey[500]} />}
        description={showDescription && descriptionSection}
        {...others}
      />
    </>
  );
};
