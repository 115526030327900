import { useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import {
  Marketplace,
  ShopBidByPlatform,
  useGetReceivedOrdersLazyQuery
} from '@/apps/paraspace/generated/graphql';
import { useToggle, useWeb3Context } from '@/apps/paraspace/contexts';
import { useFormatEntity } from '@/apps/paraspace/pages/Shop/hooks';
import { Offer } from '@/apps/paraspace/pages/Shop/types';
import { useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { ERC721Symbol } from '@/apps/paraspace/typings';

const PAGE_SIZE = 5;

export type NftInfoForOrder = {
  contractAddress: string;
  symbol: ERC721Symbol;
  name: string;
  tokenId: number;
};

export type ReceivedOffer = {
  topOffer: Offer;
  offerCount: number;
  nftInfo: NftInfoForOrder;
};

export const useAccountReceivedOffers = () => {
  const { formatOrder } = useFormatEntity();
  const { nftInfoMap, userInfoLoaded } = useMMProvider();
  const enableBlur = useToggle('BLUR_INTEGRATION');
  const { account } = useWeb3Context();
  const getSymbolByContractAddress = useGetSymbolByContractAddress();

  const [getReceivedOrders, { data: offersData, loading }] = useGetReceivedOrdersLazyQuery();

  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const filterAssets = useMemo(() => {
    return userInfoLoaded && !isEmpty(nftInfoMap)
      ? Object.values(nftInfoMap)
          .filter(nftInfo =>
            nftInfo?.nftSuppliedList ? nftInfo?.nftSuppliedList?.length > 0 : false
          )
          .map(nftInfo => {
            return {
              contractAddress: nftInfo?.address,
              identifiers: nftInfo?.nftSuppliedList?.map(v => String(v)) ?? []
            };
          })
      : [];
  }, [nftInfoMap, userInfoLoaded]);

  useAsyncEffect(async () => {
    if (!isEmpty(filterAssets)) {
      getReceivedOrders({
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            taker: account,
            assets: filterAssets
          },
          pageInfo: {
            offset: (currentPage - 1) * PAGE_SIZE,
            limit: PAGE_SIZE
          },
          platforms: enableBlur ? [Marketplace.Blur] : []
        }
      });
    }
  }, [getReceivedOrders, filterAssets, currentPage, account]);

  const offers = useMemo(() => {
    return (
      offersData?.aggregatedOrders?.nodes?.map(
        ({ orderCount, aggregatedOrder, contractAddress, identifierOrCriteria }) => {
          const symbol = getSymbolByContractAddress(contractAddress) as ERC721Symbol;
          const { collectionName } = nftInfoMap[symbol] || {};
          return {
            offerCount: orderCount,
            topOffer: formatOrder(aggregatedOrder as ShopBidByPlatform),
            nftInfo: {
              contractAddress,
              symbol,
              name: `${collectionName}#${identifierOrCriteria}`,
              tokenId: Number(identifierOrCriteria)
            }
          };
        }
      ) ?? []
    );
  }, [formatOrder, getSymbolByContractAddress, nftInfoMap, offersData?.aggregatedOrders?.nodes]);

  const totalPage = useMemo(
    () => Math.ceil((offersData?.aggregatedOrders?.totalCount ?? 0) / PAGE_SIZE),
    [offersData?.aggregatedOrders?.totalCount]
  );

  const totalCount = useMemo(
    () => offersData?.aggregatedOrders?.totalCount ?? 0,
    [offersData?.aggregatedOrders?.totalCount]
  );

  const isLoading = useMemo(
    () => (isEmpty(offersData) && !isEmpty(filterAssets) && loading) || !userInfoLoaded,
    [userInfoLoaded, filterAssets, loading, offersData]
  );

  return {
    handlePageChange,
    currentPage,
    totalPage,
    totalCount,
    offers,
    loading: isLoading
  };
};
