import { Modal, ModalProps } from '@parallel-mono/components';
import { useMemo, FC, memo } from 'react';
import BigNumberJs from 'bignumber.js';

import { InitBlurRequestStepper } from './InitBlurRequestStepper';
import { BatchBuyOpenseaStepper } from './BatchBuyOpenseaStepper';

import {
  ErrorState,
  SuccessState,
  useCollectAndSubmitProcedure,
  CollectAndSubmitProcedurePhase
} from '@/apps/paraspace/components';
import { Marketplace } from '@/apps/paraspace/generated/graphql';

export type BatchBuyModalPayload = {
  platform: Marketplace;
  buyNowAmount: BigNumberJs;
  creditAmount: BigNumberJs;
  errorMsg?: string;
};

type BatchBuyModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  isOpen: boolean;
  payload: BatchBuyModalPayload;
  onClose: () => void;
  onFinish: () => void;
  onError: (e: any) => void;
};

export const BatchBuyModal: FC<BatchBuyModalProps> = memo(props => {
  const { isOpen, payload, onClose, onError, onFinish, ...others } = props;
  const { platform, errorMsg } = payload;

  const { phase, submittingError, handleSubmitFailed, handleSubmitSuccess } =
    useCollectAndSubmitProcedure<any, void, Error>({
      defaultPhase: CollectAndSubmitProcedurePhase.Submitting,
      running: isOpen,
      onFinish
    });

  const stepper = useMemo(() => {
    return platform === Marketplace.OpenSea ? (
      <BatchBuyOpenseaStepper
        onError={handleSubmitFailed}
        onSuccess={handleSubmitSuccess}
        payload={payload}
      />
    ) : (
      <InitBlurRequestStepper
        onError={handleSubmitFailed}
        onSuccess={handleSubmitSuccess}
        payload={payload}
      />
    );
  }, [handleSubmitFailed, handleSubmitSuccess, payload, platform]);

  return (
    <Modal
      size="32.5rem"
      title={`Buy with credit on ${platform}`}
      onClose={onClose}
      closable={false}
      isOpen={isOpen}
      {...others}
    >
      {phase === CollectAndSubmitProcedurePhase.Submitting && stepper}
      {phase === CollectAndSubmitProcedurePhase.Failed && (
        <ErrorState
          errorMsg={errorMsg}
          closeModal={() => {
            onClose();
            onError(submittingError);
          }}
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Success && (
        <SuccessState
          desc="Transaction succeeded"
          tip="Please check your new NFT in your supply positions."
          actionButtonText="Okay"
          onAction={onClose}
        />
      )}
    </Modal>
  );
});
