import { Pool, UiPoolDataProvider } from 'paraspace-utilities-contract-helpers';
import { keyBy, mapKeys, mapValues, merge, zipObject } from 'lodash';
import BigNumber from 'bignumber.js';
import { ERC721Symbol } from 'paraspace-configs-v2';

import { StakeFishInfo, getNTokensDataMap } from '../utils';

import { AccountData } from './types';

import { ERC20Config, ERC721Config } from '@/apps/paraspace/config';
import { ERC20Symbol } from '@/apps/paraspace/typings';

export const MULTIPLIER_BASE_DECIMAL = 4;

type ERC721PositionMap = Record<
  ERC721Symbol,
  {
    nftSuppliedList: number[];
    symbol: ERC721Symbol;
    usageAsCollateralEnabledOnUser: boolean;
    auctionedTokens: number[];
    nftCollateralList: number[];
    tokenTraitMultipliers: Record<string, BigNumber>;
    stakeFishInfoMap: Record<string, StakeFishInfo>;
  }
>;

type ERC20PositionMap = Record<
  ERC20Symbol,
  {
    scaledXTokenBalance: BigNumber;
    scaledVariableDebt: BigNumber;
    usageAsCollateralEnabledOnUser: boolean;
  }
>;

type UserPositions = {
  erc20PositionMap: Partial<ERC20PositionMap>;
  erc721PositionMap: Partial<ERC721PositionMap>;
  accountData: AccountData;
};

const getERC721SupplyPositionMap = async ({
  tokens,
  provider,
  account
}: {
  tokens: {
    xTokenAddress: string;
    nftEnumerableType: number;
    selfIncrementTokenId: boolean;
    symbol: ERC721Symbol;
  }[];
  provider: UiPoolDataProvider;
  account: string;
}) => {
  const erc721SupplyPositions = await Promise.all(
    tokens.map(async token => {
      const { xTokenAddress, nftEnumerableType } = token;

      const nftSuppliedList = await provider.getERC721Balance({
        user: account,
        nft: xTokenAddress,
        nftType: nftEnumerableType,
        viewerStep: 500,
        useTotalSupplyAsMaxId: true
      });

      return nftSuppliedList;
    })
  );

  return zipObject(
    tokens.map(token => token.symbol),
    erc721SupplyPositions
  );
};

const getNonEmptyReserves = async ({
  provider,
  lendingPoolAddressProvider,
  account
}: {
  provider: UiPoolDataProvider;
  lendingPoolAddressProvider: string;
  account: string;
}) => {
  const userReserves = await provider.getUserReservesData({
    lendingPoolAddressProvider,
    user: account
  });

  return userReserves.filter(it => it.scaledXTokenBalance.gt(0) || it.scaledVariableDebt.gt(0));
};

const HEALTH_FACTOR_DECIMAL = 18;

export const getUserERC20PositionMap = async ({
  provider,
  account,
  lendingPoolAddressProvider,
  erc20Config
}: {
  provider: UiPoolDataProvider;
  account: string;
  lendingPoolAddressProvider: string;
  erc20Config: ERC20Config;
}) => {
  const erc20AddressToConfig = mapKeys(erc20Config, config => config.address);

  const erc20Reserves = (
    await getNonEmptyReserves({
      provider,
      lendingPoolAddressProvider,
      account
    })
  ).filter(it => it.underlyingAsset in erc20AddressToConfig);

  return mapValues(
    keyBy(erc20Reserves, it => erc20AddressToConfig[it.underlyingAsset].symbol),
    it => ({
      scaledVariableDebt: new BigNumber(it.scaledVariableDebt.toString()),
      scaledXTokenBalance: new BigNumber(it.scaledXTokenBalance.toString()),
      usageAsCollateralEnabledOnUser: it.usageAsCollateralEnabledOnUser
    })
  );
};

export const getUserAccountData = async ({
  poolService,
  account,
  marketReferenceCurrencyData
}: {
  poolService: Pool;
  account: string;
  marketReferenceCurrencyData: {
    decimals: number;
    priceInUSD: number;
  };
}): Promise<AccountData> => {
  const userAccountData = await poolService.getUserAccountData({ user: account });

  return {
    totalCollateralPriceInUSD: new BigNumber(userAccountData.totalCollateralBase.toString())
      .shiftedBy(-marketReferenceCurrencyData.decimals)
      .times(marketReferenceCurrencyData.priceInUSD),
    totalDebtPriceInUSD: new BigNumber(userAccountData.totalDebtBase.toString())
      .shiftedBy(-marketReferenceCurrencyData.decimals)
      .times(marketReferenceCurrencyData.priceInUSD),
    availableBorrowsPriceInUSD: new BigNumber(userAccountData.availableBorrowsBase.toString())
      .shiftedBy(-marketReferenceCurrencyData.decimals)
      .times(marketReferenceCurrencyData.priceInUSD),
    currentLiquidationThreshold: new BigNumber(
      userAccountData.currentLiquidationThreshold.toString()
    ).shiftedBy(-MULTIPLIER_BASE_DECIMAL),
    ltv: new BigNumber(userAccountData.ltv.toString()).shiftedBy(-MULTIPLIER_BASE_DECIMAL),
    healthFactor: new BigNumber(userAccountData.healthFactor.toString()).shiftedBy(
      -HEALTH_FACTOR_DECIMAL
    ),
    nftHealthFactor: new BigNumber(userAccountData.erc721HealthFactor.toString()).shiftedBy(
      -HEALTH_FACTOR_DECIMAL
    )
  };
};

export const getUserERC721PositionMap = async ({
  provider,
  account,
  lendingPoolAddressProvider,
  erc721Config
}: {
  provider: UiPoolDataProvider;
  account: string;
  lendingPoolAddressProvider: string;
  erc721Config: ERC721Config;
}): Promise<UserPositions['erc721PositionMap']> => {
  const erc721AddressToConfig = mapKeys(erc721Config, config => config.address);

  const erc721Reserves = (
    await getNonEmptyReserves({
      provider,
      lendingPoolAddressProvider,
      account
    })
  ).filter(it => it.underlyingAsset in erc721AddressToConfig);

  const collectionBasicInfos = erc721Reserves.map(
    ({
      underlyingAsset,
      usageAsCollateralEnabledOnUser,
      currentXTokenBalance,
      scaledXTokenBalance,
      collateralizedBalance
    }) => {
      const { symbol, xTokenAddress, selfIncrementTokenId } =
        erc721AddressToConfig[underlyingAsset];
      return {
        underlyingAsset,
        xTokenAddress,
        selfIncrementTokenId,
        symbol,
        nftEnumerableType: 1,
        usageAsCollateralEnabledOnUser,
        currentXTokenBalance: new BigNumber(currentXTokenBalance.toString()),
        scaledXTokenBalance: new BigNumber(scaledXTokenBalance.toString()),
        collateralizedBalance: new BigNumber(collateralizedBalance.toString())
      };
    }
  );
  const erc721SupplyPositionMap = await getERC721SupplyPositionMap({
    tokens: collectionBasicInfos,
    provider,
    account
  });

  const userNTokensDataMap = await getNTokensDataMap({
    provider,
    lendingPoolAddressProvider,
    tokens: collectionBasicInfos.map(token => ({
      ...token,
      nftSuppliedList: erc721SupplyPositionMap[token.symbol]
    }))
  });

  const collectionBasicInfoMap = keyBy(collectionBasicInfos, it => it.symbol);
  const erc721PositionMap = merge(
    {},
    collectionBasicInfoMap,
    mapValues(erc721SupplyPositionMap, nftSuppliedList => ({ nftSuppliedList })),
    userNTokensDataMap
  );

  return erc721PositionMap;
};
