import { memo, useCallback, useMemo, useState } from 'react';
import {
  Button,
  H3,
  H6,
  Inline,
  SmallText,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import { formatNumber, sumBy } from '@parallel-mono/utils';

import { BuyCartAsset, Payment } from '../../../types';
import useBatchNftCredit from '../../../useBatchNftCredit';
import { supportedCurrency } from '../consts';

import { Infos } from './Infos';
import { useAssociatedPays } from './useAssociatedPays';

import {
  StackedIcons,
  WarningForBuyingApeStaking,
  SelectableTokenInput
} from '@/apps/paraspace/components';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { formatToCurrency, formatToPercentage } from '@/apps/paraspace/utils/format';

type CheckoutFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  assets: BuyCartAsset[];
  onSubmit: (formData: { assets: BuyCartAsset[]; payNow: Payment; payLater: Payment }) => void;
};

export const CheckoutForm = memo(({ assets, onSubmit, ...others }: CheckoutFormProps) => {
  const { erc20InfoMap } = useMMProvider();

  const ethPriceInUsd = erc20InfoMap.ETH?.priceInUsd ?? zero;
  const totalPriceInEth = useMemo(() => sumBy(assets, it => it.price), [assets]);
  const totalPriceInUsd = useMemo(
    () => totalPriceInEth.times(ethPriceInUsd),
    [totalPriceInEth, ethPriceInUsd]
  );

  const futureAvailableCredit = useBatchNftCredit({
    tokenList: assets,
    creditBasedCurrencyValueInUsd: ethPriceInUsd
  });

  const selectableTokens = useMemo(
    () =>
      supportedCurrency.map(currency => {
        const {
          balance = zero,
          priceInUsd,
          totalDebt,
          availableLiquidity,
          borrowApyRate
        } = erc20InfoMap[currency] ?? {};
        const utilization = totalDebt.div(totalDebt.plus(availableLiquidity));
        return {
          name: currency,
          symbol: currency,
          balance: balance.toNumber(),
          description: `${formatToPercentage(utilization)} utilized`,
          priceInUSD: priceInUsd.toNumber(),
          displayBalance: (
            <Stack gap="0">
              <H6>{formatToPercentage(borrowApyRate)}</H6>
              <SmallText>APY</SmallText>
            </Stack>
          )
        };
      }),
    [erc20InfoMap]
  );

  const {
    handlePayLaterChange,
    handlePayNowChange,
    handleMaximizePayLater,
    handleMaximizePayNow,
    maxPayNowTokenAmount,
    maxPayLaterTokenAmount,
    payNow,
    payLater
  } = useAssociatedPays({
    defaultToken: selectableTokens.find(it => it.symbol === ERC20Symbol.ETH)!,
    futureAvailableCredit,
    totalPriceInEth
  });

  const [termsAgreed, setTermsAgreed] = useState(false);

  const handleTermsCheckChange = useCallback(({ checked }) => {
    setTermsAgreed(checked);
  }, []);

  const handleSubmitButtonClicked = useCallback(() => {
    onSubmit({
      assets,
      payLater: {
        currency: payLater.token!.symbol as ERC20Symbol,
        amount: payLater.amount ?? 0
      },
      payNow: {
        currency: payNow.token!.symbol as ERC20Symbol,
        amount: payNow.amount ?? 0
      }
    });
  }, [onSubmit, assets, payNow, payLater]);

  const totalPaymentInEth = useMemo(() => {
    const { amount: payNowTokenAmount } = payNow;
    const { symbol: payNowTokenSymbol } = payNow.token!;
    const { amount: payLaterTokenAmount } = payLater;
    const { symbol: payLaterTokenSymbol } = payLater.token!;

    const payNowTokenPriceInEth = erc20InfoMap[payNowTokenSymbol].priceInETH;
    const payLaterTokenPriceInEth = erc20InfoMap[payLaterTokenSymbol].priceInETH;

    return payNowTokenPriceInEth
      .times(payNowTokenAmount ?? 0)
      .plus(payLaterTokenPriceInEth.times(payLaterTokenAmount ?? 0));
  }, [payNow, payLater, erc20InfoMap]);

  const isInsufficientPayment = totalPaymentInEth.lt(totalPriceInEth);
  const availableLiquidity = erc20InfoMap[payLater.token!.symbol]?.availableLiquidity ?? zero;
  const isInsufficientLiquidity = useMemo(
    () => availableLiquidity && availableLiquidity.lt(payLater.amount ?? 0),
    [availableLiquidity, payLater.amount]
  );

  const error = useMemo(() => {
    if (isInsufficientPayment) {
      return 'Insufficient Payment';
    }
    if (isInsufficientLiquidity) {
      return `Borrow amount exceeds market liquidity${
        availableLiquidity.toNumber() > 0
          ? `, please enter an amount below ${formatNumber(availableLiquidity)} ETH.`
          : '.'
      }`;
    }
    return null;
  }, [isInsufficientPayment, isInsufficientLiquidity, availableLiquidity]);

  return (
    <Stack {...others}>
      <Inline>
        <StackedIcons assets={assets} />
        <Stack gap="0">
          <H3>
            {assets.length} NFT{assets.length > 1 ? 's' : ''}
          </H3>
          <Text fontWeight="bold" skin="secondary">
            {formatNumber(totalPriceInEth)} ETH ({formatToCurrency(totalPriceInUsd)})
          </Text>
        </Stack>
      </Inline>

      <SelectableTokenInput
        tokens={selectableTokens}
        actionButtonText="Max"
        value={payLater}
        onActionButtonClicked={handleMaximizePayLater}
        onChange={handlePayLaterChange}
        decimals={4}
        inputProps={{
          title: 'Pay Later'
        }}
        max={maxPayLaterTokenAmount.toNumber()}
        min={0}
        classNames={{
          tokenSelect: {
            menu: 'token-select-menu',
            item: 'token-select-item'
          }
        }}
      />

      <SelectableTokenInput
        tokens={selectableTokens}
        actionButtonText="Max"
        value={payNow}
        decimals={4}
        max={maxPayNowTokenAmount.toNumber()}
        min={0}
        onChange={handlePayNowChange}
        onActionButtonClicked={handleMaximizePayNow}
        inputProps={{
          title: 'Pay Now'
        }}
        classNames={{
          tokenSelect: {
            menu: 'token-select-menu',
            item: 'token-select-item'
          }
        }}
      />

      <Infos assets={assets} payLaterCurrency={payLater.token!.symbol as ERC20Symbol} />

      <WarningForBuyingApeStaking checked={termsAgreed} onChange={handleTermsCheckChange} />

      <Button
        block
        size="large"
        disabled={!termsAgreed || !!error}
        onClick={handleSubmitButtonClicked}
      >
        {error ?? 'Confirm Payment'}
      </Button>
    </Stack>
  );
});
