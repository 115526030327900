import { SmallText, TypographyVariant } from '@parallel-mono/components';
import styled from 'styled-components';

import { Link } from '../Link';
import { DEVELOPER_DOCS_LINK } from '../../consts/externalLinks';

const BoostText = styled(SmallText)`
  max-width: 16.875rem;
`;

export const TraitBoostTip = () => {
  return (
    <BoostText skin="secondary">
      This NFT has trait boost value if supplied to ParaSpace lending pool.{' '}
      <Link
        variant={TypographyVariant.bodySmall}
        href={`${DEVELOPER_DOCS_LINK}/paraspace-as-first-cross-margin-nft-lending-protocol/supplying-nfts/get-credit-for-rare-nfts-on-paraspace`}
        target="_blank"
      >
        Learn More
      </Link>
    </BoostText>
  );
};
