import { memo } from 'react';
import { H3, H5, Inline, Stack, Tag } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import styled from 'styled-components';

import { BuyShopItemPayload } from '..';

import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { NFTThumbnail, TraitBoostTip } from '@/apps/paraspace/components';
import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';

interface FormHeaderProps {
  payload: BuyShopItemPayload;
}

const ItemDetail = styled(Stack)`
  height: 100%;
  ${({ theme }) => theme.breakpoints.down('lg')`
    overflow: hidden;
  `};
`;

const NFTInfo = styled(H3)`
  ${({ theme }) => theme.breakpoints.down('lg')`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `};
`;

export const FormHeader = memo(({ payload }: FormHeaderProps) => {
  const {
    tokenId,
    multiplier,
    lowestPriceListing,
    collection: { collectionName, symbol }
  } = payload;
  const isShowTraitMultiplier = multiplier.gt(DEFAULT_MULTIPLIER);
  return (
    <Inline alignItems="center">
      <NFTThumbnail
        symbol={symbol}
        tokenId={Number(tokenId)}
        size="large"
        floatingTag={
          isShowTraitMultiplier && (
            <Tag skin="success" size="small">
              {formatNumber(multiplier)}x Boost
            </Tag>
          )
        }
      />
      <ItemDetail justifyContent="center" gap="0.75rem">
        <Stack gap="0.25rem">
          <NFTInfo>
            {collectionName} #{tokenId}
          </NFTInfo>
          {lowestPriceListing && (
            <Inline alignItems="center" gap="0.25rem">
              <H5 fontWeight="bold">Price: {formatBalance(lowestPriceListing.price)} ETH</H5>
              <H5 fontWeight="bold" skin="secondary">
                ({formatToCurrency(lowestPriceListing.priceInUsd)})
              </H5>
            </Inline>
          )}
        </Stack>
        {isShowTraitMultiplier && <TraitBoostTip />}
      </ItemDetail>
    </Inline>
  );
});
