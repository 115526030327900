import { useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { formatBalance } from '@/apps/paraspace/utils/format';

const validate = ({
  amount,
  borrowLimit,
  tokenDisplayName,
  availableLiquidity,
  exceedMaxUtilization
}: {
  amount: Maybe<number>;
  borrowLimit: BigNumber;
  availableLiquidity: BigNumber;
  tokenDisplayName: string;
  exceedMaxUtilization: boolean;
}) => {
  if (amount === null) {
    return null;
  }
  if (exceedMaxUtilization) {
    return 'Borrow is disabled due to extreme high utilization.';
  }
  if (!Number.isFinite(amount) || (amount ?? 0) <= 0) {
    return 'Invalid input.';
  }
  if (borrowLimit.lt(amount)) {
    return 'Exceed borrow limit.';
  }
  if (availableLiquidity.lt(amount)) {
    return `Borrow amount exceeds market liquidity${
      availableLiquidity.toNumber() > 0
        ? `, please enter an amount below ${formatBalance(availableLiquidity)} ${tokenDisplayName}.`
        : '.'
    }`;
  }
  return null;
};

export const useValidation = ({
  amount,
  borrowLimit,
  tokenDisplayName,
  availableLiquidity,
  exceedMaxUtilization
}: {
  amount: Maybe<number>;
  borrowLimit: BigNumber;
  availableLiquidity: BigNumber;
  tokenDisplayName: string;
  exceedMaxUtilization: boolean;
}) => {
  return useMemo(
    () =>
      validate({ amount, borrowLimit, tokenDisplayName, availableLiquidity, exceedMaxUtilization }),
    [amount, borrowLimit, tokenDisplayName, availableLiquidity, exceedMaxUtilization]
  );
};
