import { findKey } from 'lodash';
import BigNumber from 'bignumber.js';
import { Network } from 'paraspace-configs-v2';

import { formatBalance } from '@/apps/paraspace/utils/format';

const NETWORK_NAME_MAPPING: Record<string, Network[]> = {
  Ethereum: [Network.MAINNET, Network.SEPOLIA],
  Arbitrum: [Network.ARBITRUM_ONE, Network.ARBITRUM_GOERLI],
  zkSyncEra: [Network.ZKSYNC_ERA, Network.ZKSYNC_GOERLI],
  parallelL3: [Network.PARALLEL_L3_TESTNET]
};

export const generateTwitterContent = ({
  chainId,
  position,
  rankName,
  totalPoints
}: {
  chainId: Network;
  position: string;
  rankName: string;
  totalPoints?: BigNumber;
}): string => {
  const network = findKey(NETWORK_NAME_MAPPING, v => v.includes(chainId));
  const totalPointsText = totalPoints ? `total points is ${formatBalance(totalPoints)} and` : '';

  return `Sharing my ${rankName} ranking at @ParaX_ai on %23${network}! My ${totalPointsText} rank is ${position}. Excited to be part of this thriving ecosystem. Join me on this exciting ride! 📈🚀`;
};
