import { useEffect, useMemo, useState } from 'react';
import { CryptoIcon } from '@parallel-mono/business-components';

import { ContractName } from '@/apps/paraspace/config';
import { CollectionInfo } from '@/apps/paraspace/pages/Shop/contexts';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { SelectBaseOption } from '@/apps/paraspace/components';
import { useAppConfig, useContractsMap } from '@/apps/paraspace/hooks';

export interface PaymentTokenOption extends Omit<SelectBaseOption, 'value'> {
  address: string;
  value: ERC20Symbol;
}

export const useSelectPaymentToken = ({
  paymentTokens
}: {
  paymentTokens: CollectionInfo['paymentTokens'];
}) => {
  const contracts = useContractsMap();

  const { erc20Config } = useAppConfig();

  const paymentTokenOptions: PaymentTokenOption[] = useMemo(() => {
    return Object.keys(ERC20Symbol)
      .filter(ercSymbol => {
        return paymentTokens?.find((payment: any) => {
          // for bid, payment tokens should not be native tokens
          return (
            ercSymbol === payment?.symbol &&
            erc20Config[ercSymbol as ERC20Symbol] &&
            !erc20Config[ercSymbol as ERC20Symbol]!.isNativeToken
          );
        });
      })
      .map(paymentSymbol => {
        return {
          icon: <CryptoIcon symbol={paymentSymbol} size="small" />,
          label: paymentSymbol,
          value: paymentSymbol as ERC20Symbol,
          address: contracts[paymentSymbol as ContractName]
        };
      });
  }, [paymentTokens, contracts, erc20Config]);

  const [selectedPaymentToken, setSelectedPaymentToken] = useState<PaymentTokenOption>(
    paymentTokenOptions[0]
  );

  useEffect(() => {
    setSelectedPaymentToken(paymentTokenOptions[0]);
  }, [paymentTokenOptions]);

  const handlePaymentTokenChange = (option: PaymentTokenOption | null) => {
    setSelectedPaymentToken(option!);
  };

  return {
    paymentTokenOptions,
    selectedPaymentToken,
    handlePaymentTokenChange
  };
};
