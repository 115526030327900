import { H3, Icon, Inline, Modal } from '@parallel-mono/components';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';

import useLiquidation from '../../pages/hooks/useLiquidation';

import AddWNativeTokenForm from './AddWNativeTokenForm';
import MainFormContent, { LiquidationBuyModalPayload } from './MainForm';

import { useWNativeTokenSymbol } from '@/apps/paraspace/pages/hooks';

const InlineWithCursor = styled(Inline)`
  cursor: pointer;
`;

export const LiquidationBuyModal = ({
  onUpdateBuyingAssets,
  isOpen,
  closeModal,
  data: { address, tokenId },
  data
}: {
  onUpdateBuyingAssets?: React.Dispatch<React.SetStateAction<string[]>>;
  isOpen: boolean;
  closeModal: () => void;
  data: LiquidationBuyModalPayload;
}) => {
  const [showAddWNativeToken, setShowAddWNativeToken] = useState(false);

  const wNativeToken = useWNativeTokenSymbol();

  const hideAddWNativeTokenForm = useCallback(() => {
    setShowAddWNativeToken(false);
  }, []);

  const showAddWNativeTokenForm = useCallback(() => {
    setShowAddWNativeToken(true);
  }, []);

  const { buyAsset } = useLiquidation(address!, Number(tokenId!));

  const handleBuyAsset = useCallback(
    (user: string, prices: { buyNowPrice: BigNumber; floorPrice: BigNumber }) => {
      const assetUniqId = `${data.address}-${data.tokenId}`;
      onUpdateBuyingAssets?.(prev => prev.concat(assetUniqId));
      return buyAsset({ user, asset: prices }).finally(() => {
        onUpdateBuyingAssets?.(prev => prev.filter(v => v !== assetUniqId));
      });
    },
    [buyAsset, data.address, data.tokenId, onUpdateBuyingAssets]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      keepMounted={false}
      closeOnBackdropClick={false}
      title={
        showAddWNativeToken ? (
          <InlineWithCursor gap="1rem" alignItems="center" onClick={hideAddWNativeTokenForm}>
            <Icon name="arrowLeft" />
            <H3>Add {wNativeToken}</H3>
          </InlineWithCursor>
        ) : (
          'Buy Now'
        )
      }
    >
      {showAddWNativeToken ? (
        <AddWNativeTokenForm hideAddWTokenForm={hideAddWNativeTokenForm} />
      ) : (
        <MainFormContent
          data={data}
          closeModal={closeModal}
          onBuyAsset={handleBuyAsset}
          onAddWETHClick={showAddWNativeTokenForm}
        />
      )}
    </Modal>
  );
};
