import { Network } from 'paraspace-configs-v2';

import { DOCS_URL } from '../consts/externalLinks';

import { Marketplace } from '@/apps/paraspace/generated/graphql';

export const ENABLE_OMNI_YIELD = false;

// minimum notable digit 0.001
export const MINIMUM_ACCOUNTABLE_NUM = 0.001;

export const MAXIMUM_BALANCE_DECIMALS = 4;

export const MAXIMUM_LISTING_DECIMALS = 3;

// 15s
export const BLOCK_UPDATE_INTERVAL_FOR_CONNECTED_USER = 15 * 1000;
// 10 minutes
export const BLOCK_UPDATE_INTERVAL_FOR_UNCONNECTED_USER = 10 * 60 * 1000;
export const HEALTHY_FACTOR_WITH_CUSHION_FOR_WITHDRAW = 1.01;

export const MINIMUM_LISTED_PRICE = 0.001;

export const IN_LIQUIDATION_HF_THRESHOLD = 1;

export const RECOVERY_NFT_HF_THRESHOLD = 1.05;
export const IN_LIQUIDATION_NFT_HF_THRESHOLD = 1;

export const MAX_POOL_UTILIZATION = 0.95;

// Avoid exceeding the limit caused by price changes
export const MAX_BORROW_PERCENTAGE = 0.99;

export const DEFAULT_MULTIPLIER = 1;

type platformUrlBuilderProps = {
  network: Network;
  platform: Marketplace;
  payload:
    | {
        type: 'asset';
        address: string;
        id: string;
      }
    | {
        type: 'collection';
        address: string;
      };
};

export const platformUrlBuilder = ({ network, platform, payload }: platformUrlBuilderProps) => {
  if (platform === Marketplace.OpenSea) {
    if (payload.type === 'asset') {
      const { address, id } = payload;
      if (network === Network.MAINNET) {
        return `https://opensea.io/assets/ethereum/${address}/${id}`;
      }
      return `https://testnets.opensea.io/assets/goerli/${address}/${id}`;
    }
    // if we have others user case
    return '';
  }

  if (platform === Marketplace.Blur) {
    if (payload.type === 'asset') {
      const { address, id } = payload;
      if (network === Network.MAINNET) {
        return `https://blur.io/asset/${address}/${id}`;
      }
      // Blur is currently only available on Ethereum.
      return '';
    }
    return '';
  }

  // if we have other platform
  return '';
};

export const MAX_PERCENT = 1000;
export const HIDDEN_DECIMAL_PERCENT = 1;
export const MIN_PERCENT_DECIMAL = 0;
export const DEFAULT_PERCENT_DECIMAL = 2;

export const SECURITY_AUDITS = {
  COUNT: 10,
  LINK: `${DOCS_URL}/parax/security`
};
