import React, { createContext, useContext, ReactElement, useMemo } from 'react';
import { map } from 'lodash';

import { StakeInfoListItem } from '../types';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useStakingInfos } from '@/apps/paraspace/pages/ApePairing/contexts/StakingInfosProvider';
import { useGetERC721Image } from '@/apps/paraspace/hooks';

const Context = createContext<{
  stakingInfoList: StakeInfoListItem[];
  bakcInfoList: StakeInfoListItem[];
  stakingInfoLoaded: boolean;
  refresh: () => void;
}>({
  stakingInfoList: [],
  bakcInfoList: [],
  stakingInfoLoaded: false,
  refresh: () => {}
});

export const useStakeInfoList = () => useContext(Context);

export const StakeInfoListProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const {
    balanceStakingInfo,
    suppliedStakingInfo,
    balanceStakingInfoLoaded: stakingInfoLoaded,
    refreshBalanceStakingInfo: refresh
  } = useStakingInfos();

  const getERC721Image = useGetERC721Image();

  const balanceTokensInfo: StakeInfoListItem[] = useMemo(
    () =>
      map(balanceStakingInfo, tokenInfo => ({
        ...tokenInfo,
        thumbnail: getERC721Image({
          symbol: tokenInfo.symbol,
          tokenId: tokenInfo.tokenId
        })
      })),
    [balanceStakingInfo, getERC721Image]
  );

  const suppliedTokensInfo: StakeInfoListItem[] = useMemo(
    () =>
      map(suppliedStakingInfo, tokenInfo => ({
        ...tokenInfo,
        thumbnail: getERC721Image({
          symbol: tokenInfo.symbol,
          tokenId: tokenInfo.tokenId
        })
      })),
    [suppliedStakingInfo, getERC721Image]
  );

  const apeStakingInfoList: StakeInfoListItem[] = useMemo(
    () =>
      balanceTokensInfo
        .filter(tokenInfo => tokenInfo.symbol !== ERC721Symbol.BAKC)
        .map(tokenInfo => {
          return {
            ...tokenInfo,
            pairedBakc:
              [...balanceTokensInfo, ...suppliedTokensInfo].find(
                item =>
                  tokenInfo.symbol === item.mainTokenSymbol &&
                  tokenInfo.tokenId === item.mainTokenId
              ) || null
          };
        }) as StakeInfoListItem[],
    [balanceTokensInfo, suppliedTokensInfo]
  );

  const bakcInfoList: StakeInfoListItem[] = useMemo(
    () =>
      balanceTokensInfo.filter(
        tokenInfo => tokenInfo.symbol === ERC721Symbol.BAKC
      ) as StakeInfoListItem[],
    [balanceTokensInfo]
  );

  const value = useMemo(
    () => ({
      stakingInfoList: apeStakingInfoList,
      stakingInfoLoaded,
      bakcInfoList,
      refresh
    }),
    [apeStakingInfoList, stakingInfoLoaded, bakcInfoList, refresh]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
