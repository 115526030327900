import { memo, useCallback, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  H4,
  Inline,
  SmallText,
  Spinner,
  Stack,
  StackProps,
  Text
} from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import { sumBy } from '@parallel-mono/utils';
import { mapKeys, mapValues } from 'lodash';
import styled from 'styled-components';
import { CryptoIcon } from '@parallel-mono/business-components';

import { ApeListItem, useApeListStatesAndActions } from '../../../ApeListProvider';
import { useV1TimelockApeInfo } from '../../../V1TimelockApeInfoProvider';
import { STAKE_LIMIT } from '../../../../consts';
import { assignCapeAndApeToBakcs } from '../utils';
import { BakcStakeInfo } from '../type';
import { MIN_STAKE_CAPE } from '../consts';

import { TokenSelector } from './TokenSelector';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { NftToken } from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';
import { formatBalance } from '@/apps/paraspace/utils/format';

type StakeBakcsFormProps = Omit<StackProps, 'children' | 'onSubmit'> & {
  onSubmit: (bakcs: BakcStakeInfo[]) => void;
};

const MiniChip = styled(Inline)`
  padding: 0;
  background: ${({ theme }) => theme.skin.background.slot};
  border-radius: 10000px;
  padding-right: 0.5rem;
`;

export const StakeBakcsForm = memo(({ onSubmit, ...others }: StakeBakcsFormProps) => {
  const { apesInSuppliedExcludingInP2P } = useApeListStatesAndActions();
  const { timelockInfos, isLoading } = useV1TimelockApeInfo();
  const { erc20InfoMap } = useMMProvider();
  const cAPESuppliedAmount = erc20InfoMap[ERC20Symbol.CAPE].suppliedAmount ?? zero;

  const canStakeBakcs = useMemo(
    () =>
      apesInSuppliedExcludingInP2P
        .filter(it => it.symbol === ERC721Symbol.BAKC)
        .map(it => ({
          ...it,
          stakeLimit: new BigNumber(STAKE_LIMIT[it.symbol])
        }))
        .filter(it => {
          return !timelockInfos.some(
            timelockInfo => timelockInfo.symbol === it.symbol && timelockInfo.tokenId === it.tokenId
          );
        })
        .filter(it => {
          return it.stakeLimit.gt(it.stakedAmount ?? 0);
        }),
    [apesInSuppliedExcludingInP2P, timelockInfos]
  );

  const canPairApes = useMemo(() => {
    return apesInSuppliedExcludingInP2P
      .filter(it => [ERC721Symbol.BAYC, ERC721Symbol.MAYC].includes(it.symbol))
      .filter(it => !it.pairedBakc)
      .filter(it => {
        return !timelockInfos.some(
          timelockInfo => timelockInfo.symbol === it.symbol && timelockInfo.tokenId === it.tokenId
        );
      });
  }, [apesInSuppliedExcludingInP2P, timelockInfos]);

  const [selectedBakcs, setSelectedBakcs] = useState<ApeListItem[]>([]);
  const handleSelectedBakcsChange = useCallback(
    (selectedApeTokens: Maybe<NftToken[] | NftToken>) => {
      setSelectedBakcs((selectedApeTokens ?? []) as ApeListItem[]);
    },
    []
  );

  const [selectedApes, setSelectedApes] = useState<ApeListItem[]>([]);
  const handleSelectedApesChange = useCallback(
    (selectedApeTokens: Maybe<NftToken[] | NftToken>) => {
      setSelectedApes((selectedApeTokens ?? []) as ApeListItem[]);
    },
    []
  );

  const handleStakeAll = useCallback(() => {
    const assignedBakcs = assignCapeAndApeToBakcs(canStakeBakcs, cAPESuppliedAmount, canPairApes);
    setSelectedBakcs(
      assignedBakcs.map(it => canStakeBakcs.find(bakc => bakc.tokenId === it.bakcTokenId)!)
    );
    setSelectedApes(
      assignedBakcs.map(it => canPairApes.find(ape => ape.tokenId === it.apeTokenId)!)
    );
  }, [cAPESuppliedAmount, canPairApes, canStakeBakcs]);

  const handleUnstakeAll = useCallback(() => {
    setSelectedBakcs([]);
    setSelectedApes([]);
  }, []);

  const assignedBakcs = useMemo(
    () => assignCapeAndApeToBakcs(selectedBakcs, cAPESuppliedAmount, selectedApes),
    [selectedBakcs, selectedApes, cAPESuppliedAmount]
  );

  const usedCape = sumBy(assignedBakcs, it => it.stakeAmount);

  const stakeAmountMap = useMemo(() => {
    return mapValues(
      mapKeys(assignedBakcs, it => it.bakcTokenId),
      it => it.stakeAmount
    );
  }, [assignedBakcs]);

  const enrichedBakcList = useMemo(() => {
    return canStakeBakcs.map(it => ({
      ...it,
      desc: (
        <MiniChip gap="0.25rem">
          <CryptoIcon symbol="CAPE" size="1.2rem" />
          <SmallText>{formatBalance(stakeAmountMap[it.tokenId])}</SmallText>
        </MiniChip>
      )
    }));
  }, [canStakeBakcs, stakeAmountMap]);

  const handleSubmit = useCallback(() => {
    onSubmit(assignedBakcs);
  }, [assignedBakcs, onSubmit]);

  const errors = useMemo(() => {
    if (selectedBakcs.length === 0 && selectedApes.length === 0) {
      return null;
    }

    if (
      assignedBakcs.length !== selectedBakcs.length ||
      selectedBakcs.length !== selectedApes.length ||
      assignedBakcs.length === 0
    ) {
      return 'Unable to match all BAKCs and BAYCs / MAYCs';
    }

    return null;
  }, [selectedBakcs, assignedBakcs, selectedApes]);

  const disabled = useMemo(() => {
    return !!errors || (selectedBakcs.length === 0 && selectedApes.length === 0);
  }, [errors, selectedBakcs, selectedApes]);

  const remainingCape = useMemo(
    () => (usedCape.gt(cAPESuppliedAmount) ? zero : cAPESuppliedAmount.minus(usedCape)),
    [usedCape, cAPESuppliedAmount]
  );

  const allAssigned =
    remainingCape.lt(MIN_STAKE_CAPE) ||
    (selectedBakcs.length === canStakeBakcs.length &&
      selectedApes.length === selectedBakcs.length) ||
    (selectedApes.length === canPairApes.length && selectedApes.length === selectedBakcs.length);

  return (
    <Stack {...others}>
      {!isLoading && (
        <Stack gap="0.25rem">
          <H4>BAKC</H4>
          <Card border inset="1rem 0">
            <TokenSelector
              multiselect
              tokenList={enrichedBakcList}
              selectedToken={selectedBakcs}
              onChange={handleSelectedBakcsChange}
              noDataMessage={
                <>
                  <Text fontWeight="bold">Sad...</Text>
                  <Text skin="secondary">no available BAKC found</Text>
                </>
              }
            />
          </Card>
        </Stack>
      )}
      {!isLoading && (
        <Stack gap="0.25rem">
          <H4>BAYC / MAYC</H4>
          <Card border inset="1rem 0">
            <TokenSelector
              multiselect
              tokenList={canPairApes}
              selectedToken={selectedApes}
              onChange={handleSelectedApesChange}
              noDataMessage={
                <>
                  <Text fontWeight="bold">Sad...</Text>
                  <Text skin="secondary">no available MAYC / BAYC found</Text>
                </>
              }
            />
          </Card>
        </Stack>
      )}
      {isLoading && (
        <Inline justifyContent="center">
          <Spinner size="large" />
        </Inline>
      )}
      <H4>Ava cAPE position: {formatBalance(remainingCape)}</H4>
      {errors && <Alert type="error">{errors}</Alert>}
      <Inline>
        <Button
          skin="secondary"
          loading={isLoading}
          disabled={isLoading}
          onClick={allAssigned ? handleUnstakeAll : handleStakeAll}
        >
          {allAssigned ? 'Unstake All' : 'Stake All'}
        </Button>
        <Button disabled={disabled || isLoading} loading={isLoading} block onClick={handleSubmit}>
          Re-stake ({selectedBakcs.length})
        </Button>
      </Inline>
    </Stack>
  );
});
