import { Skin, Tag, Text } from '@parallel-mono/components';
import { useMemo } from 'react';
import styled from 'styled-components';

import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { Link, Tooltip } from '@/apps/paraspace/components';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { DEVELOPER_DOCS_LINK } from '@/apps/paraspace/consts/externalLinks';

const StyledTag = styled(Tag)`
  height: 2rem;
  cursor: pointer;
`;

const freeBadge = {
  tooltip:
    'You have no NFT liquidation risks since you’ve only borrowed APE coin to stake with your NFT.',
  text: 'Liquidation Free',
  tagSkin: 'success'
};

const APES_GROUP = [ERC20Symbol.APE, ERC20Symbol.CAPE, ERC20Symbol.SAPE];

export const LiquidationTag = () => {
  const { erc20InfoMap } = useMMProvider();
  const { erc20Config } = useAppConfig();
  const hasPosition = useMemo(() => {
    // if user has erc20 positions except APE
    const hasNonApeBorrowPos = (Object.keys(erc20Config) as ERC20Symbol[]).some(key => {
      if (APES_GROUP.includes(key)) {
        return false;
      }

      const token = erc20InfoMap[key];
      return token && token.borrowedAmount?.gt(0);
    });

    if (hasNonApeBorrowPos) {
      return true;
    }

    const ape = erc20InfoMap[ERC20Symbol.APE];
    const cApe = erc20InfoMap[ERC20Symbol.CAPE];
    const sApe = erc20InfoMap[ERC20Symbol.SAPE];

    const totalSupplied = sApe?.suppliedAmount?.plus(cApe?.suppliedAmount ?? 0);
    const totalBorrowed = ape?.borrowedAmount?.plus(cApe?.borrowedAmount ?? 0);

    return totalBorrowed?.gt(totalSupplied ?? 0);
  }, [erc20InfoMap, erc20Config]);

  const { tooltip, text, tagSkin } = freeBadge;

  if (hasPosition) {
    return null;
  }

  return (
    <Tooltip
      placement="bottom"
      content={
        <Text>
          {tooltip}{' '}
          <Link
            href={`${DEVELOPER_DOCS_LINK}/staking-and-derivative-token-yield-management/apecoin-staking-with-bored-ape-yacht-club-nfts#sape-and-re-staking-rewards`}
            target="_blank"
          >
            Learn more
          </Link>
        </Text>
      }
    >
      <StyledTag skin={tagSkin as Skin} size="medium">
        {text}
      </StyledTag>
    </Tooltip>
  );
};
