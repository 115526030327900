import { FC, memo, useCallback } from 'react';
import { Inline } from '@parallel-mono/components';

import { Filter } from '../../types';

import { CommonFilters } from './CommonFilters';

import { FilterPanelProps } from '.';

import { MobileDrawer } from '@/apps/paraspace/components';

export const MobileFilterPanel: FC<FilterPanelProps> = memo(
  ({ opened, onCloseFilterPanel, onFilterChange, ...others }) => {
    const handleFilterChange = useCallback(
      (filter: Filter) => {
        onCloseFilterPanel();
        onFilterChange(filter);
      },
      [onCloseFilterPanel, onFilterChange]
    );
    return (
      <MobileDrawer
        isOpen={opened}
        onClick={() => null}
        onClose={onCloseFilterPanel}
        onTouchEnd={onCloseFilterPanel}
      >
        <Inline inset="0 1rem" width="100vw">
          <CommonFilters width="100%" onFilterChange={handleFilterChange} {...others} />
        </Inline>
      </MobileDrawer>
    );
  }
);
