import { useState } from 'react';
import BigNumber from 'bignumber.js';

import { CheckedOffer, Offer } from '../types';
import useUserPositions from '../../hooks/useUserPositions';
import { useTokensInfo } from '../../contexts/TokensInfoProvider';
import { DEFAULT_MULTIPLIER } from '../../config';

import { useStabilizedSnapshot } from '@/apps/paraspace/hooks/useStabilizedSnapshot';
import { convertToChecksumAddress } from '@/apps/paraspace/utils/convertToChecksumAddress';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { shiftedLeftBy } from '@/apps/paraspace/utils/calculations';
import { zero } from '@/apps/paraspace/consts/values';

export const useOffersMakerInfos = (offers: Offer[]) => {
  const [isLoading, setIsLoading] = useState(false);
  const [offersWithMakerInfo, setOffersWithMakerInfo] = useState<CheckedOffer[]>(
    offers.map(v => ({ ...v, isMakerInsufficientBorrowLimit: false, isMakerUnhealthy: false }))
  );

  const { loadUserPositions } = useUserPositions();
  const { tokensInfo } = useTokensInfo();

  const stabilizedOffers = useStabilizedSnapshot(offers);

  useAsyncEffect(async () => {
    setIsLoading(true);
    try {
      const res = await Promise.all(
        stabilizedOffers.map(async order => {
          const makerInfo = await loadUserPositions(order.maker);
          const tokenTraitMultiplier = makerInfo?.userReservesData
            .find(
              it =>
                convertToChecksumAddress(it.underlyingAsset) ===
                convertToChecksumAddress(order.considerationItem.contractAddress)
            )
            ?.tokenTraitMultipliers.get(`${order.considerationItem.tokenId}`);
          const additionalBorrowLimit =
            tokensInfo?.[order.considerationItem.symbol].priceInUsd
              .times(
                tokenTraitMultiplier ? shiftedLeftBy(tokenTraitMultiplier, 18) : DEFAULT_MULTIPLIER
              )
              .times(tokensInfo?.[order.considerationItem.symbol].baseLTVasCollateral) ?? zero;

          // makerAvailableBorrowLimit can be a negative number
          const makerAvailableBorrowLimit = BigNumber(makerInfo?.totalCollateralUSD ?? zero)
            .times(makerInfo?.currentLoanToValue ?? zero)
            .minus(makerInfo?.totalBorrowsUSD ?? zero);

          return {
            ...order,
            isMakerInsufficientBorrowLimit: BigNumber(order.creditData?.amount ?? 0).gt(0)
              ? order.creditData?.priceInUsd.gt(
                  BigNumber(makerAvailableBorrowLimit).plus(additionalBorrowLimit)
                ) ?? false
              : false,
            isMakerUnhealthy: BigNumber(order.creditData?.amount ?? 0).gt(0)
              ? new BigNumber(makerInfo?.healthFactor ?? 0).lt(1) &&
                !new BigNumber(makerInfo?.healthFactor ?? 0).eq(-1) // when borrowBalanceMarketReferenceCurrency is 0, the hf is -1
              : false
          };
        })
      );
      setOffersWithMakerInfo(res);
    } catch (e) {
      console.error(`Failed to fetch the offer maker's data, error: ${e}`);
      setOffersWithMakerInfo(
        stabilizedOffers.map(v => ({
          ...v,
          isMakerUnhealthy: false,
          isMakerInsufficientBorrowLimit: false
        }))
      );
    } finally {
      setIsLoading(false);
    }
  }, [stabilizedOffers]);

  return {
    isLoading,
    offers: offersWithMakerInfo
  };
};
