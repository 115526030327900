import { Spinner, Stack, H5, Text, Image } from '@parallel-mono/components';
import styled from 'styled-components';
import { FC, ReactNode, memo } from 'react';

import metamask from '@/apps/paraspace/assets/meta-mask.svg';

const StyledSpinner = styled(Spinner)`
  margin-bottom: 1rem;
`;

export const ApproveWallet: FC<{ tips?: ReactNode }> = memo(({ tips }) => (
  <Stack alignItems="center" gap="1rem">
    <Image src={metamask} width="2rem" height="2rem" />
    {tips ?? <H5>Please approve on your wallet.</H5>}
  </Stack>
));

const StyledText = styled(Text)`
  text-align: center;
`;

export const InProgress = ({ tip }: { tip: string }) => {
  return (
    <Stack gap="0.25rem" alignItems="center">
      <StyledSpinner size="large" />
      <H5>{tip}</H5>
      <StyledText skin="secondary">
        This may take up to 20 seconds. Please do not close the modal.
      </StyledText>
    </Stack>
  );
};
