import { HostedImage, MarketplaceIcon } from '@parallel-mono/business-components';
import { H6, Image } from '@parallel-mono/components';
import styled, { css } from 'styled-components';

export const ThumbnailLogoStyle = css`
  padding: 1px;
  background-color: #c4d2d8;
  border: 1px solid white;
  border-radius: 100px;
  width: 1.5rem;
  height: 1.5rem;
`;

export const StyledMarketplaceIcon = styled(MarketplaceIcon)`
  ${ThumbnailLogoStyle}
`;

export const StyledApeStakeIcon = styled(H6).attrs({
  fontWeight: 'medium'
})`
  ${ThumbnailLogoStyle}
  width: auto;
  height: auto;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(3)};
`;

export const StyledHostedImageIcon = styled(HostedImage)`
  ${ThumbnailLogoStyle}
  padding: ${({ theme }) => theme.spacing(1)}};
`;

export const StyledImageIcon = styled(Image)`
  ${ThumbnailLogoStyle}
  padding: ${({ theme }) => theme.spacing(1)}};
`;
