import { memo } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { PairApeForm } from './PairApeForm';
import { PairApeFormSubmitter } from './PairApeFormSubmitter';
import { SuccessState, SuccessStateProps } from './SuccessState';

import {
  ErrorState,
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { ApeStakingTokenSymbol, WalletType } from '@/apps/paraspace/typings';

export type StakeBakcFormData = {
  tokenId: number;
  symbol: ApeStakingTokenSymbol;
  bakcTokenId: number;
  amountFromBalance: number;
  apeSource: WalletType;
  apeCoinSource: WalletType;
  bakcSource: WalletType;
};

export type StakeBakcModalProps = {
  bakcTokenId: number;
  bakcSource: WalletType;
  apeCoinSource: WalletType;
  onClose: () => void;
} & Omit<ModalProps, 'children' | 'onClose'>;

export const StakeBakcModal = memo(
  ({ bakcTokenId, onClose, isOpen, bakcSource, apeCoinSource, ...others }: StakeBakcModalProps) => {
    const { phase, submittedFormData, handleFormSubmit, handleSubmitFailed, handleSubmitSuccess } =
      useCollectAndSubmitProcedure<StakeBakcFormData, void, void>({
        running: isOpen
      });

    return (
      <Modal isOpen={isOpen} title="Stake" onClose={onClose} {...others}>
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <PairApeForm
            onSubmit={handleFormSubmit}
            bakcTokenId={bakcTokenId}
            bakcSource={bakcSource}
            apeCoinSource={apeCoinSource}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <PairApeFormSubmitter
            formData={submittedFormData!}
            onFinish={handleSubmitSuccess}
            onError={handleSubmitFailed}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState
            bakcTokenId={bakcTokenId}
            info={submittedFormData as SuccessStateProps['info']}
            onFinish={onClose}
          />
        )}
      </Modal>
    );
  }
);
