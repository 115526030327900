import { Button, H6, Icon, Inline, Text, Tooltip } from '@parallel-mono/components';
import { memo, useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';

import {
  useApeListStatesAndActions,
  useApeStakeManager,
  useV1TimelockApeInfo
} from '../../../../contexts';
import { STAKE_LIMIT } from '../../../../consts';

import PanelCard from './PanelCard';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { zero } from '@/apps/paraspace/consts/values';

const MIN_CAPE = 1;

const RawReStakePanel = memo(() => {
  const { setReStake } = useApeStakeManager();

  const handleReStake = useCallback(() => {
    setReStake();
  }, [setReStake]);

  const { isLoading, timelockInfos } = useV1TimelockApeInfo();
  const { erc20InfoMap } = useMMProvider();
  const { apesInSuppliedExcludingInP2P } = useApeListStatesAndActions();
  const canStakeApes = apesInSuppliedExcludingInP2P
    .map(it => ({
      ...it,
      stakeLimit: new BigNumber(STAKE_LIMIT[it.symbol])
    }))
    .filter(it => {
      return (
        it.stakeLimit.gt(it.stakedAmount ?? 0) &&
        !timelockInfos.some(
          timelockInfo => timelockInfo.symbol === it.symbol && timelockInfo.tokenId === it.tokenId
        )
      );
    });
  const cApeSupply = erc20InfoMap[ERC20Symbol.CAPE].suppliedAmount ?? zero;

  const canReStake = useMemo(
    () => canStakeApes.length > 0 && cApeSupply?.gte(MIN_CAPE) && !isLoading,
    [canStakeApes, cApeSupply, isLoading]
  );

  const noApesToReStake = canStakeApes.length === 0 && timelockInfos.length === 0;

  const disableTip = useMemo(() => {
    if (cApeSupply.lt(MIN_CAPE)) {
      return "You don't have enough cAPE position to Re-stake";
    }
    if (noApesToReStake) {
      return "You don't have any NFT to Re-stake";
    }
    return 'Your NFTs are still in ParaSpace V1 Timelock';
  }, [cApeSupply, noApesToReStake]);

  if (cApeSupply.lt(MIN_CAPE) || noApesToReStake) {
    return null;
  }

  return (
    <PanelCard
      highLight
      title={
        <Inline gap="0.5rem">
          <Icon name="coins" size="1.25rem" />
          <H6>Re-Stake</H6>
        </Inline>
      }
      coreContent={
        <Text>
          Your Apes are currently in ParaSpace V1 timelock. After unlocking, you can re-stake them
          with cAPE supplied position.
        </Text>
      }
      actionButton={
        <Tooltip content={disableTip} disabled={canReStake}>
          <Button
            block
            onClick={handleReStake}
            skin="primary"
            disabled={!canReStake}
            loading={isLoading}
          >
            Re-Stake
          </Button>
        </Tooltip>
      }
    />
  );
});

const ReStakePanel = memo(() => {
  const { userInfoLoaded } = useMMProvider();

  if (!userInfoLoaded) {
    return null;
  }

  return <RawReStakePanel />;
});

export default ReStakePanel;
