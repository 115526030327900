import { Inline, Stack, H2, Button, Text } from '@parallel-mono/components';
import { memo, useContext } from 'react';
import styled from 'styled-components';

import EllipseImg from '../image/ellipse.svg';

import { Context } from '.';

const Diamond = styled(Inline).attrs({ alignItems: 'center', justifyContent: 'center' })`
  flex-shrink: 0;
  width: 11.625rem;
  height: 11.625rem;
  font-size: 5rem;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 100%;
    height: 100%;
    background: url(${EllipseImg}) no-repeat;
    content: '';
  }
`;

interface IProps {
  handleStatus: () => void;
  inToggleNesting: boolean;
}

const NoNestingBase = ({ handleStatus, inToggleNesting }: IProps) => {
  const { isOwner } = useContext(Context);
  return (
    <Inline gap="2.5rem" alignItems="center">
      <Diamond>💎</Diamond>
      <Stack gap="1rem">
        <Stack gap=".25rem">
          <H2>Nest on ParaSpace</H2>
          <Text skin="secondary">You can nest your Moonbird while supplying on ParaSpace.</Text>
        </Stack>
        <Button skin="secondary" onClick={handleStatus} disabled={inToggleNesting || !isOwner}>
          Nest
        </Button>
      </Stack>
    </Inline>
  );
};

export const NoNesting = memo(NoNestingBase);
