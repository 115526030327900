import { Button, ButtonProps } from '@parallel-mono/components';
import { memo } from 'react';

import { useWeb3Context } from '@/apps/paraspace/contexts';

type ShareRankButtonProps = ButtonProps & {
  isLoading: boolean;
  twitterContent: string;
  hashtags: string;
};

export const ShareRankButton = memo(
  ({ isLoading, twitterContent, hashtags, ...props }: ShareRankButtonProps) => {
    const { isUsingUserWallet } = useWeb3Context();

    return isUsingUserWallet ? (
      <Button
        block
        size="large"
        disabled={isLoading}
        onClick={() =>
          window.open(
            `https://twitter.com/intent/tweet?text=${twitterContent}&hashtags=${hashtags}`,
            '_blank',
            'popup=true,width=648,height=707'
          )
        }
        {...props}
      >
        Share My Rank
      </Button>
    ) : null;
  }
);
