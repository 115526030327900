import { Inline, InlineProps, useBreakpoints } from '@parallel-mono/components';
import { FC, RefObject, memo } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { RightHeader } from './RightHeader';
import { LeftHeader } from './LeftHeader';
import { MobileHeader } from './mobile';
import { BackdropFilter } from './components';
import { AppSelectorProvider } from './contexts';

const StyledHeaderBar = styled(Inline)<{ height?: CSSProperties['height'] }>`
  width: 100%;
  height: ${({ height }) => `${height}px` ?? 'auto'};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10000;
  backdrop-filter: blur(6px);
`;

export const HEADER_HEIGHT = 116; // unit px

export const Header: FC<
  Omit<InlineProps, 'children'> & {
    bodyContainer: RefObject<HTMLElement>;
  }
> = memo(({ bodyContainer, ...others }) => {
  const { desktop } = useBreakpoints();

  return (
    <AppSelectorProvider>
      <StyledHeaderBar
        justifyContent="space-between"
        inset={desktop ? '0 4.5rem' : '0.75rem 1rem'}
        height={HEADER_HEIGHT}
        {...others}
      >
        {desktop ? (
          <>
            <LeftHeader />
            <RightHeader />
          </>
        ) : (
          <MobileHeader />
        )}
        <BackdropFilter bodyContainer={bodyContainer} />
      </StyledHeaderBar>
    </AppSelectorProvider>
  );
});
