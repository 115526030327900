import { floor } from 'lodash';
import { FC, RefObject, memo } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { useScroll } from 'react-use';

import bg from '../images/nav-bg.svg';
import mobileBg from '../images/mobile-nav-bg.svg';
import { HEADER_HEIGHT } from '..';

const BackgroundWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.skin.background.main};
  background-image: url(${bg});
  ${({ theme }) => theme.breakpoints.only('mobile')`
    background-image: url(${mobileBg});
  `}

  background-size: cover;
  z-index: -1;
`;

const DEFAULT_HEADER_OPACITY = 0.85;

const useBackdropFilter = (scroller: RefObject<HTMLElement>) => {
  const { y: scrollY } = useScroll(scroller);

  const opacity = floor(DEFAULT_HEADER_OPACITY * (scrollY / HEADER_HEIGHT), 2);
  const headerBgOpacity = opacity > DEFAULT_HEADER_OPACITY ? DEFAULT_HEADER_OPACITY : opacity;

  return headerBgOpacity;
};

export const BackdropFilter: FC<{
  bodyContainer: RefObject<HTMLElement>;
}> = memo(({ bodyContainer, ...others }) => {
  const opacity = useBackdropFilter(bodyContainer);

  const animation = useSpring({
    opacity
  });

  return <BackgroundWrapper as={animated.div} style={animation} {...others} />;
});
