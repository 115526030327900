import BigNumberJS from 'bignumber.js';
import { useCallback, useMemo, useState } from 'react';

import { useApeBorrowLimit } from '../../../hooks/useApeBorrowLimit';

import { zero } from '@/apps/paraspace/consts/values';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/ApePairing/consts';
import { MAXIMUM_BALANCE_DECIMALS, MAX_POOL_UTILIZATION } from '@/apps/paraspace/pages/config';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol, ERC721Symbol, WalletType } from '@/apps/paraspace/typings';
import { ApeListItem } from '@/apps/paraspace/pages/ApePairing/contexts';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useEOABalances } from '@/apps/paraspace/pages/contexts';

const stakeLimit = BigNumberJS(STAKE_LIMIT.BAKC);

export const useBalanceAndCreditAmount = (
  apeToken: Maybe<ApeListItem>,
  apeCoinSource: WalletType,
  bakcToken?: Maybe<ApeListItem>
) => {
  const [amountFromBalance, setBalanceAmount] = useState<Maybe<number>>(null);
  const [amountFromCredit, setCreditAmount] = useState<Maybe<number>>(null);

  const handleBalanceAmountChange = useCallback(amount => setBalanceAmount(amount), []);
  const handleCreditAmountChange = useCallback(amount => setCreditAmount(amount), []);

  const {
    erc20InfoMap: {
      [ERC20Symbol.APE]: { balance: aaBalance = null },
      [ERC20Symbol.CAPE]: { availableLiquidity: apeLiquidity, totalDebt }
    }
  } = useMMProvider();

  const { erc20BalanceMap } = useEOABalances();

  const eoaBalance = erc20BalanceMap?.[ERC20Symbol.APE] ?? zero;

  const balance = apeCoinSource === 'AA' ? aaBalance : eoaBalance;

  const borrowLimit = useApeBorrowLimit({
    symbol: apeToken?.symbol ?? ERC721Symbol.BAYC,
    tokenId: apeToken?.tokenId ?? 0,
    balance: amountFromBalance,
    bakcTokenId: bakcToken?.tokenId ?? 0
  });

  // temporary restrictions on ape borrowing
  const restrictToBorrowApe = totalDebt.div(apeLiquidity.plus(totalDebt)).gte(MAX_POOL_UTILIZATION);
  const availableToBorrowApe = useMemo(
    () => (restrictToBorrowApe ? zero : borrowLimit),
    [borrowLimit, restrictToBorrowApe]
  );

  const newStakeLimit = useMemo(
    () => stakeLimit.minus(bakcToken?.stakedAmount ?? zero),
    [bakcToken?.stakedAmount]
  );

  const maxAmountFromCredit = useMemo(
    () =>
      BigNumberJS.max(
        BigNumberJS.min(
          availableToBorrowApe,
          newStakeLimit.minus(amountFromBalance ?? 0),
          apeLiquidity
        ),
        0
      ),
    [availableToBorrowApe, newStakeLimit, amountFromBalance, apeLiquidity]
  );

  const maxAmountFromBalance = useMemo(
    () =>
      BigNumberJS.max(BigNumberJS.min(newStakeLimit.minus(amountFromCredit ?? 0), balance ?? 0), 0),
    [amountFromCredit, balance, newStakeLimit]
  );

  const handleMaxAmountFromCredit = useCallback(() => {
    setCreditAmount(
      maxAmountFromCredit
        .decimalPlaces(MAXIMUM_BALANCE_DECIMALS, BigNumberJS.ROUND_FLOOR)
        .toNumber()
    );
  }, [maxAmountFromCredit]);

  const handleMaxAmountFromBalance = useCallback(() => {
    setBalanceAmount(
      maxAmountFromBalance
        .decimalPlaces(MAXIMUM_BALANCE_DECIMALS, BigNumberJS.ROUND_FLOOR)
        .toNumber()
    );
  }, [maxAmountFromBalance]);

  return {
    handleBalanceAmountChange,
    handleCreditAmountChange,
    amountFromBalance,
    amountFromCredit,
    handleMaxAmountFromCredit,
    handleMaxAmountFromBalance,
    maxAmountFromBalance,
    maxAmountFromCredit,
    borrowLimit,
    cApeLiquidity: apeLiquidity,
    apeCoinBalance: balance,
    restrictToBorrowApe
  };
};
