import { Button, Icon, Stack } from '@parallel-mono/components';
import styled from 'styled-components';
import { memo, useCallback, useState } from 'react';
import {
  TokenOption,
  Value
} from '@parallel-mono/business-components/components/SelectableTokenInput/types';

import { UnwrapWETHFormData } from '.';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { SelectableTokenInput } from '@/apps/paraspace/components';
import { formatBalance } from '@/apps/paraspace/utils/format';

const StyledIcon = styled(Icon)`
  align-self: center;
`;

export const UnwrapWETHForm = memo(
  ({ onSubmit }: { onSubmit: (formData: UnwrapWETHFormData) => void }) => {
    const [wethAmount, setWEthAmount] = useState<number | null>(null);

    const { erc20InfoMap } = useMMProvider();

    const handleWithdraw = useCallback(() => {
      if (!wethAmount) return;
      onSubmit({ amount: wethAmount });
    }, [wethAmount, onSubmit]);

    const { priceInUsd, balance } = erc20InfoMap[ERC20Symbol.WETH] || {};

    const handleETHMaxClick = () => {
      setWEthAmount(balance?.toNumber() ?? 0);
    };

    const handleWETHChange = ({ amount }: Value<TokenOption>) => {
      if (amount && balance && balance?.lte(amount)) {
        handleETHMaxClick();
        return;
      }
      setWEthAmount(amount ?? 0);
    };

    return (
      <Stack>
        <SelectableTokenInput
          tokens={[
            {
              name: 'WETH',
              symbol: ERC20Symbol.WETH,
              balance: balance?.toNumber() ?? 0,
              priceInUSD: priceInUsd.toNumber()
            }
          ]}
          value={{
            token: {
              name: 'WETH',
              symbol: ERC20Symbol.WETH,
              balance: balance?.toNumber() ?? 0,
              priceInUSD: priceInUsd.toNumber(),
              displayBalance: `${formatBalance(balance)} ETH`
            },
            amount: wethAmount
          }}
          actionButtonText="Max"
          decimals={MAXIMUM_BALANCE_DECIMALS}
          onActionButtonClicked={handleETHMaxClick}
          onChange={handleWETHChange}
        />
        <StyledIcon name="chevronDown" />
        <SelectableTokenInput
          tokens={[
            {
              name: 'ETH',
              symbol: ERC20Symbol.ETH,
              balance: balance?.toNumber() ?? 0,
              priceInUSD: priceInUsd.toNumber()
            }
          ]}
          value={{
            token: {
              name: 'ETH',
              symbol: ERC20Symbol.ETH,
              balance: balance?.toNumber() ?? 0,
              priceInUSD: priceInUsd.toNumber()
            },
            amount: wethAmount
          }}
          decimals={MAXIMUM_BALANCE_DECIMALS}
          onChange={handleWETHChange}
        />
        <Button
          size="large"
          block
          onClick={handleWithdraw}
          disabled={!wethAmount || wethAmount <= 0}
        >
          Unwrap
        </Button>
      </Stack>
    );
  }
);
