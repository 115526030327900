import { pick } from 'lodash';
import { Network } from 'paraspace-configs-v2';
import {
  ERC20Symbol,
  parallelERC20Configs,
  parallelTestnetERC20Configs
} from '@parallel-utils/contracts-registry';

export type WalletERC20Config = {
  symbol: string;
  address: string;
  isWrappedNativeToken?: boolean;
  decimals: number;
};
export const ERC20_CONFIG_FOR_PARALLEL_TESTNET = {
  ...pick(parallelTestnetERC20Configs, [
    ERC20Symbol.WETH,
    ERC20Symbol.USDC,
    ERC20Symbol.DAI,
    ERC20Symbol.WBTC,
    ERC20Symbol.USDT,
    ERC20Symbol.wstETH,
    ERC20Symbol.rETH,
    ERC20Symbol.swETH,
    ERC20Symbol.ETHx,
    ERC20Symbol.LSETH,
    ERC20Symbol.OETH,
    ERC20Symbol.uniETH,
    ERC20Symbol.osETH,
    ERC20Symbol.ankrETH,
    ERC20Symbol.rsETH,
    ERC20Symbol.ezETH,
    ERC20Symbol.rswETH,
    ERC20Symbol.weETH,
    ERC20Symbol.pufETH,
    ERC20Symbol.LINK,
    ERC20Symbol.AAVE
    // ERC20Symbol.ARB,
    // ERC20Symbol.PT_eETH,
    // ERC20Symbol.PT_ezETH,
    // ERC20Symbol.PT_rsETH,
    // ERC20Symbol.wUSDM,
    // ERC20Symbol.MANTA,
    // ERC20Symbol.STONE,
  ])
};

export const ERC20_CONFIG_FOR_PARALLEL_MAINNET = {
  ...pick(parallelERC20Configs, [
    ERC20Symbol.WETH,
    ERC20Symbol.WBTC,
    ERC20Symbol.DAI,
    ERC20Symbol.USDC,
    ERC20Symbol.USDT,
    ERC20Symbol.USDe,
    ERC20Symbol.ankrETH,
    ERC20Symbol.rsETH,
    ERC20Symbol.ezETH,
    ERC20Symbol.rswETH,
    ERC20Symbol.weETH,
    ERC20Symbol.pufETH,
    ERC20Symbol.wstETH,
    ERC20Symbol.rETH,
    ERC20Symbol.swETH,
    ERC20Symbol.ETHx,
    ERC20Symbol.LSETH,
    ERC20Symbol.OETH,
    ERC20Symbol.uniETH,
    ERC20Symbol.osETH,
    ERC20Symbol.PT_eETH,
    ERC20Symbol.PT_ezETH,
    ERC20Symbol.PT_rsETH,
    ERC20Symbol.ARB,
    ERC20Symbol.wUSDM,
    ERC20Symbol.MANTA,
    ERC20Symbol.STONE,
    ERC20Symbol.M_STONE,
    ERC20Symbol.SolvBTC
  ])
};
export const ERC20_CONFIG_FOR_PARALLEL_NETWORK: Record<
  Network.PARALLEL_L3_TESTNET | Network.PARALLEL,
  Record<string, WalletERC20Config>
> = {
  [Network.PARALLEL_L3_TESTNET]: ERC20_CONFIG_FOR_PARALLEL_TESTNET,
  [Network.PARALLEL]: ERC20_CONFIG_FOR_PARALLEL_MAINNET
};
