import { memo, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import { useMount } from 'react-use';

import { ApproveWallet, InProgress } from '@/apps/paraspace/components';
import { ApeStakingMainAssetSymbol, ERC20Symbol } from '@/apps/paraspace/typings';
import useP2PPairStaking, {
  CreateListingReturnType,
  P2PListing,
  StakingType
} from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { ErrorConfig, getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { FLOAT_SCALING_FACTOR } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useContractsMap } from '@/apps/paraspace/hooks';

type Props = {
  formData: {
    stakingType: StakingType;
    offerSymbol: ApeStakingMainAssetSymbol;
    wantedPercentage: BigNumber;
    duration: number;
    tokenId: number;
  };
  onFinish: (listingsData: CreateListingReturnType) => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const SignListingSubmitter = memo(
  ({
    formData: { stakingType, offerSymbol, tokenId, wantedPercentage, duration },
    onFinish,
    onError
  }: Props) => {
    const { erc20InfoMap } = useMMProvider();
    const [signing, setSigning] = useState(false);
    const { createListing } = useP2PPairStaking();
    const { account } = useWeb3Context();
    const contracts = useContractsMap();
    const assetAddress =
      offerSymbol === ERC20Symbol.APE ? erc20InfoMap?.CAPE?.address : contracts[offerSymbol];

    const listingInfo: P2PListing = useMemo(
      () => ({
        stakingType,
        offerer: account,
        token: assetAddress,
        share: wantedPercentage.multipliedBy(FLOAT_SCALING_FACTOR).decimalPlaces(0).toNumber(),
        startTime: dayjs().subtract(30, 's').unix(),
        tokenId,
        endTime: dayjs().add(duration, 'h').unix()
      }),
      [account, assetAddress, duration, stakingType, tokenId, wantedPercentage]
    );

    useMount(() => {
      setSigning(true);
      createListing(listingInfo)
        .then(res => {
          onFinish(res!);
        })
        .catch(err => {
          const errConfig = getUserFriendlyError<ErrorConfig>(err, undefined, 'errObj');
          onError(errConfig ?? null);
          throw getUserFriendlyError(err);
        });
    });

    return signing ? <InProgress tip="Signing Securely" /> : <ApproveWallet />;
  }
);
