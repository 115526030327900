import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { BRIDGE_SERVICE } from '../../configs';

import { useEOAProvider } from '@/apps/parax/contexts';
import { Maybe } from '@/apps/parax/typings/basic';

export const usePendingActivities = () => {
  const [pendingCount, setPendingCount] = useState<Maybe<number>>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { account } = useEOAProvider();

  const fetch = useCallback(async () => {
    if (account) {
      try {
        setIsLoading(true);
        const { data } = await axios.get<any>(
          `${BRIDGE_SERVICE}/activity/${account}/count?status=pending`
        );
        setPendingCount(data ? Number(data) : null);
      } catch (e) {
        console.error('Fetch activity counts failed, error: ', e);
      }
    }
  }, [account]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { count: pendingCount, isLoading, refetch: fetch };
};
