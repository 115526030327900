import { isNil, some } from 'lodash';
import { useState } from 'react';

import { useCheckApeStakedState } from '@/apps/paraspace/pages/hooks/ApeStaking/useCheckApeStakedState';
import { ApeStakingTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';

export const useShowApeStakingWarning = (
  nfts: {
    symbol: ERC721Symbol;
    tokenId?: number;
  }[]
) => {
  const checkApeStakedState = useCheckApeStakedState();
  const [showApeStakingWarning, setShowApeStakingWarning] = useState(true);

  useAsyncEffect(async () => {
    const apesAndBakc = nfts.filter(item => isNFTinvolvedWithApeStaking(item.symbol));
    if (apesAndBakc.length > 0) {
      const isForCollection = some(apesAndBakc, { tokenId: isNil });

      if (isForCollection) {
        setShowApeStakingWarning(true);
      } else {
        const status = await checkApeStakedState(
          apesAndBakc.map(item => ({
            symbol: item.symbol as ApeStakingTokenSymbol,
            tokenId: item.tokenId!,
            targetPool: item.symbol as ApeStakingTokenSymbol
          }))
        );
        setShowApeStakingWarning(some(status, { isStaked: true }));
      }
    } else {
      setShowApeStakingWarning(false);
    }
  }, [nfts]);

  return showApeStakingWarning;
};
