import { Inline } from '@parallel-mono/components';
import styled from 'styled-components';

import bg from './images/bg.svg';
import { ContentContainer } from './Content';

const Container = styled(Inline)`
  ${({ theme }) => theme.breakpoints.up('tablet')`
    height: 54rem;
    max-height: 54rem;
    background: url(${bg}) center no-repeat;
    background-size: cover;
  `};
  ${({ theme }) => theme.breakpoints.only('mobile')`
    height: calc(100vh - var(--header-height-pc));
  `};
  width: 100%;
  max-width: calc(var(--max-app-width-pc) - 10rem);
`;

export const ApeStakingNotConnect = () => {
  return (
    <Inline width="100%" justifyContent="center">
      <Container justifyContent="center">
        <ContentContainer />
      </Container>
    </Inline>
  );
};
