export type Duration = {
  label: string;
  value: number;
};

export const durationList = [
  { label: '1 day', value: 1 },
  { label: '5 days', value: 5 },
  { label: '10 days', value: 10 },
  { label: '30 days', value: 30 }
];
