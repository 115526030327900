import { useState } from 'react';
import BigNumber from 'bignumber.js';
import { useDebounce } from 'react-use';

import { useSupplyAndBorrowApy } from '@/apps/paraspace/pages/hooks/useSupplyAndBorrowApy';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useConvertSymbolWithNetwork } from '@/apps/paraspace/pages/hooks';

export const useDebouncedBorrowApy = ({
  amount,
  symbol,
  defaultValue
}: {
  amount: Maybe<number>;
  symbol: ERC20Symbol;
  defaultValue: BigNumber;
}) => {
  const { getBorrowApy } = useSupplyAndBorrowApy();
  const [borrowApy, setBorrowApy] = useState<Maybe<BigNumber>>(null);
  const amountAsStr = amount?.toString();
  const { convertNativeTokenToWToken } = useConvertSymbolWithNetwork();

  useDebounce(
    () => {
      const handle = async () => {
        if (amountAsStr) {
          setBorrowApy(null);
          const result = await getBorrowApy(convertNativeTokenToWToken(symbol), amountAsStr);
          setBorrowApy(result ? new BigNumber(result.newBorrowApy) : defaultValue);
          return;
        }
        setBorrowApy(defaultValue);
      };
      handle();
    },
    500,
    [amountAsStr, getBorrowApy, setBorrowApy, defaultValue, symbol]
  );

  return borrowApy;
};
