import dayjs from 'dayjs';
import { isNil } from 'lodash';

export enum TimelockEventState {
  CREATED,
  CLAIMED,
  FREEZED
}

export enum TimelockQueueState {
  RELEASED,
  CLAIMED,
  FREEZED,
  WAIT
}

export const getTimelockQueueState = (
  state: TimelockEventState,
  isContractFrozen: boolean,
  expectedRelease: Date,
  shouldTimelockCompareWithBatchBlock: boolean,
  batchBlockTime?: number
): TimelockQueueState => {
  if (state === TimelockEventState.FREEZED || isContractFrozen) return TimelockQueueState.FREEZED;

  if (shouldTimelockCompareWithBatchBlock && isNil(batchBlockTime)) {
    return TimelockQueueState.WAIT;
  }

  // For the some of the networks(e.g. zkSync), the released status is figure out by comparing the releaseTime and the batch block time.
  // The release time is after of the batch block time ==> WAIT to release
  // The release time is before of (or same with) the batch block time ==> RELEASED
  const comparingDate = batchBlockTime ? dayjs(batchBlockTime * 1000) : dayjs(new Date());
  const isReleased = comparingDate.isAfter(dayjs(expectedRelease));

  if (state === TimelockEventState.CREATED && !isReleased) return TimelockQueueState.WAIT;

  if (state === TimelockEventState.CREATED && isReleased) return TimelockQueueState.RELEASED;

  return TimelockQueueState.CLAIMED;
};
