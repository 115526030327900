import { Modal } from '@parallel-mono/components';
import BigNumber from 'bignumber.js';
import { useCallback } from 'react';
import ReactGA from 'react-ga4';

import SuccessState from '../component/CommonSuccess';

import WithdrawForm from './WithdrawForm';
import WithdrawFormSubmitter from './WithdrawFormSubmitter';

import { formatBalance } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ApeStakingMainTokenSymbol, ApeStakingTokenSymbol } from '@/apps/paraspace/typings';
import {
  ErrorState,
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';

export type WithdrawModalProps = {
  isOpen: boolean;
  symbol: Maybe<ApeStakingTokenSymbol>;
  tokenId: Maybe<number>;
  mainTokenId?: number;
  mainTokenSymbol?: ApeStakingMainTokenSymbol;
  stakedAmount?: BigNumber;
  pendingRewards?: BigNumber;
  onClose: () => void;
};

const WithdrawModal = ({
  symbol,
  tokenId,
  mainTokenId,
  mainTokenSymbol,
  stakedAmount,
  pendingRewards,
  onClose,
  isOpen,
  ...others
}: WithdrawModalProps) => {
  const handleFinish = useCallback((amount?: Maybe<number>) => {
    ReactGA.event({
      action: 'apestaking',
      category: 'nftstaking',
      label: 'unstake',
      value: amount ?? 0
    });
  }, []);

  const {
    phase,
    submittedFormData: withdrawAmount,
    handleFormSubmit,
    handleSubmitSuccess,
    handleSubmitFailed
  } = useCollectAndSubmitProcedure<number, number>({
    running: isOpen,
    onFinish: handleFinish
  });

  return (
    <Modal isOpen={isOpen} title="Withdraw" onClose={onClose} {...others}>
      {phase === CollectAndSubmitProcedurePhase.Collecting && (
        <WithdrawForm
          mainTokenId={mainTokenId}
          mainTokenSymbol={mainTokenSymbol}
          tokenId={tokenId!}
          symbol={symbol!}
          stakedValue={stakedAmount?.toNumber()}
          pendingRewards={pendingRewards?.toNumber()}
          onSubmit={handleFormSubmit}
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Submitting && (
        <WithdrawFormSubmitter
          formData={{
            symbol: symbol!,
            tokenId: tokenId!,
            mainTokenId,
            mainTokenSymbol,
            stakedValue: stakedAmount?.toNumber(),
            withdrawAmount: withdrawAmount!
          }}
          onFinish={handleSubmitSuccess}
          onError={handleSubmitFailed}
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
      {phase === CollectAndSubmitProcedurePhase.Success && (
        <SuccessState
          desc={`Successfully withdraw ${formatBalance(withdrawAmount)} APE coins.`}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};

export default WithdrawModal;
