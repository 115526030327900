// import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { cloneDeep, findIndex, isNil } from 'lodash';
import { BigNumber as EthersBigNumber } from 'ethers';
import { Environment } from 'parax-sdk';
import { ethereumChainConfig, sepoliaChainConfig } from '@parallel-utils/contracts-registry';
import axios from 'axios';
import { ChildToParentMessageStatus } from '@arbitrum/sdk';

import { BRIDGE_SERVICE, BridgeNetworks, SupportedChainId, parallelChain } from '../../configs';
import { getOutgoingMessageState } from '../../helper';
import { useNetworkProvider } from '../useNetworkProvider';

import { ActivityDetail, Event, L1StatusMapping, L2ToL1Event } from './types';

import { Maybe } from '@/apps/parax/typings/basic';
import { env } from '@/env';
import { useEOAProvider } from '@/apps/parax/contexts';

type ActivityResponse = {
  items: L2ToL1Event[];
  totalCount: number;
};

const DEFAULT_PAGE_SIZE = 5;

export const WITHDRAWAL_EVENTS_STATUS_CACHE_KEY = 'withdrawal_events_executed_tx_hash';

const ethWithdrawDestChainMap = {
  [Environment.DEVELOPMENT]: sepoliaChainConfig,
  [Environment.STAGING]: sepoliaChainConfig,
  [Environment.PRODUCTION]: ethereumChainConfig
}[env];

export const useNativeTokenActivities = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activities, setActivities] = useState<Maybe<ActivityDetail[]>>(null);
  const [pageInfo, setPageInfo] =
    useState<Maybe<{ page: number; size: number; totalPage: number }>>(null);

  const { account } = useEOAProvider();

  const l1Provider = useNetworkProvider(ethWithdrawDestChainMap.chainId as number);
  const l2Provider = useNetworkProvider(parallelChain.chainId);

  const fetchActivities = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await axios.get<ActivityResponse>(
        `${BRIDGE_SERVICE}/activity/native-token/${account}`
      );

      const events = response.data.items ?? [];

      const formattedActivities = await Promise.all(
        events.map(async activity => {
          const fromChainId: SupportedChainId = parallelChain.chainId;
          const toChainId: SupportedChainId = ethWithdrawDestChainMap.chainId as number;

          const rawEvent = {
            ...activity,
            hash: EthersBigNumber.from(activity?.transactionHash),
            position: EthersBigNumber.from(activity?.position),
            arbBlockNum: EthersBigNumber.from(activity?.arbBlockNum),
            ethBlockNum: EthersBigNumber.from(activity?.ethBlockNum),
            timestamp: EthersBigNumber.from(activity?.timestamp),
            callvalue: EthersBigNumber.from(activity?.value),
            data: '0x'
          };

          const status = await getOutgoingMessageState(rawEvent, l1Provider!, l2Provider!, account);

          const targetNativeCurrency = BridgeNetworks[fromChainId].nativeCurrency;
          if (!targetNativeCurrency) {
            throw new Error('Failed to find the target native currency');
          }

          const amount = BigNumber(activity.value).shiftedBy(-targetNativeCurrency.decimals);
          const type = 'withdraw';

          return {
            id: activity.id,
            type: type as 'deposit' | 'withdraw',
            chains: {
              from: fromChainId,
              to: toChainId
            },
            metadata: {
              amount,
              symbol: targetNativeCurrency.symbol,
              from: activity.caller,
              to: activity.destination,
              event: rawEvent
            },
            sendEvent: {
              blockNumber: activity.arbBlockNum,
              status: activity.transactionStatus as Event['status'],
              timestamp: activity.timestamp,
              transactionHash: activity.transactionHash
            },
            receivedEvent: {
              status: L1StatusMapping[status] as Event['status']
            }
          };
        })
      );

      setPageInfo({
        totalPage: Math.ceil(events.length / DEFAULT_PAGE_SIZE),
        page: 1,
        size: DEFAULT_PAGE_SIZE
      });
      setActivities(formattedActivities);
    } catch (e) {
      console.error('Fetch Bridge Activity failed, error: ', e);
    } finally {
      setIsLoading(false);
    }
  }, [account, l1Provider, l2Provider]);

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  const refetch = useCallback((page: number) => {
    setPageInfo(prev => prev && { ...prev, page });
  }, []);

  const updateLocalEventStatus = useCallback((id, status: ChildToParentMessageStatus) => {
    setActivities(prev => {
      const index = findIndex(prev, v => v.id === id);
      const result = cloneDeep(prev) ?? [];
      if (result[index]?.receivedEvent) {
        result[index].receivedEvent!.status = L1StatusMapping[status] as Event['status'];
      }
      return result;
    });
  }, []);

  return {
    isLoading,
    activities: isNil(pageInfo)
      ? activities?.slice(0, DEFAULT_PAGE_SIZE)
      : activities?.slice(
          (pageInfo.page - 1) * DEFAULT_PAGE_SIZE,
          DEFAULT_PAGE_SIZE * pageInfo.page
        ),
    refetch,
    pageInfo,
    updateLocalEventStatus
  };
};
