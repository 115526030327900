import { memo } from 'react';

import { NetworkCheck, GoogleAnalytics } from '../components';
import {
  AAProvider,
  BalanceInfoProvider,
  V1UserContextProvider,
  ApolloProvider,
  useEOAProvider
} from '../contexts';

import { Apps } from './Apps';

export const AppsFramework = memo(() => {
  const { chainId, account } = useEOAProvider();

  return (
    <NetworkCheck key={`${chainId}-${account}`}>
      <ApolloProvider>
        <AAProvider>
          <BalanceInfoProvider>
            <GoogleAnalytics>
              <V1UserContextProvider>
                <Apps />
              </V1UserContextProvider>
            </GoogleAnalytics>
          </BalanceInfoProvider>
        </AAProvider>
      </ApolloProvider>
    </NetworkCheck>
  );
});
