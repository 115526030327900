import { useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { Maybe } from '@/apps/paraspace/typings/basic';

export const useValidation = ({
  balance,
  amount
}: {
  amount: Maybe<number>;
  balance?: BigNumber;
}) =>
  useMemo(() => {
    if (!balance) {
      return 'Balance not loaded';
    }

    if (amount === null) {
      return 'Enter an amount';
    }

    if (amount <= 0) {
      return 'Invalid input.';
    }

    if (balance.lt(amount)) {
      return 'Insufficient balance.';
    }

    return null;
  }, [amount, balance]);
