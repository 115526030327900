import { memo, useCallback } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';

import { SupplyNftForm, SupplyNftFormData } from './SupplyNftForm';
import NotConnectedSupplyModal from './NotConnectedSupplyModal';
import SuccessState from './SuccessState';
import { SupplyNftFormStepper } from './SupplyNftFormStepper';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import {
  ErrorState,
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { ERC721Symbol, WalletType } from '@/apps/paraspace/typings';

export type SupplyNftModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  symbol: ERC721Symbol;
  walletType: WalletType;
  collectionName: string;
  onClose: () => void;
  onFinish?: () => void;
  onError?: () => void;
};

export type SupplyNftModalHandle = {
  open: (arg: { symbol: string; collectionName: string }) => void;
};

export const NFTSupplyModal = memo(
  ({
    symbol,
    collectionName,
    walletType,
    isOpen,
    onClose,
    onFinish,
    onError,
    ...others
  }: SupplyNftModalProps) => {
    const handleFinish = useCallback(() => {
      onFinish?.();
    }, [onFinish]);

    const { phase, submittedFormData, handleFormSubmit, handleSubmitSuccess, handleSubmitFailed } =
      useCollectAndSubmitProcedure<SupplyNftFormData>({
        onError,
        running: isOpen,
        onFinish: handleFinish
      });

    const { isUsingUserWallet } = useWeb3Context();

    if (!isUsingUserWallet) {
      return (
        <Modal onClose={onClose} isOpen={isOpen} size="500px" title="Supply NFT">
          <NotConnectedSupplyModal close={onClose} />
        </Modal>
      );
    }

    return (
      <Modal
        title={`Supply ${collectionName}`}
        closeOnBackdropClick={false}
        size={phase === CollectAndSubmitProcedurePhase.Collecting ? '45rem' : '30rem'}
        isOpen={isOpen}
        onClose={onClose}
        {...others}
      >
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <SupplyNftForm
            symbol={symbol}
            collectionName={collectionName}
            walletType={walletType}
            onSubmit={handleFormSubmit}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <SupplyNftFormStepper
            formData={submittedFormData!}
            onFinish={handleSubmitSuccess}
            onError={handleSubmitFailed}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && <ErrorState closeModal={onClose} />}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <SuccessState onFinish={onClose} formData={submittedFormData!} />
        )}
      </Modal>
    );
  }
);
