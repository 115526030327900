import { useState, useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { allotShare, percentageToShare } from '../../utils';

import {
  ApeCoinListing,
  ApeListing,
  BakcListing
} from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/types';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { one, zero } from '@/apps/paraspace/consts/values';

export const useListingShareValuesAndHandlers = ({
  apeListing,
  bakcListing,
  apeCoinListing
}: {
  apeListing: Maybe<ApeListing>;
  bakcListing: Maybe<BakcListing>;
  apeCoinListing: Maybe<ApeCoinListing>;
}) => {
  const stakeholderCount = useMemo(() => 3, []);
  const [shareOfBakc, setShareOfBakc] = useState<Maybe<BigNumber>>(
    bakcListing?.share ?? allotShare([apeListing?.share, apeCoinListing?.share], stakeholderCount)
  );

  const [shareOfApe, setShareOfApe] = useState<Maybe<BigNumber>>(
    apeListing?.share ?? allotShare([bakcListing?.share, apeCoinListing?.share], stakeholderCount)
  );

  const [shareOfApeCoin, setShareOfApeCoin] = useState<Maybe<BigNumber>>(
    apeCoinListing?.share ?? allotShare([apeListing?.share, bakcListing?.share], stakeholderCount)
  );

  const handleShareOfBakcChanged = useCallback(
    (percentage: Maybe<number>) => {
      const share = percentageToShare(percentage);
      setShareOfBakc(share);
      if (apeListing === null) {
        setShareOfApe(allotShare([share, shareOfApeCoin], stakeholderCount) ?? null);
      }

      if (apeCoinListing === null) {
        setShareOfApeCoin(allotShare([share, shareOfApe], stakeholderCount) ?? null);
      }
    },
    [apeListing, apeCoinListing, shareOfApeCoin, stakeholderCount, shareOfApe]
  );

  const handleShareOfApeChanged = useCallback(
    (percentage: Maybe<number>) => {
      const share = percentageToShare(percentage);
      setShareOfApe(share);
      if (bakcListing === null) {
        setShareOfBakc(allotShare([share, shareOfApeCoin], stakeholderCount) ?? null);
      }
      if (apeCoinListing === null) {
        setShareOfApeCoin(allotShare([share, shareOfBakc], stakeholderCount) ?? null);
      }
    },
    [bakcListing, apeCoinListing, shareOfApeCoin, stakeholderCount, shareOfBakc]
  );

  const handleShareOfApeCoinChanged = useCallback(
    (percentage: Maybe<number>) => {
      const share = percentageToShare(percentage);
      setShareOfApeCoin(share);
      if (bakcListing === null) {
        setShareOfBakc(allotShare([share, shareOfApe], stakeholderCount) ?? null);
      }
      if (apeListing === null) {
        setShareOfApe(allotShare([share, shareOfBakc], stakeholderCount) ?? null);
      }
    },
    [bakcListing, apeListing, shareOfApe, stakeholderCount, shareOfBakc]
  );

  const shareLeft = one
    .minus(apeListing?.share ?? zero)
    .minus(bakcListing?.share ?? zero)
    .minus(apeCoinListing?.share ?? zero);

  return {
    shareLeft,
    shareOfBakc,
    shareOfApe,
    shareOfApeCoin,
    handleShareOfBakcChanged,
    handleShareOfApeChanged,
    handleShareOfApeCoinChanged
  };
};
