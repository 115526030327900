import { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { Environment } from 'parax-sdk';

import { useWeb3Context } from '../../contexts';

export const GoogleAnalytics: FC = ({ children }) => {
  const { env } = useWeb3Context();
  useEffect(() => {
    const isProduction = env === Environment.PRODUCTION;
    if (isProduction) {
      ReactGA.initialize('G-XQ58CQPC9X');
      TagManager.initialize({ gtmId: 'GTM-N8QX8QC' });
    }
  }, [env]);
  return <>{children}</>;
};
