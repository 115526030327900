import { memo, useCallback, MouseEvent, useMemo } from 'react';
import {
  Button,
  H3,
  Modal,
  ModalProps,
  Stack,
  useBreakpoints,
  Icon,
  Inline
} from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import styled, { useTheme } from 'styled-components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { TransactionReceipt } from '@ethersproject/providers';

import { AssetType, TimelockQueueRow } from '../../TimelockQueue/hooks';

import {
  ErrorState,
  StackedIcons,
  ClaimTimelockSubmitter,
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ERC20Symbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useConvertToggle } from '@/apps/paraspace/pages/Credit/hooks/useConvertToggle';
import { useClaimToEOAToggle, useConvertSymbolWithNetwork } from '@/apps/paraspace/pages/hooks';
import { MAXIMUM_BALANCE_DECIMALS } from '@/apps/paraspace/pages/config';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

type ClaimTimelockModalProps = Omit<ModalProps, 'children' | 'id' | 'onError'> & {
  onClose: () => void;
  onSuccess: (data: TimelockQueueRow) => void;
  onError: (data: TimelockQueueRow) => void;
  timelockItem: TimelockQueueRow;
};

const Container = styled(Stack)`
  min-width: 26rem;
  width: fit-content;
  ${({ theme }) => theme.breakpoints.only('mobile')`
   min-width: auto;
   width: 100%;
`};
`;

export const ClaimTimelockModal = memo(
  ({ timelockItem, isOpen, onClose, onSuccess, onError, ...others }: ClaimTimelockModalProps) => {
    const { symbol: aliasSymbol, assetInfo, actionTypeName, agreementId } = timelockItem ?? {};

    const { convertWTokenToNativeToken } = useConvertSymbolWithNetwork();
    const symbol = convertWTokenToNativeToken(aliasSymbol as ERC20Symbol);

    const handleFinish = useCallback(() => {
      onSuccess(timelockItem);
    }, [onSuccess, timelockItem]);

    const handleError = useCallback(() => {
      onError(timelockItem);
    }, [onError, timelockItem]);

    const { phase, handleFormSubmit, handleSubmitFailed, handleSubmitSuccess } =
      useCollectAndSubmitProcedure<MouseEvent, Maybe<TransactionReceipt>[]>({
        running: isOpen,
        onFinish: handleFinish,
        onError: handleError
      });

    const { toggleComponent: unwrapToCryptoPunksToggle, toggleOpened: shouldUnwrapToCryptoPunks } =
      useConvertToggle('Unwrap it to CryptoPunks');
    const { toggleComponent: claimToEOAToggle, shouldClaimToEOA } = useClaimToEOAToggle();
    const { toggleComponent: convertCAPEToggle, toggleOpened: shouldConvertToAPE } =
      useConvertToggle('Convert your cAPE to APE', true);
    const { erc20InfoMap } = useMMProvider();

    const showPunksToggle = useMemo(() => aliasSymbol === ERC721Symbol.WPUNKS, [aliasSymbol]);
    const showCAPEConvertToggle = useMemo(() => aliasSymbol === ERC20Symbol.CAPE, [aliasSymbol]);

    const { mobile } = useBreakpoints();

    const theme = useTheme();

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={mobile ? '100%' : 'fit-content'}
        title="Claim"
        {...others}
      >
        {timelockItem ? (
          <Container alignItems="center">
            {phase === CollectAndSubmitProcedurePhase.Collecting && (
              <>
                {assetInfo.type === AssetType.ERC20 ? (
                  <Stack gap="1rem" justifyContent="center" alignItems="center">
                    <CryptoIcon symbol={symbol} size="3.75rem" />
                    <H3>
                      {actionTypeName} Amount{' '}
                      {formatNumber(assetInfo.amount ?? 0, { decimal: MAXIMUM_BALANCE_DECIMALS })}{' '}
                      {erc20InfoMap[symbol].displayName}
                    </H3>
                  </Stack>
                ) : (
                  <>
                    <StackedIcons
                      assets={assetInfo.tokenIds?.map(item => ({ symbol, tokenId: item })) || []}
                    />
                    <H3>
                      {actionTypeName} {assetInfo.tokenIds?.length} {symbol}
                    </H3>
                  </>
                )}
                <Stack width="100%" inset="2rem 0 0 0" gap="1rem">
                  {showCAPEConvertToggle && (
                    <Inline gap="0.5rem" justifyContent="space-between">
                      {convertCAPEToggle}
                    </Inline>
                  )}
                  {showPunksToggle && (
                    <Inline gap="0.5rem" justifyContent="space-between">
                      {unwrapToCryptoPunksToggle}
                    </Inline>
                  )}
                  <Inline justifyContent="space-between" gap="0.5rem">
                    {claimToEOAToggle}
                  </Inline>
                </Stack>
                <Button block skin="primary" size="large" onClick={handleFormSubmit}>
                  Claim
                </Button>
              </>
            )}
            {phase === CollectAndSubmitProcedurePhase.Submitting && (
              <ClaimTimelockSubmitter
                formData={{
                  tokenIds: assetInfo.tokenIds,
                  amount: assetInfo.amount,
                  symbol,
                  agreementIds: [String(agreementId)],
                  shouldUnwrapToCryptoPunks,
                  shouldClaimToEOA,
                  shouldConvertToAPE: symbol === ERC20Symbol.CAPE && shouldConvertToAPE
                }}
                shouldWaitingPrepare={false}
                onFinish={handleSubmitSuccess}
                onError={handleSubmitFailed}
              />
            )}
            {phase === CollectAndSubmitProcedurePhase.Failed && (
              <ErrorState width="100%" closeModal={onClose} />
            )}
            {phase === CollectAndSubmitProcedurePhase.Success && (
              <Stack width="100%" alignItems="center">
                <Icon name="checkContained" size="xlarge" color={theme.skin.success.main} />
                <Stack alignItems="center" gap="0.25rem">
                  <H3>Claim Successfully</H3>
                </Stack>
                <Button block size="large" onClick={onClose} skin="secondary">
                  Done
                </Button>
              </Stack>
            )}
          </Container>
        ) : null}
      </Modal>
    );
  }
);
