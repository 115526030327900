import { ERC20Symbol } from '@/apps/parax/typings';

export const ERC20_EXPECTED_ORDER = [
  ERC20Symbol.ETH,
  ERC20Symbol.WETH,
  ERC20Symbol.USDT,
  ERC20Symbol.USDC,
  ERC20Symbol.CAPE,
  ERC20Symbol.APE
];
