import { useEffect, useMemo } from 'react';
import { sortBy } from 'lodash';

import { formatNearLiquidationInfo } from '../utils';
import { NearLiquidationAssetType } from '../types';
import { useMMProvider } from '../../contexts/MMProvider';
import { useNativeTokenSymbol } from '../../hooks';

import {
  AccountNearLiquidationInfo,
  useGetNearLiquidationAccountsQuery
} from '@/apps/paraspace/generated/graphql';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useAppConfig, useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';

const REFRESH_DATA_INTERVAL = 20000;

export const useNearLiquidationDashboardState = (filterCollections: ERC721Symbol[]) => {
  const { data, loading, client, refetch } = useGetNearLiquidationAccountsQuery();
  const liquidations = useMemo(
    () => (data?.nearLiquidationAccounts || []) as AccountNearLiquidationInfo[],
    [data?.nearLiquidationAccounts]
  );

  const { erc20InfoMap } = useMMProvider();
  const nativeTokenSymbol = useNativeTokenSymbol();
  const { priceInETH: nativeTokenPriceInMarketCurrency } = erc20InfoMap[nativeTokenSymbol] || {};
  const { erc20Config, marketCurrencyTokenInfo } = useAppConfig();

  const getSymbolByContractAddress = useGetSymbolByContractAddress();

  const formattedNearLiquidationInfos = useMemo(
    () =>
      liquidations.map(liquidation =>
        formatNearLiquidationInfo({
          nearLiquidationInfo: liquidation!,
          erc20Configs: erc20Config,
          getSymbolByContractAddress,
          marketCurrencyTokenInfo,
          nativeTokenPriceInMarketCurrency
        })
      ),
    [
      liquidations,
      erc20Config,
      getSymbolByContractAddress,
      marketCurrencyTokenInfo,
      nativeTokenPriceInMarketCurrency
    ]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, REFRESH_DATA_INTERVAL);
    return () => {
      clearInterval(intervalId);
    };
  }, [refetch]);

  const filteredLiquidations = useMemo(
    () =>
      filterCollections.length > 0
        ? formattedNearLiquidationInfos.filter(liq =>
            liq.assets.some(
              asset =>
                asset.type === NearLiquidationAssetType.ERC721 &&
                filterCollections.includes(asset.symbol)
            )
          )
        : formattedNearLiquidationInfos,
    [formattedNearLiquidationInfos, filterCollections]
  );

  const sortedNearLiquidationInfos = useMemo(
    () => sortBy(filteredLiquidations, 'healthFactor'),
    [filteredLiquidations]
  );

  return {
    loading,
    client,
    nearLiquidationInfos: sortedNearLiquidationInfos
  };
};
