import { useCallback, useEffect, useState } from 'react';
import { chain, isEmpty, map, zipObject } from 'lodash';

import { ApeTokenStakingInfo } from './types';
import { useApeStaking } from './useApeStaking';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC721Symbol
} from '@/apps/paraspace/typings';

export const useMainToBakcMap = (
  mainTokens: {
    symbol: ApeStakingMainTokenSymbol;
    tokenId: number;
    supplied?: boolean;
  }[]
): [Record<string, Record<string, ApeTokenStakingInfo>> | null, () => Promise<void>] => {
  const { account } = useWeb3Context();
  const { mainToBakcs, getStakingInfosByTokenInfos } = useApeStaking();
  const [mainToBakcMap, setMainToBakcMap] = useState<Record<
    string,
    Record<string, ApeTokenStakingInfo>
  > | null>(null);

  const refresh = useCallback(async () => {
    if (mainTokens.length < 1) {
      setMainToBakcMap({});
      return;
    }

    try {
      const bakcInfos = await mainToBakcs(mainTokens);
      if (!bakcInfos || isEmpty(bakcInfos)) {
        setMainToBakcMap(null);
        return;
      }
      const pairs = mainTokens.map((mainToken, index) => {
        const { isPaired, tokenId } = bakcInfos[index];
        return {
          ...mainToken,
          pairedBakcId: isPaired ? tokenId.toNumber() : null
        };
      });

      const validPairs = pairs.filter(it => it.pairedBakcId);
      const bakcTokenInfos = validPairs.map(({ pairedBakcId }) => ({
        type: ERC721Symbol.BAKC as ApeStakingTokenSymbol,
        id: String(pairedBakcId),
        userAddr: account
      }));

      const bakcStakings = await getStakingInfosByTokenInfos(bakcTokenInfos);
      const enrichedBakcStakingInfos = bakcStakings.map((staking, index) => ({
        ...staking,
        symbol: ERC721Symbol.BAKC,
        tokenId: validPairs[index].pairedBakcId,
        mainTokenId: validPairs[index].tokenId,
        mainTokenSymbol: validPairs[index].symbol,
        supplied:
          mainTokens.find(
            item =>
              item.tokenId === validPairs[index].pairedBakcId &&
              item.symbol === (ERC721Symbol.BAKC as ApeStakingTokenSymbol)
          )?.supplied ?? null
      }));

      if (isEmpty(validPairs) || isEmpty(enrichedBakcStakingInfos)) {
        setMainToBakcMap(null);
        return;
      }

      const bakcIdToStakingInfoMap = zipObject(
        map(validPairs, 'pairedBakcId') as number[],
        enrichedBakcStakingInfos
      );

      const generatedMap = chain(validPairs)
        .groupBy('symbol')
        .mapValues(value =>
          chain(value)
            .keyBy('tokenId')
            .mapValues(({ pairedBakcId }) =>
              pairedBakcId === null ? null : bakcIdToStakingInfoMap[pairedBakcId]
            )
            .value()
        )
        .value();
      setMainToBakcMap(generatedMap as Record<string, Record<string, ApeTokenStakingInfo>>);
    } catch (err) {
      setMainToBakcMap(null);
      throw err;
    }
  }, [mainToBakcs, mainTokens, account, getStakingInfosByTokenInfos]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [mainToBakcMap, refresh];
};
