import { useCallback } from 'react';

import { useMMProvider } from '../contexts/MMProvider';

import { useIsTokenSupplierCheck } from './useIsTokenSupplierCheck';

import { useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { ERC721Symbol } from '@/apps/paraspace/typings';

export const useIsTokenOwnerCheck = () => {
  const { nftInfoMap } = useMMProvider();
  const { checkIsTokenSupplier } = useIsTokenSupplierCheck();

  const getSymbolByContractAddress = useGetSymbolByContractAddress();
  const checkIsTokenOwner = useCallback(
    (contractAddr: string, tokenId: string) => {
      const symbol = getSymbolByContractAddress(contractAddr ?? '') as ERC721Symbol;
      const isSuppliedOwner = checkIsTokenSupplier(contractAddr, tokenId);

      const isOwnerButNotSupplied =
        nftInfoMap[symbol]?.balance?.some(it => String(it) === tokenId) ?? false;

      return isOwnerButNotSupplied || isSuppliedOwner;
    },
    [checkIsTokenSupplier, nftInfoMap, getSymbolByContractAddress]
  );

  return { checkIsTokenOwner };
};
