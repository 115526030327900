import { memo, useCallback } from 'react';
import BigNumber from 'bignumber.js';

import { parallelChain } from '../../configs';

import { useBridgeImplementation } from './hooks';

import { FormSubmitter, FormSubmitterProps } from '@/apps/parax/components';

type BridgeNativeTokenSubmitterProps = Omit<FormSubmitterProps, 'submit'> & {
  formData: {
    amount: BigNumber;
    to: string;
    l1GasFeeInWei: BigNumber;
    l2GasFeeInWei: BigNumber;
  };
};

export const BridgeNativeTokenSubmitter = memo(
  ({
    formData: { amount, to, l1GasFeeInWei, l2GasFeeInWei },
    ...others
  }: BridgeNativeTokenSubmitterProps) => {
    const { genBridgeNativeTokenTx } = useBridgeImplementation(null, parallelChain.eId!);
    const submit = useCallback(
      () => genBridgeNativeTokenTx(amount, to, l1GasFeeInWei, l2GasFeeInWei),
      [amount, to, l1GasFeeInWei, l2GasFeeInWei, genBridgeNativeTokenTx]
    );
    return <FormSubmitter submit={submit} inProgressMessage="Bridging native token" {...others} />;
  }
);
