import { useMemo } from 'react';
import {
  parallelFuncContractAddresses,
  parallelTestnetFuncContractAddresses
} from '@parallel-utils/contracts-registry';
import { Network } from 'paraspace-configs-v2';

import { contractsConfig } from '../config/contractsConfig';
import { useEOAProvider } from '../contexts';
import { ContractMap } from '../config';

export const useContractsMap = () => {
  const { chainId } = useEOAProvider();

  const contracts = useMemo(() => {
    return ({
      [Network.PARALLEL]: parallelFuncContractAddresses,
      [Network.PARALLEL_L3_TESTNET]: parallelTestnetFuncContractAddresses
    }[chainId as number] ?? {
      ...contractsConfig.contracts[chainId],
      AAAccountFactory: contractsConfig.contracts[chainId].AAAcountFactory
    }) as ContractMap;
  }, [chainId]);

  return contracts;
};
