import { isNil, minBy } from 'lodash';
import { useCallback } from 'react';

import { JoinBAKCPairListingsFormData } from '../../types';

import { useListingInputs } from './useListingInputs';
import { usePredictedListings } from './usePredictedListings';
import { useListingShareValuesAndHandlers } from './useListingShareValuesAndHandlers';
import { useInfos } from './useInfos';
import { useErrors } from './useErrors';
import { useListingCheck } from './useListingCheck';

import {
  ApeCoinListing,
  ApeListing,
  BakcListing
} from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/types';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { WalletType } from '@/apps/paraspace/typings';

export const useJoinBAKCPairListingsFormStateAndHandlers = ({
  apeListing,
  apeCoinListing,
  bakcListing,
  walletType,
  onSubmit
}: {
  apeListing: Maybe<ApeListing>;
  apeCoinListing: Maybe<ApeCoinListing>;
  bakcListing: Maybe<BakcListing>;
  walletType: WalletType;
  onSubmit: (formData: JoinBAKCPairListingsFormData) => void;
}) => {
  const {
    apeCoinSourceInput,
    apeListingTokenInput,
    bakcListingTokenInput,
    handleApeCoinSourceInputChange,
    handleApeListingTokenInputChange,
    handleBakcListingTokenInputChange
  } = useListingInputs({ apeListing, bakcListing });

  const {
    shareLeft,
    shareOfBakc,
    shareOfApe,
    shareOfApeCoin,
    handleShareOfBakcChanged,
    handleShareOfApeChanged,
    handleShareOfApeCoinChanged
  } = useListingShareValuesAndHandlers({
    apeListing,
    apeCoinListing,
    bakcListing
  });

  const { predictedApeListing, predictedApeCoinListing, predictedBakcListing } =
    usePredictedListings({
      apeListing,
      apeCoinListing,
      bakcListing,
      shareOfApe,
      shareOfApeCoin,
      shareOfBakc,
      apeListingTokenInput,
      bakcListingTokenInput
    });

  const infos = useInfos({
    apeCoinListing,
    predictedApeListing,
    predictedApeCoinListing,
    predictedBakcListing
  });

  const errors = useErrors({
    apeCoinListing,
    apeCoinSourceInput,
    apeListingTokenInput,
    bakcListingTokenInput,
    shareOfBakc,
    shareOfApeCoin,
    shareOfApe,
    walletType
  });

  const expirationDate = minBy(
    [
      apeCoinListing?.expirationDate,
      apeListing?.expirationDate,
      bakcListing?.expirationDate
    ].filter(it => !isNil(it)),
    it => it!.getTime()
  );

  const handleConfirm = useCallback(() => {
    onSubmit({
      apeListing,
      apeCoinListing,
      bakcListing,
      apeListingToCreate: apeListing ? null : { ...apeListingTokenInput!, share: shareOfApe! },
      apeCoinListingToCreate: apeCoinListing
        ? null
        : { source: apeCoinSourceInput, share: shareOfApeCoin! },
      bakcListingToCreate: bakcListing ? null : { ...bakcListingTokenInput!, share: shareOfBakc! }
    });
  }, [
    onSubmit,
    apeListing,
    apeListingTokenInput,
    apeCoinListing,
    apeCoinSourceInput,
    bakcListing,
    bakcListingTokenInput,
    shareOfApe,
    shareOfApeCoin,
    shareOfBakc
  ]);

  const validationResult = useListingCheck({
    apeCoinListing,
    apeListing,
    bakcListing
  });

  return {
    predictedApeListing,
    predictedApeCoinListing,
    predictedBakcListing,
    shareLeft,
    shareOfBakc,
    shareOfApe,
    shareOfApeCoin,
    handleShareOfBakcChanged,
    handleShareOfApeChanged,
    handleShareOfApeCoinChanged,
    apeCoinSourceInput,
    apeListingTokenInput,
    bakcListingTokenInput,
    handleApeCoinSourceInputChange,
    handleApeListingTokenInputChange,
    handleBakcListingTokenInputChange,
    infos,
    errors,
    expirationDate,
    handleConfirm,
    validationResult
  };
};
