import { memo, useCallback, useMemo } from 'react';

import { BorrowERC20FormData } from '../types';

import { BorrowERC20Submitter, Stepper, StepperProps } from '@/apps/paraspace/components';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

type BorrowERC20StepperProps = Omit<StepperProps, 'steps' | 'step' | 'onError'> & {
  formData: BorrowERC20FormData;
  onFinish: (claimed: boolean) => void;
  onError: (errorConfig: Maybe<ErrorConfig>) => void;
};

export const BorrowERC20Stepper = memo(
  ({ formData: { symbol, amount }, onFinish, onError, ...others }: BorrowERC20StepperProps) => {
    const { erc20InfoMap } = useMMProvider();

    const handleBorrowed = useCallback(async () => {
      onFinish(false);
    }, [onFinish]);

    const steps = useMemo(
      () => [
        {
          description: `Borrow ${erc20InfoMap[symbol].displayName} and add to Timelock Queue`,
          content: (
            <BorrowERC20Submitter
              formData={{ amount, symbol }}
              onFinish={handleBorrowed}
              onError={onError}
            />
          )
        }
      ],
      [erc20InfoMap, symbol, amount, handleBorrowed, onError]
    );

    return <Stepper step={0} steps={steps} {...others} />;
  }
);
