import { BigNumber } from 'ethers';
import { ParaXProvider, WalletTypeEnum } from 'parax-sdk';

import { SupportedNetworks } from '../../typings';

import { networkConfigs } from '@/apps/paraspace/config';
import { EIP6963_PROVIDER_MANAGER } from '@/apps/parax/contexts/EOAProvider/hooks';

export const isWalletInstalled = (type: WalletTypeEnum) => {
  if (type === WalletTypeEnum.METAMASK) {
    return (
      window.ethereum?.isMetaMask ||
      EIP6963_PROVIDER_MANAGER.list.find(v => v.info.name === 'MetaMask')
    );
  }
  if (type === WalletTypeEnum.BITGET) {
    return (
      !!(window as any).bitkeep ||
      EIP6963_PROVIDER_MANAGER.list.find(v => v.info.name === 'Bitget Wallet')
    );
  }
  if (type === WalletTypeEnum.OKX_WALLET) {
    return (
      !!window.okxwallet || EIP6963_PROVIDER_MANAGER.list.find(v => v.info.name === 'OKX Wallet')
    );
  }

  if (type === WalletTypeEnum.COINBASE_WALLET) {
    return (
      window.ethereum?.providerMap?.get?.('CoinbaseWallet') || window.ethereum?.isCoinbaseWallet
    );
  }
  // TODO check for GNOSIS_SAFE
  if (type === WalletTypeEnum.WALLET_CONNECT || type === WalletTypeEnum.GNOSIS_SAFE) {
    return true;
  }

  console.warn(`Not integrated ${type} yet`);
  return false;
};

export const getAddChainParameters = (newChainId: SupportedNetworks) => {
  const chainConfig = networkConfigs[newChainId];
  const hexChainId = `0x${newChainId.toString(16)}`;
  if (chainConfig) {
    return {
      chainId: hexChainId,
      chainName: chainConfig.name,
      nativeCurrency: chainConfig.nativeCurrency,
      rpcUrls: chainConfig.publicJsonRPCUrl,
      blockExplorerUrls: chainConfig.explorerLink
    };
  }
  return {
    chainId: hexChainId
  };
};

export const getWalletDownloadUrl = (type: WalletTypeEnum) => {
  if (type === WalletTypeEnum.METAMASK) {
    return 'https://metamask.io';
  }

  if (type === WalletTypeEnum.OKX_WALLET) {
    return 'https://www.okx.com/web3';
  }

  if (type === WalletTypeEnum.COINBASE_WALLET) {
    return 'https://www.coinbase.com/wallet';
  }

  return '';
};

export const rewriteGasEstimation = (
  provider: ParaXProvider,
  alwaysByPassGasEstimation: boolean = false
) => {
  const originEstimateGas = provider.estimateGas;
  provider.estimateGas = async (...args: Parameters<typeof originEstimateGas>) => {
    /**
     * 1. disable in production
     * 2. disable in staging/dev/fork by default but can enable explicitly
     */
    // eslint-disable-next-line no-underscore-dangle
    const bypassGasEstimation = (window as any).__PARASPACE_DEBUG__ === true;
    // eslint-disable-next-line no-underscore-dangle

    if (alwaysByPassGasEstimation || bypassGasEstimation) {
      return BigNumber.from(5000000);
    }
    return originEstimateGas.call(provider, ...args);
  };
};

export const withoutGasEstimation = async <T>(func: () => Promise<T>): Promise<T> => {
  // eslint-disable-next-line no-underscore-dangle
  const originalDebugToggleValue = (window as any).__PARASPACE_DEBUG__;
  // eslint-disable-next-line no-underscore-dangle
  (window as any).__PARASPACE_DEBUG__ = true;
  try {
    const result = await func();
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  } finally {
    // eslint-disable-next-line no-underscore-dangle
    (window as any).__PARASPACE_DEBUG__ = originalDebugToggleValue;
  }
};
