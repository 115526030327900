import {
  Button,
  Card,
  H5,
  H6,
  Icon,
  Image,
  Inline,
  Popover,
  PopoverProps,
  Skeleton,
  SmallText,
  Stack,
  StackProps,
  stackTwoColors
} from '@parallel-mono/components';
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useCopyToClipboard } from 'react-use';
import BigNumber from 'bignumber.js';
import { isEmpty, isNil, noop } from 'lodash';

import paraAccountIcon from '../images/para-account.svg';
import { MobileBottomDrawer } from '../../MobileBottomDrawer';

import { formatToCurrency, truncateTextMid } from '@/apps/parax/utils';
import { useAAProvider, useEOAProvider, useParaXToast } from '@/apps/parax/contexts';
import { useCreateParaAccount, useParaAccountInfos } from '@/apps/parax/hooks';

const AccountItem = styled(Inline).attrs({
  background: 'slot'
})<{ active?: boolean }>`
  width: 320px;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    width: 100%;
  `}
  border: 1px solid ${({ theme }) => theme.skin.grey[200]};
  border-radius: 1rem;
  cursor: pointer;
  padding: 1rem 0.75rem;
  padding-right: 1.5rem;
  background-size: cover;
  background: ${({ theme }) => theme.skin.background.slot};
  &:hover {
    background: ${({ theme }) =>
      stackTwoColors(theme.skin.background.slot, theme.skin.action.hoverMask)};
  }

  border: 2px solid
    ${({ active, theme }) => (active ? `${theme.skin.grey[900]}` : theme.skin.background.slot)};

  ${({ active, theme }) =>
    active &&
    `background: ${stackTwoColors(theme.skin.background.slot, theme.skin.action.hoverMask)}}`};
`;
const StyledDropdownIcon = styled(Icon)<{ expanded: boolean }>`
  transition: transform 0.2s ease-in-out;
  transform: rotate(${({ expanded }) => (expanded ? -180 : 0)}deg);
`;

const AccountList: FC<{
  accounts: string[];
  activeAccount: string | null;
  isLoadingBalance: boolean;
  balanceInfos: {
    address: string;
    totalBalance: BigNumber;
  }[];
}> = ({ accounts, activeAccount, isLoadingBalance, balanceInfos }) => {
  const paraXToast = useParaXToast();
  const [copyState, copyToClipboard] = useCopyToClipboard();
  const [copiedAddress, setCopiedAddress] = useState<string | null>();
  const timeRef = useRef<any>();
  const { switchAccount } = useAAProvider();

  useEffect(() => {
    const { value, error } = copyState;
    if (value) {
      setCopiedAddress(value);
      paraXToast.success('Wallet Address copied!');
      timeRef.current = setTimeout(() => {
        setCopiedAddress(null);
      }, 5000);
    }
    if (error) {
      paraXToast.error(error.message);
    }
    return () => clearTimeout(timeRef.current);
  }, [copyState, paraXToast]);

  const {
    skin: {
      success: { main: successColor }
    }
  } = useTheme();

  return (
    <Stack width="100%" gap="0.5rem">
      {accounts.map(account => (
        <AccountItem
          key={account}
          onClick={() => {
            switchAccount(account);
          }}
          active={account === activeAccount}
          alignItems="center"
          justifyContent="space-between"
        >
          <Inline alignItems="center" gap="0.5rem">
            <Image width="1.5rem" height="1.5rem" src={paraAccountIcon} />
            <H6 skin="secondary">{truncateTextMid(account ?? '')}</H6>
            {copiedAddress === account ? (
              <Icon name="checkContained" color={successColor} size="1.25rem" />
            ) : (
              <Icon
                size="1rem"
                name="copy"
                onClick={e => {
                  e.stopPropagation();
                  copyToClipboard(account);
                }}
              />
            )}
          </Inline>
          {isLoadingBalance ? (
            <Skeleton.Title width="3.5rem" height="1.25rem" />
          ) : (
            <H5>
              {formatToCurrency(balanceInfos.find(v => v.address === account)?.totalBalance ?? 0)}
            </H5>
          )}
        </AccountItem>
      ))}
    </Stack>
  );
};

export const DesktopAccountSelector: FC<Omit<PopoverProps, 'popup'>> = memo(({ ...others }) => {
  const { accounts: paraAccounts, account: paraAccount } = useAAProvider();
  const { loading: isLoadingBalance, accountInfos, refreshAccountInfo } = useParaAccountInfos();
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibleChange = useCallback((visible: boolean) => {
    if (!visible) {
      setIsVisible(false);
    } else {
      setIsVisible(prev => !prev);
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      refreshAccountInfo();
    }
  }, [isVisible, refreshAccountInfo]);

  const popupContent = useMemo(() => {
    return (
      <Card
        onClick={e => {
          e.stopPropagation();
        }}
        border
        shadow="none"
        inset="1.5rem 1rem"
      >
        <Stack gap="0.5rem">
          <SmallText skin="secondary">Parallel Account{paraAccounts.length > 1 && 's'}</SmallText>
          <AccountList
            isLoadingBalance={isLoadingBalance}
            balanceInfos={accountInfos ?? []}
            accounts={paraAccounts}
            activeAccount={paraAccount}
          />
        </Stack>
      </Card>
    );
  }, [accountInfos, isLoadingBalance, paraAccount, paraAccounts]);

  return (
    <Stack inset="0" gap="0" {...others}>
      <Popover
        visible={isVisible}
        onVisibleChange={handleVisibleChange}
        placement="bottom"
        trigger="click"
        popup={popupContent}
        gap="0.25rem"
      >
        <Inline inset="6px 1rem" alignItems="center" gap="0.25rem">
          <Icon name="purse" strokeWidth={2} size="1.25rem" />
          <H6>{truncateTextMid(paraAccount ?? '')}</H6>
          <StyledDropdownIcon expanded={isVisible} name="chevronDown" strokeWidth="2" />
        </Inline>
      </Popover>
    </Stack>
  );
});

export const MobileAccountSelector: FC<Omit<StackProps, 'children'>> = memo(({ ...others }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { accounts: paraAccounts, account: paraAccount } = useAAProvider();
  const { loading: isLoadingBalance, accountInfos, refreshAccountInfo } = useParaAccountInfos();
  const { loading: isCreating, createParaAccountHandle } = useCreateParaAccount();
  const { account: eoaAccount } = useEOAProvider();

  useEffect(() => {
    if (isOpen) {
      refreshAccountInfo();
    }
  }, [isOpen, refreshAccountInfo]);

  return (
    <Stack gap="0" {...others}>
      <Inline
        onClick={() => {
          setIsOpen(true);
        }}
        inset="0.5rem"
      >
        <Icon name="purse" size="1.25rem" strokeWidth={2} />
      </Inline>

      <MobileBottomDrawer
        isOpen={isOpen}
        onClick={noop}
        onClose={() => setIsOpen(false)}
        onTouchEnd={() => setIsOpen(false)}
      >
        <Stack width="100%" inset="0 1rem 2rem 1rem">
          <AccountList
            isLoadingBalance={isLoadingBalance}
            balanceInfos={accountInfos ?? []}
            accounts={paraAccounts}
            activeAccount={paraAccount}
          />
          {isEmpty(paraAccounts) && (
            <Button
              block
              onClick={createParaAccountHandle}
              loading={isCreating}
              disabled={isCreating || isNil(eoaAccount)}
            >
              Create Parallel Account
            </Button>
          )}
        </Stack>
      </MobileBottomDrawer>
    </Stack>
  );
});
