import { FC, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Modal } from '@parallel-mono/components';

import { ClaimETHValidatorModal } from './ClaimETHValidatorModal/ClaimETHValidatorModal';

type ClaimETHValidatorContextValue = {
  claim: (tokenId: number) => void;
};
const ClaimETHValidatorContext = createContext<ClaimETHValidatorContextValue>({
  claim: () => {
    throw new Error('not implemented yet');
  }
});

export const ClaimETHValidatorProvider: FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [claimingTokenId, setClaimingTokenId] = useState<number>();

  const handleClaim = useCallback((tokenId: number) => {
    setClaimingTokenId(tokenId);
    setIsModalOpen(true);
  }, []);

  const value = useMemo(
    () => ({
      claim: handleClaim
    }),
    [handleClaim]
  );

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <ClaimETHValidatorContext.Provider value={value}>
      {children}
      <Modal size="500px" title="Claim Rewards" onClose={closeModal} isOpen={isModalOpen}>
        {claimingTokenId && (
          <ClaimETHValidatorModal tokenId={claimingTokenId} onClose={closeModal} />
        )}
      </Modal>
    </ClaimETHValidatorContext.Provider>
  );
};

export const useClaimETHValidator = () => useContext(ClaimETHValidatorContext);
