import { memo, useMemo, ReactNode, useState } from 'react';
import { InfoPanel } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';
import { Button, H3, Inline, Stack, StackProps, Text } from '@parallel-mono/components';
import styled from 'styled-components';
import { Maybe } from 'graphql/jsutils/Maybe';
import BigNumber from 'bignumber.js';
import { StakingType } from 'paraspace-utilities-contract-helpers';

import { ApeCoinListing, ApeListing, BakcListing } from '../../../types';

import { ContractBanner } from '@/apps/paraspace/pages/ApePairing/pages/P2PStaking/components/ContractBanner/ContractBanner';
import { useApeStaking } from '@/apps/paraspace/pages/hooks/ApeStaking/useApeStaking';
import useAsyncEffect from '@/apps/paraspace/hooks/useAsyncEffect';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';

type UnmatchFormProps = Omit<StackProps, 'children'> & {
  account: string;
  apeListing: ApeListing;
  apeCoinListing: ApeCoinListing;
  bakcListing: Maybe<BakcListing>;
  disabled: boolean;
  onSubmit?: () => void;
};

const CenteredText = styled(Text)`
  text-align: center;
`;

export const UnmatchForm = memo(
  ({
    account,
    apeListing,
    apeCoinListing,
    bakcListing,
    disabled,
    onSubmit,
    ...others
  }: UnmatchFormProps) => {
    const { nftInfoMap } = useMMProvider();
    const p2pPairingAddress = useMemo(() => nftInfoMap?.P2PPairStaking?.address, [nftInfoMap]);
    const [pendingRewards, setPendingRewards] = useState<BigNumber>();
    const { getStakingInfoByTokenInfo } = useApeStaking();

    useAsyncEffect(async () => {
      const { stakingType } = apeListing;
      if (stakingType === StakingType.BAKCPairStaking) {
        const stakingPairingInfo = await getStakingInfoByTokenInfo(
          bakcListing?.stakingPool!,
          (bakcListing?.tokenId ?? '').toString(),
          p2pPairingAddress
        );
        setPendingRewards(stakingPairingInfo?.pendingRewards);
      } else {
        const stakingPairingInfo = await getStakingInfoByTokenInfo(
          apeListing.stakingPool!,
          apeListing.tokenId.toString(),
          p2pPairingAddress
        );
        setPendingRewards(stakingPairingInfo?.pendingRewards);
      }
    }, [p2pPairingAddress, apeListing, getStakingInfoByTokenInfo, apeListing?.stakingPool]);

    const isOwnApe = apeListing.offerer === account;
    const isOwnApeCoin = apeCoinListing.offerer === account;
    const isOwnBakc = bakcListing?.offerer === account;

    const infos = useMemo(() => {
      let result: { title: ReactNode; value: ReactNode }[] = [];
      if (isOwnApe || isOwnBakc) {
        result = [
          ...result,
          {
            title: 'Withdraw NFT',
            value: (
              <Text fontWeight="bold">
                {isOwnApe && !isOwnBakc && `1 ${apeListing.symbol}`}
                {!isOwnApe && isOwnBakc && `1 ${bakcListing?.symbol}`}
                {isOwnApe && isOwnBakc && `1 ${apeListing.symbol} + 1 ${bakcListing?.symbol}`}
              </Text>
            )
          }
        ];
      }
      if (isOwnApeCoin) {
        result = [
          ...result,
          {
            title: 'Withdraw Amount',
            value: <Text fontWeight="bold">{formatNumber(apeCoinListing.amount ?? 0)} APE</Text>
          }
        ];
      }
      return [
        ...result,
        {
          title: 'Pending Rewards',
          value: <Text fontWeight="bold">{formatNumber(pendingRewards ?? 0)} APE</Text>
        }
      ];
    }, [
      isOwnApe,
      isOwnBakc,
      isOwnApeCoin,
      pendingRewards,
      apeListing.symbol,
      bakcListing?.symbol,
      apeCoinListing.amount
    ]);
    return (
      <Stack {...others}>
        <ContractBanner
          apeListing={apeListing}
          apeCoinListing={apeCoinListing}
          bakcListing={bakcListing ?? null}
          account={account}
        />
        <Stack gap="0.25rem">
          <Inline justifyContent="center">
            <H3>Calling The Party Off?</H3>
          </Inline>
          <CenteredText skin="secondary">
            Once withdrawn, you and everyone in this co-stake will stop earning rewards. What you
            have earned might still be in the Auto Compound pool for compound interest, which you
            can withdraw at any time.
          </CenteredText>
        </Stack>
        {infos.length > 0 && <InfoPanel infos={infos} skin="primary" />}
        <Button block size="large" skin="primary" onClick={onSubmit} disabled={disabled}>
          Confirm
        </Button>
      </Stack>
    );
  }
);
