import BigNumberJs from 'bignumber.js';
import { memo } from 'react';
import { BigNumber } from 'ethers';
import { ERC721Symbol, Network } from 'paraspace-configs-v2';

import type { ContractName, TokenCategory } from '@/apps/paraspace/config';

export { ERC721Symbol };

export enum ERC20Symbol {
  BLUR = 'BLUR',
  SAPE = 'SAPE',
  ETH = 'ETH',
  WETH = 'WETH',
  DAI = 'DAI',
  USDC = 'USDC',
  USDT = 'USDT',
  APE = 'APE',
  WBTC = 'WBTC',
  STETH = 'STETH',
  WSTETH = 'WSTETH',
  AWETH = 'AWETH',
  AUSDC = 'AETHUSDC',
  CAPE = 'CAPE',
  UBAYC = 'ΜBAYC', // 'µBAYC uppercase'
  UPPG = 'ΜPPG', // 'µPPG uppercase'

  CBETH = 'CBETH',
  RETH = 'RETH',
  FRAX = 'FRAX',
  ARB = 'ARB',
  GMX = 'GMX',
  LINK = 'LINK',
  UNI = 'UNI',
  AAVE = 'AAVE',
  RDNT = 'RDNT',

  MATIC = 'MATIC',
  STMATIC = 'STMATIC',
  CRV = 'CRV',
  BAL = 'BAL',
  WMATIC = 'WMATIC',

  // moonbeam supported erc20
  GLMR = 'GLMR',
  WGLMR = 'WGLMR',
  XCDOT = 'XCDOT',
  XCUSDT = 'XCUSDT',
  STDOT = 'STDOT',

  // zksync USDC
  'USDC.E' = 'USDC.E',

  PARA = 'PARA'
}

export type UniswapSpecificInfo = {
  liquidity: BigNumberJs;
  liquidityToken0Amount: BigNumberJs;
  liquidityToken1Amount: BigNumberJs;
  lpFeeToken0Amount: BigNumberJs;
  lpFeeToken1Amount: BigNumberJs;

  token0ConvertToken1MinPrice: BigNumberJs;
  token0ConvertToken1MaxPrice: BigNumberJs;
  token0ConvertToken1CurrentPrice: BigNumberJs;
  token1ConvertToken0MinPrice: BigNumberJs;
  token1ConvertToken0MaxPrice: BigNumberJs;
  token1ConvertToken0CurrentPrice: BigNumberJs;

  token0: string;
  token1: string;
  token0Symbol: ERC20Symbol;
  token1Symbol: ERC20Symbol;

  feeRate: BigNumberJs;
  isInRange: boolean;
  isClosed: boolean;
};

export enum StakeFishState {
  PreDeposit,
  PostDeposit,
  Active,
  ExitRequested,
  Exited,
  Withdrawn,
  Burnable
}

export type StakeFishNTokenData = {
  feePoolAddress: string;
  nftArtUrl: string;
  pendingFeePoolReward: [BigNumber, BigNumber];
  protocolFee: BigNumber;
  pubkey: string;
  stateHistory: {
    state: number;
    userData: string;
    changedAt: BigNumber;
  }[];
  validatorIndex: BigNumber;
  withdrawnBalance: BigNumber;
};

export type StakeFishSpecificInfo = {
  withdrawnBalance: BigNumberJs;
  nftArtUrl: string;
  pubkey: string;
  validatorIndex: BigNumberJs;
  state: number;
  unclaimedPendingReward: BigNumberJs;
  claimedPendingReward: BigNumberJs;
};

export type TimeLockStrategyData = {
  lastResetTimestamp: number;
  maxWaitTime: number;
  midThreshold: BigNumberJs;
  midWaitTime: number;
  minThreshold: BigNumberJs;
  minWaitTime: number;
  period: number;
  poolPeriodLimit: BigNumberJs;
  poolPeriodWaitTime: number;
  totalAmountInCurrentPeriod: BigNumberJs;
};

export type BasicNFTSpecificInfo = {
  priceInUsd: BigNumberJs;
  priceInETH: BigNumberJs;
  baseLTVasCollateral: BigNumberJs;
  reserveLiquidationThreshold: BigNumberJs;
};
export type NFTSpecificInfo = BasicNFTSpecificInfo & UniswapSpecificInfo & StakeFishSpecificInfo;
export interface TokenInfo<T = string> {
  displayName: string;
  collectionName: string;
  symbol: T;
  address: string;
  variableDebtTokenAddress: string;
  xTokenAddress: string;
  priceInUsd: BigNumberJs;
  priceInETH: BigNumberJs;
  supplyApyRate: BigNumberJs;
  liquidityIndex: BigNumberJs;
  liquidityRate: BigNumberJs;
  lastUpdateTimestamp: number;
  variableBorrowIndex: BigNumberJs;
  supplyRewardRate: BigNumberJs;
  borrowApyRate: BigNumberJs;
  borrowRewardRate: BigNumberJs;
  availableLiquidity: BigNumberJs;
  borrowingEnabled: boolean;
  borrowUp: BigNumberJs;
  baseLTVasCollateral: BigNumberJs;
  reserveLiquidationThreshold: BigNumberJs;
  decimals: number;
  totalLiquidityUSD: BigNumberJs;
  totalDebt: BigNumberJs;
  totalDebtUSD: BigNumberJs;
  hasTokenSpecificInfos: boolean;
  timeLockStrategyData: TimeLockStrategyData;
  tokenSpecificInfos: Record<string, NFTSpecificInfo>;
}
export type UserERC20BalanceInfo = {
  balance: BigNumberJs;
};
export type UserERC20PositionInfo = {
  suppliedAmount: BigNumberJs;
  suppliedAmountInUsd: BigNumberJs;
  borrowedAmount: BigNumberJs;
  borrowedAmountInUsd: BigNumberJs;
  usageAsCollateralEnabledOnUser: boolean;
};
export type UserNFTBalanceInfo = {
  balance: number[];
};
export type UserNFTPositionInfo = {
  auctionedTokens: number[];
  nftSuppliedList: number[];
  nftSuppliedBalanceInUsd: BigNumberJs;
  nftCollateralList: number[];
  nftAsCollateralBalanceInUsd: BigNumberJs;
  avgMultiplier: BigNumberJs;

  tokenTraitMultipliers: Record<string, BigNumberJs>;
};
export type UserNFTImageMap = Record<
  string,
  {
    disable?: boolean;
    name?: string;
    description?: string;
    image: string;
    imageThumbnail: string;
  }
>;

export type CollectionConfig = {
  symbol: ERC721Symbol;
  collectionName: string;
  contractName: ContractName;
  wrappedContractName?: ContractName;
  unlisted?: boolean;
  nftEnumerableType: number;
};

export enum Platform {
  OPENSEA = 'OPENSEA',
  LOOKSRARE = 'LOOKSRARE',
  PARASPACE = 'PARASPACE'
}

export enum FetchingStatus {
  INIT = 'INIT',
  FETCHING = 'FETCHING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL'
}

export type ApeStakingTokenSymbol = ERC721Symbol.BAYC | ERC721Symbol.MAYC | ERC721Symbol.BAKC;
export type ApeStakingMainTokenSymbol = ERC721Symbol.BAYC | ERC721Symbol.MAYC;
export type ApeStakingMainAssetSymbol = ApeStakingTokenSymbol | ERC20Symbol.APE;

export type ERC20Info = TokenInfo<ERC20Symbol> &
  Partial<UserERC20BalanceInfo & UserERC20PositionInfo> & {
    isNativeTokenOrDerivatives?: boolean;
    isNativeToken?: boolean;
    isWrappedNativeToken?: boolean;
    tokenCategory?: TokenCategory;
  };
export type ERC20InfoMap = Record<string, ERC20Info>;

export type NFTInfo = TokenInfo<ERC721Symbol> &
  Partial<UserNFTBalanceInfo & UserNFTPositionInfo> & { imageMap: UserNFTImageMap };
export type NFTInfoMap = Record<string, NFTInfo>;

export type ERC1155TokenInfo = {
  balance: BigNumberJs;
  tokenId: number;
};

export enum ERC1155Symbol {
  VALIDATOR_NFT = 'VALIDATOR_NFT'
}

export type SupportedNetworks =
  | Network.MAINNET
  | Network.SEPOLIA
  | Network.ZKSYNC_ERA
  | Network.ZKSYNC_GOERLI
  | Network.ARBITRUM_GOERLI
  | Network.ARBITRUM_ONE
  | Network.PARALLEL_L3_TESTNET;

export const genericMemo: <C>(c: C) => C = memo;

export type WalletType = 'EOA' | 'AA';
