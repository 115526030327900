import { FC, memo, useMemo, useState, useCallback } from 'react';
import BigNumber from 'bignumber.js';

import { StakingApeProps } from '../../../hook/useLegacyOfficialApeStaking';
import { useOfficialApeStaking } from '../../../hook';
import { useStakeInfoList } from '../../../StakeInfoListProvider/StakeInfoListProvider';
import { OFFICIAL_STAKE_CONFIG } from '../../../config';

import { useERC20 } from '@/apps/paraspace/hooks';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';
import { formatBalance } from '@/apps/paraspace/utils/format';
import {
  DepositERC20Submitter,
  DepositERC721Submitter,
  Stepper
} from '@/apps/paraspace/components';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { useWeb3Context } from '@/apps/paraspace/contexts';

interface StepProps {
  onError: () => void;
  onSuccess: () => void;
  formData: StakingApeProps;
}

const StakingStep: FC<StepProps> = ({ onError, onSuccess, formData }) => {
  const { env } = useWeb3Context();
  const { createApproval } = useERC20(OFFICIAL_STAKE_CONFIG[env].APE_COIN_ADDR);
  const { genStakingApeTx } = useOfficialApeStaking();

  const { bakcInfoList } = useStakeInfoList();

  const { type, tokenId, cashAmount: amount, apeSource, apeCoinSource } = formData;

  const batchTxs = useMemo(() => {
    const bakcInfo = bakcInfoList.find(item => item.tokenId === tokenId && item.symbol === type);
    const tokenInfo = bakcInfo
      ? {
          tokenId,
          amount,
          mainTokenId: bakcInfo.mainTokenId,
          mainTokenSymbol: bakcInfo.mainTokenSymbol
        }
      : { tokenId, amount };

    return [
      {
        tx: createApproval({ spender: OFFICIAL_STAKE_CONFIG[env].APE_STAKING_ADDR, amount })
      },
      {
        tx: genStakingApeTx(type, [tokenInfo])
      }
    ];
  }, [createApproval, genStakingApeTx, bakcInfoList, tokenId, amount, type, env]);

  const [step, setStep] = useState(0);

  const handleNext = useCallback(() => {
    setStep(curr => curr + 1);
  }, []);

  const { nftInfoMap, erc20InfoMap } = useMMProvider();
  const apeAddress = nftInfoMap[type]?.address ?? '';
  const apeCoinAddress = erc20InfoMap[ERC20Symbol.APE]?.address ?? '';
  const steps = useMemo(
    () => [
      {
        description: `Deposit ${type} #${tokenId}`,
        content: (
          <DepositERC721Submitter
            onFinish={handleNext}
            onError={onError}
            formData={{
              tokenId,
              address: apeAddress
            }}
          />
        ),
        skip: apeSource !== 'EOA'
      },
      {
        description: `Deposit ${formatBalance(amount)} ape coin`,
        content: (
          <DepositERC20Submitter
            onFinish={handleNext}
            onError={onError}
            formData={{
              value: new BigNumber(amount),
              address: apeCoinAddress,
              symbol: ERC20Symbol.APE
            }}
          />
        ),
        skip: apeCoinSource !== 'EOA'
      },
      {
        description: 'Staking',
        content: (
          <BatchTransactionsSubmitter
            batchTxs={batchTxs}
            onError={onError}
            onFinish={onSuccess}
            inProgressMessage={`Staking ${formatBalance(amount)} APE`}
          />
        )
      }
    ],
    [
      onSuccess,
      onError,
      batchTxs,
      amount,
      tokenId,
      apeAddress,
      handleNext,
      type,
      apeSource,
      apeCoinSource,
      apeCoinAddress
    ]
  );

  return <Stepper steps={steps} step={step} />;
};

export default memo(StakingStep);
