import {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { useThemeConfig } from '@parallel-mono/components';
import {
  AppPackage,
  AuthenticationType,
  AppStorage,
  OrganizedAddAssetsContext,
  WalletNameMap,
  WalletIconMap,
  WalletTypeEnum
} from 'parax-sdk';
import { useNavigate } from 'react-router-dom';

import { useEOAProvider, useAAProvider, useAppsContext } from '../../../../contexts';

import { useWalletModalContext } from '@/apps/parax/components';
import { env } from '@/env';
import { ThemedString } from '@/apps/consts';
import { useCreateParaAccount } from '@/apps/parax/hooks';
import { getChainKey } from '@/apps/parax/config';

type AppContainerProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  app: AppPackage;
  domainApp?: { icon: ThemedString; path?: string };
  baseRoute: string;
};

export const AppContainer = memo(({ app, baseRoute, domainApp, ...others }: AppContainerProps) => {
  const { provider, account } = useAAProvider();
  const {
    account: EOAAccount,
    provider: EOAProvider,
    chainId,
    isUsingUserWallet,
    walletType
  } = useEOAProvider();
  const { colorMode } = useThemeConfig();
  const { setWalletModalOpen } = useWalletModalContext();
  const { createParaAccountHandle } = useCreateParaAccount();
  const navigate = useNavigate();

  const handleConnectWallet = useCallback(() => {
    if (!isUsingUserWallet) {
      setWalletModalOpen(true);
    } else if (!account) {
      createParaAccountHandle();
    }
  }, [isUsingUserWallet, account, setWalletModalOpen, createParaAccountHandle]);

  const handleAddAssets = useCallback(
    async (_: OrganizedAddAssetsContext) => {
      const chainPath = getChainKey(chainId);
      navigate(`/${chainPath}/apps/wallet`);
    },
    [chainId, navigate]
  );

  const { updateRoutes } = useAppsContext();

  const renderContainerRef = useRef<HTMLDivElement>(null);

  const EOAAuthenticationMeta = useMemo(
    () => ({
      walletType,
      walletIcon: walletType === WalletTypeEnum.NETWORK ? '' : WalletIconMap[walletType],
      walletName: WalletNameMap[walletType],
      account: EOAAccount,
      provider: EOAProvider
    }),
    [EOAAccount, EOAProvider, walletType]
  );

  useLayoutEffect(() => {
    const { render } = app;
    const dom = renderContainerRef.current;
    render({
      state: {
        colorMode,
        provider,
        chainId,
        account: account ?? '',
        baseRoute,
        authentication: {
          type: AuthenticationType.EOA,
          meta: EOAAuthenticationMeta
        }
      },
      env,
      container: dom!,
      apis: {
        connectWallet: handleConnectWallet,
        addAssets: handleAddAssets,
        updateRoutes
      },
      localStorage: new AppStorage(app.name)
    });
  }, [
    app,
    baseRoute,
    colorMode,
    provider,
    chainId,
    account,
    handleConnectWallet,
    handleAddAssets,
    updateRoutes,
    EOAAccount,
    walletType,
    EOAProvider,
    EOAAuthenticationMeta,
    domainApp?.path
  ]);

  useEffect(() => {
    const dom = renderContainerRef.current;
    return () => {
      unmountComponentAtNode(dom!);
    };
  }, []);

  return <div ref={renderContainerRef} {...others} />;
});
