import { useMemo } from 'react';
import { BigNumber } from 'bignumber.js';

import { ActionTypeEnum } from '@/apps/paraspace/pages/hooks/useLendingSimulation';
import { ERC721Symbol, ERC20Symbol } from '@/apps/paraspace/typings';
import { isNFTinvolvedWithApeStaking } from '@/apps/paraspace/utils/isNFTinvolvedWithApeStaking';
import { zero } from '@/apps/paraspace/consts/values';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ApeTokenStakingInfo } from '@/apps/paraspace/pages/hooks/ApeStaking/types';
import { StakingInfo } from '@/apps/paraspace/pages/Credit/MoneyMarket/Modal/hook/useBatchApeStakingInfo';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';

export const useApeRelatedActions = ({
  symbol,
  stakingInfo,
  bakcStakingInfo,
  tokenIds
}: {
  symbol: ERC721Symbol;
  stakingInfo: Record<string, StakingInfo>;
  bakcStakingInfo: Record<string, StakingInfo> | Record<string, ApeTokenStakingInfo>;
  tokenIds: number[];
}) => {
  const { features } = useAppConfig();
  const { erc20InfoMap } = useMMProvider();

  const showApeStakingInfo = isNFTinvolvedWithApeStaking(symbol);

  const capeInfo = erc20InfoMap[ERC20Symbol.CAPE];
  const sAPETokenInfo = erc20InfoMap[ERC20Symbol.SAPE];

  const apeStakingEnabled = useMemo(() => features.includes(Feature.ApeStaking), [features]);

  // 2. actions related to sAPE and cAPE
  // withdraw BAYC/MAYC = withdraw nft + withdraw sAPE + repay cAPE + supply cAPE left
  return useMemo(() => {
    // TODO refactor this to eliminate the knowledge of arbitrum
    if (!apeStakingEnabled) {
      return [];
    }
    let sApeWithdrawnInTotal = new BigNumber(0);
    const cApeBorrowedInTotal = capeInfo?.borrowedAmount || new BigNumber(0);
    return showApeStakingInfo && symbol !== ERC721Symbol.BAKC
      ? tokenIds
          .map(id => {
            //  main token staking & rewards
            const mainStakingAndRewards =
              stakingInfo[id]?.stakedAmount?.plus(stakingInfo[id]?.pendingRewards ?? zero) ||
              new BigNumber(0);
            // bakc staking part
            const bakcStaking = bakcStakingInfo[id]?.stakedAmount || new BigNumber(0);
            const sAPEInTotal = mainStakingAndRewards.plus(bakcStaking);
            sApeWithdrawnInTotal = sApeWithdrawnInTotal.plus(sAPEInTotal);

            // 2.1 sAPE withdraw
            return {
              type: ActionTypeEnum.WITHDRAW,
              targetAsset: {
                id: ERC20Symbol.SAPE,
                value: sAPETokenInfo.priceInUsd.times(sAPEInTotal),
                LTV: sAPETokenInfo.baseLTVasCollateral,
                reserveLiquidationThreshold: sAPETokenInfo.reserveLiquidationThreshold
              }
            };
          })
          .concat(
            [
              // 2.2 cAPE repay
              {
                type: ActionTypeEnum.REPAY,
                targetAsset: {
                  id: ERC20Symbol.CAPE,
                  value: capeInfo.priceInUsd.times(
                    BigNumber.min(sApeWithdrawnInTotal, cApeBorrowedInTotal)
                  ),
                  LTV: capeInfo.baseLTVasCollateral,
                  reserveLiquidationThreshold: capeInfo.reserveLiquidationThreshold
                }
              },
              // 2.3 cAPE supply
              sApeWithdrawnInTotal.gt(cApeBorrowedInTotal)
                ? {
                    type: ActionTypeEnum.SUPPLY,
                    targetAsset: {
                      id: ERC20Symbol.CAPE,
                      value: capeInfo.priceInUsd.times(
                        sApeWithdrawnInTotal.minus(cApeBorrowedInTotal)
                      ),
                      LTV: capeInfo.baseLTVasCollateral,
                      reserveLiquidationThreshold: capeInfo.reserveLiquidationThreshold
                    }
                  }
                : null
            ].filter(v => v) as any[]
          )
      : [];
  }, [
    capeInfo?.borrowedAmount,
    capeInfo?.priceInUsd,
    capeInfo?.baseLTVasCollateral,
    capeInfo?.reserveLiquidationThreshold,
    showApeStakingInfo,
    symbol,
    tokenIds,
    stakingInfo,
    bakcStakingInfo,
    sAPETokenInfo?.priceInUsd,
    sAPETokenInfo?.baseLTVasCollateral,
    sAPETokenInfo?.reserveLiquidationThreshold,
    apeStakingEnabled
  ]);
};
