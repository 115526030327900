import { BigNumber } from 'bignumber.js';
import { truncate } from 'lodash';
import { formatNumber as formatNumberUtil } from '@parallel-mono/utils';

import {
  DEFAULT_PERCENT_DECIMAL,
  HIDDEN_DECIMAL_PERCENT,
  MAX_PERCENT,
  MAXIMUM_BALANCE_DECIMALS,
  MIN_PERCENT_DECIMAL
} from '@/apps/paraspace/pages/config';

export const MIN_BALANCE_THRESHOLD = 0.0001;
export const FLOAT_SCALING_FACTOR = 10000;
export const PERCENTAGE_SCALING_FACTOR = 100;
export const MIN_VALUE_THRESHOLD = 0.01;
const MIN_PERCENTAGE_THRESHOLD = 0.0001;
export const FEE_POINT_DECIMAL = 4;

const formatBalance = (
  number: number | BigNumber | null | undefined,
  decimal: number = MAXIMUM_BALANCE_DECIMALS,
  min: number = MIN_BALANCE_THRESHOLD
) =>
  formatNumberUtil(number ?? 0, {
    decimal,
    threshold: {
      min
    }
  });

const formatToCurrency = (
  number: number | BigNumber,
  {
    decimal = 2,
    min = 10 ** -decimal
  }: {
    decimal?: number;
    min?: number;
  } = {}
) => {
  return formatNumberUtil(number, {
    output: 'currency',
    decimal,
    threshold: {
      min
    }
  });
};

const formatToPercentage = (number: number | BigNumber, decimal = 2) => {
  return formatNumberUtil(number, {
    decimal,
    output: 'percent',
    threshold: BigNumber(number).lt(0)
      ? {
          max: -MIN_PERCENTAGE_THRESHOLD
        }
      : {
          min: MIN_PERCENTAGE_THRESHOLD
        }
  });
};

const truncateTextMid = (text: string, start = 4, end = 4) =>
  text.length > start + end ? text.replace(text.substring(start, text.length - end), '...') : text;

const truncateTextEnd = (text: string, length = 16) => truncate(text, { length: length + 3 });

const truncateAddress = (text: string, start = 2, end = 8) => text.substring(start, end + 1);
const formatMillisecondToDuration = (millisecond: number) => {
  const mins = Math.floor((millisecond / 1000 / 60) % 60);
  const hours = Math.floor((millisecond / 1000 / 60 / 60) % 24);
  const days = Math.floor(millisecond / 1000 / 60 / 60 / 24);

  const unit = ['d', 'h', 'm'];

  return days
    ? `${days} ${unit[0]} ${hours} ${unit[1]} ${mins} ${unit[2]}`
    : `${hours} ${unit[1]} ${mins} ${unit[2]}`;
};

const formatSecondsWithRound = (seconds: number) => {
  if (seconds < 60 * 60 * 24) {
    return `${Math.round(seconds / 60 / 60)} hours`;
  }
  return `${Math.round(seconds / 60 / 60 / 24)} days`;
};

const toPercentageCompoundAPY = (apy: BigNumber) => {
  return formatNumberUtil(apy, {
    output: 'percent',
    decimal:
      apy.toNumber() >= HIDDEN_DECIMAL_PERCENT ? MIN_PERCENT_DECIMAL : DEFAULT_PERCENT_DECIMAL,
    threshold: {
      max: MAX_PERCENT
    }
  });
};

const toPercentageByFactor = (value: BigNumber | number) => {
  if (typeof value === 'number') {
    return formatToPercentage(BigNumber(value).div(FLOAT_SCALING_FACTOR));
  }
  return formatToPercentage(value.div(FLOAT_SCALING_FACTOR));
};

export {
  formatBalance,
  formatToCurrency,
  formatToPercentage,
  truncateTextMid,
  truncateTextEnd,
  truncateAddress,
  formatMillisecondToDuration,
  formatSecondsWithRound,
  toPercentageCompoundAPY,
  toPercentageByFactor
};
