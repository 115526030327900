import { NETWORK_NAME_MAP, Network } from 'paraspace-configs-v2';

import { SupportedNetworks } from '../typings';

export type NetworkConfig = {
  name: string;
  publicJsonRPCUrl: string[]; // public rpc used if not private found, and used to add specific network to wallets if user don't have them. Normally with slow rates
  /**
   * When this is set withdrawals will automatically be unwrapped
   */
  // function returning a link to etherscan
  explorerLink: string[];
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
};

export const networkConfigs: { [key in SupportedNetworks]: NetworkConfig } = {
  [Network.SEPOLIA]: {
    name: 'Sepolia',
    publicJsonRPCUrl: [
      'https://1rpc.io/sepolia',
      'wss://ethereum-sepolia-rpc.publicnode.com',
      'https://eth-sepolia.public.blastapi.io',
      'https://sepolia.drpc.org'
    ],
    explorerLink: ['https://sepolia.etherscan.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.MAINNET]: {
    name: 'Ethereum',
    publicJsonRPCUrl: [
      'https://eth-mainnet.g.alchemy.com/v2/PsbsgznLjebAgdpVEe-IKZ2vwJbVJPQV',
      'https://eth-mainnet.g.alchemy.com/v2/rKDZRHKOwLwB45qMDw-tP4VpnZcoQG1A',
      'https://eth-mainnet.g.alchemy.com/v2/-frGB-7NjlFF3YHr7-PnX1Qu8KAro2GZ',
      'https://eth-mainnet.g.alchemy.com/v2/nKJt9usZT3Erev3Ps6Oq3mRxO34GZcwe',
      'https://eth-mainnet.g.alchemy.com/v2/-HdlfRm8ar43DEM4fB-qLASD-m5XDKve',
      'https://rpc.flashbots.net'
    ],
    explorerLink: ['https://etherscan.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.ZKSYNC_GOERLI]: {
    name: NETWORK_NAME_MAP[Network.ZKSYNC_GOERLI],
    publicJsonRPCUrl: [
      'https://zksync-era-testnet.blockpi.network/v1/rpc/public',
      'https://testnet.era.zksync.dev'
    ],
    explorerLink: ['https://goerli.explorer.zksync.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.ZKSYNC_ERA]: {
    name: NETWORK_NAME_MAP[Network.ZKSYNC_ERA],
    publicJsonRPCUrl: ['https://mainnet.era.zksync.io'],
    explorerLink: ['https://explorer.zksync.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.ARBITRUM_ONE]: {
    name: NETWORK_NAME_MAP[Network.ARBITRUM_ONE],
    publicJsonRPCUrl: ['https://arb1.arbitrum.io/rpc'],
    explorerLink: ['https://explorer.arbitrum.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.ARBITRUM_GOERLI]: {
    name: NETWORK_NAME_MAP[Network.ARBITRUM_GOERLI],
    publicJsonRPCUrl: ['https://goerli-rollup.arbitrum.io/rpc'],
    explorerLink: ['https://goerli.arbiscan.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.PARALLEL_L3_TESTNET]: {
    name: NETWORK_NAME_MAP[Network.PARALLEL_L3_TESTNET],
    publicJsonRPCUrl: ['https://rpc-accused-coffee-koala-b9fn1dik76.t.conduit.xyz'],
    explorerLink: ['https://explorerl2new-accused-coffee-koala-b9fn1dik76.t.conduit.xyz'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  }
};
