import { H5, Toggle } from '@parallel-mono/components';
import { useCallback, useMemo, useState } from 'react';

import { useWeb3Context } from '../../contexts';

export const useClaimToEOAToggle = (defaultValue = true) => {
  const [shouldClaimToEOA, setShouldClaimToEOA] = useState(defaultValue);
  const {
    authentication: {
      meta: { walletType }
    }
  } = useWeb3Context();

  const handleToggleChange = useCallback(value => {
    setShouldClaimToEOA(value);
  }, []);

  const toggleComponent = useMemo(() => {
    return (
      <>
        <H5>Claim to your {walletType} address</H5>
        <Toggle checked={shouldClaimToEOA} onChange={handleToggleChange} />
      </>
    );
  }, [handleToggleChange, shouldClaimToEOA, walletType]);

  return { shouldClaimToEOA, toggleComponent };
};
