import { FC, memo } from 'react';
import styled from 'styled-components';
import { Responsive } from '@parallel-mono/components';

import { ApeList, SideBar } from './components';

import { StakeInfoListProvider as OfficialStakeInfoListProvider } from '@/apps/paraspace/pages/OfficialPairing/StakeInfoListProvider/StakeInfoListProvider';
import { ApeStakingManagerProvider as OfficialApeStakingManagerProvider } from '@/apps/paraspace/pages/OfficialPairing/ApeStakingManagerProvider';

const Container = styled(Responsive)`
  position: relative;
  width: 100%;
`;

const StyledSideBar = styled(SideBar)`
  width: 100%;
  height: fit-content;
  ${({ theme }) => theme.breakpoints.up('desktop')`
    flex: 1;
    position: sticky;
    top: calc(var(--header-height-pc) + 1rem);
  `}
`;

const StyledApeList = styled(ApeList)`
  width: 100%;
  order: 1;
  ${({ theme }) => theme.breakpoints.up('desktop')`
    order: initial;
    flex: 2;
  `}
`;

export const MyApes: FC = memo(() => {
  return (
    <OfficialStakeInfoListProvider>
      <OfficialApeStakingManagerProvider>
        <Container>
          <StyledApeList />
          <StyledSideBar />
        </Container>
      </OfficialApeStakingManagerProvider>
    </OfficialStakeInfoListProvider>
  );
});
