import BigNumberJs from 'bignumber.js';
import { isNil } from 'lodash';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { MINIMUM_LISTED_PRICE } from '@/apps/paraspace/pages/config';

export const useListingValidate = ({
  listedAmount,
  inputValue
}: {
  listedAmount: BigNumberJs;
  inputValue: Maybe<number>;
}) => {
  if (isNil(inputValue)) {
    return null;
  }
  if (listedAmount.lt(inputValue)) {
    return 'The new sale price must be lower than the current price. If you need to set a higher price, cancel the listing and re-list.';
  }
  if (inputValue < MINIMUM_LISTED_PRICE) {
    return `Minimum input ${MINIMUM_LISTED_PRICE}.`;
  }
  return null;
};
