import { memo, useCallback } from 'react';
import { Modal, ModalProps } from '@parallel-mono/components';
import ReactGA from 'react-ga4';

import { ApeCoinListing, ApeListing, BakcListing } from '../../../types';
import { JoinListingSuccessState } from '../components';

import { JoinBAKCPairListingsForm } from './JoinBAKCPairListingsForm/JoinBAKCPairListingsForm';
import { JoinBAKCPairListingsFormData } from './types';
import { JoinBAKCPairListingsFormSubmitter } from './JoinBAKCPairListingsFormSubmitter';
import { ErrorState } from './ErrorState';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { Maybe } from '@/apps/paraspace/typings/basic';
import {
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure
} from '@/apps/paraspace/components';
import { WalletType } from '@/apps/paraspace/typings';

export type JoinBAKCPairListingsModalProps = Omit<ModalProps, 'children'> & {
  apeListing: Maybe<ApeListing>;
  apeCoinListing: Maybe<ApeCoinListing>;
  bakcListing: Maybe<BakcListing>;
  walletType: WalletType;
  onFinish?: () => void;
  onError?: () => void;
};

export const JoinBAKCPairListingsModal = memo(
  ({
    isOpen,
    walletType,
    onFinish,
    onError,
    onClose,
    apeListing,
    apeCoinListing,
    bakcListing,
    ...others
  }: JoinBAKCPairListingsModalProps) => {
    const finalHandleFinish = useCallback(() => {
      ReactGA.event({
        action: 'apestaking',
        category: 'p2pstaking',
        label: 'join'
      });
      onFinish?.();
    }, [onFinish]);

    const {
      phase,
      submittedFormData,
      submittingResult: joinResult,
      submittingError,
      handleFormSubmit,
      handleSubmitFailed,
      handleSubmitSuccess
    } = useCollectAndSubmitProcedure<
      JoinBAKCPairListingsFormData,
      {
        apeListing: ApeListing;
        apeCoinListing: ApeCoinListing;
        bakcListing: BakcListing;
      },
      {
        apeListing: Partial<ApeListing>;
        apeCoinListing: Partial<ApeCoinListing>;
        bakcListing: Partial<BakcListing>;
      }
    >({
      onError,
      onFinish: finalHandleFinish,
      running: isOpen
    });

    const { account } = useWeb3Context();

    return (
      <Modal
        closeOnBackdropClick={false}
        isOpen={isOpen}
        title="Join Staking"
        onClose={onClose}
        {...others}
      >
        {phase === CollectAndSubmitProcedurePhase.Collecting && (
          <JoinBAKCPairListingsForm
            apeListing={apeListing}
            apeCoinListing={apeCoinListing}
            bakcListing={bakcListing}
            walletType={walletType}
            onSubmit={handleFormSubmit}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Submitting && (
          <JoinBAKCPairListingsFormSubmitter
            formData={submittedFormData!}
            walletType={walletType}
            onFinish={handleSubmitSuccess}
            onError={handleSubmitFailed}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Failed && (
          <ErrorState
            account={account}
            apeCoinListing={submittingError!.apeCoinListing}
            apeListing={submittingError!.apeListing}
            bakcListing={submittingError!.bakcListing}
            onFinish={onClose}
          />
        )}
        {phase === CollectAndSubmitProcedurePhase.Success && (
          <JoinListingSuccessState
            apeCoinListing={joinResult!.apeCoinListing}
            apeListing={joinResult!.apeListing}
            bakcListing={joinResult!.bakcListing}
            onFinish={onClose}
          />
        )}
      </Modal>
    );
  }
);
