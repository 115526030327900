import { useMemo } from 'react';

import { formatApeCoinListing, formatApeListing } from '../../../hooks';
import { Position } from '../types';
import { ApeListing, BakcListing } from '../../../types';

import { useWeb3Context } from '@/apps/paraspace/contexts';
import { useGetP2PApeStakingPositionsQuery } from '@/apps/paraspace/generated/graphql';
import { useGetSymbolByContractAddress } from '@/apps/paraspace/hooks';

export const usePositions = () => {
  const { account } = useWeb3Context();

  const { data, loading, refetch } = useGetP2PApeStakingPositionsQuery({
    variables: {
      walletAddress: account
    }
  });

  const getSymbolByContractAddress = useGetSymbolByContractAddress();

  const positions: Position[] = useMemo(
    () =>
      data?.P2PApeStakingPositions?.map(position => ({
        apeListing: position.apeListing
          ? (formatApeListing(position.apeListing, getSymbolByContractAddress) as ApeListing)
          : null,
        bakcListing: position.bakcListing
          ? (formatApeListing(position.bakcListing, getSymbolByContractAddress) as BakcListing)
          : null,
        apeCoinListing: position.apeCoinListing
          ? formatApeCoinListing(position.apeCoinListing)
          : null,
        blockHeight: position.blockHeight ?? '',
        matched: position.matched,
        matchedHash: position.matchedHash ?? ''
      })) ?? [],
    [data?.P2PApeStakingPositions, getSymbolByContractAddress]
  );

  return { loading, refetch, positions };
};
