import { useCallback, useMemo } from 'react';
import { UiPoolDataProvider } from 'paraspace-utilities-contract-helpers';
import { formatReserves, formatUserSummary } from 'paraspace-utilities-math-utils';
import { DeploylessViewerClient } from 'deployless-view';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { useContractsMap } from '@/apps/paraspace/hooks';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { Network } from '@/apps/paraspace/config';

const PUNKS_NFT_TYPE = 2;

const useUserPositions = () => {
  const { provider, chainId } = useWeb3Context();
  const contracts = useContractsMap();

  const viewer = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new DeploylessViewerClient(provider);
  }, [provider]);

  const loadUserPositions = useCallback(
    async (account: string) => {
      if (!provider || !account) {
        return null;
      }
      const uiPoolData = new UiPoolDataProvider({
        uiPoolDataProviderAddress: contracts.UiPoolDataProvider,
        provider,
        chainId
      });

      const reserveData = await uiPoolData.getReservesHumanized({
        lendingPoolAddressProvider: contracts.PoolAddressesProvider
      });

      const userReservesData = await uiPoolData.getUserReservesHumanized({
        lendingPoolAddressProvider: contracts.PoolAddressesProvider,
        user: account,
        reservesDataHumanized: reserveData
      });

      const currentTimestamp = (new Date().getTime() / 1000).toFixed(0);
      const formatReservesData = formatReserves({
        reserves: reserveData.reservesData,
        currentTimestamp: Number(currentTimestamp),
        marketReferenceCurrencyDecimals:
          reserveData.baseCurrencyData.marketReferenceCurrencyDecimals,
        marketReferencePriceInUsd: reserveData.baseCurrencyData.marketReferenceCurrencyPriceInUsd
      });

      const formatUserSummaryData = formatUserSummary({
        currentTimestamp: Number(currentTimestamp),
        marketReferenceCurrencyDecimals:
          reserveData.baseCurrencyData.marketReferenceCurrencyDecimals,
        marketReferencePriceInUsd: reserveData.baseCurrencyData.marketReferenceCurrencyPriceInUsd,
        userReserves: userReservesData.userReserves,
        userEmodeCategoryId: userReservesData.userEmodeCategoryId,
        formattedReserves: formatReservesData
      });

      // For now the getPunksBalance is only supported on ethereum network.
      // TODO refactor it when the para-config is right.
      if ([Network.MAINNET, Network.SEPOLIA].includes(chainId)) {
        const rawData = await viewer!.getAllTokensByOwner(account, contracts.PUNKS, PUNKS_NFT_TYPE);
        const punksBalance = rawData.tokenInfos as number[];
        const wpunksUserReservesData = formatUserSummaryData.userReservesData.find(
          item => item.reserve.symbol === ERC721Symbol.WPUNKS
        );
        if (punksBalance && wpunksUserReservesData) {
          const formatUserSummaryDataWithPunks = {
            ...formatUserSummaryData,
            userReservesData: [
              ...formatUserSummaryData.userReservesData,
              {
                ...wpunksUserReservesData,
                ownedTokens: punksBalance,
                underlyingAsset: contracts.PUNKS,
                suppliedTokens: [],
                collaterizedTokens: [],
                auctionedTokens: [],
                collaterizedBalance: '0',
                scaledXTokenBalance: '0'
              }
            ]
          };
          return formatUserSummaryDataWithPunks;
        }
      }

      return formatUserSummaryData;
    },
    [
      provider,
      contracts.UiPoolDataProvider,
      contracts.PoolAddressesProvider,
      contracts.PUNKS,
      chainId,
      viewer
    ]
  );

  return { loadUserPositions };
};

export default useUserPositions;
