import { keyBy, pick, values } from 'lodash';
import {
  ChainId,
  ERC20Config,
  ERC20Symbol,
  arbitrumOneERC20Configs,
  arbitrumSepoliaERC20Configs,
  ethereumERC20Configs,
  mantaERC20Configs,
  merlinERC20Configs,
  parallelERC20Configs,
  parallelTestnetERC20Configs,
  sepoliaERC20Configs
} from '@parallel-utils/contracts-registry';
import { Environment } from 'parax-sdk';

import { SupportedChainId } from './networks';

import { env } from '@/env';

export const supportedERC20sMap: { [key in SupportedChainId]: ERC20Config<ERC20Symbol>[] } = {
  [ChainId.Sepolia]: values(
    pick(sepoliaERC20Configs, [
      ERC20Symbol.DAI,
      ERC20Symbol.WBTC,
      ERC20Symbol.USDC,
      ERC20Symbol.rsETH,
      ERC20Symbol.ezETH,
      ERC20Symbol.rswETH,
      ERC20Symbol.weETH,
      ERC20Symbol.pufETH,
      ERC20Symbol.wstETH,
      ERC20Symbol.rETH,
      ERC20Symbol.swETH,
      ERC20Symbol.ETHx,
      ERC20Symbol.LSETH,
      ERC20Symbol.OETH,
      ERC20Symbol.osETH,
      ERC20Symbol.LINK,
      ERC20Symbol.USDT,
      ERC20Symbol.AAVE
    ])
  ),
  [ChainId.Ethereum]: values(
    pick(ethereumERC20Configs, [
      ERC20Symbol.USDC,
      ERC20Symbol.USDT,
      ERC20Symbol.WBTC,
      ERC20Symbol.USDe,
      ERC20Symbol.rsETH,
      ERC20Symbol.ezETH,
      ERC20Symbol.rswETH,
      ERC20Symbol.weETH,
      ERC20Symbol.pufETH,
      ERC20Symbol.wstETH,
      ERC20Symbol.rETH,
      ERC20Symbol.swETH,
      ERC20Symbol.ETHx,
      ERC20Symbol.LSETH,
      ERC20Symbol.OETH,
      ERC20Symbol.osETH,
      ERC20Symbol.wUSDM,
      ERC20Symbol.PT_eETH,
      ERC20Symbol.PT_ezETH,
      ERC20Symbol.PT_rsETH
    ])
  ),
  [ChainId.ParallelTestnet]: values(
    pick(parallelTestnetERC20Configs, [
      ERC20Symbol.wstETH,
      ERC20Symbol.rETH,
      ERC20Symbol.swETH,
      ERC20Symbol.ETHx,
      ERC20Symbol.LSETH,
      ERC20Symbol.OETH,
      ERC20Symbol.uniETH,
      ERC20Symbol.osETH,
      ERC20Symbol.ankrETH,
      ERC20Symbol.rsETH,
      ERC20Symbol.ezETH,
      ERC20Symbol.rswETH,
      ERC20Symbol.weETH,
      ERC20Symbol.pufETH,
      ERC20Symbol.DAI,
      ERC20Symbol.LINK,
      ERC20Symbol.USDC,
      ERC20Symbol.WBTC,
      ERC20Symbol.WETH,
      ERC20Symbol.USDT,
      ERC20Symbol.AAVE,
      ERC20Symbol.ARB
    ])
  ),
  [ChainId.Parallel]: values(
    pick(parallelERC20Configs, [
      ERC20Symbol.USDC,
      ERC20Symbol.USDT,
      ERC20Symbol.WBTC,
      ERC20Symbol.USDe,
      ERC20Symbol.rsETH,
      ERC20Symbol.ezETH,
      ERC20Symbol.rswETH,
      ERC20Symbol.weETH,
      ERC20Symbol.pufETH,
      ERC20Symbol.wstETH,
      ERC20Symbol.rETH,
      ERC20Symbol.swETH,
      ERC20Symbol.ETHx,
      ERC20Symbol.LSETH,
      ERC20Symbol.OETH,
      ERC20Symbol.osETH,
      ERC20Symbol.PT_eETH,
      ERC20Symbol.PT_ezETH,
      ERC20Symbol.PT_rsETH,
      ERC20Symbol.ARB,
      ERC20Symbol.wUSDM,
      ERC20Symbol.MANTA,
      ERC20Symbol.STONE,
      ERC20Symbol.WETH,
      ERC20Symbol.M_STONE,
      ERC20Symbol.SolvBTC
    ])
  ),
  [ChainId.ArbitrumOne]: values(
    pick(arbitrumOneERC20Configs, [
      ERC20Symbol.USDC,
      ERC20Symbol.USDT,
      ERC20Symbol.WBTC,
      ERC20Symbol.ezETH,
      ERC20Symbol.weETH,
      ERC20Symbol.wstETH,
      ERC20Symbol.rETH,
      ERC20Symbol.ARB,
      ERC20Symbol.rsETH,
      ERC20Symbol.PT_rsETH
    ])
  ),
  [ChainId.ArbitrumSepolia]: values(
    pick(arbitrumSepoliaERC20Configs, [
      ERC20Symbol.USDC,
      ERC20Symbol.USDT,
      ERC20Symbol.WBTC,
      ERC20Symbol.ezETH,
      ERC20Symbol.weETH,
      ERC20Symbol.wstETH,
      ERC20Symbol.rETH
    ])
  ),
  [ChainId.Manta]: values(
    pick(mantaERC20Configs, [ERC20Symbol.wUSDM, ERC20Symbol.MANTA, ERC20Symbol.STONE])
  ),
  [ChainId.Merlin]: values(pick(merlinERC20Configs, [ERC20Symbol.M_STONE, ERC20Symbol.SolvBTC]))
};

export const parallelTokensMap = {
  [Environment.DEVELOPMENT]: keyBy(supportedERC20sMap[ChainId.ParallelTestnet], it => it.symbol),
  [Environment.STAGING]: keyBy(supportedERC20sMap[ChainId.ParallelTestnet], it => it.symbol),
  [Environment.PRODUCTION]: keyBy(supportedERC20sMap[ChainId.Parallel], it => it.symbol)
}[env];
