import { useCallback, useState, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { StakingType } from 'paraspace-utilities-contract-helpers';

import {
  ApeListing,
  ListingType,
  ApeCoinListing,
  BakcListing,
  ApeCoinSourceToJoinApeListing
} from '../../../../types';
import { JoinBAKCPairListingsFormData } from '../types';
import { MatchListingsSubmitter, SignListingSubmitter } from '../../../../submitters';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import { CreateListingReturnType } from '@/apps/paraspace/pages/hooks/useP2PPairStaking';
import {
  ERC20Symbol,
  ERC721Symbol,
  WalletType,
  ApeStakingMainTokenSymbol
} from '@/apps/paraspace/typings';
import { FLOAT_SCALING_FACTOR } from '@/apps/paraspace/utils/format';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { useContractsMap, useERC20, useERC721 } from '@/apps/paraspace/hooks';
import { STAKE_LIMIT } from '@/apps/paraspace/pages/ApePairing/consts';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';
import {
  ApproveERC721FromEOASubmitter,
  DepositERC20Submitter,
  SupplyERC721FromEOASubmitter
} from '@/apps/paraspace/components';
import { useAutoCompoundApe } from '@/apps/paraspace/hooks/useAutoCompoundApe';

/* eslint max-lines-per-function: ["error", { "max": 640 }] */
export const useJoinBAKCPairListingsFormSubmitterSteps = ({
  formData,
  walletType,
  onNext,
  onError,
  onFinish
}: {
  formData: JoinBAKCPairListingsFormData;
  walletType: WalletType;
  onNext: () => void;
  onError: (error: {
    apeListing: Partial<ApeListing>;
    apeCoinListing: Partial<ApeCoinListing>;
    bakcListing: Partial<BakcListing>;
  }) => void;
  onFinish: (result: {
    apeListing: ApeListing;
    apeCoinListing: ApeCoinListing;
    bakcListing: BakcListing;
  }) => void;
}) => {
  const [formDataSnapshot] = useState<JoinBAKCPairListingsFormData>(formData);

  const { apeCoinListing, apeListing, bakcListing } = formDataSnapshot;

  const { account } = useWeb3Context();
  const contracts = useContractsMap();

  const apeListingToCreate = useMemo(
    () =>
      formData.apeListingToCreate ?? {
        symbol: ERC721Symbol.BAYC as ApeStakingMainTokenSymbol,
        tokenId: 0,
        supplied: false,
        share: new BigNumber(0)
      },
    [formData.apeListingToCreate]
  );
  const apeCoinListingToCreate = useMemo(
    () =>
      formData.apeCoinListingToCreate ?? {
        source: ApeCoinSourceToJoinApeListing.CAPE_BALANCE,
        supplied: false,
        share: new BigNumber(0)
      },
    [formData.apeCoinListingToCreate]
  );
  const bakcListingToCreate = useMemo(
    () =>
      formData.bakcListingToCreate ?? {
        tokenId: 0,
        supplied: false,
        share: new BigNumber(0)
      },
    [formData.bakcListingToCreate]
  );

  const [createdApeListing, setCreatedApeListing] = useState<Maybe<ApeListing>>(null);

  const handleApeListingCreated = useCallback(
    (createdListing: CreateListingReturnType) => {
      const {
        offerer,
        share: createdListingShare,
        startTime,
        endTime,
        v,
        r,
        s,
        token
      } = createdListing;
      setCreatedApeListing({
        token,
        listingHash: '',
        offerer,
        share: new BigNumber(createdListingShare).dividedBy(FLOAT_SCALING_FACTOR),
        startDate: new Date(startTime * 1000),
        expirationDate: new Date(endTime * 1000),
        tokenId: formDataSnapshot.apeListingToCreate!.tokenId,
        v,
        r,
        s,
        stakingType: StakingType.BAKCPairStaking,
        stakingPool: ERC721Symbol.BAKC,
        symbol: formDataSnapshot.apeListingToCreate!.symbol,
        listingType: ListingType.ApeListing
      });
    },
    [setCreatedApeListing, formDataSnapshot.apeListingToCreate]
  );

  const [createdApeCoinListing, setCreatedApeCoinListing] = useState<Maybe<ApeCoinListing>>(null);

  const handleApeCoinListingCreated = useCallback(
    (createdListing: CreateListingReturnType) => {
      const { offerer, share, startTime, endTime, v, r, s, token } = createdListing;
      setCreatedApeCoinListing({
        listingHash: '',
        offerer,
        share: new BigNumber(share).dividedBy(FLOAT_SCALING_FACTOR),
        startDate: new Date(startTime * 1000),
        expirationDate: new Date(endTime * 1000),
        v,
        r,
        s,
        stakingType: StakingType.BAKCPairStaking,
        listingType: ListingType.ApeCoinListing,
        token
      } as ApeCoinListing);
    },
    [setCreatedApeCoinListing]
  );

  const [createdBakcListing, setCreatedBakcListing] = useState<Maybe<BakcListing>>(null);

  const handleBakcListingCreated = useCallback(
    (createdListing: CreateListingReturnType) => {
      const {
        offerer,
        share: createdListingShare,
        startTime,
        endTime,
        v,
        r,
        s,
        token
      } = createdListing;
      setCreatedBakcListing({
        token,
        listingHash: '',
        offerer,
        share: new BigNumber(createdListingShare).dividedBy(FLOAT_SCALING_FACTOR),
        startDate: new Date(startTime * 1000),
        expirationDate: new Date(endTime * 1000),
        tokenId: formDataSnapshot.bakcListingToCreate!.tokenId,
        v,
        r,
        s,
        stakingType: StakingType.BAKCPairStaking,
        stakingPool: ERC721Symbol.BAKC,
        symbol: ERC721Symbol.BAKC,
        listingType: ListingType.BakcListing
      });
    },
    [setCreatedBakcListing, formDataSnapshot.bakcListingToCreate]
  );

  const handleError = useCallback(() => {
    onError({
      apeListing: apeListing ??
        createdApeListing ?? {
          offerer: account,
          stakingPool: ERC721Symbol.BAKC,
          stakingType: StakingType.BAKCPairStaking,
          listingType: ListingType.ApeListing,
          tokenId: apeListingToCreate!.tokenId,
          symbol: apeListingToCreate!.symbol
        },
      apeCoinListing: apeCoinListing ??
        createdApeCoinListing ?? {
          offerer: account,
          stakingPool: ERC721Symbol.BAKC,
          stakingType: StakingType.BAKCPairStaking,
          symbol: ERC20Symbol.APE,
          listingType: ListingType.ApeCoinListing
        },
      bakcListing: bakcListing ??
        createdBakcListing ?? {
          offerer: account,
          symbol: ERC721Symbol.BAKC,
          stakingPool: ERC721Symbol.BAKC,
          stakingType: StakingType.BAKCPairStaking,
          listingType: ListingType.BakcListing,
          tokenId: bakcListingToCreate!.tokenId
        }
    });
  }, [
    onError,
    apeListingToCreate,
    bakcListingToCreate,
    createdApeListing,
    createdApeCoinListing,
    createdBakcListing,
    account,
    bakcListing,
    apeCoinListing,
    apeListing
  ]);

  const handleFinish = useCallback(() => {
    onFinish({
      apeListing: apeListing ?? createdApeListing!,
      apeCoinListing: apeCoinListing ?? createdApeCoinListing!,
      bakcListing: bakcListing ?? createdBakcListing!
    });
  }, [
    onFinish,
    createdApeListing,
    createdApeCoinListing,
    createdBakcListing,
    apeListing,
    apeCoinListing,
    bakcListing
  ]);

  const { createApproval: genApprovalForAPE } = useERC20(contracts.APE);
  const { createApproval: genApprovalForCAPE } = useERC20(contracts.cAPE);
  const {
    genApprovalForAll: genApprovalForApeListing,
    genSupplyERC721Txs: genSupplyERC721TxsForApeListing
  } = useERC721(apeListingToCreate.symbol);
  const {
    genApprovalForAll: genApprovalForBAKCListing,
    genSupplyERC721Txs: genSupplyERC721TxsForBAKCListing
  } = useERC721(ERC721Symbol.BAKC);
  const { genStakingAutoCompoundApe } = useAutoCompoundApe();

  const batchTxsForCreateListing = useMemo(
    () => [
      // 1. batch the apeListingToCreate txs
      {
        tx: genApprovalForApeListing({ ids: [apeListingToCreate.tokenId] }),
        skip:
          walletType === 'EOA' ||
          formData.apeListingToCreate === null ||
          formData.apeListingToCreate.supplied
      },
      {
        tx: genSupplyERC721TxsForApeListing({ tokenIds: [apeListingToCreate.tokenId] }),
        skip:
          walletType === 'EOA' ||
          formData.apeListingToCreate === null ||
          formData.apeListingToCreate.supplied
      },
      // 2. batch the apeCoinListingToCreate txs
      {
        tx: genApprovalForAPE({
          spender: contracts.cAPE
        }),
        skip:
          formData.apeCoinListingToCreate === null ||
          formData.apeCoinListingToCreate.source === ApeCoinSourceToJoinApeListing.CAPE_BALANCE
      },
      {
        tx: genStakingAutoCompoundApe({
          amount: new BigNumber(STAKE_LIMIT[ERC721Symbol.BAKC]).toString(10)
        }),
        skip:
          formData.apeCoinListingToCreate === null ||
          formData.apeCoinListingToCreate.source === ApeCoinSourceToJoinApeListing.CAPE_BALANCE
      },
      {
        tx: genApprovalForCAPE({
          spender: contracts.P2PPairStaking
        }),
        skip: formData.apeCoinListingToCreate === null
      },
      // 3. batch the bakcListingToCreate txs
      {
        tx: genApprovalForBAKCListing({ ids: [bakcListingToCreate.tokenId] }),
        skip:
          walletType === 'EOA' ||
          formData.bakcListingToCreate === null ||
          formData.bakcListingToCreate.supplied
      },
      {
        tx: genSupplyERC721TxsForBAKCListing({ tokenIds: [bakcListingToCreate.tokenId] }),
        skip:
          walletType === 'EOA' ||
          formData.bakcListingToCreate === null ||
          formData.bakcListingToCreate.supplied
      }
    ],
    [
      walletType,
      formData.bakcListingToCreate,
      formData.apeListingToCreate,
      formData.apeCoinListingToCreate,
      apeListingToCreate,
      bakcListingToCreate,
      contracts,
      genApprovalForApeListing,
      genApprovalForAPE,
      genApprovalForBAKCListing,
      genApprovalForCAPE,
      genSupplyERC721TxsForApeListing,
      genSupplyERC721TxsForBAKCListing,
      genStakingAutoCompoundApe
    ]
  );

  const steps = useMemo(() => {
    return [
      // 1. non-batch the apeListingToCreate txs
      {
        description: `Approve ${apeListingToCreate.symbol}`,
        content: (
          <ApproveERC721FromEOASubmitter
            formData={{
              name: apeListingToCreate.symbol,
              assetAddress: contracts[apeListingToCreate.symbol]
            }}
            onFinish={onNext}
            onError={handleError}
          />
        ),
        skip:
          walletType !== 'EOA' ||
          formData.apeListingToCreate === null ||
          formData.apeListingToCreate.supplied
      },
      {
        description: `Supply ${apeListingToCreate.symbol} to ParaSpace`,
        content: (
          <SupplyERC721FromEOASubmitter
            formData={{
              symbol: apeListingToCreate.symbol,
              tokenIds: [apeListingToCreate.tokenId]
            }}
            onFinish={onNext}
            onError={handleError}
          />
        ),
        skip:
          walletType !== 'EOA' ||
          formData.apeListingToCreate === null ||
          formData.apeListingToCreate.supplied
      },
      // 2. non-batch the apeCoinListingToCreate txs
      {
        description: 'Deposit APE',
        content: (
          <DepositERC20Submitter
            formData={{
              address: contracts.APE,
              symbol: ERC20Symbol.APE,
              value: new BigNumber(STAKE_LIMIT[ERC721Symbol.BAKC])
            }}
            onFinish={onNext}
            onError={handleError}
          />
        ),
        skip:
          walletType !== 'EOA' ||
          formData.apeCoinListingToCreate === null ||
          formData.apeCoinListingToCreate.source === ApeCoinSourceToJoinApeListing.CAPE_BALANCE
      },
      {
        description: 'Deposit cAPE',
        content: (
          <DepositERC20Submitter
            formData={{
              address: contracts.cAPE,
              symbol: ERC20Symbol.CAPE,
              value: new BigNumber(STAKE_LIMIT[ERC721Symbol.BAKC])
            }}
            onFinish={onNext}
            onError={handleError}
          />
        ),
        skip:
          walletType !== 'EOA' ||
          formData.apeCoinListingToCreate === null ||
          formData.apeCoinListingToCreate.source === ApeCoinSourceToJoinApeListing.APE_BALANCE
      },
      // 3. non-batch the bakcListingToCreate txs
      {
        description: `Approve BAKC`,
        content: (
          <ApproveERC721FromEOASubmitter
            formData={{
              name: ERC721Symbol.BAKC,
              assetAddress: contracts[ERC721Symbol.BAKC]
            }}
            onFinish={onNext}
            onError={handleError}
          />
        ),
        skip:
          walletType !== 'EOA' ||
          formData.bakcListingToCreate === null ||
          formData.bakcListingToCreate.supplied
      },
      {
        description: `Supply BAKC to ParaSpace`,
        content: (
          <SupplyERC721FromEOASubmitter
            formData={{
              tokenIds: [bakcListingToCreate.tokenId],
              symbol: ERC721Symbol.BAKC
            }}
            onFinish={onNext}
            onError={handleError}
          />
        ),
        skip:
          walletType !== 'EOA' ||
          formData.bakcListingToCreate === null ||
          formData.bakcListingToCreate.supplied
      },
      {
        description: `Create Listing`,
        content: (
          <BatchTransactionsSubmitter
            batchTxs={batchTxsForCreateListing}
            onFinish={onNext}
            inProgressMessage="Create Listing"
            onError={handleError}
          />
        ),
        skip: batchTxsForCreateListing.every(({ skip }) => skip)
      },
      {
        description: 'Sign to create listing',
        content: (
          <SignListingSubmitter
            key="SignApeListingSubmitter"
            formData={{
              stakingType: StakingType.BAKCPairStaking,
              offerSymbol: apeListingToCreate.symbol,
              tokenId: apeListingToCreate.tokenId,
              wantedPercentage: apeListingToCreate.share,
              duration: 1 / 12
            }}
            onError={handleError}
            onFinish={(createdListing: CreateListingReturnType) => {
              handleApeListingCreated(createdListing);
              onNext();
            }}
          />
        ),
        skip: formData.apeListingToCreate === null
      },
      {
        description: 'Sign to create listing',
        content: (
          <SignListingSubmitter
            key="SignApeCoinListingSubmitter"
            formData={{
              stakingType: StakingType.BAKCPairStaking,
              offerSymbol: ERC20Symbol.APE,
              tokenId: 0,
              wantedPercentage: apeCoinListingToCreate.share,
              duration: 1 / 12
            }}
            onError={handleError}
            onFinish={(createdListing: CreateListingReturnType) => {
              handleApeCoinListingCreated(createdListing);
              onNext();
            }}
          />
        ),
        skip: formData.apeCoinListingToCreate === null
      },
      {
        description: 'Sign to create listing',
        content: (
          <SignListingSubmitter
            key="SignBakcListingSubmitter"
            formData={{
              stakingType: StakingType.BAKCPairStaking,
              offerSymbol: ERC721Symbol.BAKC,
              tokenId: bakcListingToCreate.tokenId,
              wantedPercentage: bakcListingToCreate.share,
              duration: 1 / 12
            }}
            onError={handleError}
            onFinish={(createdListing: CreateListingReturnType) => {
              handleBakcListingCreated(createdListing);
              onNext();
            }}
          />
        ),
        skip: formData.bakcListingToCreate === null
      },
      {
        description: 'Matching Order',
        content: (
          <MatchListingsSubmitter
            formData={{
              apeListing: apeListing ?? createdApeListing!,
              apeCoinListing: apeCoinListing ?? createdApeCoinListing!,
              bakcListing: bakcListing ?? createdBakcListing!
            }}
            onError={handleError}
            onFinish={handleFinish}
          />
        ),
        skip: false
      }
    ];
  }, [
    batchTxsForCreateListing,
    formData.apeListingToCreate,
    formData.bakcListingToCreate,
    formData.apeCoinListingToCreate,
    walletType,
    contracts,
    onNext,
    handleApeListingCreated,
    handleApeCoinListingCreated,
    handleBakcListingCreated,
    handleError,
    handleFinish,
    apeListingToCreate,
    apeCoinListingToCreate,
    bakcListingToCreate,
    apeCoinListing,
    apeListing,
    bakcListing,
    createdApeCoinListing,
    createdApeListing,
    createdBakcListing
  ]);

  return steps;
};
