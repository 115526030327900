import {
  createContext,
  memo,
  FC,
  useMemo,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { flatMap, isNil, map } from 'lodash';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { emptyArray } from '@/apps/paraspace/consts/values';
import { ApeStakingTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { useApeStaking } from '@/apps/paraspace/pages/hooks/ApeStaking/useApeStaking';
import { ApeTokenStakingInfo } from '@/apps/paraspace/pages/hooks/ApeStaking/types';
import { poolIdToSymbol } from '@/apps/paraspace/pages/ApePairing/utils/poolIdToSymbol';
import { useWeb3Context } from '@/apps/paraspace/contexts';

const stakingInfoKeyToSymbolMap: {
  [key: string]: ApeStakingTokenSymbol;
} = {
  maycStakes: ERC721Symbol.MAYC,
  baycStakes: ERC721Symbol.BAYC,
  bakcStakes: ERC721Symbol.BAKC
};

type ContextValue = {
  suppliedStakingInfo: ApeTokenStakingInfo[] | null;
  suppliedStakingInfoLoaded: boolean;
  refreshSuppliedStakingInfo: () => void;
  balanceStakingInfo: ApeTokenStakingInfo[] | null;
  refreshBalanceStakingInfo: () => void;
  balanceStakingInfoLoaded: boolean;
};
export const StakingInfosContext = createContext({} as ContextValue);

export const StakingInfosProvider: FC = memo(({ children }) => {
  const [suppliedStakingInfo, setSuppliedStakingInfo] = useState<ApeTokenStakingInfo[] | null>(
    null
  );
  const [balanceStakingInfo, setBalanceStakingInfo] = useState<ApeTokenStakingInfo[] | null>(null);

  const { nftInfoMap } = useMMProvider();
  const { eoaAccount } = useWeb3Context();
  const { getStakingInfosByTokenInfos, getSuppliedBakcStakingInfo, getStakingInfoByUserAddress } =
    useApeStaking();

  const {
    [ERC721Symbol.BAYC]: {
      nftSuppliedList: baycSupplment = emptyArray,
      xTokenAddress: baycXTokenAddress = ''
    } = {},
    [ERC721Symbol.MAYC]: {
      nftSuppliedList: maycSupplment = emptyArray,
      xTokenAddress: maycXTokenAddress = ''
    } = {},
    [ERC721Symbol.BAKC]: { nftSuppliedList: bakcSupplment = emptyArray } = {}
  } = nftInfoMap;

  const tokens = useMemo(
    () =>
      baycSupplment
        .map(id => ({
          type: ERC721Symbol.BAYC,
          id,
          userAddr: baycXTokenAddress
        }))
        .concat(
          maycSupplment.map(id => ({
            type: ERC721Symbol.MAYC,
            id,
            userAddr: maycXTokenAddress
          }))
        ) as {
        type: ApeStakingTokenSymbol;
        id: number;
        userAddr: string;
      }[],
    [baycSupplment, maycSupplment, baycXTokenAddress, maycXTokenAddress]
  );

  const refreshSuppliedStakingInfo = useCallback(async () => {
    if (tokens.length === 0 && bakcSupplment.length === 0) {
      setSuppliedStakingInfo([]);
      return;
    }

    const suppliedBakcStakingInfos = ((await getSuppliedBakcStakingInfo()) ?? [])
      .map(item => ({
        ...item,
        symbol: ERC721Symbol.BAKC,
        mainTokenSymbol: poolIdToSymbol(item.mainPoolId),
        supplied: true,
        source: null
      }))
      .filter(bakcInfo => bakcSupplment.includes(bakcInfo.tokenId)) as ApeTokenStakingInfo[];

    await getStakingInfosByTokenInfos(tokens)
      .then(rawStakingInfos => {
        const stakingInfos = rawStakingInfos.map((rawStakingInfo, index) => ({
          tokenId: tokens[index].id,
          symbol: tokens[index].type,
          ...rawStakingInfo,
          supplied: true,
          source: null
        }));
        const validInfos = stakingInfos.filter(info => info !== null) as ApeTokenStakingInfo[];

        setSuppliedStakingInfo([...validInfos, ...suppliedBakcStakingInfos]);
      })
      .catch(() => {
        setSuppliedStakingInfo([]);
      });
  }, [bakcSupplment, getStakingInfosByTokenInfos, getSuppliedBakcStakingInfo, tokens]);

  const refreshBalanceStakingInfo = useCallback(async () => {
    const [aaBalanceStakingInfos, eoaBalanceStakingInfos] = await Promise.all([
      getStakingInfoByUserAddress(),
      getStakingInfoByUserAddress(eoaAccount)
    ]);

    const aaItemInfos = flatMap(aaBalanceStakingInfos, (itemInfoMap, key) => {
      const symbol = stakingInfoKeyToSymbolMap[key];
      return map(
        itemInfoMap,
        itemInfo =>
          ({
            ...itemInfo,
            symbol,
            mainTokenSymbol: poolIdToSymbol(itemInfo.mainPoolId),
            supplied: false,
            source: 'AA'
          } as ApeTokenStakingInfo)
      );
    });

    const eoaItemInfos = flatMap(eoaBalanceStakingInfos, (itemInfoMap, key) => {
      const symbol = stakingInfoKeyToSymbolMap[key];
      return map(
        itemInfoMap,
        itemInfo =>
          ({
            ...itemInfo,
            symbol,
            mainTokenSymbol: poolIdToSymbol(itemInfo.mainPoolId),
            supplied: false,
            source: 'EOA'
          } as ApeTokenStakingInfo)
      );
    });

    setBalanceStakingInfo(aaItemInfos.concat(eoaItemInfos));
  }, [getStakingInfoByUserAddress, eoaAccount]);

  useEffect(() => {
    refreshBalanceStakingInfo();
    refreshSuppliedStakingInfo();
  }, [refreshBalanceStakingInfo, refreshSuppliedStakingInfo]);

  const value = useMemo(
    () => ({
      suppliedStakingInfo,
      refreshSuppliedStakingInfo,
      suppliedStakingInfoLoaded: !isNil(suppliedStakingInfo),
      balanceStakingInfo,
      refreshBalanceStakingInfo,
      balanceStakingInfoLoaded: !isNil(suppliedStakingInfo)
    }),
    [balanceStakingInfo, refreshBalanceStakingInfo, refreshSuppliedStakingInfo, suppliedStakingInfo]
  );

  return <StakingInfosContext.Provider value={value}>{children}</StakingInfosContext.Provider>;
});

export const useStakingInfos = () => {
  return useContext(StakingInfosContext);
};
