import { useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';

import {
  useGetLiquidationInfoByAssetLazyQuery,
  useUpdateAssetAuctionStatusMutation
} from '@/apps/paraspace/generated/graphql';
import useLiquidation from '@/apps/paraspace/pages/hooks/useLiquidation';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { AuctionStatus } from '@/apps/paraspace/pages/Liquidation/types';
import { shiftedLeftBy, shiftedRightBy } from '@/apps/paraspace/utils/calculations';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { useNativeTokenSymbol } from '@/apps/paraspace/pages/hooks';
import { calculatePriceInNativeToken } from '@/apps/paraspace/pages/Liquidation/utils';
import { useMount } from '@/apps/paraspace/hooks/useMount';

export const useAssetLiquidationInfo = (contractAddr: string, tokenId: string) => {
  const parallelToast = useParallelToast();
  const [updateAssetAuctionStatusInServer] = useUpdateAssetAuctionStatusMutation();

  const [, { data, client, refetch }] = useGetLiquidationInfoByAssetLazyQuery({
    variables: {
      contractAddress: contractAddr,
      identifierOrCriteria: tokenId
    }
  });

  useMount(() => {
    refetch();
  });

  const { triggerAuction, closeSingleAuction } = useLiquidation(contractAddr, Number(tokenId));

  const handleCloseLiquidation = useCallback(
    async (address: string) => {
      await parallelToast.promise(
        closeSingleAuction({
          user: address
        })
      );
      client.cache.modify({
        fields: {
          liquidationInfoForAsset: () => null
        }
      });
    },
    [closeSingleAuction, client, parallelToast]
  );

  const handleTriggerAuction = useCallback(
    async (address: string) => {
      await parallelToast.promise(
        triggerAuction({
          user: address
        })
      );
      client.cache.modify({
        fields: {
          liquidationInfoForAsset: item => {
            const { floorPrice, floorPriceInUSD, maxPriceMultiplier } = item.asset;
            const multiplier = shiftedLeftBy(maxPriceMultiplier, 18);
            return {
              ...item,
              asset: {
                ...item.asset,
                auctionStatus: AuctionStatus.StartedAndCanBeLiquidated,
                currentPrice: new BigNumber(floorPrice).times(multiplier).toString(),
                currentPriceInUSD: new BigNumber(floorPriceInUSD).times(multiplier).toNumber()
              }
            };
          }
        }
      });
      updateAssetAuctionStatusInServer({
        variables: {
          contractAddress: contractAddr,
          identifierOrCriteria: tokenId,
          auctionStatus: AuctionStatus.StartedAndCanBeLiquidated
        }
      }).catch(() => null);
    },
    [
      client.cache,
      contractAddr,
      parallelToast,
      tokenId,
      triggerAuction,
      updateAssetAuctionStatusInServer
    ]
  );

  const { erc20InfoMap, basicInfoLoaded } = useMMProvider();
  const { marketCurrencyTokenInfo } = useAppConfig();
  const nativeTokenSymbol = useNativeTokenSymbol();
  const { priceInETH: nativeTokenPriceInMarketCurrency, decimals } =
    erc20InfoMap[nativeTokenSymbol] || {};
  const liquidationData = useMemo(() => {
    const { liquidationInfoForAsset } = data || {};
    if (!liquidationInfoForAsset || !basicInfoLoaded) return null;

    const { floorPrice, currentPrice } = liquidationInfoForAsset.asset;
    const floorPriceInNativeToken = shiftedRightBy(
      calculatePriceInNativeToken({
        priceInMarketCurrencyWithDecimal: floorPrice,
        marketCurrencyTokenInfo,
        nativeTokenPriceInMarketCurrency
      }),
      decimals
    );
    const currentPriceInNativeToken = shiftedRightBy(
      calculatePriceInNativeToken({
        priceInMarketCurrencyWithDecimal: currentPrice,
        marketCurrencyTokenInfo,
        nativeTokenPriceInMarketCurrency
      }),
      decimals
    );

    return {
      ...liquidationInfoForAsset,
      asset: {
        ...liquidationInfoForAsset.asset,
        floorPrice: floorPriceInNativeToken.toString(),
        currentPrice: currentPriceInNativeToken.toString()
      }
    };
  }, [basicInfoLoaded, data, decimals, marketCurrencyTokenInfo, nativeTokenPriceInMarketCurrency]);

  return {
    liquidationInfoForAsset: liquidationData,
    handleCloseLiquidation,
    handleTriggerAuction
  };
};
