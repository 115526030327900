import { Icon, RoundImage, Stack, StackProps } from '@parallel-mono/components';
import { FC, memo, useCallback } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import imageCompression from 'browser-image-compression';
import styled, { useTheme } from 'styled-components';

import { fileToBase64 } from '@/apps/paraspace/utils';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { RoundProfilePicture } from '@/apps/paraspace/components';

const AvatarWrapper = styled(Stack)`
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
  ::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
  }
  :hover {
    &::after {
      background-color: rgba(0, 0, 0, 0.3);
    }
    & svg {
      opacity: 1;
    }
  }
`;
const StyledIcon = styled(Icon)`
  position: absolute;
  opacity: 0;
  z-index: 99;
  transition: all 0.2s ease;
`;

type Props = {
  avatar: Maybe<string>;
  onAvatarChange: (avatar: string) => void;
};

export const AvatarSetting: FC<Props & Omit<StackProps, 'children'>> = memo(
  ({ avatar, onAvatarChange, ...others }) => {
    const theme = useTheme();

    const handleAvatarChange = useCallback(
      async (images: ImageListType) => {
        if (images[0].file) {
          const compressedFile = await imageCompression(images[0].file, {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 800,
            useWebWorker: true
          });
          const imageBase64 = await fileToBase64(compressedFile);

          onAvatarChange(imageBase64 as string);
        }
      },
      [onAvatarChange]
    );

    return (
      <Stack gap="0" justifyContent="center" {...others}>
        <ImageUploading
          acceptType={['png', 'jpg', 'jpeg', 'gif']}
          maxFileSize={80000000}
          multiple={false}
          value={[]}
          onChange={handleAvatarChange}
        >
          {({ onImageUpload }) => (
            <AvatarWrapper
              gap="0"
              alignItems="center"
              justifyContent="center"
              onClick={onImageUpload}
            >
              {avatar ? (
                <RoundImage width="7.5rem" height="7.5rem" src={avatar} />
              ) : (
                <RoundProfilePicture width="7.5rem" height="7.5rem" />
              )}
              <StyledIcon
                size="1.5rem"
                color={theme.skin.grey.white}
                stroke-width="2"
                name="camera"
              />
            </AvatarWrapper>
          )}
        </ImageUploading>
      </Stack>
    );
  }
);
