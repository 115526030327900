import { useCallback, useMemo } from 'react';
import { DeploylessViewerClient } from 'deployless-view';
import { UiPoolDataProvider, WalletBalanceProvider } from 'paraspace-utilities-contract-helpers';
import { formatReserves, formatUserSummary } from 'paraspace-utilities-math-utils';
import { ERC721Symbol } from 'paraspace-configs-v2';
import { /* isEmpty, */ findKey, isEmpty, sortBy, values, zipWith } from 'lodash';
import BigNumber from 'bignumber.js';
import { CollectionConfig } from '@parallel-utils/contracts-registry';

import { useEOAProvider } from '../../EOAProvider';
import { ERC20_CONFIG_FOR_NETWORK_MAP, Network } from '../../../config';
import { Maybe } from '../../../typings/basic';
import { ERC20BalanceInfo, UserBalanceInfos } from '../types';
import { ERC20_CONFIG_FOR_PARALLEL_NETWORK } from '../config';

import { PositionMap } from './types';
import {
  extractUniswapBalancesFromPosition,
  getForgedNativeTokenBalanceInfo,
  getPositionMapFromUserPosition
} from './utils';

import { ERC20Symbol } from '@/apps/parax/typings';
import { ERC20_EXPECTED_ORDER, zero } from '@/apps/parax/consts';
import { useContractsMap, useOracleClient } from '@/apps/parax/hooks';
import { getERC721Config } from '@/apps/parax/config/erc721Configs';
import { convertToChecksumAddress } from '@/apps/parax/utils';

const PUNKS_NFT_TYPE = 2;

/* eslint max-lines-per-function: ["error", { "max": 325 }] */
export const useBalancesLoader = () => {
  const { provider, chainId } = useEOAProvider();
  const contracts = useContractsMap();
  const ERC20Config = useMemo(() => ERC20_CONFIG_FOR_NETWORK_MAP[chainId], [chainId]);
  const ERC721Config = useMemo(() => getERC721Config(chainId), [chainId]);

  const { getTokenPrices } = useOracleClient(provider);
  const viewer = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new DeploylessViewerClient(provider);
  }, [provider]);

  const uiPoolDataProvider = useMemo(() => {
    if (!contracts.UiPoolDataProvider) return null;
    return new UiPoolDataProvider({
      uiPoolDataProviderAddress: contracts.UiPoolDataProvider,
      provider,
      chainId
    });
  }, [chainId, contracts.UiPoolDataProvider, provider]);

  const walletBalanceProvider = useMemo(
    () =>
      new WalletBalanceProvider({
        walletBalanceProviderAddress: contracts.WalletBalanceProvider,
        provider
      }),
    [contracts.WalletBalanceProvider, provider]
  );

  const loadUserPositions = useCallback(
    async (accountAddress: string) => {
      if (!viewer || !uiPoolDataProvider) {
        return null;
      }

      const reserveData = await uiPoolDataProvider.getReservesHumanized({
        lendingPoolAddressProvider: contracts.PoolAddressesProvider
      });

      const userReservesData = await uiPoolDataProvider.getUserReservesHumanized({
        lendingPoolAddressProvider: contracts.PoolAddressesProvider,
        user: accountAddress,
        reservesDataHumanized: reserveData
      });

      const currentTimestamp = (new Date().getTime() / 1000).toFixed(0);
      const formatReservesData = formatReserves({
        reserves: reserveData.reservesData,
        currentTimestamp: Number(currentTimestamp),
        marketReferenceCurrencyDecimals:
          reserveData.baseCurrencyData.marketReferenceCurrencyDecimals,
        marketReferencePriceInUsd: reserveData.baseCurrencyData.marketReferenceCurrencyPriceInUsd
      });

      const formatUserSummaryData = formatUserSummary({
        currentTimestamp: Number(currentTimestamp),
        marketReferenceCurrencyDecimals:
          reserveData.baseCurrencyData.marketReferenceCurrencyDecimals,
        marketReferencePriceInUsd: reserveData.baseCurrencyData.marketReferenceCurrencyPriceInUsd,
        userReserves: userReservesData.userReserves,
        userEmodeCategoryId: userReservesData.userEmodeCategoryId,
        formattedReserves: formatReservesData
      });

      // For now the getPunksBalance is only supported on ethereum network.
      if ([Network.MAINNET, Network.SEPOLIA].includes(chainId)) {
        // const punksBalance = await getPunksBalance();
        const rawData = await viewer.getAllTokensByOwner(
          accountAddress,
          contracts.PUNKS,
          PUNKS_NFT_TYPE
        );
        const punksBalance = rawData.tokenInfos as number[];
        const wpunksUserReservesData = formatUserSummaryData.userReservesData.find(
          item => item.reserve.symbol === ERC721Symbol.WPUNKS
        );
        if (punksBalance && wpunksUserReservesData) {
          const formatUserSummaryDataWithPunks = {
            ...formatUserSummaryData,
            userReservesData: [
              ...formatUserSummaryData.userReservesData,
              {
                ...wpunksUserReservesData,
                ownedTokens: punksBalance,
                underlyingAsset: contracts.PUNKS,
                suppliedTokens: [],
                collaterizedTokens: [],
                auctionedTokens: [],
                collaterizedBalance: '0',
                scaledXTokenBalance: '0',
                reserve: {
                  ...wpunksUserReservesData.reserve,
                  symbol: ERC721Symbol.PUNK,
                  underlyingAsset: contracts.PUNKS
                }
              }
            ]
          };
          return formatUserSummaryDataWithPunks;
        }
      }

      return formatUserSummaryData;
    },
    [viewer, uiPoolDataProvider, contracts.PoolAddressesProvider, contracts.PUNKS, chainId]
  );

  const getUserERC721BalanceInfos = useCallback(
    async (accountAddress: string, positionMap: Maybe<PositionMap>) => {
      if (!ERC721Config || !uiPoolDataProvider) return [];

      const tokens = Object.values(ERC721Config) as CollectionConfig[];
      const balances = await Promise.all(
        tokens.map(token =>
          uiPoolDataProvider.getERC721Balance({
            user: accountAddress,
            nft: contracts[token.contractName]!,
            nftType: token.nftEnumerableType,
            viewerStep: token.selfIncrementTokenId ? 3000 : 10000,
            useTotalSupplyAsMaxId: token.selfIncrementTokenId
          })
        )
      );

      return tokens.map((config, index) => {
        const positionInfo = values(positionMap).find(
          v =>
            convertToChecksumAddress(v.underlyingAsset) ===
            convertToChecksumAddress(contracts[config.contractName]!)
        );

        return {
          address: convertToChecksumAddress(positionInfo?.underlyingAsset ?? ''),
          tokenIds: balances[index] ?? [],
          symbol: config.symbol,
          name: config.contractName,
          collectionName: config.collectionName,
          priceInUSD: new BigNumber(positionInfo?.reserve.priceInUSD ?? 0)
        };
      });
    },
    [ERC721Config, contracts, uiPoolDataProvider]
  );

  const getUserERC20BalanceInfos = useCallback(
    async (
      accountAddress: string,
      positionMap: Maybe<PositionMap>
    ): Promise<ERC20BalanceInfo[]> => {
      if (!ERC20Config) return [];
      const erc20Tokens = (Object.keys(ERC20Config) as ERC20Symbol[])
        .filter(key => !ERC20Config[key]?.isNativeToken)
        .map(key => {
          const { contractName, decimals } = ERC20Config[key]!;
          return { contractName, symbol: key, address: contracts[contractName], decimals };
        });
      const assets = erc20Tokens.map(token => token.address);
      const balances = await walletBalanceProvider.batchBalanceOf([accountAddress], assets);

      return erc20Tokens.map(({ contractName, symbol, address, decimals }, index) => ({
        symbol,
        address,
        balance: new BigNumber(balances[index].toString()).shiftedBy(-decimals),
        priceInUSD: new BigNumber(positionMap?.[contractName]?.reserve.priceInUSD ?? 0),
        displayName: contractName ?? symbol
      }));
    },
    [walletBalanceProvider, ERC20Config, contracts]
  );

  const getParallelERC20BalanceInfos = useCallback(
    async (account: string) => {
      const parallelChainId = chainId as Network.PARALLEL_L3_TESTNET | Network.PARALLEL;
      const erc20Tokens = values(ERC20_CONFIG_FOR_PARALLEL_NETWORK[parallelChainId]);

      if (!isEmpty(erc20Tokens)) {
        const balances = await walletBalanceProvider.batchBalanceOf(
          [account],
          erc20Tokens!.map(v => v.address)
        );

        const prices = await getTokenPrices(erc20Tokens.map(v => v.address));
        return zipWith(
          erc20Tokens,
          balances,
          prices,
          ({ symbol, address, decimals, isWrappedNativeToken }, balance, price) => ({
            symbol,
            address,
            decimals,
            balance: new BigNumber(balance.toString()).shiftedBy(-decimals),
            priceInUSD: price ?? BigNumber(0),
            displayName: symbol,
            isWrappedNativeToken
          })
        );
      }
      return [];
    },
    [chainId, getTokenPrices, walletBalanceProvider]
  );

  const getParallelWalletTokenBalance = useCallback(
    async (account: string) => {
      const customizedERC20BalanceInfos = await getParallelERC20BalanceInfos(account);

      const wTokenBalanceInfo = customizedERC20BalanceInfos.find(v => v.isWrappedNativeToken)!;

      const nativeTokenSymbol = findKey(ERC20Config, it => it.isNativeToken) as ERC20Symbol;
      const nativeTokenBalance = await provider?.getBalance(account);
      const nativeTokenInfo = {
        symbol: nativeTokenSymbol,
        address: '',
        decimals: wTokenBalanceInfo.decimals,
        displayName: nativeTokenSymbol,
        balance: BigNumber(nativeTokenBalance.toString()).shiftedBy(-wTokenBalanceInfo.decimals),
        priceInUSD: new BigNumber(wTokenBalanceInfo?.priceInUSD ?? 0),
        isNativeToken: true
      };

      return {
        ERC20Balances: [nativeTokenInfo, ...customizedERC20BalanceInfos],
        // For the parallel network, the ERC721 tokens are not support yet.
        ERC721Balances: [],
        uniswapBalances: []
      } as UserBalanceInfos;
    },
    [ERC20Config, getParallelERC20BalanceInfos, provider]
  );

  const getCommonWalletTokenBalance = useCallback(
    async (account: string) => {
      const userPositions = await loadUserPositions(account);
      const positionMap = userPositions ? getPositionMapFromUserPosition(userPositions) : null;

      const erc721Balances = await getUserERC721BalanceInfos(account, positionMap);
      const erc20BalanceInfos = await getUserERC20BalanceInfos(account, positionMap);

      const nativeTokenBalance = await provider?.getBalance(account);
      const forgedNativeTokenBalanceInfo = getForgedNativeTokenBalanceInfo({
        erc20Config: ERC20Config,
        positionMap,
        nativeTokenBalance: new BigNumber(nativeTokenBalance.toString())
      });

      const uniswapBalances = positionMap?.[ERC721Symbol.UNISWAP_LP]
        ? extractUniswapBalancesFromPosition(positionMap?.[ERC721Symbol.UNISWAP_LP], contracts)
        : [];

      if (forgedNativeTokenBalanceInfo) {
        erc20BalanceInfos.unshift(forgedNativeTokenBalanceInfo);
      }

      const orderedERC20BalanceInfos = sortBy(
        erc20BalanceInfos,
        ({ symbol, balance, priceInUSD }) => {
          const balanceInUSD = balance.times(priceInUSD || zero);
          const index = ERC20_EXPECTED_ORDER.indexOf(symbol);
          // specify the token order and sort the remaining assets by position value
          return -(index === -1 ? balanceInUSD : 1e15 - index);
        }
      );

      const orderedERC721BalanceInfos = sortBy(
        erc721Balances,
        ({ tokenIds, priceInUSD }) => -priceInUSD.times(tokenIds.length)
      );

      return {
        ERC20Balances: orderedERC20BalanceInfos,
        ERC721Balances: orderedERC721BalanceInfos,
        uniswapBalances
      } as UserBalanceInfos;
    },
    [
      ERC20Config,
      contracts,
      getUserERC20BalanceInfos,
      getUserERC721BalanceInfos,
      loadUserPositions,
      provider
    ]
  );

  const getAccountBalance = useCallback(
    async (account: string) => {
      if ([Network.PARALLEL_L3_TESTNET, Network.PARALLEL].includes(chainId)) {
        const accountBalance = await getParallelWalletTokenBalance(account);
        return accountBalance;
      }
      const accountBalance = await getCommonWalletTokenBalance(account);
      return accountBalance;
    },
    [chainId, getCommonWalletTokenBalance, getParallelWalletTokenBalance]
  );

  return getAccountBalance;
};
