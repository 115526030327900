import { ComponentType, useRef, memo, useImperativeHandle, forwardRef, useMemo } from 'react';
import { mergeWith, noop, uniqueId } from 'lodash';
import { Modal, ModalProps } from '@parallel-mono/components';
import styled from 'styled-components';

import usePatchElement from './usePatchElement';

import { AnyPlainObject } from '@/apps/paraspace/typings/basic';

const StyledModal = styled(Modal)`
  & .modal {
    overflow: hidden;
  }

  & .modal-body {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #fafafa;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #c1c1c1;
    }
  }
`;
interface ElementsHolderRef {
  patchElement: ReturnType<typeof usePatchElement>[1];
}

const ElementsHolder = memo(
  forwardRef<ElementsHolderRef>((_props, ref) => {
    const [elements, patchElement] = usePatchElement();
    useImperativeHandle(
      ref,
      () => ({
        patchElement
      }),
      [patchElement]
    );
    return <>{elements}</>;
  })
);

interface ModalFunc {
  closeModal: () => void;
}

let uuid = 0;

const useModal = <P extends object>(
  Component: ComponentType<P>,
  modalProps?: Omit<ModalProps, 'children' | 'isOpen' | 'onClose' | 'title'> & {
    titleContent?: string | ((_?: AnyPlainObject) => string | undefined);
  },
  componentProps?: Omit<P, 'data' | 'closeModal'>
) => {
  const holderRef = useRef<ElementsHolderRef>(null);
  const classNames = useMemo(
    () =>
      mergeWith(
        modalProps?.classNames,
        { modal: 'modal', body: 'modal-body' },
        (v1, v2) => `${v1 ?? ''} ${v2 ?? ''}`
      ),
    [modalProps?.classNames]
  );
  let removeModal = noop;
  const closeModal: ModalFunc['closeModal'] = () => removeModal();

  const openModal = <T extends AnyPlainObject>(data?: T) => {
    uuid += 1;
    const title =
      typeof modalProps?.titleContent === 'function'
        ? modalProps?.titleContent(data)
        : modalProps?.titleContent;

    const modal = (
      <StyledModal
        key={`modal-${uuid}`}
        isOpen
        onClose={closeModal}
        title={title}
        closeOnBackdropClick={
          modalProps?.closeOnBackdropClick !== undefined ? modalProps?.closeOnBackdropClick : false
        }
        {...modalProps}
        classNames={classNames}
      >
        <Component data={data} {...(componentProps as P)} closeModal={closeModal} />
      </StyledModal>
    );

    removeModal = holderRef?.current?.patchElement(modal) || removeModal;
  };

  const holderKey = useMemo(() => uniqueId(), []);

  return {
    openModal,
    closeModal,
    holder: <ElementsHolder ref={holderRef} key={`holder-${holderKey}`} />
  };
};

export type { ModalFunc };
export default useModal;
