import { useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { calculateBorrowLimitPoint } from '@/apps/paraspace/pages/hooks/useLendingSimulation/calculateBorrowLimitPoint';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ApeStakingTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';
import useSuppliedAssets from '@/apps/paraspace/pages/hooks/useLendingSimulation/useSuppliedAssets';
import { DEFAULT_MULTIPLIER, MAX_BORROW_PERCENTAGE } from '@/apps/paraspace/pages/config';

type ApeBorrowLimitProps = {
  symbol: ApeStakingTokenSymbol;
  tokenId: number;
  balance: number | null;
  bakcTokenId?: number;
};

export const useApeBorrowLimit = ({
  symbol,
  tokenId,
  balance,
  bakcTokenId
}: ApeBorrowLimitProps) => {
  const currentAllAssets = useSuppliedAssets();
  const {
    erc20InfoMap,
    nftInfoMap,
    overviewUserInfo: { totalBorrowedPositionInUsd }
  } = useMMProvider();
  const { priceInUsd: capePriceInUsd } = erc20InfoMap.CAPE;
  const { priceInUsd: sapePriceInUsd, baseLTVasCollateral: sapeBaseLtv } = erc20InfoMap.SAPE;

  const calculateSupplyNftCredit = useCallback(
    (nftSymbol: ERC721Symbol, nftId: number) => {
      const {
        priceInUsd: nftPriceInUsd,
        baseLTVasCollateral: nftBaseLtv,
        tokenTraitMultipliers,
        nftSuppliedList
      } = nftInfoMap[nftSymbol];

      const isSuppliedNft = nftSuppliedList?.includes(nftId);
      return isSuppliedNft
        ? BigNumber(0)
        : nftPriceInUsd
            .times(nftBaseLtv)
            .times(tokenTraitMultipliers?.[nftId] || DEFAULT_MULTIPLIER);
    },
    [nftInfoMap]
  );

  const supplyNftsCredit = useMemo(() => {
    const supplyMainNftCredit = calculateSupplyNftCredit(symbol, tokenId);
    if (bakcTokenId) {
      const supplyBakcCredit = calculateSupplyNftCredit(ERC721Symbol.BAKC, bakcTokenId);
      return supplyMainNftCredit.plus(supplyBakcCredit);
    }
    return supplyMainNftCredit;
  }, [bakcTokenId, calculateSupplyNftCredit, symbol, tokenId]);

  const supplyBalanceCredit = useMemo(
    () => (balance ? sapeBaseLtv.times(balance).times(sapePriceInUsd) : BigNumber(0)),
    [balance, sapeBaseLtv, sapePriceInUsd]
  );

  const currentCredit = useMemo(
    () => calculateBorrowLimitPoint(currentAllAssets).borrowLimit.minus(totalBorrowedPositionInUsd),
    [currentAllAssets, totalBorrowedPositionInUsd]
  );

  // Formula: supplyNftsCredit + supplyBalanceCredit + currentCredit + maxApeCredit * sapeBaseLtv * sapePrice = maxApeCredit * capePrice
  return useMemo(() => {
    const result = supplyNftsCredit
      .plus(supplyBalanceCredit)
      .plus(currentCredit)
      .div(capePriceInUsd.minus(sapeBaseLtv.times(sapePriceInUsd)));

    return result.gt(0) ? result.times(MAX_BORROW_PERCENTAGE) : BigNumber(0);
  }, [
    capePriceInUsd,
    currentCredit,
    sapeBaseLtv,
    sapePriceInUsd,
    supplyBalanceCredit,
    supplyNftsCredit
  ]);
};
