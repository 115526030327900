import { Modal, ModalProps } from '@parallel-mono/components';
import { useCallback, useEffect, useState } from 'react';
import { StakingType } from 'paraspace-utilities-contract-helpers';

import { ApeCoinSourceToJoinApeListing } from '../../../../types';

import { CreateListingStepper } from './CreateListingStepper';

import { ErrorState, SuccessState } from '@/apps/paraspace/components';
import {
  ApeStakingMainAssetSymbol,
  ApeStakingTokenSymbol,
  WalletType
} from '@/apps/paraspace/typings';

enum State {
  PROCESSING = 1,
  SUCCESS = 2,
  ERROR = 3
}

type Props = {
  offerSymbol: ApeStakingMainAssetSymbol;
  walletType: WalletType;
  seekSymbol: ApeStakingTokenSymbol;
  stakingType: StakingType;
  tokens?: { tokenId: number; supplied: boolean }[];
  numberOfListings?: number;
  apeCoinSource?: ApeCoinSourceToJoinApeListing;
  wantedPercentage: number;
  duration: number;
  onClose: () => void;
  onFinish?: () => void;
} & Omit<ModalProps, 'children'>;

const CreateListingModal = ({
  offerSymbol,
  tokens,
  seekSymbol,
  walletType,
  stakingType,
  apeCoinSource,
  numberOfListings,
  wantedPercentage,
  duration,
  onClose,
  onFinish,
  ...other
}: Props) => {
  const [status, setStatus] = useState(State.PROCESSING);

  useEffect(() => {
    if (other.isOpen) {
      setStatus(State.PROCESSING);
    }
  }, [other.isOpen]);

  const handleSuccess = useCallback(() => {
    setStatus(State.SUCCESS);
    onFinish?.();
  }, [onFinish]);

  const handleError = useCallback(() => {
    setStatus(State.ERROR);
  }, []);

  return (
    <Modal title="Create Listing" onClose={onClose} {...other}>
      {(() => {
        if (status === State.PROCESSING) {
          return (
            <CreateListingStepper
              offerSymbol={offerSymbol}
              walletType={walletType}
              stakingType={stakingType}
              tokens={tokens}
              apeCoinSource={apeCoinSource}
              seekSymbol={seekSymbol}
              numberOfListings={numberOfListings}
              wantedPercentage={wantedPercentage}
              duration={duration}
              onSuccess={handleSuccess}
              onError={handleError}
            />
          );
        }
        if (status === State.SUCCESS) {
          return (
            <SuccessState
              desc="Successfully Created Listing"
              tip="You can view your position any time."
              actionButtonText="Done"
              onAction={onClose}
            />
          );
        }
        if (status === State.ERROR) {
          return <ErrorState closeModal={onClose} />;
        }
        return null;
      })()}
    </Modal>
  );
};

export default CreateListingModal;
