import { useCallback } from 'react';
import BigNumberJs from 'bignumber.js';
import { ItemType } from 'paraspace-seaport-js/lib/constants';
import { ConsiderationInputItem, CreateInputItem } from 'paraspace-seaport-js/lib/types';
import dayjs from 'dayjs';
import { isEmpty, last } from 'lodash';

import { ListingToken } from '../types';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import useSeaport from '@/apps/paraspace/pages/hooks/useSeaport';
import { shiftedRightBy } from '@/apps/paraspace/utils/calculations';
import { useAppConfig, useContractsMap } from '@/apps/paraspace/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { OrderSide } from '@/apps/paraspace/consts/order';
import { useCollections } from '@/apps/paraspace/pages/Shop/contexts';
import { InputProtocolData, useCreateOrdersMutation } from '@/apps/paraspace/generated/graphql';
import { convertListOrderTo14Compatible } from '@/apps/paraspace/utils/listOrderFormater';
import { ERC721Symbol } from '@/apps/paraspace/typings';

export type CreateOrderResponseData = {
  hash: string;
  tokenId?: number;
  symbol?: ERC721Symbol;
};

export const useCreateOrders = () => {
  const { nftInfoMap } = useMMProvider();
  const { erc20Config } = useAppConfig();
  const { account } = useWeb3Context();
  const { seaportSDK } = useSeaport();
  const contracts = useContractsMap();
  const { collections } = useCollections();

  const [createOrders] = useCreateOrdersMutation({
    refetchQueries: ['getShopListings']
  });

  const createOrder = useCallback(
    async ({ tokenId, symbol, duration, price }: ListingToken) => {
      if (!account) {
        return null;
      }
      try {
        const contractAddress = nftInfoMap[symbol].address;
        const startTime = dayjs().unix();
        const endTime = dayjs.unix(startTime).add(duration, 'day').unix();
        const amount = shiftedRightBy(BigNumberJs(price), erc20Config.ETH.decimals).toString();
        const offer = [
          {
            token: contractAddress,
            itemType: ItemType.ERC721,
            identifier: tokenId.toString()
          } as CreateInputItem
        ];
        const consideration = [
          {
            amount,
            recipient: account
          } as ConsiderationInputItem
        ];

        const fees = collections.find(v => v.symbol === symbol)?.fees;

        const feesParams = fees
          ? [
              {
                recipient: fees.paraSpaceFeeAddress,
                basisPoints: fees.paraSpaceFeePoint
              },
              { recipient: fees.creatorFeeAddress, basisPoints: fees.creatorFeePoint }
            ]
          : [];

        if (seaportSDK) {
          const { executeAllActions } = await seaportSDK.createOrder(
            {
              startTime: `${startTime}`,
              endTime: `${endTime}`,
              offer,
              offerer: account,
              consideration,
              restrictedByZone: false,
              conduitKey: contracts.ConduitKey,
              fees: feesParams.filter(fee => fee.recipient && fee.basisPoints)
            },
            account
          );

          const order = await executeAllActions();
          return order;
        }
        throw new Error('Seaport does not exist.');
      } catch (e) {
        console.error(`Create Seaport order failed, error: ${e}`);
        throw e;
      }
    },
    [nftInfoMap, erc20Config.ETH.decimals, account, collections, seaportSDK, contracts.ConduitKey]
  );

  const createOrderList = useCallback(
    async (listingTokens: ListingToken[]) => {
      const orders = await Promise.allSettled(listingTokens.map(token => createOrder(token), []));

      const validOrders = orders.filter(
        order => order.status === 'fulfilled'
      ) as PromiseFulfilledResult<any>[];
      if (isEmpty(validOrders)) {
        throw (
          (last(orders) as PromiseRejectedResult)?.reason ??
          new Error('Create seaport order unknown error')
        );
      }

      const inputOrders = validOrders.map(order => ({
        side: OrderSide.Consideration,
        // TODO: update once paraspace seaport has migrated to v1.4
        protocolVersion: '1.1',
        protocolContract: contracts.Seaport,
        protocolData: convertListOrderTo14Compatible(order!.value) as InputProtocolData
      }));
      const results = await createOrders({
        variables: {
          inputOrders
        }
      });
      return results.data?.createOrders.map(({ hash, offerItems }) => ({
        hash,
        tokenId:
          offerItems?.[0]?.identifierOrCriteria && Number(offerItems?.[0]?.identifierOrCriteria),
        collectionAddress: offerItems?.[0]?.token
      }));
    },
    [contracts.Seaport, createOrder, createOrders]
  );

  return createOrderList;
};
