import { memo, useCallback, useEffect, useRef } from 'react';
import { Stack } from '@parallel-mono/components';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { MoneyMarketActionsProvider, NFTPageUIProvider } from '../contexts';
import { ReStakeBanner, V1cAPEConvert } from '../components';
import Stats from '../Stats';
import { Market, MarketHandle } from '../MoneyMarket';
import { ApeListProvider, StakingInfosProvider } from '../../ApePairing';
import { LiquidationAlert } from '../LiquidationAlert';

import { PageLayout } from '@/apps/paraspace/components';

const BannerWrapper = styled(Stack)`
  &:empty + * {
    margin-top: 0;
  }
  ${({ theme }) => theme.breakpoints.only('mobile')`
    width: 100vw;
    overflow-x: scroll;
    flex-direction: row;
    padding-right: 2rem;

    &> * {
        flex: 0 0 18.875rem;
    }
    &> *:only-child {
      flex: 1;
    }

    &> * + * {
        margin-left: 1.5rem;
        margin-top: 0;
    }
  `}
`;

export enum SCROLL_TO_NAME {
  ERC20_TABLE
}

type LocationState = {
  scrollTo?: SCROLL_TO_NAME;
} | null;

export const Lending = memo(() => {
  const MarketAndSupplyRef = useRef<MarketHandle>(null);
  const scrollToERC20Table = useCallback(() => {
    MarketAndSupplyRef.current?.locateBorrowTable();
  }, []);

  const scrollToNFTSupplyTable = useCallback(() => {
    MarketAndSupplyRef.current?.locateSupplyNft();
  }, []);

  const { state } = useLocation();
  useEffect(() => {
    if ((state as LocationState)?.scrollTo === SCROLL_TO_NAME.ERC20_TABLE) {
      scrollToERC20Table();
    }
  }, [scrollToERC20Table, state]);

  return (
    <NFTPageUIProvider
      value={{
        scrollToERC20Table,
        scrollToNFTSupplyTable
      }}
    >
      <MoneyMarketActionsProvider>
        <PageLayout
          gap="0"
          banner={
            <Stack gap="0">
              <Stats />
            </Stack>
          }
          mainContent={
            <Stack width="100%">
              <BannerWrapper>
                <StakingInfosProvider>
                  <ApeListProvider>
                    <ReStakeBanner />
                  </ApeListProvider>
                </StakingInfosProvider>
                <V1cAPEConvert />
              </BannerWrapper>

              <LiquidationAlert />
              <Market ref={MarketAndSupplyRef} />
              {/* <Footer /> */}
            </Stack>
          }
        />
      </MoneyMarketActionsProvider>
    </NFTPageUIProvider>
  );
});
