import { FC, memo } from 'react';
import { H5, Inline, Image } from '@parallel-mono/components';

import heartHalfContained from '@/apps/paraspace/assets/heart-half-contained.svg';

export const SpecificTip: FC<{ message: string }> = memo(({ message }) => {
  return (
    <Inline gap=".5rem">
      <Image src={heartHalfContained} />
      <H5>{message}</H5>
    </Inline>
  );
});
