import { useState, useCallback } from 'react';
import BigNumber from 'bignumber.js';

import { ClaimAllModalProps } from '../ClaimAllModal';

import { zero } from '@/apps/paraspace/consts/values';

const defaultClaimAllRewardModalProps: ClaimAllModalProps = {
  onSuccess: () => {},
  onClose: () => {},
  claimValue: zero,
  isOpen: false
};

export const useClaimAllReward = (): [
  ClaimAllModalProps,
  (claimValue: BigNumber, onSuccess: () => void) => Promise<void>
] => {
  const [claimAllModalProps, setClaimAllModalProps] = useState<ClaimAllModalProps>(
    defaultClaimAllRewardModalProps
  );

  const claimAllReward = useCallback(
    (claimValue: BigNumber, onSuccess: () => void) => {
      return new Promise<void>(resolve => {
        setClaimAllModalProps({
          isOpen: true,
          claimValue,
          onSuccess,
          onClose: () => {
            setClaimAllModalProps(prev => ({
              ...prev,
              isOpen: false
            }));
            resolve();
          }
        });
      });
    },
    [setClaimAllModalProps]
  );

  return [claimAllModalProps, claimAllReward];
};
