import { createContext, memo, ReactNode, useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { ApeCoinListing, ApeListing, BakcListing, Listing } from '../../types';

import { CancelListingModal } from './CancelListingModal';
import { useCancelListing } from './hooks/useCancelListing';
import { UnmatchModal } from './UnmatchModal';
import { useUnmatchListing } from './hooks/useUnmatchListing';
import { ClaimAllModal } from './ClaimAllModal';
import { useClaimAllReward } from './hooks/useClaimAllReward';
import { JoinListingWithApeCoinModal } from './JoinListingWithApeCoinModal';
import { useJoinListingWithApeCoin } from './hooks/useJoinListingWithApeCoin';
import { useJoinListingWithApe } from './hooks/useJoinListingWithApe';
import { JoinListingWithApeModal } from './JoinListingWithApe';
import { useJoinBAKCPairListings } from './hooks';
import { JoinBAKCPairListingsModal } from './JoinBAKCPairListingsModal';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { WalletType } from '@/apps/paraspace/typings';

export type P2PStakingContextValue = {
  cancelListing: (listing: Listing) => Promise<void>;
  unmatchListing: (params: {
    blockHeight: string;
    matchedHash: string;
    apeListing: ApeListing;
    apeCoinListing: ApeCoinListing;
    bakcListing: Maybe<BakcListing>;
  }) => Promise<void>;
  claimAllReward: (claimValue: BigNumber, onSuccess: () => void) => Promise<void>;
  joinListingWithApeCoin: (apeListing: ApeListing, walletType: WalletType) => Promise<void>;
  joinListingWithApe: (apeCoinListing: ApeCoinListing, walletType: WalletType) => Promise<void>;
  joinBAKCPairListings: (params: {
    apeListing: Maybe<ApeListing>;
    bakcListing: Maybe<BakcListing>;
    apeCoinListing: Maybe<ApeCoinListing>;
    walletType: WalletType;
  }) => Promise<void>;
};

const P2PStakingContext = createContext<P2PStakingContextValue>({
  cancelListing: () => {
    throw new Error('not implemented yet');
  },
  unmatchListing: () => {
    throw new Error('not implemented yet');
  },
  claimAllReward: () => {
    throw new Error('P2PStakingProvider not found');
  },
  joinListingWithApeCoin: () => {
    throw new Error('not implemented yet');
  },
  joinListingWithApe: () => {
    throw new Error('not implemented yet');
  },
  joinBAKCPairListings: () => {
    throw new Error('not implemented yet');
  }
});

type P2PStakingProviderProps = {
  walletType: WalletType;
  children: ReactNode;
};

export const P2PStakingProvider = memo(({ children }: P2PStakingProviderProps) => {
  const [cancelListingModalProps, cancelListing] = useCancelListing();
  const [unmatchListingModalProps, unmatchListing] = useUnmatchListing();
  const [claimAllModalProps, claimAllReward] = useClaimAllReward();
  const [joinListingWithApeCoinModalProps, joinListingWithApeCoin] = useJoinListingWithApeCoin();
  const [joinListingWithApeModalProps, joinListingWithApe] = useJoinListingWithApe();
  const [joinBAKCPairListingsModalProps, joinBAKCPairListings] = useJoinBAKCPairListings();

  const contextValue: P2PStakingContextValue = useMemo(
    () => ({
      cancelListing,
      claimAllReward,
      unmatchListing,
      joinListingWithApeCoin,
      joinListingWithApe,
      joinBAKCPairListings
    }),
    [
      cancelListing,
      claimAllReward,
      unmatchListing,
      joinListingWithApeCoin,
      joinListingWithApe,
      joinBAKCPairListings
    ]
  );

  return (
    <P2PStakingContext.Provider value={contextValue}>
      <CancelListingModal {...cancelListingModalProps} />
      <UnmatchModal {...unmatchListingModalProps} />
      <ClaimAllModal {...claimAllModalProps} />
      <JoinListingWithApeCoinModal {...joinListingWithApeCoinModalProps} />
      <JoinListingWithApeModal {...joinListingWithApeModalProps} />
      <JoinBAKCPairListingsModal {...joinBAKCPairListingsModalProps} />
      {children}
    </P2PStakingContext.Provider>
  );
});

export const useP2PStaking = () => useContext(P2PStakingContext);
