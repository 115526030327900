import { Network } from 'paraspace-configs-v2';

import { SupportedNetworks } from '../../typings';

export type MarketCurrencyTokenInfo = {
  name: string;
  decimal: number;
  isNativeTokenForNetWork: boolean;
};

const ETH_TOKEN_INFO: MarketCurrencyTokenInfo = {
  decimal: 18,
  name: 'ETH',
  isNativeTokenForNetWork: true
};

const USD_TOKEN_INFO: MarketCurrencyTokenInfo = {
  decimal: 8,
  name: 'USD',
  isNativeTokenForNetWork: false
};

export const MARKET_CURRENCY_INFO_WITH_NETWORK: Record<SupportedNetworks, MarketCurrencyTokenInfo> =
  {
    [Network.SEPOLIA]: ETH_TOKEN_INFO,
    [Network.MAINNET]: ETH_TOKEN_INFO,
    [Network.ZKSYNC_ERA]: USD_TOKEN_INFO,
    [Network.ZKSYNC_GOERLI]: USD_TOKEN_INFO,
    [Network.ARBITRUM_ONE]: ETH_TOKEN_INFO,
    [Network.ARBITRUM_GOERLI]: ETH_TOKEN_INFO,
    [Network.PARALLEL_L3_TESTNET]: ETH_TOKEN_INFO
  };
