import { WalletTypeEnum } from 'parax-sdk';

export const LAST_CONNECTED_TYPE_LOCAL_SESSION_NAME = 'LAST_CONNECTED_TYPE_LOCAL_SESSION_NAME';

export const UNRECOGNIZED_CHAIN_ERR_CODE = 4902;

export const ORDERED_WALLET_TO_INIT = [
  WalletTypeEnum.GNOSIS_SAFE,
  WalletTypeEnum.METAMASK,
  WalletTypeEnum.OKX_WALLET,
  WalletTypeEnum.COINBASE_WALLET,
  WalletTypeEnum.NETWORK
];
