import { useCallback } from 'react';

import useLegacyERC721 from '../useLegacyERC721';
import useCryptPunksMarket from '../useCryptoPunksMarket';

import { useApproval } from './useApproval';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useSymbolByContractAddress } from '@/apps/paraspace/hooks';

export const useERC721Approval = ({
  token,
  spender,
  ids
}: {
  token: string;
  spender?: string;
  ids?: number[];
}) => {
  const { setApprovalForAll, checkApprovalForAll } = useLegacyERC721(token);
  const symbol = useSymbolByContractAddress(token) as ERC721Symbol;
  const { getPunksApprovedInfo, offerPunkForSaleToAddress } = useCryptPunksMarket();

  const getAllowance = useCallback(async () => {
    if (symbol === ERC721Symbol.PUNK) {
      const punkInfo = await getPunksApprovedInfo(ids!);
      return punkInfo.every(item => item.approved);
    }
    return checkApprovalForAll(spender);
  }, [checkApprovalForAll, getPunksApprovedInfo, ids, spender, symbol]);

  const checkAllowance = useCallback(
    (approvedForAll: Maybe<boolean>) => approvedForAll ?? false,
    []
  );

  const createApproveTransaction = useCallback(async () => {
    if (symbol === ERC721Symbol.PUNK) {
      const punkInfos = (await getPunksApprovedInfo(ids!)).filter(item => !item.approved);
      return Promise.all(
        punkInfos.map(({ id }) =>
          offerPunkForSaleToAddress(id).then(async tx => {
            await tx?.wait();
          })
        )
      );
    }
    return setApprovalForAll(spender);
  }, [getPunksApprovedInfo, ids, offerPunkForSaleToAddress, setApprovalForAll, spender, symbol]);

  return useApproval<boolean>({
    getAllowance,
    checkAllowance,
    createApproveTransaction
  });
};
