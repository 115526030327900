import { Stack, Text } from '@parallel-mono/components';
import { FC, useCallback, useMemo, useState } from 'react';

import { ApeStakeInfo, BakcStakeInfo, ReStakeFormData } from './type';
import { StakeBakcsForm, StakeApesForm } from './forms';

import { NFTCollectionThumbnail, Select, SelectBaseOption } from '@/apps/paraspace/components';
import { ApeStakingTokenSymbol, ERC721Symbol } from '@/apps/paraspace/typings';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const ReStakeForm: FC<{ onSubmit: (formData: ReStakeFormData) => void }> = props => {
  const { onSubmit } = props;

  const [selectedPool, setSelectedPool] = useState<ApeStakingTokenSymbol>(ERC721Symbol.BAYC);

  const stakePoolOptions = useMemo(
    () =>
      [ERC721Symbol.BAYC, ERC721Symbol.MAYC, ERC721Symbol.BAKC].map(symbol => ({
        icon: <NFTCollectionThumbnail symbol={symbol} size="xSmall" round />,
        label: `${symbol} Pool`,
        value: symbol
      })),
    []
  );

  const handleSubmitApes = useCallback(
    (apes: ApeStakeInfo[]) => {
      onSubmit({
        apes
      });
    },
    [onSubmit]
  );

  const handleSubmitBakcs = useCallback(
    (bakcs: BakcStakeInfo[]) => {
      onSubmit({
        bakcs
      });
    },
    [onSubmit]
  );

  return (
    <Stack>
      <Text>You can re-stake them with cAPE supplied position.</Text>
      <Select
        options={stakePoolOptions}
        value={selectedPool}
        onChange={(option: Maybe<SelectBaseOption>) => {
          setSelectedPool(option?.value);
        }}
      />
      {selectedPool === ERC721Symbol.BAYC && (
        <StakeApesForm symbol={ERC721Symbol.BAYC} onSubmit={handleSubmitApes} />
      )}
      {selectedPool === ERC721Symbol.MAYC && (
        <StakeApesForm symbol={ERC721Symbol.MAYC} onSubmit={handleSubmitApes} />
      )}
      {selectedPool === ERC721Symbol.BAKC && <StakeBakcsForm onSubmit={handleSubmitBakcs} />}
    </Stack>
  );
};
