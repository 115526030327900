import { Environment } from 'parax-sdk';

import { ERC721Symbol } from '@/apps/paraspace/typings';

export const OFFICIAL_STAKE_CONFIG = {
  [Environment.DEVELOPMENT]: {
    APE_STAKING_ADDR: '0xeF37717B1807a253c6D140Aca0141404D23c26D4',
    APE_COIN_ADDR: '0x328507DC29C95c170B56a1b3A758eB7a9E73455c',
    APE_NFT_ADDR: {
      [ERC721Symbol.BAYC]: '0xF40299b626ef6E197F5d9DE9315076CAB788B6Ef',
      [ERC721Symbol.BAKC]: '0xd60d682764Ee04e54707Bee7B564DC65b31884D0',
      [ERC721Symbol.MAYC]: '0x3f228cBceC3aD130c45D21664f2C7f5b23130d23'
    }
  },
  [Environment.STAGING]: {
    APE_STAKING_ADDR: '0xeF37717B1807a253c6D140Aca0141404D23c26D4',
    APE_COIN_ADDR: '0x328507DC29C95c170B56a1b3A758eB7a9E73455c',
    APE_NFT_ADDR: {
      [ERC721Symbol.BAYC]: '0xF40299b626ef6E197F5d9DE9315076CAB788B6Ef',
      [ERC721Symbol.BAKC]: '0xd60d682764Ee04e54707Bee7B564DC65b31884D0',
      [ERC721Symbol.MAYC]: '0x3f228cBceC3aD130c45D21664f2C7f5b23130d23'
    }
  },
  [Environment.PRODUCTION]: {
    APE_STAKING_ADDR: '0x5954ab967bc958940b7eb73ee84797dc8a2afbb9',
    APE_COIN_ADDR: '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
    APE_NFT_ADDR: {
      [ERC721Symbol.BAYC]: '0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D',
      [ERC721Symbol.BAKC]: '0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623',
      [ERC721Symbol.MAYC]: '0x60E4d786628Fea6478F785A6d7e704777c86a7c6'
    }
  }
};
