import { PaymentTokenOption } from './hooks';

import { Maybe } from '@/apps/paraspace/typings/basic';

export const MINIMUM_BID_PRICE = 0.001;

export const validate = ({
  borrowAmount,
  walletAmount,
  selectedPaymentToken
}: {
  borrowAmount: Maybe<number>;
  walletAmount: Maybe<number>;
  selectedPaymentToken: PaymentTokenOption;
}) => {
  if (
    (borrowAmount !== null || walletAmount !== null) &&
    Number(borrowAmount) + Number(walletAmount) < MINIMUM_BID_PRICE
  ) {
    return `Minimum offer must be more than ${MINIMUM_BID_PRICE} ${selectedPaymentToken?.value}.`;
  }
  return null;
};
