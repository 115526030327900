import { useCallback, useState } from 'react';
import { find } from 'lodash';

import { ApeListItem } from '@/apps/paraspace/pages/ApePairing/contexts';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const useApeTokenSelector = (apeList: ApeListItem[], defaultApe: ApeListItem) => {
  const [apeToken, setApeToken] = useState<Maybe<ApeListItem>>(defaultApe);

  const handleApeTokenChange = useCallback(
    selectedToken => {
      if (selectedToken) {
        const selectedMainTokenInfo = find(
          apeList,
          i => i.symbol === selectedToken.symbol && i.tokenId === selectedToken.tokenId
        );

        setApeToken(selectedMainTokenInfo ?? null);
      } else {
        setApeToken(null);
      }
    },
    [apeList]
  );

  return {
    handleApeTokenChange,
    apeToken
  };
};
