import { Modal, ModalProps } from '@parallel-mono/components';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { BatchListTokensSteppers } from '../steppers';
import { ListingToken } from '../../../types';
import { CreateOrderResponseData } from '../../../hooks';

import {
  CollectAndSubmitProcedurePhase,
  useCollectAndSubmitProcedure,
  ErrorState,
  SuccessState
} from '@/apps/paraspace/components';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';

type ListingModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  listingTokens: ListingToken[];
  onError?: () => void;
  onFinish?: () => void;
  onClose?: () => void;
};

export const ListingModal: FC<ListingModalProps> = ({
  isOpen,
  listingTokens,
  onFinish,
  onClose,
  onError,
  ...others
}) => {
  const handleFinish = useCallback(() => {
    onFinish?.();
  }, [onFinish]);

  const { phase, handleSubmitFailed, handleSubmitSuccess } = useCollectAndSubmitProcedure<
    {
      listingTokens: ListingToken[];
    },
    CreateOrderResponseData[]
  >({
    running: isOpen,
    defaultPhase: CollectAndSubmitProcedurePhase.Submitting,
    onFinish: handleFinish,
    onError
  });

  const navigate = useNavigate();

  const handleCloseModal = useCallback(() => {
    onClose?.();

    if (phase === CollectAndSubmitProcedurePhase.Success) {
      navigate(absoluteRouteNames.SHOP.LIST.MY_LISTINGS);
    }
  }, [navigate, onClose, phase]);
  return (
    <Modal
      closeOnBackdropClick={false}
      closeOnEscapeKey={false}
      isOpen={isOpen}
      onClose={handleCloseModal}
      title="List"
      {...others}
    >
      {phase === CollectAndSubmitProcedurePhase.Submitting && (
        <BatchListTokensSteppers
          formData={{ listingTokens }}
          onError={handleSubmitFailed}
          onFinish={handleSubmitSuccess}
        />
      )}

      {phase === CollectAndSubmitProcedurePhase.Success && (
        <SuccessState
          desc={`Successfully Listed ${
            listingTokens?.length > 1 ? `${listingTokens?.length} NFTs` : 'NFT'
          } `}
          tip="You can change your listings on “My Listing Tab”"
          onAction={handleCloseModal}
          actionButtonText="Done"
        />
      )}
      {phase === CollectAndSubmitProcedurePhase.Failed && (
        <ErrorState closeModal={handleCloseModal} />
      )}
    </Modal>
  );
};
