import { FC, memo, useCallback } from 'react';

import useAcceptBlurBid, { BlurBid } from '@/apps/paraspace/pages/hooks/useAcceptBlurBid';
import { FormSubmitter } from '@/apps/paraspace/components';

type Props = {
  onFinish: () => void;
  onError: () => void;
  formData: {
    bid: BlurBid;
  };
};

export const AcceptBlurBidSubmitter: FC<Props> = memo(
  ({ onFinish, onError, formData: { bid } }) => {
    const { initiateAcceptBlurBids } = useAcceptBlurBid();

    const handleSubmit = useCallback(
      async () => initiateAcceptBlurBids([bid]),
      [bid, initiateAcceptBlurBids]
    );

    return <FormSubmitter submit={handleSubmit} onError={onError} onFinish={onFinish} />;
  }
);
