import { Card, H3, H5, Stack, Tabs, Image } from '@parallel-mono/components';
import { HTMLAttributes, memo, useMemo } from 'react';
import styled from 'styled-components';

import compoundApe from '../../../../Images/compound-ape.svg';

import { Withdraw } from './CApeWithdraw';
import SupplyApeContainer from './SupplyApeCoin';

export type PoolTab = 'supply' | 'withdraw';

type ApeCoinPoolProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  tab: PoolTab;
  onTabChange: (tab: PoolTab) => void;
};

const Container = styled(Card)`
  width: 29rem;
  flex: 0 0 29rem;
  height: fit-content;
  ${({ theme }) => theme.breakpoints.down('desktop')`
    width: 100%;
  `}
`;
const StyledTabs = styled(Tabs)`
  margin-bottom: 0.5rem;
  width: 100%;
  .tabs-slot {
    width: 100%;
  }
`;

const tabs = [
  { title: <H5>Supply</H5>, content: <SupplyApeContainer />, key: 'supply' },
  { title: <H5>Withdraw</H5>, content: <Withdraw width="100%" />, key: 'withdraw' }
];

export const ApeCoinPool = memo(({ tab, onTabChange, ...others }: ApeCoinPoolProps) => {
  const tabIndex = useMemo(() => tabs.findIndex(it => it.key === tab), [tab]);
  return (
    <Container border {...others}>
      <Stack alignItems="center">
        <Image src={compoundApe} />
        <H3>ParaSpace ApeCoin Pool</H3>
        <StyledTabs
          classNames={{ tab: 'tab' }}
          tabs={tabs}
          activeTab={tabs[tabIndex]}
          onActiveTabChange={(_, index) => onTabChange(tabs[index].key as PoolTab)}
        />
        {tabs[tabIndex].content}
      </Stack>
    </Container>
  );
});
