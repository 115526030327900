import { useMemo } from 'react';
import { DataGridColumn, Inline, useBreakpoints, H5, Text } from '@parallel-mono/components';

import { useNetworkConfig } from '@/apps/paraspace/hooks';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { Link, RoundProfilePicture } from '@/apps/paraspace/components';

export type GridData = {
  position: string;
  value: string;
  address: string;
  display: string;
};

export const useBnplAndDepositColum = ({
  userData,
  valueColumnLabel
}: {
  userData: Maybe<GridData>;
  valueColumnLabel: string;
}) => {
  const { mobile } = useBreakpoints();
  const {
    explorerLink: [explorerLinkBaseUrl]
  } = useNetworkConfig();

  const columns: DataGridColumn<GridData>[] = useMemo(
    () => [
      {
        name: 'position',
        title: mobile ? null : 'Position',
        width: '3fr'
      },
      {
        name: 'name',
        title: 'Name',
        width: '3fr',
        render: ({ data: { address, display } }) => {
          const isCurrent = address === userData?.address;
          return (
            <Inline alignItems="center" gap="0.5rem">
              <RoundProfilePicture width="1.5rem" height="1.5rem" />
              <Link target="_blank" href={`${explorerLinkBaseUrl}/address/${address}`}>
                {isCurrent ? <H5>{display} (you)</H5> : <Text>{display}</Text>}
              </Link>
            </Inline>
          );
        }
      },
      {
        name: 'value',
        title: valueColumnLabel,
        width: '3fr',
        render: ({ data: { value, address } }) => {
          const isCurrent = address === userData?.address;
          return <Inline>{isCurrent ? <H5>{value}</H5> : <Text>{value}</Text>}</Inline>;
        }
      }
    ],
    [mobile, valueColumnLabel, userData?.address, explorerLinkBaseUrl]
  );

  return columns;
};
