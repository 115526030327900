import { useCallback } from 'react';
import BigNumber from 'bignumber.js';

import { useWNativeTokenSymbol } from './useWNativeTokenSymbol';

import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import useLegacyERC20 from '@/apps/paraspace/pages/hooks/useLegacyERC20';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useSymbolByContractAddress } from '@/apps/paraspace/hooks';

const useLiquidation = (contractAddress: string, identifierOrCriteria: number) => {
  const { startAuction, endAuction, getAuctionData, liquidationERC721 } = usePool();
  const { erc20InfoMap, nftInfoMap } = useMMProvider();
  const wNativeToken = useWNativeTokenSymbol();
  const { approve: approveWNativeToken, getAllowance: getWethAllowance } = useLegacyERC20(
    erc20InfoMap[wNativeToken]?.address
  );

  const symbol = useSymbolByContractAddress(contractAddress) as ERC721Symbol;

  const buyAsset = useCallback(
    async ({
      user,
      asset: { floorPrice, buyNowPrice }
    }: {
      user: string;
      asset: { floorPrice: BigNumber; buyNowPrice: BigNumber };
    }) => {
      const { xTokenAddress } = nftInfoMap[symbol];
      const latestAuctionData = await getAuctionData(
        xTokenAddress,
        String(identifierOrCriteria)
      ).catch(() => null);

      const latestPriceMultiplier = latestAuctionData?.currentPriceMultiplier
        ? new BigNumber(latestAuctionData.currentPriceMultiplier.toString())
            .shiftedBy(-18)
            .integerValue(BigNumber.ROUND_CEIL)
            .toString()
        : null;

      const price = latestPriceMultiplier
        ? floorPrice.times(latestPriceMultiplier).toString()
        : buyNowPrice.toString();

      const allowance = await getWethAllowance();
      if (new BigNumber(allowance).lt(price)) {
        await approveWNativeToken();
      }

      return liquidationERC721({
        user,
        asset: contractAddress,
        tokenId: identifierOrCriteria,
        price
      })
        .then(tx => tx?.wait())
        .catch(e => {
          console.error(`Calling liquidation failed, error: ${e}`);
          throw e;
        });
    },
    [
      approveWNativeToken,
      contractAddress,
      getAuctionData,
      getWethAllowance,
      identifierOrCriteria,
      liquidationERC721,
      nftInfoMap,
      symbol
    ]
  );

  const triggerAuction = useCallback(
    async ({ user }: { user: string }) =>
      startAuction(user, contractAddress, identifierOrCriteria)
        .then(tx => tx?.wait())
        .catch(e => {
          throw getUserFriendlyError(e);
        }),
    [contractAddress, identifierOrCriteria, startAuction]
  );

  const closeSingleAuction = useCallback(
    async ({ user }: { user: string }) =>
      endAuction(user, contractAddress, identifierOrCriteria)
        .then(tx => tx?.wait())
        .catch(e => {
          throw getUserFriendlyError(e);
        }),
    [contractAddress, endAuction, identifierOrCriteria]
  );

  return { buyAsset, triggerAuction, closeSingleAuction };
};

export default useLiquidation;
