import { HTMLAttributes, memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button, H3, Text, Inline, Stack, Tag } from '@parallel-mono/components';
import { InfoPanel } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';
import ReactGA from 'react-ga4';
import { TransactionReceipt } from '@ethersproject/providers';

import {
  NFTThumbnail,
  ErrorState,
  TraitBoostTip,
  ConnectWallet
} from '@/apps/paraspace/components';
import { ERC721Symbol } from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useWeb3Context, useParallelToast } from '@/apps/paraspace/contexts';
import { formatBalance, formatToCurrency } from '@/apps/paraspace/utils/format';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { DEFAULT_MULTIPLIER } from '@/apps/paraspace/pages/config';
import { useSymbolByContractAddress } from '@/apps/paraspace/hooks';
import { useWNativeTokenSymbol } from '@/apps/paraspace/pages/hooks';

const ItemInfo = styled(Inline).attrs({ gap: '0' })`
  height: 7.5rem;
`;

const ItemDetail = styled(Stack)`
  padding-left: 1.5rem;
  ${({ theme }) => theme.breakpoints.down('lg')`
    overflow: hidden;
  `};
`;

const StyledH3 = styled(H3)`
  ${({ theme }) => theme.breakpoints.down('lg')`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `};
`;

export type ProcessStatus = 'init' | 'processing' | 'success' | 'error';
export type LiquidationBuyModalPayload = {
  buyParameters: { user: string; buyNowPrice: BigNumber; floorPrice: BigNumber };
  name?: string;
  address?: string;
  currentPrice?: string;
  currentPriceInUsd?: number;
  tokenId?: string;
  traitMultiplier?: BigNumber;
};
export type MakeOfferModalProps = HTMLAttributes<HTMLDivElement> & {
  data: LiquidationBuyModalPayload;
  onBuyAsset: (
    user: string,
    prices: { buyNowPrice: BigNumber; floorPrice: BigNumber }
  ) => Promise<TransactionReceipt | undefined>;
  closeModal: () => void;
  onAddWETHClick: () => void;
};

const StyledInfoPanel = styled(InfoPanel)`
  margin-top: 1.5rem;
`;

const MainForm = memo((props: MakeOfferModalProps) => {
  const {
    data: {
      name,
      address,
      tokenId,
      currentPrice,
      currentPriceInUsd,
      buyParameters,
      traitMultiplier
    },
    closeModal,
    onBuyAsset,
    onAddWETHClick,
    ...others
  } = props;
  const isShowTraitMultiplier = traitMultiplier && traitMultiplier.gt(DEFAULT_MULTIPLIER);
  const wNativeToken = useWNativeTokenSymbol();

  const symbol = useSymbolByContractAddress(address ?? '');

  const { isUsingUserWallet } = useWeb3Context();
  const { erc20InfoMap } = useMMProvider();

  const [processStatus, setProcessStatus] = useState<ProcessStatus>('init');
  const { balance: wTokenBalance } = erc20InfoMap[wNativeToken] || {};

  const parallelToast = useParallelToast();

  const handleBuyLiquidationAsset = useCallback(async () => {
    const { user, floorPrice, buyNowPrice } = buyParameters;
    await parallelToast.promise(async () => {
      setProcessStatus('processing');
      await onBuyAsset(user, {
        buyNowPrice,
        floorPrice
      })
        .then(() => {
          closeModal();
          setProcessStatus('success');
          ReactGA.event({
            action: 'liquidation',
            category: 'buy',
            label: symbol ?? '',
            value: buyNowPrice.toNumber()
          });
        })
        .catch(err => {
          setProcessStatus('error');
          throw getUserFriendlyError(err);
        });
    });
  }, [buyParameters, closeModal, onBuyAsset, parallelToast, symbol]);

  const currentPriceInEth = new BigNumber(currentPrice || '0');
  const confirmBtnDisabled = wTokenBalance?.lt(currentPriceInEth);
  const isLoading = processStatus === 'processing';

  return (
    <Stack {...others}>
      {(() => {
        if (processStatus === 'error') {
          return <ErrorState closeModal={closeModal} />;
        }
        return (
          <>
            <ItemInfo alignItems="center">
              <NFTThumbnail
                size="medium"
                symbol={symbol as ERC721Symbol}
                tokenId={Number(tokenId)}
                floatingTag={
                  isShowTraitMultiplier && (
                    <Tag skin="success" size="small">
                      {formatNumber(traitMultiplier)}x Boost
                    </Tag>
                  )
                }
              />
              <ItemDetail gap="0.75rem">
                <Stack gap="0.25rem">
                  <StyledH3>
                    {name} #{tokenId}
                  </StyledH3>
                  {currentPriceInUsd && (
                    <Text>
                      Liquidation Price: {formatBalance(currentPriceInEth)} {wNativeToken} (
                      {formatToCurrency(currentPriceInUsd)})
                    </Text>
                  )}
                </Stack>
                {isShowTraitMultiplier && <TraitBoostTip />}
              </ItemDetail>
            </ItemInfo>
            {isUsingUserWallet ? (
              <>
                <StyledInfoPanel
                  skin="primary"
                  infos={[
                    {
                      title: `Your ${wNativeToken} Balance`,
                      value: `${formatBalance(wTokenBalance)} ${wNativeToken}`
                    }
                  ]}
                />
                <Inline>
                  <Button
                    size="large"
                    skin="primary"
                    block
                    disabled={confirmBtnDisabled || isLoading}
                    loading={isLoading}
                    onClick={handleBuyLiquidationAsset}
                  >
                    {confirmBtnDisabled ? `Insufficient ${wNativeToken}` : 'Buy Now'}
                  </Button>
                  <Button size="large" block onClick={onAddWETHClick} disabled={isLoading}>
                    Add {wNativeToken}
                  </Button>
                </Inline>
              </>
            ) : (
              <ConnectWallet btnTxt="Connect Wallet to Buy" />
            )}
          </>
        );
      })()}
    </Stack>
  );
});

export default MainForm;
