import styled from 'styled-components';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { Skeleton } from '@parallel-mono/components';

import { NoNesting } from './NoNesting';
import { Nesting } from './Nesting';
import useMoonBirdsData from './useMoonBirdsData';

import useNTokenMoonbirds from '@/apps/paraspace/pages/hooks/useNTokenMoonbirds';
import { useParallelToast } from '@/apps/paraspace/contexts';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { Collapse } from '@/apps/paraspace/components';

const Container = styled.div`
  margin-top: 1.5rem;
`;

export const Context = React.createContext({ isOwner: false });

interface NestProps {
  tokenId: string;
  isOwner: boolean;
}

const NestSection = ({ tokenId, isOwner }: NestProps) => {
  const { toggleNesting } = useNTokenMoonbirds();
  const transationToast = useParallelToast();

  const [inToggleNesting, setIntoggleNesting] = useState(false);
  const [refetch, setReferch] = useState(false);
  const data = useMoonBirdsData(tokenId, refetch);

  const handleStatus = useCallback(() => {
    setIntoggleNesting(true);
    transationToast.promise(
      toggleNesting(tokenId, data.nesting)
        .then(async transaction => {
          if (transaction === false) {
            throw new Error('', { cause: { name: 'nesting closed', message: '' } });
          } else {
            await transaction?.wait();
            setReferch(value => !value);
          }
        })
        .catch((e: Error) => {
          if ((e.cause as any)?.name === 'nesting closed') {
            throw new Error('Nesting is currently closed, nesting is not allowed.');
          }
          throw getUserFriendlyError(e);
        })
        .finally(() => {
          setIntoggleNesting(false);
        })
    );
  }, [transationToast, toggleNesting, tokenId, data]);

  const getComponent = () => {
    if (data.loading) {
      return <Skeleton />;
    }
    if (data.nesting || data.totalTime > 0) {
      return <Nesting data={data} inToggleNesting={inToggleNesting} handleStatus={handleStatus} />;
    }
    return <NoNesting handleStatus={handleStatus} inToggleNesting={inToggleNesting} />;
  };

  const contextValue = useMemo(
    () => ({
      isOwner
    }),
    [isOwner]
  );

  return (
    <Collapse border header="Nesting" defaultOpen>
      <Context.Provider value={contextValue}>
        <Container>{getComponent()}</Container>
      </Context.Provider>
    </Collapse>
  );
};

export const MoonbirdsNestSection = memo(NestSection);
