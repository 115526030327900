import { FC, memo, useCallback, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';

import { useContractsMap, useERC20 } from '@/apps/paraspace/hooks';
import { DepositERC20Submitter, Stepper, StepperProps } from '@/apps/paraspace/components';
import { ERC20Symbol, WalletType } from '@/apps/paraspace/typings';
import { formatBalance } from '@/apps/paraspace/utils/format';
import { BatchTransactionsSubmitter } from '@/apps/paraspace/submitters';
import { useAutoCompoundApe, useHelperContract } from '@/apps/paraspace/pages/hooks';
import { useWeb3Context } from '@/apps/paraspace/contexts';

interface StepProps extends Omit<StepperProps, 'steps' | 'step' | 'onError'> {
  onError: (e: any) => void;
  onSuccess: () => void;
  apeCoinSource: WalletType;
  amount: string;
  enableSupplyCApe: boolean;
}

const StakingStep: FC<StepProps> = ({
  onError,
  onSuccess,
  amount,
  enableSupplyCApe,
  apeCoinSource,
  ...others
}) => {
  const [step, setStep] = useState(0);

  const contracts = useContractsMap();
  const { account } = useWeb3Context();
  const { createApproval } = useERC20(contracts.APE);

  const onStepFinish = useCallback(() => {
    setStep(prevStep => prevStep + 1);
  }, []);

  const { genConvertApeCoinToPCApeTx } = useHelperContract();
  const { genStakingAutoCompoundApeTx } = useAutoCompoundApe();

  const steps = useMemo(
    () => [
      {
        description: `Deposit ${formatBalance(new BigNumber(amount))} APE to your Parallel account`,
        content: (
          <DepositERC20Submitter
            formData={{
              address: contracts.APE,
              symbol: ERC20Symbol.APE,
              value: new BigNumber(amount)
            }}
            onFinish={onStepFinish}
            onError={onError}
          />
        ),
        skip: apeCoinSource === 'AA'
      },
      {
        description: 'Stake APE',
        content: (
          <BatchTransactionsSubmitter
            inProgressMessage="Approve and Stake APE"
            batchTxs={[
              {
                tx: createApproval({ spender: contracts.HelperContract, amount }),
                skip: !enableSupplyCApe
              },
              {
                tx: genConvertApeCoinToPCApeTx(amount, account),
                skip: !enableSupplyCApe
              },
              {
                tx: createApproval({ spender: contracts.cAPE, amount }),
                skip: enableSupplyCApe
              },
              {
                tx: genStakingAutoCompoundApeTx(amount),
                skip: enableSupplyCApe
              }
            ]}
            onFinish={onSuccess}
            onError={onError}
          />
        )
      }
    ],
    [
      apeCoinSource,
      amount,
      contracts.HelperContract,
      contracts.cAPE,
      contracts.APE,
      account,
      enableSupplyCApe,
      onError,
      onStepFinish,
      onSuccess,
      createApproval,
      genConvertApeCoinToPCApeTx,
      genStakingAutoCompoundApeTx
    ]
  );

  return <Stepper steps={steps} step={step} {...others} />;
};

export default memo(StakingStep);
