import { sum } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';
import { isNil } from 'lodash';

import { one } from '@/apps/paraspace/consts/values';
import { Maybe } from '@/apps/paraspace/typings/basic';

export const allotShare = (
  existingShare: (BigNumber | undefined | null)[],
  stakeholderCount: number
) => {
  const filterOutNilShare = existingShare.filter(it => !isNil(it));
  if (filterOutNilShare.length === 0) {
    return null;
  }
  return one
    .minus(sum(filterOutNilShare as BigNumber[]))
    .div(stakeholderCount - filterOutNilShare.length);
};

export const shareToPercentage = (num: Maybe<BigNumber>) => {
  if (num === null) {
    return null;
  }
  return num.times(100).toNumber();
};

export const percentageToShare = (percentage: Maybe<number>) => {
  if (percentage === null) {
    return null;
  }
  return new BigNumber(percentage / 100);
};
