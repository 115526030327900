import { config } from './config';

export { getGraphqlConfig } from './config';
export { Network, NETWORK_NAME_MAP } from 'paraspace-configs-v2';

export * from './config';
export * from './types';
export * from './networksConfig';
export * from './contractsConfig';
export * from './erc20Configs';
export default config;
