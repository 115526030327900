import {
  DataGrid,
  DataGridColumn,
  H5,
  Inline,
  SmallText,
  Text,
  Stack,
  Button,
  DataGridProps,
  Skeleton,
  H6
} from '@parallel-mono/components';
import { FC, useMemo } from 'react';
import { isEmpty } from 'lodash';

import { ExplorerLink } from './components';

import { CollectionRow } from '.';

import { formatBalance, formatToPercentage } from '@/apps/paraspace/utils/format';
import { NFTCollectionThumbnail, ChainlinkLogo, StackedIcons } from '@/apps/paraspace/components';
import { useBendDaoBalanceMap, useWalletBalanceMap } from '@/apps/paraspace/pages/Credit/hooks';
import {
  SupplyERC721DropdownMenu,
  WalletsBalanceDropdown
} from '@/apps/paraspace/pages/Credit/components';
import { FetchingStatus, WalletType } from '@/apps/paraspace/typings';
import { useNativeTokenSymbol } from '@/apps/paraspace/pages/hooks/useNativeTokenSymbol';
import { useAppConfig } from '@/apps/paraspace/hooks';

export type TableForDesktopProps = Omit<DataGridProps<CollectionRow>, 'columns'> & {
  onSupply: (nft: { collectionName: string; symbol: string }, walletType: WalletType) => void;
  onSupplyFromOtherProtocols: (nft: { collectionName: string; symbol: string }) => void;
};

const TableForDesktop: FC<TableForDesktopProps> = ({
  onSupply,
  onSupplyFromOtherProtocols,
  ...others
}) => {
  const { AA: paraXBalance, EOA: EOABalance } = useWalletBalanceMap();
  const bendDaoBalanceMap = useBendDaoBalanceMap();
  const nativeSymbol = useNativeTokenSymbol();
  const {
    marketCurrencyTokenInfo: { name: marketCurrencyTokenName, isNativeTokenForNetWork }
  } = useAppConfig();

  const columns: DataGridColumn<CollectionRow>[] = useMemo(
    () => [
      {
        name: 'collection',
        width: '1.5fr',
        title: <H5 fontWeight="medium">Collection</H5>,
        render: ({ data: { symbol, collectionName } }) => {
          return (
            <Inline gap="0.75rem" alignItems="center">
              <NFTCollectionThumbnail symbol={symbol} size="small" round />
              <H5>{collectionName}</H5>
            </Inline>
          );
        }
      },
      {
        name: 'yourWallet',
        width: '1fr',
        title: <H5 fontWeight="medium">Your Wallet</H5>,
        render: ({
          data: {
            balanceMap: { AA, EOA },
            isComingSoon,
            balanceLoadingStatus
          }
        }) => {
          if (balanceLoadingStatus === FetchingStatus.FETCHING) {
            return <Skeleton.Title />;
          }
          if (isComingSoon || (isEmpty(AA.balances) && isEmpty(EOA.balances))) {
            return <SmallText>-</SmallText>;
          }

          return (
            <Stack gap="0.5rem">
              <StackedIcons assets={AA.balances.concat(EOA.balances)} variant="list" size="small" />
              <Inline>
                <WalletsBalanceDropdown
                  EOABalance={{
                    loading: EOA.loading,
                    value: <H6>{EOA.balances.length}</H6>
                  }}
                  paraXBalance={{ loading: AA.loading, value: <H6>{AA.balances.length}</H6> }}
                />
              </Inline>
            </Stack>
          );
        }
      },
      {
        name: 'floorPrice',
        width: '1fr',
        title: <H5 fontWeight="medium">Oracle Est. Floor</H5>,
        render: ({ data: { floorPrice, isComingSoon, symbol, floorPriceInMarketCurrency } }) => {
          if (!floorPrice || isComingSoon) return <SmallText>-</SmallText>;
          return (
            <Stack gap="0">
              {isNativeTokenForNetWork ? (
                <>
                  <Text>
                    {formatBalance(floorPrice)} {nativeSymbol}
                  </Text>
                  <ChainlinkLogo symbol={symbol} />
                </>
              ) : (
                <>
                  <Text>
                    {formatBalance(floorPriceInMarketCurrency)} {marketCurrencyTokenName}
                  </Text>
                  <SmallText skin="secondary">
                    {formatBalance(floorPrice)} {nativeSymbol}
                  </SmallText>
                </>
              )}
            </Stack>
          );
        }
      },
      {
        name: 'ltv',
        width: '1fr',
        title: <H5 fontWeight="medium">Loan To Value </H5>,
        render: ({ data: { ltv, floorPriceInMarketCurrency, isComingSoon } }) => {
          if (!floorPriceInMarketCurrency || !ltv || isComingSoon) return <SmallText>-</SmallText>;
          return (
            <Stack gap="0">
              <Text>{formatToPercentage(ltv)}</Text>
              <SmallText skin="secondary">
                {formatBalance(floorPriceInMarketCurrency.times(ltv))} {nativeSymbol}/NFT
              </SmallText>
            </Stack>
          );
        }
      },
      {
        name: 'totalSupplied',
        width: '1fr',
        title: <H5 fontWeight="medium">Total Supplied</H5>,
        render: ({ data: { totalSupplied, isComingSoon, symbol } }) => {
          if (!totalSupplied || isComingSoon) return <SmallText>-</SmallText>;
          return (
            <Stack gap="0">
              <Text>{formatBalance(totalSupplied)}</Text>
              <ExplorerLink symbol={symbol} />
            </Stack>
          );
        }
      },
      {
        name: 'action',
        width: '1fr',
        title: '',
        render: ({ data: { collectionName, symbol, isComingSoon } }) => {
          return (
            <Inline width="100%" justifyContent="flex-end">
              {isComingSoon ? (
                <Button skin="secondary" disabled>
                  Coming Soon
                </Button>
              ) : (
                <SupplyERC721DropdownMenu
                  menuTrigger={<Button skin="secondary">Supply</Button>}
                  paraXWalletOption={{
                    onClick: () => onSupply({ collectionName, symbol }, 'AA'),
                    balance: paraXBalance[symbol]?.length ?? 0
                  }}
                  EOAWalletOption={{
                    onClick: () => onSupply({ collectionName, symbol }, 'EOA'),
                    balance: EOABalance[symbol]?.length ?? 0
                  }}
                  otherProtocolOption={{
                    onClick: () => onSupplyFromOtherProtocols({ collectionName, symbol }),
                    balance: bendDaoBalanceMap[symbol] ?? 0
                  }}
                />
              )}
            </Inline>
          );
        }
      }
    ],
    [
      isNativeTokenForNetWork,
      nativeSymbol,
      marketCurrencyTokenName,
      paraXBalance,
      EOABalance,
      bendDaoBalanceMap,
      onSupply,
      onSupplyFromOtherProtocols
    ]
  );

  return <DataGrid columns={columns} {...others} />;
};

export default TableForDesktop;
