import { memo } from 'react';
import { Button, H4, Icon, Stack, StackProps, Text } from '@parallel-mono/components';
import styled from 'styled-components';

import { ContractBanner } from '../../../components/ContractBanner';
import { ApeCoinListing, ApeListing } from '../../../types';

import { Maybe } from '@/apps/paraspace/typings/basic';
import { ErrorConfig } from '@/apps/paraspace/utils/getUserFriendlyError';

const ErrorIcon = styled(Icon).attrs({
  name: 'closeContained',
  size: 'xlarge'
})`
  color: ${({ theme }) => theme.skin.error.main};
`;

const ConfirmButton = styled(Button).attrs({
  skin: 'secondary'
})`
  margin-top: 1.5rem;
  width: 100%;
`;

const StyledUl = styled.ul`
  list-style: disc;
  padding: 0 2.75rem;

  & > * {
    line-height: 1.5;
    margin-top: 0.25rem;
  }
`;

type JoinApeCoinListingErrorStateProps = Omit<StackProps, 'children'> & {
  onFinish: () => void;
  account: string;
  apeListing: Maybe<ApeListing>;
  apeCoinListing: ApeCoinListing;
  errConfig: Maybe<ErrorConfig>;
};

export const JoinListingWithApeErrorState = memo(
  ({
    onFinish,
    apeListing,
    apeCoinListing,
    account,
    errConfig,
    ...others
  }: JoinApeCoinListingErrorStateProps) => {
    return (
      <Stack justifyContent="center" alignItems="center" {...others}>
        <ContractBanner
          apeListing={apeListing}
          bakcListing={null}
          apeCoinListing={apeCoinListing}
          account={account}
        />
        <ErrorIcon />
        <Stack gap="0" alignItems="center">
          {errConfig ? (
            <H4>{errConfig.userFriendlyErrorMsg}</H4>
          ) : (
            <>
              <H4>An error has occurred. Possible errors:</H4>
              <StyledUl>
                <Text as="li" skin="secondary">
                  Insufficient cAPE from the other party.
                </Text>
                <Text as="li" skin="secondary">
                  This offer has been taken by other users.
                </Text>
              </StyledUl>
            </>
          )}
        </Stack>
        <ConfirmButton onClick={onFinish}>Done</ConfirmButton>
      </Stack>
    );
  }
);
