import {
  Button,
  Card,
  CardProps,
  H4,
  Inline,
  SmallText,
  Stack,
  Text
} from '@parallel-mono/components';
import { FC, memo, useCallback, useState } from 'react';
import { CryptoIcon } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { useV1cAPEConvertProvider } from '@/apps/paraspace/pages/contexts/V1cAPEConvertManagerProvider';
import { useWeb3Context } from '@/apps/paraspace/contexts';
import { formatBalance } from '@/apps/paraspace/utils/format';

const StyledSmallText = styled(SmallText)`
  text-align: center;
`;

export const V1cAPEBalanceInfos: FC<{ balance: BigNumber } & Omit<CardProps, 'children'>> = memo(
  ({ balance, ...others }) => {
    const [isConverting, setIsConverting] = useState(false);
    const { convertV1cAPE } = useV1cAPEConvertProvider();

    const {
      authentication: {
        meta: { account: EOAAccount }
      },
      account
    } = useWeb3Context();

    const handleConvert = useCallback(async () => {
      setIsConverting(true);
      await convertV1cAPE(balance, EOAAccount, account).finally(() => {
        setIsConverting(false);
      });
    }, [EOAAccount, account, balance, convertV1cAPE]);

    return (
      <Card inset="1.5rem" width="100%" border shadow="none" {...others}>
        <Stack>
          <Stack gap="0.25rem" alignItems="center" justifyContent="center">
            <H4>Convert your cAPE</H4>
            <StyledSmallText skin="secondary">
              Convert your cAPE from ParaSpace V1 to your current Parallel Account
            </StyledSmallText>
          </Stack>
          <Inline alignItems="center" gap="1rem">
            <CryptoIcon symbol="CAPE" size="large" />
            <Stack gap="0">
              <Text skin="secondary">V1 cAPE Balance</Text>
              <Inline gap="0.25rem">
                <H4>{formatBalance(balance.toNumber())}</H4>
                <H4 skin="secondary">cAPE</H4>
              </Inline>
            </Stack>
          </Inline>
          <Button loading={isConverting} disabled={isConverting} block onClick={handleConvert}>
            Convert
          </Button>
        </Stack>
      </Card>
    );
  }
);
