import { useCallback, useEffect, useMemo, useState } from 'react';
import { ParaspaceApeAirdrop } from 'paraspace-utilities-contract-helpers';

import { Network } from '@/apps/paraspace/config';
import { useWeb3Context, useParallelToast } from '@/apps/paraspace/contexts';
import submitTransaction from '@/apps/paraspace/utils/submitTransaction';
import { getUserFriendlyError } from '@/apps/paraspace/utils/getUserFriendlyError';
import { useAppConfig } from '@/apps/paraspace/hooks';

const CONTRACT_ADDRESS = {
  [Network.MAINNET]: '0xD22f0FebDE8971A5B3CACE07D2550Bfd397754B9',
  [Network.SEPOLIA]: '0x8140561516c244C8bbE4f1344cea12060ddFa709',
  [Network.FORK]: '0xD22f0FebDE8971A5B3CACE07D2550Bfd397754B9'
} as Record<Network, string>;

export const useAirdrop = () => {
  const { chainId, provider, account } = useWeb3Context();
  const { erc20Config } = useAppConfig();
  const toast = useParallelToast();
  const address = CONTRACT_ADDRESS[chainId];
  const [claiming, setClaiming] = useState(false);
  const [availableAmount, setAvailableAmount] = useState(0);

  const airdrop = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new ParaspaceApeAirdrop(provider, address);
  }, [address, provider]);

  const refresh = useCallback(async () => {
    if (airdrop) {
      const { claimed, amount } = await airdrop.userStatus(account);
      if (!claimed) {
        setAvailableAmount(amount.dividedBy(10 ** erc20Config.APE.decimals).toNumber());
      } else {
        setAvailableAmount(0);
      }
    }
  }, [account, airdrop, erc20Config.APE.decimals]);

  const claim = useCallback(() => {
    if (!airdrop || !provider) {
      return null;
    }
    const transaction = airdrop.claimAirdrop(account)[0];
    const txRes = submitTransaction({ provider, tx: transaction });
    setClaiming(true);
    return toast.promise(
      txRes
        .then(async tx => {
          await tx?.wait();
          setClaiming(false);
        })
        .catch((e: Error) => {
          setClaiming(false);
          throw getUserFriendlyError(e);
        })
        .finally(async () => {
          await refresh();
        })
    );
  }, [account, airdrop, provider, refresh, toast]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    availableAmount,
    claiming,
    claim
  };
};
