import {
  DecoratedInput,
  H4,
  NumericInputBase,
  NumericInputProps,
  TypographyVariant
} from '@parallel-mono/components';
import { FC } from 'react';
import { isNil } from 'lodash';
import styled, { CSSProperties } from 'styled-components';
import BigNumberJs from 'bignumber.js';

import { Maybe } from '@/apps/paraspace/typings/basic';

const InputContainer = styled(DecoratedInput)<{
  typography: TypographyVariant;
  width?: CSSProperties['width'];
}>`
  width: ${({ width }) => width ?? '100%'};
  padding-top: ${({ theme }) => `${theme.spacing(4)};`};
  padding-bottom: ${({ theme }) => `${theme.spacing(4)};`};
  ${({ theme, typography }) => theme.typography[typography]}
`;

const Symbol = styled(H4)`
  color: ${({ theme }) => `${theme.skin.grey[500]};`};
`;

const PERCENTAGE_SCALING_FACTOR = 100;
const MIN_PERCENT_DECIMAL = 0;

interface PercentageInputProps extends Omit<NumericInputProps, 'onChange'> {
  onChange?: (value: Maybe<number>) => void;
  typography?: TypographyVariant;
  width?: CSSProperties['width'];
}

export const PercentageInput: FC<PercentageInputProps> = ({
  max = 1,
  value,
  onChange,
  typography = 'header3',
  decimals = MIN_PERCENT_DECIMAL,
  width,
  ...others
}) => {
  const maxValue = max * PERCENTAGE_SCALING_FACTOR;
  const displayValue = isNil(value)
    ? null
    : BigNumberJs(value).multipliedBy(PERCENTAGE_SCALING_FACTOR).toNumber();

  const handlePercentageChange = (number: Maybe<number>) => {
    onChange?.(
      isNil(number) ? null : BigNumberJs(number).div(PERCENTAGE_SCALING_FACTOR).toNumber()
    );
  };

  return (
    <InputContainer
      width={width}
      Component={NumericInputBase}
      endAdornment={<Symbol>%</Symbol>}
      typography={typography}
      inputProps={{
        min: 0,
        max: maxValue,
        decimals,
        value: displayValue,
        onChange: handlePercentageChange,
        ...others
      }}
    />
  );
};
