import { Stack, StackProps, Icon } from '@parallel-mono/components';
import { memo, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { CollectionSelect } from './CollectionSelect';
import { Stats } from './Stats';

import { SideMenus, PageLayout, SelectBaseOption } from '@/apps/paraspace/components';
import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { ERC721Symbol } from '@/apps/paraspace/typings';

export type LiquidationDashboardProps = Omit<StackProps, 'children'>;

const menus = [
  {
    name: 'In Liquidation',
    path: absoluteRouteNames.LENDING.LIQUIDATION.IN_LIQUIDATION,
    icon: <Icon name="pulse" strokeWidth={2} />
  },
  {
    name: 'Near Liquidation',
    path: absoluteRouteNames.LENDING.LIQUIDATION.NEAR_LIQUIDATION,
    icon: <Icon name="thermometer" strokeWidth={2} />
  },
  {
    name: 'Paused Liquidation',
    path: absoluteRouteNames.LENDING.LIQUIDATION.PAUSED_LIQUIDATION,
    icon: <Icon name="pause" strokeWidth={2} />
  }
];

export type LiquidationOutletContext = {
  selectedCollectionSymbols: ERC721Symbol[];
};

export const LiquidationDashboard = memo((props: LiquidationDashboardProps) => {
  const [selectedCollections, setSelectedCollections] = useState<SelectBaseOption[]>([]);

  const selectedCollectionSymbols = useMemo(
    () => selectedCollections.map(op => op.value as ERC721Symbol),
    [selectedCollections]
  );

  return (
    <Stack {...props}>
      <PageLayout
        banner={<Stats />}
        sideMenu={<SideMenus menus={menus} />}
        mainContent={
          <Stack width="100%">
            <CollectionSelect value={selectedCollectionSymbols} onChange={setSelectedCollections} />
            <Outlet context={{ selectedCollectionSymbols }} />
          </Stack>
        }
      />
    </Stack>
  );
});
