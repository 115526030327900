import BigNumberJs from 'bignumber.js';

type TokenProps = {
  amount: BigNumberJs;
  currentPrice: BigNumberJs;
  minPrice: BigNumberJs;
  maxPrice: BigNumberJs;
};

export const getToken0AmountForToken1Amount = ({
  amount,
  currentPrice,
  minPrice,
  maxPrice
}: TokenProps) => {
  const liquidityValue = amount.div(currentPrice.sqrt().minus(minPrice.sqrt()));
  return liquidityValue
    .div(currentPrice.sqrt())
    .div(maxPrice.sqrt())
    .times(maxPrice.sqrt().minus(currentPrice.sqrt()));
};

export const getToken1AmountForToken0Amount = ({
  amount,
  currentPrice,
  minPrice,
  maxPrice
}: TokenProps) => {
  const liquidityValue = amount
    .times(currentPrice.sqrt())
    .times(maxPrice.sqrt())
    .div(maxPrice.sqrt().minus(currentPrice.sqrt()));
  return liquidityValue.times(currentPrice.sqrt().minus(minPrice.sqrt()));
};
