import { HostedImage } from '@parallel-mono/business-components';
import { Icon } from '@parallel-mono/components';
import { memo, useMemo } from 'react';

import { absoluteRouteNames } from '@/apps/paraspace/App/routeConfig';
import { SideMenus } from '@/apps/paraspace/components';
import { useToggle } from '@/apps/paraspace/contexts';

const APE_STAKING_MENUS = [
  {
    icon: <HostedImage height={24} name="design/PDS_V3/chain_products/paraspace/swap" />,
    name: 'Auto Compound',
    path: absoluteRouteNames.APE_STAKING.AUTO_COMPOUND,
    subMenus: [
      {
        name: 'ApeCoin Pool',
        path: absoluteRouteNames.APE_STAKING.COIN_POOL.index
      },
      {
        name: 'NFT Pool',
        path: absoluteRouteNames.APE_STAKING.NFT_POOLS
      },
      {
        name: 'Share Pool',
        path: absoluteRouteNames.APE_STAKING.APE_SHARE_POOLS.index
      }
    ]
  },
  {
    icon: <Icon name="barChart2" strokeWidth={2} />,
    name: 'My Earnings',
    path: absoluteRouteNames.APE_STAKING.MY_EARNINGS
  },
  {
    icon: <Icon name="percent" strokeWidth={2} />,
    name: 'Calculator',
    path: absoluteRouteNames.APE_STAKING.CALCULATOR
  },
  {
    icon: <Icon name="gift" strokeWidth={2} />,
    name: 'Mint',
    path: absoluteRouteNames.APE_STAKING.MINT
  },
  {
    icon: <Icon name="medal" strokeWidth={2} />,
    name: 'Airdrop',
    path: absoluteRouteNames.APE_STAKING.AIRDROP
  },
  {
    icon: <Icon name="code" strokeWidth={2} />,
    name: 'Horizen Staking',
    path: absoluteRouteNames.APE_STAKING.HORIZEN_STAKING
  },
  {
    icon: <Icon name="pulse" strokeWidth={2} />,
    name: 'Pool Stats',
    path: absoluteRouteNames.APE_STAKING.POOL_STATS
  }
];

export const Menus = memo(() => {
  const mintEnabled = useToggle('APE_MINT');

  const enabledMenus = useMemo(() => {
    return APE_STAKING_MENUS.filter(
      menu => menu.path !== absoluteRouteNames.APE_STAKING.MINT || mintEnabled
    );
  }, [mintEnabled]);

  return <SideMenus menus={enabledMenus} />;
});
