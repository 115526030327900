import { useCallback } from 'react';
import BigNumberJs from 'bignumber.js';

import { useBuyCartProvider } from '.';

import usePool from '@/apps/paraspace/pages/hooks/usePool';
import { useAppConfig } from '@/apps/paraspace/hooks';

export const useBuyWithCreditOnBlur = () => {
  const { initiateBlurBuyWithCredit, listenBlurExchangeRequestResultEvent } = usePool();
  const { totalPrice, buyCartList } = useBuyCartProvider();
  const { erc20Config } = useAppConfig();

  const initBuy = useCallback(
    async (creditAmount: BigNumberJs) => {
      const tx = await initiateBlurBuyWithCredit(
        buyCartList.map(item => {
          const payLaterAmount = new BigNumberJs(creditAmount).times(item.price.div(totalPrice));
          return {
            price: item.price.shiftedBy(erc20Config.ETH.decimals).toString(),
            payLaterAmount: payLaterAmount.shiftedBy(erc20Config.ETH.decimals).toString(),
            collection: item.contractAddress,
            tokenId: item.tokenId
          };
        })
      );
      tx?.wait();
    },
    [buyCartList, initiateBlurBuyWithCredit, totalPrice, erc20Config.ETH.decimals]
  );

  const listenResult = useCallback(
    (callback: Parameters<typeof listenBlurExchangeRequestResultEvent>[1]) => {
      listenBlurExchangeRequestResultEvent(
        buyCartList.map(each => {
          return {
            collection: each.contractAddress,
            tokenId: each.tokenId
          };
        }),
        callback
      );
    },
    [buyCartList, listenBlurExchangeRequestResultEvent]
  );

  return {
    initBuy,
    listenResult
  };
};
