import { useCallback, useMemo, useState } from 'react';
import { Alert, Inline, Stack, Text, H5, Button } from '@parallel-mono/components';
import { CryptoIcon, InfoPanel } from '@parallel-mono/business-components';
import BigNumberJS from 'bignumber.js';
import { isNil } from 'lodash';

import { validation } from './validate';

import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { FLOAT_SCALING_FACTOR, formatToPercentage } from '@/apps/paraspace/utils/format';
import {
  ApeCompoundSellToken,
  useLegacyPoolApeStaking
} from '@/apps/paraspace/pages/hooks/usePoolApeStaking';
import { ERC20Symbol } from '@/apps/paraspace/typings';
import { SellToken } from '@/apps/paraspace/pages/ApePairing/pages/MyApes/components/SideBar/AutoSellPanel/hooks';
import { AutoRepayTag } from '@/apps/paraspace/pages/ApePairing/pages/MyApes/components/SideBar/AutoSellPanel/components';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { one } from '@/apps/paraspace/consts/values';
import {
  FormSubmitter,
  PercentageInput,
  Select,
  ErrorState,
  SuccessState,
  SelectBaseOption
} from '@/apps/paraspace/components';

enum FormState {
  MAIN_TOKEN_FORM = 1,
  STEPPER = 2,
  ERROR = 3,
  SUCCESS = 4
}

export type AutoSellFormProps = {
  curPercentage: BigNumberJS;
  curToken: SellToken;
  onClose: () => void;
};

const currencySelectOptions = [
  {
    icon: <CryptoIcon symbol="USDC" size="small" />,
    label: ERC20Symbol.USDC,
    value: ERC20Symbol.USDC
  },
  {
    icon: <CryptoIcon symbol="ETH" size="small" />,
    label: ERC20Symbol.ETH,
    value: ERC20Symbol.ETH
  }
];

export const AutoSellForm = ({ curPercentage, curToken, onClose }: AutoSellFormProps) => {
  const { erc20InfoMap } = useMMProvider();
  const { setUserApeCompoundStrategy } = useLegacyPoolApeStaking();

  const [formState, setFormState] = useState<FormState>(FormState.MAIN_TOKEN_FORM);
  const [newToken, setNewToken] = useState<SellToken>(curToken);
  const [newPercentage, setNewPercentage] = useState<Maybe<BigNumberJS>>(curPercentage);

  const { supplyApyRate } = erc20InfoMap[newToken];

  const handleSellPercentage = useCallback(value => {
    setNewPercentage(isNil(value) ? null : BigNumberJS(value));
  }, []);

  const noAutoRepay = useMemo(() => newPercentage?.eq(one), [newPercentage]);

  const handleSuccess = useCallback(() => {
    setFormState(FormState.SUCCESS);
  }, []);
  const handleError = useCallback(() => {
    setFormState(FormState.ERROR);
  }, []);

  const error = useMemo(
    () =>
      validation({
        curPercentage,
        newPercentage,
        curToken,
        newToken
      }),
    [curPercentage, curToken, newPercentage, newToken]
  );

  const handleSubmit = useCallback(
    () =>
      setUserApeCompoundStrategy(
        newPercentage?.times(FLOAT_SCALING_FACTOR).toNumber() ?? 0,
        ApeCompoundSellToken[newToken]
      ),
    [setUserApeCompoundStrategy, newPercentage, newToken]
  );

  if (formState === FormState.MAIN_TOKEN_FORM) {
    return (
      <Stack gap="1.5rem">
        <Stack gap=".25rem">
          <Inline justifyContent="space-between" alignItems="center">
            <H5>Auto Repay</H5>
            <AutoRepayTag autoSellPercentage={newPercentage} />
          </Inline>
          <Text skin="secondary">
            Automatically repay your cAPE debt to prevent your interest from compounding and
            maximizes your return. Auto repay is always on unless you choose to auto sell 100% of
            profits.
          </Text>
        </Stack>
        <Inline alignItems="center" justifyContent="space-between">
          <H5>Sell to currency</H5>
          <Select
            options={currencySelectOptions}
            value={newToken}
            onChange={(item: SelectBaseOption | null) => setNewToken(item?.value)}
          />
        </Inline>
        {noAutoRepay && (
          <Alert type="info">
            Auto Repay is turned off because you are selling 100% of the profits.
          </Alert>
        )}
        <Stack gap="0.5rem">
          <H5>Auto Sell % of Profits</H5>
          <PercentageInput value={newPercentage?.toNumber()} onChange={handleSellPercentage} />
        </Stack>
        <InfoPanel
          skin="primary"
          infos={[
            {
              title: 'Lending APY',
              value: formatToPercentage(supplyApyRate ?? 0)
            }
          ]}
        />
        <Button
          block
          size="large"
          disabled={!!error}
          onClick={() => {
            setFormState(FormState.STEPPER);
          }}
        >
          {error ?? 'Confirm'}
        </Button>
      </Stack>
    );
  }

  if (formState === FormState.STEPPER) {
    return (
      <FormSubmitter
        submit={handleSubmit}
        onError={handleError}
        onFinish={handleSuccess}
        inProgressMessage="Setting up auto-sell for you "
      />
    );
  }

  if (formState === FormState.ERROR) {
    return <ErrorState closeModal={onClose} />;
  }

  if (formState === FormState.SUCCESS) {
    return (
      <SuccessState
        desc="Success"
        tip="Successfully changed auto sell settings. You can always change again at any time."
        onAction={onClose}
      />
    );
  }

  return null;
};
