import { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { Environment } from 'parax-sdk';

import config from '../../config';

export const GoogleAnalytics: FC = ({ children }) => {
  useEffect(() => {
    const isProduction = config.env === Environment.PRODUCTION;
    if (isProduction) {
      ReactGA.initialize('G-XQ58CQPC9X');
      TagManager.initialize({ gtmId: 'GTM-N8QX8QC' });
    }
  }, []);
  return <>{children}</>;
};
