import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  H6,
  Inline,
  Stack,
  StackProps,
  Tag,
  Text,
  Toggle
} from '@parallel-mono/components';

import { ERC721Symbol } from '@/apps/paraspace/typings';
import { NFTThumbnail } from '@/apps/paraspace/components';

export type TokenItemProps = Omit<StackProps, 'children'> & {
  inLiquidation: boolean;
  tokenId: number;
  name: string;
  asCollateral: boolean;
  isInRange: boolean;
  isClosed: boolean;
  onToggleCollateral: (tokenId: number) => void;
  onRemoveLiquidity: (tokenId: number) => void;
  onAddLiquidity: (tokenId: number) => void;
  contractAddress: string;
};

const HeaderRow = styled(Inline)`
  flex-wrap: wrap;
`;

const SkinnyButton = styled(Button)`
  padding: 0.5rem;
`;

export const TokenItem = memo(
  ({
    inLiquidation,
    tokenId,
    name,
    asCollateral,
    isInRange,
    isClosed,
    onToggleCollateral,
    contractAddress,
    onRemoveLiquidity,
    onAddLiquidity,
    ...others
  }: TokenItemProps) => {
    const navigate = useNavigate();

    const handleDetailClick = useCallback(() => {
      navigate(`/details/${contractAddress}/${tokenId}`);
    }, [navigate, contractAddress, tokenId]);

    const handleToggleCollateral = useCallback(() => {
      onToggleCollateral(tokenId);
    }, [onToggleCollateral, tokenId]);

    return (
      <Stack gap="1rem" {...others}>
        <HeaderRow gap="0" onClick={handleDetailClick} alignItems="center">
          <NFTThumbnail symbol={ERC721Symbol.UNISWAP_LP} tokenId={tokenId} size="small" />
          <Text fontWeight="bold">{name.replace(/^Uniswap.*?-\s?/, '')}</Text>
          {!isInRange && !isClosed && (
            <Tag skin="warning" size="small">
              Out of Range
            </Tag>
          )}
          {isClosed && (
            <Tag skin="primary" size="small">
              Closed
            </Tag>
          )}
        </HeaderRow>
        <Inline justifyContent="space-between">
          <H6 skin="secondary" fontWeight="medium">
            Collateral
          </H6>
          <Toggle
            checked={asCollateral}
            disabled={asCollateral && inLiquidation}
            onChange={handleToggleCollateral}
          />
        </Inline>
        <Inline justifyContent="space-between">
          <SkinnyButton block skin="secondary" onClick={() => onAddLiquidity(tokenId)}>
            Add Liquidity
          </SkinnyButton>
          <SkinnyButton
            skin="secondary"
            block
            disabled={isClosed || inLiquidation}
            onClick={() => onRemoveLiquidity(tokenId)}
          >
            Remove Liquidity
          </SkinnyButton>
        </Inline>
      </Stack>
    );
  }
);
