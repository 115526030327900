import { useEffect, useMemo, useState } from 'react';
import { H5, Image, Stack } from '@parallel-mono/components';

import { useBuyWithCreditOnBlur } from '../../../context/useBuyWithCreditOnBlur';

import { StyledSpinner, StyledText } from './BatchBuyOpenseaStepper';

import { BatchBuyModalPayload } from '.';

import { Stepper } from '@/apps/paraspace/components';
import metamask from '@/apps/paraspace/assets/meta-mask.svg';

export const InitBlurRequestStepper = ({
  payload,
  onError,
  onSuccess
}: {
  payload: BatchBuyModalPayload;
  onError: (error: Error) => void;
  onSuccess: () => void;
}) => {
  const [step, setStep] = useState(0);
  const { initBuy, listenResult } = useBuyWithCreditOnBlur();
  const { creditAmount } = payload;

  useEffect(() => {
    if (step === 0) {
      initBuy(creditAmount)
        .then(() => {
          setStep(1);
        })
        .catch(error => {
          onError(error);
          throw error;
        });
    } else {
      listenResult((resultMap, finished) => {
        if (finished) {
          // const allSuccess = Object.keys(resultMap).every(key => resultMap[key].fulfilled);
          const allFailed = Object.keys(resultMap).every(
            key => resultMap[key as unknown as number].rejected
          );
          if (allFailed) {
            const error = new Error('Buying service failed to finish purchasing from Blur.');
            onError(error);
            throw error;
          } else {
            onSuccess();
          }
        }
      });
    }
  }, [initBuy, listenResult, creditAmount, step, onSuccess, onError]);

  const steps = useMemo(() => {
    return [
      {
        description: 'Initialize Blur buy now pay later',
        content: (
          <Stack alignItems="center" gap="1rem">
            <Image src={metamask} width="2rem" height="2rem" />
            <H5>Please approve on your wallet.</H5>
            <StyledText skin="secondary">
              This may take up to 20 seconds. Please do not close the modal.
            </StyledText>
          </Stack>
        )
      },
      {
        description: 'Waiting our service to finish purchasing',
        content: (
          <Stack gap="0.25rem" alignItems="center">
            <StyledSpinner size="large" />
            <StyledText skin="secondary">
              This may take up to several minutes. Please do not close modal.
            </StyledText>
          </Stack>
        )
      }
    ];
  }, []);
  return <Stepper steps={steps} step={step} />;
};
