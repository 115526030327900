import { memo, useMemo } from 'react';
import { Stack } from '@parallel-mono/components';

import depositTvlTitle from '../assets/depositTvlTitle.svg';
import { GridData, LeaderboardTypes, useBnplAndDepositColum, useLeaderboard } from '../hooks';
import { StyledCard, StyledImage } from '../styles';
import { generateTwitterContent } from '../utils';
import { RankGrid, ShareRankButton } from '../components';

import { useWeb3Context } from '@/apps/paraspace/contexts';

export const DepositTvl = memo(() => {
  const { chainId } = useWeb3Context();
  const { data, userData, loading, currentPage, totalPage, handlePageChange } = useLeaderboard(
    LeaderboardTypes.DEPOSIT_TVL
  );
  const twitterContent = useMemo(
    () =>
      userData
        ? generateTwitterContent({
            chainId,
            position: userData.position,
            rankName: 'TVL'
          })
        : '',
    [chainId, userData]
  );
  const columns = useBnplAndDepositColum({ userData, valueColumnLabel: 'Deposited TVL' });
  return (
    <Stack gap="2rem">
      <StyledCard border shadow="none">
        <Stack gap="0">
          <StyledImage src={depositTvlTitle} />
          <RankGrid<GridData>
            data={data}
            userData={userData as GridData}
            loading={loading}
            columns={columns}
            currentPage={currentPage}
            totalPage={totalPage}
            handlePageChange={handlePageChange}
          />
        </Stack>
      </StyledCard>
      <ShareRankButton
        isLoading={loading}
        twitterContent={twitterContent}
        hashtags="ParallelFi,TVLranking,CryptoCommunity"
      />
    </Stack>
  );
});
